import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalTitle, ModalFooter } from "@vds3/modals";
import { Body, Micro, Title } from "@vds3/typography";
import {
  postClearCart,
  setCleartCartDetails,
} from "pages/ShoppingCart/ducks/ShoppingCartSlice";
// import { reset4GState } from "pages/Landing4G-V2/ducks/4GSlice";
// import { reset5GState } from "pages/Landing5G-V2/ducks/5GSlice";
// import { resetPlansSlice } from "pages/Plans/ducks/PlansSlice";
// import { resetMobilitySlice, setDeviceListBy } from "pages/Mobility/ducks/MobilitySlice";
import * as SiteAnalytics from "../../../../sitecatalyst";

const ClearCartModal = ({
  opened,
  onOpenedChange,
  pageDataValue,
  cartProperties,
  isDSP,
  serviceProvider,
  UnifiedCart,
  ...props
}) => {
  const { t } = useTranslation(["shoppingCart"]);
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  
  useEffect(() => {
    if (pageDataValue?.cleartCartDetails?.data?.success) {
      if(!UnifiedCart && !cartProperties?.SmartPhones && !cartProperties?.MobilityByod){ 
       let path = "/shop/products/devices/smartphones";
      if(isDSP && serviceProvider){
        path = `/shop/dsp/${serviceProvider}/devices`;
      } else if(cartProperties?.FourG || cartProperties?.FiveG) {
        window.location.href = "https://www.verizon.com/business/products/internet/";
      } else if(cartProperties?.OneTalk) {
        path = "/shop/products/voice-collaboration/unified-communications/one-talk";
      }
      //navigate(path);
    }
      dispatch(setCleartCartDetails({}));
    }
  }, [pageDataValue?.cleartCartDetails?.data]);

  const onClearCartClicked = () => {
    try {
      SiteAnalytics.eventShoppingCartInfoTracking('Clear Cart',JSON.parse(window?.localStorage?.getItem("_cartData")), 'clear_cart', 'clear_cart_popup');
    }
    catch (e) {
      console.error('Shopping Cart Remove', e)
    }
    // dispatch(reset4GState());
    // dispatch(reset5GState());
    // dispatch(resetPlansSlice());
    dispatch(postClearCart());
    props?.setClearCart(false); 
    if(UnifiedCart || cartProperties?.SmartPhones || cartProperties?.MobilityByod) {
      if(cartProperties?.MobilityByod) {
        // dispatch(resetMobilitySlice());
      }
      onOpenedChange(false);
    }
  };

  return (
    <>
      {opened && (
        <Modal
          hideCloseButton={true}
          disableOutsideClick={true}
          onOpenedChange={onOpenedChange}
          opened={opened}
        >
          <ModalTitle>
            <Title size="large" bold>
              {t("shopping-cart.clear-cart-modal.clearcart")}
            </Title>
          </ModalTitle>
          <ModalFooter
            buttonData={{
              primary: {
                children: "Yes",
                onClick: () => {
                  onClearCartClicked();
                },
                'data-track':"Yes"
              },
              close: {
                children: "No",
                onClick: () => {
                  onOpenedChange(false);
                },
                'data-track':"No"
              },
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default ClearCartModal;
