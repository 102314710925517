import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import ColorSwatch from "common/components/ColorSwatch/ColorSwatch";
import { MarginSpacerL } from "common/components/styleTag";
import PDPContext from "./pdpContext";

function ColorOptions({
	colorOptions,
	showTopLine,
	handleHoveredColor,
	isSmartWatch,
	...props
}) {
	const {
		selectedColor: { selectedColor, setSelectedColor },
	} = React.useContext(PDPContext);

	const isMobile = useMediaQuery({
		query: "(max-width: 545px)",
	});

	const Colorbox = styled.div`
		&:hover {
			border: ${({ selected }) => (selected ? "none" : "2px solid #000")};
			border-radius: 50%;

			.StrokeCircle {
				stroke: #000 !important;
			}
		}
		display: flex;
		flex-direction: column;
		flex: 0;
		margin-right: ${isMobile && colorOptions?.length > 5 ? "30px" : "22px"};
		text-align: center;
		align-items: center;
		background-color: transparent;
		svg {
			margin-left: auto;
			margin-right: auto;
		}
	`;

	useEffect(() => {
		if (colorOptions?.length && !selectedColor) {
			setSelectedColor(colorOptions[0]?.cssColor);
		}
	}, [colorOptions]);

	return (
		<ColorOptionsSection className="color-swatch">
			<MarginSpacerL />
			<ColorboxContainer>
				{colorOptions?.map((sku) => {
					const colorCode =
						isSmartWatch && sku?.caseColorCode ? sku.caseColorCode : sku?.cssColor;
					const colorName =
						isSmartWatch && sku?.caseColor ? sku.caseColor : sku?.color;
					return (
						<Colorbox
							key={sku.cssColor}
							selected={sku.cssColor === selectedColor}
							data-track={sku.color}
						>
							<ColorSwatch
								cssColor={colorCode}
								isSelected={sku?.cssColor === selectedColor}
								value={sku.index}
								onClick={() => {
									setSelectedColor(sku?.cssColor);
								}}
								onMouseOver={() => {
									typeof handleHoveredColor === "function" &&
										handleHoveredColor(colorName);
								}}
								onMouseLeave={() => {
									typeof handleHoveredColor === "function" && handleHoveredColor("");
								}}
								size="large"
								isOutOfStock={sku.outOfStock}
								width={"48"}
								height={"48"}
							/>
						</Colorbox>
					);
				})}
			</ColorboxContainer>
		</ColorOptionsSection>
	);
}

const ColorOptionsSection = styled.div`
	margin: 0px !important;
`;
const ColorboxContainer = styled.div`
	display: flex;
	flex-flow: row wrap;
	row-gap: 0.75rem;
	padding-bottom: 0px !important;
`;
const Colorbox = styled.div`
	display: flex;
	flex-direction: column;
	flex: 0;
	// flex-basis: 5rem;
	margin-right: 16px;
	text-align: center;
	align-items: center;
	background-color: transparent;
	svg {
		margin-left: auto;
		margin-right: auto;
	}
`;

export default ColorOptions;
