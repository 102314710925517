import styled from "styled-components";
import { RadioBox } from "@vds3/radio-boxes";
import { Modal, ModalBody, ModalFooter } from "@vds3/modals";
import { ColorTokens } from "@vds-tokens/color";

export const InternetGatewayModal = styled(Modal)`
  height: auto;
  width: 50%;
  min-height: auto;
  outline: none !important;
  > div:first-child {
    overflow: hidden;
  }
  @media only screen and (max-width: 767px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95vw;
    > div:first-child {
      padding: 2px;
    }
  }
`;

export const InternetGatewayModalBody = styled(ModalBody)`
  padding: 0 0px 0;
  outline: none !important;
  height: auto;
`;

export const InternetGatewayModalFooter = styled(ModalFooter)`
  padding: 0 0px 0;
  outline: none !important;
  height: auto;
`;

export const BYORModal = styled(Modal)`
  height: auto;
  width: 50%;
  min-height: auto;
  outline: none !important;
  > div:first-child {
    overflow: hidden;
  }
  @media only screen and (max-width: 767px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95vw;
    > div:first-child {
      padding: 2px;
    }
  }
`;

export const BYORModalBody = styled(ModalBody)`
  padding: 0 0px 0;
  outline: none !important;
  height: auto;
`;

export const BYORModalFooter = styled(ModalFooter)`
  padding: 0 0px 0;
  outline: none !important;
  height: auto;
`;

export const EquipmentCardGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => (props.isMobile ? 1 : props.span ? props.span : 4)},
    minmax(158px, 1fr)
  );
  grid-auto-rows: 1fr;
  grid-gap: 10px;
  padding: 0;
  min-height: ${(props) => (props.isMobile ? "244px" : "204px")};
`;

export const LinksSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LinksSubSection = styled.div`
  column-gap: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 2rem;
`;

export const EquipmentRadioBox = styled(RadioBox)`
  label {
    span {
      height: 100%;
      > div {
        height: 100%;
      }
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  row-gap: 8px;
  * {
    color: ${(props) =>
      props.isDisabled ? ColorTokens.palette.gray85.value : ""};
  }
`;

export const CardContentDetailsBtn = styled.div`
  bottom: 12px;
  position: absolute;
  right: 12px;
`;

export const RecommendedBadge = styled.div`
  width: 100%;
  height: 1rem;
`;

export const CardContent = styled.div`
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardDescription = styled.div`
  margin-bottom: 16px;
  ${({ mwwCart, isByor }) =>
    mwwCart && isByor ? "margin-top: 50px" : "margin-top: 16px;"}
  width: 100%;
`;

export const PriceBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
`;

export const ImageContent = styled.div`
  width: fit-content;
`;

export const ImgCheckmark = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  margin-top: 3px;
`;
