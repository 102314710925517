import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//import { useLocation, useNavigate } from "react-router-dom";
//import IFrameModal from "../../../Modals/IFrameModal";
import OfferCard from "./OfferCard";
import { isTradeInOfferEligible } from "./util";
import { RemoveTradeInModal } from "pages/ShoppingCart/components/Modals/RemoveTradeInModal";
import PDPContext from "../pdpContext";

const TradeInOffer = ({
	tradeInPromoDetails,
	skuDetails,
	editPackagePackageId,
	...props
}) => {
	const { t } = useTranslation(["pdp"]);
	//const location = useLocation();
	//const navigate = useNavigate();
	const tradeInPageData = useSelector((state) => state.TradeInSlice);
	const [showTradeInModal, setShowTradeInModal] = useState(false);
	const [removeTradeInModal, handleRemoveTradeInModal] = useState(false);

	const {
		product,
		selectedPromo: { setSelectedPromo },
		tradeInValueInfo: { tradeInValueInfo, handleTradeInValueInfo },
	} = React.useContext(PDPContext);
	const isAdded = isTradeInOfferEligible(tradeInValueInfo);
	const modalIframeUrl = tradeInPromoDetails?.promoMessages?.length
		? tradeInPromoDetails?.promoMessages[0]?.badgeToolTipUrl
		: tradeInPromoDetails?.promoMessages?.badgeToolTipUrl;

	const goToTradeIn = () => {
		let skuList = [];
		if (product !== null && product) {
			const { deviceDetails } = product;
			if (deviceDetails !== null && deviceDetails) {
				const { skulist } = deviceDetails;
				if (skulist !== null && skulist && skulist.length) {
					skulist.map((sku) => {
						skuList.push(sku?.sku);
					});
				}
			}
		}
		sessionStorage.removeItem("TRADE_INFO_VALUE");
		setSelectedPromo(null);
		// navigate("/shop/trade-in-new", {
		//   state: {
		//     isFromPDP: true,
		//     pdpUrl: location.pathname,
		//     pdpSkuDetails: skuDetails,
		//     skuList
		//   },
		// });
	};

	const openTradeinModal = () => {
		setShowTradeInModal(true);
	};

	return (
		<>
			<OfferCard
				eyebrow={"Trade-in promotion"}
				title={
					tradeInPageData?.tradeInNewCompleted
						? t("device-pdp.moreWaysToSavingOptions.promoPrice", {
								price: "0",
						  })
						: tradeInPromoDetails?.promoMessages?.badgeText
				}
				onDetailsClick={openTradeinModal}
				isActionable={true}
				onAddClick={goToTradeIn}
				onRemoveClick={() => handleRemoveTradeInModal(true)}
				isAdded={!!isAdded}
			/>
			{removeTradeInModal && (
				<RemoveTradeInModal
					opened={removeTradeInModal}
					setOpened={handleRemoveTradeInModal}
					lineItemId={editPackagePackageId}
					deviceId={tradeInValueInfo["tradeInItems"][0]["deviceId"]}
					handleTradeInValueInfo={handleTradeInValueInfo}
				/>
			)}
			{/* {showTradeInModal && (
        <IFrameModal
          opened={showTradeInModal}
          onOpenedChange={setShowTradeInModal}
          iframeUrl={modalIframeUrl}
        />
      )} */}
		</>
	);
};

export default TradeInOffer;
