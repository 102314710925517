import React from "react";
import styled from "styled-components";
import { MagnifyingGlass } from "../SVGIcons";
import "./SearchInput.css";

const IconSpan = styled.span`
  cursor: pointer;
  position: absolute;
  right: ${({isBuss})=>isBuss ? '' : '8px'};
  left: ${({isBuss})=>isBuss ? '13px' : ''};
  top: 8px;
  text-decoration: underline;
`;

const SearchInput = ({ value, onChangeFn, onClickFn, placeholderText,isBuss=false}) => {
  return (
    <div className={`search-input ${isBuss ? 'buss-search' : ''}`}>
      <input
        value={value}
        onChange={onChangeFn}
        placeholder={placeholderText}
      />
      <IconSpan onClick={onClickFn} isBuss = {isBuss}>
        <MagnifyingGlass />
      </IconSpan>
    </div>
  );
};

export default SearchInput;
