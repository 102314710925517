import React, { useEffect, useReducer, useRef, useState } from "react";
import { Button } from "@vds3/buttons";
import VerticalProgressContext from "common/components/VerticalProgress/verticalProgressContext";
import { Body, Micro } from "@vds3/typography";
import { MarginSpacerM, MarginSpacerS } from "common/components/styleTag";
import { Input } from "@vds3/inputs";
import { Grid, Row, Col } from "@vds3/grids";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { SignerState, reducer, actions as Actions } from "./containers/AuthSignerReducer";
import { setCreditPath, setSSNInfo } from "pages/Checkout/ducks/CheckoutSlice";
import { useMediaQuery } from 'react-responsive'

const SocialSecurityNo = () => {
    const {
        isComplete,
        onComplete,
        setModifier,
        isEditMode: { isEditMode, setIsEditMode },
    } = React.useContext(VerticalProgressContext);
    const { t } = useTranslation(["app", "checkout"]);
    const isMobile = useMediaQuery({
        query: "(max-width: 525px)",
    });
    const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
    const pageData = useSelector((state) => state.ProspectWidgets_CheckoutData);
    const sessionCartData =
        globalData?.sessionCartData?.apiResponse?.prospectFlowData;
    const isOrderQuoteFlow = globalData.isOrderQuoteFlow;
    const isDigitalQuote = sessionCartData?.cart?.digitalQuote;
    const isAbandonedCart = sessionCartData?.cart?.abandonedCart;
    const [errors, setErrors] = useState({});
    const [businessInfoState, businessDispatch] = useReducer(reducer,
        Object.keys(pageData?.authSignerInfo).length === 0 ? SignerState : pageData?.authSignerInfo);
    const [ssnLabel, setSSNLabel] = useState(true);
    const dispatch1 = useDispatch();
    const isTaxIdOptional = globalData?.cmp?.isFromGoogleShop;
    const isBlendedCredit = sessionCartData?.enableBlendedCredit;
    const isBizOnlyCredit = sessionCartData?.enableBizOnlyCredit;
    const isCreditChoicePageActive = isTaxIdOptional ? true : isBlendedCredit && isBizOnlyCredit;
    const [ssnErrors, setSSNErrors] = useState({});

    useEffect(() => {
        if (!isEditMode && pageData?.SSNInfo) {
            businessDispatch(Actions.setCorporation({ ...businessInfoState?.corporation, ["SSN"]: pageData?.SSNInfo }));
        }else{
            //SiteAnalytics.dynamicComponentRendered({ order: 3, sectiontName: "Business Verification",subSection:"Social Security Number",isEditMode:false });
        }
    }, [isEditMode])
    const hasErrors = () => {
        let error = false;
        for (const key of Object.keys(errors)) {
            if (errors[key] && errors[key]?.length > 0) {
                error = true;
            }
        }
        if (
            (isTaxIdOptional && !businessInfoState?.corporation?.SSN)
            || (isTaxIdOptional && businessInfoState?.errorTexts?.corporation?.SSN)
        ) {
            error = true;
        }
        return error;
    }
    const formatSSN = () => {
        if (businessInfoState?.corporation?.SSN) {

            let digits = pageData?.SSNInfo ?? businessInfoState?.corporation?.SSN;
            let firstfive = digits.slice(0, 6).replace(/\d/g, '#')
            let hashdigit = digits.slice(6)
            let formated = firstfive + hashdigit
            return formated;

        }
    }
    useEffect(() => {
        const element = document.getElementById("ssn-info");
        element?.scrollIntoView({ behavior: "smooth", block: "start" });
    },[]);

    const isValidCorporationInfoField = (key, e) => {
        let val = "";
        let errors = "";
        const { errorTexts } = businessInfoState;
        const { corporation } = errorTexts;

        // ssn
        if (key === "SSN") {
            val = e?.target?.value;
            val = e?.target?.value
                .replace(/[^0-9]/g, "")
                .replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
            errors =
            isTaxIdOptional &&
                    (!val || !val?.trim())
                    ? t("checkout.businessInfo-section.errorMsgs.validSSN", { ns: "checkout" })
                    : "";
            errors +=
                val && val?.length != 11 && val.length >=1
                    ? t("checkout.businessInfo-section.errorMsgs.validSSN", { ns: "checkout" })
                    : "";
        }
        businessDispatch(Actions.setCorporation({ ...businessInfoState?.corporation, [key]: val }));
        businessDispatch(
            Actions.setErrorMessages({
                ...errorTexts,
                corporation: { ...corporation, [key]: errors },
            })
        );
        setSSNErrors({ ...ssnErrors, [key]: errors });
    }
    const getDefaultContent = () => {
        return (
            <>
                <div>
                    {isMobile ?
                        <Body size="large"><StyledSpan
                        dangerouslySetInnerHTML={{
                            __html: t("checkout.businessInfo-section.messages.ssn-info-mobile", {
                                ns: "checkout",
                            }),
                        }}
                    /></Body> :
                        <Body size="large"><StyledSpan
                        dangerouslySetInnerHTML={{
                            __html: t("checkout.businessInfo-section.messages.ssn-info-desktop", {
                                ns: "checkout",
                            }),
                        }}
                    /></Body>
                    }
                    <MarginSpacerM />
                    {isMobile ?
                        <Body size="large"><StyledSpan
                        dangerouslySetInnerHTML={{
                            __html: t("checkout.businessInfo-section.messages.ssn-info1-mobile", {
                                ns: "checkout",
                            }),
                        }}
                    /></Body> :
                        <Body size="large"><StyledSpan
                        dangerouslySetInnerHTML={{
                            __html: t("checkout.businessInfo-section.messages.ssn-info1-desktop", {
                                ns: "checkout",
                            }),
                        }}
                    /></Body>
                    }
                    <MarginSpacerM />
                    <Grid
                        colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
                        colGutter={false}
                        rowGutter="5"
                    >
                        <Row>
                            {
                                (isCreditChoicePageActive &&
                                    pageData?.creditPath === "BLENDED") ||
                                    !isCreditChoicePageActive ?
                                    (
                                        <Col colSizes={{ mobile: 4, tablet: 6, desktop: 8 }}>
                                            <div
                                                className="form-col show-label"
                                                style={{ marginRight: "1rem", position: "relative" }}
                                            >
                                                <Input
                                                    label={`${t("checkout.businessInfo-section.labelTexts.ssn", {
                                                        ns: "checkout",
                                                    })}${!isCreditChoicePageActive ? "" : "*"}`}
                                                    name="SSN"
                                                    width={"100%"}
                                                    required={isCreditChoicePageActive}
                                                    maxLength={9}
                                                    value={businessInfoState?.corporation?.SSN}
                                                    error={businessInfoState?.errorTexts?.corporation?.SSN?.length > 0}
                                                    errorText={
                                                        businessInfoState?.errorTexts?.corporation?.SSN ||
                                                        t("checkout.businessInfo-section.errorMsgs.ssnReq", { ns: "checkout" })
                                                    }
                                                    onChange={(e) => isValidCorporationInfoField("SSN", e)}
                                                    tooltipContent={t("checkout.businessInfo-section.labelTexts.SSNsoftCreditEnquiry", {
                                                        ns: "checkout",
                                                    })}
                                                    type={"password"}
                                                    inlineActionButtonLabel={ssnLabel ? "Show" : "Hide"}
                                                    onClickInlineAction={() =>
                                                        ssnLabel ? setSSNLabel(false) : setSSNLabel(true)
                                                    }
                                                    onBlur={(e) => {
                                                        isValidCorporationInfoField("SSN", e)
                                                    }}

                                                    {...(!isCreditChoicePageActive
                                                        ? { required: false }
                                                        : {})}
                                                />
                                                <div className="helper-text">
                                                    <Micro color="#6F7171" size="small">
                                                        {t("checkout.businessInfo-section.helperTexts.ssn", {
                                                            ns: "checkout",
                                                        })}
                                                    </Micro>
                                                </div>
                                            </div>
                                        </Col>
                                    ) : (
                                        <></>
                                    )}
                        </Row>
                    </Grid>
                    <MarginSpacerM />
                </div>
                {!isEditMode &&
                <>
                    <div style={{ display: "flex", justifyContent:isMobile ?"center" : "flex-start"}}>
                        <Button width={isMobile? "90%" : "22%"} size="large" use="primary" onClick={() => {
                            // add here the redux obj
                            if(businessInfoState?.errorTexts?.corporation?.SSN?.length == 0 || businessInfoState?.errorTexts?.corporation?.SSN == undefined){
                            dispatch1(setSSNInfo(businessInfoState?.corporation?.SSN))
                            dispatch1(setCreditPath(businessInfoState?.corporation?.SSN ? "BLENDED" : "BizOnlyCredit"))
                            setModifier(null);
                            onComplete();}
                        }}
                            disabled={hasErrors()}
                            data-track="Business Verification:Social Security Number:Continue"
                        >
                            {"Continue"}
                        </Button>
                    </div>
                    <MarginSpacerS/>
                    </>}
            </>
        );
    };

    const getCompletedContent = () => {
        return (
            ((isOrderQuoteFlow || isDigitalQuote || isAbandonedCart)&& sessionCartData?.redirectPageTo && sessionCartData?.redirectPageTo === "checkout") ?
            <div>
                <Body size="large">{'#########'}</Body>
                {/* <Body size="large">{businessInfoState?.corporation?.SSN}</Body> */}
                {<MarginSpacerM />}
            </div>
           : <div>
                <Body size="large">{formatSSN()}</Body>
                {/* <Body size="large">{businessInfoState?.corporation?.SSN}</Body> */}
                {businessInfoState?.corporation?.SSN && <MarginSpacerM />}
            </div>
        );
    };


    const getEditModeContent = () => {
        return (
            <>
                <>{getDefaultContent()}</>
                <div  style={{ display: "flex", justifyContent:isMobile ?"center" : "flex-start"}}>
                    <Button
                    width={isMobile? "90%" : "15%"}
                        size="large"
                        use="primary"
                        onClick={() => {
                            if(businessInfoState?.errorTexts?.corporation?.SSN?.length == 0 || businessInfoState?.errorTexts?.corporation?.SSN == undefined){
                            dispatch1(setSSNInfo(businessInfoState?.corporation?.SSN));
                            dispatch1(setCreditPath(businessInfoState?.corporation?.SSN ? "BLENDED" : "BizOnlyCredit"))
                            setModifier(null);
                            onComplete();
                            setIsEditMode(false)
                            }
                        }}
                        disabled={hasErrors()}
                    >
                        {"Save"}
                    </Button>
                </div>
                <MarginSpacerS />
            </>
        );
    };

    if (isComplete) {
        if (isEditMode) {
            return getEditModeContent();
        } else {
            return getCompletedContent();
        }
    } else {
        return getDefaultContent();
    }
};

const StyledSpan = styled.span`
  > a {
    color: black;
  }
`;
const ColPad = styled(Col)`
  margin-top: 2rem !important;
`;

export default SocialSecurityNo;
