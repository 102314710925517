import { combineReducers } from "redux";
import { reducer as ProspectWidgets_GlobalData } from "../app/ducks/GlobalSlice";
import { reducer as ProspectWidgets_LQPage } from "../pages/Qualification/ducks/QualificationSlice";
import { reducer as ProspectWidgets_4GPlansData } from "../pages/Landing4G-V2/ducks/4GSlice";
import { reducer as ProspectWidgets_5GPlansData } from "../pages/Landing5G-V2/ducks/5GSlice";
import { reducer as ProspectWidgets_ShoppingCartData } from "../pages/ShoppingCart/ducks/ShoppingCartSlice";
import { reducer as ProspectWidgets_CheckoutData } from "../pages/Checkout/ducks/CheckoutSlice";
import { reducer as ProspectWidgets_ReviewData } from "../pages/Checkout/ReviewOrder/ducks/ReviewSlice";
import { reducer as ProspectWidgets_PaymentData } from "../pages/Checkout/Payment/ducks/PaymentSlice";
import { reducer as ProspectWidgets_CreditData } from "../pages/Checkout/CreditResult/CreditResultsNew/CreditSlice";
import { reducer as ProspectWidgets_MobilityData } from "../pages/Mobility/ducks/MobilitySlice";
import { reducer as ProspectWidgets_WirelessPlansData } from "../pages/Mobility/components/PlansNew/ducks/PlansSlice";
import { reducer as ProspectWidgets_BussProductsData } from "../pages/BussProductsPage/ducks/BussProductSlice";
import { reducer as LowerFunnelSlice} from "../pages/CheckoutNew/ducks/LowerFunnelSlice";

const allReducers = combineReducers({
  ProspectWidgets_GlobalData,
  ProspectWidgets_LQPage,
  ProspectWidgets_4GPlansData,
  ProspectWidgets_5GPlansData,
  ProspectWidgets_ShoppingCartData,
  ProspectWidgets_CheckoutData,
  ProspectWidgets_ReviewData,
  ProspectWidgets_PaymentData,
  ProspectWidgets_CreditData,
  ProspectWidgets_MobilityData,
	ProspectWidgets_WirelessPlansData,
	ProspectWidgets_BussProductsData,
  LowerFunnelSlice
});

const rootReducer = (state, action) => {
  if (action.type === "CLEAR_GLOBAL_STORE") {
    state = undefined;
  }
  return allReducers(state, action);
};

export default rootReducer;
