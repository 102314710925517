import React, { useState, useMemo, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import {
	Accordion,
	AccordionItem,
	AccordionHeader,
	AccordionTitle,
	AccordionDetail,
} from "@vds3/accordions";
import { ButtonGroup } from "@vds3/buttons";
import { Checkbox } from "@vds3/checkboxes";
import { Grid, Row, Col } from "@vds3/grids";
import { Line } from "@vds3/lines";
import { Modal, ModalTitle, ModalBody } from "@vds3/modals";
import { DropdownSelect } from "@vds3/selects";
import { TrailingTooltip } from "@vds3/tooltips";
import { Title } from "@vds3/typography";
import {
	MarginSpacerM,
	MarginSpacerL,
	MarginSpacerXL,
} from "common/components/styleTag";
import "./GridwallFilterBar.css";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

const getCategoryURI = (category) => `${category === "all" ? "" : category}/`;

const CustomAccordionItem = styled(AccordionItem)``;
const GridFilter = styled.div`
	position: sticky;
	top: ${({ headerHeight }) => (headerHeight > 0 ? 60 + headerHeight : 60)}px;
	overflow: auto;
	padding-left:1rem;
`;
const GridwallFilterBar = (props) => {
	const [filterList, setFilterList] = useState([]);
	const [selectedFilter, setSelectedFilter] = useState({});
	const [mobileAccordionStatus, handleMobileAccordionStatus] = useState([
		"Compatible with",
	]);
	const [brandSelectedOnTabClick, handleTabSelected] = useState(false);
	const { aemContent, filterData, filters } = props;
	const isDesktop = useMediaQuery({
		query: "(min-width: 768px)",
	});

	const filterAll = props.filterAccordionsAll;
	const [filterAccAll, setFilterAccAll] = useState(filterAll);

	const mobileHotspots =
		String(window.location.pathname).includes("mobile-hotspots") ?? false;
	const smartPhones =
		String(window.location.pathname).includes("smartphones") ?? false;
	const pageData = useSelector((state) => state.ProspectWidgets_MobilityData);
	let isBasicPhones = location?.pathname?.includes("basic-phones");
	let isSmartphones5g = location?.pathname?.includes("/5g/smartphones");
	let isPreOwned = location?.pathname?.includes("certified-pre-owned");

	useEffect(() => {
		setSelectedFilter(props.filtersApplied);
	}, [props.filtersApplied]);

	useEffect(() => {
		const resultData = [];
		typeof props?.aemContent?.filters === "object" &&
			Object.entries(props?.aemContent?.filters)
				.filter(([_key, value]) => value?.enable)
				.filter(
					([_key, value]) =>
						!value?.hideOnPages?.length ||
						!(
							value.hideOnPages.includes(props.category) ||
							value.hideOnPages.includes(props?.brandSelectedOnTab?.split("/")?.[0])
						)
				)
				.sort((a, b) => a[1].order - b[1].order)
				.forEach(([key, value]) => {
					resultData.push({
						name: value?.label,
						value: key,
					});
				});
		setFilterList(resultData);
	}, [props.aemContent, props.category, props.brandSelectedOnTab]);

	const isFilterSelected = (key, value) => {
		if (value?.type === props.compatibleType) {
			return value?.value === props.selectedPhoneId ? true : false;
		}
		return Array.isArray(filters[value?.type]) && filters[value?.type]?.length > 0
			? !!filters[value?.type].find((i) => i.key === key)
			: false;
	};

	const getCheckboxText = (key, value) =>
		`${value?.name ?? key} ${value?.docCount > 0 ? `(${value?.docCount})` : ""}`;

	const getFilterCheckboxes = (filterListName, filterListData) => {
		const cbData = [];
		if (filterListData instanceof Map) {
			for (const [key, value] of filterListData) {
				if (!(key === "2 Year Contract Pricing" && (smartPhones || isPreOwned))) {
					const tooltips = props?.aemContent?.filters?.[filterListName]?.tooltips;
					cbData.push(
						<CBWrapper>
							<Checkbox
								name={value?.name ?? key}
								value={key}
								width="auto"
								label=""
								error={false}
								errorText=""
								selected={isFilterSelected(key, value)}
								data-track={value?.name ?? key}
								disabled={
									// (filterListName === "brandList" &&
									//   props?.brandFilter &&
									//   !String(props?.brandFilter)
									//     .toLowerCase()
									//     .includes("all")) ||
									filterListName === "simTypeList" && props.simFilter
								}
								onChange={(e) => {
									if (filterListName === props.compatibleType)
										props.setSelectedPhoneId(value?.value ? value.value : "");
									else onFilterSelect(e.target.value, filterListName);
								}}
							>
								{tooltips?.[key] ? (
									<TrailingTooltip
										typographySize="large"
										typographyPrimitive="p"
										typographyType="body"
										typographyColor="#000000"
										tooltipSize="medium"
										tooltipTitle={tooltips?.[key]?.title}
										tooltipContent={tooltips?.[key]?.content}
									>
										{getCheckboxText(key, value)}
									</TrailingTooltip>
								) : (
									<>{getCheckboxText(key, value)}</>
								)}
							</Checkbox>
						</CBWrapper>
					);
				}
			}
		}
		return cbData;
	};

	const onFilterSelect = (key, filterListName) => {
		if (
			filterListName == "brandList" &&
			props.setBrandFilter !== null &&
			props.setBrandFilter &&
			typeof props.setBrandFilter == "function"
		) {
			props.setBrandFilter(key);
		}
		const selected = { ...filters };
		const item = filterData[filterListName].get(key);
		let list = Array.isArray(selected[filterListName])
			? Object.assign([], selected[filterListName])
			: [];
		if (list.find((i) => i.key === key)) {
			if (filterListName !== "paymentList") {
				list = list.filter((i) => i.key !== key);
			}
		} else {
			if (filterListName === "paymentList") {
				list = [];
			}
			list.push({ ...item, checked: true });
		}
		let isBrandorColorSelected = false;
		if (filterListName === "priceList") {
			Object.keys(selected).forEach((data) => {
				if (
					(data === "brandList" || data === "colorList") &&
					selected[data] !== null &&
					selected[data] &&
					selected[data].length > 0
				) {
					isBrandorColorSelected = true;
				}
			});
		}
		if (isBrandorColorSelected) {
			selected["priceList"] = [];
			list = [];
			list.push({ ...item, checked: true });
		}
		selected[filterListName] = list;
		props.setFilters(selected);
		setSelectedFilter(selected);
		setFilterAccAll(filterAll);
	};

	useEffect(() => {
		let count = 0;
		if (
			filters?.["brandList"] !== null &&
			filters?.["brandList"] &&
			filters?.["brandList"].length > 0
		) {
			filters["brandList"].map((data) => {
				if (
					String(props.brandSelectedOnTab).includes(String(data.key).toLowerCase())
				) {
					count++;
					handleTabSelected(true);
					return;
				}
			});
		}
		if (count === 0) {
			handleTabSelected(false);
		}
	}, [props.brandSelectedOnTab]);

	let updatedSelectedFilter = useMemo(() => {
		if (selectedFilter) {
			const filterNew = { ...selectedFilter };
			if (filterNew && filterNew["brandList"]) {
				if (filterNew["brandList"]?.length > 1) {
					props.setBrandFilter("All");
				} else if (filterNew["brandList"]?.length === 1) {
					const brand = filterNew["brandList"]?.[0]?.key;
					props.setBrandFilter(brand || "All");
				} else {
					props.setBrandFilter("All");
				}
			}
			delete filterNew["paymentList"];
			if (location?.pathname?.includes("certified-pre-owned"))
				delete filterNew["sorSkuTypeList"];
			return Object.values(filterNew).map((item) => item);
		}
		return [];
	}, [selectedFilter]);

	let isChecked = useMemo(() => {
		const sampleFilter = {
			checked: true,
			docCount: 0,
			imageUrl: null,
			key: "",
			name: null,
			type: "brandList",
		};
		if (updatedSelectedFilter) {
			const filteredValue = updatedSelectedFilter?.filter(
				(value) => value?.length > 0
			);
			return filteredValue.length > 0
				? filteredValue
				: brandSelectedOnTabClick
				? [
						{
							...sampleFilter,
							key:
								String(props.brandSelectedOnTab).charAt(0).toUpperCase() +
								String(props.brandSelectedOnTab).slice(1),
						},
				  ]
				: [];
		}
		return [];
	}, [selectedFilter, updatedSelectedFilter, brandSelectedOnTabClick]);

	const getButtonValues = () => {
		return isMobile
			? [
					{
						children: aemContent?.labels?.viewResults,
						size: "large",
						use: "primary",
						width: "50%",
						onClick: () => {
							props.setViewResults(true);
						},
					},
					{
						children: aemContent?.labels?.clearAll,
						size: "large",
						use: "secondary",
						width: "50%",
						onClick: () => {
							props?.onClearFilter();
						},
					},
			  ]
			: [
					{
						children: aemContent?.labels?.clearAll,
						size: "large",
						use: "secondary",
						width: "50%",
						onClick: () => {
							props?.onClearFilter();
						},
					},
			  ];
	};

	return (
		<>
			{isDesktop && (
				<GridFilter headerHeight={props?.headerHeight}>
					<Grid rowGutter="1rem" colGutter={false}>
						<RowNoPad>
							<Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
								<Grid rowGutter="1rem" colGutter={false}>
									<FiltersWrap isChecked={isChecked?.length > 0}>
										<FilterTitle>
											<Title size="medium" bold>
												{/* {aemContent?.labels?.filters} */}
												Sort and filter
											</Title>
										</FilterTitle>
										<MarginSpacerL/>
										{props.sortByDisplay()}
										<MarginSpacerL/>
										<div className="scroll-container-filter">
											{filterList.map((accordion) => {
												let isOpenAccordain =
													selectedFilter?.[accordion?.value] === undefined
														? false
														: accordion?.value ===
														  selectedFilter?.[accordion?.value][0]?.type;
												if (
													filterData !== null &&
													filterData &&
													typeof filterData == "object" &&
													Object.keys(filterData).length &&
													filterData[accordion.value] !== null &&
													filterData[accordion.value] &&
													filterData[accordion.value].size > 0
												) {
													return (
														<div
															key={accordion.name}
															style={{
																maxWidth: `auto`,
															}}
														>
															<Accordion>
																<CustomAccordionItem
																	data-track={accordion.name}
																	opened={
																		String(accordion["value"]).includes("compatibleWithList") ||
																		(brandSelectedOnTabClick &&
																			String(accordion.value) === "brandList") ||
																		(pageData?.categoryOpen &&
																			String(accordion.value) === "categoryList")
																			? true
																			: false
																	}
																>
																	<AccordionHeader triggerType={"icon"}>
																		<AccordionTitle>Category Type</AccordionTitle>
																	</AccordionHeader>
																	<AccordionDetail>
																		<CheckboxGroup>
																			{getFilterCheckboxes(
																				accordion.value,
																				filterData[accordion.value]
																			)}
																		</CheckboxGroup>
																	</AccordionDetail>
																</CustomAccordionItem>
															</Accordion>
														</div>
													);
												}
											})}
										</div>
									</FiltersWrap>

									<CloseClearWrap isChecked={isChecked?.length > 0}>
										<Row>
											<Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
												<ButtonGroup data={getButtonValues()} />
											</Col>
										</Row>
									</CloseClearWrap>
								</Grid>
							</Col>
						</RowNoPad>
					</Grid>
				</GridFilter>
			)}
			{/* // END DESKTOP LAYOUT
      // BEGIN MOBILE LAYOUT */}
			{props.showFilters && (
				<Modal opened={props.showFilters} onOpenedChange={props.toggleFilters}>
					<ModalTitle>{aemContent?.labels?.sort}</ModalTitle>
					<ModalBody>
						<Line type="heavy" />
						<MarginSpacerM />
						<DropdownSelect
							label={aemContent?.labels?.sortBy || "Sort by:"}
							onChange={(e) => {
								props.setSortBy(e.target.value);
								props.toggleFilters(false);
							}}
							width={"100%"}
							inlineLabel={true}
						>
							{props.sortOptions.map((item, index) => (
								<option value={item.value} key={index}>
									{item.name}
								</option>
							))}
						</DropdownSelect>
						<MarginSpacerM />
						<FilterContainer>
							{filterList.map(
								(accordion) =>
									!!accordion.show && (
										<Accordion
											key={accordion.name}
											className="filter-accordion"
											topLineType="light"
										>
											<AccordionItem
												className="filter-accordion-items"
												data-track={accordion.name}
											>
												<AccordionHeader triggerType={"icon"}>
													<AccordionTitle>{accordion.name}</AccordionTitle>
												</AccordionHeader>
												<AccordionDetail>
													<div className="checkbox-group">
														{getFilterCheckboxes(
															accordion.value,
															filterData[accordion.value]
														)}
													</div>
												</AccordionDetail>
											</AccordionItem>
										</Accordion>
									)
							)}
						</FilterContainer>
						<MarginSpacerL />
					</ModalBody>
				</Modal>
			)}

			{props.showFiltersTypes && (
				<Modal
					opened={props.showFiltersTypes}
					onOpenedChange={props.toggleFiltersTypes}
				>
					<ModalTitle>Filter</ModalTitle>
					<ModalBody>
						<MobileFilterModalWrap>
							{filterList.map((accordion) => (
								<div key={accordion.name}>
									<Accordion>
										<CustomAccordionItem
											data-track={accordion.name}
											opened={
												mobileAccordionStatus.includes(accordion.name) ||
												String(accordion["value"]).includes("compatibleWithList") ||
												(brandSelectedOnTabClick && String(accordion.value) === "brandList")
													? true
													: false
											}
										>
											<AccordionHeader
												triggerType={"icon"}
												onClick={(val) => {
													handleMobileAccordionStatus((data) => {
														if (mobileAccordionStatus.includes(accordion.name)) {
															const resultData = data.filter((ac) => ac !== accordion.name);
															return resultData;
														} else return [...data, accordion.name];
													});
												}}
												opened={
													brandSelectedOnTabClick && String(accordion.value) === "brandList"
														? true
														: false
												}
											>
												<AccordionTitle>{accordion.name}</AccordionTitle>
											</AccordionHeader>
											<AccordionDetail>
												<div className="checkbox-group">
													{getFilterCheckboxes(accordion.value, filterData[accordion.value])}
												</div>
											</AccordionDetail>
										</CustomAccordionItem>
									</Accordion>
								</div>
							))}
							<MarginSpacerXL />
						</MobileFilterModalWrap>
					</ModalBody>
					<MobileFiltersCloseBtnWrap isChecked={isChecked?.length > 0}>
						<Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
							<ButtonGroup
								data={[
									{
										children: aemContent?.labels?.viewResults,
										size: "small",
										use: "primary",
										width: "50%",
										onClick: () => {
											props.setViewResults(true);
											props?.toggleFiltersTypes(false);
										},
									},
									{
										children: aemContent?.labels?.clearAll,
										size: "small",
										use: "secondary",
										width: "50%",
										onClick: () => {
											props?.onClearFilter();
										},
									},
								]}
							/>
						</Col>
					</MobileFiltersCloseBtnWrap>
				</Modal>
			)}
		</>
	);
};

const RowNoPad = styled(Row)`
	@media only screen and (max-width: 545px) {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
`;

const FiltersWrap = styled.div`
	display: block !important;
	height: 90vh;
	overflow: auto;
	padding-bottom: 2rem;
	padding-left: 2px !important;
	.scroll-container-filter {
		width: 92%;
	}

	&::-webkit-scrollbar {
		width: 4px;
		height: 20px;
		background: #d8dada;
		padding-left: 1rem;
		border-radius: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #6f7171;
		border-radius: 10px;
	}

	.childrenWrapper {
		padding: 2rem 0px 2rem 0px;

		p {
			font-weight: normal;
		}
	}
	.toggleIconWrapper {
		top: 40% !important;
		right: 5px;
	}

	.ngtbh {
		&:hover {
			.toggleIconWrapper {
				top: 40% !important;
			}
		}
	}

	& > div {
		& > div {
			border-top: 0.2px solid #d8dada !important;
			margin-bottom: 0px !important;
		}
	}

	div {
		background-color: transparent !important;

		button {
			span {
				background-color: transparent !important;
			}
		}
	}
	div[role="region"]:has(> .rah-static) {
		padding-bottom: 0 !important;
	}
`;

const CloseClearWrap = styled.div`
	width: 100%;
	display: block;
	padding-left: 10px;
	display: ${({ isChecked }) => (isChecked ? "block" : "none")};
	background: ${({ isChecked }) => (isChecked ? "#f6f6f6" : "none")};
	bottom: 10px;
	& > div:first-child {
		padding-top: 20px;
	}
	&.close-clear-wrap {
		border-top: none;
		padding-top: 0px;
	}

	& div {
		width: 100%;
		& div {
			width: 100%;
			max-width: 100%;
			flex-basis: auto;
			display: flex;
			padding-left: 0px !important;
			padding-right: 0px !important;
		}
	}

	button {
		width: 90%;

		&:hover {
			box-shadow: none !important;
		}

		span {
			display: flex;
			align-items: center;
			padding: 0.5rem 0.7rem !important;
		}
	}
`;

const FilterContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 10px;

	& > div {
		flex-grow: 1;
		min-width: 155px;
	}

	@media (max-width: 767px) {
		display: block;

		& > div {
			width: 100%;
			max-width: unset;
		}
	}
`;
const FilterTitle = styled.div``;
const CheckboxGroup = styled.div`
	label {
		margin-bottom: 25px;
	}
	span[data-testid="test-child-wrapper"]::first-letter {
		text-transform: uppercase;
	}
`;
const MobileFilterModalWrap = styled.div`
	label {
		margin-bottom: 20px;
	}
`;

const MobileFiltersCloseBtnWrap = styled.div`
	display: ${({ isChecked }) => (isChecked ? "block" : "none")};
	background: ${({ isChecked }) => (isChecked ? "#f6f6f6" : "none")};
	margin-top: 30px;
	position: fixed;
	left: 0;
	right: 0;
	width: 100%;
	bottom: 0;
	background-color: white;
	padding: 1rem 0.5rem;

	& > div {
		& > div {
			display: flex;
			gap: 10px;
		}
	}

	button {
		width: 100%;
	}
`;

const CBWrapper = styled.div`
	span::first-letter {
		text-transform: none !important;
	}
`;

export default GridwallFilterBar;
