import React from "react";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { TextLink } from "@vds3/buttons";
import CartPkgContext from "../CartPackage/cartPkgContext";
// import { addToCart } from "pages/Mobility/ducks/MobilitySlice";
const CartEditLinks = ({ isEditable, onEditClick, onRemoveClick, cartData, quantity }) => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [oneTalkFlowPresent,handleOneTalkFlowPresent] = React.useState(false)
  const { pack, isUpperFunnel, is5GMultiPlan, showRightSection, isDSP, isDigitalQuote,isOneTalkFlow,oneTalkFlowAdded } = React.useContext(CartPkgContext);
  const isLtePlusWithDQ = pack?.plan?.planType == "UNCAPPED" && isDigitalQuote;
  const isOneTalkWithDQ = pack?.packageType == "ONETALK" && isDigitalQuote;

  React.useEffect(()=>{
  if(pack['packageType']==="ONETALK" && (isOneTalkFlow||oneTalkFlowAdded)) handleOneTalkFlowPresent(true)
  else if(!oneTalkFlowPresent) handleOneTalkFlowPresent(false)
  },[pack,isOneTalkFlow])
  
  const onNewDeviceSelect = (pack) => {
    const packageDetails = cartData?.cart?.packages;
    const deviceId = pack?.packageId;
      const payload = {
        cartMeta: {
          zipCode: '',
          shoppingPath: "prospect",
        },
        lines: [],
      };
        Object.keys(packageDetails).filter((device) => {
          if(device === deviceId){
        let line = {
          packageId: device,
          packageType: "REGULAR",
          operation: "DELETE",
          quantity: quantity
        };
        payload.lines.push(line);
        i++;
    }});
      if (payload.lines.length) {
       // dispatch(addToCart(payload));
      }

  }

  const oosSelectDeviceClick = (pack) => {
    // if(pack?.outOfStock){
    //   onNewDeviceSelect(pack);
    //   navigate("/shop/products/devices/smartphones", {
    //   })
    // }  
     
  };
  return (
    <EditLinkContainer>
      {pack?.outOfStock && 
       <TextLink onClick={() => oosSelectDeviceClick(pack)}>{"Select device"}</TextLink>
      }
       {((!!isUpperFunnel && !!onEditClick && !is5GMultiPlan && !isDSP && !pack?.outOfStock && !isLtePlusWithDQ && !isOneTalkWithDQ && !oneTalkFlowPresent) || pack.buss) && (
        <TextLink onClick={() => onEditClick(pack)}>{"Edit"}</TextLink>
      )}
      {!!onRemoveClick && (!pack?.emptyByodFromDQ || (pack?.emptyByodFromDQ && !showRightSection)) && (
        <TextLink onClick={onRemoveClick}>{"Remove"}</TextLink>
      )}
    </EditLinkContainer>
  );
};

const EditLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1.5rem;
  // @media (max-width: 545px) {
  //   gap: 0.5rem;
  // }
`;

export default CartEditLinks;
