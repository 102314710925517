import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ButtonGroup, TextLink } from "@vds3/buttons";
import { Modal, ModalTitle, ModalBody } from "@vds3/modals";
import { Body, Title } from "@vds3/typography";
import { MarginSpacerS, MarginSpacerM } from "common/components/styleTag";
import { Input } from "@vds3/inputs";
import { TrailingTooltip } from "@vds3/tooltips";
import useDeviceCompatibilityCheck from "hooks/useDeviceCompatibilityCheck";
import { CircleCheck } from "common/components/SVGIcons/";
import EquipmentSelection from "./EquipmentSelection";

const CompatModal = styled(Modal)`
  width: 600px;
`;
const CompatModalBody = styled(ModalBody)`
  padding-left: 0;
`;
const IconWrapper = styled.span`
  margin-right: 0.25rem;
`;
const StyledInput = styled(Input)`
  a {
    margin-top: -10px;
  }
`;

const CheckCompatibility = ({
  planInfo,
  routers,
  imei,
  setImei,
  sim,
  setSim,
  onRouterSelect,
  setUseOwnReceiver,
  onContinue,
  ...props
}) => {
  const { t } = useTranslation(["4G-5G"]);
  // const [imei, setImei] = useState("");
  // const [sim, setSim] = useState("");
  const [isImeiCompatible, setImeiCompatible] = useState(false);
  const [isSimCompatible, setSimCompatible] = useState(false);
  const [isIMEIError, setIMEIError] = useState(false);
  const [isSimError, setSimError] = useState(false);
  const [selectedRouter, setSelectedRouter] = useState("");
  const [showModal, setShowModal] = useState(false);
  const {
    callValidateDeviceId,
    validateDeviceIdResp,
    callValidateSimId,
    validateSimIdResp,
  } = useDeviceCompatibilityCheck();

  const isContinueDisabled =
    (!sim || !isSimCompatible || isSimError) && !selectedRouter;

  useEffect(() => {
    if (validateDeviceIdResp && Object.keys(validateDeviceIdResp)?.length) {
      if (validateDeviceIdResp?.serviceStatus?.success) {
        setImeiCompatible(true);
        setIMEIError(false);
      } else {
        setImeiCompatible(false);
        setIMEIError(true);
      }
    }
  }, [validateDeviceIdResp]);

  useEffect(() => {
    if (validateSimIdResp && Object.keys(validateSimIdResp)?.length) {
      if (validateSimIdResp?.serviceStatus?.success) {
        setSimCompatible(true);
        setSimError(false);
      } else {
        setSimCompatible(false);
        setSimError(true);
      }
    }
  }, [validateSimIdResp]);

  const setImeiValue = (value) => {
    if (value) {
      value = value.trim();
    }
    setImeiCompatible(false);
    setIMEIError(false);
    setImei(value);
  };

  const clearImei = () => {
    setImeiCompatible(false);
    setIMEIError(false);
    setImei(" ");
  };

  const validateImei = () => {
    const val = imei.trim();
    if (val) {
      callValidateDeviceId(val);
    }
  };

  const setSimValue = (value) => {
    if (value) {
      value = value?.trim();
    }
    setSimCompatible(false);
    setSimError(false);
    setSim(value);
  };

  const clearSim = () => {
    setSimCompatible(false);
    setSimError(false);
    setSim(" ");
  };

  const validateSim = () => {
    const val = sim.trim();
    if (val) {
      callValidateSimId(imei, sim);
    }
  };

  const clearState = () => {
    clearImei();
    clearSim();
    setSelectedRouter("");
  };

  const setModalState = (modalState) => {
    clearState();
    setShowModal(!!modalState);
  };

  const onContinueClick = () => {
    if (!selectedRouter) {
      onContinue("ownReceiver");
    } else {
      onContinue(selectedRouter);
    }
  };

  return (
    <>
      <Title>Have your own equipment?</Title>
      <MarginSpacerM />
      <TextLink type="standalone" onClick={() => setShowModal(true)}>
        Check your device compatibility.
      </TextLink>
      {showModal && (
        <CompatModal opened={showModal} onOpenedChange={setModalState} disableOutsideClick disableFocusLock= {true}>
          <ModalTitle>
            {t("4G-5G.fiveG.router-content.byorModal.heading")}
          </ModalTitle>
          <CompatModalBody>
            <Body size="large">
              {t("4G-5G.fiveG.router-content.byorModal.enterImeiInfo")}
            </Body>
            <MarginSpacerM />
            <StyledInput
              type={
                isImeiCompatible || !imei?.trim()?.length
                  ? "text"
                  : "inlineAction"
              }
              label={t("4G-5G.fiveG.router-content.byorModal.imeiLabel")}
              value={imei}
              onChange={(e) => {
                setImeiCompatible(false);
                setImeiValue(e.target.value);
              }}
              // onBlur={() => callValidateDeviceId(imei)}
              inlineActionButtonLabel={
                isImeiCompatible ? undefined : isIMEIError ? "Clear" : "Apply"
              }
              onClickInlineAction={
                isImeiCompatible
                  ? undefined
                  : isIMEIError
                  ? clearImei
                  : validateImei
              }
              success={isImeiCompatible}
              successText={t(
                "4G-5G.fiveG.router-content.byorModal.imeiOkStatus"
              )}
              error={isIMEIError}
              errorText={t(
                "4G-5G.fiveG.router-content.byorModal.imeiNotOkStatus"
              )}
            />
            <MarginSpacerM />
            {isImeiCompatible && (
              <StyledInput
                type={
                  isSimCompatible || !sim?.trim()?.length
                    ? "text"
                    : "inlineAction"
                }
                label={t("4G-5G.fiveG.router-content.byorModal.iccidLabel")}
                value={sim}
                onChange={(e) => {
                  setSimCompatible(false);
                  setSimValue(e.target.value);
                }}
                // onBlur={() => callValidateSimId(imei, sim)}
                inlineActionButtonLabel={
                  isSimCompatible ? undefined : isSimError ? "Clear" : "Apply"
                }
                onClickInlineAction={
                  isSimCompatible
                    ? undefined
                    : isSimError
                    ? clearSim
                    : validateSim
                }
                success={isSimCompatible}
                successText={t(
                  "4G-5G.fiveG.router-content.byorModal.iccidOkStatus"
                )}
                error={isSimError}
                errorText={t(
                  "4G-5G.fiveG.router-content.byorModal.iccidNotOkStatus"
                )}
              />
            )}
            {!isImeiCompatible && !isIMEIError && (
              <TrailingTooltip
                typographySize="large"
                tooltipTitle={t(
                  "4G-5G.fiveG.router-content.byorModal.findImeiNumber"
                )}
                tooltipContent={t(
                  "4G-5G.fiveG.router-content.byorModal.findImeiNumberInfo"
                )}
              >
                {t("4G-5G.fiveG.router-content.byorModal.whereIsMyIMEI")}
              </TrailingTooltip>
            )}
            <MarginSpacerM />
            {(isSimError || isIMEIError) && (
              <>
                <Title>We have what you need.</Title>
                <MarginSpacerS />
                <Body size="large">
                  Verizon's equipment has been tested and approved to work with
                  5G service.
                </Body>
                <MarginSpacerM />
                <EquipmentSelection
                  routers={routers}
                  selectedRouter={selectedRouter}
                  setSelectedRouter={setSelectedRouter}
                />
              </>
            )}
            <MarginSpacerM />
            <ButtonGroup
              viewport={"desktop"}
              childWidth={"100%"}
              rowQuantity={{ desktop: 2 }}
              data={[
                {
                  children: t("4G-5G.fiveG.router-content.byorModal.continue"),
                  size: "large",
                  type: "primary",
                  disabled: isContinueDisabled,
                  onClick: onContinueClick,
                },
                {
                  children: t("4G-5G.fiveG.router-content.byorModal.cancel"),
                  size: "large",
                  type: "secondary",
                  onClick: () => setModalState(false),
                },
              ]}
            />
            <MarginSpacerM />
            <span>
              <IconWrapper>
                <CircleCheck width={14} height={14} />
              </IconWrapper>
              <Body primitive="span">{`You've selected our ${planInfo?.displayName} plan.`}</Body>
            </span>
          </CompatModalBody>
        </CompatModal>
      )}
    </>
  );
};

export default CheckCompatibility;
