import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { Button } from "@vds3/buttons";
import { Icon } from "@vds3/icons";
import { Body } from "@vds3/typography";
import { MarginSpacerS } from "../../../../common/components/styleTag";

const CartPromoBanner = ({
  icon = "tag",
  iconColor = "#000000",
  bgColor = "#f6f6f6",
  titleText,
  bodyText,
  ctaText,
  ctaTextChildren,
  onCTAClick,
  children,
  ...props
}) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 545px)",
  });

  return (
    <PromoBanner bgColor={bgColor}>
      {!!icon && <Icon name={icon} color={iconColor} />}
      <PromoBannerContent>
        <PromoBannerText>
          {children ? (
            children
          ) : (
            <>
              <Body size="small" bold>
                {titleText}
              </Body>
              {!!isMobile && <MarginSpacerS />}
              <Body size="small">{bodyText}</Body>
            </>
          )}
        </PromoBannerText>
        {ctaText ? (
          <div>
            <Button
              use="secondary"
              size="small"
              onClick={onCTAClick}
            >
              {ctaText}
            </Button>
          </div>
        ) : ctaTextChildren ? (
          <div>
            <Button
              use="secondary"
              size="small"
              onClick={onCTAClick}
              children={!!ctaTextChildren && <Body>{ctaTextChildren}</Body>}
            />
          </div>
        ) : (
          <></>
        )}
      </PromoBannerContent>
    </PromoBanner>
  );
};

const PromoBanner = styled.div`
  background-color: ${(props) => props.bgColor};
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 8px;
  // min-height: 2.25rem;
  // box-sizing: border-box;
`;

const PromoBannerContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  row-gap: 1rem;
`;

const PromoBannerText = styled.div`
  width: 570px;
  max-width: 90%;
  flex-grow: 1;
`;

export default CartPromoBanner;
