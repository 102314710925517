import { createSlice, createAction } from "@reduxjs/toolkit";

const initialState = {
  address: "",
  addressId: "",
  apiError: false,
  cband: false,
  fiosQualResponse: {},
  fixedWirelessResponse: {},
  flow: "5g",
  isFios: false,
  isLteBiPlus: false,
  loading: false,
  loopQualAddressResponse: {},
  mmw: false,
  streetLookupResponse: {},
  unavailableService: "",
  unitLookupResponse: {},
  multipleAddresses:[],
  qualifiedRemovedAddress:[]
};

const QualificationSlice = createSlice({
  name: "qualification",
  initialState,
  reducers: {
    resetQualificationSlice: (state) => {
      return initialState;
    },
    setAddress: (state, action) => {
      state["address"] = action.payload;
    },
    setMultipleAddress: (state, action) => {
      if(action.payload?.length === 0){
        state["multipleAddresses"] = action.payload;
      } else{
        state["multipleAddresses"].push(action.payload);
      }
      
    },
    setAddressId: (state, action) => {
      state["addressId"] = action.payload;
    },
    setAPIError: (state, action) => {
      state["apiError"] = action.payload;
    },
    setBBALQResp: (state, action) => {
      state["BBALQResp"] = action.payload;
    },
    setCBand: (state, action) => {
      state["cband"] = action.payload;
    },
    setClear4g5gCartResp: (state, action) => {
      state["clear4g5gCartResp"] = action.payload;
    },
    setFlow: (state, action) => {
      state["flow"] = action.payload;
    },
    setIsFios: (state, action) => {
      state["isFios"] = action.payload;
    },
    setIsLteBiPlus: (state, action) => {
      state["isLteBiPlus"] = action.payload;
    },
    setLoopQualAddressResp: (state, action) => {
      state["loopQualAddressResponse"] = action.payload;
    },
    setLoopQualFiosQualResp: (state, action) => {
      state["fiosQualResponse"] = action.payload;
    },
    setLoopQualFixedWirelessResp: (state, action) => {
      state["fixedWirelessResponse"] = action.payload;
    },
    setLoopQualLoading: (state, action) => {
      state["loading"] = action.payload;
    },
    setLoopQualStreetLookupResp: (state, action) => {
      state["streetLookupResponse"] = action.payload;
    },
    setLoopQualUnitLookupResp: (state, action) => {
      state["unitLookupResponse"] = action.payload;
    },
    setMMW: (state, action) => {
      state["mmw"] = action.payload;
    },
    setUnavailableService: (state, action) => {
      state["unavailableService"] = action.payload;
    },
    setUnit: (state, action) => {
      state["unit"] = action.payload;
    },
    setTotalAvailableUnit: (state, action) => {
      state["totalAvailableUnit"] = action.payload;
    },
    setBBALQBulkAddressResp: (state, action) => {
      state["BBALQBulkAddressResp"] = action.payload;
    },
    setQualifiedSelectedAddress: (state, action) => {
      state["qualifiedselectedAddress"] = action.payload;
    },
    setAddressChange:(state,action) => {
      state["isAddressChange"] = action.payload;
    },
    setQualifiedRemovedAddress: (state, action) => {
      state["qualifiedRemovedAddress"] = action.payload;
    },
  },
});

export const reducer = QualificationSlice.reducer;

export const clear4g5gCart = createAction("qualification/clear4g5gcart");
export const get5gRouterDevices = createAction(
  "qualifiction/get5gRouterDevices"
);
export const getFixedWirelessCoverageCheck = createAction(
  "qualification/getFixedWirelessCoverageCheck"
);
export const getLQSessionDetails = createAction(
  "qualification/getLQSessionDetails"
);
export const getBulkLQSessionDetails = createAction(
  "qualification/getBulkLQSessionDetails"
);

export const postLoopQualAddress = createAction(
  "qualification/postLoopQualAddress"
);
export const postLQFiosQualification = createAction(
  "qualification/postLQFiosQualification"
);
export const postLQStreetLookup = createAction(
  "qualification/postLQStreetLookup"
);
export const postLQUnitLookup = createAction("qualification/postLQUnitLookup");

export const {
  resetQualificationSlice,
  setAddress,
  setUnit,
  setAddressId,
  setAPIError,
  setBBALQResp,
  setCBand,
  setClear4g5gCartResp,
  setFlow,
  setIsFios,
  setIsLteBiPlus,
  setLoopQualAddressResp,
  setLoopQualFiosQualResp,
  setLoopQualFixedWirelessResp,
  setLoopQualLoading,
  setLoopQualStreetLookupResp,
  setLoopQualUnitLookupResp,
  setMMW,
  setRouterDeviceResp,
  setUnavailableService,
  setTotalAvailableUnit,
  setMultipleAddress,
  setBBALQBulkAddressResp,
  setQualifiedSelectedAddress,
  setAddressChange,
  setQualifiedRemovedAddress
} = QualificationSlice.actions;
