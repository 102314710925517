import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Modal, ModalTitle, ModalBody } from "@vds3/modals";
import { Button } from "@vds3/buttons";
import { Body } from "@vds3/typography";

const ReviewOfferDetails = (props) => {
  const  {
    reviewOfferList
  } = props
  const getUnorderListTop = ()=>{
    return(
      <>
         <ol>
          {reviewOfferList?.bulletPointsTop?.map((item)=>{
            return <li size="bodyLarge" >
            <Body size="large">{item?.bulletDesc}</Body>
          </li>
          })}
        </ol>
      </>
    )
  }

  const getUnorderListMiddle = ()=>{
    return(
      <>
         <ol>
          {reviewOfferList?.bulletPointsMiddle?.map((item)=>{
            return <li size="bodyLarge" >
            <Body size="large">{item?.bulletDesc}</Body>
          </li>
          })}
        </ol>
      </>
    )
  }

  const getUnorderListBottom = ()=>{
    return(
      <>
         <ol>
          {reviewOfferList?.bulletPointsBottom?.map((item)=>{
            return <li size="bodyLarge" >
              <Body size="large">{item?.bulletDesc}</Body>
          </li>
          })}
        </ol>
      </>
    )
  }


  return(
    <>
      <NCModal
        ariaLabel=""
        opened={true}
        onOpenedChange={props?.setReviewOfferdetails}
        disableOutsideClick={true}
        disableFocusLock= {true}
      >  

        <ModalTitle>
          {reviewOfferList?.reviewHeading}
        </ModalTitle>

        <ModalBody>
          {reviewOfferList?.reviewSubHeading && 
          <ReviewOfferListItemsHeading>
            <Body size="large" bold>{reviewOfferList?.reviewSubHeading}</Body>
          </ReviewOfferListItemsHeading> }
          
         {reviewOfferList?.bulletPointsTop && <ListItemsWrapper>{getUnorderListTop()}</ListItemsWrapper> }      

         {reviewOfferList?.reviewDescTop && <ReviewModalDesc>
            <Body size="large">{reviewOfferList?.reviewDescTop}</Body>
          </ReviewModalDesc>}
                         
          {reviewOfferList?.reviewDescTwo &&<ReviewModalDesc>
            <Body size="large">{reviewOfferList?.reviewDescTwo}</Body>
          </ReviewModalDesc>}
          {reviewOfferList?.reviewSubHeadingTwo && 
            <>
              <ReviewOfferListItemsHeading>
                <Body size="large" bold>{reviewOfferList?.reviewSubHeadingTwo}</Body>
              </ReviewOfferListItemsHeading>
          <ListItemsWrapper>{getUnorderListMiddle()}</ListItemsWrapper>
          <ListItemsWrapper>{getUnorderListBottom()}</ListItemsWrapper>
              <ReviewModalDesc>
                <Body size="large">{reviewOfferList?.reviewDescMiddle}</Body>
              </ReviewModalDesc>
             {reviewOfferList?.reviewDescBottomTwo && <ReviewModalDesc>
                <Body size="large">{reviewOfferList?.reviewDescBottomTwo}</Body>
              </ReviewModalDesc>}
              
</>
          }       
          {reviewOfferList?.reviewSubHeadingThree &&
            <>
              <ReviewOfferListItemsHeading>
                <Body size="large" bold>{reviewOfferList?.reviewSubHeadingThree}</Body>
              </ReviewOfferListItemsHeading>
              <ListItemsWrapper>{getUnorderListBottom()}</ListItemsWrapper>
              <ReviewModalDesc>
                <Body size="large">{reviewOfferList?.reviewDescBottom}</Body>
              </ReviewModalDesc>
              {reviewOfferList?.reviewDescBottomTwo && <ReviewModalDesc>
                <Body size="large">{reviewOfferList?.reviewDescBottomThree}</Body>
              </ReviewModalDesc>}
            </>
          }
        </ModalBody>

        <ReviewModalFooter>
          <Button 
            size="large"
            disabled={false}
            use="primary"
            onClick={()=>props?.setReviewOfferdetails(false)}
            >
            Okay
          </Button>
        </ReviewModalFooter>


      </NCModal>
    </>
  )
}

const NCModal = styled(Modal)`
  height: auto;
  width: 550px;
  min-height: auto;
  outline: none !important;
  overflow: hidden;
  z-index:9999;
  > div:first-child {
    overflow: hidden;
  }
  @media only screen and (max-width:1030px) {
    width:60%
  }
  
  @media only screen and (max-width: 767px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95vw;
    > div:first-child {
      padding: 2px;
    }
  }

  @media only screen and (max-width:420px) {    
    bottom: 0px;
    position: fixed;
    top: 0px;
    left: 0px;
    transform:translate(0px,0px);
  }
`;

const ReviewOfferListItemsHeading = styled.div`
  margin:0.5rem 0px 1rem 0px;
`

const ReviewModalDesc = styled.div`
  margin:2rem 0px;
`

const ReviewModalFooter = styled.div`
`

const ListItemsWrapper = styled.div`
  ol {padding-left:20px;
    li {margin-top:1rem;    
      & > p {padding-left:8px;}
    }
  }
`



export default ReviewOfferDetails;