import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Modal, ModalTitle, ModalBody } from "@vds3/modals";
import { Body } from "@vds3/typography";
import { MarginSpacerL } from "common/components/styleTag";
import { StyledFooterContainer } from "./styles";
import FWAContext from "../fwaContext.js";
import { Button } from "@vds3/buttons";

const PromotionsModal = ({
  opened,
  onOpenedChange,
  isCband,
  isMMW,
  selectedPlan,
  showVisaOffer,
  isfromAuth,
  maxSpeed,
  isFwa,
  starkEnabled,
  aemContent
}) => {
  const { flow } = useContext(FWAContext);
  const { t } = useTranslation(["4G-5G"]);
  const aemKey = flow === "4g" ? "fourG" : "fiveG";
  const whichModal =starkEnabled ? "promotionsMMWStarkModal" :
    ((isCband && isMMW && !isfromAuth) || (!isCband && isMMW && flow === "5g")) && ((maxSpeed && Number(maxSpeed) > 300) || !maxSpeed)
      ? "promotionsMMWModal" : ((isCband && isMMW && !isfromAuth) || (!isCband && isMMW && flow === "5g")) && ((maxSpeed && Number(maxSpeed) <= 300) || !maxSpeed) ?
      "promotionsMMWModal200"
      : "promotionsModal";
  const aem = t(`4G-5G.${aemKey}.plan-content.${whichModal}`, {
    returnObjects: true,
  });
  const aemVisaOffer5G = t(`4G-5G.visaOffer5G`, {
    returnObjects: true,
  });
  const aemVisaOfferLTE = t(`4G-5G.visaOfferLTE`, {
    returnObjects: true,
  });

  let visaOffer = '';
  if (showVisaOffer) {
    visaOffer = aemKey === "fourG" ? aemVisaOfferLTE : aemKey === "fiveG" ? aemVisaOffer5G : '';
  }

  return (
    <>
      {!!isFwa ? (
      <Modal surface="light"
        hideCloseButton={false}
        opened={opened}
        onOpenedChange={onOpenedChange}>
        <ModalTitle>{aemContent?.promotionTitle}</ModalTitle>
        <ModalBody>
          <ModalBodyContainer>
            <Body size="large">
              {aemContent?.promotionContent}
            </Body>
          </ModalBodyContainer>
          <MarginSpacerL />
        </ModalBody>
        <StyledFooterContainer>
          <Button
            width="100%"
            onClick={() => {
              onOpenedChange(false);
            }}
          >
            Okay
          </Button>
        </StyledFooterContainer>
      </Modal>)
        : (
        <Modal
          surface="light"
          hideCloseButton={false}
          opened={opened}
          onOpenedChange={onOpenedChange}
        >
          <ModalTitle>{aem?.heading}</ModalTitle>
          <ModalBody>
            <ModalBodyContainer>
              {<Body size="large">
                {showVisaOffer ? <span
                  dangerouslySetInnerHTML={{
                    __html: visaOffer,
                  }}
                /> :
                  aem?.content?.map((i) => (
                    <>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: i,
                        }}
                      />
                    </>
                  ))}
              </Body>
              }
            </ModalBodyContainer>
          </ModalBody>
          <MarginSpacerL />
          <StyledFooterContainer>
            <Button
              width="100%"
              onClick={() => {
                onOpenedChange(false);
              }}
            >
              Close
            </Button>
          </StyledFooterContainer>
        </Modal>)}

    </>
  );
};

const ModalBodyContainer = styled.div`
  li:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;

export default PromotionsModal;
