import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { TextLink } from "@vds3/buttons";
import { Body } from "@vds3/typography";
import {
  LineItem,
  LineItemPriceGrid,
} from "pages/ShoppingCart/components/LineItemPriceGrid/LineItemPriceGrid";
import { MarginSpacerM } from "../../../../common/components/styleTag";
import { formatPriceString } from "utils/commonUtils";
import CartPkgContext from "./cartPkgContext";
import { PackagePriceDiv, SeparatorLine } from "./styles";

export const LicenseType = ({ onEditClick }) => {
  const { t } = useTranslation(["shoppingCart"]);
  const { pack} = React.useContext(CartPkgContext);
  const quantity = Number(pack?.quantity);
  const packTenure = (pack?.tenure / 12)
  return (
    <>
      <LineItemPriceGrid title={"License Type"}>
        <LineItem
          displayName={(String(pack?.priceType).toUpperCase() === 'ANNUAL') ? "Annually" : "Monthly" }
          price={
            <PackagePriceDiv>
              {formatPriceString(Number(pack?.plan?.planPrice) * quantity)}
              {(String(pack?.priceType).toUpperCase() === 'ANNUAL') ?
              ("/"+packTenure+((packTenure > 1) ? "yrs" :"yr")) : t("shopping-cart.cart.perMonth")}
            </PackagePriceDiv>
          }
          subText={pack?.bussSubscriptionInfo?.autoRenew === 'Y' ? "Auto-renewal is enabled." : ""}
          subTextColor="black"
          subTextSize="small"
        />
      </LineItemPriceGrid>
    </>
  );
};

export const ProfessionalService = ({ onEditClick }) => {
    const { t } = useTranslation(["shoppingCart"]);
    const { pack} = React.useContext(CartPkgContext);
    const quantity = Number(pack?.quantity);
    const packTenure = (pack?.tenure / 12)
    return (
      <>
        <LineItemPriceGrid title={"Professional Service"}>
          <LineItem
            displayName={pack?.description}
            price={
              <PackagePriceDiv>
                {formatPriceString(Number(pack?.totalDueMonthly) * quantity)}
                {(String(pack?.priceType).toUpperCase() === 'ANNUAL') ?
              ("/"+packTenure+((packTenure > 1) ? "yrs" :"yr")) : t("shopping-cart.cart.perMonth")}
              </PackagePriceDiv>
            }
          />
        </LineItemPriceGrid>
      </>
    );
  };
  

