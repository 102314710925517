import React, { useState, useEffect } from 'react'
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { getCompareDevices, getGridwallList, setCompareDevices } from 'pages/Mobility/ducks/MobilitySlice';
import { DropdownSelect } from "@vds3/selects";

const CompareSelectDevice = (props) => {
  const dispatch = useDispatch();
  const { aem } = props;
  const [Brand, setBrand] = useState(aem?.labels?.brand)
  const [Model, setModel] = useState(aem?.labels?.model || "")
  const [selectedBrand, setSelectedBrand] = useState('')
  const [selectedModel, setSelectedModel] = useState('')
  const [categoryType, setCategoryType] = useState('')
  const BrandsList = useSelector((state) => state?.MobilityPage?.brandsList)
  const MobilityPageData = useSelector((state) => state?.MobilityPage.gridwall)
  const compareDevicesList = useSelector((state) => state?.MobilityPage?.compareDevices)
  const DeviceList = useSelector((state) => MobilityPageData?.data?.deviceList)
  const [modelEnable, setModelEnable] = useState(true)

  const [deviceListLocal, setDeviceListLocal] = useState([]);
 
  useEffect(() => {
    setDeviceListLocal(DeviceList)
  }, [DeviceList]);

  useEffect(()=>{
    let categoryLIst =  Object.keys(compareDevicesList).map(key=>{
       return compareDevicesList[key]?.category
      })
      setCategoryType(categoryLIst[0])
    },[])
    
  const handleSelect = (e) => {
    setSelectedBrand(e.target.value);
    setModelEnable(false)
    setBrand(e.target.value)
  }
  const handleSelectModel = (e) => {   
    setSelectedModel(e.target.value);
    setModel(e.target.value)
  }

  useEffect((e) => {
    if (selectedModel) {
      let deviceItem = DeviceList && DeviceList.find((item) => item.name === selectedModel)
      let findDefaultSku = deviceItem && deviceItem?.skulist && deviceItem?.skulist?.find(item => item.defaultSku)
      let findDeviceId = deviceItem.deviceId
      let updateobj = { ...deviceItem, selectedSku: findDefaultSku }
      let finalUpdateObj = { ...compareDevicesList, [findDeviceId]: updateobj }
      dispatch(setCompareDevices(finalUpdateObj));
      let payload = Object.keys(finalUpdateObj).map((device) => {
        return {
          deviceId: finalUpdateObj[device]?.deviceId,
          deviceSku: finalUpdateObj[device]?.selectedSku?.sku,
          deviceOfferPercentage: "",
          deviceOfferAmount: "",
          seoURL: finalUpdateObj[device]?.seoUrlName,
        }
      });
     
      dispatch(getCompareDevices(payload))
      setBrand(aem?.labels?.brand)
      setModel(aem?.labels?.model)
      setModelEnable(true)
    }
  }, [selectedModel])

  useEffect(() => {
    const requestBody = {
      deviceFilters: {
        brandList: [
          {
            key: selectedBrand,
            type: "brandList",
            imageUrl: null,
            name: null
          }
        ],
        categoryList: [
          {
              type: "categoryList",
              key: categoryType,
              imageUrl: null,
              name: categoryType
          }
      ]
      }
    };
    dispatch(getGridwallList(requestBody));
  }, [selectedBrand])

  return (
    <ByodImageContainer
    >
      <img
        alt=""
        aria-label=""
        src={`${window?.mbtGlobal?.assetsBaseUrl}/assets/images/byod.png`}
        height="186px"
        onClick={props?.handleImgClick}
        className="byodimage"
      />
      <SelecttdropsDowns>
        <BrandDropDown className="noOutline">
          <DropdownSelect
            value={Brand}
            onChange={handleSelect}
            error={false}
            disabled={false}
            readOnly={false}
            inlineLabel={false}
          >
            <option selected disabled>{aem?.labels?.brand}</option>
            {BrandsList.map((val, index) => (
              <option value={val.key}>{val.key}</option>
            ))
            }
          </DropdownSelect>
        </BrandDropDown>

        <ModelDropDown>          
          <DropdownSelect
            value={Model}
            onChange={handleSelectModel}
            disabled={modelEnable}
            error={false}
            readOnly={false}
            inlineLabel={false}
          >
            <option selected disabled>{aem?.labels?.model}</option>
            {DeviceList !== null && DeviceList ? DeviceList?.map((obj) => <option>{obj.name}</option>) : <></>}
          </DropdownSelect>
        </ModelDropDown>
      </SelecttdropsDowns>

    </ByodImageContainer>
  )
}


const SelecttdropsDowns = styled.div`
margin:35px;

`
const BrandDropDown = styled.div`
.noOutline:focus-visible{
outline :none;
}
.noOutline:focus{
  outline :none;
}
margin-top: 0px;
`
const ModelDropDown = styled.div`
margin-top: 20px;
`

const ByodImageContainer = styled.div`
  display: flex;
  justify-content: space-around;
  border-radius: 8px;
  background-color: #f6f6f6;
  flex-direction: column;
  align-items: center;       
  width: 290px;       
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom:5px;

  select{
      width: 242px;
      height: 44px;
      border-radius: 5px;
      padding-left: 8px;
      
  }

  select:focus-visible{
    bordercolor:#f6f6f6;
  }
  
  img{
    margin-top: 35px;
  }
  
  @media only screen and (max-width: 767px) {
    width: 158px;
    height: 100%;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin : 5px;
    // box-shadow: 0px 16px 24px rgba(0,0,0,0.02);

  select{
    width: 242px;
    height: 44px;
    border-radius: 5px;
    padding-left: 8px;
      
  }

  select:focus-visible{
    bordercolor:#f6f6f6;
  }
  img{
    margin-top: 35px;
  }
    
  @media only screen and (max-width: 767px) {
    width: 158px;
    height: 100%;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin : 5px;
    // box-shadow: 0px 16px 24px rgba(0,0,0,0.02);
    select{
        width: 134px !important;
        height: 44px !important;
        margin-bottom:0px;
    }

    img{
        width: 110px !important;
        height: 134px;
        margin-bottom: 30px;
      }
  }
  
  @media only screen and (max-width:545px) {
    margin : 0px; 
    min-height:501px;   

    select{
      margin-bottom:0px;
    } 

    img{
      height:182px;
     
    }
  }
  
`;
export default CompareSelectDevice