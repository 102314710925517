import React from "react";
import { Button, TextLink } from "@vds3/buttons";
import { UnorderedList, ListItem } from "@vds3/lists";
import { Modal, ModalTitle, ModalBody } from "@vds3/modals";
import { Body, Title } from "@vds3/typography";
import { MarginSpacerL } from "../../styleTag";

export const ByorDisclaimerModal = ({
  showBYORDisclaimerModal,
  setShowBYORDisclaimerModal,
  modalTitle,
  modalSubHeading,
  byorDisclaimerModalHTML,
  isMMW,
  ...props
}) => {
  let byorDisclaimerModalContentList = byorDisclaimerModalHTML;
  let byorDisclaimerModalContentSubHeading = modalSubHeading;

  return (
    <Modal
      opened={showBYORDisclaimerModal}
      onOpenedChange={setShowBYORDisclaimerModal}
      surface="light"
      hideCloseButton={false}
      toggleButton={<TextLink></TextLink>}
    >
      <ModalTitle>
        <Title size="large" bold={true}>
          <div
            dangerouslySetInnerHTML={{
              __html: modalTitle,
            }}
          ></div>
        </Title>
      </ModalTitle>
      <ModalBody>
        <Body size="large" bold={!isMMW}>
          <div
            dangerouslySetInnerHTML={{
              __html: byorDisclaimerModalContentSubHeading,
            }}
          ></div>
        </Body>
        <div style={{ marginTop: "1rem", marginLeft: "0.5rem" }}>
          <UnorderedList>
            {byorDisclaimerModalContentList.map((element, idx) => {
              return (
                <ListItem key={idx} size="bodyLarge" spacing="compact">
                  <span dangerouslySetInnerHTML={{ __html: element }} />
                </ListItem>
              );
            })}
          </UnorderedList>
        </div>
        <MarginSpacerL />
        <Button
          width={props?.isMobile ? "100%" : ""}
          onClick={() => {
            setShowBYORDisclaimerModal(false);
          }}
          disabled={false}
        >
          Close
          {/* {aemContent?.byorDisclaimerModal?.label} */}
        </Button>
      </ModalBody>
    </Modal>
  );
};
