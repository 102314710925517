import React, { useEffect, useState } from 'react'
import * as APIURL from "../../../containers/APIurls";
import fetchApigeeToken from './useFetchApigeeToken';
import { useDispatch } from 'react-redux';
import { setTotalAvailableUnit } from 'pages/Qualification/ducks/QualificationSlice';
import { useTranslation } from 'react-i18next';

import ApiClient from '../../../utils/apiClient'

const useFetchUnits = (locusID, ntasAddrID, unitTerm, isDropDown , callback, onApiCompletedCallback) => {

  const [unitList, setUnitList] = useState([]);
  const [totalUnits, setTotalUnits] = useState(0);
  const [accessToken, setAccessToken] = useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation(["app"]);
   let APIGEE_URL;
   let LOCUS_URL;
   
      // const baseUrl = t("app.uat.baseUrlApigee", {ns: "app"})
      // const locusbaseUrl = t("app.uat.baseUrlLocus", {ns: "app"})
      const locusbaseUrl = process.env.PROSPECT_WIDGETS_APP_LOCUS_URL;
      LOCUS_URL = locusbaseUrl + APIURL.GET_LOCUS_URL;
   useEffect(() => {
    if ((!locusID && !ntasAddrID)) {
      return;
    }
    const invokeFunc = async () =>{
      let response = null;
      try {
        let token  = accessToken
        // if(!token) {
        //  token = await fetchApigeeToken(APIGEE_URL);
        //  setAccessToken(token)
        // }
        response = await getUnitsInfo(locusID, ntasAddrID, unitTerm, token,LOCUS_URL)  
        if (response?.data?.total === '0'||response?.data?.total === 0 ) {
          response = await getUnitsInfo(locusID, ntasAddrID, '', token,LOCUS_URL)
        }   
      } catch (err) {
        try{
        // const token = await fetchApigeeToken(APIGEE_URL);    
        //  setAccessToken(token);
        response = await getUnitsInfo(locusID, ntasAddrID, unitTerm, token,LOCUS_URL)
        } catch (err){ 
          onApiCompletedCallback(0);
          setTotalUnits(0);
          setUnitList([]);
        }
      }
      onApiCompletedCallback(response?.data?.total);
      if (response?.data?.total > 0 ) {
        setUnitList(response.data.unitDetails);
        dispatch(setTotalAvailableUnit(response.data.unitDetails))
        if(!unitTerm) {
          setTotalUnits(response.data.total)
        }
      } else {
        setUnitList([]);
        if(!unitTerm) {
          setTotalUnits(0)
        }
      }
    }
    invokeFunc();
  }, [locusID, ntasAddrID, unitTerm])
  callback();
  return [
    unitList,
    totalUnits,
    setUnitList,
    setTotalUnits
  ]
}


export default useFetchUnits
async function getUnitsInfo(locusID, ntasAddrID, unitTerm, accessToken,LOCUS_URL) {
  
  let response = null;
  if (!locusID && ntasAddrID) {
    const config = {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }
    let url = APIURL.GET_UNITS_URL + ntasAddrID;
    if (unitTerm) {
      url += '&unitterm=' + unitTerm
    }
    response = await ApiClient.get(url, config).then(res => res.data)
  } else {
    const LOCUSAPI_KEY = process.env.PROSPECT_WIDGETS_APP_ATOM_APIKEY;

    const configLocus = {
      headers: {
        'apikey': LOCUSAPI_KEY
      }
    }
    let url = LOCUS_URL + locusID + `&size=10`;
    if (unitTerm) {
      url += '&unitterm=' + unitTerm
    }
    response = await ApiClient.get(url, configLocus)
      .then(response => {
        response.data.unitDetails = response?.data?.data?.map(e => ({
          addressId: e?.location_id,
          baseAddressId: e?.location_id,
          unit: e?.sublocation, ...e
        }));
        let tempUnitList = Array.from( response?.data?.data);
        let listLength = 0;
        tempUnitList.length >0  && tempUnitList.map((unit, key) => {
            if (!tempUnitList[key].ntasSublocation) {
                listLength = listLength+1
            }
        })
        response.data.total = ntasAddrID? tempUnitList.length !== listLength ? response.data.totalHits : 0 : response.data.totalHits;
        return response

        // response.data.unitDetails = response?.data?.data?.map(e => ({
        //   addressId: e?.location_id,
        //   baseAddressId: e?.location_id,
        //   unit: e?.sublocation, ...e
        // }));
        // response.data.total = response.data.totalHits;
        // return response
      });
  }
  return response;
}