import cloneDeep from 'lodash/cloneDeep';
import InitialState from './initialState';
export const isValidInfoEntered = (errorTexts) => {
    const keys = Object.keys(errorTexts);
    for (let i = 0; i < keys?.length; i++) {
        if (errorTexts[keys[i]]) {
            return false;
        }
    }
    return true;
}

const formatDate = (e) => {
    let string = e ? e.replaceAll("/", "").replaceAll("-", "") : "";
    if (!string?.trim || string?.length == 6) {
      let mon = "0" + (string.substr(0, 1));
      let date = "0" + string.substr(1, 1);
      let year = "" + string.substr(2, 4);
      return `${mon}-${date}-${year}`;
    }
    if (!string?.trim || string?.length == 7) {
      let mon = "" + (string.substr(0, 2));
      let date = "0" + string.substr(2, 1);
      let year = "" + string.substr(3, 4);
      return `${mon}-${date}-${year}`;
    }
    if (!string?.trim || string?.length == 8) {
      let mon = "" + (string.substr(0, 2));
      let date = "" + string.substr(2, 2);
      let year = "" + string.substr(4, 4);
      return `${mon}-${date}-${year}`;
    }
    return e;
  };

  const findAge = (val) => {
    var today = new Date();
    var birthDate = new Date(val);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

export const isValidContactInfoFieldHelper = (key, val, ErrorTexts, t) => {
    let value = val;
    let errorTexts = cloneDeep(ErrorTexts ?? InitialState?.errorTexts);
    // firstName
    if (key === "firstName") {
        if (!value?.trim()) {
            errorTexts.contactInfo['fName'] = t('verification.errorMsgs.signerNameReq', { ns: 'checkout' });
        } else {
            const pattern = /^[a-zA-Z-\s]+$/;
            if (!pattern.test(value)) {
                errorTexts.contactInfo['fName'] = t('verification.errorMsgs.invalidChar', { ns: 'checkout' });
            } else {
                errorTexts.contactInfo['fName'] = "";
            }
        }
    }

    // lastName
    if (key === "lastName") {
        if (!value?.trim()) {
            errorTexts.contactInfo['lName'] = t('verification.errorMsgs.lastNameReq', { ns: 'checkout' });
        } else {
            const pattern = /^[a-zA-Z-\s]+$/;
            if (!pattern.test(value)) {
                errorTexts.contactInfo['lName'] = t('verification.errorMsgs.invalidChar', { ns: 'checkout' });
            } else {
                errorTexts.contactInfo['lName'] = "";
            }
        }
    }

    // phoneNumber
    if (key === "phoneNumber") {
        const phoneNumber = value?.replace(/[^\d]/g, "");
        if (/^[0-9]{0,10}$/.test(phoneNumber)) {
            value = phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, "$1.$2.$3");
        }
        if (!value?.trim()) {
            errorTexts.contactInfo['cPhoneNumber'] = t('verification.errorMsgs.phoneReq', { ns: 'checkout' });
        } else {
            const pattern = /^\d{10}$/;
            if (!pattern.test(phoneNumber)) {
                errorTexts.contactInfo['cPhoneNumber'] = t('verification.errorMsgs.phoneLength', { ns: 'checkout' });
            } else {
                errorTexts.contactInfo['cPhoneNumber'] = "";
            }
        }
    }

    // email
    if (key === "email") {
        if (!value?.trim()) {
            errorTexts.contactInfo['cEmail'] = t('verification.errorMsgs.emailReq', { ns: 'checkout' });
        } else {
            const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const minChar = value?.split("@")[0];
            if (!pattern.test(value) || !(minChar?.trim()?.length > 1)) {
                errorTexts.contactInfo['cEmail'] = t('verification.errorMsgs.validEmail', { ns: 'checkout' });
            } else {
                errorTexts.contactInfo['cEmail'] = '';
            }
        }
    }
    if (key === "DateOfBirth") {
        let temp = value ? value.replaceAll("/", "").replaceAll("-", "") : "";
        if (!value.trim || value.length === 0) {
            errorTexts.contactInfo['dob'] = t("verification.errorMsgs.dobReq", { ns: "checkout" });
        }
        /* check if length of formated date lenth is less than 6  */
        else if (temp.length < 8) {
          val = value;
          errorTexts.contactInfo['dob'] = t("verification.errorMsgs.invalidDate", {
            ns: "checkout",
          });
        }
        else {
          val = formatDate(value);
          let inDate = val?.replaceAll("-", "").substr(2, 2);
          let age = findAge(val)
          let isFutureDate = (new Date(val?.replace(/-/g, "/"))) > new Date()
          /*  future date error */
          if (isFutureDate) {
            errorTexts.contactInfo['dob'] = t("verification.errorMsgs.futureDate", {
              ns: "checkout",
            });
          }
          /* Invalide date error  */
          else if (new Date(val?.replace(/-/g, "/"))?.toString() === "Invalid Date") {
            errorTexts.contactInfo['dob'] = t("verification.errorMsgs.invalidDate", {
              ns: "checkout",
            });
          }
          /* Invalide number of days error  */
          else if (new Date(val?.replace(/-/g, "/"))?.getDate() !== parseInt(inDate)) {
            errorTexts.contactInfo['dob'] = t("verification.errorMsgs.invalidDate", {
              ns: "checkout",
            });
          }
  
          /*  check for min age */
          else if (!isFutureDate && age < 18) {
            errorTexts.contactInfo['dob']= t("verification.errorMsgs.minAge", {
              ns: "checkout",
            });
          } else {
            errorTexts.contactInfo['dob'] = '';
        }
        }
      }
    // jobTitle
    if(key === 'jobTitle'){
      errorTexts.contactInfo['jobTitle'] = !value?.trim() ? t('verification.errorMsgs.businessName', { ns: 'checkout' }) : "";
    }
    // businessName
    if (key === "fedTaxId") {
        errorTexts.contactInfo['fedTaxId'] = !value?.trim() ? t('verification.errorMsgs.taxIdReq', { ns: 'checkout' }) : "";
    }

    // address
    if (key === "address") {
        errorTexts.contactInfo['cAddress'] = !value?.trim() ? t('verification.errorMsgs.addressLine1Req', { ns: 'checkout' }) : "";
    }
    // city
    if (key === "city") {
        errorTexts.contactInfo['cCity'] = !value?.trim() ? t('verification.errorMsgs.cityReq', { ns: 'checkout' }) : "";
    }
    // state
    if (key === "state") {
        errorTexts.contactInfo['cState'] = !value?.trim() ? t('verification.errorMsgs.stateReq', { ns: 'checkout' }) : "";
    }
    // zip code
    if (key === "zipCode") {
        errorTexts.contactInfo['cZip'] = !value?.trim() ? t('verification.errorMsgs.zipReq', { ns: 'checkout' }) : "";
    }
    return errorTexts;
}