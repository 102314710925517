import React, { useEffect, useReducer, useRef, useState } from "react";
import styled from "styled-components";
import { Modal, ModalBody, ModalTitle } from "@vds3/modals";
import { Body } from "@vds3/typography";
import { useMediaQuery } from "react-responsive";
import { Button, TextLink } from "@vds3/buttons";
import { reducer } from "./containers/FormReducer";
import * as CONSTANTS from "./containers/constants";
import { Input } from "@vds3/inputs";
import { MarginSpacerM, MarginSpacerL } from "common/components/styleTag";
import { Notification } from "@vds3/notifications";
import { useSelector } from "react-redux";
import { DropdownSelect } from "@vds3/selects";
import { useTranslation } from "react-i18next";
import MapQStreetAddress from "pages/Checkout/ShippingInfo/components/MapQStreetAdress";

const AddressVerificationModal = ({
  setAddressConfirmationModal,
  continueClick,
  opened,
  address,
  setShippingState,
  aemContent,
  fromShoppingCart = false,
  smartWatchAemContent,
  isAPIError,
  isAPIErrorMessage,
  displayAddress,
  businessRawAddress,
  showCloseButton = false,
  fromBusinessAddress = false,
  unitDefaultValue,
  addressLine2,
  handleAddressSelect = () => { },
  validateBusinessAddress = () => { },
}) => {
  const businessStreetRef = useRef();
  const [state, dispatch] = useReducer(
    reducer,
    JSON.parse(localStorage.getItem("shippingState"))
  );
  const { t } = useTranslation(["checkout"]);
  const pageData = useSelector((state) => state.CheckoutPage);
  const [inputDisabled, setInputDisabled] = useState(
    fromShoppingCart || fromBusinessAddress ? false : true
  );
  const [errors, setErrors] = useState({});
  const [businessUnit, setBusinessUnit] = useState(unitDefaultValue || "");
  const [businessSaStreetAddressObj, setBusinessStreetADressObj] = useState({});
  const [streetValue, setStreetValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [zipValue, setZipValue] = useState("");
  const [addressLineOne, setAddresLineOne] = useState(null);
  const [showErrNotification, setShowErrNotification] = useState(false);
  const [ctaClicked , setCtaClicked] = useState(false);
  const [unitError,setUnitError] = useState("");
  const shippingState = JSON.parse(localStorage.getItem("shippingState"));
  const oldProperties = shippingState?.businessStreetAddress?.place?.properties;
  const businessInfoAEM = t("checkout.businessInfo-section", { ns: "checkout", returnObjects: true });
  const stateList = businessInfoAEM.addresStateList.state || [];
  const unitErrorMessage =
        t("checkout.businessInfo-section.addressConfirmationModal.unitErrorMessage", {
            ns: "checkout"
        }) || '';
  const isMobile = useMediaQuery({
    query: "(max-width: 725px)",
  });
  // const stateList = [" ", "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"];

  // useEffect(() => {
  //   if (setShippingState) {
  //     setShippingState(state);
  //     setAddresLineOne(state?.businessStreetAddress?.name);
  //     handleCompanyInfo();
  //   }
  // }, []);

  useEffect(() => {
    if (pageData?.addressValidationInfo?.serviceStatus && !pageData?.addressValidationInfo?.serviceStatus?.success) {
      setShowErrNotification(true);
      setCtaClicked(false);
    }
  }, [pageData?.addressValidationInfo])

  useEffect(() => {
    const shippingState = JSON.parse(localStorage.getItem("shippingState"));
    const properties = shippingState?.businessStreetAddress?.place?.properties;
    if (
      (properties?.street === streetValue ||
        properties?.street === addressLineOne) &&
      properties?.city === cityValue &&
      (properties?.state === stateValue ||
        properties?.stateCode === stateValue) &&
      properties?.postalCode === zipValue
    ) {
      setErrors({
        ...errors,
        street: aemContent?.errors?.addrInvalid,
        businessStreetAddress: aemContent?.errors?.addrInvalid,
      });
      setStreetValue("");
    } else if (addressLineOne !== null && addressLineOne.length == 0) {
      setErrors({
        ...errors,
        street: aemContent?.errors?.addrInvalid,
        businessStreetAddress: aemContent?.errors?.addrInvalid,
      });
    } else if (addressLineOne == null && ctaClicked) {
      setErrors({
        ...errors,
        street: aemContent?.errors?.addrInvalid,
        businessStreetAddress: aemContent?.errors?.addrInvalid,
      });
    } 
    else {
      setErrors({
        ...errors,
        street: null,
        businessStreetAddress: null,
      });
    }
  }, [state, streetValue, addressLineOne, cityValue, stateValue, zipValue]);

  
  const validateUnitFiled = (val) =>{
    const PATTERN_ADDRESS = /^[A-Za-z0-9 ]+$/.test(val);
    if(!PATTERN_ADDRESS){
      setUnitError("only letter allowed")
    }else{
      setUnitError("")
    }
    dispatch({
      type: CONSTANTS.SET_BUSINESS_STREET_ADDRESS2,
      payload: val,
    });
    setBusinessUnit(val);
  }

  const handleAddressSelection = (value) => {
    let tempErrors = {};
    if (!value) {
      tempErrors = {
        ...errors,
        city: aemContent?.errors?.cityInvalid,
        state: aemContent?.errors?.stateReqd,
        zipCode: aemContent?.errors?.zipInvalid,
        businessStreetAddress: aemContent?.errors?.addrLine1Reqd,
      };
    } else {
      tempErrors = {
        ...errors,
        city: "",
        state: "",
        zipCode: "",
        businessStreetAddress: "",
      };
    }
    const properties = value?.place?.properties;
    if (
      properties?.street?.toUpperCase &&
      properties?.street?.toUpperCase()?.includes("PO BOX")
    ) {
      tempErrors = {
        ...errors,
        businessStreetAddress: aemContent?.errors?.poBox,
      };
    }
    setBusinessStreetADressObj(value);
    setAddresLineOne(properties ? properties?.street : "");
    setCityValue(properties ? properties?.city : "");
    setPropertyType(properties ? properties?.propertyType : "");
    setStateValue(properties ? properties?.stateCode : "");
    setZipValue(properties ? properties?.postalCode : "");
    setErrors(tempErrors);
    dispatch({
      type: CONSTANTS.SET_BUSINESS_STREET_ADDRESS,
      payload: value,
    });
    dispatch({
      type: CONSTANTS.SET_SHIPPING_ERRORS,
      payload: { ...tempErrors },
    });
  };

  const handleAddressInput = (e, name) => {
    const value = e?.target?.value;
    setBusinessStreetADressObj(null);
    const properties = {};
    const shippingState = JSON.parse(localStorage.getItem("shippingState"));
    const previousProperties =
      shippingState?.businessStreetAddress?.place?.properties;
    let objValue = {
      place: {
        properties: {
          country: properties?.country,
          countryCode: properties?.country,
          state: properties?.state,
          stateCode: properties?.stateCode,
          street: properties?.street,
          city: properties?.city,
          postalCode: properties?.postalCode,
          propertyType: properties?.propertyType,
        },
      },
      displayString: businessSaStreetAddressObj?.displayString,
      name: businessSaStreetAddressObj?.name,
    };
    if (name === "street") {
      objValue.place.properties.street = value?.length
        ? value
        : properties?.street;
      objValue.place.properties.city = cityValue?.length
        ? cityValue
        : properties?.city;
      objValue.place.properties.stateCode = stateValue?.length
        ? stateValue
        : properties?.stateCode;
      objValue.place.properties.postalCode = zipValue?.length
        ? zipValue
        : properties?.postalCode;
      objValue.place.properties.propertyType = propertyType?.length
        ? propertyType
        : properties?.propertyType;
      setStreetValue(value);
      if (!value) {
        setErrors({
          ...errors,
          street: aemContent?.errors?.addrInvalid,
        });
      } else {
        if (previousProperties?.street === value) {
          setErrors({
            ...errors,
            street: aemContent?.errors?.addrInvalid,
          });
        }
      }
      setAddresLineOne(value);
    } else if (name === "city") {
      objValue.place.properties.street = streetValue?.length
        ? streetValue
        : properties?.street;
      objValue.place.properties.city = value?.length ? value : properties?.city;
      objValue.place.properties.stateCode = stateValue?.length
        ? stateValue
        : properties?.stateCode;
      objValue.place.properties.postalCode = zipValue?.length
        ? zipValue
        : properties?.postalCode;
      objValue.place.properties.propertyType = propertyType?.length
        ? propertyType
        : properties?.propertyType;
      setCityValue(value);
      const PATTERN_STATE = /^[a-zA-Z ]+$/.test(value);

      if (!value) {
        setErrors({
          ...errors,
          city: aemContent?.errors?.cityInvalid,
        });
      } else if (!PATTERN_STATE) {
        setErrors({
          ...errors,
          city: aemContent?.errors?.cityInvalid,
        });
      } else {
        setErrors({
          ...errors,
          city: null,
        });
      }
    } else if (name === "state") {
      objValue.place.properties.street = streetValue?.length
        ? streetValue
        : properties?.street;
      objValue.place.properties.city = cityValue?.length
        ? cityValue
        : properties?.city;
      objValue.place.properties.stateCode = value?.length
        ? value
        : properties?.stateCode;
      objValue.place.properties.postalCode = zipValue?.length
        ? zipValue
        : properties?.postalCode;
      objValue.place.properties.propertyType = propertyType?.length
        ? propertyType
        : properties?.propertyType;
      const PATTERN_STATE = /^[a-zA-Z]+$/.test(value);
      if (value?.length > 2) {
        return;
      }
      setStateValue(value);
      if (!value) {
        setErrors({
          ...errors,
          state: aemContent?.errors?.stateReqd,
        });
      } else if (!PATTERN_STATE) {
        setErrors({
          ...errors,
          state: aemContent?.errors?.stateReqd,
        });
      } else {
        setErrors({
          ...errors,
          state: null,
        });
      }
    } else if (name === "zipCode") {
      objValue.place.properties.street = streetValue?.length
        ? streetValue
        : properties?.street;
      objValue.place.properties.city = cityValue?.length
        ? cityValue
        : properties?.city;
      objValue.place.properties.stateCode = stateValue?.length
        ? stateValue
        : properties?.stateCode;
      objValue.place.properties.postalCode = value?.length
        ? value
        : properties?.postalCode;
      objValue.place.properties.propertyType = propertyType?.length
        ? propertyType
        : properties?.propertyType;
      const PATTERN_ZIPCODE = /^\d{5}(-\d{4})?$/.test(value);
      if (value?.length > 9) {
        return;
      }
      setZipValue(value);
      if (!value) {
        setErrors({
          ...errors,
          zipCode: aemContent?.errors?.zipInvalid,
        });
      } else if (!PATTERN_ZIPCODE) {
        setErrors({
          ...errors,
          zipCode: aemContent?.errors?.zipInvalid,
        });
      } else {
        setErrors({
          ...errors,
          zipCode: null,
        });
      }
    }
    dispatch({
      type: CONSTANTS.SET_BUSINESS_STREET_ADDRESS,
      payload: objValue,
    });
  };

  const hasErrors = () => {
    let error = false;
    for (const key of Object.keys(errors)) {
      if (errors[key] && errors[key]?.length > 0) {
        error = true;
      }
    }
    if (
      !state?.businessStreetAddress?.displayString ||
      !addressLineOne?.length ||
      !streetValue?.length ||
      !cityValue?.length ||
      !stateValue?.length ||
      !zipValue?.length
    ) {
      error = true;
    }
    if (
      !state?.businessStreetAddress?.displayString &&
      (streetValue || addressLineOne) &&
      cityValue?.length > 2 &&
      stateValue?.length > 1 &&
      zipValue?.length > 4
    ) {
      error = false;
    }
    return error;
  };

  const handleCompanyInfo = () => {
    setCityValue(state?.businessStreetAddress?.place?.properties?.city);
    setStateValue(state?.businessStreetAddress?.place?.properties?.state);
    setZipValue(state?.businessStreetAddress?.place?.properties?.postalCode);
  };

  const getDisplayName = () => {
    let displayName = "";
    if (oldProperties?.name) {
      displayName = displayName + " " + oldProperties?.name;
    } else if (oldProperties?.street) {
      displayName = displayName + " " + oldProperties?.street;
    }
    if (oldProperties?.city) {
      displayName = displayName + " " + oldProperties?.city;
    }
    if (oldProperties?.state) {
      displayName = displayName + " " + oldProperties?.state;
    } else if (oldProperties?.stateCode) {
      displayName = displayName + " " + oldProperties?.stateCode;
    }
    if (oldProperties?.postalCode) {
      displayName = displayName + " " + oldProperties?.postalCode;
    }
    if (fromShoppingCart) {
      displayName = smartWatchAemContent["description"];
    }
    return displayName;
  };

  return (
    <Modal
      ariaLabel=""
      isMobile={isMobile}
      onOpenedChange={setAddressConfirmationModal}
      opened={opened}
      disableOutsideClick={true}
    >
      <ModalTitle>
        {fromShoppingCart
          ? smartWatchAemContent["heading1"]
          : fromBusinessAddress
            ? aemContent?.businessAddressHeading
            : inputDisabled
              ? aemContent?.heading
              : aemContent?.heading1}
      </ModalTitle>
      <ModalBody>
        <StyledContainer>
          {isAPIError && addressLineOne?.length && (
            <div style={{ marginBottom: "1rem" }}>
              <Notification
                type="error"
                title={isAPIErrorMessage ? smartWatchAemContent["errors"]["addrInvalid"] : smartWatchAemContent["errors"]["sww"]}
                fullBleed={true}
                inlineTreatment={false}
                className="notification"
              />
            </div>
          )}

          {inputDisabled ? (
            <Body size="large">
              {getDisplayName()}
              <span style={{ marginLeft: "1rem" }}>
                <TextLink onClick={() => setInputDisabled(false)}>
                  {aemContent?.edit}
                </TextLink>
              </span>
            </Body>
          ) : (
            <InputContainer>
              {fromBusinessAddress ? (
                <>
                  <Body size="large">{aemContent?.addressNotFound}</Body>
                  <MarginSpacerM />
                  <Body size="large" bold={!showErrNotification}>{displayAddress?.street || businessRawAddress}</Body>
                  <Body size="large" bold={!showErrNotification}>{addressLine2}</Body>
                  <Body size="large" bold={!showErrNotification}>{displayAddress?.city} {displayAddress?.state} {displayAddress?.postalCode}</Body>
                </>
              ) :
                <Body size="large">{getDisplayName()}</Body>
              }

              <MarginSpacerL />
              {showErrNotification &&
                <Notification
                  type="error"
                  title={aemContent.notificationErrTitle}
                  subtitle={aemContent.notificationErrSubTitle}
                  fullBleed={true}
                  inlineTreatment={false}
                />}
              <InputContainer>
                <div className="form-col-acm">
                  {!showErrNotification && <Body size="large">{aemContent?.reEnterInfo}</Body>}
                  <MarginSpacerM />
                   <Body size="large">{aemContent?.requiredFieldLabel}</Body>
                  <MarginSpacerM />
                  {fromBusinessAddress ?
                    <Input
                      type="text"
                      name="Street address"
                      label={
                        fromBusinessAddress
                          ? aemContent?.labels?.street?.label
                          : aemContent?.labels?.addrLine1?.label}
                      error={!!errors?.street}
                      errorText={errors?.street || aemContent?.errors?.addrInvalid}
                      required={true}
                      placeholder={aemContent?.labels?.street?.placeholder}
                      helperTextPlacement="bottom"
                      // helperText={aemContent?.labels?.street?.helperText}
                      value={addressLineOne}
                      onChange={(e) => handleAddressInput(e, "street")}
                    /> :
                    <MapQStreetAddress
                      fromShoppingCart={fromShoppingCart}
                      errorMessage={(isAPIError && addressLineOne?.length > 0) ? smartWatchAemContent.addNotFound : errors?.businessStreetAddress}
                      label={
                        fromBusinessAddress
                          ? aemContent?.labels?.street?.label
                          : aemContent?.labels?.addrLine1?.label
                      }
                      onSelectionChange={handleAddressSelection}
                      handleAddressInput={(e) => handleAddressInput(e, "street")}
                      isAddressEmty={true}
                      isError={errors?.businessStreetAddress || (isAPIError && addressLineOne?.length > 0)}
                      setAddressFieldError={(value) => {
                        setErrors({ ...errors, businessStreetAddress: value });
                        dispatch({
                          type: CONSTANTS.SET_SHIPPING_ERRORS,
                          payload: { ...errors, businessStreetAddress: value },
                        });
                      }}
                      ref={businessStreetRef}
                      isNameOnly
                      addressLineOne={addressLineOne || ""}
                      isVerifyBussiness={true}
                      placeHolder={aemContent?.labels?.street?.placeholder}
                    // helperText={aemContent?.labels?.street?.helperText}
                    />
                  }
                </div>
              </InputContainer>
              <FlexRow>
                <InputContainer>
                  <div className="form-col-acm">
                    <StreetInputDiv>
                      <div
                        className={
                          businessUnit === unitDefaultValue
                            ? "placholder-text"
                            : ""
                        }
                      >
                        <Input
                          type="text"
                          label={
                            fromBusinessAddress
                              ? aemContent?.labels?.unit?.label
                              : aemContent?.labels?.addrLine2?.label
                          }
                          placeholder={aemContent?.labels?.addrLine2?.placeholder}
                          helperTextPlacement="bottom"
                          maxLength={20}
                          // helperText={aemContent?.labels?.addrLine2?.helperText}
                          required={false}
                          onChange={(e) => validateUnitFiled(e.target.value)}
                          value={businessUnit}
                          error={unitError?.length > 0}
	                        errorText={unitErrorMessage || ''}
                          onFocus={() => {
                            if (businessUnit === unitDefaultValue) {
                              dispatch({
                                type: CONSTANTS.SET_BUSINESS_STREET_ADDRESS2,
                                payload: "",
                              });
                              setBusinessUnit("");
                            }
                          }}
                        />
                      </div>
                    </StreetInputDiv>
                  </div>
                </InputContainer>
              </FlexRow>
              <InputContainer>
                <div className="form-col-acm">
                  <Input
                    type="text"
                    name="city"
                    label={aemContent?.labels?.city?.label}
                    placeholder={aemContent?.labels?.city?.placeholder}
                    error={!!errors?.city}
                    errorText={errors?.city || aemContent?.errors?.cityInvalid}
                    required={true}
                    value={cityValue}
                    onChange={(e) => handleAddressInput(e, "city")}
                  />
                </div>
              </InputContainer>
              <FlexRow>
                <div className="form-col-acm state">
                  {/* <Input
                    type="text"
                    name="state"
                    label={aemContent?.labels?.state?.label}
                    placeholder={aemContent?.labels?.state?.placeholder}
                    error={!!errors?.state}
                    errorText={errors?.state}
                    required={true}
                    value={stateValue}
                    onChange={(e) => handleAddressInput(e, "state")}
                  /> */}

                  <DropdownSelect
                    label={aemContent?.labels?.state?.label}
                    name="state"
                    onChange={(e) => handleAddressInput(e, "state")}
                    value={stateValue}
                    required={true}
                    error={!!errors?.state}
                    errorText={errors?.state}
                  >
                    {stateList?.map((option) => {
                      return (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      );
                    })}
                  </DropdownSelect>
                </div>
                <div className="form-col-acm">
                  <Input
                    type="text"
                    name="zipCode"
                    maxLength={5}
                    label={aemContent?.labels?.zipcode?.label}
                    placeholder={aemContent?.labels?.zipcode?.placeholder}
                    error={!!errors?.zipCode}
                    errorText={errors?.zipCode || aemContent?.errors?.zipInvalid}
                    required={true}
                    value={zipValue}
                    onChange={(e) => handleAddressInput(e, "zipCode")}
                  />
                </div>
              </FlexRow>
            </InputContainer>
          )}
        </StyledContainer>
        <MarginSpacerL />
        {!inputDisabled && (
          <Button
            disabled={!fromBusinessAddress && hasErrors()}
            onClick={() => {
              const payloadForShoppingAddress = {
                addressLine1: addressLineOne || streetValue,
                addressLine2: businessUnit || "",
                city: cityValue,
                state: stateValue,
                zipCode: zipValue,
              };
              if (fromBusinessAddress) {
                if (hasErrors()) {
                  setErrors({
                    ...errors,
                    city: !cityValue?.length ? aemContent?.errors?.cityInvalid : null,
                    state: !stateValue?.length ? aemContent?.errors?.stateReqd : null,
                    zipCode: !zipValue?.length ? aemContent?.errors?.zipInvalid : null,
                    street: !(addressLineOne || streetValue) ? aemContent?.errors?.addrInvalid : null,
                  });
                  setCtaClicked(true);
                }
                else {
                  validateBusinessAddress(payloadForShoppingAddress, businessUnit);
                  let businessAddress = Object.assign({}, state?.businessStreetAddress);
                  if (!businessAddress?.displayString) {
                    businessAddress.displayString = `${streetValue || addressLineOne || ""}, ${cityValue || ""}, ${stateValue || ""}, ${zipValue || ""}`;
                  }
                  handleAddressSelect(businessAddress);
                }
              } else {
                if (!fromShoppingCart) {
                  setShippingState(state);
                }
                continueClick(
                  fromShoppingCart ? payloadForShoppingAddress : state,
                  true,
                  state,
                );
              }
            }}
            data-track={fromBusinessAddress
              ? aemContent?.continueBtn
              : aemContent?.confirmBtn}
          >
            {fromBusinessAddress
              ? aemContent?.continueBtn
              : aemContent?.confirmBtn}
          </Button>
        )}
      </ModalBody>
    </Modal>
  );
};

const StyledContainer = styled.div`
  .form-col-acm {
    margin: 0.5rem 1rem 0.5rem 0;
  }
  .state{
    width: 50%;
  }
  @media only screen and (max-width: 545px) {
    .state{
      width : 95%;
    }
  }
  @media (max-width: 767px) {
    .form-col-acm {
      margin: 1rem 1rem 0.5rem 0;
    }
  }
`;
const StreetInputDiv = styled.div`
position: relative;
width: 100%;
.placholder-text input{
    color: #d3d3d3;
}
`;
const InputContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  
  @media only screen and (max-width: 545px) {
    flex-direction: column;
    width: 100%;
  }
`;

export default AddressVerificationModal;
