import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DropdownSelect } from "@vds3/selects";
import { Body, Title } from "@vds3/typography";
import { TextLink, Button } from "@vds3/buttons";
import {
  MarginSpacerL,
  MarginSpacerM,
  MarginSpacerS,
  MarginSpacerXL,
  MarginTopSpacerM,
} from "common/components/styleTag";
import { getDescriptionInfo } from "./../../ItemDescription";
import {
  LeftSection,
  PackageInfoContainer,
  PackageEyebrowText,
  ByodContainer,
  ByorImg,
  ByorSpan,
} from "./styles";
import PackageImage from "./PackageImage";
import PackageDetail from "./PackageDetail";
import CartEditLinks from "../CartEditLinks/CartEditLinks";
import CartPkgContext from "./cartPkgContext";
import PackageEquipmentDetail from "./PackageEquipmentDetail";
import BulkByorQuantityModal from "../Modals/BulkByorQuantiyModal";
import {
  LineItem,
  LineItemPriceGrid,
} from "../LineItemPriceGrid/LineItemPriceGrid";
import PackageByorMwwDetail from "./PackageByorMwwDetail";
import { SeparatorLine, LeftDivSection } from "./styles";
import CartContext from "pages/ShoppingCart/cartContext";
import Google from '../../../../assets/images/google-logo.png'
import { Input } from "@vds/inputs";

const PackageInfo = ({
  isEditable,
  onEditClick,
  onRemoveClick,
  onQuantityChange,
  byodFromDQ,
  enableMobilityRemove,
  cartData,
  starkEnabled,
  ...props
}) => {
  const {
    accessoriesCount,
    allowedAccessoriesCount,
    backupPlanData,
    disabledQty,
    equipment,
    isMMW,
    isMobile,
    onOwnSimSelection,
    pack,
    primaryPlanData,
    quantity,
    setSelectedImei,
    isDigitalQuote,
    setbyodPack,
    oneTalkFlowAdded
  } = React.useContext(CartPkgContext);
  const {
    setMaximumLimitReachedAccessories,
  } = React.useContext(CartContext);
  const { t } = useTranslation(["shoppingCart", "app"]);
  const bussIcons = t("shopping-cart.bussProduct.iconUrls", {
		ns: "shoppingCart",
		returnObjects: true,
	  });
  const MAX_QUANTITY = 9;
  // const isQuantityEnabled = ((pack?.packageType !== "FIVEG" || pack?.packageType === "FIVEG") && !pack?.byod) || (pack?.byor || (!cartData?.cart?.accessories && cartData?.cart?.mwwCart) );
  const isFWA = pack?.packageType === "FIVEG" || pack?.packageType === "FOURG";
  const descriptionInfo = getDescriptionInfo(pack);
  const equipmentInfo = equipment?.length && getDescriptionInfo(equipment[0]);
  const brand = pack?.brand || descriptionInfo?.brand;
  const [showByorModal, setShowByorModal] = useState(false);
  const [qtySelected, setQtySelected] = useState("");
  const [quantitySelected,setQuantitySelected] = useState(quantity)
  const hideQty = byodFromDQ || (pack?.packageType === "REGULAR" && pack.byod);
  const shippingstartDate = pack?.shipmentDate;
  const backOrderStartDate = pack?.backOrderStartDate;
  const shippingstartDateDC = new Date(shippingstartDate).toString();
  const backOrderStartDateDC = new Date(backOrderStartDate).toString();
  let upatedDate = shippingstartDateDC.split(" ");
  let updatedbackorderstartdate = backOrderStartDateDC.split(" ");
  let fullDate = `${upatedDate[0]}, ${shippingstartDate}`;
  let fullDatebo = `${updatedbackorderstartdate[0]}, ${backOrderStartDate}`;
  const isLtePlusWithDQ = ((pack?.plan?.planType == "UNCAPPED" && isDigitalQuote) || (oneTalkFlowAdded&& pack.packageType === 'ONETALK'));
  const checkAccessory =
    cartData?.cart?.accessories && (pack?.packageType !== "REGULAR" && pack?.packageType !== "ONETALK")
      ? true
      : false;

    useEffect(() => {
      if(checkAccessory && pack?.accessoryId) {
        if(allowedAccessoriesCount > 0 && pack?.quantity >= allowedAccessoriesCount){
          setMaximumLimitReachedAccessories(true)
        } else {
          setMaximumLimitReachedAccessories(false)
        }
      }
    }, [pack]);

  const eyebrowText = useMemo(() => {
    let text = "";
    if (pack?.preOrder) {
      text = `${t(
        "shopping-cart.cart-devices.pre-order"
      )} ${shippingstartDate}`;
    } else if (pack?.backOrder) {
      text = `${t("backOrder.dtBtwnMsg", { ns: "app" })
        .replace("{{backordershippingdate}}", fullDate)
        .replace("{{backordershippingstartdate}}", fullDatebo)}`;
    } else if (
      pack?.deviceType === "FIVEGRECEIVER" &&
      !!isMMW &&
      equipment?.length
    ) {
      text = `${t("5g.shopping-cart.installationRequired")}`;
    }
    return text;
  }, [pack]);

  const getDisplayName = () => {
    if (pack?.byor && pack?.deviceType !== "FIVEGRECEIVER") {
      return "Use your own router";
    } else if (byodFromDQ) {
      let count = pack?.productName && pack?.productName?.match(/\d+/)?.[0];
      if(pack?.productName && pack?.productName?.includes("Netbook")) {
        return `Tablet ${count}`
      }
      if(pack?.productName && pack?.productName?.includes("Internet")) {
        return `Hotspot ${count}`
      }
      return `${pack?.productName ?? ""}`;
    } else {
      return `${brand ?? ""} ${pack?.buss ? "" : pack?.productName ?? ""}`;
    }
  };

  const getDisplayImage = () => {
    if (equipment?.length) {
      return <PackageImage description={equipmentInfo} />;
    } else {
      return <PackageImage pack={pack} description={descriptionInfo} />;
    }
  };

  const getRecieverImage = () => {
    const tempPack = JSON.parse(JSON.stringify(pack));
    tempPack.byor = false;
    tempPack.byod = false;
    return <PackageImage pack={tempPack} description={descriptionInfo} />;
  };

  const getRemaingAllowedQuantity = (pack) => {
    const accessories = cartData?.cart?.accessories;
    let usedQty = 0;
    if (accessories && Object.keys(accessories).length > 0) {
      for (const key of Object.keys(accessories)) {
        if (key != pack?.accessoryId) {
          usedQty += accessories[key].quantity;
        }
      }
    }
    return usedQty;
  }

  const onByorModal = (qty) => {
    setQtySelected(qty);
    setShowByorModal(true);
  };
  const getBussImage = ()=>{
      const selectedBrand = (String(pack.brand).toLowerCase())
    if(selectedBrand){
      return(<img
      src={process.env.PROSPECT_WIDGETS_APP_ASSESTS_BASE_URL + `${bussIcons[selectedBrand?.toUpperCase()?.trim()]}`}
      alt={`${pack.brand}`}
      width={100}
      height={100}
    />)
      }else return <></>
  }

  return (
    <LeftDivSection>
      {cartData?.cart?.mwwCart ? (
        <LeftSection>
          {pack?.byod &&
            cartData?.cart?.mwwCart &&
            pack.packageType === "FIVEG" &&
            pack?.deviceType === "FIVEGRECEIVER" ? (
            <ByodContainer width={100} height={100}>
              <ByorImg />
              <ByorSpan />
            </ByodContainer>
          ) : (
            getDisplayImage()
          )}
          <PackageInfoContainer>
            {!!eyebrowText && (
              <PackageEyebrowText>
                <Body size="small">{eyebrowText}</Body>
              </PackageEyebrowText>
            )}
            {pack?.byod &&
              pack.packageType === "FIVEG" &&
              pack?.deviceType === "FIVEGRECEIVER" && (
                <>
                  <PackageByorMwwDetail />
                </>
              )}
            {!pack?.byod &&
              !!equipment?.length &&
              equipment.map((equip) => (
                <PackageEquipmentDetail equipment={equip} starkEnabled={starkEnabled}/>
              ))}
          </PackageInfoContainer>
        </LeftSection>
      ) : (
        <></>
      )}
      {/*   specific to mmw flow changes to display image and description ends here */}

      <LeftSection>
        {/* {cartData?.cart?.mwwCart ? <MarginSpacerXL /> : <MarginSpacerS />} */}
        {cartData?.cart?.mwwCart ? getRecieverImage() : getDisplayImage()}
        {pack?.buss ? getBussImage() : <></>}
        {showByorModal && (
          <BulkByorQuantityModal
            opened={showByorModal}
            onOpenedChange={setShowByorModal}
            qtySelected={qtySelected}
            onQuantityChange={onQuantityChange}
          />
        )}

        <PackageInfoContainer>
          {!!eyebrowText && !cartData?.cart?.mwwCart && (
            <PackageEyebrowText>
              <Body size="small">{eyebrowText}</Body>
            </PackageEyebrowText>
          )}
          {!!equipment?.length &&
            !cartData?.cart?.mwwCart &&
            equipment.map((equip) => (
              <PackageEquipmentDetail equipment={equip} starkEnabled={starkEnabled}/>
            ))}
          <MarginSpacerS />
          {cartData?.cart?.mwwCart && pack.byor && <MarginSpacerXL />}
          <Title size="medium" bold>
            {getDisplayName()}
          </Title>
          {pack?.byod ? (
            <MarginSpacerS />
          ) : (
            <PackageDescription pack={pack} description={descriptionInfo} />
          )}
          {pack?.buss ? <></> : 
          <PackageDetail cartData={cartData} />}

          {enableMobilityRemove && byodFromDQ && (
            <>
              <MarginSpacerM />
              <Button
                use="secondary"
                style={{ height: "38px" }}
                width={isMobile ? "100%" : "70%"}
                onClick={() => onEditClick(pack, pack?.packageId)}
              >
                {t("shopping-cart.cart-byod.addDeviceCta")}
              </Button>
              <MarginSpacerS />
            </>
          )}
          {pack?.byod &&
            (pack?.psimCapable === "Y" || pack?.esimCapable === "Y") &&
            !isFWA &&
            pack?.imei && (
              <>
                <MarginSpacerS />
                <LineItemPriceGrid title={t("shopping-cart.cart-byod.imei")}>
                  <LineItem displayName={pack.imei} />
                </LineItemPriceGrid>
              </>
            )}
          {pack?.byod && pack?.psimCapable === "Y" && !isFWA && (
            <>
              <MarginSpacerM />
              <LineItemPriceGrid
                title={`${t("shopping-cart.cart-deviceitem.simType")}${t(
                  "shopping-cart.cart-deviceitem.physicalSim"
                )}`}
                byod={pack?.byod}
              >
                {(pack?.simNumber && isEditable) ? (
                  <LineItem displayName={pack.simNumber} />
                ) : (
                  <>
                    <LineItem
                      displayName={t("shopping-cart.cart-byod.newSim")}
                      price={t("shopping-cart.cart-byod.zeroDollar")}
                    />
                    <MarginTopSpacerM>
                        <TextLink
                          type="standAlone"
                          size="large"
                          onClick={(e) => {
                            setSelectedImei(pack?.imei);
                            setbyodPack(pack);
                            onOwnSimSelection(pack?.simNumber);
                          }}
                        >
                          {t("shopping-cart.cart-byod.useOwnSim")}
                        </TextLink>
                      </MarginTopSpacerM>
                  </>
                )}
              </LineItemPriceGrid>
            </>
          )}
          {pack?.byod && pack?.esimCapable === "Y" && !isFWA && (
            <>
              <MarginSpacerM />
              <LineItemPriceGrid byod={pack?.byod}>
                <LineItem
                  subTextSize="small"
                  subText={`${t("shopping-cart.cart-deviceitem.simType")}${t(
                    "shopping-cart.cart-deviceitem.embeddedSim"
                  )}`}
                  price={t("shopping-cart.cart-byod.zeroDollar")}
                />
              </LineItemPriceGrid>
            </>
          )}
          {/* BYOD end */}
          {!!isEditable && (
            <>
              <MarginSpacerM />
              <CartEditLinks
                isEditable={isEditable}
                onEditClick={() => onEditClick(pack, pack?.packageId)}
                onRemoveClick={() => onRemoveClick(pack)}
                cartData={cartData}
                quantity={quantity}
              />
            </>
          )}
          <>
              <MarginSpacerL />
              <Input
              width={"82px"}
              className="numLines-dropdownSelect"
              maxLength={3}
              onChange={(e) => setQuantitySelected(Number(e.target.value))}
              onBlur = {()=>onQuantityChange(quantitySelected)}
              value={quantitySelected}>
              </Input>
            </>
          {false && !hideQty && (
            <>
              <MarginSpacerL />
              <DropdownSelect
                label={t("shopping-cart.quantity")}
                disabled={true || (isLtePlusWithDQ? isLtePlusWithDQ : pack?.outOfStock ? pack?.outOfStock : !disabledQty)}
                error={false}
                width="90px"
                onChange={(e) =>
                  pack?.byor
                    ? onByorModal(e.target.value)
                    : onQuantityChange(e.target.value)
                }
                value={quantity}
              >
                <>
                  {Array.apply(
                    0,
                    Array(
                      (checkAccessory && allowedAccessoriesCount)
                        ? (pack?.quantity > allowedAccessoriesCount ? pack?.quantity : allowedAccessoriesCount) - getRemaingAllowedQuantity(pack) ?? "0"
                        : !pack?.backUpPlanPresent
                          ? MAX_QUANTITY - Number(backupPlanData?.quantity ?? "0")
                          : MAX_QUANTITY -
                          Number(primaryPlanData?.quantity ?? "0")
                    )
                  ).map((_, b) => (
                    (oneTalkFlowAdded&& pack.packageType === 'ONETALK') ? <option key={0}>{""}</option>:
                    <option key={b+1}>{b+1}</option>
                  ))}
                  {pack?.packageType !== "FOURG" && !pack?.accessoryId && (
                    <option
                      key={
                        (checkAccessory && allowedAccessoriesCount)
                          ? allowedAccessoriesCount
                          : MAX_QUANTITY + 1
                      }
                    >{`${(checkAccessory && allowedAccessoriesCount)
                        ? allowedAccessoriesCount
                        : MAX_QUANTITY + 1
                      }${checkAccessory ? "" : "+"}`}</option>
                  )}
                </>
              </DropdownSelect>

              {checkAccessory && allowedAccessoriesCount > 0 && pack?.quantity  >= allowedAccessoriesCount && (
                <div style={{ margin: "0.25rem 0" }}>
                  <Body size="medium">
                    {t("shopping-cart.cart-accessory-item.maximum", {
                      maxQtyNew: allowedAccessoriesCount,
                      ns: "shoppingCart",
                    })}</Body></div>
              )}
            </>
          )}
          <>{isMobile && <SeparatorLine type="secondary" />}</>
        </PackageInfoContainer>
      </LeftSection>
    </LeftDivSection>
  );
};

const PackageDescription = ({ pack, description }) => {
  const showDescription = pack?.packageType === "REGULAR" || pack?.accessoryId;
  const color = pack?.color ?? description?.color;
  if (showDescription && (color || pack?.capacity)) {
    return (
      <div style={{ margin: "0.25rem 0" }}>
        <Body size="large">{`${pack?.capacity ?? ""}${pack?.capacity && color ? " " : ""
          }${color}`}</Body>
      </div>
    );
  } else {
    return <MarginSpacerS />;
  }
};
export default PackageInfo;
