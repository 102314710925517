import { createSlice, createAction, createSelector } from "@reduxjs/toolkit";

const initialState = {
  apiError: false,
  byor: {},
  initialLoad: false,
  loading: false,
  imeiCheckAttempt: 0,
  primaryPlan: {
    routerDetails: {},
    selectedPlan: "",
    selectedRouter: "",
    selectedRouterPayment: "",
    selectedReceiver:"",
  },
  isEdit5GEquipmentFlow: false,
  isEdit5GPrimaryPlansFlow: false,
  htmlTemplateFcc:'',
  planDetailsFcc: {},
  taxesCharges : {},
  storeFCCDetails: {},
  toggleStatusForCBandRouter:{},
  showInternetMobilePlanFiveG:false,
  updatedPlanRouterResp:{}
};

const Landing5GSlice = createSlice({
  name: "Landing5G",
  initialState,
  reducers: {
    reset5GState: () => {
      return initialState;
    },
    setSelectedPlan: (state, action) => {
      state[action.payload.planType]["selectedPlan"] = action.payload.value;
    },
    setSelectedRouter: (state, action) => {
      state[action.payload.planType]["selectedRouter"] = action.payload.value;
    },
    setSelectedReceiver: (state, action) => {
      state[action.payload.planType]["selectedReceiver"] = action.payload.value;
    },
    setRouterDeviceResp: (state, action) => {
      state[action.payload.planType]["routerDetails"] = action.payload.response;
    },
    setSelectedRouterPayment: (state, action) => {
      state[action.payload.planType]["selectedRouterPayment"] =
        action.payload.value;
    },
    set5GLandingPageLoading: (state, action) => {
      state["loading"] = action.payload;
    },
    setPlansDetails: (state, action) => {
      state["planDetails"] = action.payload;
    },
    setRouterUpdateResp: (state, action) => {
      state["routerUpdateResp"] = action.payload;
    },
    setValidateDeviceIdResp: (state, action) => {
      state["byor"]["validateDeviceIdResp"] = action.payload;
    },
    setValidateSimIdResp: (state, action) => {
      state["byor"]["validateSimIdResp"] = action.payload;
    },
    setDeviceId: (state, action) => {
      state["byor"]["deviceId"] = action.payload;
    },
    setSimNumber: (state, action) => {
      state["byor"]["simNumber"] = action.payload;
    },
    setImeiCheckAttempt: (state, action) => {
      state["imeiCheckAttempt"] = action.payload;
    },
    setAPIError: (state, action) => {
      state["apiError"] = action.payload;
    },
    setInitialLoad: (state, action) => {
      state["initialLoad"] = action.payload;
    },
    setPlanPreselection: (state, action) => {
      state["planPreselection"] = action.payload;
    },
    setAuthCartSuccess: (state, action) => {
      state["authCartSuccess"] = action.payload;
    },
    setIsEdit5GEquipmentFlow: (state, action) => {
      state["isEdit5GEquipmentFlow"] = action.payload;
    },
    setIsEdit5GPrimaryPlansFlow: (state, action) => {
      state["isEdit5GPrimaryPlansFlow"] = action.payload;
    },
    sethtmlTemplateFcc:(state, action) => {
      state["htmlTemplateFcc"] = action.payload;
    },
    setPlansDetailsFcc: (state, action) => {
      state["planDetailsFcc"] = action.payload;
    },
    setTaxesCharges: (state, action) => {
      state["taxesCharges"] = action.payload;
    },
    setStoreFCCDetails: (state, action) => {
      state["storeFCCDetails"] = action.payload;
    },
    setToggleForCBandRouter: (state,action) => {
      state["toggleStatusForCBandRouter"] = action.payload;
    },
    setShowInternetMobilePlanFiveG: (state, action) => {
      state["showInternetMobilePlanFiveG"] = action.payload;
    },
    setUpdatedPlanRouterResp: (state, action) => {
      state["updatedPlanRouterResp"] = action.payload;
    },
    setCombinedResponse: (state, action) => {
      state["combinedResponse"] = action.payload;
    },
  },
});

export const reducer = Landing5GSlice.reducer;
export const getFixedWirelessCoverageCheck = createAction(
  "Landing5G/getFixedWirelessCoverageCheck"
);
export const get5gRouterDevices = createAction("Landing5G/get5gRouterDevices");
export const get5gPlans = createAction("Landing5G/get5gPlans");
export const getTaxesCharges = createAction("Landing5G/getTaxesCharges");
export const get5gTabPlans = createAction("Landing5G/get5gTabPlans");
export const update5GPlan = createAction("Landing5G/update5GPlan");
export const update5GRouter = createAction("Landing5G/update5GRouter");
export const update5GADDORUpdate = createAction("Landing5G/update5GADDORUpdate");
export const validateDeviceId = createAction("Landing5G/validateDeviceId");
export const validateSimId = createAction("Landing5G/validateSimId");
export const get5GPageLoadData = createAction("Landing5G/get5GPageLoadData");
export const addToCartFromAuth = createAction("Landing5G/addToCartFromAuth");
export const getPlanPreselection = createAction(
  "Landing5G/getPlanPreselection"
);
export const update5GPlanAndFetchRouters = createAction(
  "Landing5G/update5GPlanAndFetchRouters"
);
export const updateFCCTemplate = createAction("Landing5G/updateFCCTemplate");
export const updateFCCJson = createAction("Landing5G/updateFCCJson");
export const storeFccDetails = createAction("Landing5G/storeFccDetails");
export const getComibnedPlanAndRouters = createAction("Landing5G/getComibnedPlanAndRouters");
export const getPlandAndRouters = createAction("Landing5G/getPlandAndRouters");

export const {
  reset5GState,
  set5GLandingPageLoading,
  setRouterDeviceResp,
  setPlansDetails,
  setRouterUpdateResp,
  setPlanPreselection,
  setInitialLoad,
  setValidateDeviceIdResp,
  setValidateSimIdResp,
  setSelectedPlan,
  setSelectedRouter,
  setSelectedReceiver,
  setSelectedRouterPayment,
  setAPIError,
  setImeiCheckAttempt,
  setAuthCartSuccess,
  setIsEdit5GPrimaryPlansFlow,
  setIsEdit5GEquipmentFlow,
  sethtmlTemplateFcc,
  setPlansDetailsFcc,
  setTaxesCharges,
  setStoreFCCDetails,
  setToggleForCBandRouter,
  setShowInternetMobilePlanFiveG,
  setUpdatedPlanRouterResp,
  setCombinedResponse
} = Landing5GSlice.actions;
