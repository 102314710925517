import React, { useState, forwardRef, useEffect , useImperativeHandle, useRef} from 'react'
import {DropdownInput, UnitsSectionDiv, SuggestionListDiv, UnitTextInput} from '../styledComponents'
import { useDispatch } from 'react-redux';
import useFetchUnits from 'common/components/CustomHooks/useFetchUnits';

const ErrorMessage = 'Please select valid Unit #.';

const MapQUnit = forwardRef ((props, ref) => {
    const dispatch = useDispatch();
    const [selectedUnit, setSelectedUnit] = useState(props?.selectedUnit || null);
    const [unitTerm, setUnitTerm] = useState("");
    const [outSideClick, setOutSideClick] = useState(false);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false)
    const [isInputFocussed, setIsInputFocussed] = useState(false)
    const inputRef = useRef();
    const dropdownRef = useRef();
    let [ unitList, totalUnits, setUnitList, setTotalUnits] = useFetchUnits(props.locusID, props.ntasAddrID, unitTerm , dropdownRef.current, 
         () =>{
            // if(totalUnits && parseInt(totalUnits) > 10 && inputRef?.current) { 
            //     inputRef.current.getElementsByTagName("input")[0].focus()
            // } else if( totalUnits && dropdownRef?.current && parseInt(totalUnits) > 0) {
            //     dropdownRef.current.getElementsByTagName("input")[0].focus();
            // }
        },
        (respUnits) => {
            if(parseInt(respUnits) === 0) {
                props.setTotalAvailableUnits(respUnits);
            }
        }
    );

    const isFocussed = () => {
        let currentElement = inputRef.current || dropdownRef.current;
         const inputState =  currentElement ?   currentElement.getElementsByTagName("input")[0] === document.activeElement : false;
         if(inputState!=isInputFocussed) setIsInputFocussed(inputState)
         return inputState;
    }

    // useImperativeHandle(
    //     ref,
    //     () => ({
    //         clearData() {
    //             setSelectedUnit(null);
    //             setUnitTerm("");
    //             setOutSideClick(false);
    //             setIsSuccess(false);
    //         },
    //         setFocus() {
    //             if(parseInt(totalUnits) > 10 && inputRef.current && inputRef.current.getElementsByTagName) { 
    //                 inputRef.current.getElementsByTagName("input")[0].focus()
    //             } else if(dropdownRef.current && dropdownRef.current.getElementsByTagName) {
    //                 dropdownRef.current.getElementsByTagName("input")[0].focus()
    //             }
    //         }
    //     }),
    // )

    useEffect(()=> {
        setTotalUnits(0);
        setUnitList([]);
        props.onSelectionChange(null);
    },[props.locusID,props.ntasAddrID])


    useEffect(()=>{ 
        if(parseInt(totalUnits) > 10 && inputRef?.current && inputRef?.current?.getElementsByTagName) { 
            inputRef.current.getElementsByTagName("input")[0]?.focus();
            setToggleDropdown(true)
        } else if(dropdownRef?.current && dropdownRef?.current?.getElementsByTagName) {
            dropdownRef?.current?.getElementsByTagName("input")[0].focus();
            setToggleDropdown(true)
        }
        window.lqWidgetTotalUnits = parseInt(totalUnits)
    }, [totalUnits])

    const selectThisUnit = (unit, eventType) => {
      
        // props.disableCheckAvailability();
        const addressFound  = matchUnitAddress(unit);
        if(!addressFound) {
            props.onSelectionChange(null);
            setSelectedUnit(null)
            setUnitTerm(unit?.unit)
            setOutSideClick(false)
            setToggleDropdown(false)
            setIsSuccess(false)
        } 
    }
    
    const matchUnitAddress = (unitObj) => {
        let unitFound = false;
        const {unit} = unitObj;
        if(unitList && unit) {
            unitList.map((value, key) => { 
                if(formatString(value.unit) == formatString(unit)) { 
                    props.onSelectionChange(value);
                    unitFound = true;
                    setSelectedUnit(value)
                    setUnitTerm(value?.unit)
                    setOutSideClick(true)
                    setToggleDropdown(false)
                    setIsSuccess(true)
                }
            });
        }
        return unitFound;
    }
    
    const formatString = str => {
      return str ? str.replace(/\s/g, '').replace(/,/g, '').toLowerCase().trim() : "";
    }
    
    
    const selectThisUnitOnClick = (unitObj) => {
        setSelectedUnit(unitObj)
        setUnitTerm(unitObj?.unit)
        setOutSideClick(true)
        setToggleDropdown(false)
        setIsSuccess(true)
        props.onSelectionChange(unitObj);
    }
    
      const renderInputList = () => {
        let tempUnitList = Array.from(unitList);
        let showSuggestions = false;
 
        showSuggestions =   isFocussed() && props?.locusID ;
        const unitSuggestionList = (showSuggestions) && !selectedUnit ?
       (tempUnitList && tempUnitList.length >0  && 
            <SuggestionListDiv>
               {tempUnitList.map((unit, key) => {
                    return (
                        <div className="suggestion-list-item" key={tempUnitList[key].addressId}>
                            <span onClick={() =>{  
                                 selectThisUnit({ unit: tempUnitList[key].unit },"change");   
                                 setSelectedUnit(tempUnitList[key])
                                 setUnitTerm(tempUnitList[key].unit)
                                 selectThisUnitOnClick(tempUnitList[key])
                                }}>
                                {tempUnitList[key].unit}
                            </span>
                        </div>
                    )
                })} 
            </SuggestionListDiv>) : '';
    
        return (
            <form onSubmit={handleSubmit} autoComplete="off" key={'lq-widget-unit' +  props.isError}>
            <div style={{ position: "relative" }} ref={inputRef} >
                <UnitTextInput type="text" label={props.label}
                 id="unittextaheadsearch" 
                 placeholder="" name="myBrowser" width="100%" 
                //  key={'lq-widget-unit'  + unitTerm+  props.isError}
                onChange={(e) => {
                    setUnitTerm( e.target.value);
                    selectThisUnit({ unit: e.target.value },"change");
                }} 
                onBlur={(e) => {
                    setTimeout(() => {
                        setOutSideClick(true);
                    },1000);
                    }
                } 
                
                errorEvent="change"
                onFocus={() =>{ setOutSideClick(false); setIsInputFocussed(true)}}
                onPaste={ e => {selectThisUnit({ unit: e.target.value }, "paste")}}
                defaultValue={unitTerm}
                value={unitTerm}
                error={props.isError}
                errorText={props.isError ? ErrorMessage : ''}
                success={isSuccess}
                // autoFocus={true}
                />
                <span  key={unitTerm}>{unitSuggestionList}</span>
            </div>
            </form>
        );
    };
    
    const handleSubmit = (e) => {
      e.preventDefault()
    }

    const renderDropdownList = () => {
        const tempUnitList = Array.from(unitList).sort((a,b) => a.unit > b.unit?1:-1);

        return (
            <form onSubmit={handleSubmit} autoComplete="off"  key={'lq-widget-unit'+ props.isError}>
            <div style={{ position: "relative", width: "100%" }}>
                <div>
                    <div className="dropdown-align" ref={dropdownRef} >
                    <DropdownInput width="100%" label={props.label}  placeholder="Select unit#" value={unitTerm}
                        error={props.isError}
                        key={'lq-widget-unit'  + unitTerm+  props.isError}
                        errorText={props.isError ? ErrorMessage : ''} 
                        name="lq-widget-unit"
                        onBlur={(e) => {
                            // setTimeout(() => {
                            //     setToggleDropdown(false)
                            // },1000)
                        }} 
                        success={isSuccess}
                        onChange={(e) => {
                            setToggleDropdown(true)
                            setUnitTerm( e.target.value);
                            selectThisUnit({ unit: e.target.value },"change");
                        }} 
                        disabled={tempUnitList?.length>0?false:true}
                        className={tempUnitList?.length>0?"":"unit-disabled"}
                        onPaste={ e => {selectThisUnit({ unit: e.target.value }, "paste")}}  
                        onFocus={() => {tempUnitList?.length === 1 ? setToggleDropdown(false) : setToggleDropdown(true)}}
                    />
                    {/* <DropdownInput width="100%" label={props.label}  placeholder="Select unit#" value={unitTerm}
                        error={props.isError}
                        key={'lq-widget-unit'  + unitTerm+  props.isError}
                        errorText={props.isError ? ErrorMessage : ''} 
                        name="lq-widget-unit"
                        onBlur={(e) => {
                            // setTimeout(() => {
                            //     setToggleDropdown(false)
                            // },1000)
                        }} 
                        onFocus={() => {setToggleDropdown(true)}}
                        success={isSuccess}
                    /> */}
                    </div>
                </div>
                {toggleDropdown && props?.locusID && 
                    <SuggestionListDiv>
                        {tempUnitList && tempUnitList.map((unit, key) => {
                            return (<div className="suggestion-list-item" key={tempUnitList[key].addressId}  onClick={() => {
                                setUnitTerm( tempUnitList[key]);
                            selectThisUnitOnClick(tempUnitList[key], true)}}>
                                <span key={tempUnitList[key].addressId} value={tempUnitList[key].unit}>
                                    {tempUnitList[key].unit}
                                </span>
                            </div>)
                        })}
                    </SuggestionListDiv>}
            </div>
            </form>
        )
    }
    
    // if((!props.locusID && !props.ntasAddrID) || parseInt(totalUnits) == 0) {
    //     // props.setTotalAvailableUnits(totalUnits);
    //   return null;
    // }
      return (
        <UnitsSectionDiv >
          <div id="lq-widget-unit-inputbox" className="Unit-inputbox" style={{marginTop:0}}>
            {parseInt(totalUnits )> 10 ? renderInputList() : renderDropdownList()}
          </div>
        </UnitsSectionDiv>
      )
    }
);


export default MapQUnit;