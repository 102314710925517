import { useSelector } from "react-redux";
import apiClient from "../utils/apiClient";
import { Ss7DomainWithVerizonWireless } from "../utils/commonUtils";

const useImageUtils = () => {
	const getCarouselImagesetData = (imageSetUrl, imageName = "") => {
		const regExp = /\{[^)]+}/g;
		let imageSet = [];
		const url = process.env.PROSPECT_WIDGETS_APP_IMAGE_CDN_URL_2 + imageSetUrl;
		return apiClient.get(url, { responseType: "text" }).then((res) => {
			const data = res?.data.match(regExp);
			const resp = JSON?.parse(data.toString());
			imageSet = resp?.set?.item.map(
				(item) =>
					`${process.env.PROSPECT_WIDGETS_APP_IMAGE_CDN_URL_1}${item?.i?.n}?$acc-lg$&hei=350&wid=350`
			);
			return imageSet;
		});
	};

	const getAccessoryCarouselImagesetData = (imageSetUrl, imageName = "") => {
		const regExp = /\{[^)]+}/g;
		let imageSet = [];
		const url =
			process.env.PROSPECT_WIDGETS_APP_IMAGE_CDN_URL_2 +
			imageSetUrl +
			"?req=imageset,json";
		return apiClient.get(url, { responseType: "text" }).then((res) => {
			const data = res?.data.match(regExp);
			const resp = JSON.parse(data.toString());
			imageSet = new Set(
				resp?.IMAGE_SET.split(";").map(
					(item) =>
						`${process.env.PROSPECT_WIDGETS_APP_IMAGE_CDN_URL_1}${item}?$acc-lg$&hei=350&wid=350`
				)
			);
			return Array.from(imageSet.values());
		});
	};

	const getCarouselImagesetLink = (image) => {
		return (
			process.env.PROSPECT_WIDGETS_APP_IMAGE_CDN_URL_2 +
			image +
			"?req=imageset,json"
		);
	};

	// For getting the image src from ss7
	const getImageLink = (
		image,
		width = 400,
		height = 400,
		useWebP = false,
		ss7Domains
	) => {
		return `${Ss7DomainWithVerizonWireless(
			ss7Domains
		)}${image}?$acc-lg$&hei=${height}&wid=${width}${
			useWebP ? "&fmt=webp-alpha" : ""
		}`;
		//return `${process.env.PROSPECT_WIDGETS_APP_IMAGE_CDN_URL_2}${image}?$acc-lg$&hei=${height}&wid=${width}${useWebP ? "&fmt=webp-alpha" : ""}`;
	};
	const getPDPImageLink = (image, useWebP = false, ss7Domains) => {
		return `${Ss7DomainWithVerizonWireless(ss7Domains)}${image}`;
	};

	return {
		getCarouselImagesetData,
		getAccessoryCarouselImagesetData,
		getCarouselImagesetLink,
		getImageLink,
		getPDPImageLink,
	};
};

export default useImageUtils;
