import React, { useState,useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import "./BroadbandPlan.css"
import NewCustomerAddressModal from "common/components/Modals/NewCustomerAddressModal";



 const BroadbandPlan = ({planId,flow,isfromAuth=false, backUpPlan = false,fwaBundleSelected = false, onScrollHandler}) => {
    const dispatch = useDispatch();
    const [openModal,setOpenModal] = useState(false);
    const mobilityPageData = useSelector((state) => state.ProspectWidgets_MobilityData);
    const sessionCartData = useSelector((state) => state.ProspectWidgets_GlobalData?.sessionCartData);
    const planFirstAddress = useSelector((state)=> state.PlanFirst?.addressFromLearn);
    const [pdpBillingAddress,setPdpBillingAddress] = useState(sessionCartData?.apiResponse?.prospectFlowData?.pdpBillingAddress || {})
    const [prospect5gLoopQualAddress,setProspect5gLoopQualAddress] = useState(sessionCartData?.apiResponse?.prospectFlowData?.prospect5gLoopQualAddress || {})
    const [addressFromPlansFirst,setAddressFromPlansFirst] = useState({planFirstAddress} || {})
    const [loadHtml,setLoadHtml] = useState([]);
    const lqData = useSelector((state) => state.ProspectWidgets_LQPage);
    const mmw = lqData?.mmw;
    const isMobile = useMediaQuery({
        query: "(max-width: 500px)",
      });
    const [tempVal,setTempVal] = useState([]);
    let heightAlign;
    // const heightAlign = mmw ? 'heightAlign4g5gMMW' :((flow === "4g" || flow === "5g") && !backUpPlan && !fwaBundleSelected) ? 'heightAlign4g5g' : ((flow === "4g" || flow === "5g") && !backUpPlan && fwaBundleSelected) ? 'heightAlign4g5gFWABundle':'heighAlignNormal';

    let pageData;
    
    if(flow === "5g"){
        pageData = useSelector((state) => state.ProspectWidgets_5GPlansData);
    }else if(flow === "4g"){
        pageData = useSelector((state) => state.ProspectWidgets_4GPlansData);
    }else{
        pageData = useSelector((state) => state.ProspectWidgets_WirelessPlansData?.htmlTemplateFcc ? state.WirelessPlans : state.PlanFirst);
    }
    let htmlTemplateFcc = pageData?.htmlTemplateFcc;
    let planDetailsFcc = pageData?.planDetailsFcc;
    const [taxAndCharges,setTaxAndCharges] = useState(pageData?.taxesCharges?.offerTaxDetails);
    const tempArr=[];

    if(mmw){
        heightAlign = 'heightAlign4g5gMMW';
    }else if(flow == "4g" && !backUpPlan && !fwaBundleSelected){
        heightAlign = 'heightAlign4g';
    }else if(flow == "4g" && !backUpPlan && fwaBundleSelected){
        heightAlign = 'heightAlign4gFWABundle';
    }else if(flow == "5g" && !backUpPlan && !fwaBundleSelected){
        heightAlign = 'heightAlign5g';
    }else if(flow == "5g" && !backUpPlan && fwaBundleSelected){
        heightAlign = 'heightAlign5gFWABundle';
    }else{
        heightAlign=  'heighAlignNormal';
    }

    useEffect(()=>{
        loadBroadBandTemplate();
    },[htmlTemplateFcc,planDetailsFcc,pdpBillingAddress,tempVal,prospect5gLoopQualAddress,addressFromPlansFirst]);

    const loadBroadBandTemplate =()=>{

        for(let plan in planDetailsFcc){
            let tplHTML = htmlTemplateFcc;
            var resValues = plan && (planDetailsFcc[plan]?.find(obj => obj.isActive ?? planDetailsFcc[plan][0] ));
            if(resValues && tplHTML && (planDetailsFcc[plan]?.find(obj => obj.isActive ?? planDetailsFcc[plan][0])?._fcc_VZplanCode_) == planId){
                var resKeys = Object.keys(resValues);
                if(resKeys && Array.isArray(resKeys)){
                    resKeys.map((resKey)=>{
                        var keyReg = new RegExp(resKey,'g');
                        if(Array.isArray(resValues[resKey])){
                            var tplHTMLArr = "";
                            resValues[resKey].map((labVal)=>{
                                if(labVal && labVal.label && labVal.value){
                                    tplHTMLArr += '<div class="fl-col9"><p>'+ labVal.label  +'</p></div><div class="fl-col3"><p class="txt-rt fwt-bd fcc-val">'+ labVal.value  +'</p></div>';
                                }
                            });
                            tplHTML = String(tplHTML).replace(keyReg, tplHTMLArr);
                        } else if(resValues[resKey] != "_DY_"){
                            tplHTML = String(tplHTML).replace(keyReg, (resValues[resKey] ? resValues[resKey] : ""));
                        }
                        if((flow == "5g") || (pdpBillingAddress && Object.keys(pdpBillingAddress).length  !== 0) 
                            || ( Object.keys(prospect5gLoopQualAddress).length !== 0)
                            || (addressFromPlansFirst && ((addressFromPlansFirst?.addressLine1 && addressFromPlansFirst?.addressLine1?.length !== 0 )|| (addressFromPlansFirst?.streetName && addressFromPlansFirst?.streetName?.length !== 0 )))){
                            var tplHTMLArr = "";
                                tempVal.map((labVal)=>{
                                if(labVal && labVal.label && labVal.value){
                                    tplHTMLArr += '<div class="fl-col9"><p>'+ labVal.label  +'</p></div><div class="fl-col3"><p class="txt-rt fwt-bd fcc-val">'+ labVal.value  +'</p></div>';
                                }
                            });
                        if(Array.isArray(resValues['_fcc_providerMthyFee_'])){
                                resValues['_fcc_providerMthyFee_'].map((labVal)=>{
                                    if(labVal && labVal.label && labVal.value){
                                        tplHTMLArr += '<div class="fl-col9"><p>'+ labVal.label  +'</p></div><div class="fl-col3"><p class="txt-rt fwt-bd fcc-val">'+ labVal.value  +'</p></div>';
                                    }
                                });
                            }
                           
                        tplHTML = String(tplHTML).replace('_fcc_providerMthyFee_', tplHTMLArr);
                       
                        }else{
                        //    let tplHTMLArr = '<div class="fl-col9"><p>'+ 8888  +'</p></div><div class="fl-col3"><p class="txt-rt fwt-bd fcc-val">'+ 99999  +'</p></div>';
                          let tplHTMLArr = `<div style="background-color:#fff4e0;padding: 11px;margin:4px 0px 8px -8px;"><div style="display:flex;flex-direction:row"><div><svg id="Layer_1" style="height:16px" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.6 21.6"><path d="M19.52557,15.78473,12.588,3.74951a2.0643,2.0643,0,0,0-3.584-.001L2.0733,15.78668a2.07277,2.07277,0,0,0,1.79688,3.10358H17.73157a2.07289,2.07289,0,0,0,1.794-3.10553ZM18.548,17.2926a.9494.9494,0,0,1-.81738.47266H3.87115a.94838.94838,0,0,1-.82226-1.419L9.97961,4.30811a.9396.9396,0,0,1,1.63184,0L18.551,16.34625A.95147.95147,0,0,1,18.548,17.2926ZM9.95,14.367h1.7V16.05H9.95Zm0-6.817h1.7v2.49048l-.56951,2.635H10.511l-.561-2.635Z"></path></svg></div><div><div style="display:flex;flex-direction:column;padding-left: 5px"><div> Enter your full address to get all provider monthly fees.</div><div><button id='btn-${planId}' style="border-radius: 1.5rem;font-size: 12px;background-color: transparent;padding: 0.6rem;cursor: pointer;margin-top: 10px;font-weight: 700;width: 120px;">Enter address</button></div></div></div> </div>     </div>`
                        //    let tplHTML = '<Notification type="warning" title="Enter your full address to get all provider monthly fees."   subtitle={<Button  size="large" disabled={false} use="secondary" onClick={openAddressModal}> Enter address</Button>} fullBleed={true} inlineTreatment={false} hideCloseButton={true}/>'
                           tplHTML = String(tplHTML).replace('_fcc_providerMthyFee_', tplHTMLArr);
                        }
                    });
                
                    tempArr.push(tplHTML);
                }
            }
        }
        setLoadHtml(tempArr);

    }
    if(isMobile){
    window.addEventListener('click',(e)=>{
        if(String(e.target.id).includes('btn')){
            const getE = document.getElementById(e.target.id)
            if(getE){
                getE.dispatchEvent(new Event('click'))
            }
        }
    }, {once: true})
    }
    useEffect(()=>{
        const el = document.getElementById(`btn-${planId}`);     
        // if(loadHtml.length > 0  && (flow != "4g" && flow != "5g") &&  (!pdpBillingAddress  || Object?.keys(pdpBillingAddress).length === 0) && el?.getAttribute('listener') !== 'true'){
        if(loadHtml.length > 0  && (flow != "5g")  &&  Object?.keys(pdpBillingAddress).length === 0 && el?.getAttribute('listener') !== 'true' || ( Object?.keys(prospect5gLoopQualAddress).length === 0 || (addressFromPlansFirst?.addressLine1?.length === 0 || addressFromPlansFirst?.streetName?.length === 0 ) && pageData?.isFrom4GLearn) ){

            el?.addEventListener('click', openAddressModal);
            el?.setAttribute('listener', 'true');
        }
        
    },[loadHtml])

    useEffect(()=>{
        return () => { 
            const el = document.getElementById(`btn-${planId}`);
            el?.removeEventListener('click',openAddressModal);
        };
    },[])
    const openAddressModal = useCallback(
        (e) => {
            setOpenModal(true);
        },
        []
    );

    useEffect(()=>{
        setPdpBillingAddress(sessionCartData?.apiResponse?.prospectFlowData?.pdpBillingAddress || {})
        setProspect5gLoopQualAddress(sessionCartData?.apiResponse?.prospectFlowData?.prospect5gLoopQualAddress || {})
    },[sessionCartData])
    
    useEffect(()=>{
        setAddressFromPlansFirst(planFirstAddress||{});
    },[planFirstAddress])

    useEffect(()=>{
        setTaxAndCharges(pageData?.taxesCharges?.offerTaxDetails);
    },[pageData?.taxesCharges?.offerTaxDetails])

    useEffect(()=>{
        if(taxAndCharges && taxAndCharges.hasOwnProperty(planId)){

            if(Array.isArray(taxAndCharges[planId])){
                const taxMap = taxAndCharges[planId].map(taxItem => ({
                    "label": taxItem?.taxDescription,
                    "value": `$${(Math.round(taxItem.taxAmount* 100) / 100).toFixed(2)}`
                }))
                setTempVal(taxMap || [])

            }
            
        }
    },[taxAndCharges])

    const onScroll = () => {
        if(onScrollHandler) {
            onScrollHandler();
        }
    }
        
    return (
        <>
        {loadHtml && loadHtml?.length>0 && loadHtml?.map((htmlText,index) =>{
            return (

            <>
            <BroadBandWrapper isfromAuth={isfromAuth} onScroll={onScroll}>
                 <div key={index} className={heightAlign}>
                    <div  dangerouslySetInnerHTML={{
                    __html: htmlText 
                    }}
                    className={isfromAuth ? "from-auth" : ""}
                    />
                </div>
                    {
                        openModal &&  <NewCustomerAddressModal
                        pageData={mobilityPageData}
                        hideZipCode ={openModal}
                        closeNCModal={setOpenModal}
                       
                     />
                    }
            </BroadBandWrapper>
                    
            </>
            )
        })}
        </>
    )
}
const BroadBandWrapper = styled.div`
  cursor: default;
//   padding-right:  ${({ isfromAuth }) => isfromAuth ? '0.2rem' : '0.6rem'}};
//   height: 13.625rem;
//   overflow-y: scroll;
//   &::-webkit-scrollbar {
//     width: 3px;
//     height: 40px;
//     background: #d8dada;
//     padding-left: 1rem;
//     border-radius: 10px;
//   }
//   ::-webkit-scrollbar-thumb {
//     background: #6f7171;
//     border-radius: 10px;
//   }
`;
const MainText = styled.div`
  font-weight : bold;
`;

export default React.memo(BroadbandPlan);

