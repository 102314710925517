import React, { useState, useMemo, useContext } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Button } from "@vds3/buttons";
import { Line } from "@vds3/lines";
import { Icon } from "@vds3/icons";
import { Modal, ModalTitle, ModalBody, ModalFooter } from "@vds3/modals";
import {
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
  Cell,
} from "@vds3/tables";
import { Body, Title, Micro } from "@vds3/typography";
import { StyledFooterContainer } from "../styles";
import {
  StyledTable,
  HighlightHeader,
  HighlightCell,
  Disclaimers,
  PlanHeaderDiv,
  ComparePlansButton,
} from "./styles";
import FWAContext from "../../fwaContext";

const CompareContext = React.createContext({});

const ComparePlans = ({ ...props }) => {
  const [isOpened, setOpened] = useState(false);
  const { flow, primaryPlan } = useContext(FWAContext);
  const { t } = useTranslation(["4G-5G"]);
  const lqData = useSelector((state) => state.ProspectWidgets_LQPage);
  const isHybrid = lqData?.cband &&  lqData?.mmw;
  const aemKey = flow === "4g" ? "fourG" : "fiveG";
  const aem = t(`4G-5G.${aemKey}.${props?.planType === "backupPlan" ? "backup-plan-content":"plan-content"}.${isHybrid ? "comparePlansModalHydrid":"comparePlansModal"}`, {
    returnObjects: true,
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 545px)",
  }); 
  const plans = useSelector((state) =>
    flow === "5g"
      ? state.ProspectWidgets_5GPlansData.planDetails?.plans
      : props?.planType === "primaryPlan"
      ? props?.planDetails
      : props?.planDetailsBackup
  );
  const planIDs = useMemo(
    () =>
      flow === "5g" 
        ? plans && Object.values(plans)?.sort((a, b) => (Number(a.monthlyFee) > Number(b.monthlyFee) ? 1 : -1)).map(a => a.billingCode)
        : plans?.map((i) => i.billingCode),
    [plans]
  );

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        surface="light"
        width="575px"
        hideCloseButton={false}
        opened={isOpened}
        onOpenedChange={setOpened}
        toggleButton={
          <ComparePlansButton
            marginTop={props?.marginTop || -8}
            isMobile={isMobile}
            use="secondary"
            size={isMobile ? "small" : "large"}
            onClick={(e) => setOpened(true)}
            plansLength={plans && Object.values(plans)?.length}
            isfromAuth={props?.isfromAuth}
            is5GFlow={flow === "5g"}
            selected={!!primaryPlan?.selectedPlan} 
          >
            {flow === "5g" ? aem?.buttonText : props?.compareBtnLabel}
          </ComparePlansButton>
        }
      >
        <ModalTitle>{aem?.modalTitle}</ModalTitle>
        <ModalBody>
          <CompareContext.Provider value={{ aem, planIDs }}>
            <Line type="secondary" surface="light" />
            <StyledTable>
              <HeaderRow />
              <TableBody>
                {aem?.rowTitles ? Object.entries(aem?.rowTitles).map(([key, value]) => {
                  if (key !== "header") return <CompareRow rowKey={key} />;
                }): <></>}
              </TableBody>
            </StyledTable>
            {/* <MarginSpacerL /> */}
            <Disclaimers>
              <ol>
                {aem?.disclaimers &&
                  aem?.disclaimers.map((i) => (
                    <li key={i?.bold}>
                      <Body>
                        <Body primitive="span" bold>
                          {i?.bold}
                        </Body>
                        {i?.text}
                      </Body>
                    </li>
                  ))}
              </ol>
            </Disclaimers>
          </CompareContext.Provider>
        </ModalBody>
        <StyledFooterContainer>
          <Button
            width="100%"
            onClick={() => {
              setOpened(false);
            }}
          >
            Close
          </Button>
        </StyledFooterContainer>
      </Modal>
    </div>
  );
};

const HeaderRow = ({ ...props }) => {
  const { aem, planIDs } = useContext(CompareContext);
  return (
    <TableHead>
      <HighlightHeader>
        <Title size="small" bold>
          {aem?.rowTitles?.header?.text}
        </Title>
      </HighlightHeader>
      <TableHeader colSpan={planIDs?.length}>
        <PlanHeaderDiv cols={planIDs?.length}>
          {planIDs?.map((i) => (
            <div>
              <Body size="large" bold>
                {aem?.plans[i] && aem?.plans[i]?.speed}
              </Body>
              <Body size="small">{aem?.plans[i] && aem?.plans[i]?.price}</Body>
            </div>
          ))}
        </PlanHeaderDiv>
        <Line
          type="secondary"
          surface="light"
          style={{ marginTop: "4px", marginBottom: "2px" }}
        />
        <div style={{ textAlign: "center" }}>
          <Micro color="#A7A7A7">{aem?.headerPriceDisclaimer}</Micro>
        </div>
      </TableHeader>
    </TableHead>
  );
};

const CheckmarkIcon = ({ checked }) => {
  return <Icon name={checked ? "checkmark" : "minus"} />;
};

const CompareRow = ({ rowKey, type }) => {
  const { aem, planIDs } = useContext(CompareContext);
  const { flow } = useContext(FWAContext);

  if (aem && planIDs) {
    return (
      <TableRow>
        <HighlightCell>
          <Body size="small">
            {aem?.rowTitles[rowKey]?.text}
            <sup>{aem?.rowTitles[rowKey]?.superscript}</sup>
          </Body>
        </HighlightCell>
        {planIDs.map((i) => (
          <Cell key={`${i}-${rowKey}`}>
            {
            typeof aem?.plans[i][rowKey] == "boolean" ? (
              <CheckmarkIcon checked={aem?.plans[i][rowKey]} />
            ) : rowKey == "routerIncludedSup" ? (
              <Body size="small">
               <div dangerouslySetInnerHTML={{ __html: aem?.plans[i][rowKey]}}></div>
            </Body>
            ) : (
              <Body size="small">{aem?.plans[i][rowKey]}</Body>
            )}
          </Cell>
        ))}
      </TableRow>
    );
  } else return null;
};

export default ComparePlans;
