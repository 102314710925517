import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Button } from "@vds3/buttons";
import { Input } from "@vds3/inputs";
import { Modal, ModalTitle, ModalBody, ModalFooter } from "@vds3/modals";
import { RadioBox } from "@vds3/radio-boxes";
import { Body } from "@vds3/typography";
import {
  validateDeviceId,
  setValidateDeviceIdResp,
  setValidateSimIdResp,
  validateSimId,
  update5GRouter,
  setImeiCheckAttempt
} from "../../ducks/5GSlice";
import { isValidIMEIFormat, isValidICCIDFormat } from "utils/byodUtils";
import { useGlobalData } from "hooks";
import useDebounce from "hooks/useDebounce";

const IMEIModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const IMEIModalFooter = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;
  @media only screen and (max-width: 546px) {
    position: absolute;
    bottom: 16px;
    width: 95%;
    display: flex;
    justify-content: center;
    button {
      width: 100%;
    }
  }
`;

const SIMModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  button {
    width: 95%;
    margin-right: 0.5rem;
  }
  @media only screen and (max-width: 546px) {
    position: absolute;
    bottom: 16px;
    width: 95%;
    flex-direction: column;
    justify-content: center;
    button {
      width: 100%;
      margin-bottom: 0.75rem;
    }
  }
`;

const SIMRadioBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.75rem;
`;

const numericRegex = new RegExp(/[^\d]/, "ig");

const IMEICheckModal = ({
  opened,
  onOpenedChange,
  isMobile,
  isCband,
  isMMW,
  setImeiCheckModal,
  isfromAuth = false,
  ...props
}) => {
  const { sessionCartData } = useGlobalData();
  const { t } = useTranslation(["4G-5G"]);
  const dispatch = useDispatch();
  const [imei, setImei] = useState("");
  const [iccid, setIccid] = useState("");
  const [checkClicked, setCheckClicked] = useState(false);
  const [simSelection, setSimSelection] = useState("");
  const [showSimId, setShowSimId] = useState(false);

  const IMEI_MAX_LENGTH = 18;
  const SIM_MAX_LENGTH = 25;

  const validateDeviceIdResp = useSelector(
    (state) => state?.ProspectWidgets_5GPlansData?.byor?.validateDeviceIdResp
  );
  const isImeiCompatible = validateDeviceIdResp?.serviceStatus?.success;
  const imeiCheckAttempt = useSelector(
    (state) => state?.ProspectWidgets_5GPlansData?.imeiCheckAttempt
  );
  const validateSimIdResp = useSelector(
    (state) => state?.ProspectWidgets_5GPlansData?.byor?.validateSimIdResp
  );
  const isSimCompatible =
    validateSimIdResp?.serviceStatus?.success &&
    validateSimIdResp?.simValidationInfo?.compatible;

  const setInputValue = useCallback((maxLength, updateState, inputValue) => {
    let value = inputValue.replace(numericRegex, "");
    if (value) {
      value = value.trim();
    }
    if (value?.length <= maxLength) {
      updateState(value);
    }
  });

  const clearDeviceValidation = useCallback(() => {
    dispatch(setValidateDeviceIdResp(""));
    dispatch(setValidateSimIdResp(""));
  });

  const validateImei = () => {
    const val = imei.trim();
    if (val) {
      dispatch(
        validateDeviceId({
          cband: isCband,
          mmw: isMMW,
          deviceId: val,
          router: true,
          flow: "5g",
        })
      );
      window.sessionStorage.setItem("ISBYOR", true);
    }
  };

  const validateIccid = () => {
    const simId = iccid.trim();
    const imeiNo = imei.trim();
    if (simId && imeiNo) {
      dispatch(
        validateSimId({
          cband: isCband,
          newSim: simSelection === "newSim",
          simNumber: simId,
          deviceId: imeiNo,
          router: true,
          flow: "5g",
        })
      );
    }
  };

  useEffect(() => {
    if(imei?.length && isValidIMEIFormat(imei) && !isImeiCompatible) {
      let failedCount = imeiCheckAttempt + 1;
      dispatch(setImeiCheckAttempt(failedCount));
    }
  }, [validateDeviceIdResp]);

  useDebounce(() => {
    if(iccid?.length && isValidICCIDFormat(iccid)){
      validateIccid();
    }
  }, 1000, [iccid]);


  const cancelBYORouter = () => {
    window.sessionStorage.removeItem("ISBYOR");
    setImeiCheckModal(false);
    clearDeviceValidation();
  }

  const isCheckDisabled = !imei?.length || !isValidIMEIFormat(imei) || imeiCheckAttempt >= 9;
  const isCheckSimIdDisabled =
    !simSelection || (simSelection === "existingSim" && !isSimCompatible) ||
    (simSelection === "existingSim"  &&
      (!iccid?.length || !isValidICCIDFormat(iccid)));
  const isImeiError = (imei?.length && imeiCheckAttempt >= 9) || checkClicked && validateDeviceIdResp && !isImeiCompatible;
  const isIccidError = iccid?.length && validateSimIdResp && !isSimCompatible
  const isImeiVerified =
    checkClicked && validateDeviceIdResp && isImeiCompatible;

  const addBYORouter = () => {
    const deviceInfo = validateDeviceIdResp?.deviceInfo;
    const simNumber = validateSimIdResp?.simValidationInfo?.simNumber;
    const request = {
      flow: "5g",
      sessionCartData: sessionCartData?.apiResponse?.prospectFlowData,
      deviceInfo: deviceInfo,
      pricingType: "zeroYear",
      byor: true,
      byod: true,
      newSim: simSelection === "newSim",
      cband: isCband,
      mmw: isMMW,
      byodInfo: {
        imei: deviceInfo?.imei,
        name: deviceInfo?.name,
        simSku: deviceInfo?.simSku,
        pSimCapable: deviceInfo?.pSimCapable,
        eSimCapable: deviceInfo?.eSimCapable,
        newSim: simSelection === "newSim",
        simNumber: simNumber,
      },
    };
    dispatch(update5GRouter(request));
  };

  const onModalClose = (modalState) => {
    if(!modalState) {
      setImeiCheckModal(false);
      if(!isfromAuth){
        clearDeviceValidation();
      }
    }
  }

  const SimIdModalBody = () => (
    <>
      <ModalTitle>
        {t("4G-5G.fiveG.router-content.byorModal.sim.heading")}
      </ModalTitle>
      <ModalBody
        children={
          <IMEIModalBody>
            <Body size="large">
              {t("4G-5G.fiveG.router-content.byorModal.sim.haveSimQuestion")}
              <Body size="large">
                {t("4G-5G.fiveG.router-content.byorModal.sim.chooseOne")}
              </Body>
            </Body>
            <SIMRadioBoxContainer>
              <RadioBox
                id={"newSim"}
                width={isMobile ? "100%" : ""}
                text={t(
                  "4G-5G.fiveG.router-content.byorModal.sim.radioBoxes.newSim.text"
                )}
                subtext={t(
                  "4G-5G.fiveG.router-content.byorModal.sim.radioBoxes.newSim.subtext"
                )}
                value={"newSim"}
                selected={simSelection === "newSim"}
                onChange={(e) => setSimSelection(e?.target?.value)}
              />
              <RadioBox
                id={"existingSim"}
                width={isMobile ? "100%" : ""}
                text={t(
                  "4G-5G.fiveG.router-content.byorModal.sim.radioBoxes.existingSim.text"
                )}
                subtext={t(
                  "4G-5G.fiveG.router-content.byorModal.sim.radioBoxes.existingSim.subtext"
                )}
                value={"existingSim"}
                selected={simSelection === "existingSim"}
                onChange={(e) => setSimSelection(e?.target?.value)}
              />
            </SIMRadioBoxContainer>
            {simSelection === "existingSim" && (
              <Input
                name="ICCID"
                type={isIccidError? "inlineAction" : "number"}
                maxLength={SIM_MAX_LENGTH}
                label={t("4G-5G.fiveG.router-content.byorModal.sim.label")}
                helperText={t(
                  "4G-5G.fiveG.router-content.byorModal.sim.helperText"
                )}
                helperTextPlacement="bottom"
                error={iccid?.length && validateSimIdResp && !isSimCompatible}
                errorText={t(
                  "4G-5G.fiveG.router-content.byorModal.sim.simNotOkStatus"
                )}
                success={(iccid?.length && validateSimIdResp && isSimCompatible) || false}
                successText={t(
                  "4G-5G.fiveG.router-content.byorModal.sim.simOkStatus"
                )}
                value={iccid}
                onChange={(e) => {
                  setInputValue(SIM_MAX_LENGTH, setIccid, e.target.value);
                }}
                inlineActionButtonLabel={t(
                  "4G-5G.fiveG.router-content.byorModal.clear"
                )}
                onClickInlineAction={() => {
                  setInputValue(SIM_MAX_LENGTH, setIccid, "");
                  dispatch(setValidateSimIdResp(""));
                }}
              />
            )}
          </IMEIModalBody>
        }
      />
      <SIMModalFooter isMobile={isMobile}>
        <Button
          disabled={isCheckSimIdDisabled}
          onClick={() => {
              if(!isfromAuth) {
                addBYORouter();
              }
              onOpenedChange(false);
          }}
        >
          {t("4G-5G.fiveG.router-content.byorModal.continue")}
        </Button>
        <Button use="secondary" onClick={cancelBYORouter}>
          {t("4G-5G.fiveG.router-content.byorModal.cancel")}
        </Button>
      </SIMModalFooter>
    </>
  );

  const imeiErrorMsg = imeiCheckAttempt >= 9 ?
   t("4G-5G.fiveG.router-content.byorModal.imeiFailedAttempt"):
   t("4G-5G.fiveG.router-content.byorModal.imeiNotOkStatus");

  const ImeiModalBody = () => (
    <>
      <ModalTitle>
        {t("4G-5G.fiveG.router-content.byorModal.heading")}
      </ModalTitle>
      <ModalBody
        children={
          <IMEIModalBody>
            <Body size="large">
              {t("4G-5G.fiveG.router-content.byorModal.enterImeiInfo")}
            </Body>
            <Body size="large">
              {t("4G-5G.fiveG.router-content.byorModal.findImeiNumberInfo")}
            </Body>
            <Input
              name="imei"
              type={isImeiError ? "inlineAction" : "number"}
              maxLength={IMEI_MAX_LENGTH}
              label={t("4G-5G.fiveG.router-content.byorModal.imeiLabel")}
              helperText={t("4G-5G.fiveG.router-content.byorModal.helperText")}
              helperTextPlacement="bottom"
              error={isImeiError}
              errorText={imeiErrorMsg}
              success={isImeiVerified}
              successText={t("4G-5G.fiveG.router-content.byorModal.imeiOkStatus")}
              value={imei}
              onChange={(e) => {
                setInputValue(IMEI_MAX_LENGTH, setImei, e.target.value);
              }}
              inlineActionButtonLabel={t(
                "4G-5G.fiveG.router-content.byorModal.clear"
              )}
              onClickInlineAction={() => {
                setInputValue(IMEI_MAX_LENGTH, setImei, "");
                dispatch(setValidateDeviceIdResp(""));
              }}
            />
          </IMEIModalBody>
        }
      />
      <IMEIModalFooter isMobile={isMobile}>
        {isImeiVerified && imeiCheckAttempt <= 9 ? (
          <Button
            onClick={() => {
              if(!isCband && isMMW) {
                addBYORouter();
                onOpenedChange(false);
              } else {
                setShowSimId(true);
              }
            }}
          >
            {t("4G-5G.fiveG.router-content.byorModal.continue")}
          </Button>
        ) : (
          <Button
            disabled={isCheckDisabled}
            onClick={() => {
              setCheckClicked(true);
              validateImei();
            }}
          >
            {t("4G-5G.fiveG.router-content.byorModal.check")}
          </Button>
        )}
      </IMEIModalFooter>
    </>
  );

  return (
    <Modal
      opened={opened}
      onOpenedChange={onModalClose}
      surface="light"
      ariaLabel="IMEI and SIM ID Check Modal"
    >
      {!showSimId ? ImeiModalBody() : SimIdModalBody()}
    </Modal>
  );
};

export default IMEICheckModal;
