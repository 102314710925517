import styled from "styled-components";
import { LayoutTokens } from "@vds-tokens/layout";
import {
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
  Cell,
} from "@vds3/tables";
import { Button } from "@vds3/buttons";

export const StyledTable = styled(Table)`
  th {
    padding: ${LayoutTokens.space["3X"].value};
    *:not(:first-child) {
      margin-top: 0.1rem;
    }
  }
  th:first-of-type {
    width: 112px;
  }
  td {
    padding: ${LayoutTokens.space["3X"].value};
  }
  sup {
    vertical-align: top;
    font-size: 0.6em;
  }
`;

export const HighlightHeader = styled(TableHeader)`
  background-color: #f6f6f6;
`;

export const HighlightCell = styled(Cell)`
  background-color: #f6f6f6;
  min-width: 140px;
  @media (max-width: 576px) {
    min-width: unset;
  }
`;

export const Disclaimers = styled.div`
  ol {
    padding-left: 1rem;
  }
  li {
    padding-bottom: 1rem;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    font-family: Verizon-NHG-eTX, Helvetica, Arial, Sans-serif;
  }
`;

export const PlanHeaderDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.cols}, 1fr);
  div:not(:first-of-type) {
    padding-left: 12px;
  }
  div:not(:last-of-type) {
    padding-right: 12px;
  }
`;

export const ComparePlansButton = styled(Button)``
// `
//   margin-top: ${({marginTop}) => marginTop}px;
//   margin-bottom: 5px;
//   ${({plansLength, isfromAuth, is5GFlow, selected}) =>
//     (is5GFlow && !isfromAuth && ((plansLength>2) ? 
//     `margin-right: ${selected ? "-50px" :"-20px"}`: 
//     `margin-right: ${selected ? "250px" :"280px"}`))
//   }  
// `