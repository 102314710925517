import React from "react";
// import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalTitle, ModalFooter } from "@vds3/modals";
import { Title } from "@vds3/typography";
import {
  setEditPackagePackageId,
  setIsEditPackageFlow,
  setLoadMobilityProductDetails,
  setLoadShoppingCartWidget,
} from "app/ducks/GlobalSlice";
import { setSelectedBussProduct } from "pages/BussProductsPage/ducks/BussProductSlice";
// import { setDeviceId } from "pages/Mobility/ducks/MobilitySlice";

function EditDeviceModal({
  opened,
  onOpenedChange,
  editDevicePackageId,
  selectedDevice,
  pack,
  ...props
}) {
  const { t } = useTranslation(["shoppingCart"]);
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const onEditDeviceClicked = () => {
    if(pack && pack?.buss){
      dispatch(setLoadShoppingCartWidget(false));
      dispatch(setSelectedBussProduct(pack))
			dispatch(setLoadMobilityProductDetails(true));
    }
    dispatch(setIsEditPackageFlow(true));
    dispatch(setEditPackagePackageId(editDevicePackageId));
    if(selectedDevice?.accessoryId!==null && selectedDevice?.accessoryId){
      // dispatch(setDeviceId(selectedDevice?.accessoryId));
      // navigate(`/shop/accessories/${selectedDevice?.description?.split(":_:")
      // ? selectedDevice?.description?.split(":_:")[7]
      // : selectedDevice?.productName}`, {
      //   state: { isFromCart: true },
      // })
    }
    else if (selectedDevice?.packageType === "ONETALK") {
      if (selectedDevice?.deviceType === "DESKPHONE") {
        // navigate(
        //   `/shop/products/voice-collaboration/desk-conference-phones/${
        //     selectedDevice?.description?.split(":_:")
        //       ? selectedDevice?.description?.split(":_:")[7]
        //       : selectedDevice?.productName
        //   }`
        // );
      } else if (selectedDevice?.deviceType === "SMARTPHONE") {
        // navigate(
        //   `/shop/products/voice-collaboration/smartphone/${
        //     selectedDevice?.description?.split(":_:")
        //       ? selectedDevice?.description?.split(":_:")[7]
        //       : selectedDevice?.productName
        //   }`
        // );
      } else if (selectedDevice?.deviceType === "MOBILEAPPCLIENT") {
        // navigate(
        //   `/shop/products/voice-collaboration/unified-communications/one-talk/mobile-app`
        // );
      }
    } else if (selectedDevice?.packageType === "FOURG") {
      // navigate(
      //   "/shop/products/networks/connectivity/lte-business-internet/plans"
      // );
    } else if (selectedDevice?.packageType === "FIVEG") {
      // navigate("/shop/solutions/5g/plans");
    } else {
      // dispatch(setDeviceId(selectedDevice.deviceId));
      // navigate(
      //   `/products/devices/smartphones/${
      //     selectedDevice?.description?.split(":_:")
      //       ? selectedDevice?.description?.split(":_:")[7]
      //       : selectedDevice?.productName
      //   }`
      // );
    }
  };

  return (
    <>
      {opened && (
        <Modal
          hideCloseButton={true}
          disableOutsideClick={true}
          onOpenedChange={onOpenedChange}
          opened={opened}
        >
          <ModalTitle>
            <Title size="large" bold>
              {t("shopping-cart.edit-device-modal.title")}
            </Title>
          </ModalTitle>
          <ModalBody>{t("shopping-cart.edit-device-modal.body")}</ModalBody>
          <ModalFooter
            buttonData={{
              primary: {
                children: t("shopping-cart.edit-device-modal.continue"),
                onClick: () => {
                  onEditDeviceClicked();
                },
              },
              close: {
                children: t("shopping-cart.edit-device-modal.cancel"),
                onClick: () => {},
              },
            }}
          />
        </Modal>
      )}
    </>
  );
}

export default EditDeviceModal;
