import * as CONSTANTS from './constants'

export const INITIAL_STATE = {
  businessStreetAddress: '',
  shippingStreetAddress: '',
  companyName: '',
  phoneNumber: '',
  errorTexts: {
    companyName: '',
    phoneNumber: '',
  },
  errors: {}
}

export const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.SET_BUSINESS_STREET_ADDRESS:
      return {
        ...state,
        businessStreetAddress: action.payload,
      }
    case CONSTANTS.SET_BUSINESS_STREET_ADDRESS2:
        return {
          ...state,
          businessStreetAddress2: action.payload,
        }
    case CONSTANTS.SET_COMPANY_NAME:
      return {
        ...state,
        companyName: action.payload,
      }
    case CONSTANTS.SET_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.payload,
      }
    case CONSTANTS.SET_BUSINESS_UNITS:
      return {
        ...state,
        businessUnit: action.payload
      }
    case CONSTANTS.SET_SHIPPING_UNITS:
      return {
        ...state,
        shippingUnit: action.payload
      }
    case CONSTANTS.SET_IS_SAME_ADDRESSS:
      return {
        ...state,
        isSameAddress: action.payload
      }
    case CONSTANTS.SET_SHIPPING_ERRORS:
      return {
        ...state,
        errors: action.payload
      }
    default:
      return state
  }
}

export default reducer
