import styled from "styled-components";

export const GridwallStickyTabBar = styled.div`
  position: sticky;
  top: ${({ headerHeight }) => headerHeight ?? 0}px;
  z-index: 2;
  background-color: white;
`;

export const GridwallTabBar = styled.div`
  background-color: white;
  max-width: 1272px;
  margin-right: auto;
  padding-left: 3rem;
  padding-right: 1rem;
  button:hover {
    background: #fff;
    color: #000;
  }
`;

export const GridwallContainer = styled.div`
  // margin-left: 1rem;
  // margin-right: 1rem;
  border-top: 0.0625rem solid #d8dada;
  background-color: #f6f6f6;
  padding-bottom: 4.25rem;
`;

export const SortButtonContainer = styled.div`
  float: "right";
  text-align: end;
  display: flex;
  flex-direction: ${({isDesktop}) => isDesktop ? "column" : "row"};
  gap: ${({isMobile}) => (isMobile ? "1rem" : "10px")};
  align-items: ${({isDesktop, isTabLayout}) =>
    !isDesktop && isTabLayout ? "center" : "unset"};
  justify-content: ${({isDesktop, isTabLayout}) =>
    !isDesktop && !isTabLayout ? "space-between" : "unset"};
  margin-top: ${({isMobile, isTabLayout}) => isMobile && !isTabLayout
    ? "1.5rem"
    : "unset"};
  float: ${({isDesktop}) => isDesktop ? "right" : ""};
  p {
    padding-right: 0.5rem;
  }
`;
