import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@vds3/buttons";
import { Grid, Row, Col } from "@vds3/grids";
import { Title, Body } from "@vds3/typography";
import { postCompleteOrder } from "../Checkout/ducks/PaymentSlice";
import {
  VerticalProgress,
} from "common/components/VerticalProgress";
import { MarginSpacerL, MarginSpacerM, MarginSpacerXL } from "common/components/styleTag";
import {
  setSectionsConfig,
  setSectionComplete,
  setSectionInProgress,
  setSectionModifier,
  setSectionRemoveComplete,
  getCheckoutSections,
  setCompletedSection,
  completedSectionType,
  setShoppingCartPageRevisited,
  setShoppingCartPageRevisitedLower,
  setInProgressSection,
  setComBoSectionInProgress,
  setCurrentOrder,
} from "./ducks/LowerFunnelSlice";
import OrderSummary from "../../pages/ShoppingCart/components/OrderSummary/OrderSummary";
import styled from 'styled-components';
import { Notification } from "@vds3/notifications";
import AboutBusiness from "./Components/BusinessInfo/AboutBusiness";
import IdentityVerification from "./Components/IdentityVerification/IdentityVerification";
import AuthorisedSigner from "./Components/BusinessInfo/AuthorisedSigner";
import SocialSecurityNo from "./Components/BusinessInfo/SSN-info"
import AuthoriseSignerConsent from "./Components/BusinessInfo/AuthoriseConsent"
import CreditResultPage from "./Components/CreditResult/CreditResult";
import PaymentNew from "./Components/Payment/PaymentNew";
import FederalTaxIDInfo from "./Components/BusinessInfo/FedTaxId-Optional";
import { useMediaQuery } from "react-responsive";
import { Accordion, AccordionDetail, AccordionHeader, AccordionItem, AccordionTitle } from "@vds3/accordions";
import { CartContextProvider } from "pages/ShoppingCart/cartContext";
import { loadAccountsForBussProducts, postSharedCartSaveUrl, setOneTimeCharge, setSuspendFlow } from "pages/Checkout/ducks/CheckoutSlice";
import { Modal, ModalBody } from "@vds3/modals";
import { Icon } from "@vds3/icons";
import SpinnerComponent from "common/components/Spinner/Spinner";
import { loadScript } from "app/ducks/utils";
import BusinessContractsTAC from "./Components/BusinessContractsTAC/BusinessContractsTACUC";
import { checkIfAemExists } from "utils/commonUtils";
import BillingInformation from "./Components/BusinessInfo/BillingInformation";
import ServiceInformation from "./Components/BusinessInfo/ServiceInformation";
import AccountsModal from "./Components/AccountsModal";
import PaymentNewBUSS from "./Components/Payment/PaymentNewBuss";
import Shipping from "./Components/Shipping/Shipping";
import { setLoadCheckoutWidget, setLoadShoppingCartWidget } from "app/ducks/GlobalSlice";

/*
const sectionComponentMap = {
  "subsection-1": <VerticalProgressContent />,
  "subsection-2": <VerticalProgressContent />,
  "subsection-3": <VerticalProgressContent />,
  "subsection-4": <VerticalProgressContent />,
  "subsection-5": <VerticalProgressContent />,
  "button-section": () => {
    alert("button pressed");
  },
  "new-button": <VerticalProgressButtonExample buttonText={"Button example"} />,
};
*/

const CheckoutNew = ({ ...props }) => {
  const { t } = useTranslation(["app", "mock-response", "checkout-sections", "payment"]);
  const dispatch = useDispatch();
  const sectionsConfigList = useSelector(
    (state) => state.LowerFunnelSlice?.checkoutSections
  );
  const sectionsConfig = useSelector(
    (state) => state.LowerFunnelSlice?.sectionsConfig
  );
  const shoppingCartPageRevisited = useSelector(
    (state) => state.LowerFunnelSlice?.shoppingCartPageRevisited
  );
  const shoppingCartPageRevisitedLower = useSelector(
    (state) => state.LowerFunnelSlice?.shoppingCartPageRevisitedLower
  );
  const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
  const widgetInputParams = globalData?.widgetInputParams;

  const checkoutPageData = useSelector(
    (state) => state.ProspectWidgets_CheckoutData
  );
  const paymentPageData = useSelector(
    (state) => state.ProspectWidgets_PaymentData
  );
  const orderDetails = useSelector(
    (state) => state.ProspectWidgets_ReviewData
  );
  const cartData =
    globalData?.sessionCartData?.apiResponse?.prospectFlowData;

  const orderSuccCompleted = orderDetails?.orderSuccessDetails?.orderNumber || orderDetails?.UnifiedCartorderSuccessDetails?.submitOrderResponse[0]?.orderNumber;
  const orderSuccess = orderSuccCompleted ? true : false
  const isPaymentSectionDone = sectionsConfig?.ShippingAndPayment?.subsections?.payment?.isComplete;
  const showSubmitBtn = !!sectionsConfig?.MbtBusinessContracts?.subsections?.["terms-n-conditions"]?.isComplete;
  // (isPaymentSectionDone
  //   && paymentPageData?.paymentInfo?.stage === "stag2:FULL_AUTH"
  //   && paymentPageData?.paymentInfo?.status === "SUCCESS") ? true : false;
  const jwt = paymentPageData?.paymentInfo?.jwt || "";
  //Determining key from api and taking sections from aem based on key
  // const flow = "mmwSection"
  const sessionCartData = globalData?.sessionCartData?.apiResponse?.prospectFlowData;
  const isAbandonedCart = sessionCartData?.cart?.abandonedCart;
  const isDigitalQuote = sessionCartData?.cart?.digitalQuote;
  // const flow = "defaultSection"
  const pageData = useSelector(state => state.ProspectWidgets_CheckoutData);
  const lqData = useSelector((state) => state.ProspectWidgets_LQPage);
  const flow = pageData?.unifiedCartParams?.flow ?? lqData?.flow;
  const [showOneTime, setShowOneTime] = useState(false);
  // const aemContent = t(`checkout-sections.${flow}`, { ns: "checkout-sections", returnObjects: true });
  const saveCartSections = t('checkout-sections.saveCartEnabledSections', { ns: "checkout-sections", returnObjects: true });
  const [isOpened, setOpened] = useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width: 725px)",
  });

  const cartId = cartData?.cart?.cartId || pageData?.cartId;
  const sectionComponentMap = {
    "about-business": <AboutBusiness />,
    "signer-info": <AuthorisedSigner />,
    "ssn-info": <SocialSecurityNo />,
    "fedtax-info": <FederalTaxIDInfo />,
    "signer-consent": <AuthoriseSignerConsent />,
    "identity-verification": <IdentityVerification />,
    "credit-results": <CreditResultPage />,
    "terms-n-conditions": <BusinessContractsTAC />,
    "payment": <PaymentNewBUSS />,
    "payment-uc": <PaymentNew />,
    "billing-information": <BillingInformation />,
    "service-information": <ServiceInformation />,
    "shipping": <Shipping />,
    "payment-buss": <PaymentNewBUSS />
    // "submit-order": <VerticalProgressButtonExample buttonText={"Submit Order"} />,
  };
  useEffect(() => {
    if (!sectionsConfig || Object.keys(sectionsConfig).length === 0 || shoppingCartPageRevisited) {
      // setInitialState();
      dispatch(getCheckoutSections(sessionCartData?.standAloneBuSS));
    }
    // dispatch(setSectionsConfig(aemContent));
    // SiteAnalytics.trackPageInfo();
    //SiteAnalytics.paymentLanding();
    //window["digitalData"].isUniqROS = true;
    window["digitalData"] && (window["digitalData"].isUniqBOS = true);
    if (window && !window?.Cardinal) {
      loadScript(window?.mbtGlobal?.sb);
    }
    window.sessionStorage.setItem("saveCartSections", JSON.stringify(saveCartSections));
  }, []);

  useEffect(() => {
    if (cartId) {
      // triggerCartIdLPEvent("", cartId);
      // attachLPEvent();
    }
  }, [cartId]);
  useEffect(() => {
    const currentUrl = window.location.href;
    const newUrl = new URL(currentUrl)
    const searchParams = new URLSearchParams(newUrl?.search)
    // Function to handle the resize event
    const handleBackButonCheckout = () => {
      if (searchParams.has("pageName")) {
        newUrl.searchParams.set("pageName", "ShoppingCart");
        window.history.replaceState({}, '', newUrl);
        dispatch(setLoadShoppingCartWidget(true))
        dispatch(setLoadCheckoutWidget(false))
      }

    };

    // Add the event listener
    window.addEventListener('popstate', handleBackButonCheckout);

    // Cleanup function to remove the listener on unmount
    return () => {
      window.removeEventListener('popstate', handleBackButonCheckout);
    };
  }, [dispatch])

  useEffect(() => {
    if (!sectionsConfig || Object.keys(sectionsConfig).length === 0 || (shoppingCartPageRevisited && shoppingCartPageRevisitedLower)) {
      setInitialState();
    }
  }, [sectionsConfigList]);
  useEffect(() => {
    if (pageData?.suspendFlow) {
      dispatch(setSuspendFlow(false));
      //SiteAnalytics.contactUsLanding();
      //  navigate('/shop/contact-us', {state: {refUrl : window.location.pathname}})
    }
  }, [pageData?.suspendFlow])
  // useEffect(() => {
  //   if (sectionsConfig && Object.keys(sectionsConfig)?.length > 0) {
  //     let sectionList = [...sectionsConfig];
  //     if (sectionsConfigList?.sections && Object.keys(sectionsConfigList?.sections)?.length > 0) {
  //       Object.keys(sectionsConfigList?.sections).map(sectionName => {
  //         const list = sectionList?.filter(item => item?.title === sectionsConfigList?.sections[sectionName]?.title);
  //         if (list?.length === 0) {
  //           if (sectionName !== "PhoneNumbers" && sectionName !== "EmergencyInfo") {
  //             sectionList[sectionName]=sectionsConfigList?.sections[sectionName];
  //           } else if (sectionName === "PhoneNumbers" && cartData?.showPhoneNumberSection) {
  //             sectionList[sectionName]=sectionsConfigList?.sections[sectionName];
  //           } else if (sectionName === "EmergencyInfo" && cartData?.showE911Section) {
  //             sectionList[sectionName]=sectionsConfigList?.sections[sectionName];
  //           }
  //         }
  //       });
  //       dispatch(setSectionsConfig(sectionList));
  //     }
  //   }
  // }, [])

  useEffect(() => {
    if (cartData && cartData?.orderSummaryOnetimeDueMap) {
      Object.values(cartData?.orderSummaryOnetimeDueMap)?.forEach((val) => {
        if (val.length > 0) {
          setShowOneTime(true);
          dispatch(setOneTimeCharge(true))
        } else {
          setShowOneTime(false);
          dispatch(setOneTimeCharge(false))
        }
      });
    }
  }, [cartData?.orderSummaryOnetimeDueMap]);

  useEffect(() => {
    if (
      paymentPageData?.paymentInfo?.stage === "stage4:FULL_AUTH" &&
      paymentPageData?.paymentInfo?.status === "SUCCESS"
    ) {
      // if (window["digitalData"].isUniqBOS) {
      //   // SiteAnalytics.eventOrderInfoTracking(
      //   //   "Unified Business Order Submitted",
      //   //   cartData,
      //   //   "payment",
      //   //   "",
      //   //   orderDetails?.orderSuccessDetails || orderDetails?.UnifiedCartorderSuccessDetails
      //   // );
      // }
      // orderSuccess && navigate("/shop/confirmation");
    }
  }, [paymentPageData?.paymentInfo]);

  const setInitialState = () => {
    // let sectionList = [];
    // if (sectionsConfigList?.sections && Object.keys(sectionsConfigList?.sections)?.length > 0) {
    //   Object.keys(sectionsConfigList?.sections).map(sectionName => {
    //     if (sectionName !== "PhoneNumbers" && sectionName !== "EmergencyInfo") {
    //       sectionList[sectionName]=sectionsConfigList?.sections[sectionName];
    //     } else if (sectionName === "PhoneNumbers" && cartData?.showPhoneNumberSection) {
    //       sectionList[sectionName]=sectionsConfigList?.sections[sectionName];
    //     } else if (sectionName === "EmergencyInfo" && cartData?.showE911Section) {
    //       sectionList[sectionName]=sectionsConfigList?.sections[sectionName];
    //     }
    //   });
    // }
    // if (sectionList && Object.keys(sectionList)?.length > 0) {
    //   dispatch(setSectionsConfig(sectionList));
    // } else {
    dispatch(setSectionsConfig(sectionsConfigList?.sections));
    dispatch(setShoppingCartPageRevisited(false))
    dispatch(setShoppingCartPageRevisitedLower(false))
    // }

    if (sectionsConfigList?.sections && Object.keys(sectionsConfigList?.sections)?.length > 0 && (isDigitalQuote || isAbandonedCart)) {
      Object.keys(sectionsConfigList?.sections).map(sectionName => {
        if (sectionName == "BusinessVerification" && sectionName.isComplete == true) {
          dispatch(setEditableSections({ sections: ["about-business", "signer-info", "ssn-info", "signer-consent", "fedtax-info"], isEditable: false }));
        }
        if (sectionName == "InstallationAuthorization" && sectionName.isComplete == true) {
          dispatch(setEditableSections({ sections: ["authorization-confirmation"], isEditable: false }));
        }
      });
    }
  };

  const setComplete = ({ sectionId, subsectionId, combosecId, order }) => {
    dispatch(setSectionComplete({ sectionId, subsectionId, combosecId }));
    if (sectionId) dispatch(setInProgressSection(sectionId))
    if (subsectionId) dispatch(setCompletedSection(subsectionId));
    if (combosecId) dispatch(setComBoSectionInProgress(combosecId))
    if (order) dispatch(setCurrentOrder(order))
    dispatch(completedSectionType(combosecId ? "combosecId" : subsectionId ? "subsectionId" : "sectionId"));
    // Commented due to save cart call happened before session cart completes, so implemented along with session cart call
    // if (saveCartSections.includes(subsectionId) || saveCartSections.includes(combosecId)) {
    //   dispatch(postSharedCartSaveUrl());
    // }
  };

  const setInProgress = ({ sectionId, subsectionId, isInProgress }) => {
    dispatch(setSectionInProgress({ sectionId, subsectionId, isInProgress }));
  };

  const setRemoveSection = ({ sectionId, subsectionId, combosecId }) => {
    dispatch(setSectionRemoveComplete({ sectionId, subsectionId, combosecId }));
  };

  const setModifier = ({ sectionId, modifier }) => {
    dispatch(setSectionModifier({ sectionId, modifier }));
  };

  const submitOrder = () => {
    const useMock = checkIfAemExists(t("complete-order-mock", { ns: "mock-response" }));
    dispatch(postCompleteOrder({ jwt: jwt, checkoutNew: true, useMock: useMock, paymentErrorStatusCode: t('payment.paymentNewErrorMessages', { ns: 'payment', returnObjects: true }) }))
  }
  return (
    <>
    {!props?.fromBUSS ? 
      <AccountsModal />:<></>}
      {(paymentPageData?.loading || pageData?.isShoppingCartApiCallInProgress?.postBusinessInformation
        || pageData?.isShoppingCartApiCallInProgress?.postBusinessInfoBussAlone) && <SpinnerComponent />}
      {/* <Header pageName={"Checkout"} />
      {pageData?.notification?.show && <StyledNotification
        buttonData={pageData?.notification?.buttonData}
        children={pageData?.notification?.children}
        fullBleed={true}
        hideCloseButton={pageData?.notification?.hideCloseButton}
        subtitle={pageData?.notification?.subtitle}
        title={
          <span
            dangerouslySetInnerHTML={{
              __html: pageData?.notification?.title,
            }}
          />
        }
        type={pageData?.notification?.type}
      />} */}

      <CartContextProvider
        value={{
          pageData,
          cartData,
          isMobile,
          isLowerFunnel: true

        }}
      >
        <Grid>
          <Row>
            <Col colSizes={{ mobile: 4, tablet: 10, desktop: 10 }}>
              <MarginSpacerL />
              <Title size="XLarge">{"Checkout"}</Title>
              {!isMobile && <MarginSpacerL />}
            </Col>
            <Col colSizes={{ mobile: 4, tablet: 2, desktop: 2 }}>
              <MarginSpacerL />
              <MarginSpacerL />
            </Col>
          </Row>
          <Row>
            <Col colSizes={{ mobile: 4, tablet: 8, desktop: 8 }}>
              <VerticalProgress
                sectionState={sectionsConfig}
                sectionComponents={sectionComponentMap}
                setComplete={setComplete}
                setInProgress={setInProgress}
                setRemoveSection={setRemoveSection}
                setModifier={setModifier}
              />
            </Col>
            <Col colSizes={{ mobile: 4, tablet: 4, desktop: 4 }}>
              {!isMobile ?
                <OrderSummaryDiv>
                  {/* <OrderTitleContainer><Title size="small" bold={true}>{t("app.common.orderSummary.orderSummaryText", { ns: "app" })}</Title></OrderTitleContainer> */}
                  <OrderSummary
                    defaultOpenAccordions={false}
                    isShoppingCart={true}
                    showOneTime={showOneTime}
                    starkEnabled={true}
                    isCheckout={true}
                    excludeFooterContent={true}
                  />
                  <div style={{ height: '150px', padding: '1rem', 'background-color': '#000', color: '#fff' }}>
                    <Body size="small" color="#FFFFFF"> Identify a sales representative to support this order </Body>
                  </div>
                </OrderSummaryDiv> : <></>
              }
              {isMobile ? <>{!isOpened ?
                <OrderSummaryWrapper>
                  <Accordion topLine={false}
                    id="tootltip-setos"
                    viewport="mobile">
                    <AccordionItem opened={false} sticky={true} data-track="Order summary">
                      <div onClick={() => setOpened(true)}>
                        <AccordionHeader
                          trigger={{
                            type: "icon",
                            alignment: "top"
                          }}
                          bold={true}

                        >
                          <AccordionTitle><TitleContainer>{t("app.common.orderSummary.orderSummaryText", { ns: "app" })}</TitleContainer></AccordionTitle>
                        </AccordionHeader>
                      </div>
                    </AccordionItem>
                  </Accordion>
                </OrderSummaryWrapper> :
                <Modal
                  surface="light"
                  fullScreenDialog={isMobile}
                  disableAnimation={true}
                  opened={isOpened}
                  // onOpenedChange={setOpened(false)}
                  closeButton={<></>}
                  disableOutsideClick={false}
                  ariaLabel="Order summary"
                >
                  {/* <ModalTitle> */}
                  {/* <StyledTitle size="large"> */}
                  {/* <StyledContainer> */}
                  <Container onClick={() => setOpened(false)}>
                    <StyledTitle size="titleSmall" bold={true}>
                      <TitleContainer>{t("app.common.orderSummary.orderSummaryText", { ns: "app" })}</TitleContainer>
                      <Icon
                        name="up-caret"
                        size="medium"
                        color="#000000"
                      />
                    </StyledTitle>
                  </Container>


                  {/* </StyledContainer> */}

                  {/* </StyledTitle> */}

                  {/* </ModalTitle> */}
                  <ModalBody>
                    <OrderSummary
                      defaultOpenAccordions={false}
                      isShoppingCart={true}
                      showOneTime={showOneTime}
                      starkEnabled={true}
                      isCheckout={true}
                      excludeFooterContent={true}
                    />
                  </ModalBody>
                </Modal>

              }
              </> : <></>}
            </Col>
          </Row>

          <Row>
            <Col colSizes={{ mobile: 4, tablet: 4, desktop: 4 }}>
              {

                showSubmitBtn && <div style={{ marginLeft: `${isMobile ? "48px" : "34px"}` }}>
                  <StyledButton onClick={() => submitOrder()} data-track={t("app.common.page-stepper.submit-order-btn", { ns: "app" })}>{t("app.common.page-stepper.submit-order-btn", { ns: "app" })}</StyledButton>

                </div>
              }
            </Col>
          </Row>
          <MarginSpacerL />
          <Row>
            <Col colSizes={{ mobile: 4, tablet: 8, desktop: 8 }}>
              <Body size="small">{t("app.common.page-stepper.cartId", { ns: "app" })} {cartData?.cart?.cartId || pageData?.cartId}  </Body>
            </Col>
          </Row>
          <MarginSpacerL />
        </Grid>
      </CartContextProvider >
      {/* <IdleTimer sessionCartData={sessionCartData} /> */}
    </>
  );
};

const StyledNotification = styled(Notification)`
  [class^=ButtonAreaWrapper-sc]{
    max-width:${({ buttonData }) => buttonData ? 'auto' : '20% !important'};
  }
  position: sticky;
  top: 0px;
  z-index: 99;
  > div {
    opacity: 1;
    > div {
      > span:last-child {
          > div {
            > div {
              > div {
                > button {
                  border: none !important;
                  &:hover:not(:active) {
                    box-shadow: none;
                  }
                  > span:last-child {
                      padding : 0 !important;
                    }
                }
              }
            }
          }
       }
      }
    }
  }
`;

const StyledButton = styled(Button)`
${({ isMobile }) => isMobile && `
  width:100%;
`}
${({ isMobile }) => !isMobile && `
  marging-left:32px;
`}
`

const OrderSummaryDiv = styled.div`
  flex-grow: 1;
  min-width: 328px;
  flex-basis: 31.4125%;
  position:sticky;
  top:32px;
  
  .accordionButton{
    padding-top:24px;
      padding-bottom:24px;
  }
`;
const OrderSummaryWrapper = styled.div`
    position: fixed;
    z-index: 99;
    bottom: 0;
    background-color: white;
    width: 100%;
    padding-left:10px;
    padding-right:15px;
    
    .accordionButton{
      padding-top:12px;
      padding-bottom:12px;
      width:94% !important;
    }
    .toggleIconWrapper{
      top:12px !important;
    }
`;

const StyledTitle = styled(Title)`
  background-color: white; 
  `

const Container = styled.div`
  z-index: 99;
  background-color: white;
  width: 95%;
  position:fixed;
  padding-top: 24px;
  padding-bottom: 24px;
  top:0;
  `
const TitleContainer = styled.span`
display:inline-flex;
font-size:16px;
width:94%;
font-weight:bold;
`;

export default CheckoutNew;
