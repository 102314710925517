import React, { Fragment, useState } from "react";
//import { useNavigate } from "react-router-dom";
import { ListItem, UnorderedList } from "@vds3/lists";
import { Modal, ModalTitle, ModalBody } from "@vds3/modals";
import { Title, Body } from "@vds3/typography";
import { MarginSpacerS, MarginSpacerL } from "../styleTag";
import styled from "styled-components";
import { CloseIcon } from "../SVGIcons";
import { Button } from "@vds3/buttons";
import "./PromoBanner.css";
import { useMediaQuery } from "react-responsive";

const IconSpan = styled.span`
	padding-left: 0.2rem;
	padding-top: 0.2rem;
`;
const StyledButton = styled(Button)`
	display: flex;
	span:nth-child(2) {
		padding: 0.5rem 2rem !important;
	}
	@media screen and (max-width: 768px) {
		flex-grow: 1;
	}
`;

const PromoBannerLarge = ({ promoData }) => {
	//const navigate = useNavigate();
	const [isVisible, setIsVisible] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const isMobile = useMediaQuery({
		query: "(max-width: 767px)",
	});

	if (isVisible && promoData.show) {
		return isMobile ? (
			""
		) : (
			<div className="promo-banner-large">
				<div>
					<Title size="large">{promoData?.title}</Title>
					<MarginSpacerS />
					<div className="body-text">
						<Body size="large">{promoData?.description}</Body>
						{promoData?.modal?.show && (
							<IconSpan className="cursor-pointer" onClick={() => setShowModal(true)}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									aria-hidden="true"
									role="img"
									width="1em"
									height="1em"
									preserveAspectRatio="xMidYMid meet"
									viewBox="0 0 1024 1024"
								>
									<path
										fill="currentColor"
										d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372s372 166.6 372 372s-166.6 372-372 372z"
									/>
									<path
										fill="currentColor"
										d="M464 336a48 48 0 1 0 96 0a48 48 0 1 0-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"
									/>
								</svg>
							</IconSpan>
						)}
					</div>
					{promoData?.button?.show && (
						<Fragment>
							<MarginSpacerL />
							<StyledButton use="secondary" onClick={() => {}}>
								{promoData?.button?.text}
							</StyledButton>
						</Fragment>
					)}
				</div>
				<CloseIcon onClickFn={() => setIsVisible(false)} />
				<Modal opened={showModal} onOpenedChange={setShowModal}>
					<ModalTitle children={<Title>{promoData?.modal?.title}</Title>} />
					<ModalBody>
						{Array.isArray(promoData?.modal?.description) &&
							promoData?.modal?.description.map((item) => (
								<>
									<Body size="large">{item.title}</Body>
									{Array.isArray(item?.items) && item.items?.length && (
										<UnorderedList>
											{item.items.map((i) => (
												<ListItem>{i}</ListItem>
											))}
										</UnorderedList>
									)}
									<MarginSpacerL />
								</>
							))}
					</ModalBody>
				</Modal>
			</div>
		);
	} else return <></>;
};

export default PromoBannerLarge;
