import React from "react";
import styled from "styled-components";
import { Button } from "@vds3/buttons";
import { Title } from "@vds3/typography";
import VerticalProgressContext from "./verticalProgressContext";

const VerticalProgressSection = ({
  title,
  isButton,
  isComponent,
  onButtonClick,
  children,
  ...props
}) => {
  const { sectionId, isSectionComplete, isSectionActive, onComplete } =
    React.useContext(VerticalProgressContext);

  return (
    <SectionContainer id={sectionId} className="sectionContainerBuss">
      {isComponent ? (
        <>{children}</>
      ) : isButton ? (
        <div>
          <Button
            size="large"
            disabled={!isSectionActive}
            onClick={onButtonClick}
          >
            {title}
          </Button>
        </div>
      ) : (
        <>
          <Title
            size="large"
            bold
            color={isSectionActive || isSectionComplete ? "#000000" : "#d8dada"}
          >
            {title}
          </Title>
          <>{children}</>
        </>
      )}
    </SectionContainer>
  );
};

const SectionContainer = styled.div`
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1.5rem;
`;

export default VerticalProgressSection;
