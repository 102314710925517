import React from "react";
import { Modal, ModalTitle, ModalBody, ModalFooter } from "@vds3/modals";
import { useTranslation } from "react-i18next";
import { Button } from "@vds3/buttons";
import { Title } from "@vds3/typography";
import { MarginSpacerL } from "../../styleTag";

export const EquipmentLearnMoreModal = ({showEquipmentLearnMoreModal, setShowEquipmentLearnMoreModal, ...props}) => {
    const { t } = useTranslation(["app", "4G-5G"]);

    const handleClick = () => {
      setShowEquipmentLearnMoreModal(false);
      //window.scrollTo(0, 0);
    };

    return (
      <Modal
        opened={showEquipmentLearnMoreModal}
        onOpenedChange={setShowEquipmentLearnMoreModal}
      >
        <ModalTitle>
          <Title size="large" bold={true} >
            {t(
              "4G-5G.fourG.router-content.verizonInternetGatewayModal.heading",
              {
                ns: "4G-5G",
              }
            )}
          </Title>
        </ModalTitle>
        <ModalBody>
          <div
            className="font-16"
            dangerouslySetInnerHTML={{
              __html: `${t(
                "4G-5G.fourG.router-content.verizonInternetGatewayModal.subHeading",
                {
                  ns: "4G-5G",
                }
              )}`,
            }}
          ></div>
        </ModalBody>
        {!!props.isMobile && (
          <ModalFooter
            buttonData={{
              primary: {
                width: "100%",
                children: t("4G-5G.fourG.router-content.verizonInternetGatewayModal.label", { ns: "4G-5G",}),
                onClick: () => setShowEquipmentLearnMoreModal(false),
              },
            }}
          />
        )}
        {!props.isMobile && (
          <ModalFooter
            children={
              <div>
                <div>
                  <Button onClick={e => handleClick()}>{t("4G-5G.fourG.router-content.verizonInternetGatewayModal.label", { ns: "4G-5G",})}</Button>
                </div>
              </div>
            }
          />
        )}
       
      </Modal>
);
  };