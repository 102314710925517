import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalTitle, ModalFooter } from "@vds3/modals";
import { Body, Title } from "@vds3/typography";

function MaxExceededModal(props) {
  const { t } = useTranslation(["payment"]);


  const onOkClicked = () => {
    props.setShowModal(false);
    return;
  };

  const onOpenedChange = (modalState) => {
    if(!modalState) { 
      props?.setShowModal(false); 
    }
  }

  return (
    <>
      {props.showModal && (
        <Modal
          hideCloseButton={false}
          disableOutsideClick={true}
          onOpenedChange={onOpenedChange}
          opened={props.showModal}
        >
          <ModalTitle>
            <Title size="medium">
            {t("payment.maximumAttemptModal.maximumAttemptsMessage", {
          ns: "payment",
        })}
            </Title>
          </ModalTitle>
          <ModalBody>
            <Body size="large">
            {t("payment.maximumAttemptModal.description1", {
          ns: "payment",
        })}
        {t("payment.maximumAttemptModal.description2", {
          ns: "payment",
        })}
                </Body>
          </ModalBody>
          <ModalFooter
            buttonData={{
              primary: {
                children: "Ok",
                onClick: () => {
                  onOkClicked();
                },
              }
            }}
          />
        </Modal>
      )}
    </>
  );
}

export default MaxExceededModal;
