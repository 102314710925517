import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "@vds3/modals";
import { Body, Title } from "@vds3/typography";
import { MarginSpacerM, MarginSpacerS } from "common/components/styleTag";

const MaxDevicesModal = ({
  deviceQuantity,
  opened,
  onOpenedChange,
  ...props
}) => {
  const { t } = useTranslation(["app"]);
  const callText = t(
    "app.common.maximumDevicesExitModal.callExpert.availability"
  );
  const chatText = t(
    "app.common.maximumDevicesExitModal.chatExpert.availability"
  );
  return (
    <Modal
      ariaLabel=""
      opened={opened}
      onOpenedChange={onOpenedChange}
    >
      <Title size="medium">
        {t("app.common.maximumDevicesExitModal.heading", {
          maximumCartDevicesQuantity: deviceQuantity,
        })}
      </Title>
      <MarginSpacerM />
      <Body size="medium" bold>
        {t("app.common.maximumDevicesExitModal.callExpert.header")}
      </Body>
      <MarginSpacerS />
      <p dangerouslySetInnerHTML={{ __html: callText }} />
      <Body size="medium" bold>
        {t("app.common.maximumDevicesExitModal.chatExpert.header")}
      </Body>
      <MarginSpacerS />
      <p dangerouslySetInnerHTML={{ __html: chatText }} />
    </Modal>
  );
};

export default MaxDevicesModal;
