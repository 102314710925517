import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import {
  Accordion,
  AccordionHeader,
  AccordionTitle,
  AccordionItem,
  AccordionDetail,
} from "@vds3/accordions";
import { Title, Body, Micro } from "@vds3/typography";
import { Line } from "@vds3/lines";
import { TileContainer } from "@vds3/tiles";
import {
  MarginSpacerM,
  MarginSpacerL,
  MarginSpacerS,
  MarginSpacerXL,
  MarginTopSpacerS,
} from "common/components/styleTag";
import PaymentCard from "./PaymentCard";
import FWAContext from "../fwaContext";
import { CustomAccordion, AccordionWrapper, AccordionTitleFlex } from "../styles";
import { GridContainer, GridSpanItem } from "../PlanSelection/styles";

const PaymentCardGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => (props.isMobile ? 1 : props.span ? props.span : 4)},
    minmax(158px, 1fr)
  );
  grid-auto-rows: 1fr;
  grid-gap: 10px;
  padding: 0;
  min-height: ${(props) => (props.isMobile ? "132px" : "132px")};
`;

const RouterPayment = ({
  routerDetails,
  backupRouterDetails,
  onPaymentSelect,
  isCollapsed,
  isHavingBackupPlans,
  setIsCollapsed,
  isfromAuth,
  ...props
}) => {
  const { flow, primaryPlan, backupPlan } = useContext(FWAContext);
  const [reintiaterFlag, setReintiaterFlag] = useState(false);
  const { t } = useTranslation(["4G-5G"]);
  const aemKey = flow === "4g" ? "fourG" : "fiveG";
  const aemContent = t("4G-5G", { ns: "4G-5G", returnObjects: true })[aemKey][
    "router-content"
  ];
  const disableBackupPlansFeature = t("4G-5G", {
    ns: "4G-5G",
    returnObjects: true,
  })[aemKey]["disableBackupPlansFeature"];

  const isMobile = useMediaQuery({
    query: "(max-width: 545px)",
  });
  const heading = (isCollapsed && primaryPlan?.selectedRouterPayment) ? aemContent?.selectedRouterPayment : aemContent?.headingRouterPayment;

  const setPaymentSelection = (planType, e) => {
    setReintiaterFlag(!reintiaterFlag);
    const val = e;
    onPaymentSelect(val, planType);
    if(isfromAuth){
      setIsCollapsed(true);
    }
  };
  useEffect(() => {
    if(!isfromAuth) {
      setIsCollapsed(
        (primaryPlan?.selectedPlan &&
          !backupPlan?.selectedPlan &&
          primaryPlan?.selectedRouterPayment) ||
          (!primaryPlan?.selectedPlan &&
            backupPlan?.selectedPlan &&
            backupPlan?.selectedRouterPayment) ||
          (primaryPlan?.selectedPlan &&
            backupPlan?.selectedPlan &&
            primaryPlan?.selectedRouterPayment &&
            backupPlan?.selectedRouterPayment)
      );
    }
  }, [
    primaryPlan?.selectedRouterPayment,
    backupPlan?.selectedRouterPayment,
    primaryPlan?.selectedPlan,
    backupPlan?.selectedPlan,
    reintiaterFlag,
    isfromAuth
  ]);

  const paymentRadioBoxes = useCallback(
    (planType) => {
      let hasPromos = false;
      let retailRouterPrice;
      let offerDiscount;
      let list = [];
      const commonPlan = planType === "primaryPlan" ? primaryPlan : backupPlan;
      const routerSelected = commonPlan?.selectedRouter;
      const commonRouterDetails =
        planType === "primaryPlan" ? routerDetails : backupRouterDetails;
      let index = commonRouterDetails?.deviceList?.findIndex(obj=>obj?.skulist[0]?.sku?.includes(routerSelected));      
      index = (index == -1) ? 0 : index;
      if (
        Array.isArray(commonRouterDetails?.deviceList) &&
        commonRouterDetails?.deviceList?.length &&
        Array.isArray(commonRouterDetails?.deviceList[index]?.skulist)   &&
        commonRouterDetails?.deviceList[index]?.skulist?.length
      ) {
        const pricingDetails =
          commonRouterDetails?.deviceList[index]?.skulist[0]?.pricingDetails;
        if (pricingDetails) {
          if (pricingDetails?.devicePayments) {
            pricingDetails?.devicePayments?.forEach((dppPrice, index) => {
              const id = `devicePayments-${index}`;
              if (dppPrice?.offerDetails) {
                hasPromos = true;
                retailRouterPrice=dppPrice?.offerDetails?.itemOriginalPrice;
                offerDiscount=dppPrice?.offerDetails?.discountAmount
              }
              list.push(
                <PaymentCard
                  id={id}
                  key={id}
                  selected={commonPlan?.selectedRouterPayment === id}
                  isDPP={true}
                  heading={aemContent?.router?.paymonthly}
                  price={dppPrice?.finalPrice}
                  promoPrice={dppPrice?.discountAmount}
                  isPromotion={hasPromos}
                  retailRouterPrice={retailRouterPrice}
                  offerDiscount={offerDiscount}
                  enablePromotion={hasPromos}
                  installments={dppPrice?.nofInstallements}
                  quantity={commonPlan?.quantity}
                  onChange={setPaymentSelection.bind(this, planType)}
                  planType={planType}
                  selectedRouter={commonPlan?.selectedRouter}
                  originalPrice={dppPrice?.price}
                />
              );
            });
          }
          if (pricingDetails?.twoYear) {
            const id = "twoYear";
            list.push(
              <PaymentCard
                id={id}
                key={id}
                selected={commonPlan?.selectedRouterPayment === id}
                isDPP={false}
                heading={"2 year contract"}
                price={pricingDetails?.twoYear?.price}
                isPromotion={false}
                enablePromotion={hasPromos}
                quantity={commonPlan?.quantity}
                onChange={setPaymentSelection.bind(this, planType)}
                planType={planType}
              />
            );
          }
          if (pricingDetails?.zeroYear) {
            const id = "zeroYear";
            list.push(
              <PaymentCard
                id={id}
                key={id}
                selected={commonPlan?.selectedRouterPayment === id}
                isDPP={false}
                heading={aemContent?.router?.paytoday}
                price={pricingDetails?.zeroYear?.price}
                isPromotion={false}
                enablePromotion={hasPromos}
                quantity={commonPlan?.quantity}
                onChange={setPaymentSelection.bind(this, planType)}
                planType={planType}
              />
            );
          }
         
        }
      }
      return list;
    },
    [
      routerDetails,
      backupRouterDetails,
      setPaymentSelection,
      primaryPlan,
      backupPlan,
    ]
  );

  const selectedRouterText = useCallback(
    (planType) => {
      const commonPlan = planType === "primaryPlan" ? primaryPlan : backupPlan;
      if (commonPlan?.selectedRouterPayment) {
        return commonPlan?.selectedRouterPayment === "zeroYear" 
          ? aemContent?.router?.paytoday : commonPlan?.selectedRouterPayment==='twoYear' ? "2 Year Contract" 
          : aemContent?.router?.paymonthly;
      }
    },
    [aemContent, primaryPlan, backupPlan]
  );

  const disabledAccordion =
    !!primaryPlan?.selectedRouter || !!backupPlan?.selectedRouter;

  const get4gEquipmentTitle = (title) => (
    <>
      <Title size="small" bold={false}>
        {title}
      </Title>
      <MarginSpacerL />
    </>
  );

  const CustomAccordionDetail = (planType, title = "", hideTitle = false) => (
    <>
      {flow === "4g" && !hideTitle && get4gEquipmentTitle(title)}
      <PaymentCardGroup
        isMobile={isMobile}
        span={paymentRadioBoxes(planType)?.length}
      >
        {paymentRadioBoxes(planType)}
      </PaymentCardGroup>
    </>
  );

  return (
    <AccordionWrapper disabledAccordion={!disabledAccordion}>
      <CustomAccordion surface="light">
        <AccordionItem opened={!isCollapsed}>
           <AccordionHeader
            trigger={{type: (!!primaryPlan?.selectedRouterPayment ||
            !!backupPlan?.selectedRouterPayment) && !isfromAuth
              ? "link"
              : ""}}
            closeAccordionTriggerLabel="Collapse"
            onClick={() => {
              setIsCollapsed(!isCollapsed);
            }}
            children={
              <>
                <AccordionTitleFlex  isMobile={isMobile}>
                  <Title size={"large"} bold>
                    {heading}
                  </Title>
                  {!disabledAccordion && (
                    <MarginTopSpacerS>
                      <Body size="large" color="#6F7171">
                        {aemContent?.requiredEquipmentSelection}
                      </Body>
                    </MarginTopSpacerS>
                  )}
                </AccordionTitleFlex>
                {!!isCollapsed && (
                  <>
                    <MarginSpacerS />
                    {primaryPlan?.selectedRouterPayment && (
                      <Title size="small" color="#6F7171">
                        {isHavingBackupPlans && (
                          <span style={{ fontWeight: "bold", paddingRight: 5 }}>
                            {`${
                              flow === "4g"
                                ? aemContent?.primaryRouterPayment
                                : aemContent?.routerPayment
                            }:`}
                          </span>
                        )}
                        {selectedRouterText("primaryPlan")}
                      </Title>
                    )}
                    <MarginSpacerS />
                    {backupPlan?.selectedRouterPayment && (
                      <Title size="small" color="#6F7171">
                        {isHavingBackupPlans && (
                          <span
                            style={{ fontWeight: "bold", paddingRight: 5 }}
                          >{`${aemContent?.backupRouterPayment}:`}</span>
                        )}
                        {selectedRouterText("backupPlan")}
                      </Title>
                    )}
                  </>
                )}
              </>
            }
          />  
          <AccordionDetail
            children={
              <>
                {!!routerDetails &&
                  !!primaryPlan?.selectedRouter &&
                  primaryPlan?.selectedRouter !== "byor" && (
                    <GridContainer
                      isMobile={isMobile}
                      style={{ rowGap: "1rem" }}
                    >
                      <GridSpanItem
                        span={(!isMobile && isfromAuth) ? 
                          paymentRadioBoxes("primaryPlan")?.length + 1 :
                          paymentRadioBoxes("primaryPlan")?.length}
                      >
                        {CustomAccordionDetail(
                          "primaryPlan",
                          aemContent?.primaryRouterPayment,
                          disableBackupPlansFeature || !isHavingBackupPlans ? true : false
                        )}
                      </GridSpanItem>
                    </GridContainer>
                  )}
                {!!primaryPlan?.selectedRouter &&
                  !!backupPlan?.selectedRouter && (
                    <>
                      <MarginSpacerXL />
                      <Line type="secondary" />
                      <MarginSpacerXL />
                    </>
                  )}
                {backupRouterDetails &&
                  flow === "4g" &&
                  isHavingBackupPlans &&
                  !!backupPlan?.selectedRouter &&
                  backupPlan?.selectedRouter !== "byor" && (
                    <GridContainer
                      isMobile={isMobile}
                      style={{ rowGap: "1rem" }}
                    >
                      <GridSpanItem
                        span={(!isMobile && isfromAuth) ? 
                          paymentRadioBoxes("backupPlan")?.length + 1 :
                          paymentRadioBoxes("backupPlan")?.length}
                      >
                        {get4gEquipmentTitle(aemContent?.backupRouterPayment)}
                        <TileContainer
                          padding="1rem"
                          aspectRatio="none"
                          backgroundColor="gray"
                        >
                          {CustomAccordionDetail("backupPlan", "", true)}
                        </TileContainer>
                      </GridSpanItem>
                    </GridContainer>
                  )}
              </>
            }
          />
        </AccordionItem>
      </CustomAccordion>
    </AccordionWrapper>
  );
};

export default RouterPayment;
