import { put, takeLatest, select, call, fork, join } from "redux-saga/effects";
import * as API from "../../../containers/APIurls";
import apiClient from "../../../utils/apiClient";
import * as QualificationSlice from "./QualificationSlice";
import * as FourGSlice from "../../Landing4G-V2/ducks/4GSlice";
import * as FiveGSlice from "../../Landing5G-V2/ducks/5GSlice";
import { setSessionCartData } from "app/ducks/GlobalSlice";
import * as SiteAnalytics from "../../../sitecatalyst";

function* getFixedWirelessCoverageCheck(params) {
	const globalData = yield select((state) => state.ProspectWidgets_GlobalData);
	const lqData = yield select((state) => state.ProspectWidgets_LQPage);
	const zip = lqData?.address?.place?.properties?.postalCode?.split("-");
	try {
		yield put(QualificationSlice.setLoopQualLoading(true));
		let requestBody;
		if (globalData?.cmp?.cmpValue) {
			requestBody = {
				cartMeta: {
					shoppingPath: "PROSPECT",
					flow: lqData?.flow,
				},
				address: {
					addressLine1: params.payload?.street,
					city: params.payload?.city,
					state: params.payload?.state,
					zipCode: params.payload?.zipCode,
					zipPlus4: params.payload?.zipPlus4,
				},
				additionalInfo: {
					coveragefor4G: true,
					coveragefor4GHome: true,
					skipCoveragefor5GHome: false,
					addendaCode: globalData?.cmp?.cmpValue,
				},
			};
		} else {
			requestBody = {
				cartMeta: {
					shoppingPath: "PROSPECT",
					flow: lqData?.flow,
				},
				address: {
					addressLine1: params.payload?.street,
					city: params.payload?.city,
					state: params.payload?.state,
					zipCode: params.payload?.zipCode,
					zipPlus4: params.payload?.zipPlus4,
				},
				additionalInfo: {
					coveragefor4G: true,
					coveragefor4GHome: true,
					skipCoveragefor5GHome: false,
				},
			};
		}

		const resp = yield apiClient.post(
			API.DEVICE_FIXED_WIRELESS_COVERAGE_CHECK_URL,
			requestBody
		);
		const lqResp = resp.data;

		if (!lqResp?.serviceStatus?.success) {
			throw Object.assign(new Error("FixedWirelessCheck Failed"), {
				status: resp?.status,
				data: resp?.data,
			});
		}
		yield put(QualificationSlice.setLoopQualFixedWirelessResp(lqResp));
		yield put(QualificationSlice.setAPIError(false));
		yield* setCBandMWW(lqResp);

		if (lqData?.address && lqResp && Object.keys(lqResp)?.length) {
			if (!params.payload?.fromStreetLookup) {
				let loopQualDlDataNew = {
					sessionId: "",
					Qualified_4g: true,
					Qualified4GHome: JSON.parse(lqResp?.response?.qualified4GHome) || false,
					QualifiedCBand: JSON.parse(lqResp?.response?.qualified5GCband) || false,
					QualifiedHSI: JSON.parse(lqResp?.response?.qualifiedHSI) || false,
					Qualified_5gMww: JSON.parse(lqData?.mmw) || false,
					Qualified5GHome: JSON.parse(lqResp?.response?.qualified5GHome) || false,
					QualifiedLTE: true,
					QualifiedFIOS: JSON.parse(lqResp?.response?.qualifiedFios) || false,
					QualifiedFIOSHome: JSON.parse(lqResp?.response?.qualifiedFios) || false,
					Qualified_5g: JSON.parse(lqResp?.response?.qualified5GCband) || false,
				};
				SiteAnalytics.eventNewLoopQual("LQ_Attempts", loopQualDlDataNew);
				if (
					lqResp?.response?.qualified5GHome === true ||
					lqResp?.response?.qualified5GCband
				) {
					yield put(QualificationSlice.setFlow("5g"));
					const requestLoopQualAddress = {
						flow: "5g",
						street: lqData?.address?.place?.properties?.street,
						city: lqData?.address?.place?.properties?.city,
						state: lqData?.address?.place?.properties?.stateCode,
						zipCode: zip?.length > 0 ? zip[0] : zip,
						zipPlus4: zip?.length > 1 ? zip[1] : "",
						eventCorrelationId: lqResp?.eventCorrelationId,
						cband: lqData?.cband,
						mmw: lqData?.mmw,
						digitalQuote: false,
					};
					yield* postLoopQualAddress({ payload: requestLoopQualAddress });
				} else if (lqResp?.response?.qualifiedFios) {
					yield put(QualificationSlice.setFlow("4g"));
					yield put(QualificationSlice.setIsFios(true));
					const requestLQStreetLookup = {
						street: lqData?.address?.place?.properties?.street,
						zip: zip?.length > 0 ? zip[0] : zip,
					};
					yield* postLQStreetLookup({ payload: requestLQStreetLookup });
				} else {
					yield put(QualificationSlice.setFlow("4g"));
					if (lqResp?.response?.qualified4GHome) {
						yield put(
							QualificationSlice.setIsLteBiPlus(
								// lqResp?.response?.qualified4GHome ?? false
								false
							)
						);
					}
					window.sessionStorage.setItem("fivegTofourg", "true");
					const requestLoopQualAddress = {
						flow: "4g",
						street: lqData?.address?.place?.properties?.street,
						city: lqData?.address?.place?.properties?.city,
						state: lqData?.address?.place?.properties?.stateCode,
						zipCode: zip?.length > 0 ? zip[0] : zip,
						zipPlus4: zip?.length > 1 ? zip[1] : "",
						eventCorrelationId: lqResp?.eventCorrelationId,
						cband: lqData?.cband,
						mmw: lqData?.mmw,
						digitalQuote: false,
					};
					yield* postLoopQualAddress({ payload: requestLoopQualAddress });
				}
				SiteAnalytics.eventNewLoopQual("LQ_Success", loopQualDlDataNew);
			} else {
				yield* setUnavailableService(lqResp);
			}
		}
	} catch (err) {
		// error handling
		yield put(QualificationSlice.setAPIError(true));
	} finally {
		yield put(QualificationSlice.setLoopQualLoading(false));
	}
}

function* setCBandMWW(resp) {
	let cband;
	let mmw;
	if (
		resp?.response?.qualified5GHome === true &&
		resp.response?.qualified5GCband
	) {
		//first plan is cband and other two plan is mmw
		cband = true;
		mmw = true;
	} else if (
		resp?.response?.qualified5GHome === true &&
		!resp?.response?.qualified5GCband
	) {
		//pure 5g plan
		cband = false;
		mmw = true;
	} else if (
		resp?.response?.qualified5GHome === false &&
		resp?.response?.qualified5GCband
	) {
		//pure cband cart
		cband = true;
		mmw = false;
	} else if (
		(resp?.response?.qualified === true ||
			resp?.response?.qualified === "true") &&
		resp.response?.qualifiedForCband
	) {
		//first plan is cband and other two plan is mmw
		cband = true;
		mmw = true;
	} else if (
		(resp?.response?.qualified === true ||
			resp?.response?.qualified === "true") &&
		!resp?.response?.qualifiedForCband
	) {
		//pure 5g plan
		cband = false;
		mmw = true;
	} else if (
		(resp?.response?.qualified === false ||
			resp?.response?.qualified === "false") &&
		resp?.response?.qualifiedForCband
	) {
		//pure cband cart
		cband = true;
		mmw = false;
	} else {
		//4g cart
		cband = false;
		mmw = false;
	}
	yield put(QualificationSlice.setCBand(cband));
	yield put(QualificationSlice.setMMW(mmw));
}

function* setUnavailableService(resp) {
	if (resp?.response?.qualified5GHome === true) {
		yield put(QualificationSlice.setUnavailableService("fios-5g"));
	} else if (resp?.response?.qualified5GHome === false) {
		yield put(QualificationSlice.setUnavailableService("fios"));
	}
}

function* postLoopQualAddress(params) {
	const lqData = yield select((state) => state.ProspectWidgets_LQPage);
	const globalData = yield select((state) => state.ProspectWidgets_GlobalData);
	try {
		yield put(QualificationSlice.setLoopQualLoading(true));
		const { city, state, street, zipCode, zipPlus4, email, phoneNumber } =
			params.payload;
		let requestBody;
		let flow = params?.payload?.flow || "4g";
		if (flow === "4g") {
			requestBody = {
				cartMeta: {
					shoppingPath: "PROSPECT",
					flow: flow,
					qualified4GHome: lqData?.BBALQResp?.qualified4GHome,
				},
				address: {
					addressLine1: street ?? "",
					addressLine2: "",
					streetNum: "",
					streetName: "",
					type: "",
					city: city ?? "",
					state: state ?? "",
					zipCode: zipCode ?? "",
					zipPlus4: zipPlus4 ?? "",
					country: "",
				},
				userInfo: {
					email: email ?? "",
					phoneNumber: phoneNumber ?? lqData?.BBALQResp?.telephNoLQ ?? "",
				},
				additionalInfo: {
					eventCorrelationId: params?.payload?.eventCorrelationId ?? "",
					launchType: "FCL",
					locationCode: "",
					showVisaOffer: false,
					userName:
						lqData?.BBALQResp?.userName ||
						globalData?.widgetInputParams?.userid?.value,
					locationId:
						lqData?.BBALQResp?.locationId ||
						globalData?.widgetInputParams?.locid?.value,
					salesCode:
						lqData?.BBALQResp?.salesCode ||
						globalData?.widgetInputParams?.salesCode?.value,
					businessName:
						lqData?.BBALQResp?.businessName ||
						globalData?.widgetInputParams?.businessName?.value,
					firstName:
						lqData?.BBALQResp?.firstName ||
						globalData?.widgetInputParams?.firstName?.value,
					lastName:
						lqData?.BBALQResp?.lastName ||
						globalData?.widgetInputParams?.lastName?.value,
					checkOutLocationId:
						lqData?.BBALQResp?.checkOutLocationId ||
						globalData?.widgetInputParams?.checkOutLocationCode?.value ||
						globalData?.widgetInputParams?.checkoutlocationcode?.value,
					mmw:lqData?.mmw!==null&&lqData?.mmw ? true : false
				},
			};
		}
		if (flow === "5g" || flow === "unified") {
			requestBody = {
				cartMeta: {
					shoppingPath: "PROSPECT",
					flow: "5g",
				},
				address: {
					addressLine1: street ?? "",
					addressLine2: "",
					streetNum: "",
					streetName: "",
					type: "",
					city: city ?? "",
					state: state ?? "",
					zipCode: zipCode ?? "",
					zipPlus4: zipPlus4 ?? "",
					country: "",
				},
				userInfo: {
					email: email ?? "",
					phoneNumber: phoneNumber ?? lqData?.BBALQResp?.telephNoLQ ?? "",
				},
				additionalInfo: {
					eventCorrelationId: params?.payload?.eventCorrelationId,
					launchType: "FCL",
					locationCode: "",
					cband: lqData?.cband,
					maxSpeed:
						lqData?.BBALQResp?.maxSpeed ||
						lqData?.fixedWirelessResponse?.response?.maxSpeed,
					showVisaOffer: false,
					userName:
						lqData?.BBALQResp?.userName ||
						globalData?.widgetInputParams?.userid?.value,
					locationId:
						lqData?.BBALQResp?.locationId ||
						globalData?.widgetInputParams?.locid?.value,
					salesCode:
						lqData?.BBALQResp?.salesCode ||
						globalData?.widgetInputParams?.salesCode?.value,
					businessName:
						lqData?.BBALQResp?.businessName ||
						globalData?.widgetInputParams?.businessName?.value,
					firstName:
						lqData?.BBALQResp?.firstName ||
						globalData?.widgetInputParams?.firstName?.value,
					lastName:
						lqData?.BBALQResp?.lastName ||
						globalData?.widgetInputParams?.lastName?.value,
					checkOutLocationId:
						lqData?.BBALQResp?.checkOutLocationId ||
						globalData?.widgetInputParams?.checkOutLocationCode?.value ||
						globalData?.widgetInputParams?.checkoutlocationcode?.value,
						mmw:lqData?.mmw!==null&&lqData?.mmw ? true : false
				},
			};
		}
		const resp = yield apiClient.post(
			API.CARTCOMP_LOOP_QUAL_ADDRESS_URL,
			requestBody
		);
		const lqResp = resp.data;

		// if (!params?.payload?.digitalQuote) {
		//   yield call(clear4g5gCart, {
		//     payload: { flow: params?.payload?.flow || "5g" },
		//   });
		// }

		yield put(QualificationSlice.setLoopQualAddressResp(lqResp));
	} catch (err) {
		// error handling
	} finally {
		yield put(QualificationSlice.setLoopQualLoading(false));
	}
}

function* postLQStreetLookup(params) {
	try {
		yield put(QualificationSlice.setLoopQualLoading(true));
		const requestBody = {
			streetAddress: params.payload?.street,
			approach: "",
			flow: "4g",
			shoppingPath: "PROSPECT",
			zip: params.payload?.zip,
		};

		const resp = yield apiClient.post(
			API.DEVICE_FIOS_STREET_LOOKUP_URL,
			requestBody
		);
		const lqResp = resp.data;

		yield put(QualificationSlice.setLoopQualStreetLookupResp(lqResp));
	} catch (err) {
		// error handling
	} finally {
		yield put(QualificationSlice.setLoopQualLoading(false));
	}
}

function* postLQUnitLookup(params) {
	try {
		yield put(QualificationSlice.setLoopQualLoading(true));
		const requestBody = {
			addressId: params.payload?.addressId,
			approach: "",
			flow: "4g",
			shoppingPath: "PROSPECT",
			state: params.payload?.state,
			streetAddress: params.payload?.street,
			unit: null,
			zip: params.payload?.zip,
		};

		const resp = yield apiClient.post(
			API.DEVICE_FIOS_UNIT_LOOKUP_URL,
			requestBody
		);
		const lqResp = resp.data;

		yield put(QualificationSlice.setLoopQualUnitLookupResp(lqResp));
	} catch (err) {
		// error handling
	} finally {
		yield put(QualificationSlice.setLoopQualLoading(false));
	}
}

function* postLQFiosQualification(params) {
	try {
		yield put(QualificationSlice.setLoopQualLoading(true));
		const requestBody = {
			addressId: params.payload?.addressId,
			approach: "",
			baseAddressId: params.payload?.baseAddressId,
			city: params?.payload?.city,
			emailId: params?.payload?.email,
			flow: "4g",
			shoppingPath: "PROSPECT",
			state: params?.payload?.state,
			streetAddress: params?.payload?.street,
			telephoneNumber: params?.payload?.phone,
			zipCode: params?.payload?.zip,
		};

		const resp = yield apiClient.post(
			API.DEVICE_FIOS_SUBMIT_FIOS_QUALIFICATION_URL,
			requestBody
		);
		const lqResp = resp.data;

		yield put(QualificationSlice.setLoopQualFiosQualResp(lqResp));
	} catch (err) {
		// error handling
	} finally {
		yield put(QualificationSlice.setLoopQualLoading(false));
	}
}

function* clear4g5gCart(params) {
	try {
		yield put(QualificationSlice.setLoopQualLoading(true));
		const requestBody = {
			approach: "",
			flow: params?.payload?.flow || "5g",
			shoppingPath: "PROSPECT",
			launchType: params?.payload?.launchType,
		};

		yield put(FourGSlice.setAPIError(false));
		yield put(FiveGSlice.setAPIError(false));
		yield put(FourGSlice.reset4GState());
		yield put(FiveGSlice.reset5GState());
		yield put(setSessionCartData({}));
		const resp = yield apiClient.post(
			API.CARTCOMP_CLEAR_4G_5G_CART_URL,
			requestBody
		);

		yield put(QualificationSlice.setClear4g5gCartResp(resp?.data));
	} catch (err) {
		// error handling
		console.error(err);
	} finally {
		yield put(QualificationSlice.setLoopQualLoading(false));
	}
}

function* getLQSessionDetails(params) {
	const lqData = yield select((state) => state.ProspectWidgets_LQPage);
	try {
		const payload = {
			approach: "",
			flow: params?.payload?.flow || "5g",
			shoppingPath: "PROSPECT",
			launchType: "new",
		};
		const cartResp = yield apiClient.post(
			API.CARTCOMP_CLEAR_4G_5G_CART_URL,
			payload
		);

		yield put(QualificationSlice.setClear4g5gCartResp(cartResp?.data));
		yield put(QualificationSlice.setLoopQualLoading(true));
		const requestBody = {
			approach: "",
			flow: params?.payload?.flow || "5g",
			shoppingPath: "PROSPECT",
			lqsessionId: params?.payload?.lqsessionId,
			redirectedFrom: params?.payload?.redirectedFrom,
		};
		const resp = yield apiClient.post(
			API.CARTCOMP_FETCH_BBA_LQ_DETAILS_URL,
			requestBody
		);
		yield put(QualificationSlice.setBBALQResp(resp?.data));
		const data = { response: resp?.data };
		yield* setCBandMWW(data);
		let extLoopQualDlDataNew = {
			sessionId: params?.payload?.lqsessionId,
			Qualified_4g: true,
			Qualified4GHome: JSON.parse(data?.response?.qualified4GHome) || false,
			QualifiedCBand: JSON.parse(data?.response?.qualifiedForCband) || false,
			QualifiedHSI: data?.response?.hsiqualified === "Y" ? true : false,
			Qualified_5gMww: false,
			Qualified5GHome: JSON.parse(data?.response?.qualifiedForCband) || false,
			QualifiedLTE: true,
			QualifiedFIOS: JSON.parse(lqData?.isFios) || false,
			QualifiedFIOSHome: JSON.parse(lqData?.isFios) || false,
			Qualified_5g: JSON.parse(data?.response?.qualifiedForCband) || false,
		};
		SiteAnalytics.eventNewLoopQual("LQ_Attempts", extLoopQualDlDataNew);
		yield put(QualificationSlice.setClear4g5gCartResp(resp?.data));
		const lqPayload = {
			payload: {
				flow: lqData?.flow,
				street: resp?.data?.addressLine1,
				city: resp?.data?.city,
				state: resp?.data?.state,
				zipCode: resp?.data?.zipCode,
				eventCorrelationId:
					resp?.data?.eventCorrelationId ||
					lqData?.fixedWirelessResponse?.eventCorrelationId,
				cband: resp?.data?.qualifiedForCband,
				mmw: lqData?.mmw,
				email: resp?.data?.emailLQ,
				phoneNumber: resp?.data?.telephNoLQ,
			},
		};
		SiteAnalytics.eventNewLoopQual("LQ_Success", extLoopQualDlDataNew);
		//	yield call(getFixedWirelessCoverageCheck, lqPayload);
		yield call(postLoopQualAddress, lqPayload);
	} catch (err) {
		// error handling
	} finally {
		yield put(QualificationSlice.setLoopQualLoading(false));
	}
}

function* getBulkLQSessionDetails(params) {
	try {
		const payload = {
			approach: "",
			flow: params?.payload?.flow || "5g",
			shoppingPath: "PROSPECT",
			launchType: "new",
		};
		const cartResp = yield apiClient.post(
			API.CARTCOMP_CLEAR_4G_5G_CART_URL,
			payload
		);

		yield put(QualificationSlice.setLoopQualLoading(true));
		const requestBody = {
			approach: "",
			shoppingPath: "PROSPECT",
			addresses:params?.payload,
			multipleAddress: true,
			additionalInfo: {
				cband: true,
				mmw: true
             }
		};
		const resp = yield apiClient.post(
			API.CARTCOMP_FETCH_BBA_LQ_BULK_ADDRESS_URL,
			requestBody
		);
		yield put(QualificationSlice.setBBALQBulkAddressResp(resp?.data));
	
	
	} catch (err) {
		// error handling
	} finally {
		yield put(QualificationSlice.setLoopQualLoading(false));
	}
}

export default function* actionWatcher() {
	yield takeLatest(
		QualificationSlice.getFixedWirelessCoverageCheck.type,
		getFixedWirelessCoverageCheck
	);
	yield takeLatest(
		QualificationSlice.postLoopQualAddress.type,
		postLoopQualAddress
	);
	yield takeLatest(
		QualificationSlice.postLQStreetLookup.type,
		postLQStreetLookup
	);
	yield takeLatest(QualificationSlice.postLQUnitLookup.type, postLQUnitLookup);
	yield takeLatest(
		QualificationSlice.postLQFiosQualification.type,
		postLQFiosQualification
	);
	yield takeLatest(QualificationSlice.clear4g5gCart.type, clear4g5gCart);
	yield takeLatest(
		QualificationSlice.getLQSessionDetails.type,
		getLQSessionDetails
	);
	yield takeLatest(
		QualificationSlice.getBulkLQSessionDetails.type,
		getBulkLQSessionDetails
	);
}
