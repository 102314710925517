import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//import { useLocation, useNavigate } from "react-router-dom";
import { BreadcrumbItem, Breadcrumbs } from "@vds3/breadcrumbs";
import styled from "styled-components";

const CustomBreadcrumbs = styled.div`
	padding: 16px 0;
	ol,
	li {
		margin-bottom: 0;
		margin-top: 0;
		@media screen and (max-width: 768px) {
			display: flex;
			align-items: center;
		}
	}
	li a,
	li span {
		line-height: unset;
	}
	font-family: initial;
`;

const CustomBreadcrumb = ({ isProspect, productName, isBogo,isBuss, ...props }) => {
	//const location = useLocation();
	//const navigate = useNavigate();
	const SHOP_PATH = window?.mbtGlobal?.reactUibasePath;
	const [breadcrumbList, setBreadcrumbList] = useState([]);
	const [oneTalkValue, SetOneTalkValue] = useState("");
	const { t } = useTranslation(["app"]);
	const oneTalkUrls = t("one-talk.app.breadcrumbs", {
		ns: "app",
		returnObjects: true,
	});
	const oneTalkCommonUrls = t("one-talk.app.common", {
		ns: "app",
		returnObjects: true,
	});
	const prospectUrls = t("prospect.breadcrumbs", {
		ns: "app",
		returnObjects: true,
	});
	const path = typeof window !== "undefined" && window.location.pathname;

	useEffect(() => {
		if (SHOP_PATH === "/mbt/prospect/") {
			//added for VBGHSA-19332
			SetOneTalkValue(oneTalkCommonUrls.redirectLowerEnvUrl);
		} else {
			SetOneTalkValue(oneTalkCommonUrls.redirectUrl);
		}
	}, []);

	const getBreadcrumbs = (urlList, isOneTalk) => {
		let breadCrumbArr = [],
			tempPathArr = path
				.replace("/", "")
				.split("?")[0]
				.split("/")
				.filter((i) => !!i);
		breadCrumbArr.push({
			name: urlList["business"]?.name,
			value: urlList["business"]?.value,
		});
		if (isOneTalk) {
			breadCrumbArr.push({
				name: urlList["onetalk"]?.name,
				value: oneTalkValue,
			});
		}
		if (isBuss) {
			breadCrumbArr.push({
				name: urlList["buss"]?.name,
				value: urlList["buss"]?.value,
			});
		}

		for (const i of tempPathArr) {
			const isLastItem = tempPathArr.indexOf(i) === tempPathArr.length - 1;
			let item;
			if (i === "business") {
				item = "";
			} else if (i === "devices") {
				item = "all";
			} else if (i === "one-talk") {
				item = "onetalk";
			} else {
				item = i;
			}

			// if (i === "compare") {
			//   continue;
			// }
			if (urlList[item]?.value) {
				if (item === "accessories") {
					breadCrumbArr.push({
						name: urlList?.all?.name,
						value: urlList?.all?.value?.replace("/shop", `${SHOP_PATH}shop`),
					});
				}
				if (props.is5gsmartphones && item === "smartphones") {
					let breadcrumName = item.charAt(0).toUpperCase() + item.slice(1);
					let url = urlList[item]?.value?.replace(`/${item}`, `/5g/${item}`);
					breadCrumbArr.push({
						name: `5G ${breadcrumName}`,
						value: isLastItem ? "" : url?.replace("/shop", `${SHOP_PATH}shop`),
					});
				} else {
					breadCrumbArr.push({
						name: urlList[item].name,
						value: isLastItem
							? ""
							: urlList[item]?.value?.replace("/shop", `${SHOP_PATH}shop`),
					});
				}
			} else if (i && isLastItem) {
				// handle last node for brand name/product detail
				const name =
					String(i).toLowerCase() === "5g"
						? "5G"
						: productName || i.charAt(0).toUpperCase() + i.slice(1);
				breadCrumbArr.push({
					name: name,
					value: window.location.pathname,
				});
			}
			// remove duplicates
			if (
				breadCrumbArr[breadCrumbArr.length - 1]?.name ===
				breadCrumbArr[breadCrumbArr.length - 2]?.name
			) {
				breadCrumbArr = breadCrumbArr.slice(0, breadCrumbArr.length - 1);
			}
		}

		return breadCrumbArr;
	};

	useEffect(() => {
		if(isBuss){
			const bread = {
			"baseUrl": "/",
			"business": {
				"name": "Shop",
				"value": "https://www.verizon.com/business/"
			},
			"buss": {
				"name": "Verizon Business Marketplace",
				"value": ""
			}
			}
			const abc = getBreadcrumbs(bread)
			setBreadcrumbList(abc);
		}else if (isProspect) {
			setBreadcrumbList(getBreadcrumbs(prospectUrls));
		} else {
			setBreadcrumbList(getBreadcrumbs(oneTalkUrls, true));
		}
	}, [isProspect, productName,isBuss]);

	const getBreadcrumbItem = (item, index) => {
		return (
			<BreadcrumbItem
				key={`${index}-${item.name}-${item.value}`}
				onClick={() => {
					if (item.name === "One Talk") {
						window.location.href = oneTalkValue;
					} else if (item.value?.slice(0, 4) === "http") {
						window.location.href = item.value;
						return;
					} else {
						let target = item.value.replace("/mbt/prospect", "");
						target = target.replace("/business", "");
						//navigate(target, { relative: "path" });
					}
				}}
			>
				{item.name}
			</BreadcrumbItem>
		);
	};

	return (
		<CustomBreadcrumbs className="breadcrumb-container" {...props}>
			<Breadcrumbs inverted={false}>
				{isBogo
					? breadcrumbList
							?.filter((item, index) => index !== 1)
							?.map((item, index) => getBreadcrumbItem(item, index))
					: breadcrumbList?.map((item, index) => getBreadcrumbItem(item, index))}
			</Breadcrumbs>
		</CustomBreadcrumbs>
	);
};

export default React.memo(CustomBreadcrumb);
