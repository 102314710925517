import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
//import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Button, TextLink } from "@vds3/buttons";
import { Col, Grid, Row } from "@vds3/grids";
import { Line } from "@vds3/lines";
import { Notification } from "@vds3/notifications";
import { Tab, Tabs } from "@vds3/tabs";
import { LayoutTokens } from "@vds-tokens/layout";
import useGlobalData from "hooks/useGlobalData";
import { Carousel } from "@vds3/carousels";
import Header from "common/components/Header/Header";
import SpinnerComponent from "common/components/Spinner/Spinner";
import { MarginSpacer2XL, MarginSpacerM, MarginSpacerS, MarginSpacerXL, MarginSpacerRightXS } from "common/components/styleTag";
import VideoModal from "common/components/VideoModal/VideoModal";
import { Body, Title } from "@vds3/typography";
import {
  HeroSection,
  PlanSelectionNew,
  EquipmentSection,
  RouterPayment,
  IncludedInOrder,
  EquipmentSectionNew,
} from "common/components/FWA";
import { FWAContextProvider } from "common/components/FWA/fwaContext";
import {
  reset4GState,
  setQuantity,
  setSelectedPlan,
  setSelectedRouter,
  setSelectedRouterPayment,
  setPrevSelectedRouterPayment,
  get4GPageLoadData,
  get4gRouterDevices,
  setAPIError,
  setCappedPlans,
  setTabIndex,
  setUncappedPlans,
  update4GRouter,
  update4GPlan,
  addOrUpdate4GPage,
  addPlanAndRouter,
  setBackupPlans,
  setIsEditLTEPrimaryPlansFlow,
  setIsEditLTEBackupPlansFlow,
  setIsEditLTEEquipmentFlow,
  setIsFrom4GLearn,
  update4GPlanAndFetchRouters,
  setIsIntcmpLearn,
  updateFCCTemplate,
  updateFCCJson,
  setLteLearnShowAddressModal,
  setAddressMicroUnit,
  setLoopCallFromLearnPageCompleted,
  getPlanPreselection,
  setAddtoCartResponse,
  getTaxesCharges,
  getComibnedPlanAndRouters,
  updateFCCJsonNew,
  getCombinedAemData,
  setPlansDetailsFcc,
  setTriggerTaxesApi,
  setJOInternetSelected,
  setFWABundleTabSelected,
  setpackageCompatible,
  clearCartAndAdd4G
} from "./ducks/4GSlice";
import {
  setFlow,
  setBBALQResp,
  setAddress,
  setLoopQualAddressResp,
  setLoopQualStreetLookupResp,
  getLQSessionDetails,
  clear4g5gCart,
  postLoopQualAddress
} from "../../pages/Qualification/ducks/QualificationSlice";
//import { StickyHeaderVds3, SEOTagger } from "common/components";
import styled from "styled-components";
import "./LandingPage4G.css";
import * as SiteAnalytics from "../../sitecatalyst";
import { resetCheckoutState } from "pages/Checkout/ducks/CheckoutSlice";
import { setContainerHeightBB, setLoadFWAQualifiedPlans, setLoadQualficationSelection, setLoadShoppingCartWidget, setNoThanksClicked } from "app/ducks/GlobalSlice";
import { useImageUtils, useStickyHeaderHeight } from "hooks";
import { MaxDevicesModal } from "pages/ShoppingCart/components/Modals";
// import NewCustomerAddressModal from "common/components/Modals/NewCustomerAddressModal";
import { lqData } from "pages/Checkout/ducks/selectors";
import { Tilelet } from "@vds3/tiles";
import JointOfferDetails from "../../common/components/FWA/PlanSelection/JointOfferDetails";
import StickyHeaderVds3 from "common/components/StickyHeader/StickyHeaderVds3";
import AlreadyACustomer from "common/components/AlreadyACustomer/AlreadyACustomer";
import ChangeAddress from "common/components/FWA/Modals/ChangeAddress";
import FiveGCbandModal from "common/components/Modals/FiveGCbandModal";
import PackageCompatibleModal from "common/components/Modals/PackageIncompatibleModal";
import { base64ToJson } from "app/ducks/utils";
import JointOfferDetailsNew from "common/components/FWA/PlanSelectionNew/JointOfferDetailsNew";

const CheckoutButton = styled(Button)`
  
  height: 44px;
  padding: 12px 0;
  @media screen and (max-width: 576px) {
    width: 100%;
  }
`;
const StyledRow = styled(Row)`
background-color:#FFFFFF
`

const ChatDiv = styled.div`
  margin-top: 8px;
  margin-right: 20px;
  display:flex;
  justify-content:flex-end;
`;

const authHeaderTxt = "Good news: Verizon LTE Business Internet is available.";
const authSubHeaderTxt =
  "The plan that you selected requires professional installation. Since you’re unable to acquire approval, we recommend that you choose one of the plans below because you’ll be able to install the equipment yourself - no permissions required.";
const authSubHeaderTxt2 = "Enjoy reliable wireless connectivity virtually anywhere you do business on Verizon's 4G LTE network"

const LandingPage4G = ({ ...props }) => {
  const dispatch = useDispatch();
  //const navigate = useNavigate();
  const { t, ready } = useTranslation(["app", "4G-5G"], { useSuspense: false });
  const aemContent = t("4G-5G", { ns: "4G-5G", returnObjects: true });
  // const { metaInformation } = useGlobalData()
  const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
  const pageData = useSelector((state) => state.ProspectWidgets_4GPlansData);
  const LQData = useSelector((state) => state.ProspectWidgets_LQPage);
  const [showCartNudgeModal, setshowCartNudgeModal] = useState(false);
  const BBALQResp = useSelector((state) => state.ProspectWidgets_LQPage?.BBALQResp);
  const CheckoutData = useSelector((state) => state.ProspectWidgets_CheckoutData);
  const unifiedCartParams = useSelector(
    (state) => state.ProspectWidgets_CheckoutData?.unifiedCartParams
  );
  const sessionCartData =
    globalData?.sessionCartData?.apiResponse?.prospectFlowData;
  const globalPromos =
    globalData?.metaInformation?.globalPromos &&
    globalData?.metaInformation?.globalPromos["4g-plans"];
  const headerType = (BBALQResp?.headerType) ? BBALQResp?.headerType : "DEFAULT";
  const isDigitalQuote = window.sessionStorage.getItem("isDigitalQuote");
  //const isLTEPlus = !!LQData?.isLteBiPlus;
  const isLTEPlus = false;
  const primaryPlan = useSelector((state) => state.ProspectWidgets_4GPlansData.primaryPlan);
  const backupPlan = useSelector((state) => state.ProspectWidgets_4GPlansData.backupPlan);
  const isIntcmpLearn = useSelector((state) => state.ProspectWidgets_4GPlansData.isIntcmpLearn);
  const addressMicroUnitVal = useSelector((state) => state.ProspectWidgets_4GPlansData.addressMicroUnit);
  const loopCallFromLearnPageCompleted = useSelector((state) => state.ProspectWidgets_4GPlansData.loopCallFromLearnPageCompleted);
  const [showChangeAddressModal, setChangeAddressModal] = useState(false);
  const [showSetupVideoModal, setShowSetupVideoModal] = useState(false);
  const [collapsePlans, setCollapsePlans] = useState(false);
  const [selectPlanErr, setSelectPlanErr] = useState(false);
  const [fccVisited, setFccVisited] = useState(false);
  const [selectRouterErr, setSelectRouterErr] = useState(false);
  const [selectPaymentErr, setSelectPaymentErr] = useState(false);
  const [collapseEquipment, setCollapseEquipment] = useState(false);
  const [taxCompleted, setTaxCompleted] = useState(false);
  const [collapsePayment, setCollapsePayment] = useState(false);
  const [addToCartPayload, setAddToCartPayload] = useState("");
  const [isPlansBackupSectionCollapsed, setIsPlansBackupSectionCollapsed] =
    useState(true);
  const planDetailsFccNewObj = useSelector((state) => state.ProspectWidgets_4GPlansData.planDetailsFccNewObj);

  // const [searchParams, setSearchParams] = useSearchParams();
  //const { state } = useLocation();
  const [changedPlans, setChangedPlans] = useState(false);

  // const learnBackupPlanCode = searchParams.get("backupPricePlanCode");
  const lqsessionId = ""// searchParams.get("lqsessionId");
  const redirectedFrom = ""// searchParams.get("redirectedFrom");
  const newPricePlanCode = ""// searchParams.get("pricePlanCode");
  const fromPage = ""//searchParams.get("from");
  const learnPlanCode =
    useSelector((state) => state.ProspectWidgets_GlobalData?.queryParams?.pricePlanCode) ||
    "";//searchParams.get("pricePlanCode");
  const learnIntcmp = useSelector((state) => state.ProspectWidgets_GlobalData?.queryParams?.intcmp) ||
    "";//searchParams.get("intcmp");
  const learnBackupPlanCode =
    useSelector((state) => state.ProspectWidgets_GlobalData?.queryParams?.backupPricePlanCode) ||
    "";//searchParams.get("backupPricePlanCode");
  const validateSimIdResp = useSelector(
    (state) => state?.ProspectWidgets_4GPlansData?.validateSimIdResp
  );
  const isLqGbcom = useSelector((state) => state.ProspectWidgets_GlobalData?.queryParams?.redirectedFrom) === "GBCOM" ||
    "";//searchParams.get("GBCOM");
  const [fourgModal, setFourGModal] = useState(false);
  const fromSaveCartLteAvailble = useSelector((state) => state.ProspectWidgets_4GPlansData.saveCartLteAvailble);
  const { metaInformation } = useGlobalData({ clear4g5g: false });
  const isHeaderType5gorFios = (headerType === "FIOS" || headerType === "5G" || headerType === "BBA" || headerType == "DEFAULT") ? true : false;
  const [showNewCustomerModal, setShowNewCustomerModal] = useState(false);
  const { getImageLink } = useImageUtils();
  const routerImage = aemContent?.fourG?.lte?.routerImage;
  const checkForArisRouter = primaryPlan?.selectedRouter === 'NVG558HX' || false;
  const [isFwaBundleSelected, setIsFwaBundleSelected] = useState(false)
  const [isPlanError, setIsPlanError] = useState(0);
  const selectFwaBundletabForJO = sessionCartData?.selectFwaBundletabForJO;
  const [jointOfferModal, setJointOfferModal] = useState(false)
  const [jointOfferList, setJointOfferList] = useState([])
  const jointOfferAem = t("4G-5G", { ns: "4G-5G", flow: 'LTE', returnObjects: true });

  const addressMicroUnit = "";// searchParams.get("addressMicroUnit");
  const addressJson = addressMicroUnit ? base64ToJson(addressMicroUnit) : '';
  const FLOW = "4g";
  if (false) {
    if (!window.localStorage.getItem("lqsessionId")) {
      window.localStorage.setItem("lqsessionId", lqsessionId);
    } else if (lqsessionId !== window.localStorage.getItem("lqsessionId")) {
      window.localStorage.setItem("lqsessionId", lqsessionId);
    }
  }
  if (false) {
    if (!window.sessionStorage.getItem("newPricePlanCode")) {
      window.sessionStorage.setItem("newPricePlanCode", newPricePlanCode);
    }
    if (!window.sessionStorage.getItem("lqsessionIdNew")) {
      window.sessionStorage.setItem("lqsessionIdNew", lqsessionId);
    }
  }
  const [showMaxDeviceModal, setMaxDeviceModal] = useState(false);
  const maxDeviceQuantity = 10;
  const planPreselection = useSelector((state) => state.ProspectWidgets_4GPlansData.planPreselection);
  const addtoCartResponse = useSelector((state) => state.ProspectWidgets_4GPlansData.addtoCartResponse);
  const isPDPLayoutFWA = t("4G-5G.isPDPLayoutFWA", { ns: "4G-5G" });

  const isOptimizationEnabled = !!(
    window && window?.mbtGlobal?.flags?.ENABLE_REACT_OPTIMISATION === "Y"
  );

  const isAddonsEnabled = !!(window && window?.mbtGlobal?.flags?.ENABLE_SECURITY_PLUSPREFERRED?.includes("FOURG")) || !!(
    window && window?.mbtGlobal?.flags?.ENABLE_BACKUP_ADDON === "Y"
  );
  const showBussProductPage = !!(
    window && window?.mbtGlobal?.flags?.PROSPECT_SHOW_BUSS_PRODUCTS_4G_5G === "Y"
  ) || sessionCartData?.showBussProducts;

  const [isAddonPageNeeded, setIsAddonPageNeeded] = useState(false);
  const [isBussPageNeeded, setIsBussPageNeeded] = useState(false);
  const RemoveCollapsePlanAccordion = t(
    "app.common.orderSummary.isRemoveCollapsePlanAccordion",
    { ns: "app" }
  );
  const isLearnPlanCode = ((learnPlanCode !== null && learnPlanCode !== undefined) && RemoveCollapsePlanAccordion) ? true : false;
  const isComparePlanCode = (learnPlanCode !== primaryPlan?.selectedPlan) ? false : true;
  const displayOnlyInternetMobileTab = LQData.isAddressChange ? (sessionCartData?.showLinkForMixedCart && pageData?.showCheckAvailabiltyLink) || (sessionCartData?.selectFwaBundletabForJO) :
    (sessionCartData?.showLinkForMixedCart && pageData?.showCheckAvailabiltyLink) || (sessionCartData?.selectFwaBundletabForJO && pageData?.showInternetMobilePlan);
  const [continueClicked, setContinueClicked] = useState(false);
  const prePlanSelectedFlowEnabled = aemContent?.prePlanSelectedFlowEnabled;
  const [isShoppingIconClicked, setIsShoppingIconClicked] = useState(false);
  const [isNewFlow, setisNewFlow] = useState(true);
  const showShoppingIcon = sessionCartData?.cart?.packages !== null && sessionCartData?.cart?.packages && Object.values(sessionCartData?.cart?.packages)?.length > 0 ? true : false;
  // const oneApiCall = aemContent?.oneApiCall;
  const oneApiCall = t(
    "app.common.oneApiCall",
    { ns: "app" }
  );
  const shoppingCartRedirect = sessionCartData?.showLinkForMixedCart && pageData?.showCheckAvailabiltyLink;
  const hasPackages = sessionCartData?.cart?.packages != null && Object.values(sessionCartData?.cart?.packages)?.length > 0 ? true : false;
  const hasRegularPackage = hasPackages ? Object.keys(sessionCartData?.cart?.packages).filter(packageKey => sessionCartData?.cart?.packages[packageKey]?.packageType == "REGULAR").length > 0 : false;
  const hasInternetPackage = hasPackages ? Object.keys(sessionCartData?.cart?.packages).filter(packageKey => sessionCartData?.cart?.packages[packageKey]?.packageType == "FOURG").length > 0 : false;

  const isMobile = useMediaQuery({
    query: "(max-width: 545px)",
  });
  const [timer, setTimer] = useState(30);
  const { stickyHeaderRef, headerHeight } = useStickyHeaderHeight(sessionCartData);
  const onlyBandQualified = false;//(aemContent?.fourG?.enableBandPayment ?? false)
  const updatePlan = (planCode, planType) => {
    const request = {
      plan: planCode,
      planType,
      plansResp: pageData?.planDetails,
      sessionCartData: sessionCartData,
      groupId: pageData?.grpId ?? "",
      fwaBundleSelected: isFwaBundleSelected
    };
    if (isOptimizationEnabled) {
      dispatch(update4GPlanAndFetchRouters(request));
    } else {
      dispatch(update4GPlan(request));
    }
  };
  const [planSelected, setPlanSelected] = useState(null);
  const updateRouter = (paymentMethod, isByor, planType, name) => {
    const commonPlan = planType === "primaryPlan" ? primaryPlan : backupPlan;
    const commonRouterDetails =
      planType === "primaryPlan"
        ? primaryPlan?.routerDetails
        : backupPlan?.routerDetails;
    const byor = !!(commonPlan?.selectedRouter === "byor") || isByor;
    const routerSelected = commonPlan?.selectedRouter === 'NVG558HX' ? name : commonPlan?.selectedRouter;
    dispatch(setPrevSelectedRouterPayment({ planType, value: paymentMethod }));
    const index = commonRouterDetails?.deviceList?.findIndex(obj => obj?.skulist[0]?.sku?.includes(routerSelected));
    const deviceInfo = byor
      ? pageData?.validateDeviceIdResp?.deviceInfo
      : (index !== -1) ? commonPlan?.routerDetails?.deviceList[index] : commonPlan?.routerDetails?.deviceList[0];
    const pricingType = byor
      ? null
      : paymentMethod ?? commonPlan?.selectedRouterPayment;
    const simNumber = validateSimIdResp?.simValidationInfo?.simNumber;
    const request = {
      flow: FLOW,
      sessionCartData: sessionCartData,
      deviceInfo: deviceInfo,
      pricingType: pricingType,
      byor: byor,
      newSim: simNumber ? false : true,
      quantity: commonPlan?.quantity,
      planType: planType,
      routerDetails: commonRouterDetails,
      simNumber: simNumber,
      routerSelected: routerSelected,
      fwaBundleSelected: isFwaBundleSelected
    };
    dispatch(update4GRouter(request));
  };

  const addPlanAndRouterFromLearn = (planCode, planType) => {
    const commonPlan = planType === "primaryPlan" ? primaryPlan : backupPlan;
    const request = {
      planId: planCode,
      planType,
      device: commonPlan.routerDetails?.deviceList[0],
      priceType: "devicePayments-0",
    };
    dispatch(addPlanAndRouter(request));
  };

  useEffect(() => {
    if (LQData?.load4GPlans) {
      load4GData();
    }
  }, [lqData?.load4GPlans])

  useEffect(() => {
    dispatch(setAddress(""));
    dispatch(setLoopQualAddressResp({}));
    dispatch(setLoopQualStreetLookupResp({}));
    if (redirectedFrom) {
      dispatch(resetCheckoutState());
    }
    if (lqsessionId) {
      dispatch(getLQSessionDetails({ lqsessionId, redirectedFrom, isFrom4GPage: true }));
    }
    if (learnPlanCode || learnBackupPlanCode) {
      dispatch(setIsFrom4GLearn(true));
    }
    if (learnIntcmp) {
      dispatch(setIsIntcmpLearn(true));
    }

    if (fromSaveCartLteAvailble) {
      dispatch(clear4g5gCart({
        payload: { flow: params?.payload?.flow || "5g", fromSaveCartLteAvailble: true },
      }));
    }

    // for (const param of searchParams) {
    //   dispatch(setQueryParam({
    //     name: param[0],
    //     value: param[1] ?? true
    //   }));
    // }
    // if (searchParams?.size) {
    //   //setSearchParams({}, { replace: true });
    // }
    window.sessionStorage.setItem("_flowName", "4g Business Internet");
    SiteAnalytics.trackPageInfo("4G plan");
  }, []);

  // useEffect(() =>{
  //   if(globalData?.metaInformation && Object.keys(globalData?.metaInformation)?.length > 0){
  //     //dispatch(getPlanPreselection());
  //   }
  // },[globalData?.metaInformation])

  useEffect(() => {
    if (pageData?.toggleStatusForCBandRouter && Object.keys(pageData?.toggleStatusForCBandRouter)?.length > 0) {
      if (primaryPlan?.selectedPlan && primaryPlan?.selectedRouter && primaryPlan?.selectedRouterPayment) {
        if (primaryPlan?.selectedRouter in pageData?.toggleStatusForCBandRouter) {
          dispatch(setSelectedRouterPayment({
            planType: "primaryPlan",
            value: pageData?.toggleStatusForCBandRouter[primaryPlan?.selectedRouter]
              ? "devicePayments-0"
              : "zeroYear",
          })
          );
        }
      }
    }
  }, [pageData?.toggleStatusForCBandRouter])

  useEffect(() => {
    if (addressJson && Object.keys(addressJson)?.length > 0) {
      dispatch(setAddressMicroUnit(addressJson))
      dispatch(setLteLearnShowAddressModal(false))
    }
    if (addressMicroUnitVal && Object.keys(addressMicroUnitVal)?.length > 0 && globalData?.metaInformation && !loopCallFromLearnPageCompleted) {
      const requestLoopQualAddress = {
        flow: "4g",
        street: addressMicroUnitVal?.addressLine1 || "",
        city: addressMicroUnitVal?.city || "",
        state: addressMicroUnitVal?.state || "",
        zipCode: addressMicroUnitVal?.zipCode || addressMicroUnitVal?.zipCodeVal || "",
        zipPlus4: "",
        eventCorrelationId: "",
        cband: "",
        mmw: "",
        addressModal: true
      };
      dispatch(postLoopQualAddress(requestLoopQualAddress));
      dispatch(setLoopCallFromLearnPageCompleted(true));
      dispatch(setLteLearnShowAddressModal(false))
    }

  }, [addressJson, addressMicroUnitVal, globalData?.metaInformation])


  useEffect(() => {
    if (continueClicked && addtoCartResponse?.success && addtoCartResponse?.statusCode === 200) {
      dispatch(setAddtoCartResponse({}));
      SiteAnalytics.eventAddtocartTracking('Add To Cart', '', '4g5g', '4g');
      //dispatch(resetQueryParams());
      if (aemContent?.newJointOfferBundleFlow?.isNewJOFlowEnabled && planSelected == "internet") {
        //navigate("/shop/shopping-cart");
        dispatch(setLoadShoppingCartWidget(true));
        const currentUrl = window.location.href;
		const newUrl = new URL(currentUrl);
		newUrl.searchParams.set("pageName", "ShoppingCart");
		window.history.pushState({}, '', newUrl);
      }
      else if (isFwaBundleSelected && !displayOnlyInternetMobileTab && !selectFwaBundletabForJO && !isShoppingIconClicked) {
        // dispatch(setIsCustomerModalVisited(true))
        //dispatch(setIsLandingFromInternetWithMobile(true));
        //navigate("/shop/products/plans?deviceType=smartphones&planid=67792&q=1");
        // dispatch(setIsFwa(true))
      }
      else if (showBussProductPage && !isBussPageNeeded && !displayOnlyInternetMobileTab && !selectFwaBundletabForJO && !isShoppingIconClicked) {
        //dispatch(setIsFromFwa('4g'));
        // navigate("/shop/buss-products");
      }
      else {
        // globalData?.isWirelessToFwaFlow || selectFwaBundletabForJO || isShoppingIconClicked ? navigate("/shop/shopping-cart") : navigate("/shop/products/networks/connectivity/lte-business-internet/addons");
        dispatch(setLoadShoppingCartWidget(true));
        const currentUrl = window.location.href;
		const newUrl = new URL(currentUrl);
		newUrl.searchParams.set("pageName", "ShoppingCart");
		window.history.pushState({}, '', newUrl);
      }
      setIsShoppingIconClicked(false)
      if (aemContent?.newJointOfferBundleFlow?.isNewJOFlowEnabled && planSelected == null) {
        dispatch(setJOInternetSelected(null));
      }
    } else if (!addtoCartResponse?.success && addtoCartResponse?.statusCode === 424 && addtoCartResponse?.statusMessage === "Quantity validation failed | Number of lines is more than 9") {
      setMaxDeviceModal(true);
    }
  }, [continueClicked, addtoCartResponse])



  useEffect(() => {
    load4GData();
    try {
      if (typeof window['lpTag'] != 'undefined' && lpTag?.section && window?.lpTag?.newPage) {
        lpTag.section = manipulateLpTagSections();
        setTimeout(() => {
          window?.lpTag?.newPage(window?.location?.href, {
            section: lpTag.section,
          });
        }, 8000);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() =>{
    if(true && sessionCartData?.prospect5gLoopQualAddress && Object.keys(sessionCartData?.prospect5gLoopQualAddress)?.length >0 && pageData?.planDetails?.plans && Object.keys(pageData?.planDetails?.plans)?.length>0){
      const payload = {
        planIds : pageData?.planDetails?.plans,
        address : sessionCartData?.prospect5gLoopQualAddress
      }
      dispatch(getTaxesCharges(payload));
    }
  },[sessionCartData?.prospect5gLoopQualAddress,pageData?.planDetails?.plans])

  useEffect(() => {
    if (globalData?.metaInformation) {
      // const isRefererFromOthers = !!document?.referrer;
      // if (isRefererFromOthers && !sessionCartData?.prospect5gLoopQualAddress 
      //   && !(lqsessionId || globalData.queryParams?.lqsessionId)
      //   && !(pageData?.isFrom4GLearn || learnPlanCode || learnBackupPlanCode) && !state?.fromFiosOption
      //   ){ navigate("/shop/solutions/qualification"); }
      // load4GData();
    }
  }, [globalData?.metaInformation]);

  const load4GData = () => {
    if (oneApiCall) {
      dispatch(getComibnedPlanAndRouters({
        plans: {
          enabled: !pageData?.cappedPlans?.length,
          request: { ...LQData, internetAndMobile: displayOnlyInternetMobileTab || false, planId: newPricePlanCode || null || primaryPlan?.selectedPlan }
        }
      }))
      if (aemContent?.fourG?.combinedAemDataFlag) {
        dispatch(getCombinedAemData());
        dispatch(setTriggerTaxesApi(true))
      } else {
        dispatch(updateFCCTemplate());
        dispatch(updateFCCJsonNew());
      }
      if (window?.manipulateLpTagSections && window?.lpTag?.newPage) {
        var pageSections = window?.manipulateLpTagSections();
        window?.lpTag?.newPage(window?.location?.href, {
          section: pageSections,
        });
      }
      if (LQData?.flow === "5g") {
        dispatch(setFlow("4g"));
      }
      return () => {
        dispatch(setBackupPlans([]));
        dispatch(setCappedPlans([]));
        dispatch(setTabIndex(0));
        dispatch(setUncappedPlans([]));
        dispatch(setAPIError(false));
        dispatch(setIsEditLTEPrimaryPlansFlow(false));
        dispatch(setIsEditLTEBackupPlansFlow(false));
        dispatch(setIsEditLTEEquipmentFlow(false));
      };
    } else {
      dispatch(
        get4GPageLoadData({
          plans: {
            enabled: !pageData?.cappedPlans?.length,
            request: { ...LQData, internetAndMobile: displayOnlyInternetMobileTab || false }
          },
          routers: {
            enabled: true,
            request: {
              pricePlanCode:
                learnBackupPlanCode ||
                  newPricePlanCode ? newPricePlanCode : learnPlanCode ||
                primaryPlan?.selectedPlan,
              planType: learnBackupPlanCode ? "backupPlan" : "primaryPlan",
            },
          },
        })
      );
      if (aemContent?.fourG?.combinedAemDataFlag) {
        dispatch(getCombinedAemData());
      } else {
        dispatch(updateFCCTemplate());
        dispatch(updateFCCJsonNew());
      }
      if (window?.manipulateLpTagSections && window?.lpTag?.newPage) {
        var pageSections = window?.manipulateLpTagSections();
        window?.lpTag?.newPage(window?.location?.href, {
          section: pageSections,
        });
      }
      if (LQData?.flow === "5g") {
        dispatch(setFlow("4g"));
      }
      return () => {
        dispatch(setBackupPlans([]));
        dispatch(setCappedPlans([]));
        dispatch(setTabIndex(0));
        dispatch(setUncappedPlans([]));
        dispatch(setAPIError(false));
        dispatch(setIsEditLTEPrimaryPlansFlow(false));
        dispatch(setIsEditLTEBackupPlansFlow(false));
        dispatch(setIsEditLTEEquipmentFlow(false));
      };
    }
  }

  useEffect(() => {
    if (document.getElementById("fourgSetupVideoLink"))
      document
        .getElementById("fourgSetupVideoLink")
        .addEventListener("click", openVideoModal);
  });

  useEffect( () => {
    const currentUrl = window.location.href;
    const newUrl = new URL(currentUrl)
    const searchParams =new URLSearchParams(newUrl?.search)
    // Function to handle the resize event
    const handleBackButon4g = () => {
     if(searchParams.has("pageName")){
       newUrl.searchParams.set("pageName", "QualificationSelection");
       window.history.replaceState({}, '', newUrl);
       dispatch(setLoadQualficationSelection(true))
       dispatch(setLoadFWAQualifiedPlans(false))
     }
     
     };
   
     // Add the event listener
     window.addEventListener('popstate', handleBackButon4g);
   
     // Cleanup function to remove the listener on unmount
     return () => {
     window.removeEventListener('popstate', handleBackButon4g);
     };
 }, [dispatch])


  useEffect(() => {
    if (pageData?.checkAvailabilityRedirection) {
      load4GData();
    }
  }, [pageData?.checkAvailabilityRedirection])

  useEffect(() => {
    if ((planPreselection?.planId && planPreselection?.routerSku) || learnPlanCode) {
      if (!primaryPlan?.selectedPlan && !primaryPlan?.selectedRouter && !primaryPlan?.selectedRouterPayment) {
        dispatch(
          setSelectedPlan({
            planType: "primaryPlan",
            value: planPreselection?.planId || learnPlanCode,
          })
        );
      }
      if (!primaryPlan?.selectedRouter) {
        dispatch(setSelectedRouter({ planType: "primaryPlan", value: planPreselection?.routerSku }));
      }
      if (!primaryPlan?.selectedRouterPayment) {
        dispatch(setSelectedRouterPayment({
          planType: "primaryPlan",
          value: "devicePayments-0",
        })
        );
      }
    }
  }, [planPreselection, learnPlanCode])

  useEffect(() => {
    const sessionCartPlanType = sessionCartData?.planType || "CAPPED";
    const sessionCartPrimaryPlanQuantity = getSelectedPlanQuantity(
      sessionCartData?.cart,
      "primaryPlan"
    );
    const sessionCartBackupPlanQuantity = getSelectedPlanQuantity(
      sessionCartData?.cart,
      "backupPlan"
    );
    if (sessionCartPlanType === "UNCAPPED") {
      dispatch(setTabIndex(1));
    }
    if (Number(sessionCartPrimaryPlanQuantity) > 1) {
      dispatch(
        setQuantity({
          planType: "primaryPlan",
          value: sessionCartPrimaryPlanQuantity,
        })
      );
    }
    if (Number(sessionCartBackupPlanQuantity) > 1) {
      dispatch(
        setQuantity({
          planType: "backupPlan",
          value: sessionCartBackupPlanQuantity,
        })
      );
    }
    if (pageData?.planDetails?.plans) {
      const sortedCappedPlans = Object.values(pageData?.planDetails?.plans)
        .filter((plan) =>
          pageData?.planDetails?.cappedPlans?.includes(plan?.billingCode)
        )
        .map((plan) => ({
          ...plan,
          globalPromo: globalPromos ? globalPromos[plan?.billingCode] : null,
        }));
      // .sort((a, b) =>
      //   Number(a?.monthlyFee) > Number(b?.monthlyFee) ? 1 : -1
      // );
      dispatch(setCappedPlans(sortedCappedPlans));
      const sortedUncappedPlans = Object.values(pageData?.planDetails?.plans)
        .filter((plan) =>
          pageData?.planDetails?.unCappedPlans?.includes(plan?.billingCode)
        )
        .map((plan) => ({
          ...plan,
          globalPromo: globalPromos ? globalPromos[plan?.billingCode] : null,
        }))
        .sort((a, b) =>
          Number(a.monthlyFee) > Number(b?.monthlyFee) ? 1 : -1
        );
      dispatch(setUncappedPlans(sortedUncappedPlans));
      const sortedBackupPlans = Object.values(pageData?.planDetails?.plans)
        .filter((plan) =>
          pageData?.planDetails?.backUpPlans?.includes(plan?.billingCode)
        )
        .map((plan) => ({
          ...plan,
          globalPromo: globalPromos ? globalPromos[plan?.billingCode] : null,
        }))
        .sort((a, b) =>
          Number(a.monthlyFee) > Number(b?.monthlyFee) ? 1 : -1
        );
      dispatch(setBackupPlans(sortedBackupPlans));
      if (learnIntcmp && learnBackupPlanCode || learnIntcmp && sortedBackupPlans.length > 0) {
        dispatch(setIsIntcmpLearn(true));
        dispatch(setQuantity({ planType: "primaryPlan", value: "0", isDefault: false }));
        setCollapsePlans(false);
      }

      if (!primaryPlan?.selectedPlan && props?.isfromAuth) {
        if (sortedCappedPlans?.length > 0 && pageData?.tabIndex === 0) {
          dispatch(
            setSelectedPlan({
              planType: "primaryPlan",
              value: sortedCappedPlans[0]?.billingCode,
            })
          );
        } else if (
          sortedUncappedPlans?.length > 0 &&
          pageData?.tabIndex === 1
        ) {
          dispatch(
            setSelectedPlan({
              planType: "primaryPlan",
              value: sortedUncappedPlans[0]?.billingCode,
            })
          );
        }
        setCollapsePlans(false);
      }
    }
  }, [pageData?.planDetails, props?.isfromAuth, globalPromos]);

  useEffect(() => {
    if (
      pageData?.planDetails &&
      (primaryPlan?.routerDetails || backupPlan?.routerDetails)
    ) {
      const sessionCartPrimaryPlanId = getSelectedPlan(
        sessionCartData?.cart,
        "primaryPlan"
      );
      const sessionCartBackupPlanId = getSelectedPlan(
        sessionCartData?.cart,
        "backupPlan"
      );
      const sessionCartPrimaryRouter = getSelectedRouter(
        sessionCartData?.cart,
        "primaryPlan"
      );
      const sessionCartBackupRouter = getSelectedRouter(
        sessionCartData?.cart,
        "backupPlan"
      );
      if ((learnPlanCode || sessionCartPrimaryPlanId) && !props.isfromAuth) {
        !pageData?.isEditLTEPrimaryPlansFlow && setCollapsePlans(false);
        if (sessionCartPrimaryPlanId && !newPricePlanCode) {
          dispatch(
            setSelectedPlan({
              planType: "primaryPlan",
              value: sessionCartPrimaryPlanId,
            })
          );
          if (!sessionCartPrimaryRouter?.priceType) {
            dispatch(setSelectedRouterPayment({ planType: "primaryPlan", value: "" }));
          }
        } else {
          if (learnPlanCode || newPricePlanCode) {
            onPlanSelected(newPricePlanCode ? newPricePlanCode : learnPlanCode, "primaryPlan");
            setCollapsePlans(false);
          }
        }
      }
      if (
        (learnBackupPlanCode || sessionCartBackupPlanId) &&
        !props.isfromAuth
      ) {
        !pageData?.isEditLTEPrimaryPlansFlow && !learnIntcmp && setCollapsePlans(false);
        if (sessionCartBackupPlanId) {
          dispatch(
            setSelectedPlan({
              planType: "backupPlan",
              value: sessionCartBackupPlanId,
            })
          );
          if (!sessionCartBackupRouter?.priceType) {
            dispatch(setSelectedRouterPayment({ planType: "backupPlan", value: "" }));
          }
        } else {
          if (learnBackupPlanCode) {
            onPlanSelected(learnBackupPlanCode, "backupPlan");
            !learnIntcmp && setCollapsePlans(false);
            setIsPlansBackupSectionCollapsed(true);
          }
        }
      }
      if (
        !primaryPlan?.selectedRouter &&
        sessionCartPrimaryRouter?.priceType &&
        !newPricePlanCode &&
        !learnPlanCode &&
        !props.isfromAuth
      ) {
        !pageData?.isEditLTEPrimaryPlansFlow && setCollapseEquipment(false);
        setCollapsePayment(false);
        if (sessionCartPrimaryRouter?.byor) {
          dispatch(setSelectedRouter({ planType: "primaryPlan", value: "byor" }));
        } else {
          dispatch(setSelectedRouter({ planType: "primaryPlan", value: sessionCartPrimaryRouter?.deviceSku }));
          dispatch(
            setSelectedRouterPayment({
              planType: "primaryPlan",
              value:
                sessionCartPrimaryRouter?.priceType === "RETAIL"
                  ? "zeroYear" : sessionCartPrimaryRouter?.priceType === "twoYear" ? "twoYear"
                  : "devicePayments-0",
            })
          );
        }
        if (
          !backupPlan?.selectedRouter &&
          sessionCartBackupRouter
        ) {
          !pageData?.isEditLTEPrimaryPlansFlow && setCollapseEquipment(false);
          setCollapsePayment(false);
          if (sessionCartBackupRouter?.byor) {
            dispatch(
              setSelectedRouter({ planType: "backupPlan", value: "byor" })
            );
          } else {
            dispatch(
              setSelectedRouter({ planType: "backupPlan", value: sessionCartBackupRouter?.deviceSku })
            );
            dispatch(
              setSelectedRouterPayment({
                planType: "backupPlan",
                value:
                  sessionCartBackupRouter?.priceType === "RETAIL"
                    ? "zeroYear" : sessionCartBackupRouter?.priceType === "twoYear" ? "twoYear"
                    : "devicePayments-0",
              })
            );
          }
        }
      }
    }
  }, [pageData?.planDetails]);

  useEffect(() => {
    if (pageData?.planDetails && primaryPlan?.routerDetails) {
      if (
        !primaryPlan?.selectedRouter &&
        primaryPlan?.selectedPlan &&
        props?.isfromAuth
      ) {
        dispatch(
          setSelectedRouter({ planType: "primaryPlan", value: aemContent?.fourG["router-content"]?.byorModal?.defaultDeviceSku })
        );
        dispatch(
          setSelectedRouterPayment({
            planType: "primaryPlan",
            value: "devicePayments-0",
          })
        );
      }
    }
  }, [pageData?.planDetails, primaryPlan?.routerDetails, props?.isfromAuth]);

  useEffect(() => {
    if (primaryPlan?.selectedRouter) {
      setTimer(10)
    }
  }, [primaryPlan?.selectedRouter])

  useEffect(() => {
    if (isLearnPlanCode && isComparePlanCode && (primaryPlan?.selectedRouter || primaryPlan?.selectedRouterPayment)) {
      setCollapsePlans(false)
    }
    if ((primaryPlan?.selectedRouter == "byor") && isComparePlanCode) {
      setCollapsePlans(false)
    }
  }, [isLearnPlanCode, primaryPlan?.selectedRouter, primaryPlan?.selectedRouterPayment, isComparePlanCode])

  useEffect(() => {
    if (pageData?.isEditLTEPrimaryPlansFlow) {
      setCollapsePlans(false);
      if (pageData?.isEditLTEBackupPlansFlow) {
        setIsPlansBackupSectionCollapsed(false);
      }
    }
    if (pageData?.isEditLTEEquipmentFlow) {
      setCollapseEquipment(false);
    }
  }, [pageData]);

  useEffect(() => {
    if (globalData?.sessionCartData?.apiResponse) {
      // if (
      //   (pageData?.primaryPlan?.selectedPlan ||
      //     pageData?.backupPlan?.selectedPlan) &&
      //   !globalData?.sessionCartData?.apiResponse?.prospectFlowData?.cart
      //     ?.packages && !isIntcmpLearn
      // ) {
      //   dispatch(resetGlobalSlice());
      //   dispatch(reset4GState());
      // }

      const sessionCartPrimaryPlanQuantity = getSelectedPlanQuantity(
        globalData?.sessionCartData?.apiResponse?.prospectFlowData?.cart,
        "primaryPlan"
      );
      const sessionCartBackupPlanQuantity = getSelectedPlanQuantity(
        globalData?.sessionCartData?.apiResponse?.prospectFlowData?.cart,
        "backupPlan"
      );

      if (isAddonsEnabled) {
        const featureIds = aemContent?.["addons-content"]?.featureIds || [];
        if (Number(sessionCartPrimaryPlanQuantity) > 0 && Number(sessionCartBackupPlanQuantity) > 0) {
          setIsAddonPageNeeded(false);
        }
        else if (Number(sessionCartPrimaryPlanQuantity) > 0) {
          const primaryFeatures = getSelectedFeatures(globalData?.sessionCartData?.apiResponse?.prospectFlowData?.cart, "primaryPlan");
          setIsAddonPageNeeded(!Object.keys(primaryFeatures)?.some(r => featureIds.includes(r)));
        }
        else if (Number(sessionCartBackupPlanQuantity) > 0) {
          const backupFeatures = getSelectedFeatures(globalData?.sessionCartData?.apiResponse?.prospectFlowData?.cart, "backupPlan")
          setIsAddonPageNeeded(!Object.keys(backupFeatures)?.some(r => featureIds.includes(r)));
        }
      }
      if (showBussProductPage) {
        if (!isBussPageNeeded) {
          let isBussAvailable = false;
          if (sessionCartData?.cart?.packages) {
            const packages = sessionCartData?.cart?.packages;
            for (const pkg in packages) {
              const pkgObj = packages[pkg];
              if (pkgObj?.buss) {
                isBussAvailable = true;
              }
            }
          }
          if (isBussAvailable) {
            setIsBussPageNeeded(true);
          }
        }
      }
    }
  }, [globalData?.sessionCartData]);

  useEffect(() => {

    if (primaryPlan?.selectedRouter !== "byor" && backupPlan?.selectedRouter !== "byor" && (primaryPlan?.prevSelectedRouterPayment || backupPlan?.prevSelectedRouterPayment)) {
      const planType = primaryPlan?.prevSelectedRouterPayment ? "primaryPlan" : "backupPlan";
      if (!prePlanSelectedFlowEnabled) {
        updateRouter(primaryPlan?.prevSelectedRouterPayment, false, planType);
      }

    }
  }, [primaryPlan?.selectedRouter, backupPlan?.selectedRouter])

  const getSelectedFeatures = (cartData, planType) => {
    let features = [];
    let isBackupPlan = planType === "backupPlan" ? true : false;
    if (cartData?.packages) {
      for (let packages in cartData.packages) {
        const pkg = cartData.packages[packages];
        if (
          pkg?.packageType === "FOURG" &&
          pkg?.backUpPlanPresent === isBackupPlan
        ) {
          features = pkg?.features || [];
        }
      }
    }
    return features;
  };


  // useEffect(() => {
  //   if(!(pageData?.isEditLTEPrimaryPlansFlow ||
  //   pageData?.isEditLTEBackupPlansFlow ||
  //   pageData?.isEditLTEEquipmentFlow)) {
  //     !learnBackupPlanCode &&
  //     !backupPlan?.isDefault &&
  //     onNoOfPlansQtyChange(0, "backupPlan");
  //   }
  // }, []);

  const getSelectedPlan = (cartData, planType) => {
    let planId = "";
    let isBackupPlan = planType === "backupPlan" ? true : false;
    if (cartData?.packages) {
      for (let packages in cartData.packages) {
        const pkg = cartData.packages[packages];
        if (
          pkg?.packageType === "FOURG" &&
          pkg?.backUpPlanPresent === isBackupPlan
        ) {
          if (pageData?.grpId && pkg?.groupId == pageData?.grpId) {
            planId = pkg?.plan?.pricePlanCode;
            return planId;
          }
          else {
            planId = pkg?.plan?.pricePlanCode;
          }
        }
      }
    }
    return planId;
  };

  const getSelectedPlanQuantity = (cartData, planType) => {
    let planQuantity = "";
    let isBackupPlan = planType === "backupPlan" ? true : false;
    if (cartData?.packages) {
      for (let packages in cartData.packages) {
        const pkg = cartData.packages[packages];
        if (
          pkg?.packageType === "FOURG" &&
          pkg?.backUpPlanPresent === isBackupPlan
        ) {
          planQuantity = cartData?.groupPackages?.[pkg?.groupId]?.quantity;
        }
      }
    }
    return planQuantity;
  };

  const getSelectedRouter = (cartData, planType) => {
    let router = null;
    let isBackupPlan = planType === "backupPlan" ? true : false;
    if (cartData?.packages) {
      for (const packages in cartData.packages) {
        const pkg = cartData.packages[packages];
        if (
          pkg?.packageType === "FOURG" &&
          pkg?.backUpPlanPresent === isBackupPlan
        ) {
          router = {
            byor: pkg?.byor,
            deviceSku: pkg?.deviceSku,
            priceType: pkg?.priceType,
            tenure: pkg?.tenure,
          };
        }
      }
    }
    return router;
  };



  const onContinueClick = (event, JointOfferOption, IsFromShoppingCart = false) => {
    if (!primaryPlan?.selectedPlan) {
      setSelectPlanErr(true);
      setIsPlanError(isPlanError + 1);
    } else if (primaryPlan?.selectedPlan && !primaryPlan?.selectedRouter) {
      setIsPlanError(0);
      let element = document.getElementById('plansContainer');
      let elementPosition = element?.offsetHeight;
      window.scrollTo({
        top: elementPosition + headerHeight,
      })
      setSelectRouterErr(true);
    } else if (primaryPlan?.selectedPlan && primaryPlan?.selectedRouter &&
      (primaryPlan?.selectedRouter !== "byor" && (!primaryPlan?.selectedRouterPayment && !onlyBandQualified))) {
      let element = document.getElementById("routerPaymentSel");
      let planElement = document.getElementById('plansContainer');
      let routerElement = document.getElementById('primaryEquipmentSel');
      if (!element) {
        element = document.getElementById("onContinue")
      }
      let elementPosition = planElement?.offsetHeight + routerElement?.offsetHeight;
      window.scrollTo({
        top: elementPosition + headerHeight,
      });
      setSelectPaymentErr(true);
    } else if ((primaryPlan?.selectedPlan && primaryPlan?.selectedRouter === "byor") || (primaryPlan?.selectedPlan && primaryPlan?.selectedRouter) && prePlanSelectedFlowEnabled) {
      const isByor = primaryPlan?.selectedRouter === "byor" || false;
      const routerDetails = primaryPlan?.routerDetails?.deviceList;
      let skuDetails = [];
      let selcetedSku = {};
      if (!isByor) {
        skuDetails = routerDetails?.filter((device) => {
          if (device?.skulist && device?.skulist?.length > 0) {
            const checkForRouter = device?.skulist?.filter((skuDetails) => skuDetails?.sku === primaryPlan?.selectedRouter)?.length > 0;
            return checkForRouter;
          }
          return false;
        })

        if (skuDetails && Array.isArray(skuDetails) && skuDetails?.length === 1) {
          selcetedSku = skuDetails[0];
        }
      }

      const byorDevice = isByor && pageData?.validateDeviceIdResp?.deviceInfo ? pageData?.validateDeviceIdResp?.deviceInfo : {};

      let payLoad = {};
      let selectedBulkAddrKeys=[]
      LQData?.qualifiedselectedAddress?.map((address) => {
        selectedBulkAddrKeys.push(address?.eventCorrelationId)});
      let operationVal;
      let groupId = "";
      const checkPackages = sessionCartData?.cart?.packages && Object.values(sessionCartData?.cart?.packages)?.filter((d) => d.packageType === "FOURG");
      if (checkPackages?.length > 0) {
        groupId = checkPackages[0]?.groupId ?? "";
        operationVal = "UPDATEBULKQTY";
      } else {
        groupId = "";
        operationVal = "ADD";
      }
      if (isByor) {
        payLoad = {
          "cartMeta": {
            "zipCode": "",
            "shoppingPath": "prospect",
            "flow": "4g",
            "clientId": globalData?.widgetInputParams?.clientName?.value ?? "",
            "mfeShoppingPath": window?.mbtGlobal?.shoppingPath
          },
          "lines": [
            {
              "backUp": false,
              "packageId": "",
              "packageType": "FOURG",
              "operation": operationVal,
              "plan": {
                "planId": primaryPlan?.selectedPlan
              },
              "groupId": groupId,
              "isFwaBundleSelected": isFwaBundleSelected,
              "quantity": 1,
              "byor": isByor || false,
              "device": {
                "deviceSku": Object.keys(byorDevice)?.length > 0 ? byorDevice?.deviceSkuId || byorDevice?.skulist[0]?.sku : aemContent?.fourG["router-content"]?.byorModal?.defaultDeviceSku,
                "deviceCategory": selcetedSku?.category,
                "deviceType": "FOURGROUTER",
                "quantity": 1,
                "byod": true,
                "byodInfo": {
                  imei: byorDevice?.imei,
                  name: byorDevice?.name,
                  simSku: byorDevice?.simSku,
                  psimCapable: byorDevice?.psimCapable,
                  esimCapable: byorDevice?.esimCapable,
                  newSim: true,
                  skipImei: true
                }
              },
              "bulkAddresses":[
                {
                    "operation": "UPDATE",
                    "addressKeys": selectedBulkAddrKeys
                },
                // {
                //     "operation": "DELETE",
                //     "addressKeys": ["30312_139393933930_10"]
                // }
            ]
            }
          ]
        }
      } else {
        payLoad = {
          "cartMeta": {
            "zipCode": "",
            "shoppingPath": "prospect",
            "flow": "4g",
            "clientId": globalData?.widgetInputParams?.clientName?.value ?? "",
            "mfeShoppingPath": window?.mbtGlobal?.shoppingPath
          },
          "lines": [
            {
              "backUp": false,
              "packageId": "",
              "packageType": "FOURG",
              "operation": operationVal,
              "plan": {
                "planId": primaryPlan?.selectedPlan
              },
              "groupId": groupId,
              "isFwaBundleSelected": JointOfferOption != null ? false : isFwaBundleSelected,
              "quantity":  globalData?.widgetInputParams?.clientName?.value == "MBT" ? selectedBulkAddrKeys?.length :primaryPlan?.quantity,
              "byor": isByor || false,
              "device": {
                "deviceSku": primaryPlan?.selectedRouter,
                "deviceCategory": selcetedSku?.category,
                "deviceType": "FOURGROUTER",
                "quantity":  globalData?.widgetInputParams?.clientName?.value == "MBT" ? selectedBulkAddrKeys?.length :primaryPlan?.quantity,
                "priceType": onlyBandQualified ? (pageData?.toggleStatusForCBandRouter[primaryPlan?.selectedRouter] ? "MONTH_TO_MONTH" : "RETAIL") : (primaryPlan?.selectedRouterPayment === "devicePayments-0" ? "MONTH_TO_MONTH" : primaryPlan?.selectedRouterPayment ==="twoYear"? "twoYear": "RETAIL"),
                "installmentTerm": onlyBandQualified ? (pageData?.toggleStatusForCBandRouter[primaryPlan?.selectedRouter] ? "36" : "0") : (primaryPlan?.selectedRouterPayment === "devicePayments-0" ? "36" : primaryPlan?.selectedRouterPayment ==="twoYear"? "24" : "0"),
                "seoUrlName": selcetedSku?.seoUrlName,
                "byod": false
              },
              "bulkAddresses":[
                {
                    "operation": "UPDATE",
                    "addressKeys": selectedBulkAddrKeys
                },
                // {
                //     "operation": "DELETE",
                //     "addressKeys": ["30312_139393933930_10"]
                // }
            ]
            }
          ]
        }
      }

      // const reqDevice = {
      //   deviceSku: isByor
      //     ? device?.deviceSkuId || device?.skulist[0]?.sku
      //     : device?.sku || device?.skulist[0]?.sku,
      //   // deviceId: device.deviceId,
      //   deviceCategory: device?.category,
      //   deviceType: "FOURGROUTER",
      //   quantity: commonPlan?.quantity,
      //   priceType:
      //     commonPlan?.selectedRouterPayment === "zeroYear"
      //       ? "RETAIL"
      //       : "MONTH_TO_MONTH",
      //   ...(!isByor && {
      //     installmentTerm:
      //       device?.nofInstallements ||
      //       device?.skulist[0]?.pricingDetails?.devicePayments[0]
      //         ?.nofInstallements,
      //     seoUrlName: device?.seoUrlName,
      //   }),
      //   byod: !!isByor,
      //   ...(isByor && {
      //     byodInfo: {
      //       imei: device?.imei,
      //       name: device?.name,
      //       simSku: device?.simSku,
      //       psimCapable: device?.psimCapable,
      //       esimCapable: device?.esimCapable,
      //     },
      //   }),
      // };
      if (!IsFromShoppingCart || (pageData?.isPlanEdit && changedPlans)) {
        setAddToCartPayload(payLoad);
        dispatch(addOrUpdate4GPage(payLoad));
        setContinueClicked(true);
      } else {
        // navigate("/shop/shopping-cart");
        dispatch(setLoadShoppingCartWidget(true));
        const currentUrl = window.location.href;
		const newUrl = new URL(currentUrl);
		newUrl.searchParams.set("pageName", "ShoppingCart");
		window.history.pushState({}, '', newUrl);
      }
    }
    // else{
    //   SiteAnalytics.eventAddtocartTracking('Add To Cart', '', '4g5g', '4g');
    //   dispatch(resetQueryParams());
    //   if (isFwaBundleSelected && !displayOnlyInternetMobileTab && !selectFwaBundletabForJO) {
    //     dispatch(setIsCustomerModalVisited(true))
    //     dispatch(setIsLandingFromInternetWithMobile(true));
    //     navigate("/shop/products/plans?deviceType=smartphones&planid=67792&q=1");
    //     dispatch(setIsFwa(true))
    //   }
    //   else if (showBussProductPage && !isBussPageNeeded && !displayOnlyInternetMobileTab && !selectFwaBundletabForJO) {
    //     dispatch(setIsFromFwa('4g'));
    //     navigate("/shop/buss-products");
    //   }
    //   else {
    //     globalData?.isWirelessToFwaFlow || selectFwaBundletabForJO ? navigate("/shop/shopping-cart") : navigate("/shop/products/networks/connectivity/lte-business-internet/addons");
    //   }
    //   // else if (isAddonPageNeeded) {
    //   //   navigate("/shop/products/networks/connectivity/lte-business-internet/addons");
    //   // } else {
    //   //   navigate("/shop/shopping-cart");
    //   // }
    // }
  };

  const openVideoModal = () => {
    setShowSetupVideoModal(true);
  };

  const getRemaingQuantity = (pack) => {
    const grpPackagesObj = sessionCartData?.cart?.groupPackages;
    let usedQty = 0;
    for (const key of Object.keys(grpPackagesObj)) {
      if (pack?.groupId === key || grpPackagesObj[key].deviceCategory === 'BroadbandAccess Devices') {
        usedQty = grpPackagesObj[key]?.quantity;
      }
    }
    return usedQty;
  }

  const onNoOfPlansQtyChange = (e, planType, isDefault = true) => {
    const packagesObj = sessionCartData?.cart?.packages;
    if (packagesObj && Object.keys(packagesObj).length > 0) {
      const packages = Object.values(packagesObj).filter(
        (item) => item?.packageType === "FOURG" && !item?.backUpPlanPresent
      );
      const remaing =
        Object.keys(packagesObj).length - getRemaingQuantity(packages?.[0]);
      const qtyAllowed = Number(e) + remaing;
      if (qtyAllowed > 9) {
        setMaxDeviceModal(true);
        return;
      }
    }
    if (e === "0") {
      dispatch(setQuantity({ planType, value: e, isDefault: false }));
      dispatch(setSelectedPlan({ planType, value: "" }));
      dispatch(setSelectedRouter({ planType, value: "" }));
      dispatch(setSelectedRouterPayment({ planType, setSelectedRouter: "" }));
    } else {
      dispatch(setQuantity({ planType, value: e, isDefault }));
    }
    const commonPlan = planType === "primaryPlan" ? primaryPlan : backupPlan;
    if (!props.isfromAuth) {
      if (e > 1 && commonPlan?.selectedRouter === "byor") {
        const request = {
          flow: FLOW,
          planType: planType,
          sessionCartData: sessionCartData,
          deviceInfo: commonPlan?.routerDetails?.deviceList[0],
          pricingType: commonPlan?.selectedRouterPayment,
          byor: false,
          newSim: true,
          quantity: e,
        };
        dispatch(update4GRouter(request));
      } else {
        if (commonPlan?.selectedPlan) {
          updatePlan(commonPlan?.selectedPlan, planType);
        }
      }
    }
  };

  const onTabChangeApiCall = (planType) => {
    const commonPlan = planType === "primaryPlan" ? primaryPlan : backupPlan;
    if (!props.isfromAuth) {
      if (commonPlan?.selectedRouter === "byor") {
        const request = {
          flow: FLOW,
          planType: planType,
          sessionCartData: sessionCartData,
          deviceInfo: commonPlan?.routerDetails?.deviceList[0],
          pricingType: commonPlan?.selectedRouterPayment,
          byor: false,
          newSim: true,
          quantity: e,
        };
        dispatch(update4GRouter(request));
      } else {
        if (commonPlan?.selectedPlan) {
          updatePlan(commonPlan?.selectedPlan, planType);
        }
      }
    }
  };

  const onPlanSelected = (e, planType) => {
    if (props?.isfromAuth) {
      dispatch(setSelectedPlan({ planType, value: e }));
      dispatch(get4gRouterDevices({ planType, pricePlanCode: e }));
    } else {
      dispatch(setSelectedPlan({ planType, value: e }));
      dispatch(get4gRouterDevices({ planType, pricePlanCode: e }));
      //updatePlan(e, planType);
      let element = document.getElementById('plansContainer');
      let elementPosition = element?.offsetHeight;
      window.scrollTo({
        top: elementPosition + headerHeight,
      })
    }
    setChangedPlans(true);
    // if ((primaryPlan?.selectedPlan || backupPlan?.selectedPlan)) {

    //     let element = document.getElementById('plansContainer');
    //     let elementPosition = element?.offsetTop;

    //     window.scrollTo({
    //       top: elementPosition - headerHeight,
    //       behavior: "smooth"
    //     });


  }
  useEffect(() => {
    let element = document.getElementById('plansContainer');
    if (!fromPage || fromPage === undefined) {
      setTimeout(() => {
        element?.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 1000);
    }
  }, [])
  function getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY
    };
  }
  useEffect(() => {
    if ((selectPlanErr || primaryPlan?.selectedPlan) && !props?.isfromAuth) {
      let element = document.getElementById('plansContainer');
      let elementPosition = element?.offsetHeight;
      if (fromPage === 'plan') {
        element?.scrollIntoView({ behavior: "smooth", block: "start" });
        setisNewFlow(false);
      } else if (fromPage === 'router' && isMobile) {
        const equipId = document.getElementById('primaryEquipmentSel');
        setTimeout(() => {
          equipId?.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 501);
        setisNewFlow(false);
      } else if (isNewFlow && !isMobile && fromPage) {
        const equipId = document.getElementById('primaryEquipmentSel');
        let elementPositionRouter = Number(getOffset(equipId)?.top) - 250;
        fromSaveCartLteAvailble ? window.scrollTo({
          top: 100,
          behavior: "smooth"
        }) : fromPage === 'router' ? window.scrollTo(0, elementPositionRouter) :
          window.scrollTo({
            top: elementPosition + headerHeight,
            behavior: "smooth"
          })
        setisNewFlow(false);
      } else if (isNewFlow && isMobile) {
        fromSaveCartLteAvailble ? window.scrollTo({ top: 50, behavior: "smooth" }) :
          element?.scrollIntoView({ behavior: "smooth" });
        setisNewFlow(false);
      }
    }
  }, [primaryPlan?.selectedPlan, props?.isfromAuth])

  // useEffect(()=>{
  //   if ((selectRouterErr || primaryPlan?.selectedRouter) && !props?.isfromAuth){
  //     let element = document.getElementById('primaryEquipmentSel');
  //         let elementPosition = element?.offsetHeight;
  //     window.scrollTo({
  //       top: 8000,
  //       behavior: "smooth"
  //     })
  //   }

  // },[primaryPlan?.selectedRouter,props?.isfromAuth])







  // if ((selectPaymentErr || primaryPlan?.selectedRouterPayment) && !props?.isfromAuth){
  //   let element = document.getElementById('routerPaymentSel');
  //   let elementPosition = element?.offsetHeight;
  //       window.scrollTo({
  //   top: elementPosition + headerHeight,
  //   behavior: "smooth"

  //   })
  // }


  const onTabChangeFunc = (event, index) => {
    dispatch(setTabIndex(index));
    onLinesQuantityChange(
      1,
      index === 0
        ? pageData?.cappedPlans[0].billingCode
        : pageData?.uncappedPlans[0].billingCode
    );
  };

  const displayPlans = () => {
    return (
      <div id="plansContainer">
        {/* <PlanSelectionNew
          isCollapsed={collapsePlans}
          isHavingBackupPlans={pageData?.backupPlans?.length ? true : false}
          isPlansBackupSectionCollapsed={isPlansBackupSectionCollapsed}
          learnBackupPlanCode={learnBackupPlanCode}
          headerHeight={headerHeight}
          isPlanError={isPlanError}
          learnPlanCode={learnPlanCode}
          setIsCollapsed={setCollapsePlans}
          onSelect={onPlanSelected}
          planDetails={
            pageData?.tabIndex === 0
              ? pageData?.cappedPlans
              : pageData?.uncappedPlans
          }
          tabIndex={pageData?.tabIndex}
          showPromoCard={false}
          isfromAuth={props?.isfromAuth}
          learnIntcmp={learnIntcmp}
          selectPlanErr={selectPlanErr}
          setSelectPlanErr={setSelectPlanErr}
          isCheckoutNew={props?.isCheckoutNew}
          setIsFwaBundleSelected={setIsFwaBundleSelected}
          isNewFlow={isNewFlow}
          hasInternetPackage={hasInternetPackage}
          hasRegularPackage={hasRegularPackage}
          hasPackages={hasPackages}
        /> */}
         <PlanSelectionNew
          isCollapsed={collapsePlans}
          isHavingBackupPlans={pageData?.backupPlans?.length ? true : false}
          isPlansBackupSectionCollapsed={isPlansBackupSectionCollapsed}
          learnBackupPlanCode={learnBackupPlanCode}
          headerHeight={headerHeight}
          isPlanError={isPlanError}
          learnPlanCode={learnPlanCode}
          setIsCollapsed={setCollapsePlans}
          onSelect={onPlanSelected}
          planDetails={
            pageData?.tabIndex === 0
              ? pageData?.cappedPlans
              : pageData?.uncappedPlans
          }
          tabIndex={pageData?.tabIndex}
          showPromoCard={false}
          isfromAuth={props?.isfromAuth}
          learnIntcmp={learnIntcmp}
          selectPlanErr={selectPlanErr}
          setSelectPlanErr={setSelectPlanErr}
          isCheckoutNew={props?.isCheckoutNew}
          setIsFwaBundleSelected={setIsFwaBundleSelected}
          isNewFlow={isNewFlow}
          hasInternetPackage={hasInternetPackage}
          hasRegularPackage={hasRegularPackage}
          hasPackages={hasPackages}
        />
      </div>
    );
  };

  const isLoading = pageData.loading;

  // const isContinueDisabled = () => {
  //   let flag1 =
  //     !primaryPlan?.selectedPlan ||
  //     !primaryPlan?.selectedRouter ||
  //     (primaryPlan?.selectedRouter !== "byor" &&
  //       !primaryPlan?.selectedRouterPayment);
  //   // let flag2 =
  //   //   !backupPlan?.selectedPlan ||
  //   //   !backupPlan?.selectedRouter ||
  //   //   (backupPlan?.selectedRouter !== "byor" &&
  //   //     !backupPlan?.selectedRouterPayment);
  //   let flag2 = false
  //   if (primaryPlan?.selectedPlan && backupPlan?.selectedPlan) {
  //     return flag1 || flag2;
  //   } else if (primaryPlan?.selectedPlan) {
  //     return flag1;
  //   } else if (backupPlan?.selectedPlan) {
  //     return flag2;
  //   } else {
  //     return true;
  //   }
  // };


  useEffect(() => {
    if (true && pageData?.planDetails?.plans && !fccVisited && planDetailsFccNewObj && Object.keys(planDetailsFccNewObj)?.length > 0) {
      //dispatch(updateFCCTemplate());
      const testPlan = Object.keys(pageData?.planDetails?.plans);
      //dispatch(updateFCCJson(testPlan));
      setFccVisited(true);
      let obj = [];
      for (let i of testPlan) {
        if (Array.isArray(planDetailsFccNewObj[i]) && planDetailsFccNewObj[i].length > 0) {
          // setPlanDataFwa(resp.planDetails[testPlan]);
          obj.push(planDetailsFccNewObj[i])
        }
      }
      dispatch(setPlansDetailsFcc(obj))
      if ((testPlan?.length != obj?.length) && obj?.length > 0) {
        dispatch(setContainerHeightBB("32.5"));
      }
    }
  }, [pageData?.planDetails, planDetailsFccNewObj])

  const newCustomerBtnClicked = () => {
    // finalizeMultipleSimSelection();
    let element = document.getElementById('errorSel');
    let elementPosition = element?.offsetHeight;
    window.scrollTo({
      top: elementPosition + headerHeight,
    })
    dispatch(setLteLearnShowAddressModal(false))
  };

  useEffect(() => {
    if (addressMicroUnitVal && Object.keys(addressMicroUnitVal)?.length < 0 && !primaryPlan?.selectedRouter) {
      setShowNewCustomerModal(pageData?.isFrom4GLearn && pageData?.lteLearnShowAddressModal)
    } else {
      setShowNewCustomerModal(false)
    }
  }, [pageData?.isFrom4GLearn, pageData?.lteLearnShowAddressModal])

  const existingCustomerBtnClicked = () => {
    let redirectUrl = `${t("app.common.deepLinkUrls.4g", { ns: "app" })}`;
    window.location.href = redirectUrl;
  };

  const handleJointOfferDetails = () => {
    setJointOfferList(jointOfferAem["newJointOfferBundleFlow"]["ContinueInternetOnly"][0]);
    setJointOfferModal(true);
  }

  return (
    <>
      {(!ready || !globalData?.metaInformation) ? <SpinnerComponent disabled={false} /> :

        <FWAContextProvider
          value={{
            flow: FLOW,
            isMobile,
            primaryPlan,
            backupPlan,
            pageData,
            sessionCartData,
            oneApiCall
          }}
        >
          <div id="landingPage" className="landing4g" style={{ width: props?.isCheckoutNew && !isMobile ? '125%' : '100%' }}>
            <Header pageName={"Landing4G"} />
            {showMaxDeviceModal && (
              <MaxDevicesModal
                deviceQuantity={maxDeviceQuantity}
                opened={showMaxDeviceModal}
                onOpenedChange={setMaxDeviceModal}
              />
            )}
            {/* <SEOTagger pageName={"4g"} /> */}
            {props?.isCheck !== "auth-no" &&
              props?.isPermission !== "permission-no" && (
                <StickyHeaderVds3
                  headerRef={stickyHeaderRef}
                  onContinue={onContinueClick}
                  disabledContinueBtn={false}
                  hideAddress={false}
                  hasInternetPackage={hasInternetPackage}
                  backClickHandler={() => {
                    setFourGModal(true);
                  }}
                  enableBackLink={true}
                  onAddressChange={() => setChangeAddressModal(true)}
                  continueBtnText={ t("4G-5G.common.page-stepper.continue-btn", {
                    ns: "4G-5G",
                  })
                    // !aemContent?.newJointOfferBundleFlow?.isNewJOFlowEnabled ?
                    //   t("4G-5G.common.page-stepper.continue-btn", {
                    //     ns: "4G-5G",
                    //   })
                    //   : hasPackages ?
                    //     hasInternetPackage && hasRegularPackage ? t("4G-5G.common.page-stepper.continue-btn", { ns: "4G-5G" }) :
                    //       ((hasRegularPackage || globalData?.isBackBtnClick) ? pageData?.fwaBundleTabSelected == true && !sessionCartData?.selectFwaBundletabForJO ? aemContent?.newJointOfferBundleFlow?.mobileFlowContinue : aemContent?.newJointOfferBundleFlow?.internetFlowContinue : t("4G-5G.common.page-stepper.continue-btn", { ns: "4G-5G" })) :
                    //     (pageData?.fwaBundleTabSelected == true ? aemContent?.newJointOfferBundleFlow?.mobileFlowContinue : aemContent?.newJointOfferBundleFlow?.internetFlowContinue)
                  }
                  pdp={true}
                  hideShoppingBagIcon={showShoppingIcon ? false : true}
                  fromLandingPage4g={true}
                  fromFwa={!(!!primaryPlan?.selectedPlan && !!primaryPlan?.selectedRouter && !!primaryPlan?.selectedRouterPayment)}
                  zIndex={6}
                  isJOBundle={aemContent?.newJointOfferBundleFlow?.isNewJOFlowEnabled}
                  setIsShoppingIconClicked={setIsShoppingIconClicked}
                  setshowCartNudgeModal={setshowCartNudgeModal}
                />
              )}
            {isLoading && <SpinnerComponent disabled={false} />}
            {isDigitalQuote &&
              LQData?.fixedWirelessResponse?.response &&
              !LQData?.fixedWirelessResponse?.response?.qualified4GHome ? (
              <Notification
                type="info"
                inline={true}
                fullBleed={true}
                title={t("4G-5G.common.page-stepper.lte-plus-disabled-msg", {
                  ns: "4G-5G",
                })}
              />
            ) : ""}
            {/* <HeroSection
              titleText={
                (props?.isfromAuth && !isLqGbcom && !pageData?.isFrom4GLearn)
                  ? authHeaderTxt
                  : !isLqGbcom && pageData?.isFrom4GLearn
                  ? aemContent?.fourG["plan-content"]?.lteLearnAvailable.heading
                  : isHeaderType5gorFios
                  ? aemContent?.fourG["plan-content"]?.header?.[headerType]?.heading
                  : isLTEPlus
                    ? aemContent?.fourG["plan-content"]?.header["4gLTEPlus"]
                      ?.heading ??
                    "5G Business Internet isn't available, but - good news! - we do offer LTE BI and LTE BI Plus."
                    :aemContent?.fourG["plan-content"]?.header?.[headerType]?.heading
                   
 }
              subtitleText={
                (props?.isfromAuth && !isLqGbcom && !pageData?.isFrom4GLearn)
                  ? authSubHeaderTxt2
                  : !isLqGbcom && pageData?.isFrom4GLearn
                  ? aemContent?.fourG["plan-content"]?.lteLearnAvailable.subHeading
                  : isHeaderType5gorFios
                  ? aemContent?.fourG["plan-content"]?.header?.[headerType]
                  ?.subheading
                  : (!isLTEPlus && !isHeaderType5gorFios) 
                    ? aemContent?.fourG["plan-content"]?.header?.[headerType]
                    ?.subheading
                    : aemContent?.fourG["plan-content"]?.lqFourgAvailable.subHeading
              }
                   
              subtitleColor={props?.isfromAuth ? "secondary" : "primary"}
              isDarkTheme={!props?.isfromAuth || props?.isCheckoutNew}
              isfromAuth={props?.isfromAuth}
              // isLqGbcom={isLqGbcom}
              isHeaderType5gorFios={isHeaderType5gorFios}
            /> */}
            {pageData?.isPackageCompatible && (<PackageCompatibleModal
              opened={pageData?.isPackageCompatible}
              sessionCartData={sessionCartData}
              addToCartPayload={addToCartPayload}
              onContinue={() => {
                dispatch(setpackageCompatible(false));
                dispatch(setAddtoCartResponse({}));
                setContinueClicked(true);
                dispatch(clearCartAndAdd4G());
                //navigate("/shop/buss-products");
              }}
            />)}
            {/* {
              pageData?.isFrom4GLearn && showNewCustomerModal && <NewCustomerAddressModal
                pageData={pageData}
                isFrom4GLearn={pageData?.isFrom4GLearn}
                closeBtnClick={setShowNewCustomerModal}
                existingCustomerBtnClicked={existingCustomerBtnClicked}
                newCustomerBtnClicked={newCustomerBtnClicked}
                showNewCustomerModal={showNewCustomerModal}
              />
            } */}

            {fromSaveCartLteAvailble ? (
              <>
                <Notification
                  type="error"
                  inline={true}
                  fullBleed={true}
                  hideCloseButton={true}
                  title="We're sorry, the 5G quoted plan is no longer available at your service address today."
                  subtitle="Don't worry you can order our LTE Business Internet today.  Just pick a plan below to get started"
                />
                <MarginSpacerM />
              </>
            ) : <></>}
            {/* {!isMobile && !props?.isfromAuth && <ChatDiv><ChatLink /></ChatDiv>} */}
            <Grid colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
              <StyledRow>
                {
                  props?.isfromAuth ?
                    <></>
                    :
                    <Col colSizes={{ mobile: 4, tablet: 12, desktop: 6 }}>
                      <div className={isMobile ? 'image-align' : 'image-align-desktop'}>
                        <Carousel
                          layout="1UP"
                          gutter="24px"
                          peek={"none"}
                          surface="light"
                          paginationFill="light"
                          paginationInset="0px"
                          paginationDisplay="onHover"
                          aspectRatio="1.37:1"
                          data={[
                            {
                              textAlignment: "left",
                              textPosition: "bottom",
                              surface: "light",
                              title: {
                                primitive: 'h1',
                                children: <div style={{ backgroundColor: 'white', padding: '16px', borderRadius: '10px', width: '90%', position: 'absolute', bottom: '1rem', opacity: '0.9' }}>
                                  <Title size="medium" bold={true}>{aemContent?.fourG?.carousel.imageHeading.plan50mbps}</Title>
                                  <MarginSpacerS />
                                  <Body size="small">{aemContent?.fourG?.carousel.carcontent.plan50mbps}</Body>
                                </div>,
                              },

                              backgroundColor: 'white',
                              backgroundImage: aemContent?.fourG?.carousel.images.plan50mbps,
                            },
                            {
                              textAlignment: "left",
                              textPosition: "bottom",
                              title: {
                                size: 'titleSmall',
                                primitive: 'h1',
                                children: <div style={{ backgroundColor: 'white', padding: '16px', borderRadius: '10px', width: '90%', position: 'absolute', bottom: '1rem', opacity: '0.9' }}>
                                  <Title size="medium" bold={true}>{aemContent?.fourG?.carousel.imageHeading.plan25mbps}</Title>
                                  <MarginSpacerS />
                                  <Body size="small">{aemContent?.fourG?.carousel.carcontent.plan25mbps}</Body>
                                </div>,
                              },

                              backgroundColor: 'white',
                              backgroundImage: aemContent?.fourG?.carousel.images.plan25mbps,
                            },
                            {
                              textAlignment: "left",
                              textPosition: "bottom",
                              title: {
                                size: 'titleSmall',
                                primitive: 'h1',
                                children: <div style={{ backgroundColor: 'white', padding: '16px', borderRadius: '10px', width: '90%', position: 'absolute', bottom: '1rem', opacity: '0.9' }}>
                                  <Title size="medium" bold={true}>{aemContent?.fourG?.carousel.imageHeading.plan10mbps}</Title>
                                  <MarginSpacerS />
                                  <Body size="small">{aemContent?.fourG?.carousel.carcontent.plan10mbps}</Body>
                                </div>,
                              },

                              backgroundColor: 'white',
                              backgroundImage: aemContent?.fourG?.carousel.images.plan10mbps,
                            },
                            {
                              textAlignment: "left",
                              textPosition: "bottom",
                              title: {
                                size: 'titleSmall',
                                primitive: 'h1',
                                children: <div style={{ backgroundColor: 'white', padding: '16px', borderRadius: '10px', width: '90%', position: 'absolute', bottom: '1rem', opacity: '0.9' }}>
                                  <Title size="medium" bold={true}>{aemContent?.fourG?.carousel.imageHeading.backupService}</Title>
                                  <MarginSpacerS />
                                  <Body size="small">{aemContent?.fourG?.carousel.carcontent.backupService}</Body>
                                </div>,
                              },
                              backgroundColor: 'white',
                              backgroundImage: aemContent?.fourG?.carousel.images.backupService,
                            },
                            {
                              textAlignment: "left",
                              textPosition: "bottom",
                              title: {
                                size: 'titleSmall',
                                primitive: 'h1',
                                children: <div style={{ backgroundColor: 'white', padding: '16px', borderRadius: '10px', width: '90%', position: 'absolute', bottom: '1rem', opacity: '0.9' }}>
                                  <Title size="medium" bold={true}>{aemContent?.fourG?.carousel.imageHeading.router}</Title>
                                  <MarginSpacerS />
                                  <Body size="small">{aemContent?.fourG?.carousel.carcontent.router}</Body>
                                </div>,
                              },

                              backgroundColor: 'white',
                              backgroundImage: aemContent?.fourG?.carousel.images.router
                            },
                          ]}
                          renderItem={(props) => <Tilelet {...props} width="100%" />}
                        />
                      </div>
                    </Col>

                }

                <Col colSizes={{ mobile: 4, tablet: 12, desktop: props.isfromAuth ? 10 : 6 }}>
                  <div id="errorSel">
                    {pageData.apiError && (
                      <Notification
                        type="error"
                        title="Something went wrong. Please try again later."
                        fullBleed={true}
                        inlineTreatment={false}
                      />
                    )}
                  </div>
                  {((sessionCartData?.showLinkForMixedCart && pageData?.showCheckAvailabiltyLink) ||
                    (globalData.isJoBundleEnabled)) &&
                    !(hasInternetPackage && hasRegularPackage) && (
                      // <Notification
                      //   type="info"
                      //   title={t("app.shoppingCartToLTEPromoBanner.heading", { ns: "app" })}
                      //   fullBleed={true}
                      //   surface="light"
                      //   inlineTreatment={false}
                      //   hideCloseButton={true}
                      // />
                      <>
                        <MarginSpacer2XL />
                        <div style={{ fontSize: '1rem', color: '#6F7171' }}>
                          {t("app.GWToLTEFiveGPromoBanner.heading", { ns: "app" })}
                          <MarginSpacerRightXS />
                          <TextLink
                            type="inline"
                            size="small"
                            onClick={e => handleJointOfferDetails('internetAndMobile')}>
                            Learn More.
                          </TextLink>
                        </div>
                      </>
                    )}

                  {!isMobile && <MarginSpacerM />}
                  {!isLTEPlus && props?.isfromAuth ? (
                    <Line type="secondary" />
                  ) : (
                    <></>
                  )}
                  {/* {LQData?.isLteBiPlus ? ( */}
                  {isLTEPlus ? (
                    <>
                      <Tabs
                        indicatorPosition="bottom"
                        linePosition="bottom"
                        onTabChange={onTabChangeFunc}
                        orientation="horizontal"
                        selectedIndex={pageData?.tabIndex}
                      >
                        <Tab label={aemContent?.fourG["plan-content"]?.standard} />
                        <Tab label={aemContent?.fourG["plan-content"]?.premium} />
                      </Tabs>
                      {pageData?.tabIndex === 0 ? displayPlans() : <></>}
                      {pageData?.tabIndex === 1 ? displayPlans() : <></>}
                    </>
                  ) : (

                    <div id="plansContainer">
                      {/* <PlanSelectionNew
                        globalPromos={globalPromos}
                        isCollapsed={collapsePlans}
                        learnPlanCode={learnPlanCode}
                        headerHeight={headerHeight}
                        isPlanError={isPlanError}
                        isHavingBackupPlans={
                          pageData?.backupPlans?.length ? true : false
                        }
                        isPlansBackupSectionCollapsed={
                          isPlansBackupSectionCollapsed
                        }
                        learnBackupPlanCode={learnBackupPlanCode}
                        onNoOfPlansQtyChange={onNoOfPlansQtyChange}
                        onTabChangeApiCall={onTabChangeApiCall}
                        onSelect={onPlanSelected}
                        planDetails={
                          pageData?.tabIndex === 0
                            ? pageData?.cappedPlans
                            : pageData?.uncappedPlans
                        }
                        planDetailsBackup={pageData?.backupPlans}
                        setIsCollapsed={setCollapsePlans}
                        setIsPlansBackupSectionCollapsed={
                          setIsPlansBackupSectionCollapsed
                        }
                        showPromoCard={false}
                        showVisaOffer={
                          sessionCartData?.prospect5gLoopQualAddress?.showVisaOffer
                        }
                        tabIndex={pageData?.tabIndex}
                        setCollapseEquipment={setCollapseEquipment}
                        isfromAuth={props?.isfromAuth}
                        learnIntcmp={learnIntcmp}
                        selectPlanErr={selectPlanErr}
                        setSelectPlanErr={setSelectPlanErr}
                        isCheckoutNew={props?.isCheckoutNew}
                        setIsFwaBundleSelected={setIsFwaBundleSelected}
                        displayOnlyInternetMobileTab={displayOnlyInternetMobileTab}
                        selectFwaBundletabForJO={selectFwaBundletabForJO}
                        isNewFlow={isNewFlow}
                        hasInternetPackage={hasInternetPackage}
                        hasRegularPackage={hasRegularPackage}
                        hasPackages={hasPackages}
                      /> */}
                       <PlanSelectionNew
                        globalPromos={globalPromos}
                        isCollapsed={collapsePlans}
                        learnPlanCode={learnPlanCode}
                        headerHeight={headerHeight}
                        isPlanError={isPlanError}
                        isHavingBackupPlans={
                          pageData?.backupPlans?.length ? true : false
                        }
                        isPlansBackupSectionCollapsed={
                          isPlansBackupSectionCollapsed
                        }
                        learnBackupPlanCode={learnBackupPlanCode}
                        onNoOfPlansQtyChange={onNoOfPlansQtyChange}
                        onTabChangeApiCall={onTabChangeApiCall}
                        onSelect={onPlanSelected}
                        planDetails={
                          pageData?.tabIndex === 0
                            ? pageData?.cappedPlans
                            : pageData?.uncappedPlans
                        }
                        planDetailsBackup={pageData?.backupPlans}
                        setIsCollapsed={setCollapsePlans}
                        setIsPlansBackupSectionCollapsed={
                          setIsPlansBackupSectionCollapsed
                        }
                        showPromoCard={false}
                        showVisaOffer={
                          sessionCartData?.prospect5gLoopQualAddress?.showVisaOffer
                        }
                        tabIndex={pageData?.tabIndex}
                        setCollapseEquipment={setCollapseEquipment}
                        isfromAuth={props?.isfromAuth}
                        learnIntcmp={learnIntcmp}
                        selectPlanErr={selectPlanErr}
                        setSelectPlanErr={setSelectPlanErr}
                        isCheckoutNew={props?.isCheckoutNew}
                        setIsFwaBundleSelected={setIsFwaBundleSelected}
                        displayOnlyInternetMobileTab={displayOnlyInternetMobileTab}
                        selectFwaBundletabForJO={selectFwaBundletabForJO}
                        isNewFlow={isNewFlow}
                        hasInternetPackage={hasInternetPackage}
                        hasRegularPackage={hasRegularPackage}
                        hasPackages={hasPackages}
                      />
                    </div>
                  )}
                  <div id="primaryEquipmentSel">
                    {(primaryPlan?.routerDetails || backupPlan?.routerDetails) && (
                      <EquipmentSectionNew
                      toggleStatusForCBandRouter = {pageData?.toggleStatusForCBandRouter}
                      onlyCBandQualified ={onlyBandQualified}
                      updateCart = {(price,byor,planType)=>{
                        if(!prePlanSelectedFlowEnabled)
                          updateRouter(price,byor,planType)
                        }
                      }
                      is4G = {true}
                      fromInstallation = {props?.fromInstallation}
                      
                      routers={primaryPlan?.routerDetails}
                      backupPlanRouters={backupPlan?.routerDetails}
                      onRouterSelect={(name, planType) => {
                        dispatch(setIsEditLTEEquipmentFlow(false));
                        dispatch(setSelectedRouter({ planType, value: name }));
                        //dispatch(setSelectedRouterPayment({ planType, value: name }));
                        if(pageData?.toggleStatusForCBandRouter && name in pageData?.toggleStatusForCBandRouter){
                          dispatch(setSelectedRouterPayment({planType: "primaryPlan",
                              value: pageData?.toggleStatusForCBandRouter[name]
                                  ? "devicePayments-0" 
                                  : "zeroYear",
                            })
                          );
                        }
                        if (name === "byor" && !props.isfromAuth) {
                          updateRouter(null, true, planType);
                        }
                        if (name !== "byor" && primaryPlan?.selectedPlan && primaryPlan?.selectedRouterPayment && !prePlanSelectedFlowEnabled) {
                          updateRouter(null, false, planType, name);
                        }
                        if(onlyBandQualified){
                          const element = document.getElementById("onContinue")
                          if(element) element.scrollIntoView({behavior:'smooth',block:'start',inline:'start'})
                          
                        }
                        //setCollapsePlans(true);
                        let element, elementPosition, planElement;
                        if (planType == "primaryPlan" && !onlyBandQualified) {
                          setTimeout(() => {
                            element = document.getElementById("backupEquipmentSel")
                            if (!element) {
                              element = document.getElementById("primaryEquipmentSel")
                              planElement = document.getElementById('plansContainer');

                            }
                            elementPosition = element?.offsetHeight + planElement?.offsetHeight;
                            window.scrollTo({
                              top: elementPosition + headerHeight,
                              behavior: "smooth"
                            });
                          }, timer)
                        }
                        if (planType == "backupPlan" && !onlyBandQualified) {
                          setTimeout(() => {
                            element = document.getElementById("routerPaymentSel")
                            elementPosition = element?.offsetHeight;
                            window.scrollTo({
                              top: (elementPosition + headerHeight),
                              behavior: "smooth"
                            });
                          }, timer)
                        }
                      }}
                      isCollapsed={collapseEquipment}
                      isEditLTEEquipmentFlow={pageData?.isEditLTEEquipmentFlow}
                      isHavingBackupPlans={
                        pageData?.backupPlans?.length ? true : false
                      }
                      setIsCollapsed={setCollapseEquipment}
                      setCollapsePayment={setCollapsePayment}
                      mwwCart={sessionCartData?.cart?.mwwCart}
                      // isfromAuth={props?.isfromAuth}
                      selectRouterErr={selectRouterErr}
                      setSelectRouterErr={setSelectRouterErr}
                      sessionCart ={sessionCartData}
                    />
                    )}
                  </div>
                  <div id="routerPaymentSel">
                    {!onlyBandQualified && (primaryPlan?.routerDetails || backupPlan?.routerDetails) &&
                      (primaryPlan?.selectedRouter !== "byor" ||
                        (backupPlan?.selectedRouter && backupPlan?.selectedRouter !== "byor")) && (
                        <RouterPayment
                          flow="4g"
                          onPaymentSelect={(data, planType) => {
                            //if (props?.isfromAuth) {
                              dispatch(
                                setSelectedRouterPayment({ planType, value: data })
                              );
                            //}
                            if (!props.isfromAuth && !prePlanSelectedFlowEnabled) {
                              updateRouter(data, false, planType);
                            }

                            !learnIntcmp && setCollapsePlans(false);
                            isComparePlanCode && setCollapsePlans(false);
                            var element, elementPosition, planElement, routerElement;
                            if (planType == "primaryPlan") {
                              setTimeout(() => {
                                element = document.getElementById("routerPaymentSel");
                                planElement = document.getElementById('plansContainer');
                                routerElement = document.getElementById('primaryEquipmentSel');

                                if (!element) {
                                  element = document.getElementById("onContinue")
                                }
                                elementPosition = element?.offsetHeight + planElement?.offsetHeight + routerElement?.offsetHeight;
                                window.scrollTo({
                                  top: elementPosition + headerHeight,
                                  behavior: "smooth"
                                });
                              }, 3000)
                            }
                            if (planType == "backupPlan") {
                              setTimeout(() => {
                                element = document.getElementById("onContinue")
                                elementPosition = element?.offsetHeight;
                                window.scrollTo({
                                  top: elementPosition + headerHeight,
                                  behavior: "smooth"
                                });
                              }, 3000)
                            }
                          }}
                          routerDetails={primaryPlan?.routerDetails}
                          backupRouterDetails={backupPlan?.routerDetails}
                          isCollapsed={collapsePayment}
                          isHavingBackupPlans={
                            pageData?.backupPlans?.length ? true : false
                          }
                          setIsCollapsed={setCollapsePayment}
                          isfromAuth={props?.isfromAuth}
                          selectPaymentErr={selectPaymentErr}
                          setSelectPaymentErr={setSelectPaymentErr}
                        />
                      )}
                  </div>
                  <MarginSpacerXL />
                  <div id="onContinue">
                    <IncludedInOrder
                      aemContent={aemContent?.fourG["router-content"]}
                      heading={aemContent?.fourG["router-content"]?.connectionHeading}
                      content={aemContent?.fourG["router-content"]?.connectionOptions}
                      showAll={false}
                      isfromAuth={props?.isfromAuth}
                      isCheckoutNew={props?.isCheckoutNew}
                    />
                  </div>
                  <MarginSpacerXL />
                  {props?.isCheck !== "auth-no" &&
                    props?.isPermission !== "permission-no" ? (
                    <CheckoutButton
                      onClick={onContinueClick}
                      disabled={false}
                      data-track={t("4G-5G.common.page-stepper.continue-btn", { ns: "4G-5G" })}
                    >
                      
                      {
                      t("4G-5G.common.page-stepper.continue-btn", { ns: "4G-5G" })
                      /* {!aemContent?.newJointOfferBundleFlow?.isNewJOFlowEnabled ?
                        t("4G-5G.common.page-stepper.continue-btn", { ns: "4G-5G" })
                        : hasPackages ?
                          hasInternetPackage && hasRegularPackage ? t("4G-5G.common.page-stepper.continue-btn", { ns: "4G-5G" }) :
                            ((hasRegularPackage || globalData?.isBackBtnClick) ? pageData?.fwaBundleTabSelected == true && !sessionCartData?.selectFwaBundletabForJO ? aemContent?.newJointOfferBundleFlow?.mobileFlowContinue : aemContent?.newJointOfferBundleFlow?.internetFlowContinue : t("4G-5G.common.page-stepper.continue-btn", { ns: "4G-5G" })) :
                          (pageData?.fwaBundleTabSelected == true ? aemContent?.newJointOfferBundleFlow?.mobileFlowContinue : aemContent?.newJointOfferBundleFlow?.internetFlowContinue)
                      } */}

                    </CheckoutButton>
                  ) : (
                    <></>
                  )}

                  {((pageData?.fwaBundleTabSelected == true && aemContent?.newJointOfferBundleFlow?.isNewJOFlowEnabled)
                    || globalData.isJoBundleEnabled) &&
                    !(hasInternetPackage && hasRegularPackage) && (
                      <div>
                        <MarginSpacerM />
                        {!hasInternetPackage && (
                          <Body size="large">
                            <TextLink
                              type="inline"
                              onClick={() => {
                                if (!sessionCartData?.selectFwaBundletabForJO) {
                                  setPlanSelected("internet");
                                  dispatch(setJOInternetSelected("internet"));
                                  onContinueClick("", "internetOnlyclicked");
                                  dispatch(setFWABundleTabSelected(false));
                                  setIsFwaBundleSelected(false);
                                }
                                else {
                                  // navigate("/shop/shopping-cart");
                                  dispatch(setLoadShoppingCartWidget(true));
                                  const currentUrl = window.location.href;
		const newUrl = new URL(currentUrl);
		newUrl.searchParams.set("pageName", "ShoppingCart");
		window.history.pushState({}, '', newUrl);
                                  globalData?.isJoBundleEnabled && dispatch(setNoThanksClicked(true))
                                }
                              }}>
                              {aemContent?.newJointOfferBundleFlow?.BundleTextLink}
                            </TextLink>
                            {!sessionCartData?.selectFwaBundletabForJO ? aemContent?.newJointOfferBundleFlow?.BundleCopy : aemContent?.newJointOfferBundleFlow?.BundleInternetCopy}
                          </Body>
                        )}

                      </div>
                    )}

                  <MarginSpacerXL />
                  {((aemContent?.fourG?.skipLandingPage || lqsessionId) && !props?.isfromAuth && false) && (
                    <AlreadyACustomer
                      flow={"4g"}
                      fromPartnersReward={CheckoutData?.isFromPartnersReward}
                      cmpValue={globalData?.cmp?.cmpValue}
                      email={sessionCartData?.prospect5gLoopQualAddress?.email || ''}
                      mtn={sessionCartData?.prospect5gLoopQualAddress?.mtn || sessionCartData?.prospect5gLoopQualAddress?.phoneNumber || ''}
                      addressData={sessionCartData?.prospect5gLoopQualAddress || ''}
                      redirectedFrom={redirectedFrom || ''}
                      lqsessionId={window.localStorage.getItem("lqsessionId") || ''}
                    />
                  )}
                  <div style={{ marginBottom: LayoutTokens.space["32X"].value }} />
                </Col>
              </StyledRow>
            </Grid>
            {showSetupVideoModal && (
              <VideoModal
                onCloseBtnClick={() => setShowSetupVideoModal(false)}
                srcVideoURL={
                  aemContent?.fourG?.["plan-content"].header[headerType].videolink
                }
                title={
                  aemContent?.fourG?.["plan-content"].header?.setUpVideoModal?.title
                }
              />
            )}
            {fourgModal && < FiveGCbandModal opened={fourgModal} onOpenedChange={setFourGModal} isFourG={true} LQData={LQData} BBALQResp={BBALQResp} pageData={pageData} shoppingCartRedirect={shoppingCartRedirect} sessionCartData={sessionCartData} />}
            {showChangeAddressModal && (
              <ChangeAddress
                opened={showChangeAddressModal}
                onOpenedChange={setChangeAddressModal}
                url={""}
              />
            )}
            {jointOfferModal && !isPDPLayoutFWA && <JointOfferDetails setJointOfferModal={setJointOfferModal} jointOfferList={jointOfferList} flow={"4g"} />}
            {jointOfferModal && isPDPLayoutFWA && <JointOfferDetailsNew setJointOfferModal={setJointOfferModal} jointOfferList={jointOfferList} flow={"4g"} />}
          </div>
        </FWAContextProvider>
      }
    </>
  );
};

export default LandingPage4G;
