import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "@vds3/buttons";
import { Modal, ModalBody, ModalTitle } from "@vds3/modals";
import { Input } from "@vds3/inputs";
import { Body, Micro, Title } from "@vds3/typography";
import styled from "styled-components";
import SpinnerComponent from "common/components/Spinner/Spinner";
import {
	MarginSpacerM,
	MarginSpacerS,
	MarginSpacerXL,
} from "../VerifyBusiness/styleTag";
import { Checkbox } from "@vds3/checkboxes";
import {
	mitekSMSVerification,
	mitekCheckVerificationResult,
	setMitekCheckVerificationResult,
	setActiveAccordian,
	sendMitekVerification,
} from "../ducks/CheckoutSlice";
import { MITEK_QR_CODE } from "../../../containers/APIurls";
import { Notification } from "@vds3/notifications";
import { RadioButtonGroup } from "@vds3/radio-buttons";
import { Col, Grid, Row } from "@vds3/grids";
import { MarginTopSpacerL } from "common/components/styleTag";
import { isValidEmail } from "utils/commonUtils";
import { Icon } from "@vds3/icons";
const FModal = styled(Modal)`
	height: auto;
	> div:first-child {
		overflow: hidden;
		min-height: unset;
	}
	width: 596px !important;
	max-width: 596px !important;
	padding: 3rem 0 3rem 3rem;
	a {
		text-decoration: underline;
	}
	button[class^="StyledButton-sc"] {
		width: 40%;
	}

	@media only screen and (max-width: 767px) {
		> div:first-child {
			padding: 2px;
		}
		padding: 1rem 0 1rem 1rem;
		button[class^="StyledButton-sc"] {
			width: 100%;
		}
		.pad-rt-custom {
			padding-right: 0rem;
		}
	}
`;

const ModalCard = styled.div`
	border: 1px solid rgba(216, 208, 208, 0.5);
	padding: 15px;
	margin-bottom: 12px;
`;

const CustomRadioButtonGroup = styled(RadioButtonGroup)`
	display: flex !important;
	flex-direction: row;
	column-gap: 30px;
	> div > label {
		font-size: small;
		> div > span[class*="cLgMcI"] {
			font-weight: normal;
		}
	}
	> div[class*="ComponentWrapper-VDS__sc-bccgul-0"] {
		margin-top: 0;
	}
`;

const MitekModal = (props) => {
	const { t, i18n } = useTranslation(["app","checkout"]);

	const pageData = useSelector((state) => state.ProspectWidgets_CheckoutData);
	const isLoading = pageData?.mitekVerficationResultLoading;
	const dispatch = useDispatch();
	const [agreeChecked, setAgreeChecked] = useState(false);
	const [mobileNumber, setMobileNumber] = useState(
		pageData?.eligiblePhoneNumberList?.[0]?.value
	);
	const [email, setEmail] = useState(
		props?.sessionCartData?.businessInfo?.email
	);
	const [sendMitekError, setSendMitekError] = useState(false);
	const [showRefreshBtn, setShowRefreshBtn] = useState(false);
	const [continueBtnClicked, setContinueBtnClicked] = useState(false);
	const [showContinueNext, setShowContinueNext] = useState(false);
	const [mitekOption, setMitekOption] = useState(
		pageData?.eligiblePhoneNumberList?.length > 0 ? "sms" : "email"
	);

	const mitekAemRes = t("ddiaVerification.DDIA", {
		ns: "app",
		returnObjects: true,
	});
	useEffect(() => {
		if (continueBtnClicked) {
			if (
				pageData?.mitekCheckVerificationResult?.verificationStatus === "PASSED"
			) {
				setShowContinueNext(true);
				setShowRefreshBtn(false);
			} else if (
				pageData?.mitekCheckVerificationResult?.verificationStatus === "STARTED" ||
				pageData?.mitekCheckVerificationResult?.verificationStatus === "NOT_FOUND"
			) {
				setShowRefreshBtn(true);
			} else if (
				pageData?.mitekCheckVerificationResult?.verificationStatus ===
					"STORE_VISIT_ENABLED" ||
				pageData?.mitekCheckVerificationResult?.verificationStatus ===
					"INITIATE_BLENDED"
			) {
				setShowContinueNext(true);
				setShowRefreshBtn(false);
				//navigate("/shop/uc/personal-validation");
			} else if (
				pageData?.mitekCheckVerificationResult?.verificationStatus === "FAIL" ||
				pageData?.mitekCheckVerificationResult?.verificationStatus === "FAILED" ||
				pageData?.mitekCheckVerificationResult?.verificationStatus === "DECLINED"
			) {
				setShowContinueNext(false);
				setShowRefreshBtn(false);
				setShowRefreshBtn(false);
				setSendMitekError(true);
				//navigate("/shop/store-search");
			}
		}
	}, [pageData?.mitekCheckVerificationResult]);

	useEffect(() => {
		if (continueBtnClicked) {
			if (pageData?.sendMitekVerification?.status?.success) {
				setSendMitekError(false);
				setShowRefreshBtn(true);
			} else {
				setSendMitekError(true);
				setShowRefreshBtn(false);
			}
		}
	}, [pageData?.sendMitekVerification]);

	const handleMitekSubmit = () => {
		setContinueBtnClicked(true);
		// dispatch(
		// 	setActiveAccordian(pageData?.activeAccordionDisplayOrder?.creditResult)
		// );
		dispatch(
			sendMitekVerification({
				mtn: mobileNumber,
				email: email,
				shoppingPath: "PROSPECT",
				approach: "",
				flow: "prospect",
				notificationType: mitekOption === "email" ? "MITEK_EMAIL" : "MITEK_SMS",
			})
		);
	};
	const handleCheckMitekVerification = () => {
		dispatch(setMitekCheckVerificationResult({}));
		dispatch(mitekCheckVerificationResult());
	};
	const onSelectChange = (e) => {
		setMitekOption(e?.target?.value);
	};

	const setContactInfo = (type, e) => {
		if (type === "email") {
			setEmail(e);
		} else if (type === "phoneNumber") {
			setMobileNumber(e);
		}
	};
	const options =
		pageData?.eligiblePhoneNumberList?.length > 0
			? [
					{
						name: "mitekOptions",
						label: "SMS",
						value: "sms",
						ariaLabel: "sms",
						disabled: false,
					},
					{
						name: "mitekOptions",
						label: "Email",
						value: "email",
						ariaLabel: "email",
					},
			  ]
			: [
					{
						name: "mitekOptions",
						label: "Email",
						value: "email",
						ariaLabel: "email",
					},
			  ];
	return (
		<>
			{isLoading && <SpinnerComponent />}
			{/* {sendMitekError && (
				<Notification
					type="error"
					title={`${t("ddiaVerification.DDIA.mitek.modal.messages.wireless_number", {
						ns: "app",
					})}`}
					fullBleed={false}
					inlineTreatment={false}
					hideCloseButton={true}
					layout="vertical"
				/>
			)} */}
			<Body size="medium" bold>
				{t(`verification.optixCheckout.${mitekOption == "sms" ? "mitekAccordianSMSSubHeader" : "mitekAccordianEmailSubHeader"}`, {
					ns: "checkout",
				})}
			</Body>
			<MarginTopSpacerL />
			{showRefreshBtn || showContinueNext ? (
				<Row>
					<Col colSizes={{ mobile: 4, tablet: 4, desktop: 4 }}>
						<Body size="medium" bold>
							{t("verification.optixCheckout.mitekStatusText", {
								ns: "checkout",
							})}
						</Body>
					</Col>
					<Col colSizes={{ mobile: 4, tablet: 4, desktop: 4 }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							{showContinueNext ? (
								<Icon name="checkmark-alt" color={"rgb(0, 184, 69)"} size="medium" />
							) : (
								""
							)}
							<Body size="medium" bold>
								{showContinueNext
									? t("verification.optixCheckout.mitekStatusPassed", {
											ns: "checkout",
									  })
									: t("verification.optixCheckout.mitekStatusPending", {
											ns: "checkout",
									  })}
							</Body>
						</div>
					</Col>
				</Row>
			) : (
				<>
					<CustomRadioButtonGroup
						id="mitekOptions"
						onChange={(e) => onSelectChange(e)}
						error={false}
						selected={mitekOption}
						data={options}
					/>
					<MarginTopSpacerL />
					<Row>
						<Col
							colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}
							className="pad-tp-24 pad-bt-8"
						>
							{mitekOption === "email" ? (
								<Input
									label={`Business email address`}
									placeholder=""
									value={email}
									onChange={(e) => setContactInfo("email", e?.target?.value)}
									width={"350px"}
									disabled={true}
									maxLength={50}
									error={!isValidEmail(email)}
									errorText={t("verification.errorMsgs.emailReq", {
										ns: "checkout",
									})}
									helperText={showRefreshBtn ? "Email sent successfully" : ""}
								/>
							) : (
								// <Input
								// 	label={`Business mobile number`}
								// 	placeholder=""
								// 	value={
								// 		mobileNumber?.length === 10
								// 			? mobileNumber.includes("-")
								// 				? mobileNumber
								// 				: mobileNumber?.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
								// 			: mobileNumber
								// 	}
								// 	onChange={(e) =>
								// 		setContactInfo("phoneNumber", e.target.value?.replace(/[^\d]/g, ""))
								// 	}
								// 	width={"100%"}
								// 	maxLength={10}
								// 	error={mobileNumber?.length < 10}
								// 	errorText={`Please enter a valid mobile number`}
								// 	helperText={showRefreshBtn ? "SMS sent successfully" : ""}
								// />
								<RadioButtonGroup
									id=""
									onChange={(e) =>
										setContactInfo("phoneNumber", e.target.value?.replace(/[^\d]/g, ""))
									}
									error={false}
									data={pageData?.eligiblePhoneNumberList}
								/>
							)}
						</Col>
					</Row>
				</>
			)}
			<MarginSpacerXL />
			{showContinueNext ? (
				<Button
					size="medium"
					primary
					className="calBtn"
					onClick={() =>
						dispatch(
							setActiveAccordian(pageData?.activeAccordionDisplayOrder?.creditResult)
						)
					}
				>
			{t("verification.continueBtn", { ns: "checkout" })}

				</Button>
			) : showRefreshBtn ? (
				<Row>
					<Col colSizes={{ mobile: 4, tablet: 3, desktop: 3 }}>
						<Button
							size="medium"
							use="secondary"
							className="calBtn"
							onClick={
								() => handleCheckMitekVerification()
								// dispatch(
								// 	setActiveAccordian(pageData?.activeAccordionDisplayOrder?.creditResult)
								// )
							}
						>
							{t("verification.refresh", { ns: "checkout" })}
						</Button>
					</Col>
					<Col colSizes={{ mobile: 4, tablet: 3, desktop: 3 }}>
						<Button
							size="medium"
							use="secondary"
							className="calBtn"
							disabled={
								mitekOption === "email"
									? !isValidEmail(email)
									: mobileNumber?.length < 10
							}
							onClick={
								() => handleMitekSubmit()
								// dispatch(
								// 	setActiveAccordian(pageData?.activeAccordionDisplayOrder?.creditResult)
								// )
							}
						>
							{t("verification.resend", { ns: "checkout" })}
						</Button>
					</Col>
				</Row>
			) : (
				<Button
					size="medium"
					primary
					className="calBtn"
					disabled={
						mitekOption === "email" ? !isValidEmail(email) : mobileNumber?.length < 10
					}
					onClick={
						() => handleMitekSubmit()
						// dispatch(
						// 	setActiveAccordian(pageData?.activeAccordionDisplayOrder?.creditResult)
						// )
					}
				>
					{t("verification.send", { ns: "checkout" })}
				</Button>
			)}
		</>
	);
};

export default MitekModal;
