import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Grid, Row, Col } from "@vds3/grids";
import {
	ListGroup,
	ListGroupItem,
	ListGroupItemTitle,
	ListGroupItemSubtitle,
} from "@vds3/lists";
import { Body, Title, Micro } from "@vds3/typography";
import PDPContext from "../pdpContext";
import {
	MarginSpacerL,
	MarginSpacer2XL,
	MarginSpacerXL,
} from "common/components/styleTag";
// import "./specs.css";

const Specifications = ({
	deviceDetails,
	isOneTalk,
	isAccessoryDevice,
	sku,
	...props
}) => {
	const { t } = useTranslation(["pdp"]);
	const { selectedSku } = React.useContext(PDPContext);
	const specsText = isAccessoryDevice
		? deviceDetails?.techSpecification
		: deviceDetails?.basicFeaturesText;

	return (
		<div
			ref={props.scrollRef}
			style={{
				scrollMarginTop: props?.scrollMarginTop ? props.scrollMarginTop : 0,
			}}
		>
			{isAccessoryDevice ? (
				<Grid rowGutter="0.5rem" className="no-margin" colGutter={false}>
					<Row>
						<Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
							{specsText ? (
								<div>
									<div className="heading">
										{/* <Title size="small">
                      {t("accessory.pdp.accessoryspec", { ns: "accessories" })}{" "}
                    </Title> */}
									</div>
									<AccessorySpecs>
										<span dangerouslySetInnerHTML={{ __html: specsText }} />
									</AccessorySpecs>
								</div>
							) : (
								""
							)}
							<div>
								<div className="heading">
									<Title bold size="small">
										{t("device-pdp.sku", { ns: "pdp" })}
									</Title>
								</div>
								<div class="attr-spec bdr-black">
									<Grid colGutter={false}>
										<Row>
											<Col colSizes={{ mobile: 2, tablet: 3, desktop: 3 }}>
												<div>
													<p>{t("device-pdp.sku", { ns: "pdp" })}</p>
												</div>
											</Col>
											<Col colSizes={{ mobile: 2, tablet: 9, desktop: 9 }}>
												<div>
													<p>{props?.sku}</p>
												</div>
											</Col>
										</Row>
									</Grid>
									<div class="gray-border"></div>
								</div>
							</div>
						</Col>
					</Row>
				</Grid>
			) : (
				<>
					<MarginSpacerL />
					<Grid rowGutter="0.5rem" className="no-margin" colGutter={false}>
						<Row>
							<Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
								<SpecsContainer>
									{deviceDetails?.groupSpecs?.map((element) => {
										return (
											<>
												<Title size="medium" bold>
													{element?.groupName}
												</Title>
												<ListGroup topLine={false} bottomLine={true} surface="light">
													{element?.specs?.map((spec, i) => (
														<ListGroupItem key={spec?.attributeKey} actionElement="none">
															<ListGroupItemTitle>{spec?.attributeKey}</ListGroupItemTitle>
															<ListGroupItemSubtitle>
																{spec?.attributeValue}
															</ListGroupItemSubtitle>
														</ListGroupItem>
													))}
												</ListGroup>
												<MarginSpacer2XL />
											</>
										);
									})}
									<Title size="medium" bold>
										{t("device-pdp.sku", { ns: "pdp" })}
									</Title>
									<ListGroup topLine={false} bottomLine={true} surface="light">
										<ListGroupItem actionElement="none">
											<ListGroupItemTitle>
												{selectedSku?.selectedSku?.sku
													? selectedSku?.selectedSku?.sku
													: sku}
											</ListGroupItemTitle>
										</ListGroupItem>
									</ListGroup>
								</SpecsContainer>
							</Col>
							<Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
								<WhatsInTheBox>
									<Title size="medium" bold>
										{t("device-pdp.whatsInTheBox", { ns: "pdp" })}
									</Title>
									<MarginSpacerXL />
									<Body size="large">
										<span
											dangerouslySetInnerHTML={{
												__html: deviceDetails.inTheBox,
											}}
										/>
									</Body>
								</WhatsInTheBox>
							</Col>
							{deviceDetails?.additionalDisclosure ? (
								<Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
									<Footnotes>
										<Micro>
											<span
												dangerouslySetInnerHTML={{
													__html: deviceDetails.additionalDisclosure,
												}}
											/>
										</Micro>
									</Footnotes>
								</Col>
							) : (
								<></>
							)}
						</Row>
					</Grid>
				</>
			)}
		</div>
	);
};

const SpecsContainer = styled.div`
	@media (min-width: 546px) {
		margin-right: 2.5rem;
	}
`;
const Footnotes = styled.div`
	ol {
		padding-left: 0.5rem;
	}
	@media (min-width: 546px) {
		margin-top: 2.5rem;
	}
	@media (max-width: 545px) {
		margin-top: 1.5rem;
	}
	a {
		color: black;
	}
`;
const WhatsInTheBox = styled.div`
	ul {
		padding-left: 1rem;
	}
	li {
		margin-bottom: 1rem;
	}
	@media (max-width: 545px) {
		margin-top: 2.5rem;
	}
`;
const AccessorySpecs = styled.div`
	ul {
		padding-left: 1rem;
	}
	ul li {
		padding-top: 1rem;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.25rem;
		letter-spacing: 0.03125rem;
	}
`;

export default Specifications;
