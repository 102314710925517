import React from "react";
import { Col, Grid, Row } from "@vds3/grids";
import { Modal, ModalBody, ModalTitle } from "@vds3/modals";
import { Title, Body } from "@vds3/typography";
import styled from "styled-components";
import "./ProgressOverlay.css";

const FModal = styled(Modal)`
[class^=StyledHeader-sc]{
  padding-right:0px !important;
}
[class^=ChildWrapper-sc]{
  margin:2rem 0;
}
@media only screen and (min-width: 601px){
  max-height:100vh;
   height: auto;
  min-height: unset;
  padding: 50px 45px;
  > div:first-child {
    overflow: hidden;
    min-height: unset;
    >span{
      padding-right: 2px;
    }
  }
}
.progressBarWrapper{
  margin-top:1rem;
}
`;

const FModalBody = styled(ModalBody)`
  padding-left: 0;
  padding-right: 0;
`;

const ProgressOverlay = ({
  onOpenedChange,
  showProgressOverlay,
  modalHeading,
  modalDesc,
  modalFooter,
  progress,
  color = "#008330",
}) => {
  const [progressNew,setProgress] = React.useState(0)
  React.useEffect(()=>{
    setTimeout(()=>{
      if(progressNew < 90&&showProgressOverlay) setProgress(progressNew+5)
    },400)
  if(!showProgressOverlay && progressNew !== 100) setProgress(100)
  },[progressNew])
  return (
    <FModal
      hideCloseButton={true}
      disableOutsideClick={true}
      onOpenedChange={onOpenedChange}
      opened={showProgressOverlay}
    >
      <ModalTitle>
        <Title size="large">{`${modalHeading}`}</Title>
      </ModalTitle>
      <FModalBody>
        <div className="progress-overlay-body">
          <Grid
            colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
            colGutter={false}
            rowGutter="5"
          >
            <Row>
              {modalDesc!==null&&modalDesc?
              <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
                <div className="description">
                  <Body size="large">{modalDesc}</Body>
                </div>
              </Col>:<></>}
              <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
                <div className="footer">
                  <Body size="large">{modalFooter}</Body>
                </div>
              </Col>
              <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
                <div className="progressBarWrapper">
                  <div
                    className="progress"
                    style={{ width: `${progressNew}%`, backgroundColor: color }}
                  ></div>
                  <div className="unprogress"></div>
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
      </FModalBody>
    </FModal>
  );
};

export default ProgressOverlay;
