import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextLinkCaret } from "@vds3/buttons";
import { Col, Grid, Row } from "@vds3/grids";
import { Line } from "@vds3/lines";
import { MarginSpacerL, MarginSpacerM } from "../../common/components/styleTag";
import { Body, Title } from "@vds3/typography";
import { Table, TableHead, TableHeader, TableBody, TableRow, Cell } from "@vds3/tables";
import { setCurrentFlow, setLoadFWAQualifiedPlans, setLoadQualficationLanding, setLoadQualficationSelection } from "app/ducks/GlobalSlice";
import { Checkbox } from "@vds3/checkboxes";
import { Icon } from "@vds3/icons";
import { setQualifiedRemovedAddress, setQualifiedSelectedAddress } from "./ducks/QualificationSlice";
import styled from "styled-components";
const qulaifiedDataStoreData = {
	"listOfAdresses": [
		{
			"qualified4G": "true", // 4g support
			"qualifiedCbandforLoggedin": "true", //5g support
			"qualified": null,
			"qualifiedFiosHome": null,
			"eventCorrelationId": "75013_1723389573136_91",
			"address": "1090 W EXCHANGE PKWY",
			"suiteUnit": null,
			"city": "ALLEN",
			"state": "TX",
			"zipcode": "75013"
		}
	],
	"duplicateAdresses": 0,
	"processedAdresses": 2,
	"totalAdresses": 2,
	"failedAdresses": 0,
	"mmwAdresses": 0,
	"cbandAdresses": 1,
	"lteAdresses": 2,
}
const BackLink = styled.div`
	margin-right: 2rem;
		margin-bottom: 20px;
`;
const QualificationSelection = ({ ...props }) => {
	const { t } = useTranslation(["app"]);
	const dispatch = useDispatch();
	const bulkAddressResp = useSelector((state) => state.ProspectWidgets_LQPage?.BBALQBulkAddressResp);
	const qualifiedRemovedAddress = useSelector((state) => state.ProspectWidgets_LQPage?.qualifiedRemovedAddress);
	const bulkAddressObj = bulkAddressResp?.response?.prospectBulkLoopQualAddress
	const bulkAddressArr = Object.values(bulkAddressObj);
	let qualifedAddress = []
	bulkAddressArr?.map((address) => {
		qualifedAddress.push({
			id: address?.eventCorrelationId,
			"qualified4G": true, // 4g support
			"qualifiedCbandforLoggedin": address?.qualified5GCband, //5g support
			"qualified": null,
			"qualifiedFiosHome": null,
			"eventCorrelationId": address?.eventCorrelationId,
			"address": address?.addressLine1,
			"suiteUnit": address?.unit,
			"city": address?.city,
			"state": address?.state,
			"zipcode": address?.zipCode
		});
	});
	const qulaifiedDataStoreData = {
		bulkAddressCheckResponse: {
			listOfAdresses: qualifedAddress,
			duplicateAdresses: 0,
			processedAdresses: bulkAddressArr?.length,
			totalAdresses: bulkAddressArr?.length,
			failedAdresses: 0,
			mmwAdresses: 0,
			cbandAdresses: bulkAddressArr?.length,
			lteAdresses: bulkAddressArr?.length,
		}
	}
	const [qulaifiedData, setQualifiedData] = useState(qulaifiedDataStoreData);
	const [selectedNetworkType, setSelectedNetworkType] = useState("");
	const bulkAddressCheckResponse = qulaifiedData?.bulkAddressCheckResponse;



	useEffect(() => {
		const currentUrl = window.location.href;
		const objUrl = new URL(currentUrl)
		const searchParams = new URLSearchParams(objUrl?.search)
		// Function to handle the resize event
		const handleBackButonSel = () => {
			if (searchParams.has("pageName")) {
				objUrl?.searchParams.set("pageName", "QualificationLanding");
				window.history.replaceState({}, '', objUrl);
				dispatch(setLoadQualficationSelection(false))
			}

		};

		// Add the event listener
		window.addEventListener('popstate', handleBackButonSel);

		// Cleanup function to remove the listener on unmount
		return () => {
			window.removeEventListener('popstate', handleBackButonSel);
		};
	}, [dispatch])




	const handlePurchaseSubmit = () => {
		dispatch(setQualifiedSelectedAddress(selectedAddress))
		dispatch(setCurrentFlow(selectedNetworkType))
		dispatch(setLoadFWAQualifiedPlans(true));
		dispatch(setLoadQualficationSelection(false));
		dispatch(setLoadQualficationLanding(false));
		const currentUrl = window.location.href;
		const newUrl = new URL(currentUrl);
		newUrl.searchParams.set("pageName", "FWAPlansLanding");
		window.history.pushState({}, '', newUrl);
	}


	const handleSelection = (address, type, checked) => {
		const list = qulaifiedData?.bulkAddressCheckResponse?.listOfAdresses
		/* 
		const adIndex = list?.find( (ad) => ad.id  === address?.id)
		list[adIndex] = {
			...list[adIndex],
			selectedNetworkType: type
		} */

		const listOfAdresses = list?.map((adr) => {
			if (adr.eventCorrelationId === address?.eventCorrelationId)
				return {
					...adr,
					selectedNetworkType: checked ? type : "",
					selected: checked
				}

			return adr;
		});

		setQualifiedData({
			...qulaifiedData,
			bulkAddressCheckResponse: {
				...qulaifiedData.bulkAddressCheckResponse,
				listOfAdresses
			}
		});
		setSelectedNetworkType(checked ? type : "");
	}

	const handleAllSelection = (type, checked) => {
		const list = qulaifiedData?.bulkAddressCheckResponse?.listOfAdresses

		const listOfAdresses = list?.map((adr) => ({

			...adr,
			selectedNetworkType: checked ? type : "",
			selected: checked

		}));

		setQualifiedData({
			...qulaifiedData,
			bulkAddressCheckResponse: {
				...qulaifiedData.bulkAddressCheckResponse,
				listOfAdresses
			}
		});
		setSelectedNetworkType(checked ? type : "");
	}
	const getButtonDisabled = (type) => {
		return selectedNetworkType != "" && selectedNetworkType != type;
	}

	const selectedAddress = qulaifiedData?.bulkAddressCheckResponse?.listOfAdresses?.filter(addr => addr.selected == true);

	const getButtonText = (address, type) => {
		return qulaifiedData?.bulkAddressCheckResponse?.listOfAdresses?.some(addr => addr.selectedNetworkType === type && addr.eventCorrelationId === address.eventCorrelationId) ? true : false;
	}

	const is5GEligible = 
		 qulaifiedData?.bulkAddressCheckResponse?.listOfAdresses?.some(addr => addr.qualifiedCbandforLoggedin) ? true : false;

	const hanldeRemoveItem = (address) => {
		setQualifiedData({
			...qulaifiedData,
			bulkAddressCheckResponse: {
				...qulaifiedData.bulkAddressCheckResponse,
				listOfAdresses: qulaifiedData?.bulkAddressCheckResponse?.listOfAdresses?.filter((i) => i.id !== address.id)
			}

		});
		dispatch(setQualifiedRemovedAddress(qualifiedRemovedAddress?.push(address)));
	}
	return (
<>	<BackLink>
											<TextLinkCaret
												iconPosition="left"
												onClick={() => {
													dispatch(setLoadQualficationSelection(false));
												}}
											>
												Back
											</TextLinkCaret>
										</BackLink>
		<Grid >
			<Row>
				<Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
					<Title size="medium" bold>
						Total - {bulkAddressCheckResponse?.totalAdresses} | Qualified - {bulkAddressCheckResponse?.processedAdresses}  | Duplicates - {bulkAddressCheckResponse?.duplicateAdresses}  | Failed - {bulkAddressCheckResponse?.failedAdresses}
					</Title>
				</Col>
			</Row>
			<MarginSpacerL />
			<Row>
				<Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
					<Body size="large" >
						Select all the locations you would like to purchase
					</Body>

				</Col>
				<Col colSizes={{ mobile: 4, tablet: 3, desktop: 3 }}>
					<Body size="large" >

						{selectedAddress?.length ?? 0} / {bulkAddressCheckResponse?.totalAdresses} locations selected
					</Body>
				</Col>
				<Col colSizes={{ mobile: 4, tablet: 3, desktop: 3 }}>
					<Body size="large" >

						<Button
							size="large"
							width="100%"
							disabled={!qulaifiedData?.bulkAddressCheckResponse?.listOfAdresses?.some(addr => addr.selectedNetworkType && addr.selectedNetworkType !== "")}
							onClick={() => {
								handlePurchaseSubmit();
							}}
						>
							Purchase
						</Button>
					</Body>

				</Col>

			</Row>

			<Row>
				<Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
					<MarginSpacerM />
					<Line type="primary" />
					<Table
						striped={false}
						bottomLine="secondary"
						padding="standard"
					>
						<TableHead bottomLine="primary">
							<TableHeader>Address</TableHeader>
							<TableHeader>Zip code </TableHeader>
							<TableHeader><div>LTE Business Internet</div>
								{/* <Body size="large">{" "}</Body>
							<Body>{bulkAddressCheckResponse?.lteAdresses}{" "}addresses</Body> */}
							</TableHeader>
							<TableHeader><div>5G Business Internet</div>
								{/* <Body size="large">Self Installation</Body>
								<Body>{bulkAddressCheckResponse?.cbandAdresses}{" "}addresses</Body> */}
							</TableHeader>
							<TableHeader>Download All</TableHeader>
						</TableHead>
						<TableBody>
							<TableRow>
								<Cell>
								</Cell>
								<Cell>
								</Cell>
								<Cell>
									{/* <Button size="small" use="secondary" disabled={getButtonDisabled("4g")}


										onClick={() => handleAllSelection('4g')}

									>{qulaifiedData?.bulkAddressCheckResponse?.listOfAdresses?.every(addr => addr.selectedNetworkType === '4g') ? "Selected all" : "Seelct all"}</Button> */}
									<Checkbox
										disabled={getButtonDisabled("4g")}
										error={false}
										errorText=""
										label="Select all"
										onChange={(e) => handleAllSelection("4g", e?.target?.checked)}
										// onKeyDown={(e) => setIsCheckBoxSelected(e?.target?.checked)}
										selected={qulaifiedData?.bulkAddressCheckResponse?.listOfAdresses?.every(addr => addr.selectedNetworkType === '4g')}
										width="100%"
									>
									</Checkbox>

								</Cell>
								<Cell>
									{/* <Button size="small" use="secondary" disabled={getButtonDisabled("5g")}
										onClick={() => handleAllSelection('5g')}
									> {qulaifiedData?.bulkAddressCheckResponse?.listOfAdresses?.every(addr => addr.selectedNetworkType === '5g') ? "Selected all" : "Seelct all"}</Button> */}
									{is5GEligible && <Checkbox
										disabled={getButtonDisabled("5g")}
										error={false}
										errorText=""
										label="Select all"
										onChange={(e) => handleAllSelection("5g", e?.target?.checked)}
										// onKeyDown={(e) => setIsCheckBoxSelected(e?.target?.checked)}
										selected={qulaifiedData?.bulkAddressCheckResponse?.listOfAdresses?.every(addr => addr.selectedNetworkType === '5g')}
										width="100%"
									>
									</Checkbox>
                                   }
								</Cell>
								<Cell></Cell>
							</TableRow>
							{qulaifiedData?.bulkAddressCheckResponse?.listOfAdresses?.map(address => {
								if (address) {
									return (
										<TableRow>
											<Cell>
												{address?.address}
											</Cell>
											<Cell>
												{address?.zipcode}
											</Cell>
											<Cell>
												{/* {address?.qualified4G == "true" && <Button size="small" use="secondary" onClick={() => {
													handleSelection(address, "4g")
												}

												}
													disabled={getButtonDisabled("4g")}

												> {getButtonText(address, "4g")}</Button>} */}
												{address?.qualified4G == true && <Checkbox
													disabled={getButtonDisabled("4g")}
													error={false}
													errorText=""
													label="Select"
													onChange={(e) => handleSelection(address, "4g", e?.target?.checked)}
													// onKeyDown={(e) => setIsCheckBoxSelected(e?.target?.checked)}
													selected={getButtonText(address, '4g')}
													width="100%"
												>
												</Checkbox>}
											</Cell>
											<Cell>
												{/* {address?.qualifiedCbandforLoggedin == "true" && <Button size="small" use="secondary" onClick={() => {
													handleSelection(address, "5g")
												}

												}
													disabled={getButtonDisabled("5g")}
												> {getButtonText(address,'5g')}</Button>
												} */}
												{/* <CloseIconWrapper onClick={() => setIsVisible(false)}>
							<Icon name="close" color={color || "#ffffff"} size={tooltipIconSize} />
						</CloseIconWrapper */}
												{address?.qualifiedCbandforLoggedin == true && <Checkbox
													disabled={getButtonDisabled("5g")}
													error={false}
													errorText=""
													label="Select"
													onChange={(e) => handleSelection(address, "5g", e?.target?.checked)}
													// onKeyDown={(e) => setIsCheckBoxSelected(e?.target?.checked)}
													selected={getButtonText(address, '5g')}
													width="100%"
												>
												</Checkbox>}
											</Cell>
											<Cell>
												<div onClick={() => hanldeRemoveItem(address)} >
													<Icon
														name="trash"
														size="large"
														color="#000000"

													/></div></Cell>
										</TableRow>
									)
								}
							})}

						</TableBody>
					</Table>
				</Col>
			</Row>


		</Grid>
		</>
	)
};

export default QualificationSelection;
