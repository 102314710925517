import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import {
  validateDeviceId,
  validateSimId,
  setValidateDeviceIdResp,
  setValidateSimIdResp,
} from "pages/Landing5G-V2/ducks/5GSlice";

const useDeviceCompatibilityCheck = () => {
  const [validateDeviceIdResponse, setValidateDeviceIdResponse] = useState();
  const [validateSimIdResponse, setValidateSimIdResponse] = useState();
  const pageData = useSelector((state) => state.ProspectWidgets_5GPlansData);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setValidateDeviceIdResp(''));
      dispatch(setValidateSimIdResp(''));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(pageData?.byor?.validateDeviceIdResp)) {
      setValidateDeviceIdResponse(pageData?.byor?.validateDeviceIdResp);
      // dispatch(setValidateDeviceIdResp(''));
    }
  }, [pageData?.byor?.validateDeviceIdResp]);

  useEffect(() => {
    if (!isEmpty(pageData?.byor?.validateSimIdResp)) {
      setValidateSimIdResponse(pageData?.byor?.validateSimIdResp);
      // dispatch(setValidateSimIdResp(''));
    }
  }, [pageData?.byor?.validateSimIdResp]);

  const callValidateDeviceId = (deviceId, cband = true, flow = "5g") => {
    const request = {
      deviceId: deviceId,
      router: true,
      cband: cband,
      flow: flow,
    };
    dispatch(validateDeviceId(request));
  };

  const callValidateSimId = (deviceId, simId, cband = true, flow = "5g") => {
    const request = {
      deviceId: deviceId,
      simNumber: simId,
      newSim: false,
      cband: cband,
      flow: flow,
    };
    dispatch(validateSimId(request));
  };

  return {
    callValidateDeviceId,
    callValidateSimId,
    validateDeviceIdResp: validateDeviceIdResponse,
    validateSimIdResp: validateSimIdResponse,
  };
};

export default useDeviceCompatibilityCheck;
