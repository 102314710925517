// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.display-features{
    display: inline;
  }

.display-planchange-info{
    display: inline;
  }`, "",{"version":3,"sources":["webpack://./src/common/components/FWA/PlanSelectionNew/PlanCard.css"],"names":[],"mappings":"AAAA;IACI,eAAe;EACjB;;AAEF;IACI,eAAe;EACjB","sourcesContent":[".display-features{\n    display: inline;\n  }\n\n.display-planchange-info{\n    display: inline;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
