import React from "react";

const ShoppingBag = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg"
			aria-hidden="true"
			role="img"
			width= "60"
			height= "40"
			preserveAspectRatio="xMidYMid meet"
			viewBox="0 0 1024 1024" >
			<circle cx="40%" cy="20%" r="100" fill="red"/>
			<path
				fill="currentColor"				
				d="M401.067,145.067H358.4V102.4C358.4,45.935,312.465,0,256,0S153.6,45.935,153.6,102.4v42.667h-42.667
			c-4.71,0-8.533,3.823-8.533,8.533v349.867c0,4.71,3.823,8.533,8.533,8.533h290.133c4.71,0,8.533-3.823,8.533-8.533V153.6
			C409.6,148.89,405.777,145.067,401.067,145.067z M170.667,102.4c0-47.053,38.281-85.333,85.333-85.333
			s85.333,38.281,85.333,85.333v42.667H170.667V102.4z M392.533,494.933H119.467v-332.8H153.6v25.6c0,4.71,3.823,8.533,8.533,8.533
			c4.71,0,8.533-3.823,8.533-8.533v-25.6h170.667v25.6c0,4.71,3.823,8.533,8.533,8.533c4.71,0,8.533-3.823,8.533-8.533v-25.6h34.133
			V494.933z"/>
			
		</svg>
	);
};

export default ShoppingBag;
