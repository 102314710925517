import isEmpty from "lodash/isEmpty";

export const getNumDevices = (cart, creditResult) => {
  // get the number of devices with amount due today
  let count = 0;
  const packages = cart?.packages;
  for (const pkg in packages) {
    if (
      creditResult === "securityDeposit" ||
      creditResult === "dppToFRPWithDeposit"
    ) {
      if (packages[pkg]?.downPayment && packages[pkg].downPayment > 0) {
        count++;
      }
    } else {
      if (packages[pkg]?.totalDueToday && packages[pkg]?.totalDueToday > 0) {
        count++;
      }
    }
  }
  return count;
};

export const getCreditCheckResult = (res) => {
  if (
    res?.serviceStatus?.statusCode === "CREDIT_HOLD_NEEDED" ||
    res?.serviceStatus?.statusCode === "CREDIT_RESULTS_FOR_HOLD_NEEDED"
  ) {
    return "CREDIT_HOLD_NEEDED";
  }
  const statusCode = res?.data?.status?.statusCode?.toUpperCase();
  if (res?.data?.status?.success) {
    if (statusCode === "APPROVED") {
      if (res?.data?.cartData?.dppToFppChangedFlag) {
        //case 2
        if (Number(res?.data?.securityDeposit) === 0) {
          return "dppToFRP";
        } else {
          return "dppToFRPWithDeposit";
        }
      } else if (res?.data?.withinFinanceLimit == false) {
        if (Number(res?.data?.requiredDownPaymentPercentage) === 0) {
          return "finance"; //case 5
        } else if (Number(res?.data?.requiredDownPaymentPercentage) > 0) {
          return "partialFinance"; //case 6
        }
      } else {
        // 0 maxDownPaymentPercent means approved //case 4
        if (
          Number(res?.data?.requiredDownPaymentPercentage) === 0 &&
          Number(res?.data?.securityDeposit) === 0
        ) {
          return "approved";
        } else if (Number(res?.data?.securityDeposit) > 0) {
          return "securityDeposit";
        } else {
          // > 0 maxDownPaymentPercent means approved but customer representative will call the customer case1
          return "conditionallyApproved";
        }
      }
    }
  } else {
    if (statusCode === "UNAPPROVED") {
      return "unApproved";
    } else {
      return "pendingReview";
    }
  }
  return null;
};

export const isCreditResultHold = (cartData) => {
  let creditApprovalStatus = cartData?.businessInfo?.creditApprovalStatus;
  if (creditApprovalStatus === "AP" || creditApprovalStatus === "PR") {
    return false;
  }
  return true;
};

const isCreditResultMultipleCart = (creditDetailsResponse) => {
  let { bizOnlyCreditCart, blendedCart, dbaCreditCart } = creditDetailsResponse;
  let creditCount = 0;
  if (!isEmpty(bizOnlyCreditCart)) {
    creditCount++;
  }
  if (!isEmpty(blendedCart)) {
    creditCount++;
  }
  if (!isEmpty(dbaCreditCart)) {
    creditCount++;
  }
  return creditCount >= 2 ? true : false;
};

const isCreditResultDppToFrpScenario = (result) => {
  if (
    result === "dppToFRP" ||
    result === "dppToFRPWithDeposit"
  ) {
    return true;
  }
  return false;
};

const isCreditResultSuspendedScenario = (result) => {
  if (
    result === "finance" ||
    result === "partialFinance" ||
    result === "unApproved" ||
    result === "pendingReview" ||
    result === null
  ) {
    return true;
  }
  return false;
};

const replaceTokens = (str, amount, numDevices) => {
  str = str.replaceAll("{{amount}}", `$${amount}`);
  str = str.replaceAll("{{devicesNo}}", numDevices);
  str = str.replaceAll("{{devices}}", numDevices);
  return str;
};

const functions = {
  getNumDevices,
  getCreditCheckResult,
  isCreditResultHold,
  isCreditResultMultipleCart,
  isCreditResultDppToFrpScenario,
  isCreditResultSuspendedScenario,
  replaceTokens,
};

export default functions;
