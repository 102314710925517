import { call, put, select, takeLatest } from "redux-saga/effects";
import * as API from "../../../containers/APIurls";
import apiClient from "../../../utils/apiClient";
import * as BussProductSlice from "./BussProductSlice";
import * as GlobalSlice from "../../../app/ducks/GlobalSlice";
import * as selectors from "./selectors";
import Utils from "app/ducks/utils";
import BussPrd from './BussData.json'
import { setAPIError, setError, setGridwallList, setPageLoading, setPdpDeviceDetails, setProductDetail } from "pages/Mobility/ducks/MobilitySlice";

function* accessToShowMarketplace(params) {
	try {
		const requestBody = params.payload
		const response = yield apiClient.post(API.MARKETPLACE_AUTHENTICATION, requestBody);
		if (response?.data && String(response?.data?.statusMessage).toUpperCase() === "ALLOWED") {
			yield put(BussProductSlice.setMArketPlaceAccess(true))
		} else yield put(BussProductSlice.setMArketPlaceAccess(false))

	} catch (e) {
		yield put(BussProductSlice.setMArketPlaceAccess(false))
	} finally {

	}
}

function* addToCart(params) {
	const flow = params?.payload?.flow;
	let exceededCreditLimit = undefined;
	// const plansPayload = {
	//   approach: "",
	//   flow: flow === "one-talk" ? flow : "prospect",
	//   shoppingPath: "PROSPECT",
	// };
	let productDetailsObj = params.payload.productDetailsObj;
	let planFirstFlag = params.payload.planFirstFlag;
	//  let imageSet = ''
	//   delete params?.payload?.productDetailsObj
	//   delete params?.payload?.planFirstFlag
	//   if(params?.payload?.exceedingCreditLimitData){
	//     exceededCreditLimit = params?.payload?.exceedingCreditLimitData
	//     imageSet = params?.payload?.imageSets
	//     delete params?.payload?.imageSets
	//     delete params?.payload?.exceedingCreditLimitData
	//   }
	yield put(BussProductSlice.setAPIError(false));
	// yield put(AccessorySlice.setPageLoading(true));
	yield put(BussProductSlice.setPageLoading(true));
	// yield put(CheckoutSlice.setIsShoppingCartApiCallInProgress({ apiName: "getFetchCart5G", status: true }));
	try {
		const bussProducts = yield select(state => state.ProspectWidgets_BussProductsData.selectedProductBUSS)
		let requestBody = Object.assign({}, params.payload);
		// delete requestBody?.routeToFeatures;
		// const isMixedCart = yield select(state=>state?.GlobalData?.sessionCartData?.isMixedCart)
		// if(isMixedCart) requestBody = {...requestBody,"allowMixedCart":true}
		const response = yield apiClient.post(API.MARKETPLACE_SAVE_PRODUCT, requestBody);
		// yield call(getSharedCartSave, {
		//   payload: { shoppingPath: "PROSPECT", fromUCSubmitOrder: false },
		// });
		// if(planFirstFlag && response?.data?.status?.statusCode ==200){
		//   yield put (PlanFirstSlice.addDeviceToLines(productDetailsObj))
		// }
		// let sessionCartResponse = {
		//   prospectFlowData: response?.data?.response,
		//   serviceStatus: response?.data?.status
		// }
		// if (!response?.data?.status?.success) {
		//   yield put(BussProductSlice.setAddToCartResponse(response?.data));
		//   throw Object.assign(
		//     new Error('Failed to retrieve cart'),
		//     { status: response?.status, data: response?.data }
		//   );
		// } else
		if (response && response.data.success) yield put(BussProductSlice.setIsAddToCartCompleted(true));
		if (response && response.data && response.data.response) {
			let sessionCartResponse = {
				prospectFlowData: response?.data?.response,
				serviceStatus: response?.data?.status,
			};
			const newStore = Utils.setStoreFromRetrieve(sessionCartResponse);
			yield put(GlobalSlice.setSessionCartData(newStore));
			yield put(BussProductSlice.setCartResponse(response?.data?.response));
			yield put(BussProductSlice.setSelectedProduct({ ...bussProducts, [params?.payload['lines'][0]['packageId']]: params?.payload['lines'][0]['quantity'] }))
			// yield put(GlobalSlice.setLoadShoppingCartWidget(true));
		}
		if (exceededCreditLimit && response?.data?.response?.cart?.packages) {
			const key = Object.keys(response?.data?.response?.cart?.packages).find(
				(data) => String(data).includes(exceededCreditLimit)
			);
			// yield put(BussProductSlice.addedDeviceForExceededCredit({...response?.data?.response?.cart?.packages[key],imageSet:imageSet, packageId: key }));
		}
		yield put(BussProductSlice.setMarketplaceSaveProductResp(response.data));
	} catch (err) {
		if (params?.payload?.routeToFailure) {
			params?.payload?.routeToFailure();
		}
		yield put(BussProductSlice.setAPIError(true));
		yield put(BussProductSlice.setPageLoading(false));
	} finally {
		// yield put(AccessorySlice.setPageLoading(false));
		// yield put(BussProductSlice.setPageLoading(false));
		// yield put(CheckoutSlice.setIsShoppingCartApiCallInProgress({ apiName: "getFetchCart5G", status: false }));
	}
}

function* getBussProductPlans(params) {
	yield put(BussProductSlice.setAPIError(false));
	yield put(BussProductSlice.setPageLoading(true));
	try {
		const apiPayload = {
			viewOptions: [
				{
					name: "Position",
					value: "1",
				},
			],
			viewId: "DEEPLINKING",
			viewParam: params?.payload?.cartId,
		};
		//const  data  = {...BussPrd}
		const response = yield apiClient.post(API.GET_MARKETPLACE_PRODUCTS, apiPayload);
		const data = response?.data;
		if (data?.view) {
			yield put(BussProductSlice.setBussResponse(data.view));
		}
	} catch (err) {
		yield put(BussProductSlice.setAPIError(true));
	} finally {
		yield put(BussProductSlice.setPageLoading(false));
	}
}

function* marketplaceAddToCart(params) {
	yield put(BussProductSlice.setAPIError(false));
	yield put(BussProductSlice.setPageLoading(true));
	try {
		const apiPayload = {
			"cartMeta": {
				"shoppingPath": "prospect",
			}
		};
		let requestBody = Object.assign({}, params.payload);

		//const  data  = {...BussPrd}
		const response = yield apiClient.post(API.MOBILITY_ADD_TO_CART, requestBody);
		if (response && response.data && response.data.response) {
			let sessionCartResponse = {
				prospectFlowData: response?.data?.response,
				serviceStatus: response?.data?.status,
			};
			const newStore = Utils.setStoreFromRetrieve(sessionCartResponse);
			yield put(GlobalSlice.setSessionCartData(newStore));
			yield put(BussProductSlice.setCartResponse(response?.data?.response));
			// yield put(GlobalSlice.setLoadShoppingCartWidget(true));
		}
		const data = response?.data;
		yield put(BussProductSlice.setMarketplaceAddtoCartResp(data));
	} catch (err) {
		yield put(BussProductSlice.setAPIError(true));
	} finally {
		yield put(BussProductSlice.setPageLoading(false));
	}
}
function* getBussGridwall() {
	yield put(setPageLoading(true));
	try {
		const apiPayload = {
			"cartMeta": {
				"shoppingPath": "prospect",
				"clientId": "PROSPECT"
			}
		}
		const resp = yield apiClient.post(API.MARKETPLACE_GRIDWALL, apiPayload);
		if (resp?.data?.serviceStatus?.success) {
			if (
				(resp?.data && resp.data === null) ||
				resp.data === undefined ||
				(resp.data !== null &&
					resp.data &&
					(resp.data.deviceList === null ||
						resp.data.deviceList === undefined))
			)
				yield put(setError(true));
			else yield put(setError(false));
			const resultData = { ...resp.data, data: resp?.data }
			yield put(setGridwallList(resultData));
		} else
			throw Object.assign(new Error("Failed to fetch deviceList for Gridwall"), {
				status: resp?.status,
				data: resp?.data,
			});

	} catch (err) {
		console.log(err)
	} finally {
		yield put(setPageLoading(false));
	}
}
export function* getBussPDP(params) {
	yield put(setAPIError(false));
	yield put(setPageLoading(true));

	try {
		const { bussName } = params.payload;
		const resultData = bussName ? String(String(bussName).split(' ')[0]).toUpperCase() : ''
		const resp = yield apiClient.post(API.MARKETPLACE_PDP, { "productType": resultData })
		// {data:ProductDetails}
		if (
			resp.data.data === null ||
			resp.data.data === undefined ||
			(resp.data.data !== null &&
				resp.data.data &&
				(resp.data.data.deviceDetails === null ||
					resp.data.data.deviceDetails === undefined))
		)
			yield put(setError(true));
		else yield put(setError(false));
		const productDetail = { ...resp?.data, plans: resp?.data?.productPlans }
		yield put(setProductDetail({ data: productDetail }))
		yield put(setPdpDeviceDetails(resp?.data));
	} catch (err) {
		// yield put(MobilitySlice.setAPIError(true));
	} finally {
		yield put(setPageLoading(false));
	}
}

export default function* actionWatcher() {
	yield takeLatest(BussProductSlice.accessToShowMarketplace.type, accessToShowMarketplace)
	yield takeLatest(BussProductSlice.addToCart.type, addToCart);
	yield takeLatest(BussProductSlice.marketplaceAddToCart.type, marketplaceAddToCart);
	yield takeLatest(
		BussProductSlice.getBussProductPlans.type,
		getBussProductPlans
	);
	yield takeLatest(BussProductSlice.bussGridwall, getBussGridwall)
	yield takeLatest(BussProductSlice.bussPDP, getBussPDP)
}
