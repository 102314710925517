// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.digicertContainer img {
    margin-top: 30px;
    width: 44%;
}

.captchaContainer {
  margin-top: 35px;
}

.tootltip-set div.rah-static{
  overflow: unset !important;
}

@media screen and (min-width: 768px) {
  .digicertContainer img {
    width: 44%;
  }
  #otpNumberRadioGroup {
    display: grid;
    grid-template-columns: repeat(2,0.4fr);
  }
}

.otpContinuegroup {
  display: flex;
  justify-content: flex-start;
}
#otpNumberRadioGroup > div {
  margin-right: 10px;
}

.otpResendLink {
  display: flex;
  flex-direction: row;
}
.verifyBusiness .Prod-Discription {
  margin-bottom: 1rem;
}
.paddingverify{
  padding: 26px 0px;
}
.checkboxverify{
  padding-left: 0px;
 padding-right: 0.8rem;

}
.font-12{
  font-size: 12px !important;
}
.container .form-col p {
  font-size: 13px !important;
}
.padding16{
padding: 16px 0px;
}
.hide-verifyBusiness{
  opacity: 0;
}
.otp-failed > svg{
  position: relative;
  top: 5px;
  margin-right: 5px;
  }
.otp-failed{
  color: rgb(255, 128, 39);
  font-size: 1rem;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Checkout/VerifyBusiness/VerifyBusiness.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,UAAU;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,aAAa;IACb,sCAAsC;EACxC;AACF;;AAEA;EACE,aAAa;EACb,2BAA2B;AAC7B;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,iBAAiB;CAClB,qBAAqB;;AAEtB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,0BAA0B;AAC5B;AACA;AACA,iBAAiB;AACjB;AACA;EACE,UAAU;AACZ;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,iBAAiB;EACjB;AACF;EACE,wBAAwB;EACxB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".digicertContainer img {\n    margin-top: 30px;\n    width: 44%;\n}\n\n.captchaContainer {\n  margin-top: 35px;\n}\n\n.tootltip-set div.rah-static{\n  overflow: unset !important;\n}\n\n@media screen and (min-width: 768px) {\n  .digicertContainer img {\n    width: 44%;\n  }\n  #otpNumberRadioGroup {\n    display: grid;\n    grid-template-columns: repeat(2,0.4fr);\n  }\n}\n\n.otpContinuegroup {\n  display: flex;\n  justify-content: flex-start;\n}\n#otpNumberRadioGroup > div {\n  margin-right: 10px;\n}\n\n.otpResendLink {\n  display: flex;\n  flex-direction: row;\n}\n.verifyBusiness .Prod-Discription {\n  margin-bottom: 1rem;\n}\n.paddingverify{\n  padding: 26px 0px;\n}\n.checkboxverify{\n  padding-left: 0px;\n padding-right: 0.8rem;\n\n}\n.font-12{\n  font-size: 12px !important;\n}\n.container .form-col p {\n  font-size: 13px !important;\n}\n.padding16{\npadding: 16px 0px;\n}\n.hide-verifyBusiness{\n  opacity: 0;\n}\n.otp-failed > svg{\n  position: relative;\n  top: 5px;\n  margin-right: 5px;\n  }\n.otp-failed{\n  color: rgb(255, 128, 39);\n  font-size: 1rem;\n  margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
