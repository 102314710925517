import React, { useState, useMemo, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {  AccordionHeader, AccordionDetail } from "@vds3/accordions";
import { Button, TextLink } from "@vds3/buttons";
import { Icon } from "@vds3/icons";
import { ModalTitle } from "@vds3/modals";
import { Body, Title } from "@vds3/typography";
import { Notification } from "@vds3/notifications";
import {
  MarginSpacerM,
  MarginSpacerL,
  MarginSpacerS,
  MarginSpacerXL,
} from "common/components/styleTag";
import FWAContext from "../fwaContext.js";
import {
  AccordionWrapper,
  CustomAccordion,
  CustomAccordionItem,
  CustomTileContainer,
  ShowDetailsStyle,
} from "../styles.js";
import {
  PlansAccordionContainer,
  GridContainer,
  GridSpanItem,
  SpeedPlanModal,
  SpeedPlanModalBody,
  SpeedPlanModalFooter,
  PlanCardGroup,
  PromoBannerContainer,
  PromoBanner,
  ComparePlansBtnContainer,
  ComparePlansAuthContainer,
  TitleContainer,
  PlanMultipler,
  StyledTabs,
  TrailingTooltipWrapper,
} from "./styles.js";
import { ComparePlans } from "../Modals";
import PlanCard from "./PlanCard";
import PromoCard from "./PromoCard";
import PlanDisclaimers from "./PlanDisclaimers";
import NumberOfPlansSection from "../NumberOfPlansSection/NumberOfPlansSection.js";
import { useEffect } from "react";
import styled from "styled-components";
import { get4gPlans, get4gTabPlans, getPlandAndRouters, setFWABundleTabSelected, setIsEditLTEBackupPlansFlow, setIsEditLTEPrimaryPlansFlow, storeFccDetails } from "pages/Landing4G-V2/ducks/4GSlice.js";
import { get5gPlans, get5gTabPlans, setIsEdit5GPrimaryPlansFlow } from "pages/Landing5G-V2/ducks/5GSlice.js";
import ReviewOfferDetails from "./OfferDetails.js";
import JointOfferDetails from "./JointOfferDetails.js";
import { Tab, Tabs } from "@vds3/tabs";
import JointOfferDetailsNew from "./JointOfferDetailsNew.js";

const StyledBody = styled.span`
  width: 98%;
  display: inline-flex;
  @media (max-width: 560px){
    width: 100%;
  }
`;

const PlanSelection = ({
  planDetails,
  planDetailsBackup,
  heading,
  selected,
  onSelect,
  isCollapsed,
  isHavingBackupPlans,
  isPlansBackupSectionCollapsed = true,
  onNoOfPlansQtyChange,
  onTabChangeApiCall,
  setIsCollapsed,
  isEditLTEPrimaryPlansFlow = false,
  isEditLTEBackupPlansFlow = false,
  isEdit5GPrimaryPlansFlow = false,
  setIsPlansBackupSectionCollapsed,
  showPromoCard,
  learnPlanCode,
  showVisaOffer,
  globalPromos,
  setCollapseEquipment,
  isfromAuth = false,
  learnIntcmp,
  selectPlanErr,
  setSelectPlanErr,
  setIsFwaBundleSelected,
  headerHeight,
  isPlanError,
  isCheckoutNew,
  starkEnabled = false,
  displayOnlyInternetMobileTab=false,
  selectFwaBundletabForJO,
  setIsTabSelected,
  isNewFlow,
  hasPackages,
  hasRegularPackage,
  hasInternetPackage,
  ...props
}) => {
  const { flow, isMobile, primaryPlan, backupPlan, pageData, oneApiCall } = useContext(FWAContext);
  const dispatch = useDispatch();
  const { t } = useTranslation(["4G-5G"]);
  const aemKey = flow === "4g" ? "fourG" : "fiveG";
  const aemContent = t("4G-5G", { ns: "4G-5G", returnObjects: true })[aemKey][
    "plan-content"
  ];
  const backupAemContent = t("4G-5G", { ns: "4G-5G", returnObjects: true })[
    "fourG"
  ]["backup-plan-content"];
  const jointOfferAem = t("4G-5G", { ns: "4G-5G", flow: flow === "4g" ? 'LTE' : '5G', returnObjects: true });
  const disableBackupPlansFeature = t("4G-5G", {
    ns: "4G-5G",
    returnObjects: true,
  })[aemKey]["disableBackupPlansFeature"];
  const bulkOptionAemContent = t("4G-5G", { ns: "4G-5G", returnObjects: true })[
    "bulkOption"
  ];
  const RemoveCollapsePlanAccordion = t(
    "app.common.orderSummary.isRemoveCollapsePlanAccordion",
    { ns: "app" }
  );
  const  newJointOfferBundleFlow = t("4G-5G", {
    ns: "4G-5G",
    returnObjects: true,
  })["newJointOfferBundleFlow"];
  const isLearnPlanCode=((learnPlanCode!==null && learnPlanCode!==undefined) && RemoveCollapsePlanAccordion)?true:false;
  const isComparePlanCode=(learnPlanCode!==primaryPlan?.selectedPlan)?false:true;
  const planType="primaryPlan";
  const commonPlan = planType === "primaryPlan" ? primaryPlan : backupPlan;
  const showComparePlans = commonPlan?.quantity === "0";
  const lqData = useSelector((state) => state.ProspectWidgets_LQPage);
  const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
  const landing4gData = useSelector((state) => state.ProspectWidgets_4GPlansData);
  const landing5gData = useSelector((state) => state.ProspectWidgets_5GPlansData);

  const sessionCartData =
    globalData?.sessionCartData?.apiResponse?.prospectFlowData;
  const [speedPlanModal, setSpeedPlanModal] = useState(false);
  const [promoModal, setPromoModal] = useState(false);
  const [clickedPromoLinkCode, setClickedPromoLinkCode] = useState(null);
  const [reintiaterFlag, setReintiaterFlag] = useState(false);
  const cband = lqData?.cband;
  const mmw = lqData?.mmw;
  const maxSpeed = lqData?.BBALQResp?.maxSpeed || lqData?.fixedWirelessResponse?.response?.maxSpeed;
  const isHSIQualified =
    lqData?.fixedWirelessResponse?.response?.qualifiedHSI ||
    lqData.BBALQResp?.hsiqualified === "Y";
  const broadBandRedirectUrl =
    lqData?.fixedWirelessResponse?.response?.broadBandRedirectUrl ??
    lqData.BBALQResp?.broadBandRedirectUrl;
  const isDigitalQuote = window.sessionStorage.getItem("isDigitalQuote");
  const isPDPLayoutFWA = t("4G-5G.isPDPLayoutFWA", { ns: "4G-5G" });
  const promoBannerContent = isPDPLayoutFWA ? aemContent?.globalPromoNew : aemContent?.globalPromo;
  let isValidOneOffer = false;
  const MAX_LINES = aemContent?.fourG?.allowableLines || 9;
  const compareBtnLabel=
    disableBackupPlansFeature || !isHavingBackupPlans
      ? aemContent?.comparePlansModal?.buttonText
      : aemContent?.comparePrimaryPlans;
  const jointoffEnabled = jointOfferAem?.jointOfferEnabled;
  const isMixedCartApplied = sessionCartData?.cart?.mixedCartApplied?.includes("REGULAR_4G") || sessionCartData?.cart?.mixedCartApplied?.includes("REGULAR_5G") ? true : false
  // const isMixedCartEligble = sessionCartData?.cart?.mixedCartEligible?.includes("REGULAR_4G") || sessionCartData?.cart?.mixedCartEligible?.includes("REGULAR_5G") ? true : false
  const isMixedCartEligble = true;
  const plansList = planType === "primaryPlan" ? planDetails : planDetailsBackup;
  const planDetailFCC = Object.keys(landing4gData?.planDetailsFcc).length > 0 ? landing4gData?.planDetailsFcc : landing5gData?.planDetailsFcc || {};
  const fccAddressDeatil =  landing4gData?.storeFCCDetails?.address || landing5gData?.storeFCCDetails?.address || {};
  const taxDetails = landing4gData?.storeFCCDetails?.taxDetails || landing5gData?.storeFCCDetails?.taxDetails;
  const [storeApiCall,setStoreApiCall] = useState(false);
  const [fwaBundleSelected,setFwaBundleSelected] = useState(false);
  const [hasCliccked,setHasClicked]= useState(false);
  const combinedPlanRouter = pageData?.combinedResponse
  // const hasPackages = sessionCartData?.cart?.packages != null && Object.values(sessionCartData?.cart?.packages)?.length > 0 ? true : false ;
  // const hasRegularPackage = hasPackages ? Object.keys(sessionCartData?.cart?.packages).filter( packageKey => sessionCartData?.cart?.packages[packageKey]?.packageType =="REGULAR").length > 0 : false;



  useEffect(()=>{
    if(!storeApiCall && fccAddressDeatil && Object.keys(fccAddressDeatil).length > 0 && plansList.length > 0 && Object.keys(planDetailFCC).length > 0){
      const tempObj ={}
      plansList?.forEach((planID)=>{
        for(let plan in planDetailFCC){
          if(planDetailFCC[plan].length === 1 && planDetailFCC[plan][0]?._fcc_VZplanCode_ == planID?.billingCode){
              const tempVal = planDetailFCC[plan][0];
              const providerMontlyFee = tempVal._fcc_providerMthyFee_;
              const newAr = taxDetails[planID?.billingCode] || [];
              if(providerMontlyFee === "_DY_"){
                  const finalArr = [...newAr]
                  const finalVal ={...tempVal,_fcc_providerMthyFee_ :finalArr };
                  tempObj[planID?.billingCode] = finalVal;
              }else if(Array.isArray(providerMontlyFee) && providerMontlyFee?.length > 0){
                if(Array.isArray(newAr) && newAr?.length > 0){
                  const finalArr = [...providerMontlyFee,...newAr]
                  const finalVal ={...tempVal,_fcc_providerMthyFee_ :finalArr };
                  tempObj[planID?.billingCode] = finalVal;

                }else{
                  const finalArr = [...providerMontlyFee]
                  const finalVal ={...tempVal,_fcc_providerMthyFee_ :finalArr };
                  tempObj[planID?.billingCode] = finalVal;
                }
              }
          }
       }
      })
      const payload = {
        address : fccAddressDeatil,
        serviceName: "FWA",
        fccPlanDetails:tempObj
     }
     dispatch(storeFccDetails(payload));
     setStoreApiCall(true)
    }
  },[fccAddressDeatil,plansList,planDetailFCC])

  const bbShowDeatils = () => {
    const url = t("app.broadBandRedirectUrl", {ns: "app",})
    window.open(url,'_blank','toolbar=0,location=0,menubar=0');

  };
   
  const getCommonPlan = (planType) =>
    planType === "primaryPlan" ? primaryPlan : backupPlan;

  const planRadioBoxes = useCallback(
    (planType, plansCardDisabled) => {
      let list = [],
        isValidOfferCount = 0, maxOffersPerPlan = 0;
      let count = 0;
      const now = new Date();
      const plansList =
        planType === "primaryPlan" ? planDetails : planDetailsBackup;
      const selectedPlan = getCommonPlan(planType)?.selectedPlan;
      plansList.forEach((eachInfo, index) => {
        const planOfferCount = aemContent?.offers[eachInfo.billingCode]?.filter(o => new Date().getTime() >= new Date(o?.startDateTime).getTime() &&
          new Date().getTime() <= new Date(o?.endDateTime).getTime()).length;
        maxOffersPerPlan = planOfferCount > maxOffersPerPlan ? planOfferCount : maxOffersPerPlan;
      });
      plansList.forEach((eachInfo, index) => {
        if (eachInfo.cband !== props.isCheckCband) {
          const planDetails =
            aemContent?.planDetails &&
            aemContent?.planDetails?.plans[eachInfo.billingCode];
          const offer =
            aemContent?.offers &&
            aemContent?.offers[eachInfo.billingCode] &&
            aemContent?.offers[eachInfo.billingCode][0];
          const offerStartDate = offer && new Date(offer.startDateTime);
          const offerEndDate = offer && new Date(offer.endDateTime);
          const isOfferValid =
            offer &&
            now.getTime() >= offerStartDate.getTime() &&
            now.getTime() <= offerEndDate.getTime();
          isOfferValid && isValidOfferCount++;
          const showVisaPromo =
            showVisaOffer && !mmw &&
            (eachInfo?.billingCode === "48390" ||
              eachInfo?.billingCode === "51219" ||
              eachInfo?.billingCode === "27976");
                    let eachObject = (
            <PlanCard
              showVisa={sessionCartData?.prospect5gLoopQualAddress?.showVisaOffer}
              setReviewOfferList={setReviewOfferList}
              mmw={mmw}
              reviewOffers={aemContent.reviewOffers}
              reviewOfferswithoutVisaOffers={aemContent.reviewOfferswithoutVisaOffers}
              reviewOfferswithoutVisa={aemContent.reviewOfferswithoutVisa}
              id={eachInfo.billingCode}
              setReviewOfferdetails={setReviewOfferdetails}
              key={eachInfo.billingCode}
              flow={flow}
              isMultiline={flow === "4g"}
              isfromAuth={isfromAuth}
              selected={selectedPlan === eachInfo.billingCode}
              displayName={planDetails?.displayName}
              price={eachInfo.monthlyFee}
              discountPresent={eachInfo?.discountPresent}
              previousMonthlyFee={eachInfo?.previousMonthlyFee}
              aemContent={planDetails}
              priceGuarantee={planDetails?.priceGuarantee}
              routerIncluded={planDetails?.routerIncluded}
              description={planDetails?.description}
              installationDesc={planDetails?.installationDesc}
              receiverIncluded={planDetails?.includesReceiver}
              includedDetails={planDetails?.includedDetails}
              bundleOffer={planDetails?.bundleOffer}
              limitedOffer={planDetails?.limitedOffer}
              starkEnabled={starkEnabled}
              hasClicked = {hasCliccked}
              setHasClicked={setHasClicked}
              // TEMPORARY:
              promos={
                showVisaPromo
                //removing global promos dependency
                // ? globalPromos && globalPromos[eachInfo.billingCode]
                // : aemContent?.offers && aemContent?.offers[eachInfo.billingCode]
              ? aemContent?.offersWithVisa && aemContent?.offersWithVisa[eachInfo.billingCode]
            :aemContent?.offers && aemContent?.offers[eachInfo.billingCode]
              }
              maxOffersPerPlan={maxOffersPerPlan}
              promoTooltip={isOfferValid && offer?.subHeading}
              isMobile={isMobile}
              selectedPlan={selectedPlan}
              isDisabled={plansCardDisabled || false}
              onChange={(e) => {
                setReintiaterFlag(!reintiaterFlag);
                if (e !== selectedPlan) {
                  onSelect(e, planType);
                  setCollapseEquipment(false);
                }
                if (isfromAuth) {
                  setIsCollapsed(true);
                }
                if (flow === "4g") {
                  //setIsPlansBackupSectionCollapsed(false);
                  dispatch(setIsEditLTEPrimaryPlansFlow(false));
                  dispatch(setIsEditLTEBackupPlansFlow(false));
                } else if (flow === "5g") {
                  dispatch(setIsEdit5GPrimaryPlansFlow(false));
                }
              }}
              showVisaPromo={showVisaPromo}
              planType={planType}
              fwaBundleSelected={fwaBundleSelected}
            />
          );
          count++;
          list.push(eachObject);
        }
      });
      if (isValidOfferCount > 0) {
        isValidOneOffer = true;
      }
      // promo card
      if (showPromoCard) {
        list.push(
          <PromoCard
            title={aemContent?.exploreDetailsTile?.heading}
            subTitle={aemContent?.exploreDetailsTile?.subHeading}
            linkText={aemContent?.exploreDetailsTile?.exploreMoreCta}
            linkUrl={aemContent?.exploreDetailsTile?.flyerPDFURL}
         />
        );
      }
      return list;
    },
    [
      flow,
      aemContent,
      isMobile,
      planDetails,
      onSelect,
      showPromoCard,
      planDetailsBackup,
      primaryPlan,
      backupPlan,
      pageData?.planDetails
    ]
  );

  const showSpeedPlanModal = () => {
    let aem = aemContent,
      speedDetailModalHTML = "",
      modalTitle = "";
    if (props.flow === "5g") {
      modalTitle = aem["modalHeader"];
      if (cband === "false" && mmw === "true") {
        speedDetailModalHTML = aem["speedDetailModalHTMLCbandMMWHybrid"];
      } else if (cband === "true" && mmw === "false") {
        speedDetailModalHTML = aem["speedDetailModalHTMLCbandOnly"];
      } else {
        speedDetailModalHTML = aem["speedDetailModalHTMLMMW"];
      }
    } else {
      speedDetailModalHTML =
        props?.tabIndex === 0
          ? aem?.tabs?.standard?.speedDetailModalHTML
          : aem?.tabs?.premium?.speedDetailModalHTML;
    }

    const dom = document.createElement("html");
    dom.innerHTML = speedDetailModalHTML;
    const domBody = dom.getElementsByTagName("BODY");
    if (domBody?.length && domBody[0]?.children?.length) {
      for (const el of domBody[0].children) {
        if (el.tagName === "H2") {
          modalTitle = "Plan, price, and speed details";
        } else {
          speedDetailModalHTML = el.innerHTML;
        }
      }
    }

    const handleClick = () => {
      setSpeedPlanModal(false);
      //window.scrollTo(0, 0);
    };

    return (
      <SpeedPlanModal
        ariaLabel=""
        opened={speedPlanModal}
        onOpenedChange={setSpeedPlanModal}
        disableFocusLock= {true}
      >
        <ModalTitle>
          <Title size="medium">
            <div dangerouslySetInnerHTML={{ __html: modalTitle }}></div>
          </Title>
          <MarginSpacerM></MarginSpacerM>
        </ModalTitle>
        <SpeedPlanModalBody>
          <div dangerouslySetInnerHTML={{ __html: speedDetailModalHTML }}></div>
          <MarginSpacerM></MarginSpacerM>
        </SpeedPlanModalBody>
        {!!isMobile && (
          <SpeedPlanModalFooter
            buttonData={{
              primary: {
                width: "100%",
                children: "Close",
                onClick: () => setSpeedPlanModal(false),
              },
            }}
          />
        )}
        {!isMobile && (
          <SpeedPlanModalFooter
            children={
              <div>
                <div>
                  <Button onClick={handleClick}>Close</Button>
                </div>
              </div>
            }
          />
        )}
      </SpeedPlanModal>
    );
  };

  const showPromoModal = () => {
    let promoContent = "";
    planDetails.forEach((plan) => {
      if (plan?.billingCode === clickedPromoLinkCode) {
        promoContent = plan?.globalPromo?.tooltipText;
      }
    });
    return (
      <SpeedPlanModal
        ariaLabel=""
        opened={promoModal}
        onOpenedChange={setPromoModal}
      >
        <SpeedPlanModalBody>
          <div dangerouslySetInnerHTML={{ __html: promoContent }} />
        </SpeedPlanModalBody>
      </SpeedPlanModal>
    );
  };

  const redirectToHsi = () => {
    window.location.href = broadBandRedirectUrl;
  };

  const selectionDisplayText = useCallback(
    (planType) => {
      const plansList =
        planType === "primaryPlan" ? planDetails : planDetailsBackup;
      const selectedPlan = getCommonPlan(planType)?.selectedPlan;
      if (selectedPlan && plansList?.length) {
        const plan = plansList.find((e) => e.billingCode === selectedPlan);
        return plan?.displayName;
      }
    },
    [planDetails, planDetailsBackup, primaryPlan, backupPlan]
  );

  const disabledAccordion =
    !!primaryPlan?.selectedPlan || !!backupPlan?.selectedPlan;
  const hidePrimaryPlan = primaryPlan?.quantity !== "0";
  const hideBackupPlan = backupPlan?.quantity !== "0";
  const maxQuantityCheck = useCallback(
    (planType) => {
      const commonPlan = getCommonPlan(planType);
      return Number(commonPlan?.quantity) === 9;
    },
    [primaryPlan, backupPlan]
  );
  const primaryPlanData = useMemo(() => {
    if (sessionCartData?.cart?.groupPackages && Object.keys(sessionCartData?.cart?.groupPackages).length) {
      return Object.values(sessionCartData?.cart?.groupPackages)?.find(
        (el) => !el?.backUpPlanPresent
      );
    } else {
      return [];
    }
  }, [sessionCartData?.cart?.groupPackages]);
  const backupPlanData = useMemo(() => {
    if (sessionCartData?.cart?.groupPackages && Object.keys(sessionCartData?.cart?.groupPackages).length) {
      return Object.values(sessionCartData?.cart?.groupPackages)?.find(
        (el) => el?.backUpPlanPresent
      );
    } else {
      return [];
    }
  }, [sessionCartData?.cart?.groupPackages]);

  const getMaxLines = useCallback(
    (planType) => {

      let quantity = 0;

      if (planType == "primaryPlan") quantity = primaryPlanData?.quantity ?? 0;
      else quantity = backupPlanData?.quantity ?? 0;

      return Number(quantity)
        ? 9 - Number(quantity)
        : 9;
    },
    [primaryPlan, backupPlan]
  );

  useEffect(() => {
    if (landing4gData?.fwaBundleTabSelected) {
      setTimeout(() => {
        onTabChangeFunc({ event: {}, index: 1 });
      }, 1000);
    }
  }, [])

  useEffect(() => {
    if(primaryPlan?.selectedPlan || backupPlan?.selectedPlan){
      setSelectPlanErr(false);
    }
    if (!isfromAuth) {
      (!isEditLTEPrimaryPlansFlow || !isEditLTEBackupPlansFlow || !isEdit5GPrimaryPlansFlow) && setIsCollapsed(
        false
        // (primaryPlan?.selectedPlan &&
        //   !backupPlan?.selectedPlan) ||
        // (!primaryPlan?.selectedPlan &&
        //   backupPlan?.selectedPlan) ||
        // (primaryPlan?.selectedPlan &&
        //   backupPlan?.selectedPlan)
      );
    }
  }, [
    primaryPlan.selectedPlan,
    backupPlan?.selectedPlan,
    reintiaterFlag,
    isEditLTEPrimaryPlansFlow,
    isEditLTEBackupPlansFlow,
    isEdit5GPrimaryPlansFlow,
    isfromAuth
  ]);
  useEffect(() => {
    if(flow === "4g" && selectPlanErr && isPlanError > 0){
      let noOfPlanContainer = document.getElementById('noOfPlanContainer');
      let tabContainer = document.getElementById('tabContainer');
      let elementPosition = noOfPlanContainer?.offsetHeight + tabContainer?.offsetHeight;
      window.scrollTo({
        top: headerHeight + elementPosition,
      })
    }   
  }, [selectPlanErr,isPlanError]);
  
const [reviewOfferDetails, setReviewOfferdetails] = useState(false)
const [reviewOfferList, setReviewOfferList] = useState([])
const [jointOfferModal, setJointOfferModal] = useState(false)
const [jointOfferList, setJointOfferList] = useState([])

const handleJointOfferDetails = (tabGroup="")=>{
  
  const aem = isPDPLayoutFWA ? jointOfferAem?.jointOffersNew : jointOfferAem?.jointOffers;
  if(isMixedCartApplied){
    setJointOfferList(aem["withBup"][0]);
  } else if(tabGroup === "internetAndMobile") {
    const JOBundleContent = newJointOfferBundleFlow?.isNewJOFlowEnabled && (flow === "4g" || flow ==="5g" && !mmw ) ? (!selectFwaBundletabForJO ? jointOfferAem["newJointOfferBundleFlow"]["noBupInternetOnly"][0] : jointOfferAem["newJointOfferBundleFlow"]["ContinueInternetOnly"][0]) : aem["noBupInternetOnly"][0];
    setJointOfferList(JOBundleContent);
  }else{
    setJointOfferList(aem["noBupInternetAndMobile"][0]);
  }
  setJointOfferModal(true);
}
const onTabChangeFunc = (event, index) => {
    if(index === 0){
      //NFWAFIOS-635 - Selection Retain
       flow === "4g" ? oneApiCall ?  dispatch(getPlandAndRouters({...combinedPlanRouter, internetAndMobile: false})) : dispatch(get4gTabPlans({...lqData, internetAndMobile: false})) : dispatch(get5gTabPlans({...lqData, internetAndMobile: false}));
      setFwaBundleSelected(false);
      setIsFwaBundleSelected(false);   
      dispatch(setFWABundleTabSelected(false));
      if(flow==='5g'){
        setIsTabSelected(true);
      }
     
    }else{
      //NFWAFIOS-635 - Selection Retain
       flow === "4g" ? oneApiCall ?  dispatch(getPlandAndRouters({...combinedPlanRouter,internetAndMobile:true})) : dispatch(get4gTabPlans({...lqData, internetAndMobile: true})) : dispatch(get5gTabPlans({...lqData,internetAndMobile:true})) ;
      setFwaBundleSelected(true)
      setIsFwaBundleSelected(true);
      dispatch(setFWABundleTabSelected(true));
      if(flow==='5g'){
        setIsTabSelected(true);
      }
    }
};

useEffect(()=>{
  if(displayOnlyInternetMobileTab){
    setFwaBundleSelected(true)
    dispatch(setFWABundleTabSelected(true));
    setIsFwaBundleSelected(true);
  }
},[displayOnlyInternetMobileTab])

useEffect(()=>{
  if(primaryPlan?.selectedPlan && selectFwaBundletabForJO){
    if(flow === "4g") { dispatch(get4gTabPlans({...lqData,internetAndMobile:true})) }
    else {
    dispatch(get5gTabPlans({...lqData,internetAndMobile:true})) ;
    setIsTabSelected(true);
    }
    setFwaBundleSelected(true)
    setIsFwaBundleSelected(true);
  }
},[fwaBundleSelected, selectFwaBundletabForJO])

  return (
    <PlansAccordionContainer>
      <AccordionWrapper disabledAccordion={false} isfromAuth={isfromAuth} isCheckoutNew={isCheckoutNew}>
        <CustomAccordion
          surface="light"
          className="FWA-plans-selection"
          id="FWA-plans-selection"
        >
          <CustomAccordionItem opened={!isCollapsed} alwaysOpen={disabledAccordion ? false : true}>
            <AccordionHeader
              // triggerType={(disabledAccordion) && !isfromAuth ? "link" : ""}
              triggerType={(disabledAccordion && mmw) || (disabledAccordion && flow=="4g" && isCollapsed) ? "link" : ""}              
              closeAccordionTriggerLabel="Collapse"
              onClick={() => {
                setIsCollapsed(!isCollapsed);
              }}
              children={
                (isCollapsed) ? (
                  <>
                    <Title size="large" bold>
                    {(mmw || displayOnlyInternetMobileTab) && (heading && heading != "" ? heading : aemContent?.selectPlan)}
                    </Title>
                    {flow === "4g" ? <Title size="large" bold>
                    {aemContent?.selectPlan}
                    </Title> : <></>}
                    <MarginSpacerS />
                    {primaryPlan?.selectedPlan && (
                      <Title size="small" color="#6F7171">                        
                        <span style={{ fontWeight: "bold" }}>
                          Plan:{" "}
                        </span>
                        {selectionDisplayText("primaryPlan")}
                      </Title>
                    )}
                    <MarginSpacerS />
                    {backupPlan?.selectedPlan && (
                      <Title size="small" color="#6F7171">
                        {isHavingBackupPlans && (
                          <span style={{ fontWeight: "bold" }}>
                            Backup plan:{" "}
                          </span>
                        )}
                        {selectionDisplayText("backupPlan")}
                      </Title>
                    )}
                  </>
                ) : isMobile ? (
                  <GridContainer isMobile={isMobile}>
                    <Title size="large" bold>
                      {(mmw || displayOnlyInternetMobileTab) && (heading && heading != "" ? heading : aemContent?.selectPlan)}
                    </Title>
                    {/* {flow === "5g" && <ComparePlans marginTop={15} />} */}
                  </GridContainer>
                ) : (
                  <TitleContainer isMobile={isMobile || (!isMobile && isfromAuth)}
                    planLength={planDetails && planDetails?.length}  >
                    <Title size="large" bold primitive="h3">
                      {(mmw || displayOnlyInternetMobileTab) && (heading && heading != "" ? heading : aemContent?.selectPlan)}
                    </Title>
                  
                    {/* {flow === "5g" && (
                      isfromAuth ? (
                        // <ComparePlansAuthContainer>
                        <ComparePlans isfromAuth={isfromAuth} />
                        // </ComparePlansAuthContainer>
                      ) : 
                      (
                        <ComparePlansBtnContainer
                          span={!!primaryPlan?.selectedPlan ?
                            2 :
                            (planRadioBoxes("primaryPlan")?.length + 1)}
                        >
                          <ComparePlans planDetails={planDetails} />
                        </ComparePlansBtnContainer>
                      )
                      )} */}
                  </TitleContainer>
                )
              }
            />
            <AccordionDetail
              children={
                <>
                   {globalData.bbloadError && (
                     <div id="errorContainer">
                        <CustomNotification
                          type="warning"
                          title = {t("app.broadBandLoadError.heading", { ns: "app" })}
                          subtitle={
                            <div
                              style={{
                              marginBottom:"2px",
                              marginTop:"7px"
                              }}
                            >
                              <TextLink 
                              type="standAlone" 
                              onClick={bbShowDeatils}>
                              {t("app.broadBandLoadError.linkHeading", { ns: "app" })}
                            </TextLink>
                            </div>
                            
                          }
                          fullBleed={true}
                          inlineTreatment={false}
                          hideCloseButton={true}
                          />
                     <MarginSpacerL />
                    </div>
                   
                  )}
                  {/* {sessionCartData?.selectFwaBundletabForJO && (pageData?.showInternetMobilePlanFiveG || pageData?.showInternetMobilePlan)&& (
                    <div style = {{fontSize: '1rem', color: '#6F7171'}}>                                                                         
                        {t("app.GWToLTEFiveGPromoBanner.heading", { ns: "app" })}
                        <span style={{ marginLeft: '5px' }}>
                          <TextLink
                            type="inline"
                            size="small"
                            onClick={e => handleJointOfferDetails('internetAndMobile')}>
                            Learn More
                          </TextLink>  
                        </span>                          
                    </div>
                  )} */}
                  {
                    !mmw &&  !displayOnlyInternetMobileTab &&
                    <div id="tabContainer">
                    <StyledTabs 
                    orientation="horizontal"
                    indicatorPosition="bottom"
                    size="large"
                    width="auto"
                    onTabChange={onTabChangeFunc}
                    selectedIndex = {selectFwaBundletabForJO ? 0 : landing4gData?.fwaBundleTabSelected ? 1 : 0}
                    >
                      
                      <Tab label="Internet" hide={selectFwaBundletabForJO}>
                        <AccordionWrapper
                              disabledAccordion={false}
                            >
                              <CustomAccordion surface="light"
                              className="FWA-4g-plans-selection"
                              id="FWA-4g-plans-selection" >
                                <CustomAccordionItem opened={!isCollapsed} alwaysOpen={(disabledAccordion) ? false : true}>                                
                                  <AccordionHeader
                                    triggerType={disabledAccordion
                                      ? "link"
                                      : ""}
                                    closeAccordionTriggerLabel="Collapse"
                                    onClick={() => {
                                      setIsCollapsed(!isCollapsed);
                                    }}
                                    children={
                                      (isCollapsed) ? (
                                        <>
                                          {primaryPlan?.selectedPlan && (
                                            <Title size="small" color="#6F7171">
                                              <span style={{ fontWeight: "bold" }}>
                                                Plan:{" "}
                                              </span>
                                              {selectionDisplayText("primaryPlan")}
                                            </Title>
                                          )}
                                          <MarginSpacerS />
                                          {backupPlan?.selectedPlan && (
                                            <Title size="small" color="#6F7171">
                                              {isHavingBackupPlans && (
                                                <span style={{ fontWeight: "bold" }}>
                                                  Backup plan:{" "}
                                                </span>
                                              )}
                                              {selectionDisplayText("backupPlan")}
                                            </Title>
                                          )}
                                        </>
                                      ) : isMobile ? (
                                        <GridContainer isMobile={isMobile}>
                                          <Title size="large" bold>
                                            {aemContent?.selectPlan}
                                          </Title>
                                          {/* {flow === "5g" && <ComparePlans marginTop={15} />} */}
                                        </GridContainer>
                                      ) : (
                                        <TitleContainer isMobile={isMobile || (!isMobile && isfromAuth)}
                                          planLength={planDetails && planDetails?.length}  >
                                          <Title size="large" bold primitive="h3">
                                            {aemContent?.selectPlan}
                                          </Title>

                                          {/* {flow === "5g" && (
                      isfromAuth ? (
                        // <ComparePlansAuthContainer>
                        <ComparePlans isfromAuth={isfromAuth} />
                        // </ComparePlansAuthContainer>
                      ) : 
                      (
                        <ComparePlansBtnContainer
                          span={!!primaryPlan?.selectedPlan ?
                            2 :
                            (planRadioBoxes("primaryPlan")?.length + 1)}
                        >
                          <ComparePlans planDetails={planDetails} />
                        </ComparePlansBtnContainer>
                      )
                      )} */}
                                        </TitleContainer>
                                      )
                                    }
                                  />
                                  {
                                    flow === "4g" ? <span className="promoBannerdiv">
                                      <TrailingTooltipWrapper
                                        typographySize="large"
                                        typographyType="body"
                                        typographyColor="#6F7171"
                                        tooltipSize="small"
                                        tooltipTitle={""}
                                        typographyPrimitive="p"
                                        tooltipContent={

                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: aemContent?.selectNoLinesTooltip,
                                            }}
                                          />
                                        }
                                        bold={false}
                                        inverted={false}
                                      >
                                        {(mmw && !isfromAuth && ((maxSpeed && Number(maxSpeed) > 300) || !maxSpeed))
                                          ? promoBannerContent?.textMMW
                                          : jointoffEnabled ? promoBannerContent?.textJointOffMobileOnly : promoBannerContent?.text}
                                      </TrailingTooltipWrapper>
                                    </span> :<div></div> 
                                  }
                                  {!cband ? <MarginSpacerL /> : isMobile ? <MarginSpacerL /> :  <div></div>}
                                </CustomAccordionItem>
                              </CustomAccordion>
                            </AccordionWrapper>
                      </Tab>
                      <Tab label="Internet & Mobile" hide={true}>
                        <MarginSpacerXL />
                        {!!promoBannerContent && hidePrimaryPlan && isPDPLayoutFWA && !landing4gData?.showCheckAvailabiltyLink && (
                            <>
                              <PromoBannerContainer
                                className="promoBannerdiv"
                                isMobile={isMobile || (!isMobile && isfromAuth)}
                                planLength={planDetails && planDetails?.length}
                              >
                                {!newJointOfferBundleFlow?.isNewJOFlowEnabled || newJointOfferBundleFlow?.isNewJOFlowEnabled && mmw || (hasInternetPackage && hasRegularPackage) && (<div className="promoBannerStrip">
                                  <PromoBanner
                                    span
                                    className={planRadioBoxes("primaryPlan")}
                                  >
                                    <Icon
                                      name={promoBannerContent?.icon ?? "tag"}
                                      color="#000000"
                                    />
                                    <Body size="small" bold>
                                      {(mmw && !isfromAuth && ((maxSpeed && Number(maxSpeed) > 300) || !maxSpeed))
                                        ? promoBannerContent?.textMMW
                                        : jointoffEnabled ? promoBannerContent?.textJointOffInternetMobile : promoBannerContent?.text}
                                    </Body>
                                    <MarginSpacerS></MarginSpacerS>
                                    {jointoffEnabled && !starkEnabled && !isMobile &&
                                  <CustomTextLink 
                                    type="standAlone" 
                                    size="small"
                                    onClick={e => handleJointOfferDetails('internetAndMobile')}>
                                    Show details
                                  </CustomTextLink>}
                                  </PromoBanner>
                                    </div>)}                                 
                                    </PromoBannerContainer>
                                {newJointOfferBundleFlow?.isNewJOFlowEnabled && !sessionCartData?.selectFwaBundletabForJO && (flow === "4g" || (flow === "5g" && !mmw)) && !(hasInternetPackage && hasRegularPackage) && (
                                  <div style={{ fontSize: '1rem', color: '#6F7171' }}>
                                    {newJointOfferBundleFlow?.promoBannerCopy}
                                    <TextLink
                                      type="inline"
                                      size="small"
                                      onClick={e => handleJointOfferDetails('internetAndMobile')}>
                                      Learn More
                                    </TextLink>
                                  </div>
                                )}   
                                {isMobile && !(newJointOfferBundleFlow?.isNewJOFlowEnabled && !sessionCartData?.selectFwaBundletabForJO && (flow === "4g" || (flow === "5g" && !mmw)) && !(hasInternetPackage && hasRegularPackage)) &&
                                  <ShowDetailsStyle
                                    span
                                    className={planRadioBoxes("primaryPlan")}
                                  >
                                    <MarginSpacerS></MarginSpacerS>
                                    {jointoffEnabled && !starkEnabled &&
                                      <CustomTextLink
                                        type="standAlone"
                                        size="small"
                                        onClick={e => handleJointOfferDetails('internetAndMobile')}>
                                        Show details
                                      </CustomTextLink>}
                                  </ShowDetailsStyle>
                                 
                                }
                            </>
                          )}
                            <AccordionWrapper
                              disabledAccordion={false}
                            >
                              <CustomAccordion surface="light"
                              className="FWA-4g-plans-selection"
                              id="FWA-4g-plans-selection" >
                                <CustomAccordionItem opened={!isCollapsed} alwaysOpen={(disabledAccordion) ? false : true}>                                
                                  <AccordionHeader
                                    triggerType={disabledAccordion && isMobile
                                      ? "link"
                                      : ""}
                                    closeAccordionTriggerLabel= {isMobile ? "Collapse" : ""}
                                    onClick={() => {
                                      setIsCollapsed(!isCollapsed);
                                    }}
                                    children={
                                      (isCollapsed) ? (
                                        <>
                                          {primaryPlan?.selectedPlan && (
                                            <Title size="small" color="#6F7171">
                                              <span style={{ fontWeight: "bold" }}>
                                                Plan:{" "}
                                              </span>
                                              {selectionDisplayText("primaryPlan")}
                                            </Title>
                                          )}
                                          <MarginSpacerS />
                                          {backupPlan?.selectedPlan && (
                                            <Title size="small" color="#6F7171">
                                              {isHavingBackupPlans && (
                                                <span style={{ fontWeight: "bold" }}>
                                                  Backup plan:{" "}
                                                </span>
                                              )}
                                              {selectionDisplayText("backupPlan")}
                                            </Title>
                                          )}
                                        </>
                                      ) : isMobile ? (
                                        <GridContainer isMobile={isMobile}>
                                          <Title size="large" bold>
                                            {aemContent?.selectPlan}
                                          </Title>
                                          {/* {flow === "5g" && <ComparePlans marginTop={15} />} */}
                                        </GridContainer>
                                      ) : (
                                        <TitleContainer isMobile={isMobile || (!isMobile && isfromAuth)}
                                          planLength={planDetails && planDetails?.length}  >
                                          <Title size="large" bold primitive="h3">
                                            {aemContent?.selectPlan}
                                          </Title>
                                        </TitleContainer>
                                      )
                                    }
                                  />
                              {flow === "4g" ? <span className="promoBannerdiv">
                              <TrailingTooltipWrapper
                                typographySize="large"
                                typographyType="body"
                                typographyColor="#6F7171"
                                tooltipSize="small"
                                tooltipTitle={""}
                                typographyPrimitive="p"
                                tooltipContent={

                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: aemContent?.selectNoLinesTooltip,
                                    }}
                                  />
                                }
                                bold={false}
                                inverted={false}
                              >
                                {(mmw && !isfromAuth && ((maxSpeed && Number(maxSpeed) > 300) || !maxSpeed))
                                  ? promoBannerContent?.textMMW
                                  : jointoffEnabled ? promoBannerContent?.textJointOffMobileOnly : promoBannerContent?.text}
                              </TrailingTooltipWrapper>
                            </span>:<div></div> } 
                            <MarginSpacerL />                        
                                </CustomAccordionItem>
                              </CustomAccordion>
                            </AccordionWrapper>
                      </Tab>
                    </StyledTabs>
                    </div>
                  }
                  

                
                  {promoBannerContent?.textMMW !=="" && !!promoBannerContent && hidePrimaryPlan && (!isPDPLayoutFWA||mmw)  && (
                    
                    <>
                      <PromoBannerContainer
                        className="promoBannerdiv"
                        isMobile={isMobile || (!isMobile && isfromAuth)}
                        planLength={planDetails && planDetails?.length}
                      >
                        <div className="promoBannerStrip">
                          <PromoBanner
                            span
                            className={planRadioBoxes("primaryPlan")}
                          >
                            <Icon
                              name={promoBannerContent?.icon ?? "tag"}
                              color="#000000"
                            />
                            <Body size="small" bold>
                              {(mmw && !isfromAuth && ((maxSpeed && Number(maxSpeed) > 300) || !maxSpeed))
                                ? promoBannerContent?.textMMW
                                : jointoffEnabled ? (promoBannerContent?.textJointOff != null ? promoBannerContent?.textJointOff :
                                  promoBannerContent?.textJointOffInternetMobile) : promoBannerContent?.text}
                            </Body>
                            <MarginSpacerS></MarginSpacerS>
                            {jointoffEnabled && !starkEnabled &&
                          <CustomLink 
                            type="standAlone" 
                            size="small"
                            onClick={e => handleJointOfferDetails()}>
                            See how
                          </CustomLink>}
                          </PromoBanner>
                        </div>
                      </PromoBannerContainer>

                      <MarginSpacerL />
                    </>
                  )}
                  {selectPlanErr ? 
                  <>
                     <Notification
                       type="error"
                       inline={true}
                       fullBleed={true}
                       hideCloseButton={true}
                       title={t("4G-5G.common.page-stepper.plan-err-msg", {
                        ns: "4G-5G",
                       })}
                      />
                      <MarginSpacerM></MarginSpacerM>
                    </>
                   : <></>
                   }
                  {planDetails && hidePrimaryPlan ? (
                    <PlanCardGroup
                      isMobile={isMobile}
                      isValidOneOffer={isValidOneOffer}
                      span={(!isMobile && isfromAuth) && 3}
                    >
                      {planRadioBoxes(
                        "primaryPlan",
                        maxQuantityCheck("backupPlan")
                      )}
                    </PlanCardGroup>
                  ) : (
                    <></>
                  )}
            { !showComparePlans&& (
                <>
            <MarginSpacerL/>
            <ComparePlansBtnContainer
              span={Math.min(planRadioBoxes?.length)}
              isHavingBackupPlans={isHavingBackupPlans}
              planType={planType}
              isFlexEnd={(planType === "primaryPlan")}
            >
              <ComparePlans
               compareBtnLabel={compareBtnLabel}
               planDetails={planDetails}
                planDetailsBackup={planDetailsBackup}
                planType={planType}
                starkEnabled={starkEnabled}
                isfromAuth={isfromAuth}      
                
              />
            </ComparePlansBtnContainer>
            </>
              )
              }
                  {hidePrimaryPlan ? (
                    <GridContainer
                      isMobile={isMobile}
                      style={{ rowGap: "1rem" }}
                    >
                      <GridSpanItem
                        span={planRadioBoxes("primaryPlan")?.length}
                      >
                        <PlanDisclaimers
                          isHSIQualified={isHSIQualified}
                          broadBandRedirectUrl={broadBandRedirectUrl}
                          isCband={cband}
                          isMMW={mmw}
                          planType="primaryPlan"
                          selectedPlan={primaryPlan?.selectedPlan}
                          showVisaOffer={showVisaOffer}
                          isfromAuth={isfromAuth}
                          maxSpeed={maxSpeed}
                          starkEnabled={starkEnabled}
                          isMixedCartEligble={isMixedCartEligble}
                          fwaBundleSelected={fwaBundleSelected}
                        />
                      </GridSpanItem>
                    </GridContainer>
                  ) : (
                    <></>
                  )}
                  {/* {flow === "4g" && isHavingBackupPlans && (
                    <>
                      <MarginSpacerXL />
                      <GridContainer
                        isMobile={isMobile}
                        style={{ rowGap: "1rem" }}
                      >
                        <GridSpanItem
                          span={Math.min(
                            planRadioBoxes("backupPlan")?.length + 1,
                            3
                          )}
                        >
                          <Line type="secondary" />
                        </GridSpanItem>
                      </GridContainer>
                      <GridContainer
                        isMobile={isMobile}
                        style={{ rowGap: "1rem" }}
                      >
                        <GridSpanItem
                          span={planRadioBoxes("backupPlan")?.length}
                        >
                          <Accordion bottomLine={false} surface="light">
                            <CustomAccordionItem
                              opened={!isPlansBackupSectionCollapsed ||
                                learnIntcmp}
                            >
                              <AccordionHeader
                                triggerType="icon"
                                onClick={() => {
                                  setIsPlansBackupSectionCollapsed(
                                    !isPlansBackupSectionCollapsed
                                  );

                                  backupPlan.quantity === 0 &&
                                    onNoOfPlansQtyChange(1, "backupPlan");
                                }}
                                children={
                                  <Title size="medium">
                                    {backupAemContent?.heading}
                                  </Title>
                                }
                              />
                              <AccordionDetail
                                children={
                                  <CustomTileContainer
                                    padding="1rem"
                                    aspectRatio="none"
                                    backgroundColor="gray"

                                  >
                                    {hideBackupPlan && (
                                      <Body size="large">
                                        <StyledBody>{backupAemContent?.subHeading}{" "}</StyledBody>
                                      </Body>
                                    )}
                                    <MarginSpacerL />
                                    <NumberOfPlansSection
                                      compareBtnLabel={
                                        backupAemContent?.compareBackupPlans
                                      }
                                      disableBackupPlansFeature={disableBackupPlansFeature}
                                      disabledSelection={maxQuantityCheck(
                                        "primaryPlan"
                                      )}
                                      dropdownLabel={
                                        backupAemContent?.noOfBackupPlans
                                      }
                                      isHavingBackupPlans={isHavingBackupPlans}
                                      maxLines={getMaxLines("primaryPlan")}
                                      maxQuantityError={maxQuantityCheck(
                                        "primaryPlan"
                                      )}
                                      notificationTitle={
                                        aemContent?.notificationTitle
                                      }
                                      notificationSubtitle={
                                        aemContent?.notificationSubtitleBackup
                                      }
                                      onNoOfPlansQtyChange={
                                        onNoOfPlansQtyChange
                                      }
                                      planDetails={planDetails}
                                      planDetailsBackup={planDetailsBackup}
                                      planRadioBoxes={planRadioBoxes(
                                        "backupPlan"
                                      )}
                                      planType="backupPlan"
                                      width="170px"
                                      isfromAuth={isfromAuth}
                                    />
                                    <MarginSpacerL />
                                    {planDetails && hideBackupPlan ? (
                                      <PlanCardGroup
                                        isMobile={isMobile}
                                        isValidOneOffer={isValidOneOffer}
                                        span={planRadioBoxes("backupPlan")?.length}
                                      >
                                        {planRadioBoxes(
                                          "backupPlan",
                                          maxQuantityCheck("primaryPlan")
                                        )}
                                      </PlanCardGroup>
                                    ) : (
                                      <></>
                                    )}
                                    {hideBackupPlan && (
                                      <GridContainer
                                        isMobile={isMobile}
                                        style={{ rowGap: "1rem" }}
                                      >
                                        <GridSpanItem
                                          span={
                                            planRadioBoxes("backupPlan")
                                              ?.length + 1
                                          }
                                        >
                                          <PlanDisclaimers
                                            isHSIQualified={isHSIQualified}
                                            broadBandRedirectUrl={
                                              broadBandRedirectUrl
                                            }
                                            isCband={cband}
                                            isMMW={mmw}
                                            planType="backupPlan"
                                            selectedPlan={
                                              backupPlan?.selectedPlan
                                            }
                                            showVisaOffer={showVisaOffer}
                                            maxSpeed={maxSpeed}
                                          />
                                        </GridSpanItem>
                                      </GridContainer>
                                    )}
                                  </CustomTileContainer>
                                }
                              />
                            </CustomAccordionItem>
                          </Accordion>
                        </GridSpanItem>
                      </GridContainer>
                    </>
                  )} */}
                </>
              }
            />
          </CustomAccordionItem>
        </CustomAccordion>
      </AccordionWrapper>
      {speedPlanModal && showSpeedPlanModal()}
      {promoModal && showPromoModal()}
      {reviewOfferDetails && <ReviewOfferDetails setReviewOfferdetails={setReviewOfferdetails} reviewOfferList={reviewOfferList} />}
      {jointOfferModal && !isPDPLayoutFWA && <JointOfferDetails setJointOfferModal={setJointOfferModal} jointOfferList={jointOfferList} flow={flow} />}
      {jointOfferModal && isPDPLayoutFWA && <JointOfferDetailsNew setJointOfferModal={setJointOfferModal} jointOfferList={jointOfferList} flow={flow} />}

    </PlansAccordionContainer>
  );
};

export default PlanSelection;


const CustomNotification = styled(Notification)`
  div[type="warning"] {
    border-radius: 4px;
  }
`;
const CustomTextLink = styled(TextLink)`
  place-self: flex-start;
`

const CustomLink = styled(TextLink)`
 width: 110px;
`