let ssIndex = 0;

export const stripHTML = (str) => str && str.replace(/<\/?[^>]+(>|$)/g, "");

export const ellipsisShorten = (string, lengthLimit) => {
	if (string && string?.length && lengthLimit && string.length > lengthLimit) {
		const limit = string.substr(0, lengthLimit).lastIndexOf(" ");
		return string.substr(0, limit) + "...";
	} else {
		return string;
	}
};

export const Ss7Domain = (ss7DomainsVal) => {
	const defaultUrl = "https://ss7.vzw.com/is/image/";
	const ss7Domains = ss7DomainsVal?.split(",") || [];
	const url = ss7Domains[ssIndex];
	if (ssIndex >= ss7Domains?.length) {
		ssIndex = 0;
	} else {
		ssIndex = ssIndex + 1;
	}
	return url || defaultUrl;
};

export const Ss7DomainWithVerizonWireless = (ss7Domains) => {
	return `${Ss7Domain(ss7Domains)}VerizonWireless/`;
};

export const attachLPEvent = (callback = () => {}) => {
	if (window?.["lpTag"]) {
		window?.["lpTag"].events.bind({
			eventName: "state",
			appName: "lpUnifiedWindow",
			func: (state) => {
				if (state.state === "init") {
					console.log("chat initiated");
					if (callback) {
						callback();
					}
				}
			},
			context: window,
			async: false, //default is false,
			triggerOnce: false, //default is false
		});

		addEventForMobile();
	}
};

const addEventForMobile = (callback = () => {}) => {
	if (window?.["lpTag"]) {
		if (
			window?.["lpTag"] &&
			!window?.["lpTag"].unifiedwin?.DeviceDetector().isDesktop()
		) {
			// if(callback){
			//   callback();
			// }
		}
	}
};

export function downloadScript(url, id) {
	const scriptElem = document.createElement("script");
	scriptElem.type = "text/javascript";
	scriptElem.defer = true;
	scriptElem.src = url;
	scriptElem.onload = () => {
		const mktoForms2 = window["MktoForms2"];
		mktoForms2.loadForm("//info.verizonwireless.com", "324-BZD-350", id);
	};
	document.body.appendChild(scriptElem);
}

export const pickAs = (obj, props) =>
	Object.entries(obj).reduce((r, [k, v]) => {
		if (k in props) r[props[k]] = v;

		return r;
	}, {});

export const formatPriceString = (price, trimTrailingZeroes = false) => {
	if (price !== 0 && !price) {
		return "-";
	}
	if (typeof price === "string") {
		price = price.replace(/[$]+/g, "");
	}
	price = Number(price)?.toFixed(2);
	let ret = `$${price}`;
	if (trimTrailingZeroes) ret = ret.replace(".00", "");
	return ret;
};

export const isValidEmail = (val) => {
	if (!val?.trim()) {
		return false;
	} else {
		const pattern =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return pattern.test(val);
	}
};

export const phoneNumberFormat = (val) => {
       return val ? val?.replace(/(\d{3})(\d{3})(\d{4})/, "$1.$2.$3") : "";
}


export const addScrollView = (name)=>{
	const business = document.getElementsByClassName(name)
		if(business!==null&&business&&business.length){
			setTimeout(()=>{
				business[0].scrollIntoView()
			setTimeout(()=>window.scrollBy(0,-160),500)
			},500)
		}
}
export const getFullRatingWidth = (rating) => {
	const size = Math.round(20 * rating);
	return size + "px";
};

export const getProductType = (category) =>
	category === "Smart Watches" ||
	category === "Connected Smartwatches" ||
	location?.pathname?.includes("smart-watches") ||
	String(category).toLowerCase().includes("smartwatch")
		? "smart-watches"
		: category === "Phones" || location?.pathname?.includes("basic-phones")
		? "basic-phones"
		: category === "BroadbandAccess Devices" ||
		  location?.pathname?.includes("mobile-hotspots")
		? "mobile-hotspots"
		: category === "Tablets" || location?.pathname?.includes("tablets")
		? "tablets"
		: category === "Connected Laptops" || location?.pathname?.includes("laptops")
		? "laptops"
		: category === "Connected Devices" ||
		  location?.pathname?.includes("desk-conference-phones")
		? "desk-conference-phones"
		: "smartphones";
/* sets a cookie in current domain */
export function setLocalCookie(
	cookieName,
	cookieValue,
	expiryDays = 5,
	secureOnly = true
) {
	const date = new Date();
	date.setTime(date.getTime() + expiryDays * 1000 * 60 * 60 * 24);
	let cookieStr =
		cookieName +
		"=" +
		cookieValue +
		"; expires=" +
		date.toUTCString() +
		(secureOnly ? ";secure" : "");
	const rootDomain = getDomainTLD(window.location.hostname);
	cookieStr = cookieStr + ";domain=" + rootDomain + ";path=/";
	document.cookie = cookieStr;
}

function getDomainTLD(domain) {
	if (undefined === domain || typeof domain != "string") {
		return null;
	}
	try {
		var domainParts = domain.split(".");
		var domainTLD =
			domainParts.length > 1
				? domainParts[domainParts.length - 2] +
				  "." +
				  domainParts[domainParts.length - 1]
				: domain;
		return domainTLD ? domainTLD : null;
	} catch (error) {
		return error;
	}
}

export const checkIfAemExists = (val)=>{
	if(typeof val == "boolean") return val
	return false
  }