import React, { useEffect, useRef, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { Button } from "@vds3/buttons";
import { Grid, Col, Row } from "@vds3/grids";
import { Icon } from "@vds3/icons";
import { Line } from "@vds3/lines";
import { Notification } from "@vds3/notifications";
import { Tabs, Tab } from "@vds3/tabs";
import { Title, Body } from "@vds3/typography";
// import { Pagination } from "@vds3/pagination";
import StickyHeaderVds3 from "common/components/StickyHeader/StickyHeaderVds3";
import SpinnerComponent from "common/components/Spinner/Spinner";
import {
	MarginSpacer2XL,
	MarginSpacerL,
	MarginSpacerM,
	MarginSpacerS,
} from "common/components/styleTag";
import { TextLink, TextLinkCaret } from "@vds3/buttons";
import {
	addToCart,
	getBussProductPlans,
	marketplaceAddToCart,
} from "pages/BussProductsPage/ducks/BussProductSlice";
import PdpPlanList from "./PdpPlanList";
import _ from "lodash";
// import { ChangeAddress } from "common/components/FWA/Modals";
import * as SiteAnalytics from "../../sitecatalyst";
import { setClientId, setLoadBussPDPPage, setLoadShoppingCartWidget } from "app/ducks/GlobalSlice";
import Reviews from "pages/Mobility/components/MobilityProductDetails/Reviews/ReviewsVds3";
import { Toggle } from "@vds3/toggles";
import { PDPListView } from "./PDPListView";

const PAGE_SIZE = 6;
const BussProductPDP = (props) => {
	const dispatch = useDispatch();
	const isLowerenv = window?.location?.pathname?.includes("/mbt/prospect/");
	const bussAemText = !isLowerenv ? "marketPlaceQA" : "marketPlace";
	const { t, ready } = useTranslation(["app",bussAemText], { useSuspense: false });
	const [isAPIError, setIsAPIError] = useState(false);
	const [pageSelected, setPageSelected] = useState(1);
	const [showChangeAddressModal, setChangeAddressModal] = useState(false);
	const [isYearlyPlan, setIsYearlyPlan] = useState(false);
	const [dataTosend,setData] = useState({})
	const bussData = useSelector(
		(state) => state?.ProspectWidgets_BussProductsData
	);
	const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
	const [tabIndex, setTabIndex] = useState(0);
	const cartData = globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	const selectedBussId = bussData?.bussId || "MOBILEIRON";
	let cmpVal = globalData?.cmp?.cmpValue;

	const aemContent = t("buss-product", {
		ns: bussAemText,
		returnObjects: true,
	});
	const pageTemplate = aemContent?.products?.[selectedBussId];
	let pageTemplateTabs = pageTemplate?.tabs || [];
	const footerContent = aemContent?.marketPlaceProductLandingPage?.footerContent;
	const isMobile = useMediaQuery({
		query: "(max-width: 545px)",
	});
	const tabsRef = useRef();
	const targetRefs = useRef(new Array());
	const textColor = pageTemplate?.darkImage ? "#ffffff" : "#000000";
	const assetsBaseUrl = process.env.PROSPECT_WIDGETS_APP_ASSESTS_BASE_URL;

	let slicedPlansList = useMemo(() => {
		let plansDta = bussData?.bussResponse?.category?.[0]?.childGroup;
		let plans = [];
		if (plansDta?.length) {
			Object.values(plansDta).forEach((item) => {
				if (item && Object.values(item)?.length) {
					let items = Object.values(item);
					plans = [...plans, ...items];
				}
			});
		}
		plans = _.flatten(plans);
		let filteredPlans = [],
			yearlyPlan = false;
		plans?.forEach((item) => {
			let planData = item;
			if (!!planData) {
				if (item?.pricePlanPackageDetails?.length) {
					item?.pricePlanPackageDetails?.some((pack) => {
						if (pack?.frequency === "ANNUAL") {
							yearlyPlan = true;
							return true;
						}
					});
				}
				filteredPlans.push(item);
			}
		});
		if (yearlyPlan && !isYearlyPlan) {
			setIsYearlyPlan(true);
		}
		return filteredPlans;
	}, [bussData]);

	const totalItems = slicedPlansList?.length;
	const onTabChangeFn = (event, tabIndex) => {
		const scrollTarget = targetRefs.current[tabIndex]?.offsetTop;
		const tabsSize = tabsRef?.current?.clientHeight;
		setTabIndex(tabIndex);

		window.scrollTo({
			top: scrollTarget - tabsSize,
			behavior: "smooth",
		});
	};
	const removeOverView = ((t('showOverview',{ns:'app'}) === true) && props.clientId === 'B360')
	const removeFeatures = ((t('showFeatures',{ns:'app'}) === true) && props.clientId === 'B360')
	const defaultListView = ((t('defaultListView',{ns:'app'}) === true) && props.clientId === 'B360')
	if(removeOverView){
		pageTemplateTabs = pageTemplateTabs.filter(data=>String(data['tabTitle']).toUpperCase() !== "OVERVIEW")
	}
	if(removeFeatures){
		pageTemplateTabs = pageTemplateTabs.filter(data=>String(data['tabTitle']).toUpperCase() !== "FEATURES")
	}
	const handleContinueClick = (itemID, frequency, productType, enteredData = [], quantity = 1, selectedItem ={}) => {
		if (globalData?.widgetInputParams?.flow?.value?.toLowerCase() === 'assisted') {
			dispatch(setClientId("optix"));
		}
		const cart = cartData?.cart;
		let operation = cart?.packages ? "UPDATE" : "ADD";
		if(!!enteredData && enteredData.length){
			setData({...dataTosend,[itemID]:enteredData})
		}
		const payload = {
			allowMixedCart: true,
			cartMeta: {
				zipCode:
					cart?.zipCode ||
					cartData?.prospect5gLoopQualAddress?.zipCode ||
					cartData?.businessInfo?.address?.zipCode,
				shoppingPath: "prospect",
				channel: globalData?.clientId
			},
			flow: "prospect",
			lines: [
				{
					packageId: itemID || "",
					packageType: "IOT",
					priceType: frequency || "MONTHLY",
					operation: operation,
					productType: productType || "",
					quantity: quantity,
					marketPlaceProduct: true,
					name: selectedItem?.itemName,
					shortDescription:  selectedItem?.itemName,
					price:selectedItem?.pricePlanPackageDetails?.[0]?.purchasePrice,
					characteristics: [...enteredData]
				},
			],
		};
		
		dispatch(
			addToCart({
				...payload,
			})
		);
	};

	const handleContinueOrderClick = () => {
		dispatch(marketplaceAddToCart());
	}


	useEffect(() => {
		if ((!bussData || !bussData?.bussResponse?.category) && selectedBussId) {
			dispatch(getBussProductPlans({ cartId: selectedBussId }));
		}
		SiteAnalytics.trackPageInfo();
	}, []);

	useEffect(() => {
		if (bussData?.cartResponse?.cart) {
			//dispatch(setLoadShoppingCartWidget(true));
		}
	}, [bussData?.cartResponse]);

	useEffect(() => {
		if (bussData?.marketplaceSaveAddtoCartResp?.status) {
			dispatch(setLoadBussPDPPage(false));
			dispatch(setLoadShoppingCartWidget(true));
		}
	}, [bussData?.marketplaceSaveAddtoCartResp]);

	return ready ? (
		<ProductContainer>
			<StickyHeaderVds3
				onContinue={() => {handleContinueOrderClick()}}
				// onCancel={onCancel}
				disabledContinueBtn={!bussData?.marketplaceSaveProductResp?.success}
				hideAddress={true}
				backClickHandler={() => { }}
				enableBackLink={true}
				onAddressChange={() => { }}
				continueBtnText={"Continue Order"}
				hideContinueButton={false}
			/>
			{/* <SEOTagger /> */}
			{!!isAPIError && (
				<Notification
					type="error"
					inline={true}
					fullBleed={true}
					title={aemContent?.["cart-update-notification"]?.sww}
				/>
			)}
			{bussData?.loading && <SpinnerComponent disabled={false} />}
			<Grid>
				<StickyRow>
					<Container ref={tabsRef}>
						{!!pageTemplateTabs?.length && (
							<Tabs
								indicatorPosition="top"
								orientation="horizontal"
								onTabChange={onTabChangeFn}
								selectedIndex={tabIndex}
								sticky
							>
								{pageTemplateTabs?.map((tab) => (
									<Tab key={tab?.tabTitle} label={tab?.tabTitle} />
								))}
							</Tabs>
						)}
						<MarginSpacerS />
					</Container>
				</StickyRow>
				<Row>
					{removeOverView  ? <></> :
					<Container ref={(element) => targetRefs.current.push(element)}>
						<MarginSpacerM />
						<Line type="secondary" />
						<ImageContainer
							id="overview"
							url={
								pageTemplate?.image?.bgImage &&
								`${assetsBaseUrl}${pageTemplate?.image?.imageUrl}`
							}
							isVideoPresent={pageTemplate?.prodVideo}
						>
							<OverviewFlex isBgImage={!pageTemplate?.image?.bgImage}>
								{pageTemplate?.pageTitle && (
									<>
										<Title bold={true} size="large" color={"#0754d4"}>
											{pageTemplate?.pageTitle}
										</Title>
										<Body size="large">{pageTemplate?.pageSubTitle}</Body>
										<MarginSpacerM />
									</>
								)}
								<Title bold={true} size="large" color={textColor}>
									{pageTemplate?.heading}
								</Title>
								<MarginSpacerS />
								<CustomBody>
									<Body
										bold={!!(pageTemplate?.subHeading?.length < 150)}
										size="medium"
										color={textColor}
									>
										{pageTemplate?.subHeading}
									</Body>
								</CustomBody>
								<MarginSpacerL />
								<Button
									onClick={() => {
										let scrollDiv = document.getElementById("shop-now-plans")?.offsetTop;
										window.scrollTo({
											top: scrollDiv - tabsRef?.current?.clientHeight,
											behavior: "smooth",
										});
									}}
								>
									{pageTemplate?.ctaBtnText}
								</Button>
							</OverviewFlex>
							{!!pageTemplate?.youtubeEmbed && (
								<OverviewFlex isYoutubeEmbed={pageTemplate?.youtubeEmbed}>
									<iframe
										width="100%"
										height="335px"
										src={pageTemplate?.youtubeEmbed}
										title="YouTube video player"
										frameborder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowfullscreen=""
									/>
								</OverviewFlex>
							)}
							{!!pageTemplate?.image && !pageTemplate?.image?.bgImage && (
								<OverviewFlex>
									<img src={`${assetsBaseUrl}${pageTemplate?.image?.imageUrl}`} />
								</OverviewFlex>
							)}
						</ImageContainer>
						{pageTemplate?.prodVideo && (
							<video width="100%" muted controls autoPlay loop id="product-video">
								<source
									src={`${assetsBaseUrl}${pageTemplate?.prodVideo}`}
									type="video/mp4"
								/>
							</video>
						)}
					</Container>
					}
				</Row>
				<Row>
					{removeOverView ? <></>: 
					<>
						{pageTemplate?.featureTitle && (
							<GreyContainer noBottomPad>
								<Title size="large" bold>
									{pageTemplate?.featureTitle}
									{pageTemplate?.featureDesc && (
										<>
											<MarginSpacerS />
											<Body size="large">{pageTemplate?.featureDesc}</Body>
										</>
									)}
								</Title>
							</GreyContainer>
						)}
					</> }
				</Row>
				{removeFeatures ? <></> : 
				<>
				<Row>
					<GreyContainer
						ref={(element) => targetRefs.current.push(element)}
						flexWrap={true}
					>
						{pageTemplate?.feature?.map((viewItem) => (
							<BussFeature item={viewItem} />
						))}
					</GreyContainer>
				</Row>
				<Row>
					<>
						{pageTemplate?.featureHalf && (
							<BussFeatureHalf
								item={pageTemplate?.featureHalf}
								ref={(element) => targetRefs.current.push(element)}
							/>
						)}
					</>
				</Row>
				<Row>
					{pageTemplate?.GcFeatures && (
						<BussGCFeature item={pageTemplate?.GcFeatures} />
					)}
				</Row>
				<Row>
					{pageTemplate?.lookoutFeature?.map((viewItem) => (
						<BussLookoutSection item={viewItem} />
					))}
				</Row>
				</>}
				<Row>
					<div ref={(element) => targetRefs.current.push(element)}>
						<BussPlanSection
							planDetails={pageTemplate?.planDetails}
							handleContinueClick={handleContinueClick}
							aemContent={aemContent}
							bussData={bussData}
							isMobile={isMobile}
							totalItems={totalItems}
							slicedPlansList={slicedPlansList}
							pageSelected={pageSelected}
							setPageSelected={setPageSelected}
							isYearlyPlan={isYearlyPlan}
							cartId={selectedBussId}
							cartData={cartData}
							selectedProducts = {bussData?.selectedProductBUSS}
							dataTosend={dataTosend}
							defaultListView = {defaultListView}
						/>
					</div>
				</Row>
				<Row>
					<>
						{pageTemplate?.bussContentTitle && (
							<GreyContainer noBottomPad>
								<Title size="large" bold>
									{pageTemplate?.bussContentTitle}
								</Title>
							</GreyContainer>
						)}
					</>
				</Row>
				<Row>
					<>
						{pageTemplate?.bussContent && (
							<GreyContainer flexWrap={true}>
								{pageTemplate?.bussContent?.map((viewItem) => (
									<BussFeature item={viewItem} />
								))}
							</GreyContainer>
						)}
					</>
				</Row>
				<Row>
					<>
						{pageTemplate?.featureHalf1 && (
							<BussFeatureHalf item={pageTemplate?.featureHalf1} />
						)}
					</>
				</Row>
				<Row>
					<>
						{pageTemplate?.featureHalf2 && (
							<BussFeatureHalf item={pageTemplate?.featureHalf2} />
						)}
					</>
				</Row>
				<MarginSpacer2XL />
				<Row>
					<div
						ref={(element) => targetRefs.current.push(element)}
						flexWrap={true}
					>

						<Title size="Large" bold>
							Reviews</Title>
						<Reviews reviews={aemContent?.reviews}></Reviews>

					</div>
				</Row>

				{/* <Row>
          <GreyContainer flexSA={true}>
            <div>
              <Title size="2XLarge" bold>
                {footerContent?.heading}
              </Title>
            </div>
            <div>
              <MarginSpacerS>
                <Title size="small">
                  {footerContent?.contactText}
                </Title>
              </MarginSpacerS>
              <Title size="small">
                {footerContent?.contactNo}
              </Title>
            </div>
            <div>
              <MarginSpacerS>
                <Title size="small">
                  {footerContent?.requestCallTitle}
                </Title>
              </MarginSpacerS>
              <Button
                use="secondary"
                onClick={() =>
                  (window.location.href = footerContent?.callUrl)
                }
              >
                {footerContent?.requestText}
              </Button>
            </div>
          </GreyContainer>
        </Row> */}
			</Grid>
			{showChangeAddressModal && (
				<ChangeAddress
					opened={showChangeAddressModal}
					onOpenedChange={setChangeAddressModal}
					url={""}
				/>
			)}
		</ProductContainer>
	) : (
		<SpinnerComponent />
	);
};

export const BussGCFeature = ({ item }) => {
	const assetsBaseUrl = process.env.PROSPECT_WIDGETS_APP_ASSESTS_BASE_URL;
	return (
		<GCFeaturesWrapper>
			{!!item?.imageUrl && (
				<GCFeatureImage
					src={`${assetsBaseUrl}${item?.imageUrl}`}
					alt={item?.altText}
				/>
			)}
			<MarginSpacerM />
			<GCFeatureContent>
				<Title bold={true} size="small">
					{item?.contentTitle}
				</Title>
				<MarginSpacerM />
				<Body size="medium">
					<span dangerouslySetInnerHTML={{ __html: item.contentText }} />
				</Body>
			</GCFeatureContent>
		</GCFeaturesWrapper>
	);
};

export const BussFeature = ({ item }) => {
	const assetsBaseUrl = process.env.PROSPECT_WIDGETS_APP_ASSESTS_BASE_URL;

	return (
		<FeatureItem>
			{!!item?.iconUrl ? (
				<FeatureImage src={`${assetsBaseUrl}${item.iconUrl}`} alt={item.iconName} />
			) : !!item?.iconName ? (
				<Icon size="XLarge" color="#000000" name={item.iconName} />
			) : (
				<></>
			)}
			<MarginSpacerM />
			<Title bold={true} size="small">
				{item.contentTitle}
			</Title>
			<MarginSpacerM />
			<Body size="small">{item.contentText}</Body>
		</FeatureItem>
	);
};

export const BussFeatureHalf = ({ item }) => {
	const assetsBaseUrl = process.env.PROSPECT_WIDGETS_APP_ASSESTS_BASE_URL;
	return (
		<FeartureHalfWrapper reverse={item?.reverse}>
			<FeatureItemHalf>
				{!item?.reverse ? (
					<Title bold={true} size="small">
						{item?.content1}
					</Title>
				) : (
					<Body size="large">{item?.content1}</Body>
				)}
				{!item?.reverse ? (
					<Title bold={true} size="small">
						{item?.content1SubText}
					</Title>
				) : (
					<>
						<MarginSpacerM />
						<Body size="large">{item?.content1SubText}</Body>
					</>
				)}
			</FeatureItemHalf>
			<FeatureItemHalf>
				{!item?.reverse ? (
					<Body size="large" bold={item?.reverse}>
						{item?.content2}
					</Body>
				) : (
					<Title bold={true} size="small">
						{item?.content2}
					</Title>
				)}
				<MarginSpacerM />
				<Body size="large">{item?.content3}</Body>
			</FeatureItemHalf>
			{!!item?.bannerImage && (
				<img src={`${assetsBaseUrl}${item?.bannerImage}`} alt={item.iconName} />
			)}
		</FeartureHalfWrapper>
	);
};

export const BussLookoutSection = ({ item }) => {
	const assetsBaseUrl = process.env.PROSPECT_WIDGETS_APP_ASSESTS_BASE_URL;

	return (
		<LookoutWrapper reverse={item?.reverse}>
			<LookoutItem>
				<Title bold={true} size="medium">
					{item?.contentTitle}
				</Title>
				<MarginSpacerM />
				<Body bold={false} size="large">
					{item?.contentText}
				</Body>
				{item?.downloadText && (
					<>
						<MarginSpacerL />
						<TextLinkCaret href={`${assetsBaseUrl}${item?.downloadPdfLink}`}>
							{item?.downloadText}
						</TextLinkCaret>
					</>
				)}
				<MarginSpacerL />
				{item?.btnText && (
					<Button
						onClick={() => {
							window.location.href = item?.btnUrl;
						}}
					>
						{item?.btnText}
					</Button>
				)}
			</LookoutItem>
			{!!item?.lookoutImage && (
				<img src={`${assetsBaseUrl}${item?.lookoutImage}`} alt={item.altText} />
			)}
		</LookoutWrapper>
	);
};

const BussPlanSection = ({
	planDetails,
	handleContinueClick,
	aemContent,
	bussData,
	isMobile,
	totalItems,
	slicedPlansList,
	pageSelected,
	setPageSelected,
	isYearlyPlan,
	cartId,
	cartData,
	selectedProducts,
	dataTosend={},
	defaultListView = false
}) => {
	const [toggleOn, setToggleOn] = useState({})
	useEffect(()=>{
		if(defaultListView && !!aemContent?.marketPlaceProductLandingPage?.plansTab && aemContent?.marketPlaceProductLandingPage?.plansTab.length){
			setToggleOn({[aemContent?.marketPlaceProductLandingPage?.plansTab[0]['frequency']] : { 'toggle': true}})
		}
	},[defaultListView])
	return (
		<PlanSection id="shop-now-plans">
			<LeftSection>
				<Title bold={true} color={"#ffffff"} size={"2XLarge"}>
					{planDetails?.heading}
				</Title>
				<MarginSpacerL />
				{!!planDetails?.termsText && (
					<TextLink
						type="standAlone"
						size="large"
						surface="dark"
						onClick={() => {
							window.location.href = planDetails?.termsLink;
						}}
					>
						{planDetails?.termsText}
					</TextLink>
				)}
			</LeftSection>
			<TabContainer>
				<Tabs indicatorPosition="bottom" orientation="horizontal">
					{aemContent?.marketPlaceProductLandingPage?.plansTab
						?.filter(({ allowedCarts, blockedCarts }) =>
							!!allowedCarts
								? allowedCarts.includes(cartId)
								: !blockedCarts?.includes(cartId)
						)
						?.map((tab) => {
							return (
								<Tab
									key={tab?.tabTitle}
									label={tab?.tabTitle}
									hide={!!(tab?.tabTitle === "Annually" && !isYearlyPlan)}
								>
									<MarginSpacerM />
									<Toggle
										value={toggleOn[tab.frequency] && toggleOn[tab.frequency]['toggle'] ? true : false}
										surface="light"
										disabled={false}
										showText={true}
										textWeight="regular"
										textSize="small"
										textPosition="right"
										statusText={(e) => toggleOn[tab.frequency] && toggleOn[tab.frequency]['toggle'] == true ? "List View" : "Grid View"}
										onChange={(e, name) => {
											setToggleOn({ ...toggleOn, [tab.frequency]: { 'toggle': name.on } })
										}}
										on={toggleOn[tab.frequency] && toggleOn[tab.frequency]['toggle'] ? true : false}
									/>
									<MarginSpacerM/>
									{toggleOn[tab.frequency] && toggleOn[tab.frequency]['toggle'] ? 
									<PDPListView
									handleContinueClick={handleContinueClick}
									aemContent={aemContent}
									bussData={bussData}
									slicedPlansList={slicedPlansList}
									planDetails={planDetails}
									frequency={tab?.frequency}
									selectedProducts={selectedProducts}
									dataTosend = {dataTosend}
									/>
										:
										<BussGridContainer isMobile={isMobile}>
											<PdpPlanList
												handleContinueClick={handleContinueClick}
												aemContent={aemContent}
												bussData={bussData}
												slicedPlansList={slicedPlansList}
												planDetails={planDetails}
												frequency={tab?.frequency}
												type={tab?.type}
												selectedProducts={selectedProducts}
												dataTosend={dataTosend}
											/>
										</BussGridContainer>
									}
									{/* {totalItems && totalItems > PAGE_SIZE && (
                <CustomPagination
                  selectPage={(e) => {
                    setPageSelected(e);
                  }}
                  selectedPage={pageSelected}
                  total={Math.ceil(totalItems / PAGE_SIZE)}
                  showArrow={true}
                />
              )} */}
								</Tab>
							);
						})}
				</Tabs>
			</TabContainer>
		</PlanSection>
	);
};

// const CustomPagination = styled(Pagination)`
//   margin-left: auto;
//   display: flex;
//   justify-content: flex-end;
// `;
const ProductContainer = styled.div``;
const TabContainer = styled.div`
	width: 100%;
	margin-left: 1rem;
	margin-top: 1rem;
	@media only screen and (max-width: 545px) {
		margin: 2px;
	}
`;

const StickyRow = styled(Row)`
	position: sticky;
	top: 60px;
	z-index: 999;
	background-color: #fff;
`;

export const GreyContainer = styled.div`
	width: 100%;
	background-color: #f6f6f6;
	display: flex;
	flex-direction: row;
	row-gap: 1rem;
	padding-top: 2rem;
	${({ noBottomPad }) => !noBottomPad && "padding-bottom: 2rem"};
	padding-left: 1.3rem;
	@media only screen and (max-width: 545px) {
		flex-direction: column;
	}
	${({ flexSA }) =>
		flexSA &&
		`
    justify-content: space-around; 
    padding-right: 4rem;
  `}

	${({ flexWrap }) => flexWrap && `flex-wrap: wrap;`}
`;

const PlanSection = styled.div`
	min-height: 260px;
	display: flex;
	@media only screen and (max-width: 545px) {
		max-width: 100%;
		flex-direction: column;
	}
`;

const LeftSection = styled.div`
	background-color: #000000;
	@media only screen and (min-width: 545px) {
		min-width: 25%;
		max-width: 25%;
	}
	padding: 1.5rem;
	a,
	span {
		color: #ffffff !important;
		border-color: #ffffff !important;
	}
`;

const BussGridContainer = styled.div`
	display: grid;
	grid-template-columns: ${({ isMobile }) =>
		isMobile ? "repeat(1, minmax(250px, 1fr))" : "repeat(3, minmax(150px, 1fr))"};
	column-gap: 1rem;
	margin-top: 1rem;

	@media only screen and (min-width: 545px) {
		overflow-y: auto;
		max-height: 500px;
		padding-right: 1rem;
		&::-webkit-scrollbar {
			width: 4px;
			height: 20px;
			background: #d8dada;
			padding-left: 1rem;
			border-radius: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #6f7171;
			border-radius: 10px;
		}
	}
`;

const GCFeatureImage = styled.img`
	max-width: 50%;
`;

const GCFeatureContent = styled.div`
	padding-left: 3rem;
	padding-top: 3rem;
	@media only screen and (max-width: 545px) {
		padding: 1.5rem;
	}
`;

const GCFeaturesWrapper = styled.div`
	display: flex;
	flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
	border-bottom: 1px solid #d8dada;
	@media only screen and (max-width: 545px) {
		max-width: 100%;
		flex-direction: column;
		img {
			max-width: 100%;
			min-width: unset;
		}
	}
	.text-bold {
		font-weight: 700;
	}
`;

const LookoutWrapper = styled.div`
	display: flex;
	flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
	justify-content: space-around;
	padding-top: 3rem;
	padding-bottom: 3rem;
	border-bottom: 1px solid #d8dada;
	img {
		max-width: 50%;
		min-width: 350px;
	}
	@media only screen and (max-width: 545px) {
		max-width: 100%;
		margin-bottom: 1rem;
		flex-direction: column;
		img {
			max-width: 100%;
			min-width: unset;
		}
	}
`;

const LookoutItem = styled.p`
	margin-right: 2rem;
	margin-left: 2rem;
	margin-top: 2rem;
	width: 25%;
	@media only screen and (max-width: 545px) {
		width: unset;
	}
`;

const FeartureHalfWrapper = styled.div`
	display: flex;
	flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
	justify-content: ${({ reverse }) => (reverse ? "flex-end" : "flex-start")};
	border-bottom: 1px solid #d8dada;
	img {
		max-width: 50%;
		min-width: 350px;
	}
	@media only screen and (max-width: 545px) {
		max-width: 100%;
		margin-bottom: 1rem;
		flex-direction: column;
		img {
			max-width: 100%;
			min-width: unset;
		}
	}
`;

const FeatureItemHalf = styled.p`
	margin-right: 2rem;
	margin-left: 2rem;
	margin-top: 2rem;
	width: 25%;
	@media only screen and (max-width: 545px) {
		width: unset;
	}
`;

const FeatureItem = styled.div`
	max-width: 21%;
	@media only screen and (max-width: 545px) {
		max-width: 100%;
		margin-bottom: 1rem;
	}
`;
const CustomBody = styled.div`
	width: 80%;
	@media only screen and (max-width: 545px) {
		width: unset;
	}
`;

const Container = styled.div`
	width: 100%;
`;

const FeatureImage = styled.img`
	width: 28px;
	height: 28px;
`;

const ImageContainer = styled.div`
	background-image: ${({ url }) => `url('${url}')`};
	background-repeat: no-repeat;
	background-size: cover;
	height: ${({ isVideoPresent }) => (isVideoPresent ? "250px" : "400px")};
	${({ url }) => url && "padding-top: 60px"};
	padding-left: 20px;
	display: flex;
	row-gap: 1rem;
	justify-content: space-between;
	@media only screen and (max-width: 545px) {
		height: ${({ url }) => (url ? "320px" : "auto")};
		flex-wrap: wrap;
		padding-left: ${({ url }) => (url ? "20px" : "unset")};
	}
`;

const OverviewFlex = styled.div`
	${({ isBgImage }) => isBgImage && "padding-top: 45px"};
	img {
		height: 370px;
		width: 630px;
	}
	${({ isYoutubeEmbed }) => isYoutubeEmbed && `min-width: 50%;`}
	@media only screen and (max-width: 545px) {
		img {
			height: 100%;
			width: 100%;
		}
	}
`;

const ColNoPad = styled(Col)`
	padding-right: 0;
	padding-left: 0;
`;

const RowNoPad = styled(Row)`
	@media only screen and (min-width: 545px) {
		> div {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
	}
`;

export default BussProductPDP;
