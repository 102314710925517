import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { RadioBox } from "@vds3/radio-boxes";
import { Body, Title } from "@vds3/typography";
import { MarginSpacerM, MarginSpacerS } from "common/components/styleTag";
import { formatPriceString } from "utils/commonUtils";
import FWAContext from "../fwaContext";

const PaymentCard = ({
  id,
  heading,
  selected,
  enablePromotion,
  isPromotion,
  promoPrice,
  isDPP,
  price,
  installments,
  isMobile,
  onChange,
  planType,
  offerDiscount,
  retailRouterPrice,
  originalPrice,
    ...props
}) => {
  const { flow, primaryPlan, backupPlan } = useContext(FWAContext);
  const { t } = useTranslation(["4G-5G"]);
  const aemKey = flow === "4g" ? "fourG" : "fiveG";
  const aemObject = t(`4G-5G.${aemKey}.router-content`, {
    returnObjects: true,
  });

  let dicountamounttotalprice;
  let offerDiscountamt;
  const commonPlan = planType === "primaryPlan" ? primaryPlan : backupPlan;
  const actualPrice = isPromotion ? promoPrice : price;

  const totalPrice = (Number(actualPrice) * Number(commonPlan?.quantity ?? 1)).toFixed(2);
  const discountAmt = (price * Number(commonPlan?.quantity ?? 1)).toFixed(2) - totalPrice;

  let billcreditOffer = ((price * Number(commonPlan?.quantity ?? 1)).toFixed(2) - totalPrice).toFixed(2);
  dicountamounttotalprice= retailRouterPrice && offerDiscount ? (Number(retailRouterPrice)-Number(offerDiscount)) :Number(originalPrice) ;
  const iscrown=planType === "primaryPlan"&&primaryPlan?.selectedRouter==="ASK-NCM1100E"&&isPromotion?true:false;
  const price1=t(`4G-5G.${aemKey}.router-content.crownPrice1`);
  if(iscrown){
  billcreditOffer=(Number(price1)).toFixed(2);
  offerDiscountamt=Number(offerDiscount)
  }
  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onChange(id);
    },
    [id, primaryPlan, backupPlan]
  );

  return (
    <div onClick={onClick} data-loc={`${flow}:${heading}`}>
      <StyledBox
        id={id}
        value={id}
        name={id}
        selected={selected}
        isMobile={isMobile}
        // onChange={onChange}
        children={
          <CardContainer>
            {!!enablePromotion && (
              <>
                <PromoTag>
                  {!!isPromotion && (
                    <Body>
                      {(aemObject?.paymentHeading &&
                        aemObject?.paymentHeading[commonPlan?.selectedPlan] &&
                        aemObject?.paymentHeading[commonPlan?.selectedPlan][
                          installments
                        ]) ||
                        (aemObject?.paymentHeading?.default &&
                          aemObject?.paymentHeading?.default[installments]) ||
                        "Promotion*"}
                    </Body>
                  )}
                </PromoTag>
                <MarginSpacerS />
              </>
            )}
            <Title size="small" bold>
              {heading}
            </Title>
            <MarginSpacerS />
            <div style={{ minHeight: "2rem" }}>
              <Body size="small" bold>
                {formatPriceString(actualPrice)}
                {!!isPromotion && (
                  <>
                    <span>&nbsp;&nbsp;</span>
                    <Body size="small" strikethrough primitive="span">
                      {formatPriceString(price)}
                    </Body>
                  </>
                )}
                <Body size="small" primitive="span">
                  {isDPP
                    ? t(`4G-5G.${aemKey}.router-content.priceInfo`, {
                        installments,
                      })
                    : flow === "4g"
                    ? id==="twoYear" ? " for 2 Year contract, plus taxes" : t(`4G-5G.${aemKey}.router-content.perRouter`)
                    : id==="twoYear" ? " for 2 Year contract, plus taxes" : t(`4G-5G.${aemKey}.router-content.priceInfoDesc`)}
                </Body>
              </Body>
            </div>
            <MarginSpacerM />
            {flow === "4g" && (
              <Body size="small" bold>
                {`Total: ${commonPlan?.quantity} ${commonPlan?.quantity > 1 ? "routers" : "router"}`}
              </Body>
            )}
            {isDPP ? (
              isPromotion ? (
                <>
                  <Body color="#6F7171">
                    {t(`4G-5G.${aemKey}.router-content.dppPromoDetail`, {
                      billcreditOffer,
                      discountAmt:Number(discountAmt).toFixed(2),
                      installments,
                      dicountamounttotalprice
                    })}
                  </Body>
                </>
              ) : (
                <>
                  <Body color="#6F7171">
                    {t(`4G-5G.${aemKey}.router-content.priceInfoDetail`, {
                      totalPrice,
                      installments,
                      dicountamounttotalprice
                    })}
                  </Body>
                </>
              )
            ) : (
              <>
              { id !== 'twoYear' && <>
                {flow === "4g" && (
                  <Body color="#6F7171">
                    {t(`4G-5G.${aemKey}.router-content.totalPlusTaxes`, {
                      totalPrice,
                    })}
                  </Body>
                )}
                <Body color="#6F7171">
                  {t(`4G-5G.${aemKey}.router-content.noDPPRequired`)}
                </Body> 
              </>
        }
        </>
            )}
          </CardContainer>
        }
      />
    </div>
  );
};

PaymentCard.defaultProps = {
  quantity: 1,
};

const StyledBox = styled(RadioBox)`
  label {
    span {
      height: 100%;
      > div {
        height: 100%;
        overflow-wrap: anywhere;
      }
    }
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  
`;

const PromoTag = styled.div`
  width: 100%;
  min-height: 0.75rem;
`;

export default PaymentCard;
