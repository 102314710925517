import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalTitle, ModalFooter } from "@vds3/modals";
import { Body, Title } from "@vds3/typography";
import styled from "styled-components";
import {
  MarginSpacerM,
} from "common/components/styleTag";

const BulkByorQuantityModal = ({ opened, onOpenedChange, path, ...props }) => {
  const { t } = useTranslation(["app"]);
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClicked = () => {
    onOpenedChange(false);
    props.onQuantityChange(props?.qtySelected);
  };
  const getImage = () => {
    // if (packages) {
    const description =
      "Verizon:_:Internet Gateway - Business:_::_::_::_:verizon-stark-black-lvv65b-odu-2-iset:_:Router:_:vz-internet-gateway-fsno21va";
    const brandName = "Verizon";
    const image = "verizon-stark-black-lvv65b-odu-2-iset";
    const productName = "Internet Gateway - Business";
    const imageCDN = "//ss7.vzw.com/is/image/VerizonWireless/";
    const carouselImageset = [];
    if (image) {
      carouselImageset.push(imageCDN + image + "?$acc-lg$&hei=138&wid=138");
    } else if (description) {
      const parts = description.split(":_:");
      let imgSrc = parts[5];
      if (imgSrc) {
        carouselImageset.push(imageCDN + imgSrc + "?$acc-lg$&hei=138&wid=138");
      }
    }
    // }
    if (carouselImageset && carouselImageset.length > 0) {
      return (
        <img
          style={{ width: "100px", height: "100px" }}
          src={carouselImageset[0]}
          alt={brandName + "-" + productName}
        />
      );
    } else return "";
  };

  return (
    <>
      {opened && (
        <Modal
          width="560px"
          hideCloseButton={true}
          disableOutsideClick={true}
          onOpenedChange={onOpenedChange}
          opened={opened}
        >
          <ModalTitle>
            <Title size="large" bold>
              You'll use our Verizon Internet Gateway router for all lines.
            </Title>
          </ModalTitle>
          <ModalBody>
            <ModalBodyContent>
              <div id="text-container">
                <Body size="large">
                  The Verizon Internet Gateway router ($349.99) is required for
                  each line of service when purchasing online multiple lines.
                  Your cart will be updated to use these routers. Router
                  promotions may apply.
                </Body>
                <MarginSpacerM />
                <Body size="large">
                  The payment method for each Gateway router will default to a
                  monthly plan ($9.72/mo for 36 months, plus taxes). This will
                  require a device payment agreement.
                </Body>
              </div>
              <div>{getImage()}</div>
            </ModalBodyContent>
          </ModalBody>
          <ModalFooter
            buttonData={{
              primary: {
                children: "Continue",
                onClick: () => {
                  onClicked();
                },
                'data-track':"Continue"
              },
              close: {
                children: "Cancel",
                onClick: () => {
                  onOpenedChange(false);
                },
                'data-track':"Cancel"
              },
            }}
          />
        </Modal>
      )}
    </>
  );
};

const ModalBodyContent = styled.div`
  letter-spacing: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  #text-container {
    max-width: 329px;
  }
  @media only screen and (max-width: 700px) {
    flex-direction: column;
    justify-content: flex-start;
    #text-container {
      max-width: 95%;
      margin-bottom: 32px;
    }
  }
`;

export default BulkByorQuantityModal;
