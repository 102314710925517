import React, { useEffect, useMemo } from "react";
//import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tabs, Tab } from "@vds3/tabs";
import { setFilterSelected } from "pages/Mobility/ducks/MobilitySlice";
import { useDispatch, useSelector } from "react-redux";

const getCategoryURI = (category) => `${category === "all" ? "" : category}/`;

const GridwallTabs = React.memo(function GridwallTabs({
	category,
	basePath,
	currentPath,
	setViewResults,
	isOCPO,
	deviceTabForOCPO,
	brandFilter,
	setBrandFilter,
	...props
}) {
	//const navigate = useNavigate();
	const dispatch = useDispatch();
	const pageData = useSelector((state) => state.ProspectWidgets_MobilityData);

	const { t } = useTranslation(["gridwall"]);
	useEffect(()=>{
		dispatch(
			setFilterSelected({ ...pageData?.filterApplied, brandList: [] })
		);
		setBrandFilter("Business solutions");	
	},	[]);
	const tabs = ["Business solutions"]
	// isOCPO
	// 	? deviceTabForOCPO
	// 	: category === "tablets"
	// 	? aemContent?.tabletTabs
	// 	: aemContent?.categoryTabs;
	const selectedTab = () => {
		let activeTab = "Business solutions";
		if (tabs?.length) {
			tabs.forEach((t) => {
				if (currentPath === `${getCategoryURI(category)}${t.toLowerCase()}`) {
					activeTab = String(t).toLowerCase();
				}
			});
		}
		return activeTab;
	};

	if (tabs?.length) {
		return (
			// <Tabs indicatorPosition="bottom" size="medium">
			// 	{tabs.map((t) => (
			// 		<Tab
			// 			key={t}
			// 			label={t}
			// 			active={
			// 				String(brandFilter).toLowerCase().includes(String(t).toLowerCase())
			// 				// currentPath === `${getCategoryURI(brandFilter)}${t.toLowerCase()}`
			// 			}
			// 			onClick={() => {
			// 				// isOCPO
			// 				// 	? navigate(
			// 				// 			`${basePath}${isOCPO ? "certified-pre-owned" : ""}${getCategoryURI(
			// 				// 				category
			// 				// 			)}${t.toLowerCase() !== "all" ? t.toLowerCase() : ""}`
			// 				// 	  )
			// 				// 	: navigate(`${basePath}${getCategoryURI(category)}${t.toLowerCase()}`);
			// 				if (t.toLowerCase() == "business solutions" && !isOCPO) {
			// 					dispatch(
			// 						setFilterSelected({ ...pageData?.filterApplied, brandList: [] })
			// 					);
			// 					setBrandFilter("Business solutions");
			// 				}
			// 			}}
			// 			selected={String(brandFilter)
			// 				.toLowerCase()
			// 				.includes(String(t).toLowerCase())}
			// 		/>
			// 	))}
			// </Tabs>
			<div></div>
		);
	} else return null;
});

export default GridwallTabs;
