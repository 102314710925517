import React, { useEffect, useRef, useState, useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Body, Title } from "@vds3/typography";
import { useDispatch, useSelector } from "react-redux";
import { MarginSpacer, MarginSpacer2XL, MarginSpacerL, MarginSpacerM, MarginSpacerS, MarginSpacerXL } from "common/components/styleTag";
import { StyledBox, PromoBadge, CardContainer, CardContent, StyledText } from "./styles";
import { Icon } from "@vds3/icons";
import { Col, Grid, Row } from "@vds3/grids";
import { Button, TextLink, ButtonGroup, ButtonGroupWrapper} from "@vds3/buttons";
//import {InputStepper} from "@vds3/input-stepper";
import styled from "styled-components";
import { UnorderedList, ListItem } from "@vds3/lists";
import BroadbandPlan  from "./BroadbandPlan";
import { Badge } from "@vds3/badges";
import "./PlanCard.css";
import { getTaxesCharges, setQuantity,setTriggerTaxesApi,setPlanDisplayNameFlag,setPlanDisplayNameForMbps } from "pages/Landing4G-V2/ducks/4GSlice";
import { Notification } from "@vds3/notifications";
import FWAContext from "../fwaContext";

const PlanCard = ({
  id,
  displayName,
  promos,
  maxOffersPerPlan,
  promoTooltip,
  description,
  price,
  discountPresent,
  previousMonthlyFee,
  priceGuarantee,
  routerIncluded,
  selected,
  isMobile,
  onChange,
  isMultiline,
  flow,
  installationDesc,
  selectedPlan,
  isDisabled = false,
  planType,
  mmw,
  reviewOffers,
  reviewOfferswithoutVisaOffers,
  reviewOfferswithoutVisa,
  setReviewOfferList,
  showVisa,
  receiverIncluded,
  includedDetails,
  starkEnabled,
  bundleOffer,
  limitedOffer,
  isfromAuth,
  fwaBundleSelected = false,
  ...props
}) => {

  const { t } = useTranslation(["4G-5G"]);
  const contHeight = useSelector((state)=>state.ProspectWidgets_GlobalData?.containerHeightBB);
    const aemContent = t("4G-5G", { returnObjects: true })[
    flow === "4g" ? "fourG" : "fiveG"
  ]["plan-content"];

  let offers = [];
  const idAnchored =  (!starkEnabled && flow === "5g" && displayName === aemContent?.anchoredPlan) ? "anchored-elem" : ""
  if (promos?.length) {
    offers = promos.filter(o => new Date().getTime() >= new Date(o?.startDateTime).getTime() &&
      new Date().getTime() <= new Date(o?.endDateTime).getTime());
  } else if (promos) {
    offers = [promos];
  }
    let showReviewText = false;
  if(['10 Mbps'].includes(displayName)||(['100 Mbps'].includes(displayName) && mmw && !starkEnabled)) {
      showReviewText = showVisa
  } else{
    showReviewText = true
  }

  const dispatch =useDispatch();
  const [showStepper, setShowStepper] = useState(selected || false);
  const [planFeatures, setPlanFeatures] = useState(false);
  const [selectedDifferentPlan, setSelectedDifferentPlan] = useState(false);
  const primaryPlanData = useSelector((state) => state.ProspectWidgets_4GPlansData.primaryPlan);
  const { sessionCartData, pageData } = useContext(FWAContext);
  //const [triggerTaxesApi, setTriggerTaxesApi] = useState(true);
  const triggerTaxesApi = useSelector((state) => state.ProspectWidgets_4GPlansData.triggerTaxesApi);

  const [showNotification,setShowNotification] = useState(false);
  //const [quantity, setQuantity] = useState(1);

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onChange(id);
  }

  useEffect(()=>{
    if(selected && flow ==="4g" && !showStepper){
      setShowStepper(selected)
    }
  },[selected])

  const handleReviewOfferDetails = (id)=>{
    props.setReviewOfferdetails(true)
    setReviewOfferList(reviewOffers[id][0])
    if(!showVisa && (['10 Mbps'].includes(displayName) )){
    setReviewOfferList(reviewOfferswithoutVisa[id][0])
    }
    if(!showVisa && (['100 Mbps'].includes(displayName) )){
      setReviewOfferList(reviewOfferswithoutVisaOffers[id][0])
      }
  }

  const onScrollHandler =() => {
    if(!triggerTaxesApi) {
      return;
    }
    const payload = {
      planIds : pageData?.planDetails?.plans,
      address : sessionCartData?.prospect5gLoopQualAddress
    }
    dispatch(getTaxesCharges(payload));
    dispatch(setTriggerTaxesApi(false));
  }

  const DisplayPlanFeatures = () => {
    return (
      <>
      { isMobile && flow != "4g" ? <MarginSpacer2XL/>:<></> }        
     
      <MarginSpacerL/>
      <div style={{ backgroundColor: "#F6F6F6", padding:"16px" }}>
      <Grid rowGutter={false} colGutter={false}>
      <Row>
      <div style={{paddingLeft: "1rem"}}>                       
      <Body bold={true} size="medium" style={{fontSize: "14px"}}>Plan Features</Body>
      </div>
      </Row>
      <MarginSpacerM />
      <Row colSizes={{ mobile: 4, tablet: 4, desktop: 6}} >
      {aemContent?.planDetails?.plans[id]?.planFeatures?.map((device, index) => (
                           <Col colSizes={{ mobile: 12, tablet: 4, desktop: 6 }}>
                          <div style={{display:"inline-flex"}}>
                              <Icon size="medium" name={device?.iconName ? device?.iconName : "tag"} color="#000000" />
                              <span style={{ fontSize: "12px" , paddingRight: ".8rem", marginLeft: flow === "5g" && !mmw && !isMobile ? "12px" : "5px"}}>{device?.content}</span>
                              <MarginSpacerS />
                              </div>
                              </Col>
                      ))}
                      </Row>
      </Grid>
      </div>
    </>
    )
  }
  // const InputStepperAndTextLinkForLte = () => {
  //   return (
  //     <>
  //                   <InputStepperWrapper>
  //                     <InputStepper
  //                           defaultValue={primaryPlanData?.quantity || 1}
  //                           maxValue={9}
  //                           minValue={1}
  //                           surface="light"
  //                           trailingText= {primaryPlanData?.quantity === 1 ? " Plan" : " Plans"}
  //                           width="auto"
  //                           onChange={(val) => {dispatch(setQuantity({
  //                             planType: "primaryPlan",
  //                             value: val,
  //                             isDefault: false
  //                           }))
  //                           setSelectedDifferentPlan(false)
  //                         }
  //                         }
  //                         />
  //                         <span style={{ paddingLeft: isMobile ? "0px" : "1rem", paddingTop: "0.7rem", textAlign: isMobile ? "center" : "left"}}>
  //                         <TextLink
  //                 onClick={() => {
  //                   setPlanFeatures(!planFeatures);
  //                 }}
  //                 size="small"
  //                 type="standAlone"
  //               >
  //                 {planFeatures?'Hide features':'Plan features'}
  //                         </TextLink>
  //                         </span>
  //                   </InputStepperWrapper>      
  //   </>
  //   )
  // }
  const ButtonGroupForLte = () => {
    return (
      <>
      <div style={{width: isMobile ? "100%" : "286px"}}> 
      <ButtonGroupContainer>
      <ButtonGroup
          childWidth={'100%'}
          viewport={isMobile ? 'mobile' : 'desktop'}
          rowQuantity={{ mobile: 1, desktop: 2 }}
          data={[
            {
              disabled: !!selected,
              children: !!selected ? "Selected" : "Select this plan",
              size: 'large',
              use: 'secondary',
              width: isMobile? '100%':'auto',
              surface: 'light',
              onClick: (e) => onClickPlanTile(e),
            },
            {
              children: planFeatures ? 'Hide features' : 'Plan features',
              size: 'small',
              use: 'textLink',
              width: 'auto',
              onClick: () => { setPlanFeatures(!planFeatures) },
              "data-track": 'Plan features'
            }]}
        />
      </ButtonGroupContainer>
      </div>
      </>
    )
  }

  const ButtonGroupForCband = () => {
    return (
      <>
      <div style={{width: "286px",height: "3rem", position: isMobile ? "relative":"", bottom: "0px"}}>
      <ButtonGroupContainer fromCBand={true}>
      <ButtonGroup
          childWidth={'100%'}
          viewport={isMobile ? 'mobile' : 'desktop'}
          rowQuantity={{ mobile: 1, desktop: 2 }}
          data={[
            {
              disabled: !!selected,
              children: !!selected ? "Selected" : "Select this plan",
              size: 'large',
              use: 'secondary',
              width: isMobile? '100%':'auto',
              surface: 'light',
              onClick: (e) => onClickPlanTile(e),
            },
            {
              children: planFeatures ? 'Hide features' : 'Plan features',
              size: 'small',
              use: 'textLink',
              width: 'auto',
              onClick: () => { setPlanFeatures(!planFeatures) },
              "data-track": 'Plan features'
            }]}
        />
        </ButtonGroupContainer>
      </div>
      </>
    )
  }

  const DisplayPlanChangeInfo = () => {
    return (
      <>
      <Grid rowGutter={false} colGutter={false}>
      <Notification
                    type="info"
                    fullBleed={false}
                    inlineTreatment={false}
                    hideCloseButton={false}
                    title={aemContent?.planDetails?.planChangeInfo?.heading?.content}
                    subtitle={aemContent?.planDetails?.planChangeInfo?.subHeading}
                    disableFocus={true}
                    />
      </Grid>  
                    <MarginSpacerL/>
      </>
    )
  }

  const DisplayPlanChangeInfoCband = () => {
    return (
      <>
      <Grid rowGutter={false} colGutter={false}>
      <Notification
                    type="info"
                    fullBleed={false}
                    inlineTreatment={false}
                    hideCloseButton={false}
                    disableFocus={true}
                    title={aemContent?.planDetails?.planChangeInfo}
                    />
      </Grid>  
                    <MarginSpacerL/>
      </>
    )
  }

const onClickPlanTile = (e) => {
  // e.preventDefault();
  // e.stopPropagation();
  if(!selected){
    if(props.hasClicked){
      setShowNotification(true);
    }else{
      props.setHasClicked(true);
    } 
  if (flow === "4g") {
    setShowStepper(true);
    if (
      ["100 Mbps"].includes(displayName) ||
      ["200 Mbps"].includes(displayName) ||
      ["50 Mbps"].includes(displayName)
    ) {
      dispatch(setPlanDisplayNameFlag(true));
    } else {
      dispatch(setPlanDisplayNameFlag(false));
    }
    dispatch(setPlanDisplayNameForMbps(displayName));
    setSelectedDifferentPlan(true);
    e.preventDefault();
    e.stopPropagation();
    onChange(id);
  } else if (flow === "5g" && mmw) {
  } else {
    if((['100 Mbps'].includes(displayName)) || (['200 Mbps'].includes(displayName)) || (['50 Mbps'].includes(displayName))){
      dispatch(setPlanDisplayNameFlag(true));
    }else{
      dispatch(setPlanDisplayNameFlag(false));
    }
    dispatch(setPlanDisplayNameForMbps(displayName));
    setSelectedDifferentPlan(true);
    e.preventDefault();
    e.stopPropagation();
    onChange(id);
  }
}
};
    
  return (
      <>
        <div>
          {
            (promos?.length > 0 && offers?.length > 0) &&
            <PlanPromoFourGFiveG>
            {promos?.length ? <PromoBadge>
              <Grid rowGutter={false} colGutter={false}>
                {offers.map(o =>
                  <CustomRow>
                    <Col colSizes={{ mobile: 1, tablet: 1, desktop: 1 }}>
                      <Icon size="small" name={o?.iconName ? o?.iconName : "tag"} color="white" />
                    </Col>
                    <Col colSizes={{ mobile: 11, tablet: 11, desktop: 11 }}>
                      <StyledText bold={o?.iconName == "tag" || !o?.iconName ? true : false}
                        dangerouslySetInnerHTML={{
                          __html: o?.heading,
                        }}>
                      </StyledText>
                    </Col>
                  </CustomRow>)
                  }
                 <CustomRow>
                {(promos?.length )
                    &&<ReviewOfferLinkWrap>
                        <Link
                          type="standAlone"
                          size="small" 
                          surface="dark"
                          onClick={e => handleReviewOfferDetails(id)}>
                            {t('4G-5G.reviewDetails')}
                        </Link>
                      </ReviewOfferLinkWrap>}
                    </CustomRow>
                  </Grid>
                  
                </PromoBadge> : <></>}
              </PlanPromoFourGFiveG> 
          }
        <PlanCardWrapper   onClick={(e)=>onClickPlanTile(e)}
        // onClick={(e) => {
        //         setSelectedDifferentPlan(true)
        //         setShowStepper(true)
        //         e.preventDefault();
        //         // e.stopPropagation();
        //         onChange(id);
        //       }} 
              contHeight={contHeight} data-loc={`${flow}:${displayName}`} id={idAnchored ? idAnchored : ""}>   
        <StyledBox
          id={id}
          value={id}
          name={id}
          selected={selected}
          isMobile={isMobile}
          disabled={isDisabled}
          selectedPlan={selectedPlan}
          // width={isMobile ? "158px" : "299px"}
          // onChange={onChange}
          children={
            <CardContainer isDisabled={isDisabled}>
              
              <CardContent isMobile={isMobile} showCursor={flow === "4g" ? false : true}>
                <FourGFiveGPlanBoxWrap>
                <Grid>
                  {
                    (discountPresent || fwaBundleSelected) ?
                    <CustomRow>
                      <CustomCol colSizes={{ mobile: 4, tablet: 12, desktop: 6 }}>
                        <Badge fillColor="black">
                            Limited time offer
                        </Badge>
                        <MarginSpacerM/>
                      </CustomCol>
                    </CustomRow>
                    
                     :<></>
                  }

                  {
                    flow === "4g" && id===selectedPlan && selected  && showNotification &&selectedDifferentPlan ?<DisplayPlanChangeInfo/>:<></>
                  }

                  {
                    flow === "5g" && !mmw && id===selectedPlan   && showNotification && selected  &&selectedDifferentPlan ?<DisplayPlanChangeInfoCband/>:<></>
                  }
                  
                  <CustomRow>
                     
                    <CustomCol colSizes={{ mobile: 4, tablet: 12, desktop: 6 }}>
                    <div onClick={!isDisabled && mmw? onClick : () => { }} style={!isMobile ? {marginRight:10} : {}}>
                    <Title size="medium">
                      {displayName} Speed
                    </Title>
                    <MarginSpacerS/>
                    
                    {flow==="4g"&&<div style={{color: "#6F7171",fontSize: ".9rem",paddingLeft: "0.2rem"}}>
                    LTE Business Internet
                    </div>}
                    {flow==="5g"&&!mmw&&<div style={{color: "#6F7171",fontSize: ".9rem",paddingLeft: "0.2rem"}}>
                    5G Business Internet
                    </div>}
                    <MarginSpacerM/>
                    <div style={{ marginTop: "12px" }}>
                      {/* align-items: baseline; */}
                     <PriceAmount discountPresent={discountPresent}>
                        <Body size="small">
                          <Title size="large" bold primitive="span">
                            {`$${Number(price)?.toFixed(0)}`}
                          </Title> 
                        </Body>
                      
                       <div>
                       {mmw === true  ?
                        <div>                          
                          <Body size="small">{t(`4G-5G.${flow === "4g" ? "fourG" : "fiveG"}.plan-content.planDetails.${planType === "backupPlan" ? 'perMonthBackup' : 'perMonthMMW'}`)}</Body>
                          { discountPresent && <Body size="small" color="#6F7171">was  <span style={{textDecoration:'line-through'}}>${Number(previousMonthlyFee).toFixed(2)}</span> /per plan*</Body>}
                          <Body size="small">{t(`4G-5G.${flow === "4g" ? "fourG" : "fiveG"}.plan-content.planDetails.${planType === "backupPlan" ? '' : 'chargesApply'}`)} </Body>                           
                        </div>
                        :  
                        <div>                          
                          <Body size="small">{t(`4G-5G.${flow === "4g" ? "fourG" : "fiveG"}.plan-content.planDetails.${planType === "backupPlan" ? 'perMonthBackup' : 'perMonth'}`)}</Body>
                          { discountPresent && <Body size="small" color="#6F7171">was  <span style={{textDecoration:'line-through'}}>${Number(previousMonthlyFee).toFixed(0)}</span> /per plan*</Body>}
                         <Body size="small">{t(`4G-5G.${flow === "4g" ? "fourG" : "fiveG"}.plan-content.planDetails.${planType === "backupPlan" ? '' : 'taxesAndFees'}`)}</Body>
                        </div>
                       }                     
                        </div> 
                     </PriceAmount>
                        

                        {/* {' '}              
                        <Trans
                          ns="4G-5G"
                          i18nKey={`4G-5G.${flow === "4g" ? "fourG" : "fiveG"}.plan-content.planDetails.${planType === "backupPlan" ? 'perMonthBackup' : 'perMonth'}`}
                          values={{ dagger: "†" }}
                          shouldUnescape={true}
                        >
                          <sup />
                        </Trans>
                        {
                          discountPresent &&
                          <span> - {limitedOffer}</span>
                        }
                          {' '}   */}
                          {/* <Trans
                          ns="4G-5G"
                          i18nKey={`4G-5G.${flow === "4g" ? "fourG" : "fiveG"}.plan-content.planDetails.${planType === "backupPlan" ? 'perMonthBackup' : 'perMonth'}`}
                          values={{ dagger: "†" }}
                          shouldUnescape={true}
                        >
                          <sub >hello</sub>
                        </Trans> */}
                        
                        {/* {' '}        
                        <Trans
                          ns="4G-5G"
                          i18nKey={`${limitedOffer}`}
                          values={{ dagger: "†" }}
                          shouldUnescape={true}
                        >
                          <sub />
                        </Trans> */}
                      {/* </Body> */}

                      <MarginSpacerS />
                      {!starkEnabled && 
                        <>
                        {/* <Body size="small" bold>
                          {planType === "backupPlan" ? t(
                            `4G-5G.${flow === "4g" ? "fourG" : "fiveG"
                            }.plan-content.planDetails.plans.${id}.dataCap`
                          )
                            : priceGuarantee}
                        </Body> */}
                         {/* <Body size="small" bold>
                          {routerIncluded}
                        </Body> */}
                        {/* {
                          fwaBundleSelected && <Body size="small" bold>{bundleOffer}</Body>
                        } */}
                       
                        
                        
                        <MarginSpacerM/>
                        {/* <Body size="small">{installationDesc}</Body> */}
                        {receiverIncluded && <Body size="small">{receiverIncluded}</Body>}
                        </>
                      }
                      {!starkEnabled && includedDetails?.length > 0 && (
                        !mmw?
                      <div className="display-features">
                            {includedDetails?.map((device, index) => (
                              <div style={{display: isMobile ? "flex":"inline-flex",paddingBottom: "10px"}}>
                              <Icon size="medium" name={device?.iconName ? device?.iconName : "tag"} color="#000000" />
                              <span size="Small" style={{fontSize: "12px", font:"Verizon NHG eTX", paddingLeft: "0.2rem"}}>{device?.content}</span>
                              </div>
                            ))}
                      </div>:<div className="included-devices">
                        <Body>
                          <UnorderedList>
                            {includedDetails?.map((device, index) => (
                              <CustomListItem size="bodySmall">{device}</CustomListItem>
                            ))}
                          </UnorderedList>
                        </Body>
                        <MarginSpacerM />
                      </div>
                     )}
                      {starkEnabled && includedDetails?.length > 0 && (
                        <>
                          {
                            fwaBundleSelected && <Body size="small" bold>{bundleOffer}</Body>
                          }
                          <MarginSpacerM/>
                          {flow ==="4g"?
                          <div className="included-devices">
                            <Body>
                              <UnorderedList>
                                {includedDetails?.map((device, index) => (
                                  <CustomListItem size="bodySmall">{device}</CustomListItem>
                                ))}
                              </UnorderedList>
                            </Body>
                            <MarginSpacerM />
                          </div>:<div className="included-devices">
                            <Body>
                              <UnorderedList>
                                {includedDetails?.map((device, index) => (
                                  <CustomListItem size="bodySmall">{device}</CustomListItem>
                                ))}
                              </UnorderedList>
                            </Body>
                            <MarginSpacerM />
                          </div>
          }
                        </>
                     )}
                    </div>
                   { mmw && <MarginSpacerM />}
                    {!starkEnabled && mmw && <Body size="small" color="#000000">
                      {description}
                    </Body>}
                    {starkEnabled && mmw &&
                      <Body size="small" color="#000000">
                      {description?.split('\n')?.map((it, i) => <div key={'x'+i}>{it}</div> ) || ''}
                    </Body>}
                          {isMobile ? <MarginSpacerL />:<MarginSpacerM /> }
                          {flow ==="4g"&& selected && showStepper?<ButtonGroupForLte/>:flow === "4g" ? <ButtonGroupForLte/> : flow === "5g"&& mmw ?<Button
                              size="large"
                              disabled={!!selected}
                              use="secondary"
                              width="100%">
                              {!!selected ? "Selected" : "Select plan"}
                            </Button>:<ButtonGroupForCband/>
                          }
                          {
                            isMobile && flow !="4g"&& <MarginSpacerL />
                          }
                    {
                      isMobile && flow !="4g" && <MarginSpacerL/> && planFeatures && <DisplayPlanFeatures />         
                    }
                    {
                      isMobile && flow =="4g" && planFeatures && <DisplayPlanFeatures />
                    }
                    </div>
                    </CustomCol>

                  <CustomCol colSizes={{ mobile: 4, tablet: 12, desktop: 6 }}>
                    <div style={!isMobile ? {marginLeft:10} : {marginTop:24}}>
                      <BroadbandPlan   planId={id} flow={flow} isfromAuth={isfromAuth} fwaBundleSelected={fwaBundleSelected} onScrollHandler ={onScrollHandler} triggerTaxesApi={triggerTaxesApi}/>
                    </div>
                  </CustomCol>
                  </CustomRow>
                  </Grid>
                  
                </FourGFiveGPlanBoxWrap>
                {
                             !isMobile && planFeatures && <DisplayPlanFeatures />
                          }
              </CardContent>
            </CardContainer>
          }
        />
        </PlanCardWrapper>
      </div > 
      </>
  );
};

const PlanCardWrapper = styled.div`
  & > div > div {
    padding-top:1rem;
    min-height: ${props => props.contHeight}rem;
  }
  @media only screen and (min-width: 545px ) and (max-width: 768px) {
      & > div > div {
        min-height:24rem
      }
  }
  @media only screen and (min-width: 768px) and (max-width: 850px) {
      & > div > div {
        min-height:23.4rem
      }
  }
  @media only screen and (min-width: 850px) and (max-width: 992px) {
    & > div > div {
      min-height:21.56rem
    }
}
`

const FourGFiveGPlanBoxWrap = styled.div`
    * {
    letter-spacing:0px !important;
  }
  [class^=StyledAccordionButton-VDS]{
    padding-right:  0px;
  }
  & > div:first-child {margin-top:0px !important;};


`
const PlanPromoFourGFiveG = styled.div`

   
`


const FourGFiveGPlanbtn = styled.div`
  // position: absolute;
  bottom: 1.5rem;
  left: 1rem;
  right: 1rem;
`;
const CustomListItem = styled(ListItem)`
  padding-top:4px;
`;



const PriceAmount = styled.div`
  display: flex;
  gap: 8px;
`
const ReviewOfferLinkWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top:4px;

  & > a {
    &:hover {border-bottom:1px solid #fff;}
  }
`
const InputStepperWrapper = styled.div`
  display: flex;
  width: "286px";
  margin-top: "10px";
  @media only screen and (max-width: 767px) {
    justify-content: center;
    flex-direction: column;
    width: 100%;
    [class^=InputStepperWrapper-VDS]{
      width: 100%;
    }
  }
`
const Link = styled(TextLink)`
  list-style-type: none;
`
const CustomRow = styled(Row)`
  padding: 0 !important;
`
const CustomCol = styled(Col)`
	padding-left: 4px;
	padding-right: 2px;
  position: relative;
  @media only screen and (max-width: 767px) {
    padding-left: 2px;
  }
`;

const ButtonGroupContainer = styled.div`
  [class^="ButtonGroupWrapper-VDS"]
  [class^="ButtonGroupRow-VDS"] {
    ${({ fromCBand }) => fromCBand && `height: 3rem;`}
  }
  [class^="ButtonWrapper-VDS"] > span {
    display: flex;
    align-self: center;
  }
`;

export default PlanCard;
