export const initialPriceSummary = {
    dueToday: {
        devices: 0,
        accessories: 0,
        deductions: 0
    },
    dueMonthly: {
        devices: 0,
        plans: 0,
        others: 0,
        deductions: 0
    }
}