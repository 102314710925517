
export function reducer(state, action) {
  switch (action.type) {
    case 'CLEAR_ADDRESS_ERROR': 
      return {
        ...state,
        addressError: false, 
        unitError: false 
      }
    case 'SHOW_ERRORS': 
      return {
        ...state,
        showError: action.payload
      }
    case 'SHOW_BIZADDR_ERRORS': 
      return {
        ...state,
        showBizAdrErr: action.payload
      }
    
    case 'SET_BUSINESS_INFO': 
      return {
        ...state,
        businessInfo: action.payload
      }
    
    case 'SET_CONTACT_INFO': 
      return {
        ...state,
        contactInfo: action.payload
      }
    case 'SET_ERROR_MESSAGES': 
      return {
        ...state,
        errorTexts: action.payload
      }
    case 'SET_IS_SAME_ADDRESSS':
      return {
        ...state,
        isSameAddress: action.payload
      }
    case 'SET_HOME_STREET_ADDRESS':
      return {
        ...state,
        homeAdressInfo: action.payload
      }
      case 'SET_HOME_STREET_ADDRESS2':
        return {
          ...state,
          homeAdressInfo2: action.payload
        }  
    default:
      // throw new Error();
      return {
        ...state
      }
  }
}


function clearAddressError() { return { type: 'CLEAR_ADDRESS_ERROR'}}
function showErrors(data) { return { type: 'SHOW_ERRORS', payload: data}}
function showBizAddrErrors(data) { return { type: 'SHOW_BIZADDR_ERRORS', payload: data}}
function setBusinessInformation(data) { return { type: 'SET_BUSINESS_INFO', payload: data}}
function setContactInformation(data) { return { type: 'SET_CONTACT_INFO', payload: data}}
function setErrorMessages(data) { return { type: 'SET_ERROR_MESSAGES', payload: data}}
function setIsSameAddress(data) { return { type: 'SET_IS_SAME_ADDRESSS', payload: data}}
function setHomeAddressInfo(data) { return { type: 'SET_HOME_STREET_ADDRESS', payload: data}}
function setHomeAddress2Info(data) { return { type: 'SET_HOME_STREET_ADDRESS2', payload: data}}


export const actions  = {
  clearAddressError,
  showErrors,
  showBizAddrErrors,
  setContactInformation,
  setErrorMessages,
  setBusinessInformation,
  setIsSameAddress,
  setHomeAddressInfo,
  setHomeAddress2Info
 };



 