import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Carousel } from "@vds3/carousels";
import OffersInfo from "./OffersInfo";
import PDPContext from "../pdpContext";
import TradeInOffer from "./TradeInOffer";
import TradeInValue from "./TradeInValue";
import { isTradeInOfferEligible } from "./util";

const OfferSection = ({ setShowAccordion, ...props }) => {
	const { t } = useTranslation(["pdp"]);
	const {
		product,
		isBogo,
		selectedSku: { selectedSku },
		selectedPayment: { selectedPayment },
		selectedPromo: { selectedPromo, setSelectedPromo },
		tradeInValueInfo: { tradeInValueInfo },
		pack,
	} = React.useContext(PDPContext);
	const bogoPromoTypes =
		t("device-pdp.bogoPromoTypes", { returnObjects: true }) || [];
	const tradeInPromoTypes = ["TRADEIN_PER_MONTH"];
	const tradeInPromoEligible = isTradeInOfferEligible(tradeInValueInfo);

	const skuDetails = useMemo(() => {
		const details = {
			...product?.deviceDetails,
			...selectedSku,
			priceType: selectedPayment === "monthly" ? "MONTH_TO_MONTH" : "RETAIL",
			pricingDetails:
				selectedPayment === "monthly" &&
				selectedSku?.pricingDetails?.devicePayments?.length
					? selectedSku?.pricingDetails?.devicePayments[0]
					: selectedSku?.pricingDetails?.zeroYear,
		};
		delete details.skulist;
		return details;
	}, [product, selectedSku, selectedPayment]);

	useEffect(() => {
		if (!!tradeInValueInfo && typeof setShowAccordion === "function") {
			setShowAccordion(true);
		}
	}, [tradeInValueInfo]);

	const promoCards = useMemo(() => {
		const promoData = skuDetails?.pricingDetails?.promoDetails;
		let promoList = [];
		if (promoData?.length) {
			for (const promo of promoData) {
				if (promo?.promoMessages?.length) {
					const pdpPromo = promo.promoMessages.find((e) => e.placement === "PDP");
					if (pdpPromo) {
						if (
							!tradeInPromoTypes.includes(promo.promoType) ||
							!tradeInValueInfo ||
							tradeInPromoEligible
						) {
							promoList.push({
								...promo,
								promoMessages: pdpPromo,
							});
						}
					}
				}
			}
		}
		if (isBogo) {
			promoList = promoList.filter((i) => bogoPromoTypes.includes(i?.promoType));
		}
		const promos = promoList.map((promo) =>
			promo?.promoType === "TRADEIN_PER_MONTH" ? (
				<TradeInOffer
					key={promo?.promotionId}
					tradeInPromoDetails={promo}
					skuDetails={skuDetails}
					tradeInValueInfo={tradeInValueInfo}
					editPackagePackageId={props.editPackagePackageId}
				/>
			) : (
				<OffersInfo
					key={promo?.promotionId}
					promo={promo}
					isBogo={isBogo}
					isBogoFlag={props?.isBogoFlag}
					brandName={product?.deviceDetails?.brandName}
					editPackagePackageId={props.editPackagePackageId}
				/>
			)
		);
		return promos;
	}, [skuDetails]);

	return (
		<div id="offers">
			<StyledCarouselContainer>
				<div id="carousel-container">
					{!!promoCards?.length && (
						<Carousel
							layout="2UP"
							gutter="12px"
							peek="none"
							paginationDisplay="persistent"
							viewportOverride={{
								mobile: {
									layout: "1UP",
									peek: "standard",
									gutter: "12px",
									paginationInset: "12px",
								},
							}}
							data={promoCards}
							renderItem={(data) => (
								<OfferCard padding="1.5rem" width="292px" height="100%">
									{data}
								</OfferCard>
							)}
						/>
					)}
				</div>
			</StyledCarouselContainer>
			{!!tradeInValueInfo && (
				<TradeInValue
					tradeInValueInfo={tradeInValueInfo}
					skuDetails={skuDetails}
					pack={pack}
				/>
			)}
		</div>
	);
};

const StyledCarouselContainer = styled.div`
	#carousel-container > div > div > div {
		padding-left: 0px;
		padding-bottom: 0px;
	}
	#carousel-container > div > div > div > div {
		padding-left: 0px;
		padding-bottom: 0px;
		width: unset;
		flex: unset;
	}
	#carousel-container > div > div > div > div > div {
		height: 100%;
	}
	#carousel-container > div > div:last-child {
		margin-top: 32px;
	}
	#carousel-container > div > div:first-child {
		margin-top: 0px;
	}
`;

const OfferCard = styled.div`
	border-radius: 8px;
	background: var(--vds-background-secondary-light, #f6f6f6);
	width: ${({ width }) => width ?? "292px"};
	height: ${({ height }) => height ?? "335px"};
	padding: ${({ padding }) => padding ?? "1.5rem"};
`;

export default OfferSection;
