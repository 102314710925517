import React from "react";
import { useTranslation } from "react-i18next";
import {
  LineItem,
  LineItemPriceGrid,
} from "pages/ShoppingCart/components/LineItemPriceGrid/LineItemPriceGrid";
import CartPkgContext from "./cartPkgContext";

const PackageOffers = ({ ...props }) => {
  const { t } = useTranslation(["shoppingCart"]);
  const { offers } = React.useContext(CartPkgContext);
  if (offers?.length || props?.isAmexOfferAvailable) {
    return (
      <>
        <LineItemPriceGrid title={t("shopping-cart.cart-offers.heading")} isOffers={true}>
          {offers.map((offer) => (
            <LineItem
              key={offer?.offerId}
              displayName={offer?.offerName ?? offer?.offerDesc}
            />
          ))}
          {props?.isAmexOfferAvailable &&
            <LineItem            
              displayName={props?.promoAmex}
            />
          }
        </LineItemPriceGrid>
      </>
    );
  } else return false;
};

export default PackageOffers;
