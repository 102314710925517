import React from "react";
import styled from "styled-components";
const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px;
  border: 1px solid #D8DADA;
  border-radius: 50%;
  background-color: #FFFFFF;
`;
const SVGContainer = styled.div`
height: 40px;
width: 40px;
  svg {
    height: 20px;
    width: 20px;
    margin:10px;
  }
  @media only screen and (max-width: 767px) {
    height: 28px !important;
width: 28px !important;
  svg {
    height: 17px!important;
    width: 17px!important;
    margin-left:6px;
    margin-top:6px;
  }
  
`;

function RightCaret() {
  return (
    <Container>
      <SVGContainer>
        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 21.6 21.6">
          <polygon points="6.71 19.8 5.89 18.981 14.07 10.799 5.89 2.619 6.71 1.8 15.71 10.799 6.71 19.8" />
        </svg>
      </SVGContainer>
    </Container>
  );
}

export default RightCaret;