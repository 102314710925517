import React, { useEffect, useReducer, useState } from "react";
import { reducer, actions as Actions } from "./reducer";
import {
	reducer as creditReducer,
	actions as creditActions,
} from "../CreditCheck5g/reducer";
import { useTranslation } from "react-i18next";
import { Notification } from "@vds3/notifications";
import { Micro } from "@vds3/typography";
import { Input } from "@vds3/inputs";
import { Grid, Row, Col } from "@vds3/grids";
import { Button } from "@vds3/buttons";
import "./BusinessInfo.css";
import { isValidInfoEntered, isValidContactInfoFieldHelper } from "./utils";
import InitialState from "./initialState";
import { MarginSpacerXL } from "common/components/styleTag";
import { useDispatch } from "react-redux";
import { setSameAsCompanyAddressNode } from "../ducks/CheckoutSlice";
import { addScrollView } from "utils/commonUtils";
function BusinessInfo(props) {
	const redDisptach = useDispatch();
	const businessLocalState = InitialState;
	const [state, dispatch] = useReducer(reducer, businessLocalState);
	const creditCheckLocalState = localStorage.getItem("creditCheckState")
		? JSON.parse(localStorage.getItem("creditCheckState"))
		: InitialState;
	const [creditState, creditDispatch] = useReducer(
		creditReducer,
		creditCheckLocalState
	);

	const { t } = useTranslation(["app", "checkout"]);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		if (props?.eventHandlers?.onLoad) {
			props?.eventHandlers?.onLoad("retrieveCartInfo");
		}
		addScrollView("businessInfo");
	}, []);

	useEffect(() => {
		const { prospect5gLoopQualAddress: address = null } = props;
		if (
			address !== null &&
			Object.keys(address)?.length &&
			!state?.isSameAddress
		) {
			dispatch(Actions.setIsSameAddress(true));
		}
		dispatch(
			Actions.setContactInformation({
				...state?.contactInfo,
				["email"]:
					state?.contactInfo?.email !== null && state?.contactInfo?.email
						? state?.contactInfo?.email
						: props?.sessionCartData?.businessInfo?.email ??
						  props?.prospect5gLoopQualAddress?.email,
				["lastName"]:
					state?.contactInfo?.lastName !== null && state?.contactInfo?.lastName
						? state?.contactInfo?.lastName
						: props?.prospect5gLoopQualAddress?.lastName,
				["firstName"]:
					state?.contactInfo?.firstName !== null && state?.contactInfo?.firstName
						? state?.contactInfo?.firstName
						: props?.prospect5gLoopQualAddress?.firstName,
			})
		);
	}, [props?.prospect5gLoopQualAddress]);

	useEffect(() => {
		if (props?.setBusinessState) {
			props?.setBusinessState(state);
		}
	}, [state]);
	useEffect(() => {
		if (props?.sessionCartData?.businessInfo) {
			const { firstName, lastName, email, jobTitle } = state?.contactInfo;
			dispatch(
				Actions.setContactInformation({
					...state?.contactInfo,
					["email"]:
						email !== null && email
							? email
							: props?.sessionCartData?.businessInfo?.email,
					["lastName"]:
						lastName !== null && lastName
							? lastName
							: props?.sessionCartData?.businessInfo?.lastName ?? "",

					["firstName"]:
						firstName !== null && firstName
							? firstName
							: props?.sessionCartData?.businessInfo?.firstName ?? "",
					["jobTitle"]:
						jobTitle !== "" && jobTitle
							? jobTitle
							: props?.sessionCartData?.businessInfo?.title ?? "",
				})
			);
		}
	}, [props.sessionCartData]);

	const isValidContactInfo = () => {
		const contactInfo = state?.contactInfo;
		const errorTexts = state?.errorTexts?.contactInfo;
		const keys = Object.keys(contactInfo);

		for (let i = 0; i < keys?.length; i++) {
			isValidContactInfoField(keys[i], contactInfo[keys[i]]);
		}

		return isValidInfoEntered(errorTexts);
	};

	const formatDate = (e) => {
		let string = e ? e.replaceAll("/", "").replaceAll("-", "") : "";
		if (!string?.trim || string?.length == 6) {
			let mon = "0" + string.substr(0, 1);
			let date = "0" + string.substr(1, 1);
			let year = "" + string.substr(2, 4);
			return `${mon}-${date}-${year}`;
		}
		if (!string?.trim || string?.length == 7) {
			let mon = "" + string.substr(0, 2);
			let date = "0" + string.substr(2, 1);
			let year = "" + string.substr(3, 4);
			return `${mon}-${date}-${year}`;
		}
		if (!string?.trim || string?.length == 8) {
			let mon = "" + string.substr(0, 2);
			let date = "" + string.substr(2, 2);
			let year = "" + string.substr(4, 4);
			return `${mon}-${date}-${year}`;
		}
		return e;
	};

	const isValidContactInfoField = (key, val) => {
		dispatch(
			Actions.setErrorMessages(
				isValidContactInfoFieldHelper(key, val, state?.errorTexts, t)
			)
		);
		// if (key === "jobTitle") {
		// 	const PATTERN_STATE = /^[a-zA-Z0-9]+$/.test(val);
		// 	if (PATTERN_STATE) {
		// 		dispatch(
		// 			Actions.setContactInformation({ ...state?.contactInfo, [key]: val })
		// 		);
		// 	} else {
		// 		return;
		// 	}
		// } else {
		dispatch(
			Actions.setContactInformation({ ...state?.contactInfo, [key]: val })
		);
		redDisptach(setSameAsCompanyAddressNode({ key, value: val }));
		setErrors({ ...errors, [key]: state?.errorTexts });
		// }
	};

	const hasErrors = () => {
		let error = false;
		for (const key of Object.keys(errors)) {
			if (errors[key] && errors[key]?.length > 0) {
				error = true;
			}
		}
		if (
			!state?.contactInfo?.firstName ||
			!state?.contactInfo?.lastName ||
			!state?.contactInfo?.email ||
			state?.errorTexts?.contactInfo?.cEmail
		) {
			error = true;
		}
		return error;
	};

	return (
		<div className="businessInfo">
			<div className="container">
				<Grid
					colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
					colGutter={false}
					rowGutter="5"
				>
					<Row>
						<Col>
							<div className="Prod-Discription">
								<Micro size="medium" primitive="h2">
									{t("verification.messages.infomsg1", { ns: "checkout" })}
								</Micro>
							</div>
						</Col>
					</Row>
					<Row>
						<Col colSizes={{ mobile: 4, tablet: 4, desktop: 4 }}>
							<div className="form-col">
								<Input
									label="Authorized signers first name*"
									maxLength={49}
									placeholder=""
									name="firstName"
									width={"100%"}
									value={state?.contactInfo?.firstName}
									onChange={(e) => {
										isValidContactInfoField("firstName", e?.target?.value);
										dispatch(
											Actions.setContactInformation({
												...state?.contactInfo,
												["firstName"]: e?.target?.value,
											})
										);
										redDisptach(
											setSameAsCompanyAddressNode({
												key: "firstName",
												value: e?.target?.value,
											})
										);
									}}
									error={state?.errorTexts?.contactInfo?.fName?.length > 0}
									errorText={
										state?.errorTexts?.contactInfo?.fName ||
										t("verification.errorMsgs.signerNameReq", {
											ns: "checkout",
										})
									}
									tooltipContent={t(
										"verification.labelTexts.authorisedSignerFnameToolTip",
										{
											ns: "checkout",
										}
									)}
								/>
							</div>
						</Col>
						<Col colSizes={{ mobile: 4, tablet: 4, desktop: 4 }}>
							<div className="form-col">
								<Input
									label="Authorized signers last name*"
									maxLength={49}
									placeholder=""
									name="lastName"
									width={"100%"}
									value={state?.contactInfo?.lastName}
									onChange={(e) => {
										isValidContactInfoField("lastName", e?.target?.value);
										dispatch(
											Actions.setContactInformation({
												...state?.contactInfo,
												["lastName"]: e?.target?.value,
											})
										);
										redDisptach(
											setSameAsCompanyAddressNode({
												key: "lastName",
												value: e?.target?.value,
											})
										);
									}}
									error={state?.errorTexts?.contactInfo?.lName?.length > 0}
									errorText={
										state?.errorTexts?.contactInfo?.lName ||
										t("verification.errorMsgs.lastNameReq", {
											ns: "checkout",
										})
									}
								/>
							</div>
						</Col>
						<Col colSizes={{ mobile: 4, tablet: 4, desktop: 4 }}>
							<div className="form-col">
								<Input
									label={t("verification.signerEmailAddress", { ns: "checkout" })}
									placeholder=""
									value={
										businessLocalState?.contactInfo?.email || state?.contactInfo?.email
									}
									onChange={(e) => isValidContactInfoField("email", e?.target?.value)}
									width={"100%"}
									maxLength={50}
									error={state?.errorTexts?.contactInfo?.cEmail?.length > 0}
									errorText={
										state?.errorTexts?.contactInfo?.cEmail ||
										t("verification.errorMsgs.emailReq", { ns: "checkout" })
									}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col colSizes={{ mobile: 4, tablet: 4, desktop: 4 }}>
							<div className="form-col" style={{ marginRight: "1rem" }}>
								<Input
									type="text"
									label={t("verification.signerDobTitle", { ns: "checkout" })}
									maxLength={10}
									dateFormat="mmddyyyy"
									placeholder={"MMDDYYYY"}
									onFocus={(e) => {
										let value = e.target.value;
										let temp = value ? value.replaceAll("/", "").replaceAll("-", "") : "";
										creditDispatch(
											creditActions.setCorporation({
												...creditState?.corporation,
												DateOfBirth: temp || e.target.value,
											})
										);
									}}
									// onBlur={(e) => {
									// 	isValidContactInfoField("DateOfBirth", e);
									// }}
									readOnly={false}
									required={true}
									disabled={false}
									name="DateOfBirth"
									width={"100%"}
									onChange={(e) => {
										let value = e.target.value;
										let temp = value ? value.replaceAll("/", "").replaceAll("-", "") : "";
										if (temp?.length > 8) {
											return null;
										}
										isValidContactInfoField("DateOfBirth", e.target.value);
										const DateOfBirth =
											(temp || e.target.value)?.length === 8
												? formatDate(temp || e.target.value)
												: temp || e.target.value;
										dispatch(
											Actions.setContactInformation({
												...state?.contactInfo,
												["DateOfBirth"]: DateOfBirth,
											})
										);
										localStorage.setItem(
											"creditCheckState",
											JSON.stringify({
												...creditCheckLocalState,
												corporation: {
													...creditCheckLocalState?.corporation?.DateOfBirth,
													DateOfBirth,
												},
											})
										);
									}}
									value={state?.contactInfo?.DateOfBirth}
									error={state?.errorTexts?.contactInfo?.dob?.length > 0}
									errorText={
										state?.errorTexts?.contactInfo?.dob ||
										t("verification.errorMsgs.dobReq", { ns: "checkout" })
									}
									tooltipContent={t("verification.labelTexts.dobToolTip", {
										ns: "checkout",
									})}
								/>
								<div className="helper-text">
									<Micro size="small">
										{t("verification.helperTexts.dob", { ns: "checkout" })}
									</Micro>
								</div>
							</div>
						</Col>
						<Col colSizes={{ mobile: 4, tablet: 4, desktop: 4 }}>
							<div className="form-col mv-vw-mr-bt">
								{/* <MarginSpacerXL /> */}
								<Input
									label={t("verification.labelTexts.authorisedSignerTitle", {
										ns: "checkout",
									})}
									maxLength={49}
									placeholder=""
									width="100%"
									value={state?.contactInfo?.jobTitle}
									onChange={(e) => isValidContactInfoField("jobTitle", e?.target?.value)}
									error={false}
									required={true}
									errorText={t("verification.errorMsgs.titleReq", {
										ns: "checkout",
									})}
								/>
								<div className="helper-text">
									<Micro size="small">
										{t("verification.helperTexts.authorisedSignerTitle", {
											ns: "checkout",
										})}
									</Micro>
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						<Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
							{state?.showError ? (
								<Notification
									onCloseButtonClick={() => {
										Actions.showError(false);
									}}
									type="error"
								>
									{t("verification.enterReqField", {
										ns: "checkout",
									})}
								</Notification>
							) : (
								<></>
							)}
						</Col>
					</Row>
					<Row>
						<Col colSizes={{ mobile: 4, tablet: 3, desktop: 3 }}>
							{!props?.hideContinue && (
								<div className="continue-btn">
									<Button
										onClick={props?.onContinue}
										size="large"
										disabled={hasErrors()}
										data-analyticstrack="Continue:Contact Information"
									>
										{t("verification.helperTexts.continuePlus", { ns: "checkout" })}
									</Button>
								</div>
							)}
						</Col>
					</Row>
				</Grid>
			</div>
		</div>
	);
}

export default BusinessInfo;
