import { Button, ButtonGroup } from "@vds3/buttons";
import { Col, Grid, Row } from "@vds3/grids";
import { Checkbox } from "@vds3/checkboxes";
import { Input } from "@vds3/inputs";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "@vds3/modals";
import { DropdownSelect } from "@vds3/selects";
import { Body, Title } from "@vds3/typography";
import SpinnerComponent from "common/components/Spinner/Spinner";
import { MarginSpacerL, MarginSpacerM, MarginSpacerS, MarginSpacerXL } from "common/components/styleTag";
import { checkAddressValidation, setAddressUpdated, setAddressValidationInfo } from "pages/Checkout/ducks/CheckoutSlice";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getImageLink, getImage } from 'utils/imageUtils';
import MapQStreetAddress from "pages/Checkout/ShippingInfo/components/MapQStreetAdress";

const StyledModal = styled(Modal)`
width:560px;
height:800px;
max-height:100vh;
padding: 48px 0px 0px 42px !important;
.header_wrapper{
  position:relative;
  top:25px;
}
[class^=StyledHeader-VDS]{
  padding-right:0px !important;
}
[class^=Wrapper-VDS]{
  height:100% !important;
}
[class^=Wrapper-VDS]>div{
  height:100% !important;
}
[class^=TriggerIconWrapper-VDS]{
  right:15px;
  @media screen and (min-width:767px){
  top:30px;
  }
}
[class^=TriggerIconSVGWrapper-VDS]{
  width: 0.95rem !important;
  min-width: 0.95rem !important;
}
[class^="StyledHeader-VDS"] {
  padding-right: 1rem !important;
}
`;

const StyledRow = styled(Row)`
  > div {
    padding: 5px;
  }
  padding: 0rem !important;
  input{
    letter-spacing:0;
  }
`;

const CardContainerCustom = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color:#fff;
        max-height: 300px;
        overflow: auto;
        border-radius:8px;
`;
const CardContent = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0px 12px 0px 0px;
`;
const CheckBoxWrapper = styled(Checkbox)`
margin:1rem 1rem;
`;
const ByodImgComponent = () => {
    return (
        <ParentContainer>
            <ChildDiv />
        </ParentContainer>
    )
}
const AddNewAddress = ({ ...props }) => {
    const pageData = useSelector(state => state.ProspectWidgets_CheckoutData);
    const { t } = useTranslation(["shipping", "checkout"]);
    const dispatch = useDispatch();
    const [addressLine1Error, setAddressLine1Error] = useState("");
    const [cityError, setCityError] = useState("");
    const [stateError, setStateError] = useState("");
    const [zipCodeError, setZipCodeError] = useState("");
    const [addressLine1, setAddressLine1] = useState(props?.currentAddressItem?.address?.addressLine1 || "");
    const [addressLine2, setAddressLine2] = useState(props?.currentAddressItem?.address?.addressLine2 || "");
    const [city, setCity] = useState(props?.currentAddressItem?.address?.city || "");
    const [state, setState] = useState(props?.currentAddressItem?.address?.state || "");
    const [zipCode, setZipCode] = useState(props?.currentAddressItem?.address?.zipCode || "");
    const [currentAddressItem, setCurrentAddressItem] = useState(props.currentAddressItem ? JSON.parse(JSON.stringify(props.currentAddressItem)) : {});
    const [addressName, setAddressName] = useState(currentAddressItem?.addressName || "");
    const [address, setAddress] = useState(currentAddressItem?.address || {});
    const addressRef = useRef();
    const [isNewAddress, setIsNewAddress] = useState(true);
    const [unitError, setUnitError] = useState("");
    const [isSubmitted, setIsSibmitted] = useState(false);
    const [isNicknameUpdated, setNicknameUpdated] = useState(false);
    const [isAddressValUpdated, setAddressValUpdated] = useState(false);
    const showMultiShippingRouterModal = t("delivery.showMultiShippingRouterModal", { ns: "shipping", returnObjects: true });
    const packagesAllowedToAnotherAddress = t("checkout.shipping.packagesAllowedToAnotherAddress", { ns: "checkout", returnObjects: true });
    const [addNewAddressModal, setAddNewAddressModal] = useState(false);
    const availableShippingDevices = props?.addressesWithDevices ? JSON.parse(JSON.stringify(props.addressesWithDevices)) : [];
    const filterData = [];

    showMultiShippingRouterModal && availableShippingDevices.forEach(item => {
        if (item.routers) {
            if (props?.currentAddressItem?.isEditAddress) {
                if (props?.currentAddressItem?.id == item.id) {
                    Object.values(item.routers).forEach(routerArray => {
                        routerArray.forEach(router => {
                            if (packagesAllowedToAnotherAddress.includes(router?.packageType)) {
                                router.selected = true;
                                filterData.push(router)
                            }
                        })
                    });
                    Object.values(item.devices).forEach(deviceArray => {
                        deviceArray.forEach(device => {
                            if (packagesAllowedToAnotherAddress.includes(device?.packageType)) {
                                device.selected = true;
                                filterData.push(device)
                            }
                        })
                    });
                }
            }
            else {
                if (item?.isDefaultAddress) {
                    Object.values(item?.routers)?.forEach(routerArray => {
                        routerArray.forEach(router => {
                            router.selected = true;
                            filterData.push(router);
                        })
                    });
                    Object.values(item.devices).forEach(deviceArray => {
                        deviceArray.forEach(device => {
                            if (device?.packageType === "ONETALK") {
                                device.selected = true;
                                filterData.push(device)
                            }
                        })
                    });
                }
            }
        }
    });
    const [availableRouters, setAvailableRouters] = useState(filterData);
    const [selectedRouters, setSeletedRouters] = useState(filterData?.filter(x => x?.selected).length);
    const [selectedQuantity, setSelectedQuantity] = useState(!showMultiShippingRouterModal ? (currentAddressItem?.selectedQuantity || 1) : filterData?.length);
    const [isSelectAllChecked, setIsSelectAllChecked] = useState(true);//!props?.currentAddressItem?.isEditAddress ? true : filterData?.filter(x => x?.selected)?.length == props?.currentAddressItem?.availRoutersCount);
    const [selectedRoutersInfo, setSeletedRoutersInfo] = useState(filterData?.filter(info => info.selected)?.map(data => data?.packageKey));
    const generateNumbersAemContent = t("npanxx.generateNumberNew", { ns: "npa", returnObjects: true });
    const isLoading =
        pageData?.isShoppingCartApiCallInProgress?.checkAddressValidation;
    const unitErrorMessage =
        t("checkout.businessInfo-section.addressConfirmationModal.unitErrorMessage", {
            ns: "checkout"
        }) || '';
    const stateList = t("checkout.businessInfo-section.addresStateList.state", { ns: "checkout", returnObjects: true })
    if (props?.currentAddressItem && JSON.parse(JSON.stringify(props?.currentAddressItem) && (currentAddressItem?.addressName === undefined))) {
        setCurrentAddressItem({ ...currentAddressItem, addressName: "Shipping Address" });
    }

    useEffect(() => {
        if (pageData?.addressValidationInfo?.serviceStatus?.success && isSubmitted) {
            currentAddressItem.addressName = addressName;
            currentAddressItem.selectedQuantity = Number(selectedQuantity);
            currentAddressItem.address = { ...address, addressLine1: addressLine1, addressLine2: addressLine2, city: city, state: state, zipCode: zipCode };
            currentAddressItem.selectedRoutersInfo = showMultiShippingRouterModal ? selectedRoutersInfo : [];
            setCurrentAddressItem(currentAddressItem);
            props.addOrUpdateNewAddress(currentAddressItem);
            if (isAddressValUpdated) {
                dispatch(setAddressUpdated(true));
            }
            if (isNicknameUpdated) {
                if (!isAddressValUpdated) {
                    dispatch(setAddressUpdated(false));
                }
            }
            dispatch(setAddressValidationInfo({}))
        }
        else if (pageData?.addressValidationInfo?.serviceStatus && !pageData?.addressValidationInfo?.serviceStatus?.success) {
            setAddressLine1Error(t("checkout.shipping.errorMsgs.validShipAddress", { ns: "checkout" }));
            dispatch(setAddressValidationInfo({}))
        }
    }, [pageData?.addressValidationInfo?.serviceStatus])


    const handleMoreAddressInput = (value, name) => {
        // dispatch(setAddress(e))
        if (name === "addressName") {
            setAddressName(value);
            setNicknameUpdated(true);
        }
        else if (name === "addressLine2") {
            const PATTERN_ADDRESS = /^[A-Za-z0-9 ]+$/.test(value);
            if (!PATTERN_ADDRESS) {
                setUnitError("only letter allowed")
            } else {
                setUnitError("")
            }
            setAddressLine2(value);
        } else if (name === "city") {
            setCityError("");
            if (!value || value?.length === 0) {
                setCityError("Enter valid city name");
            }
            setCity(value);
        } else if (name === "state") {
            const PATTERN_STATE = /^[a-zA-Z]+$/.test(value);
            setStateError("");
            if (!value || value?.length === 0 || !PATTERN_STATE) {
                setStateError("Select state");
            }
            if (value?.length > 2) {
                return;
            }
            setState(value);
        } else if (name === "zipCode") {
            const PATTERN_ZIPCODE = /^[0-9]{1,5}$/.test(value);
            setZipCodeError("")
            value = value.replace(/[^0-9]/g, "");
            if (!value || value?.length === 0 || !PATTERN_ZIPCODE) {
                setZipCodeError("Enter valid ZIP Code");
            }
            if (value?.length > 9) {
                return;
            }
            setZipCode(value);
        }
        return !cityError && !stateError && !zipCodeError;
    };

    const handleAddressInput = (value) => {
        setAddressValUpdated(true);
        if (!value || value?.length === 0) {
            setAddressLine1("")
            setAddressLine1Error(t("checkout.shipping.errorMsgs.validShipAddress", { ns: "checkout" }));
        }
    }

    const isValidAddress = (value) => {
        const addr = {
            addressLine1: value?.place?.properties?.street,
            addressLine2: "",
            city: value?.place?.properties?.city,
            state: value?.place?.properties?.stateCode,
            zipCode: value?.place?.properties?.postalCode?.substring(0, 5),
        };
        setAddressLine1(addr?.addressLine1);
        setCity(addr?.city);
        setState(addr?.state);
        setZipCode(addr?.zipCode);
        return isAddresAllowed(addr);
    }

    const isAddresAllowed = (addr) => {
        let isAddressAllowed = true;
        let isExistingAddress = false;
        if (props.restrictedAddresses && props.restrictedAddresses.length > 0) {
            for (const a of props.restrictedAddresses) {
                if (a?.addressLine1 === addr?.addressLine1 && a?.zipCode === addr?.zipCode) {
                    isExistingAddress = true;
                }
            }
        }
        setAddressLine1Error("");
        setAddressLine1Error("");
        if (!addr?.addressLine1) {
            setAddressLine1Error(t("checkout.shipping.errorMsgs.validShipAddress", { ns: "checkout" }));
            isAddressAllowed = false;
        }
        else if (addr?.addressLine1?.toUpperCase()?.includes("PO BOX")) {
            setAddressLine1Error(t("checkout.shipping.errorMsgs.poBoxNotAllowed", { ns: "checkout" }));
            isAddressAllowed = false;
        }
        else if (isExistingAddress) {
            setAddressLine1Error(t("checkout.shipping.errorMsgs.existingAddress", { ns: "checkout" }));
            isAddressAllowed = false;
        }
        if (!handleMoreAddressInput(addr?.city, "city")) {
            isAddressAllowed = false;
        }
        if (!handleMoreAddressInput(addr?.state, "state")) {
            isAddressAllowed = false;
        }
        if (!handleMoreAddressInput(addr?.zipCode, "zipCode")) {
            isAddressAllowed = false;
        }
        setAddress({ ...address, ...addr });
        return isAddressAllowed;
    }

    const onAddClicked = () => {
        const addr = {
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            state: state,
            zipCode: zipCode,
        };
        if (isAddresAllowed(addr)) {
            dispatch(checkAddressValidation(addr));
            setIsSibmitted(true);
        }
    }

    const onChangeSelectAll = (e) => {
        const renderArr = availableRouters.map((device) => {
            return {
                ...device,
                selected: e.target.checked,
            }
        });
        setAvailableRouters(
            renderArr
        );
        setIsSelectAllChecked(e.target.checked);
        if (e.target.checked) {
            setSelectedQuantity(renderArr.length);
            setSeletedRoutersInfo(renderArr.map(x => x.packageKey));
        }
        else {
            setSelectedQuantity(0);
            setSeletedRoutersInfo([]);
        }
    };


    const onChangeDeviceSelect = (e, d) => {
        const selectedData = availableRouters.map((device) => {
            if (device?.packageKey === d?.packageKey) {
                return {
                    ...device,
                    selected: e.target.checked,
                };
            }
            return device;
        });
        const selectedCount = selectedData?.filter(device => device?.selected).length;
        setAvailableRouters(selectedData);
        availableRouters?.length == selectedCount ? setIsSelectAllChecked(true) : setIsSelectAllChecked(false);
        setSelectedQuantity(selectedCount);
        setSeletedRoutersInfo(selectedData?.filter(device => device?.selected)?.map(x => x.packageKey));
    }
    return (
        <>
            {isLoading && <SpinnerComponent />}
            <StyledModal
                surface="light"
                fullScreenDialog={false}
                disableAnimation={false}
                ariaLabel="New Address Modal"
                hideCloseButton={true}
                disableOutsideClick={true}
                onOpenedChange={props.setShowNewAddressModal}
                opened={props.showNewAddressModal}
                disableFocusLock={true}
            >
                <ModalTitle>
                    {currentAddressItem.isEditAddress ? t("checkout.shipping.editAddressHeader", { ns: "checkout" }) : t("checkout.shipping.newAddressHeader", { ns: "checkout" })}
                </ModalTitle>
                <MarginSpacerXL />
                <Body size="large">{t("checkout.shipping.routersShipNotice", { ns: "checkout", })}</Body>
                <ModalBody>
                    <Grid colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
                        colGutter={false}
                        rowGutter="11px">
                        <StyledRow>
                            {
                                !addNewAddressModal && showMultiShippingRouterModal ? (
                                    <Col colSizes={{ mobile: 4, tablet: 6, desktop: 12 }}>
                                        <Input
                                            disabled={false}
                                            error={''}
                                            errorText={''}
                                            onChange={() => { }}
                                            label={''}
                                            type="inlineAction"
                                            name="zip"
                                            value={selectedRouters + " devices selected"}
                                            width={"100%"}
                                            inlineActionButtonLabel={"Edit"}
                                            onClickInlineAction={() => {
                                                setAddNewAddressModal(true);
                                            }}
                                        />
                                    </Col>
                                ) : showMultiShippingRouterModal ? (
                                    <Col colSizes={{ mobile: 4, tablet: 6, desktop: 12 }}>
                                        <Body size="medium">
                                            {selectedRouters + " devices selected"}
                                        </Body>
                                    </Col>
                                ) : ""
                            }
                        </StyledRow>
                        <StyledRow>
                            {!addNewAddressModal && <Col colSizes={{ mobile: 4, tablet: 6, desktop: 12 }}>
                                <Body size="small">
                                    * Required fields
                                </Body>
                            </Col>}
                        </StyledRow>
                        <StyledRow>
                            {
                                addNewAddressModal && (
                                    <Col colSizes={{ mobile: 4, tablet: 6, desktop: 12 }}>
                                        {<div className='card-container-custom'>
                                            <CardContainerCustom>
                                                <CardContent>
                                                    <div className="select-all-container">
                                                        <CheckBoxWrapper
                                                            disabled={false}
                                                            error={false}
                                                            errorText=""
                                                            label={"Select All" + ' (' + availableRouters.length + ')'}
                                                            name="select-all"
                                                            onChange={onChangeSelectAll}
                                                            selected={isSelectAllChecked}
                                                            width="auto"
                                                        />
                                                    </div>
                                                </CardContent>
                                            </CardContainerCustom>
                                        </div>}
                                        {availableRouters.length > 0 &&
                                            (
                                                <div className="devices-container">
                                                    {availableRouters.map((device, index) => (
                                                        <div className="devices-checkbox item" data-selected={device?.selected}>
                                                            <CheckBoxWrapper
                                                                error={false}
                                                                errorText=""
                                                                label=""
                                                                name={`checkbox-${index}`}
                                                                onChange={(e) => onChangeDeviceSelect(e, device)}
                                                                selected={device?.selected}
                                                                width="auto"
                                                            >
                                                                <div className="devices-details">
                                                                    {!device?.byod ?
                                                                        <img
                                                                            className="img-wid card-container"
                                                                            src={device?.image ? getImageLink(
                                                                                device?.image,
                                                                                87,
                                                                                90
                                                                            ) : getImage(device?.imageSet,
                                                                                87,
                                                                                90
                                                                            )}
                                                                            alt={device?.packageDesc} /> :
                                                                        !!device?.image ?
                                                                            <img
                                                                                className="img-wid card-container"
                                                                                src={getImageLink(
                                                                                    device?.image,
                                                                                    87,
                                                                                    90
                                                                                )}
                                                                                alt={device?.packageDesc}
                                                                            /> :
                                                                            <ByodImgComponent />}
                                                                    {<div className="devices-info">
                                                                        <Body size="large" primitive="h3" bold>
                                                                            {device?.brand || ''} {device?.productName || ''}
                                                                        </Body>
                                                                        <Body size="small">
                                                                            {device?.plan?.pricePlanDesc}
                                                                        </Body>
                                                                        {device?.byod &&
                                                                            <Body size="large" primitive="h3">
                                                                                {device?.byod ? generateNumbersAemContent?.yourOwnDevicetxt || '' : ''}
                                                                            </Body>}
                                                                        <Body size="large" primitive="h3">
                                                                            {device?.capacity
                                                                                ? `${device?.capacity}, `
                                                                                : ``}{" "}
                                                                            {device?.colorDisplay ? device?.colorDisplay : ''}
                                                                        </Body>
                                                                    </div>}

                                                                </div>
                                                            </CheckBoxWrapper>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                    </Col>)
                            }
                        </StyledRow>
                        <StyledRow>
                            {addNewAddressModal && <Col colSizes={{ mobile: 4, tablet: 6, desktop: 12 }}>
                                <Body size="small">
                                    * Required fields
                                </Body>
                            </Col>}
                        </StyledRow>
                        <StyledRow>
                            <Col colSizes={{ mobile: 4, tablet: 6, desktop: 12 }}>
                                <Input
                                    disabled={false}
                                    maxLength={25}
                                    type="text"
                                    label="Shipping address nickname"
                                    required={false}
                                    value={addressName}
                                    onChange={(e) =>
                                        handleMoreAddressInput(e.target.value, "addressName")
                                    }
                                />
                            </Col>
                        </StyledRow>
                        <StyledRow>
                            <Col colSizes={{ mobile: 4, tablet: 6, desktop: 12 }}>
                                <MapQStreetAddress
                                    errorMessage={addressLine1Error || t("checkout.shipping.errorMsgs.validShipAddress", { ns: "checkout" })}
                                    isError={!!addressLine1Error}
                                    label="Shipping address *"
                                    handleAddressInput={(e) => handleAddressInput(e.target.value)}
                                    isAddressEmty={true}
                                    onSelectionChange={(value) => isValidAddress(value)}
                                    ref={addressRef}
                                    setAddressFieldError={() => { }}
                                    isNameOnly
                                    isMultiShipping={props.isMultiShipping}
                                    isShippingInfo={true}
                                    addressLineOne={addressLine1 || ''}
                                    helperText={t("checkout.businessInfo-section.labelTexts.addressLine1Helper", { ns: "checkout" })}
                                />
                            </Col>
                        </StyledRow>
                        <StyledRow>
                            <Col colSizes={{ mobile: 4, tablet: 6, desktop: 12 }}>
                                <Input
                                    type="text"
                                    name="addressLine2"
                                    label="Suit, room, apt."
                                    placeholder=""
                                    width="100%"
                                    maxLength={20}
                                    value={addressLine2}
                                    disabled={false}
                                    required={false}
                                    onChange={(e) =>
                                        handleMoreAddressInput(e.target.value, "addressLine2")
                                    }
                                    error={unitError?.length > 0}
                                    errorText={unitErrorMessage || ''}
                                />
                            </Col>
                        </StyledRow>
                        <StyledRow>
                            <Col colSizes={{ mobile: 4, tablet: 6, desktop: 12 }}>
                                <Input
                                    type="text"
                                    name="city"
                                    label="City *"
                                    placeholder="Enter City"
                                    disabled={false}
                                    error={!!cityError}
                                    errorText={cityError}
                                    required={true}
                                    value={city}
                                    onChange={(e) => handleMoreAddressInput(e.target.value, "city")}
                                />
                            </Col>
                        </StyledRow>
                        <StyledRow>
                            <Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
                                <DropdownSelect
                                    label="State *"
                                    name="state"
                                    onChange={(e) => handleMoreAddressInput(e.target.value, "state")}
                                    value={state}
                                    required={true}
                                    error={!!stateError}
                                    errorText={stateError}
                                >
                                    {stateList?.map((option) => {
                                        return (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        );
                                    })}
                                </DropdownSelect>
                            </Col>
                            <Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
                                <Input
                                    type="text"
                                    name="zipCode"
                                    label="ZIP Code *"
                                    placeholder="Enter Zip Code"
                                    disabled={!isNewAddress}
                                    error={!!zipCodeError}
                                    errorText={zipCodeError}
                                    required={true}
                                    value={zipCode}
                                    onChange={(e) => handleMoreAddressInput(e.target.value, "zipCode")}
                                />
                            </Col>
                        </StyledRow>
                        <StyledRow>
                            {!showMultiShippingRouterModal && (
                                <Col colSizes={{ mobile: 4, tablet: 9, desktop: 9 }}>
                                    <Body size="medium">
                                        {t("checkout.shipping.routerQuntityMsg", { ns: "checkout" })}
                                    </Body>
                                    <MarginSpacerS />
                                    <div style={{ width: '66%' }}>
                                        <DropdownSelect
                                            label={t("checkout.shipping.quantityLbl", { ns: "checkout" })}
                                            errorText='Select a quantity'
                                            error={false}
                                            disabled={false}
                                            readOnly={false}
                                            inlineLabel={false}
                                            onChange={(e) => { setSelectedQuantity(e?.target?.value) }}
                                            value={selectedQuantity}
                                        >
                                            {[...Array(currentAddressItem?.availRoutersCount || 1)].map((e, i) => {
                                                return <option key={i + 1} value={i + 1}>{i + 1}</option>
                                            })}
                                        </DropdownSelect>
                                    </div>
                                </Col>)}
                        </StyledRow>
                        <StyledRow>
                            <Col colSizes={{ mobile: 4, tablet: 9, desktop: 9 }}>
                                <MarginSpacerM />
                                <ButtonGroup
                                    childWidth={'100%'}
                                    viewport={'desktop'}
                                    rowQuantity={{ desktop: 2 }}
                                    data={[
                                        {
                                            children: currentAddressItem.isEditAddress ? "Save" : "Add address",
                                            size: 'large',
                                            use: 'primary',
                                            width: 'auto',
                                            onClick: () => { onAddClicked(); },
                                        },
                                        {
                                            children: "Cancel",
                                            size: 'large',
                                            use: 'secondary',
                                            width: 'auto',
                                            onClick: () => { props?.setShowNewAddressModal(false); }
                                        },

                                    ]}
                                    alignment={'left'}
                                />
                            </Col>
                        </StyledRow>
                    </Grid>
                </ModalBody>
            </StyledModal>
        </>
    );
}

export default AddNewAddress;