import styled from 'styled-components';
import { spacers } from '@vds-core/theme';
import { Col ,Row} from '@vds3/grids';

export const MarginSpacer = styled.div`
	margin-bottom: ${spacers.medium};
`;

export const MarginSpacerXL = styled.div`
	margin-bottom: ${spacers.extraLarge} !important;
`;
export const MarginSpacerL = styled.div`
	margin-bottom: ${spacers.large} !important;
`;
export const MarginSpacerNoLabel = styled.div`
	margin-bottom: ${spacers.large} !important;
	margin-top: 12px;
`;
export const MarginSpacerS = styled.div`
	margin-bottom: ${spacers.small} !important;
`;
export const MarginSpacerM = styled.div`
	margin-bottom: ${spacers.medium} !important;
`;
export const MarginTopSpacerL = styled.div`
	margin-top: ${spacers.large} !important;
`;

export const CustomCol = styled(Col)`
	padding-left: 0px;
	padding-right: 0.8rem;
`;
export const CustomRow = styled(Row)`
	margin-left: 0px;
	margin-right: 0px;
`;

export const PhonePlanWrapper = styled.div`
	height: 130px;
`;

export const PhoneTextWrapper = styled.div`
	min-height: 100px;
`;

export const ProductContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: ${spacers.medium};
`;

export const SmallTileWrapper = styled.div`
	height: 40px;
`;

export const CheckoutGrid = styled.div`
	margin-top: ${spacers.large} !important;
`;

export const OwnerShipContainer = styled.div`
	background: #f6f6f6;
	padding-top: 20px;
`;

export const Calendarsection = styled.div`
	display: block;
	position: relative;
`;

export const CheckoutContainer = styled.div`
	margin-top: 50px;
	margin-left: 10px;
`;
