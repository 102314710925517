import React from "react";
import { Body } from "@vds3/typography";
import styled from "styled-components";
import { Col, Grid, Row } from "@vds3/grids";
import { Button } from "@vds3/buttons";
import { useTranslation } from "react-i18next";

const ProductContainer = styled.div`
.cat-name{
    margin: 0 0 10px;
    font-size: 1rem;
    font-weight: bold;
}
`;

export const BussProductList = ({
    setSelectedItemId,
    setSelectedCategoryId,
    selectedItemId,
    ...props }) => {
    const { t } = useTranslation(["shoppingCart"]);
    const aemContent = t("shopping-cart", { ns: "shoppingCart", returnObjects: true });

    const handleAddButton = (itemId, catId) => {
        setSelectedItemId(itemId);
        setSelectedCategoryId(catId);
    }

    return (
        <ProductContainer>
            {aemContent?.bussProduct?.category?.map((catItem, i) => {
                return (<div>
                    <p className="cat-name">{catItem.catName}</p>
                    {catItem?.item?.map((item, i) => {
                        return (
                        <Grid rowGutter="1rem" colGutter="1rem">
                        <Row>
                            <Col colSizes={{ mobile: 2, tablet: 8, desktop: 9 }}>
                                <Body size="small" bold={true}>{item.itemName}</Body>
                                <Body size="small" bold={true}>{item.pricePlanPackageDetails.map((pkg, i) => {
                                    return (<Row>
                                        <Col colSizes={{ mobile: 2, tablet: 4, desktop: 4 }}>
                                            <Body size="small">{pkg.frequency}</Body>
                                        </Col>
                                        <Col colSizes={{ mobile: 1, tablet: 4, desktop: 4 }}>
                                            <Body size="small">{pkg.purchasePrice}$</Body>
                                        </Col>
                                    </Row>)
                                })}</Body>
                                <Body size="medium">{item.longDescription}</Body>
                            </Col>
                            <Col colSizes={{ mobile: 1, tablet: 4, desktop: 3 }}>
                                <Button
                                    use="primary"
                                    size="small"
                                    disabled={selectedItemId}
                                    onClick={() => {
                                        handleAddButton(item.itemID, catItem.catID);
                                    }}
                                >
                                    ADD
                                </Button>
                            </Col>
                        </Row>
                        </Grid>
                        );
                    })}
                </div>)
            })}
        </ProductContainer>
    );
};
