// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing5g .header-message {
    padding: 2rem 0rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
 }

 #fivegSetupVideoLink {
   text-decoration: underline;
   cursor: default;
}
.landing5g .sideMargin {
   padding-right: 1rem !important;
   padding-left: 1rem!important;
    /* margin-left: 25px !important; */
    margin-bottom: 1rem !important;
  }
#showDetails{
   margin-bottom: 2px;
 }
 .image-align{
   display: block;
   width: 100%;
   position: relative;
   padding: 15px 0px 0px 0px;  
}
.image-align-desktop{
   margin-top: 85px;
   position: sticky;
   top: 130px;
   bottom: 100px;
   z-index: 99;
}
@media screen and (max-width: 768px) {
   .landing5g .sideMargin {
      margin-left: 0px !important;
   }
}

@media screen and (min-width: 768px) {
   .landing5g .plan-tiles {
      padding-left: 0 !important;
      padding-right: 0 !important;
   }
}

#FWA-5g-plans-selection button:hover{
   background-color: none !important;
}

.accordionButton {
   padding-right: 0px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Landing5G-V2/LandingPage5G.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,SAAS;CACZ;;CAEA;GACE,0BAA0B;GAC1B,eAAe;AAClB;AACA;GACG,8BAA8B;GAC9B,4BAA4B;IAC3B,kCAAkC;IAClC,8BAA8B;EAChC;AACF;GACG,kBAAkB;CACpB;CACA;GACE,cAAc;GACd,WAAW;GACX,kBAAkB;GAClB,yBAAyB;AAC5B;AACA;GACG,gBAAgB;GAChB,gBAAgB;GAChB,UAAU;GACV,aAAa;GACb,WAAW;AACd;AACA;GACG;MACG,2BAA2B;GAC9B;AACH;;AAEA;GACG;MACG,0BAA0B;MAC1B,2BAA2B;GAC9B;AACH;;AAEA;GACG,iCAAiC;AACpC;;AAEA;GACG,kBAAkB;AACrB","sourcesContent":[".landing5g .header-message {\n    padding: 2rem 0rem;\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n }\n\n #fivegSetupVideoLink {\n   text-decoration: underline;\n   cursor: default;\n}\n.landing5g .sideMargin {\n   padding-right: 1rem !important;\n   padding-left: 1rem!important;\n    /* margin-left: 25px !important; */\n    margin-bottom: 1rem !important;\n  }\n#showDetails{\n   margin-bottom: 2px;\n }\n .image-align{\n   display: block;\n   width: 100%;\n   position: relative;\n   padding: 15px 0px 0px 0px;  \n}\n.image-align-desktop{\n   margin-top: 85px;\n   position: sticky;\n   top: 130px;\n   bottom: 100px;\n   z-index: 99;\n}\n@media screen and (max-width: 768px) {\n   .landing5g .sideMargin {\n      margin-left: 0px !important;\n   }\n}\n\n@media screen and (min-width: 768px) {\n   .landing5g .plan-tiles {\n      padding-left: 0 !important;\n      padding-right: 0 !important;\n   }\n}\n\n#FWA-5g-plans-selection button:hover{\n   background-color: none !important;\n}\n\n.accordionButton {\n   padding-right: 0px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
