import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSafetechSessionId } from "../ducks/CheckoutSlice";

export const DeviceDataCollector = (props) => {
	const dispatch = useDispatch();
	const [deviceDataCollecting, setDeviceDataCollecting] = useState(false);
	const pageData = useSelector((state) => state.ProspectWidgets_CheckoutData);
	const sessionId = pageData?.safetechSessionId;

	const SAFETECH_URL = process.env.PROSPECT_WIDGETS_APP_SAFETECH_URL;
	const DEVICE_DATA_COLLECTOR_SCRIPT_ID = "mybiz-device-data-collector-script";
	const CUSTOM_EVENT_NAME = "mybiz-safetech-device-data-collector-event";

	const setupDeviceDataCollector = () => {
		if (ka) {
			const client = new ka.ClientSDK();
			client.setupCallback({
				"collect-end": (params) => {
					const { MercSessId } = params;
					dispatch(setSafetechSessionId(MercSessId));
					stopCollectionDeviceData();
				},
				"collect-begin": ({ MercSessId }) => {
					setDeviceDataCollecting(true);
					dispatch(setSafetechSessionId(MercSessId ?? ""));
				},
			});
			client.autoLoadEvents();
		} else {
			throw Error(
				"The SafeTech Kount Device Data Collector SDK is not loaded in global scope."
			);
		}
	};

	const startCollectingDeviceData = () => {
		if (!deviceDataCollecting) {
			if (!ka) {
				throw Error("The SafeTech Kount Device Data Collector SDK is not loaded.");
			}
			if (document) {
				const event = new CustomEvent(CUSTOM_EVENT_NAME, {
					bubbles: true,
					cancelable: true,
				});
				document
					.querySelectorAll("[class='kaxsdc']")
					?.forEach((v) => v.dispatchEvent(event));
			}
		}
	};

	const stopCollectionDeviceData = () => {
		if (deviceDataCollecting) {
			setDeviceDataCollecting(false);
		}
	};

	useEffect(() => {
		// inject script on load
		if (!sessionId && !document.getElementById(DEVICE_DATA_COLLECTOR_SCRIPT_ID)) {
			const script = document.createElement("script");
			script.src = SAFETECH_URL;
			script.setAttribute("defer", "true");
			script.setAttribute("id", DEVICE_DATA_COLLECTOR_SCRIPT_ID);
			document.body.appendChild(script);
			script.onload = () => {
				setupDeviceDataCollector();
				startCollectingDeviceData();
			};
		}

		// remove on unload:
		return () => {
			stopCollectionDeviceData();
			const tag = document.getElementById(DEVICE_DATA_COLLECTOR_SCRIPT_ID);
			if (tag?.parentElement) {
				tag.parentElement.removeChild(tag);
			}
		};
	}, []);

	return <div className="kaxsdc" data-event={CUSTOM_EVENT_NAME}></div>;
};
