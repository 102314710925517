import React from "react";
import { TextLinkCaret } from "@vds3/buttons";
import { TitleLockup } from "@vds3/type-lockups";
import { PromoCardContainer } from "./styles";

const PromoCard = ({ title, subTitle, linkText, linkUrl, ...props }) => {
  return (
    <PromoCardContainer>
      <TitleLockup
        data={{
          title: {
            size: "titleMedium",
            children: title
          },
          subtitle: {
            size: "bodySmall",
            children: subTitle
          }
        }}
      />
      <TextLinkCaret
        iconPosition="right"
        onClick={() => { window.location.href = linkUrl }}
      >
        {linkText}
      </TextLinkCaret>
    </PromoCardContainer>
  );
}

export default PromoCard;
