import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { Carousel } from "@vds3/carousels";
import { Grid, Row, Col } from "@vds3/grids";
import { Body, Title } from "@vds3/typography";
import {
	MarginSpacerL,
	MarginSpacerM,
	MarginSpacerXL,
} from "common/components/styleTag";
import "./features.css";

const getImageUrl = (imgUrl) => {
	return "https://ss7.vzw.com/" + imgUrl;
};

const getDesc = (text) => {
	return <p dangerouslySetInnerHTML={{ __html: text }}></p>;
};

const Features = ({
	deviceDetails,
	isOneTalk,
	isAccessoryDevice,
	...props
}) => {
	const { t } = useTranslation(["accessories"]);

	const isMobile = useMediaQuery({
		query: "(max-width: 545px)",
	});

	const carousel = () => {
		return deviceDetails?.features?.map(
			(e) => (
				// return {
				//   title: {
				//     size: "titleLarge",
				//     primitive: "div",
				//     children: (
				<CarouselItem key={e.sequenceNumber}>
					<ImageContainer>
						<img src={getImageUrl(e.imageUrl)} />
					</ImageContainer>
					<MarginSpacerL />
					<TextWrapper>
						<Title size="medium">
							<h6
								dangerouslySetInnerHTML={{
									__html: e?.displayName,
								}}
							/>
						</Title>
						<Body size="large" color="#6F7171">
							<DeviceTextContainer
								dangerouslySetInnerHTML={{
									__html: e?.desc,
								}}
							/>
						</Body>
					</TextWrapper>
				</CarouselItem>
			)
			//     ),
			//   },
			//   backgroundColor: "gray",
			// };
		);
	};

	const getCarouselData = () => {
		return deviceDetails?.features?.map((element) => {
			return {
				title: {
					text: element.displayName,
					width: "250px",
				},
				onClick: () => {
					getSlideTitle(element);
				},
				subtitle: {
					text: getDesc(element.desc),
					width: "250px",
				},
				CTAGroup: {
					alignment: "left",
					data: [
						{
							children: "",
							secondary: true,
							ctaType: "standAlone",
							width: "300px",
							inverted: false,
						},
					],
				},
				image: {
					url: getImageUrl(element.imageUrl),
				},
				ariaLabel: element.displayName,
			};
		});
	};

	const getFeatureText = (element) => {
		return (
			<>
				<p
					className="feature-name"
					dangerouslySetInnerHTML={{
						__html: element?.displayName,
					}}
				/>
				<p
					className="feature-desc"
					dangerouslySetInnerHTML={{
						__html: element?.desc,
					}}
				/>
			</>
		);
	};

	const getFeatureImage = (element) => {
		return (
			<img
				className="img-responsive u-paddingLeftExtraLarge u-marginLeftExtraLarge"
				src={getImageUrl(element?.imageUrl)}
			/>
		);
	};

	return (
		<div ref={props.scrollRef}>
			{isAccessoryDevice ? (
				<>
					<MarginSpacerXL />
					<Grid rowGutter="0.5rem" className="no-margin" colGutter={false}>
						<RowNoPad>
							<ColNoPad colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
								<Title bold size="medium">
									{t("accessory.pdp.accessoryFeature", { ns: "accessories" })}{" "}
								</Title>
								{/* <Line type="light" inverted={false} /> */}
								<AccessoryListContainer
									className="featuresList"
									dangerouslySetInnerHTML={{ __html: deviceDetails?.features }}
								/>
							</ColNoPad>
							<ColPad colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
								{/* <div className="boxCol"> */}
								<Title bold size="medium">
									{t("accessory.pdp.whatsinbox", { ns: "accessories" })}{" "}
								</Title>
								<AccessoryListContainer
									className="featuresList"
									dangerouslySetInnerHTML={{ __html: deviceDetails?.inTheBox }}
								/>
								{/* </div> */}
							</ColPad>
						</RowNoPad>
					</Grid>
				</>
			) : (
				<div className="feature-carousel-container">
					<div className="u-paddingTopLarge">
						<div className="corousel-div">
							<Carousel
								layout={"3UP"}
								// aspectRatio={!isMobile ? "2.2:3" : "none"}
								gutter="24px"
								peek="none"
								surface="light"
								paginationFill="light"
								paginationInset="8px"
								paginationDisplay="onHover"
								viewportOverride={{
									mobile: {
										layout: "1UP",
									},
								}}
								data={carousel()}
								renderItem={(props) => (
									<CarouselCardContainer>{props}</CarouselCardContainer>
								)}
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const RowNoPad = styled(Row)`
	> div {
		padding-left: 0;
		padding-right: 0 !important;
	}
`;
const ColNoPad = styled(Col)`
	padding-right: 0;
	padding-left: 0;
`;
const ColPad = styled(Col)`
	padding-left: 1.5rem !important;
`;
const CarouselItem = styled.div`
	overflow-y: auto;
	height: 31.5rem;
	// min-height: 31.5rem;
	// max-height: 31.5rem;
	// max-width: 23vw;
	h6 {
		font-weight: 400;
		font-size: 20px;
		line-height: 24px;
		width: 100%;
		margin-top: 1px;
	}
	p {
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;
		letter-spacing: 0.5px;
		margin-top: -35px;
	}
	@media (max-width: 545px) {
		overflow-y: auto;
		height: 31.5rem;
		// // min-height: unset;
		// max-height: unset;
		// // max-width: 72vw;
		// overflow-y: unset;
	}
`;
const ImageContainer = styled.div`
	height: 294px;
	width: 294px;
	max-height: 100%;
	max-width: 100%;
	margin: auto;
	display: flex;
	img {
		max-width: 100%;
		max-height: 296px;
		margin: auto;
		display: block;
	}
	@media only screen and (min-width: 430px) and (max-width: 912px) {
		height: 196px;
		width: 196px;
	}
	@media only screen and (min-width: 360px) and (max-width: 430px) {
		height: 275px;
		width: 275px;
	}
	@media only screen and (max-width: 359px) {
		height: 210px;
		width: 210px;
	}
`;
const TextWrapper = styled.div`
	@media only screen and (max-width: 430px) {
		width: 275px;
		padding-right: 0.5rem;
	}
`;
const DeviceTextContainer = styled.p`
	max-width: 26vw;
	sup {
		font-size: 0.6rem;
		vertical-align: super;
		line-height: 0;
		// vertical-align: top;
		// position: relative;
		// top: -0.3rem;
	}
	@media (max-width: 545px) {
		max-width: 72vw;
	}
`;
const AccessoryListContainer = styled.div`
	margin-top: 2rem;
	@media (max-width: 545px) {
		margin-top: 1.5rem;
	}
`;
const CarouselCardContainer = styled.div`
	border-radius: 8px;
	background: var(--vds-background-secondary-light, #f6f6f6);
	padding: 1.5rem;
	> div {
		padding-right: 0.5rem;
	}
`;

export default Features;
