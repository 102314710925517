import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
//import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { addToCart } from "../../../pages/Mobility/ducks/MobilitySlice";
import { Title } from "@vds3/typography";
import { Modal, ModalTitle } from "@vds3/modals";
import { Button } from "@vds3/buttons";
import { MarginSpacerM } from "../../../common/components/styleTag";
import { setLocalCookie } from "utils/commonUtils";
import {
	setSuspendFlow,
	setSuspendScenario,
} from "../../../pages/Checkout/ducks/CheckoutSlice";

const NewCustomerModal = ({
	setIsAddToCartClickedModal,
	setIsFirstDeviceClicked,
	...props
}) => {
	const dispatch = useDispatch();
	//const navigate = useNavigate();
	const pageData = props?.pageData;
	const { t } = useTranslation(["app"]);

	const newCustomerClicked = () => {
		let dataObj = props?.selectedDeviceDetails;
		let priceType = dataObj[0]?.priceType;
		let quantity = dataObj[0]?.quantity;
		let selectedSku = dataObj[0]?.selectedSku;
		let selectedOffers = dataObj[0]?.selectedOffers;
		if (setIsAddToCartClickedModal) {
			setIsAddToCartClickedModal(true);
		}
		if (
			(props?.pageData?.categoryName == "smart-watches" ||
				props.pageData.productDetail.data.deviceDetails.category ==
					"Smart Watches" ||
				props.pageData.productDetail.data.deviceDetails.category ==
					"Connected Smartwatches") &&
			selectedSku[0]?.numberShareEligibilityFlag &&
			selectedSku[0]?.numberShareEligibilityFlag == "Y"
		) {
			dispatch(setSuspendScenario("SUSPENDED_FLOW_MTN_SHARE_Y"));
			dispatch(setSuspendFlow(true));
			//navigate("/shop/contact-us", { state: { refUrl: window.location.pathname } });
		}
		const tradeInItem =
			props?.tradeInValueInfo?.tradeInItems?.length &&
			props?.tradeInValueInfo?.tradeInItems[0];

		const payload = {
			cartMeta: {
				zipCode: pageData.zipCode,
				email: pageData?.email,
				shoppingPath: "prospect",
			},
			lines: [
				{
					packageId: "",
					packageType: props.isOneTalk ? "ONETALK" : "REGULAR",
					operation: "ADD",
					tradeIn:
						tradeInItem &&
						tradeInItem?.recycleDeviceOfferType &&
						tradeInItem?.recycleDeviceOfferType?.buyItemCode === selectedSku[0]?.sku
							? {
									deviceId: tradeInItem?.deviceId,
							  }
							: {},
					device: {
						deviceSku: selectedSku[0]?.sku,
						deviceId: pageData?.productDetail?.data?.deviceDetails?.deviceId,
						deviceCategory:
							pageData?.productDetail?.data?.deviceDetails?.deviceCategory ||
							pageData?.productDetail?.data?.deviceDetails?.category,
						quantity: quantity,
						priceType:
							priceType === "monthly" &&
							selectedSku[0]?.pricingDetails?.devicePayments?.[0]?.nofInstallements
								? "MONTH_TO_MONTH"
								: priceType === "twoyears"
								? "TWO_YEAR"
								: "RETAIL",
						installmentTerm:
							priceType === "monthly" &&
							selectedSku[0]?.pricingDetails?.devicePayments?.[0]?.nofInstallements
								? selectedSku[0]?.pricingDetails?.devicePayments?.[0]?.nofInstallements
								: 0,
						seoUrlName: selectedSku.seoUrlName,
						simType: getSimType(selectedSku[0]) === "esim" ? "E" : "P",
						deviceType: "SMARTPHONE",
					},
					selectedOffers,
				},
			],
			addendaCode: props?.cmpValue ?? "",
		};
		setLocalCookie("SMB_PROSPECT_CARTQTY", quantity, 1);
		if (setIsFirstDeviceClicked) {
			setIsFirstDeviceClicked(false);
		}
		dispatch(addToCart(payload));
	};

	const existingCustomerClicked = () => {
		let deviceId = pageData?.productDetail?.data?.deviceDetails?.deviceId;
		let dataObj = props?.selectedDeviceDetails;
		let skuId = dataObj[0]?.selectedSku[0]?.sku;
		let redirectUrl = `${t("app.common.deepLinkUrls.pdp", { ns: "app" })}`;
		if (deviceId && skuId && redirectUrl) {
			let finalUrl =
				redirectUrl +
				encodeURIComponent(deviceId) +
				"/" +
				encodeURIComponent(skuId);
			window.location.href = finalUrl;
		} else {
			redirectUrl = `${t("app.common.deepLinkUrls.byod", { ns: "app" })}`;
			window.location.href = redirectUrl;
		}
	};

	const getSimType = (selectedSku) => {
		let defaultSimType = selectedSku?.defaultSimType;
		let defaultSku = selectedSku?.supportedSims?.filter(
			(sim) => !!sim?.defaultSim
		);
		if (defaultSku?.length && !selectedSku?.simType?.length) {
			if (defaultSku[0]?.simSku === selectedSku?.psimSku) {
				defaultSimType = "psim";
			} else if (defaultSku[0]?.simSku === selectedSku?.esimSku) {
				defaultSimType = "esim";
			}
		} else if (selectedSku?.simType?.length) {
			defaultSimType = selectedSku?.simType;
		}
		return defaultSimType;
	};

	return (
		<NCModal
			ariaLabel=""
			opened={props?.isFirstDeviceClicked}
			onOpenedChange={props?.closeNCModal}
			disableOutsideClick={true}
		>
			<ModalTitle>
				<Title size="medium">
					<ModalTitleHeading>
						{t("gridwall-new-existing-modal.heading.new-existing-customer", {
							ns: "app",
						})}
					</ModalTitleHeading>
				</Title>
				<MarginSpacerM></MarginSpacerM>
			</ModalTitle>
			<ButtonContainer>
				<Button
					ClassName="customerButton"
					use="secondary"
					onClick={() => {
						newCustomerClicked(props?.priceType, props?.quantity, props?.selectedSku);
					}}
				>
					{t("gridwall-new-existing-modal.labels.new-customer", { ns: "app" })}
				</Button>
				<Button
					className="customerButton"
					use="secondary"
					onClick={() => {
						existingCustomerClicked();
					}}
				>
					{t("gridwall-new-existing-modal.labels.existing-customer", {
						ns: "app",
					})}
				</Button>
			</ButtonContainer>
		</NCModal>
	);
};

const ModalTitleHeading = styled.div`
	font-weight: 700;
	font-size: 32px;
	line-height: 36px;
	flex: none;
	order: 1;
	align-self: stretch;
	flex-grow: 0;
	@media only screen and (max-width: 767px) {
		font-size: 24px;
	}
`;
const NCModal = styled(Modal)`
	// height: auto;
	// width: 550px;
	// min-height: auto;
	// outline: none !important;
	// overflow: hidden;
	// > div:first-child {
	//   overflow: hidden;
	// }
	// @media only screen and (max-width:1030px) {
	//   width:60%
	// }

	// @media only screen and (max-width: 767px) {
	//   position: absolute;
	//   top: 50%;
	//   left: 50%;
	//   transform: translate(-50%, -50%);
	//   width: 95vw;
	//   > div:first-child {
	//     padding: 2px;
	//   }
	// }

	// @media only screen and (max-width: 545px) {
	//   bottom: 0px;
	//   position: fixed;
	//   top: 0px;
	//   left: 0px;
	//   transform:translate(0px,0px);
	// }
`;

const ButtonContainer = styled.div`
	display: flex;
	margin-top: 25px;
	margin-bottom: 2px;
	column-gap: 10px;
	height: 44px;
	span {
		margin-left: 15px !important;
		margin-right: 15px !important;
	}
	@media screen and (max-width: 545px) {
		position: absolute;
		bottom: 6rem;
		width: 95%;
		padding: 0px;
		margin-left: 0;
		flex-direction: column;
		row-gap: 15px;
		span {
			margin: 0px !important;
		}
	}
	.customerButton {
		padding: 0 !important;
	}
`;

export default NewCustomerModal;
