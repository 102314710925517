import { useState, useMemo } from "react";

const useMemoizedState = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const useValue = useMemo(() => {
    return [ value, setValue ];
  }, [value]);

  return useValue;
}

export default useMemoizedState;
