import React, { useEffect, useReducer, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { SignerState, reducer, actions as Actions } from "./containers/AuthSignerReducer";
import { Notification } from "@vds3/notifications";
import { Title, Body, Micro } from "@vds3/typography";
import { Input } from "@vds/inputs";
import { Grid, Row, Col } from "@vds3/grids";
import { TextLink,Button } from "@vds3/buttons";
import "./AuthorisedSigner.css";
import styled from 'styled-components'
import { Checkbox } from "@vds3/checkboxes";
import MapQStreetAddress from "./containers/MapQStreetAdress";
import { useDispatch, useSelector } from "react-redux";
import { checkAddressValidation, setAddressValidationInfo, setAuthSignerInfo, setAuthSignerInfoTemp } from "pages/Checkout/ducks/CheckoutSlice";
import VerticalProgressContext from "common/components/VerticalProgress/verticalProgressContext";
import { MarginSpacerL, MarginSpacerM, MarginSpacerS, MarginSpacerXS } from "common/components/styleTag";
import { DropdownSelect } from "@vds3/selects";
import { useMediaQuery } from 'react-responsive'
import { setCreditPath, setSSNInfo } from "pages/Checkout/ducks/CheckoutSlice";
import AddressVerificationModal from "./AddressVerificationModal";
import { checkIfAemExists, phoneNumberFormat } from "utils/commonUtils";
import {Icon} from '@vds3/icons';
import { RadioButton } from "@vds3/radio-buttons";

function ServiceInformation(props) {
  const {
    isComplete,
    onComplete,
    setModifier,
    isEditMode: { isEditMode, setIsEditMode },
  } = React.useContext(VerticalProgressContext);
  const pageData = useSelector((state) => state.ProspectWidgets_CheckoutData);
  const businessStreetRef = useRef();
  const [businessInfoState, businessDispatch] = useReducer(reducer,
    pageData?.authSignerInfo && Object.keys(pageData?.authSignerInfo)?.length === 0 ? SignerState : pageData?.authSignerInfo
  );
  const { t } = useTranslation(["app", "checkout", "mock-response"]);
  const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
  const sessionCartData =
      globalData?.sessionCartData?.apiResponse?.prospectFlowData;
  const isOrderQuoteFlow = globalData.isOrderQuoteFlow;
  const isDigitalQuote = sessionCartData?.cart?.digitalQuote;
  const isAbandonedCart = sessionCartData?.cart?.abandonedCart;
  const [iscompleteclicked, setIsCompleteClicked] = useState(false);
  const [fNameErrors, setFNameErrors] = useState("");
  const [lNameErrors, setLNameErrors] = useState("");
  const [emailErrors1, setEmailErrors1] = useState("");
  const [emailErrors2, setEmailErrors2] = useState("");
  const [phoneErrors1, setPhoneErrors1] = useState("");
  const [phoneErrors2, setPhoneErrors2] = useState("");
  const [signerAddress, setSignerAddress] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [errors, setErrors] = useState({});
  const [streetErrors, setStreetErrors] = useState({});
  const [stateErrors, setStateErrors] = useState({});
  const [zipCodeErrors, setZipCodeErrors] = useState({});
  const [cityErrors, setCityErrors] = useState({});
  const [dobErrors, setDOBErrors] = useState({});
  const [ssnErrors, setSSNErrors] = useState({});
  const [cityValue, setCityValue] = useState("");
  const [unitValue, setUnitValue] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [zipValue, setZipValue] = useState("");
  const [streetValue, setStreetValue] = useState("");
  const [showContinueBtn, setShowContinueBtn] = useState(false);
  const [showErrNotification, setShowErrNotification] = useState(false);
  const [ssnLabel, setSSNLabel] = useState(true);
  const [checkBoxFlag, setCheckBoxFlag] = useState(false);
  const isTaxIdOptional = globalData?.cmp?.isFromGoogleShop;
  const aboutBusinessInfor = pageData?.aboutBusinessInfor;
  const authorisedSignerState = pageData?.authSignerInfo;
  const [fromAuthorisedSigner, setfromAuthorisedSigner] = useState(false);
  const [unitError,setUnitError] = useState("");
  const [showDefaultContent,setShowDefaultContent] = useState(false)
  const [selectedAddress,setSelectedAddress] = useState(0)
  const [selectedServiceAddress,setSelectedServiceAddress] = useState(0)
  const [serviceAddressSectionClicked,setServiceAddressSectionClicked] = useState(false)
  const [addAddress,setAddAddress] = useState({
    firstName: "",
    lastName: "",
    businessName: "",
    address: "",
    street:"",
    city: "",
    zipCode: "",
    state: "",
    phoneNumber1:"",
    phoneNumber2:"",
    email1: "",
    email2: "",
  })
  const [errorsSection,setErrorsSection] = useState({
      fName: '',
      lName: '',
      cPhoneNumber: '',
      cEmail1: '',
      cEmail2:'',
      cAddress: '',
      cState: '',
      cZip: '',
      cCity: '',
      cBusinessName: '',
      cPhone1:'',
      cPhone2:''
    })
  const [showAddAlternatePhoneNumberSection,setShowAddAlternatePhoneNumberSection] = useState(false)
  const [showAddAlternateEmailSection,setShowAddAlternateEmailSection] = useState(false)
  const unitErrorMessage =
        t("checkout.businessInfo-section.addressConfirmationModal.unitErrorMessage", {
            ns: "checkout"
        }) || '';
  const isLoading =
    pageData?.isShoppingCartApiCallInProgress?.checkAddressValidation;
  const dispatch1 = useDispatch();
  const addressConfirmaionContent = t("checkout.businessInfo-section.addressConfirmationModal", {
    ns: "checkout",
    returnObjects: true,
  });
  const businessInfoAEM = t("checkout.businessInfo-section", { ns: "checkout", returnObjects: true });
  const stateList = businessInfoAEM.addresStateList.state || [];
  // const stateList = [" ", "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"];
  const [showAddressModal, setShowAddressModal] = useState(false);
  const useMock = checkIfAemExists(t("validate-address-mock", { ns: "mock-response" }));
  const isMobile = useMediaQuery({
    query: "(max-width: 525px)",
  });
  //JSON.parse(sessionStorage.getItem("authSignerInfoTemp"));
  useEffect(() => {
    const element = document.getElementById("signer-info");
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  useEffect(() => {
    if (Object.keys(pageData?.authSignerInfo || {}).length === 0 && Object.keys(pageData?.authSignerInfoTemp || {}).length) {
      if(pageData?.authSignerInfoTemp?.hasOwnProperty("contactInfo")) {businessDispatch(Actions.setContactInformation(pageData?.authSignerInfoTemp?.contactInfo));}
      if(pageData?.authSignerInfoTemp?.hasOwnProperty("errorTexts")) { businessDispatch(Actions.setErrorMessages(pageData?.authSignerInfoTemp?.errorTexts))}
      if(pageData?.authSignerInfoTemp?.homeAdressInfo) {handleAddressSelection(pageData?.authSignerInfoTemp?.homeAdressInfo);}
      if(pageData?.authSignerInfoTemp?.hasOwnProperty("corporation")) {businessDispatch(Actions.setCorporation(pageData?.authSignerInfoTemp?.corporation));}
      if(pageData?.authSignerInfoTemp?.hasOwnProperty("isSameAddress")) {businessDispatch(Actions.setIsSameAddress(pageData?.authSignerInfoTemp?.isSameAddress))}
      setCheckBoxFlag(false);
    }
    if(Object.keys(pageData?.authSignerInfo || {}).length){
      window.sessionStorage.removeItem("authSignerInfoTemp");
    }
   
    if(globalData?.widgetInputParams?.mbaUserEmail?.value){
      businessDispatch(
        Actions.setContactInformation({ ...authorisedSignerState?.contactInfo, ["email"]: globalData?.widgetInputParams?.mbaUserEmail?.value})
      );
      businessDispatch(
        Actions.setContactInformation({ ...businessInfoState?.contactInfo, ["email"]: globalData?.widgetInputParams?.mbaUserEmail?.value })
      );
      }
    return () => {
      if (Object.keys(pageData?.authSignerInfo || {}).length === 0) {
      //  window?.sessionStorage?.getItem("authSignerInfoTemp") && dispatch1(setAuthSignerInfoTemp(JSON?.parse(window.sessionStorage.getItem("authSignerInfoTemp"))));
      }
    };
  }, []);

  useEffect(() =>{
    window.sessionStorage.setItem("authSignerInfoTemp", JSON.stringify(businessInfoState));
  },[businessInfoState])

  // useEffect(() => {
  //   if (props?.eventHandlers?.onLoad) {
  //     props?.eventHandlers?.onLoad("retrieveCartInfo");
  //   }
  // }, []);
  // useEffect(() => {
  //   if (props?.sessionCartData?.pdpEmail) {
  //     businessDispatch(
  //       Actions.setContactInformation({ ...businessInfoState?.contactInfo, ["email"]: props?.sessionCartData?.pdpEmail})
  //     );
  //   }
  // }, [props?.sessionCartData?.pdpEmail]);
  // useEffect(() => {
  //   const { prospect5gLoopQualAddress: address = null } = props;
  //   if (
  //     address !== null &&
  //     Object.keys(address)?.length &&
  //     !businessInfoState?.isSameAddress
  //   ) {
  //     businessDispatch(Actions.setIsSameAddress(true));
  //   }
  //   if(props?.prospect5gLoopQualAddress?.email){
  //     businessDispatch(
  //       Actions.setContactInformation({ ...businessInfoState?.contactInfo, ["email"]: props?.prospect5gLoopQualAddress?.email })
  //     );
  //   }
  // }, [props?.prospect5gLoopQualAddress]);
  // useEffect(() => {
  //   if (props?.setBusinessState) {
  //     props?.setBusinessState(businessInfoState);
  //   }
  // }, [businessInfoState]);

  useEffect(() => {
    if (!isEditMode && pageData?.authSignerInfo && Object.keys(pageData?.authSignerInfo)?.length) {
      businessDispatch(
        Actions.setContactInformation({ ...authorisedSignerState?.contactInfo, ["firstName"]: authorisedSignerState?.contactInfo?.firstName })
      );
      businessDispatch(
        Actions.setContactInformation({ ...authorisedSignerState?.contactInfo, ["lastName"]: authorisedSignerState?.contactInfo?.lastName })
      );
      businessDispatch(
        Actions.setContactInformation({ ...authorisedSignerState?.contactInfo, ["jobTitle"]: authorisedSignerState?.contactInfo?.jobTitle })
      );
      businessDispatch(
        Actions.setContactInformation({ ...authorisedSignerState?.contactInfo, ["email"]: authorisedSignerState?.contactInfo?.email })
      );
      businessDispatch(Actions.setCorporation({ ...authorisedSignerState?.corporation, ["DateOfBirth"]: authorisedSignerState?.corporation?.DateOfBirth }));
      businessDispatch(Actions.setHomeAddressInfo(authorisedSignerState?.homeAdressInfo));
      businessDispatch(Actions.setIsSameAddress(authorisedSignerState?.isSameAddress))
    } else {
      setShowAddressModal(false);
      // dispatch1(setAddressValidationInfo(null));
     // SiteAnalytics.dynamicComponentRendered({ order: 2, sectiontName: "Business Verification",subSection:"Authorized signer verification",isEditMode:false });
    }
  }, [isEditMode])

  const formatDate = (e) => {
    let string = e ? e.replaceAll("/", "").replaceAll("-", "") : "";
    if (!string?.trim || string?.length == 6) {
      let mon = "0" + (string.substr(0, 1));
      let date = "0" + string.substr(1, 1);
      let year = "" + string.substr(2, 4);
      return `${mon}/${date}/${year}`;
    }
    if (!string?.trim || string?.length == 7) {
      let mon = "" + (string.substr(0, 2));
      let date = "0" + string.substr(2, 1);
      let year = "" + string.substr(3, 4);
      return `${mon}/${date}/${year}`;
    }
    if (!string?.trim || string?.length == 8) {
      let mon = "" + (string.substr(0, 2));
      let date = "" + string.substr(2, 2);
      let year = "" + string.substr(4, 4);
      return `${mon}/${date}/${year}`;
    }
    return e;
  };

  const findAge = (val) => {
    var today = new Date();
    var birthDate = new Date(val);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const isValidContactInfoField = (key, val) => {
    const  errorTexts = businessInfoState?.errorTexts;
    const  contactInfo  = errorTexts?.contactInfo;
    let value = val;
    if (key === "firstName") {
      value = val ? val?.replace(/[^a-zA-Z ]/g, "") : "";
      let errors = "";
      if (!value?.trim()) {
        errors = t('checkout.businessInfo-section.errorMsgs.signerNameReq', { ns: 'checkout' });
      } else {
        const pattern = /^[a-zA-Z-\s]+$/;
        if (!pattern.test(value)) {
          errors = t('checkout.businessInfo-section.errorMsgs.signerNameReq', { ns: 'checkout' });
        } else {
          errors = "";
        }
      }
      // businessDispatch(
      //   Actions.setErrorMessages({
      //     ...errorTexts,
      //     contactInfo: { ...contactInfo, ["fName"]: errors },
      //   }))
      setErrorsSection({...errorsSection,["fName"]: errors})
      setFNameErrors(errors);
      setAddAddress({...addAddress,[key]:value})
      // businessDispatch(
      //   Actions.setContactInformation({ ...businessInfoState?.contactInfo, [key]: value })
      // );
      if (errors?.length) return true;
      else return false;
    }


    // lastName
    if (key === "lastName") {
      value = val ? val?.replace(/[^a-zA-Z ]/g, "") : "";
      let errors = "";
      if (!value?.trim()) {
        errors = t('checkout.businessInfo-section.errorMsgs.lastNameReq', { ns: 'checkout' });
      } else {
        const pattern = /^[a-zA-Z-\s]+$/;
        if (!pattern.test(value)) {
          errors = t('checkout.businessInfo-section.errorMsgs.lastNameReq', { ns: 'checkout' });
        } else {
          errors = "";
        }
      }
      // businessDispatch(
      //   Actions.setErrorMessages({
      //     ...errorTexts,
      //     contactInfo: { ...contactInfo, ["lName"]: errors },
      //   }))
      setErrorsSection({...errorsSection,["lName"]: errors})
      setLNameErrors(errors);
      setAddAddress({...addAddress,[key]:value})
      // businessDispatch(
      //   Actions.setContactInformation({ ...businessInfoState?.contactInfo, [key]: value })
      // );
      if (errors?.length) return true;
      else return false;
    }

    if (key === "email1" || key === "email2") {
      let errors = ""
      if (!value?.trim()) {
        errors = t('checkout.businessInfo-section.errorMsgs.validEmail', { ns: 'checkout' });
      } else {
        const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const minChar = value?.split("@")[0];
        if (!pattern.test(value) || !(minChar?.trim()?.length > 1)) {
          errors = t('checkout.businessInfo-section.errorMsgs.validEmail', { ns: 'checkout' });
        } else {
          errors = '';
        }
      }
      setErrorsSection({...errorsSection,[key === "email1" ? "cEmail1" : "cEmail2"]: errors})
      setAddAddress({...addAddress,[key]:value})
      // businessDispatch(
      //   Actions.setErrorMessages({
      //     ...errorTexts,
      //     contactInfo: { ...contactInfo, ["cEmail"]: errors },
      //   }))
      if(key === "email1") setEmailErrors1(errors);
      else setEmailErrors2(errors)
      value = value?.replaceAll(' ', '');
      // businessDispatch(
      //   Actions.setContactInformation({ ...businessInfoState?.contactInfo, [key]: value })
      // );
      if (errors?.length) return true;
      else return false;
    }
    if (key === "phoneNumber1" || key === "phoneNumber2") {
      let errors = ""
      if (!value?.trim()) {
        errors = t('checkout.businessInfo-section.errorMsgs.validPhone', { ns: 'checkout' });
      } else {
       if(value.length < 10){
        errors = t('checkout.businessInfo-section.errorMsgs.validPhone', { ns: 'checkout' });
       }
      }
      setErrorsSection({...errorsSection,[key === "phoneNumber1" ? "cPhone1" :"cPhone2"]: errors})
      setAddAddress({...addAddress,[key]:value})
      // businessDispatch(
      //   Actions.setErrorMessages({
      //     ...errorTexts,
      //     contactInfo: { ...contactInfo, ["cEmail"]: errors },
      //   }))
      if(key === "phoneNumber1") setPhoneErrors1(errors);
      else setPhoneErrors2(errors)
      value = value?.replaceAll(' ', '');
      // businessDispatch(
      //   Actions.setContactInformation({ ...businessInfoState?.contactInfo, [key]: value })
      // );
      if (errors?.length) return true;
      else return false;
    }

    if (key === "jobTitle") {
      // const PATTERN_STATE = /^[a-zA-Z0-9]+$/.test(val);
      var PATTERN_STATE = /[^0-9A-Za-z,\s]+$/gi;
      value = value?.replace(PATTERN_STATE, "");
      if (value === '') {
        businessDispatch(
          Actions.setContactInformation({ ...businessInfoState?.contactInfo, [key]: value })
        );
      } else {
        businessDispatch(
          Actions.setContactInformation({ ...businessInfoState?.contactInfo, [key]: value })
        );
      }
    }

  };

  const isValidCorporationInfoField = (key, e) => {
    let val = "";
    let errors = "";
    const { errorTexts } = businessInfoState;
    const { corporation } = errorTexts;
    //dob
    if (key === "DateOfBirth") {
      let temp = e ? e.replaceAll("/", "").replaceAll("-", "") : "";
      if (!e.trim || e.length === 0) {
        errors = t("checkout.businessInfo-section.errorMsgs.invalidDate", { ns: "checkout" });
      }
      /* check if length of formated date lenth is less than 6  */
      else if (temp.length < 8) {
        val = e;
        errors = t("checkout.businessInfo-section.errorMsgs.invalidDate", {
          ns: "checkout",
        });
      }
      else {
        val = formatDate(e);
        let inDate = val?.replaceAll("/", "").substr(2, 2);
        let age = findAge(val)
        let isFutureDate = (new Date(val?.replace(/-/g, "/"))) > new Date()
        /*  future date error */
        if (isFutureDate) {
          errors = t("checkout.businessInfo-section.errorMsgs.futureDate", {
            ns: "checkout",
          });
        }
        /* Invalide date error  */
        else if (new Date(val?.replace(/-/g, "/"))?.toString() === "Invalid Date") {
          errors = t("checkout.businessInfo-section.errorMsgs.invalidDate", {
            ns: "checkout",
          });
        }
        /* Invalide number of days error  */
        else if (new Date(val?.replace(/-/g, "/"))?.getDate() !== parseInt(inDate)) {
          errors = t("checkout.businessInfo-section.errorMsgs.invalidDate", {
            ns: "checkout",
          });
        }

        /*  check for min age */
        else if (!isFutureDate && age < 18) {
          errors = t("checkout.businessInfo-section.errorMsgs.minAge", {
            ns: "checkout",
          });
        }
        /*Max age check */
        else if (!isFutureDate && age >= 100) {
          errors = t("checkout.businessInfo-section.errorMsgs.maxAge", {
            ns: "checkout",
          });
        }
      }
      setDOBErrors(errors)
    }

    if (key === "SSN") {
      val = e;
      val = e
        .replace(/[^0-9]/g, "")
        .replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
      errors =
        isTaxIdOptional && (!val || !val?.trim().length)
          ? t("checkout.businessInfo-section.errorMsgs.validSSN", { ns: "checkout" })
          : "";
      errors +=
        isTaxIdOptional && val && val?.length != 11
          ? t("checkout.businessInfo-section.errorMsgs.validSSN", { ns: "checkout" })
          : "";
      setSSNErrors(errors);
    }

    businessDispatch(Actions.setCorporation({ ...businessInfoState?.corporation, [key]: val }));
    businessDispatch(
      Actions.setErrorMessages({
        ...errorTexts,
        corporation: { ...corporation, [key]: errors },
      })
    );
  }

  const validateUnitFiled = (val) =>{
    const PATTERN_ADDRESS = /^[A-Za-z0-9 ]+$/.test(val);
    if(!PATTERN_ADDRESS){
      setUnitError("only letter allowed")
    }else{
      setUnitError("")
    }
    businessDispatch(Actions.setHomeAddress2Info(val))
  }

  const checkAllFeilds = () => {
    let fName = isValidContactInfoField("firstName", businessInfoState?.contactInfo?.firstName ?? " ")
    let lName = isValidContactInfoField("lastName", businessInfoState?.contactInfo?.lastName ?? " ")
    let email = isValidContactInfoField("email", businessInfoState?.contactInfo?.email ?? " ")
    let street = handleAddressInput(streetValue ?? " ", "street")
    let city = handleAddressInput(cityValue ?? " ", "city")
    let state = handleAddressInput(stateValue ?? " ", "state")
    let zipCode = handleAddressInput(zipValue ?? " ", "zipCode")
    let dob = isValidCorporationInfoField("DateOfBirth", businessInfoState?.corporation?.DateOfBirth ?? " ")
    let ssn = isValidCorporationInfoField("SSN", businessInfoState?.corporation?.SSN ?? " ")

    return [fName, lName, email, street, city, state, zipCode, dob, ssn]
  }
  const hasErrors = () => {
    let checkAll = checkAllFeilds()
    let error = false;
    for (let temp of checkAll) {
      if (temp) {
        error = true;
      }
    }
    if (!businessInfoState?.contactInfo?.firstName
      || !businessInfoState?.contactInfo?.lastName
      || !businessInfoState?.contactInfo?.email
      || !businessInfoState?.corporation?.DateOfBirth
      || (isTaxIdOptional && !businessInfoState?.corporation?.SSN)
      || businessInfoState?.errorTexts?.contactInfo?.fName
      || businessInfoState?.errorTexts?.contactInfo?.lName
      || businessInfoState?.errorTexts?.contactInfo?.cEmail
      || businessInfoState?.errorTexts?.corporation?.DateOfBirth
      || (isTaxIdOptional && businessInfoState?.errorTexts?.corporation?.SSN)
      || (!businessInfoState?.isSameAddress && !businessInfoState?.homeAdressInfo)
      || (!cityValue || !stateValue?.length || (stateValue?.length != 2) ||
        !zipValue?.length || (zipValue?.length != 5))
    ) {
      error = true;
    }
    if (!error) {
      validateBusinessAddress();
      setfromAuthorisedSigner(true);

      if (isTaxIdOptional) {
        dispatch1(setSSNInfo(businessInfoState?.corporation?.SSN))
        dispatch1(setCreditPath(businessInfoState?.corporation?.SSN ? "BLENDED" : "BizOnlyCredit"))
      }
    } else {
      let element = document.getElementById('authSigner');
      let elementPosition = element?.offsetTop;
      window.scrollTo({
        top: elementPosition,
        behavior: "smooth"
      })
      setfromAuthorisedSigner(false);
    }
    onComplete()
    // return error;
  }

  // useEffect(() => {
  //   if (props?.eventHandlers?.onLoad) {
  //     props?.eventHandlers?.onLoad("retrieveCartInfo");
  //   }
  // }, []);
  // useEffect(() => {
  //   const  businessInfo  = props?.data?.businessInfo;
  //   const { contactInfo } = props?.businessInfoState;
  //   signerDispatch(
  //     Actions.setContactInformation({
  //       zipCode: businessInfo?.zip,
  //       city: businessInfo?.muni,
  //       state: businessInfo?.state,
  //       address: businessInfo?.dispstr,
  //       firstName: contactInfo?.firstName,
  //       email: (contactInfo?.email || props?.prospect5gLoopQualAddress?.email),
  //       lastName: contactInfo?.lastName,
  //     })
  //   );
  // }, [props?.data?.contactInfo, props?.data?.businessInfo, props?.businessInfoState]);

  // useEffect(() => {
  //   const { prospect5gLoopQualAddress: address = null } = props;
  //   if (
  //     address !== null &&
  //     Object.keys(address)?.length &&
  //     !businessInfoState?.isSameAddress
  //   ) {
  //     signerDispatch(Actions.setIsSameAddress(true));
  //   }
  // }, [props?.prospect5gLoopQualAddress]);

  // useEffect(() => {
  //   if (props?.setSignerAddState) {
  //     props?.setSignerAddState(state);
  //   }
  // }, [state]);

  useEffect(() => {
    if (businessInfoState?.isSameAddress) {
      setCityValue(
        aboutBusinessInfor?.businessStreetAddress?.place?.properties?.city
      );
      setPropertyType(
        aboutBusinessInfor?.businessStreetAddress?.place?.properties
          ?.propertyType
      );
      setStateValue(
        aboutBusinessInfor?.businessStreetAddress?.place?.properties?.stateCode
      );
      setZipValue(
        aboutBusinessInfor?.businessStreetAddress?.place?.properties
          ?.postalCode
      );
      setUnitValue(aboutBusinessInfor?.companyAddressUnit)
      businessDispatch(Actions.setHomeAddress2Info(aboutBusinessInfor?.companyAddressUnit))
    }
  }, [aboutBusinessInfor?.businessStreetAddress?.place?.properties, aboutBusinessInfor?.companyAddressUnit]);

  useEffect(() => {
    if (businessInfoState?.homeAdressInfo) {
      setStreetValue(businessInfoState?.homeAdressInfo?.place?.properties?.street)
      setCityValue(businessInfoState?.homeAdressInfo?.place?.properties?.city);
      setPropertyType(businessInfoState?.homeAdressInfo?.place?.properties?.propertyType)
      setStateValue(businessInfoState?.homeAdressInfo?.place?.properties?.stateCode);
      setZipValue(businessInfoState?.homeAdressInfo?.place?.properties?.postalCode);
    }
  }, [businessInfoState?.homeAdressInfo?.place?.properties]);


  useEffect(() => {
    let temp = aboutBusinessInfor?.businessStreetAddress;
    if (
      businessInfoState?.isSameAddress &&
      aboutBusinessInfor?.businessStreetAddress?.place?.properties?.city
    ) {
      handleAddressSelection(temp, true);
    } else if (!businessInfoState?.isSameAddress && checkBoxFlag) {
      setStreetValue(null);
      setSignerAddress(null);
      handleAddressSelection(null, false, false);
    }
  }, [businessInfoState?.isSameAddress]);

  const handleAddressInput = (e, name) => {
    const value = String(e).trimStart();
    const properties = businessInfoState?.homeAdressInfo?.place?.properties;
    let objValue = {
      place: {
        properties: {
          country: properties?.country,
          countryCode: properties?.country,
          state: properties?.state,
          stateCode: properties?.stateCode,
          street: properties?.street,
          city: properties?.city,
          postalCode: properties?.postalCode,
          propertyType: properties?.propertyType,
        },
      },
      displayString: businessInfoState?.homeAdressInfo?.displayString,
      name: businessInfoState?.homeAdressInfo?.name,
    };

    if (name === "street") {
      objValue.place.properties.street = value
      // ? value
      // : properties?.street;
      objValue.place.properties.city = cityValue?.length
        ? cityValue
        : properties?.city;
      objValue.place.properties.stateCode = stateValue?.length
        ? stateValue
        : properties?.stateCode;
      objValue.place.properties.postalCode = zipValue?.length
        ? zipValue
        : properties?.postalCode;
      objValue.place.properties.propertyType = propertyType?.length
        ? propertyType
        : properties?.propertyType;
      setStreetValue(value);

      if (!value) {
        setStreetErrors({
          ...streetErrors,
          homeAdressInfoErr:  t("checkout.businessInfo-section.errorMsgs.homeAddLine1Req", {
            ns: "checkout",
          }),
        });
      }
      else {
        setStreetErrors({
          ...streetErrors,
          homeAdressInfoErr: null,
        });
      }

    }
    else if (name === "city") {
      objValue.place.properties.street = streetValue?.length
        ? streetValue
        : properties?.street;
      objValue.place.properties.city = value;
      // ? value : properties?.city;
      objValue.place.properties.stateCode = stateValue?.length
        ? stateValue
        : properties?.stateCode;
      objValue.place.properties.postalCode = zipValue?.length
        ? zipValue
        : properties?.postalCode;
      objValue.place.properties.propertyType = propertyType?.length
        ? propertyType
        : properties?.propertyType;
      setCityValue(value);
      const PATTERN_STATE = /^[a-zA-Z\s]+$/.test(value);

      if (!value) {
        setCityErrors({
          ...cityErrors,
          city: t("checkout.businessInfo-section.errorMsgs.validCityReq", {
            ns: "checkout",
          }),
        });
      } else if (!PATTERN_STATE) {
        setCityErrors({
          ...cityErrors,
          city: t("checkout.businessInfo-section.errorMsgs.validCityReq", {
            ns: "checkout",
          }),
        });
      } else {
        setCityErrors({
          ...cityErrors,
          city: null,
        });
      }
    } else if (name === "state") {
      objValue.place.properties.street = streetValue?.length
        ? streetValue
        : properties?.street;
      objValue.place.properties.city = cityValue?.length
        ? cityValue
        : properties?.city;
      objValue.place.properties.stateCode = value
      objValue.place.properties.state = value
      objValue.place.properties.postalCode = zipValue?.length
        ? zipValue
        : properties?.postalCode;
      objValue.place.properties.propertyType = propertyType?.length
        ? propertyType
        : properties?.propertyType;
      const PATTERN_STATE = /^[a-zA-Z]+$/.test(value);
      if (value?.length > 2) {
        return;
      }
      setStateValue(value);
      if (!value) {
        setStateErrors({
          ...stateErrors,
          state:  t("checkout.businessInfo-section.errorMsgs.selectState", {
            ns: "checkout",
          }),
        });
      } else if (!PATTERN_STATE) {
        setStateErrors({
          ...stateErrors,
          state:  t("checkout.businessInfo-section.errorMsgs.selectState", {
            ns: "checkout",
          }),
        });
      } else {
        setStateErrors({
          ...stateErrors,
          state: null,
        });
      }
    } else if (name === "zipCode") {
      const PATTERN_ZIPCODE = /[^0-9]+$/;
      let valZip = value?.replace(PATTERN_ZIPCODE, "");
      objValue.place.properties.street = streetValue?.length
        ? streetValue
        : properties?.street;
      objValue.place.properties.city = cityValue?.length
        ? cityValue
        : properties?.city;
      objValue.place.properties.stateCode = stateValue?.length
        ? stateValue
        : properties?.stateCode;
      objValue.place.properties.postalCode = valZip
      // ? value
      // : properties?.postalCode;
      objValue.place.properties.propertyType = propertyType?.length
        ? propertyType
        : properties?.propertyType;

      // const PATTERN_ZIPCODE = /^\d{5}(-\d{4})?$/.test(value);
      if (valZip?.length > 9) {
        return;
      }
      setZipValue(valZip);
      if (!valZip) {
        setZipCodeErrors({
          ...zipCodeErrors,
          zipCode: t("checkout.businessInfo-section.errorMsgs.validZipcodeReq", {
            ns: "checkout",
          }),
        });
      }
      else if (valZip && valZip?.length !==5) {
        setZipCodeErrors({
          ...zipCodeErrors,
          zipCode: t("checkout.businessInfo-section.errorMsgs.validZipcodeReq", {
            ns: "checkout",
          }),
        });
      } 
      else {
        setZipCodeErrors({
          ...zipCodeErrors,
          zipCode: null,
        });
      }
    }
    // signerDispatch({
    //   type: CONSTANTS.SET_BUSINESS_STREET_ADDRESS,
    //   payload: objValue,
    // });
    businessDispatch(Actions.setHomeAddressInfo(objValue));
    setHomeAddress(objValue);
    // if(props?.isSignerAddComplete){
    //   props?.setDisableAcceptContinue(true);
    //   setShowContinueBtn(true);
    //   props?.eventHandlers?.emit(
    //     "disableContinueBtn"
    //   );
    // } 
  };

  const handleAddressSelection = (value, isDispatch = true, isError = true) => {
    if (!value && isError) {
      setErrors({
        ...errors,
        city: t("checkout.businessInfo-section.errorMsgs.validCityReq", {
          ns: "checkout",
        }),
        state: t("checkout.businessInfo-section.errorMsgs.selectState", {
          ns: "checkout",
        }),
        zipCode: t("checkout.businessInfo-section.errorMsgs.validZipcodeReq", {
          ns: "checkout",
        }),
      });
    } else {
      setErrors({
        ...errors,
        city: null,
        state: null,
        zipCode: null,
      });
    }

    const properties = value?.place?.properties;
    if (
      properties?.street &&
      properties?.street?.toUpperCase()?.includes("PO BOX")
    ) {
      setErrors({
        ...errors,
        homeAdressInfoErr:
          t("checkout.businessInfo-section.errorMsgs.poNotAllowed", {
            ns: "checkout",
          }),
      });
    }
    if(properties){
    setAddAddress({...addAddress,
      address:properties.addressLine1 ?? properties?.street,
      city:properties?.city,
      state:properties?.stateCode,
      zipCode:properties?.postalCode
    })
    }
    setCityValue(properties ? properties?.city : "");
    setPropertyType(properties ? properties?.propertyType : "");
    setStateValue(properties ? properties?.stateCode : "");
    setZipValue(properties ? properties?.postalCode : "");
    if (businessInfoState?.isSameAddress) {
      setUnitValue(aboutBusinessInfor?.companyAddressUnit)
      businessDispatch(Actions.setHomeAddress2Info(aboutBusinessInfor?.companyAddressUnit))
    } else {
      setUnitValue("")
      businessDispatch(Actions.setHomeAddress2Info(""))
    }
    if(properties?.stateCode && properties?.stateCode?.length)   {setStateErrors( {...stateErrors, state : null})}
    if(properties?.postalCode && properties?.postalCode?.length)  {setZipCodeErrors( {...zipCodeErrors, zipCode : null})}
    if(properties?.city && properties?.city?.length) {setCityErrors( {...cityErrors, city : null})}
   
    businessDispatch(Actions.setHomeAddressInfo(value));
    setHomeAddress(value);
  };
  const validateBusinessAddress = (address = null, unit = null) => {
    // handleContinueClicked(true)
    let payload;
    const businessSaStreetAddressObj = aboutBusinessInfor?.businessStreetAddress
    if (address) {
      payload = {
        addressLine1: address?.addressLine1,
        addressLine2: address?.addressLine2,
        city: address?.city,
        state: address?.state,
        zipCode: address?.zipCode,
        useMock: useMock
      };
    }
    else if (businessInfoState?.isSameAddress && businessSaStreetAddressObj) {
      const properties = businessSaStreetAddressObj?.place?.properties;
      payload = {
        addressLine1: properties?.street || properties?.addressLine1,
        addressLine2: businessInfoState?.homeAdressInfo2,
        city: properties?.city,
        state: properties?.stateCode || properties?.state,
        zipCode: properties?.postalCode || properties?.zipCode,
        useMock: useMock
      };
    }
    else {
      const properties = businessInfoState?.homeAdressInfo?.place?.properties;
      payload = {
        addressLine1: streetValue || properties?.street || properties?.addressLine1,
        addressLine2: businessInfoState?.homeAdressInfo2,
        city: cityValue || properties?.city,
        state: stateValue || properties?.stateCode || properties?.state,
        zipCode: zipValue || properties?.postalCode || properties?.zipCode,
        useMock: useMock
      };
    }
    dispatch1(checkAddressValidation(payload));
  };

  useEffect(() => {
    if (pageData?.addressValidationInfo?.serviceStatus?.success) {
        let correctedAddress = pageData?.addressValidationInfo?.validatedAddress;
        let objValue = {
          place: {
            properties: {
              country: correctedAddress?.country,
              countryCode: correctedAddress?.country,
              state: correctedAddress?.state,
              stateCode: correctedAddress?.state,
              street:  correctedAddress?.streetNum + " " + correctedAddress?.streetName + " " + correctedAddress?.type ,
              city: correctedAddress?.city,
              postalCode: correctedAddress?.zipCode,
              propertyType: correctedAddress?.type,
            },
          },
          displayString: businessInfoState?.homeAdressInfo?.displayString,
          name: businessInfoState?.homeAdressInfo?.name,
        };
        businessDispatch(Actions.setHomeAddressInfo(objValue));
        setHomeAddress(objValue);
      setShowErrNotification(false);
      setShowAddressModal(false);
      dispatch1(setAuthSignerInfo({...businessInfoState , homeAdressInfo :{...businessInfoState.homeAdressInfo, ...objValue} }));
      let temp = Object.assign({}, businessInfoState)
      setSignerAddress(temp?.homeAdressInfo?.name)
      window.sessionStorage.removeItem("authSignerInfoTemp");
      // handleAddressSelection(temp);
      setModifier(null);
      if (fromAuthorisedSigner) {
        onComplete();
      }
      setIsEditMode(false)
    } else if (
      pageData?.addressValidationInfo?.serviceStatus &&
      !pageData?.addressValidationInfo?.serviceStatus?.success
    ) {
      if (pageData?.addressValidationInfo?.serviceStatus?.statusMessage == "For security and fraud reasons, we do not ship to PO Boxes") {
        let tempErrors = {};
        tempErrors = {
          ...errors,
          businessStreetAddress:
            "For security and fraud reasons, we do not allow PO boxes.",
        };
        setErrors(tempErrors);
      }
      setShowErrNotification(true);
      setShowAddressModal(true);
    }
    else if (
      pageData?.addressValidationInfo?.serviceStatus &&
      !pageData?.addressValidationInfo?.serviceStatus?.success) {
      setShowErrNotification(true);
      setShowAddressModal(true);
    }
    dispatch1(setAddressValidationInfo(null));

  }, [pageData?.addressValidationInfo?.serviceStatus]);
  useEffect(()=>{

    if(!!pageData?.serviceInformation && typeof pageData?.serviceInformation == 'object' ){
        const {email="",domain="",bussinessName = '', firstName = '',lastName = '', addressLine1 = '', city = '',state = '', zipCode = '',  phoneNumber = '' } = pageData?.serviceInformation
        const reduPayload = {
          businessName:bussinessName,
            firstName:firstName,
            lastName:lastName,
            address:addressLine1,
            city:city,
            state:state,
            zipCode:zipCode,
            phoneNumber1:phoneNumber,
            email1:email,
            selectedMailType: "",
            fedTaxId: "",
            domain:domain,
            serviceAddress:{
            address:addressLine1,
            city:city,
            state:state,
            zipCode:zipCode
            }
        }
        const servicePayload = {
            firstName:firstName,
            lastName:lastName,
            address:addressLine1,
            city:city,
            state:state,
            zipCode:zipCode
        }
        businessDispatch(Actions.setContactInformationBUSS(reduPayload ))
        businessDispatch(Actions.setServiceInformationBUSS(servicePayload ))
    }
},[])
  const onConitnueClick = (val)=>{
    const proceed = Object.values(errorsSection).filter(data=>data !== null && data) ?? []
    if (proceed.length === 0 && !val) {
      if (serviceAddressSectionClicked) {
        const payload = {
          firstName: businessInfoState.constactInfoBUSS[businessInfoState.serviceAddressBuss.length] ? businessInfoState.constactInfoBUSS[businessInfoState.serviceAddressBuss.length]['firstName'] : '',
          lastName: businessInfoState.constactInfoBUSS[businessInfoState.serviceAddressBuss.length] ? businessInfoState.constactInfoBUSS[businessInfoState.serviceAddressBuss.length]['lastName'] : '',
          address: addAddress?.address,
          city: addAddress?.city,
          state: addAddress?.state,
          zipCode: addAddress?.zipCode
        }
        businessDispatch(Actions.setServiceInformationBUSS(payload));
        setShowDefaultContent(false)
        setServiceAddressSectionClicked(false)
      } else {
        businessDispatch(Actions.setContactInformationBUSS(addAddress));
        setShowDefaultContent(false)
      }

      businessDispatch(Actions.setIsSameAddress(false));
      businessDispatch(Actions.setHomeAddressInfo(""))
      setAddAddress({
        firstName: "",
        lastName: "",
        email: "",
        businessName: "",
        address: "",
        street: "",
        city: "",
        zipCode: "",
        state: "",
        phoneNumber1: "",
        phoneNumber2: "",
        email1: "",
        email2: "",
      })
      setCityValue('')
      setZipValue('')
      setStreetValue("")
      setStateValue("")
    }
    if(val==="cancel") setShowDefaultContent(false)
  }
  const getDefaultContent = () => {
    return (
      <div className="businessInfo">
        <div className="container" id="authSigner">
          {/* {showErrNotification &&
            <Notification
              type="error"
              title={addressConfirmaionContent?.notificationErrTitle}
              subtitle={addressConfirmaionContent?.notificationErrSubTitle}
              fullBleed={true}
              inlineTreatment={false}
            />} */}
          {isMobile ? <Body size="large"><StyledSpan
            dangerouslySetInnerHTML={{
              __html: t("checkout.businessInfo-section.messages.auth-info-mobile", {
                ns: "checkout",
              }),
            }}
          /></Body> :
            <Body size="large">Tell us where  you want our proffersionals to visit you.</Body>}
          <MarginSpacerM />
          <Grid
            colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
            colGutter={false}
            rowGutter="5"
          >
            {serviceAddressSectionClicked ? <></> : <>
            <Row>
              <ColPad>
                <div className="Prod-Discription">
                  <Micro size="medium" primitive="h2">
                    {t("checkout.businessInfo-section.messages.infomsg1", { ns: "checkout" })}
                  </Micro>
                </div>
              </ColPad>
            </Row>
            <Row>
              <ColPad colSizes={{ mobile: 4, tablet: 6, desktop: 4 }}>
                <div className="form-col">
                  <Input
                    label={`${t("checkout.businessInfo-section.labelTexts.authorisedSignerFname", {
                      ns: "checkout",
                    })} *`}
                    maxLength={49}
                    placeholder=""
                    name="firstName"
                    width={"100%"}
                    value={addAddress?.firstName}
                    onChange={(e) =>
                      isValidContactInfoField("firstName", e?.target?.value)
                    }
                    error={fNameErrors?.length > 0}
                    errorText={
                      fNameErrors ||
                      t("checkout.businessInfo-section.errorMsgs.signerNameReq", {
                        ns: "checkout",
                      })
                    }
                    tooltipContent={
                      t("checkout.businessInfo-section.labelTexts.authorisedSignerFnameToolTip", {
                        ns: "checkout",
                      })
                    }
                  />
                </div>
              </ColPad>
              <ColPad colSizes={{ mobile: 4, tablet: 6, desktop: 4 }}>
                <div className="form-col">
                  <Input
                    label={`${t("checkout.businessInfo-section.labelTexts.authorisedSignerLname", {
                      ns: "checkout",
                    })} *`}
                    maxLength={49}
                    placeholder=""
                    name="lastName"
                    width={"100%"}
                    value={addAddress?.lastName}
                    onChange={(e) =>
                      isValidContactInfoField("lastName", e?.target?.value)
                    }
                    error={lNameErrors?.length > 0}
                    errorText={
                      lNameErrors ||
                      t("checkout.businessInfo-section.errorMsgs.lastNameReq", {
                        ns: "checkout",
                      })
                    }
                  />
                </div>
              </ColPad>
            </Row>
            <Row>
              <ColPad colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
                <div className="form-col">
                  <Input
                    label={`Phone Number`}
                    placeholder=""
                    value={addAddress?.phoneNumber1}
                    onChange={(e) =>{
                      if(!isNaN(e.target.value) && e.target.value.length < 11)
                      isValidContactInfoField("phoneNumber1", e?.target?.value)
                    }}
                    width={"100%"}
                    maxLength={50}
                    error={phoneErrors1.length > 0}
                    errorText={
                      phoneErrors1 ||
                      t("checkout.businessInfo-section.errorMsgs.validPhone", { ns: "checkout" })
                    }
                  />
                </div>
              </ColPad>
              <ColPad colSizes={{ mobile: 6, tablet: 6, desktop: 6 }}>
              {!showAddAlternatePhoneNumberSection ? 
              <div style={{cursor:'pointer',display:'flex',position:'relative',top:'3rem'}} onClick = {()=>setShowAddAlternatePhoneNumberSection(true)}>
              <Icon name="zoom-in" color={"black"} size="large"/>
              <TextLink>Add another Contact Number</TextLink>
              </div>
              :
                <div className="form-col">
                  <Input
                    label={`Alternate Phone Number `}
                    placeholder=""
                    value={addAddress?.phoneNumber2}
                    onChange={(e) =>{
                      if(!isNaN(e.target.value) && e.target.value.length < 11)
                      isValidContactInfoField("phoneNumber2", e?.target?.value)
                    }}
                    width={"100%"}
                    maxLength={50}
                    error={phoneErrors2.length > 0}
                    errorText={
                      phoneErrors2 ||
                      t("checkout.businessInfo-section.errorMsgs.validPhone", { ns: "checkout" })
                    }
                  />
                </div>}
              </ColPad>
            </Row>
            <Row>
              <ColPad colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
                <div className="form-col">
                  <Input
                    label={`${t("checkout.businessInfo-section.labelTexts.companyEmail", {
                      ns: "checkout",
                    })} *`}
                    placeholder=""
                    value={addAddress?.email1}
                    onChange={(e) =>{
                      isValidContactInfoField("email1", e?.target?.value)
                    }}
                    width={"100%"}
                    maxLength={50}
                    error={emailErrors1.length > 0}
                    errorText={
                      emailErrors1 ||
                      t("checkout.businessInfo-section.errorMsgs.validEmail", { ns: "checkout" })
                    }
                  />
                </div>
              </ColPad>
              <ColPad colSizes={{ mobile: 6, tablet: 6, desktop: 6 }}>
              {!showAddAlternateEmailSection ? 
              <div style={{cursor:'pointer',display:'flex',position:'relative',top:'3rem'}} onClick = {()=>setShowAddAlternateEmailSection(true)}>
              <Icon name="zoom-in" color={"black"} size="large"/>
              <TextLink>Add another Contact Number</TextLink>
              </div>
              :
                <div className="form-col">
                  <Input
                    label={`Email ID 2`}
                    placeholder=""
                    value={addAddress?.email2}
                    onChange={(e) =>
                      isValidContactInfoField("email2", e?.target?.value)
                    }
                    width={"100%"}
                    maxLength={50}
                    error={emailErrors2.length > 0}
                    errorText={
                      emailErrors2 ||
                      t("checkout.businessInfo-section.errorMsgs.validEmail", { ns: "checkout" })
                    }
                  />
                </div>}
              </ColPad>
            </Row>
            <Row>
              <ColPad colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
                {businessInfoState?.showError ? (
                  <Notification
                    onCloseButtonClick={() => {
                      Actions.showError(false);
                    }}
                    type="error"
                  >
                    {"Please enter required fields."}
                  </Notification>
                ) : (
                  <></>
                )}
              </ColPad>
            </Row>
            <Row>
              <ColPad colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
                <CheckboxWrapper className="pad-bt-8 mr-tp-1">
                  <Checkbox
                    name="default"
                    width="auto"
                    disabled={!aboutBusinessInfor?.businessStreetAddress}
                    error={false}
                    selected={businessInfoState?.isSameAddress}
                    errorText=""
                    onChange={(e) => {
                      if (businessInfoState?.isSameAddress) {
                        setStreetErrors({ ...streetErrors, homeAdressInfoErr: null })
                        setStateErrors({ ...stateErrors, state: null, });
                        setCityErrors({ ...cityErrors, city: null, });
                        setZipCodeErrors({ ...zipCodeErrors, zipCode: null, });
                        businessDispatch(Actions.setHomeAddressInfo(null));
                      }
                      setCheckBoxFlag(true);
                      businessDispatch(Actions.setIsSameAddress(!businessInfoState?.isSameAddress));
                      if (e.target.checked) {
                        businessDispatch(Actions.setErrorMessages({}))
                        setErrors({})
                      }
                    }}
                  >
                    {`${t("checkout.businessInfo-section.labelTexts.sameAsCAddress", {
                      ns: "checkout",
                    })}`}
                  </Checkbox>
                </CheckboxWrapper>
              </ColPad>
            </Row>
            </>}
            <Row>
              <ColPad colSizes={{ mobile: 4, tablet: 8, desktop: 8 }}>
                {!businessInfoState?.isSameAddress ? (
                  <div className="address-container form-col">
                    <MapQStreetAddress
                      errorMessage={(errors?.homeAdressInfoErr?.length && errors?.homeAdressInfoErr) || (streetErrors?.homeAdressInfoErr?.length && streetErrors?.homeAdressInfoErr) || 
                        t("checkout.businessInfo-section.errorMsgs.homeAddLine1Req", {
                        ns: "checkout",
                      })}
                      label={t("checkout.businessInfo-section.labelTexts.signerhomeAddress", { ns: "checkout" })}
                      onSelectionChange={handleAddressSelection}
                      isError={!!errors?.homeAdressInfoErr || !!streetErrors?.homeAdressInfoErr}
                      handleAddressInput={(e) => handleAddressInput(e.target.value, "street")}
                      isAddressEmty={true}
                      setAddressFieldError={(value) => {
                        if(value){
                          businessDispatch(
                            Actions.setErrorMessages({
                              ...businessInfoState?.errorTexts,
                              homeAdressInfoErr:  value ? t("checkout.businessInfo-section.errorMsgs.homeAddLine1Req", {
                                ns: "checkout",
                              }) : ""
                            })
                          );
                          setStreetErrors({
                            ...streetErrors,
                            homeAdressInfoErr: value ? t("checkout.businessInfo-section.errorMsgs.homeAddLine1Req", {
                              ns: "checkout",
                            }) : ""
                          });
                        }
                      }}
                      required={true}
                      ref={businessStreetRef}
                      addressLineOne={streetValue || signerAddress || ""}
                      value={businessInfoState?.homeAdressInfo}
                      isNameOnly
                      isVerifyBussiness={true}
                    // helperText={t("checkout.businessInfo-section.labelTexts.addressLine1Helper", { ns: "checkout" })}
                    />
                  </div>
                ) : (
                  <div>
                    <div className="form-col">
                      <Input
                        label={t("checkout.businessInfo-section.labelTexts.signerhomeAddress", { ns: "checkout" })}
                        placeholder=""
                        width="100%"
                        value={aboutBusinessInfor?.businessStreetAddress?.businessStreetAddress ?
                          aboutBusinessInfor?.businessStreetAddress?.businessStreetAddress?.place?.properties?.street : aboutBusinessInfor?.businessStreetAddress?.place
                            ?.properties?.street
                        }
                        disabled={true}
                        required={true}
                        helperText={t("checkout.businessInfo-section.labelTexts.addressLine1Helper", { ns: "checkout" })}
                      />
                    </div>
                  </div>
                )}
              </ColPad>
            </Row>
            <Row>
              <ColPad colSizes={{ mobile: 4, tablet: 8, desktop: 8 }}>
                {businessInfoState?.isSameAddress ? (
                  <>
                    {aboutBusinessInfor?.companyAddressUnit && (
                      <div className="form-col">
                        <Input
                          label={t("checkout.businessInfo-section.labelTexts.addressLine2Label", { ns: "checkout" })}
                          placeholder=""
                          value={businessInfoState?.homeAdressInfo2}
                          disabled={true}
                          required={false}
                          helperText={t("checkout.businessInfo-section.labelTexts.signerUnitHelper", { ns: "checkout" })}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="form-col">
                    <Input
                      disabled={businessInfoState?.isSameAddress}
                      type="text"
                      maxLength={20}
                      label={t("checkout.businessInfo-section.labelTexts.addressLine2Label", { ns: "checkout" })}
                      placeholder="Enter Unit number"
                      value={businessInfoState?.homeAdressInfo2}
                      required={false}
                      onChange={(e) =>
                        validateUnitFiled(e?.target?.value)
                      }
                      error={unitError?.length > 0}
	                    errorText={unitErrorMessage}
                      helperText={t("checkout.businessInfo-section.labelTexts.signerUnitHelper", { ns: "checkout" })}
                    />
                  </div>
                )}
              </ColPad>
            </Row>

            <Row>
              <ColPad colSizes={{ mobile: 4, tablet: 8, desktop: 8 }}>
                <div className="form-col">
                  <Input
                    type="text"
                    name="city"
                    label="City *"
                    placeholder="Enter City"
                    disabled={businessInfoState?.isSameAddress}
                    error={!!errors?.city || !!cityErrors?.city}
                    errorText={(errors?.city?.length && errors?.city) || (cityErrors?.city?.length && cityErrors?.city) || t("checkout.businessInfo-section.errorMsgs.validCityReq", {
                      ns: "checkout",
                    })}
                    required={true}
                    value={cityValue}
                    onChange={(e) => handleAddressInput(e.target.value, "city")}
                  />
                </div>
              </ColPad>
            </Row>
            <Row>
              {/* <ColPad colSizes={{ mobile: 4, tablet: 6, desktop: 4 }}>
                <div className="form-col">
                  <Input
                    type="text"
                    name="state"
                    label="State *"
                    placeholder="Enter State"
                    disabled={businessInfoState?.isSameAddress}
                    error={!!errors?.state || !!stateErrors?.state}
                    errorText={errors?.state || stateErrors?.state}
                    required={true}
                    value={stateValue}
                    onChange={(e) => handleAddressInput(e.target.value, "state")}
                  />
                </div>
              </ColPad> */}
              <Col colSizes={{ mobile: 2, tablet: 6, desktop: 4 }}>
                <div className="form-col">
                  <DropdownSelect
                    label={"State *"}
                    name="state"
                    onChange={(e) => handleAddressInput(e.target.value, "state")}
                    value={stateValue}
                    disabled={businessInfoState?.isSameAddress}
                    required={true}
                    error={!!errors?.state || !!stateErrors?.state}
                    errorText={(errors?.state?.length && errors?.state)|| (stateErrors?.state?.length && stateErrors?.state)  || t("checkout.businessInfo-section.errorMsgs.selectState", {
                      ns: "checkout",
                    })}
                  >
                    {stateList?.map((option) => {
                      return (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      );
                    })}
                  </DropdownSelect>
                </div>
              </Col>
              <ColPad colSizes={{ mobile: 2, tablet: 6, desktop: 4 }}>
                <div className="form-col">
                  <Input
                    type="text"
                    name="zipCode"
                    label="ZIP Code *"
                    maxLength={5}
                    placeholder="Enter Zip Code"
                    disabled={businessInfoState?.isSameAddress}
                    error={!!errors?.zipCode || !!zipCodeErrors?.zipCode}
                    errorText={(errors?.zipCode?.length && errors?.zipCode) || (zipCodeErrors?.zipCode?.length && zipCodeErrors?.zipCode) || t("checkout.businessInfo-section.errorMsgs.validZipcodeReq", {
                      ns: "checkout",
                    })}
                    required={true}
                    value={zipValue}
                    onChange={(e) => handleAddressInput(e.target.value, "zipCode")}
                  />
                </div>
              </ColPad>
            </Row>
            <MarginSpacerM />
          </Grid>
          {!isEditMode &&
            <>
              <MarginSpacerS />
              <div style={{ display: "flex", justifyContent: isMobile ? "center" : "flex-start" }}>
                <Button width={isMobile ? "90%" : "22%"} size="large" use="primary" onClick={() => {
                  onConitnueClick()
                }}
                  data-track="Business Verification:Authorized signer verification:Continue"
                >
                  {"Continue"}
                </Button> &nbsp;&nbsp;
                <Button width={isMobile ? "90%" : "22%"} size="large" use="secondary" onClick={() => {
                  onConitnueClick("cancel")
                }}
                  data-track="Business Verification:Authorized signer verification:Continue"
                >
                  {"Cancel"}
                </Button>
              </div>
              <MarginSpacerM />
            </>}

        </div>
        {showAddressModal && (
          <AddressVerificationModal
            opened={showAddressModal}
            setAddressConfirmationModal={setShowAddressModal}
            setShippingState={(data) => {
              setSignerAddress(data?.businessStreetAddress?.displayString)
              localStorage.setItem("shippingState", JSON.stringify(data));
            }}
            fromSignerAddress={true}
            handleAddressSelect={(state) => {
              handleAddressSelection(state)
            }}
            aemContent={addressConfirmaionContent}
            displayAddress={homeAddress?.place?.properties}
            businessRawAddress={streetValue}
            addressLine2={businessInfoState?.homeAdressInfo2 ? businessInfoState?.homeAdressInfo2 : " "}
            showCloseButton={true}
            fromBusinessAddress={true}
            //unitDefaultValue={unitDefaultValue}
            validateBusinessAddress={validateBusinessAddress}
          />
        )}
      </div>
    );

  }
  const formatSSN = () => {
    if (businessInfoState?.corporation?.SSN) {

      let digits = pageData?.SSNInfo ?? businessInfoState?.corporation?.SSN;
      let firstfive = digits.slice(0, 6).replace(/\d/g, '#')
      let hashdigit = digits.slice(6)
      let formated = firstfive + hashdigit
      return formated;

    }
  }

  const getCompletedContent = () => {
    if(!!businessInfoState.constactInfoBUSS && businessInfoState.constactInfoBUSS.length > 0){
      return(
        <>
        <Body size="medium" bold>Administration info:</Body>
      <div className="serviceInfoContainer">
      {businessInfoState.constactInfoBUSS.map((bussInformation,i)=>(
        <CompletedContent bussInformation={bussInformation} lastIndex = {i+1 === businessInfoState.constactInfoBUSS.length} index={i}/>
      ))}
      </div>
      {/* <MarginSpacerL/> */}
      <Body size="medium" bold>Service address:</Body>
      <div className="serviceInfoContainer">
      {businessInfoState.serviceAddressBuss.map((bussInformation,i)=>(
        <CompletedContentServiceAddress bussInformation={bussInformation} lastIndex = {i+1 === businessInfoState.serviceAddressBuss.length} index={i}/>
      ))}
      </div>
      {!iscompleteclicked ? 
    <Button width="150px" onClick={()=>{
      businessDispatch(Actions.setContactInformation({...businessInfoState.constactInfoBUSS[selectedAddress]} ))
      onComplete()
      setIsCompleteClicked(true)
    }} >Continue</Button>:<></>}
      </>
      )
    }else return <div></div>
  };

  const getEditModeContent = () => {
    return (
      <>
        <>{getCompletedContent()}</>
        <div style={{ display: "flex", justifyContent: isMobile ? "center" : "flex-start" }}>
          <Button
            width={isMobile ? "90%" : "15%"}
            size="large"
            use="primary"
            onClick={() => {
              hasErrors();
            }}
            data-track="Continue"
          >
            {"Save"}
          </Button>
        </div>
        <MarginSpacerS />
      </>
    );
  };
  const CompletedContent = ({bussInformation = {},lastIndex=false,index=0})=>{
    return(
      <>
        <div className="service-information">
        <RadioButton
        selected = {index === selectedAddress}
        onChange={() => setSelectedAddress(index)}
        label={(bussInformation.firstName ? bussInformation?.firstName : '') +' '+(bussInformation?.lastName ? bussInformation?.lastName : '')}
        // selected={selected === selectedItem}
      />
        <>
        <Body size="large" >{bussInformation?.address}</Body>
        <Body size="large" >{bussInformation?.city}{', '}{bussInformation?.state}{' '}{bussInformation?.zipCode}</Body>
        <Body size="large" >Tel: {phoneNumberFormat(bussInformation?.phoneNumber1)}</Body>
        {bussInformation?.phoneNumber2 ? <Body size="large" >Tel2: {bussInformation?.phoneNumber2}</Body> : <></>}
        <Body size="large" >Email: {bussInformation?.email1 ?? bussInformation?.email1}</Body>
        {bussInformation?.email2 ? <Body size="large" >Email2: {bussInformation?.email2 ?? bussInformation?.email2}</Body>:<></>}
        <MarginSpacerM/>
        </>
    </div>
    {(lastIndex && index < 4 && false) ? 
    <div className="service-information"  style={{cursor:'pointer'}} onClick={()=>{
      setServiceAddressSectionClicked(false)
      setShowAddAlternatePhoneNumberSection(false)
      setShowAddAlternateEmailSection(false)
      businessDispatch(Actions.setIsSameAddress(false));
      setAddAddress({
        firstName: "",
        lastName: "",
        email: "",
        businessName: "",
        address: "",
        street:"",
        city: "",
        zipCode: "",
        state: "",
        phoneNumber1:"",
        phoneNumber2:"",
        email1: "",
        email2: "",
      })
      setShowDefaultContent(true)
    }}>
      <Body size="large" bold={true}>Add new point of contact</Body>	
      <div>
	    <Icon name="plus" color={"black"} size="large"/>
      </div>
    </div>:<></>}
    </>
    )
}
const CompletedContentServiceAddress = ({bussInformation = {},lastIndex=false,index=0})=>{
  return(
    <>
    <div className="service-information">
      <RadioButton
      selected = {index === selectedServiceAddress}
      onChange={() => setSelectedServiceAddress(index)}
      label={(bussInformation.firstName ? bussInformation?.firstName : '') +' '+(bussInformation?.lastName ? bussInformation?.lastName : '')}
      // selected={selected === selectedItem}
    />
      <>
      <Body size="large" >{bussInformation?.address}</Body>
      <Body size="large" >{bussInformation?.city}{', '}{bussInformation?.state}{' '}{bussInformation?.zipCode}</Body>
      <MarginSpacerM/>
      </>
  </div>
  {(lastIndex && index < 4 && false) ? 
  <div className="service-information" style={{cursor:'pointer'}} onClick={()=>{
    setServiceAddressSectionClicked(true)
    setShowDefaultContent(true)
  }}>
    <Body size="large" bold={true}>Add new service address</Body>	
    <div>
    <Icon name="plus" color={"black"} size="large"/>
    </div>
  </div>:<></>}
  </>
  )
}
const FinalContent = ()=>{
  return(
    <div className="billing-information">
    <div>
    <Body size="large" bold={true}>Administrator name</Body>
    <MarginSpacerS/>
    <Body size="large" >{businessInfoState?.contactInfo?.firstName ?? businessInfoState?.contactInfo?.firstName}{' '}{businessInfoState?.contactInfo?.lastName ?? businessInfoState?.contactInfo?.lastName}</Body>
    <MarginSpacerL/>
    <Body size="large" bold={true}>Contact</Body>
    <MarginSpacerS/>
    <Body size="large" >{businessInfoState?.contactInfo?.phoneNumber1}</Body>
    <MarginSpacerL/>
    <Body size="large" bold={true}>Notification email</Body>
    <MarginSpacerS/>
    <Body size="large" >{businessInfoState?.contactInfo?.email ?? businessInfoState?.contactInfo?.email1}</Body>

    </div>
    <div>
    <Body size="large" bold={true}>Billing address</Body>
    <MarginSpacerS/>
    <>
    <Body size="large" >{businessInfoState?.contactInfo?.address}</Body>
    <MarginSpacerS/>
    <Body size="large" >{businessInfoState?.contactInfo?.city}{', '}{businessInfoState?.contactInfo?.state}{' '}{businessInfoState?.contactInfo?.zipCode}</Body>
    <MarginSpacerS/>
    </>
    <Body size="large" >Tel: {phoneNumberFormat(businessInfoState?.contactInfo?.phoneNumber1)}</Body>
    <MarginSpacerL/>
    <Body size="large" bold={true}>Domain</Body>
    <MarginSpacerS/>
    <Body size="large" >{businessInfoState?.contactInfo?.domain ?? "Verizon"}</Body>
    </div>
    </div>
  )
}
if(iscompleteclicked && !isEditMode){
  return <FinalContent/>
}
else{
if(showDefaultContent){
return getDefaultContent()
}else{
  if (isComplete) {
    if (isEditMode) {
      return getEditModeContent();
    } else {
      return getCompletedContent();
    }
  } else {
    return getCompletedContent();
  }

}
}
}

const CheckboxWrapper = styled.div`
    margin-top:1.5rem;  
`
const StyledSpan = styled.span`
  > a {
    color: black;
  }
`;

const ColPad = styled(Col)`
 
}
`;
export default ServiceInformation;