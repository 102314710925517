import React, { useEffect, useState, useMemo, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// import { useSearchParams } from "react-router-dom";
import { TextLinkCaret } from "@vds3/buttons";
import { Title, Body } from "@vds3/typography";
import { useMediaQuery } from "react-responsive";
import get from "lodash/get";
import styled from "styled-components";
import { Col, Grid, Row } from "@vds3/grids";
import { Notification } from "@vds3/notifications";
import { useGlobalData } from "hooks";
import SpinnerComponent from "common/components/Spinner/Spinner";
import Utils from "app/ducks/utils";
import {
	setEditPackagePackageId,
	setIsEditPackageFlow,
	setLoadShoppingCartWidget,
	setSaveCartSuccess,
	setLoadCheckoutWidget,
	postSaveCart,
	setLoadBussPDPPage,
	setLoadMobilityProductDetails,
	setLoadFWAQualifiedPlans,
	setCurrentFlow,
} from "app/ducks/GlobalSlice";
import {
	getRetrieveCart5G,
	getSessionCart,
	postSharedCartSaveUrl,
	setCreditPath,
	setActiveSection,
	setvalidateCreditDetails,
	accessoriesRemove,
	postApplyPromoCode,
	getFetchCart5G,
	setFetchCartResponse,
	setFetchCartStatus,
	setEnableRoutingWithoutGaurd,
	setUnifiedCartParams,
	getOrderQuoteFetchSavedCart,
	setOrderQuoteFetchSavedCartResponse,
	setIsShoppingCartApiCallInProgress,
} from "pages/ShoppingCart/ducks/ShoppingCartSlice";
import {
  addToCart,
  setAddToCartResponse,
} from "pages/Mobility/ducks/MobilitySlice";
import { getDescriptionInfo } from "./ItemDescription";
import {
	EditDeviceModal,
	RemoveCartModal,
	ClearCartModal,
	MaxDevicesModal,
	ShowLimitModal,
} from "./components/Modals";
import SaveCartModal from "common/components/SaveCartModal/SaveCartModal";
// import AddressConfirmationModal from "common/components/AddressConfirmationModal/AddressConfirmationModal";
import { CartContextProvider } from "./cartContext";
import CartDetails from "pages/ShoppingCart/components/CartDetails/CartDetails";
import OrderSummary from "./components/OrderSummary/OrderSummary";
import CartFooter from "./components/CartFooter/CartFooter";
import {
	continueDisabled,
	getCartProperties,
	continueText,
	isCartHaveUncappedPlan,
} from "./util";
import CartStickyFooter from "./components/CartStickyFooter/CartStickyFooter";
// import SEOTagger from "common/components/SEOTagger/SEOTagger";
import {
	setIsEditLTEBackupPlansFlow,
	setIsEditLTEEquipmentFlow,
	setIsEditLTEPrimaryPlansFlow,
	setSelectedRouterPayment as set4GRouterPayment,
} from "pages/Landing4G-V2/ducks/4GSlice";
import { attachLPEvent } from "utils/commonUtils";
import { PhySicalSimModalByod } from "./components/Modals/PhySicalSimModal";
import { BussProductModal } from "./components/Modals/BussProductModal";
import { validateSimId } from "pages/Landing5G-V2/ducks/5GSlice";
import { MarginSpacerM } from "common/components/styleTag";
import { OneTalk5G } from "./components/Modals/OneTalk5G";
import { setSelectedBussProduct } from "pages/BussProductsPage/ducks/BussProductSlice";
import AccountsModal from "pages/CheckoutNew/Components/AccountsModal";
import { loadAccountsForBussProducts } from "pages/Checkout/ducks/CheckoutSlice";

const redirectionDetails = {
	CREDIT_RESULT_PAGE: "/shop/unified-checkout/credit-result",
	AGREEMENT_PAGE: "/shop/unified-checkout/docusign",
	REVIEW_ORDER: "/shop/review",
	TNC_PAGE: "/shop/unified-checkout/ReviewTAC",
	MTN_PAGE: "/shop/unified-checkout/npaNxx",
	SHOPPING_CART: "/shop/shopping-cart",
};

const ShoppingCart = ({ ...props }) => {
	const { t, ready } = useTranslation(["app", "shoppingCart"], {
		useSuspense: false,
	});
	const dispatch = useDispatch();
	// const navigate = useNavigate();
	const { metaInformation, sessionCartData, cmp } = useGlobalData({
		skipSessionCart: true,
	});

	// Redux selectors
	const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
	const pageData = useSelector((state) => state.ShoppingCartData);
	const lqData = useSelector((state) => state.ProspectWidgets_LQPage);
	const creditData = useSelector((state) => state.CreditSlice);
	const LandingASPState = useSelector((state) => state.LandingPageASP);
	const Landing5GPage = useSelector((state) => state.Landing5GPage);
    const checkoutData = useSelector(state => state.ProspectWidgets_CheckoutData)
	const mobilityPageData = useSelector(
		(state) => state.ProspectWidgets_MobilityData
	);
	const redirectToClearCart = useSelector(
		(state) => state.ShoppingCartData?.redirectToClearCart
	);

	const queryString = {};
	const queryParams = {};

	// Set flags
	const cartData = sessionCartData?.apiResponse?.prospectFlowData;
	const isDigitalQuote =
		window.sessionStorage.getItem("isDigitalQuote") ||
		cartData?.cart?.digitalQuote;
	const isLoading =
		pageData?.isShoppingCartApiCallInProgress.getFetchCart5G ||
		pageData?.isShoppingCartApiCallInProgress?.validateCreditDetails ||
		pageData?.promoCodeLoading ||
		Landing5GPage?.loading;
	const isAPIError = globalData?.apiError || pageData?.showError;
	const showNotification = false;
	const isBlendedCredit = cartData?.enableBlendedCredit;
	const isBizOnlyCredit = cartData?.enableBizOnlyCredit;
	const isCreditChoicePageActive = isBlendedCredit && isBizOnlyCredit;
	const isUnifiedCart = !!cartData?.cart?.unifiedCart;
	const showSimCompatible = !!globalData?.showSimCompatible;
	const unifiedCartParams = pageData?.unifiedCartParams || {};
	const isRetrieveCart = queryParams?.retrieve === "cart";
	const flow = pageData?.unifiedCartParams?.flow ?? lqData?.flow;
	const discontinuedItemsInCart = sessionCartData?.cart?.discontinuedItemsInCart;
	const pageFlow = cartData?.prospect5gLoopQualAddress?.flow;

	// ASP / DSP
	const isDSP = !!cartData?.dspData?.dspSource;
	const vendorData = get(LandingASPState, "vendorData");
	const serviceProvider = get(vendorData, "vendor");

	const maxDeviceQuantity = 10;
	const maxAfpDeviceQuantity = 20;

	// Modal states
	const [addressConfirmationModal, setAddressConfirmationModal] =
		useState(false);
	const [removeDevice, setRemoveDevice] = useState(false);
	const [removePackage, setRemovePackage] = useState("");
	const [editDevice, setEditDevice] = useState(false);
	const [selectedPackForEdit, setSelectedPackForEdit] = useState(undefined);
	const [editDevicePackageId, setEditDevicePackageId] = useState("");
	const [selectedDevice, setSelectedDevice] = useState({});
	const [clearCart, setClearCart] = useState(false);
	const [saveCart, setSaveCart] = useState(false);
	const [showMaxDeviceModal, setMaxDeviceModal] = useState(false);
	const [showDeviceLimitModal, setShowDeviceLimitModal] = useState(false);
	const [isUpdatedCart, setUpdatedCart] = useState(false);
	const [physicalSim, setPhysicalSim] = useState("");
	const [isValidSim, setIsValidSim] = useState(true);
	const [ownSimModal, setOwnSimModal] = useState(false);
	const [bussProductModal, setBussProductModal] = useState(false);
	const [selectedImei, setSelectedImei] = useState("");
	const [byodPack, setbyodPack] = useState(null);
	const [accessoriesCount, setAccessoriesCount] = useState(0);
	const [allowedAccessoriesCount, setAllowedAccessoriesCount] = useState(0);
	const [maximumLimitReachedAccessories, setMaximumLimitReachedAccessories] =
		useState(false);
	const [isContinue, setIsContinue] = useState(false);
	const [isHavingUncappedPlan, setIsHavingUncappedPlan] = useState(false);
	const [oneTalk5GModal, handleOneTalk5GModal] = useState(false);
	const [selectedPack, handleSelectedPack] = useState(null);
	const [isOneTalkFlow, handleOneTalkFlow] = useState(false);
	const [oneTalkFlowAdded, handleOneTalkFlowAdded] = useState(false);
	const [openAccountModal, setOpenAccountModal] = useState(false);
	const [dueMonthlyLocal, setDueMonthlyLocal] = useState([]);
	const [dueYearlyLocal, setDueYearlyLocal] = useState([]);
	const [dueYearlyTotalLocal, setDueYearlyTotalLocal] = useState(0);
	const [dueMonthlyTotalLocal, setDueMonthlyTotalLocal] = useState(0);
	const isMobile = useMediaQuery({
		query: "(max-width: 545px)",
	});
	const widgetInputParams = globalData?.widgetInputParams;
	const getSessionCartId = () => {
		if (!pageData?.cartId && !cartData?.cart?.cartId) {
			dispatch(postSharedCartSaveUrl());
		}
	};
	useEffect( () => {
		const currentUrl = window.location.href;
		const newUrl = new URL(currentUrl)
		const searchParams =new URLSearchParams(newUrl?.search)
		// Function to handle the resize event
		const handleBackButonCart = () => {
		 if(searchParams.has("pageName")){
		   newUrl.searchParams.set("pageName", "FWAPlansLanding");
		   window.history.replaceState({}, '', newUrl);
		   dispatch(setLoadFWAQualifiedPlans(true))
		   dispatch(setLoadShoppingCartWidget(false))
		 }
		 
		 };
	   
		 // Add the event listener
		 window.addEventListener('popstate', handleBackButonCart);
	   
		 // Cleanup function to remove the listener on unmount
		 return () => {
		 window.removeEventListener('popstate', handleBackButonCart);
		 };
	 }, [dispatch])

	useEffect(() => {
		if(!checkoutData.loadBussAccountDetails){
		const payload = {
			"ecpdId": widgetInputParams?.ecpdId?.value ?? window?.mbtGlobal?.ecpdId,
			"loginUserId": widgetInputParams?.mbLoginId?.value ?? widgetInputParams?.loginUserId?.value ?? widgetInputParams?.mbaUserId?.value ?? window?.mbtGlobal?.userId,
			"rootEcpdId": widgetInputParams?.ecpdId?.value ?? window?.mbtGlobal?.ecpdId,
			"clientId": widgetInputParams?.clientName?.value ?? ""
		  }
		dispatch(loadAccountsForBussProducts(payload))
		}
		let isCallTrigered = false;
		setTimeout(() => {
			if (!pageData?.cartId && !cartData?.cart?.cartId && !isCallTrigered) {
				attachLPEvent(getSessionCartId);
				isCallTrigered = true;
			}
		}, 2000);
		if (pageData?.cartId || cartData?.cart?.cartId) {
			window.dispatchEvent(
				new CustomEvent("cartDetailsFromWidget", {
					detail: {
						flow: "shoppingCart",
						cartId: pageData?.cartId || cartData?.cart?.cartId,
					},
				})
			);
		}
		return () => {
			dispatch(setLoadShoppingCartWidget(false));
			dispatch(setSaveCartSuccess({}));
		};
	}, []);

	useEffect(() => {
		if (!pageData?.isShoppingCartApiCallInProgress.getFetchCart5G) {
			const { retrieve, cartId, signature, flow, intend } = queryParams;
			if (retrieve === "cart" && intend) {
				dispatch(
					setUnifiedCartParams({
						retrieve,
						cartId,
						signature,
						flow,
						intend,
					})
				);
				dispatch(getOrderQuoteFetchSavedCart({ cartId, signature, flow, intend }));
			} else if (retrieve === "cart" && !intend) {
				dispatch(getFetchCart5G({ retrieve, cartId, signature, flow }));
			} else if (
				unifiedCartParams?.cartId &&
				!pageData?.fetchCart &&
				!globalData?.isOrderQuoteFlow
			) {
				const { retrieve, cartId, signature, flow, quoteId } = unifiedCartParams;
				dispatch(getFetchCart5G({ retrieve, cartId, signature, flow, quoteId }));
			} else {
				dispatch(getRetrieveCart5G(unifiedCartParams?.flow ?? lqData?.flow));
			}
			// dispatch(setBusinessCreditData({}));
			dispatch(setvalidateCreditDetails({}));
		}
		dispatch(setEditPackagePackageId(""));
		dispatch(setIsEditPackageFlow(false));
	}, []);

	useEffect(() => {
		const redirectPageTo =
			pageData?.orderQuoteFetchSavedCartResponse?.response?.redirectPageTo ||
			"SHOPPING_CART";
		if (
			pageData?.orderQuoteFetchSavedCartResponse?.status?.success === true &&
			(redirectPageTo === "SHOPPING_CART" ||
				(redirectionDetails[redirectPageTo] && redirectPageTo !== "REVIEW_ORDER"))
		) {
			// navigate("/shop/shopping-cart");
			dispatch(setOrderQuoteFetchSavedCartResponse({}));
			dispatch(
				getRetrieveCart5G(pageData?.unifiedCartParams?.flow ?? lqData?.flow)
			);
		} else if (
			pageData?.orderQuoteFetchSavedCartResponse?.status?.success === true &&
			redirectPageTo === "REVIEW_ORDER"
		) {
			dispatch(setEnableRoutingWithoutGaurd(true));
			// navigate("/shop/review");
			dispatch(setOrderQuoteFetchSavedCartResponse({}));
		} else if (
			pageData?.orderQuoteFetchSavedCartResponse?.status?.success === false
		) {
			// navigate("/shop/contact-us");
			dispatch(setOrderQuoteFetchSavedCartResponse({}));
		}
	}, [pageData?.orderQuoteFetchSavedCartResponse]);

	useEffect(() => {
		if (
			pageData?.fetchCart &&
			pageData?.fetchCart?.cart?.abandonedCart &&
			pageData?.isShoppingCartApiCallInProgress?.getCreditReadAndSpoDetails
		) {
			const redirectPageTo = pageData?.fetchCart?.redirectPageTo;
			if (
				pageData?.fetchCartStatus?.success === true &&
				redirectionDetails[redirectPageTo]
			) {
				const path = redirectionDetails[redirectPageTo];
				dispatch(setEnableRoutingWithoutGaurd(true));
				if (activeSection[redirectPageTo]) {
					dispatch(
						setActiveSection({
							activeSection: activeSection[redirectPageTo],
						})
					);
				}
				dispatch(setFetchCartStatus({}));
				dispatch(
					setIsShoppingCartApiCallInProgress({
						apiName: "getCreditReadAndSpoDetails",
						status: false,
					})
				);
				// navigate(path);
			} else if (pageData?.fetchCartStatus?.success === false) {
				// navigate("/shop/contact-us");
				dispatch(setFetchCartResponse({}));
				dispatch(setFetchCartStatus({}));
				dispatch(
					setIsShoppingCartApiCallInProgress({
						apiName: "getCreditReadAndSpoDetails",
						status: false,
					})
				);
			}
		}
	}, [
		pageData?.fetchCart,
		pageData?.fetchCartStatus,
		pageData?.isShoppingCartApiCallInProgress,
	]);

	useEffect(() => {
	  if (
	    isUpdatedCart &&
	    mobilityPageData?.addToCartResponse?.status?.statusCode === 200 &&
	    mobilityPageData?.addToCartResponse?.status?.statusMessage != ""
	  ) {
	    dispatch(
	      getRetrieveCart5G(pageData?.unifiedCartParams?.flow ?? lqData?.flow)
	    );
	    dispatch(setAddToCartResponse({}));
	  }
	}, [mobilityPageData?.addToCartResponse]);

	useEffect(() => {
		const accessories = cartData?.cart?.accessories;
		let accCount = 0;
		let allowedAccCount = 0;

		if (
			cartData?.cart?.packages &&
			Object.entries(cartData?.cart?.packages)?.length
		) {
			let filteredPackages = Object.entries(cartData?.cart?.packages)?.filter(
				([key, pkg]) => pkg?.packageType === "REGULAR"
			);
			allowedAccCount = filteredPackages?.length * 10;
		}
		setAllowedAccessoriesCount(allowedAccCount);
		isCartHaveUncappedPlan(cartData?.cart?.packages) &&
			setIsHavingUncappedPlan(true);
		for (const acc in accessories) {
			const qty = parseInt(accessories[acc].quantity);
			accCount += isNaN(qty) ? 0 : qty;
		}
		setAccessoriesCount(accCount);
	}, [cartData?.cart]);

	useEffect(() => {
		if (isContinue) {
			if (pageData?.creditDetails?.data?.serviceStatus?.message === "SUCCESS") {
				dispatch(setEnableRoutingWithoutGaurd(true));
				//navigate("/shop/unified-checkout/credit-result");
			} else if (
				pageData?.creditDetails?.data?.serviceStatus?.message === "FAILURE"
			) {
				setShowSpinner(false);
				// navigate("/shop/contact-us");
			}
		}
	}, [pageData?.creditDetails?.data?.serviceStatus, isContinue]);
	useEffect(() => {
		if (!oneTalk5GModal) window.scrollTo(0, 0);
	}, [oneTalk5GModal]);
	// Cart data parsing
	const cartProperties = useMemo(
		() => getCartProperties(cartData?.cart),
		[sessionCartData]
	);
	const cartPackages = useMemo(
		() =>
			Utils.getSortedPackages(
				cartData?.cart?.packages,
				cartData?.cart?.groupPackages
			),
		[sessionCartData]
	);
	const isContinueDisabled = useMemo(
		() => globalData?.saveCartSucess?.data?.serviceStatus?.success,
		[globalData?.saveCartSucess]
	);
	const handleContinueText = useMemo(
		() => continueText(cartPackages, t),
		[cartPackages, t, ready]
	);

	const onUpdateClick = (pack, operation, quantity) => {
		if(pack.buss){
			handleAddBussProduct(pack?.deviceSku,quantity, pack.brand);
			return;
		}
		const packagesObj = cartData?.cart?.packages;
		const remaing = Object.keys(packagesObj).length - getRemaingQuantity(pack);
		const qtyAllowed = Number(quantity) + remaing;
		if(quantity < 1) return
		if (quantity === "10+" || qtyAllowed > 999) {
			setMaxDeviceModal(true);
			return;
		}
		const packInfo = getDescriptionInfo(pack);
		const seoUrlName = packInfo?.filePath;
		const payload = {
			cartMeta: {
				zipCode: cartData?.prospect5gLoopQualAddress?.zipCode || "",
				shoppingPath: "prospect",
				flow: "4g",
			},
			lines: [
				{
					backUp: pack?.backUpPlanPresent,
					packageId: "",
					packageType: pack?.packageType,
					operation: operation,
					plan: {
						planId: pack?.plan?.pricePlanCode,
					},
					quantity: quantity,
					byor: false,
					device: {
						deviceSku: pack?.byor
							? "FSNO21VA"
							: pack?.deviceOtherSkus && pack?.deviceOtherSkus[0]?.sku,
						deviceCategory: pack?.byor
							? "BroadbandAccess Devices"
							: pack?.deviceCategory,
						deviceType: pack?.deviceType,
						quantity: quantity,
						priceType: pack?.byor ? "MONTH_TO_MONTH" : pack?.priceType,
						installmentTerm: pack?.byor ? "36" : pack?.tenure,
						seoUrlName: seoUrlName,
						byor5gEnabled: false,
						byod: false,
					},
				},
			],
		};
		dispatch(addToCart(payload));
		// dispatch(setSelectedRouter("router"));
		setUpdatedCart(true);
	};
	const getRemaingQuantity = (pack) => {
		const grpPackagesObj = cartData?.cart?.groupPackages;
		let usedQty = 0;
		for (const key of Object.keys(grpPackagesObj)) {
			if (pack?.groupId === key) {
				usedQty = grpPackagesObj[key]?.quantity;
			}
		}
		return usedQty;
	};

	const onUpdateRegularQty = (pack, operation, quantity) => {
		const packagesObj = cartData?.cart?.packages;
		const remaing = Object.keys(packagesObj).length - getRemaingQuantity(pack);
		const qtyAllowed = Number(quantity) + remaing;
		if (quantity === "10+" || qtyAllowed > 999) {
			setMaxDeviceModal(true);
			return;
		}
		const packInfo = getDescriptionInfo(pack);
		const seoUrlName = packInfo?.filePath;
		const payload = {
			cartMeta: {
				zipCode: cartData?.prospect5gLoopQualAddress?.zipCode || "",
				shoppingPath: "prospect",
				flow: "prospect",
			},
			lines:
				pack?.accessoryId !== null && pack?.accessoryId
					? [
							{
								packageType:
									mobilityPageData.flowType == "one-talk" ? "ONETALK" : "REGULAR",
								// "packageType":"REGULAR",
								operation: "UPDATE",
								accessories: [
									{
										operation: "ADD",
										accessorySku: pack?.accessoryId,
										productId: Number(pack?.description?.split(":_:")[7]),
										quantity: quantity,
									},
								],
							},
					  ]
					: [
							{
								packageId: "",
								packageType: "REGULAR",
								operation: operation,
								plan: {
									planId: pack?.plan?.pricePlanCode,
								},
								device: {
									deviceSku: pack?.deviceSku,
									deviceCategory: pack?.deviceCategory,
									deviceType: pack?.deviceType,
									quantity: quantity,
									priceType: pack?.priceType,
									installmentTerm: pack?.tenure,
									simType: pack.simType ?? "P",
									seoUrlName: seoUrlName,
								},
							},
					  ],
		};
		if (
			pack.tradInInfo !== null &&
			pack.tradInInfo &&
			Object.keys(pack.tradInInfo).length > 0
		) {
			payload["lines"][0]["tradeIn"] = {
				deviceId: pack["tradInInfo"]["deviceId"],
			};
		}
		dispatch(addToCart(payload));
		setUpdatedCart(true);
	};

	const onUpdateByod = () => {
		const packInfo = getDescriptionInfo(byodPack);
		const seoUrlName = packInfo?.filePath;

		const payload = {
			cartMeta: {
				zipCode: cartData?.prospect5gLoopQualAddress?.zipCode || "",
				shoppingPath: "prospect",
				flow: "prospect",
			},

			lines: [
				{
					packageId: byodPack?.packageId,
					packageType: "REGULAR",
					operation: "UPDATE",
					device: {
						deviceSku: byodPack?.deviceSku,
						deviceCategory: byodPack?.deviceCategory,
						deviceType: byodPack?.deviceType,
						quantity: 1,
						priceType: byodPack?.priceType,
						installmentTerm: byodPack?.tenure,
						simType: byodPack?.simType ?? "P",
						seoUrlName: seoUrlName,
						byod: true,
						portIn: byodPack?.portIn,
						byodInfo: {
							imei: byodPack?.imei,
							newSim: false,
							simNumber: physicalSim,
							skipImei: !byodPack?.imei ? true : "",
						},
					},
				},
			],
		};
		//dispatch(addToCart(payload));
		setUpdatedCart(true);
	};

	const handleAddBussProduct = (selectedItemId, quantity, brand) => {
		const payload = {
			cartMeta: {
				zipCode: cartData?.prospect5gLoopQualAddress?.zipCode || "",
				shoppingPath: "prospect",
			},
			flow: "prospect",
			lines: [
				{
					packageId: selectedItemId,
					packageType: "IOT",
					operation: "UPDATE",
					productType:brand,
					quantity:quantity
				},
			],
			allowMixedCart: true,
		};
		dispatch(addToCart(payload));
		setUpdatedCart(true);
		setBussProductModal(false);
	};

	const onUpdateDspClick = (pack, operation, quantity) => {
		const packagesObj = cartData?.cart?.packages;
		const grpPackagesObj = cartData?.cart?.groupPackages;
		if (packagesObj && isDSP) {
			let devicesQty = Object.keys(packagesObj)?.length;
			const grpPackageQty = grpPackagesObj[pack?.groupId]?.quantity;

			if (grpPackageQty > quantity) {
				let updatedQtySub = grpPackageQty - quantity;
				devicesQty = devicesQty - updatedQtySub;
			} else {
				let updatedQtyAdd = quantity - grpPackageQty;
				devicesQty = devicesQty + updatedQtyAdd;
			}
			if (devicesQty > maxAfpDeviceQuantity) {
				setShowDeviceLimitModal(true);
			} else {
				onUpdateDspQty(pack, operation, quantity);
			}
		}
	};

	const onUpdateDspQty = (pack, operation, quantity) => {
		let seoUrlName = pack?.description?.split(":_:");
		if (seoUrlName?.length) {
			seoUrlName = seoUrlName[seoUrlName.length - 1];
		}
		const features = pack.features;
		const dspDetails = cartData?.dspData;
		const payload = {
			cartMeta: {
				zipCode: dspDetails?.zipCode,
				shoppingPath: "prospect",
				vendor: serviceProvider,
				flow: "dsp",
				dspSource: dspDetails?.dspSource,
				partnerCode: dspDetails?.partnerCode,
				salesRepId: "ECN01",
			},
			lines: [
				{
					packageId: "",
					packageType: "REGULAR",
					operation: operation,
					plan: {
						planId: pack?.plan?.pricePlanCode,
					},
					device: {
						deviceSku: pack?.deviceSku,
						deviceCategory: pack?.deviceCategory,
						deviceType: pack?.deviceType,
						quantity: quantity,
						priceType: pack?.priceType,
						installmentTerm: pack?.tenure,
						simType: pack.simType,
						seoUrlName: seoUrlName,
					},
				},
			],
		};
		//dispatch(addToCart(payload));
		// dispatch(setSelectedRouter("router"));
		setUpdatedCart(true);
	};

	// Edit link actions
	const onEditDeviceClick = (pack, key) => {
		if(pack.buss){
			setSelectedPackForEdit(pack)
		} if (pack?.byod && !pack.byor) {
			dispatch(setEditPackagePackageId(key || pack?.packageId));
			dispatch(setIsEditPackageFlow(true));
			//navigate("/shop/products/devices/smartphones/byod");
		} else if (pack.packageType === "FOURG") {
			const currentUrl = window.location.href;
			const newUrl = new URL(currentUrl);
			newUrl.searchParams.set("pageName", "FWAPlansLanding");
			window.history.pushState({}, '', newUrl);
			dispatch(setLoadShoppingCartWidget(false));
			dispatch(setCurrentFlow('4G'))
			dispatch(setLoadFWAQualifiedPlans(true))
			dispatch(setIsEditLTEEquipmentFlow(true));
			// navigate(
			//   "/shop/products/networks/connectivity/lte-business-internet/plans"
			// );
		} else if (pack.packageType === "FIVEG") {
			const currentUrl = window.location.href;
			const newUrl = new URL(currentUrl);
			newUrl.searchParams.set("pageName", "FWAPlansLanding");
			window.history.pushState({}, '', newUrl);
			dispatch(setLoadShoppingCartWidget(false));
			dispatch(setCurrentFlow('5G'))
			dispatch(setLoadFWAQualifiedPlans(true))
			//dispatch(setIsEdit5GEquipmentFlow(true));
			//navigate("/shop/solutions/5g/plans");
		} else {
			// setDeviceId(pack?.deviceId);
			setEditDevicePackageId(key);
			if (
				pack !== null &&
				pack &&
				Object.keys(pack).length > 0 &&
				pack["accessoryId"] !== null &&
				pack["accessoryId"]
			)
				setEditDevicePackageId(pack["accessoryId"]);
			else setSelectedDevice(pack);
			setEditDevice(true);
			// setSelectedSku(pack);
		}
	};

	const onEditPlanClick = (pack) => {
		if (pack?.packageType === "TYS") {
			//navigate(`/shop/tys/plans`);
		} else if (pack?.packageType === "FOURG") {
			dispatch(setIsEditLTEPrimaryPlansFlow(true));
			dispatch(setIsEditLTEBackupPlansFlow(pack?.backUpPlanPresent));
			// navigate(
			//   "/shop/products/networks/connectivity/lte-business-internet/plans"
			// );
		} else if (pack?.packageType === "FIVEG") {
			// dispatch(setIsEdit5GPrimaryPlansFlow(true));
			//navigate("/shop/solutions/5g/plans");
		} else {
			//navigate("/shop/plans");
		}
	};

	const onEditFeatureClick = (pack) => {
		if (isDSP) {
			// dispatch(setSelectedRemoveFeatures(pack));
			// navigate(`/shop/dsp/${serviceProvider}/protection`);
		} else {
			// navigate("/shop/features");
		}
	};

	const onRemoveSecurityAddOnClick = (pack) => {
		let operation = "DELETE";
		let request = {
			featureCode: "00000",
			flow: flow,
		};

		let features = [];
		Object.entries(pack?.features).reduce((acc, [key, value]) => {
			if (value?.displaySection?.toLowerCase() === "securityaddon") {
				features.push({
					featureCode: value?.visFeatureCode,
					featureType: value?.type,
					featureLevel: value?.level,
					featurePrice: value?.featurePrice,
					operation: operation,
					featureDesc: value?.featureDesc,
				});
				return [...acc, value];
			}
			return acc;
		}, []);
		if (features?.length > 0) {
			request.features = features;
			//dispatch(updateAddonToCart(request));
			setUpdatedCart(true);
		}
	};

	const onRemoveClick = (pack) => {
		setRemovePackage(pack);
		setRemoveDevice(true);
	};

	const onRemoveClickConfirm = (pack) => {
		setRemoveDevice(false);
	};

	// Continue button actions
	const onContinueBtnClick = () => {
		setSaveCart(true);
		// if (handleContinueText === t("app.common.page-stepper.go-to-plans-btn")) {
		//  // navigate("/shop/plans");
		// } else if (handleContinueText === t("app.common.page-stepper.select-protection-btn")) {
		//  // navigate("/shop/features");
		// } else if (handleContinueText === t("app.common.page-stepper.select-router-btn")) {
		//   if (cartProperties?.FiveG) {
		//     //dispatch(set5GRouterPayment({ planType: "primaryPlan", value: "" }));
		//   //  navigate("/shop/solutions/5g/plans");
		//   } else if (cartProperties?.FourG) {
		//     dispatch(set4GRouterPayment({ planType: "primaryPlan", value: "" }));
		//     dispatch(set4GRouterPayment({ planType: "backupPlan", value: "" }));
		//    // navigate("/shop/products/networks/connectivity/lte-business-internet/plans");
		//   }
		// } else {
		//   if (isDigitalQuote && (cartProperties?.FiveG || isHavingUncappedPlan)
		//     && !lqData?.loopQualAddressResponse?.status?.success) {
		//     setAddressConfirmationModal(true);
		//   } else if (cartProperties?.isMMW) {
		//    // navigate("/shop/installationAuthorization");
		//   } else if (false) {
		//     // TODO: if some trade-in imei is missing?
		//   } else {
		//     continueToLowerFunnel();
		//   }
		// }
	};

	const onCheckout = () => {
		const payload = {
			shoppingPath: "PROSPECT",
			fromUCSubmitOrder: false,
		};
		dispatch(postSaveCart(payload));
		dispatch(setLoadShoppingCartWidget(false));
		dispatch(setLoadCheckoutWidget(true));
		const currentUrl = window.location.href;
		const newUrl = new URL(currentUrl);
		newUrl.searchParams.set("pageName", "Checkout");
		window.history.pushState({}, '', newUrl);
	};

	const continueToLowerFunnel = () => {
		setIsContinue(true);
		dispatch(getSessionCart());
		dispatch(postSharedCartSaveUrl());
		if (cartData?.skipBusinessInfo) {
			// dispatch(setCreditDetailsResponse({}));
			// dispatch(skipBizValidateCreditDetails(flow));
		} else {
			if (cmp?.isFromGoogleShop) {
				// skip credit choice for google shop customers
				const creditPath = "BLENDED";
				dispatch(setCreditPath(creditPath));
				// dispatch(
				//   updateProspectCreditDetails({
				//     flow: pageData?.unifiedCartParams?.flow,
				//     creditPath: creditPath,
				//     cmpCode: globalData?.cmp?.cmpValue,
				//   })
				// );
				// navigate("/shop/unified-checkout/verifyBusiness");
			} else if (isCreditChoicePageActive) {
				// navigate("/shop/credit-choice");
			} else {
				if (isBizOnlyCredit) {
					dispatch(setCreditPath("BizOnlyCredit"));
				}
				// navigate("/shop/unified-checkout/verifyBusiness");
			}
			dispatch(setActiveSection({ activeSection: "verifyBusiness" }));
		}
	};

	const onOwnSimSelection = (simNumber) => {
		setPhysicalSim(simNumber);
		setOwnSimModal(true);
	};

	const onBussSelection = () => {
		navigate("/shop/buss-products");
		// setBussProductModal(true);
	};

	const validate = (value) => {
		if (value) {
			setIsValidSim(value?.length && value.length >= 19 && value.length <= 20);
		}
	};

	const handleSimcardInputChange = (e) => {
		validate(e.target.value);
		setPhysicalSim(e.target.value);
	};

	const onclickPhysicalCheckSim = () => {
		const payload = {
			deviceId: selectedImei || "",
			deviceSku: selectedImei ? "" : byodPack?.deviceSku || "",
			simNumber: physicalSim,
			shoppingPath: "PROSPECT",
			approach: "",
			flow: "prospect",
		};
		dispatch(validateSimId(payload));
	};
	// const navigateToDevices = () => {
	//   dispatch(
	//     setFilterSelected({
	//       paymentList: [
	//         {
	//           key: "Device Payment",
	//         },
	//       ],
	//     })
	//   );
	//   navigate("/shop/products/devices/all");
	// }
	const navigateToOnetalk = () => {
		//navigate("/shop/products/voice-collaboration/unified-communications/one-talk");
	};
	const navigateToBYOD = () => {
		//  navigate("/shop/products/devices/smartphones/byod");
	};
	const navigateToAccessories = () => {
		// navigate("/shop/accessories");
	};
	const navigateToLTEPlans = () => {
		window.location.href = "https://www.verizon.com/business/products/internet/";
	};
	const enableMobilityEdit =
		window?.mbtGlobal?.flags?.ENABLE_VDS3_SHOPPINGCART_MOBILITY_EDIT === "Y"
			? true
			: false;
	useEffect(()=>{
		if(props?.fromBUSS && cartData && cartData?.cart && cartData?.cart?.packages && typeof cartData?.cart?.packages == 'object'){
			let dueMonthly = {}
			let dueYearly = {}
			let yearPrice = 0
			let monthPrice = 0
			Object.values(cartData?.cart?.packages).map(data=>{
				dueMonthly[data['brand']] = dueMonthly[data['brand']] ? dueMonthly[data['brand']] : []
				dueYearly[data['brand']] = dueYearly[data['brand']] ? dueYearly[data['brand']] : []
				const dataToPush = {
					"itemName": data["description"],
					"count": cartData?.cart?.groupPackages[data?.groupId]['quantity'],
					"itemPrice": data["itemPrice"],
					"itemId": data["deviceSku"],
					"previousItemPrice": 0,
					"offerApplied": false,
					"byod": false,
					"byor": false,
					"brand":data['brand']
				}
				if(String(data?.priceType).toUpperCase() === 'ANNUAL'){
					yearPrice += data["itemPrice"];
					(dueYearly[data['brand']]).push(dataToPush)
				}else{
					monthPrice += data["itemPrice"];
				(dueMonthly[data['brand']]).push(dataToPush)
				}
			})
			Object.keys(dueMonthly).map(key=>{
				if(dueMonthly[key] && dueMonthly[key].length === 0) delete dueMonthly[key]
			})
			Object.keys(dueYearly).map(key=>{
				if(dueYearly[key] && dueYearly[key].length === 0) delete dueYearly[key]
			})
			setDueMonthlyLocal(dueMonthly)
			setDueYearlyLocal(dueYearly)
			setDueYearlyTotalLocal(yearPrice)
			setDueMonthlyTotalLocal(monthPrice)
		}
	},[cartData])
	return(
		<CartContextProvider
			value={{
				accessoriesCount,
				allowedAccessoriesCount,
				cartData,
				cartPackages,
				cartProperties,
				isDigitalQuote,
				isDSP,
				isMobile,
				onEditDeviceClick,
				onEditFeatureClick,
				onRemoveSecurityAddOnClick,
				onEditPlanClick,
				onOwnSimSelection,
				onRemoveClick,
				onUpdateClick,
				onUpdateDspClick,
				onUpdateRegularQty,
				pageData,
				serviceProvider,
				setbyodPack,
				onBussSelection,
				setClearCart,
				setMaximumLimitReachedAccessories,
				setSaveCart,
				setSelectedImei,
				showSimCompatible,
				setMaxDeviceModal,
				oneTalk5GModal,
				handleOneTalk5GModal,
				isOneTalkFlow,
				handleOneTalkFlow,
				oneTalkFlowAdded,
				handleOneTalkFlowAdded,
				selectedPack,
				handleSelectedPack,
				setOpenAccountModal,
				fromBUSS:props?.fromBUSS,
				dueMonthlyLocal,
				dueYearlyLocal,
				dueYearlyTotalLocal,
				dueMonthlyTotalLocal
			}}
		>
			{props?.fromBUSS ? 
			<AccountsModal openAccountModal={openAccountModal} fromShoppingCart = {true} setOpenAccountModal={(val)=>setOpenAccountModal(val)}/>:<></>}
			{false ? <OneTalk5G /> : <></>}
			{/* {(isDigitalQuote || isRetrieveCart || unifiedCartParams?.cartId) && (
        <SEOTagger />
      )} */}
			{/* {!!isLoading && <SpinnerComponent />} */}
			{!redirectToClearCart && !!isAPIError && (
				<Notification
					type="error"
					inline={true}
					fullBleed={true}
					title={t("app.notifications.shopping-cart.sww", { ns: "app" })}
				/>
			)}
			{(((isRetrieveCart || isDigitalQuote) && saveCart) ||
				(isRetrieveCart && pageData?.fetchCartStatus?.statusCode === 200)) && (
				<Notification
					type="info"
					inline={true}
					fullBleed={true}
					title={t("app.common.retrive-cart.msg", { ns: "app" })}
				/>
			)}
			{isUpdatedCart && (
				<Notification
					type={t("shopping-cart.cart-update-notification.type", {
						ns: "shoppingCart",
					})}
					title={t("shopping-cart.cart-update-notification.title", {
						ns: "shoppingCart",
					})}
					subtitle={t("shopping-cart.cart-update-notification.subtitle", {
						ns: "shoppingCart",
					})}
					fullBleed={true}
					inline={true}
					// hideCloseButton={() => { }}
					onCloseButtonClick={() => setUpdatedCart(false)}
					layout={"horizontal"}
				/>
			)}
			{discontinuedItemsInCart && (
				<Notification
					type={t("shopping-cart.cart.routerNotification.type", {
						ns: "shoppingCart",
					})}
					title={t("shopping-cart.cart.routerNotification.contentMsg", {
						ns: "shoppingCart",
					})}
					fullBleed={true}
					inline={true}
					// hideCloseButton={() => { }}
					onCloseButtonClick={() => {}}
					layout={"horizontal"}
				/>
			)}
			{maximumLimitReachedAccessories && (
				<Notification
					fullBleed={true}
					inline={true}
					layout={"horizontal"}
					onCloseButtonClick={() => {}}
					title={t("shopping-cart.cart-accessory-item.maximum", {
						maxQtyNew: allowedAccessoriesCount,
						ns: "shoppingCart",
					})}
					type="info"
				/>
			)}
				<BackLink>
											<TextLinkCaret
												iconPosition="left"
												onClick={() => {
													dispatch(setLoadMobilityProductDetails(true));
													dispatch(setLoadShoppingCartWidget(false));
													//navigateToLTEPlans();
												}}
											>
												Back
											</TextLinkCaret>
										</BackLink>
			<div style={{ margin: "1rem 0 0 0" }}>
				{cartData?.cart?.packages && Object.keys(cartData?.cart?.packages) && (
					<Grid>
						<Row>
							<Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
								<CartFlexContainer>
									<CartDetailsDiv>
										<CartDetails isEditable={true} accountInfo={checkoutData?.selectedAccountInfo}/>
										{/* <CartOffers /> */}
									</CartDetailsDiv>
									<OrderSummaryDiv>
										<OrderSummary
											defaultOpenAccordions={false}
											isShoppingCart={true}
											excludeFooterContent={true}
											fromBUSS={props?.fromBUSS}
											// onContinueClick={onContinueBtnClick}
											// disableContinueButton={isContinueDisabled}
										/>
									</OrderSummaryDiv>
								</CartFlexContainer>
							</Col>
						</Row>
					</Grid>
				)}
				{(redirectToClearCart && !isLoading) ||
				(!cartData?.cart?.packages && !isLoading) ? (
					<ShoppingCartData>
						<NoShippingData>
							<HeaderSection>
								<Title bold={false} size="XLarge">
									{t("shopping-cart.shoppingcart-data.heading", { ns: "shoppingCart" })}
								</Title>
								<MarginSpacerM />
								<Body bold={false} size="Large">
									{t("shopping-cart.shoppingcart-data.heading1", { ns: "shoppingCart" })}
								</Body>
							</HeaderSection>
							<BodySection>
								{(isUnifiedCart || pageFlow === "5g" || pageFlow === "4g") && (
									<>
										<BackLink>
											<TextLinkCaret
												iconPosition="right"
												onClick={() => {
													navigateToLTEPlans();
												}}
											>
												{t("shopping-cart.shop-more.shopInternetTxtLink", {
													ns: "shoppingCart",
												})}
											</TextLinkCaret>
										</BackLink>
									</>
								)}
								{enableMobilityEdit && (
									<>
										<BackLink>
											<TextLinkCaret
												iconPosition="right"
												onClick={() => {
													navigateToDevices();
												}}
											>
												{t("shopping-cart.shop-more.shopDevicesTxtLink", {
													ns: "shoppingCart",
												})}
											</TextLinkCaret>
										</BackLink>
										{(isUnifiedCart || pageFlow === "5g" || pageFlow === "4g") && (
											<>
												<BackLink>
													<TextLinkCaret
														iconPosition="right"
														onClick={() => {
															navigateToOnetalk();
														}}
													>
														{t("shopping-cart.shop-more.shopOneTalkTxtLink", {
															ns: "shoppingCart",
														})}
													</TextLinkCaret>
												</BackLink>
											</>
										)}
										<BackLink>
											<TextLinkCaret
												iconPosition="right"
												onClick={() => {
													navigateToAccessories();
												}}
											>
												{t("shopping-cart.shop-more.shopAccessoriesTxtLink", {
													ns: "shoppingCart",
												})}
											</TextLinkCaret>
										</BackLink>
										<BackLink>
											<TextLinkCaret
												iconPosition="right"
												onClick={() => {
													navigateToBYOD();
												}}
											>
												{t("shopping-cart.shop-more.byodTxtLink", { ns: "shoppingCart" })}
											</TextLinkCaret>
										</BackLink>
									</>
								)}
							</BodySection>
						</NoShippingData>
					</ShoppingCartData>
				) : (
					<></>
				)}
				{/* <CartFooter cartId={cartData?.cart?.cartId || pageData?.cartId} /> */}
			</div>
			{cartData?.cart?.packages && Object.keys(cartData?.cart?.packages) && (
				<Suspense fallback={<></>}>
					<CartStickyFooter
						onContinue={onContinueBtnClick}
						disabledContinueBtn={globalData?.retrieveLoader || isContinueDisabled}
						continueBtnText={"Send quote"}
						checkoutBtnText={"Checkout"}
						onCheckout={onCheckout}
						enableSendQuote={globalData?.widgetInputParams?.flow?.value?.toLowerCase() === 'assisted'}
						checkoutDisable={((props?.fromBUSS && !checkoutData?.storedAccountNumber) || globalData?.retrieveLoader)}
						enableCheckout={t("shopping-cart.shoppingcart-data.enableOptixCheckout", {
							ns: "shoppingCart",
						})}
					/>
				</Suspense>
			)}
			{/* MODALS START */}
			{removeDevice && (
				<RemoveCartModal
					opened={removeDevice}
					onOpenedChange={setRemoveDevice}
					mobilityPageData={mobilityPageData}
					pageData={pageData}
					pack={removePackage}
					setRemoveDevice={setRemoveDevice}
					isDSP={isDSP}
					serviceProvider={serviceProvider}
					cartProperties={cartProperties}
					UnifiedCart={isUnifiedCart}
					oneTalkFlowAdded={oneTalkFlowAdded}
					isOneTalkFlow={isOneTalkFlow}
					selectedPack={selectedPack}
					onRemoveClickConfirm={onRemoveClickConfirm}
				/>
			)}
			{clearCart && (
				<ClearCartModal
					opened={clearCart}
					onOpenedChange={setClearCart}
					pageDataValue={pageData}
					cartProperties={cartProperties}
					isDSP={isDSP}
					serviceProvider={serviceProvider}
					UnifiedCart={isUnifiedCart}
					// path={
					//     flow == "4g"
					//     ? "/shop/products/networks/connectivity/lte-business-internet/plans"
					//     : "/shop/solutions/5g/plans"
					// }
					pageData={pageData}
					setClearCart={setClearCart}
				/>
			)}
			{saveCart && (
				<SaveCartModal
					opened={saveCart}
					onOpenedChange={setSaveCart}
					pageData={pageData}
				/>
			)}
			{editDevice && (
				<EditDeviceModal
					pack = {selectedPackForEdit}
					opened={editDevice}
					onOpenedChange={setEditDevice}
					editDevicePackageId={editDevicePackageId}
					selectedDevice={selectedDevice}
				/>
			)}
			{/* {addressConfirmationModal && (
        <AddressConfirmationModal
          setAddressConfirmationModal={setAddressConfirmationModal}
          opened={addressConfirmationModal}
          cb={continueToLowerFunnel}
        />
      )} */}
			{showMaxDeviceModal && (
				<MaxDevicesModal
					deviceQuantity={maxDeviceQuantity}
					opened={showMaxDeviceModal}
					onOpenedChange={setMaxDeviceModal}
				/>
			)}
			{showDeviceLimitModal && (
				<ShowLimitModal
					isOpen={showDeviceLimitModal}
					onOpenedChange={(mstate) => {
						if (!mstate) {
							setShowDeviceLimitModal(false);
						}
					}}
				/>
			)}
			{ownSimModal && (
				<PhySicalSimModalByod
					onOpenedChange={setOwnSimModal}
					physicalSim={physicalSim}
					opened={ownSimModal}
					pageData={pageData}
					handleSimcardInputChange={(e) => handleSimcardInputChange(e)}
					onclickPhysicalCheckSim={onclickPhysicalCheckSim}
					setOwnSimModal={setOwnSimModal}
					isValidSim={isValidSim}
					statusCode={
						Landing5GPage?.byor?.validateSimIdResp?.serviceStatus?.statusCode
					}
					onUpdateByod={onUpdateByod}
				/>
			)}
			{bussProductModal && (
				<BussProductModal
					onOpenedChange={setBussProductModal}
					opened={bussProductModal}
					setBussProductModal={setBussProductModal}
					pageData={pageData}
					handleAddBussProduct={handleAddBussProduct}
				/>
			)}

			{/* MODALS END */}
		</CartContextProvider>
	);
};

const CartFlexContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	gap: 1.5rem;
	column-gap: 2.5rem;
	@media only screen and (max-width: 990px) {
		flex-wrap: wrap;
	}
`;

const CartDetailsDiv = styled.div`
	flex-grow: 2;
	max-width: 100%;
`;

const OrderSummaryDiv = styled.div`
	flex-grow: 1;
	// max-width: 387px;
	flex-basis: 31.4125%;
`;
const NoShippingData = styled.div`
	padding: 20px;
	max-width: 1272px;
	margin: 0 auto;
`;
const HeaderSection = styled.div`
	margin-bottom: 50px;
	@media screen and (max-width: 768px) {
		margin-bottom: 0px;
	}
`;
const BodySection = styled.div`
	display: flex;
	align-items: center;
	padding: 23px 0px 18px;
	@media screen and (max-width: 768px) {
		display: block;
		padding: 23px 0px 0px;
	}
`;
const BackLink = styled.div`
	margin-right: 2rem;
	@media screen and (max-width: 768px) {
		margin-bottom: 20px;
	}
`;
const ShoppingCartData = styled.div`
	.no-shipping-data {
		padding: 20px;
		max-width: 1272px;
		margin: 0 auto;
	}
	.header-section {
		margin-bottom: 50px;
	}
	.header-section h1 {
		font-family: Verizon NHG DS;
		font-weight: 300;
		font-size: 48px;
		margin-top: 4px;
		margin-bottom: 0px;
	}
	.header-section p {
		margin: 12px 0px;
		font-size: 12px;
	}
	.body-section {
		display: flex;
		align-items: center;
		padding: 23px 0px 18px;
	}
	.back-link {
		margin-right: 34px;
	}
	@media screen and (max-width: 768px) {
		.header-section {
			margin-bottom: 0px;
		}
		.header-section h1 {
			font-size: 32px;
		}
		.body-section {
			display: block;
			padding: 23px 0px 0px;
		}
		.body-section .back-link {
			margin-bottom: 20px;
		}
	}
`;

export default ShoppingCart;
