import React from "react";

const RatingStar = (props) => {
  // Implementing star as per Figma
  return (
    <svg xmlns="http://www.w3.org/2000/svg" 
    width={props?.width || "21"}
    height={props?.height || "21"}
    fill={props.fillColor}
    viewBox="0 0 24 24" id="IconChangeColor" > 
    <g> <path fill="none" d="M0 0h24v24H0z" id="mainIconPathAttribute" stroke-width="0" stroke="#0e0c0c" filter="url(#shadow)"></path> 
    <path d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z" id="mainIconPathAttribute" stroke="#0e0c0c"></path> </g> 
    </svg>
  )
};

export default RatingStar;