import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalTitle, ModalFooter } from "@vds3/modals";
import { Body, Title } from "@vds3/typography";
import { TextLink } from "@vds3/buttons";
import { MarginSpacerXL } from "common/components/styleTag";
import { useGlobalData } from "hooks";
import {
  postClearCart,
  setCleartCartDetails,
} from "pages/ShoppingCart/ducks/ShoppingCartSlice";
// import { addToCart, setDeviceListBy } from "pages/Mobility/ducks/MobilitySlice";
// import { addToCart as addToCartOneTalk } from "pages/OneTalk/ducks/OneTalkSlice";
import * as SiteAnalytics from "../../../../sitecatalyst";

const RemoveCartModal = ({
  opened,
  onOpenedChange,
  pack,
  onChangeDevice,
  isDSP,
  serviceProvider,
  cartProperties,
  UnifiedCart,
  ...props
}) => {
  const { t } = useTranslation(["shoppingCart"]);
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  // const globalData = useSelector((state) => state.GlobalData);
  // const sessionCartData =
  //   globalData?.sessionCartData?.apiResponse?.prospectFlowData;
  const { sessionCartData } = useGlobalData();
  const mobilityData = useSelector((state) => state?.MobilityPage);
  const deviceListBy = mobilityData?.deviceListBy;
  const { packageId, packageIds } = pack;
  const cart = sessionCartData?.apiResponse?.prospectFlowData?.cart;
  const packageDetails = cart?.packages;
  const mobilityPageData = props?.mobilityPageData;
  const totalPackages = packageDetails && Object.keys(packageDetails).length;
  const filteredgrp = packageDetails?.[packageId]?.groupId;
  const quantity = cart?.groupPackages?.[filteredgrp]?.quantity;
  const REACT_UPPER_FUNNEL = t("shopping-cart.react-upper-funnel", {
    returnObjects: true,
  });
  const upperFunnelEnabled =
    Array.isArray(REACT_UPPER_FUNNEL) &&
    REACT_UPPER_FUNNEL.includes(pack?.packageType);

  let isAccountLevelFeatures = [], packagesQty;
  if (cartProperties?.AccountLevelFeatures?.length) {
    isAccountLevelFeatures = cartProperties?.AccountLevelFeatures.filter(
      (feature) => feature?.displaySection !== "CartPromotion"
    );
    if(packageDetails) {
      let packages = Object.values(packageDetails).filter((pack) => pack?.packageType === "REGULAR");
      if(packages?.length) {
        packagesQty = packages.length - quantity;
      }
    }
  }
  const isAccountLevelThere = !!(isAccountLevelFeatures?.length && packagesQty < 3);

  const enableCTASection = 
    !(isAccountLevelThere) &&
    window?.mbtGlobal?.flags?.ENABLE_VDS3_SHOPPINGCART_MOBILITY_EDIT === 'Y' ? true : false;

  useEffect(() => {
    if (props?.pageData?.cleartCartDetails?.data?.statusCode === 200) {
      // dispatch(reset4GState());
      // dispatch(reset5GState());
      dispatch(setCleartCartDetails({}));
      //dispatch(setDeviceListBy(null));
      if(!UnifiedCart && (!cartProperties?.SmartPhones && !cartProperties?.MobilityByod)) {
        let path = "/shop/products/devices/smartphones";
        if(isDSP && serviceProvider){
          path = `/shop/dsp/${serviceProvider}/devices`;
        } else if(pack?.packageType === "FOURG" || pack?.packageType === "FIVEG") {
          window.location.href = "https://www.verizon.com/business/products/internet/";
        } else if(pack?.packageType === "ONETALK") {
          path = "/shop/products/voice-collaboration/desk-conference-phones";
        }
        //navigate(path);
      } else {
        onOpenedChange(false);
      }
    }
  }, [props?.pageData?.cleartCartDetails?.data]);

  const onRemoveCartClicked = (newDevice = false) => {
    if (totalPackages === quantity) {
      if(newDevice) {
        dispatch(postClearCart({ routeToDevices: () => {
          // dispatch(reset4GState());
          // dispatch(reset5GState());
          dispatch(setCleartCartDetails({}));
          //dispatch(setDeviceListBy(null));
          let path = "/shop/products/devices/smartphones";
          if(pack?.packageType === "REGULAR" && !pack?.byod){
            path = "/shop/products/devices/smartphones";
          } else if(pack?.packageType === "REGULAR" && pack?.byod){
            path = "/shop/products/devices/smartphones/byod";
          } else if(pack?.packageType === "ONETALK") {
            path = "/shop/products/voice-collaboration/desk-conference-phones";
          }
          //navigate(path);
        }}));
      } else {
        dispatch(postClearCart());
      }
      props?.onRemoveClickConfirm();

      // navigate(path);
    } else {
      const payload = {
        ...(pack?.packageType === "IOT" && {allowMixedCart: true}),
        cartMeta: {
          zipCode: mobilityPageData?.zipCode,
          shoppingPath: "prospect",
        },
        lines: [],
      };
      if(props.oneTalkFlowAdded) payload["oneTalk5GFlow"] = true
      Object.keys(packageDetails).filter((device) => {
        // if (device === packageId) {
        if (packageIds?.includes(device)) {
          SiteAnalytics.eventShoppingCartInfoTracking('Shopping Cart Remove', pack, 'shopping-cart', 'cart_remove');
          let line = {
            packageId: device,
            packageType: pack?.packageType,
            operation: "DELETE",
          };
          payload.lines.push(line);
          i++;
        }
      });
      if(!packageIds && pack?.accessoryId && !packageId) {
        let line = {
          //packageType: pack?.packageType || "REGULAR",
          packageType: mobilityPageData.flowType == 'one-talk' ? "ONETALK" : "REGULAR" ,
          operation: "UPDATE",
          accessories: [{
            operation: "DELETE",
            accessorySku: pack?.accessoryId,
            quantity: pack?.quantity || 1,
          }]
        };
        payload.lines.push(line);
      }
      if(isAccountLevelThere) {
        if(packageDetails) {
          Object.keys(packageDetails).filter((key) =>{
            let pack = packageDetails[key];
            let featureCode = Object.values(isAccountLevelFeatures);
             if(pack?.packageType === "REGULAR" && !packageIds?.includes(key)) {
              let line = {
                packageId: key,
                packageType: pack?.packageType,
                operation: "UPDATE",
                features: [{
                  operation: "DELETE",
                  featureCode: featureCode[0]?.visFeatureCode,
                  featureType: "SFO",
                  featureLevel: "A",
                  quantity: "1",
                }]
              };
              payload.lines.push(line);
             }
          });
        }
      }
      if (payload.lines.length) {
        // if(payload["oneTalk5GFlow"]!==null&&payload["oneTalk5GFlow"]) dispatch(addToCartOneTalk({...payload, routeToFeatures: isAccountLevelThere }))
        // else dispatch(addToCart({...payload, routeToFeatures: isAccountLevelThere }));
        // if(pack?.packageType === "REGULAR" && pack?.byod && deviceListBy) {
        //   let list = deviceListBy?.filter((device) => device?.packageId !== pack?.packageId);
        //   if(list?.length) {
        //     dispatch(setDeviceListBy(list));
        //   } else {
        //     dispatch(setDeviceListBy(null));
        //   }
        // }
      }
      // if((props.oneTalkFlowAdded||props.isOneTalkFlow)&&props.selectedPack!==null&&props.selectedPack&&props.selectedPack.length&&pack['packageType']!=="ONETALK"){
      //   dispatch(reset5GState())
      //   dispatch(reset4GState())
      // }
      if(newDevice) {
        let path = "/shop/products/devices/smartphones";
        if(pack?.packageType === "REGULAR" && !pack?.byod){
          path = "/shop/products/devices/smartphones";
        } else if(pack?.packageType === "REGULAR" && pack?.byod){
          path = "/shop/products/devices/smartphones/byod";
        } else if(pack?.packageType === "ONETALK") {
          path = "/shop/products/voice-collaboration/desk-conference-phones";
        }
        //navigate(path);
      } else {
        onOpenedChange(false);
      }
    }
  };

  return (
    <>
      {opened && (
        <Modal
          hideCloseButton={true}
          disableOutsideClick={true}
          onOpenedChange={onOpenedChange}
          opened={opened}
        >
          <Title size="large" bold>
            {t("shopping-cart.remove-device-modal.title")}
          </Title>
          <ModalBody>
            <Body size="large">
              {isAccountLevelThere ?
               t("shopping-cart.remove-device-modal.body1"):
               t("shopping-cart.remove-device-modal.body")}
            </Body>
            {!upperFunnelEnabled && enableCTASection && (!pack?.accessoryId && pack?.packageType === 'REGULAR') && (
              <>
                <MarginSpacerXL />
                <TextLink
                  type="standAlone"
                  size="large"
                  onClick={() => {
                    onRemoveCartClicked(true);
                  }}
                >
                  {t("shopping-cart.remove-device-modal.linkText")}
                </TextLink>
              </>
            )}
          </ModalBody>
          <ModalFooter
            buttonData={{
              primary: {
                children: (isAccountLevelThere) ? t("shopping-cart.remove-device-modal.decline") : t("shopping-cart.remove-device-modal.remove"),
                onClick: () => {
                  onRemoveCartClicked();
                },
                'data-track':"Remove"
              },
              close: {
                children: t("shopping-cart.remove-device-modal.cancel"),
                onClick: () => {},
                'data-track':"cancel"
              },
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default RemoveCartModal;
