import React, { useEffect, useReducer, useRef, useState } from "react";
import { Button } from "@vds3/buttons";
import VerticalProgressContext from "common/components/VerticalProgress/verticalProgressContext";
import { Title, Body } from "@vds3/typography";
import { MarginSpacerM, MarginSpacerS } from "common/components/styleTag";
import { Input } from "@vds3/inputs";
import { Grid, Row, Col } from "@vds3/grids";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { InitialState, reducer, actions as Actions } from "./containers/FormReducer";
import { useTranslation } from "react-i18next";
import { setAboutBusinessInfor } from "../../../Checkout/ducks/CheckoutSlice";
import { useMediaQuery } from 'react-responsive'

const FederalTaxIDInfo = ({ ...props }) => {
    const {
        isComplete,
        onComplete,
        setModifier,
        isEditMode: { isEditMode, setIsEditMode },
    } = React.useContext(VerticalProgressContext);
    const { t } = useTranslation(["app", "checkout"]);
    const isMobile = useMediaQuery({
        query: "(max-width: 525px)",
    });
    const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
    const pageData = useSelector((state) => state.ProspectWidgets_CheckoutData);
    const sessionCartData =
        globalData?.sessionCartData?.apiResponse?.prospectFlowData;
    const isTaxIdOptional = globalData?.cmp?.isFromGoogleShop;
    const [taxErrors, setTaxErrors] = useState({ "fedTaxId": "" });
    const [taxIDLabel, setTaxIDLabel] = useState(true);
    const isOrderQuoteFlow = globalData.isOrderQuoteFlow;
    const isDigitalQuote = sessionCartData?.cart?.digitalQuote;
    const isAbandonedCart = sessionCartData?.cart?.abandonedCart;
    const [state, dispatch] = useReducer(
        reducer,
        Object.keys(pageData?.aboutBusinessInfor).length === 0 ? InitialState : pageData?.aboutBusinessInfor
    );
    const dispatch1 = useDispatch();

    useEffect(() => {
        if (!isEditMode && Object.keys(pageData?.aboutBusinessInfor).length) {
            dispatch(Actions.setCorporation({ ...state?.corporation, ["fedTaxId"]: pageData?.aboutBusinessInfor?.corporation?.fedTaxId }));
            setTaxErrors({ "fedTaxId": "" })
        }
    }, [isEditMode])

    useEffect(() => {
        const element = document.getElementById("fedtax-info");
        element?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, []);

    const isValidTaxIdInfo = (key, e) => {
        let val = "";
        let errors = "";
        if (key === "fedTaxId") {
            val = e ? e.replace(/[^0-9]/g, "").replace(/(\d{2})(\d{7})/, "$1-$2") : "";
            if (isTaxIdOptional && val === "") {
                errors = ""
            }
            else {
                errors =
                    (!isTaxIdOptional && !val) || !val?.trim() || val?.length < 10 || val == undefined
                        ? t("checkout.businessInfo-section.errorMsgs.validTaxId", { ns: "checkout" })
                        : val?.length > 10 ? t("checkout.businessInfo-section.errorMsgs.validTaxId", { ns: "checkout" }) : "";
            }
            dispatch(Actions.setCorporation({ ...state?.corporation, [key]: val }));
            setTaxErrors({ ...taxErrors, [key]: errors });
            if (errors.length) return true;
            else return false;
        }
    }

    const getDefaultContent = () => {
        return (
            <>
                <div>
                    {isMobile ? <Body size="large"><StyledSpan
                        dangerouslySetInnerHTML={{
                            __html: t("checkout.businessInfo-section.messages.fedTax-info-mobile", {
                                ns: "checkout",
                            }),
                        }} /></Body> :
                        <>
                            <Body size="large"><StyledSpan
                                dangerouslySetInnerHTML={{
                                    __html: t("checkout.businessInfo-section.messages.fedTax-info-desktop", {
                                        ns: "checkout",
                                    }),
                                }}
                            /></Body>
                        </>}
                    <MarginSpacerM />
                    <Grid
                        colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
                        colGutter={false}
                        rowGutter="5"
                    >
                        <Row>
                            <ColPad colSizes={{ mobile: 4, tablet: 6, desktop: 8 }}>
                                <div className="form-col show-label">
                                    <Input
                                        label={`${t("checkout.businessInfo-section.labelTexts.federalTaxID", {
                                            ns: "checkout",
                                        })}${isTaxIdOptional ? "" : " *"}`}
                                        placeholder=""
                                        required={!isTaxIdOptional}
                                        name="fedTaxId"
                                        width={"100%"}
                                        maxLength={9}
                                        value={state?.corporation?.fedTaxId}
                                        onChange={(e) => isValidTaxIdInfo("fedTaxId", e?.target?.value)}
                                        error={taxErrors?.fedTaxId?.length > 0}
                                        errorText={
                                            taxErrors?.fedTaxId ||
                                            t("checkout.businessInfo-section.errorMsgs.validTaxId", { ns: "checkout" })
                                        }
                                        tooltipContent={
                                            isTaxIdOptional
                                                ? t("checkout.businessInfo-section.labelTexts.FederalIdOptionalTooltip", {
                                                    ns: "checkout",
                                                })
                                                : t("checkout.businessInfo-section.labelTexts.FederalIdTooltip", {
                                                    ns: "checkout",
                                                })
                                        }
                                        type={"password"}
                                        onClickInlineAction={() =>
                                            taxIDLabel ? setTaxIDLabel(false) : setTaxIDLabel(true)
                                        }
                                        onBlur={(e) => {
                                            if (!isTaxIdOptional) isValidTaxIdInfo("fedTaxId", e?.target?.value)
                                        }}
                                    />
                                    <div className="helper-text">
                                        <Body size="small" color="#6F7171">
                                            {t("checkout.businessInfo-section.helperTexts.federalTaxID", {
                                                ns: "checkout",
                                            })}
                                        </Body>
                                    </div>
                                </div>
                            </ColPad>
                        </Row>
                    </Grid>
                </div>
                {!isEditMode &&
                    <>
                        <MarginSpacerS />
                        <div style={{ display: "flex", justifyContent: isMobile ? "center" : "flex-start" }}>
                            <Button size="large" use="primary" onClick={() => {
                                // add here the redux obj
                                if (taxErrors?.fedTaxId?.length == 0) {
                                    dispatch1(setAboutBusinessInfor(state))
                                    setModifier(null);
                                    onComplete();
                                }
                            }}
                                width={isMobile ? "90%" : "22%"}
                                data-track="Continue"
                            >
                                {"Continue"}
                            </Button>

                        </div>
                        <MarginSpacerS />
                    </>}
            </>
        );
    };

    const getCompletedContent = () => {
        return (
            ((isOrderQuoteFlow || isDigitalQuote || isAbandonedCart) && sessionCartData?.redirectPageTo && sessionCartData?.redirectPageTo === "checkout") ?
                <div>
                    <Body size="large"> {'#########'}</Body>
                    {sessionCartData?.businessInfo?.taxId && <MarginSpacerM />}
                </div>
                : <div>
                    <Body size="large"> {formatTaxID()}</Body>
                    {state?.corporation?.fedTaxId && <MarginSpacerM />}
                </div>
        );
    };

    const getEditModeContent = () => {
        return (
            <>
                <>{getDefaultContent()}</>
                <MarginSpacerS />
                <div style={{ display: "flex", justifyContent: isMobile ? "center" : "flex-start" }}>
                    <Button
                        width={isMobile ? "90%" : "15%"}
                        size="large"
                        use="primary"
                        onClick={() => {
                            if (taxErrors?.fedTaxId?.length == 0) {
                                dispatch1(setAboutBusinessInfor(state))
                                setModifier(null);
                                onComplete();
                                setIsEditMode(false)
                            }
                        }}
                        data-track="Save"
                    >
                        {"Save"}
                    </Button>

                </div>
                <MarginSpacerS />
            </>
        );
    };
    const formatTaxID = () => {
        if (state?.corporation?.fedTaxId) {
            let digits = pageData?.aboutBusinessInfor?.corporation?.fedTaxId ?? state?.corporation?.fedTaxId;
            let firstTwo = digits.slice(0, 2)
            let hashdigit = digits.slice(2).replace(/\d/g, '#')
            let formated = firstTwo + hashdigit
            return formated;
        }
    }

    if (isComplete) {
        if (isEditMode) {
            return getEditModeContent();
        } else {
            return getCompletedContent();
        }
    } else {
        return getDefaultContent();
    }
};

const StyledSpan = styled.span`
  > a {
    color: black;
  }
`;

const ColPad = styled(Col)`
  margin-top: 1rem !important;
`;

export default FederalTaxIDInfo;
