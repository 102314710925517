export const globalData = (state) => state.ProspectWidgets_GlobalData;
export const getFlowConfig = (state) => state.ProspectWidgets_CheckoutData.flowConfig;
export const pageData = (state) => state.ProspectWidgets_CheckoutData;
export const fetchCart = (state) => state.ProspectWidgets_CheckoutData.fetchCart;
export const flowState = (state) => state.CheckoutFlow;
export const flowType = (state) => state.ProspectWidgets_CheckoutData.flowType;
export const availableDevices = (state) =>
  state.ProspectWidgets_CheckoutData.npaNxx.availableDevices;
export const npaNxxData = (state) => state.ProspectWidgets_CheckoutData.npaNxx;
export const paymentData = (state) => state.ProspectWidgets_PaymentData;
export const lqData = (state) => state.ProspectWidgets_LQPage;
export const creditData = (state) => state.ProspectWidgets_CreditData;
export const MobilityData = (state) => state.ProspectWidgets_MobilityData;
