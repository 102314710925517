import { createAction, createSlice } from '@reduxjs/toolkit';

const AEMContentSlice = createSlice({
    name: "aemContent",
    initialState: {
        loading: false,
        resources: {},
        timestamp: {}
    },
    reducers: {
        setLoading: (state, action) => {
            state['loading'] = action.payload;
        },
        setResource: (state, action) => {
            state['resources'][action.payload.resourceName] = action.payload.data;
        },
        setTimestamp: (state, action) => {
            state['timestamp'][action.payload.resourceName] = action.payload.status;
        },
    }
});

export const reducer = AEMContentSlice.reducer;
export const getAEMContentResources = createAction('aemContent/getAEMContentResources');
export const { setLoading, setResource, setTimestamp } = AEMContentSlice.actions;