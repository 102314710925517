import get from "lodash/get";
import pick from "lodash/pick";
import {
	call,
	fork,
	join,
	put,
	select,
	takeLatest,
	takeLeading,
} from "redux-saga/effects";

import * as API from "../../../containers/APIurls";
import apiClient from "../../../utils/apiClient";
import * as CheckoutSlice from "../../Checkout/ducks/CheckoutSlice";
import * as GlobalSlice from "../../../app/ducks/GlobalSlice";
//import * as PlanSlice from "../../Plans/ducks/PlansSlice";
import * as MobilitySlice from "./MobilitySlice";
//import * as TradeInSlice from "../../TradeIn/ducks/TradeInSlice";
//import * as PlanFirstSlice from "pages/PlanFirst/ducks/PlanFirstSlice";
//import * as AccessorySlice from "../../Accessories/ducks/AccessorySlice";
//import { getGridwallPegaRecommendation as getPegaRecommendation } from "./MobilitySlice";
import Utils from "../../Checkout/ducks/utils";
//import { navigationRef } from "AppWrapper";
import ProductList from './ProductList.json'
import ProductDetails from './ProductDetails.json'
import { getBussPDP } from "pages/BussProductsPage/ducks/BussSagas";
import PurchaseInfo from './PurchanseInfo.json'

const getSimSku = (state) =>
	get(state, "MobilityPage.carrierDetailsByDevice.selectedSimSku", undefined);

const getValidDevices = (state) => get(state, "MobilityPage.validDevices", []);

const mobilityData = (state) => state.ProspectWidgets_MobilityData;

const getSelectedDevicesForComparison = (state) => {
	let devicesList = get(state, "MobilityPage.compareDevices", null);
	if (state?.MobilityPage?.oneTalk) {
		devicesList = get(state, "OneTalkPage.compareDevices", null);
	}
	if (!devicesList) {
		return [];
	}
	let payload = Object.keys(devicesList).map((device) =>
		pick(devicesList[device], ["deviceId", "selectedSku", "seoUrlName"])
	);

	payload = payload.map((d) => ({
		deviceId: d.deviceId,
		deviceSku: d.selectedSku.sku,
		deviceOfferPercentage: "",
		deviceOfferAmount: "",
		seoURL: d.seoUrlName,
	}));

	return payload;
};

const getZipCode = (state) => {
	const zipCode =
		get(state, "MobilityPage.zipCode", null) ||
		localStorage.getItem("zipCode") ||
		"";
	return zipCode;
};

function* getCarrierDetailsByDevice(params) {
	yield put(MobilitySlice.setAPIError(false));
	yield put(MobilitySlice.setPageLoading(true));
	try {
		const response = yield apiClient.post(API.GET_SIM_DETAILS, params.payload);
		yield put(MobilitySlice.setCarrierDetailsByDevice(response.data));
	} catch (err) {
		yield put(MobilitySlice.setAPIError(true));
	} finally {
		yield put(MobilitySlice.setPageLoading(false));
	}
}

function* updateValidDevices(params) {
	const { payload } = params;
	const devices = yield select(getValidDevices);
	const isDeviceAlreadyAdded = devices.some(
		(device) => device.imei === payload.imei
	);
	if (isDeviceAlreadyAdded) {
		// set error message in the store
		return;
	}
	yield put(MobilitySlice.setValidDevices(payload));
}

function* getPlansForValidDevices(params) {
	const zipCode = yield select(getZipCode);
	//yield put(PlanSlice.setPageLoading(true));
	try {
		const devices = yield select(getValidDevices);

		const devicePlanPromosPayload = {
			deviceList: devices.map((device) => ({
				deviceSku: device.deviceSkuId,
				priceType: "MONTH_TO_MONTH",
			})),
			approach: "",
			flow: "prospect",
			shoppingPath: "PROSPECT",
		};
		// Payload for deviceCompatibleplans
		const deviceCompatiblePlansPayload = {
			address: { zipCode },
			approach: "",
			flow: "prospect",
			planCatagoryType: "buePlans",
			shoppingPath: "PROSPECT",
			deviceDetailsList: devices.map((device) => ({
				deviceSku: device.deviceSkuId,
				contractLength: "MONTH_TO_MONTH",
				packageType: device.category,
				simSku: device.simSku,
			})),
		};

		// const promosResp = yield apiClient.post(
		//   API.GET_DEVICE_PLAN_PROMOS,
		//   devicePlanPromosPayload
		// );

		// const compaitibleResp = yield apiClient.post(
		//   API.GET_DEVICES_COMPATIBLE_PLANS,
		//   deviceCompatiblePlansPayload
		// );

		// yield put(CheckoutSlice.getSessionCart());
		// yield put(PlanSlice.setDeviceCompatiblePlansInfo(compaitibleResp.data));
		// yield put(PlanSlice.setDevicePlanPromosInfo(promosResp.data));
	} catch (err) {
	} finally {
		//yield put(PlanSlice.setPageLoading(false));
	}
}

function* getPlans(params) {
	const zipCode = yield select(getZipCode);
	//yield put(PlanSlice.setPageLoading(true));
	//yield put(PlanSlice.setAPIError(false));
	const simSKU = yield select(getSimSku);

	const { deviceSku } = params.payload;

	const promoPlansPayload = {
		deviceDetailList: [{ deviceSku, priceType: "MONTH_TO_MONTH" }],
		shoppingPath: "PROSPECT",
		approach: "",
		flow: "prospect",
	};

	const protectionPlansPayload = {
		address: { zipCode: zipCode },
		deviceDetailsList: [
			{
				deviceSku,
				simSku: simSKU,
				contractLength: "MONTH_TO_MONTH",
				packageType: "SMARTPHONE",
				offerId: "",
			},
		],
		planCatagoryType: "buePlans",
		shoppingPath: "PROSPECT",
		approach: "",
		flow: "prospect",
	};

	const plansPayload = {
		approach: "",
		flow: "prospect",
		shoppingPath: "PROSPECT",
	};

	try {
		// const plansResp = yield apiClient.post(API.GET_PLANS_AEM, plansPayload);
		// const protectionPlansResp = yield apiClient.post(
		//   API.GET_DEVICE_PROTECTION_PLANS,
		//   protectionPlansPayload
		// );
		// const promoPlansResp = yield apiClient.post(
		//   API.GET_DEVICE_PLAFN_PROMOS,
		//   promoPlansPayload
		// );
		// yield put(CheckoutSlice.getSessionCart());
		// yield put(PlanSlice.setPlanAEM(plansResp?.data?.data));
		// yield put(PlanSlice.setDevicePlanPromosInfo(promoPlansResp.data));
	} catch (err) {
		//yield put(PlanSlice.setAPIError(true));
	} finally {
		//yield put(PlanSlice.setPageLoading(false));
	}
}

function* getInitPageLoadInfo() {
	yield put(MobilitySlice.setAPIError(false));
	yield put(MobilitySlice.setPageLoading(true));
	try {
		const forks = [];
		forks.push(yield fork(getMetaInformation));
		forks.push(yield fork(getCombinePricingInfo));
		yield join([...forks]);
	} catch (err) {
		yield put(MobilitySlice.setAPIError(true));
	} finally {
		yield put(MobilitySlice.setPageLoading(false));
	}
}

function* getMetaInformation() {
	yield put(MobilitySlice.setAPIError(false));
	yield put(MobilitySlice.setPageLoading(true));
	try {
		const requestBody = {
			digitalQuote: false,
			shoppingPath: "PROSPECT",
			approach: "",
			flow: "one-talk",
		};
		const resp = yield apiClient.post(API.ONETALK_META_INFO, requestBody);
		yield put(MobilitySlice.setMetaInfo(resp?.data?.data));
	} catch (err) {
		yield put(MobilitySlice.setAPIError(true));
	} finally {
		yield put(MobilitySlice.setPageLoading(false));
	}
}

function* getCombinePricingInfo() {
	yield put(MobilitySlice.setAPIError(false));
	yield put(MobilitySlice.setPageLoading(true));
	try {
		const requestBody = {
			cartMeta: { initFlow: true },
			shoppingPath: "PROSPECT",
			approach: "",
			flow: "one-talk",
		};
		const resp = yield apiClient.post(API.ONETALK_COMBINE_PRICING, requestBody);
		yield put(MobilitySlice.setCombinePricingInfo(resp?.data?.response));
	} catch (err) {
		yield put(MobilitySlice.setAPIError(true));
	} finally {
		yield put(MobilitySlice.setPageLoading(false));
	}
}

function* getSmartphones(params) {
	yield put(MobilitySlice.setAPIError(false));
	yield put(MobilitySlice.setPageLoading(true));
	const sortByValue = window.sessionStorage.getItem("sortBy");

	try {
		const {
			offset,
			pageSize,
			sortBy,
			searchValue,
			pdpDeviceName,
			deviceId,
			deviceFilters,
			categoryName,
			selectedSkuDetails,
			isOneTalk,
			zipCode,
			identifier,
			pdpPersonalisation,
		} = params.payload;
		const requestBody = {
			category: "smartphones",
			sortBy: "featured",
			cartMeta: {
				shoppingPath: "PROSPECT",
				flow: isOneTalk ? "one-talk" : "prospect",
			},
			pageInfo: {
				offset: 0,
				pageSize: 20,
			},
			deviceFilters: {
				paymentList: [],
				categoryList: [
					{
						type: "categoryList",
						key: "PDA/SmartPhones",
						docCount: 0,
						checked: true,
						name: "Smartphones",
					},
				],
				brandList: [],
				colorList: [],
				sorDeviceCategoryList: [],
				compatibilityList: isOneTalk
					? [
							{
								type: "compatibilityList",
								key: "one talk",
								docCount: 0,
								checked: true,
							},
					  ]
					: [],
				preOwnedDevices: [],
				sorSkuTypeList: [],
				technologyTypeList: [],
				basicColorList: [],
			},
			searchValue: "",
		};
		if (isOneTalk) {
			requestBody.oneTalkFlow = isOneTalk;
		}
		const isColorFilter = yield select(
			(state) => state?.MobilityPage?.isColorFilter
		);
		const requestBody1 = {
			cartMeta: {
				shoppingPath: "PROSPECT",
				flow: isOneTalk ? "one-talk" : "prospect",
			},
			deviceRequest: {
				seo: pdpDeviceName,
				category: categoryName || "smartphones",
				deviceId: deviceId,
				sku: selectedSkuDetails?.deviceSku || selectedSkuDetails?.skuId,
				colour: selectedSkuDetails?.color,
				selectedSku: isColorFilter
					? selectedSkuDetails?.sku
					: selectedSkuDetails?.deviceSku ||
					  ((sortByValue == "high" || sortByValue == "low") &&
							selectedSkuDetails?.defaultSku)
					? selectedSkuDetails?.sku
					: undefined,
			},
		};
		selectedSkuDetails?.color == ""
			? delete requestBody1["deviceRequest"]["colour"]
			: requestBody1;

		const url = pdpDeviceName
			? `${API.MOBILITY_SMARTPHONE}`
			: API.MOBILITY_SMARTPHONES;
		const resp = {data:ProductDetails}
		// yield pdpDeviceName
		// 	? apiClient.post(url, requestBody1)
		// 	: apiClient.post(url, requestBody);
		pdpDeviceName
			? yield put(MobilitySlice.setProductDetail(resp?.data))
			: yield put(MobilitySlice.setSmartphones(resp?.data));
		if (
			resp.data.data === null ||
			resp.data.data === undefined ||
			(resp.data.data !== null &&
				resp.data.data &&
				(resp.data.data.deviceDetails === null ||
					resp.data.data.deviceDetails === undefined))
		)
			yield put(MobilitySlice.setError(true));
		else yield put(MobilitySlice.setError(false));
		yield put(MobilitySlice.setProductDetail(resp?.data))
		yield put(MobilitySlice.setPdpDeviceDetails(resp?.data?.data));
	} catch (err) {
		// yield put(MobilitySlice.setAPIError(true));
	} finally {
		yield put(MobilitySlice.setPageLoading(false));
	}
}

function* getProductDetailPageInfo(params) {
	yield put(MobilitySlice.setAPIError(false));
	yield put(MobilitySlice.setPageLoading(true));
	const zipCode = yield select(getZipCode);
	try {
		const {
			deviceName,
			deviceId,
			categoryName,
			selectedSkuDetails,
			isOneTalk,
			identifier,
			pdpPersonalisation,
			bussName
		} = params.payload;
		// TODO: are these calls in parallel or sequential?
		// const forks = [];
		// forks.push(yield fork(getDeskPhones, { payload:
		//   { pdpDeviceName: deviceName }
		// }));
		// forks.push(yield fork(getCombinePricingInfo));
		// yield join([...forks]);
		const payload = {
			pdpDeviceName: deviceName,
			deviceId: deviceId,
			categoryName,
			selectedSkuDetails,
			zipCode: zipCode,
			identifier: identifier,
			pdpPersonalisation: pdpPersonalisation,
			bussName:bussName
		};
		if (isOneTalk) {
			payload.isOneTalk = isOneTalk;
		}
		yield put(MobilitySlice.setFlowType(isOneTalk ? "one-talk" : "prospect"));
		if(bussName) yield call(getBussPDP , {payload:payload})
		else{
		yield call(getSmartphones, {
			payload: payload,
		});
		}
		// yield call(getCombinePricingInfo);
	} catch (err) {
		// yield put(MobilitySlice.setAPIError(true));
	} finally {
		yield put(MobilitySlice.setPageLoading(false));
		// if (window?.newrelic && window?.newrelic?.interaction && !navigator.userAgent.match('CriOS')) {
		//   window?.newrelic?.interaction().end();
		// }
	}
}

function* getReviewsForComparePage({ payload }) {
	try {
		let response = {};
		for (var i = 0; i < payload.length; i++) {
			const reviewUrl =
				API.GET_REVIEWS +
				payload[i].deviceId +
				"&Sort=rating:desc&Limit=1&Offset=1";
			const config = {
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
			};
			const resp = yield apiClient.get(reviewUrl, config);

			if (resp !== null && resp && resp.status === 200) {
				const { TotalResults, Includes } = resp.data;
				let average = "";
				let val = Object.values(Includes["Products"]);
				let secondaryRatings = val[0].ReviewStatistics.SecondaryRatingsAverages;
				Object.keys(Includes["Products"]).map((data) => {
					average = Number(
						Includes["Products"][data]["ReviewStatistics"]["AverageOverallRating"]
					).toFixed(1);
					// chary =  (Includes.Products.data.ReviewStatistics.SecondaryRatingsAverages)
				});
				// let val = Object.values(Object.keys(Includes['Products']))
				response = {
					...response,
					[payload[i].deviceId]: {
						reviews: TotalResults,
						averageRating: average,
						secondaryRatings: secondaryRatings,
					},
				};
			}
			yield put(MobilitySlice.setReviewsForCompareDevices(response));
		}
	} catch (e) {
		console.log(e);
	}
}

function* getReviews(params) {
	yield put(MobilitySlice.setAPIError(false));
	yield put(MobilitySlice.setPageLoading(true));
	try {
		const { productId, limit, offset, sort } = params.payload;
		const reviewUrl =
			API.GET_REVIEWS +
			productId +
			"&Sort=" +
			sort +
			"&Limit=" +
			limit +
			"&Offset=" +
			offset +
			"&Filter=IsRatingsOnly:false";
		const config = {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		};
		const resp = yield apiClient.get(reviewUrl, config);
		yield put(MobilitySlice.setGetReviewsResponse(resp?.data));
	} catch (err) {
		// yield put(MobilitySlice.setAPIError(true));
	} finally {
		yield put(MobilitySlice.setPageLoading(false));
	}
}
function* submitFeedBack(params) {
	try {
		const { vote } = params.payload;
		const reviewUrl = API.GET_FEEDBACK + vote;
		const config = {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		};
		const resp = yield apiClient.post(reviewUrl, {}, config);
	} catch (err) {}
}

function* clearComparedDevicesAct() {
	yield put(MobilitySlice.clearComparedDevices());
}

function* removeComparedDevicesAct(params) {
	const { payload } = params;
	yield put(MobilitySlice.removeComparedDevice(payload));
}

function* getCompareDevices(params) {
	yield put(MobilitySlice.setAPIError(false));
	yield put(MobilitySlice.setPageLoading(true));
	try {
		const compareDeviceRequest =
			params.payload != undefined
				? params.payload
				: yield select(getSelectedDevicesForComparison);

		const apiPayload = {
			cartMeta: { shoppingPath: "PROSPECT", flow: "prospect" },
			compareDeviceRequest,
		};

		const { data } = yield apiClient.post(
			API.MOBILITY_COMPARE_DEVICES,
			apiPayload
		);
		const modifiedData = data?.compareDeviceInfoList
			.filter((data) => (data.deviceId !== null && data.deviceId ? true : false))
			.map((device) => {
				const { seoURL } = compareDeviceRequest?.find(
					(de) => de.deviceId === device.deviceId
				);
				return {
					...device,
					seoURL,
				};
			});
		data.compareDeviceInfoList = modifiedData;
		yield put(MobilitySlice.setProductCompareResp(data));
	} catch (err) {
		yield put(MobilitySlice.setAPIError(true));
	} finally {
		yield put(MobilitySlice.setPageLoading(false));
	}
}

function* addToCart(params) {
	const flow = params?.payload?.flow;
	let exceededCreditLimit = undefined;
	const plansPayload = {
		approach: "",
		flow: flow === "one-talk" ? flow : "prospect",
		shoppingPath: "PROSPECT",
	};
	let productDetailsObj = params.payload.productDetailsObj;
	let planFirstFlag = params.payload.planFirstFlag;
	let imageSet = "";
	delete params?.payload?.productDetailsObj;
	delete params?.payload?.planFirstFlag;
	if (params?.payload?.exceedingCreditLimitData) {
		exceededCreditLimit = params?.payload?.exceedingCreditLimitData;
		imageSet = params?.payload?.imageSets;
		delete params?.payload?.imageSets;
		delete params?.payload?.exceedingCreditLimitData;
	}
	yield put(MobilitySlice.setAPIError(false));
	// yield put(AccessorySlice.setPageLoading(true));
	yield put(MobilitySlice.setPageLoading(true));
	yield put(
		CheckoutSlice.setIsShoppingCartApiCallInProgress({
			apiName: "getFetchCart5G",
			status: true,
		})
	);
	try {
		let requestBody = Object.assign({}, params.payload);
		delete requestBody?.routeToFeatures;
		const isMixedCart = yield select(
			(state) => state?.ProspectWidgets_GlobalData?.sessionCartData?.isMixedCart
		);
		if (isMixedCart) requestBody = { ...requestBody, allowMixedCart: true };
		const response = yield apiClient.post(API.MOBILITY_ADD_TO_CART, requestBody);
		yield call(getSharedCartSave, {
			payload: { shoppingPath: "PROSPECT", fromUCSubmitOrder: false },
		});
		if (planFirstFlag && response?.data?.status?.statusCode == 200) {
			//yield put(PlanFirstSlice.addDeviceToLines(productDetailsObj));
		}
		let sessionCartResponse = {
			prospectFlowData: response?.data?.response,
			serviceStatus: response?.data?.status,
		};
		if (!response?.data?.status?.success) {
			yield put(MobilitySlice.setAddToCartResponse(response?.data));
			throw Object.assign(new Error("Failed to retrieve cart"), {
				status: response?.status,
				data: response?.data,
			});
		} else if (response && response.data && response.data.response) {
			const newStore = Utils.setStoreFromRetrieve(sessionCartResponse);
			window?.localStorage?.setItem("_cartData", JSON.stringify(newStore));
			yield put(GlobalSlice.setSessionCartData(newStore));
		}
		if (exceededCreditLimit && response?.data?.response?.cart?.packages) {
			const key = Object.keys(response?.data?.response?.cart?.packages).find(
				(data) => String(data).includes(exceededCreditLimit)
			);
			yield put(
				MobilitySlice.addedDeviceForExceededCredit({
					...response?.data?.response?.cart?.packages[key],
					imageSet: imageSet,
					packageId: key,
				})
			);
		}
		// const plansResp = yield apiClient.post(API.GET_PLANS_AEM, plansPayload);
		// yield put(PlanSlice.setPlanAEM(plansResp?.data?.data));
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "getFetchCart5G",
				status: false,
			})
		);
		yield put(MobilitySlice.setAddToCartResponse(response?.data));
		if (params?.payload?.routeTo) {
			params?.payload?.routeTo();
		}
		if (params?.payload?.from && params?.payload?.from === "protection") {
			//navigationRef.current("/shop/shopping-cart");
		}
		// yield put(CheckoutSlice.getSessionCart());
		if (params?.payload?.routeToFeatures) {
			yield put(GlobalSlice.setIsEditPlansFlow(true));
			yield put(GlobalSlice.setIsEditFeaturesFlow(true));
			yield put(GlobalSlice.setIsEditPackageType("REGULAR"));
			//navigationRef.current("/shop/features");
		}
	} catch (err) {
		if (params?.payload?.routeToFailure) {
			params?.payload?.routeToFailure();
		}
		yield put(MobilitySlice.setAPIError(true));
	} finally {
		// yield put(AccessorySlice.setPageLoading(false));
		yield put(MobilitySlice.setPageLoading(false));
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "getFetchCart5G",
				status: false,
			})
		);
	}
}

function* clearCartAndAddToCart(params) {
	try {
		yield put(MobilitySlice.setAPIError(false));
		yield put(MobilitySlice.setPageLoading(true));

		const clearCartResp = yield apiClient.post(API.UPDATE_CLEAR_CART, {
			shoppingPath: "PROSPECT",
			approach: "",
			flow: params?.payload?.flow || "prospect",
		});
		// console.debug("cart cleared");
		yield call(addToCart, params);
	} catch (err) {
		yield put(MobilitySlice.setAPIError(true));
	} finally {
		yield put(MobilitySlice.setPageLoading(false));
	}
}

function* removeTradeInDevice(params) {
	yield put(MobilitySlice.setAPIError(false));
	yield put(MobilitySlice.setPageLoading(true));
	try {
		const requestBody = params.payload;
		const resp = yield apiClient.post(API.MOBILITY_REMOVE_TRADE_IN, requestBody);
		yield put(MobilitySlice.setRemoveTradeInDeviceResponse(resp?.data));
		//yield put(TradeInSlice.setTradeInComplete(false));
	} catch (err) {
		yield put(MobilitySlice.setAPIError(true));
	} finally {
		yield put(MobilitySlice.setPageLoading(false));
	}
}

export function* getSharedCartSave(params) {
	yield put(MobilitySlice.setAPIError(false));
	// yield put(MobilitySlice.setPageLoading(true));
	try {
		const requestBody = params.payload;
		const resp = apiClient.post(API.MOBILITY_SHARED_CART_SAVE, requestBody);
	} catch (err) {
		yield put(MobilitySlice.setAPIError(true));
	} finally {
		// yield put(MobilitySlice.setPageLoading(false));
	}
}

function* getGridwallList(params) {
	yield put(MobilitySlice.setAPIError(false));
	yield put(MobilitySlice.setPageLoading(true));
	try {
		const {
			category,
			offset,
			pageSize,
			sortBy,
			deviceFilters,
			searchValue,
			isOneTalk,
			bagxOfferIdList,
		} = params.payload;
		const requestBody = {
			cartMeta: {
				flow: isOneTalk ? "one-talk" : "prospect",
				shoppingPath: "PROSPECT",
			},
			category: category,
			sortBy: sortBy || "featured",
			searchValue: searchValue,
			deviceFilters: deviceFilters,
			pageInfo: {
				offset: offset ?? 0,
				pageSize: pageSize ?? 20,
			},
			bagxOfferIdList,
		};
		if (isOneTalk) {
			requestBody.oneTalkFlow = isOneTalk;
		}
		const resp = {...ProductList}
		//const resp = yield apiClient.post(API.MOBILITY_DEVICE_LIST, requestBody);
		if (resp?.data?.serviceStatus?.success) {
			if (
				(resp?.data && resp.data.data === null) ||
				resp.data.data === undefined ||
				(resp.data.data !== null &&
					resp.data.data &&
					(resp.data.data.deviceList === null ||
						resp.data.data.deviceList === undefined))
			)
				yield put(MobilitySlice.setError(true));
			else yield put(MobilitySlice.setError(false));
			yield put(MobilitySlice.setGridwallList(resp?.data));

			if (
				resp?.data?.data?.deviceFilters?.basicColorList &&
				Object.entries(resp?.data?.data?.deviceFilters?.basicColorList || {})
					?.length
			) {
				const colorList = Object.entries(
					resp?.data?.data?.deviceFilters?.basicColorList || {}
				);
				let filteredColorList = colorList?.filter(
					([_key, color]) => color?.checked === true
				);
				if (filteredColorList?.length > 0) {
					yield put(MobilitySlice.setIsColorFilter(true));
				} else {
					yield put(MobilitySlice.setIsColorFilter(false));
				}
			}
		} else
			throw Object.assign(new Error("Failed to fetch deviceList for Gridwall"), {
				status: resp?.status,
				data: resp?.data,
			});
	} catch (err) {
		// yield put(MobilitySlice.setAPIError(true));
	} finally {
		yield put(MobilitySlice.setPageLoading(false));
	}
}

function* validateZipcode(params) {
	yield put(MobilitySlice.setAPIError(false));
	yield put(MobilitySlice.setPageLoading(true));
	try {
		yield put(MobilitySlice.setValidZipcode(""));
		const { zipcode, isEmailEnable } = params.payload;
		const requestBody = {
			shoppingPath: "PROSPECT",
			approach: "",
			flow: "prospect",
			isEmailEnable: isEmailEnable ? "Y" : "N",
		};
		const resp = yield apiClient.post(
			API.MOBILITY_ZIPCODE + zipcode + "/prospect",
			requestBody
		);
		window.localStorage.setItem("zipCode", zipcode);
		yield put(MobilitySlice.setValidZipcode(resp.data));
		yield put(MobilitySlice.setZipcode(zipcode));
	} catch (err) {
		yield put(MobilitySlice.setAPIError(true));
	} finally {
		yield put(MobilitySlice.setPageLoading(false));
	}
}

function* validateAddress911(params) {
	yield put(MobilitySlice.setPageLoading(true));
	yield put(
		CheckoutSlice.setIsShoppingCartApiCallInProgress({
			apiName: "getFetchCart5G",
			status: true,
		})
	);

	try {
		const data = params.payload;
		let requestBody = {
			e911Address: {
				addressLine1: data?.addressLine1,
				city: data?.city,
				state: data?.state,
				zipCode: data?.zipCode,
				addressLine2: data?.addressLine2,
				type: "NEW",
			},
			shoppingPath: "PROSPECT",
			approach: "",
			flow: "prospect",
		};
		const response = yield apiClient.post(
			API.VALIDATE_EMERGENCY_ADDRESS,
			requestBody
		);
		if (response?.data?.serviceStatus?.success === false) {
			yield put(MobilitySlice.setAPIError(true));
			if (
				response?.data?.serviceStatus?.message.includes(
					"Error while validating the E911 address"
				)
			) {
				yield put(MobilitySlice.setAPIErrorMessage(true));
			} else {
				yield put(MobilitySlice.setAPIErrorMessage(false));
			}
		} else {
			yield put(MobilitySlice.setAPIError(false));
			yield put(MobilitySlice.setAddressEmergencyInfo(response.data));
			yield put(MobilitySlice.setPageLoading(false));
		}
	} catch (err) {
		yield put(MobilitySlice.setAPIError(true));
	} finally {
		yield put(MobilitySlice.setPageLoading(false));
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "getFetchCart5G",
				status: false,
			})
		);
	}
}

function* getStoreAppointmentDetails(params) {
	yield put(MobilitySlice.setAPIError(false));
	yield put(MobilitySlice.setPageLoading(true));
	try {
		const { storeId } = params?.payload;
		const apiPayload = {
			shoppingPath: "PROSPECT",
			approach: "",
			flow: "prospect",
			storeId: storeId,
		};

		const { data } = yield apiClient.post(API.STORE_APPOINMENT, apiPayload);
		yield call(getSalesRepAvailability, data);
		yield put(MobilitySlice.setStoreAppointmentInfo(data));
	} catch (err) {
		yield put(MobilitySlice.setAPIError(true));
	} finally {
		yield put(MobilitySlice.setPageLoading(false));
	}
}

function* getSalesRepAvailability(params) {
	yield put(MobilitySlice.setAPIError(false));
	yield put(MobilitySlice.setPageLoading(true));
	try {
		const { storeDetail } = params;
		const apiPayload = {
			shoppingPath: "PROSPECT",
			approach: "",
			flow: "prospect",
			zip: storeDetail?.zip,
		};

		const { data } = yield apiClient.post(API.SALES_REP_AVAILABILITY, apiPayload);
		yield put(MobilitySlice.setSalesRepAvailability(data));
	} catch (err) {
		yield put(MobilitySlice.setAPIError(true));
	} finally {
		yield put(MobilitySlice.setPageLoading(false));
	}
}

function* getBussProductPlans(params) {
	yield put(MobilitySlice.setAPIError(false));
	yield put(MobilitySlice.setPageLoading(true));
	try {
		const apiPayload = {
			viewOptions: [
				{
					name: "Position",
					value: "1",
				},
			],
			viewId: "DEEPLINKING",
			viewParam: params?.payload?.cartId,
		};
		const { data } = yield apiClient.post(API.GET_BUSS_PRODUCTS, apiPayload);
		if (data?.view) {
			yield put(MobilitySlice.setBussResponse(data.view));
		}
	} catch (err) {
		yield put(MobilitySlice.setAPIError(true));
	} finally {
		yield put(MobilitySlice.setPageLoading(false));
	}
}

function* getStoreScheduleAppointmentDetails(params) {
	yield put(MobilitySlice.setAPIError(false));
	yield put(MobilitySlice.setPageLoading(true));
	try {
		const apiPayload = params.payload;
		const { data } = yield apiClient.post(
			API.STORE_SCHEDULE_APPOINMENT,
			apiPayload
		);
		yield put(MobilitySlice.setStoreScheduleAppoinmentInfo(data));
	} catch (err) {
		yield put(MobilitySlice.setAPIError(true));
	} finally {
		yield put(MobilitySlice.setPageLoading(false));
	}
}

function* getGridwallPegaRecommendation(params) {
	try {
		let pegaPayload = JSON.parse(JSON.stringify(params.payload));
		if (pegaPayload?.payload?.deviceId) {
			delete pegaPayload.payload.deviceId;
		}
		if (pegaPayload?.payload?.deviceSku) {
			delete pegaPayload.payload.deviceSku;
		}
		const response = yield apiClient.post(
			API.GET_GRIDWALL_PEGA_SUGGESTION,
			pegaPayload
		);
		yield put(MobilitySlice.setDeviceSuggestionResp(response?.data));
		if (response?.data?.propositions?.length) {
			let compareDeviceRequest = [];
			compareDeviceRequest = response?.data?.propositions?.map((item) => {
				return {
					deviceId: item?.itemCode,
					deviceOfferAmount: "",
					deviceOfferPercentage: "",
					deviceSku: item?.itemSKU,
					seoURL: "",
				};
			});
			compareDeviceRequest.push({
				deviceId: params?.payload?.deviceId,
				deviceOfferAmount: "",
				deviceOfferPercentage: "",
				deviceSku: params?.payload?.deviceSku,
				seoURL: "",
			});
			const apiPayload = {
				cartMeta: { shoppingPath: "PROSPECT", flow: "prospect" },
				compareDeviceRequest,
			};
			const { data } = yield apiClient.post(
				API.MOBILITY_COMPARE_DEVICES,
				apiPayload
			);
			const modifiedData = data?.compareDeviceInfoList?.map((device) => {
				const tempDevice = compareDeviceRequest?.find(
					(de) => de.deviceId === device.deviceId
				);
				return {
					...device,
					seoURL: tempDevice?.seoURL || "",
				};
			});
			data.compareDeviceInfoList = modifiedData;
			yield put(MobilitySlice.setPegaRecommendations(data));
		}
	} catch (err) {
		console.log("pega recommendation failed", err);
	}
}

function* sendEmailDetails(params) {
	const requestBody = {
		emailId: params.payload,
		throttleEnabled: true,
	};
	const response = yield apiClient.post(API.SEND_EMAIL_THROTTLE, requestBody);
}
function* getPurchaseInfo(params){
	try{
		const response = {data:PurchaseInfo}
		yield put(MobilitySlice.setPurchaseInfo(response.data))
	}catch(e){
		console.log(e)
	}finally{

	}

}

export default function* actionWatcher() {
	yield takeLatest(MobilitySlice.getInitPageLoadInfo.type, getInitPageLoadInfo);
	yield takeLatest(MobilitySlice.getMetaInformation.type, getMetaInformation);
	yield takeLatest(
		MobilitySlice.getCombinePricingInfo.type,
		getCombinePricingInfo
	);
	yield takeLatest(MobilitySlice.getSmartphones.type, getSmartphones);
	yield takeLatest(
		MobilitySlice.getProductDetailPageInfo.type,
		getProductDetailPageInfo
	);
	yield takeLatest(
		MobilitySlice.getCarrierDetailsByDevice.type,
		getCarrierDetailsByDevice
	);
	yield takeLatest(MobilitySlice.getPlans.type, getPlans);
	yield takeLatest(MobilitySlice.getCompareDevices.type, getCompareDevices);
	yield takeLatest(MobilitySlice.addToCart.type, addToCart);
	yield takeLatest(MobilitySlice.getSharedCartSave.type, getSharedCartSave);
	yield takeLeading(MobilitySlice.getGridwallList.type, getGridwallList);
	yield takeLatest(MobilitySlice.getReviews.type, getReviews);
	yield takeLatest(MobilitySlice.validateZipcode.type, validateZipcode);
	yield takeLatest(MobilitySlice.updateValidDevices.type, updateValidDevices);
	yield takeLatest(MobilitySlice.removeTradeInDevice.type, removeTradeInDevice);
	yield takeLatest(
		MobilitySlice.getPlansForValiDevices.type,
		getPlansForValidDevices
	);
	yield takeLatest(
		MobilitySlice.removeComparedDevicesAct.type,
		removeComparedDevicesAct
	);
	yield takeLatest(
		MobilitySlice.clearComparedDevicesAct.type,
		clearComparedDevicesAct
	);
	yield takeLatest(MobilitySlice.validateAddress911.type, validateAddress911);
	yield takeLatest(
		MobilitySlice.clearCartAndAddToCart.type,
		clearCartAndAddToCart
	);
	yield takeLatest(
		MobilitySlice.getStoreAppointmentDetails.type,
		getStoreAppointmentDetails
	);
	yield takeLatest(
		MobilitySlice.getSalesRepAvailability.type,
		getSalesRepAvailability
	);
	yield takeLatest(
		MobilitySlice.getStoreScheduleAppointmentDetails.type,
		getStoreScheduleAppointmentDetails
	);
	yield takeLatest(MobilitySlice.getFeedBack.type, submitFeedBack);
	yield takeLatest(
		MobilitySlice.getReviewsForComparePage.type,
		getReviewsForComparePage
	);
	yield takeLatest(MobilitySlice.getBussProductPlans.type, getBussProductPlans);
	yield takeLatest(
		MobilitySlice.getGridwallPegaRecommendation.type,
		getGridwallPegaRecommendation
	);
	yield takeLatest(MobilitySlice.sendEmailDetails.type, sendEmailDetails);
	yield takeLatest(MobilitySlice.getPurchaseInfo.type, getPurchaseInfo);
}
