import React, {
	useEffect,
	useState,
	useMemo,
	useCallback,
	useRef,
	Suspense,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Modal, ModalBody, ModalTitle } from "@vds3/modals";
import { Notification } from "@vds3/notifications";
import { Body } from "@vds3/typography";
import { Grid, Row, Col } from "@vds3/grids";
import { DropdownSelect } from "@vds3/selects";
import { Button } from "@vds3/buttons";
import { useMediaQuery } from "react-responsive";
import PromoBannerLarge from "common/components/PromoBanner/PromoBannerLarge";
import ErrorBoundary from "common/components/ErrorBoundary/ErrorBoundary";

import SpinnerComponent from "common/components/Spinner/Spinner";

import {
	GridwallFilterBar,
	GridwallFilterBoxes,
} from "../MobilityGridwall/GridwallFilterBar";
import useToggle from "../../../../hooks/useToggle";
import useStickyHeaderHeight from "hooks/useStickyHeaderHeight";
import useGlobalData from "hooks/useGlobalData";
//import * as SiteAnalytics from "../../../../sitecatalyst";
import { getProductType } from "utils/commonUtils";
import { MarginSpacer2XL, MarginSpacerXL } from "common/components/styleTag";
import {
	FLOWS,
	setGWPlansID,
	setGWDeviceType,
	setLoadCheckoutWidget,
	setLoadShoppingCartWidget,
	setLoadMobilityProductDetails,
	setSelectedGWProduct,
} from "app/ducks/GlobalSlice";
import {
	getGridwallList,
	setCategoryName,
	setCompareDevices,
	setDeviceId,
	setGridwallList,
	setSelectedSku,
	setSkuData,
	setDeviceName,
	setBrandsList,
	setFilterSelected,
	setProductDetail,
	setPagination,
} from "../../ducks/MobilitySlice";
import GridwallTabs from "./GridwallTabs";
import GridwallHeader from "./GridwallHeader";
import styled from "styled-components";

import "./MobilityGridwall.css";
import {
	GridwallTabBar,
	GridwallContainer,
	GridwallStickyTabBar,
} from "./styles";
import SEOFooter from "./SEOFooter/SEOFooter";
import GridwallCompare from "./GridwallCompare";
import StickyHeaderVds3 from "common/components/StickyHeader/StickyHeaderVds3";
import Gridwall from "./Gridwall/Gridwall";
import PromoBanner from "common/components/PromoBanner/PromoBanner";
import { Input } from "@vds3/inputs";
import { bussGridwall } from "pages/BussProductsPage/ducks/BussProductSlice";
import { Pagination } from "@vds3/pagination";
import SearchInput from "common/components/SearchInput/SearchInput";


// const SEOFooter = import("./SEOFooter/SEOFooter");
// const GridwallCompare = import("./GridwallCompare");
// const StickyHeaderVds3 =
// 	import("common/components/StickyHeader/StickyHeaderVds3");
// const Gridwall = import("./Gridwall/Gridwall");
// const PromoBanner = 
// 	import("common/components/PromoBanner/PromoBanner");

const MobilityGridwall = (props) => {
	const [brandFilter, setBrandFilter] = useState(props.brandFilter);
	//const [searchParams, setSearchParams] = useSearchParams();
	const { t, ready } = useTranslation(["gridwall"], { useSuspense: false });
	const aem = t("gridwall", { ns: "gridwall", returnObjects: true }) || {};
	//const navigate = useNavigate();
	//const location = useLocation();
	const dispatch = useDispatch();
	const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
	const pageData = useSelector((state) => state.ProspectWidgets_MobilityData);
	const length =
		pageData?.compareDevices && Object.keys(pageData.compareDevices).length;
	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	const bogoOfferId = pageData?.bogoOfferId;
	const isBogo = false;
	//searchParams.get("type") && searchParams.get("type") === "GET" && bogoOfferId;
	const pageNumParam = 1;
	const gridwallRef = useRef();
	const [showFilters, toggleFilters] = useToggle(false);
	const [showFiltersTypes, toggleFiltersTypes] = useToggle(false);
	const [showCartDetailsModal, setshowCartDetailsModal] = useState(false);
	const [pageNumClicked, setPageNum] = useState(null);
	const [showSeoFooter, setShowSeoFooter] = useState(false);
	const [seoFooterData, setSeoFooterData] = useState(null);
	const [filterData, setFilterData] = useState({
		brandList: {
			type: "brandList",
			key: "",
		},
		simTypeList: [{ key: "eSim", name: "eSIM" }],
	});
	const [clearAll, showClearAll] = useState(false);

	useEffect(() => {
		let brandListData = filterData["brandList"];
		let list = [];
		if (brandListData instanceof Map) {
			for (const [key, value] of brandListData) {
				list.push({ key });
			}
		}
		if (list.length > 1) dispatch(setBrandsList(list));
	}, [filterData]);

	const isMobile = useMediaQuery({
		query: "(max-width:545px)",
	});

	const categoryList = sessionStorage.getItem("categoryList");
	let isSamePage = false;
	const [deviceFilters, setDeviceFilters] = useState({
		brandList:
			brandFilter ?? props.brandFilter
				? [
					{
						type: "brandList",
						key: brandFilter ?? props.brandFilter,
						docCount: 0,
						checked: true,
					},
				]
				: pageData?.filterApplied?.brandList && (isSamePage || !props.category)
					? pageData?.filterApplied?.brandList
					: [],
		simTypeList: props?.simFilter
			? [
				{
					type: "simTypeList",
					key: props.simFilter,
					docCount: 0,
					checked: true,
				},
			]
			: pageData?.filterApplied?.simTypeList && (isSamePage || !props.category)
				? pageData?.filterApplied?.simTypeList
				: [],
		colorList: pageData?.filterApplied?.colorList
			? pageData?.filterApplied?.colorList
			: [],
		categoryList:
			props.isOneTalk || props.is5gsmartphones
				? [
					{
						type: "categoryList",
						key: "PDA/SmartPhones",
						docCount: 0,
						checked: true,
						name: "Smartphones",
					},
				]
				: pageData?.filterApplied?.categoryList &&
					!props?.brandFilter &&
					props?.category === "all"
					? pageData?.filterApplied?.categoryList
					: [],
		sorDeviceCategoryList: pageData?.filterApplied?.sorDeviceCategoryList
			? pageData?.filterApplied?.sorDeviceCategoryList
			: [],
		compatibilityList: props.isOneTalk
			? [
				{
					checked: true,
					docCount: 0,
					key: "one talk",
					type: "compatibilityList",
				},
			]
			: pageData?.filterApplied?.compatibilityList
				? pageData?.filterApplied?.compatibilityList
				: [],
		preOwnedDevices: pageData?.filterApplied?.preOwnedDevices
			? pageData?.filterApplied?.preOwnedDevices
			: [],
		sorSkuTypeList: String(window.location.pathname).includes(
			"certified-pre-owned"
		)
			? [
				{
					imageUrl: null,
					key: "OCPO",
					docCount: 11,
					checked: true,
					name: null,
					type: "sorSkuTypeList",
				},
			]
			: pageData?.filterApplied?.sorSkuTypeList
				? pageData?.filterApplied?.sorSkuTypeList
				: [],
		technologyTypeList: pageData?.filterApplied?.technologyTypeList
			? pageData?.filterApplied?.technologyTypeList
			: [],
		basicColorList: pageData?.filterApplied?.basicColorList
			? pageData?.filterApplied?.basicColorList
			: [],
	});

	const sortByValue = window.sessionStorage.getItem("sortBy");
	const [sortBy, setSortBy] = useState(
		pageData?.deviceSelectedForExceedginCreditLimit !== null &&
			pageData?.deviceSelectedForExceedginCreditLimit
			? sortDeviceOptions[1].value
			: sortByValue !== undefined
				? sortByValue
				: sortDeviceOptions[0].value
	);
	const [searchValue, setSearchValue] = useState("");
	const [deviceList, setDeviceList] = useState([]);
	const [setDeviceTabForOCPO, handleDeviceTab] = useState([]);
	const [totalResults, setTotalResults] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const [showCompareLimitModal, setShowCompareLimitModal] = useState(false);
	const [gridPage, setGridPage] = useState(1);
	const [currentPath, setCurrentPath] = useState("");
	const [showByodModal, toggleByodModal] = useToggle();
	const [viewResults, setViewResults] = useState(false);
	const [loadEmptyDataContainer, handleLoadContainer] = useState(false);
	const [dataReady, setDataReady] = useState(false);
	const { metaInformation } = useGlobalData({ skipSessionCart: true });
	const { stickyHeaderRef, headerHeight } =
		useStickyHeaderHeight(sessionCartData);

	const isOneTalk = props.isOneTalk ? props.isOneTalk : false;
	const is5gsmartphones = props.is5gsmartphones ?? false;
	const isTabLayout = String(props?.layout).includes("tabs");
	const isSearchLayout = String(props?.layout).includes("search");

	const selectedCompare = useMemo(
		() => Object.keys(pageData?.compareDevices ?? {}),
		[pageData?.compareDevices]
	);

	const isPlanFirstFlow = useMemo(
		() =>
			globalData?.flow === FLOWS.PLANFIRST &&
			Object.values(globalData?.sessionCartData?.devices || {})?.filter(
				(i) => i?.deviceFullDetail?.dummy
			)?.length,
		[globalData?.flow, globalData?.sessionCartData?.devices]
	);

	const handleImgClick = () => {
		toggleByodModal();
	};

	// const getUpdateSearchParams = () => {
	// 	let paramsPlanId = searchParams.get("planId")?.match(/\d+/)[0];
	// 	if (paramsPlanId) {
	// 		dispatch(setGWPlansID(paramsPlanId));
	// 	}
	// 	let paramsDeviceType = searchParams.get("deviceType");
	// 	if (paramsDeviceType) {
	// 		dispatch(setGWDeviceType(paramsDeviceType));
	// 	}
	// };

	const PAGE_SIZE = window?.mbtGlobal?.gwpagesize || 14;
	const BASE_PATH = "/shop/products/devices/";
	const globalPromos = metaInformation?.globalPromos?.gridwall;
	const isLoading = pageData?.loading;
	const isLaptopSelected = deviceFilters?.categoryList?.find(
		(i) => i?.checked && String(i?.key)?.toLowerCase()?.includes("laptop")
	);

	const byodCard = useMemo(() => {
		const cat = deviceList?.[0]?.category?.includes("BroadbandAccess Devices")
			? "mobile-hotspots"
			: props.category;
		return {
			title: t("gridwall.byod.byod-card-heading", { ns: "gridwall" }),
			description: t("gridwall.byod.use-your-own-device", { ns: "gridwall" }),
			handleImgClick,
			promo: globalPromos?.byodTile?.heading,
			position: Number(
				t(`gridwall.byod.reactPosition.${cat}`, { ns: "gridwall" })
			),
		};
	}, [globalPromos, props?.category, ready, deviceList]);

	// useEffect(() => {
	// 	if (
	// 		props.isCategory &&
	// 		(sessionStorage.getItem("categoryList") === undefined ||
	// 			sessionStorage.getItem("categoryList") === null)
	// 	)
	// 		sessionStorage.setItem("categoryList", props.category);
	// 	initGridwallData();
	// 	setCurrentPath(location.pathname.replace(BASE_PATH, ""));
	// }, [location]);
	useEffect(() => {
		if (props?.fromBUSS) dispatch(bussGridwall())
	}, [])
	useEffect(() => {
		let data = { ...aem?.seoBodyCopy };
		let keyFound = false;
		if (data && Object.keys(data).length > 0) {
			Object.keys(data).forEach((key) => {
				if (
					window?.location?.href?.indexOf(key) > -1 &&
					window?.location?.href?.lastIndexOf(key) + key?.length ===
					window?.location?.href?.length
				) {
					keyFound = true;
					setShowSeoFooter(true);
					setSeoFooterData(aem?.seoBodyCopy[key]);
				}
			});
			if (!keyFound) {
				setShowSeoFooter(false);
				setSeoFooterData(null);
			}
		}
	}, [ready, props.category, deviceFilters]);

	const listOfPaths = ["laptops", "mobile-hotspots", "smart-watches"];
	const pathnotIncluded = false;
	//  !listOfPaths.some((item) =>
	// 	location.pathname.includes(item)
	// );

	useEffect(() => {
		handleLoadContainer(false);
		window.sessionStorage.setItem("_flowName", "Device");
		//SiteAnalytics.trackPageInfo();
	}, []);

	useEffect(() => {
		if (
			((props.isCategory && isSamePage) || !props.isCategory) &&
			pageData?.filterApplied !== null &&
			pageData?.filterApplied
		)
			setDeviceFilters({
				...pageData?.filterApplied,
				categoryList: props.isCategory
					? []
					: pageData?.filterApplied?.categoryList || [],
				brandList: props?.brandFilter
					? [
						{
							type: "brandList",
							key: props?.brandFilter,
							docCount: 0,
							checked: true,
						},
					]
					: pageData?.filterApplied?.brandList || [],
				simTypeList: props?.simFilter
					? [
						{
							type: "simTypeList",
							key: props?.simFilter,
							docCount: 0,
							checked: true,
						},
					]
					: pageData?.filterApplied?.simTypeList || [],
			});
	}, [pageData?.filterApplied, ready]);

	useEffect(() => {
		// append the newly fetched devices to the list
		if (pageData?.gridwall?.serviceStatus?.success) {
			handleLoadContainer(true);
			if (pageData?.gridwall?.data?.deviceList?.length) {
				const pageInfo = pageData?.gridwall?.data?.pageInfo;
				const pageNum = pageInfo?.pageSize
					? pageInfo?.offset / pageInfo?.pageSize + 1
					: 1;
				const list = [];
				list.push(...(pageData?.gridwall?.data?.deviceList || []));
				setGridPage(pageNum);
				setDeviceList(list);
				parseFilterData(pageData?.gridwall?.data?.deviceFilters);
				setTotalResults(pageData?.gridwall?.data?.deviceList?.length);
				setHasMore(list.length <= pageData?.gridwall?.data?.totalHits);
				if (
					setDeviceTabForOCPO.length == 0 &&
					props.cpoFilter !== null &&
					props.cpoFilter &&
					props.cpoFilter === "OCPO" &&
					pageData?.gridwall?.data?.deviceFilters !== null &&
					pageData?.gridwall?.data?.deviceFilters &&
					Object.keys(pageData?.gridwall?.data?.deviceFilters).length > 0
				) {
					const lowerCaseData = [...aem?.ocpoTabs].map((data) =>
						String(data).toLowerCase()
					);
					const { deviceFilters } = pageData?.gridwall?.data;
					const { brandList } = deviceFilters;
					let resultData = ["All"];
					brandList.map((data) => {
						if (
							lowerCaseData.includes(String(data.key).toLowerCase()) &&
							data.docCount > 0
						)
							resultData.push(data.key);
					});
					handleDeviceTab(resultData);
				}
				//dispatch(setGridwallList({}));
				window.scrollTo(0, 0);
				setDataReady(true);
				// if (pageNum === 1) {
				//   gridwallRef.current.scrollIntoView();
				// }
			} else {
				setHasMore(false);
				//dispatch(setGridwallList({}));
				setDeviceList([]);
				setTotalResults(0);
			}
			setSearchValue("");
		} else if (
			pageData?.gridwall?.serviceStatus &&
			pageData?.gridwall?.serviceStatus?.success === false
		) {
			setDataReady(true);
		}
	}, [pageData?.gridwall]);

	// Setting query Params for Page
	const sanitizeNumber = (x) => {
		if (!x || isNaN(x)) {
			return 1;
		} else if (Number.isSafeInteger(Number(x))) {
			return Number(x);
		} else {
			return 1;
		}
	};

	// Setting query Params for Page
	useEffect(() => {
		if (pageNumClicked) {
			const params = 1; //searchParams;
			// if (
			// 	params.get("page") !== null &&
			// 	params.get("page") &&
			// 	Number(pageNumClicked) !== 1 &&
			// 	Number(pageNumClicked) != Number(params.get("page"))
			// ) {
			// 	params.set("page", sanitizeNumber(pageNumClicked));
			// 	//setSearchParams(params);
			// 	//getUpdateSearchParams();
			// }
		}
	}, [pageNumClicked]);

	const waitforBtnClick = false;

	if (waitforBtnClick) {
		useEffect(() => {
			if (Object.keys(filterData)?.length || viewResults) fetchNextItems();
		}, [sortBy, viewResults]);
	} else {
		useEffect(() => {
			if (Object.keys(filterData)?.length) fetchNextItems();
		}, [sortBy, deviceFilters]);
	}
	const getFilterPayload = ({ filter, redFilter }) => {
		let filterResult = {};
		let keyForFilter = [];
		if (filter !== null && filter && Object.keys(filter).length)
			keyForFilter = [...keyForFilter, ...Object.keys(filter)];
		if (redFilter !== null && redFilter && Object.keys(redFilter).length)
			keyForFilter = [...keyForFilter, ...Object.keys(redFilter)];
		keyForFilter.forEach((key) => {
			if (redFilter?.[key] && Object.values(redFilter[key]).length)
				filterResult = { ...filterResult, [key]: redFilter[key] };
			if (filter?.[key]) filterResult = { ...filterResult, [key]: filter[key] };
		});
		return filterResult;
	};
	const fetchNextItems = useCallback(
		(pageNum = pageNumParam, filters = deviceFilters) => {
			const requestBody = {
				category: props.category,
				offset: (pageNum - 1) * PAGE_SIZE, // pages start with 1
				pageSize: PAGE_SIZE,
				sortBy: sortBy,
				deviceFilters: getFilterPayload({
					filter: filters,
					redFilter: pageData?.filterApplied,
				}),
				searchValue: searchValue,
				isOneTalk: !!props.isOneTalk,
				bagxOfferIdList: isBogo && bogoOfferId ? [bogoOfferId] : [],
			};
			if (!props?.fromBUSS) dispatch(getGridwallList(requestBody));
			setViewResults(false);
			setPageNum(pageNum);
		},
		[
			props.category,
			sortBy,
			deviceFilters,
			searchValue,
			props.isOneTalk,
			isBogo,
			bogoOfferId,
		]
	);

	const initGridwallData = () => {
		if (
			categoryList != null &&
			categoryList &&
			props.isCategory &&
			categoryList === props.category
		)
			isSamePage = true;
		else if (
			categoryList != null &&
			categoryList &&
			categoryList !== props.category &&
			props.isCategory
		) {
			sessionStorage.setItem("categoryList", props.category);
			isSamePage = false;
		}
		let filters = {};
		if (props.isCategory && !isSamePage)
			dispatch(setFilterSelected({ ...filters }));
		else filters = { ...filters, ...pageData?.filterApplied };
		if (props.brandFilter) {
			filters["brandList"] = [
				{
					type: "brandList",
					key: props.brandFilter,
					docCount: 0,
					checked: true,
				},
			];
		}
		if (props.is5gsmartphones) {
			filters["categoryList"] = [
				{
					type: "categoryList",
					key: "PDA/SmartPhones",
					docCount: 0,
					checked: true,
					name: "Smartphones",
				},
			];
		}
		if (props.cpoFilter) {
			filters["sorSkuTypeList"] = [
				{
					type: "sorSkuTypeList",
					key: props.cpoFilter,
					docCount: 0,
					checked: true,
				},
			];
		}
		if (props.simFilter) {
			filters["simTypeList"] = [
				{
					type: "simTypeList",
					key: props.simFilter,
					docCount: 0,
					checked: true,
				},
			];
		}
		setDeviceFilters(filters);
	};

	const onGridwallClickHandler = useCallback(
		(seoUrlName, deviceId, selectedSku, category, name, groupId) => {
			if (groupId) dispatch(setSelectedGWProduct(groupId))
			dispatch(setProductDetail(""));
			dispatch(setDeviceId(deviceId));
			dispatch(setSelectedSku(selectedSku));
			dispatch(setSkuData(selectedSku));
			dispatch(setDeviceName(seoUrlName));
			const catName = getProductType(category);
			dispatch(setCategoryName(catName));
			dispatch(setLoadMobilityProductDetails(true));
			// 	let url = location?.pathname?.replace("/shop", "");
			// 	if (props?.category === "all") {
			// 		if (ready) {
			// 			const brandTabVals = new Set([
			// 				...aem?.categoryTabs,
			// 				...aem?.tabletTabs,
			// 				...aem?.ocpoTabs,
			// 			]);
			// 			for (const c of brandTabVals) {
			// 				if (url.includes(`/${String(c)?.toLowerCase()}`)) {
			// 					url = url.replace(`/${String(c)?.toLowerCase()}`, `/${catName}`);
			// 				}
			// 			}
			// 		}
			// 	}
			// 	if (url.includes("/certified-pre-owned")) {
			// 		url = url.replace("/certified-pre-owned", `/${catName}`);
			// 	}
			// 	const deviceCat = [
			// 		"/apple",
			// 		"/samsung",
			// 		"/motorola",
			// 		"/google",
			// 		"/kyocera",
			// 		"/tcl",
			// 	];
			// 	deviceCat.forEach((dev) => {
			// 		if (String(url).includes(dev)) url = url.replace(dev, "/");
			// 	});
			// 	const simCat = ["/esim"];
			// 	simCat.forEach((i) => {
			// 		if (String(url).includes(i)) url = url.replace(i, "/");
			// 	});
			// 	const simType = deviceFilters?.simTypeList?.find(
			// 		(i) => i?.checked === true
			// 	)?.key;
			// 	navigate(`${url}/${seoUrlName}${isBogo ? "?type=GET" : ""}`, {
			// 		state: {
			// 			color: selectedSku?.cssColor,
			// 			simType,
			// 		},
			// 	});
		},
		[ready, aem, props?.category, deviceFilters]
	);

	const parseFilterData = (filters) => {
		if (!Object.keys(filters)?.length) return;
		const result = {};
		for (const filterList of Object.keys(filters)) {
			if (Array.isArray(filters[filterList])) {
				const filterMap = new Map();
				for (const f of filters[filterList]) {
					filterMap.set(f.key, { ...f, type: filterList });
				}
				result[filterList] = filterMap;
			}
		}
		setFilterData(result);
	};

	const removeCompareDevice = (deviceId) => {
		const selected = pageData?.compareDevices ?? {};
		let newSelected = { ...selected };
		delete newSelected[deviceId];
		dispatch(setCompareDevices(newSelected));
	};

	const onCompareSelect = useCallback(
		(item) => {
			const selected = pageData?.compareDevices ?? {};
			const deviceId = item?.deviceId;
			if (Object.keys(selected).includes(deviceId)) {
				removeCompareDevice(deviceId);
			} else if (Object.keys(selected)?.length < 4) {
				let newSelected = { ...selected };
				newSelected[deviceId] = item;
				dispatch(setCompareDevices(newSelected));
			} else {
				setShowCompareLimitModal(true);
			}
		},
		[pageData?.compareDevices]
	);

	useEffect(() => {
		let clearAllLoc = false;
		if (deviceFilters.paymentList == "") {
			setDeviceFilters({ ...deviceFilters });
		}
		if (
			deviceFilters === null ||
			deviceFilters === undefined ||
			Object.keys(deviceFilters).length === 0
		)
			clearAllLoc = false;
		else if (
			deviceFilters !== null &&
			deviceFilters &&
			Object.keys(deviceFilters).length
		) {
			let count = 0;
			Object.values(deviceFilters).forEach((data) => {
				if (data.length === 0 || data === undefined || data === null) count++;
			});
			if (count === Object.keys(deviceFilters).length) clearAllLoc = false;
			else clearAllLoc = true;
		}
		showClearAll(clearAllLoc);
	}, [deviceFilters, ready]);

	const onClearFilter = () => {
		if (props.brandFilter || props.simFilter) {
			resetToBasePath();
		}
		setCurrentPath("all");
		setBrandFilter("All");
		setDeviceFilters({
			sorSkuTypeList: String(window.location.pathname).includes(
				"certified-pre-owned"
			)
				? [
					{
						imageUrl: null,
						key: "OCPO",
						docCount: 11,
						checked: true,
						name: null,
						type: "sorSkuTypeList",
					},
				]
				: [],
		});
		dispatch(
			setFilterSelected({
				sorSkuTypeList: String(window.location.pathname).includes(
					"certified-pre-owned"
				)
					? [
						{
							imageUrl: null,
							key: "OCPO",
							docCount: 11,
							checked: true,
							name: null,
							type: "sorSkuTypeList",
						},
					]
					: [],
			})
		);
	};
	// VBGHSA-19919 Implementation by removing Payment Type
	const filterAll = [
		{
			name: aem?.labels?.frpFilterTitle,
			value: "priceList",
		},
		{
			name: aem?.labels?.simFilterTitle,
			value: "simTypeList",
		},
		{
			name: aem?.labels?.Category,
			value: "categoryList",
		},
		{
			name: aem?.labels?.brand,
			value: "brandList",
		},
		{
			name: aem?.labels?.color2,
			value: "basicColorList",
		},
		{
			name: aem?.labels?.Capability,
			value: "compatibilityList",
		},
		{
			name: aem?.labels?.preOwnedDevices,
			value: "sorSkuTypeList",
		},
	];

	const resetToBasePath = () => {
		let path = String(window.location.pathname)
			.split("/")
			.filter((i) => !!i);
		path.pop();
		let pathRedirection = path[path.length - 1];
		if (path[path.length - 1] === "devices") pathRedirection = "all";
		setCurrentPath("all");
		//navigate(BASE_PATH + pathRedirection);
	};
	const sortByDisplay = () => {
		return (
			<StyledDropDownList>
				<DropdownSelect
					label={aem?.labels?.sortBy || "Sort by:"}
					onChange={(e) => {
						setSortBy(e.target.value);
						window.sessionStorage.setItem("sortBy", e.target.value);
					}}
					inlineLabel={true}
				>
					{sortDeviceOptions.map((item, index) => (
						<option value={item.value} key={item.value}>
							{item.name}
						</option>
					))}
				</DropdownSelect>
			</StyledDropDownList>
		)
	}
	const handleSearch = (val) => {
		//const allowShow = (!search || (search && String(item?.cartTitle).toLowerCase().includes(search.toLowerCase()))) 
		let newDeviceList = [];
		newDeviceList = deviceList.filter(item => String(item?.name).toLowerCase().includes(val.toLowerCase()))
		if (!val.length) newDeviceList = pageData?.gridwall?.data?.deviceList;
		setDeviceList(newDeviceList);
	}
	return (
		<ErrorBoundary>
			<MobilityGridwallWrap>
				{ready && isLoading && <SpinnerComponent />}
				{/* <SEOTagger pageName={"prospect"} /> */}
				<Suspense fallback={<></>}>
					{globalPromos && currentPath && (
						<PromoBanner promoData={globalPromos[currentPath] ?? globalPromos.page} />
					)}
				</Suspense>
				<Suspense fallback={<></>}>
					{sessionCartData?.cart?.packages &&
						Object.keys(sessionCartData?.cart?.packages).length ? (
						<StickyHeaderVds3
							onCartClick={() => {
								dispatch(setLoadShoppingCartWidget(true));
								//navigate("/shop/shopping-cart");
							}}
							disabledContinueBtn={true}
							hideAddress={false}
							hideContinueButton={true}
							enableBackLink={!!isBogo}
							backClickHandler={() => { }}
							pdp={true}
							notSticky={isMobile}
							hideShoppingBagIcon={isPlanFirstFlow}
						/>
					) : (
						<></>
					)}
				</Suspense>
				<GridwallContentWrap className="content" ref={gridwallRef}>
					<GridwallHeader
						currentPath={currentPath}
						metaInformation={metaInformation}
						isOneTalk={isOneTalk}
						is5gsmartphones={is5gsmartphones}
						isSearchLayout={isSearchLayout}
						isTabLayout={isTabLayout}
						isBogo={isBogo}
						totalResults={totalResults}
						searchValue={searchValue}
						setSearchValue={setSearchValue}
						fetchNextItems={fetchNextItems}
						toggleFilters={toggleFilters}
						showCartDetailsModal={showCartDetailsModal}
						setshowCartDetailsModal={setshowCartDetailsModal}
						hideBreadcrumbs={false}
						isBuss={props?.fromBUSS}
					/>
					{/* <MarginSpacerXL /> */}
					{true && (
						<GridwallStickyTabBar headerHeight={headerHeight}>
							<GridwallTabBar>
								<GridwallTabs
									category={props.category}
									basePath={BASE_PATH}
									currentPath={currentPath}
									cpoFilter={props.cpoFilter}
									deviceFilters={deviceFilters}
									isOCPO={props?.cpoFilter === "OCPO"}
									deviceTabForOCPO={setDeviceTabForOCPO}
									brandFilter={brandFilter ?? props.brandFilter}
									setBrandFilter={setBrandFilter}
								/>
							</GridwallTabBar>
						</GridwallStickyTabBar>
					)}
					{aem?.["promo-banner"] && aem?.["promo-banner"]?.[currentPath] ? (
						<GridwallHeaderWrap className="full-width">
							<PromoBannerLarge promoData={aem?.["promo-banner"]?.[currentPath]} />
						</GridwallHeaderWrap>
					) : (
						<></>
					)}

					<GridwallContainer>
						{pageData?.apiError && (
							<Notification
								type="error"
								title={t("gridwall.err_addUpdate", { ns: "gridwall" })}
								fullBleed={true}
								inlineTreatment={false}
								className="notification"
							/>
						)}
						{!isBogo ? (
							<>
							<div className="desktop-vw">
							<Grid
							bleed="1440"
							colSizes={{
								mobile: 1,
								tablet: 1,
								desktop: 3,
							}}
						>
							<RowNoPad>
								<Col colSizes={{mobile: 0,	tablet: 0,	desktop: 3,	}}></Col>
								<Col colSizes={{mobile: 4,tablet: 12,desktop: 9}}>
							
							 {/* <FilterSortWrap
							 	headerHeight={headerHeight}
							 	className="full-width top-pad bottom-pad gridwall-header"
							 > */}
								{/* <SortByWrapper>
									<SortByDropdown>
										{false && <Body size="large">
											{`${totalResults ?? 0} ${t("gridwall.labels.results", {
												ns: "gridwall",
											})}`}
										</Body>}

										{isMobile ? (
											<MobFilterSortWrap>
												<Button
													size="small"
													disabled={false}
													use="secondary"
													onClick={toggleFiltersTypes}
												>
													Filter
												</Button>
												<Button
													size="small"
													disabled={false}
													use="secondary"
													onClick={toggleFilters}
												>
													Sort
												</Button>
											</MobFilterSortWrap>
										) : (
											<></>
										)}
										 {!isMobile ? (
											<StyledDropDownList>
												<DropdownSelect
													label={aem?.labels?.sortBy || "Sort by:"}
													onChange={(e) => {
														setSortBy(e.target.value);
														window.sessionStorage.setItem("sortBy", e.target.value);
													}}
													inlineLabel={true}
												>
													{sortDeviceOptions.map((item, index) => (
														<option value={item.value} key={item.value}>
															{item.name}
														</option>
													))}
												</DropdownSelect>
											</StyledDropDownList>
										) : (
											<></>
										)} 
									</SortByDropdown>
								</SortByWrapper> */}

								<FilterBoxWrap>
									{!ready ? (
										<></>
									) : (
										<div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%'}}>
										<div style={{maxWidth:"65%"}} >
										<GridwallFilterBoxes
											filters={deviceFilters}
											filterData={filterData}
											brandFilter={brandFilter || props.brandFilter}
											setBrandFilter={setBrandFilter}
											// brandFilter={props.brandFilter}
											simFilter={props.simFilter}
											setFilters={(val, refetch) => {
												setDeviceFilters(val);
												dispatch(setFilterSelected(val));
												if (refetch) {
													fetchNextItems(pageNumParam, val);
												}
											}}
											// showClearAll={(val) => showClearAll(val)}
											setCurrentPath={() => resetToBasePath()}
										/>
										
										<FilterClearWrap>
											{deviceFilters && Object.keys(deviceFilters)?.length && clearAll ? (
												<Button
													size="small"
													disabled={false}
													use="secondary"
													onClick={() => {
														onClearFilter();
													}}
												>
													{aem?.labels?.clearAll}
													{/* //Clear all test */}
												</Button>
											) : (
												<> </>
											)}
										</FilterClearWrap>
										</div>
										<div style={{display:'flex',alignItems:'center'}}>
											<div className="dsk-tp-vw-rslt">
											<Body size="medium">{deviceList.length+' results'}</Body>
											</div>
											<div >
												<SearchInput onChangeFn={e => handleSearch(e.target.value)} isBuss={props?.fromBUSS}/>
												{/* <Input type="text" onChange={e => handleSearch(e.target.value)} label="Search" required={false} /> */}
											</div>
										</div>
										<div className="mb-vw-fltr">
										<div>
											<Body size="medium">{deviceList.length+' results'}</Body>
											</div>
										<MobFilterSortWrap>
												<Button
													size="small"
													disabled={false}
													use="secondary"
													onClick={toggleFiltersTypes}
												>
													Filter
												</Button>
												<Button
													size="small"
													disabled={false}
													use="secondary"
													onClick={toggleFilters}
												>
													Sort
												</Button>
											</MobFilterSortWrap>
										</div>
										
									</div>
									)}
								</FilterBoxWrap>
								</Col>
								</RowNoPad>
								</Grid>
								</div>
								<div className="mb-vw">
								<FilterBoxWrap mobileView={true}>
								<div style={{width:'100%',marginBottom:'1rem'}}>
									<SearchInput onChangeFn={e => handleSearch(e.target.value)} isBuss={props?.fromBUSS}/>
								</div>
								<div className="mb-vw-fltr">
										<div>
										<Body size="medium">{deviceList.length+' results'}</Body>
										</div>
										<MobFilterSortWrap>
												<Button
													size="small"
													disabled={false}
													use="secondary"
													onClick={toggleFiltersTypes}
												>
													Filter
												</Button>
												<Button
													size="small"
													disabled={false}
													use="secondary"
													onClick={toggleFilters}
												>
													Sort
												</Button>
											</MobFilterSortWrap>
										</div>
										
									{!ready ? (
										<></>
									) : (
										<div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%',marginTop:'1rem'}}>
										<div >
										<GridwallFilterBoxes
											filters={deviceFilters}
											filterData={filterData}
											brandFilter={brandFilter || props.brandFilter}
											setBrandFilter={setBrandFilter}
											// brandFilter={props.brandFilter}
											simFilter={props.simFilter}
											setFilters={(val, refetch) => {
												setDeviceFilters(val);
												dispatch(setFilterSelected(val));
												if (refetch) {
													fetchNextItems(pageNumParam, val);
												}
											}}
											// showClearAll={(val) => showClearAll(val)}
											setCurrentPath={() => resetToBasePath()}
										/>
										
										<FilterClearWrap>
											{deviceFilters && Object.keys(deviceFilters)?.length && clearAll ? (
												<Button
													size="small"
													disabled={false}
													use="secondary"
													onClick={() => {
														onClearFilter();
													}}
												>
													{aem?.labels?.clearAll}
													{/* //Clear all test */}
												</Button>
											) : (
												<> </>
											)}
										</FilterClearWrap>
										</div>
									</div>
									)}
								</FilterBoxWrap>
								</div>
								</>
							
						) : (
							<MarginSpacer2XL />
						)}

						<Grid
							bleed="1440"
							colSizes={{
								mobile: 1,
								tablet: 1,
								desktop: 3,
							}}
						>
							<RowNoPad>
								<ColContainer
									colSizes={{
										mobile: 2,
										tablet: 4,
										desktop: 3,
									}}
								>
									<Suspense fallback={<></>}>
										{!ready ? (
											<></>
										) : (
											<GridwallFilterBar
												sortByDisplay={sortByDisplay}
												brandFilter={brandFilter ?? props.brandFilter}
												categ={props.category}
												setBrandFilter={setBrandFilter}
												basePath={BASE_PATH}
												aemContent={aem}
												category={props.brandFilter}
												filterData={filterData}
												filters={deviceFilters}
												setFilters={(val) => {
													// const params = searchParams;
													// if (params.get("page")) {
													// 	params.set("page", 1);
													// 	//setSearchParams(params);
													// 	//getUpdateSearchParams();
													// }
													setDeviceFilters(val);
													dispatch(setFilterSelected(val));
												}}
												setViewResults={setViewResults}
												sortOptions={sortDeviceOptions}
												sortBy={sortBy}
												setSortBy={setSortBy}
												showFilters={showFilters}
												toggleFilters={toggleFilters}
												showFiltersTypes={showFiltersTypes}
												toggleFiltersTypes={toggleFiltersTypes}
												totalResults={totalResults}
												filterAccordionsAll={filterAll}
												onClearFilter={onClearFilter}
												filtersApplied={pageData?.filterApplied}
												location={""}
												headerHeight={headerHeight}
												brandSelectedOnTab={currentPath}
												simFilter={props.simFilter}
											/>
										)}
									</Suspense>
								</ColContainer>

								<Col
									colSizes={{
										mobile: 4,
										tablet: 8,
										desktop: 9,
									}}
								>
									<Suspense fallback={<></>}>
										{!ready ? (
											<></>
										) : (
											<>
												<Gridwall
													isBuss={props?.fromBUSS}
													gridData={deviceList}
													loadEmptyDataContainer={loadEmptyDataContainer}
													pageNum={pageNumParam}
													loadFunc={(e) => {
														// const params = searchParams;
														// params.set("page", sanitizeNumber(e));
														// setSearchParams(params);
														// getUpdateSearchParams();
														fetchNextItems(e);
													}}
													pageNumber={pageData?.pagination}
													hasMore={hasMore}
													totalResults={totalResults}
													initialLoad={false}
													onClickHandler={onGridwallClickHandler}
													showDPP={true}
													sortBy={sortBy}
													onCompareSelect={onCompareSelect}
													selectedCompare={selectedCompare}
													byodCard={!isBogo ? byodCard : null}
													isBogo={isBogo}
													hotspotPromo={
														(props.category === "mobile-hotspots" && aem?.hotspot?.show) ||
														deviceList[0]?.category?.includes("BroadbandAccess Devices")
													}
													laptopPromo={
														(props.category === "laptops" || isLaptopSelected) &&
														aem?.connectedLaptops?.show
													}
													hidePromoBadges={isBogo}
													aemContent={aem}
													onClearFilter={() => onClearFilter()}
													selectedCategory={deviceFilters["categoryList"]}
													pageNumClicked={pageNumClicked}
													deviceFilters={deviceFilters}
												/>
											<div style={{display:'flex',justifyContent:'flex-end'}}>
											<Pagination total={pageData?.gridwall?.totalHits ?? ((deviceList.length / 18)+(((deviceList.length%18) > 0 ? 1 : 0))) ?? 4} showArrow={true} surface="light"
											selectedPage = {pageData?.pagination}
											selectPage={(page) => {dispatch(setPagination(page))}} />
											</div>
											</>
										)}
									</Suspense>
								</Col>
							</RowNoPad>
						</Grid>
					</GridwallContainer>
					<Suspense fallback={<></>}>
						{!!(showSeoFooter && seoFooterData) && <SEOFooter data={seoFooterData} />}
					</Suspense>
				</GridwallContentWrap>
				{showCompareLimitModal && (
					<Modal
						opened={showCompareLimitModal}
						onOpenedChange={setShowCompareLimitModal}
					>
						<ModalTitle>
							<span
								size="large"
								dangerouslySetInnerHTML={{
									__html: t("gridwall.headingFourDeviceCompare", {
										ns: "gridwall",
									}),
								}}
							/>
						</ModalTitle>
						<ModalBody>
							<span
								dangerouslySetInnerHTML={{
									__html: t("gridwall.four-device-compare", { ns: "gridwall" }),
								}}
							/>
						</ModalBody>
					</Modal>
				)}
				<div className="compare-devices">
					<Suspense fallback={<></>}>
						<GridwallCompare
							aem={aem}
							selectedProducts={pageData?.compareDevices}
							tileCloseFn={removeCompareDevice}
							topOffset={headerHeight}
							count={length}
						/>
					</Suspense>
				</div>
			</MobilityGridwallWrap>
		</ErrorBoundary>
	);
};

const sortDeviceOptions = [
	{
		name: "Featured",
		value: "featured",
	},
	{
		name: "Price low to high",
		value: "low",
	},
	{
		name: "Price high to low",
		value: "high",
	},
	{
		name: "Newest",
		value: "newest",
	},
];

const RowNoPad = styled(Row)`
	@media only screen and (max-width: 545px) {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
`;

const ColContainer = styled(Col)`
	padding-left: 1rem !important;
	padding-right: 0.4rem !important;
`;

const MobilityGridwallWrap = styled.div`
	min-height: 37.5rem;
	margin: 0px !important;

	.gridwall-header {
		display: flex;
		flex-wrap: wrap;
		max-width: 1272px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.ezYXtw {
		padding-bottom: 0;
	}

	.notification {
		&:nth-child(1) {
			opacity: 1 !important;
		}
	}

	.promo-banner-large {
		width: 100%;
	}
`;

const GridwallContentWrap = styled.div`
	.header {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		margin-bottom: 2rem;
	}

	.side-padding {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.call-btn-container {
		position: fixed;
		right: 0;
		top: 450px;
		z-index: 9999;
		.call-btn {
			display: flex;
			justify-content: space-around;
			align-items: center;
			flex-direction: column;
			background-color: #000000;
			border: 2px solid #fff;
			color: #fff;
			border-radius: 10px;
			height: 70px;
			width: 25px;
			padding: 5px;
			span {
				margin-bottom: 5px;
				transform: rotate(270deg);
			}
		}
	}

	.top-pad {
		padding-top: 1rem;
	}

	.bottom-pad {
		padding-bottom: 1rem;
	}

	.full-width {
		width: 100%;
	}

	.left-flex {
		width: 66%;
	}

	.right-flex {
		display: flex;
		flex-direction: row-reverse;
		gap: 45px;
		margin-left: auto;
		padding: 16px 0;
	}
	@media screen and (max-width: 767px) {
		.bottom-pad {
			padding-bottom: 0rem;
		}
	}
`;

const GridwallHeaderWrap = styled.div`
	display: flex;
	flex-wrap: wrap;
	max-width: 1272px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 1rem;
	padding-right: 1rem;

	.chat-link {
		margin-top: 1rem;
		margin-right: 1.5rem;
	}

	.contact-sales {
		margin-top: 1rem;
		margin-right: 1.5rem;
	}

	.search-input {
		margin-top: 1rem;
		max-width: 66%;

		@media (max-width: 800px) {
			max-width: 100%;
		}
	}
`;

const StyledDropDownList = styled.div`
	> div {
		min-width: 200px;
		width:94%;
	}
`;

const SortByDropdown = styled.div`
	display: flex;
	align-items: center;
	flex-basis: unset;
	max-width: 100%;
	width: 100%;

	& > p {
		width: 100%;
		text-align: right;
		padding-right: 10px;
	}

	options {
		text-align: center;
	}

	@media (max-width: 800px) {
		& > p {
			width: 80%;
		}
	}

	@media (max-width: 545px) {
		& > p {
			text-align: left;
		}
	}
`;

const MobFilterSortWrap = styled.div`
	display: flex;
	gap: 10px !important;
`;

const SortByWrapper = styled.div`


	@media (max-width: 900px) {
		width: 60%;

		select {
			padding: 0.25rem 1.5rem 0.25rem 0 !important;
		}
	}

	@media (max-width: 545px) {
		width: 100%;
		order: 1;
		padding-bottom: 1rem;
		border-bottom: 1px solid #d8dada;
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
`;

const FilterBoxWrap = styled.div`
	width:100%;
	display: ${({mobileView})=>mobileView ? 'inline' : 'flex'};
	align-items: center;

	& > div {
		height: fit-content;
		flex-wrap: wrap;
		gap: 0.5rem;

		& div {
			border-radius: 5px;
			display: flex;
			align-items: center;
			height: auto;
			padding: 3px 2px 3px 6px;
			gap: 5px;

			&:last-child {
				gap: 0px;
			}
		}
	}

	@media (max-width: 900px) {
		width: 40%;
	}

	@media (max-width: 545px) {
		width: 100%;
		order: 2;
	}
`;
// margin-left: auto;
// 	margin-right: auto;
// 	padding-left: 1rem;
// 	padding-right: 1rem;
const FilterSortWrap = styled.div`
	justify-content: space-between !important;
	display: flex !important;
	flex-wrap: wrap;
	max-width: 1272px;
	&.gridwall-header {
		max-width: 1272px;
		padding-left: 1rem;
		padding-right: 1rem;
		margin-left: 0px;
		margin-right: 0px;
	}

	@media only screen and (min-width: 545px) {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	@media only screen and (max-width: 545px) {
		position: sticky !important;
		top: ${({ headerHeight }) => (headerHeight > 0 ? 44 + headerHeight : 44)}px;
		z-index: 4;
		background-color: #f6f6f6;
	}
`;

const FilterClearWrap = styled.div`
	margin-left: 0.5rem;

	& > button {
		border: none;
		text-decoration: underline;
		line-height: 1rem;
		color: #000000;

		&:hover {
			border: none !important;
			box-shadow: none !important;
		}

		span {
			padding: 0px !important;
		}
	}
`;

export default React.memo(MobilityGridwall);
