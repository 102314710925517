 const InitialState ={
    corporation: {
        billingName: '',
        DateOfBirth: '',
        fedTaxId: '',
        SSN: '',
        taxIdNumber: '',
        confirmSSN:'',
        matchSSN: false,
    },
    errorTexts: {
        soleOwner: {},
        partnership1: {},
        partnership2: {},
        corporation: {},
        confirmSSN:{},
    },
    retryCount: 0,
    customerType: 'Corporation',
    creditBannerText: 'To process your online order for new service, a credit application is required. For a Sole Owner, a personal credit application - including your social security number - is requested. This information is secure and will be verified with a credit bureau. We will notify you if we need additional information.',
    oneTimePassModal : {
        mobileNumber: ''
    },
    oneTimePassModalErrorTexts: {},
    showOneTimePassModal: false,
    ownerList: [
        {
            type: "radiobox", subtext: "  ", text: "Sole owner", id: 'SoleOwner', value: 'SoleOwner'
        },
        {
            type: "radiobox", subtext: "  ", text: "Partnership", id: 'Partner', value: 'Partner'
        },
        {
            type: "radiobox", subtext: "  ", text: "Corporation", id: 'Corporation', value: 'Corporation'
        }
    ]
};

export default InitialState;