import React, { useEffect, useReducer, useState } from "react";
import { reducer, actions as Actions } from "./reducer";
import { useTranslation } from "react-i18next";
import { Micro } from "@vds3/typography";
import { Input } from "@vds3/inputs";
import { Grid, Row, Col } from "@vds3/grids";
import { Button } from "@vds3/buttons";
import "./BusinessInfo.css";
import { CheckboxWrapper } from "../ShippingInfo/styledComponents";
import { Checkbox } from "@vds3/checkboxes";
import MapQStreetAddress from "../ShippingInfo/components/MapQStreetAdress";
import { useDispatch } from "react-redux";
import {
	checkAddressValidation,
	setAddressValidationInfo,
} from "../ducks/CheckoutSlice";
import { Notification } from "@vds3/notifications";
import { MarginSpacerM } from "common/components/styleTag";
import { isValidEmail } from "utils/commonUtils";

function SignerAdd(props) {
	const [state, dispatch] = useReducer(
		reducer,
		JSON.parse(localStorage.getItem("signerState"))
	);
	const redDisptach = useDispatch();

	const { t } = useTranslation(["app", "checkout"]);
	const [homeAddress, setHomeAddress] = useState("");
	const [errors, setErrors] = useState({});
	const [cityValue, setCityValue] = useState("");
	const [propertyType, setPropertyType] = useState("");
	const [stateValue, setStateValue] = useState("");
	const [zipValue, setZipValue] = useState("");
	const [showAddrsErr, setShowAddrsErr] = useState(false);
	const [continueClick, setContinueClick] = useState(false);
	const [streetValue, setStreetValue] = useState("");
	const businessLocalState = localStorage.getItem("businessState")
		? JSON.parse(localStorage.getItem("businessState"))
		: {};
	const creditCheckLocalState = localStorage.getItem("creditCheckState")
		? JSON.parse(localStorage.getItem("creditCheckState"))
		: {};

	useEffect(() => {
		if (props?.eventHandlers?.onLoad) {
			props?.eventHandlers?.onLoad("retrieveCartInfo");
		}
	}, []);
	const addressConfirmaionContent = t("verification.addressConfirmationModal", {
		ns: "checkout",
		returnObjects: true,
	});
	// useEffect(() => {
	// 	const businessInfo = props?.data?.businessInfo;
	// 	const contactInfo = props?.businessInfoState?.contactInfo;
	// 	dispatch(
	// 		Actions.setContactInformation({
	// 			zipCode: businessInfo?.zip,
	// 			city: businessInfo?.muni,
	// 			state: businessInfo?.state,
	// 			address: businessInfo?.dispstr,
	// 			firstName: contactInfo?.firstName,
	// 			email: contactInfo?.email || props?.prospect5gLoopQualAddress?.email,
	// 			lastName: contactInfo?.lastName,
	// 		})
	// 	);
	// }, [
	// 	props?.data?.contactInfo,
	// 	props?.data?.businessInfo,
	// 	props?.businessInfoState,
	// ]);

	useEffect(() => {
		const { prospect5gLoopQualAddress: address = null } = props;
		if (
			address !== null &&
			Object.keys(address)?.length &&
			!state?.isSameAddress
		) {
			dispatch(Actions.setIsSameAddress(true));
		}
	}, [props?.prospect5gLoopQualAddress]);

	useEffect(() => {
		if (props?.setSignerAddState) {
			props?.setSignerAddState(state);
		}
	}, [state]);

	useEffect(() => {
		if (state?.isSameAddress) {
			setCityValue(
				props?.shippingInfo?.businessStreetAddress?.place?.properties?.city
			);
			setPropertyType(
				props?.shippingInfo?.businessStreetAddress?.place?.properties?.propertyType
			);
			setStateValue(
				props?.shippingInfo?.businessStreetAddress?.place?.properties?.stateCode
			);
			setZipValue(
				props?.shippingInfo?.businessStreetAddress?.place?.properties?.postalCode
			);
		}
	}, [props?.shippingInfo?.businessStreetAddress?.place?.properties]);

	useEffect(() => {
		if (
			state?.isSameAddress &&
			props?.shippingInfo?.businessStreetAddress?.place?.properties?.city
		) {
			handleAddressSelection(props?.shippingInfo?.businessStreetAddress, false);
		} else if (!state?.isSameAddress) {
			handleAddressSelection(null, false, false);
		}
		setShowAddrsErr(false);
	}, [state?.isSameAddress]);

	const handleAddressInput = (e, name) => {
		const value = e?.target?.value;
		const properties = state?.homeAdressInfo?.place?.properties;
		let objValue = {
		  place: {
			properties: {
			  country: properties?.country,
			  countryCode: properties?.country,
			  state: properties?.state,
			  stateCode: properties?.stateCode,
			  street: properties?.street,
			  city: properties?.city,
			  postalCode: properties?.postalCode,
			  propertyType: properties?.propertyType,
			},
		  },
		  displayString: state?.homeAdressInfo?.displayString,
		  name: state?.homeAdressInfo?.name,
		};
	
		if (name === "street") {
		objValue.place.properties.street = value?.length
		  ? value
		  : properties?.street;
		objValue.place.properties.city = cityValue?.length
		  ? cityValue
		  : properties?.city;
		objValue.place.properties.stateCode = stateValue?.length
		  ? stateValue
		  : properties?.stateCode;
		objValue.place.properties.postalCode = zipValue?.length
		  ? zipValue
		  : properties?.postalCode;
		objValue.place.properties.propertyType = propertyType?.length
		  ? propertyType
		  : properties?.propertyType;
		setStreetValue(value);
	 
		if (!value) {
		  setErrors({
			...errors,
			homeAdressInfoErr: "Home Address is required",
		  });
		} 
		else {
		  setErrors({
			...errors,
			homeAdressInfoErr: null,
		  });
		}
	
		 }
		if (name === "city") {
			const PATTERN_STATE = /^[a-zA-Z]+$/.test(value);

			setCityValue(value);
			if (!value) {
				setErrors({
					...errors,
					city: "City is required",
				});
			} else if (!PATTERN_STATE) {
				setErrors({
					...errors,
					city: "City is invalid",
				});
			} else {
				setErrors({
					...errors,
					city: null,
				});
			}
		} else if (name === "state") {
			const PATTERN_STATE = /^[a-zA-Z]+$/.test(value);
			if (value?.length > 2) {
				return;
			}
			setStateValue(value);
			if (!value) {
				setErrors({
					...errors,
					state: "State is required",
				});
			} else if (!PATTERN_STATE) {
				setErrors({
					...errors,
					state: "State code is invalid",
				});
			} else {
				setErrors({
					...errors,
					state: null,
				});
			}
		} else if (name === "zipCode") {
			const PATTERN_ZIPCODE = /^\d{5}(-\d{4})?$/.test(value);
			if (value?.length > 9) {
				return;
			}
			setZipValue(value);
			if (!value) {
				setErrors({
					...errors,
					zipCode: "Zip code is required",
				});
			} else if (!PATTERN_ZIPCODE) {
				setErrors({
					...errors,
					zipCode: "Zip code is invalid",
				});
			} else {
				setErrors({
					...errors,
					zipCode: null,
				});
			}
		}
	};

	const handleAddressSelection = (value, isDispatch = true, isError = true) => {
		dispatch(
			Actions.setErrorMessages({
				...state?.errorTexts,
				homeAdressInfoErr: false,
			})
		);
		if (!value && isError) {
			setErrors({
				...errors,
				city: "City is required",
				state: "State is required",
				zipCode: "Zip code is required",
			});
		} else {
			setErrors({
				...errors,
				city: null,
				state: null,
				zipCode: null,
			});
		}
		const properties = value?.place?.properties;
		setCityValue(properties ? properties?.city : "");
		setPropertyType(properties ? properties?.propertyType : "");
		setStateValue(properties ? properties?.stateCode : "");
		setZipValue(properties ? properties?.postalCode : "");
		setStreetValue(properties?.street);
		isDispatch && dispatch(Actions.setHomeAddressInfo(value));
		setHomeAddress(value);
	};

	const hasErrors = () => {
		let error = false;
		for (const key of Object.keys(errors)) {
			if (errors[key] && errors[key]?.length > 0) {
			  error = true;
			}
			if((!state?.isSameAddress && !(state?.homeAdressInfo || streetValue))){
			  error = true;
			}
			if(!cityValue || !stateValue || !zipValue){
			  error = true;
			}
		  }

		if (
			businessLocalState?.contactInfo?.firstName === "" ||
			businessLocalState?.contactInfo?.lastName === "" ||
			businessLocalState?.contactInfo?.email === "" ||
			!isValidEmail(businessLocalState?.contactInfo?.email) ||
			businessLocalState?.contactInfo?.DateOfBirth === "" ||
			businessLocalState?.contactInfo?.jobTitle === ""
		) {
			error = true;
		}

		return error;
	};

	const validateBusinessAddress = () => {
		window.dispatchEvent(
			new CustomEvent("customerEmailInfoFromWidget", {
				detail: {
					customerEmail: businessLocalState?.contactInfo?.email,
				},
			})
		);
		if (state?.isSameAddress) {
			props?.saveBusinessInfo();
			props?.onContinue();
		} else {
			setShowAddrsErr(false);
			let payload;
			if (state?.homeAdressInfo?.place?.properties?.street || streetValue) {
				payload = {
					state: state?.homeAdressInfo?.place?.properties?.state ?? stateValue,
					addressLine1: state?.homeAdressInfo?.place?.properties?.street ?? streetValue,
					addressLine2: "",
					city: state?.homeAdressInfo?.place?.properties?.city ?? cityValue,
					zipCode: state?.homeAdressInfo?.place?.properties?.postalCode ?? zipValue,
				};
			}
			redDisptach(checkAddressValidation(payload));
			setContinueClick(true);
		}
	};

	useEffect(() => {
		if (continueClick) {
			if (props?.shippingPageData?.addressValidationInfo?.serviceStatus?.success) {
				setShowAddrsErr(false);
				// handleAddressSelection(temp);
				props?.saveBusinessInfo();
			} else if (
				props?.shippingPageData?.addressValidationInfo?.serviceStatus?.success ==
				false
			) {
				setShowAddrsErr(true);
				setContinueClick(false);
			}
			redDisptach(setAddressValidationInfo(null));
		}
	}, [props?.shippingPageData?.addressValidationInfo?.serviceStatus]);

	useEffect(() => {
		if (
			props?.shippingPageData?.saveBusinessInfoResponse?.serviceStatus?.success &&
			continueClick
		) {
			setContinueClick(false);
			props?.onContinue();
		}
	}, [props?.shippingPageData?.saveBusinessInfoResponse?.serviceStatus]);

	return (
		<div className="businessInfo">
			<div className="container">
				<Grid
					colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
					colGutter={false}
					rowGutter="5"
				>
					<Row>
						<Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
							<CheckboxWrapper className="pad-bt-8 mr-tp-1">
								<Checkbox
									name="default"
									width="auto"
									disabled={!props?.shippingInfo?.businessStreetAddress}
									error={false}
									selected={state?.isSameAddress}
									errorText=""
									onChange={(e) => {
										if (state?.isSameAddress) {
											dispatch(Actions.setHomeAddressInfo(null));
										}
										dispatch(Actions.setIsSameAddress(!state?.isSameAddress));
									}}
								>
									{`${t("verification.labelTexts.sameAsCAddress", {
										ns: "checkout",
									})}*`}
								</Checkbox>
							</CheckboxWrapper>
						</Col>
					</Row>
					<Row>
						<Col colSizes={{ mobile: 4, tablet: 6, desktop: 4 }}>
							{!state?.isSameAddress ? (
								<div className="address-container form-col">
									<MapQStreetAddress
										errorMessage={t("verification.errorMsgs.homeAddLine1Req", {
											ns: "checkout",
										})}
										label={t("verification.labelTexts.authorisedSignerHomeAdd", {
											ns: "checkout",
										})}
										onSelectionChange={handleAddressSelection}
										isError={state?.errorTexts?.homeAdressInfoErr}
										handleAddressInput={(e) => handleAddressInput(e, "street")}
										isAddressEmty={true}
										setAddressFieldError={(value) => {
											dispatch(
												Actions.setErrorMessages({
													...state?.errorTexts,
													homeAdressInfoErr: value,
												})
											);
										}}
										isNameOnly
										isVerifyBussiness={true}
										helperText={t("verification.labelTexts.addressLine1Helper", {
											ns: "checkout",
										})}
									/>
								</div>
							) : (
								<div>
									<div className="form-col">
										<Input
											label={t("verification.labelTexts.authorisedSignerHomeAdd", {
												ns: "checkout",
											})}
											placeholder=""
											width="100%"
											value={
												props?.shippingInfo?.businessStreetAddress?.place?.properties
													?.street
											}
											disabled={true}
										/>
									</div>
								</div>
							)}
						</Col>
						<Col colSizes={{ mobile: 4, tablet: 6, desktop: 5 }}>
							{state?.isSameAddress ? (
								<>
									{props?.shippingInfo?.businessStreetAddress2 && (
										<div className="form-col">
											<Input
												label={t("verification.labelTexts.authorisedSignerAddLine2", {
													ns: "checkout",
												})}
												placeholder=""
												value={props?.shippingInfo?.businessStreetAddress2}
												disabled={true}
											/>
										</div>
									)}
								</>
							) : (
								<div className="form-col">
									<Input
										disabled={state?.isSameAddress}
										type="text"
										label={t("verification.labelTexts.authorisedSignerAddLine2", {
											ns: "checkout",
										})}
										placeholder={t("verification.labelTexts.enterUnitNo", {
											ns: "checkout",
										})}
										value={state?.homeAdressInfo2}
										required={false}
										onChange={(e) =>
											dispatch(Actions.setHomeAddress2Info(e?.target?.value))
										}
									/>
								</div>
							)}
						</Col>
					</Row>
					<Row>
						<Col colSizes={{ mobile: 4, tablet: 6, desktop: 4 }}>
							<div className="form-col">
								<Input
									type="text"
									name="city"
									label={t("verification.labelTexts.cityTitle", {
										ns: "checkout",
									})}
									placeholder={t("verification.labelTexts.enterCity", {
										ns: "checkout",
									})}
									disabled={state?.isSameAddress}
									error={!!errors?.city}
									errorText={errors?.city}
									required={true}
									value={cityValue}
									onChange={(e) => handleAddressInput(e, "city")}
								/>
							</div>
						</Col>
						<Col colSizes={{ mobile: 4, tablet: 6, desktop: 3 }}>
							<div className="form-col">
								<Input
									type="text"
									name="state"
									label={t("verification.labelTexts.stateTitle", {
										ns: "checkout",
									})}
									placeholder={t("verification.labelTexts.enterState", {
										ns: "checkout",
									})}
									disabled={state?.isSameAddress}
									error={!!errors?.state}
									errorText={errors?.state}
									required={true}
									value={stateValue}
									onChange={(e) => handleAddressInput(e, "state")}
								/>
							</div>
						</Col>
						<Col colSizes={{ mobile: 4, tablet: 6, desktop: 2 }}>
							<div className="form-col">
								<Input
									type="text"
									name="zipCode"
									label={t("verification.labelTexts.zipCodeTitle", {
										ns: "checkout",
									})}
									placeholder={t("verification.labelTexts.zipCodePlaceholder", {
										ns: "checkout",
									})}
									disabled={state?.isSameAddress}
									error={!!errors?.zipCode}
									errorText={errors?.zipCode}
									required={true}
									value={zipValue}
									onChange={(e) => handleAddressInput(e, "zipCode")}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col colSizes={{ mobile: 4, tablet: 3, desktop: 3 }}>
							{!props?.hideContinue && (
								<div className="continue-btn">
									{!state?.isSameAddress && showAddrsErr && (
										<Notification
											type="error"
											title={addressConfirmaionContent?.widigetNotificationErr}
											fullBleed={true}
											inlineTreatment={false}
										/>
									)}
									<MarginSpacerM />

									<Button
										onClick={() => {
											validateBusinessAddress();
											//props?.onContinue();
										}}
										size="large"
										disabled={hasErrors()}
										data-analyticstrack="Continue:Enter authorized signer's home address"
									>
										{props?.personalInfo?.isPersonalInfoSaved
											? t("verification.ConfirmText", { ns: "checkout" })
											: t("app.common.page-stepper.continue-btn", {
													ns: "app",
											  })}{" "}
									</Button>
								</div>
							)}
						</Col>
					</Row>
				</Grid>
			</div>
		</div>
	);
}

export default SignerAdd;
