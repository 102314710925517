import { formatPriceString } from "utils/commonUtils";

export const getCartProperties = (cart) => {
  const cartProperties = {};
  const accountLevel = new Map();
  if (cart && Object.keys(cart)?.length) {
    const packages = cart?.packages;
    for (const pkg in packages) {
      const pkgObj = packages[pkg];
      const featureLevel = packages[pkg]?.features;
      switch (pkgObj?.packageType) {
        case "FIVEG":
          cartProperties.FiveG = true;
          break;
        case "FOURG":
          cartProperties.FourG = true;
          break;
        case "ONETALK":
          cartProperties.OneTalk = true;
          break;
        case "REGULAR":
          if(pkgObj?.byod && pkgObj?.emptyByodFromDQ) {
            cartProperties.MobilityByod = true;
            cartProperties.emptyByodFromDQ = true;
            break;
          }
          if(pkgObj?.byod && !pkgObj?.byor) {
            cartProperties.MobilityByod = true;
            break;
          }
          cartProperties.SmartPhones = true;
          break;
        default:
          break;
      }
      cartProperties.isMMW = !!cart?.mwwCart;
      if (!pkgObj?.plan || pkgObj?.plan === "undefined") {
        cartProperties.NoPlan = true;
      }
      if (featureLevel) {
        for (const key of Object.keys(featureLevel)) {
          if (featureLevel[key]?.level === "A") {
            accountLevel.set(key, featureLevel[key]);
          }
        }
      } else if (!featureLevel || featureLevel === "undefined") {
        cartProperties.NoFeature = true;
      }
    }
  }
  cartProperties.AccountLevelFeatures = [...accountLevel.values()];
  return cartProperties;
};

export const getSavingsData = (cart, savingsAemData) => {
  if (cart?.packages) {
    const totalAmount = cart?.totalRecurringBicDiscount;
    let bicOneTimeAmount = 0;
    let maxTenure = 0;
    const modalContentArray = [];
    const modalOneTimeBicContentArray = [];

    for (const key in cart.packages) {
      if (cart.packages.hasOwnProperty(key)) {
        const detail = cart.packages[key];
        if (Object.keys(detail.displayDiscountPrice || {}).length) {
          const amount =
            detail.itemPriceWithOutDiscount -
            detail.displayDiscountPrice.itemPrice;
          maxTenure = Math.max(maxTenure, detail.tenure);
          const deviceName =
            (detail.brandName ? detail.brandName + " " : "") +
            detail.productName;
          const modalContent = savingsAemData.modalContent
            .replace("{{amount}}", formatPriceString(amount))
            .replace("{{deviceName}}", deviceName);
          modalContentArray.push(modalContent);
        }

        for (const offer in detail.offers?.sbdOffer) {
          // BOVV-106590, if more than one bicNoOfOccurences = 1, take discAmt from the first non-zero amount
          if (
            detail?.offers?.sbdOffer[offer]?.bicNoOfOccurences === 1 &&
            bicOneTimeAmount === 0
          ) {
            bicOneTimeAmount = cart?.bicOneTimeAmount;
            const bicOneTimeModelContent =
              savingsAemData.bicOneTimemodalContent.replace(
                "{{offerdesc}}",
                detail.offers.sbdOffer[offer].offerDesc
              );
            if (
              modalOneTimeBicContentArray &&
              !modalOneTimeBicContentArray.includes(bicOneTimeModelContent)
            ) {
              modalOneTimeBicContentArray.push(bicOneTimeModelContent);
            }
          }
          if (detail.offers.sbdOffer[offer]?.bicBogoOccCode === "BYODRECBIC") {
            maxTenure = Math.max(
              maxTenure,
              detail.offers.sbdOffer[offer].bicNoOfOccurences
            );
            const portInDiscAmt =
              detail.offers.sbdOffer[offer].discAmt / maxTenure;
            const deviceName =
              (detail.brandName ? detail.brandName + " " : "") +
              detail.productName;
            const modalContent = savingsAemData.modalContent
              .replace("{{amount}}", formatPriceString(portInDiscAmt))
              .replace("{{deviceName}}", deviceName);

            modalContentArray.push(modalContent);
          }
        }
      }
    }
    // modalContentArray = modalContentArray.concat(modalOneTimeBicContentArray);
    // if (totalAmount || bicOneTimeAmount) {
    //   if (this.firstTimeSavingNotification) {
    //     this.firstTimeSavingNotification = false;

    //     this.dispatchSavingsNotification(
    //       totalAmount,
    //       modalContentArray,
    //       maxTenure,
    //       bicOneTimeAmount,
    //       modalOneTimeBicContentArray
    //     );
    //   } else {
    //     if (
    //       (this.totalSavingsAmount &&
    //         this.totalSavingsAmount !==
    //           this.currencyPipe.transform(
    //             totalAmount,
    //             "USD",
    //             "symbol-narrow",
    //             "1.2-2"
    //           )) ||
    //       bicOneTimeAmount
    //     ) {
    //       this.dispatchSavingsNotification(
    //         totalAmount,
    //         modalContentArray,
    //         maxTenure,
    //         bicOneTimeAmount,
    //         modalOneTimeBicContentArray
    //       );
    //     }
    //   }
    // } else if (!this.firstTimeSavingNotification) {
    //   this.cartStore.dispatch(REMOVE_NOTIFICATION({}));
    // }

    return {
      totalAmount,
      modalContentArray,
      maxTenure,
      bicOneTimeAmount,
      modalOneTimeBicContentArray,
    };
  }
};

export const continueDisabled = (cartPackages) => {
  const isMobilityEdit = window?.mbtGlobal?.flags?.ENABLE_VDS3_SHOPPINGCART_MOBILITY_EDIT === 'Y' ? true : false;
  if (!Object.keys(cartPackages)?.length) {
    return true;
  } else {
    for (const i in cartPackages) {
      if (
        (cartPackages[i]?.tradInInfo &&
        !cartPackages[i].tradInInfo?.deviceId) ||
         (cartPackages[i]?.emptyByodFromDQ && isMobilityEdit) || cartPackages[i]?.outOfStock
        //  || sortedPackages[i]?.buss
      ) {
        return true;
      }
    }
  }
  return false;
};

export const continueText = (cartPackages, t) => {
  let continueButton = t("app.common.page-stepper.continue-btn")
  if (!Object.keys(cartPackages)?.length){
    continueButton =  t("app.common.page-stepper.continue-btn");
  } else {
    for (const i in cartPackages){
      const isLaporHotspot = String(cartPackages[i].deviceCategory).toLowerCase().includes('laptop')
      if(cartPackages[i]?.packageType === "REGULAR"){
        let protectionPlan;
        if(cartPackages[i]?.features){
          protectionPlan = Object.values(cartPackages[i]?.features)?.find(e => e.featureDesc !== "ECONOMIC ADJUSTMENT CHARGE");
        }
        if ((cartPackages[i]?.plan === null || cartPackages[i]?.plan === undefined || cartPackages[i]?.plan.pricePlanDesc === null || cartPackages[i]?.plan.pricePlanDesc === undefined)) {
          continueButton =  t("app.common.page-stepper.go-to-plans-btn");
        } else if(((cartPackages[i]?.plan && !cartPackages[i]?.features) ||
        (cartPackages[i]?.plan && !protectionPlan && !isLaporHotspot))&&continueButton!==t("app.common.page-stepper.go-to-plans-btn")){
          continueButton = t("app.common.page-stepper.select-protection-btn");
        }
      }
    }
  }
  return continueButton
};

export const isCartHaveUncappedPlan = (cartPackages) => {
  for (let pkg in cartPackages) {
    let pkgObj = cartPackages[pkg];
    if (pkgObj?.packageType === "FOURG" && pkgObj?.plan?.planType === "UNCAPPED") {
       return true;
    }
  }   
  return false;
};