import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
//import { useLocation, useNavigate } from "react-router-dom";
import get from "lodash/get";
import {
	Accordion,
	AccordionDetail,
	AccordionHeader,
	AccordionItem,
	AccordionTitle,
} from "@vds3/accordions";
import { TextLink } from "@vds3/buttons";
import { Col, Grid, Row } from "@vds3/grids";
import { Micro, Title } from "@vds3/typography";
import SpinnerComponent from "common/components/Spinner/Spinner";
import CustomBreadcrumb from "common/components/CustomBreadcrumb/CustomBreadcrumb";
import RatingStar from "common/components/RatingStar/RatingStar";
import ColorSwatch from "common/components/ColorSwatch/ColorSwatch";
import { getFullRatingWidth } from "utils/commonUtils";
import {
	getReviewsForComparePage,
	removeComparedDevicesAct,
	setReviewsForCompareDevices,
} from "../../ducks/MobilitySlice";
import DeviceDetails from "./DeviceDetails";
import styled from "styled-components";
import { MarginSpacerM } from "common/components/styleTag";
import ComparePDPCarousel from "./comparePDPCarousel";
import CompareSelectDevice from "./compareSelectDevice";
import { useTranslation } from "react-i18next";
//import * as SiteAnalytics from "../../../../sitecatalyst";

const GridwallCompareDevices = ({
	fromPdp = false,
	selectedSku = null,
	deviceName = null,
}) => {
	const { t } = useTranslation(["gridwall", "app"]);
	const aem = t("gridwall", { ns: "gridwall", returnObjects: true });
	const dispatch = useDispatch();
	//const navigate = useNavigate();
	//const location = useLocation();
	const MobilityPageData = useSelector((state) => state?.MobilityPage);

	const compareListUnsorted = useMemo(() => {
		if (fromPdp) {
			return get(
				MobilityPageData,
				"pegaRecommendations.compareDeviceInfoList",
				[]
			);
		}
		return get(MobilityPageData, "productCompare.compareDeviceInfoList", []);
	}, [fromPdp, MobilityPageData]);

	const compareDeviceInfoList = useMemo(() => {
		if (selectedSku || deviceName) {
			let copyList = JSON.parse(JSON.stringify(compareListUnsorted));
			const findIndex = copyList?.findIndex(
				(list) => list?.skuId === selectedSku?.sku || list?.seoURL === deviceName
			);
			let f = copyList?.splice(findIndex, 1)[0];
			copyList?.splice(0, 0, f);
			return copyList;
		}
		return compareListUnsorted;
	}, [compareListUnsorted, MobilityPageData]);

	const loading = get(MobilityPageData, "loading", false);
	const apiError = get(MobilityPageData, "apiError", false);

	const isMobile = useMediaQuery({
		query: "(max-width: 545px)",
	});

	const onReviewsClick = () => {};

	const handleDeviceTileCloseClick = (deviceId) => {
		dispatch(removeComparedDevicesAct(deviceId));
	};

	useEffect(() => {
		if (apiError) {
			// navigate("/shop/contact-us", {
			// 	state: { refUrl: window.location.pathname },
			// });
		}
	}, [apiError]);

	useEffect(() => {
		dispatch(setReviewsForCompareDevices(undefined));
		//SiteAnalytics.trackPageInfo();
	}, []);

	const goBack = () => {
		localStorage.setItem("back", "true");
		//navigate(-1);
	};

	const colorSwatchRenderer = ({ availableColors }) => {
		return (
			<>
				{Object.keys(availableColors).map((color) =>
					!isMobile ? (
						<ColorName>
							<ColorSwatch key={color} cssColor={availableColors[color]} />
							<ColorTextName>{color}</ColorTextName>
						</ColorName>
					) : (
						<ColorSwatch key={color} cssColor={availableColors[color]} />
					)
				)}
			</>
		);
	};
	useEffect(() => {
		if (
			compareDeviceInfoList !== null &&
			compareDeviceInfoList &&
			compareDeviceInfoList.length > 0 &&
			!MobilityPageData?.reviewsForCompareDevices
		) {
			dispatch(getReviewsForComparePage(compareDeviceInfoList));
			if (window?.["digitalData"] && window["digitalData"]?.pageInfo) {
				window["digitalData"].pageInfo.throttle = "MVO_A_Dev_P_T";
			}
		}
	}, [compareDeviceInfoList]);

	useEffect(() => {
		if (
			MobilityPageData?.deviceSuggestionResp &&
			Object.keys(MobilityPageData?.deviceSuggestionResp)?.length > 0
		) {
			const propositionData = MobilityPageData?.deviceSuggestionResp?.propositions;
			let contentFragments = [];
			contentFragments = propositionData?.map((item) => {
				return `${item?.propositionId}|${MobilityPageData?.deviceSuggestionResp?.modelInsightInfo?.modelName}|smartphones|${MobilityPageData?.deviceSuggestionResp?.responseHeaderInfo?.interactionId}|6|${item?.itemSKU}`;
			});
			if (window?.["digitalData"] && window["digitalData"]?.pageInfo) {
				window["digitalData"].pageInfo.contentFragments = contentFragments;
			}
		}
	}, [MobilityPageData?.deviceSuggestionResp]);
	return (
		<>
			{loading && !fromPdp && <SpinnerComponent />}
			<Grid colGutter={false} rowGutter="1rem">
				<RowNoPad fromPdp={fromPdp}>
					<Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
						<GridWallContent>
							<GridWallHeader isMobile={isMobile} fromPdp={fromPdp}>
								<LeftFlex isMobile={isMobile}>
									{!fromPdp && <CustomBreadcrumb isProspect={true} />}
									<Title size={fromPdp ? "medium" : "large"}>
										<Title bold size={fromPdp ? "medium" : "large"} primitive="span">
											{fromPdp ? "Compare devices" : aem?.labels?.compare}
										</Title>
										{!fromPdp && ` (${compareDeviceInfoList?.length})`}
									</Title>
									<MarginSpacerM />
									{!fromPdp && (
										<Text>
											<TextLink
												type="standAlone"
												bold={true}
												size="small"
												inverted={false}
												disabled={false}
												onClick={goBack}
											>
												{aem?.labels?.goBack}
											</TextLink>
										</Text>
									)}
								</LeftFlex>
								<RightFlex>
									{!fromPdp && (
										<ContactSales showPhoneNumber={false} isMobile={isMobile}>
											{!isMobile && <ChatLink />}
										</ContactSales>
									)}
								</RightFlex>
							</GridWallHeader>

							{!isMobile ? (
								<>
									<CardContainer data-loc="pega_pdp_compare_devices" fromPdp={fromPdp}>
										{compareDeviceInfoList?.map((device, index) => (
											<DeviceDetails
												{...device}
												key={device.deviceId}
												maxDeviceLength={0}
												onCloseClick={handleDeviceTileCloseClick}
												aem={aem}
												fromPdp={fromPdp}
												device={device}
												hideDetailsBtn={!!(index === 0) && fromPdp}
												modelName={
													MobilityPageData?.deviceSuggestionResp?.modelInsightInfo?.modelName
												}
											/>
										))}
										{!(compareDeviceInfoList.length === 4) && !fromPdp && (
											<CompareSelectDevice aem={aem} />
										)}
										{compareDeviceInfoList.length === 0 && !fromPdp && (
											<>
												<BorderBox></BorderBox>
												<BorderBox></BorderBox>
												<BorderBox></BorderBox>
											</>
										)}
										{compareDeviceInfoList.length === 1 && !fromPdp && (
											<>
												<BorderBox></BorderBox>
												<BorderBox></BorderBox>
											</>
										)}
										{compareDeviceInfoList.length === 2 && !fromPdp && (
											<>
												<BorderBox></BorderBox>
											</>
										)}
									</CardContainer>
									<ComparingSection>
										<Accordion className="test" type="multi">
											<AccordionItem opened={true} data-track={aem?.labels?.batteryLife}>
												<AccordionHeader triggerType="icon">
													<AccordionTitle>
														<AccordionTitleClass className="Testing">
															{aem?.labels?.batteryLife}
														</AccordionTitleClass>
													</AccordionTitle>
												</AccordionHeader>
												<AccordionDetail>
													<DeviceCompareSection>
														{compareDeviceInfoList.map((device) => (
															<CompareListNotGrayed fromPdp={fromPdp}>
																<CompareTitle>{aem?.labels?.standbyTime}</CompareTitle>
																{device?.batteryDetails?.standbyTime}
															</CompareListNotGrayed>
														))}
													</DeviceCompareSection>
													<DeviceCompareSection className="CompareDesc">
														{compareDeviceInfoList.map((device) => (
															<CompareListGrayed fromPdp={fromPdp}>
																{" "}
																<CompareTitle>{aem?.labels?.usageTime}</CompareTitle>
																{device?.batteryDetails?.usageTime}
															</CompareListGrayed>
														))}
													</DeviceCompareSection>
												</AccordionDetail>
											</AccordionItem>
										</Accordion>
										<Accordion>
											<AccordionItem opened={true} data-track={aem?.labels?.dimensions}>
												<AccordionHeader triggerType="icon">
													<AccordionTitle>
														<AccordionTitleClass>
															{aem?.labels?.dimensions}
														</AccordionTitleClass>
													</AccordionTitle>
												</AccordionHeader>
												<AccordionDetail>
													<DeviceCompareSection>
														{compareDeviceInfoList.map((device) => (
															<CompareListNotGrayed fromPdp={fromPdp}>
																<CompareSectionTitle>
																	<CompareNewTitle>{aem?.labels?.height} </CompareNewTitle>
																	<CompareNewTitle>{device?.dimensions?.height}</CompareNewTitle>
																</CompareSectionTitle>
															</CompareListNotGrayed>
														))}
													</DeviceCompareSection>
													<DeviceCompareSection className="CompareDesc">
														{compareDeviceInfoList.map((device) => (
															<CompareListGrayed fromPdp={fromPdp}>
																<CompareSectionTitle>
																	<CompareTitle>{aem?.labels?.width}</CompareTitle>
																	<CompareNewTitle> {device?.dimensions?.width}</CompareNewTitle>
																</CompareSectionTitle>
															</CompareListGrayed>
														))}
													</DeviceCompareSection>
													<DeviceCompareSection>
														{compareDeviceInfoList.map((device) => (
															<CompareListNotGrayed fromPdp={fromPdp}>
																{" "}
																<CompareSectionTitle>
																	<CompareTitle>{aem?.labels?.depth}</CompareTitle>
																	<CompareNewTitle>{device?.dimensions?.depth}</CompareNewTitle>
																</CompareSectionTitle>
															</CompareListNotGrayed>
														))}
													</DeviceCompareSection>
												</AccordionDetail>
											</AccordionItem>
										</Accordion>
										<Accordion>
											<AccordionItem opened={true} data-track={aem?.labels?.screen}>
												<AccordionHeader triggerType="icon">
													<AccordionTitle>
														<AccordionTitleClass>{aem?.labels?.screen}</AccordionTitleClass>
													</AccordionTitle>
												</AccordionHeader>
												<AccordionDetail>
													<DeviceCompareSection mb="40">
														{compareDeviceInfoList.map((device) => (
															<CompareListNotGrayed fromPdp={fromPdp}>
																{device.screen}
															</CompareListNotGrayed>
														))}
													</DeviceCompareSection>
												</AccordionDetail>
											</AccordionItem>
										</Accordion>
										<Accordion>
											<AccordionItem opened={true} data-track={aem?.labels?.reviews2}>
												<AccordionHeader triggerType="icon">
													<AccordionTitle>
														<AccordionTitleClass>{aem?.labels?.reviews2}</AccordionTitleClass>
													</AccordionTitle>
												</AccordionHeader>
												<AccordionDetail>
													<DeviceCompareSection mb="40">
														{compareDeviceInfoList.map((device) => (
															<CompareListNotGrayed fromPdp={fromPdp}>
																<RatingstarRenderer
																	{...device}
																	overallRating={
																		MobilityPageData.reviewsForCompareDevices !== null &&
																		MobilityPageData.reviewsForCompareDevices &&
																		Object.keys(MobilityPageData.reviewsForCompareDevices)
																			.length > 0 &&
																		MobilityPageData.reviewsForCompareDevices[device.deviceId] !==
																			null &&
																		MobilityPageData.reviewsForCompareDevices[device.deviceId]
																			? MobilityPageData.reviewsForCompareDevices[device.deviceId][
																					"averageRating"
																			  ]
																			: 0
																	}
																/>

																<Micro>
																	<TextLink onClick={onReviewsClick}>
																		{" "}
																		(
																		{MobilityPageData.reviewsForCompareDevices !== null &&
																		MobilityPageData.reviewsForCompareDevices &&
																		Object.keys(MobilityPageData.reviewsForCompareDevices)
																			.length > 0 &&
																		MobilityPageData.reviewsForCompareDevices[device.deviceId] !==
																			null &&
																		MobilityPageData.reviewsForCompareDevices[device.deviceId]
																			? MobilityPageData.reviewsForCompareDevices[device.deviceId][
																					"reviews"
																			  ]
																			: 0}{" "}
																		{aem?.labels?.reviews2})
																	</TextLink>
																	<MarginSpacerM />
																</Micro>
																<>
																	<CompareSectionTitle>
																		<CompareTitleReview>{aem?.labels?.design}</CompareTitleReview>
																		<CompareTitleReview>
																			{MobilityPageData?.reviewsForCompareDevices !== null &&
																			MobilityPageData?.reviewsForCompareDevices &&
																			Object.keys(MobilityPageData?.reviewsForCompareDevices)
																				.length > 0 &&
																			MobilityPageData?.reviewsForCompareDevices[
																				device.deviceId
																			] !== null &&
																			MobilityPageData?.reviewsForCompareDevices[device.deviceId]
																				? MobilityPageData?.reviewsForCompareDevices[
																						device.deviceId
																				  ]?.secondaryRatings?.Design?.AverageRating.toFixed(1)
																				: 0}
																		</CompareTitleReview>
																	</CompareSectionTitle>
																	<MarginSpacerM />

																	<CompareSectionTitle>
																		<CompareTitleReview>
																			{aem?.labels?.features}
																		</CompareTitleReview>
																		<CompareTitleReview>
																			{MobilityPageData?.reviewsForCompareDevices !== null &&
																			MobilityPageData?.reviewsForCompareDevices &&
																			Object.keys(MobilityPageData?.reviewsForCompareDevices)
																				.length > 0 &&
																			MobilityPageData?.reviewsForCompareDevices[
																				device.deviceId
																			] !== null &&
																			MobilityPageData?.reviewsForCompareDevices[device.deviceId]
																				? MobilityPageData?.reviewsForCompareDevices[
																						device.deviceId
																				  ]?.secondaryRatings?.Features?.AverageRating.toFixed(1)
																				: 0}
																		</CompareTitleReview>
																	</CompareSectionTitle>
																	<MarginSpacerM />

																	<CompareSectionTitle>
																		<CompareTitleReview>
																			{" "}
																			{aem?.labels?.storage}
																		</CompareTitleReview>
																		<CompareTitleReview>{"-"}</CompareTitleReview>
																	</CompareSectionTitle>
																	<MarginSpacerM />

																	<CompareSectionTitle>
																		<CompareTitleReview>
																			{aem?.labels?.display}
																		</CompareTitleReview>
																		<CompareTitleReview>{"-"}</CompareTitleReview>
																	</CompareSectionTitle>
																	<MarginSpacerM />

																	<CompareSectionTitle>
																		<CompareTitleReview>
																			{aem?.labels?.batteryLife}
																		</CompareTitleReview>
																		<CompareTitleReview>{"-"}</CompareTitleReview>
																	</CompareSectionTitle>
																	<MarginSpacerM />
																</>
															</CompareListNotGrayed>
														))}
													</DeviceCompareSection>
												</AccordionDetail>
											</AccordionItem>
										</Accordion>
										<Accordion>
											<AccordionItem opened={true} data-track={aem?.labels?.weight}>
												<AccordionHeader triggerType="icon">
													<AccordionTitle>
														<AccordionTitleClass>{aem?.labels?.weight}</AccordionTitleClass>
													</AccordionTitle>
												</AccordionHeader>
												<AccordionDetail>
													<DeviceCompareSection mb="40">
														{compareDeviceInfoList.map((device) => (
															<CompareListNotGrayed fromPdp={fromPdp}>
																{device.weight}
															</CompareListNotGrayed>
														))}
													</DeviceCompareSection>
												</AccordionDetail>
											</AccordionItem>
										</Accordion>
										<Accordion>
											<AccordionItem opened={true} data-track={aem?.labels?.storage}>
												<AccordionHeader triggerType="icon">
													<AccordionTitle>
														<AccordionTitleClass>{aem?.labels?.storage}</AccordionTitleClass>
													</AccordionTitle>
												</AccordionHeader>
												<AccordionDetail>
													<DeviceCompareSection mb="40">
														{compareDeviceInfoList.map((device) => {
															if (device?.storageOptions?.length > 1) {
																return (
																	<CompareListNotGrayed fromPdp={fromPdp}>
																		{device?.storageOptions?.map((item) => {
																			return (
																				<div>
																					{" "}
																					{item} <MarginSpacerM />
																				</div>
																			);
																		})}
																	</CompareListNotGrayed>
																);
															}
															return (
																<CompareListNotGrayed fromPdp={fromPdp}>
																	{device?.storageOptions?.map((storage, idx) => (
																		<div key={idx}>{storage}</div>
																	))}
																</CompareListNotGrayed>
															);
														})}
													</DeviceCompareSection>
												</AccordionDetail>
											</AccordionItem>
										</Accordion>
										<Accordion>
											<AccordionItem opened={true} data-track={aem?.labels?.rearCamera}>
												<AccordionHeader triggerType="icon">
													<AccordionTitle>
														<AccordionTitleClass>
															{aem?.labels?.rearCamera}
														</AccordionTitleClass>
													</AccordionTitle>
												</AccordionHeader>
												<AccordionDetail>
													<DeviceCompareSection mb="40">
														{compareDeviceInfoList.map((device) => (
															<CompareListNotGrayed fromPdp={fromPdp}>
																{device.rearCameraPixelSize}
															</CompareListNotGrayed>
														))}
													</DeviceCompareSection>
												</AccordionDetail>
											</AccordionItem>
										</Accordion>
										<Accordion>
											<AccordionItem opened={true} data-track={aem?.labels?.worldDevice}>
												<AccordionHeader triggerType="icon">
													<AccordionTitle>
														<AccordionTitleClass>
															{aem?.labels?.worldDevice}
														</AccordionTitleClass>
													</AccordionTitle>
												</AccordionHeader>
												<AccordionDetail>
													<DeviceCompareSection mb="40">
														{compareDeviceInfoList.map((device) => (
															<CompareListNotGrayed fromPdp={fromPdp}>
																{device.worldDevice}
															</CompareListNotGrayed>
														))}
													</DeviceCompareSection>
												</AccordionDetail>
											</AccordionItem>
										</Accordion>
										<Accordion>
											<AccordionItem opened={true} data-track={aem?.labels?.colors}>
												<AccordionHeader triggerType="icon">
													<AccordionTitle>
														<AccordionTitleClass>{aem?.labels?.colors}</AccordionTitleClass>
													</AccordionTitle>
												</AccordionHeader>
												<AccordionDetail>
													<DeviceCompareSection mb="40">
														{compareDeviceInfoList.map((device) => (
															<CompareListNewNotGrayed style={{ flexDirection: "row" }}>
																{colorSwatchRenderer(device)}
															</CompareListNewNotGrayed>
														))}
													</DeviceCompareSection>
												</AccordionDetail>
											</AccordionItem>
										</Accordion>
									</ComparingSection>
								</>
							) : (
								<>
									<ComparePDPCarousel
										compareDeviceInfoList={compareDeviceInfoList}
										handleDeviceTileCloseClick={handleDeviceTileCloseClick}
										RatingstarRenderer={RatingstarRenderer}
										onReviewsClick={onReviewsClick}
										colorSwatchRenderer={colorSwatchRenderer}
										aem={aem}
										fromPdp={fromPdp}
									/>
								</>
							)}
						</GridWallContent>
					</Col>
				</RowNoPad>
			</Grid>
		</>
	);
};

const RatingstarRenderer = ({ overallRating }) => {
	return (
		<Ratings>
			<RatingFull width={getFullRatingWidth(overallRating)}>
				{Array(5)
					.fill(1)
					.map((item, index) => (
						<RatingStar key={`fullstar-${index}`} fillColor="#000000" />
					))}
			</RatingFull>
			<RatingEmpty>
				{Array(5)
					.fill(1)
					.map((item, index) => (
						<RatingStar key={`emptystar-${index}`} fillColor="#FFFFFF" />
					))}
			</RatingEmpty>
		</Ratings>
	);
};

const RowNoPad = styled(Row)`
	${({ fromPdp }) =>
		fromPdp &&
		`> div {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }`}
`;

const LeftFlex = styled.div`
	${({ isMobile }) => `width: ${isMobile ? "100%" : "100%"};`}
`;
const RightFlex = styled.div`
	display: flex;
	flex-direction: row-reverse;
	gap: 1rem;
	margin-left: auto;
	padding: 16px 0px;
	width: 30%;
`;

const BorderBox = styled.div`
	width: 290px;
	border: 1px dashed #000000;
	box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.02);
	border-radius: 8px;
	margin-top: 6px;
	margin-left: 10px;
	margin-bottom: 8px;
`;

const ColorTextName = styled.div`
	margin-top: 2px;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
`;

const ComparingSection = styled.div`
width: 100% !important;
.toggleIconWrapper{
  right:10px !important;

`;

const CompareTitle = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #6f7171;

	@media only screen and (max-width: 767px) {
		font-size: 12px;
	}
`;
const CompareNewTitle = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #6f7171;
	margin-right: 10px;

	@media only screen and (max-width: 767px) {
		font-size: 12px;
		margin-right: 0px;
	}
`;
const CompareSectionTitle = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;
const CompareTitleReview = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	margin-top: 2px;
	color: #6f7171;
	@media only screen and (max-width: 767px) {
		font-size: 12px;
	}
`;
const AccordionTitleClass = styled.div`
	margin-left: 10px !important;
`;

const GridWallContent = styled.div`
	display: flex;
	flex-wrap: wrap;
	max-width: 1272px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 2rem;
`;

const CardContainer = styled.div`
	${({ fromPdp }) =>
		fromPdp
			? `
    width: 100%;
    margin-left:8px;
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: repeat(4, minmax(290px, 1fr));
  `
			: `
    width: 1272px;
    margin-left:auto;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
  `};
	margin-right: auto;
	padding-right: 0px;
`;

const CompareListGrayed = styled.div`
	display: flex;
	width: ${({ fromPdp }) => (fromPdp ? "25%" : "24%")};
	background-color: #fff;
	flex-direction: column;
	font-size: 14px;
	padding-left: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	justify-content: space-between;
	font-weight: 400;
	background-color: #f6f6f6 !important;

	@media only screen and (max-width: 767px) {
		font-size: 12px;
	}
`;

const CompareListNotGrayed = styled.div`
	display: flex;
	width: ${({ fromPdp }) => (fromPdp ? "25%" : "24%")};
	background-color: #fff;
	flex-direction: column;
	font-size: 14px;
	padding-left: 20px;
	display: flex;
	justify-content: flex-start;
	font-weight: 400;
	@media only screen and (max-width: 767px) {
		height: 100%;
		font-size: 12px;
	}
`;

const CompareListNewNotGrayed = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 25%;
	background-color: #fff;
	flex-direction: column;
	font-size: 14px;
	padding: 0px 4%;
	display: flex;
	justify-content: flex-start;
	font-weight: 400;
	align-items: baseline;
	@media only screen and (max-width: 767px) {
		height: 100%;
		font-size: 12px;
	}
`;

const ColorName = styled.div`
	display: flex;
	width: 33.33%;
	flex-direction: column;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	margin-bottom: 15px;

	svg {
		margin: 0 auto;
	}
`;

const DeviceCompareSection = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	${({ mb = 0 }) => `margin-bottom: ${mb}px;`};
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	margin-bottom: 10px;
	&.CompareDesc {
		background: #f6f6f6;
	}
`;

const Ratings = styled.div``;

const RatingFull = styled.div`
	position: absolute;
	z-index: 1;
	overflow: hidden;
	display: inline-block;
	white-space: nowrap;
	${({ width }) => `width: ${width};`}
`;

const RatingEmpty = styled.div`
	position: relative;
`;

const GridWallHeader = styled.div`
	padding-right: 1rem;
	padding-left: ${({ isMobile, fromPdp }) =>
		isMobile && fromPdp ? "0" : "1rem"};
	padding-bottom: 20px;
	width: 100%;
	display: flex;
`;
const Text = styled.div`
	display: flex;
	justify-content: space-between;
`;

export default GridwallCompareDevices;
