import React, { useState } from "react";
import { Modal, ModalBody,ModalFooter } from "@vds3/modals";
import { Input } from "@vds3/inputs";
import { MarginSpacerM } from "common/components/styleTag";
import { DropdownSelect } from "@vds3/selects";
import { Button } from "@vds3/buttons";

export const BUSSModal = ({ opened = false, displayItems = [],sendResultData=()=>{},setModalsState=()=>{} }) => {
    const [userData,setUSerData] = useState({})
    const [mandatoryD,setMandatory] = useState({})
    const isMandatory = displayItems.filter(data=>data?.mandatory).length

    const handleChange = (e,id,isMandatory)=>{
        const val = e.target.value
        setUSerData({...userData,[id]:val})
        if(isMandatory) setMandatory({...mandatoryD,[id]:val})
    }
    const handleContinue = ()=>{
        let resultData = []
        displayItems.map(item=>{
            if(userData[item?.id]){
            resultData.push({
                "valueType": String(item?.valueType).charAt(0).toUpperCase() + String(item?.valueType).slice(1),
                "name": item?.name,
                "value": userData[item?.id]
            })
        }
        })
    sendResultData(resultData)
    }

    return (
        <Modal hideCloseButton={false} opened={opened} disableOutisdeClick={false} onOpenedChange={(val)=>{if(!val)setModalsState(val)}}>
            <ModalBody>
                {displayItems.map((item) => (
                    <>
                        {item?.valueType === 'text' && item?.mandatory ?
                            <>
                                <Input
                                    type={item?.valueType}
                                    label={item?.name}
                                    required={item?.mandatory}
                                    helperTextPlacement="bottom"
                                    helperText={item?.description}
                                    value={userData[item?.id]}
                                    onChange={(e)=>handleChange(e,item?.id,item?.mandatory)}
                                />
                                <MarginSpacerM />
                            </>
                            : item?.valueType === 'dropdown' && item?.mandatory ? <>
                                <DropdownSelect
                                    label={item?.name}
                                    onChange={(e)=>handleChange(e,item?.id,item?.mandatory)}
                                    width={"100%"}
                                    helperText={item?.description}
                                    helperTextPlacement="bottom"
                                    selectId = {userData[item?.id]}
                                    required = {item?.mandatory}
                                >
                                    <option defaultSelected={true}>Select</option>
                                    {item?.characteristicValueSpecification.map(drpDVal=>(
                                    <option value={drpDVal?.value}>{drpDVal?.value}</option>
                                    ))}
                                </DropdownSelect>
                                <MarginSpacerM/>
                            </> : <></>}
                    </>
                ))}
            </ModalBody>
            <ModalFooter>
                <Button onClick = {()=>handleContinue()} disabled = {isMandatory !== Object.keys(mandatoryD).length} >Continue</Button>
            </ModalFooter>
        </Modal>
    )
}

