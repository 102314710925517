import React from "react";
import styled from "styled-components";
import Ratings from "../Ratings";
import PDPContext from "../pdpContext";
import { TextLink } from "@vds3/buttons";
import styles from "./PriceInfo.module.css";

const RatingContainer = styled.div`
	display: flex !important;
`;

const CustomTextLink = styled(TextLink)`
	font-family: "Verizon-NHG-eDS";
	margin-left: 5px;
	font-weight: 400;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.5px;
	text-align: left;
`;
const ColSpan = styled.div`
	grid-column: span 2;
`;
const Container = styled.div``;

const PriceInfo = ({ showDescription, reviewCount, ...props }) => {
	const {
		product,
		memoryOptions,
		selectedSku: { selectedSku, setSelectedSku },
		isOneTalk,
		averagRating,
	} = React.useContext(PDPContext);
	const currentSku = selectedSku || (memoryOptions && memoryOptions[0]);
	const { devicePayments, zeroYear } = currentSku?.pricingDetails ?? {
		devicePayments: null,
		zeroYear: null,
	};

	const reviewClickHandler = () => {
		let selecteObj = { ...selectedSku, reviewIndex: "Reviews" };
		setSelectedSku(selecteObj);
	};

	return (
		<Container>
			{reviewCount > 0 && (
				<div
					class={
						styles[isOneTalk ? "pricing-container-onetalk" : "pricing-container"]
					}
				>
					<RatingContainer>
						<Ratings points={averagRating} />
						<CustomTextLink size="large" onClick={() => reviewClickHandler()}>
							{" "}
							Reviews ({reviewCount})
						</CustomTextLink>
					</RatingContainer>
					<ColSpan className="apr"></ColSpan>
				</div>
			)}
		</Container>
	);
};

export default PriceInfo;
