import React from "react";
import { Title, Body } from "@vds3/typography";
import { MarginSpacerS, MarginSpacerM } from "common/components/styleTag";
import { formatPriceString } from "utils/commonUtils";
import {
  PackageDetailContainer,
  PackageDetailDiv,
  PackagePriceDiv,
} from "./styles";
import CartPkgContext from "./cartPkgContext";
import { useTranslation } from "react-i18next";

const PackageByorMwwDetail = ({ ...props }) => {
  const { t } = useTranslation(["shoppingCart"]);
  const { pack, quantity } = React.useContext(CartPkgContext);
  const totalPrice =
    pack?.groupDisplayPrice?.totalItemPrice ??
    Number(pack?.itemPrice) * quantity;
  return (
    <div>
      <Title size="medium" bold>
        Use your own router
      </Title>
      <MarginSpacerS />
      <PackageDetailContainer>
        <PackageDetailDiv>
          <Body size="small" color="#6F7171">
            {t("shopping-cart.cart.productNouns.router", {
              count: quantity,
            })}
          </Body>
        </PackageDetailDiv>
        <PackagePriceDiv>
          <Body size="large">{formatPriceString(totalPrice)}</Body>
        </PackagePriceDiv>
      </PackageDetailContainer>
      <MarginSpacerM />
    </div>
  );
};

export default PackageByorMwwDetail;
