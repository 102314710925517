import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TextLink } from "@vds3/buttons";
import { Tabs, Tab } from "@vds3/tabs";
import Features from "./features/features";
import Specifications from "./specs/specs";
import Reviews from "./Reviews/ReviewsVds3";
import CalifProp65Modal from "common/components/Modals/CalifProp65Modal";
import { checkForReviews } from "./pdpUtils";
import PDPContext from "./pdpContext";
import { MarginTopSpacerL } from "common/components/styleTag";
import GridwallCompareDevices from "../MobilityGridwall/GridwallCompareDevices";

// const GridwallCompareDevices = React.lazy(() =>
// 	import("pages/Mobility/components/MobilityGridwall/GridwallCompareDevices")
// );

const OverviewContainer = styled.div`
	margin: 1.5rem 0 48px 0;
	padding: 0;
	a {
		color: black;
	}
`;

const OverViewDescription = styled.p`
	font-size: 16px !important;
	line-height: 20px !important;
	font-family: Verizon-NHG-eDS !important;
	font-weight: 400 !important;
	line-height: 20px !important;
	letter-spacing: 0.5px !important;
	text-align: left !important;
	color: #000000 !important;
	width: 49% !important;
	@media only screen and (min-width: 320px) and (max-width: 767px) {
		width: 100% !important;
	}
`;
const OverviewFeatures = styled.div`
	display: flex;
	line-height: 10px;
	align-items: center;
	cursor: pointer;
`;

const TABS = ["Overview", "Features", "Specs", "Reviews"];

const DetailTabs = ({ reviews, ...props }) => {
	const { t } = useTranslation(["app"]);
	const {
		product,
		isOneTalk,
		selectedSku: { selectedSku, setSelectedSku },
		averagRating,
		pegaRecommendations,
		compareDeviceName,
		compareSelectedSku,
	} = React.useContext(PDPContext);
	const [tabIndex, setTabIndex] = useState(0);
	const [seeMore, setSeeMore] = useState(false);
	const [showProp65Modal, setShowProp65Modal] = useState(false);
	const linkName = seeMore ? "See Less" : "See More";
	const [tabs, setTabs] = useState(["Overview", "Specs"]);

	useEffect(() => {
		if (product?.devicePdpDetails?.features?.length > 0) {
			const tabsCopy = [...tabs];
			if (tabsCopy.indexOf("Features") === -1) {
				tabsCopy.splice(1, 0, "Features");
			}
			setTabs(tabsCopy);
		}
	}, [product]);

	const onTabChangeFunc = (event, index) => {
		setTabIndex(index);
	};

	useEffect(() => {
		if (selectedSku?.reviewIndex) {
			setTabIndex(
				checkForReviews(reviews) ? tabs.indexOf(selectedSku?.reviewIndex) : 0
			);
		}
	}, [selectedSku]);

	useEffect(() => {
		if (selectedSku?.reviewIndex == "Reviews") {
			var element = document.getElementById("pdpTabs");
			element.scrollIntoView();
			let selecteObj = { ...selectedSku, reviewIndex: "" };
			setSelectedSku(selecteObj);
		}
	}, [tabIndex, selectedSku?.reviewIndex]);

	const getDescriptionText = (
		desc,
		ignoreSubstring = false,
		ignoreStripHTML = false
	) => {
		if (!desc) return desc;
		let text = ignoreStripHTML ? desc : stripHTML(desc);
		text = seeMore || ignoreSubstring ? text : text.substr(0, 90);
		return <span dangerouslySetInnerHTML={{ __html: text }} />;
	};
	useEffect(() => {
		if (!tabs.includes("Reviews")) {
			setTabs((data) => {
				if (!data.includes("Reviews")) {
					let result = checkForReviews(reviews) ? "Reviews" : "";
					if (result === "Reviews") data.push(result);
				}
				return data;
			});
		}
	}, [reviews]);
	const getTabContent = React.useMemo(() => {
		const tabSelected = tabs[tabIndex];
		switch (tabSelected) {
			case "Features":
				return (
					<Features
						deviceDetails={product?.devicePdpDetails}
						isOneTalk={isOneTalk}
					/>
				);
			case "Specs":
				return (
					<Specifications
						deviceDetails={product?.devicePdpDetails}
						isOneTalk={isOneTalk}
						sku={product?.deviceDetails?.skulist[0]?.sku}
					/>
				);
			case "Reviews":
				return (
					<div id="reviewBlock" className="u-paddingTopMedium">
						<Reviews reviews={reviews}></Reviews>
					</div>
				);
			default:
				return (
					<OverviewContainer>
						<OverViewDescription>
							{getDescriptionText(product?.devicePdpDetails?.description, true, true)}
						</OverViewDescription>
						<div className="warning-section">
							<span>
								{`${t("app.common.prop65WarningModal.headingInfo.primary", {
									ns: "app",
								})} `}
							</span>
							{isOneTalk && <MarginTopSpacerL />}
							<TextLink
								onClick={() => {
									setShowProp65Modal(true);
								}}
							>
								{t("app.common.prop65WarningModal.headingInfo.secondary", {
									ns: "app",
								})}
								&nbsp;
								<span
									style={{ fontWeight: "bold" }}
									dangerouslySetInnerHTML={{
										__html: `${t("app.common.prop65WarningModal.headingInfo.warning", {
											ns: "app",
										})}`,
									}}
								></span>
							</TextLink>
						</div>
						<MarginTopSpacerL />
						{/* {tabs.map((item, index) => {
                if (!item || item === "Overview") {
                  return "";
                }
                return (
                  <>
                    {item === "Reviews" && (
                      <div>
                        <OverviewReviews reviews={reviews} averagRating={averagRating}></OverviewReviews>
                        <MarginTopSpacerL />
                      </div>
                    )}
                    <OverviewFeatures
                      className=""
                      onClick={() => {
                        setTabIndex(index);
                      }}
                    >
                      <Body size="large" bold>
                        All {item === "Reviews" ? "reviews"  :item}
                      </Body>
                      <Caret />
                    </OverviewFeatures>
                    <MarginTopSpacerL />
                    <MarginTopSpacerL />
                  </>
                );
              })} */}
						<Suspense fallback={<></>}>
							{pegaRecommendations?.length > 0 ? (
								<GridwallCompareDevices
									// category={data?.deviceDetails?.category || "smartphones"}
									fromPdp={true}
									selectedSku={compareSelectedSku}
									deviceName={compareDeviceName}
								/>
							) : (
								<></>
							)}
						</Suspense>
					</OverviewContainer>
				);
		}
	}, [
		product,
		tabIndex,
		tabs,
		reviews,
		compareDeviceName,
		compareSelectedSku,
		pegaRecommendations,
	]);

	return (
		<>
			<Tabs
				id="pdpTabs"
				indicatorPosition="bottom"
				linePosition="bottom"
				onTabChange={onTabChangeFunc}
				orientation="horizontal"
				selectedIndex={tabIndex}
			>
				{tabs.map((item) => (
					<Tab label={item} key={item} />
				))}
			</Tabs>
			{getTabContent}
			{showProp65Modal && (
				<CalifProp65Modal
					opened={showProp65Modal}
					onOpenedChange={setShowProp65Modal}
				/>
			)}
		</>
	);
};

export default React.memo(DetailTabs);
