import { formatPriceString } from "utils/commonUtils";

export const getStartsAtPrice = (deviceDetails) => {
  let price = 0;
  const pricing = deviceDetails?.pricingDetails;
  if (pricing?.devicePayments?.length && pricing?.devicePayments[0]) {
    price =
      pricing?.devicePayments[0]?.offerDetails?.discountAmount ??
      pricing?.devicePayments[0]?.finalPrice;
  } else {
    // no DPP available, use FRP
    price = pricing?.zeroYear?.finalPrice;
  }
  return isNaN(price) ? 0 : Number(price).toFixed(2);
};

export const getFullRatingWidth = (rating) => {
  const size = Math.round(20 * rating);
  return size + "px";
};

export const checkForReviews = (reviews) => {
  if (!reviews) return null;
  return (
    Object.keys(reviews?.Includes).length > 0 || reviews?.Results?.length > 0
  );
};

export const capacityToGB = (capacity) => {
  const arr = capacity.split(" ");
  const ret = Number(
    capacity.split(" ")[0] * (capacity.split(" ")[1] === "TB" ? 1024 : 1)
  );
  return ret;
};

export const getDefaultSku = (deviceOptions, sortBy, ignoreStock = false) => {
  let defaultProduct;
  let defaultSkuObj = [];
  if (deviceOptions) {
    if (deviceOptions[0].capacity) {
      deviceOptions = Array.from(deviceOptions).sort((a, b) => {
        if (a.capacity && b.capacity) {
          return capacityToGB(a.capacity) - capacityToGB(b.capacity);
        }
        return 0;
      });
    }
  }
  if (deviceOptions.every((o) => o.backOrder)) {
    if (
      deviceOptions.every(
        (e) => e.shipmentDate === deviceOptions[0].shipmentDate
      )
    ) {
      // check for lowest dpp
      defaultProduct = Array.from(deviceOptions).sort((a, b) => {
        if (!a.devicePaymentFinalPrice) return 1;
        if (a.devicePaymentFinalPrice && b.devicePaymentFinalPrice)
          return a.devicePaymentFinalPrice - b.devicePaymentFinalPrice;
        return -1;
      })[0];
    } else {
      defaultProduct = Array.from(deviceOptions).sort((a, b) => {
        if (new Date(a.shipmentDate) <= new Date(b.shipmentDate)) {
          return a.outOfStock ? 1 : -1;
        }
      })[0];
    }
  }
  // to display black device as default if it is in stock
  if (sortBy === "featured" || ignoreStock) {
    defaultProduct = deviceOptions.find(
      (d) =>
        d.color !== null &&
        d.color &&
        d.color.toLowerCase().includes("black") &&
        d.color &&
        !d.color.toLowerCase().includes("white") &&
        (ignoreStock || !d.outOfStock)
    );
    // to display a device which is in stock (if black not available)
    if (!defaultProduct) {
      defaultProduct = deviceOptions.find((d) => !d.outOfStock);
    }
  }
  // if any sort by is applied, then it should display a device which is in stock (if black not available) - added for VBGHSA-6805
  // getting device.sortBy as undefined, so added another sortBy as Input variable
  else if (sortBy) {
    defaultProduct = deviceOptions.find((d) => !d.outOfStock);
  }
  // to display first device in an array
  if (!defaultProduct) {
    defaultProduct = deviceOptions[0];
  }
  defaultSkuObj.push(defaultProduct);
  defaultSkuObj.push(deviceOptions);
  return defaultSkuObj;
};

export const sortStorageCapacities = (a, b) => {
  // ASSUMING STRING FORMAT LIKE "256 GB"
  const UNITS = ["GB", "TB"];
  let [aNum, aUnit] = a.split(" ");
  let [bNum, bUnit] = b.split(" ");
  aUnit = aUnit?.substring(0, 2);
  bUnit = bUnit?.substring(0, 2);
  if (aUnit !== bUnit) {
    return (
      UNITS.indexOf(aUnit.toUpperCase()) - UNITS.indexOf(bUnit.toUpperCase())
    );
  } else {
    return +aNum - +bNum;
  }
};

export const getEllipsisText = (value, args) => {
  if (value && args && value.length > args) {
    const limit = value.substr(0, args).lastIndexOf(" ");
    return value.substr(0, limit) + "...";
  } else {
    return value;
  }
};

export const getPromoDetails = (selectedSku) => {
  const val =
    !!selectedSku?.pricingDetails?.devicePayments?.length &&
    !!selectedSku?.pricingDetails?.devicePayments?.[0]?.promoDetails?.length &&
    selectedSku?.pricingDetails?.devicePayments?.[0]?.promoDetails[0]?.promoMessages?.filter(
      (i) => i.placement === "PDP"
    );
  return val[0];
};

export const findSelectedPromo = ({
  selectedSku,
  isBogo,
  bogoPromoTypes,
  tradeInPromoEligible,
  onPromoSelect,
}) => {
  if (
    selectedSku?.pricingDetails?.devicePayments?.length &&
    selectedSku?.pricingDetails?.devicePayments?.[0]?.promoDetails
  ) {
    let promo = null;
    for (const p of selectedSku?.pricingDetails?.devicePayments?.[0]
      ?.promoDetails) {
      if (p?.promoMessages.some((i) => i.placement === "PDP")) {
        if (isBogo && bogoPromoTypes.includes(p.promoType)) {
          promo = p;
          break;
        } else if (
          tradeInPromoEligible &&
          p.promoType === "TRADEIN_PER_MONTH"
        ) {
          promo = p;
          break;
        }
      }
    }
    if (promo) {
      onPromoSelect(promo);
    }
  }
};

export const getPriceSubtitle = ({
  selectedSku,
  selectedPayment,
  isBogo,
  tradinPriceForSubtitle,
  fromAccessory,
  outOfStockDevice,
  outOfStockText,
}) => {
  return !outOfStockDevice ? (
    fromAccessory ? (
      ""
    ) : (
      <>
        {selectedSku?.color ||
        selectedSku?.capacity ||
        !selectedSku?.outOfStock ? (
          <>
            {" "}
            {selectedSku?.capacity ? <> {selectedSku?.capacity + ", "}</> : ""}
            {selectedPayment == "monthly" ? (
              <span>
                {tradinPriceForSubtitle !== null &&
                tradinPriceForSubtitle > -1 ? (
                  <>
                    {formatPriceString(tradinPriceForSubtitle) + " "}
                    <s>
                      $
                      {
                        selectedSku?.pricingDetails?.devicePayments?.[0]
                          ?.finalPrice
                      }
                      /mo
                    </s>
                  </>
                ) : selectedSku?.pricingDetails !== null &&
                  selectedSku?.pricingDetails ? (
                  selectedSku?.pricingDetails?.devicePayments?.length &&
                  selectedSku?.pricingDetails?.devicePayments?.[0]?.promoDetails
                    ?.length &&
                  selectedSku?.pricingDetails?.devicePayments?.[0]?.offerDetails
                    ?.discountAmount ? (
                    <>
                      {formatPriceString(
                        selectedSku?.pricingDetails?.devicePayments?.[0]
                          ?.offerDetails?.discountAmount
                      ) + " "}
                      {isBogo ? (
                        ""
                      ) : (
                        <s>
                          $
                          {
                            selectedSku?.pricingDetails?.devicePayments?.[0]
                              ?.finalPrice
                          }
                          /mo
                        </s>
                      )}{" "}
                    </>
                  ) : (
                    <span>
                      {selectedSku?.pricingDetails?.devicePayments?.[0]
                        ?.offerDetails?.discountAmount ? (
                        <>
                          {formatPriceString(
                            selectedSku?.pricingDetails?.devicePayments?.[0]
                              ?.offerDetails?.discountAmount
                          ) + " "}
                          {isBogo ? (
                            ""
                          ) : (
                            <s>
                              $
                              {
                                selectedSku?.pricingDetails?.devicePayments?.[0]
                                  ?.finalPrice
                              }
                              /mo
                            </s>
                          )}{" "}
                        </>
                      ) : (
                        selectedSku?.pricingDetails?.devicePayments?.length &&
                        formatPriceString(
                          selectedSku?.pricingDetails?.devicePayments?.[0]
                            ?.finalPrice
                        ) + "/mo"
                      )}
                    </span>
                  )
                ) : (
                  <></>
                )}
                {!!selectedSku?.pricingDetails?.devicePayments?.length && (
                  <> for 36 months</>
                )}
              </span>
            ) : selectedPayment == "twoyears" ? (
              `$${selectedSku?.pricingDetails?.twoYear?.price}`
            ) : selectedSku?.pricingDetails !== null &&
              selectedSku?.pricingDetails?.zeroYear?.price ? (
              `$${selectedSku?.pricingDetails?.zeroYear?.price}`
            ) : (
              <></>
            )}
          </>
        ) : (
          <>{outOfStockText}</>
        )}
      </>
    )
  ) : (
    <>{outOfStockText}</>
  );
};

export const getUniqueColors = (list) => {
  const colors = new Map();
  list.forEach((sku, index) => {
    const existing = colors.get(sku.cssColor);
    if (!existing || existing?.outOfStock) {
      colors.set(sku.cssColor, {
        index: index,
        color: sku.color,
        cssColor: sku.cssColor,
        caseColor: sku?.caseColor,
        caseColorCode: sku?.caseColorCode,
        skuId: sku.sku,
        outOfStock: sku.outOfStock,
      });
    }
  });
  return Array.from(colors.values());
};

export const getUniqueDeviceList = (list) => {
  const PreOwnedDevices = new Map();
  list.forEach((sku, index) => {
    const existing = PreOwnedDevices.get(sku.conditionShort);
    if (!existing && sku?.conditionShort) {
      PreOwnedDevices.set(sku.conditionShort, {
        index: index,
        conditionLong: sku.conditionLong,
        conditionShort: sku.conditionShort,
        conditionUrl: sku.conditionUrl,
        skuId: sku.sku,
      });
    }
  });
  return Array.from(PreOwnedDevices.values());
};

export const getUniqueWatchCases = (list) => {
  const cases = new Map();
  list.forEach((sku, index) => {
    const existing = cases.get(sku?.caseMaterial);
    if (!existing || existing?.outOfStock) {
      cases.set(sku.caseMaterial, {
        index,
        skuId: sku.sku,
        caseMaterial: sku.caseMaterial,
        outOfStock: sku.outOfStock,
      });
    }
  });
  return Array.from(cases.values());
};

export const getUniqueWatchSizesAndBands = (list) => {
  const sizes = new Map();
  const bands = new Map();
  list.forEach((sku, index) => {
    const existingSize = sizes.get(sku?.caseSize);
    const existingBand = bands.get(sku?.bandType);
    if (!existingSize || existingSize?.outOfStock) {
      sizes.set(sku.caseSize, {
        index,
        skuId: sku.sku,
        outOfStock: sku.outOfStock,
        name: sku.caseSize,
        desc: sku?.caseSizeDesc,
      });
    }
    if (!existingBand || existingBand?.outOfStock) {
      bands.set(sku.bandType, {
        index,
        skuId: sku.sku,
        outOfStock: sku.outOfStock,
        name: sku.bandType,
        desc: sku.bandDesc,
      });
    }
  });
  return {
    sizes: Array.from(sizes.values()),
    bands: Array.from(bands.values()),
  };
};

export const getUniqueWatchBands = (list) => {
  const sizes = new Map();
  const colors = new Map();
  list.forEach((sku, index) => {
    const existingSize = sizes.get(sku?.bandSize);
    const existingColor = colors.get(sku?.bandColor);
    if (!existingSize || existingSize?.outOfStock) {
      sizes.set(sku.bandSize, {
        index,
        skuId: sku.sku,
        outOfStock: sku.outOfStock,
        name: sku.bandSize,
        desc: sku?.bandSizeDesc,
      });
    }
    if (!existingColor || existingColor?.outOfStock) {
      colors.set(sku.bandColor, {
        index,
        skuId: sku.sku,
        outOfStock: sku.outOfStock,
        name: sku.bandColor,
        desc: sku.bandColorDesc,
      });
    }
  });
  return {
    colors: Array.from(colors.values()),
    sizes: Array.from(sizes.values()),
  };
};

export const sortAlphaByName = (list) =>
  list.sort((a, b) => {
    if (a?.name < b?.name) return -1;
    else if (a?.name > b?.name) return 1;
    else return 0;
  });
