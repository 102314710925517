import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Body, Title } from "@vds3/typography";
import { TextLink } from "@vds3/buttons";
import styled from "styled-components";
import { Modal } from "@vds3/modals";
//import { InStoreSPickUpSVG } from "pages/Accessories/components/ProductDetails/components/ExternalSVGs";
import PDPContext from "./pdpContext";
import {
	MarginSpacerS,
	MarginSpacerL,
	MarginSpacerXL,
} from "common/components/styleTag";

const Container = styled.div`
	padding-top: 48px;
	margin: 1rem 0;
	display: flex;
	flex-direction: column;
	.spacer {
		margin-bottom: 1rem;
	}
	h4 {
		font-size: 24px;
		font-family: Verizon-NHG-eDS;
		padding-bottom: 16px;
		font-weight: 700;
	}
	@media screen and (min-width: 230px) and (max-width: 767px) {
		h4 {
			font-size: 20px;
			font-weight: 700;
			font-family: Verizon-NHG-eDS;
		}
	}
`;
const Options = styled.div`
	display: flex;
	flex-direction: column;
`;
const Option = styled.div`
	margin: 0rem 0;
	margin-bottom: 8rem;
	display: flex;
	align-items: center;
	svg {
		margin-right: 0.5rem;
	}
	@media screen and (min-width: 230px) and (max-width: 767px) {
		margin-bottom: 0rem;
	}
	.imageText p {
		 {
			padding-left: 8px;
		}
	}
`;

const SvgContainer = styled.span`
	svg {
		height: 21px;
		width: 23px;
		margin-right: 0.1rem;
	}
`;
const Shippinginstruction = styled.div`
	padding-bottom: 1rem !important;
	p {
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0.5px;
		text-align: left;
		font-family: Verizon-NHG-eDS;
	}
`;
const CustomTextLink = styled(TextLink)`
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.5px;
	text-align: left;
	font-family: Verizon-NHG-eDS;
	margin-left: 2px;
`;
const ImageText = styled.div`
	padding: 8px;
	p {
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0.5px;
		text-align: left;
		font-family: Verizon-NHG-eDS;
	}
`;

const ShippingOptions = (...props) => {
	const { t } = useTranslation(["pdp"]);
	const {
		selectedSku: { selectedSku, setSelectedSku },
	} = React.useContext(PDPContext);
	const backordershippingstartdate = selectedSku?.shipmentDate;
	const backorderstartDate = selectedSku?.backOrderStartDate;
	const backordershipmentdate = new Date(backordershippingstartdate).toString();
	const backorderstartdatec = new Date(backorderstartDate).toString();
	let updatedbackorderstartdate = backorderstartdatec.split(" ");
	let fullDatebo = `${updatedbackorderstartdate[0]}, ${updatedbackorderstartdate[1]} ${updatedbackorderstartdate[2]}`;
	let upatedDate = backordershipmentdate.split(" ");
	let fullDatesd = `${upatedDate[0]}, ${upatedDate[1]} ${upatedDate[2]}`;
	const [isSeeDetails, setIsSeeDetails] = useState(false);
	const seeDetails = () => {
		setIsSeeDetails(true);
	};
	const showSeeDetails = () => {
		return (
			<Modal ariaLabel="" opened={isSeeDetails} onOpenedChange={setIsSeeDetails}>
				<Title size="large">
					{t("device-pdp.shippingOptions.getItFast", { ns: "pdp" })}
				</Title>
				<MarginSpacerXL />
				<Body bold size="large">
					{t("device-pdp.shippingOptions.free2DayShipping", { ns: "pdp" })}
				</Body>
				<MarginSpacerS />
				<Body size="large">
					{t("device-pdp.shippingOptions.free2DayShippingInfo", { ns: "pdp" })}
				</Body>
				<MarginSpacerL />
				<Body bold size="large">
					{t("device-pdp.shippingOptions.freeInStorePickup", { ns: "pdp" })}
				</Body>
				<MarginSpacerS />
				<Body size="large">
					{t("device-pdp.shippingOptions.freeInStorePickupInfo", { ns: "pdp" })}
				</Body>
			</Modal>
		);
	};
	return (
		<Container>
			<Title size="medium" bold>
				{t("device-pdp.shippingOptions.shipping", { ns: "pdp" })}
			</Title>
			{selectedSku?.shipmentDate && selectedSku?.backOrder && (
				<Body size="medium" bold className="title">
					{t("device-pdp.shippingOptions.dtBtwnMsg", { ns: "pdp" })
						.replace("{{backordershippingdate}}", fullDatesd)
						.replace("{{backordershippingstartdate}}", fullDatebo)}
				</Body>
			)}
			<Shippinginstruction>
				<Body size="medium" className="title">
					{" "}
					{t("device-pdp.shippingOptions.info", { ns: "pdp" })}
					<CustomTextLink onClick={() => seeDetails()}>Details</CustomTextLink>
				</Body>
			</Shippinginstruction>
			<Options className="spacer">
				<Option>
					<img
						className="shippingImage paddingright5"
						src={`${window?.mbtGlobal?.assetsBaseUrl}/assets/images/icons/shipping_blk.png`}
					/>
					<ImageText>
						<Body size="medium">
							{t("device-pdp.shippingOptions.free2DayShipping", { ns: "pdp" })}
						</Body>
					</ImageText>
				</Option>
				{/* VBGHSA-13948: if no content for ISPU, hide ISPU section */}
				{t("device-pdp.shippingOptions.freeInStorePickup", { ns: "pdp" }) ? (
					<Option>
						<SvgContainer>{/* // <InStoreSPickUpSVG />{" "} */}</SvgContainer>

						<Body size="medium">
							{" "}
							{t("device-pdp.shippingOptions.freeInStorePickup", { ns: "pdp" })}
						</Body>
					</Option>
				) : null}
			</Options>
			{isSeeDetails && showSeeDetails()}
		</Container>
	);
};

export default React.memo(ShippingOptions);
