import styled from 'styled-components'
import { Input } from '@vds/inputs'
import { spacers } from '@vds-core/theme'
import { Notification } from '@vds3/notifications'

export const ButtonWrapper = styled.div`
    display: flex;
    button {
        margin-right:2rem;
        margin-bottom: 2rem;
        width: 250px;
    }
        @media only screen and (max-width: 600px) {
            display: block;
        }
  }
`
export const MarginSpacerM = styled.div`
    margin-top: ${spacers.medium} !important;
    margin-bottom: ${spacers.medium} !important;
`
export const MarginSpacerL = styled.div`
    margin-top: ${spacers.large} !important;
    margin-bottom: ${spacers.large} !important;
`
export const ExternalLinkSpacer = styled.div`
    margin-top: ${spacers.large} !important;
    margin-bottom: ${spacers.large} !important;
`

export const StreetInputDiv = styled.div`
position: relative;
width: 100%;
.placholder-text input{
    color: #d3d3d3;
}
`
export const StreetInput = styled(Input)`

`
export const UnitTextInput = styled(Input)`
 
`
export const SuggestionListDiv = styled.div`
    position: absolute;
    background-color: white;
    z-index: 10;
    width: 100%;
    border: 1px solid rgb(204, 204, 204);
    overflow: auto;
    max-height: 10rem;
    top:4rem;
    max-width: 37.25rem;
    min-width: 4.125rem;
    .suggestion-list-item {
        border-bottom: 1px solid rgb(204,204,204);
        border-left: .375rem solid white;
    }
    
    .suggestion-list-item:active, .suggestion-list-item:hover{
        border-left-color: #cd040b;
        background-color: #f6f6f6;
        cursor: pointer;
    }
    
    .suggestion-list-item span {
        display: block;
        padding: .45rem .5rem;
    }
`

export const DropdownInput = styled(Input)`
    cursor: pointer;
    color: transparent;
    text-shadow: 0 0 0 #000000;
`
export const TextInput = styled(Input)`
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
`

export const UnitsSectionDiv = styled.div`

    padding-left: 0;
    padding-right: 0;

    .Unit-inputbox {
        // margin-top: 1.5rem;
    }

    .unit-suggestion-list {
        position: absolute;
        background-color: white;
        z-index: 10;
        width: 100%;
        border: 1px solid rgb(204,204,204);
        overflow: auto;
        max-height: 10rem;
    }

    .dropdown-align{
        display: inline-block;
        width: 100%;
    }

    .dropdown-caret {
        position: absolute;
        right: 5px;
        top: 32px;
        cursor: pointer;
        pointer-events: none;
    }
`

export const CAButtonWrapper = styled.div`
    margin: 1.5rem 0;
`
// export const StyledTitle = styled(Feature)`
//     font-size: 3rem; 
// `
export const StyledSubTitle = styled.div`
    padding-top: 1rem;
`  

export const StyledNotification = styled(Notification)`
    margin-top:0.5rem;
    > div  > div {
        display: block !important;
    }
`

export const DefaultWrapper = styled.div`

`
export const CheckboxWrapper = styled.div`
    margin-top:1.5rem;  
`

export const EyeUnderline = styled.span`
position: absolute;
top: 30px;
right: 38px;
text-decoration: underline;
cursor: pointer;
`