import React, { useEffect, useState } from "react";
import { Body } from "@vds3/typography";
import { Title } from "@vds3/typography";
import styled from "styled-components";
import {
    MarginSpacerM,
    MarginSpacerXL,
    MarginSpacerXS,
} from "common/components/styleTag";
import { Button } from "@vds3/buttons";
import { formatPriceString } from "utils/commonUtils";
import _ from "lodash";
import { BUSSModal } from "./BussModal";
import { Table,  TableHead,  TableHeader,  TableBody,  TableRow,  Cell } from "@vds3/tables";
import {InputStepper} from '@vds/input-stepper'


export const PDPListView = ({
    bussData,
    aemContent,
    planDetails,
    handleContinueClick,
    frequency,
    slicedPlansList,
    type,
    selectedProducts,
    dataTosend={}
}) => {
    const [selectedPlanCat,setSelectedPlanCat] = useState([])
  const [defaultData,setDefaultData] = useState({})
  const [openModal,setModal] = useState(false)
  const [currentId,setCurrentId] = useState('')
  const [storeData,setStoreData] = useState({})
    useEffect(()=>{
        if(!!dataTosend && Object.keys(dataTosend).length){
            setStoreData({...storeData,...dataTosend})
        }
    },[dataTosend])
    return (
        <div>
            {selectedPlanCat!==null&&selectedPlanCat&&selectedPlanCat.length && openModal  ? 
            <BUSSModal opened={openModal} 
            displayItems = {selectedPlanCat}
            sendResultData = {(data)=>{
            const {itemId,frequency,vewName,quantity,selectedItem} = defaultData[currentId]
            handleContinueClick(itemId,frequency,vewName,data,quantity, selectedItem);
            setStoreData({...storeData,[currentId]:data})
            setModal(false)
            }}
            setModalsState = {val=>setModal(val)}
            />: <></>}
            <Table
                striped={false}
                bottomLine="secondary"
                padding="standard"
            >
                <TableHead bottomLine="primary">
                    <TableHeader>Plans</TableHeader>
                    <TableHeader>Quantity </TableHeader>
                    <TableHeader>Price</TableHeader>
                    <TableHeader>Total</TableHeader>
                </TableHead>
                <TableBody>
                    {slicedPlansList.map(slicedPlan=>{
                        const isCatPresent = slicedPlan?.productOfferingCharacteristics !==null && slicedPlan?.productOfferingCharacteristics && slicedPlan?.productOfferingCharacteristics.length ? slicedPlan?.productOfferingCharacteristics : []
                        const frequencySelected = frequency 
                        const price = (slicedPlan?.pricePlanPackageDetails && frequency === slicedPlan?.pricePlanPackageDetails[0]?.frequency) ? slicedPlan?.pricePlanPackageDetails[0]?.purchasePrice : slicedPlan?.pricePlanPackageDetails ? slicedPlan?.pricePlanPackageDetails[1]?.purchasePrice : ''
                        if(price){
                        return(
                        <TableRow>
                        <Cell>
                            {slicedPlan?.itemName}
                            <MarginSpacerM/>
                            <Body size="medium" color="grey">{slicedPlan?.shortDescription}</Body>
                        </Cell>
                        <Cell><InputStepper
                            defaultValue={selectedProducts[slicedPlan['itemID']] ?? 0}
                            maxValue={99}
                            minValue={1}
                            surface="light"
                            trailingText=""
                            width="auto"
                            onChange = {(value)=>{
                                const dtaToSend = storeData[slicedPlan?.itemID] ?? []
                                if(!!isCatPresent && isCatPresent.length && value === 1){
                                    setSelectedPlanCat(isCatPresent)
                                    setModal(true)
                                    setCurrentId(slicedPlan?.itemID)
                                    setDefaultData({...defaultData,[slicedPlan?.itemID]:{itemId:slicedPlan?.itemID || slicedPlan?.catID,frequency:frequencySelected,vewName:bussData?.bussResponse?.viewName,quantity:value, selectedItem:slicedPlan}})
                                  }else{
                                handleContinueClick(slicedPlan?.itemID , frequencySelected, bussData?.bussResponse?.viewName,dtaToSend, value, slicedPlan)
                                  }
                            }}/>
                            </Cell>
                        <Cell>{Number(price).toFixed(2)}</Cell>
                        <Cell>{Number((price) * (selectedProducts[slicedPlan['itemID']] ?? 0)).toFixed(2)}</Cell>
                    </TableRow>
                    )}})}
                    
                </TableBody>
            </Table>

        </div>
    )
} 