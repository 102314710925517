// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FhrTfPD4zLkaIA7QZ6W7 {
  margin: 8px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1rem;
  column-gap: 3rem;
}

.ZiVjo0XMtVZ6kBBbzdMQ {
  margin: 8px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1rem;
  column-gap: 3rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/Mobility/components/MobilityProductDetails/PriceInfo/PriceInfo.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".pricing-container {\n  margin: 8px 0;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  row-gap: 1rem;\n  column-gap: 3rem;\n}\n\n.pricing-container-onetalk {\n  margin: 8px 0;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  row-gap: 1rem;\n  column-gap: 3rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pricing-container": `FhrTfPD4zLkaIA7QZ6W7`,
	"pricing-container-onetalk": `ZiVjo0XMtVZ6kBBbzdMQ`
};
export default ___CSS_LOADER_EXPORT___;
