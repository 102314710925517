import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, TextLink } from "@vds3/buttons";
import { Grid, Row, Col } from "@vds3/grids";
import { Input } from "@vds3/inputs";
import { RadioButtonGroup } from "@vds3/radio-buttons";
import { Body } from "@vds3/typography";
import styled from "styled-components";
import {
    postOtpPhoneNumber,
    postOtpSendCode,
    postOtpValidatePasscode,
    resetOtp,
    setNotification,
    setOtpSendCode,
    setSuspendFlow,
    setSuspendScenario,
} from "../../../../pages/Checkout/ducks/CheckoutSlice";
import { MarginSpacerL, MarginSpacerM, MarginSpacerS, MarginSpacerXL } from "../../../../common/components/styleTag";
import { Notification } from "@vds3/notifications";
import { checkIfAemExists } from "utils/commonUtils";
import SpinnerComponent from "common/components/Spinner/Spinner";

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 25px;
  margin-bottom: 2px;
  column-gap: 10px;
  span {
    margin-left:15px !important;
    margin-right:15px !important;
  } 
  @media screen and (max-width:767px) {
    width: 95%;
    padding: 0px;
    margin-left: 0;
    flex-direction: column;
    row-gap: 15px;
    span {
      margin:0px !important;
    }
  }
.customerButton{
  padding: 0 !important;
}  
`;

const ColPad = styled(Col)`
padding-left: 1rem !important;
@media screen and (max-width:767px) {
    padding-left: 0rem !important;
}
`;

const OTPVerification = (props) => {
    const { t, i18n } = useTranslation(["app", "verification", "checkout","mock-response"]);
    const ddiaAemObj = t("verification.DDIA", {
        ns: "verification",
        returnObjects: true,
    });
    const otpContent = t("checkout.businessInfo-section.otp", {
        ns: "checkout",
        returnObjects: true,
    });

    const [mobileNumber, setMobileNumber] = useState("");
    const [profileNumbers, setProfileNumbers] = useState([]);
    const [passcode, setPasscode] = useState("");
    const [clickedNext, setClickedNext] = useState(false);
    const [codeSendCount, setCodeSendCount] = useState(0);
    const [flowComplete, setFlowComplete] = useState(false);
    const [matchFoundForInputNumber, setMatchFoundForInputNumber] = useState(true);
    const pageData = useSelector((state) => state.ProspectWidgets_CheckoutData);
    const businessInfoRequest = pageData.postBusinessInfoRequest;
    const flow = pageData?.unifiedCartParams?.flow;
    const isLoading = pageData?.otp?.otpLoading;
    const phoneNumberResp = pageData?.otp?.otpPhoneNumber;
    const sendCodeResponseData = pageData?.otp?.otpSendCode;
    const [sendCodeResponse, setSendCodeResponse] = useState(sendCodeResponseData);

    const validateResponse = pageData?.otp?.otpValidatePasscode;
    const creditEmailFlow = pageData?.creditEmailFlow;
    const isCreditCartFetched = pageData?.isRetrieveCreditCartSuccess;
    const dispatch = useDispatch();

    const phoneNumberSuccess = phoneNumberResp?.data?.serviceStatus?.success;
    const phoneRecommendationStop =
        phoneNumberResp?.data?.phoneRecommendation === "STOP" ? true : false;
    const MAX_RETRIES = 3;
    const [isOtherBtnEnabled, setOtherBtnEnabled] = useState(false);
    const [firstInputMobileNum, setFirstInputMobileNum] = useState("");
    const [errors, setErrors] = useState("");
    const [showError, setShowError] = useState(false);
    const [showResendLink, setShowResendLink] = useState(false);
    const [skipOtp, setSkipOtp] = useState(false);
    const [selectedPhoneOption, setSelectedPhoneOption] = useState("");


    useEffect(() => {
        dispatch(resetOtp());
    }, []);

    useEffect(() => {
        setMobileNumber(props.businessInfo?.contactInfo?.phoneNumber ?? "");
    }, [props.businessInfo]);

    useEffect(() => {
        if (pageData?.otp?.otpSendCode?.data?.serviceStatus?.success) {
            setShowResendLink(false);
            setSendCodeResponse(pageData?.otp?.otpSendCode);
            setCodeSendCount(codeSendCount + 1);
            setTimeout(() => {
                setShowResendLink(true);
            }, otpContent?.resendLinkTimer * 1000);
            dispatch(setOtpSendCode({}));
        }
    }, [pageData?.otp?.otpSendCode]);

    useEffect(()=>{
       // SiteAnalytics.dynamicComponentRendered({ order: 5, sectiontName: "Business Verification",subSection:"Identity verification",isEditMode:false });
    },[creditEmailFlow])

    useEffect(() => {
        if (clickedNext) {
            let notification = {};
            if (phoneNumberSuccess && !phoneRecommendationStop) {
                const resp = phoneNumberResp?.data;
                setMatchFoundForInputNumber(resp?.matchFoundForInputNumber);
                if (resp?.matchFoundForInputNumber) {
                    sendCode();
                } else if (resp?.matchFoundForInputNumber === false) {
                    setMobileNumber("");
                    setSelectedPhoneOption("");
                    setNumberList(resp?.phoneList);

                    if (!resp?.phoneList.length > 0) {
                        OTPUpdateForNotEligible();
                    }
                    if (resp?.phoneList && resp.phoneList.length > 0) {
                        if (isOtherBtnEnabled) {
                            OTPUpdateForNotEligible();
                        }
                    }
                }
            } else if (phoneRecommendationStop) {
                OTPUpdateForNotEligible();
            } else if (!phoneNumberSuccess && phoneNumberResp?.data?.serviceStatus?.statusCode === "ERROR_BUSINESS_INFO") {
                notification = {
                    show: true,
                    type: "error",
                    title: t(`verification.api_errors.ERROR_BUSINESS_INFO`, {
                        ns: "verification",
                    }),
                    subtitle: "",
                };
            } else {
                dispatch(
                    setSuspendScenario(phoneNumberResp?.data?.serviceStatus?.statusCode)
                );
                //SiteAnalytics.contactUsLanding();
                //props.onOTPValidateResponseFail()

               // navigate("/shop/contact-us", { state: { refUrl: window.location.pathname } });
            }
            dispatch(setNotification(notification));
        }
    }, [phoneNumberResp]);

    useEffect(() => {
        if (flowComplete) {
            if (validateResponse?.data?.serviceStatus?.success === true && phoneRecommendationStop) {
                props.onOTPPhoneRecommendationStop()
            } else if (validateResponse?.data?.serviceStatus?.success === true && validateResponse?.data?.svcCode === "M120") {
                props.onOTPValidateResponseSuccess()
            } else {
                if (otpContent?.forceStopOnFail) {
                    codeSendCount >= otpContent?.otpMaxTriesLimit && setSkipOtp(true);
                    setErrors(t("checkout.businessInfo-section.otp.errorMsgs.passcodeNotMatch", { ns: "checkout", }))
                    setShowError(true);
                } else {
                    props.onOTPValidateResponseFail();
                }
            }
        }
    }, [validateResponse]);

    const getAddress = () => {
        const addr = pageData?.postBusinessInfoResponse?.companyInfo?.address || businessInfoRequest?.companyInfo?.address;
        const { addressLine1, addressLine2, city, state, zipCode } = addr || {};
        return {
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            state: state,
            zipcode: zipCode,
        };
    };

    const setNumberList = (phoneList) => {
        const options = [];
        for (const num of phoneList) {
            if (num?.phoneSource === "I") {
                setFirstInputMobileNum(num.phone);
            }
            if (num?.phoneType === "MOBL" && num?.phoneSource === "O") {
                options.push(num);
            }
        }
        if (options?.length > 0) {
            options.push({
                phoneNumber: "Other",
                disabled: false,
                phone: "Other mobile number",
                name: "phonegroup"
            })
        }
        if (options?.length === 0) {
            props.onNextBtnClick();
        }
        setProfileNumbers(options);
    };

    const mobileNext = () => {
        // if (props.setShowProgressModal !== null && props.setShowProgressModal && typeof props.setShowProgressModal == 'function') {
        //     props.setShowProgressModal(true)
        // }
    const useMock = checkIfAemExists(t("phone-number-mock", { ns: "mock-response" }));

        if (!matchFoundForInputNumber && !selectedPhoneOption) {
            let errorTexts = t("checkout.businessInfo-section.otp.errorMsgs.makePhoneSelection", { ns: "checkout", });
            setErrors(errorTexts);
            setShowError(true);
        }
        if (!mobileNumber) {
            let errorTexts = t("checkout.businessInfo-section.otp.errorMsgs.noMobileError", { ns: "checkout", });
            setErrors(errorTexts);
            setShowError(true);
        }
        else if (mobileNumber === firstInputMobileNum) {
            let errorTexts = t("verification.errorMsgs.alternateNumber", { ns: "verification" })
            setErrors(errorTexts);
            setShowError(true);
        } else {
            setClickedNext(true);
            dispatch(setNotification({}));
            if (creditEmailFlow) {
                if ((props?.otpPhoneNumber === mobileNumber || props?.otpPhoneNumber === props?.businessInfo?.pocAddress?.phoneNumber) && isCreditCartFetched) {
                    props.onNextBtnClick();
                } else {
                    suspendOTPFlow('SUSPENDED_FLOW_CREDIT_HOLD');
                }
            } else {
                let phoneNum = mobileNumber;
                if (phoneNumberSuccess && !phoneRecommendationStop) {
                    const resp = phoneNumberResp?.data;
                    const phoneData = resp?.phoneList.filter((d) => d.phone === mobileNumber);
                    if (phoneData?.length > 0) {
                        phoneNum = phoneData[0]?.phoneNumber ?? mobileNumber;
                    }
                }
                const params = {
                    flow: flow,
                    firstName: businessInfoRequest?.signerInfo?.firstname,
                    lastName: businessInfoRequest?.signerInfo?.lastName,
                    phoneNumber: phoneNum,
                    dob: businessInfoRequest?.signerInfo?.dob,
                    address: getAddress(),
                    functionInd: pageData?.creditPath === "BLENDED" ? "" : "MB_PROS_NOSSN",
                    retryCount: 0,
                    useMock : useMock
                };
                dispatch(postOtpPhoneNumber(params));
            }
        }
    };
    const mobileSkip = () => {
        suspendOTPFlow('SUSPENDED_FLOW_OTP');
    };
    const suspendOTPFlow = (scenario = null) => {
        dispatch(setSuspendScenario(scenario ?? "SUSPENDED_FLOW_OTP"));
        dispatch(setSuspendFlow(true));
    };
    const sendCode = () => {
        if (codeSendCount < otpContent?.otpMaxTriesLimit) {
            setErrors("");
            setShowError(false);
            const resp = phoneNumberResp?.data;
            const number = resp?.phoneList?.filter(t => t.phoneSource === "I")[0].phone;
            setMobileNumber(number);
            dispatch(postOtpSendCode({ phoneNumber: number, flow: flow }));
        }
    };

    const codeNext = () => {
        if (skipOtp) {
            props.onOTPValidateResponseFail();
            return;
        }
        setShowError(false);
        setErrors("");
        let errorText = validatePassCode(passcode)
        if (errorText?.length > 0) {
            setShowError(true);
            return;
        }
        setFlowComplete(true);
        const params = {
            flow: flow,
            otpStatus: "VALID",
            passcode: passcode,
            phoneNumber: mobileNumber,
            trackingNumber: sendCodeResponse?.data?.trackingNumber,

        };
        dispatch(postOtpValidatePasscode(params));
    };

    const OTPUpdateForNotEligible = () => {
        setFlowComplete(true);
        const params = {
            flow: flow,
            otpStatus: "NOT ELIGIBLE",
            passcode: "",
            phoneNumber: "",
            trackingNumber: "",
        };
        dispatch(postOtpValidatePasscode(params));
    }

    const getMobileNumber = (e) => {
        setErrors("");
        if (e?.target?.value === "Other") {
            setMobileNumber("");
            setOtherBtnEnabled(true);
        } else {
            setOtherBtnEnabled(false);
            setMobileNumber(e?.target?.value)
        }
        setSelectedPhoneOption(e?.target?.value);
    }

    const validateMobileNumVal = (value) => {
        let errorTexts = "";
        let val = value;
        var checkChars = /[^0-9]/gi;
        if (checkChars?.test(val)) {
            val = val?.replace(checkChars, "");
        }
        const phoneNumber = val?.replace(/[^\d]/g, "");
        if (/^[0-9]{0,10}$/.test(phoneNumber)) {
            val = phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        }
        const pattern = /^\d{10}$/;
        if (!val?.trim() || !pattern.test(phoneNumber)) {
            errorTexts = t("checkout.businessInfo-section.otp.errorMsgs.otpPhoneError", { ns: "checkout" });
        }
        setErrors(errorTexts);
        setMobileNumber(val?.replaceAll("-", ""));
    }

    const validatePassCode = (value) => {
        let errorTexts = "";
        let val = value;
        var checkChars = /[^0-9]/gi;
        if (checkChars?.test(val)) {
            val = val?.replace(checkChars, "");
        }
        const passCode = val?.replace(/[^\d]/g, "");
        const pattern = /^\d{5}$/;
        if (!val?.trim()) {
            errorTexts = t("checkout.businessInfo-section.otp.errorMsgs.passCodeRequired", { ns: "checkout" });
        }
        else if (!pattern.test(passCode)) {
            errorTexts = t("checkout.businessInfo-section.otp.errorMsgs.passcodeLengthError", { ns: "checkout" });
        }
        setErrors(errorTexts);
        setPasscode(passCode);
        return errorTexts;
    }

    const formatMobileNumber = () => {
        if (mobileNumber) {
            let digits = mobileNumber?.length === 10 ? mobileNumber.includes("-") ? mobileNumber : mobileNumber?.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") : mobileNumber;
            let firstSix = digits.slice(0, 8);
            let hashdigit = digits.slice(8).replace(/\d/g, '#');
            let formated = firstSix + hashdigit;
            return formated;
        }
    }

    const OtherPhoneSelected = () => {
        return (
            <>
                {selectedPhoneOption === "Other" &&
                    <Fragment>
                        <MarginSpacerM />
                        <Input
                            placeholder=""
                            required={true}
                            name="altNumber"
                            width={"100%"}
                            maxLength={10}
                            value={
                                mobileNumber?.length === 10
                                    ? mobileNumber.includes("-")
                                        ? mobileNumber
                                        : mobileNumber?.replace(
                                            /(\d{3})(\d{3})(\d{4})/,
                                            "$1-$2-$3"
                                        )
                                    : mobileNumber
                            }
                            onChange={(e) => validateMobileNumVal(e.target.value?.replace(/[^\d]/g, ""))}
                            error={errors?.length > 0}
                            errorText={errors}
                            helperText={t("checkout.businessInfo-section.otp.helperTexts.otpPhoneNumber", { ns: "checkout", })}
                            helperTextPlacement="bottom"
                        />
                    </Fragment>
                }
            </>
        );
    };

    return (
        <div>
            {isLoading && <SpinnerComponent />}
            <Grid colSizes={{ mobile: 4, tablet: 12, desktop: 12 }} colGutter={false} rowGutter={false}>
                <StyledRow>
                    <Col colSizes={{ mobile: 4, tablet: 9, desktop: 9 }}>
                        <Body size="large">
                            {sendCodeResponse?.data?.serviceStatus?.success ? `${t("verification.modalOTPSent", { ns: "verification", })} ${formatMobileNumber()}.` :
                                !sendCodeResponse?.data?.serviceStatus?.success && !matchFoundForInputNumber ? t("verification.numberNotAssociatedText", { ns: "verification", }) :
                                    t("checkout.businessInfo-section.otp.defaultHeaderText", { ns: "checkout", })}
                        </Body>
                        {!sendCodeResponse?.data?.serviceStatus?.success && !matchFoundForInputNumber &&
                            <>
                                <MarginSpacerL />
                                <Body size="large">
                                    {t("verification.associatedSelectText", {
                                        ns: "verification",
                                    })}
                                </Body>
                            </>
                        }
                    </Col>
                </StyledRow>
                <MarginSpacerL />
                {showError && errors?.length > 0 ?
                    <StyledRow>
                        <Col colSizes={{ mobile: 4, tablet: 9, desktop: 9 }}>
                            <Notification
                                type="error"
                                title={errors}
                                subtitle=""
                                fullBleed={true}
                                inlineTreatment={false}
                                hideCloseButton={false}
                                width={"100%"}
                                onCloseButtonClick={setShowError}
                            ></Notification>
                            <MarginSpacerM />
                        </Col>
                    </StyledRow> : <></>
                }
                {!sendCodeResponse?.data?.serviceStatus?.success ? (
                    <>
                        {matchFoundForInputNumber ? (
                            <StyledRow>
                                <Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
                                    <Input
                                        label={!creditEmailFlow ?
                                            t("verification.enterTelephoneText", { ns: "verification" }) :
                                            t("creditHoldOtpModal.enterTelephoneText", { ns: "app" })}
                                        placeholder=""
                                        maxLength="10"
                                        value={
                                            mobileNumber?.length === 10
                                                ? mobileNumber.includes("-")
                                                    ? mobileNumber
                                                    : mobileNumber?.replace(
                                                        /(\d{3})(\d{3})(\d{4})/,
                                                        "$1-$2-$3"
                                                    )
                                                : mobileNumber
                                        }
                                        onChange={(e) => validateMobileNumVal(e.target.value?.replace(/[^\d]/g, ""))}
                                        error={errors?.length > 0}
                                        errorText={errors}
                                        helperText={t("checkout.businessInfo-section.otp.helperTexts.otpPhoneNumber", { ns: "checkout", })}
                                        helperTextPlacement="bottom"
                                    />
                                </Col>
                            </StyledRow>
                        ) : (
                            <StyledRow>
                                <Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
                                    <RadioButtonGroup
                                        onChange={(e) => getMobileNumber(e)}
                                        error={false}
                                        value={selectedPhoneOption}
                                        data={
                                            profileNumbers &&
                                            profileNumbers?.map((item, index) => {
                                                return {
                                                    children: item?.phoneNumber === "Other" ? OtherPhoneSelected() : <></>,
                                                    disabled: false,
                                                    label: item?.phone,
                                                    name: "phonegroup",
                                                    value: item?.phoneNumber,
                                                };
                                            })
                                        }
                                    />
                                </Col>
                            </StyledRow>
                        )}
                        <MarginSpacerL />
                        <StyledRow>
                            <Col colSizes={{ mobile: 4, tablet: 3, desktop: 3 }}>
                                <Button size="large" use="primary" onClick={mobileNext} data-track={`Business Verification:Identity verification:${!creditEmailFlow ?
                                        t("verification.continue", { ns: "verification" }) :
                                        t("creditHoldOtpModal.next", { ns: "app" })}`}>
                                    {!creditEmailFlow ?
                                        t("verification.continue", { ns: "verification" }) :
                                        t("creditHoldOtpModal.next", { ns: "app" })}
                                </Button>
                            </Col>
                            {!matchFoundForInputNumber &&
                                <ColPad colSizes={{ mobile: 4, tablet: 3, desktop: 3 }}>
                                    <Button size="large" use="secondary" onClick={() => props.onOTPValidateResponseFail()} data-track={`Business Verification:Identity verification:${t("verification.skip", { ns: "verification" })}`}>
                                        {t("verification.skip", { ns: "verification" })}
                                    </Button>
                                </ColPad>
                            }
                        </StyledRow>
                        <MarginSpacerS />
                    </>) : <></>
                }
                {sendCodeResponse?.data?.serviceStatus?.success ? (
                    <>
                        <StyledRow>
                            <Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}                            >
                                <Input
                                    label={t("verification.modalOTP", { ns: "verification" })}
                                    placeholder=""
                                    maxLength="5"
                                    type="password"
                                    value={passcode}
                                    width={"100%"}
                                    onChange={(e) => validatePassCode(e?.target?.value)}
                                    error={errors?.length > 0}
                                />
                            </Col>
                        </StyledRow>
                        {showResendLink ? (
                            <StyledRow>
                                <Col colSizes={{ mobile: 4, tablet: 9, desktop: 9 }} >
                                    <MarginSpacerXL />
                                    <Body size="medium">
                                        {t("verification.modalOTPDidntGet", { ns: "verification" })}
                                    </Body>
                                    <MarginSpacerS />
                                    <TextLink disabled={codeSendCount >= otpContent?.otpMaxTriesLimit} onClick={sendCode}>
                                        {t("verification.modalResendCode", { ns: "verification" })}
                                    </TextLink>
                                    {/* <MarginSpacerM />
                                <Body size="medium">
                                    {t("verification.passCodeReSentText", { ns: "verification" })}
                                </Body> */}
                                </Col>
                            </StyledRow>
                        ) : <></>}
                        <StyledRow>
                            <Col colSizes={{ mobile: 4, tablet: 3, desktop: 3 }}>
                                <MarginSpacerXL />
                                <Button onClick={codeNext}>
                                    {skipOtp ? t("verification.skip", { ns: "verification" }) : t("verification.continue", { ns: "verification" })}
                                </Button>
                            </Col>
                        </StyledRow>
                    </>) : <></>
                }
            </Grid>
        </div>
    );
};

const StyledRow = styled(Row)`
  > div {
    padding: 0rem;
  }
  padding: 0rem !important;
`;

export default OTPVerification;
