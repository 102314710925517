import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Body, Title } from "@vds3/typography";
import SearchInput from "common/components/SearchInput/SearchInput";
import CustomBreadcrumb from "common/components/CustomBreadcrumb/CustomBreadcrumb";
import { MarginSpacerM, MarginSpacerS } from "common/components/styleTag";

const GridwallHeader = ({
	currentPath,
	metaInformation,
	isOneTalk,
	is5gsmartphones,
	isSearchLayout,
	isTabLayout,
	totalResults,
	searchValue,
	setSearchValue,
	fetchNextItems,
	toggleFilters,
	setshowCartDetailsModal,
	showCartDetailsModal,
	isBogo,
	hideBreadcrumbs,
	isBuss=false,
	...props
}) => {
	const { t } = useTranslation(["gridwall"]);
	const isMobile = useMediaQuery({
		query: "(max-width: 545px)",
	});
	const isDesktop = useMediaQuery({
		query: "(min-width: 768px)",
	});
	const [showSearch, setShowSearch] = useState(true);
	useEffect(() => {
		if (
			window?.location?.pathname?.includes("basic-phones") ||
			window?.location?.pathname?.includes("mobile-hotspots")
		) {
			setShowSearch(false);
		}
	}, []);

	const getTitleText = () => {
		if(isBuss) return "Verizon Business Marketplace"
		const gridwallHeadings = metaInformation?.headings?.gridwall;
		if (isBogo) {
			return t("gridwall.bogo.heading");
		}
		if (!currentPath || !gridwallHeadings) return "";
		if (isOneTalk) {
			return "One Talk Smartphones";
		} else if (is5gsmartphones) {
			return "Business 5G Smartphones";
		} else if (
			gridwallHeadings &&
			gridwallHeadings[currentPath] &&
			gridwallHeadings[currentPath]?.title
		) {
			return gridwallHeadings[currentPath].title;
		} else if (
			gridwallHeadings[props.category] &&
			gridwallHeadings[props.category]?.title
		) {
			return gridwallHeadings[props.category].title;
		} else if (
			gridwallHeadings[currentPath.split("/")[0]] &&
			gridwallHeadings[currentPath.split("/")[0]]?.title
		) {
			return gridwallHeadings[currentPath.split("/")[0]].title;
		} else {
			return "Business Devices";
		}
	};

	return (
		<div className={`gridwall-header ${isBuss ? 'buss-gridwall-header':''}`} {...props}>
			<div className={isMobile ? "full-width" : "left-flex"}>
				{!hideBreadcrumbs && (
					<CustomBreadcrumb
						isProspect={!isOneTalk}
						is5gsmartphones={is5gsmartphones}
						isBogo={isBogo}
						isBuss={isBuss}
					/>
				)}
			</div>
			<div className="right-flex">
				{/* <ContactSales
					showPhoneNumber={!isOneTalk}
					isMobile={isMobile}
					showCartDetailsModal={showCartDetailsModal}
					setshowCartDetailsModal={setshowCartDetailsModal}
				/>
				{!isMobile && <ChatLink />} */}
			</div>
			{/* {!isMobile ? (
        <div className="right-flex">
          <ContactSales showPhoneNumber={!isOneTalk} />
          <ChatLink onClickFn={() => alert("clicked")} />
        </div>
      ) : 
        <div className="call-btn-container">
          <button className="call-btn" onClick={() => setshowCartDetailsModal(true)}>
          <span>call</span>
            <CloseIconSVG
               height={15}
               width={15}
               stroke={"#ffffff"}
            /> 
          </button>
          {showCartDetailsModal &&
            <CallDetailsModal
              onCloseBtnClick={setshowCartDetailsModal}
            />
          }
      </div>} */}

			<div className={`${isDesktop ? "left-flex" : "full-width"} top-pad`}>
				<Title bold size="large" primitive="h1">
					{getTitleText()}
				</Title>
				<MarginSpacerS/>
				{isBuss ? 
				<>
				<Body size="large">All your favorite services on one bill, sold by Verizon.</Body>
				<MarginSpacerM/>
				</>:<></>}
				{isSearchLayout && showSearch && (
					<SearchInput
						value={searchValue}
						onChangeFn={(e) => setSearchValue(e.target.value)}
						onClickFn={() => fetchNextItems(1)}
						placeholderText={t("gridwall.labels.search-device")}
					/>
				)}
			</div>
			{!isBogo && (
				//   <div className={`${isDesktop ? "right-flex" : "full-width"} top-pad`}>
				//   <SortButtonContainer
				//     isMobile={isMobile}
				//     isDesktop={isDesktop}
				//     isTabLayout={isTabLayout}
				//   >
				//     <Button
				//       size={isDesktop ? "large" : "small"}
				//       secondary={true}
				//       onClick={() => toggleFilters()}
				//     >
				//       {t("gridwall.labels.sortFilter") ?? "Sort & Filter"}
				//     </Button>
				//     <Micro>{`${totalResults ?? 0} ${t(
				//       "gridwall.labels.results"
				//     )}`}</Micro>
				//   </SortButtonContainer>
				// </div>
				<></>
			)}
			{isOneTalk && (
				<div>
					<Body size="large">
						Select from a full range of smartphones to connect and grow your business.{" "}
					</Body>
					<Body size="large">Chat with one of our small business experts.</Body>
					<MarginSpacerM />
					<Body size="large">
						You can save your cart once an item has been added.
					</Body>
				</div>
			)}
			{!!isBogo && (
				<div className="full-width">
					<MarginSpacerM />
					<Body size="large">{t("gridwall.bogo.subHeading")}</Body>
					<MarginSpacerM />
				</div>
			)}
		</div>
	);
};

export default GridwallHeader;
