import { createSlice, createAction } from "@reduxjs/toolkit";

const initialState = {
  creditDetailsResponse: {},
  creditHold: {
    code: "",
    loading: false,
    redirectedFromCreditHold: false,
    showError: false,
    isfromCreditHold: false,
    isFirstCallInvoked: false
  },
  creditResult: {
    loading: false,
  },
  creditReadStatus: "",
  isLoadingCheckCreditOptions: false,
  isLowerFinancedAmountOptionSelected: false,
  retries: 0,
  showProgressOverlay: false,
  updateProspectCreditDetailsResponse: {},
  validateBusinessCreditDetailsResponse: {},
  validateBlendedCreditDetailsResponse: {},
};

const CreditSlice = createSlice({
  name: "credit",
  initialState,
  reducers: {
    resetCreditSlice: (state, action) => {
      return initialState;
    },
    setIsLoadingCheckCreditOptions: (state, action) => {
      state["isLoadingCheckCreditOptions"] = action.payload;
    },
    setCreditReadStatus: (state, action) => {
      state["creditReadStatus"] = action.payload;
    },
    setIsLowerFinancedAmountOptionSelected: (state, action) => {
      state["isLowerFinancedAmountOptionSelected"] = action.payload;
    },
    setRetries: (state, action) => {
      state["retries"] = action.payload;
    },
    setBusinessCreditData: (state, action) => {
      state["validateBusinessCreditDetailsResponse"] = action.payload;
    },
    setShowProgressOverlay: (state, action) => {
      state["showProgressOverlay"] = action.payload;
    },
    setUpdateProspectCreditDetailsResponse: (state, action) => {
      state["updateProspectCreditDetailsResponse"] = action.payload;
    },
    setValidateBlendedCreditDetailsResponse: (state, action) => {
      state["validateBlendedCreditDetailsResponse"] = action.payload;
    },
    setCreditHoldCode: (state, action) => {
      state["creditHold"]["code"] = action.payload;
    },
    setCreditHoldLoading: (state, action) => {
      state["creditHold"]["loading"] = action.payload;
    },
    setCreditResultLoading: (state, action) => {
      state["creditResult"]["loading"] = action.payload;
    },
    setCreditHoldShowError: (state, action) => {
      state["creditHold"]["showError"] = action.payload;
    },
    setCreditHoldRedirectedFromCreditHold: (state, action) => {
      state["creditHold"]["redirectedFromCreditHold"] = action.payload;
    },
    setIsFromCreditHold: (state, action) => {
      state["creditHold"]["isfromCreditHold"] = action.payload;
    },
    setCreditDetailsResponse: (state, action) => {
      state["creditDetailsResponse"] = action.payload;
    },
    setCreditHoldIsFirstCallInvoked: (state, action) => {
      state["creditHold"]["isFirstCallInvoked"] = action.payload;
    },
    setCreditHoldIsVisited: (state, action) => {
      state["creditHoldVisited"] = action.payload;
    },
  },
});

export const reducer = CreditSlice.reducer;
export const retrieveBusinessCreditDetails = createAction(
  "checkout/retrieveBusinessCreditDetails"
);
export const validateBusinessCreditDetails = createAction(
  "checkout/validateBusinessCreditDetails"
);
export const validateBlendedCreditDetails = createAction(
  "checkout/validateBlendedCreditDetails"
);
export const updateProspectCreditDetails = createAction(
  "checkout/updateProspectCreditDetails"
);
export const validateCreditDetails = createAction(
  "checkout/validateCreditDetails"
);
export const validateCreditHoldCreditDetails = createAction(
  "checkout/validateCreditHoldCreditDetails"
);
export const {
  resetCreditSlice,
  setCreditReadStatus,
  setRetries,
  setBusinessCreditData,
  setValidateBlendedCreditDetailsResponse,
  setIsLoadingCheckCreditOptions,
  setCreditHoldCode,
  setCreditHoldLoading,
  setCreditResultLoading,
  setCreditHoldShowError,
  setCreditHoldRedirectedFromCreditHold,
  setIsFromCreditHold,
  setUpdateProspectCreditDetailsResponse,
  setCreditDetailsResponse,
  setShowProgressOverlay,
  setIsLowerFinancedAmountOptionSelected,
  setCreditHoldIsFirstCallInvoked,
  setCreditHoldIsVisited
} = CreditSlice.actions;
