import styled from "styled-components";
import { Line } from "@vds3/lines";
import { TextLink, Button } from "@vds3/buttons";

export const StickyContainer = styled.div`
	position: sticky;
	top: 0px;
	z-index: 999;
	background-color: white;
	width: 100%;
	margin: 0 auto;
`;

export const FlexContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 32px;
	row-gap: 10px;
	> * {
		height: 32px;
	}
	@media (max-width: 378px) {
		gap: 0;
		row-gap: 10px;
	}
	@media (max-width: 639px) {
		gap: ${(props) => !props.hasAddress && "unset"};
	}
`;

export const LeftContainer = styled.div`
	display: flex;
	width: fit-content;
	margin-right: auto;
`;

export const TotalsContainer = styled(LeftContainer)`
	@media (max-width: 639px) {
		margin-right: ${(props) => !props.hasAddress && "unset"};
	}
`;

export const TotalsDiv = styled.div`
	display: flex;
	flex-direction: row;
`;

export const TopLine = styled(Line)`
	margin-bottom: 14px;
`;

export const BottomLine = styled(Line)`
	margin-top: 16px;
`;

export const ShoppingBagContainer = styled.div`
	cursor: pointer;
	display: flex;
	svg {
		margin: auto;
	}
`;

export const AddressContainer = styled.div`
	display: flex;
	* {
		color: #000;
		border-color: #000;
	}
`;

export const BackIconWrapper = styled.div`
	cursor: pointer;
	margin: auto;
	padding-right: 1.75rem;
`;

export const RowBreak = styled.hr`
	width: 100%;
	border: 0;
	height: 0;
	margin: 0;
`;
export const TextLinkWrapper = styled(TextLink)`
	height: 20px !important;
	margin-top: 12px;
`;
export const DivBodyWarpper = styled.div`
	height: 20px !important;
	margin-top: 12px;
`;
export const ButtonWrapperMobile = styled(Button)`
	display: none;
	width: 100%;
	background-color: white !important;
	z-index: 100;
	position: fixed;
	bottom: 0px;
	left: 0px;
	right: 0px;
	border: none;
	border-radius: 0px;
	> span {
		width: 100%;
	}
	[class^="StyledChildWrapper"] {
		padding: 1rem !important;
	}
	@media screen and (max-width: 767px) {
		display: block !important;
	}
`;
