import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "@vds3/buttons";
import { Body } from "@vds3/typography";
import { ListGroup } from "@vds3/lists";
import {
  storeTermsConditons,
  resumeEcpdDocs,
  setNotification,
  getOneTalkEnrollment,
  getTNCDetails,
  setShowProgressOverlay,
  postSharedCartSaveUrl,
  setShowMaaSuccessMessage,
  getSessionCart,
} from "../../../Checkout/ducks/CheckoutSlice";
import VerticalProgressContext from "common/components/VerticalProgress/verticalProgressContext";
import { checkIfAemExists } from "utils/commonUtils";
import ProgressOverlay from "pages/Checkout/ProgressOverlay/ProgressOverlay";
import SpinnerComponent from "common/components/Spinner/Spinner";
import TermsUtils from './BusinessContractstermsUtils';
import "./BusinessContractsTAC.css";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import * as SiteAnalytics from "../../../../sitecatalyst";
import { postCompleteOrder } from "pages/Checkout/ducks/PaymentSlice";

function BusinessContractsTAC({...props}) {
  const {
    isComplete,
    onComplete,
    setModifier,
    onInProgress,
    isEditMode: { isEditMode, setIsEditMode },
  } = React.useContext(VerticalProgressContext);
  const { t } = useTranslation(["terms", "automation","mock-response"]);
  const dispatch = useDispatch();
  const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
  const pageData = useSelector((state) => state.ProspectWidgets_CheckoutData);
  const paymentPageData = useSelector((state) => state.ProspectWidgets_PaymentData);
  const orderDetails = useSelector((state) => state.ProspectWidgets_ReviewData);
  const sectionsConfig = useSelector((state) => state.LowerFunnelSlice?.saveTempSectionsConfig);
  const sessionCartData =
    globalData?.sessionCartData?.apiResponse?.prospectFlowData;
  const [tncItems, setTncItems] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [hideButton, setHideButton] = useState(false);
  const [showFinalContinueBtn, setShowFinalContinueBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(
    !!pageData?.isCheckoutPageApiCallInProgress?.generateInstallment
  );
  const [isHavingUncappedPlan, setIsHavingUncappedPlan] = useState(false);
  const aemContent = t("terms", { ns: "terms", returnObjects: true });
  const isResumeEcpdLoading =
    pageData?.isShoppingCartApiCallInProgress?.resumeEcpdDocs;
  const generateInstallamentForProspect =
    pageData?.TNCDetailMethodInfo?.serviceStatus?.statusCode;
  const termId =
    pageData?.TNCDetailMethodInfo?.terms?.termid;

  const flow = pageData?.unifiedCartParams?.flow ?? "";
  const isStoreTermsLoading = !!pageData?.isCheckoutPageApiCallInProgress?.storeTermsLoading;
  const isUnifiedCart =  sessionCartData?.cart?.unifiedCart ? true : false;
  const isOrderQuoteFlow = globalData?.isOrderQuoteFlow ? true : false;
  const isMixedCart=  sessionCartData?.cart?.mixedCart ? true:false;
  const isOneTalkOnly = !isUnifiedCart && pageData?.flowFlags?.isOneTalk;
  const isOnlyBussProduct = sessionCartData?.standAloneBuSS;
  const useMock = checkIfAemExists(t("store-terms", { ns: "mock-response" }));
  const redirectedToTnc = sectionsConfig?.BusinessContracts?.subsections["retail-account-agreement"]?.isComplete;
  const isAbandonedCart =
    (sessionCartData?.cart?.abandonedCart &&
      sessionCartData?.redirectPageTo === "checkout" && redirectedToTnc) ||
    false;
  const isAutomationComplete =
    sessionCartData?.automationStepsStatus?.automationStatus?.tncCompleted
      ?.status === "SUCCESS" || useMock;
  const isMobile = useMediaQuery({
    query: "(max-width: 725px)",
  });
  const isMaaSignedInd = sessionCartData?.ecpdLookupCartData?.ecpdLookupCartData?.[0]?.isMaaSignedInd || "";
  const agreementPDF= pageData?.TNCDetailMethodInfo?.agreementPDF;
  const rentalAgreementTnc = pageData?.TNCDetailMethodInfo?.rentalAgreementTnc || false;
  const isVBC = sessionCartData?.cart?.packages !== null && sessionCartData?.cart?.packages !== undefined && Object.values(sessionCartData?.cart?.packages).filter( packageData => packageData?.packageType == "RENTAL_REGULAR").length > 0;
  const [pdfUrl,setPDFUrl] =useState(null)

  useEffect(() => {
    window.addEventListener("ReviewTAC_continue", onContinueClick);
    dispatch(getSessionCart());
    if ((!isAbandonedCart && !globalData?.reviewAgreement) && !isAutomationComplete && (pageData?.resumeEcpdDocsInfo === "" || pageData?.resumeEcpdDocsInfo === null || pageData?.resumeEcpdDocsInfo === undefined) && !isOnlyBussProduct) {
    //   dispatchSetShowReviewProgressOverlay(true);
    //   dispatch(resumeEcpdDocs({ flow, isOneTalkOnly }));
    // } else if (pageData?.TNCDetailMethodInfo === null || pageData?.TNCDetailMethodInfo === undefined) {
    //   
    dispatch(getTNCDetails());
      if (isAbandonedCart) {
        if (flow === "OneTalk" || isOneTalkOnly) {
          dispatch(getOneTalkEnrollment());
        }
      }
    }
    // SiteAnalytics.dynamicComponentRendered({ order: 8, sectiontName: "Business contracts",subSection:"Terms and conditions",isEditMode:false });
    // SiteAnalytics.creditCheckResultEvent();
    return () => {
      window.removeEventListener("ReviewTAC_continue", onContinueClick);
    };
  }, []);
  useEffect(() => {
    const element = document.getElementById("terms-n-conditions");
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
},[]);

function base64ToBlob( base64, type = "application/octet-stream" ) {
  const binStr = atob( base64 );
  const len = binStr.length;
  const arr = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    arr[ i ] = binStr.charCodeAt( i );
  }
  return new Blob( [ arr ], { type: type } );
}

useEffect(() =>{
  if(agreementPDF && rentalAgreementTnc){
    var base64 = agreementPDF
    const blob = base64ToBlob( base64, 'application/pdf' );
    const url = URL.createObjectURL( blob );
    setPDFUrl(url);
    //setShowModal(true);
    // const pdfWindow = window.open("", "", "popup");
    // console.log("pdfWindow:",pdfWindow)
    // pdfWindow?.document?.write("<iframe width='100%' height='100%' src='" + url + "'></iframe>");
  }
},[agreementPDF])

  useEffect(() => {
    if (generateInstallamentForProspect == "SUSPENDED_FLOW_TnC") {
      let notification = {
        show: true,
        type: "error",
        title: t(`terms.SUSPENDED_FLOW_TnC.error`, {
          ns: "terms",
          subtitle: "",
        }),
      };
      dispatch(setNotification(notification));
    }
  }, [true]);

  useEffect(() => {
    if (generateInstallamentForProspect == "200" && isMaaSignedInd !== "Y") {
      let notification = {
        show: true,
        type: "info",
        title: t(`terms.docusignNotification`, {
          ns: "terms"
        }),
      };
      dispatch(setNotification(notification));
    }
  }, [true]);

  useEffect(() => {
    setIsLoading(
      pageData?.isCheckoutPageApiCallInProgress?.generateInstallment
    );
  }, [pageData?.isCheckoutPageApiCallInProgress?.generateInstallment]);

  useEffect(() => {
    const response = pageData?.TNCDetailMethodInfo;
    let items;
    if (response) {
      let packages = sessionCartData?.cart?.packages;
      for (let pkg in packages) {
        let pkgObj = packages[pkg];
        if (pkgObj?.packageType === "FOURG") {
          setIsHavingUncappedPlan(
            pkgObj?.plan?.planType === "UNCAPPED" ? true : false
          );
          break;
        }
      }
      items = TermsUtils?.getTermsItems(
      aemContent,
      flow,
      pageData?.flowFlags,
      isUnifiedCart,
      isMixedCart,
      response,
      isOnlyBussProduct
      );
      if(!items?.length) {
        onComplete();
      }
      if (isAutomationComplete) {
        items = items.map((i) => ({ ...i, signed: true }));
        onComplete();
      }
      setTncItems(items);
      // if (items?.length) {
      //   if (isAutomationComplete) {
      //     props?.eventHandlers?.emit("enableContinueBtn");
      //   } else {
      //     props?.eventHandlers?.emit("disableContinueBtn");
      //   }
      // } else {
      //   // no TnC to accept, go to next page
      //   props?.eventHandlers?.emit("enableContinueBtn");
      //   if (generateInstallamentForProspect !== "SUSPENDED_FLOW_TnC" && !globalData?.reviewAgreement) {
      //     onContinueClick();
      //   }
      // }
    }
  }, [pageData?.TNCDetailMethodInfo]);

  // useEffect(() => {
  //   if(tncItems?.some(doc => doc?.signed)) {
  //     onInProgress(true);
  //   } else {
  //     onInProgress(false)
  //   }
  // }, [activeIndex])

  const tncModal = () => {
    return (
      showModal &&
        TermsUtils?.businessContractsTermModal(
          activeIndex,
          false,
          onContinueClick,
          setShowModal,
          showModal,
          t,
          tncItems,
          isAbandonedCart,
          isOnlyBussProduct,
          pdfUrl,
          rentalAgreementTnc,
          isVBC
        )
    )
  }

  // const checkNpaNxxSkip = () => {
  //   let skipNpaNxx = true;
  //   const packages = sessionCartData?.cart?.packages;
  //   for (const pkg in packages) {
  //     const pkgObj = packages[pkg];
  //     if (pkgObj?.packageType !== "FIVEG" && pkgObj?.packageType !== "FOURG") {
  //       skipNpaNxx = false;
  //       break;
  //     }
  //   }
  //   dispatch(setSkipNpaNxx(skipNpaNxx));
  //   return skipNpaNxx;
  // };

  // const checkInstallationSkip = () => {
  //   let skipInstallation = true;
  //   const packages = sessionCartData?.cart?.packages;
  //   for (const pkg in packages) {
  //     const pkgObj = packages[pkg];
  //     if (
  //       pkgObj?.packageType === "FIVEG" ||
  //       pkgObj?.packageType === "ONETALK"
  //     ) {
  //       skipInstallation = false;
  //       break;
  //     }
  //   }
  //   dispatch(setSkipInstallation(skipInstallation));
  // };

  // const goToNextPage = () => {
  //   const skipNpaNxx = checkNpaNxxSkip();
  //   checkInstallationSkip();
  //   if (
  //     ((window.sessionStorage.getItem("isCheck") == "auth-yes" ||
  //     (window.sessionStorage.getItem("isCheck") == "auth-no" &&
  //       window.sessionStorage.getItem("isPermission") == "permission-yes"))
  //       && sessionCartData?.cart?.mwwCart &&
  //       !sessionCartData?.cart?.unifiedCart) ||
  //     (!sessionCartData?.showShippingPage && skipNpaNxx)
  //   ) {
  //     window.sessionStorage.setItem("skipShippingPage", true);
  //     navigate("/shop/review");
  //   } else {
  //     window.sessionStorage.setItem("skipShippingPage", false);
  //     props.eventHandlers?.emit("updateReviewTAC");
  //   }
  // };

  const onContinueClick = (clickType='Approve') => {
    // if(globalData?.reviewAgreement) {
    //   const selectedIndex = activeIndex;
    //   if (tncItems?.length) {
    //     tncItems[selectedIndex].signed = true;
    //   }
    //   if (tncItems?.length && selectedIndex < tncItems.length - 1) {
    //     setActiveIndex(activeIndex + 1);
    //   } else {
    //    dispatch(storeTermsConditons({
    //       tncItems, callback: () => {
    //       dispatch(postSharedCartSaveUrl({tacCompleted: true}));
    //       }
    //     }));  
    //   setHideButton(true);
    //   setShowModal(false);
    //   }
    //   return;
    // }
    // if (isAutomationComplete && tncItems.every(doc => doc?.signed)) {
    //   // goToNextPage();
    //   setHideButton(true)
    //   setShowModal(false)
    //   setShowFinalContinueBtn(true)
    // } else if (!tncItems[activeIndex]?.signed) {
    //   const selectedIndex = activeIndex;
    //   window.sessionStorage.setItem("skipShippingPage", false);
    //   if (tncItems?.length) {
    //     tncItems[selectedIndex].signed = true;
    //   }
    //   if(tncItems.every(doc => doc?.signed)) {
    //     onContinueClick()
    //   }
    //   else if (tncItems?.length && selectedIndex < tncItems.length - 1) {
    //     setActiveIndex(activeIndex + 1);
    //   } else {
    //     setShowModal(false);
    //     // setActiveIndex(0);
    //     dispatch(storeTermsConditons({
    //       tncItems, callback: () => {
    //         // goToNextPage();
    //       }
    //     }));
    //   }
    // } else {
    //   const selectedIndex = activeIndex;
    //   setActiveIndex(activeIndex + 1);
    //   if (selectedIndex === tncItems.length - 1) {
    //     setShowModal(false);
    //     setActiveIndex(0);
    //   }
    // }
    if (isAutomationComplete && clickType !== "Close") {
      setHideButton(true)
      setShowModal(false)
      onComplete();
    } else if (!tncItems[activeIndex]?.signed) {
      const selectedIndex = activeIndex;
      if (tncItems?.length && tncItems[selectedIndex]) {
        tncItems[selectedIndex].signed = true;
        onInProgress(true);
      }
      if (tncItems?.length && selectedIndex < tncItems.length - 1) {
        setActiveIndex(activeIndex + 1);
      } else if(tncItems!==null&&tncItems&&tncItems.length){
        setShowModal(false);
        setActiveIndex(0);
        // SMBPROS-2080,SMBPROS-2094,SMBPROS-1985
        dispatch(storeTermsConditons({
          tncItems,useMock:useMock
        }));
      }
    } else {
    //   const selectedIndex = activeIndex;
    //   setActiveIndex(activeIndex + 1);
    // if (selectedIndex === tncItems.length - 1) {
    //   setShowModal(false);
    //   setActiveIndex(0);
    // }
    setShowModal(false);
    setActiveIndex(tncItems.findIndex(doc => !doc?.signed));
    }
  };

  const openModal = () => {
    if (!tncItems?.length || activeIndex >= tncItems.length) {
      setActiveIndex(0);
    }
    setShowModal(true);
    dispatch(setShowMaaSuccessMessage(false))
  };

  const setIndex = (element) => {
    setShowModal(false);
    const selectedTnc = element;
    const selectedIndex = tncItems.findIndex(
      (item) => item.id === selectedTnc.id
    );
    // const signedTnc = {...tncItems[selectedIndex], signed : true}
    // setTncItems({...tncItems, signedTnc })
    if (tncItems[selectedIndex]) tncItems[selectedIndex].signed = true;
    setActiveIndex(selectedIndex);
    setShowModal(true);
    if(isAutomationComplete) {
      openModal();
    }
  };
  const dispatchSetShowReviewProgressOverlay = (value) => {
    dispatch(setShowProgressOverlay(value));
  };

  const navigateToConfirmation = () => {
   
     dispatch(postCompleteOrder());
  
     // dispatch(setLoadCheckoutWidget(false));
     // dispatch(setLoadConfirmationWidget(true));
   };

  const getDefaultContent = () => {
  return (
    <div className="reviewTAC">
      {isLoading || isResumeEcpdLoading}
      {isMobile && pageData?.showProgressOverlay ?
        <></> :
        <>
          {/* {tncItems && tncItems.some(doc => doc?.signed) && !tncItems.every(doc => doc?.signed) && <div style={{paddingBottom:"5px"}}><Body size="medium" color='#0000FF' bold>In progress</Body></div>} */}
          {getCompletedContent()}
          {(!isOnlyBussProduct && !hideButton ) ? <div className="continue-btn" style={{ marginTop: "2rem" }}>
            <Button
              width={isMobile ? "100%" : "226px"}
              onClick={
                tncItems?.length && !isAutomationComplete
                  ? openModal
                  :  onContinueClick
              }
             // onClick={()=>navigateToConfirmation()}
              size="large"
              data-track={`Business contracts:Retail Account Agreement:${tncItems?.length && !isAutomationComplete
                ? "Review and approve" : "Continue" }`}
            >
              {tncItems?.length && !isAutomationComplete
                ? "Review and approve" : "Continue" }
            </Button>
          </div> 
          : isOnlyBussProduct && !isAutomationComplete && !hideButton ? <div className="continue-btn" style={{ marginTop: "2rem" }}>
           <Button
            width={isMobile ? "100%" : "226px"}
            onClick={
              tncItems?.length && !isAutomationComplete
                ? openModal
                : ""
            }
            size="large"
            data-track={`Business contracts:Retail Account Agreement:${t("terms.review", { ns: "terms" })}`}
           >
            {t("terms.review", { ns: "terms" })}
           </Button>
          </div> : <></>
          }
          {/* {((!isAutomationComplete) && showFinalContinueBtn ) && <div className="continue-btn" style={{ marginTop: "2rem" }}>
            <Button
              onClick={onComplete}
              size="large"
            >
              Continue
            </Button>
          </div>} */}
        </>
      }
      {/* {tncModal()} */}
      {(isLoading || isResumeEcpdLoading || isStoreTermsLoading) ? (
        (isAutomationComplete || isStoreTermsLoading || isOrderQuoteFlow) ? (
          <SpinnerComponent />
        ) : (
          <ProgressOverlayWrapper >
            <ProgressOverlay
              modalDesc={t("automation.maaEcpd.desc", { ns: "automation" })}
              modalFooter={t("automation.maaEcpd.footer", { ns: "automation" })}
              modalHeading={t("automation.maaEcpd.heading", { ns: "automation" })}
              onOpenedChange={dispatchSetShowReviewProgressOverlay}
              progress={20}
              showProgressOverlay={pageData?.showProgressOverlay}
            />
          </ProgressOverlayWrapper>
        )
      ) : (
        <></>
      )}
    </div>
  )}

  const getCompletedContent = () => {
    return (
      <>
        <div className="terms">
              <ListGroup 
              topLine={false}
              bottomLine={true}
              surface="light"
              >
                {TermsUtils?.renderTermsList(
                  tncItems,
                  isHavingUncappedPlan,
                  setIndex,
                  isAutomationComplete,
                )}
              </ListGroup>
        </div>
        {tncModal()}
      </>
    );
  };

  if (isComplete) {
    return getCompletedContent();
  } else {
    return getDefaultContent();
  }
}
export const ProgressOverlayWrapper = styled.div`

`;

export default BusinessContractsTAC;
