import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Grid, Row, Col } from "@vds3/grids";
import { Pagination } from "@vds3/pagination";
import { Body, Title } from "@vds3/typography";
import { MarginSpacerL, MarginSpacerM } from "common/components/styleTag";
import { Line } from "@vds3/lines";
import { TextLink, ButtonGroup } from "@vds3/buttons";
import { DropdownSelect } from "@vds3/selects";
import { getFullRatingWidth, getEllipsisText } from "../pdpUtils";
import ProgressBar from "../ProgressBar/ProgressBar";
import CircleCheck from "../CircleCheck";
import "./Reviews.css";
import { useMediaQuery } from "react-responsive";
import Ratings from "../Ratings";
import { useDispatch } from "react-redux";
import { getFeedBack, getReviews } from "pages/Mobility/ducks/MobilitySlice";

const RatingStarContainer = styled.div`
	display: flex;
	flex-direction: column;
`;
const SortByText = styled(Body)`
	font-family: "Verizon-NHG-eTX";
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0px;
	text-align: left;
	margin: 2px 0px !important;
`;

const CustomLine = styled(Line)`
	margin: 10px 0px;
	height: 0.0625rem;
	width: 100%;
	background-color: #d8dada;
`;
const CustomPagination = styled(Pagination)`
	margin-left: auto;
`;

const sortReviews = [
	{
		name: "Newest",
		value: "rating:desc",
	},
	{
		name: "Highest to lowest rating",
		value: "rating:desc",
	},
	{
		name: "Lowest to highest rating",
		value: "rating:asc",
	},
	{
		name: "Most helpful",
		value: "Helpfulness:desc",
	},
];

const Reviews = (props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation(["pdp"]);
	const [reviewProduct, setReviewProduct] = useState({});
	const [loading, setLoading] = useState(true);
	const [results, setResults] = useState([]);
	const [overAllRating, setOverAllRating] = useState(null);
	const [secondaryRatingsList, setSecondaryRatingsList] = useState(null);
	const [totalItems, setTotalItems] = useState(null);
	const [reviewTotals, setReviewTotals] = useState(null);
	const [seeMoreTextToggle, setSeeMoreTextToggle] = useState(true);
	const [pageSelected, setPageSelected] = useState(1);
	const [reviewResults, setReviewResults] = useState([]);
	const [sortBy, setSortBy] = useState("rating:desc");
	const [deviceID, setDeviceID] = useState("");
	const [selectedStar, handleSelectedStar] = useState(null);
	const [feedBackCount, selectFeedbackCount] = useState({});
	const isMobile = useMediaQuery({
		query: "(max-width: 545px)",
	});

	const pageSize = isNaN(Number(t("reviewLimit", { ns: "pdp" })))
		? 10
		: Number(t("reviewLimit", { ns: "pdp" }));

	const handlePageSelection = (pageNumber) => {
		setPageSelected(pageNumber);
		dispatch(
			getReviews({
				productId: deviceID,
				limit: pageSize,
				offset: (pageNumber - 1) * pageSize,
				sort:
					selectedStar !== null && selectedStar
						? sortBy + "&Filter=Rating:" + selectedStar
						: sortBy,
			})
		);
	};

	const handleRatingFilter = (rating) => {
		setPageSelected(1);
		handleSelectedStar(rating);
		dispatch(
			getReviews({
				productId: deviceID,
				limit: t("reviewLimit", { ns: "pdp" }),
				offset: 0,
				sort: "rating:desc&Filter=Rating:" + rating,
			})
		);
	};

	useEffect(() => {
		setReviewOverview();
		setReviewResults(props?.reviews?.Results);
		setResults(props?.reviews?.Results?.slice(0, pageSize));
	}, [props?.reviews, pageSize]);

	useEffect(() => {
		setOtherItems();
	}, [reviewProduct]);

	const setReviewOverview = () => {
		if (
			props?.reviews?.Includes &&
			Object.keys(props?.reviews?.Includes).length > 0
		) {
			setTotalItems(props?.reviews?.TotalResults);
			for (const key of Object.keys(props?.reviews.Includes.Products)) {
				setDeviceID(key);
				setReviewProduct(props?.reviews.Includes.Products[key]);
			}
		}
	};

	const setOtherItems = () => {
		if (Object.keys(reviewProduct).length > 0) {
			setSecondaryRatingsList(
				Object.values(reviewProduct?.ReviewStatistics?.SecondaryRatingsAverages)
			);
			// setTotalItems(reviewProduct?.ReviewStatistics?.TotalReviewCount);
			setReviewTotals(
				sortRatingKeys(
					reviewProduct?.ReviewStatistics?.RatingDistribution,
					"RatingValue"
				)
			);
			setOverAllRating(
				Number(reviewProduct?.ReviewStatistics?.AverageOverallRating).toFixed(1)
			);
			setLoading(false);
		}
	};

	const generateDistribution = () => {
		const html = [];
		for (const key of reviewTotals) {
			html.push(
				<>
					{generateStars(
						key?.RatingValue,
						6,
						<span>{key?.Count}</span>,
						"ratingstars"
					)}
				</>
			);
		}
		return html;
	};

	const generateStars = (rating, width, template, ratingLabel) => {
		const stars = [];
		if (ratingLabel !== "ratingstars") {
			stars.push(
				<RatingStarContainer style={{ width: "auto" }}>
					<Ratings points={rating} width={18} height={18} />
					{template}
				</RatingStarContainer>
			);
		} else {
			stars.push(
				<div className="ratingStar" style={{ width: getFullRatingWidth(width) }}>
					<TextLink onClick={() => handleRatingFilter(rating)}>
						{rating} stars ({template})
					</TextLink>
				</div>
			);
		}

		return stars;
	};
	const feedBack = ({ id, vote }) => {
		const data = `${id}&FeedbackType=helpfulness&Vote=${vote}`;
		dispatch(getFeedBack({ vote: data }));
	};
	const generateReviewLists = () => {
		const html = [];
		for (const item of results) {
			let postiveCount = item.TotalPositiveFeedbackCount,
				negativeCount = item.TotalNegativeFeedbackCount;
			html.push(
				<Row id={item.Id}>
					<MarginSpacerL></MarginSpacerL>
					{/* <Line type="xLight" /> */}
					<MarginSpacerL></MarginSpacerL>
					<Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
						<Body>
							<div className="review-comment">
								<div className="review-title">
									<span>{item?.Title}</span>
								</div>
								<div className="review-rating">
									{generateStars(
										item.Rating,
										5,
										<span className="review-user-info">
											<span className="review-user">{item.UserNickname}</span>
											<span className="review-user">
												{t("device-pdp.reviewSubmissionTime", {
													submissionTime: getDaysSince(item?.SubmissionTime),
													ns: "pdp",
												})}
											</span>
										</span>
									)}
								</div>
								{item?.IsRecommended && (
									<div className="review-recommend">
										<CircleCheck width="1rem" height="1rem"></CircleCheck>
										<span> {t("device-pdp.recommendproduct", { ns: "pdp" })}</span>
									</div>
								)}
								{item?.IsSyndicated && item?.SyndicationSource && (
									<div className="review-origin">
										<img
											src={item?.SyndicationSource?.LogoImageUrl}
											aria-label="Device Name"
										/>
										<span>
											{" "}
											{t("device-pdp.originallyposted", { ns: "pdp" })}{" "}
											{item?.SyndicationSource?.Name}
										</span>
									</div>
								)}
								{seeMoreTextToggle && (
									<div className="review-text">
										<span>{getEllipsisText(item.ReviewText, 400)}</span>
										{item?.ReviewText?.length > 400 && (
											<TextLink
												id={item.id + "more"}
												onClick={() => setSeeMoreTextToggle(false)}
											>
												{t("device-pdp.readMore", { ns: "pdp" })}
											</TextLink>
										)}
									</div>
								)}
								{!seeMoreTextToggle && (
									<div className="review-text">
										<span>{item.ReviewText}</span>
										{item?.ReviewText?.length > 400 && (
											<TextLink
												id={item.id + "less"}
												onClick={() => setSeeMoreTextToggle(true)}
											>
												{t("device-pdp.readLess", { ns: "pdp" })}
											</TextLink>
										)}
									</div>
								)}
							</div>
						</Body>
					</Col>
					{item.ReviewText !== null && item.ReviewText ? (
						<Col colSizes={{ mobile: 4, tablet: 5, desktop: 5 }}>
							<Body size="large">Was this review helpful?</Body>
							<MarginSpacerM />
							<ButtonGroup
								childWidth={"100%"}
								viewport={"desktop"}
								data={[
									{
										children: `Yes (${
											feedBackCount?.[item.Id]?.postiveCount
												? feedBackCount[item.Id].postiveCount
												: postiveCount
										})`,
										size: "small",
										use: "secondary",
										width: "auto",
										onClick: (e) => {
											postiveCount += 1;
											sessionStorage.setItem("count", postiveCount);
											sessionStorage.setItem("id", item.Id);
											selectFeedbackCount((val) => {
												return { ...val, [item.Id]: { postiveCount } };
											});
											feedBack({ id: item.Id, vote: "Positive" });
										},
									},
									{
										children: `No (${
											feedBackCount?.[item.Id]?.negativeCount
												? feedBackCount[item.Id].negativeCount
												: negativeCount
										})`,
										size: "small",
										use: "secondary",
										width: "auto",
										onClick: (e) => {
											negativeCount += 1;
											selectFeedbackCount((val) => {
												return { ...val, [item.Id]: { negativeCount } };
											});
											feedBack({ id: item.Id, vote: "Negative" });
										},
									},
								]}
							/>
						</Col>
					) : (
						<></>
					)}
					<CustomLine type="xLight" />
				</Row>
			);
		}
		return html;
	};

	useEffect(() => {
		for (const item of results) {
			if (item.Id === sessionStorage.getItem("id")) {
				let postiveCount = sessionStorage.getItem("count");
				selectFeedbackCount((val) => {
					return { ...val, [item.Id]: { postiveCount } };
				});
			}
		}
	}, [results]);

	const sortRatingKeys = (array, key) => {
		const newArr = [].concat(array);
		return newArr.sort((a, b) => (a[key] > b[key] ? -1 : 1));
	};

	const getSortedReviews = (array, key) => {
		const newArr = [].concat(array);
		return key === "high"
			? newArr.sort((a, b) => (a[key] > b[key] ? -1 : 1))
			: newArr.sort((a, b) => (a[key] > b[key] ? 1 : -1));
	};

	const getDaysSince = (date) => {
		const now = new Date();
		const review = new Date(date);
		const diff = Math.abs(now.getTime() - review.getTime());
		const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
		return diffDays;
	};

	return (
		<div className="reviews-component" ref={props.scrollRef}>
			{!loading && (
				<Grid rowGutter="0.5rem" className="no-margin" colGutter={false}>
					<Row>
						<Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
							<Grid rowGutter="0.5rem" colGutter={false}>
								<Row className="reviews-rating-container">
									<Col colSizes={{ mobile: 3, tablet: 3, desktop: 3 }}>
										<div className="reviews-rating">
											<div className="ratings">
												<Title>
													<span className="overall-rating">{overAllRating}</span>
												</Title>
											</div>
											<div className="rating-total">
												<Ratings points={overAllRating} height={21} width={21} />
												<div className="rating-message review-averages-totals">
													<span>{totalItems}</span>
													{totalItems > 1 ? (
														<span>
															&nbsp;
															{t("device-pdp.reviewsCamelCase", { ns: "pdp" })}
														</span>
													) : (
														<span>&nbsp;{t("device-pdp.pdpReview", { ns: "pdp" })}</span>
													)}
												</div>
											</div>
										</div>
									</Col>
									<Col colSizes={{ mobile: 3, tablet: 3, desktop: 1 }}></Col>
									<Col colSizes={{ mobile: 4, tablet: 4, desktop: 8 }}>
										<Grid rowGutter="0.5rem" colGutter={false}>
											<Row>
												<Col
													colSizes={{ mobile: 2, tablet: 2, desktop: 4 }}
													className="pd-ta=rt"
												>
													{generateDistribution()}
												</Col>
												<Col colSizes={{ mobile: 1, tablet: 1, desktop: 5 }}>
													<Body>
														{reviewTotals?.map((rating) => (
															<div key={rating.Id} className="review-averages">
																<span>
																	<ProgressBar
																		bgColor="#1B1D1F"
																		progress={Number(rating?.RatingValue).toFixed(1)}
																		reviewCount={Number(rating?.Count)}
																		totalItems={totalItems}
																		height="0.6rem"
																		width={isMobile ? "7.5rem" : "10rem"}
																	></ProgressBar>
																</span>
															</div>
														))}
													</Body>
												</Col>
											</Row>
										</Grid>
									</Col>
								</Row>
							</Grid>
						</Col>
						<Col colSizes={{ mobile: 2, tablet: 5, desktop: 5 }}></Col>
					</Row>
					<Row>
						<MarginSpacerL></MarginSpacerL>
						{/* <Line type="light" /> */}
						<Col colSizes={{ mobile: 4, tablet: 3, desktop: 3 }}>
							<MarginSpacerL></MarginSpacerL>
							<SortByText>Sort by:</SortByText>
							<DropdownSelect
								label={" "}
								onChange={(e) => {
									setPageSelected(1);
									setSortBy(e.target.value);
									dispatch(
										getReviews({
											productId: deviceID,
											limit: t("reviewLimit", { ns: "pdp" }),
											offset: 0,
											sort: e.target.value,
										})
									);
								}}
								width={"100%"}
								inlineLabel={false}
							>
								{sortReviews.map((item, index) => (
									<option value={item.value} key={index}>
										{item.name}
									</option>
								))}
							</DropdownSelect>
							<MarginSpacerL></MarginSpacerL>
						</Col>
						<Col colSizes={{ mobile: 4, tablet: 9, desktop: 9 }}></Col>
					</Row>
					{/* <MarginSpacerM></MarginSpacerM> */}
					<Line type="light" />
					<MarginSpacerL></MarginSpacerL>
					{results?.length > 0 && generateReviewLists()}
					{totalItems / reviewResults?.length > 0 ? (
						<CustomPagination
							selectPage={(e) => {
								handlePageSelection(e);
							}}
							selectedPage={pageSelected}
							total={Math.round(totalItems / reviewResults?.length)}
							showArrow={true}
						/>
					) : (
						<></>
					)}
				</Grid>
			)}
		</div>
	);
};

export default Reviews;
