import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { TextLink } from "@vds3/buttons";
import { Body } from "@vds3/typography";
import {
  LineItem,
  LineItemPriceGrid,
} from "pages/ShoppingCart/components/LineItemPriceGrid/LineItemPriceGrid";
import { MarginSpacerM } from "../../../../common/components/styleTag";
import { formatPriceString } from "utils/commonUtils";
import CartPkgContext from "./cartPkgContext";
import { PackagePriceDiv, SeparatorLine } from "./styles";
import { setLoadShoppingCartWidget, setSessionCartData } from "app/ducks/GlobalSlice";

const HIDE_EDIT_TYPES = ["ONETALK", "REGULAR"];

const PackagePlan = ({ onEditClick }) => {
  const { t } = useTranslation(["shoppingCart"]);
  const dispatch = useDispatch();
  const { pack, isEditable, is5GMultiPlan ,isMobilityEdit, enableMobilityRemove, isDigitalQuote, isDSP } = React.useContext(CartPkgContext);
  const quantity = Number(pack?.quantity);
  const linelevelPlanAem = t(
    "shopping-cart.cart-linelevel-plan", { ns: "shoppingCart", returnObjects: true }
  );
  const autoPayPlanCategoryCodes = linelevelPlanAem?.autoPayPlanCategoryCodes || [];
  const deviceCategoryCodes = linelevelPlanAem?.deviceCategoryCodes || [];
  const showAutoPayMessage =  deviceCategoryCodes?.includes(pack?.deviceCategory) &&
              (autoPayPlanCategoryCodes?.includes(pack?.plan?.pricePlanCatCode));
        
  //const showAutoPayMessage = pack?.packageType === "REGULAR";
  const canEditPlans =
    isEditable &&
    (!HIDE_EDIT_TYPES.includes(pack?.packageType) ||
      (pack?.byod && (!isDigitalQuote || enableMobilityRemove)) ||
      isMobilityEdit);
  const isLtePlusWithDQ = pack?.plan?.planType == "UNCAPPED" && isDigitalQuote;
  const eacDisclaimerEnabled = t("shopping-cart.cart.eacDisclaimerEnabled", { ns: "shoppingCart", returnObjects: true });
  const planFeatures = useMemo(() => {
    let features = [];
    if(pack?.features) {
      features = Object.values(pack?.features)
        .filter((feature) => feature?.displaySection === "Plans")
        .map((feature) =>
          feature?.featureDesc?.toUpperCase() === "ECONOMIC ADJUSTMENT CHARGE"
            ? {
                ...feature,
                featureDesc: t(
                  "smart-phones.shopping-cart.economicAdjustmentMessage"
                ),
              }
            : feature
        );
      if(!features?.length) {
        features = [
          {
            visFeatureCode: "",
            featureDesc: t(
              "smart-phones.shopping-cart.economicAdjustmentMessage"
            ),
            featurePrice: 0.0,
            displaySection: "Plans",
          },
        ];
      }
    } else if(!pack?.features) {
      features = [
        {
          visFeatureCode: "",
          featureDesc: t(
            "smart-phones.shopping-cart.economicAdjustmentMessage"
          ),
          featurePrice: 0.0,
          displaySection: "Plans",
        },
      ];
    }
    return features
  },[pack?.features]);
    
  const onEditClickFromWidget = () => {
    dispatch(setSessionCartData({}));
   dispatch(setLoadShoppingCartWidget(false));
  }
  return (
    <>
      <LineItemPriceGrid title={t("shopping-cart.cart-plan.heading")}>
        <LineItem
          displayName={pack?.plan?.planType == "UNCAPPED" ? "Plus " + pack?.plan?.pricePlanDesc: pack?.plan?.pricePlanDesc }
          price={
            <PackagePriceDiv>
              {formatPriceString(Number(pack?.plan?.planPrice) * quantity)}
              {t("shopping-cart.cart.perMonth")}
              {(pack?.plan?.planPrice !== pack?.plan?.planPreviousPrice &&
                pack?.packageType === "REGULAR") && (
                <Body size="large" color="#6F7171">
                  {"was "}
                  <Body
                    size="large"
                    color="#6F7171"
                    primitive="span"
                    strikethrough
                  >
                    {formatPriceString(Number(pack?.plan?.planPreviousPrice) * quantity)}
                  </Body>
                </Body>
              )}
            </PackagePriceDiv>
          }
          subText={
            quantity > 1 &&
            t("shopping-cart.cart-plan.perPlan", {
              count: quantity,
              planPrice: pack?.plan?.planPrice,
            })
            // `For ${quantity} ${quantity > 1 ? "plans" : "plan"}, $${
            //   pack?.plan?.planPrice
            // } per plan.`
          }
          subTextSize="small"
        />
      </LineItemPriceGrid>
      {!!showAutoPayMessage && (
        <>
          <MarginSpacerM />
          <Body size="small">{t("smart-phones.shopping-cart.Autopay")}</Body>
        </>
      )}
      { ((!!canEditPlans && !is5GMultiPlan && !isDSP && !isLtePlusWithDQ && !pack.buss) || true) && (
        <div>
          <MarginSpacerM />
          <TextLink onClick={() => onEditClickFromWidget(pack)}>
            {t("shopping-cart.cart.edit")}
          </TextLink>
        </div>
      )}
       {!!planFeatures?.length && (pack?.packageType !== "ONETALK" && !pack.buss) && (
        <>
          <SeparatorLine type="secondary" />
          <LineItemPriceGrid title={t("shopping-cart.cart-plan.fees")}>
            {planFeatures.map((feature) => (
              <LineItem
                key={feature?.visFeatureCode}
                displayName={feature?.featureDesc === "Economic Adjustment Charge" 
                 && eacDisclaimerEnabled && eacDisclaimerEnabled === 'Y' 
                 ? feature?.featureDesc + "**"
                 : feature?.featureDesc}
                price={`${formatPriceString(
                  Number(feature?.featurePrice) * quantity
                )}${t("shopping-cart.cart.perMonth")}`}
                subText={
                  quantity > 1 &&
                  t("shopping-cart.cart.productNouns.deviceWithCount", {
                    count: quantity,
                  })
                }
                subTextSize="small"
              />
            ))}
          </LineItemPriceGrid>
            {eacDisclaimerEnabled && eacDisclaimerEnabled === 'Y' ?
            <><MarginSpacerM />
            <Body size="small">{t("shopping-cart.shoppingcart-data.eacDisclaimer")}</Body>
            </> : <></>}
        </>
      )}
      
    </>
  );
};

export default PackagePlan;
