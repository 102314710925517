import {select} from "redux-saga/effects";

export function*  isFromAgentLinkDealsPage(requestBody)
{
  const isFromAgentLinkPage = yield select(state=>state?.ProspectWidgets_GlobalData?.fromAgentLinkDealsPage)
  const agentLocationCode = yield select(state=>state?.ProspectWidgets_GlobalData?.agentLocationCode); 
  const agentOutletId = yield select(state=>state?.ProspectWidgets_GlobalData?.agentOutletId); 
  const agentCompanyName = yield select(state=>state?.ProspectWidgets_GlobalData?.agentCompany);
  if(isFromAgentLinkPage)
    {
      requestBody = {
                ...requestBody,
                fromAgentLinkPage: isFromAgentLinkPage,
                agentLocationCode : agentLocationCode,
                agentOutletId:agentOutletId,
                agentName:agentCompanyName
              }             
     }
     return requestBody;
}
 