import apiClient from "./apiClient";
import { Ss7DomainWithVerizonWireless } from "./commonUtils";

export function getCarouselImagesetData(imageSetUrl, imageName = "") {
  const regExp = /\{[^)]+}/g;
  let imageSet = [];
  const url = process.env.PROSPECT_WIDGETS_APP_IMAGE_CDN_URL_2 + imageSetUrl;
  return apiClient.get(url, { responseType: "text" })
    .then((res) => {
      const data = res?.data.match(regExp);
      const resp = JSON?.parse(data.toString());
      imageSet = resp?.set?.item.map(item => 
        `${process.env.PROSPECT_WIDGETS_APP_IMAGE_CDN_URL_1}${item?.i?.n}?$acc-lg$&hei=350&wid=350`);
      return imageSet;
    });
}

export function getAccessoryCarouselImagesetData(imageSetUrl, imageName = "") {
  const regExp = /\{[^)]+}/g;
  let imageSet = [];
  const url = process.env.PROSPECT_WIDGETS_APP_IMAGE_CDN_URL_2 + imageSetUrl + "?req=imageset,json";
  return apiClient.get(url, { responseType: "text" })
    .then((res) => {
      const data = res?.data.match(regExp);
      const resp = JSON.parse(data.toString());
      imageSet = new Set(resp?.IMAGE_SET.split(';').map(item => 
        `${process.env.PROSPECT_WIDGETS_APP_IMAGE_CDN_URL_1}${item}?$acc-lg$&hei=350&wid=350`));
      return Array.from(imageSet.values());
    });
}

export function getCarouselImagesetLink(image) {
  return process.env.PROSPECT_WIDGETS_APP_IMAGE_CDN_URL_2 + image + "?req=imageset,json";
}

// For getting the image src from ss7
export function getImageLink(image, width = 400, height = 400, useWebP = false) {
  return `${Ss7DomainWithVerizonWireless()}${image}?$acc-lg$&hei=${height}&wid=${width}${useWebP ? "&fmt=webp-alpha" : ""}`;
  //return `${process.env.PROSPECT_WIDGETS_APP_IMAGE_CDN_URL_2}${image}?$acc-lg$&hei=${height}&wid=${width}${useWebP ? "&fmt=webp-alpha" : ""}`;
}
export const getImage = (imageSet,height,width) =>{
  const imageCDN = "//ss7.vzw.com/is/image/VerizonWireless/";
  const imgUrl =`${imageCDN}${String(imageSet).split("?req=set,json")[0]}?$acc-lg$&hei=${height}&wid=${width}`
  return imgUrl;
}
export function getPDPImageLink(image, useWebP = false){
  return `${Ss7DomainWithVerizonWireless()}${image}`;
}
