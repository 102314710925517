import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, TextLink } from "@vds3/buttons";
import { Icon } from "@vds3/icons";
import { Body, Title } from "@vds3/typography";

const OfferCard = ({
	eyebrow,
	title,
	subtitle,
	onDetailsClick,
	isActionable,
	isAutoAdded,
	onAddClick,
	onRemoveClick,
	isAdded,
	isBogo,
	isBogoFlag,
	...props
}) => {
	const { t } = useTranslation(["pdp"]);
	const [bogoCount, selectedBogoCount] = React.useState(0);
	React.useEffect(() => {
		let bogoCountLocal = 0;
		if (
			props.sessionCartData !== null &&
			props.sessionCartData &&
			props.sessionCartData?.cart?.packages !== null &&
			props.sessionCartData?.cart?.packages
		) {
			Object.keys(props.sessionCartData?.cart?.packages).map((data) => {
				props.sessionCartData?.cart?.packages[data]["selectedOffers"]?.map(
					(offer) => {
						if (String(offer.type).toUpperCase().includes("BOGO")) bogoCountLocal++;
					}
				);
			});
			selectedBogoCount(bogoCountLocal);
		}
	}, [props.sessionCartData]);
	return (
		<OfferContainer>
			<TopSection>
				<Body size="small">{eyebrow}</Body>
				<Title size="small" bold>
					{title}
				</Title>
				<div>
					<Body size="large"></Body>
					<TextLink onClick={(e) => onDetailsClick(e)}>
						{t("device-pdp.Details")}
					</TextLink>
				</div>
			</TopSection>
			<BottomSection>
				{isAdded ? (
					<>
						<div style={{ display: "flex", gap: "0.5rem" }}>
							<Icon size="XLarge" name="checkmark-alt" />
							<CenterFlex>
								<Body size="large" bold>
									{t("device-pdp.moreWaysToSavingOptions.btnTextAdded")}
								</Body>
							</CenterFlex>
						</div>
						{!isAutoAdded && (
							<CenterFlex>
								<TextLink type="standAlone" onClick={(e) => onRemoveClick(e)}>
									{isBogo ? (
										<>
											{" "}
											{isBogoFlag && (!props.editPackagePackageId || bogoCount < 2) ? (
												<> {t("device-pdp.moreWaysToSavingOptions.skipBogo")}</>
											) : (
												""
											)}
										</>
									) : (
										<>{t("device-pdp.moreWaysToSavingOptions.btnTextRemove")}</>
									)}
								</TextLink>
							</CenterFlex>
						)}
					</>
				) : isActionable ? (
					<Button use="secondary" size="small" onClick={(e) => onAddClick(e)}>
						{t("device-pdp.moreWaysToSavingOptions.btnTextAdd")}
					</Button>
				) : (
					<></>
				)}
			</BottomSection>
		</OfferContainer>
	);
};

const OfferContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 1rem;
	height: 100%;
	// overflow: auto;
`;
const TopSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

const BottomSection = styled.div`
	display: flex;
	justify-content: space-between;
	> div {
		align-items: center;
	}
`;
const CenterFlex = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export default OfferCard;
