 export const InitialState = {

    contactInfo: {
        firstName: "",
        lastName: "",
        email: "",
        businessName: "",
        jobTitle:"",
        address:"",
        city: "",
        zipCode: "",
        state: "",
        selectedMailType: "",
        fedTaxId: "",
        DateOfBirth:"",
    },
    businessInfo: {
        selected: true,
        address:  "",
        city:  "",
        propertyType: "",
        state: "",
        zipCode: ""
    },
    shippingInfo: {
        selected: false,
        address:  "",
        city:  "",
        propertyType: "",
        state: "",
        zipCode:   ""
    },
    billingaddressLabel: "You must enter your billing address.",
    enableAltShipping: false,
    errorTexts: {
        contactInfo: {
            fName: '',
            lName: '',
            cPhoneNumber: '',
            cEmail: '',
            cAddress: '',
            cState: '',
            cZip: '',
            cCity: '',
            cBusinessName: '',
            cjobTitle:'',
            fedTaxId: '',
            dob:'',
        },
        billingInfo: {
            bAddress: '',
            bCity: '',
            bState: '',
            bZip: '',
        },
        shippingInfo: {
            sAddress: '',
            sCity: '',
            sState: '',
            sZip: '',
        },
        billingErr: '',
        shippingErr: '',
        homeAdressInfoErr: ''
    },
    showBizAdrErr: false,
    homeAdressInfo: {},
    homeAdressInfo2:"",
    addressError: false,
    checkStreetAddress: '',
    isSameAddress: false,  
};

export default InitialState;