// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.businessInfo { 
    /* margin: 1rem 0rem; */
    margin: 0rem;
}
.businessInfo .container .form-col{ 
     margin: 1rem 1rem 0.5rem 0;
}
.businessInfo .container .form-col .helper-text p{ 
     color: #747676;
     margin-top: 8px;
} 
.businessInfo .container .continue-btn{ 
     margin-top: 1rem;
}
.businessInfo .container .continue-btn{ 
     margin-top: 1rem;
}
.businessInfo .container >div >div{ 
     margin: 0;
}
.businessInfo .container .Prod-Discription{ 
     margin-bottom: 0.5rem;
}
.businessInfo input:disabled {
     color: gray;
}
.fontnormal{
     font-weight: lighter;
}
@media screen and (max-width:1098px) {
     .mv-vw-mr-bt{
          margin-bottom: 32px;
     }
}`, "",{"version":3,"sources":["webpack://./src/pages/Checkout/BusinessInfo/BusinessInfo.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;AAChB;AACA;KACK,0BAA0B;AAC/B;AACA;KACK,cAAc;KACd,eAAe;AACpB;AACA;KACK,gBAAgB;AACrB;AACA;KACK,gBAAgB;AACrB;AACA;KACK,SAAS;AACd;AACA;KACK,qBAAqB;AAC1B;AACA;KACK,WAAW;AAChB;AACA;KACK,oBAAoB;AACzB;AACA;KACK;UACK,mBAAmB;KACxB;AACL","sourcesContent":[".businessInfo { \n    /* margin: 1rem 0rem; */\n    margin: 0rem;\n}\n.businessInfo .container .form-col{ \n     margin: 1rem 1rem 0.5rem 0;\n}\n.businessInfo .container .form-col .helper-text p{ \n     color: #747676;\n     margin-top: 8px;\n} \n.businessInfo .container .continue-btn{ \n     margin-top: 1rem;\n}\n.businessInfo .container .continue-btn{ \n     margin-top: 1rem;\n}\n.businessInfo .container >div >div{ \n     margin: 0;\n}\n.businessInfo .container .Prod-Discription{ \n     margin-bottom: 0.5rem;\n}\n.businessInfo input:disabled {\n     color: gray;\n}\n.fontnormal{\n     font-weight: lighter;\n}\n@media screen and (max-width:1098px) {\n     .mv-vw-mr-bt{\n          margin-bottom: 32px;\n     }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
