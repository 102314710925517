import React from "react";

const BlackCircleShape = (props) => {
    return (
    <svg xmlns="http://www.w3.org/2000/svg" 
    fill="#000000" 
    width={props.width}
    height={props.height}
    viewBox="0 0 26 26"
     >
        <g>
            <path d="M26,13c0,7.18-5.818,13-13,13C5.82,26,0,20.18,0,13C0,5.82,5.82,0,13,0C20.182,0,26,5.82,26,13z" />
        </g>
    </svg>
    );
};
export default BlackCircleShape;