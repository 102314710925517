import React, { useEffect, useState } from 'react'
import { Body, Title } from "@vds3/typography";
import { MarginSpacerM, MarginSpacerS } from 'common/components/styleTag';


const Information = ({accNo="", purchaseItem }) => {
    if(purchaseItem){
   return(
    <div style={{margin:'1rem 0'}}>
        <div className='aligningInfoManage'>
        {(purchaseItem['contacts'] && purchaseItem['contacts'].length) ? <ITInfo contactInfo={purchaseItem['contacts']}/> : <></>}
        {(purchaseItem['contacts'] && purchaseItem['contacts'].length && purchaseItem['item'] && purchaseItem['item']['sda']) ? <ServiceInfo phone={purchaseItem['contacts'][0]['phone']} sda={purchaseItem['item']['sda']}/> : <></>}
        <PaymentInfo accNo={accNo}/>
        </div>
    </div>
   )
}else return <></>
}

const ITInfo = ({contactInfo})=>{
    return(
        <div>
            <Title size="medium" bold={true}>IT Administration</Title>
            <MarginSpacerS/>
            <Body size="small">Who will be managing the portal</Body>
            <MarginSpacerM/>
            <Common title={"Administrator name"} body={contactInfo[0]['firstName'] + ' '+ contactInfo[0]['lastName']}/>
            <Common title={"Contact"} body={contactInfo[0]['phone']}/>
            <Common title={"Notification email"} body={contactInfo[0]['emailAddress']}/>
        </div>
    )
}

const ServiceInfo = ({sda,phone=""})=>{
    return(
        <div className='serInfoManage'>
            <Title size="medium" bold={true}>Service information</Title>
            <MarginSpacerS/>
            <Body size="small">Communication address for services</Body>
            <MarginSpacerM/>
            <Body size="small" bold={true}>{"Service address"}</Body>
            <Body size="medium">{sda['address1']}</Body>
            <Body size="medium">{sda['city'] + ', '+sda['state']+' '+sda['zipCode']}</Body>
            <Body size="medium">{phone}</Body>
        </div>
    )
}
const PaymentInfo = ({accNo=""})=>{
    return(
        <div>
            <Title size="medium" bold={true}>Payment Method</Title>
            <MarginSpacerS/>
            <Body size="small">How will you be paying?</Body>
            <MarginSpacerM/>
            <Common title={"Bill to Account"} body={accNo}/>

        </div>
    )
}
const Common = ({title="",body=""})=>{
    return(
        <div style={{marginBottom:'0.5rem'}}>
            <Body size="small" bold={true}>{title}</Body>
            <Body size="medium">{body}</Body>
        </div>
    )
}
// {contactInfo[0]['']}
export default Information