// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gridwall-container > div > div {
    /* margin: auto!important; */
}
.gridwall-container{
    background-color: #F6F6F6;
    padding-top:40px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Mobility/components/MobilityGridwall/Gridwall/Gridwall.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC;AACA;IACI,yBAAyB;IACzB,gBAAgB;AACpB","sourcesContent":[".gridwall-container > div > div {\n    /* margin: auto!important; */\n}\n.gridwall-container{\n    background-color: #F6F6F6;\n    padding-top:40px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
