import styled from "styled-components";
import { Modal, ModalBody, ModalFooter } from "@vds3/modals";
import { RadioBox } from "@vds3/radio-boxes";
import { Body } from "@vds3/typography";
import { TrailingTooltip } from "@vds3/tooltips";
import { Tabs } from "@vds3/tabs";

export const PlansAccordionContainer = styled.div`
  // button {
  //   padding-right: 0;
  // }
`;

export const TrailingTooltipWrapper = styled(TrailingTooltip)`
[class^=TooltipContent-VDS]{
    height: 178px;
    max-height: 11.5rem;
}
`
export const StyledTabs = styled(Tabs)`
  div{
    width: 299px;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  // grid-template-columns: repeat(
  //   ${(props) => (props.isMobile ? 1 : props.span ? props.span : 4)},
  //   minmax(158px, 1fr)
  // );
  @media screen and (min-wid:767px) and (max-width:832px){
    display: grid;
    grid-template-columns: repeat(
    ${(props) => (props.isMobile ? 1 : props.span ? props.span : 4)},
    minmax(${({span})=>span ? '256px' : '158px'}, 1fr)
  ); 
  }
`;

export const ShowDetailsStyle = styled.div`
    box-sizing: border-box;
    background-color: #f6f6f6;
    min-height: 2rem;
    display:flex;
    align-items:center;
    justify-content: flex-end;
    padding-right: 12px;
}
`;
export const GridContainerSP = styled.div`
 display:flex;
 justify-content:space-between;
 max-width:650px;
 >h1{
  width:210px;
 }
`;


export const TitleGridContainer = styled(GridContainer)`
  @media (min-width: 320px) and (max-width: 413px) {
    display: flex;
  }
`;

export const TitleContainer = styled('div')`
display:flex;
justify-content:space-between;
@media screen and (max-width:850px){
  display:${({ planLength }) => planLength > 2 ? 'flex' : 'grid'};
  [class^=PlanSelection__GridSpanItem]{
  margin-top:${({ planLength }) => planLength > 2 ? 'auto' : '20px'};
  margin-left:${({ planLength }) => planLength > 2 ? 'auto' : '-3px'};
  }
}
${({ isMobile, planLength }) =>
    isMobile ? "width: 100%" : planLength > 2 ? "width:90%" : "width:90%"}
`

export const GridSpanItem = styled.div`
  grid-column: span ${(props) => props?.span};
`;

export const SpeedPlanFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  button {
    width: 100%;
    min-height: 2.75rem;
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 700;
  }
`;

export const SpeedPlanModal = styled(Modal)`
  height: auto;
  width: 50%;
  min-height: auto;
  outline: none !important;
  > div:first-child {
    overflow: hidden;
  }
  @media only screen and (max-width: 767px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95vw;
    > div:first-child {
      padding: 2px;
    }
  }
`;

export const SpeedPlanModalBody = styled(ModalBody)`
  padding: 0 0px 0;
  outline: none !important;
  height: auto;
`;

export const SpeedPlanModalFooter = styled(ModalFooter)`
  padding: 0 0px 0;
  outline: none !important;
  height: auto;
`;

export const TitleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PlanCardGroup = styled(GridContainer)`
  ${({ isMobile }) =>
  isMobile ? "grid-auto-rows: 0fr" : "grid-auto-rows: 1fr"}
  }
  grid-gap: 10px;
  padding: 0;
  min-height: 180px;
  @media (max-width: 545px) {
    min-height: 164px;
  }
  .planCard-empty-space {
    ${({ isValidOneOffer }) =>
    isValidOneOffer ? "height: 40px;" : "padding-top: 5px;"}
  }
  align-items:end;
`;

export const PromoBannerContainer = styled(GridContainer)`
  display: flex;
  p {
    display: flex;
    align-items: center;
  }
  .promoBannerStrip {
    ${({ isMobile, planLength }) =>
      isMobile ? "width: 100%" : planLength > 2 ? "width:75%" : "width:50%"}
  }
`;

export const PromoBanner = styled(GridSpanItem)`
  box-sizing: border-box;
  background-color: #f6f6f6;
  min-height: 2.25rem;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  gap: 1rem;
  padding: 10px;
`;

export const StyledBox = styled(RadioBox)`
  label {
    padding: 0;
    span {
      height: 100%;
      > div {
        height: 100%;
      }
    }
  }

  border: ${props => (props.selected ? "3px solid #000000" : "1px solid #6f7171")}
`;

export const PromoBadge = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin: 12px 12px 4px 0px;
  padding:16px;
  border-radius:8px;
  background: #000000;
  width:100%;
  @media only screen and (max-width: 767px) {
    padding:12px;

    > div > div > div > div {
      > div:first-child{
        max-width: 5%;
        margin: 1px 4px 0px 0px;
      }
      > div:last-child{
        max-width: 90%;
      } 
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  * {
    color: ${(props) => (props.isDisabled ? "#6F7171" : "")};
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 12px 12px 12px;
`;

export const CardContentDetailsBtn = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 0 12px 12px 12px;
  p {
    margin-top: auto;
  }
`;

export const ActionCardContainer = styled.div`
  min-height:18.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  * {
    color: ${(props) => (props.isDisabled ? "#6F7171" : "")};
  }
`;

export const ActionCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
`;

export const PromoCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f6f6f6;
  padding: 12px;
  border-radius: 4px;
  min-height: 2.75rem;
`;

export const ComparePlansBtnContainer = styled(GridSpanItem)`
  position: relative;
  > div {
    bottom: 0;
    right: 0;
  }
`;

export const ComparePlansAuthContainer = styled.div`
  > div {
    bottom: 10px;
    right: 10px;
  }
`;

export const DisclaimersContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledText = styled.div`
  font-size: .75rem !important;
  color: #ffffff;
  font-weight: ${props => props.bold ? 700 : 400};
`;
export const BBAFCC = styled(GridSpanItem)`
width:200px;
`;
