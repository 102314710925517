import React, { useState, useEffect, useRef, useMemo, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, Col, Row } from "@vds3/grids";
import { Body, Title } from "@vds3/typography";
import ColorOptions from "./ColorOptions";
import DetailTabs from "./DetailTabs";
import PDPCarousel from "./PDPCarousel";
import PriceInfo from "./PriceInfo/PriceInfo";
import OfferSection from "./OfferSection/OfferSection";
import ShippingOptions from "./ShippingOptions";
import StorageAndPrice from "./StorageAndPrice";
import Quantity from "./Quantity";
import SimTypes from "./SimTypes";

import PreOwned from "./PreOwned";
import PDPAccordionSection from "./PDPAccordionSection";
import RadioBoxSection from "./RadioBoxSection";

import * as PDPUtil from "./pdpUtils";
import { FLOWS, setClientId, setLoadShoppingCartWidget } from "app/ducks/GlobalSlice";
import { setFlowType } from "../../ducks/MobilitySlice";
import { MarginSpacerL, MarginSpacerS } from "common/components/styleTag";
import useMemoizedState from "hooks/useMemoizedState";
import { PDPContextProvider } from "./pdpContext";
import "./ProductDetailsNew.css";
import { Accordion, AccordionItem } from "@vds3/accordions";
import SessionCartId from "../../../../common/components/SessionCartId/SessionCartId";
import {
	ProductTitle,
	ColorSwatchSection,
	PDPHeaderInfo,
	AccordionSection,
	QuantitySection,
	ProductSubTitle,
	PhoneDetails,
	ProductTitlePrice,
	AccessoryPriceWrapper,
	AccessoriesColorWrap,
} from "./styles";
import PdpPlanCard, { PriceContainer, TabsSection } from "./PDPPlanCard";
import { addToCart, marketplaceAddToCart, setIsAddToCartCompleted, setMarketplaceAddtoCartResp, setPageLoading, setSelectedBussProduct } from "pages/BussProductsPage/ducks/BussProductSlice";
import SpinnerComponent from "common/components/Spinner/Spinner";
import isEmpty from "lodash/isEmpty";

const ProductDetails = ({
	productDetail,
	isEditPackageFlow,
	editPackagePackageId,
	isOneTalk,
	combinePricing,
	preselectedColor,
	preselectedSku,
	preselectedSimType,
	appliedPromo,
	isBogo,
	isBogoFlag,
	bogoSelected,
	bogoRemoved,
	tradeInValueInfoDefault,
	tradeInfoDeviceSku,
	pack,
	compareDeviceName,
	compareSelectedSku,
	pegaRecommendations,
	fromAccessory,
	fromProspect = false,
	...props
}) => {
	const { t } = useTranslation(["pdp", "bussProductQA", "shoppingCart"]);
	const appData = useSelector((state) => state.ProspectWidgets_GlobalData);
	let sessionCartData = appData?.sessionCartData?.apiResponse?.prospectFlowData;
	const planFirstData = useSelector((state) => state.PlanFirst);
	const TradeInSlice = useSelector((state) => state.TradeInSlice);
	const bussData = useSelector((state) => state?.ProspectWidgets_BussProductsData);
	const dispatch = useDispatch();
	const bussIcons = t("shopping-cart.bussProduct.iconUrls", {
		ns: "shoppingCart",
		returnObjects: true,
	});
	const [product, setProduct] = useState({});
	const [imageSetUrl, setImageSetUrl] = useState("");
	const [selectedConditionShort, setSelectedConditionShort] = useState(
		productDetail?.data?.deviceDetails?.skulist[0]?.conditionShort
	);
	const [selectedSku, setSelectedSku] = useState(null);
	const [selectedColor, setSelectedColor] = useState(null);
	const [defaultColor, setDefaultColor] = useState(null);
	const [isShowOffers, setIsShowOffers] = useState(false);
	const [isShowSimTypes, setShowSimTypes] = useState(false);
	const [isShowPreOwned, setIsShowPreOwned] = useState(false);
	const [isShowColor, setIsShowColor] = useState(false);
	const [isShowPlans, setIsShowPlans] = useState(true);
	const [isShowLicense, setIsShowLicense] = useState(false);
	const [hoveredColor, handleHoveredColor] = useState("");
	const [averagRating, setAverageRating] = useState(0);
	const [isShowStorage, setIsShowStorage] = useState(false);
	const [isShowCaseType, setIsShowCaseType] = useState(false);
	const [isShowCaseSize, setIsShowCaseSize] = useState(false);
	const [isShowBandType, setIsShowBandType] = useState(false);
	const [isShowBandColor, setIsShowBandColor] = useState(false);
	const [isShowBandSize, setIsShowBandSize] = useState(false);
	const [addToBuss, addToBussClicked] = useState(false)
	const [selectedFrequency, setSelectedFrequency] = useState('');
	const [selectedId, setSelectedId] = useState('');
	const [selectedStorage, setSelectedStorage] = useMemoizedState({});
	const [selectedSimType, setSelectedSimType] = useMemoizedState(null);
	const [selectedPayment, setSelectedPayment] = useMemoizedState("");
	const [selectedQuantity, setSelectedQuantity] = useMemoizedState(1);
	const [colorOptions, setColorOptions] = useMemoizedState([]);
	const [editPackageDeviceSku, handleEditPackageDeviceSku] = useState("");
	const [tradeInValueInfo, handleTradeInValueInfo] = useMemoizedState(
		tradeInValueInfoDefault
	);
	const [preOwnedDeviceOptions, setPreOwnedDeviceOptions] = useMemoizedState([]);
	const [memoryOptions, setMemoryOptions] = useMemoizedState([]);
	const [isoutOfStock, setoutOfStock] = useState(false);
	const [promoDetails, setPromoDetails] = useMemoizedState({});
	const [selectedPromo, setSelectedPromo] = useMemoizedState(appliedPromo);
	const [addToAllLines, setAddToAllLines] = useMemoizedState(false);
	const [showOutOfStock, setShowOutofStock] = useState(false);
	const [tradinPriceForSubtitle, handleTradeInPrice] = useState(-1);
	const [showOfferAccordion, setShowOfferAccordion] = useState(false);
	const [isPricingAvailable, setIsPricingAvailable] = useState(false);
	// BEGIN watch-only options
	const [caseTypeOptions, setCaseTypeOptions] = useMemoizedState([]);
	const [caseSizeOptions, setCaseSizeOptions] = useMemoizedState([]);
	const [bandTypeOptions, setBandTypeOptions] = useMemoizedState([]);
	const [bandColorOptions, setBandColorOptions] = useMemoizedState([]);
	const [bandSizeOptions, setBandSizeOptions] = useMemoizedState([]);
	const [selectedCaseType, setSelectedCaseType] = useMemoizedState("");
	const [selectedCaseSize, setSelectedCaseSize] = useMemoizedState("");
	const [selectedBandType, setSelectedBandType] = useMemoizedState("");
	const [selectedBandColor, setSelectedBandColor] = useMemoizedState("");
	const [selectedBandSize, setSelectedBandSize] = useMemoizedState("");
	const [selectedPlan, setSelectedPlans] = useState(undefined);
	const [features, setFeature] = useState()
	const [selectedBussModalProducts, setSelectedBussModal] = useState(undefined);
	// END watch-only options
	const overviewRef = useRef();
	const featuresRef = useRef();
	const specsRef = useRef();
	const reviewsRef = useRef();
	const isPlanFirstFlow = useMemo(
		() =>
			appData?.flow === FLOWS.PLANFIRST &&
			Object.values(appData?.sessionCartData?.devices || {})?.filter(
				(i) => i?.deviceFullDetail?.dummy
			)?.length,
		[appData?.flow, appData?.sessionCartData?.devices]
	);

	useEffect(() => {
		if (TradeInSlice?.removeTradeInResp?.message === "Success") {
			setShowOfferAccordion(true);
		} else {
			setShowOfferAccordion(false);
		}
	}, [TradeInSlice?.removeTradeInResp?.message]);

	const { deviceDetails, devicePdpDetails } = productDetail?.data || {};
	const isDeskphone = !!(
		isOneTalk &&
		(deviceDetails?.category === "Connected Devices" ||
			deviceDetails?.category === "connected-devices")
	);
	const tradeInPromoEligible = tradeInValueInfo?.tradeInEligible;
	const outOfStockDevice = productDetail?.data?.deviceDetails?.outOfStock;
	const isSmartWatch = deviceDetails?.category === "Connected Smartwatches";

	const offersList =
		selectedPayment == "monthly"
			? selectedSku?.pricingDetails?.devicePayments?.length &&
			selectedSku?.pricingDetails?.devicePayments?.[0]?.promoDetails?.length &&
			selectedSku?.pricingDetails?.devicePayments?.[0]?.promoDetails
			: selectedPayment === "twoyears"
				? selectedSku?.pricingDetails?.twoYear?.promoDetails
				: selectedSku?.pricingDetails?.zeroYear?.promoDetails;
	const bogoPromoTypes =
		t("device-pdp.bogoPromoTypes", { returnObjects: true }) || [];
	const sessionData = JSON.parse(sessionStorage.getItem("TRADE_INFO_VALUE"));
	const aemSortOrderData =
		t("sort-order", { ns: "pdp", returnObjects: true }) || {};

	const setAccordionsState = () => {
		const pdpAccordionConfig = t(`device-pdp.pdpAccordionConfig`, {
			returnObjects: true,
		});
		const categoryAccordionConfig =
			pdpAccordionConfig[deviceDetails?.category] ||
			pdpAccordionConfig["PDA/SmartPhones"];
		setIsShowStorage(categoryAccordionConfig?.price);
		setIsShowPreOwned(categoryAccordionConfig?.deviceCondition);
		setIsShowColor(categoryAccordionConfig?.color);
		setIsShowOffers(categoryAccordionConfig?.waysToSave);
		setShowSimTypes(categoryAccordionConfig?.SimTypes);
	};
	const isBuss = productDetail?.data?.buss

	const getDefaultSku = () => {
		const skulist = productDetail?.data?.deviceDetails?.skulist;
		let defaultSku =
			skulist?.find((i) => i?.defaultSku) ?? (skulist && skulist[0]);
		if (isEditPackageFlow && editPackagePackageId) {
			defaultSku = skulist?.find((value) =>
				editPackagePackageId.includes(value.sku)
			);
		} else if (preselectedSku) {
			defaultSku = skulist?.find((i) => i.sku === preselectedSku);
		}
		if (isEditPackageFlow && editPackagePackageId) {
			const deviceSku =
				sessionCartData &&
				sessionCartData["cart"]["packages"][editPackagePackageId]["deviceSku"];
			defaultSku = deviceDetails?.skulist?.find((sku) => sku.sku === deviceSku);
			setDefaultColor(defaultSku?.cssColor);
			setMemoryOptions(
				deviceDetails?.skulist?.filter(
					(sku) => sku.cssColor === defaultSku?.cssColor
				)
			);
			handleEditPackageDeviceSku(deviceSku);
		}
		return defaultSku;
	};
	useEffect(() => {
		if (!!bussData?.selectedBussProduct && Object.keys(bussData?.selectedBussProduct).length && !!productDetail && !!productDetail.data && !!productDetail.data.plans && productDetail.data.plans.length) {
			productDetail.data.plans.map(plan => {
				if (plan.id === bussData?.selectedBussProduct?.deviceSku) {
					setSelectedPlans(plan)
					if (!!plan['pricingOptions'] && plan['pricingOptions'].length) {
						if (plan['pricingOptions'].length === 1) {
							setSelectedFrequency(plan['pricingOptions'][0]['priceType'])
						} else {
							plan['pricingOptions'].map(price => {
								if (String(price['priceType']).toUpperCase().includes(String(bussData?.selectedBussProduct.priceType).split('_')[0].toUpperCase())) setSelectedFrequency(price['priceType'])
							})
						}
					}
				}
			})
		}
	}, [bussData?.selectedBussProduct, productDetail])
	useEffect(() => {
		if (!!props.selectedBUSS) {
			const selectedBrand = String(String(props.selectedBUSS).split(" ")[0]).toUpperCase()
			const bussProducts = t('buss-product.products', { ns: 'bussProductQA', returnObjects: true })
			if (!!bussProducts && bussProducts[selectedBrand]) setFeature(bussProducts[selectedBrand])
		}
	}, [props.selectedBUSS])
	useEffect(() => {
		if (props.reviews !== null && props.reviews) {
			let rating = 0;
			if (
				props.reviews?.Includes &&
				Object.keys(props.reviews?.Includes).length > 0
			) {
				for (const key of Object.keys(props.reviews?.Includes.Products)) {
					rating = Number(
						props.reviews.Includes.Products[key]?.ReviewStatistics
							?.AverageOverallRating
					).toFixed(1);
				}
			}
			setAverageRating(rating);
		}
	}, [props.reviews]);

	useEffect(() => {
		let inStock = false;
		productDetail?.data?.deviceDetails?.skulist.forEach((item) => {
			if (item.outOfStock === false) {
				inStock = true;
			}
		});
		setAccordionsState();
		const sku = getDefaultSku();
		setShowOutofStock(inStock);
		setSelectedSku(sku);
		setSelectedColor(sku?.cssColor);
		setSelectedStorage(sku?.capacity);
		if (isSmartWatch) {
			setSelectedCaseType(sku?.caseMaterial);
			setSelectedCaseSize(sku?.caseSize);
			setSelectedBandType(sku?.bandType);
			setSelectedBandColor(sku?.bandColor);
			setSelectedBandSize(sku?.bandSize);
		}
		getOptionsByColor(deviceDetails, sku?.cssColor, sku?.capacity, inStock);
	}, [productDetail]);

	const getPromoDetails = () => {
		const val =
			!!selectedSku?.pricingDetails?.devicePayments?.length &&
			!!selectedSku?.pricingDetails?.devicePayments?.[0]?.promoDetails?.length &&
			selectedSku?.pricingDetails?.devicePayments?.[0]?.promoDetails[0]?.promoMessages?.filter(
				(i) => i.placement === "PDP"
			);
		return val[0];
	};
	useEffect(() => {
		let isSkuPresent = false;
		if (product !== null && product) {
			const { deviceDetails } = product;
			if (deviceDetails !== null && deviceDetails) {
				const { skulist } = deviceDetails;
				if (skulist !== null && skulist && skulist.length) {
					isSkuPresent =
						skulist.filter((sku) => sku?.sku === tradeInfoDeviceSku).length > 0;
				}
			}
		}
		if (
			isSkuPresent &&
			((tradeInfoDeviceSku !== null && tradeInfoDeviceSku) ||
				(isEditPackageFlow && editPackagePackageId && tradeInValueInfoDefault))
		) {
			if (tradeInValueInfoDefault !== tradeInValueInfo)
				handleTradeInValueInfo(tradeInValueInfoDefault);
		} else if (
			!isSkuPresent &&
			isEditPackageFlow &&
			editPackagePackageId &&
			tradeInValueInfo
		) {
			handleTradeInValueInfo(tradeInValueInfo);
		} else handleTradeInValueInfo(null);
	}, [tradeInValueInfoDefault, product]);

	useEffect(() => {
		try {
			if (
				typeof window["lpTag"] != "undefined" &&
				lpTag?.section &&
				window?.lpTag?.newPage
			) {
				lpTag.section = manipulateLpTagSections();
				setTimeout(() => {
					window?.lpTag?.newPage(window?.location?.href, {
						section: lpTag.section,
					});
				}, 8000);
			}
		} catch (e) {
			console.error(e);
		}
	}, []);

	useEffect(() => {
		if (isEditPackageFlow) {
			if (sessionCartData?.cart?.packages) {
				let pkg = sessionCartData.cart.packages[editPackagePackageId];
				setSelectedPayment(
					String(pkg?.priceType)?.toUpperCase()?.includes("RETAIL")
						? "fullpayment"
						: String(pkg?.priceType)?.toUpperCase()?.includes("TWO_YEAR")
							? "twoyears"
							: "monthly"
				);
				if (fromAccessory) {
					pkg = sessionCartData?.cart?.accessories[editPackagePackageId];
					if (pkg) {
						setSelectedQuantity(pkg.quantity);
					}
				} else if (pkg) {
					const grpPackage = sessionCartData?.cart?.groupPackages[pkg.groupId];
					if (grpPackage) {
						setSelectedQuantity(grpPackage.quantity);
					}
				}
				const selectedPkg = deviceDetails?.skulist.find(
					(value) => editPackagePackageId && editPackagePackageId.includes(value.sku)
				);
				if (selectedPkg) {
					setSelectedColor(selectedPkg.cssColor);
					setSelectedStorage(selectedPkg.capacity);
					selectedPkg?.imageSet
						? setImageSetUrl(`${selectedPkg?.imageSet}`)
						: setImageSetUrl("");
					if (isSmartWatch) {
						setSelectedCaseType(selectedPkg?.caseMaterial);
						setSelectedCaseSize(selectedPkg?.caseSize);
						setSelectedBandType(selectedPkg?.bandType);
						setSelectedBandColor(selectedPkg?.bandColor);
						setSelectedBandSize(selectedPkg?.bandSize);
					}
				}
			}
		} else if (preselectedSku) {
			const selectedPkg = deviceDetails.skulist.find(
				(value) => preselectedSku === value.sku
			);
			if (selectedPkg) {
				setSelectedColor(selectedPkg.cssColor);
				setSelectedStorage(selectedPkg.capacity);
				selectedPkg?.imageSet
					? setImageSetUrl(`${selectedPkg?.imageSet}`)
					: setImageSetUrl("");
				if (isSmartWatch) {
					setSelectedCaseType(selectedPkg?.caseMaterial);
					setSelectedCaseSize(selectedPkg?.caseSize);
					setSelectedBandType(selectedPkg?.bandType);
					setSelectedBandColor(selectedPkg?.bandColor);
					setSelectedBandSize(selectedPkg?.bandSize);
				}
			}
		}
	}, [sessionCartData, editPackagePackageId, isEditPackageFlow, preselectedSku]);

	useEffect(() => {
		if (selectedCaseType) getOptionsByCaseType(deviceDetails, selectedCaseType);
	}, [selectedCaseType]);

	useEffect(() => {
		if (selectedCaseSize) {
			const skuList = deviceDetails?.skulist?.filter(
				(i) =>
					i?.caseMaterial === selectedCaseType &&
					i?.cssColor === selectedColor &&
					i?.caseSize === selectedCaseSize
			);
			const { bands } = PDPUtil.getUniqueWatchSizesAndBands(skuList);
			const matchingSku = findWatchSkuMatch({ skuList });
			setSelectedSku(matchingSku);
			setBandTypeOptions(PDPUtil.sortAlphaByName(bands));
		}
	}, [selectedCaseSize]);

	useEffect(() => {
		if (selectedBandType) {
			const skuList = deviceDetails?.skulist?.filter(
				(i) =>
					i?.cssColor === selectedColor &&
					i?.caseSize === selectedCaseSize &&
					i?.bandType === selectedBandType
			);
			const matchingSku = findWatchSkuMatch({ skuList });
			setSelectedSku(matchingSku);
		}
	}, [selectedBandType]);

	useEffect(() => {
		if (selectedBandColor) {
			const skuList = deviceDetails?.skulist?.filter(
				(i) =>
					i?.cssColor === selectedColor &&
					i?.caseSize === selectedCaseSize &&
					i?.bandType === selectedBandType &&
					i?.bandColor === selectedBandColor
			);
			const matchingSku = findWatchSkuMatch({ skuList });
			setSelectedSku(matchingSku);
		}
	}, [selectedBandColor]);

	useEffect(() => {
		if (selectedBandSize) {
			const skuList = deviceDetails?.skulist?.filter(
				(i) =>
					i?.cssColor === selectedColor &&
					i?.caseSize === selectedCaseSize &&
					i?.bandType === selectedBandType &&
					i?.bandColor === selectedBandColor &&
					i?.bandSize === selectedBandSize
			);
			const matchingSku = findWatchSkuMatch({ skuList });
			setSelectedSku(matchingSku);
		}
	}, [selectedBandSize]);

	const findWatchSkuMatch = ({ skuList = [], color = null, inStock = null }) => {
		let exactMatch,
			colorMatch,
			exactNonColorMatch,
			exactNonBandMatch,
			bandExactMatch,
			bandTypeMatch,
			firstAvailable,
			defaultSku;
		const selColor = color ?? selectedColor;
		const isInStock = inStock ?? showOutOfStock;
		const filteredList = skuList.filter(
			(i) => i?.caseMaterial === selectedCaseType
		);
		for (const opt of filteredList) {
			if (
				opt?.color === selColor &&
				opt?.caseSize === selectedCaseSize &&
				opt?.bandType === selectedBandType &&
				opt?.bandColor === selectedBandColor &&
				opt?.bandSize === selectedBandSize &&
				!opt?.outOfStock
			) {
				exactMatch = opt;
			} else if (
				opt?.caseSize === selectedCaseSize &&
				opt?.bandType === selectedBandType &&
				opt?.bandColor === selectedBandColor &&
				opt?.bandSize === selectedBandSize &&
				!opt?.outOfStock
			) {
				exactNonColorMatch = opt;
			} else if (
				opt?.color === selColor &&
				opt?.caseSize === selectedCaseSize &&
				opt?.bandType === selectedBandType &&
				!opt?.outOfStock
			) {
				exactNonBandMatch = opt;
			} else if (
				opt?.caseSize === selectedCaseSize &&
				opt?.bandType === selectedBandType &&
				opt?.bandSize === selectedBandSize &&
				!opt?.outOfStock
			) {
				bandExactMatch = opt;
			} else if (
				opt?.caseSize === selectedCaseSize &&
				opt?.bandType === selectedBandType &&
				!opt?.outOfStock
			) {
				bandTypeMatch = opt;
			} else if (opt?.color === selColor && isInStock) {
				colorMatch = opt;
				setoutOfStock(false);
			}
			if (opt?.defaultSku && (!opt?.outOfStock || !isInStock)) {
				defaultSku = opt;
				if (opt?.outOfStock) {
					setoutOfStock(true);
				}
			}
			if (!firstAvailable && !opt?.outOfStock) {
				firstAvailable = opt;
				setoutOfStock(false);
			}
		}

		return (
			exactMatch ||
			colorMatch ||
			exactNonBandMatch ||
			exactNonColorMatch ||
			bandExactMatch ||
			bandTypeMatch ||
			defaultSku ||
			firstAvailable ||
			""
		);
	};
	useEffect(() => {
		if (bussData?.bussAddToCartCompleted && addToBuss) {
			dispatch(marketplaceAddToCart());

		}
	}, [bussData?.bussAddToCartCompleted, addToBuss]);

	useEffect(() => {
		if (bussData?.marketplaceSaveAddtoCartResp?.status && addToBuss) {
			if(fromProspect ){
				const event = new Event("addToCartCompletedInMarketPalce")
				window.dispatchEvent(event)
			}else if (appData?.widgetInputParams?.clientName?.value === "prospect") {
				window.location.href = `${process.env.PROSPECT_WIDGETS_APP_BASE_PATH}shop/shopping-cart`;
			} else {
				dispatch(setLoadShoppingCartWidget(true));
				dispatch(setPageLoading(false));
			}
		}
	}, [bussData?.marketplaceSaveAddtoCartResp, addToBuss]);


	const getOptionsByCaseType = (
		device,
		caseMaterial,
		color = null,
		inStock = null
	) => {
		const optionData =
			selectedSku?.sorSkuType === "OCPO"
				? device?.skulist?.filter(
					(el) =>
						el.conditionShort === selectedConditionShort &&
						el.caseMaterial === caseMaterial
				)
				: device?.skulist?.filter((el) => el.caseMaterial === caseMaterial);
		const uniqueColors = PDPUtil.getUniqueColors(optionData);
		setColorOptions(uniqueColors);

		const matchingSku = findWatchSkuMatch({ skuList: optionData, inStock });
		setSelectedSku(matchingSku);
	};

	const getOptionsByColor = (
		device,
		cssColor,
		storage = null,
		inStock = null
	) => {
		if (cssColor) {
			let optionData =
				selectedSku?.sorSkuType == "OCPO"
					? device?.skulist?.filter(
						(el) =>
							el.conditionShort === selectedConditionShort && el.cssColor === cssColor
					)
					: device?.skulist?.filter((el) => el.cssColor === cssColor);
			optionData[0]?.imageSet
				? setImageSetUrl(`${optionData[0]?.imageSet}`)
				: setImageSetUrl("");

			if (isSmartWatch) {
				const matchingSku = findWatchSkuMatch({ skuList: optionData, inStock });
				setSelectedSku(matchingSku);
				setMemoryOptions([matchingSku]);
				const { sizes } = PDPUtil.getUniqueWatchSizesAndBands(optionData);
				setCaseSizeOptions(PDPUtil.sortAlphaByName(sizes));
				optionData = optionData.filter(
					(i) => i?.caseSize === matchingSku?.caseSize
				);
				const { bands } = PDPUtil.getUniqueWatchSizesAndBands(optionData);
				setBandTypeOptions(PDPUtil.sortAlphaByName(bands));
			} else {
				const memOptions =
					selectedSku?.sorSkuType === "OCPO"
						? [optionData[0]]
						: optionData?.sort((a, b) =>
							PDPUtil.sortStorageCapacities(a?.capacity || "", b?.capacity || "")
						);
				if (memOptions?.length) {
					setMemoryOptions(memOptions);
				}

				let storageMatch, firstAvailable, defaultMatch, colorMatch;
				const selStorage = storage ?? selectedStorage;
				const isInStock = inStock ?? showOutOfStock;
				for (const opt of memOptions) {
					if (opt?.capacity === selStorage && isInStock) {
						storageMatch = opt;
						setoutOfStock(false);
					}
					if (!firstAvailable && !opt?.outOfStock) {
						setoutOfStock(false);
						firstAvailable = opt;
					}
					if (!firstAvailable && !storageMatch && !!opt?.defaultSku) {
						defaultMatch = opt;
						setoutOfStock(true);
					}
					if (!firstAvailable && !storageMatch && !colorMatch) {
						colorMatch = opt;
						setoutOfStock(true);
					}
				}

				if (storageMatch) {
					setSelectedSku(storageMatch);
				} else if (firstAvailable) {
					setSelectedSku(firstAvailable);
				} else if (!firstAvailable && !storageMatch && defaultMatch) {
					setSelectedSku(defaultMatch);
				} else if (
					!firstAvailable &&
					!storageMatch &&
					!defaultMatch &&
					colorMatch
				) {
					setSelectedSku(colorMatch);
				}
			}
		} else if (selectedSku?.imageSet) {
			setImageSetUrl(selectedSku?.imageSet);
		}
	};

	useEffect(() => {
		if (selectedConditionShort) {
			let list = deviceDetails?.skulist?.filter(
				(sku) => sku.conditionShort === selectedConditionShort
			);
			if (isSmartWatch) {
				const uniqueCaseMats = PDPUtil.getUniqueWatchCases(list);
				setCaseTypeOptions(
					uniqueCaseMats.sort((a, b) => {
						const optA = aemSortOrderData?.caseMaterial?.[a?.caseMaterial] || 99;
						const optB = aemSortOrderData?.caseMaterial?.[b?.caseMaterial] || 99;
						return optA - optB;
					})
				);
				const newList = list.filter(
					(sku) => sku?.caseMaterial === selectedCaseType
				);
				if (!newList?.length) {
					let updatedCaseType = "";
					for (const mat of uniqueCaseMats) {
						if (
							mat?.caseMaterial !== selectedCaseType &&
							list.filter((sku) => sku?.caseMaterial === mat?.caseMaterial)?.length
						) {
							updatedCaseType = mat?.caseMaterial;
							break;
						}
					}
					setSelectedCaseType(updatedCaseType);
					return;
				} else {
					list = newList;
				}
			}
			const uniqueColors = PDPUtil.getUniqueColors(list);
			setColorOptions(uniqueColors);
			let updatedDevceDetails = { ...deviceDetails, skulist: list };
			let isColorAvailable = list?.filter((el) => el?.cssColor === selectedColor);
			let cssColor = isColorAvailable?.length
				? selectedColor
				: list?.[0]?.cssColor;
			getOptionsByColor(updatedDevceDetails, cssColor);
		}
	}, [selectedConditionShort]);

	useEffect(
		() => {
			if (productDetail?.data && defaultColor !== selectedColor) {
				getOptionsByColor(deviceDetails, selectedColor);
			}
		},
		[selectedColor],
		selectedConditionShort
	);

	useEffect(() => {
		if (productDetail?.data) {
			setProduct({ deviceDetails, devicePdpDetails });
			dispatch(setFlowType(isOneTalk ? "one-talk" : "prospect"));
			if (deviceDetails?.skulist?.length) {
				const uniqueColors = PDPUtil.getUniqueColors(deviceDetails?.skulist);
				const uniquePreOwnedDevices = PDPUtil.getUniqueDeviceList(
					deviceDetails?.skulist
				);
				const uniqueCaseMats = PDPUtil.getUniqueWatchCases(deviceDetails?.skulist);
				if (!uniquePreOwnedDevices?.length && !isSmartWatch) {
					setColorOptions(uniqueColors);
				}
				setPreOwnedDeviceOptions(uniquePreOwnedDevices);
				setCaseTypeOptions(
					uniqueCaseMats.sort((a, b) => {
						const optA = aemSortOrderData?.caseMaterial?.[a?.caseMaterial] || 99;
						const optB = aemSortOrderData?.caseMaterial?.[b?.caseMaterial] || 99;
						return optA - optB;
					})
				);
				getOptionsByColor(deviceDetails, selectedSku?.cssColor);
			}
		}
	}, [productDetail]);

	useEffect(() => {
		if (selectedSku) {
			if (!selectedPayment) {
				if (
					(isEditPackageFlow &&
						editPackagePackageId &&
						selectedSku !== null &&
						selectedSku &&
						String(editPackageDeviceSku) !== String(selectedSku.sku)) ||
					!isEditPackageFlow
				) {
					setSelectedPayment(
						selectedSku?.pricingDetails?.devicePayments?.length
							? "monthly"
							: "fullpayment"
					);
				}
			}
			setSelectedStorage(selectedSku?.capacity);
			if (selectedSku?.supportedSims?.length && !selectedSimType) {
				let defaultSimType = selectedSku?.defaultSimType;
				let defaultSku = selectedSku?.supportedSims?.filter(
					(sim) => !!sim?.defaultSim
				);
				if (defaultSku?.length) {
					if (defaultSku[0]?.simSku === selectedSku?.psimSku) {
						defaultSimType = "psim";
					} else if (defaultSku[0]?.simSku === selectedSku?.esimSku) {
						defaultSimType = "esim";
					}
				}
				if (preselectedSimType) {
					const simEnum = ["esim", "psim"];
					const simFilter = preselectedSimType?.toLowerCase();
					if (simEnum.includes(simFilter)) {
						defaultSimType = simFilter;
					}
				}
				setSelectedSimType(defaultSimType);
			} else {
				setSelectedSimType(selectedSku?.defaultSimType);
			}
			setPromoDetails(PDPUtil.getPromoDetails());
			PDPUtil.findSelectedPromo({
				selectedSku,
				isBogo,
				bogoPromoTypes,
				tradeInPromoEligible,
				onPromoSelect,
			});
			if (isSmartWatch) {
				const skuList = deviceDetails?.skulist?.filter(
					(i) =>
						i?.color === selectedSku?.color &&
						i?.caseSize === selectedSku?.caseSize &&
						i?.bandType === selectedSku?.bandType
				);
				const { colors, sizes } = PDPUtil.getUniqueWatchBands(skuList || []);
				setBandColorOptions(colors);
				setBandSizeOptions(
					sizes.sort((a, b) => {
						const optA = aemSortOrderData?.bandSize?.[a?.name] || 99;
						const optB = aemSortOrderData?.bandSize?.[b?.name] || 99;
						return optA - optB;
					})
				);
				setSelectedColor(selectedSku?.cssColor);
				setSelectedCaseSize(selectedSku?.caseSize);
				setSelectedBandType(selectedSku?.bandType);
				setSelectedBandColor(selectedSku?.bandColor);
				setSelectedBandSize(selectedSku?.bandSize);
				setImageSetUrl(`${selectedSku?.imageSet}`);
				setMemoryOptions([selectedSku]);
			}
			const isPricingOptions =
				selectedSku?.pricingDetails?.devicePayments?.length ||
				selectedSku?.pricingDetails?.zeroYear ||
				selectedSku?.pricingDetails?.twoYear;
			setIsPricingAvailable(!!isPricingOptions);
		}
	}, [selectedSku]);

	const getRetailPrice = (selectedSku) => {
		let retailPrice = deviceDetails?.skulist?.filter(
			(sku) => sku?.color === selectedSku?.color
		);
		return retailPrice?.[0]?.retailPrice || 0;
	};

	const getConfigSections = () => {
		return (
			<AccordionSection isBuss={props.buss}>
				<Accordion type="multi" topLine={true} bottomLine={true} surface="light">
					<>
						{selectedSku?.sorSkuType == "OCPO" && (
							<PDPAccordionSection
								opened={isShowPreOwned}
								setOpened={setIsShowPreOwned}
								titleText={t("device-pdp.deviceConditionLabel", {
									ns: "pdp",
								})}
								subTitleText={
									!outOfStockDevice ? (
										selectedSku?.color ||
											selectedSku?.capacity ||
											!selectedSku?.outOfStock ? (
											<>{selectedSku?.conditionShort}</>
										) : (
											<>{t("device-pdp.outOfStock1", { ns: "pdp" })}</>
										)
									) : (
										<>{t("device-pdp.outOfStock1", { ns: "pdp" })}</>
									)
								}
								dataTrack="ShowPreOwned"
							>
								<PreOwned preOwnedDeviceOptions={preOwnedDeviceOptions} />
							</PDPAccordionSection>
						)}
						{!!isSmartWatch && (
							<PDPAccordionSection
								opened={isShowCaseType}
								setOpened={setIsShowCaseType}
								titleText={t("device-pdp.caseType", { ns: "pdp" })}
								subTitleText={
									selectedCaseType || t("device-pdp.outOfStock1", { ns: "pdp" })
								}
								dataTrack="ShowCaseType"
								disable={caseTypeOptions?.length < 2}
							>
								<RadioBoxSection
									data={caseTypeOptions?.map((i) => {
										return {
											id: i?.caseMaterial,
											title: i?.caseMaterial,
											outOfStock: i?.outOfStock,
										};
									})}
									selected={selectedCaseType}
									onChange={(e) => {
										setSelectedCaseType(e);
									}}
									compactView={true}
								/>
							</PDPAccordionSection>
						)}
						{props.buss ?
							<>
								<div style={{ borderBottom: "0.0625rem solid #d8dada" }}></div>
								{/* <PDPAccordionSection
								// opened={isShowColor}
								setOpened={setIsShowColor}
								titleText={"Overview"}
								showSubTitleWhenOpened={true}
								subTitleText={productDetail?.data?.description}
								dataTrack="ShowColor"
								disable={true}></PDPAccordionSection> */}
								<PDPAccordionSection
									opened={isShowPlans}
									setOpened={setIsShowPlans}
									titleText={"Plan"}
									showSubTitleWhenOpened={true}
									subTitleText={selectedPlan?.name ?? ""}
									dataTrack="ShowColor"
									buss={props?.buss}
									disable={false}>
									<PdpPlanCard
										selectedPlanID={!!selectedPlan ? selectedPlan['id'] : ''}
										handleContinueClick={(id, modalData) => {
											const plansToFilter = productDetail?.data?.plans
											const selectedPlanDetails = plansToFilter.find(item => item.id === id)
											setSelectedPlans(selectedPlanDetails)
											if (!!selectedPlanDetails && !!selectedPlanDetails['pricingOptions'] && selectedPlanDetails['pricingOptions'].length && selectedPlanDetails['pricingOptions'].length === 1) {
												setSelectedFrequency(selectedPlanDetails['pricingOptions'][0]["priceType"])
											}
											if (modalData) setSelectedBussModal(modalData)
											setIsShowLicense(true);
											setIsShowPlans(false);
										}}
										planDetails={productDetail?.data?.planDetails}
										planCard={productDetail?.data?.productPlans}
										type=""
										frequency="MONTHLY"
									/>
								</PDPAccordionSection>
								<PDPAccordionSection
									opened={!!selectedPlan && Object.keys(selectedPlan).length && isShowLicense}
									setOpened={setIsShowLicense}
									titleText={"License type"}
									showSubTitleWhenOpened={true}
									buss={props?.buss}
									// subTitleText={productDetail?.data?.subHeading}
									dataTrack="ShowColor"
									disable={!(!!selectedPlan && Object.keys(selectedPlan).length)}>
									<PriceContainer
										valueSelected={selectedId}
										selectedValue={(val) => setSelectedFrequency(val)}
										selectedId={(val) => setSelectedId(val)}
										pricingDetails={!!selectedPlan && selectedPlan['pricingOptions']} />
								</PDPAccordionSection>

							</> :
							<>
								{fromAccessory ? (
									<AccessoriesColorWrap>
										<ProductTitle>{t("device-pdp.color", { ns: "pdp" })}</ProductTitle>
										<ProductSubTitle>{selectedSku?.color}</ProductSubTitle>
										<ColorOptions
											onColorChange={(color) => handleColorChange(color)}
											colorOptions={colorOptions}
										/>
									</AccessoriesColorWrap>
								) : (isBuss ?
									<PDPAccordionSection
										opened={isShowColor}
										setOpened={setIsShowColor}
										titleText={
											isSmartWatch
												? t("device-pdp.caseColor", { ns: "pdp" })
												: t("device-pdp.color", { ns: "pdp" })
										}
										showSubTitleWhenOpened={true}
										subTitleText={
											!outOfStockDevice
												? hoveredColor
													? hoveredColor
													: isSmartWatch && selectedSku?.caseColor
														? selectedSku?.caseColor
														: selectedSku?.color ||
															selectedSku?.capacity ||
															!selectedSku?.outOfStock
															? selectedSku?.color
																? selectedSku?.color
																: ""
															: t("device-pdp.outOfStock1", { ns: "pdp" })
												: t("device-pdp.outOfStock1", { ns: "pdp" })
										}
										dataTrack="ShowColor"
										disable={isSmartWatch && colorOptions?.length < 2}
									>
										<ColorSwatchSection>
											<ColorOptions
												colorOptions={colorOptions}
												showTopLine={isOneTalk}
												handleHoveredColor={(col) => {
													handleHoveredColor(col);
												}}
												isSmartWatch={isSmartWatch}
											/>
										</ColorSwatchSection>
									</PDPAccordionSection>
									: <></>)}
								{selectedSku?.psimCapable == "Y" && selectedSku?.esimCapable == "Y" && (
									<PDPAccordionSection
										opened={isShowSimTypes}
										setOpened={setShowSimTypes}
										titleText={t("device-pdp.simType", { ns: "pdp" })}
										subTitleText={
											selectedSku?.capacity && selectedSimType ? (
												<>
													{selectedSimType === "esim"
														? t("device-pdp.embeddedSIM", { ns: "pdp" })
														: t("device-pdp.physicalSimCard", { ns: "pdp" })}
												</>
											) : (
												<>{t("device-pdp.outOfStock1", { ns: "pdp" })}</>
											)
										}
										dataTrack="ShowCaseSize"
									>
										<SimTypes />
									</PDPAccordionSection>
								)}
								{!!isSmartWatch && (
									<>
										{/* BEGIN SMARTWATCH SELECTIONS */}
										<PDPAccordionSection
											opened={isShowCaseSize}
											setOpened={setIsShowCaseSize}
											titleText={t("device-pdp.caseSize", { ns: "pdp" })}
											subTitleText={
												selectedCaseSize || t("device-pdp.outOfStock1", { ns: "pdp" })
											}
											dataTrack="ShowCaseSize"
											disable={caseSizeOptions?.length < 2}
										>
											<RadioBoxSection
												data={caseSizeOptions?.map((i) => {
													return {
														id: i?.name,
														title: i?.name,
														subtitle: i?.desc,
														outOfStock: i?.outOfStock,
													};
												})}
												selected={selectedCaseSize}
												onChange={(e) => {
													setSelectedCaseSize(e);
												}}
											/>
										</PDPAccordionSection>
										<PDPAccordionSection
											opened={isShowBandType}
											setOpened={setIsShowBandType}
											titleText={t("device-pdp.bandType", { ns: "pdp" })}
											subTitleText={selectedBandType}
											dataTrack="ShowBandType"
											disable={bandTypeOptions?.length < 2}
										>
											<RadioBoxSection
												data={bandTypeOptions?.map((i) => {
													return {
														id: i?.name,
														title: i?.name,
														subtitle: i?.desc,
														outOfStock: i?.outOfStock,
													};
												})}
												selected={selectedBandType}
												onChange={(e) => {
													setSelectedBandType(e);
												}}
											/>
										</PDPAccordionSection>
										<PDPAccordionSection
											opened={isShowBandColor}
											setOpened={setIsShowBandColor}
											titleText={t("device-pdp.bandColor", { ns: "pdp" })}
											subTitleText={selectedBandColor}
											dataTrack="ShowBandColor"
											disable={bandColorOptions?.length < 2}
										>
											<RadioBoxSection
												data={bandColorOptions?.map((i) => {
													return {
														id: i?.name,
														title: i?.name,
														subtitle: i?.desc,
														outOfStock: i?.outOfStock,
													};
												})}
												selected={selectedBandColor}
												onChange={(e) => {
													setSelectedBandColor(e);
												}}
												compactView={true}
											/>
										</PDPAccordionSection>
										<PDPAccordionSection
											opened={isShowBandSize}
											setOpened={setIsShowBandSize}
											titleText={t("device-pdp.bandSize", { ns: "pdp" })}
											subTitleText={
												selectedBandSize || t("device-pdp.oneSize", { ns: "pdp" })
											}
											dataTrack="ShowBandSize"
											disable={bandSizeOptions?.length < 2}
										>
											<RadioBoxSection
												data={bandSizeOptions?.map((i) => {
													return {
														id: i?.name,
														title: i?.name || t("device-pdp.oneSize", { ns: "pdp" }),
														subtitle: i?.desc,
														outOfStock: i?.outOfStock,
													};
												})}
												selected={selectedBandSize}
												onChange={(e) => {
													setSelectedBandSize(e);
												}}
											/>
										</PDPAccordionSection>
										{/* END SMARTWATCH SELECTIONS */}
									</>
								)}
								{fromAccessory ? (
									<></>
								) : (
									<PDPAccordionSection
										opened={isShowStorage}
										setOpened={setIsShowStorage}
										titleText={
											isOneTalk || isSmartWatch
												? t("device-pdp.price", { ns: "pdp" })
												: t("device-pdp.storageAndPrice", { ns: "pdp" })
										}
										subTitleText={PDPUtil.getPriceSubtitle({
											selectedSku,
											selectedPayment,
											isBogo,
											tradinPriceForSubtitle,
											fromAccessory,
											outOfStockDevice,
											outOfStockText: t("device-pdp.outOfStock1", {
												ns: "pdp",
											}),
										})}
										dataTrack="ShowStorage"
									>
										{isPricingAvailable ? (
											<StorageAndPrice
												isBogo={isBogo}
												tradeInForSubtitle={(price) => {
													if (tradinPriceForSubtitle !== price) handleTradeInPrice(price);
												}}
												tradinPrice={tradinPriceForSubtitle}
												editPackageDeviceSku={editPackageDeviceSku}
												isPlanFirstFlow={
													appData?.flow === FLOWS.PLANFIRST || appData?.flow === FLOWS.TYS
												}
											/>
										) : (
											<></>
										)}

									</PDPAccordionSection>
								)}
								{offersList?.length &&
									offersList.filter((o) =>
										o?.promoMessages?.some((i) => i?.placement === "PDP" && i?.badgeText)
									).length ? (
									<PDPAccordionSection
										opened={isShowOffers || showOfferAccordion}
										setOpened={setIsShowOffers}
										titleText={t("device-pdp.moreWaysToSavingOptions.morewaystosave", {
											ns: "pdp",
										})}
										subTitleText={
											(isOneTalk && selectedSku?.color) || selectedSku?.capacity ? (
												<>
													{offersList?.length == 1 ? (
														<>
															{" "}
															{t("device-pdp.offersAvailable_one", {
																count: offersList?.length,
																ns: "pdp",
															})}
														</>
													) : (
														<>
															{" "}
															{t("device-pdp.offersAvailable_other", {
																count: offersList?.length,
																ns: "pdp",
															})}
														</>
													)}
												</>
											) : (
												<>{t("device-pdp.outOfStock1", { ns: "pdp" })}</>
											)
										}
										showSubTitleWhenOpened={false}
										dataTrack="ShowOffers"
									>
										<OfferSection
											isBogoFlag={isBogoFlag}
											editPackagePackageId={editPackagePackageId}
											setShowAccordion={setShowOfferAccordion}
										/>
									</PDPAccordionSection>
								) : (
									<AccordionItem></AccordionItem>
								)}
								{fromAccessory ? (
									<AccessoryPriceWrapper>
										<ProductTitlePrice>
											<Title bold size="medium">
												{t("device-pdp.price", { ns: "pdp" })}
											</Title>
										</ProductTitlePrice>
										<MarginSpacerS />
										<Body bold size="large">
											{`$${selectedSku?.retailPrice || getRetailPrice(selectedSku)}`}
										</Body>
										<MarginSpacerL />
									</AccessoryPriceWrapper>
								) : (
									""
								)}
							</>}
						<MarginSpacerL />
						<QuantitySection isOneTalk={isOneTalk}>
							<Quantity
								isoutOfStock={isoutOfStock}
								buss={props.buss}
								addToCartFn={props.addToCart}
								isEditPackageFlow={isEditPackageFlow}
								maxDeviceQuantity={10}
								showProp65Warning={isOneTalk}
								hideQuantityDropdown={isPlanFirstFlow || isBogo}
								isOneTalk={isOneTalk}
								isPlanFirstFlow={isPlanFirstFlow}
								fromAccessory={fromAccessory}
								editPackagePackageId={editPackagePackageId}
								editPackageGrpId={props.editPackageGrpId}
								deviceDetails={deviceDetails}
								isPricingAvailable={isPricingAvailable}
								selectedFrequency={selectedFrequency}
								addToCartBuss={addToCartBuss}
							/>
						</QuantitySection>
						{!(isOneTalk || fromAccessory || props.buss) ? (
							<ShippingOptions />
						) : (
							<div className="no-shipping-options"></div>
						)}
					</>
				</Accordion>
			</AccordionSection>
		);
	};

	const onPromoSelect = (promo) => {
		setSelectedPromo(promo);
		if (bogoPromoTypes.includes(promo?.promoType)) {
			bogoSelected(promo);
		} else if (!promo) {
			bogoRemoved();
		}
	};
	const addToCartBuss = () => {
		dispatch(setIsAddToCartCompleted(false))
		dispatch(setMarketplaceAddtoCartResp({}))
		const getPrice = selectedPlan['pricingOptions'].find(data => data['id'] === selectedId)
		const requestBody = {
			"allowMixedCart": true,
			"cartMeta": {
				"shoppingPath": "prospect",
				"channel": "react",
				"clientId": appData?.widgetInputParams?.clientName?.value ?? "",
				"mfeShoppingPath": window?.mbtGlobal?.shoppingPath
			},
			"flow": "prospect",
			"lines": [
				{
					"packageId": selectedPlan['id'],
					"packageType": "IOT",
					"priceType": selectedFrequency,
					"operation": !!bussData?.selectedBussProduct ? "UPDATE" : "ADD",
					"productType": String(String(props?.selectedBUSS).split(' ')[0]).toUpperCase() ?? "",
					"quantity": selectedQuantity,
					"marketPlaceProduct": true,
					"name": selectedPlan['name'],
					"shortDescription": selectedPlan['name'],
					"price": getPrice['price'],
					"pricePlanCode": getPrice['id'],
					characteristics: selectedBussModalProducts
				}
			]
		}
		if (appData?.widgetInputParams?.flow?.value?.toLowerCase() === 'assisted') {
			dispatch(setClientId("optix"));
		}
		dispatch(marketplaceAddToCart({ ...requestBody }));
		addToBussClicked(true)
		dispatch(setSelectedBussProduct(undefined))
	}

	return (
		<PDPContextProvider
			value={{
				product,
				memoryOptions,
				selectedColor: { selectedColor, setSelectedColor },
				setSelectedConditionShort: {
					selectedConditionShort,
					setSelectedConditionShort,
				},
				selectedStorage: { selectedStorage, setSelectedStorage },
				selectedSimType: { selectedSimType, setSelectedSimType },
				selectedSku: { selectedSku, setSelectedSku },
				selectedPayment: { selectedPayment, setSelectedPayment },
				selectedQuantity: { selectedQuantity, setSelectedQuantity },
				selectedPromo: { selectedPromo, setSelectedPromo: onPromoSelect },
				addToAllLines: { addToAllLines, setAddToAllLines },
				isOneTalk,
				isDeskphone,
				isBogo,
				flow: appData.flow,
				isChangeDevice: planFirstData?.isChangeDevice,
				isShopPhoneDevice: planFirstData?.isShopPhoneDevice,
				combinePricing,
				tradeInValueInfo: { tradeInValueInfo, handleTradeInValueInfo },
				pack,
				averagRating,
				sessionCartData,
				pegaRecommendations,
				compareDeviceName,
				compareSelectedSku,
				isSmartWatch,
				selectedCaseType: { selectedCaseType, setSelectedCaseType },
				selectedCaseSize: { selectedCaseSize, setSelectedCaseSize },
				selectedBandType: { selectedBandType, setSelectedBandType },
				selectedBandColor: { selectedBandColor, setSelectedBandColor },
				selectedBandSize: { selectedBandSize, setSelectedBandSize },
			}}
		>
			{bussData?.loading && <SpinnerComponent disabled={false} />}
			<MarginSpacerL />
			<PhoneDetails ref={overviewRef}>
				<Grid
					colGutter={false}
					colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
					rowGutter="1rem"
				>
					<Row className="pdp-details-section">
						<Col colSizes={{ mobile: 4, tablet: 12, desktop: 6 }}>
							<Grid>
								<Row className="pdp-details-left-wrapper">
									<Col
										colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
										className="pdp grid-container"
									>
										<PDPHeaderInfo>
											<div className="header">
												{!!isOneTalk && <MarginSpacerL />}
												<Title bold size="medium">
													{`${props.buss ? productDetail?.data?.name : (deviceDetails?.brandName + '' + deviceDetails?.name)}`}
												</Title>
												<MarginSpacerL />
												<Body size="large">{isEmpty(productDetail?.data?.description) ? "" : productDetail?.data?.description}</Body>
											</div>
											<PriceInfo
												reviewCount={
													(props?.reviews?.Includes?.Products &&
														props?.reviews?.Includes?.Products[
															props?.reviews?.Includes?.ProductsOrder[0]
														]?.TotalReviewCount) ||
													0
												}
												showDescription={isOneTalk}
											/>
										</PDPHeaderInfo>
										<Suspense fallback={<></>}>
											{/* {props.buss ?
                                            <div className="image-container buss-img">
                                            <img src={process.env.PROSPECT_WIDGETS_APP_ASSESTS_BASE_URL + productDetail?.data?.imageUrl} alt="PDP Image"/>
                                            </div>
                                            : */}
											<PDPCarousel buss={props.buss} bussImages={[`${bussIcons[productDetail?.data?.name?.toUpperCase()?.trim()]}`, `${bussIcons[productDetail?.data?.name?.toUpperCase()?.trim()]}`]} imageSetUrl={imageSetUrl} isAccessories={false} />
										</Suspense>
									</Col>
								</Row>
							</Grid>
						</Col>
						<Col colSizes={{ mobile: 4, tablet: 12, desktop: 6 }}>
							<Suspense fallback={<></>}>{getConfigSections()}</Suspense>
						</Col>
					</Row>
					<Row className="paddingTabs">
						<Col
							className=""
							colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
						>
							{props?.buss ? <>
								<TabsSection pageTemplate={features ?? productDetail?.data} />
							</>
								: fromAccessory ? (
									props.detailsTabFromAccessory
								) : (
									<Suspense fallback={<></>}>
										<DetailTabs reviews={props?.reviews} />
									</Suspense>
								)}
						</Col>
					</Row>
					<Row className="paddingTabs">
						{sessionCartData?.cart?.cartId && (
							<Col className="session-cart-id">
								<SessionCartId sessionCartData={sessionCartData} isCartId={true} />
							</Col>
						)}
					</Row>
				</Grid>
			</PhoneDetails>
		</PDPContextProvider>
	);
};

export default ProductDetails;
