import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalTitle, ModalBody, ModalFooter } from "@vds3/modals";
import { Title, Body } from "@vds3/typography";
import styled from "styled-components";
import { MarginSpacerM } from "../styleTag";

const CAWarningModal = styled(Modal)`
	height: auto;
	width: 560px;
	min-height: auto;
	outline: none important;
	border-radius: 8px;

	> div:first-child {
		overflow: hidden;
	}
	.bold {
		font-weight: bold !important;
	}
	.font-14 {
		font-size: 1rem !important;
	}
	a {
		color: #000 !important;
	}
	path {
		stroke-width: 1.5;
	}
	@media only screen and (max-width: 767px) {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 95vw;
		> div:first-child {
			padding: 2px;
		}
	}
`;
const CAWarningModalBody = styled(ModalBody)`
	padding: 0 0px 0;
	outline: none !important;
	height: auto;
	span {
		line-height: 36px;
	}
`;

const ModalHeadingTag = styled.div`
	font-weight: 700;
	font-size: 32px;
	line-height: 36px;
	flex: none;
	align-self: stretch;
	flex-grow: 0;
`;

const CalifProp65Modal = (props) => {
	const { t, i18n } = useTranslation(["app"]);
	return (
		<CAWarningModal opened={props.opened} onOpenedChange={props.onOpenedChange}>
			<ModalTitle>
				<Title size="large">
					<ModalHeadingTag
						dangerouslySetInnerHTML={{
							__html: `${t("app.common.prop65WarningModal.modalHeading", {
								ns: "app",
							})}`,
						}}
					></ModalHeadingTag>
				</Title>
				<MarginSpacerM />
			</ModalTitle>
			<CAWarningModalBody>
				<div>
					<Body className="contentHeading" primitive="span" size="large">
						<span
							dangerouslySetInnerHTML={{
								__html: `${t("app.common.prop65WarningModal.modalSubHeading", {
									ns: "app",
								})}`,
							}}
						></span>
					</Body>
				</div>
			</CAWarningModalBody>
			<ModalFooter
				buttonData={{
					primary: {
						width: "75%",
						children: "Close",
						onClick: props.onOpenedChange,
					},
				}}
			/>
		</CAWarningModal>
	);
};

export default CalifProp65Modal;
