// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.u-paddingTopMedium {
  padding-top: 0.875rem !important;
}

.promos{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: initial !important;
  -webkit-align-items:initial !important;
  -webkit-box-align: initial !important;

}
.daBYfm .promos > div {
  width: 48%;
  padding: 0.75rem;
  box-sizing: border-box;
}
.strikethrough {
  text-decoration: line-through;
}
.buss-img{
  width: 70%;
  margin-top: 70px;
  /* height: 40vh; */
}
.buss-img > img{
  width: 375px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Mobility/components/MobilityProductDetails/ProductDetailsNew.css"],"names":[],"mappings":";AACA;EACE,gCAAgC;AAClC;;AAEA;EACE,WAAW;EACX,aAAa;EACb,eAAe;EACf,8BAA8B;EAC9B,+BAA+B;EAC/B,sCAAsC;EACtC,qCAAqC;;AAEvC;AACA;EACE,UAAU;EACV,gBAAgB;EAChB,sBAAsB;AACxB;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,UAAU;EACV,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,YAAY;AACd","sourcesContent":["\n.u-paddingTopMedium {\n  padding-top: 0.875rem !important;\n}\n\n.promos{\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  align-items: initial !important;\n  -webkit-align-items:initial !important;\n  -webkit-box-align: initial !important;\n\n}\n.daBYfm .promos > div {\n  width: 48%;\n  padding: 0.75rem;\n  box-sizing: border-box;\n}\n.strikethrough {\n  text-decoration: line-through;\n}\n.buss-img{\n  width: 70%;\n  margin-top: 70px;\n  /* height: 40vh; */\n}\n.buss-img > img{\n  width: 375px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
