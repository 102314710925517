import styled from "styled-components";
import { Line } from "@vds3/lines";

export const StyledTileContainer = styled.div`
  margin-top: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  div > div > span {
    width: 100%;
  }
`;

export const TileDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 60px;
  justify-content: space-between;
  @media only screen and (max-width: 545px){
    gap: unset;
  }
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 2rem;
  flex-grow: 1;
  width: 310px;
  @media (max-width: 545px) {
    flex-direction: column;
    gap: 1rem;
    width: auto;
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-grow: 1;
  width: 280px;
  @media (max-width: 545px) {
    width: 100%;
  }
`;

export const LeftTradeInSection = styled(LeftSection)`
  flex-direction: column;
  gap: 0;
  width: 412px;
  @media (max-width: 545px) {
    width: 100%;
    gap: 1rem;
  }
`;

export const RightTradeInSection = styled(RightSection)`
  @media (min-width: 576px) {
    // max-width: 285px;
    // padding-left: 110px;
  }
`;

export const SeparatorLine = styled(Line)`
  margin: 1.5rem 0;
`;

export const LeftDivSection = styled.div`
  width: auto;
  @media only screen and (max-width: 545px) {
    width: 100%;
  }
`;

export const PackageDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

export const PackageDetailDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0.25rem;
  max-width: 180px;
`;

export const PackagePriceDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  text-align: end;
`;

export const PackageInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 280px;
  @media (max-width: 545px) {
    width: unset;
  }
`;

export const PackageEyebrowText = styled.div`
  p{
    font-size: 0.72rem;
  }
`;

export const TradeInDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-top: ${props => props?.marginTop ?? 0};
  max-width: ${props => props?.maxWidth ?? "unset"};
  @media (max-width: 545px) {
    margin-top: ${(props) => props?.marginTopMobile ?? 0};
    max-width: unset;
  }
`;

export const ByodContainer = styled.div`
  width: ${({width}) => width}px;
  height: ${({height}) => height}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({center = true}) => center ? 'center' : 'flex-start'};
  @media only screen and (max-width: 545px){
    width: auto;
  }
`;

 export const ByodImg = styled.div`
  width: 50px;
  height: 100px;
  border-style: solid;
  border-radius: 9px;
  border-width: 2px;
  border-color: #A7A7A7;
`;

 export const ByorImg = styled.div`
  width: 78px;
  height: 78px;
  border-style: solid;
  border-radius: 4px;
  border-width: 2px;
  border-color: #A7A7A7;
`;
export const OosDiv = styled.div`
  width: 45% ;  
  background-color: #B95319;
  border-radius: 1px;
`;
 export const ByorSpan = styled.span`
  width: 40px !important;  
  height: 3px;
  background-color: #A7A7A7;
  border-radius: 1px;
`;
