import { createSlice, createAction } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  checkCartInventoryStatusResponse: {},
  completeOrderWithoutPayment: {},
  oosDevices: [],
  processEcpdDetailsResponse: {},
  redirectToConfirmation: false,
  redirectToPayment: false,
  redirectToFallout: false,
  updateInstallmentContractDetailsResponse: {},
  prospectPDF: {},
  userIdRes:{},
  passwordRes:{},
  securityQueRes:[],
  registraionRes: {},
};

const ReviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    resetReviewSlice: (state, action) => {
      return initialState;
    },
    setLoading: (state, action) => {
      state["loading"] = action.payload;
    },
    setCheckCartInventoryStatusResponse: (state, action) => {
      state["checkCartInventoryStatusResponse"] = action.payload;
    },
    setCompleteOrderWithoutPaymentResponse: (state, action) => {
      state["completeOrderWithoutPaymentResponse"] = action.payload;
    },
    setOosDevices: (state, action) => {
      state["oosDevices"] = action.payload;
    },
    setProcessEcpdDetailsResponse: (state, action) => {
      state["processEcpdDetailsResponse"] = action.payload;
    },
    setRedirectToConfirmation: (state, action) => {
      state["redirectToConfirmation"] = action.payload;
    },
    setRedirectToPayment: (state, action) => {
      state["redirectToPayment"] = action.payload;
    },
    setRedirectToFallout: (state, action) => {
      state["redirectToFallout"] = action.payload;
    },
    setUpdateInstallmentContractDetailsResponse: (state, action) => {
      state["updateInstallmentContractDetailsResponse"] = action.payload;
    },
    setOrderSuccessDetails: (state, action) => {
      state["orderSuccessDetails"] = action.payload;
    },
    setUcOrderSuccessDetails: (state, action) => {
      state["UnifiedCartorderSuccessDetails"] = action.payload;
    },
    setProspectCartPDF: (state, action) => {
      state["prospectPDF"] = action.payload;
    },
    setAccountUserIDRes: (state, action) => {
      state["userIdRes"] = action.payload;
    },
    setAccountPasswordRes: (state, action) => {
      state["passwordRes"] = action.payload;
    },
    setAccountSecurityQueRes: (state, action) => {
      state["securityQueRes"] = action.payload;
    },
    setRegisterRes: (state, action) => {
      state["registraionRes"] = action.payload;
    },
  },
});

export const reducer = ReviewSlice.reducer;
export const postCheckCartInventoryStatus = createAction(
  "review/postCheckCartInventoryStatus"
);
export const postProcessEcpdDetails = createAction(
  "review/postProcessEcpdDetails"
);
export const getProspectCartPDF = createAction("review/getProspectCartPDF");
export const getValidateUserId = createAction("review/getValidateUserId");
export const getValidateAccountPassword = createAction("review/getValidateAccountPassword");
export const getSecurityQuestions = createAction("review/getSecurityQuestions");
export const sendAccountCreationReq = createAction("review/sendAccountCreationReq");
export const postUpdateInstallmentContractDetails = createAction(
  "review/postUpdateInstallmentContractDetails"
);
export const {
  resetReviewSlice,
  setLoading,
  setCheckCartInventoryStatusResponse,
  setCompleteOrderWithoutPaymentResponse,
  setOosDevices,
  setProcessEcpdDetailsResponse,
  setRedirectToConfirmation,
  setRedirectToPayment,
  setRedirectToFallout,
  setUpdateInstallmentContractDetailsResponse,
  setOrderSuccessDetails,
  setProspectCartPDF,
  setUcOrderSuccessDetails,
  setAccountUserIDRes,
  setAccountPasswordRes,
  setAccountSecurityQueRes,
  setRegisterRes,
} = ReviewSlice.actions;
