import { createSlice, createAction } from "@reduxjs/toolkit";
import { sortSectionEntries } from "common/components/VerticalProgress/util";

const initialState = {
  sectionsConfig: {},
  checkoutLoading: false,
  checkoutSections: {},
  completedSection: '',
  saveSectionsConfig: {},
  completedSectionType:''
};

const LowerFunnelSlice = createSlice({
  name: "LowerFunnel",
  initialState,
  reducers: {
    setSectionsConfig: (state, action) => {
      state.sectionsConfig = action.payload;
    },
    setSaveSectionsConfig: (state, action) => {
      state.saveSectionsConfig = action.payload;
    },
    resetLowerFunnelSlice: () => {
			return initialState;
		},
    setSectionModifier: (state, action) => {
      const { sectionId, modifier } = action.payload || {};
      state.sectionsConfig[sectionId].stateModifier = modifier;
    },

    setCheckoutLoading: (state, action) => {
      state.checkoutLoading = action.payload;
    },
    setCheckoutSections: (state, action) => {
      state.checkoutSections = action.payload;
    },

    setEditableSections: (state, action) => {
      const { sections, isEditable } = action.payload || {};
      // clone sections config
      const newConfig = { ...state.sectionsConfig };

      for (const key in newConfig) {
        const subsectionList = newConfig[key].subsections;
        for (const subKey in subsectionList) {
          if (sections.includes(subKey)) {
            subsectionList[subKey].isEditable = isEditable;
          }
          const comboSections = subsectionList[subKey].comboSections || {};
          for (const combokey in comboSections) {
            if (sections.includes(combokey)) {
              comboSections[combokey].isEditable = isEditable;
            }
          }
        }
      }
      state = { ...state, sectionsConfig: newConfig };
    },

    setEditSection: (state, action) => {
      const { sectionId, subsectionId, combosecId } = action.payload || {};
      // clone sections config
      const newConfig = { ...state.sectionsConfig };

      for (const key in newConfig) {
        // if(newConfig[key].isActive) {
        newConfig[key].isActive = false;
        const subsectionList = newConfig[key].subsections;
        for (const subKey in subsectionList) {
          // if(subsectionList[subKey].isActive) {
          subsectionList[subKey].isActive = false;

          // }
          const comboSections = subsectionList[subKey].comboSections || {};
          for (const combokey in comboSections) {
            comboSections[combokey].isActive = false
          }
        }
        // }
      }

      newConfig[sectionId].isActive = true;
      newConfig[sectionId].isComplete = true;

      const newSubsection = newConfig[sectionId]?.subsections?.[subsectionId];
      if (newSubsection) {
        newSubsection.isActive = true;
        newSubsection.isComplete = true;
      }
      const newComboSubsection = newSubsection?.comboSections?.[combosecId] || {};
      if (newComboSubsection) {
        newComboSubsection.isActive = true;
        newComboSubsection.isComplete = true;

      }
      state = { ...state, sectionsConfig: newConfig };
    },

    setSectionComplete: (state, action) => {
      const { sectionId, subsectionId, combosecId } = action.payload || {};
      // clone sections config
      const newConfig = { ...state.sectionsConfig };
      // get the list of sections in correct order
      const section = newConfig?.[sectionId];
      const sectionList = Object.entries(newConfig).sort(sortSectionEntries);
      const sectionIndex = sectionList.findIndex(
        ([id, _val]) => id === sectionId
      );
      // get the list of subsections in correct order
      const subsection = section?.subsections?.[subsectionId];
      const subsectionList = Object.entries(section.subsections || {}).sort(
        sortSectionEntries
      );
      const subsectionIndex = subsectionList.findIndex(
        ([id, _val]) => id === subsectionId
      );
      let subsectionComplete = false,
        sectionComplete = false;
      // check if this subsection is a combo section
      const combosecList =
        combosecId &&
          subsection?.comboSections &&
          Object.keys(subsection.comboSections)?.length
          ? Object.entries(subsection.comboSections).sort(sortSectionEntries)
          : null;
      const combosectionIndex = combosecList
        ? combosecList.findIndex(([id, _val]) => id === combosecId)
        : -1;
      if (combosectionIndex >= 0) {
        // if combo section, mark current combo subsection complete
        const combosection = subsection?.comboSections?.[combosecId];
        combosection.isComplete = true;
        combosection.isActive = false;
        if (combosectionIndex + 1 < combosecList?.length) {
          // mark the next combo subsection as active
          const nextCombosectionId = combosecList[combosectionIndex + 1][0];
          const nextCombosection =
            subsection?.comboSections?.[nextCombosectionId];
          nextCombosection.isActive = true;
        } else {
          // last combo subsection is complete, so the subsection is complete
          subsectionComplete = true;
        }
      }
      // if last subsection of combo section is complete or it is a standalone subsection,
      // then mark the subsection complete
      if (subsection && (combosectionIndex < 0 || subsectionComplete)) {
        subsection.isComplete = true;
        subsection.isActive = false;
        if (subsectionIndex + 1 < subsectionList.length) {
          // mark the next subsection as active
          const nextSubsectionId = subsectionList[subsectionIndex + 1][0];
          const nextSubsection = section.subsections?.[nextSubsectionId];
          nextSubsection.isActive = true;
        } else {
          // last subsection of the section is complete, so the section is complete
          sectionComplete = true;
        }
      }
      // if last subsection of section is complete or if section is a standalone component,
      // then mark the section complete
      if (!subsection || sectionComplete) {
        section.isComplete = true;
        section.isActive = false;
        if (sectionIndex + 1 < sectionList.length) {
          // activate the next section
          const nextSectionId = sectionList[sectionIndex + 1][0];
          const nextSection = newConfig?.[nextSectionId];
          nextSection.isActive = true;
          if (
            nextSection.subsections &&
            typeof nextSection.subsections === "object"
          ) {
            // activate the first subsection of next section
            const nextSubsectionsList = Object.entries(
              nextSection.subsections
            ).sort(sortSectionEntries);
            const nextSubsectionId = nextSubsectionsList[0][0];
            const nextSubsection = nextSection.subsections?.[nextSubsectionId];
            nextSubsection.isActive = true;
            if (
              nextSubsection.comboSections &&
              typeof nextSubsection.comboSections === "object"
            ) {
              // activate the first subsection of combo subsection if necessary
              const nextCombosectionList = Object.entries(
                nextSubsection.comboSections
              ).sort(sortSectionEntries);
              const nextCombosectionId = nextCombosectionList[0][0];
              const nextCombosection =
                nextSubsection.comboSections?.[nextCombosectionId];
              nextCombosection.isActive = true;
            }
          }
        }
      }
      // return new state with updated section config object
      state = { ...state, sectionsConfig: newConfig };
    },


    setSectionInProgress: (state, action) => {
      const { sectionId, subsectionId, isInProgress } = action.payload || {};
      // clone sections config
      const newConfig = { ...state.sectionsConfig };
      const section = newConfig?.[sectionId];
      const subsection = section?.subsections?.[subsectionId];
      if (subsection && subsection.isActive && section.isActive && isInProgress) {
        subsection.isInProgress = true;
      } else {
        subsection.isInProgress = false;
      }
      // return new state with updated section config object
      state = { ...state, sectionsConfig: newConfig };
    },


    //for editing a section on different sceanrios
    setSectionRemoveComplete: (state, action) => {
      const { sectionId, subsectionId, combosecId } = action.payload || {};
      // clone sections config
      const newConfig = { ...state.sectionsConfig };

      // get the list of sections in correct order
      const section = newConfig?.[sectionId];
      const sectionList = Object.entries(newConfig).sort(sortSectionEntries);
      const sectionIndex = sectionList.findIndex(
        ([id, _val]) => id === sectionId
      );
      // get the list of subsections in correct order
      const subsection = section?.subsections?.[subsectionId];
      const subsectionList = Object.entries(section.subsections || {}).sort(
        sortSectionEntries
      );
      const subsectionIndex = subsectionList.findIndex(
        ([id, _val]) => id === subsectionId
      );
      let subsectionComplete = false,
        sectionComplete = false;
      // check if this subsection is a combo section
      const combosecList =
        combosecId &&
          subsection?.comboSections &&
          Object.keys(subsection.comboSections)?.length
          ? Object.entries(subsection.comboSections).sort(sortSectionEntries)
          : null;
      const combosectionIndex = combosecList
        ? combosecList.findIndex(([id, _val]) => id === combosecId)
        : -1;
      if (combosectionIndex >= 0) {
        // if combo section, mark current combo subsection complete
        const combosection = subsection?.comboSections?.[combosecId];
        combosection.isComplete = true;
        combosection.isActive = false;
        if (combosectionIndex + 1 < combosecList?.length) {
          // mark the next combo subsection as active
          const nextCombosectionId = combosecList[combosectionIndex + 1][0];
          const nextCombosection =
            subsection?.comboSections?.[nextCombosectionId];
          nextCombosection.isActive = true;
        } else {
          // last combo subsection is complete, so the subsection is complete
          subsectionComplete = true;
        }
      }
      // if last subsection of combo section is complete or it is a standalone subsection,
      // then mark the subsection complete
      if (subsection && (combosectionIndex < 0 || subsectionComplete)) {
        subsection.isComplete = true;
        subsection.isActive = false;
        if (subsectionIndex + 1 < subsectionList.length) {
          // mark the next subsection as active
          const nextSubsectionId = subsectionList[subsectionIndex + 1][0];
          const nextSubsection = section.subsections?.[nextSubsectionId];
          nextSubsection.isActive = true;
        } else {
          // last subsection of the section is complete, so the section is complete
          sectionComplete = true;
        }
      }
      // if last subsection of section is complete or if section is a standalone component,
      // then mark the section complete
      if (!subsection || sectionComplete) {
        section.isComplete = true;
        section.isActive = false;
        subsection.isEditable = false;
        if (sectionIndex + 1 < sectionList.length) {
          // activate the next section
          const nextSectionId = sectionList[sectionIndex + 1][0];
          const nextSection = newConfig?.[nextSectionId];
          nextSection.isActive = true;
          if (
            nextSection.subsections &&
            typeof nextSection.subsections === "object"
          ) {
            // activate the first subsection of next section
            const nextSubsectionsList = Object.entries(
              nextSection.subsections
            ).sort(sortSectionEntries);
            const nextSubsectionId = nextSubsectionsList[0][0];
            const nextSubsection = nextSection.subsections?.[nextSubsectionId];
            nextSubsection.isActive = true;
            if (
              nextSubsection.comboSections &&
              typeof nextSubsection.comboSections === "object"
            ) {
              // activate the first subsection of combo subsection if necessary
              const nextCombosectionList = Object.entries(
                nextSubsection.comboSections
              ).sort(sortSectionEntries);
              const nextCombosectionId = nextCombosectionList[0][0];
              const nextCombosection =
                nextSubsection.comboSections?.[nextCombosectionId];
              nextCombosection.isActive = true;
            }
          }
        }
      }
      // return new state with updated section config object
      state = { ...state, sectionsConfig: newConfig };
    },
    setCompletedSection: (state, action) => {
      state.completedSection = action.payload
    },
    setInProgressSection: (state, action) => {
      state.inProgressSection = action.payload
    },
    setComBoSectionInProgress: (state, action) => {
      state.comboSectionInProgress = action.payload
    },
    setCurrentOrder:(state, action) => {
      state.currentOrder = action.payload
    },
    completedSectionType : (state, action) => {
      state.completedSectionType = action.payload
    },
    setShoppingCartPageRevisited : (state,action) => {
      state.shoppingCartPageRevisited = action.payload
    },
    setShoppingCartPageRevisitedLower : (state,action) => {
      state.shoppingCartPageRevisitedLower = action.payload
    },
    openPaymentSection:(state,action) => {
      let sectionConfig = {...state.sectionsConfig}
      sectionConfig['ShippingAndPayment']['subsections']['payment']['isActive'] = true
      sectionConfig['ShippingAndPayment']['subsections']['payment']['isComplete'] = false
      state = { ...state, sectionsConfig: sectionConfig };
    },
  }
});

export const reducer = LowerFunnelSlice.reducer;

export const getCheckoutSections = createAction("LowerFunnel/getCheckoutSections");
export const { setShoppingCartPageRevisitedLower, setShoppingCartPageRevisited, setSectionsConfig, setSectionModifier, setSectionComplete, setSectionInProgress, setSectionRemoveComplete, setCheckoutSections, setCheckoutLoading, setCompletedSection,setInProgressSection,setComBoSectionInProgress,completedSectionType, setEditSection, setSaveSectionsConfig, setEditableSections,openPaymentSection,setCurrentOrder, resetLowerFunnelSlice } =
  LowerFunnelSlice.actions;
