import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Body } from "@vds3/typography";
import { TextLink, Button } from "@vds3/buttons";
import { Input } from "@vds3/inputs";
import { TrailingTooltip } from "@vds3/tooltips";
import {
  MarginSpacerXS,
  MarginSpacerXL,
  MarginSpacerL,
} from "common/components/styleTag";

const LineItemPriceGridContainer = styled.div`
  display: grid;
  grid-template-columns: [name] auto [price] ${({ byod }) =>
      byod ? "auto" : "100px"};
  row-gap: ${({ rowGap }) => rowGap ?? "0.75rem"};
`;

const LineItemOfferGridContainer = styled.div`
  display: grid;
  grid-template-columns: [name] auto [price] ${({ byod }) =>
      byod ? "auto" : "100px"};
  row-gap: 0.75rem;

  @media only screen and (max-width: 545px) {
    grid-template-columns: [name] auto;
  }
`;

export const PriceGridSectionContainer = styled(LineItemPriceGridContainer)`
  row-gap: 0.25rem;
`;

const LineItemPriceGridTitle = styled.div`
  margin-bottom: 4px;
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: 0;`}
`;

const LineItemName = styled.div`
  grid-column-start: name;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`}
  @media (max-width: 545px) {
    grid-column-end: ${({ hasPrice }) => !hasPrice && "span 1"};
  }
`;

const LineItemPrice = styled.div`
  grid-column-start: price;
  justify-self: end;
`;

const EACLineItemPrice = styled.div`
  grid-column-start: price;
  justify-self: end;
`;

const ZipCodeFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 229px;
`;

const ZipCodeButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  button {
    margin-right: 1rem;
  }
  a {
    align-self: center;
  }
`;

export const LineItemPriceGrid = ({
  title,
  children,
  isOffers = false,
  byod = false,
  rowGap = false,
}) => {
  return (
    <>
      {!!title && (
        <LineItemPriceGridTitle>
          <Body size="small" color="#6F7171">
            {title}
          </Body>
        </LineItemPriceGridTitle>
      )}
      {isOffers ? (
        <LineItemOfferGridContainer byod={byod} rowGap={rowGap}>
          {children}
        </LineItemOfferGridContainer>
      ) : (
        <LineItemPriceGridContainer byod={byod} rowGap={rowGap}>
          {children}
        </LineItemPriceGridContainer>
      )}
    </>
  );
};

export const LineItemPriceGridSection = ({ title, children, letterSpacing = true }) => {
  return (
    <div style={{ gridColumnStart: "span 2" }}>
      {!!title && (
        <LineItemPriceGridTitle letterSpacing={!letterSpacing}>
          <Body size="small" color="#6F7171">
            {title}
          </Body>
        </LineItemPriceGridTitle>
      )}
      <PriceGridSectionContainer>{children}</PriceGridSectionContainer>
    </div>
  );
};

export const LineItem = ({
  displayName,
  price,
  subText,
  subTextSize = "large",
  subTextColor = "#6F7171",
  bold = false,
  maxWidth = null,
  eacItem
}) => {
  return (
    <>
      <LineItemName maxWidth={maxWidth} hasPrice={!!price}>
        <Body size="large" bold={bold}>
          {displayName ?? ""}
        </Body>
        {!!subText && (
          <>
            <MarginSpacerXS />
            <Body size={subTextSize} color={subTextColor}>
              {subText}
            </Body>
          </>
        )}
      </LineItemName>
      { eacItem ? <EACLineItemPrice>
        <Body size="large" bold={bold}>
          {price ?? ""}
        </Body>
      </EACLineItemPrice> :
      <LineItemPrice>
        <Body size="large" bold={bold}>
          {price ?? ""}
        </Body>
      </LineItemPrice>   
     }   
    </>
  );
};

export const EstimatedTax = ({
  displayName,
  price,
  city,
  state,
  zipCode,
  onZipCodeChange = () => {},
  zipCodeEdit,
  setZipCodeEdit,
  zipCodeLabel,
  zipCodeValid,
  errorText,
  zipCodeError,
  calculateHandler = () => {},
  ...props
}) => {
  const { t } = useTranslation(["shoppingCart", "app"]);
  const label = displayName ?? t("shopping-cart.estimated-tax.label");
  return (
    <>
      <LineItemName>
        <div>
          <TrailingTooltip
            typographyType="body"
            typographySize="large"
            typographyPrimitive="p"
            typographyColor="#000000"
            tooltipSize="large"
            tooltipTitle={t(
              "app.common.orderSummary.estimated_tax_tooltip.title",
              { ns: "app" }
            )}
            tooltipContent={t(
              "app.common.orderSummary.estimated_tax_tooltip.desc",
              { ns: "app" }
            )}
            bold={false}
            surface="light"
          >
            {label}
          </TrailingTooltip>
          <MarginSpacerXS />
          {!!(city && state) && (
            <Body size="large" color="#6F7171">{`[${city}, ${state}]`}</Body>
          )}
          <MarginSpacerXS />
          {/* <TextLink
            type="standAlone"
            size="small"
            disabled={zipCodeEdit}
            onClick={setZipCodeEdit.bind(this, true)}
          >
            {t("shopping-cart.estimated-tax.change")}
          </TextLink> */}
          {zipCodeEdit && (
            <ZipCodeFlexContainer>
              <MarginSpacerXL />
              <Input
                type="text"
                name="zipCode"
                value={zipCode}
                label={t("shopping-cart.cart.changeZipCode", {
                  ns: "shoppingCart",
                })}
                onChange={onZipCodeChange}
                maxLength={5}
                error={zipCodeError ? zipCodeError : !zipCodeValid}
                errorText={zipCodeError ? "invalid zip code" : errorText}
              />
              <MarginSpacerL />
              <ZipCodeButtonDiv>
                <Button
                  size="small"
                  primary
                  disabled={!(zipCode?.length > 0 && zipCodeValid)}
                  onClick={calculateHandler}
                >
                  {t("shopping-cart.estimated-tax.calculate")}
                </Button>
                <TextLink
                  type="standAlone"
                  size="small"
                  onClick={setZipCodeEdit.bind(this, false)}
                >
                  {t("shopping-cart.estimated-tax.cancel")}
                </TextLink>
              </ZipCodeButtonDiv>
            </ZipCodeFlexContainer>
           )}
        </div>
      </LineItemName>
      <LineItemPrice>{`${price}`}</LineItemPrice>
    </>
  );
};
