import React from "react";
import styled from "styled-components";
const Container = styled.div`
  display: flex;
  align-items: center;
`;
const SVGContainer = styled.div`
margin-right:4px;
  svg {
    height: 20px;
    width: 20px;
  }
  
`;

function PlaySvg() {
    return (
        <Container>
            <SVGContainer>
            <svg xmlns="http://www.w3.org/2000/svg" id="Ebene_1" data-name="Ebene 1" viewBox="0 0 21.6 21.6">
                <path d="M10.8,1.8a9,9,0,1,0,9,9A9,9,0,0,0,10.8,1.8Zm0,16.88a7.88,7.88,0,1,1,7.88-7.88A7.89,7.89,0,0,1,10.8,18.68ZM8,5.93v9.74l8.44-4.87Zm1.12,2,5.07,2.92L9.11,13.72Z"/>
                </svg>
            </SVGContainer>
        </Container>
    );
}

export default PlaySvg;