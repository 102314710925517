import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "@vds3/modals";
// import { removeTradeIn } from "pages/TradeIn/ducks/TradeInSlice";

export const RemoveTradeInModal = ({
  opened,
  setOpened,
  deviceId,
  lineItemId,
  ...props
}) => {
  const { t } = useTranslation(["app"]);
  const dispatch = useDispatch();

  const closeModal = () => {
    setOpened(false);
  }

  const removeTradeInDevice = () => {
     const req = {
      cartMeta: { shoppingPath: "prospect", flow: "prospect", origin: "CART" },
      tradeInItems: [
        // lineItemId ? 
        //   { lineItemId }:
           { deviceId }
        ],
    };
    //dispatch(removeTradeIn(req));
    closeModal();
    if(props.handleTradeInValueInfo!==null&&props.handleTradeInValueInfo&&typeof props.handleTradeInValueInfo == 'function'){
      props.handleTradeInValueInfo(null)
      sessionStorage.removeItem('TRADE_INFO_VALUE')
    }
  };

  return (
    <Modal
      opened={opened}
      onOpenedChange={setOpened}
      disableOutsideClick={true}
    >
      <ModalTitle>
        {t("app.common.trade-in-appraised.modal-heading")}
      </ModalTitle>
      <ModalBody style={{ paddingLeft: 0 }}>
        {t("app.common.trade-in-appraised.msg")}
        </ModalBody>
      <ModalFooter
        buttonData={{
          primary: {
            children: t("app.common.trade-in-appraised.remove-btn"),
            onClick: removeTradeInDevice,
          },
          close: {
            children: t("app.common.trade-in-appraised.cancel-btn"),
            onClick: closeModal,
          },
        }}
      />
    </Modal>
  );
};
