import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";
import { Button } from "@vds3/buttons";
import { Grid, Row, Col } from "@vds3/grids";
import { Input } from "@vds3/inputs";
import { Input as InputVDS1 } from "@vds3/inputs";
import { Notification } from "@vds3/notifications";
import { DropdownSelect } from "@vds3/selects";
import { RadioButtonGroup } from "@vds3/radio-buttons";
import { Body, Title } from "@vds3/typography";
import SpinnerComponent from "../../../../common/components/Spinner/Spinner";
import { Line } from "@vds3/lines";
import MapQStreetAddress from "../../Components/BusinessInfo/containers/MapQStreetAdress";
import { MarginSpacerM, MarginSpacerS } from "common/components/styleTag";
import { checkAddressValidation } from "../../../../pages/Checkout/ducks/CheckoutSlice";
import AddressVerificationModal from "../BusinessInfo/AddressVerificationModal";
import { Icon } from "@vds3/icons";
import {
  postInitializePayment,
  postValidateAndSaveCard,
  doUpdatePymtCounterFail,
  setAddress,
  setAddressType,
  setCardHolderName,
  setCardNumber,
  setCardType,
  setCvv,
  setExpirationDate,
  setPaymentInfo,
  setErrorResponse,
  setNotification,
} from "../../../Checkout/ducks/PaymentSlice";
import "./Payment.css";
import { postProcessEcpdDetails, setOosDevices } from "../../../Checkout/ducks/ReviewSlice";
import { getSessionCart } from "pages/Checkout/ducks/CheckoutSlice";
import MaxExceededModal from "./MaxExceededModal";
import VerticalProgressContext from "common/components/VerticalProgress/verticalProgressContext";
import { checkIfAemExists } from "utils/commonUtils";
import { useMediaQuery } from "react-responsive";
import { DeviceDataCollector } from "pages/Checkout/DeviceDataCollector/DeviceDataCollector";

const StyleRadioButtonGroup = styled(RadioButtonGroup)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledSpan = styled.span`
  > a {
    color: black;
  }
`;

function PaymentNew(props) {
  const {
    isComplete,
    onComplete,
    setModifier,
    isEditMode: { isEditMode, setIsEditMode },
  } = React.useContext(VerticalProgressContext);
  const { t, i18n } = useTranslation(["payment", "mock-response"]);
  const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
  const paymentPageData = useSelector((state) => state.ProspectWidgets_PaymentData);
  const pageData = useSelector((state) => state.ProspectWidgets_CheckoutData);
  // const orderSuccessDetails = useSelector(
  //   (state) => state.ReviewPage?.orderSuccessDetails
  // );
  const sessionCartData =
    globalData?.sessionCartData?.apiResponse?.prospectFlowData;
  const dueTodayZero = sessionCartData?.totalAmounts?.dueToday !== 0;
  const [errors, setErrors] = useState({});
  const [isNewAddress, setIsNewAddress] = useState(false);
  const [isCardMasked, setIsCardMasked] = useState(false);
  const [businessAddressValue, setBusinessAddressValue] = useState("");
  const [continueCheked, setContinueChecked] = useState(false);
  const [unitError, setUnitError] = useState("");
  const [homeAddressValue, setHomeAddressValue] = useState("");
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [addressValidated, setAddressValidated] = useState(false);
  const [addressLine1, setAddressLine1] = useState("");
  const dispatch = useDispatch();
  const addressRef = useRef();
  let cardType;

  const businessAddress = sessionCartData?.businessInfo?.address;
  const homeAddress = sessionCartData?.businessInfo?.pocAddress;
  const [showModal, setShowModal] = useState(false);
  const paymentAem = t("payment", { ns: "payment", returnObjects: true });
  const addressConfirmaionContent = t("checkout.businessInfo-section.addressConfirmationModal", {
    ns: "checkout",
    returnObjects: true,
  });
  const unitDefaultValue = t("checkout.businessInfo-section.labelTexts.unitHelper", {
    ns: "checkout",
  });
	const assetsBaseUrl = process.env.PROSPECT_WIDGETS_APP_ASSESTS_BASE_URL;

  const unitErrorMessage =
    t("payment.unitErrorMessage", {
      ns: "payment"
    }) || '';

  useEffect(() => {
    dispatch(postProcessEcpdDetails());
    dispatch(getSessionCart());
    window?.Cardinal?.configure({
      logging: {
        level: "on",
      },
    });
    dispatch(postInitializePayment());
    dispatch(setOosDevices([]));
    const address = paymentPageData?.address;
    if (!address || !Object.keys(address)?.length) {
      let addr = {};
      switch (paymentPageData?.addressType) {
        case "BUSINESS":
          addr = { addressType: "BUSINESS", ...businessAddress };
          break;
        case "HOME":
          addr = { addressType: "HOME", ...homeAddress };
          break;
        default:
          addr = {
            addressType: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            zipCode: "",
          };
      }
      dispatch(setAddress(addr));
    }
    setIsNewAddress(
      paymentPageData?.addressType !== "BUSINESS" && paymentPageData !== "HOME"
    );
    if (businessAddress) {
      const { addressLine1, city, state, zipCode } = businessAddress;
      const tempBusinessAdress = `${addressLine1}, ${city}`;
      const stateAndzipCode = `${state} ${zipCode}`;
      setBusinessAddressValue(`${toTitleCase(tempBusinessAdress)}, ${stateAndzipCode}`);
    }
    if (homeAddress) {
      const { addressLine1, city, state, zipCode } = homeAddress;
      const tempHomeAdress = `${addressLine1}, ${city}`;
      const stateAndzipCode = `${state} ${zipCode}`;
      setHomeAddressValue(`${toTitleCase(tempHomeAdress)}, ${stateAndzipCode}`);
    }
    // SiteAnalytics.paymentLanding();
    // window["digitalData"].isUniqROS = true;
    // window["digitalData"].isUniqBOS = true;
    //SiteAnalytics.dynamicComponentRendered({ order: 12, sectiontName: "Shipping and payment", subSection: "Payment details", isEditMode: false });
  }, []);

  const toTitleCase = (str) => {
    return str?.replace(
      /\w\S*/g,
      function (txt) {
        return txt?.charAt(0)?.toUpperCase() + txt?.substr(1)?.toLowerCase();
      }
    );
  }

  useEffect(() => {
    const element = document.getElementById("payment");
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  // useEffect(() => {
  //   // if (!sessionCartData?.cart?.packages) {
  //   //   navigate("/shop/products/devices/smartphones");
  //   // }
  //   if (
  //     sessionCartData?.cart?.packages &&
  //     Object.keys(sessionCartData?.cart?.packages).length &&
  //     sessionCartData?.showPaymentPage === false &&
  //     !sessionCartData.standAloneBuSS
  //   ) {
  //     navigate("/shop/shopping-cart");
  //   }
  // }, [sessionCartData]);

  useEffect(() => {
    if (isEmpty(window?.Cardinal)) {
      const notification = {
        show: true,
        subtitle: "",
        title: t("payment.notifications.paymentScriptNotLoaded.title", {
          ns: "payment",
        }),
        type: "error",
      };
      dispatch(setNotification(notification));
    } else if (
      sessionCartData?.showZeroDueTodayMessage &&
      !isEmpty(window?.Cardinal)
    ) {
      const notification = {
        show: true,
        subtitle: t("payment.notifications.zeroDueTodayMessage.subtitle", {
          ns: "payment",
        }),
        title: t("payment.notifications.zeroDueTodayMessage.title", {
          ns: "payment",
        }),
        type: "info",
      };
      dispatch(setNotification(notification));
    } else if (!dueTodayZero && !isEmpty(window?.Cardinal)) {
      const notification = {
        show: true,
        subtitle: t("payment.notifications.zeroDueTodayMessage.subtitle", {
          ns: "payment",
        }),
        title: t("payment.notifications.zeroDueTodayMessage.title", {
          ns: "payment",
        }),
        type: "info",
      };
      dispatch(setNotification(notification));
    } else {
      const notification = {
        show: false,
        subtitle: "",
        title: "",
        type: "info",
      };
      dispatch(setNotification(notification));
    }
  }, [window?.Cardinal, sessionCartData?.showZeroDueTodayMessage]);
  const businessAdressChildren = () => {
    return (
      <div>
        <Body size="large" primitive="h4" bold>
          {t("payment.businessAddress", {
            ns: "payment",
          })}
        </Body>
        <Body size="large" primitive="h4">{businessAddressValue}</Body>
        {/* {businessAddressValue ? businessAddressValue : ""} */}
      </div>
    );
  };

  useEffect(() => {
    if (pageData?.addressValidationInfo?.serviceStatus?.success) {
      setShowAddressModal(false);
      const addr = {
        addressLine1: paymentPageData?.address?.addressLine1 ?? "",
        addressLine2: paymentPageData?.address?.addressLine2 ?? "",
        city: paymentPageData?.address?.city ?? "",
        state: paymentPageData?.address?.state ?? "",
        zipCode: paymentPageData?.address?.zipCode ?? "",
      };
      dispatch(setAddress(addr));
      setAddressValidated(true)

    } else if (
      pageData?.addressValidationInfo?.serviceStatus &&
      !pageData?.addressValidationInfo?.serviceStatus?.success
    ) {
      setShowAddressModal(true);
    }
  }, [pageData?.addressValidationInfo?.serviceStatus]);

  const HomeAdressChildren = () => {
    return (
      <div>
        <Body size="large" primitive="h4" bold>
          {t("payment.homeAddress", {
            ns: "payment",
          })}
        </Body>
        <Body size="large" primitive="h4">{homeAddressValue}</Body>
        {/* {homeAddressValue ? homeAddressValue : ""} */}
      </div>
    );
  };

  useEffect(() => {
    if (
      paymentPageData?.paymentInfo?.stage === "stage1:INITIALIZE_PAYMENT" &&
      paymentPageData?.paymentInfo?.status === "SUCCESS"
    ) {
      window?.Cardinal?.on("payments.validated", function (data, jwt) {
        switch (data.ActionCode) {
          case "NOACTION":
            if (data.Payment.ExtendedData.SignatureVerification === "N") {
              dispatch(
                setPaymentInfo({
                  ...paymentPageData?.paymentInfo,
                  stage: "stage3:3D_SECURE",
                  status: "FAILED",
                  stage3: { ...data },
                  currentStage: "stage3",
                  currentStatus: "FAILED",
                  errorStatusCode:
                    data.serviceStatus?.statusCode || "PAYMENT_ERROR_GENERIC",
                })
              );
              dispatch(doUpdatePymtCounterFail());
            } else {
              dispatch(
                setPaymentInfo({
                  ...paymentPageData?.paymentInfo,
                  stage: "stage3:3D_SECURE",
                  status: "SUCCESS",
                  stage3: { ...data },
                  currentStage: "stage3",
                  currentStatus: "SUCCESS",
                })
              );
              dispatch(postValidateAndSaveCard({ jwt: jwt, checkoutNew: true }));
            }
            break;
          case "SUCCESS":
            dispatch(
              setPaymentInfo({
                ...paymentPageData?.paymentInfo,
                stage: "stage3:3D_SECURE",
                status: "SUCCESS",
                stage3: { ...data },
                currentStage: "stage3",
                currentStatus: "SUCCESS",
              })
            );
            dispatch(postValidateAndSaveCard({ jwt: jwt, checkoutNew: true }));
            break;
          case "FAILURE":
            dispatch(
              setPaymentInfo({
                ...paymentPageData?.paymentInfo,
                stage: "stage3:3D_SECURE",
                status: "FAILED",
                stage3: { ...data },
                currentStage: "stage3",
                currentStatus: "FAILED",
                errorStatusCode:
                  data.serviceStatus?.statusCode ||
                  "PAYMENT_ERROR_FAILED_AUTHENTICATION",
              })
            );
            dispatch(doUpdatePymtCounterFail());
            break;
          case "ERROR":
            dispatch(
              setPaymentInfo({
                ...paymentPageData?.paymentInfo,
                stage: "stage3:3D_SECURE",
                status: "FAILED",
                stage3: { ...data },
                currentStage: "stage3",
                currentStatus: "FAILED",
                errorStatusCode:
                  data.serviceStatus?.statusCode || "PAYMENT_ERROR_GENERIC",
              })
            );
            dispatch(doUpdatePymtCounterFail());
            break;
        }
      });
    } else if (
      paymentPageData?.paymentInfo?.stage === "stag2:FULL_AUTH" &&
      paymentPageData?.paymentInfo?.status === "SUCCESS"
    ) {
      //if (window["digitalData"].isUniqBOS) {
      // SiteAnalytics.eventOrderInfoTracking(
      //   "Unified Business Order Submitted",
      //   sessionCartData,
      //   "payment",
      //   "",
      //   orderSuccessDetails
      // );
      //}
      onComplete();
    }
  }, [paymentPageData?.paymentInfo]);
  useEffect(() => {
    if (
      continueCheked &&
      paymentPageData.completeOrderBUSSResponse !== null &&
      paymentPageData.completeOrderBUSSResponse &&
      paymentPageData.completeOrderBUSSResponse["serviceStatus"] !== null &&
      paymentPageData.completeOrderBUSSResponse["serviceStatus"] &&
      paymentPageData.completeOrderBUSSResponse["serviceStatus"][
      "statusMessage"
      ] === "SUCCESS"
    ) {
      onComplete();
    }
  }, [paymentPageData.completeOrderBUSSResponse]);
  useEffect(() => {
    if (paymentPageData?.errorResponse) {
      dispatch(setErrorResponse(false));
      if (
        paymentPageData?.paymentInfo?.errorStatusCode ===
        "PAYMENT_BILLING_ADDRESS_VALIDATION_FAILED" ||
        paymentPageData?.validateAndSaveCardResponse?.statusCode ===
        "PAYMENT_BILLING_ADDRESS_VALIDATION_FAILED"
      ) {
        const notification = {
          show: true,
          subtitle: "Please try again.",
          title: t(
            "payment.paymentNewErrorMessages.PAYMENT_BILLING_ADDRESS_VALIDATION_FAILED",
            {
              ns: "payment",
            }
          ),
          type: "error",
        };
        //SiteAnalytics.errorEncounter({ ...notification, id: "PAYMENT_BILLING_ADDRESS_VALIDATION_FAILED", errorType: "payment" });
        dispatch(setNotification(notification));
      } else if (
        paymentPageData?.paymentInfo?.errorStatusCode ===
        "PAYMENT_ERROR_FAILED_AUTHENTICATION" ||
        paymentPageData?.validateAndSaveCardResponse?.statusCode ===
        "PAYMENT_ERROR_FAILED_AUTHENTICATION"
      ) {
        const notification = {
          show: true,
          subtitle: "Please try a different payment method.",
          title: t(
            "payment.paymentNewErrorMessages.PAYMENT_ERROR_FAILED_AUTHENTICATION",
            {
              ns: "payment",
            }
          ),
          type: "error",
        };
        //SiteAnalytics.errorEncounter({ ...notification, id: "PAYMENT_ERROR_FAILED_AUTHENTICATION", errorType: "payment" });
        dispatch(setNotification(notification));
      } else if (
        paymentPageData?.paymentInfo?.errorStatusCode ===
        "PAYMENT_ERROR_MAXIMUM_ATTEMPTS_EXHAUSTED" ||
        paymentPageData?.validateAndSaveCardResponse?.statusCode ===
        "PAYMENT_ERROR_MAXIMUM_ATTEMPTS_EXHAUSTED"
      ) {
        const notification = {
          show: true,
          subtitle: "Please visit a Verizon store.",
          title: t(
            "payment.paymentNewErrorMessages.PAYMENT_ERROR_MAXIMUM_ATTEMPTS_EXHAUSTED",
            {
              ns: "payment",
            }
          ),
          type: "error",
        };
        //SiteAnalytics.errorEncounter({ ...notification, id: "PAYMENT_ERROR_MAXIMUM_ATTEMPTS_EXHAUSTED", errorType: "payment" });
        setShowModal(true);
        dispatch(setNotification(notification));
      } else if (
        paymentPageData?.paymentInfo?.errorStatusCode ===
        "PAYMENT_ERROR_GENERIC" ||
        paymentPageData?.validateAndSaveCardResponse?.statusCode ===
        "PAYMENT_ERROR_GENERIC"
      ) {
        const notification = {
          show: true,
          subtitle: "Please review and try again.",
          title: t("payment.paymentNewErrorMessages.PAYMENT_ERROR_GENERIC", {
            ns: "payment",
          }),
          type: "error",
        };
        //SiteAnalytics.errorEncounter({ ...notification, id: "PAYMENT_ERROR_GENERIC", errorType: "payment" });
        dispatch(setNotification(notification));
      } else if (
        paymentPageData?.paymentInfo?.errorStatusCode ===
        "PAYMENT_ERROR_SAFETECH_VERIFY_DECLINE" ||
        paymentPageData?.validateAndSaveCardResponse?.statusCode ===
        "PAYMENT_ERROR_SAFETECH_VERIFY_DECLINE"
      ) {
        const notification = {
          show: true,
          subtitle: "Please review and try again.",
          title: t(
            "payment.paymentNewErrorMessages.PAYMENT_ERROR_SAFETECH_VERIFY_DECLINE",
            {
              ns: "payment",
            }
          ),
          type: "error",
        };
        //SiteAnalytics.errorEncounter({ ...notification, id: "PAYMENT_ERROR_SAFETECH_VERIFY_DECLINE", errorType: "payment" });
        dispatch(setNotification(notification));
      } else if (
        paymentPageData?.paymentInfo?.errorStatusCode ===
        "PAYMENT_ERROR_CREDIT_FUND_NOTAVAIL" ||
        paymentPageData?.validateAndSaveCardResponse?.statusCode ===
        "PAYMENT_ERROR_CREDIT_FUND_NOTAVAIL"
      ) {
        const notification = {
          show: true,
          subtitle: "Please try a different payment method.",
          title: t(
            "payment.paymentNewErrorMessages.PAYMENT_ERROR_CREDIT_FUND_NOTAVAIL",
            {
              ns: "payment",
            }
          ),
          type: "error",
        };
        //SiteAnalytics.errorEncounter({ ...notification, id: "PAYMENT_ERROR_CREDIT_FUND_NOTAVAIL", errorType: "payment" });
        dispatch(setNotification(notification));
      } else if (
        paymentPageData?.paymentInfo?.errorStatusCode ===
        "AUTOMATION_STEPS_INCOMPLETE" ||
        paymentPageData?.validateAndSaveCardResponse?.serviceStatus === false
      ) {
        const notification = {
          show: true,
          subtitle: "",
          title: "Something went wrong.",
          type: "error",
        };
        dispatch(setNotification(notification));
      } else if (
        paymentPageData?.paymentInfo?.errorStatusCode === "FAILED" &&
        paymentPageData?.paymentInfo?.stage === "stage4:FULL_AUTH"
      ) {
        setIsEditMode(true)
        const notification = {
          show: true,
          subtitle: "Please try a different credit card.",
          title: t(
            "payment.paymentNewErrorMessages.COMPLETE_ORDER_FAILED_INVALID_CARD",
            {
              ns: "payment",
            }
          ),
          type: "error",
        };
        //SiteAnalytics.errorEncounter({ ...notification, id: "COMPLETE_ORDER_FAILED_INVALID_CARD", errorType: "payment" });
        dispatch(setNotification(notification));
      } else {
        const notification = {
          show: true,
          subtitle: "",
          title: t("payment.notifications.paymentScriptNotLoaded.title", {
            ns: "payment",
          }),
          type: "error",
        };
        dispatch(setNotification(notification));
      }
    }
    setAddressValidated(false)
  }, [paymentPageData?.errorResponse]);

  useEffect(() => {
    if (addressValidated) {
      validateCardDetails();
    }
  }, [addressValidated])
  useEffect(() => {
    if (paymentPageData?.notification?.show === true) {
      let elementBody = document.getElementById("notification");
      elementBody?.scrollIntoView();
    }
  }, [paymentPageData?.notification?.show]);

  const validateCardDetails = () => {
    setContinueChecked(true);
    const tokenjwt = "";
    //Check expiration date is in correct format.
    if (paymentPageData?.expirationDate?.replace("/", "").length < 4) {
      const errorTexts =
        t("payment.expirationDateInput.errors.invalidExpirationdateNew", {
          ns: "payment",
        }) || "";
      setErrors({ ...errors, expirationDate: errorTexts });
      return;
    }
    if (hasErrors()) {
      return;
    }
    const notification = {
      show: false,
      subtitle: "",
      title: "",
      type: "info",
    };

    const useMock = checkIfAemExists(t("payment-mock", { ns: "mock-response" }));
    dispatch(setNotification(notification));
    dispatch(postValidateAndSaveCard({ useMock: useMock, jwt: tokenjwt, checkoutNew: true }));
  }

  const hasErrors = () => {
    let error = false;
    let cardNumberErr = "";
    let cardHolderNameErr = "";
    let cvvErr = "";
    let expDateErr = "";
    let addrErr = "";
    for (const key of Object.keys(errors)) {
      if (errors[key] && errors[key].length > 0) {
        error = true;
      } else if (
        isNewAddress &&
        (!paymentPageData?.address?.addressLine1 ||
          !paymentPageData?.address?.city ||
          !paymentPageData?.address?.state ||
          !paymentPageData?.address?.zipCode)
      ) {
        error = true;
      }
    }


    if (!paymentPageData.cardNumber) {
      cardNumberErr = t("payment.cardNumberInput.errors.invalid", { ns: "payment" })
    }
    if (!paymentPageData.expirationDate) {
      expDateErr = t("payment.expirationDateInput.errors.requiredExpirationDate", { ns: "payment" })
    }
    if (!paymentPageData.cardHolderName) {
      cardHolderNameErr = t(
        "payment.cardholderNameInput.errors.invalidCardHolderNameNew",
        { ns: "payment" }
      );
    }
    if (!paymentPageData.cvv) {
      let cvvMaxlength = paymentPageData.cardType === "amex" ? 4 : 3;
      cvvErr = t("payment.CVVInput.errors.invalidLengthNew", {
        length: cvvMaxlength,
        ns: "payment",
      })
    }
    if (!paymentPageData.address) {
      addrErr = "Address is required";
    }

    if (
      !paymentPageData.cardNumber ||
      !paymentPageData.expirationDate ||
      !paymentPageData.cardHolderName ||
      !paymentPageData.cvv ||
      !paymentPageData.address
    ) {
      setErrors({
        ...errors,
        cardNumber: cardNumberErr,
        cardHolderName: cardHolderNameErr,
        expirationDate: expDateErr,
        cvv: cvvErr,
        address: addrErr
      })
      error = true;
    }
    return error;
  };

  const validateBusinessAddress = (value) => {
    let payload;
    const billingAddress = value ?? paymentPageData?.address;
    if (billingAddress) {
      payload = {
        addressLine1: billingAddress?.addressLine1,
        addressLine2: billingAddress?.addressLine2,
        city: billingAddress?.city,
        state: billingAddress?.state,
        zipCode: billingAddress?.zipCode,
      };
    }
    if (payload?.addressLine1) {
      dispatch(checkAddressValidation(payload));
    }
  };

  const onClickCheck = () => {
    if (paymentPageData.addressType === "NEW" && !hasErrors()) {
      validateBusinessAddress();
    } else if (!hasErrors()) {
      validateCardDetails();
    }
    setAddressValidated(false)
  };


  const getTooltipContent = () => {
    return (
      <div className="tooltipContent">
        <div>
          <Icon name="credit-card"
            color="#000000"
            size="71px"></Icon>
          <Body>{t("payment.CVVInput.tooltip.nonAmex", {
            ns: "payment",
          })}</Body>
        </div>
        <div>
          <Icon name="credit-card"
            color="#000000"
            size="71px"></Icon>
          <Body>{t("payment.CVVInput.tooltip.amex", {
            ns: "payment",
          })}</Body>
        </div>
      </div>
    )
  }

  const detectCardType = (value) => {
    const regExVisa = new RegExp("^4"),
      regExAmex = new RegExp("^3[47]"),
      regExMaster = new RegExp("^(5[1-5]|222[1-9]|2[3-6])|27[0-2]"),
      regExDisc = new RegExp("^(6011|65|64[4-9])");
    if (regExVisa.test(value)) {
      return "visa";
    } else if (regExAmex.test(value)) {
      return "amex";
    } else if (regExMaster.test(value)) {
      return "mastercard";
    } else if (regExDisc.test(value)) {
      return "discover";
    } else {
      return "none";
    }
  };
  const maskCardNumber = (cardNumber) => {
    let type = detectCardType(cardNumber);
    let maxlength = type === "amex" ? 15 : 16;
    let trimmedCardNumber = cardNumber?.replace(/\s/g, "");
    if (trimmedCardNumber?.length === maxlength) {
      return (
        trimmedCardNumber?.replace(
          /^[\d-\s]+(?=\d{4})/,
          "*".repeat(maxlength - 4)
        ) || ""
      );
    }
    return cardNumber;
  };

  const isValidPaymentInfoField = (key, e) => {
    let errorTexts = "";
    let val = e?.target?.value;
    // card number
    if (key === "cardNumber") {
      if (val >= 4) {
        cardType = detectCardType(val);
        dispatch(setCardType(cardType));
      } else {
        dispatch(setCardType("none"));
      }
      let maxlength = cardType === "amex" ? 15 : 16;
      val = e.target.value
        .replace(/[^0-9]/g, "")
        .replace(
          cardType === "amex"
            ? /(\d{4})(\d{4})(\d{4})(\d{3})/
            : /(\d{4})(\d{4})(\d{4})(\d{4})/,
          "$1 $2 $3 $4"
        );
      errorTexts =
        !val || !val?.trim() || val?.trim().length < maxlength
          ? t("payment.cardNumberInput.errors.invalid", { ns: "payment" })
          : "";
      const cardVal = val?.trim()?.replace(/[^0-9]/g, "");
      if (cardVal.length === maxlength) {
        window?.Cardinal?.trigger("bin.process", cardVal?.substring(0, 6));
      }
      dispatch(setCardNumber(val));
    }

    // expiration date
    if (key === "expirationDate") {
      if (!val || !val.trim()) {
        errorTexts = t(
          "payment.expirationDateInput.errors.requiredExpirationDate",
          {
            ns: "payment",
          }
        );
      } else {
        val = e.target.value.replace(/[^0-9]/g, "");

        if (e.target.value === "/") {
          val = " ";
        }

        if (val.length < 5 && val.length > 0) {
          let temp = val.match(/.{1,2}/g).join("/");
          val = temp;
        }

        if (val?.trim() && val?.trim().length > 0) {
          if (val?.trim().length === 2) {
            val += "/";
          }
        }

        if (val.length === 5) {
          let temp = val.split("/");
          const date = new Date();
          const currentMonth = date.getMonth() + 1;
          const currentYear = String(date.getFullYear()).slice(-2);
          if (
            Number(temp[0]) > 12 ||
            (Number(temp[0]) < Number(currentMonth) &&
              Number(temp[1]) === Number(currentYear))
          ) {
            dispatch(setExpirationDate(""));
            val = "";
          }
          if (Number(temp[1]) < Number(currentYear)) {
            dispatch(setExpirationDate(""));
            val = "";
          }
        }

        errorTexts =
          !val || !val?.trim()
            ? t("payment.expirationDateInput.errors.invalidExpirationdateNew", {
              ns: "payment",
            })
            : "";
      }
      dispatch(setExpirationDate(val));
    }

    // card holder name
    if (key === "cardHolderName") {
      const pattern = /^$|^[a-zA-Z-\s]+$/;
      if (pattern.test(val)) {
        errorTexts = "";
        dispatch(setCardHolderName(val));
      } else {
        errorTexts = t(
          "payment.cardholderNameInput.errors.invalidCardHolderNameNew",
          { ns: "payment" }
        );
      }
    }

    // cvv input
    if (key === "cvv") {
      val = e.target.value.replace(/[^0-9]/g, "");
      let cvvMaxlength = paymentPageData.cardType === "amex" ? 4 : 3;
      errorTexts =
        !val || !val?.trim() || val?.trim().length < cvvMaxlength
          ? t("payment.CVVInput.errors.invalidLengthNew", {
            length: cvvMaxlength,
            ns: "payment",
          })
          : "";
      dispatch(setCvv(val));
    }

    // address line 1
    if (key === "addresssel") {
      errorTexts = !e ? `Address is required.` : "";
      const addr = {
        addressLine1: e?.place?.properties?.street ?? e?.target?.value ?? "",
        addressLine2: "",
        city: e?.place?.properties?.city ?? "",
        state: e?.place?.properties?.stateCode ?? "",
        zipCode: e?.place?.properties?.postalCode?.substring(0, 5) ?? "",
      };
      if (!!e?.place?.properties.street || !!e?.target?.value) {
        setErrors({
          ...errors,
          address: "Address is required",
        });
      }
      dispatch(setAddress(addr));
    }
    if (key === "address") {
      errorTexts = !e ? `Address is required.` : "";
      const addr = {
        addressLine1: e?.place?.properties?.street ?? e?.target?.value ?? "",

        city: paymentPageData?.address?.city ?? "",
        state: paymentPageData?.address?.state ?? "",
        zipCode: paymentPageData?.address?.zipCode ?? "",
      };
      if (!!e?.place?.properties.street || !!e?.target?.value) {
        setErrors({
          ...errors,
          address: "Address is required",
        });
      }
      dispatch(setAddress(addr));
    }

    setErrors({ ...errors, [key]: errorTexts });
  };

  const onChangeAddressType = (e) => {
    const addressType = e.target.value;
    let address = {
      addressType: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
    };

    if (addressType === "BUSINESS") {
      setIsNewAddress(false);
      address = { addressType: address.addressType, ...businessAddress };
    }
    if (addressType === "HOME") {
      setIsNewAddress(false);
      address = { addressType: address.addressType, ...homeAddress };
    }
    if (addressType === "NEW") {
      setIsNewAddress(true);
    }
    dispatch(setAddressType(addressType));
    dispatch(setAddress(address));
  };

  const handleAddressInput = (e, name) => {
    const value = e.target.value;
    const { ...address } = paymentPageData.address;
    if (name === "city") {
      const PATTERN_STATE = /^[a-zA-Z]+$/.test(value);
      address.city = value;
      if (!value) {
        setErrors({
          ...errors,
          city: "City is required",
        });
      } else if (!PATTERN_STATE) {
        setErrors({
          ...errors,
          city: "Enter valid city name",
        })
      }
      else {
        setErrors({
          ...errors,
          city: null,
        });
      }
    } else if (name === "state") {
      const PATTERN_STATE = /^[a-zA-Z]+$/.test(value);
      if (value.length > 2) {
        return;
      }
      address.state = value;
      if (!value) {
        setErrors({
          ...errors,
          state: "State is required",
        });
      } else if (!PATTERN_STATE) {
        setErrors({
          ...errors,
          state: "State code is invalid",
        });
      } else {
        setErrors({
          ...errors,
          state: null,
        });
      }
    } else if (name === "addressLine2") {
      const PATTERN_ADDRESS = /^[A-Za-z0-9 ]+$/.test(value);
      if (!PATTERN_ADDRESS) {
        setUnitError("only letter allowed")
      } else {
        setUnitError("")
      }
      address.addressLine2 = value;
    } else if (name === "zipCode") {
      const PATTERN_ZIPCODE = /^\d{5}(-\d{4})?$/.test(value);
      if (value.length > 9) {
        return;
      }
      address.zipCode = value;
      if (!value) {
        setErrors({
          ...errors,
          zipCode: "Zip code is required",
        });
      } else if (!PATTERN_ZIPCODE) {
        setErrors({
          ...errors,
          zipCode: "Enter valid 5-digit ZIP code.",
        });
      } else {
        setErrors({
          ...errors,
          zipCode: null,
        });
      }
    }
    dispatch(setAddress(address));
  };

  const isMobile = useMediaQuery({
    query: "(max-width: 545px)",
  });

  useEffect(() => {
    setAddressLine1(paymentPageData?.address?.addressLine1)
  }, [paymentPageData?.address?.addressLine1])

  useEffect(() => {
    if (paymentPageData?.notification?.show === true) {
      let elementBody = document.getElementById("notification");
      elementBody?.scrollIntoView();
    }
  }, [paymentPageData?.notification?.show === true]);

  const getDefaultContent = () => {
    return (
      <>
        {paymentPageData?.loading && <SpinnerComponent />}
        {showAddressModal && (
          <AddressVerificationModal
            opened={showAddressModal}
            setAddressConfirmationModal={setShowAddressModal}
            handleAddressSelect={(value) => isValidPaymentInfoField("addresssel", value)}
            aemContent={addressConfirmaionContent}
            businessRawAddress={paymentPageData?.address && Object.values(paymentPageData?.address)?.join(" ")}
            addressLine2={paymentPageData?.address?.addressLine2 ? paymentPageData?.address?.addressLine2 : " "}
            showCloseButton={true}
            fromBusinessAddress={true}
            unitDefaultValue={unitDefaultValue}
            validateBusinessAddress={validateBusinessAddress}
          />
        )}
        {showModal && (
          <MaxExceededModal showModal={showModal} setShowModal={setShowModal} />
        )}
        {(paymentPageData?.notification?.show || !dueTodayZero) && (
          <Notification
            id="notification"
            className="payment-page-notification"
            fullBleed={true}
            subtitle={paymentPageData?.notification?.subtitle}
            title={paymentPageData?.notification?.title}
            type={paymentPageData?.notification?.type}
            onCloseButtonClick={() => {
              const notification = {
                show: false,
                subtitle: "",
                title: "",
                type: "info",
              };
              dispatch(setNotification(notification));
            }}
            hideCloseButton={paymentPageData?.notification?.type == "info" ? true : false}
          />
        )}
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            autoComplete="off"
            noValidate
          >
            <Grid
              colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
              colGutter={false}
              rowGutter="16px"
            >
              <StyledRow>
                <Col colSizes={{ mobile: 4, tablet: 9, desktop: 9 }}>
                  <Body size="large" primitive="h4">
                    {t("payment.paymentMethod", { ns: "payment" })}
                  </Body>
                </Col>
                <Col colSizes={{ mobile: 4, tablet: 9, desktop: 9 }}>
                  <Body size="small" primitive="p">
                    {t("payment.infomsg1", {
                      ns: "payment",
                    })}
                  </Body>
                </Col>
              </StyledRow>
              <StyledRow>
                <Col colSizes={{ mobile: 4, tablet: 8, desktop: 8 }}>
                  <Input
                    label={`${t("payment.cardholderNameInput.label", {
                      ns: "payment",
                    })}*`}
                    maxLength={64}
                    placeholder=""
                    name="cardHolderName"
                    width={"100%"}
                    value={paymentPageData.cardHolderName}
                    onChange={(e) =>
                      isValidPaymentInfoField("cardHolderName", e)
                    }
                    error={errors.cardHolderName?.length > 0}
                    errorText={errors.cardHolderName}
                  />
                </Col>
              </StyledRow>
              <StyledRow>
                <Col colSizes={{ mobile: 4, tablet: 8, desktop: 8 }}>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "0.5rem",
                    }}
                  >
                    <Input
                      label={`${t("payment.cardNumber", {
                        ns: "payment",
                      })}*`}
                      maxLength={16}
                      placeholder={`${t("payment.cardNumber", {
                        ns: "payment",
                      })}`}
                      name="cardNumber"
                      width={"100%"}
                      value={
                        isCardMasked
                          ? maskCardNumber(
                            paymentPageData.cardNumber || ""
                          )
                          : paymentPageData.cardNumber
                      }
                      onChange={(e) =>
                        isValidPaymentInfoField("cardNumber", e)
                      }
                      error={errors.cardNumber?.length > 0}
                      errorText={errors.cardNumber}
                      onFocus={(e) => {
                        if (
                          e?.target?.value &&
                          e?.target?.value?.length &&
                          e?.target?.value?.includes("*")
                        ) {
                          dispatch(setCardNumber(""));
                          if (isCardMasked) {
                            setIsCardMasked(false);
                          }
                        }
                      }}
                      onBlur={(e) => {
                        if (
                          e?.target?.value &&
                          e?.target?.value?.length
                        ) {
                          let cardNumber = e?.target?.value;
                          let type = detectCardType(cardNumber);
                          let maxlength = type === "amex" ? 15 : 16;
                          let trimmedCardNumber = cardNumber?.replace(
                            /\s/g,
                            ""
                          );
                          if (trimmedCardNumber?.length === maxlength) {
                            if (!isCardMasked) {
                              setIsCardMasked(true);
                            }
                          }
                        }
                      }}
                    />
                    <img
                      className="selected-card"
                      style={paymentPageData?.cardType === "visa" ? { top: "37px" } : { top: "33px" }}
                      src={`${assetsBaseUrl
                        }/assets/images/payment/${paymentPageData.cardType === "none"
                          ? "cardPlaceholder"
                          : paymentPageData.cardType === "mastercard"
                            ? "master"
                            : paymentPageData.cardType
                        }.png`}
                      alt="selected card image"
                    />
                  </div>
                  <div className='images-wrapper'>
                    <div>
                      <img
                        src={`${assetsBaseUrl}/assets/images/payment/norton_secure_seal.png`}
                        className="norton-secure-seal"
                        alt="selected card image"
                      />
                    </div>
                    <div className="card-images-wrapper">
                      <div className="card-images">
                        <img
                          className="visa"
                          src={`${assetsBaseUrl}/assets/images/payment/visa.png`}
                          alt="Visa card image"
                        />
                        <img
                          className="master"
                          src={`${assetsBaseUrl}/assets/images/payment/master.png`}
                          alt="Master card image"
                        />
                        <img
                          className="amex"
                          src={`${assetsBaseUrl}/assets/images/payment/amex.png`}
                          alt="Amex card image"
                        />
                        <img
                          className="discover"
                          src={`${assetsBaseUrl}/assets/images/payment/discover.png`}
                          alt="Discover card image"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </StyledRow>
              <StyledRow>
                <Col colSizes={{ mobile: 2, tablet: 4, desktop: 4 }}>
                  <div style={{ width: '95%' }}>
                    <InputVDS1
                      label={`${t("payment.expirationDateInput.label", {
                        ns: "payment",
                      })}*`}
                      maxLength={5}
                      type="date"
                      dateFormat="mmyy"
                      placeholder={"MM/YY"}
                      name="expirationDate"
                      width={"100%"}
                      onKeyDown={(e) => {
                        if (e?.key === "Backspace") {
                          if (e?.target?.value?.length === 3) {
                            let val = e?.target?.value;
                            val = val?.split("/")[0] || "";
                            dispatch(setExpirationDate(val || ""));
                          }
                        } else if (e?.key === "Delete") {
                          dispatch(setExpirationDate(""));
                        }
                      }}
                      value={paymentPageData.expirationDate}
                      onChange={(e) =>
                        isValidPaymentInfoField("expirationDate", e)
                      }
                      error={errors.expirationDate?.length > 0}
                      errorText={
                        errors.expirationDate ||
                        `${t(
                          "payment.expirationDateInput.errors.requiredExpirationDate",
                          {
                            ns: "payment",
                          }
                        )}`
                      }
                    />
                  </div>
                </Col>
                <Col colSizes={{ mobile: 2, tablet: 4, desktop: 4 }}>
                  <Input
                    label={`${t("payment.CVVInput.label", {
                      ns: "payment",
                    })}*`}
                    maxLength={
                      paymentPageData.cardType === "amex" ? 4 : 3
                    }
                    type="password"
                    placeholder={`${t("payment.CVVInput.label", {
                      ns: "payment",
                    })}`}
                    name="cvv"
                    value={paymentPageData.cvv}
                    tooltipContent={getTooltipContent()}

                    onChange={(e) => isValidPaymentInfoField("cvv", e)}
                    error={errors.cvv?.length > 0}
                    errorText={errors.cvv}
                  />
                </Col>
              </StyledRow>

              <StyledRow>
                <Line type="secondary" />
              </StyledRow>

              <StyledRow>
                <Col colSizes={{ mobile: 4, tablet: 9, desktop: 9 }}>
                  <div className="billing-address">
                    <Body size="large" primitive="h3">
                      {t("payment.billingAddress", { ns: "payment" })}
                    </Body>
                  </div>
                </Col>
              </StyledRow>
              <StyledRow>
                <Col colSizes={{ mobile: 4, tablet: 9, desktop: 9 }}>
                  <RadioButtonGroup
                    selected={paymentPageData.addressType}
                    onChange={(e) => {
                      onChangeAddressType(e);
                    }}
                    defaultValue={paymentPageData.addressType}
                    data={[
                      {
                        name: "sameAsCompany",
                        children: businessAdressChildren(),
                        value: "BUSINESS",
                        'data-track': "BUSINESS"
                      },
                      {
                        name: "sameAsHome",
                        children: HomeAdressChildren(),
                        value: "HOME",
                        'data-track': "HOME"
                      },
                      {
                        name: "newAddress",
                        children: <Body size="large" primitive="h4" bold>{t("payment.newAddress", {
                          ns: "payment",
                        })}</Body>,
                        value: "NEW",
                        'data-track': "NEW"
                      },
                    ]}
                  />
                  {isNewAddress ? (<Grid
                    colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
                    colGutter={false}
                    rowGutter="16px"
                  >
                    <StyledRow>
                      <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
                        <MarginSpacerM />
                        <MapQStreetAddress
                          isNewAddress={isNewAddress}
                          errorMessage={errors?.address}
                          isError={errors?.address}
                          label="Billing address*"
                          handleAddressInput={(value) => {
                            isValidPaymentInfoField("address", value);
                          }}
                          isAddressEmty={false}
                          onSelectionChange={(value) =>
                            isValidPaymentInfoField("addresssel", value)
                          }
                          ref={addressRef}
                          setAddressFieldError={() => { }}
                          isNameOnly
                          isFromPayment={true}
                          value={paymentPageData?.address?.addressLine1}
                          addressLineOne={addressLine1 || ""}
                          setAutoFocus={true}
                        />
                      </Col>
                      <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
                        <Input
                          disabled={!isNewAddress}
                          type="text"
                          maxLength={20}
                          label="Suite, room, apt."
                          placeholder="Enter Unit number"
                          required={false}
                          value={paymentPageData?.address?.addressLine2}
                          onChange={(e) =>
                            handleAddressInput(e, "addressLine2")
                          }
                          error={unitError?.length > 0}
                          errorText={unitErrorMessage || ''}
                        />
                      </Col>
                    </StyledRow>
                    <StyledRow className="address-section">
                      <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
                        <Input
                          type="text"
                          name="city"
                          label="City*"
                          placeholder="Enter City"
                          disabled={!isNewAddress}
                          error={!!errors.city}
                          errorText={errors.city}
                          required={true}
                          value={paymentPageData?.address?.city}
                          onChange={(e) => handleAddressInput(e, "city")}
                        />
                      </Col>
                    </StyledRow>
                    <StyledRow>
                      <Col colSizes={{ mobile: 2, tablet: 6, desktop: 6 }}>
                        <div style={{ width: '95%' }}>
                          <DropdownSelect
                            label="State*"
                            name="state"
                            placeholder="Select State"
                            className="numLines-dropdownSelect"
                            disabled={!isNewAddress}
                            error={!!errors.state}
                            errorText={errors.state}
                            required={true}
                            value={paymentPageData?.address?.state}
                            onChange={(e) => handleAddressInput(e, "state")}
                          >
                            {paymentAem?.usStates &&
                              Object.keys(paymentAem?.usStates)?.map((val, b) => (
                                <option key={b + 1}>{val}</option>
                              ))}
                          </DropdownSelect>
                        </div>
                      </Col>
                      <Col colSizes={{ mobile: 2, tablet: 6, desktop: 6 }}>
                        <Input
                          type="text"
                          name="zipCode"
                          label="Zip Code*"
                          placeholder="Enter Zip Code"
                          disabled={!isNewAddress}
                          error={!!errors.zipCode}
                          errorText={errors.zipCode}
                          required={true}
                          value={paymentPageData?.address?.zipCode}
                          onChange={(e) => handleAddressInput(e, "zipCode")}
                        />
                      </Col>
                    </StyledRow>
                  </Grid>) : <></>}
                </Col>
              </StyledRow>
              <StyledRow>
                <Col colSizes={{ mobile: 4, tablet: 9, desktop: 9 }}>
                  <MarginSpacerS />
                  <Body size="small" primitive="h3">
                    {t("payment.disclaimer", { ns: "payment" })}
                  </Body>
                </Col>
              </StyledRow>
              {paymentAem?.privacyDisclaimer && (
                <StyledRow>
                  <Col colSizes={{ mobile: 4, tablet: 9, desktop: 9 }}>
                    <Body size="small" primitive="h3">
                      <StyledSpan
                        dangerouslySetInnerHTML={{
                          __html: paymentAem?.privacyDisclaimerNew,
                        }}
                      ></StyledSpan>
                    </Body>
                  </Col>
                </StyledRow>)}
              <StyledRow className="payment-submit-section">
                <Col colSizes={{ mobile: 4, tablet: 3, desktop: 3 }}>
                  <Button width={isMobile ? "100%" : "auto"} onClick={onComplete} disabled={false} data-track={`Shipping and payment:Payment details:${isEditMode ? t("payment.paymentSave", { ns: "payment" }) : t("payment.paymentContinue", { ns: "payment" })}`}>
                    {isEditMode ? t("payment.paymentSave", { ns: "payment" }) : t("payment.paymentContinue", { ns: "payment" })}
                  </Button>
                </Col>
              </StyledRow>
            </Grid>
          </form>
        </div>
        {/* <IdleTimer /> */}
        <DeviceDataCollector />
      </>
    )
  }

  const getEditModeContent = () => {
    return (
      <>
        {getDefaultContent()}
      </>)
  }

  const getCompletedContent = () => {
    return (
      <div>
        <div className="completeImgCardNumber"><img
          {...paymentPageData?.cardType === "visa" ? { className: "completed-card-visa" } : { className: "completed-card-others" }}
          src={`${assetsBaseUrl
            }/assets/images/payment/${paymentPageData?.cardType === "none"
              ? "cardPlaceholder"
              : paymentPageData?.cardType === "mastercard"
                ? "master"
                : paymentPageData?.cardType
            }.png`}
          alt="selected card image"
        /><Body size="large">{maskCardNumber(paymentPageData?.cardNumber || "")}</Body></div>
        <Body size="large">{paymentPageData?.expirationDate}</Body>
        <Body size="large">{paymentPageData?.cardHolderName}</Body>
      </div>
    )
  }
  if (isComplete) {
    if (isEditMode) {
      return getEditModeContent();
    } else {
      return getCompletedContent();
    }
  } else {
    return getDefaultContent();
  }
}

const StyledRow = styled(Row)`
  > div {
    padding: 0rem;
  }
  padding: 0rem !important;
`;

export default PaymentNew;