import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VDSManager } from "@vds3/utilities";
import {
	resetGlobalSlice,
	setClientId,
	setSessionCartData,
	setWidgetInputParams,
} from "app/ducks/GlobalSlice";

import {
	clear4g5gCart,
	resetQualificationSlice
} from "pages/Qualification/ducks/QualificationSlice";
import SpinnerComponent from "common/components/Spinner/Spinner";
import "./WidgetsDecider.css";
import { resetBussProductsSlice } from "pages/BussProductsPage/ducks/BussProductSlice";
import { MarketplaceWidget } from "MarketplaceWidget";
import { MarketplaceGridwallWidget } from "MarketplaceGridwallWidget";
import { MarketplaceFWA } from "MarketPlaceFWA";
import { loadScript } from "app/ducks/utils";
import { resetCheckoutState } from "pages/Checkout/ducks/CheckoutSlice";
import { resetCreditSlice } from "pages/Checkout/CreditResult/CreditResultsNew/CreditSlice";
import { resetReviewSlice } from "pages/Checkout/ducks/ReviewSlice";
import { resetPaymentSlice } from "pages/Checkout/Payment/ducks/PaymentSlice";
import { resetLowerFunnelSlice } from "pages/CheckoutNew/ducks/LowerFunnelSlice";

function WidgetsDecider(props) {
	const dispatch = useDispatch();

	const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
	const lqData = useSelector((state) => state.ProspectWidgets_LQPage);
	const [showProspectWidgets, setShowProspectWidgets] = useState(false);
	const [showSuspendModal, setShowSuspendModal] = useState(false);

	const [t, setT] = useState(null);
	//const { t } = useTranslation(["app"]);

	//const appAEMContent = t("app", { ns: "app", returnObjects: true });

	const handler = (e) => {
		setShowProspectWidgets(true);
	};

	useMemo(() => {
		dispatch(setClientId(props.clientName));
		dispatch(resetGlobalSlice());

		dispatch(resetBussProductsSlice());
		dispatch(resetCreditSlice());
		dispatch(resetReviewSlice());
		dispatch(resetPaymentSlice());
		dispatch(resetCheckoutState());
		dispatch(resetLowerFunnelSlice());
		dispatch(resetQualificationSlice());
		dispatch(setSessionCartData({}));
		dispatch(clear4g5gCart({ launchType: "new" }));
		dispatch(setWidgetInputParams(props?.inputAttributes));
		if (window) {
			loadScript(process.env.PROSPECT_WIDGETS_APP_PIE_ENCRYPTION);
			loadScript(process.env.PROSPECT_WIDGETS_APP_PIE_GATEWAY);
			loadScript(process.env.PROSPECT_WIDGETS_APP_SONGBIRD);
		}
		if (!props?.type) {
			window.addEventListener("showProspectWidgets", handler);
		}
		return () => {
			if (!props?.type) {
				window.removeEventListener("showProspectWidgets", handler);
			}
		};
	}, []);


	if (!!props?.type || showProspectWidgets) {
		return (
			<div className="widget-warpper">
				<VDSManager>

					{props?.type === "fwa" ? <MarketplaceFWA currentFlow={props?.inputAttributes?.currentFlow?.value} /> : props?.type === "softwares" ? <MarketplaceGridwallWidget flow={props.flow} clientId={props.clientId} fromProspect={props?.fromProspect} /> : props?.type === "marketplace" ? <MarketplaceWidget flow={props.flow} clientId={props.clientId} /> : <></>}
				</VDSManager>
			</div>
		);
	}
	return <></>;
}

export default WidgetsDecider;
