import digitalData from './digitalData';
import { updateDataLayer, nullCheck } from './utils';
import CustomEvent from 'custom-event';
import cloneDeep from 'lodash/cloneDeep';
import {PageInfo} from './dataObjects';
import { setPageInfo } from './utils';

window.redirectionExecuted = false;
export default function init() {
    if(!window.digitalData) {
        window.digitalData = cloneDeep(digitalData); 
    }
}
 
export function dispatchEvent(eventName, data) {
    //updateDataLayer()
    const event = new CustomEvent(eventName, {
        detail: data
    });
    window.addEventListener('Virtual Page View',function (e) {
        console.log('Virtual Page View fired  '+ window['digitalData'].pageInfo.pageName);
    });
    window.dispatchEvent(event);
}

export function dispatchErrorEvent(data) {
    const event = new CustomEvent('Error', {
        detail: data
    });
    window.dispatchEvent(event);
}

export function trackPageInfo(pageName) {
    try {
        const pageInfo = setPageInfo(PageInfo);
        window['digitalData'] && (window.digitalData.pageInfo =  pageInfo);
        window.digitalData.events.push({eventName: "Virtual Page View", eventPayload: {...pageInfo}});
        dispatchEvent('Virtual Page View', {eventName: "Virtual Page View", ...pageInfo});
    } catch(err) {
        console.log(err);
    }
}

export function eventTrackingDL(eventName, eData) {
    try{
     
        let eventData = { 'eventName': eventName, 'eventPayload': {} };
        if (eventName === 'Keyword Search') {
            eventData['eventPayload'] = eData;
            window['digitalData'] && (window['digitalData'].searches = eventData);
        } else if (eventName === 'Business Order Delivery Details Submitted') {
            let shippingMethod = {
                "orderDeliveryShipmentInfo": {
                    "shipmentShippingMethod": eData.shippingType,
                    "shipmentShippingCost": eData.shippingCost,
                    "shipmentETA": eData.shippingDescription
                }
            }
            eventData['eventPayload'] = shippingMethod;
        }
        const event = new CustomEvent('Error', {
            detail: eventData
        });
        window.dispatchEvent(event);
        window['digitalData'] && window['digitalData'].events.push(eventData);
    } catch(err) {
        console.log(err);
    }
}

export function eventAddtocartTracking(eventName, eData, pageName, sourceName) {
    const cartData = JSON.parse(window?.localStorage?.getItem("_cartData"));
    try{
        let addToCartEventPayload = {
            'eventName': eventName,
            'eventPayload': {
                'packagesArray': [],
                'productsArray': [],
                'discountsArray': [],
                'cartRes': cartData

            }
        };
        let packgArray = {
            'packageInfo': {
                'packageID': '',
                'packagePriceTotal': ''
            }
        };
        let discountsArr = {
            "discountInfo": {
                "discountId": '',
                "discountName": '',
                "discountAmount": ''
            }
        }
        errorChange(packgArray.packageInfo);
        errorChange(discountsArr.discountInfo);

        if (pageName === 'deviceInfo') {
            let packageDevicesIncludedArray = [{
                'deviceInfo': {
                    'deviceID': eData.deviceId,
                    'deviceSKU': eData.id,
                    'deviceName': eData.productName,
                    'deviceBrand': eData.brandName,
                    'deviceCategory': eData.category,
                    'deviceDescription': 'undefined',
                    'deviceRetailPriceListed': eData.deviceRetailPriceListed,
                    'deviceSalePriceListed': eData.deviceSalePriceListed,
                    'deviceTenure': eData.tenure,
                    'deviceRatingAverage': eData.rating,
                    'deviceReviewTotal': eData.reviewCount,
                    'deviceDisclaimer': 'undefined',
                    'devicePromotionDetails': 'undefined',
                    'deviceColorOptionSelected': eData.colorDisplay,
                    'deviceStorageOptionSelected': eData.capacity,
                    'devicePaymentOptionSelected': eData.selectedPriceType,
                    "deviceCarrier": eData.deviceCarrier,
                    "deviceColor": eData.colorDisplay,
                    "deviceCondition": eData.deviceCondition,
                    "deviceManufacturer": eData.deviceManufacturer,
                    "deviceModel": eData.productName,
                    "deviceSize": eData.deviceSize,
                    "deviceStorageAmount": eData.capacity,
                    "deviceCompatibleWithNetworkFlag": 'Yes',
                    "deviceSkuId": eData.id,
                    "deviceTradeInValue": eData.deviceTradeInValue,
                    "simId": eData.simSku,
                    "simCondition": eData.simCondition,
                    'byod': (eData.byod ? 'Yes' : 'No'),
                    'isTradeIn': (eData.tradInInfo ? 'Yes' : 'No'),
                    "promotionId": '',
                    "promotionMessage": '',
                    "promotionAmount": '',
                }
            }];
            let productsArray = {
                'productInfo': {
                    'productID': eData.id,
                    'productName': eData.productName,
                    'productCategory': eData.category,
                    'productType': 'Device',
                    'productPrice': eData.selectedPrice ? eData.selectedPrice : eData?.devicePaymentFinalPrice,
                    'productQuantity': eData?.quantity,
                    'productRatingAverage': eData?.rating,
                    'productReviewsTotal': eData?.reviewCount,
                    'productBrand': eData?.brandName,
                    'productMemorySize': eData?.capacity,
                    'productColor': eData?.colorDisplay,
                    "productTradeInValue": eData.productTradeInValue,
                    'deviceTenure': eData.tenure,
                    "productPurchaseCategory": 'New Purchase',
                    "productPromotionId": '',
                    "productPromotionMessage": '',
                    "productPromotionAmount": '',
                    "productSubType": '',
                    "flowName":  window?.sessionStorage?.getItem('_currentFlow')
                }
            };
            if (window?.sessionStorage?.getItem('_currentFlow') === 'one-talk') {
                productsArray.productInfo.productSubType = 'Desk Phone'
            }
            if (eData.promoDetails) {
                packageDevicesIncludedArray[0].deviceInfo.promotionAmount = eData.promoDetails.discAmount;
                packageDevicesIncludedArray[0].deviceInfo.promotionId = eData.promoDetails.promotionId;
                packageDevicesIncludedArray[0].deviceInfo.promotionMessage = eData.promoDetails.promoMessages[1].badgeText;

                productsArray.productInfo.productPromotionAmount = eData.promoDetails.discAmount;
                productsArray.productInfo.productPromotionId = eData.promoDetails.promotionId;
                productsArray.productInfo.productPromotionMessage = eData.promoDetails.promoMessages[1].badgeText;
            }

            errorChange(packageDevicesIncludedArray[0].deviceInfo);
            errorChange(productsArray.productInfo);

            addToCartEventPayload.eventPayload.productsArray.push(productsArray);
            packgArray.packageInfo['packageDevicesIncludedArray'] = packageDevicesIncludedArray;

        } else if (pageName === 'accessoryInfo') {
            let packageAccessoriesIncludedArray = [
                {
                    'accessoryInfo': {
                        'accessoryID': '',
                        'accessorySKU': eData.id,
                        'accessoryName': eData.productName,
                        'accessoryBrand': eData.brandname,
                        'accessoryCategory': eData.category,
                        'accessoryRetailPriceListed': eData.retailPrice,
                        'accessorySalePriceListed': eData.finalBestPrice,
                        'accessoryColorOptionSelected': eData.colorDisplay
                    }
                }
            ];
            let productsArray = {
                'productInfo': {
                    'productID': eData.id,
                    'productName': eData.productName,
                    'productCategory': eData.category,
                    'productType': 'Accessory',
                    'productPrice': eData.finalBestPrice,
                    'productQuantity': eData.quantity,
                    'productRatingAverage': eData.rating,
                    'productReviewsTotal': eData.reviewCount,
                    'productBrand': eData.brandname,
                    'productMemorySize': '',
                    'productColor': eData.colorDisplay,
                    "flowName": window?.sessionStorage?.getItem('_currentFlow')
                }
            };
            errorChange(packageAccessoriesIncludedArray[0].accessoryInfo);
            errorChange(productsArray.productInfo);
            addToCartEventPayload.eventPayload.productsArray.push(productsArray);
            packgArray.packageInfo['packageAccessoriesIncludedArray'] = packageAccessoriesIncludedArray;

        } else if (pageName === 'planInfo') {
            if (eventName === 'Shopping Cart Remove') {
                Object.keys(eData).forEach((key) => {
                    if (key === sourceName) {
                        eData = eData[key]
                    }
                })
            }
            let packagePlansIncludedArray = [{
                'planInfo': {
                    'planID': eData?.planId || eData?.id || '',
                    'planSKU': eData?.planId || eData?.id || '',
                    'planName': eData?.displayName || eData?.title || '',
                    'planType': eData.type,
                    'planCategory': eData.planCatType,
                    'planRetailPriceListed': eData?.monthlyFee || eData?.priceDetails?.price || '',
                    'planSalePriceListed': eData.monthlyFee,
                    'planMemorySize': '',
                    'planMemoryThreshold': '',
                    'planSpeed': eData.b2bDisplayName ? eData.b2bDisplayName : '',
                    'planSpeedAlt': '',
                    'billingCode': window?.localStorage?.getItem('billingCode')
                }
            }];
            let productsArray = {
                'productInfo': {
                    'productID': eData.planId,
                    'productName': eData.displayName,
                    'productCategory': eData.category,
                    'productType': 'Plan', // eData.type,
                    'productPrice': eData.monthlyFee,
                    'productQuantity': 1,
                    'productRatingAverage': '',
                    'productReviewsTotal': '',
                    "flowName": window?.sessionStorage?.getItem('_currentFlow')
                }
            };
            if (sourceName === '5g') {
                packagePlansIncludedArray[0].planInfo.planMemorySize = 'Unlimited';
                packagePlansIncludedArray[0].planInfo.planSKU = eData.billingCode;
                packagePlansIncludedArray[0].planInfo.planCategory = '5G Business Internet';
                productsArray.productInfo.productID = eData.billingCode;
                productsArray.productInfo.productCategory = '5G Business Internet';
            }
            else if (sourceName === '4g') {
                packagePlansIncludedArray[0].planInfo.planMemorySize = 'Unlimited';
                packagePlansIncludedArray[0].planInfo.planSKU = eData.billingCode;
                productsArray.productInfo.productID = eData.billingCode;
                packagePlansIncludedArray[0].planInfo.planCategory = '4G Business Internet';
                productsArray.productInfo.productCategory = '4G Business Internet';

            }
            else if (sourceName === 'one-talk' || productsArray.productInfo.flowName === 'one-talk') {
                packagePlansIncludedArray[0].planInfo.planSKU = eData.billingCode;
                productsArray.productInfo.productID = eData.billingCode;
                packagePlansIncludedArray[0].planInfo.planCategory = 'One Talk';
                productsArray.productInfo.productCategory = 'One Talk';
                packagePlansIncludedArray[0].planInfo.planSalePriceListed = eData.monthlyTierFee;
                packagePlansIncludedArray[0].planInfo.planSalePriceListed = eData.monthlyTierFee;
                productsArray.productInfo.productPrice = eData.monthlyTierFee
            }
            errorChange(packagePlansIncludedArray[0].planInfo);
            errorChange(productsArray.productInfo);
            addToCartEventPayload.eventPayload.productsArray.push(productsArray);
            packgArray.packageInfo['packagePlansIncludedArray'] = packagePlansIncludedArray;

        } else if (pageName === 'featureInfo') {
            if (eventName === 'Shopping Cart Remove') {
                for (let i = 0; i < eData.length; i++) {
                    if (eData[i].billingCode === sourceName) {
                        eData = eData[i]
                    }
                }
            }
 
            let packageFeaturesIncludedArray = [
                {
                    'featureInfo': {
                        'featureID': "",
                        'featureSKU': eData.visFeatureCode,
                        'featureName': eData.featureDesc,
                        'featureCategory': eData.type
                    }
                }
            ];
            let productsArray = {
                'productInfo': {
                    'productID': eData.billingCode,
                    'productName': eData.name,
                    'productCategory': eData.category,
                    'productType': 'Feature',
                    'productPrice': eData.retailPrice,
                    'productQuantity': 1,
                    'productRatingAverage': 'undefined',
                    'productReviewsTotal': 'undefined',
                    "flowName": window?.sessionStorage?.getItem('_currentFlow')
                }
            };
            if (sourceName === 'one-talk') {
                productsArray.productInfo.productQuantity = eData.quantity;
            }
            errorChange(packageFeaturesIncludedArray[0].featureInfo);
            errorChange(productsArray.productInfo);
            addToCartEventPayload.eventPayload.productsArray.push(productsArray);
            packgArray.packageInfo['packageFeaturesIncludedArray'] = packageFeaturesIncludedArray;
        } else if (pageName === 'BYOD page') {
            let packageDevicesIncludedArray = [{
                'deviceInfo': {
                    'deviceID': eData.deviceId,
                    'deviceSKU': eData.deviceSkuId,
                    'deviceName': eData.productName,
                    'deviceBrand': eData.brandName,
                    'deviceCategory': eData.category,
                    'deviceDescription': 'undefined',
                    'deviceRetailPriceListed': eData.devicePaymentFinalPrice,
                    'deviceSalePriceListed': eData.devicePaymentPrice,
                    'deviceRatingAverage': eData.rating,
                    'deviceReviewTotal': eData.reviewCount,
                    'deviceDisclaimer': 'undefined',
                    'devicePromotionDetails': 'undefined',
                    'deviceColorOptionSelected': eData.colorDisplay,
                    'deviceStorageOptionSelected': eData.capacity,
                    'devicePaymentOptionSelected': eData.selectedPriceType,
                    "deviceCarrier": eData.deviceCarrier,
                    "deviceColor": eData.colorDisplay,
                    "deviceCondition": eData.deviceCondition,
                    "deviceManufacturer": eData.deviceManufacturer,
                    "deviceModel": eData.productName,
                    "deviceSize": eData.deviceSize,
                    "deviceStorageAmount": eData.capacity,
                    "deviceCompatibleWithNetworkFlag": eData.deviceCompatibleWithNetworkFlag,
                    "deviceSkuId": eData.deviceSkuId,
                    "deviceTradeInValue": eData.deviceTradeInValue,
                    "simId": eData.simSku,
                    "simCondition": eData.sim,
                    'byod': (eData.byod ? 'Yes' : 'No'),
                    'isTradeIn': (eData.tradInInfo ? 'Yes' : 'No'),
                }
            }];
            let productsArray = {
                'productInfo': {
                    "productID": eData.deviceSkuId,
                    "productName": eData.productName,
                    "productCategory": eData.category,
                    "productType": eData.productType,
                    "productPrice": eData.devicePaymentFinalPrice,
                    "productQuantity": eData.quantity,
                    "productRatingAverage": eData.rating,
                    "productReviewsTotal": eData.reviewCount,
                    "productTradeInValue": eData.productTradeInValue,
                    "productPurchaseCategory": 'BYOD',
                    "flowName": window?.sessionStorage?.getItem('_currentFlow')
                }
            };
            errorChange(packageDevicesIncludedArray[0].deviceInfo);
            errorChange(productsArray.productInfo);

            addToCartEventPayload.eventPayload.productsArray.push(productsArray);
            packgArray.packageInfo['packageDevicesIncludedArray'] = packageDevicesIncludedArray;
        } else if (pageName === 'trade-in') {

            let eData2 = eData.devices[0]
            let packageDevicesIncludedArray = [{
                'deviceInfo': {
                    'deviceID': eData2.deviceId,
                    'deviceSKU': eData2.recycleDeviceSku,
                    'deviceName': eData.selectedDevice.bccModel,
                    'deviceBrand': eData.brandSelected,
                    'deviceCategory': eData.selectedDevice.technology,
                    'deviceDescription': 'undefined',
                    'deviceRetailPriceListed': eData2.deviceRetailPriceListed,
                    'deviceSalePriceListed': eData2.deviceSalePriceListed,
                    'deviceRatingAverage': eData2.rating,
                    'deviceReviewTotal': eData2.reviewCount,
                    'deviceDisclaimer': 'undefined',
                    'devicePromotionDetails': 'undefined',
                    'deviceColor': eData2.color,
                    'deviceStorageAmount': eData2.capacity,
                    'devicePaymentOptionSelected': eData2.hasPaymentMethods,
                    'deviceCarrier': eData.selectedCarrier,
                    'deviceCondition': eData2.deviceCondition,
                    'deviceManufacturer': eData2.equipMake,
                    'deviceModel': eData2.equipModel,
                    'deviceSize': eData2.deviceSize,
                    'deviceTradeInValue': eData2.appliedPrice,
                    'byod': (eData2.byod ? 'Yes' : 'No'),
                    'isTradeIn': 'Yes',
                    'deviceCompatibleWithNetworkFlag': eData2.compatibleWithNetworkFlag,
                    'deviceSimCondition': eData2.deviceSimCondition,
                    'deviceSimId': eData2.simId

                }
            }];
            let productsArray = {
                'productInfo': {
                    'productID': eData2.recycleDeviceSku,
                    'productName': eData2.equipModel,
                    'productCategory': eData2.technology,
                    'productType': 'Device',
                    'productPrice': 0,
                    'productQuantity': 1,
                    'productRatingAverage': eData2.rating,
                    'productReviewsTotal': eData2.reviewCount,
                    'productBrand': eData.brandSelected,
                    'productMemorySize': eData2.capacity,
                    'productColor': eData2.color,
                    "productTradeInValue": eData2.appliedPrice,
                    "productPurchaseCategory": 'Trade-in',
                    "flowName": window?.sessionStorage?.getItem('_currentFlow')
                }
            };
            errorChange(packageDevicesIncludedArray[0].deviceInfo);
            errorChange(productsArray.productInfo);

            addToCartEventPayload.eventPayload.productsArray.push(productsArray);
            packgArray.packageInfo['packageDevicesIncludedArray'] = packageDevicesIncludedArray;
        } else if (pageName === 'routerInfo') {
            let packageDevicesIncludedArray = [{
                'deviceInfo': {
                    'deviceID': eData.deviceSku,
                    'deviceSKU': eData.deviceSku,
                    'deviceName': eData.productName,
                    'deviceBrand': eData.brandName,
                    'deviceCategory': eData.byor ? 'BYOD' : 'New',
                    'deviceDescription': 'undefined',
                    'deviceRetailPriceListed': eData.itemPrice,
                    'deviceSalePriceListed': eData.finalPrice,
                    'deviceRatingAverage': eData.rating,
                    'deviceReviewTotal': eData.reviewCount,
                    'deviceDisclaimer': eData.deviceDisclaimer,
                    'devicePromotionDetails': 'undefined',
                    'deviceColorOptionSelected': eData.color,
                    'deviceStorageOptionSelected': eData.capacity,
                    'devicePaymentOptionSelected': eData.priceType,
                    "deviceCarrier": eData.deviceCarrier,
                    "deviceColor": eData.color,
                    "deviceCondition": eData.deviceCondition,
                    "deviceManufacturer": eData.deviceManufacturer,
                    "deviceModel": eData.productName,
                    "deviceSize": eData.deviceSize,
                    "deviceStorageAmount": eData.capacity,
                    "deviceCompatibleWithNetworkFlag": 'Yes',
                    "deviceSkuId": eData.deviceSku,
                    "deviceTradeInValue": eData.deviceTradeInValue,
                    "simId": eData.simSku,
                    "simCondition": eData.simCondition,
                    'byod': (eData.byor ? 'Yes' : 'No'),
                    'isTradeIn': (eData.tradInInfo ? 'Yes' : 'No'),
                    "promotionId": '',
                    "promotionMessage": '',
                    "promotionAmount": '',

                }
            }];
            let productsArray = {
                'productInfo': {
                    'productID': eData.deviceSku,
                    'productName': eData.productName,
                    'productCategory': eData.category,
                    'productType': 'Device',
                    'productPrice': eData.byor ? 0 : eData.finalPrice,
                    'productQuantity': eData.quantity ? eData.quantity : 1,
                    'productRatingAverage': eData.rating,
                    'productReviewsTotal': '',
                    'productBrand': eData.brandName,
                    'productMemorySize': 'Unlimited',
                    'productColor': eData.color,
                    "productTradeInValue": eData.productTradeInValue,
                    "productPurchaseCategory": eData.byor ? 'BYOD' : 'New Purchase',
                    "productPromotionId": '',
                    "productPromotionMessage": '',
                    "productPromotionAmount": '',

                    "productSubType": 'router',
                    "productPaymentFrequency": eData.priceType,
                    "productDuration": eData.tenure,
                    "productSpeed": '',
                    "productStatus": 'Product added',
                    "productSpeedAlt": "",
                    "flowName": window?.sessionStorage?.getItem('_currentFlow')
                }
            };
            let selectedPlan = eData?.selectedPlanDetails;
            let packagePlansIncludedArray = [{
                'planInfo': {
                    'planID': selectedPlan?.planId || selectedPlan?.id || selectedPlan?.billingCode || '',
                    'planSKU': selectedPlan?.planId || selectedPlan?.id || '',
                    'planName': selectedPlan?.displayName || selectedPlan?.title || '',
                    'planType': selectedPlan.type,
                    'planCategory': selectedPlan.planCatType || selectedPlan?.categoryCode,
                    'planRetailPriceListed': selectedPlan?.monthlyFee || selectedPlan?.priceDetails?.price || '',
                    'planSalePriceListed': selectedPlan.monthlyFee,
                    'planMemorySize': '',
                    'planMemoryThreshold': '',
                    'planSpeed': selectedPlan.b2bDisplayName ? selectedPlan.b2bDisplayName : '',
                    'planSpeedAlt': '',
                    'billingCode': window?.localStorage?.getItem('billingCode')
                }
            }];
            let productPlanArray = {
                'productInfo': {
                    'productID': selectedPlan.planId || selectedPlan?.billingCode,
                    'productName': selectedPlan.displayName,
                    'productCategory': selectedPlan.category || selectedPlan?.categoryCode,
                    'productType': 'Plan', // selectedPlan.type,
                    'productPrice': selectedPlan.monthlyFee,
                    'productQuantity': 1,
                    'productRatingAverage': '',
                    'productReviewsTotal': '',
                    "flowName": window?.sessionStorage?.getItem('_currentFlow')
                }
            };
            if (sourceName === '5g') {
                productsArray.productInfo.productCategory = '5G Business Router'
            }
            else if (sourceName === '4g') {
                productsArray.productInfo.productCategory = '4G Business Router'

            }
            if (eData.promoDetails) {
                packageDevicesIncludedArray[0].deviceInfo.promotionAmount = eData.promoDetails.discAmount;
                packageDevicesIncludedArray[0].deviceInfo.promotionId = eData.promoDetails.promotionId;
                packageDevicesIncludedArray[0].deviceInfo.promotionMessage = eData.promoDetails.promoMessages[1].badgeText;

                productsArray.productInfo.productPromotionAmount = eData.promoDetails.discAmount;
                productsArray.productInfo.productPromotionId = eData.promoDetails.promotionId;
                productsArray.productInfo.productPromotionMessage = eData.promoDetails.promoMessages[1].badgeText;
            }

            errorChange(packageDevicesIncludedArray[0].deviceInfo);
            errorChange(productsArray.productInfo);

            errorChange(packagePlansIncludedArray[0].planInfo);
            errorChange(productPlanArray.productInfo);

            addToCartEventPayload.eventPayload.productsArray.push(productsArray);
            packgArray.packageInfo['packageDevicesIncludedArray'] = packageDevicesIncludedArray;

            addToCartEventPayload.eventPayload.productsArray.push(productPlanArray);
            packgArray.packageInfo['packagePlansIncludedArray'] = packagePlansIncludedArray;
        } else if (pageName === '4g5g' || sourceName === 'clear_cart_popup') {

                let productsArray =[],planPackgArray = [],devicePackgArray =[];
                Object.keys(cartData?.devices).forEach((key, i) => {
                    let device = cartData?.devices[key]?.deviceFullDetail;
                    let plan = cartData?.devices[key]?.plan;
                    if (device) {
                        let packageDevicesIncludedArray = {
                            'deviceInfo': {
                                'deviceID': device?.deviceId,
                                'deviceSKU': device?.deviceSku,
                                'deviceName': device?.productName,
                                'deviceBrand': device?.brandName,
                                'deviceCategory': device?.category,
                                'deviceRetailPriceListed': device?.itemPriceWithOutDiscount,
                                'deviceSalePriceListed': device?.selectedPrice,
                                'deviceRatingAverage': device?.rating,
                                'deviceReviewTotal': device?.reviewCount,
                                'deviceDisclaimer': device?.deviceDisclaimer,
                                'devicePromotionDetails': 'undefined',
                                'deviceColorOptionSelected': device?.color,
                                'deviceStorageOptionSelected': device?.capacity,
                                'devicePaymentOptionSelected': device?.priceType,
                                "deviceCarrier": device?.deviceCarrier,
                                "deviceColor": device?.color,
                                "deviceCompatibleWithNetworkFlag": 'Yes',
                                "simId": device?.simSku,
                                'byod': (device?.byod ? 'Yes' : 'No'),
                                'byor': (device?.byor ? 'Yes' : 'No'),
                                'isTradeIn': (device?.tradInInfo ? 'Yes' : 'No')    
                            }
                        };
                        let deviceProductArray = {
                            'productInfo': {
                                'productID': device?.deviceId,
                                'productName': device?.productName,
                                'productCategory': device?.category,
                                'productType': 'Device',
                                'productPrice': device?.selectedPrice,
                                'productQuantity': 1,
                                'productRatingAverage': device?.rating,
                                'productReviewsTotal': '',
                                'productBrand': device?.brandName,
                                'productMemorySize': 'Unlimited',
                                "productPurchaseCategory": device?.byor ? 'BYOD' : 'New Purchase',
                                "productPromotionId": '',
                                "productPromotionMessage": '',
                                "productPromotionAmount": '',
                                "productSubType": 'router',
                                "productDuration": device?.tenure,
                                "productSpeed": '',
                                "productStatus": 'Product added',
                            }
                        };
                        devicePackgArray.push(packageDevicesIncludedArray);
                        productsArray.push(deviceProductArray)
                    }
                    if(plan){
                        let packagePlansIncludedArray = {
                            'planInfo': {
                                'planID': plan?.billingCode,
                                'planSKU': plan?.planId ,
                                'planName': plan?.displayName,
                                'planType': plan?.type,
                                'planCategory': plan?.planCatType,
                                'planRetailPriceListed': plan?.monthlyFee,
                                'planSalePriceListed': plan?.monthlyFee,
                                'planSpeed': plan?.b2bDisplayName,
                                'billingCode': plan?.billingCode
                            }
                        };
                        let planProductArray = {
                            'productInfo': {
                                'productID': plan?.billingCode,
                                'productName': plan?.displayName,
                                'productCategory': plan?.category,
                                'productType': 'Plan', // plan?.type,
                                'productPrice': plan?.monthlyFee,
                                'productQuantity': 1,
                                'productRatingAverage': '',
                                'productReviewsTotal': '',
                                "flowName": window?.sessionStorage?.getItem('_currentFlow')
                            }
                        };
                        planPackgArray.push(packagePlansIncludedArray);
                        productsArray.push(planProductArray)
                    }
                });
                packgArray.packageInfo['packageDevicesIncludedArray'] = devicePackgArray;
                packgArray.packageInfo['packagePlansIncludedArray'] = planPackgArray;
                
                addToCartEventPayload.eventPayload.packagesArray = packgArray;
                addToCartEventPayload.eventPayload.productsArray = productsArray;
        }
        if (addToCartEventPayload.eventPayload.productsArray?.length > 0 ) {
                const cusEvent = new CustomEvent(eventName, {
                    detail: addToCartEventPayload,
                    bubbles: true,
                    cancelable: true
                });
            window.dispatchEvent(cusEvent);
            window['digitalData'] && window['digitalData'].events.push(addToCartEventPayload);
        }
    } catch(err) {
        console.log(err);
    }
}


export function errorChange(edata = {}) {
    try{
        Object?.keys(edata)?.forEach((k, indx) => {
            if (edata[k] == undefined || edata[k] == "undefined" || edata[k] == null || edata[k] === '') {
                edata[k] = "undefined";
            }
        });
    } catch(err) {
        console.log(err);
    }
}

export function eventShoppingCartInfoTracking(eventName, eData1, pageName, sourceName) {
    try{
        const sections = window.location.pathname.split('/').filter(item => item).slice(0, window.location.pathname.split('/').length);
        let byodCount = 0;
        let CpoCount = 0;
        let tradeInCount = 0;
        let newDevice = 0;
        let tradeInTotal = 0;
        let count = 0;
        let shoppingCartInfo;
        if (sourceName === 'clear_cart' || sourceName === 'clear_cart_switch_modal') {
            shoppingCartInfo = {
                'cartRes':eData1,
                'productsArray': [],
                'packagesArray': [],
                'discountsArray': [],
            };
        }
        else {
            shoppingCartInfo = {
                'cartRes':eData1,
                'shoppingCartID': '',
                'shoppingCartStatus': 'New', //New, Abandoned, Recovered
                // 'shoppingCartSubTotal': '',
                // 'shoppingCartTaxTotal': '',
                'shoppingCartOrderTotal': (sourceName === 'cart_switch') ? eData1?.totalAmounts?.dueToday : (eData1?.priceSummary.total?.dueToday || ''),
                // 'shoppingCartOrderTotalMonthly': '',
                'shoppingCartOrderTaxTotalMonthly': eData1?.priceSummary?.tax?.dueToday || '',
                'shoppingCartPreferredContactMethod': '',
                'scheduledContactTimeSlot': '',
                'shoppingCartPackagesIncludedArray': [],
                'shoppingCartOrderDeliveryDetails': {
                    'orderDeliveryType': 'Online', //Online vs. in-store
                    'orderDeliveryShipmentsArray': [] //Accepts Order Delivery Shipment Objects
                },
                'shoppingCartTradeInTotalEstimate': tradeInTotal,
                'shoppingCartNewDevicesTotal': newDevice,
                'shoppingCartCpoDevicesTotal': CpoCount,
                'shoppingCartByodDevicesTotal': byodCount,
                'shoppingCartTradeInDevicesTotal': tradeInCount,
                'discountsArray': [],
                'shoppingCartDiscountsTotalAmount': 0,
                'shoppingCartTotalNumberOfDiscounts': 0,
                'sharedCartId': eData1?.cart?.cartId || '',
                'cartId': eData1?.cart?.cartId || ''
                // 'sharedCartId': eData1.sharedCartId || ''
            };
        }

        let packArray = {
            "packageInfo": {
                "packageID": '',
                "packagePriceTotal": '',
                "packagePlansIncludedArray": [],
                "packageDevicesIncludedArray": [],
                "packageAccessoriesIncludedArray": [],
                "packageFeaturesIncludedArray": []
            }
        };

        errorChange(shoppingCartInfo);
        errorChange(packArray.packageInfo);
        let productsArray = [];
        // map(eData1?.cart?.packages , (detail, name) => {

        // });
        let deviceList = eData1?.cart?.packages;
        let accessoryList = {};//eData1['devices']['accessoryList'] = eData1?.cart?.accessories || {};


        Object.keys(deviceList).forEach((key, i) => {
            let productName = deviceList[key]?.productName ;
            if (deviceList[key]?.byod === true) {
                byodCount++
                shoppingCartInfo.shoppingCartByodDevicesTotal = byodCount;
            }
            if (productName.includes('Certified Pre-Owned') || productName.includes('CPO')) {
                CpoCount++
                shoppingCartInfo.shoppingCartCpoDevicesTotal = CpoCount;
            }
            if (deviceList[key]?.tradInInfo) {
                tradeInCount++
                shoppingCartInfo.shoppingCartTradeInDevicesTotal = tradeInCount;
                shoppingCartInfo.shoppingCartTradeInTotalEstimate += deviceList[key]?.tradInInfo.itemPrice;
            }
            if (!deviceList[key]?.byod && !productName.includes('Certified Pre-Owned') && !productName.includes('CPO')) {
                newDevice++
                if (sourceName !== 'clear_cart' && sourceName !== 'clear_cart_switch_modal') {
                    shoppingCartInfo.shoppingCartNewDevicesTotal = newDevice;
                }
            }

            let device = deviceList[key];
            if (device) {
                let packageDevicesIncludedArray = {
                    'deviceInfo': {
                        'deviceID': device?.deviceId,
                        'deviceSKU': device?.deviceId,
                        'deviceName': device?.productName,
                        'deviceBrand': device?.brand,
                        'deviceCategory': device?.deviceCategory,
                        'deviceDescription':  device?.description || 'undefined',
                        'deviceRetailPriceListed': device?.itemRetailPrice,
                        'deviceSalePriceListed': device?.deviceSalePriceListed,
                        'deviceRatingAverage': device?.rating,
                        'deviceReviewTotal': device?.reviewCount,
                        'deviceDisclaimer': 'undefined',
                        'devicePromotionDetails': 'undefined',
                        'deviceColorOptionSelected': device?.colorDisplay,
                        'deviceStorageOptionSelected': device?.capacity,
                        'devicePaymentOptionSelected': device?.selectedPriceType,
                        'quantity': device?.quantity,
                        'deviceCarrier': device?.deviceCarrier,
                        'deviceColor': device?.colorDisplay,
                        'deviceCondition': device?.deviceCondition,
                        "deviceManufacturer": device?.deviceManufacturer,
                        "deviceModel": device?.productName,
                        "deviceSize": device?.deviceSize, //The size of the device
                        "deviceStorageAmount": device?.capacity, //Amount of storage available on the device (eg.g 64GB, etc.)
                        'deviceTradeInValue': (device?.tradInInfo ? device?.tradInInfo?.appliedPrice : device?.appliedPrice),
                        'isTradeIn': (device?.tradInInfo ? 'Yes' : 'No'),
                        'byod': (device?.byod ? 'Yes' : 'No'),
                        'deviceCompatibleWithNetworkFlag': device?.deviceCompatibleWithNetworkFlag,
                        'deviceSimCondition': device?.deviceSimCondition,
                        'deviceSimId': device?.deviceSimId,
                        'deviceMonthlyStartingPriceListed': device?.finalMonthlyPrice,
                        'devicePriceSubtotal': device?.devicePriceSubtotal,
                        'devicePriceTaxTotal': device?.devicePriceTaxTotal,
                        'devicePriceTotal': device?.deviceFinalPrice,
                        'discountsArray': [],
                        "promotionId": '',
                        "promotionMessage": '',
                        "promotionAmount": '',
                    }
                };
                let productArray = {
                    'productInfo': {
                        'productID': device?.id || device?.deviceId,
                        'productName': device?.productName,
                        'productCategory': device?.category,
                        'productType': 'Device',
                        'productPrice': device?.itemPrice,
                        'productQuantity': 1,
                        'productRatingAverage': device?.rating,
                        'productReviewsTotal': device?.reviewCount,
                        'productBrand': device?.brand,
                        'productMemorySize': device?.capacity,
                        'productColor': device?.colorDisplay,
                        "productTradeInValue": device?.productTradeInValue,
                        "productDiscountsTotalAmount": 0,
                        "productPromotionId": '',
                        "productPromotionMessage": '',
                        "productPromotionAmount": '',
                        "productPurchaseCategory": device?.productPurchaseCategory,
                        "productSpeed": '',
                        "productSpeedAlt": '',
                        "productStatus": '',
                        "productMemoryThreshold": '',
                        "productSubType": '',
                        "productPaymentFrequency": device?.priceType,
                        "productDuration": '',
                        "flowName": window.sessionStorage.getItem('_flowName'),
                        "productRetailPriceListed": device?.itemRetailPrice

                        // device.packageType
                    }
                };


                // try {
                //     if (device?.plan && (device?.packageType === 'FIVEG' || device?.packageType === 'FOURG')) {
                //         productArray.productInfo.productPurchaseCategory = (device?.byor ? 'New' : 'Used');
                //         productArray.productInfo.productSpeed = '';
                //         productArray.productInfo.productMemorySize = 'Unlimited';
                //         productArray.productInfo.productReviewsTotal = '';
                //         productArray.productInfo.productCategory = (device?.plan && device?.packageType) === 'FOURG' ? '4G Business Router' : '5G Business Router';
                //         productArray.productInfo.productSpeedAlt = '';
                //         productArray.productInfo.productStatus = 'product added';
                //         productArray.productInfo.productMemoryThreshold = '';
                //         productArray.productInfo.productSubType = 'router';
                //         productArray.productInfo.productPaymentFrequency = device?.priceType;
                //         productArray.productInfo.productDuration = device?.tenure;

                //         productArray.productInfo.productPrice = device?.deviceFinalPrice;


                //     }
                //     else if (window?.sessionStorage?.getItem('_currentFlow') === 'one-talk') {
                //         productArray.productInfo.productSubType = 'Desk Phone';
                //         productArray.productInfo.productPurchaseCategory = (device?.byor ? 'New' : 'Used');
                //         productArray.productInfo.productSpeed = '';
                //         productArray.productInfo.productMemorySize = 'Unlimited';
                //         productArray.productInfo.productReviewsTotal = '';
                //         productArray.productInfo.productCategory = "4G LTE Smartphone"
                //         productArray.productInfo.productSpeedAlt = '';
                //         productArray.productInfo.productStatus = 'product added';
                //         productArray.productInfo.productMemoryThreshold = '';
                //         productArray.productInfo.productPaymentFrequency = device?.priceType;
                //         productArray.productInfo.productDuration = device?.tenure;

                //         productArray.productInfo.productPrice = device?.deviceFinalPrice;
                //     }

                //     if (device?.offers?.sbdOffer) {
                //         let offerDetails = device?.offers?.sbdOffer
                //         packageDevicesIncludedArray.deviceInfo.promotionId = offerDetails?.offerId;
                //         packageDevicesIncludedArray.deviceInfo.promotionMessage = offerDetails?.offerDesc;
                //         packageDevicesIncludedArray.deviceInfo.promotionAmount = offerDetails?.discAmt;

                //         productArray.productInfo.productPromotionId = offerDetails?.offerId;
                //         productArray.productInfo.productPromotionMessage = offerDetails?.offerDesc;
                //         productArray.productInfo.productPromotionAmount = offerDetails?.discAmt;

                //     }
                // }
                // catch (e) {

                // }
                // if (device?.byod || device?.byor) {
                //     productArray.productInfo.productPurchaseCategory = 'BYOD';

                // }
                // else {
                //     productArray.productInfo.productPurchaseCategory = 'New Purchase';
                // }
                // if (device?.byod) {
                //     productArray.productInfo.productID = device?.deviceSkuId;
                //     packageDevicesIncludedArray.deviceInfo.deviceID = device?.imei;
                // }
                errorChange(packageDevicesIncludedArray?.deviceInfo);
                errorChange(productArray?.productInfo);
                productsArray.push(productArray);
                if (sourceName === 'clear_cart' || sourceName === 'clear_cart_switch_modal') {
                    shoppingCartInfo.packagesArray.push(packageDevicesIncludedArray);
                }
                else {
                    packArray.packageInfo.packageDevicesIncludedArray.push(packageDevicesIncludedArray);
                }


                // if (device.tradInInfo) {
                //     let tradeInPackageDevicesIncludedArray = {
                //         'deviceInfo': {
                //             'deviceID': device?.tradInInfo?.deviceId,
                //             'deviceSKU': device?.tradInInfo?.recycleDeviceSku,
                //             'deviceName': device?.tradInInfo?.equipModel,
                //             'deviceBrand': device?.tradInInfo?.equipMake,
                //             'deviceCategory': device?.tradInInfo?.category,
                //             'deviceDescription': 'undefined',
                //             'deviceRetailPriceListed': device?.tradInInfo?.itemRetailPrice,
                //             'deviceSalePriceListed': device?.tradInInfo?.deviceSalePriceListed,
                //             'deviceRatingAverage': device?.tradInInfo?.rating,
                //             'deviceReviewTotal': device?.tradInInfo?.reviewCount,
                //             'deviceDisclaimer': 'undefined',
                //             'devicePromotionDetails': 'undefined',
                //             'deviceColorOptionSelected': device?.tradInInfo?.color,
                //             'deviceStorageOptionSelected': device?.tradInInfo?.capacity,
                //             'devicePaymentOptionSelected': device?.tradInInfo?.selectedPriceType,
                //             'quantity': device?.tradInInfo?.quantity,
                //             'deviceCarrier': device?.tradInInfo?.equipCarrier,
                //             'deviceColor': device?.tradInInfo?.colorDisplay,
                //             'deviceCondition': device?.tradInInfo?.deviceCondition,
                //             "deviceManufacturer": device?.tradInInfo?.equipMake,
                //             "deviceModel": device?.tradInInfo?.equipModel,
                //             "deviceSize": device?.tradInInfo?.deviceSize, //The size of the device
                //             "deviceStorageAmount": device?.tradInInfo?.capacity, //Amount of storage available on the device (eg.g 64GB, etc.)
                //             'deviceTradeInValue': device?.tradInInfo?.appliedPrice,
                //             'isTradeIn': (device?.tradInInfo ? 'Yes' : 'No'),
                //             'byod': (device?.byod ? 'Yes' : 'No'),
                //             'deviceCompatibleWithNetworkFlag': device?.tradInInfo?.deviceCompatibleWithNetworkFlag,
                //             'deviceSimCondition': device?.tradInInfo?.deviceSimCondition,
                //             'deviceSimId': device?.tradInInfo?.deviceSimId,

                //         }
                //     }

                //     let productArrayTrade = {
                //         'productInfo': {
                //             'productID': device?.tradInInfo?.recycleDeviceSku,
                //             'productName': device?.tradInInfo?.equipModel,
                //             'productCategory': device?.category,
                //             'productType': 'Device',
                //             'productPrice': 0,
                //             'productQuantity': 1,
                //             'productRatingAverage': device?.tradInInfo?.rating,
                //             'productReviewsTotal': device?.tradInInfo?.reviewCount,
                //             'productBrand': device?.tradInInfo?.equipMake,
                //             'productMemorySize': device?.tradInInfo?.capacity,
                //             'productColor': device?.tradInInfo?.colorDisplay,
                //             "productTradeInValue": device?.tradInInfo?.appliedPrice,
                //             "productPurchaseCategory": 'Trade-in'
                //         }
                //     };
                //     productsArray.push(productArrayTrade);

                //     errorChange(tradeInPackageDevicesIncludedArray?.deviceInfo);
                //     errorChange(productArrayTrade?.productInfo);

                //     if (sourceName === 'clear_cart' || sourceName === 'clear_cart_switch_modal') {
                //         shoppingCartInfo.packagesArray.push(tradeInPackageDevicesIncludedArray);
                //     }
                //     else {
                //         packArray.packageInfo.packageDevicesIncludedArray.push(tradeInPackageDevicesIncludedArray);

                //     }
                // }
            }
            if (errorCheck(device.plan)) {
                let packagePlansIncludedArray = {
                    'planInfo': {
                        'planID': device?.plan?.pricePlanCode,
                        'planSKU': device?.plan?.pricePlanCode ,
                        'planName': device?.plan?.pricePlanDesc, 
                        'planType': device?.plan?.planType,
                        'planCategory': device?.plan?.primaryCategory,
                        'planRetailPriceListed': device?.plan?.planPrice,
                        'planSalePriceListed': device?.plan?.planPreviousPrice,
                        'discountsArray': device?.plan?.offerApplied,
                    }
                };
                let productArray = {
                    'productInfo': {
                        'productID': device?.plan?.pricePlanCode,
                        'productName': device?.plan?.pricePlanDesc, 
                        'productCategory': device?.plan?.primaryCategory,
                        'productType':device?.plan?.planType,
                        'productPrice': device?.plan?.planPrice,
                        'productQuantity': 1,
                        'productRatingAverage': '',
                        'productReviewsTotal': '',
                        'productDiscountsTotalAmount': 0,
                        "flowName": window.sessionStorage.getItem('_flowName'),
                    }
                };

                try {
                    if (device?.plan && device?.packageType === 'FIVEG') {
                        packagePlansIncludedArray.planInfo.planSKU = device?.plan?.billingCode;
                        productArray.productInfo.productID = device?.plan?.billingCode;
                        packagePlansIncludedArray.planInfo.planCategory = '5G Business Internet';
                        productArray.productInfo.productCategory = '5G Business Internet';
                    }
                    else if (device?.plan && device?.packageType === 'FOURG') {
                        packagePlansIncludedArray.planInfo.planSKU = device?.plan?.billingCode;
                        productArray.productInfo.productID = device?.plan?.billingCode;
                        packagePlansIncludedArray.planInfo.planCategory = '4G Business Internet';
                        productArray.productInfo.productCategory = '4G Business Internet';
                    }
                    else if (device.plan && device.packageType === 'ONETALK') {
                        packagePlansIncludedArray.planInfo.planSKU = device?.plan?.billingCode;
                        productArray.productInfo.productID = device?.plan?.billingCode;
                    }

                }
                catch (e) {
                    console.error(e, 'shopping cart page view error')
                }

                if (device?.plan?.offerApplied && device?.plan.offerApplied?.length > 0) {
                    productArray.productInfo.productDiscountsTotalAmount = device?.plan?.previousMonthlyFee - device?.plan?.monthlyFee;


                    if (sourceName !== 'clear_cart' && sourceName !== 'clear_cart_switch_modal') {
                        shoppingCartInfo.shoppingCartDiscountsTotalAmount += productArray?.productInfo?.productDiscountsTotalAmount;
                        shoppingCartInfo.shoppingCartTotalNumberOfDiscounts++;
                    }
                    count++
                    let discountsArr = {
                        "discountInfo": {
                            "discountId": count,
                            "discountName": device?.plan?.offerApplied[0],
                            "discountAmount": productArray?.productInfo?.productDiscountsTotalAmount
                        }
                    }
                    //packagePlansIncludedArray.planInfo.discountsArray.push(discountsArr);
                    errorChange(discountsArr?.discountInfo);
                    shoppingCartInfo.discountsArray.push(discountsArr);
                }
                errorChange(packagePlansIncludedArray?.planInfo);
                errorChange(productArray?.productInfo);
                productsArray.push(productArray);
                if (sourceName === 'clear_cart' || sourceName === 'clear_cart_switch_modal') {
                    shoppingCartInfo.packagesArray.push(packagePlansIncludedArray);
                }
                else {
                    packArray.packageInfo.packagePlansIncludedArray.push(packagePlansIncludedArray);

                }
            }
            if (errorCheck(device.features)) {
                let features = device.features;
                Object.keys(features).forEach((k, indx) => {
                    let fr = features[k];
                    let packageFeaturesIncludedArray = {
                        'featureInfo': {
                            'featureID': "",
                            'featureSKU': fr.visFeatureCode,
                            'featureName': fr.featureDesc,
                            'featureCategory': fr.type,
                            'discountsArray': [],

                        }
                    }
                    let productArray = {
                        'productInfo': {
                            'productID': fr.visFeatureCode,
                            'productName': fr.featureDesc,
                            'productCategory': fr.type,
                            'productType': 'Feature',
                            'productPrice': (sourceName === 'clear_cart_switch_modal') || (sourceName === 'cart_switch') || (window?.sessionStorage?.getItem('_currentFlow') === 'one-talk') ? fr.bestPrice : fr.retailPrice,
                            'productQuantity': 1,
                            'productRatingAverage': 'undefined',
                            'productReviewsTotal': 'undefined',
                            'productDiscountsTotalAmount': 0,
                            "flowName": 'Prospect Unified'
                        }
                    };
                    errorChange(packageFeaturesIncludedArray?.featureInfo);
                    errorChange(productArray?.productInfo);
                    productsArray.push(productArray);
                    if (sourceName === 'clear_cart' || sourceName === 'clear_cart_switch_modal') {
                        shoppingCartInfo.packagesArray.push(packageFeaturesIncludedArray);
                    } else {
                        packArray.packageInfo.packageFeaturesIncludedArray.push(packageFeaturesIncludedArray);

                    }

                });

            }

        });
        Object.keys(accessoryList).forEach((key, i) => {
            let accessory = accessoryList[key];
            let packageAccessoriesIncludedArray =
            {
                'accessoryInfo': {
                    'accessoryID': '',
                    'accessorySKU': accessory?.id,
                    'accessoryName': accessory?.productName,
                    'accessoryBrand': accessory?.brandname,
                    'accessoryCategory': accessory?.category,
                    'accessoryRetailPriceListed': accessory?.retailPrice,
                    'accessorySalePriceListed': accessory?.finalBestPrice,
                    'accessoryColorOptionSelected': accessory?.colorDisplay,
                    'discountsArray': [],

                }
            };
            let productArray = {
                'productInfo': {
                    'productID': accessory?.id,
                    'productName': accessory?.productName,
                    'productCategory': accessory?.category,
                    'productType': 'Accessory',
                    'productPrice': accessory?.finalBestPrice,
                    'productQuantity': accessory?.quantity,
                    'productRatingAverage': accessory?.rating,
                    'productReviewsTotal': accessory?.reviewCount,
                    'productBrand': accessory?.brandname,
                    'productMemorySize': '',
                    'productColor': accessory?.colorDisplay,
                    'productDiscountsTotalAmount': 0
                }
            };
            errorChange(packageAccessoriesIncludedArray?.accessoryInfo);
            errorChange(productArray?.productInfo);
            productsArray.push(productArray);
            packArray.packageInfo.packageAccessoriesIncludedArray.push(packageAccessoriesIncludedArray);
        });
        shoppingCartInfo['productsArray'] = productsArray;
        if (sourceName !== 'clear_cart' && sourceName !== 'clear_cart_switch_modal') {
            shoppingCartInfo.shoppingCartPackagesIncludedArray.push(packArray);
        }
        errorChange(shoppingCartInfo);

        if (eventName === 'Unified Shopping Cart Page View' || eventName === 'Shopping Cart Remove' || eventName === 'Unified Review Order Page Viewed') {
            let shopingCartPayload = { eventName: eventName, eventPayload: {...shoppingCartInfo,isReact:true,isAngular:false} };
            const cusEvent = new CustomEvent(eventName, {
                detail: shopingCartPayload,
                bubbles: true,
                cancelable: true
            });
            errorChange(shopingCartPayload);
            window['digitalData'] && window['digitalData'].events.push(shopingCartPayload);
            window.dispatchEvent(cusEvent);
            window['digitalData'].isUniqROS = eventName === 'Unified Review Order Page Viewed' ? false:true;
        } else if ((eventName === 'Cart Switch Initiated') || (eventName === 'Cart Switch Modal Presented')) {
            let shopingCartPayload = { eventName: eventName, eventPayload: {...shoppingCartInfo,isReact:true,isAngular:false} };
            const cusEvent = new CustomEvent(eventName, {
                detail: shopingCartPayload,
                bubbles: true,
                cancelable: true
            });
            errorChange(shopingCartPayload);
            window['digitalData'] && window['digitalData'].events.push(shopingCartPayload);
            window.dispatchEvent(cusEvent);
        }

        window['digitalData'] && (window['digitalData'].shoppingCartInfo = shoppingCartInfo);
    } catch(err) {
        console.log(err);
    }
}

export function errorCheck(checkValue) {
    try {
        var returnVal = "";
        if (typeof checkValue != undefined || (typeof checkValue != undefined && checkValue && checkValue != "" && checkValue != null)) returnVal = checkValue;
        // if(returnWithError) return typeof checkValue;
        return returnVal;
    } catch(err) {
        console.log(err)
    }
}

export function orderShoppingInfoUpdate(SubTotal, TaxTotal, TotalMonthly) { 
    try {
        eventShoppingCartInfoTracking('', JSON.parse(localStorage._cartData), 'shoppingcartInfo', '');
        if (window['digitalData']) {
            window['digitalData']['shoppingCartInfo']['shoppingCartSubTotal'] = SubTotal;
            window['digitalData']['shoppingCartInfo']['shoppingCartOrderTotalMonthly'] = TotalMonthly;
            window['digitalData']['shoppingCartInfo']['shoppingCartTaxTotal'] = TaxTotal;
        }
    } catch(err) {
        console.log(err)
    }
}

export function eventSaveCartInfoTracking(eventName, eData = {}, pageName, sourceName) {
    try {
        let saveCartEventPayload = {
            "eventName": eventName,
            "eventPayload": {}
        }
        if (eventName === 'Save Cart Form Presented') {
            saveCartEventPayload.eventPayload = eData;
        } else {
            eventShoppingCartInfoTracking('', JSON.parse(localStorage._cartData), 'shoppingcartInfo', 'saveCart');
            window['digitalData'] && (saveCartEventPayload.eventPayload = window['digitalData']['shoppingCartInfo']);
        }
        errorChange(saveCartEventPayload);
        const cusEvent = new CustomEvent(eventName, {
            detail: saveCartEventPayload,
            bubbles: true,
            cancelable: true
        });
        window.dispatchEvent(cusEvent);
        window['digitalData'] && window['digitalData'].events.push(saveCartEventPayload);
    } catch(err) {
        console.log(err)
    }
}

export function evntFilterByTracking(eventName, eData = {}, pageName, sourceName) {
    try {
        let filterEventPayload = { 'eventName': eventName, 'eventPayload': {} };
    
        if (eventName === 'Filter By') {
            let filtersAddedArray = [], eDataFilter;
            if (pageName === 'accessory_gridwall_page') {
                eDataFilter = eData.accessoryFilters;
            } else if (pageName === 'device_gridwall_page') {
                eDataFilter = eData.deviceFilters;
            }
            Object.keys(eDataFilter).forEach((key, i) => {
                let filersList = eDataFilter[key];
                for (let index = 0; index < filersList.length; index++) {
                    let el = filersList[index];
                    let filter = {
                        "filterInfo": {
                            "filterID": el.docCount,
                            "filterSelected": el.checked,
                            "filterType": el.type,
                            "filterValue": el.key
                        }
                    };
                    errorChange(filter);
                    filtersAddedArray.push(filter);
                }
            });
            filterEventPayload.eventPayload['filtersAddedArray'] = filtersAddedArray
        } else if (eventName === 'Sort By') {
            let filtersAddedArray = [{
                "filterInfo": {
                    "filterID": '',
                    "filterSelected": '',
                    "filterType": 'Sort',
                    "filterValue": eData
                }
            }];
            errorChange(filtersAddedArray[0].filterInfo);
            filterEventPayload.eventPayload['filtersAddedArray'] = filtersAddedArray;
        }
    
        const cusEvent = new CustomEvent(eventName, {
            detail: filterEventPayload,
            bubbles: true,
            cancelable: true
        });
        window.dispatchEvent(cusEvent);
        if (window['digitalData']) {
            window['digitalData'].events.push(filterEventPayload);
            window['digitalData'].filters = filterEventPayload;
        }
    } catch(err) {
        console.log(err)
    }
}

export function eventOrderInfoTracking(eventName, eData = {}, pageName, sourceName, orderResp) {
    try {
        let orderEventEventPayload = { eventName: eventName, eventPayload: {} };
        // let eData = JSON.parse(localStorage._cartData);
        let orderDetails = orderResp; // eData.orderDetails;
        let byodCount = 0;
        let CpoCount = 0;
        let tradeInCount = 0;
        let newDevice = 0;
        let tradeInTotal = 0;
        let orderInfo = {
            'orderRes':eData,
            'shoppingCartID': eData?.cart?.cartId || '',
            'shoppingCartStatus': 'New', //New, Abandoned, Recovered
            'shoppingCartSubTotal': '',
            'shoppingCartTaxTotal': eData?.cart?.totalAmounts?.totalTaxAmount,
            'shoppingCartOrderTotal': eData?.cart?.totalAmounts?.dueToday,
            'shoppingCartOrderTotalMonthly': eData?.cart?.totalAmounts?.dueMonthly,
            'shoppingCartOrderTaxTotalMonthly': eData?.cart?.totalAmounts?.totalTaxAmount,
            'shoppingCartPreferredContactMethod': orderDetails?.custContactPref,
            'scheduledContactTimeSlot': orderDetails?.returnCallSlot,
            'shoppingCartPackagesIncludedArray': [],
            'shoppingCartOrderDeliveryDetails': {
                'orderDeliveryType': 'Online', //Online vs. in-store
                'orderDeliveryShipmentsArray': [] //Accepts Order Delivery Shipment Objects
            },
            'shoppingCartTradeInTotalEstimate': tradeInTotal,
            'shoppingCartNewDevicesTotal': newDevice,
            'shoppingCartCpoDevicesTotal': CpoCount,
            'shoppingCartByodDevicesTotal': byodCount,
            'shoppingCartTradeInDevicesTotal': tradeInCount,
            'discountsArray': [],
            'shoppingCartDiscountsTotalAmount': 0,
            'shoppingCartTotalNumberOfDiscounts': 0,
            'purchaseID': orderDetails?.orderNumber,
            "totalDevicesCount": Object.keys(eData?.cart?.packages || {}).length,
            "totalAccessoriesCount": orderDetails?.cartSummary?.totalAccessoriesCount,
            "totalPlansCount": orderDetails?.cartSummary?.totalPlansCount,
            "totalFeaturesCount": orderDetails?.cartSummary?.totalFeaturesCount,
            "totalByodCount": orderDetails?.cartSummary?.totalByodCount,
            "totalTradeInCount": orderDetails?.cartSummary?.totalTradeInCount
        };
        errorChange(orderInfo);
        errorChange(orderInfo?.shoppingCartOrderDeliveryDetails);

        let packArray = {
            "packageInfo": {
                "packageID": '',
                "packagePriceTotal": '',
                "packagePlansIncludedArray": [],
                "packageDevicesIncludedArray": [],
                "packageAccessoriesIncludedArray": [],
                "packageFeaturesIncludedArray": []
            }
        };
        errorChange(packArray?.packageInfo);
    
        let productsArray = [];
        let deviceList = eData?.cart?.packages || {};
        let  accessoryList = eData?.cart?.accessories || {};
        Object.keys(deviceList).forEach((key, i) => {
    
            let productName = deviceList[key]?.productName
            if (deviceList[key]?.byod === true) {
                byodCount++
                orderInfo.shoppingCartByodDevicesTotal = byodCount;
            }
            if (productName.includes('Certified Pre-Owned') || productName.includes('CPO')) {
                CpoCount++
                orderInfo.shoppingCartCpoDevicesTotal = CpoCount;
            }
            if (deviceList[key].tradInInfo) {
                tradeInCount++
                orderInfo.shoppingCartTradeInDevicesTotal = tradeInCount;
                orderInfo.shoppingCartTradeInTotalEstimate += deviceList[key]?.tradInInfo?.itemPrice;
            }
            if (!deviceList[key]?.byod && !productName.includes('Certified Pre-Owned') && !productName.includes('CPO')) {
                newDevice++
                orderInfo.shoppingCartNewDevicesTotal = newDevice;
            }
    
    
            let device = deviceList[key];
            let packageDevicesIncludedArray = {
                'deviceInfo': {
                    'deviceID': device?.deviceId,
                    'deviceSKU': device?.deviceSku,
                    'deviceName': device?.productName,
                    'deviceBrand': device?.brandName,
                    'deviceCategory': device?.deviceType,
                    'deviceDescription':  device?.description || 'undefined',
                    'deviceRetailPriceListed': device?.itemRetailPrice,
                    'deviceSalePriceListed': device?.itemPriceWithOutDiscount,
                    'deviceRatingAverage': device?.rating,
                    'deviceReviewTotal': device?.reviewCount,
                    'deviceDisclaimer': 'undefined',
                    'devicePromotionDetails': 'undefined',
                    'deviceColorOptionSelected': device?.colorDisplay,
                    'deviceStorageOptionSelected': device?.capacity,
                    'devicePaymentOptionSelected': device?.selectedPriceType,
                    'quantity': device?.quantity,
                    'deviceCarrier': device?.deviceCarrier,
                    'deviceColor': device?.colorDisplay,
                    'deviceCondition': device?.deviceCondition,
                    "deviceManufacturer": device?.deviceManufacturer,
                    "deviceModel": device?.productName,
                    "deviceSize": device?.deviceSize, //The size of the device
                    "deviceStorageAmount": device?.capacity, //Amount of storage available on the device (eg?.g 64GB, etc?.)
                    'deviceTradeInValue': (device?.tradInInfo ? device?.tradInInfo?.appliedPrice : device?.appliedPrice),
                    'isTradeIn': (device?.tradInInfo ? 'Yes' : 'No'),
                    'byod': (device?.byod ? 'Yes' : 'No'),
                    'deviceCompatibleWithNetworkFlag': device?.deviceCompatibleWithNetworkFlag,
                    'deviceSimCondition': device?.deviceSimCondition,
                    'deviceSimId': device?.simSku,
                    'deviceMonthlyStartingPriceListed': device?.finalMonthlyPrice,
                    'devicePriceSubtotal': device?.devicePriceSubtotal,
                    'devicePriceTaxTotal': device?.taxAmount,
                    'devicePriceTotal': device?.displayDiscountPrice?.itemPrice ||  device?.deviceFinalPrice,
                    'discountsArray': [],
                    "promotionId": '',
                    "promotionMessage": '',
                    "promotionAmount": '',
                }
            };
            let productArray = {
                'productInfo': {
                    'productID': device?.deviceId,
                    'productName': device?.productName,
                    'productCategory': device?.category,
                    'productType': 'Device',
                    'productPrice': device?.itemPrice,
                    'productQuantity': device?.quantity,
                    'productRatingAverage': device?.rating,
                    'productReviewsTotal': device?.reviewCount,
                    'productBrand': device?.brandName,
                    'productMemorySize': device?.capacity,
                    'productColor': device?.colorDisplay,
                    "productTradeInValue": device?.productTradeInValue, "productPurchaseCategory": device?.productPurchaseCategory,
                    "productDiscountsTotalAmount": 0,
                    "productPromotionId": '',
                    "productPromotionMessage": '',
                    "productPromotionAmount": '',
    
                    "productSpeed": '',
                    "productSpeedAlt": '',
                    "productStatus": '',
                    "productMemoryThreshold": '',
                    "productSubType": '',
                    "productPaymentFrequency": device?.priceType,
                    "productDuration": '',
    
                }
            };
    
            try {
    
                if (device?.plan && device?.packageType === 'FIVEG' || 'FOURG') {
                    productArray.productInfo.productPurchaseCategory = (device?.byor ? 'New' : 'Used');
                    productArray.productInfo.productSpeed = '';
                    productArray.productInfo.productSpeedAlt = '';
                    productArray.productInfo.productStatus = 'product added';
                    productArray.productInfo.productMemoryThreshold = '';
                    productArray.productInfo.productSubType = 'router';
                    productArray.productInfo.productPaymentFrequency = device?.userSelectedPayment;
                    productArray.productInfo.productDuration = device?.tenure;
    
                    productArray.productInfo.productPrice = device?.deviceFinalPrice;
                }
    
                if (device?.offers?.sbdOffer) {
                    let offerDetails = device?.offers?.sbdOffer
                    packageDevicesIncludedArray.deviceInfo.promotionId = offerDetails?.offerId;
                    packageDevicesIncludedArray.deviceInfo.promotionMessage = offerDetails?.offerDesc;
                    packageDevicesIncludedArray.deviceInfo.promotionAmount = offerDetails?.discAmt;
    
                    productArray.productInfo.productPromotionId = offerDetails?.offerId;
                    productArray.productInfo.productPromotionMessage = offerDetails?.offerDesc;
                    productArray.productInfo.productPromotionAmount = offerDetails?.discAmt;
    
                } else if  (device?.offers?.sedOffer) {
                    let offerDetails = device?.offers?.sedOffer
                    packageDevicesIncludedArray.deviceInfo.promotionId = offerDetails?.offerId;
                    packageDevicesIncludedArray.deviceInfo.promotionMessage = offerDetails?.offerDesc;
                    packageDevicesIncludedArray.deviceInfo.promotionAmount = offerDetails?.discAmt;
    
                    productArray.productInfo.productPromotionId = offerDetails?.offerId;
                    productArray.productInfo.productPromotionMessage = offerDetails?.offerDesc;
                    productArray.productInfo.productPromotionAmount = offerDetails?.discAmt;
    
                } 
            }
            catch (e) {
    
            }
    
    
            if (device?.byod) {
                productArray.productInfo.productPurchaseCategory = 'BYOD';
    
            }
            else {
                productArray.productInfo.productPurchaseCategory = 'New Purchase';
            }
            if (device?.byod) {
                productArray.productInfo.productID = device?.deviceSkuId;
                packageDevicesIncludedArray.deviceInfo.deviceID = device?.imei;
            }
            errorChange(productArray?.productInfo);
            errorChange(packageDevicesIncludedArray?.deviceInfo);
    
    
            productsArray?.push(productArray);
            packArray?.packageInfo?.packageDevicesIncludedArray?.push(packageDevicesIncludedArray);
            if (device?.tradInInfo) {
                let tradeInPackageDevicesIncludedArray = {
                    'deviceInfo': {
                        'deviceID': device?.tradInInfo?.deviceId,
                        'deviceSKU': device?.tradInInfo?.recycleDeviceSku,
                        'deviceName': device?.tradInInfo?.equipModel,
                        'deviceBrand': device?.tradInInfo?.equipMake,
                        'deviceCategory': device?.tradInInfo?.category,
                        'deviceDescription': 'undefined',
                        'deviceRetailPriceListed': device?.tradInInfo?.itemRetailPrice,
                        'deviceSalePriceListed': device?.tradInInfo?.deviceSalePriceListed,
                        'deviceRatingAverage': device?.tradInInfo?.rating,
                        'deviceReviewTotal': device?.tradInInfo?.reviewCount,
                        'deviceDisclaimer': 'undefined',
                        'devicePromotionDetails': 'undefined',
                        'deviceColorOptionSelected': device?.tradInInfo?.color,
                        'deviceStorageOptionSelected': device?.tradInInfo?.capacity,
                        'devicePaymentOptionSelected': device?.tradInInfo?.selectedPriceType,
                        'quantity': device?.tradInInfo?.quantity,
                        'deviceCarrier': device?.tradInInfo?.equipCarrier,
                        'deviceColor': device?.colorDisplay,
                        'deviceCondition': device?.tradInInfo?.deviceCondition,
                        "deviceManufacturer": device?.tradInInfo?.equipMake,
                        "deviceModel": device?.tradInInfo?.equipModel,
                        "deviceSize": device?.devices, //The size of the device
                        "deviceStorageAmount": device?.capacity, //Amount of storage available on the device (eg?.g 64GB, etc?.)
                        'deviceTradeInValue': device?.tradInInfo?.appliedPrice,
                        'isTradeIn': (device?.tradInInfo ? 'Yes' : 'No'),
                        'byod': (device?.byod ? 'Yes' : 'No'),
                        'deviceCompatibleWithNetworkFlag': device?.deviceCompatibleWithNetworkFlag,
                        'deviceSimCondition': device?.deviceSimCondition,
                        'deviceSimId': device?.deviceSimId,
                    }
                }
                let productArrayTrade = {
                    'productInfo': {
                        'productID': device?.tradInInfo?.recycleDeviceSku,
                        'productName': device?.tradInInfo?.equipModel,
                        'productCategory': device?.category,
                        'productType': 'Device',
                        'productPrice': 0,
                        'productQuantity': 1,
                        'productRatingAverage': device?.tradInInfo?.rating,
                        'productReviewsTotal': device?.tradInInfo?.reviewCount,
                        'productBrand': device?.tradInInfo?.equipMake,
                        'productMemorySize': device?.tradInInfo?.capacity,
                        'productColor': device?.tradInInfo?.colorDisplay,
                        "productTradeInValue": device?.tradInInfo?.appliedPrice,
                        "productPurchaseCategory": 'Trade-in'
                    }
                };
                productsArray?.push(productArrayTrade);
    
                errorChange(tradeInPackageDevicesIncludedArray?.deviceInfo);
                errorChange(productArrayTrade?.productInfo);
    
                packArray.packageInfo.packageDevicesIncludedArray?.push(tradeInPackageDevicesIncludedArray);
    
            }
            if (device?.plan) {
                let packagePlansIncludedArray = {
                    'planInfo': {
                        'planID': device?.plan?.pricePlanCode,
                        'planSKU': device?.plan?.pricePlanCode,
                        'planName': device?.plan?.pricePlanDesc, 
                        'planType': device?.plan?.planType,
                        'planCategory': device?.plan?.primaryCategory,
                        'planRetailPriceListed': device?.plan?.planPrice,
                        'planSalePriceListed': device?.plan?.planPrice,
                        'discountsArray': [],
                    }
                };
                let productArray = {
                    'productInfo': {
                        'productID': device?.plan?.pricePlanCode,
                        'productName': device?.plan?.pricePlanDesc,
                        'productCategory': device?.plan?.primaryCategory,
                        'productPlanType':device?.plan?.planType,
                        'productType': 'Plan', // eData?.type,
                        'productPrice': device?.plan?.planPrice,
                        'productQuantity': 1,
                        'productRatingAverage': '',
                        'productReviewsTotal': '',
                        "productDiscountsTotalAmount": 0
                    }
                };
    
                if (device?.plan?.offerApplied && device?.plan?.offerApplied?.length > 0) {
                    productArray.productInfo.productDiscountsTotalAmount = device?.plan?.planDiscountAmount;
    
                    orderInfo.shoppingCartDiscountsTotalAmount += productArray?.productInfo?.productDiscountsTotalAmount;
                    orderInfo.shoppingCartTotalNumberOfDiscounts++;
    
                    let discountsArr = {
                        "discountInfo": {
                            "discountId": '',
                            "discountName": device?.plan?.offerApplied[0],
                            "discountAmount": productArray?.productInfo?.productDiscountsTotalAmount
                        }
                    }
                    packagePlansIncludedArray?.planInfo?.discountsArray?.push(discountsArr);
                    errorChange(discountsArr?.discountInfo);
                    orderInfo?.discountsArray?.push(discountsArr);
                }
                errorChange(packagePlansIncludedArray?.planInfo);
                errorChange(productArray?.productInfo);
    
                productsArray?.push(productArray);
                packArray?.packageInfo?.packagePlansIncludedArray?.push(packagePlansIncludedArray);
            }
            if (device?.features) {
                let features = device?.features;
                Object.keys(features).forEach((k, indx) => {
                    let fr = features[k];
                    let packageFeaturesIncludedArray = {
                        'featureInfo': {
                            'featureID': fr?.visFeatureCode,
                            'featureSKU': fr?.visFeatureCode,
                            'featureName': fr?.featureDesc,
                            'featureCategory': fr?.type,
                            'discountsArray': [],
                        }
                    }
                    let productArray = {
                        'productInfo': {
                            'productID': fr?.visFeatureCode,
                            'productName': fr?.featureDesc,
                            'productCategory': fr?.type,
                            'productType': 'Feature',
                            'productPrice': fr?.retailPrice,
                            'productQuantity': 1,
                            'productRatingAverage': 'undefined',
                            'productReviewsTotal': 'undefined',
                            "productDiscountsTotalAmount": 0
                        }
                    };
                    errorChange(packageFeaturesIncludedArray?.featureInfo);
                    errorChange(productArray?.productInfo);
    
    
                    productsArray?.push(productArray);
                    packArray?.packageInfo?.packageFeaturesIncludedArray?.push(packageFeaturesIncludedArray);
                });
    
            }
        });
        Object.keys(accessoryList).forEach((key, i) => {
            let accessory = accessoryList[key];
            let packageAccessoriesIncludedArray =
            {
                'accessoryInfo': {
                    'accessoryID': '',
                    'accessorySKU': accessory?.id,
                    'accessoryName': accessory?.productName,
                    'accessoryBrand': accessory?.brandname,
                    'accessoryCategory': accessory?.category,
                    'accessoryRetailPriceListed': accessory?.retailPrice,
                    'accessorySalePriceListed': accessory?.finalBestPrice,
                    'accessoryColorOptionSelected': accessory?.colorDisplay,
                    'discountsArray': [],
                }
            };
            let productArray = {
                'productInfo': {
                    'productID': accessory?.id,
                    'productName': accessory?.productName,
                    'productCategory': accessory?.category,
                    'productType': 'Accessory',
                    'productPrice': accessory?.finalBestPrice,
                    'productQuantity': accessory?.quantity,
                    'productRatingAverage': accessory?.rating,
                    'productReviewsTotal': accessory?.reviewCount,
                    'productBrand': accessory?.brandname,
                    'productMemorySize': '',
                    'productColor': accessory?.colorDisplay,
                    "productDiscountsTotalAmount": 0
                }
            };
            errorChange(packageAccessoriesIncludedArray?.accessoryInfo);
            errorChange(productArray?.productInfo);
    
    
            productsArray.push(productArray);
            packArray.packageInfo?.packageAccessoriesIncludedArray.push(packageAccessoriesIncludedArray);
    
    
        });
        orderInfo['productsArray'] = productsArray;
        orderInfo.shoppingCartPackagesIncludedArray.push(packArray);
        errorChange(orderInfo);
        errorChange(orderInfo['productsArray']);
    
        orderEventEventPayload.eventPayload = {...orderInfo,isReact:true,isAngular:false};
        if (window['digitalData']) {
            
            window['digitalData'].shoppingCartInfo = orderEventEventPayload.eventPayload;
            window['digitalData'].events.push(orderEventEventPayload);
            window['digitalData'].isUniqBOS = false;
        }
        const cusEvent = new CustomEvent(eventName, {
            detail: orderEventEventPayload,
            bubbles: true,
            cancelable: true
        });
        window.dispatchEvent(cusEvent);
    } catch(err) {
        console.log(err)
    }
}


export function eventCartRetrieved(eventName, eData = {}, pageName, sourceName) {
    try {
        let eventCartRetrievPayload = { eventName: eventName, eventPayload: eData };
        const cusEvent = new CustomEvent(eventName, {
            detail: eventCartRetrievPayload,
            bubbles: true,
            cancelable: true
        });
        window.dispatchEvent(cusEvent);
        window['digitalData'] && window['digitalData'].events.push(eventCartRetrievPayload);
    } catch(err) {
        console.log(err)
    }
}

export function eventCheckDevice(eventName, eData = {}, pageName, sourceName) {
    try {
        let checkDevicePayload = {
            'eventName': eventName, 'eventPayload':
                { 'deviceFullDetail': JSON.parse(JSON.stringify(eData.deviceFullDetail)) }
        };
        if (eventName === 'Check My Device Success') {
            checkDevicePayload.eventPayload.compatibleWithNetwork = true
        } else if (eventName === "Check My Device Incompatible") {
            checkDevicePayload.eventPayload.compatibleWithNetwork = false
        }
        checkDevicePayload.eventPayload.deviceId = eData.deviceId;
        checkDevicePayload.eventPayload.simID = nullCheck(eData.simID);
        checkDevicePayload.eventPayload.simCondition = nullCheck(eData.simCondition)
    
        const cusEvent = new CustomEvent(eventName, {
            detail: checkDevicePayload,
            bubbles: true,
            cancelable: true
        });
    
        window.dispatchEvent(cusEvent);
        window['digitalData'] && window['digitalData'].events.push(checkDevicePayload);
    } catch(err) {
        console.log(err)
    }
}

export function eventSimSelection(eventName, eData = {}, pageName, sourceName) {
    try {
        let simEventPayload = { 'eventName': eventName, 'eventPayload': { 'deviceFullDetail': JSON.parse(JSON.stringify(eData.deviceFullDetail)) } };
        simEventPayload.eventPayload.deviceId = eData.deviceId;
        simEventPayload.eventPayload.simID = nullCheck(eData.simID);
        simEventPayload.eventPayload.simCondition = nullCheck(eData.sim)
        if (eventName === 'New SIM Card Requested') {
            simEventPayload.eventPayload.compatibleWithNetwork = 'undefined';
        } else if (eventName === 'Existing SIM Card Validation Requested') {
            simEventPayload.eventPayload.compatibleWithNetwork = 'undefined';
        } else if (eventName === 'Check My SIM Success') {
            simEventPayload.eventPayload.compatibleWithNetwork = true;
        } else if (eventName === 'Check My SIM Fail') {
            simEventPayload.eventPayload.compatibleWithNetwork = false;
        }
    
        const cusEvent = new CustomEvent(eventName, {
            detail: simEventPayload,
            bubbles: true,
            cancelable: true
        });
    
        window.dispatchEvent(cusEvent);
        window['digitalData'] && window['digitalData'].events.push(simEventPayload);
    } catch(err) {
        console.log(err)
    }

}

export function eventTradeIn(eventName, eData = {}, pageName, sourceName) {
    try {

        
        let deviceFullDetails;
        let tradeInDevice = {
            'eventName': eventName, 'eventPayload': { 'deviceFullDetail': deviceFullDetails }
        };
    
        if (eventName === 'Trade-in Device Added') {
            let eData2 = eData.devices[0]
            deviceFullDetails = {
                "backOrder": eData2.backOrder,
                "brandName": eData.brandSelected,
                "carouselImageset": eData2.imageName,
                "category": eData2.category, //Confirm this will work for device type with values such as "4G Smartphone"
                "compatibleWithPlan": eData2.compatibleWithPlan,
                "compatibleWithNetwork": eData2.compatibleWithNetwork, //True or false.
                "contractTerm": eData2.contractTerm,
                "cssColor": eData.selectedDevice.colorCssStyle,
                "deliverBy": eData2.deliverBy,
                "deviceCarrier": eData.selectedCarrier,
                "deviceColor": eData2.color,
                "deviceCondition": eData2.deviceCondition, //The condition of a trade-in device as entered by a user, or 'Certified' for a CPO, or 'New' if new
                "deviceId": eData2.deviceId,
                "deviceManufacturer": eData2.equipMake,
                "deviceModel": eData2.equipModel,
                "devicePaymentFinalPrice": eData2.devicePaymentFinalPrice,
                "devicePaymentPrice": eData2.devicePaymentPrice,
                "devicePaymentTenure": eData2.tenure,
                "deviceSize": eData2.devicesSize, //The size of the device
                "deviceStorageAmount": eData2.capacity, //Amount of storage available on the device (eg.g 64GB, etc.)
                "deviceSkuId": eData2.recycleDeviceSku,
                "deviceTradeInValue": eData2.appliedPrice, //Estimated value of trade-in device
                "fullName": eData2.equipModel + " " + (eData2.capacity ? eData2.capacity : ''),
                "hasPaymentMethods": eData2.hasPaymentMethods,
                "imageSet": eData2.imageName,
                "imei": eData2.deviceId,
                "byod": (eData2.byod ? 'Yes' : 'No'),
                "isTradeIn": 'Yes', //True or False - Is the device a trade-in?
                "itemPrice": eData2.appliedPrice,
                "name": eData.selectedDevice.bccModel,
                "oneYearContractFinalPrice": eData2.oneYearContractFinalPrice,
                "oneYearContractPrice": eData2.oneYearContractPrice,
                "outOfStock": eData2.outOfStock,
                "preOrder": eData2.preOrder,
                "productName": eData2.modelName,
                "rating": eData2.rating,
                "reviewCount": eData2.reviewCount,
                "shipmentDelay": eData2.shipmentDelay,
                "simSku": eData2.simSku,
                "simId": eData2.simId,
                "simCondition": eData2.simCondition, //New or Used
                "twoYearContractFinalPrice": eData2.twoYearContractFinalPrice,
                "twoYearContractPrice": eData2.twoYearContractPrice
            }
            errorChange(deviceFullDetails);
        }
        else if (eventName === 'Trade-in Device Removed') {
    
            let eData2 = eData.tradInInfo
            deviceFullDetails = {
                "backOrder": eData2.backOrder,
                "brandName": eData2.equipMake,
                "carouselImageset": eData2.imageName,
                "category": eData2.category, //Confirm this will work for device type with values such as "4G Smartphone"
                "compatibleWithPlan": eData2.compatibleWithPlan,
                "compatibleWithNetwork": eData2.compatibleWithNetwork, //True or false.
                "contractTerm": eData2.contractTerm,
                "cssColor": eData2.colorCssStyle,
                "deliverBy": eData2.deliverBy,
                "deviceCarrier": eData2.equipCarrier,
                "deviceColor": eData2.color,
                "deviceCondition": eData2.deviceCondition, //The condition of a trade-in device as entered by a user, or 'Certified' for a CPO, or 'New' if new
                "deviceId": eData2.deviceId,
                "deviceManufacturer": eData2.equipMake,
                "deviceModel": eData2.equipModel,
                "devicePaymentFinalPrice": eData2.devicePaymentFinalPrice,
                "devicePaymentPrice": eData2.devicePaymentPrice,
                "devicePaymentTenure": eData2.tenure,
                "deviceSize": eData2.devicesSize, //The size of the device
                "deviceStorageAmount": eData2.capacity, //Amount of storage available on the device (eg.g 64GB, etc.)
                "deviceSkuId": eData2.recycleDeviceSku,
                "deviceTradeInValue": eData2.appliedPrice, //Estimated value of trade-in device
                "fullName": eData2.equipModel + " " + (eData2.capacity ? eData2.capacity : ''),
                "hasPaymentMethods": eData2.hasPaymentMethods,
                "imageSet": eData2.imageName,
                "imei": eData2.deviceId,
                "byod": (eData2.byod ? 'Yes' : 'No'),
                "isTradeIn": 'Yes', //True or False - Is the device a trade-in?
                "itemPrice": eData2.appliedPrice,
                "name": eData2.equipModel,
                "oneYearContractFinalPrice": eData2.oneYearContractFinalPrice,
                "oneYearContractPrice": eData2.oneYearContractPrice,
                "outOfStock": eData2.outOfStock,
                "preOrder": eData2.preOrder,
                "productName": eData2.modelName,
                "rating": eData2.rating,
                "reviewCount": eData2.reviewCount,
                "shipmentDelay": eData2.shipmentDelay,
                "simSku": eData2.simSku,
                "simId": eData2.simId,
                "simCondition": eData2.simCondition, //New or Used
                "twoYearContractFinalPrice": eData2.twoYearContractFinalPrice,
                "twoYearContractPrice": eData2.twoYearContractPrice
            }
            errorChange(deviceFullDetails);
    
        }
    
        tradeInDevice.eventPayload.deviceFullDetail = deviceFullDetails
        const cusEvent = new CustomEvent(eventName, {
            detail: tradeInDevice,
            bubbles: true,
            cancelable: true
        });
    
        window.dispatchEvent(cusEvent);
        window['digitalData'] && window['digitalData'].events.push(tradeInDevice);
    } catch(err) {
        console.log(err)
    }
}


export function storeAppointment(eventName, eData = {}, pageName, sourceName) {
    try {
        let storePayload = {
            'eventName': eventName,
            'eventPayload': {
                'optionSelected': eData.purposeOfVisit
            }
        }
        const cusEvent = new CustomEvent(eventName, {
            detail: storePayload,
            bubbles: true,
            cancelable: true
        });
    
        window.dispatchEvent(cusEvent);
        window['digitalData'] && window['digitalData'].events.push(storePayload);
    } catch(err) {
        console.log(err)
    }
}

export function  customerDetails(eventName, eData = {}, pageName, sourceName) {
    try {
        let storePayload = {
            'eventName': eventName,
            "eventPayload": {
                "referenceNumber": eData.appointmentInfo.appointmentId,
                "storeName": eData.appointmentInfo.storeDetails.storeName,
                "appointmentReason": eData.appointmentInfo.purposeDetails.purpose,
                "appointmentDate": eData.appointmentInfo.purposeDetails.appointmentDate
            } //end eventPayload
        }
        const cusEvent = new CustomEvent(eventName, {
            detail: storePayload,
            bubbles: true,
            cancelable: true
        });
    
        window.dispatchEvent(cusEvent);
        window['digitalData'] && window['digitalData'].events.push(storePayload);
    } catch(err) {
        console.log(err)
    }
}

export function eventPlanError(eventName, eData = {}, pageName, sourceName) {
    try {

        let pageN = window.location.href.split('/').pop();
        let planError;
        if (pageN === 'plans') {
            if (eData.status) {
                planError = {
                    "eventName": "Error Encountered",
                    "eventPayload": {
                        "errorInfo": {
                            "errorID": eData.status.statusCode,
                            "errorCategory": eData.status.statusMessage,
                            "errorName": eData.status.message,
                            "errorMessage": sourceName
                        }
                    }
                }
            }
            else if (eData.serviceStatus) {
                planError = {
                    "eventName": "Error Encountered",
                    "eventPayload": {
                        "errorInfo": {
                            "errorID": eData.serviceStatus.statusCode,
                            "errorCategory": eData.serviceStatus.statusMessage,
                            "errorName": eData.serviceStatus.message,
                            "errorMessage": sourceName
                        }
                    }
                }
            }
            else {
                // eData.slice(eData.indexOf(':')+1,eData.indexOf('Message:'))
                planError = {
                    "eventName": "Error Encountered",
                    "eventPayload": {
                        "errorInfo": {
                            "errorID": "",
                            "errorCategory": "",
                            "errorName": "",
                            "errorMessage": eData
                        }
                    }
                }
            }
    
            errorChange(planError.eventPayload.errorInfo);
            const cusEvent = new CustomEvent(eventName, {
                detail: planError,
                bubbles: true,
                cancelable: true
            });
            window.dispatchEvent(cusEvent);
            window['digitalData'] && window['digitalData'].events.push(planError);
        }
    } catch(err) {
        console.log(err)
    }
}

export function eventDDIATracking(eventName, eData, pageName, sourceName) {
    try {
        let dataPayload = {
            'eventName': eventName,
            "eventPayload": eData //end eventPayload
        }
        const cusEvent = new CustomEvent(eventName, {
            detail: dataPayload,
            bubbles: true,
            cancelable: true
        });
    
        window.dispatchEvent(cusEvent);
        window['digitalData'] && window['digitalData'].events.push(dataPayload);
    } catch(err) {
        console.log(err)
    }
}

export function eventLoopQual(eventName, requestData, responseData) {
    try {
        let dataPayload
        if (eventName === 'Loop Qualification Attempt') {
            dataPayload = {
                'eventName': eventName,
                "eventPayload": requestData
            }
        }
        else {
            dataPayload = {
                'eventName': eventName,
                "eventPayload":
                {
                    "request": requestData,
                    "response": responseData
                } //end eventPayload
            }
        }
    
        const cusEvent = new CustomEvent(eventName, {
            detail: dataPayload,
            bubbles: true,
            cancelable: true
        });
    
        window.dispatchEvent(cusEvent);
    } catch(err) {
        console.log(err)
    }
    window['digitalData'] && window['digitalData'].events.push(dataPayload);
}

export function eventCreditInfoTracking(eventName, eData = {}, pageName, sourceName) {
    try{
        let CreditInfoData = {
            "orgRes":eData,
            "organizationInfo": {
                "organizationId": '',
                "organizationName": '',
                "organizationCreditStatus": '',
                "organizationLinkedContractsArray": []
            }
        };
        if (eventName === 'Business Credit Details Submitted') {
            CreditInfoData.organizationInfo.organizationName = eData?.companyName;//eData.companyInfo.name;
        } else if (eventName === 'Credit Check Approved') {
            CreditInfoData = window['digitalData']?.organizationInfo;
            CreditInfoData?.organizationInfo?.organizationCreditStatus ? CreditInfoData.organizationInfo.organizationCreditStatus = "SUCCESS" : ''; //'Approved';
        }
        else if (eventName === 'Credit Check UnApproved') {
            CreditInfoData = window['digitalData']?.organizationInfo;
            CreditInfoData?.organizationInfo?.organizationCreditStatus ? CreditInfoData.organizationInfo.organizationCreditStatus = "FAILURE" : '';//'UnApproved';
        }
        errorChange(CreditInfoData?.organizationInfo);
        // window['digitalData'].organizationInfo = CreditInfoData;
        let busInfoEvntPayload = { 'eventName': eventName, 'eventPayload': CreditInfoData };
        const cusEvent = new CustomEvent(eventName, {
            detail: busInfoEvntPayload,
            bubbles: true,
            cancelable: true
        });
        window.dispatchEvent(cusEvent);
        window['digitalData'].events.push(busInfoEvntPayload);
    } catch (err) {
        console.log(err)
    }
}

// TBD
export function  errorEncounter(eventName, errorData) {
    let errorEventPayload = {
        "eventName": "Error Encountered",
        "eventPayload": errorData
    };
    const cusEvent = new CustomEvent(eventName, {
        detail: errorEventPayload,
        bubbles: true,
        cancelable: true
    });

    window.dispatchEvent(cusEvent);
    window['digitalData'].events.push(errorEventPayload);
}
export function  automationThrottleParam(ThrottleData) {
    if (ThrottleData) {
        window['digitalData'] && (window['digitalData'].pageInfo.throttleFlow = "Automation");
    } else {
        window['digitalData'] && (window['digitalData'].pageInfo.throttleFlow = "NonAutomation");
    }

}
//completed
export function eventBusInfoTracking(eventName, eData = {}, pageName, sourceName) {
    try {
        let busInfoData = {
            "orgRes":eData,
            "organizationInfo": {
                "organizationName": '',
                "organizationCreditStatus": '',
                "organizationLinkedContractsArray": []
            }
        };
        if (eventName === 'Business Credit Details Submitted') {
            busInfoData.organizationInfo.organizationName = eData?.companyName;
        } else if (eventName === 'Business Credit Details Status Response') {
            window['digitalData'] && (busInfoData = window['digitalData']?.organizationInfo);
            busInfoData.organizationInfo.organizationCreditStatus = eData; //'Approved';
        }
        errorChange(busInfoData.organizationInfo);
        // window['digitalData'].organizationInfo = busInfoData;
        let busInfoEvntPayload = { 'eventName': eventName, 'eventPayload': busInfoData };
        const cusEvent = new CustomEvent(eventName, {
            detail: busInfoEvntPayload,
            bubbles: true,
            cancelable: true
        });
        window.dispatchEvent(cusEvent);
        window['digitalData'] && window['digitalData'].events.push(busInfoEvntPayload);
    } catch (err) {
        console.log(err)
    }
}

export function  ThrottleFlowType(ThrottleFlowData) {
    if (ThrottleFlowData == "prospect") {
        window['digitalData'] && (window['digitalData'].pageInfo.throttleFlowType = "REGULAR");
    } else {
        window['digitalData'] && (window['digitalData'].pageInfo.throttleFlowType = ThrottleFlowData);
    }
}
export function  plansFirstSelection(selectedPlan, billingCode) {

    window['digitalData'] && (window['digitalData'].pageInfo.planID = selectedPlan);
    window['digitalData'] && (window['digitalData'].pageInfo.billingCode = billingCode);
    //window['digitalData'].events.eventPayload.packagesArray.packageInfo.packagePlansIncludedArray.planInfo.billingCode = billingCode;

}

/*** Package PDP  */
export function  addPackageToCartTracking(eventName, eData = {}, pageName, sourceName, cartData = {}) {
    /**  Adobe launch tracking */
    const addToCartEventPayload = {
        'eventName': eventName,
        'eventPayload': {
            'packagesArray': [],
            'productsArray': []
        }
    };
    const packgArray = {
        'packageInfo': {
            'packageID': eData?.id,
            'packageName': eData?.name,
            'packagePriceTotal': '',
            'recommendedPackage': eData?.recommendedPackage,
            'packageType': eData?.type

        }
    };
    errorChange(packgArray.packageInfo);
    if (pageName === 'packageInfo') {
        const packageDevicesIncludedArray = [{
            'deviceInfo': {
                'deviceID': eData?.deviceId,
                'deviceSKU': eData?.deviceSku,
                'deviceName': eData?.productName,
                'deviceBrand': eData?.brand,
                'deviceCategory': eData?.deviceCategory,
                'deviceDescription': eData?.description,
                'deviceRetailPriceListed': eData?.itemRetailPrice,
                'deviceSalePriceListed': eData?.retailTwoYrPrice,
                'deviceRatingAverage': eData?.overallRating,
                'deviceReviewTotal': eData?.reviewCount,
                'deviceDisclaimer': 'undefined',
                'devicePromotionDetails': 'undefined',
                'deviceColorOptionSelected': eData?.color,
                'deviceStorageOptionSelected': eData?.capacity,
                'devicePaymentOptionSelected': eData?.amount,
                "deviceCarrier": eData?.deviceCarrier,
                "deviceColor": eData?.color,
                "deviceCondition": eData?.deviceCondition,
                "deviceManufacturer": eData?.deviceManufacturer,
                "deviceModel": eData?.model,
                "deviceSize": eData?.deviceSize,
                "deviceStorageAmount": eData?.capacity,
                "deviceSkuId": eData?.skuCode
            }
        }];
        const productsArray = {
            'productInfo': {
                'productID': eData?.deviceSku,
                'productName': eData?.productName,
                'productCategory': eData?.deviceCategory,
                'productType': 'Device',
                'productPrice': eData?.amount,
                'productQuantity': 1,
                'productRatingAverage': eData?.overallRating,
                'productReviewsTotal': eData?.reviewCount,
                'productBrand': eData?.brand,
                'productMemorySize': eData?.capacity,
                'productColor': eData?.color,
                "productPurchaseCategory": 'New Purchase',
                'productDetails': eData
            }
        };
        errorChange(packageDevicesIncludedArray[0].deviceInfo);
        errorChange(productsArray.productInfo);

        addToCartEventPayload.eventPayload.productsArray.push(productsArray);
        packgArray.packageInfo['packageDevicesIncludedArray'] = packageDevicesIncludedArray;

        const packagePlansIncludedArray = [{
            'planInfo': {
                'planID': '',
                'planSKU': eData.plan?.pricePlanCode,
                'planName': eData.plan?.pricePlanDesc,
                'planType': eData.plan?.groupType,
                'planCategory': eData.plan?.planCatType,
                'planRetailPriceListed': eData.plan?.planPrice,
                'planSalePriceListed': eData.plan?.planPreviousPrice
            }
        }];
        const productsPlanArray = {
            'productInfo': {
                'productID': eData.plan?.pricePlanCode,
                'productName': eData.plan?.pricePlanDesc,
                'productCategory': eData.plan?.groupType,
                'productType': 'Plan', // eData.type,
                'productPrice': eData.plan?.monthlyCharge,
                'productQuantity': 1,
                'productRatingAverage': '',
                'productReviewsTotal': '',
                'planDetails': eData.plan
            }
        };
        errorChange(packagePlansIncludedArray[0].planInfo);
        errorChange(productsArray.productInfo);
        addToCartEventPayload.eventPayload.productsArray.push(productsPlanArray);
        packgArray.packageInfo['packagePlansIncludedArray'] = packagePlansIncludedArray;


        const packageFeaturesIncludedArray = [];
        const featureList = eData.features || {};

        Object.keys(featureList).forEach(key => {
            const element = {
                'featureInfo': {
                    'featureID': featureList[key]?.visFeatureCode,
                    'featureSKU': featureList[key]?.visFeatureCode,
                    'featureName': featureList[key]?.featureDesc,
                    'featureCategory': featureList[key]?.type
                }
            };
            const productsFeatureArray = {
                'productInfo': {
                    'productID': featureList[key]?.visFeatureCode,
                    'productName': featureList[key]?.featureDesc,
                    'productCategory': featureList[key]?.type,
                    'productType': 'Feature',
                    'productPrice': featureList[key]?.featurePrice,
                    'productQuantity': 1,
                    'productRatingAverage': 'undefined',
                    'productReviewsTotal': 'undefined',
                    'featureDetails': featureList[key]

                }
            };
            packageFeaturesIncludedArray.push(element);
            addToCartEventPayload.eventPayload.productsArray.push(productsFeatureArray);
        });

        packgArray.packageInfo['packageFeaturesIncludedArray'] = packageFeaturesIncludedArray;
    }

    addToCartEventPayload.eventPayload.packagesArray.push(packgArray);


    const cusEvent = new CustomEvent(eventName, {
        detail: addToCartEventPayload,
        bubbles: true,
        cancelable: true
    });
    window.dispatchEvent(cusEvent);
    window['digitalData'].events.push(addToCartEventPayload);
}


export function accessoryGridwall() {
    updateDataLayer('app-accessory-gridwall', 'accesorry_gridwall', 'accessories', 3, '');
}

export function accessoryOverview(selectedProduct) {
    updateDataLayer('app-accessory-gridwall', 'accesorry_pdp', 'accessories', 3, selectedProduct);
}

export function businessInfo() {
    updateDataLayer('app-business-information', 'business_information', 'business-info', 3, '');
}

export function devicePdp(selectedProduct) {
    updateDataLayer('app-device-pdp', 'device_pdp', 'smartphones', 3, selectedProduct);
}

export function deviceGridwall() {
    updateDataLayer('app-device-landing', 'device_gridwall', 'smartphones', 3, '');
}

export function deviceProtection() {
    updateDataLayer('app-device-protection', 'feature_selection_page', 'features', 3, '');
}

export function npaComponent() {
    updateDataLayer('app-npa-nxx', 'generate_mtns', 'generate-mtns', 3, '');
}

export function planSelection() {
    updateDataLayer('app-aggregate-plans', 'plan_selection_page', 'plans', 3, '');
}

export function reviewOrder() {
    updateDataLayer('app-review-order', 'review_order', 'review-order', 3, '');
}
export function orderConfirmation() {
    updateDataLayer('app-order-confirmation', 'order-confirmation', 'order-confirmation', 3, '');
}

export function shippingInfo() {
    updateDataLayer('app-shipping-info', 'shipping_info', 'shipping-info', 3, '');
}

export function cartLanding() {
    updateDataLayer('app-cart-landing', 'shopping_cart', 'shopping-cart', 3, '');
}
export function paymentLanding() {
    updateDataLayer('app-payment-landing', 'payment', 'payment', 3, '');
}
export function creditResultLanding() {
    updateDataLayer('app-credit-result-landing', 'credit_result', 'credit_result', 3, '');
}
export function docusignLanding() {
    updateDataLayer('app-docusign-landing', 'docusign', 'docusign', 3, '');
}
export function reviewTACLanding() {
    updateDataLayer('app-reviewtac-landing', 'review_tac', 'review_tac', 3, '');
}
export function e911Landing() {
    updateDataLayer('app-e911-landing', 'e911', 'e911', 3, '');
}
export function contactUsLanding() {
    updateDataLayer('app-contact-us-landing', 'contact_us', 'contact_us', 3, '');
}

export function thankYou() {
    updateDataLayer('app-thank-you', 'thank_you', 'thank-you', 3, '');

}
export function TradeIn() {
    updateDataLayer('app-trade-in', 'Trade-in', 'Trade-in', 3, '');
}

export function uploadDocuments() {
    updateDataLayer('app-upload-documents', 'upload_documents', 'upload-documents', 3, '');
}
export function confirmAppointment() {
    updateDataLayer('app-confirm-appointment', 'confirm appointment', 'confirm-appointment', 3, '');
}
export function customerDetailsUpdate() {
    updateDataLayer('app-customer-details', 'customer details', 'customer-details', 3, '');
}
export function scheduleAppointment() {
    updateDataLayer('app-schedule-appointment', 'store appointment', 'store-appointment', 2, '');
}
export function byodComponent() {
    updateDataLayer('app-byod', 'BYOD', 'Bring your own device', 3, '');
}
export function loopQual() {
    updateDataLayer('app-loop-qual', 'loop qual', 'loop-qual', 3, '');
}

export function eventNewLoopQual(eventName, loopQualDlData) {
    let loopQualeventPayload = {
        'eventName': eventName,
        "eventPayload": {}
    };

    let loopQualDlPayload = {
        res:loopQualDlData,
        addressId: 'NA',
        entrypoint: 'NA',
        type: '',
        response: {
            type: window.location.pathname,
            data: loopQualDlData,
        },
    };

    if (loopQualDlData.Qualified_5g) {
        loopQualDlData['type'] = "5g-landing";
    } else if (loopQualDlData.Qualified_4g) {
        loopQualDlData['type'] = "4g-landing";
    }

    loopQualeventPayload.eventPayload = {...loopQualDlPayload,isReact:true,isAngular:false};

    const cusEvent = new CustomEvent(eventName, {
        detail: loopQualeventPayload,
        bubbles: true,
        cancelable: true
    });

    window.dispatchEvent(cusEvent);
    window['digitalData'] && window['digitalData'].events.push(loopQualeventPayload);
}

export function setBussCredit(businessCreditValue) {
    if (businessCreditValue === 'BLENDED') {
        window['digitalData'] && (window['digitalData'].pageInfo['businessCredit'] = 'Social Security Number');
    } else if (businessCreditValue === 'BizOnlyCredit') {
        window['digitalData'] && (window['digitalData'].pageInfo['businessCredit'] = 'Federal Tax ID EIN');
    }
}
export function newCreditOption(newCreditChoice) {
    if (newCreditChoice === 'BizOnlyCredit') {
        window['digitalData'] && (window['digitalData'].pageInfo['dba_isign_new_credit_option'] = 'Without Social Security Number');
    } else if (newCreditChoice === 'BLENDED') {
        window['digitalData'] && (window['digitalData'].pageInfo['dba_isign_new_credit_option'] = 'Social Security Number AND Federal tax ID');
    } else if (newCreditChoice === 'DBA') {
        window['digitalData'] && (window['digitalData'].pageInfo['dba_isign_new_credit_option'] = 'Only Social Security Number');
    }
    else {
        window['digitalData'] && (window['digitalData'].pageInfo['dba_isign_new_credit_option'] = 'undefined');
    }
}
export function creditHoldCode(creditHoldStatusCode) {
    if (creditHoldStatusCode === 'HD-AN') {
        window['digitalData'].pageInfo['creditHold'] = 'HD_AN More information and hours of operation';
    } else if (creditHoldStatusCode === 'HD-DH') {
        window['digitalData'].pageInfo['creditHold'] = 'HD-DH Outstanding balance and hours of operation';
    } else if (creditHoldStatusCode === 'HD-BU') {
        window['digitalData'].pageInfo['creditHold'] = 'HD-BU More information and submitted BusinessCreditDoc';
    } else if (creditHoldStatusCode === 'HD-FR') {
        window['digitalData'].pageInfo['creditHold'] = 'HD-FR More information and available 24X7';
    }
    else {
        window['digitalData'].pageInfo['creditHold'] = creditHoldStatusCode;
    }
}