import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";

import apiClient from "utils/apiClient";
import { getStoreState } from "app/store";
import * as API from "../containers/APIurls";

const handleGetClientID = () => {
  const data = getStoreState()?.ProspectWidgets_GlobalData;
  return window.localStorage.getItem("clientName") ?? data.clientId 
}

const getAPIHostPath = () => {
  return API.PROSPECT_WIDGETS_APP_BASE_PATH;
}

i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: process.env.PROSPECT_WIDGETS_APP_I18NEXT_ENABLE_DEBUG_MODE,
    lng: "en-US",
    fallbackLng: false,
    load: "currentOnly",
    nsSeparator: false,
    interpolation: {
      escapeValue: false,
    },
    ns: [],
    partialBundledLanguages: true,
    react: {
      bindI18n: "languageChanged",
      bindI18nStore: "",
      useSuspense: true,
    },
    backend: {
      backends: [
        HttpBackend,
        resourcesToBackend((language, namespace, callback) => {
          import(`../translations/${namespace}/en-us.json`)
            .then(({ default: resources }) => {
              callback(null, resources);
            })
            .catch((error) => {
              callback(error, null);
            });
        }),
      ],
      backendOptions: [
        {
          loadPath: `${getAPIHostPath()}devicesvc/mbt/prospect/aem/en-us/{{ns}}/`,
          allowMultiLoading: false,
          request: async (options, url, payload, callback) => {
            try {
              const requestBody = {
                cartMeta: {
                  sourcePath: "CART",
                },
                shoppingPath: "PROSPECT",
                approach: "",
                flow: "prospect",
              };
              const translation = await apiClient.post(`${url}${handleGetClientID()}`, requestBody);
              callback(null, {
                status: 200,
                data: JSON.stringify(translation.data.data),
              });
            } catch (e) {
              callback(e);
            }
          },
        },
      ],
    },
  });

export default i18next;
