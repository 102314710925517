import styled from "styled-components";

export const CreditCheckContainer = styled.div`
  .mtb1 {
    margin: 1rem 0;
  }
  .required-field {
    margin-bottom: 1.5rem;
  }
  .checkbox_wrap > div {
    width: auto;
  }
  .snn-wrap {
    margin: 0 0 1.5rem 0;
  }
  .form-col {
    margin: 0.5rem 1rem 0.5rem 0;
  }
`;

export const EyeUnderline = styled.span`
  position: absolute;
  top: 30px;
  right: 38px;
  text-decoration: underline;
  cursor: pointer;
`;
