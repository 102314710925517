import React, { useEffect, useState } from 'react'
import { Modal, ModalTitle, ModalBody, ModalFooter } from "@vds3/modals";
import { useDispatch, useSelector } from 'react-redux';
import SpinnerComponent from 'common/components/Spinner/Spinner';
import { Title } from '@vds3/typography';
import { MarginSpacerM, MarginSpacerS, MarginSpacerXS } from 'common/components/styleTag';
import { Line } from '@vds3/lines';
import { Body } from '@vds3/typography';
import { TextLink, Button } from '@vds3/buttons';
import { Input } from '@vds3/inputs';
import { sendSelectedAccount, setStoredAccountNumber } from 'pages/Checkout/ducks/CheckoutSlice';
import { Notification } from "@vds3/notifications";

const AccountsModal = ({openAccountModal = false,fromShoppingCart=false,setOpenAccountModal = ()=>{}}) => {
    const dispatch = useDispatch()
    const checkoutData = useSelector(state => state.ProspectWidgets_CheckoutData)
    const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
    const widgetInputParams = globalData?.widgetInputParams;

    const [accounts, setAccount] = useState(undefined)
    const [numberOfRecords, setNumberOfRecords] = useState(0)
    const [showLimitedAccount, setShowLimitedAccounts] = useState(true)
    const [openModal, setOpenModal] = useState(true)
    const [searchValue, setSearchValue] = useState('')
    const [selectedAccountNumber, setSeletedAccountNumber] = useState('')
    const [loader, setLoader] = useState(false)
    const [showNotification, setShowNotification] = useState(false);
    const [continueClicked, setContinueClicked] = useState(false);
    useEffect(()=>{
        if(fromShoppingCart && openAccountModal) setOpenModal(true)
        else if(fromShoppingCart && !openAccountModal) setOpenModal(false)
    },[openAccountModal,fromShoppingCart])
    useEffect(() => {
        if (!!checkoutData.loadBussAccountDetails && typeof checkoutData?.loadBussAccountDetails == 'object') {
            const { accountDetails, totalNumberOfRecords } = checkoutData?.loadBussAccountDetails
            if (!!accountDetails && accountDetails.length) {
                setAccount(accountDetails)
                setNumberOfRecords(totalNumberOfRecords)
            }
        }
        if (continueClicked && checkoutData?.storedAccountNumber === true) {
            setOpenModal(false)
            setLoader(false)
            if(setOpenAccountModal && typeof setOpenAccountModal =='function') setOpenAccountModal(false)

        } else if (continueClicked && checkoutData?.storedAccountNumber === false) {
            setLoader(false)
            setShowNotification(true)

        }
        if(checkoutData?.storedAccountNumber === true && checkoutData?.selectedAccountInfo && checkoutData?.selectedAccountInfo?.accountInfo?.response?.businessInfo?.shellAccountNumber) setSeletedAccountNumber(checkoutData?.selectedAccountInfo?.accountInfo?.response?.businessInfo?.shellAccountNumber)
    }, [checkoutData])
    const onOpenedChange = (val) => {
        if(!val){
        setOpenModal(false)
        if(setOpenAccountModal && typeof setOpenAccountModal =='function') setOpenAccountModal(val)
        }
    }
    const showAccounts = () => {
        if (showLimitedAccount) setShowLimitedAccounts(false)
        else setShowLimitedAccounts(true)
    }
    const onInputChange = (e) => {
        const val = String(e.target.value).toLowerCase()
        setSearchValue(val)
    }
    const selectedAccount = (accountNumber) => setSeletedAccountNumber(accountNumber)
    const onContinue = () => {
        if(!selectedAccountNumber){
            setShowNotification(true)
        }else{
        setShowNotification(false)
        setContinueClicked(true);
        dispatch(setStoredAccountNumber(''))
        const payload = {
            clientId: widgetInputParams?.clientName?.value ?? "",
            accountNumber: selectedAccountNumber,
            ecpdId: widgetInputParams?.ecpdId?.value ?? window?.mbtGlobal?.ecpdId,
            loginUserId: widgetInputParams?.mbLoginId?.value ?? widgetInputParams?.loginUserId?.value ?? widgetInputParams?.mbaUserId?.value ?? window?.mbtGlobal?.userId,
            rootEcpdId: widgetInputParams?.ecpdId?.value ?? "",
            channel: widgetInputParams?.clientName?.value ?? "",
            csr: window?.mbtGlobal?.isCSR ?? "",
            csrRole:window?.mbtGlobal?.csrRole ?? "",
            csrId:window?.mbtGlobal?.csrId ?? "",
            csrFirstName:window?.mbtGlobal?.csrFirstName ?? "",
            csrLastName:window?.mbtGlobal?.csrLastName ?? "",
            csrEmail:window?.mbtGlobal?.csrEmail ?? "",
            csrRoleEligibleForCommerceOrdering:window?.mbtGlobal?.csrRoleEligibleForCommerceOrdering ?? "",
        }
        dispatch(sendSelectedAccount(payload))
        setLoader(true)
    }
    }
    if (!!accounts && accounts.length && !loader) {
        return (
            <Modal
                id="account-modal-buss"
                opened={openModal}
                hideCloseButton={false}
                disableOutsideClick={true}
                onOpenedChange={onOpenedChange}
            >
                <ModalTitle>
                    <Title bold={true} size='large'>Select an account</Title>
                    <MarginSpacerM />
                    <Line type="light" inverted={false} />
                </ModalTitle>
                <ModalBody>
                    {showNotification ? <>
                        <Notification
                            type="error"
                            inline={true}
                            fullBleed={true}
                            hideCloseButton={false}
                            title={"Select an eligible account to continue."}
                        />
                        <MarginSpacerM />
                    </> : <></>}
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                        <Input value={searchValue} width="300px" onChange={onInputChange} required={false} />
                        <Body size="large">{numberOfRecords} {" "}total accounts</Body>
                    </div>
                    <div className='account-modal'>
                        {accounts.map((account, i) => {
                            if ((showLimitedAccount && i < 6) || !showLimitedAccount) {
                                return (
                                    <AccountDiv {...account} searchValue={searchValue} selectedAccount={selectedAccount} selectedAccountNumber={selectedAccountNumber} />
                                )
                            }
                        })}
                    </div>

                    <MarginSpacerM />
                    <TextLink onClick={() => showAccounts()}>{showLimitedAccount ? "Show all accounts" : "Show less account"}</TextLink>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => onContinue()}>Continue</Button>
                </ModalFooter>
            </Modal>
        )
    } else return <SpinnerComponent fullscreen={false} />;
}
export default AccountsModal

const AccountDiv = ({ accountName = "", accountNumber = "", totalLineCt = "", searchValue = "", selectedAccount = () => { }, selectedAccountNumber = "" }) => {
    if (!searchValue || (searchValue && (String(accountNumber).toLowerCase().includes(searchValue) ||
        String(accountName).toLowerCase().includes(searchValue) || String(totalLineCt).toLowerCase().includes(searchValue)))) {
        return (
            <div style={{ padding: '1.5rem 1rem', minWidth: '200px', border: `${selectedAccountNumber === accountNumber ? "2px solid black" : "1px solid #d8dada"} `, margin: '1rem', cursor: 'pointer', 'border-radius': '8px' }} onClick={() => selectedAccount(accountNumber)}>
                <Body size="medium" bold={true}>{accountNumber}</Body>
                <MarginSpacerM />
                <Body size="medium" bold={true}>{accountName}</Body>
                <MarginSpacerXS />
                <Body size='medium'>Line count - {totalLineCt}</Body>
            </div>
        )
    } else return <></>
}
