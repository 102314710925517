import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TextLink } from "@vds3/buttons";
import { Input } from "@vds3/inputs";
import { ListGroup } from "@vds3/lists";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "@vds3/modals";
import { Body, Title } from "@vds3/typography";
import { Tabs, Tab } from "@vds3/tabs";
import styled from "styled-components";
import useToggle from "../../../../hooks/useToggle";
import { useSelector } from "react-redux";

const ModalBodyContainer = styled.div`
> div h5{
  font-size: 2rem;
  font-weight: 700;
  line-height: 2rem;
}
> div p{
  font-size: 1rem;
}
.padding20{
  padding-top: 20px;
  padding-bottom: 20px;
}
.padding10{
  padding-top: 10px;
  padding-bottom: 10px;
}
> div:nth-child(4){
  padding: .8rem 0 0;
}
.simid-input-wrappper{
  width:68%;
  padding-top:10px;
}
.simid-input-wrappper p{
  font-size: .75rem;
}
.simid-input-wrappper div:first-child{
  width:100%;
}
`;

const TabContainer = styled.div`
width: "300px";
> div div ul li button[aria-selected="false"]{
  border-top: none;
  border-bottom: 4px transparent solid;
}
> div div ul li button[aria-selected="true"]{
  border-top: none;
  border-bottom: 4px solid #EE0000;
}
>div div ul{
  box-shadow: none;
}
> div .device-item{
  list-style: decimal;
}
> div .device-item li{
  margin-bottom: 8px;
}
> div .device-item li span{
  margin-left: 1%;
}
> div .device-item li:first-child span{
  margin-left: 1.5%;
}
`;

export const SimTypeModal = (props) => {
  const [showByodModal, toggleOkButton] = useToggle();
  const { t, i18n } = useTranslation(["app", "byod", "gridwall"]);

  return (
    <>
      <TextLink
        className="link-text"
        type="standAlone"
        inverted={false}
        disabled={false}
        onClick={toggleOkButton}
      >
        {" "}
        {t("byod.embeded-physical-sim", { ns: "byod" })}
      </TextLink>
      <Modal
        opened={showByodModal}
        disableOutsideClick={true}
        onOpenedChange={props.onOpenedChange}
      >
        <ModalTitle>
          <Title size="small" bold={true}>
            {t("byod.esim-modal.heading", { ns: "byod" })}
          </Title>
        </ModalTitle>

        <ModalBody>
          <Body bold>{t("byod.esim-modal.esim", { ns: "byod" })}</Body>
          <div className="padding10">
            <Body>{t("byod.esim-modal.esimdesc", { ns: "byod" })}</Body>
          </div>
          <div className="padding10">
            <Body bold>{t("byod.esim-modal.psim", { ns: "byod" })}</Body>
            <div className="padding10">
              <Body>{t("byod.esim-modal.psimdesc", { ns: "byod" })}</Body>
            </div>
          </div>
        </ModalBody>
        <ModalFooter
          buttonData={{
            primary: {
              children: "Ok",
              onClick: toggleOkButton,
            },
          }}
        />
      </Modal>
    </>
  );
};

export const GoBackModal = ({ opened, toggleModal, onContinueClick }) => {
  const { t, i18n } = useTranslation(["app", "byod", "gridwall"]);

  return (
    <>
      <Modal
        opened={opened}
        hideCloseButton={true}
        disableOutsideClick={true}
        onOpenedChange={toggleModal}
      >
        <ModalTitle>
          <Title size="medium">{"Are you sure you want to go back?"}</Title>
        </ModalTitle>
        <ModalBody>
          <Body size="large">
            You'll lose the information you've entered about your device.
          </Body>
        </ModalBody>
        <ModalFooter
          buttonData={{
            primary: {
              children: "Continue",
              onClick: onContinueClick,
            },
            close: {
              children: "Cancel",
              onClick: toggleModal,
            },
          }}
        />
      </Modal>
    </>
  );
};
export const PhySicalSimModalByod = ({
  pageData,
  physicalSim,
  isValidSim,
  handleSimcardInputChange,
  onclickPhysicalCheckSim,
  setOwnSimModal,
  onUpdateByod,
  ...props}) => {
  const { t, i18n } = useTranslation(["app"]);
  const [isSimCompatible, setSimCompatible] = useState(false);
  const [isSimError, setSimError] = useState(false);
  const [validateSim,handleValidateSim] = useState(false)
  const validateSimIdResp = useSelector(
    (state) => state?.Landing5GPage?.byor?.validateSimIdResp
  );

  useEffect(() => {
      if (validateSimIdResp && Object.keys(validateSimIdResp)?.length) {
        if (validateSim&&validateSimIdResp?.serviceStatus?.success) {
          setSimCompatible(true);
          setSimError(false);
          onUpdateByod();
          if(physicalSim){
            setOwnSimModal(false);
          }
        } else {
          setSimCompatible(false);
          if(physicalSim){
          setSimError(true);
          setOwnSimModal(true);
          }
        }
      }
  }, [validateSimIdResp]);

    const setSimValue = (value) => {
      setSimCompatible(false);
      setSimError(false);
      handleSimcardInputChange(value);
    };

  return (
    <Modal
      opened={props?.opened}
      hideCloseButton={true}
      disableOutsideClick={true}
      onOpenedChange={props?.onOpenedChange}
    >
      <ModalBodyContainer>
      <ModalTitle>
        <Title size="small">
          {t("app.common.byod.findSimModal.modalHeading", {ns: "app"})}
          </Title>
      </ModalTitle>
      <div className="padding20">
        <Body size="medium">
        {t("app.common.byod.findSimModal.modalSubHeading", {ns: "app"})}
        </Body>
      </div>
      <div>
        <Body size="medium">
        {t("app.common.byod.findSimModal.content.heading", {ns: "app"})}
          </Body>
      </div>
      <ModalBody>
        <TabContainer>
            <Tabs
              indicatorPosition="bottom"
              active
              >
              <Tab label="iPhone" >
                <div className="padding10">
                  <ListGroup>
                    <div
                      className="device-item"
                      dangerouslySetInnerHTML={{ __html: t("app.common.byod.findSimModal.content.tabList.iPhone.content", { ns: "app" }) }}/>
                  </ListGroup>
                </div>
              </Tab>
              <Tab label="Android" >
                <div className="padding10">
                  <ListGroup>
                    <div
                      className="device-item"
                      dangerouslySetInnerHTML={{ __html: t("app.common.byod.findSimModal.content.tabList.android.content", { ns: "app" }) }}
                    />
                  </ListGroup>
                </div>
              </Tab>
              <Tab label="Windows" >
                <div className="padding10">
                  <ListGroup>
                    <div
                      className="device-item"
                      dangerouslySetInnerHTML={{ __html: t("app.common.byod.findSimModal.content.tabList.windows.content", { ns: "app" }) }}
                    />
                  </ListGroup>
                </div>
              </Tab>
            </Tabs>
        </TabContainer>
        <div className="simid-input-wrappper">
          <Input
            label={t("app.common.byod.findSimModal.content.label", {ns: "app"})}
            placeholder=""
            name=""
            width={"50%"}
            value={physicalSim}
            required={true}
            onChange={(e) => {
              setSimCompatible(false);
              setSimValue(e);
            }}
            type="number"
            success={physicalSim && isSimCompatible}
            successText={(physicalSim && isValidSim && isSimCompatible) ? t("app.common.byod.findSimModal.errorMessages.validSimMsg", {ns: "app"}) : ""}
            error={!isValidSim ? !isValidSim : isSimError}
            errorText={isSimError ? 
             t("app.common.byod.findSimModal.errorMessages.invalidid", {ns: "app"}) :
             t("app.common.byod.findSimModal.errorMessages.invalidiDigit", {ns: "app"})} 
          />
        </div>
      </ModalBody>
      <ModalFooter
        buttonData={{
          primary: {
            disabled:physicalSim?.length > 0 ? !isValidSim :true,
            children:t("app.common.byod.findSimModal.buttons.submit", {ns: "app"}),
            onClick: () => {
              handleValidateSim(true)
              onclickPhysicalCheckSim()
             } ,
          },
          close: {
             children:t("app.common.byod.findSimModal.buttons.cancel", {ns: "app"}),
            onClick: () => {setOwnSimModal(false)},
          },
        }}
      />
      </ModalBodyContainer>
    </Modal>
  );
};

export default GoBackModal;