import each from "lodash/each";
import get from "lodash/get";
import { fork, join, put, select, takeLatest } from "redux-saga/effects";

import * as API from "containers/APIurls";
import apiClient from "utils/apiClient";
import * as PlansSlice from "./PlansSlice";
import * as MobilitySlice from "../../../ducks/MobilitySlice";
import * as selectors from "./selectors";
import { areSameType } from "../util";
import { uniqBy } from "lodash";

export const getMatchingList = (data = [], isEditPackageType) => {
	return data?.filter((device) => {
		if (device?.packageType === isEditPackageType) {
			return device;
		}
	});
};

function* getInitPageLoadInfo(params) {
	const { flow } = params.payload;
	yield put(PlansSlice.setAPIError(false));
	yield put(MobilitySlice.setAPIError(false));
	yield put(PlansSlice.setPageLoading(true));
	yield put(PlansSlice.setSelectedFeatures({}));
	try {
		const forks = [];
		forks.push(yield fork(getDevicePlanPromos, flow));
		forks.push(yield fork(getDeviceCompatiblePlans));
		yield join([...forks]);
	} catch (err) {
	} finally {
		yield put(PlansSlice.setPageLoading(false));
	}
}

function* getFeatures(params) {
	const { outletId, billingState, flow } = params.payload;
	yield put(PlansSlice.setAPIError(false));
	yield put(PlansSlice.setPageLoading(true));
	const globalData = yield select(selectors.globalData);
	const mobilityPage = yield select(selectors.mobilityPage);
	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	const isEditFeaturesFlow = globalData?.isEditFeaturesFlow;
	const isEditPackageType = globalData?.isEditPackageType;
	const zipCode = sessionCartData?.businessInfo?.address?.zipCode || "";
	try {
		const requestBody = {
			cartMeta: {
				shoppingPath: "PROSPECT",
				zipCode: zipCode || mobilityPage?.zipCode,
				billingState: sessionCartData?.cart?.billingState || billingState,
				outletId: outletId,
			},
			flow: flow,
			lines: [],
		};
		const tempPackages = sessionCartData?.cart?.packages;
		const packages = Object.fromEntries(
			Object.entries(tempPackages).filter(([key, value]) => {
				return (
					value.packageType !== "FOURG" &&
					value.packageType !== "FIVEG" &&
					value.packageType !== "ONETALK" &&
					value.packageType !== "IOT"
				);
			})
		);
		for (let deviceKey in packages) {
			const device = packages[deviceKey];
			requestBody.lines.push({
				packageId: deviceKey,
				deviceSku: device.deviceSku,
				simSku: device.simSku,
				contractLength: device.priceType,
				packageType: device.packageType,
				offerId: "",
				sharePlan: false,
				operation: null,
				device: null,
				plan: null,
				features: null,
				contractTerm: device.contractTerm,
				deviceCategory: device.deviceCategory,
			});
		}

		const resp = yield apiClient.post(
			API.GET_DEVICE_PROTECTION_PLANS,
			requestBody
		);
		yield put(PlansSlice.setFeaturesDetails(resp?.data));

		const availableDevicesFrom = getDevices(sessionCartData, null, true);
		let availableDevices = availableDevicesFrom;
		if (isEditFeaturesFlow && isEditPackageType) {
			availableDevices = getMatchingList(availableDevicesFrom, isEditPackageType);
		} else {
			availableDevices = availableDevicesFrom;
		}
		yield put(PlansSlice.setFeaturesAvailableDevices(availableDevices));
		const { devicesHavingFeatures, selectedFeature } = getFeaturesForDevices(
			resp?.data,
			availableDevices
		);
		yield put(PlansSlice.setSelectedFeatureNew(selectedFeature));
		yield put(PlansSlice.setDeviceTypeFeaturesNew(devicesHavingFeatures));
		yield put(
			PlansSlice.setIsAllFeaturesSelectedOnRevisit(
				Object.keys(selectedFeature).length === availableDevices.length
			)
		);
		if (
			resp.data === null ||
			resp.data === undefined ||
			(resp.data !== null &&
				resp.data &&
				(resp.data.featureList === null || resp.data.featureList === undefined))
		)
			yield put(MobilitySlice.setError(true));
		else yield put(MobilitySlice.setError(false));
		if (availableDevices.length > 0) {
			const isLaptopSelected = availableDevices.filter((data) =>
				String(data.deviceCategory).toLowerCase().includes("laptop")
			);
			yield put(PlansSlice.setIsSelectAllChecked(isLaptopSelected ? false : true));
			const deviceWithFeaturesListFrom = availableDevices.filter((device) => {
				let flag = false;
				if (!!device.features) {
					const featureIds = Object.keys(device.features);
					if (featureIds.length) {
						featureIds.map((featureId) => {
							const f = device.features[featureId];
							if (featureId.toString() !== "2473") {
								flag = true;
							}
						});
					}
				}
				return flag;
			});

			let deviceWithFeaturesList = deviceWithFeaturesListFrom;
			if (isEditFeaturesFlow && isEditPackageType) {
				deviceWithFeaturesList = getMatchingList(
					deviceWithFeaturesListFrom,
					isEditPackageType
				);
			} else {
				deviceWithFeaturesList = deviceWithFeaturesListFrom;
			}

			const mappedDevices =
				(deviceWithFeaturesList &&
					deviceWithFeaturesList.map((device) => device.key)) ||
				[];

			let remainingListFrom = availableDevices;
			if (isEditFeaturesFlow && isEditPackageType) {
				remainingListFrom = getMatchingList(availableDevices, isEditPackageType);
			}
			const remainingList = remainingListFrom?.filter(
				(device) => mappedDevices.indexOf(device?.key) == -1
			);
			yield put(PlansSlice.setFeaturesDevicePlanList(deviceWithFeaturesList));
			const allSameTypes =
				remainingList?.length &&
				remainingList.every((device) =>
					areSameType(device.deviceCategory, remainingList[0].deviceCategory)
				);
			if (!allSameTypes) {
				yield put(PlansSlice.setFeaturesSelectedDevices([]));
				yield put(PlansSlice.setIsSelectAllChecked(false));
			} else {
				yield put(PlansSlice.setFeaturesSelectedDevices(remainingList));
				if (isLaptopSelected) yield put(PlansSlice.setIsSelectAllChecked(true));
				else yield put(PlansSlice.setIsSelectAllChecked(true));
			}
		} else {
			yield put(PlansSlice.setFeaturesDevicePlanList([]));
			yield put(PlansSlice.setFeaturesSelectedDevices([]));
		}
	} catch (err) {
	} finally {
		yield put(PlansSlice.setPageLoading(false));
		yield put(PlansSlice.setIsFeaturePageVisted(true));
	}
}

function* getMetaInformation() {
	yield put(PlansSlice.setAPIError(false));
	yield put(PlansSlice.setPageLoading(true));
	try {
		const requestBody = {
			digitalQuote: false,
			shoppingPath: "PROSPECT",
			approach: "",
			flow: "one-talk",
		};
		const resp = yield apiClient.post(API.ONETALK_META_INFO, requestBody);
		yield put(PlansSlice.setMetaInfo(resp?.data?.data));
	} catch (err) {
		yield put(PlansSlice.setAPIError(true));
	} finally {
		yield put(PlansSlice.setPageLoading(false));
	}
}

function* getDeviceCompatiblePlans() {
	yield put(PlansSlice.setAPIError(false));
	yield put(PlansSlice.setPageLoading(true));
	const globalData = yield select(selectors.globalData);
	const mobilityPage = yield select(selectors.mobilityPage);
	const chosenPlans = yield select(
		(state) => state.ProspectWidgets_WirelessPlansData.devicePlanList
	);
	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	const isEditPlansFlow = globalData?.isEditPlansFlow;
	const isEditPackageType = globalData?.isEditPackageType;
	const zipCode = sessionCartData?.businessInfo?.address?.zipCode || "";

	try {
		let requestBody = {
			cartMeta: {
				zipCode: zipCode || mobilityPage?.zipCode,
				shoppingPath: "PROSPECT",
				flow: "device-first",
			},
			lines: [],
		};
		const tempPackages = sessionCartData?.cart?.packages;
		const packages = Object.fromEntries(
			Object.entries(tempPackages).filter(([key, value]) => {
				return (
					value.packageType !== "FOURG" &&
					value.packageType !== "FIVEG" &&
					value.packageType !== "ONETALK" &&
					value.packageType !== "IOT"
				);
			})
		);
		for (let deviceKey in packages) {
			const device = packages[deviceKey];
			requestBody.lines.push({
				packageId: deviceKey,
				packageType: device?.packageType ?? "REGULAR",
				priceType: device?.priceType,
				oneTalkDeviceType:
					device?.packageType === "ONETALK" ? device?.packageType : "",
				planFlow: device?.tradInInfo?.recycleDeviceOffer?.offerId
					? "tradein"
					: device?.guidedPromoOfferDetails?.offerId
					? "bue"
					: "",
			});
		}
		const resp = yield apiClient.post(
			API.GET_DEVICE_COMPATIBLE_PLANS,
			requestBody
		);
		yield put(PlansSlice.setCartId(sessionCartData?.cart?.cartId));
		yield put(PlansSlice.setDeviceCompatiblePlansInfo(resp?.data));
		if (resp?.data?.devices === null || resp?.data?.devices === undefined)
			yield put(MobilitySlice.setError(true));
		else yield put(MobilitySlice.setError(false));
		const availableDevicesFrom = getDevices(sessionCartData, resp?.data?.devices);
		let availableDevices = availableDevicesFrom;
		if (isEditPlansFlow && isEditPackageType) {
			availableDevices = getMatchingList(availableDevicesFrom, isEditPackageType);
		} else {
			availableDevices = availableDevicesFrom;
		}
		const { devicesHavingPlans, selectedPlan } = getPlansForDevices(
			resp?.data,
			availableDevices
		);
		yield put(PlansSlice.setDeviceTypeCompatiblePlansNew(devicesHavingPlans));
		yield put(PlansSlice.setSelectedPlansNew(selectedPlan));
		const deviceTypeCompatiblePlans = getCompatiblePlans(
			availableDevices,
			resp?.data
		);
		yield put(PlansSlice.setDeviceTypeCompatiblePlans(deviceTypeCompatiblePlans));
		yield put(PlansSlice.setAvailableDevices(availableDevices));

		if (availableDevices.length > 0) {
			const devicePlanListFrom = availableDevices.filter(
				(device) =>
					!!device.plan &&
					device.plan?.pricePlanDesc !== null &&
					device.plan?.pricePlanDesc
			);

			let devicePlanList = devicePlanListFrom;
			if (isEditPlansFlow && isEditPackageType) {
				devicePlanList = getMatchingList(devicePlanListFrom, isEditPackageType);
			} else {
				devicePlanList = devicePlanListFrom;
			}

			const mappedDevices =
				(devicePlanList && devicePlanList.map((device) => device.key)) || [];

			let remainingListFrom = availableDevices;
			if (isEditPlansFlow && isEditPackageType) {
				remainingListFrom = getMatchingList(availableDevices, isEditPackageType);
			}
			const allAreSameType = remainingListFrom?.every((device) =>
				areSameType(device.deviceCategory, remainingListFrom[0].deviceCategory)
			);
			if (allAreSameType) {
				let allHaveSamePlans = true;
				if (resp?.data?.devices) {
					const keys = Object.keys(resp?.data?.devices);
					keys.map((data, i) => {
						if (
							resp?.data?.devices[keys[0]]["82"].length !==
							resp?.data?.devices[data]["82"].length
						)
							allHaveSamePlans = false;
					});
				}
				if (allHaveSamePlans)
					yield put(PlansSlice.setSelectedDevices(remainingListFrom));
				else yield put(PlansSlice.setSelectedDevices([]));
			}
			yield put(PlansSlice.setdevicePlanList(devicePlanList));
		} else {
			yield put(PlansSlice.setdevicePlanList([]));
			yield put(PlansSlice.setSelectedDevices([]));
		}
	} catch (err) {
	} finally {
		yield put(PlansSlice.setPageLoading(false));
	}
}

const getPlansForDevices = (plans, availableDevices) => {
	let deviceTypeInfo = {
		Smartphones: {
			displayName: "Smartphones",
			devices: [],
			priceType: {
				TWO_YEAR: [],
				other: [],
			},
			key: "Smartphones",
			order: 1,
		},

		Tablets: {
			displayName: "Tablets",
			devices: [],
			priceType: {
				TWO_YEAR: [],
				other: [],
			},
			order: 2,
		},
		ConnectedLaptops: {
			displayName: "Connected Laptops",
			devices: [],
			priceType: {
				TWO_YEAR: [],
				other: [],
			},
			order: 3,
		},
		BroadbandAccessDevices: {
			displayName: "Mobile Hotspots",
			devices: [],
			priceType: {
				TWO_YEAR: [],
				other: [],
			},
			order: 4,
		},
		SmartWatches: {
			displayName: "Smartwatches",
			devices: [],
			priceType: {
				TWO_YEAR: [],
				other: [],
			},
			order: 5,
		},
		BasicPhones: {
			displayName: "Basic Phones",
			devices: [],
			priceType: {
				TWO_YEAR: [],
				other: [],
			},
			order: 6,
		},
		ConnectedDevices: {
			displayName: "Connected Devices",
			devices: [],
			priceType: {
				TWO_YEAR: [],
				other: [],
			},
			order: 7,
		},
		FiveGConnectedDevice: {
			displayName: "5G Connected Devices",
			devices: [],
			priceType: {
				TWO_YEAR: [],
				other: [],
			},
			order: 8,
		},
	};
	let devicesHavingPlans = {};
	const deviceCatMapping = {
		undefined: "Smartphones",
		laptop: "ConnectedLaptops",
		tablet: "Tablets",
		netbook: "Tablets",
		phones: "BasicPhones",
		basic: "BasicPhones",
		featurephone: "BasicPhones",
		"5g connected device": "FiveGConnectedDevice",
		"connected devices": "ConnectedDevices",
		smartphone: "Smartphones",
		internet: "Smartphones",
		smartwatches: "SmartWatches",
	};
	const selectedPlans = plans?.plansCategoryMapping?.buePlans;
	const flexiblePlans = plans?.plansCategoryMapping?.flexiblePlans;
	const plansFromPlanApi = plans?.devices;
	let selectedPlan = {};
	const sendElegiblePlans = (comPlans, plansDevice) => {
		let plansToBeStored = [];
		let flexiblePlansToBeStored = [];
		if (selectedPlans !== null && selectedPlans && selectedPlans.length) {
			selectedPlans.map((planbue) => {
				if (
					comPlans[planbue] !== null &&
					comPlans[planbue] &&
					comPlans[planbue].length
				) {
					plansToBeStored = [...plansDevice, ...comPlans[planbue]];
				}
			});
		}
		if (flexiblePlans !== null && flexiblePlans && flexiblePlans.length) {
			flexiblePlans.map((flPlan) => {
				if (
					comPlans[flPlan] !== null &&
					comPlans[flPlan] &&
					comPlans[flPlan].length
				) {
					flexiblePlansToBeStored = [...plansDevice, ...comPlans[flPlan]];
				}
			});
		}
		return { plansToBeStored, flexiblePlansToBeStored };
	};
	availableDevices.map((item) => {
		const imageSet = String(item.description).split(":_:");
		let device = {
			deviceSku: item["deviceSku"],
			capacity: item["capacity"],
			color: item["color"],
			packageDesc: item["packageDesc"],
			deviceId: item["deviceId"],
			plans: [],
			image: imageSet[5]
				? `https://ss7.vzw.com/is/image/VerizonWireless/${imageSet[5]}?$acc-lg$&hei=72&wid=72`
				: item.imageSet !== null && item.imageSet
				? `https://ss7.vzw.com/is/image/VerizonWireless/${
						String(item.imageSet).split("?req=set,json")[0]
				  }?$acc-lg$&hei=72&wid=72`
				: undefined,
			packageId: item["key"],
			priceType: String(item["priceType"]).toUpperCase().includes("TWO_YEAR")
				? "TWO_YEAR"
				: "other",
			byod: item["byod"],
		};
		let deviceType = item?.deviceCategory || "";
		let plansToBeStoredNew = [];
		let flexiblePlansToBeStoredNew = [];
		if (
			plansFromPlanApi !== null &&
			plansFromPlanApi &&
			Object.keys(plansFromPlanApi).length
		) {
			if (String(item?.priceType).toUpperCase().includes("TWO_YEAR")) {
				const { plansToBeStored, flexiblePlansToBeStored } = sendElegiblePlans(
					plansFromPlanApi[item?.deviceSku + "_24"],
					device.plans
				);
				flexiblePlansToBeStoredNew = flexiblePlansToBeStored;
				plansToBeStoredNew = plansToBeStored;
			} else {
				const { plansToBeStored, flexiblePlansToBeStored } = sendElegiblePlans(
					plansFromPlanApi[item?.deviceSku + "_48"],
					device.plans
				);
				flexiblePlansToBeStoredNew = flexiblePlansToBeStored;
				plansToBeStoredNew = plansToBeStored;
			}
		}
		let newPlans = [];
		let flexiblePlansToDevice = [];

		plansToBeStoredNew.map((plan) => {
			let planToStore = { ...plans.plans[Number(plan.billingCode)] };
			Object.keys(plans?.devices).map((deviceKey) => {
				const deviceK = String(deviceKey).split("_")[0];
				if (deviceK === item?.deviceSku) {
					Object.values(plans?.devices[deviceKey]).map((val) => {
						if (val !== null && val && val.length) {
							val.map((planPromo) => {
								if (planPromo["billingCode"] === planToStore["billingCode"])
									planToStore = {
										...planToStore,
										promoEligible:
											planPromo["promoEligible"] !== null && planPromo["promoEligible"]
												? planPromo["promoEligible"]
												: null,
									};
							});
						}
					});
				}
			});
			if (
				item?.plan !== null &&
				item?.plan &&
				Object.keys(item?.plan).length &&
				plan.billingCode == item?.plan.pricePlanCode
			) {
				selectedPlan = {
					...selectedPlan,
					[item["key"]]: {
						billingCode: plan.billingCode,
						selectedPlanTitle: item?.plan?.pricePlanDesc,
						promoEligible: plan["promoEligible"],
					},
				};
			}
			newPlans.push(planToStore);
		});
		newPlans.sort((a, b) => {
			return Number(a.monthlyFee) > Number(b.monthlyFee) ? 1 : -1;
		});
		flexiblePlansToBeStoredNew.map((plan) => {
			let planToStore = { ...plans.plans[Number(plan.billingCode)] };
			if (
				item?.plan !== null &&
				item?.plan &&
				Object.keys(item?.plan).length &&
				plan.billingCode == item?.plan.pricePlanCode
			) {
				selectedPlan = {
					...selectedPlan,
					[item["key"]]: {
						billingCode: plan.billingCode,
						selectedPlanTitle: item?.plan?.pricePlanDesc,
					},
				};
			}
			flexiblePlansToDevice.push(planToStore);
		});
		flexiblePlansToDevice.sort((a, b) => {
			return Number(a.billingCode) > Number(b.billingCode) ? 1 : -1;
		});
		Object.keys(deviceCatMapping).map((key) => {
			if (String(item?.deviceCategory).toLowerCase().includes(key)) {
				deviceType = deviceCatMapping[key];
				return;
			}
		});
		deviceTypeInfo[deviceType?.replace(/\s/g, "")]["devices"].push({
			...device,
			plans: newPlans,
			flexiblePlans: flexiblePlansToDevice,
			key: deviceType?.replace(/\s/g, ""),
		});
		deviceTypeInfo[deviceType?.replace(/\s/g, "")]["priceType"][
			device["priceType"]
		].push(item["key"]);
		deviceTypeInfo[deviceType?.replace(/\s/g, "")] = {
			...deviceTypeInfo[deviceType?.replace(/\s/g, "")],
			key: deviceType?.replace(/\s/g, ""),
		};
		deviceTypeInfo[deviceType?.replace(/\s/g, "")].deviceCategory =
			item?.deviceCategory;
		devicesHavingPlans[deviceType?.replace(/\s/g, "")] =
			deviceTypeInfo[deviceType?.replace(/\s/g, "")];
	});
	return { devicesHavingPlans, selectedPlan };
};

const getFeaturesForDevices = (features, availableDevices) => {
	let deviceTypeInfo = {
		Smartphones: {
			displayName: "Smartphones",
			devices: [],
			key: "Smartphones",
			order: 1,
		},
		Tablets: {
			displayName: "Tablets",
			devices: [],
			order: 2,
		},
		ConnectedLaptops: {
			displayName: "Connected Laptops",
			devices: [],
			order: 3,
		},
		BroadbandAccessDevices: {
			displayName: "Mobile Hotspots",
			devices: [],
			order: 4,
		},
		SmartWatches: {
			displayName: "Smartwatches",
			devices: [],
			order: 5,
		},
		BasicPhones: {
			displayName: "Basic Phones",
			devices: [],
			order: 6,
		},
		ConnectedDevices: {
			displayName: "Connected Devices",
			devices: [],
			order: 7,
		},
		FiveGConnectedDevice: {
			displayName: "5G Connected Devices",
			devices: [],
			order: 8,
		},
	};
	let devicesHavingFeatures = {};
	const deviceCatMapping = {
		undefined: "Smartphones",
		laptop: "ConnectedLaptops",
		tablet: "Tablets",
		netbook: "Tablets",
		phones: "BasicPhones",
		basic: "BasicPhones",
		featurephone: "BasicPhones",
		"5g connected device": "FiveGConnectedDevice",
		"connected devices": "ConnectedDevices",
		smartphone: "Smartphones",
		internet: "Smartphones",
		smartwatches: "SmartWatches",
	};
	let selectedFeature = {};
	availableDevices.map((item) => {
		const imageSet = String(item.description).split(":_:");
		let device = {
			deviceSku: item["deviceSku"],
			capacity: item["capacity"],
			color: item["color"],
			packageDesc: item["packageDesc"],
			deviceId: item["deviceId"],
			plan: item?.plan,
			image: imageSet[5]
				? `https://ss7.vzw.com/is/image/VerizonWireless/${imageSet[5]}?$acc-lg$&hei=72&wid=72`
				: item.imageSet !== null && item.imageSet
				? `https://ss7.vzw.com/is/image/VerizonWireless/${
						String(item.imageSet).split("?req=set,json")[0]
				  }?$acc-lg$&hei=72&wid=72`
				: undefined,
			packageId: item["key"],
			byod: item["byod"],
		};
		let deviceType = item?.deviceCategory || "";
		let newFeatures = [];
		let declineFeature = undefined;
		features?.featureList.map((feature) => {
			if (
				feature?.billingCode !== "66332" &&
				feature?.spoCategoryCode !== "SS" &&
				item?.packageType !== "TYS"
			) {
				features?.linesOnTransaction &&
					features?.linesOnTransaction
						.filter((campf) => item["key"].includes(campf.linePackageId))
						.slice(0, 1)
						.map((data) => {
							if (
								item["key"].includes(data.linePackageId) &&
								data?.compatibleFeatures.includes(feature?.billingCode)
							) {
								newFeatures.push(feature);
							}
						});
			} else if (feature?.billingCode === "66332") declineFeature = { ...feature };
		});

		if (
			item?.features !== null &&
			item?.features &&
			Object.keys(item?.features).length
		) {
			Object.keys(item?.features)
				.filter((item) => item !== "2473")
				.map((cartFeature) => {
					if (
						newFeatures?.some(
							(nf) => nf?.billingCode === item?.features[cartFeature]?.visFeatureCode
						)
					) {
						selectedFeature = {
							...selectedFeature,
							[item["key"]]: {
								billingCode: item?.features[cartFeature]?.visFeatureCode,
								selectedFeatureTitle: item?.features[cartFeature]?.featureDesc,
							},
						};
					} else if (item?.features[cartFeature]?.visFeatureCode === "66332") {
						selectedFeature = {
							...selectedFeature,
							[item["key"]]: {
								billingCode: item?.features[cartFeature]?.visFeatureCode,
								selectedFeatureTitle: item?.features[cartFeature]?.featureDesc,
							},
						};
					}
				});
		}

		Object.keys(deviceCatMapping).map((key) => {
			if (String(item?.deviceCategory).toLowerCase().includes(key)) {
				deviceType = deviceCatMapping[key];
				return;
			}
		});
		deviceTypeInfo[deviceType?.replace(/\s/g, "")]["devices"].push({
			...device,
			features: newFeatures,
			key: deviceType?.replace(/\s/g, ""),
			declineFeature: declineFeature,
		});
		deviceTypeInfo[deviceType?.replace(/\s/g, "")] = {
			...deviceTypeInfo[deviceType?.replace(/\s/g, "")],
			key: deviceType?.replace(/\s/g, ""),
		};
		devicesHavingFeatures[deviceType?.replace(/\s/g, "")] = {
			...deviceTypeInfo[deviceType?.replace(/\s/g, "")],
		};
	});
	return { devicesHavingFeatures, selectedFeature };
};

const getDevices = (cart, selectedDevices, skipTYS) => {
	const devices = [];
	if (cart && selectedDevices) {
		const tempPackages = get(cart, "cart.packages", null);
		const packages = Object.fromEntries(
			Object.entries(tempPackages).filter(([key, value]) => {
				return (
					value.packageType !== "FOURG" &&
					value.packageType !== "FIVEG" &&
					value.packageType !== "ONETALK" &&
					value.packageType !== "IOT"
				);
			})
		);
		if (packages) {
			each(packages, (pck, key) => {
				const filteredKey = Object.keys(selectedDevices).filter(
					(dev) => dev.indexOf(key.split("_")[0]) > -1
				)[0];
				let compatiblePlans = selectedDevices[filteredKey];
				devices.push({ ...pck, key, compatiblePlans });
			});
		}
	} else if (cart) {
		const tempPackages = get(cart, "cart.packages", null);
		const packages = Object.fromEntries(
			Object.entries(tempPackages).filter(([key, value]) => {
				return (
					value.packageType !== "FOURG" &&
					value.packageType !== "FIVEG" &&
					value.packageType !== "ONETALK" &&
					value.packageType !== "IOT"
				);
			})
		);
		if (packages) {
			each(packages, (pck, key) => {
				if (!skipTYS || !pck.tys) {
					devices.push({ ...pck, key });
				}
			});
		}
	}
	return devices;
};

const getCompatiblePlans = (availableDevices, plans) => {
	let deviceTypeInfo = {
		Smartphones: {
			displayName: "Smartphones",
			isExpanded: false,
			billingCode: [],
		},
		BroadbandAccessDevices: {
			displayName: "Business Hotspot Devices",
			isExpanded: false,
			billingCode: [],
		},
		Tablets: {
			displayName: "Tablets",
			isExpanded: false,
			billingCode: [],
		},
		SmartWatches: {
			displayName: "Smart Watches",
			isExpanded: false,
			billingCode: [],
		},
		ConnectedLaptops: {
			displayName: "Connected Laptops",
			isExpanded: false,
			billingCode: [],
		},
		BasicPhones: {
			displayName: "Basic Phones",
			isExpanded: false,
			billingCode: [],
		},
		ConnectedDevices: {
			displayName: "Connected Devices",
			isExpanded: false,
			billingCode: [],
		},
		FiveGConnectedDevice: {
			displayName: "5G Connected Devices",
			isExpanded: false,
			billingCode: [],
		},
	};

	availableDevices.map((item) => {
		item?.compatiblePlans &&
			Object.keys(item?.compatiblePlans).map((cat) => {
				if (plans?.plansCategoryMapping?.buePlans.indexOf(+cat) !== -1) {
					item?.compatiblePlans[cat].map((plan) => {
						let category = item?.deviceCategory?.toLowerCase();
						let deviceType = item?.deviceCategory || "";
						if (
							item?.deviceCategory === undefined ||
							category?.includes("smartphone") ||
							category?.includes("internet")
						) {
							deviceType = "Smartphones";
						} else if (category?.includes("laptop")) {
							deviceType = "ConnectedLaptops";
						} else if (
							category?.includes("tablet") ||
							category?.includes("netbook")
						) {
							deviceType = "Tablets";
						} else if (
							category === "phones" ||
							category?.includes("basic") ||
							category?.includes("featurephone")
						) {
							deviceType = "BasicPhones";
						} else if (category === "5g connected device") {
							deviceType = "FiveGConnectedDevice";
						} else if (category === "connected devices") {
							deviceType = "ConnectedDevices";
						} else {
							deviceType = item?.deviceCategory;
						}
						if (deviceTypeInfo[deviceType?.replace(/\s/g, "")]) {
							deviceTypeInfo[deviceType?.replace(/\s/g, "")].deviceCategory =
								item?.deviceCategory;
							Array.isArray(
								deviceTypeInfo[deviceType?.replace(/\s/g, "")]?.billingCode
							) &&
								deviceTypeInfo[deviceType?.replace(/\s/g, "")]?.billingCode.push(
									plan?.billingCode
								);
						}
					});
				}
			});
	});
	return deviceTypeInfo;
};

function* getDevicePlanPromos(flow) {
	yield put(PlansSlice.setAPIError(false));
	const globalData = yield select(selectors.globalData);
	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;

	try {
		let requestBody = {
			deviceDetailList: [],
			shoppingPath: "PROSPECT",
			approach: "",
			flow: flow === "one-talk" ? flow : "prospect",
		};
		const tempPackages = sessionCartData?.cart?.packages;
		const packages = Object.fromEntries(
			Object.entries(tempPackages).filter(([key, value]) => {
				return (
					value.packageType !== "FOURG" &&
					value.packageType !== "FIVEG" &&
					value.packageType !== "IOT"
				);
			})
		);
		for (let deviceKey in packages) {
			const device = packages[deviceKey];
			requestBody.deviceDetailList.push({
				deviceSku: device?.deviceSku,
				packageType: "SMARTPHONE",
				priceType: device?.priceType,
				oneTalkDeviceType:
					device?.packageType === "ONETALK" ? device?.deviceType : "",
			});
		}
		const resp = yield apiClient.post(API.GET_DEVICE_PLAN_PROMOS, requestBody);
		yield put(PlansSlice.setDevicePlanPromosInfo(resp?.data));
	} catch (err) {
		// yield put(PlansSlice.setAPIError(true));
	} finally {
		// yield put(PlansSlice.setPageLoading(false));
	}
}

function* getPlansAEM(params) {
	yield put(PlansSlice.setAPIError(false));
	yield put(PlansSlice.setPageLoading(true));
	try {
		const requestBody = {
			shoppingPath: "PROSPECT",
			approach: "",
			flow: "prospect",
		};
		const resp = yield apiClient.post(API.GET_PLANS_AEM, requestBody);
		yield put(PlansSlice.setPlanAEM(resp?.data?.data));
	} catch (err) {
		yield put(PlansSlice.setAPIError(true));
	} finally {
		yield put(PlansSlice.setPageLoading(false));
	}
}

function* setPlanForDevices(params) {
	const { selectedPlan, planDetails } = params.payload;
	const availableDevices = yield select(selectors.availableDevices);
	const selectedDevices = yield select(selectors.selectedDevices);
	const isSelectAllChecked = yield select(selectors.isSelectAllChecked);
	const devicePlanList = yield select(selectors.devicePlanList);
	const newDevicePlanList = [...(devicePlanList || [])];

	try {
		selectedDevices.map((device) => {
			newDevicePlanList.push({ ...device, plan: planDetails });
		});
		yield put(
			PlansSlice.setDisablePlanSelection(
				newDevicePlanList.length === availableDevices.length
			)
		);
		const uniqDevicePlanList = uniqBy(newDevicePlanList, "key");
		yield put(PlansSlice.setdevicePlanList(uniqDevicePlanList));
		yield put(PlansSlice.setSelectedDevices([]));
		yield put(PlansSlice.setIsSelectAllChecked(false));
	} catch (err) {
	} finally {
	}
}

function* setFeatureForDevices(params) {
	const { feature, featureCode } = params.payload;
	const availableDevices = yield select(selectors.featuresAvailableDevices);
	const selectedDevices = yield select(selectors.featuresSelectedDevices);
	const isSelectAllChecked = yield select(selectors.isSelectAllChecked);
	const devicePlanList = yield select(selectors.featuresDevicePlanList);
	const newDevicePlanList =
		feature["billingCode"] === "1332" ? [] : [...(devicePlanList || [])];

	try {
		selectedDevices.map((device) => {
			const isDeviceAlreadyExist = newDevicePlanList.filter((data) =>
				data.key === device.key ? true : false
			).length
				? false
				: true;
			if (isDeviceAlreadyExist) {
				let tempDevice = { ...device, features: {} };
				tempDevice.features[feature.billingCode] = { ...feature };
				newDevicePlanList.push(tempDevice);
			}
		});

		yield put(
			PlansSlice.setDisablePlanSelection(
				newDevicePlanList.length === availableDevices.length
			)
		);
		yield put(PlansSlice.setFeaturesDevicePlanList(newDevicePlanList));
		yield put(PlansSlice.setFeaturesSelectedDevices(selectedDevices));
		yield put(PlansSlice.setIsSelectAllChecked(false));
		yield put(PlansSlice.setSelectedFeatures(feature));
	} catch (err) {
	} finally {
	}
}

export default function* actionWatcher() {
	yield takeLatest(PlansSlice.getInitPageLoadInfo.type, getInitPageLoadInfo);
	yield takeLatest(PlansSlice.getMetaInformation.type, getMetaInformation);
	yield takeLatest(PlansSlice.getDevicePlanPromos.type, getDevicePlanPromos);
	yield takeLatest(PlansSlice.getFeatures.type, getFeatures);
	yield takeLatest(PlansSlice.getPlansAEM.type, getPlansAEM);
	yield takeLatest(
		PlansSlice.getDeviceCompatiblePlans.type,
		getDeviceCompatiblePlans
	);
	yield takeLatest(PlansSlice.setPlanForDevices.type, setPlanForDevices);
	yield takeLatest(PlansSlice.setFeatureForDevices.type, setFeatureForDevices);
}
