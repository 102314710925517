import styled from "styled-components";
import { AccordionHeader } from "@vds3/accordions";

export const ProductTitle = styled.div`
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 28px;
	margin: 0;
	padding-bottom: 8px;
	@media screen and (min-width: 230px) and (max-width: 767px) {
		font-size: 20px;
		padding-bottom: 4px;
	}
`;

export const ColorSwatchSection = styled.div`
	margin-top: -1rem;
	margin-bottom: -1rem;
`;

export const PDPHeaderInfo = styled.div`
	margin-top: 8px;
`;

export const AccordionSection = styled.div`
	margin-top:2rem;
	max-width: 596px;
	.accordionButton {
		padding-bottom: 32px;
	}
	position: relative;
	top: ${({isBuss})=>isBuss ? '0rem' : '7rem'};
	div[type="secondary"] {
		background-color: #fff !important;
	}
	@media screen and (min-width: 230px) and (max-width: 767px) {
		position: relative;
		top: 0rem;
	}
	@media screen and (min-width: 230px) and (max-width: 767px) {
		.accordionButton {
			padding-bottom: 24px;
		}
	}
`;

export const ProductBorder = styled.div`
	border-bottom: 0.0625rem solid #d8dada !important;
`;

export const QuantitySection = styled.div`
	margin-top: ${({ isOneTalk }) => (isOneTalk ? "0rem" : "3rem")};
`;

export const ProductSubTitle = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.5px;
	color: #6F7171;
	margin-top: 8px;
	@media only screen and (min-width: 320px) and (max-width: 767px) {
		margin-top: 4px;
	}
`;

export const CustomAccordionHeader = styled(AccordionHeader)`
	padding-bottom: 2rem;
	.productLine {
		border: none !important;
		/* border-bottom:1px solid #D8DADA; */
	}
`;

export const PhoneDetails = styled.div`
	.font-16 {
		font-size: 16px !important;
		font-weight: 700;
		font-family: Verizon-NHG-eDS, Helvetica, Ariel, Sans-serif;
		margin: 0 !important;
	}

	.bold {
		font-weight: 700 !important;
	}

	.font-14 {
		font-size: 14px !important;
		font-family: Verizon-NHG-eDS, Helvetica, Ariel, Sans-serif;
		margin: 0 !important;
	}

	.colorBlue {
		color: #0088ce;
	}
	.no-shipping-options {
		margin-top: 8rem;
	}

	.paddingTabs {
		@media only screen and (min-width: 320px) and (max-width: 767px) {
			padding-left: 0rem !important;
			padding-right: 0rem !important;
		}
	}
	.details-tab-list {
		padding-top: 48px;
	}
	.session-cart-id > div {
		margin-top: 0rem !imporant;
	}
	.details-tab-list {
		margin-bottom: 0px !important;
	}

	.shippingImage {
		height: 15px;
		width: 25px;
	}

	.paddingright5 {
		padding-right: 5px;
	}

	.grid-container {
		padding: 0 0.5rem 1rem;
		@media only screen and (min-width: 320px) and (max-width: 767px) {
			padding: 0rem !important;
		}
	}

	.image-container {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 2rem;

		.carousel.carousel-slider .control-arrow {
			&:hover {
				background: unset !important;
			}
		}

		.slide {
			img {
				width: 70% !important;
				height: auto;
			}
		}

		.carousel {
			.control-next.control-arrow {
				&:before {
					border-left: 8px solid #201818 !important;
				}
			}
			.control-prev.control-arrow {
				&:before {
					border-right: 8px solid #201818 !important;
				}
			}
		}

		@media (max-width: 767px) {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0.5rem;
			.corousel-div .right-pagination-control,
			.corousel-div .left-pagination-control {
				display: none !important;
			}
		}
	}

	.image-bars-container {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 2rem;
	}

	@media (max-width: 767px) {
		padding: 0rem;

		#pdpTabs {
			position: sticky;
			top: 0;
			background: white;
			z-index: 999;
		}

		.paymentsTab {
			flex-direction: column !important;
		}
	}
`;

export const ProductTitlePrice = styled.div`
	padding-bottom: 32px;
	padding-top: 32px;
	@media screen and (min-width: 230px) and (max-width: 767px) {
		h5 {
			font-size: 20px;
		}
		padding-bottom: 32px;
		padding-top: 32px;
	}
`;

export const AccessoryPriceWrapper = styled.div`
	border-bottom: 0.0625rem solid #d8dada !important;
`;

export const AccessoriesColorWrap = styled.div`
	margin: 2rem 0px;
`;

export const AccordionPlaceholder = styled.div`
	padding-bottom: 2rem;
`;
