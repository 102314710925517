import "@babel/polyfill";
import "./services/i18next";

import React from "react";
import ReactDOM from "react-dom";
import reactToWebComponent from "react-to-webcomponent";
import WidgetsDeciderWrapper from "./WidgetsDeciderWrapper";
import * as serviceWorker from "./serviceWorker";

if (document.getElementById("prospect-marketplace")) {
  ReactDOM.render(
    <WidgetsDeciderWrapper />,
    document.getElementById("prospect-marketplace")
  );
}

(function () {
  if (typeof window.CustomEvent === "function") return false;

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, details: undefined };
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.details
    );
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();

const ProspectMarketplace = reactToWebComponent(
  WidgetsDeciderWrapper,
  React,
  ReactDOM
);
customElements.define("prospect-marketplace", ProspectMarketplace);

serviceWorker.unregister();
