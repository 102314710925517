import { put, select, fork, join, takeEvery, takeLeading, call } from "redux-saga/effects";
import apiClient from "utils/apiClient";
import * as API from "containers/APIurls";
import * as GlobalSlice from "./GlobalSlice";
import Utils from 'app/ducks/utils'

function* showLoading(data) {
  yield put(GlobalSlice.setLoading(true));
}

function* hideLoading(data) {
  yield put(GlobalSlice.setLoading(false));
}

function* showStickyHeaderValues(data) {
  yield put(GlobalSlice.setShowStickyHeaderValues(true));
}

function* hideStickyHeaderValues(data) {
  yield put(GlobalSlice.setShowStickyHeaderValues(false));
}

function* changeFlow(data) {
  const flow = GlobalSlice.getFlow(data.payload);
  yield put(GlobalSlice.setFlow(flow));
}

function* showNotification(data) {
  const { payload } = data;
  yield put(GlobalSlice.setNotification({ title: payload.title || "Please Provide Title", subtitle: payload.subtitle, closable: payload.closable || true, type: payload.type || "warning", open: true }));
}

function* hideNotification() {
  yield put(GlobalSlice.setNotification({ open: false }));
}

function* getMetaInformation() {
  yield put(GlobalSlice.setApiError(false));
  yield showLoading();
  try {
    const flow = yield select(state => state.ProspectWidgets_GlobalData.flow);
    const requestBody = {
      digitalQuote: false,
      shoppingPath: "PROSPECT",
      approach: "",
      flow,
    };
    const resp = yield apiClient.post(API.DEVICE_META_INFORMATION_URL, requestBody);
    yield put(GlobalSlice.setMetaInfo(resp?.data?.data));
  } catch (err) {
    yield put(GlobalSlice.setApiError(true));
  } finally {
    yield hideLoading();
  }
}

function* getSessionCartData(payload) {
  try {
    const flow = yield select(state => state.ProspectWidgets_LQPage.flow);
    const checkoutData = yield select(
			(state) => state.ProspectWidgets_CheckoutData
		);
		let currentSectionName;
		if (checkoutData) {
			if (
				checkoutData?.activeAccordian ===
				checkoutData?.activeAccordionDisplayOrder?.creditResult
			) {
				currentSectionName = "credit-result";
			} else if (
				checkoutData?.activeAccordian ===
				checkoutData?.activeAccordionDisplayOrder?.reviewOrder
			) {
				currentSectionName = "review";
			} else if (
				checkoutData?.activeAccordian ===
				checkoutData?.activeAccordionDisplayOrder?.maa
			) {
				currentSectionName = "agreement";
			} else if (
				checkoutData?.activeAccordian ===
				checkoutData?.activeAccordionDisplayOrder?.shipping
			) {
				currentSectionName = "shipping";
			}
		}
    const requestBody = {
      cartMeta: { 
        sourcePath: "CART"
      },
      shoppingPath: flow,
      approach: payload?.payload?.approach ?? "",
      flow,
      currentSectionName:currentSectionName
    }
    const response = yield apiClient.post(API.CARTCOMP_SESSION_CART_URL, requestBody);
    if (response && response.data && response.data.prospectFlowData) {
      const newStore = Utils.setStoreFromRetrieve(response.data);
      yield put(GlobalSlice.setSessionCartData(newStore));
      window?.localStorage?.setItem('_cartData', JSON.stringify(newStore));
    }
  } catch (err) {
    // error
    yield put(GlobalSlice.setApiError(true));
  }
}

function* getSessionCart(payload) {
  yield showLoading(); 
  try {
    yield call(getSessionCartData, payload);
  } catch (err) {
    yield put(GlobalSlice.setApiError(true));
  } finally {
    yield hideLoading();
  }
}

function* getGlobalPageLoadData() {
  yield put(GlobalSlice.setApiError(false));
  yield showLoading();
  try {
    const forks = [];
    forks.push(yield fork(getMetaInformation));
    forks.push(yield fork(getSessionCartData));
    yield join([...forks]);
  } catch (err) {
    console.error(err)
    yield put(GlobalSlice.setApiError(true));
  } finally {
    yield hideLoading();
  }
}

function* getCombinePricing() {
  yield put(GlobalSlice.setApiError(false));
  yield showLoading();
  try {
    const requestBody = {
      cartMeta: { initFlow: true },
      shoppingPath: "PROSPECT",
      approach: "",
      flow: "one-talk",
    };
    const resp = yield apiClient.post(API.CARTCOMP_ONETALK_COMBINE_PRICING_URL, requestBody);
    yield put(GlobalSlice.setCombinePricingInfo(resp?.data?.response));
  } catch (err) {
    console.error(err)
    yield put(GlobalSlice.setApiError(true));
  } finally {
    yield hideLoading();
  }
}

function* postSaveCart(params) {
  yield showLoading();
  const requestBody = params?.payload;
  try {
    const response = yield apiClient.post(API.CARTCOMP_CART_SAVE_URL, requestBody);
    if (response) {
      yield put(GlobalSlice.setSaveCartSuccess(response));
      yield put(GlobalSlice.setCartId(response?.data?.cartId));
    }
  } catch (err) {
    // error handling
  }finally{
    yield hideLoading();
  }
}

export default function* actionWatcher() {
  yield takeEvery(GlobalSlice.showLoading.type, showLoading);
  yield takeEvery(GlobalSlice.hideLoading.type, hideLoading);
  yield takeEvery(GlobalSlice.showStickyHeaderValues.type, showStickyHeaderValues);
  yield takeEvery(GlobalSlice.hideStickyHeaderValues.type, hideStickyHeaderValues);
  yield takeEvery(GlobalSlice.changeFlow.type, changeFlow);
  yield takeEvery(GlobalSlice.showNotification.type, showNotification);
  yield takeEvery(GlobalSlice.hideNotification.type, hideNotification);
  yield takeLeading(GlobalSlice.getMetaInformation.type, getMetaInformation);
  yield takeLeading(GlobalSlice.getSessionCart.type, getSessionCart);
  yield takeLeading(GlobalSlice.getGlobalPageLoadData.type, getGlobalPageLoadData);
  yield takeLeading(GlobalSlice.getCombinePricing.type, getCombinePricing);
  yield takeLeading(GlobalSlice.postSaveCart.type, postSaveCart);
}
