import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import {
	setConfirmZipcode,
	setValidZipcode,
	setZipcode,
	validateZipcode,
	setZipEmail,
	sendEmailDetails,
} from "../../../pages/Mobility/ducks/MobilitySlice";
import { Body } from "@vds3/typography";
import { Input } from "@vds3/inputs";
import { Modal, ModalTitle, ModalBody, ModalFooter } from "@vds3/modals";
import { Button } from "@vds3/buttons";
import {
	MarginSpacerM,
	MarginSpacerS,
} from "../../../common/components/styleTag";
import { TitleLockup } from "@vds3/type-lockups";

const ZipcodeModalBody = styled(ModalBody)`
	padding: 0 0px 0;
	outline: none !important;
	height: auto;
`;
const ButtonWrapper = styled(Button)`
	@media screen and (max-width: 767px) {
		position: absolute;
		bottom: 2px;
		width: 97%;
		right: 3%;
	}
`;

const ZipCodeModal = ({
	pageData = {},
	editZip = false,
	onClose = () => {},
}) => {
	const { t } = useTranslation(["app"]);
	const dispatch = useDispatch();
	const availZip =
		pageData?.confirmZipcode != "" && pageData?.zipEmail != "" ? true : false;
	const [isZipcode, setIsZipcode] = useState(availZip);
	const [errors, setErrors] = useState({});
	const [isConfirm, setIsConfirm] = useState(false);
	const [zipCode, handleZipcode] = useState(pageData?.zipCode);
	const zipRef = useRef(pageData?.zipCode);
	const confirmRef = useRef(isConfirm);
	const [email, handleEmail] = useState("");
	const isEmailEnable = t("app.common.isEmailEnable", { ns: "app" });
	const [emailEmpty, setEmailEmpty] = useState(true);
	const [emailValid, setEmailValid] = useState(false);
	const enableEmailThrottle = t("app.zipCodePopup.emailThrottle", {
		ns: "app",
	});

	const isMobile = useMediaQuery({
		query: "(max-width: 725px)",
	});

	const handleKeyDown = (event) => {
		if (event?.code === "Enter") {
			confirmClicked(pageData?.zipCode);
		}
	};

	useEffect(() => {
		window.addEventListener("keydown", handleKeyDown);

		// populate default zipcode if none is present from BE
		if (!pageData?.validZipcode) {
			const defaultZip = t("app.common.defaultZipcode", { ns: "app" });
			if (defaultZip) {
				dispatch(setZipcode(defaultZip));
				dispatch(setValidZipcode(true));
				setIsConfirm(true);
			}
		}

		// cleanup this component on unmount
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	useEffect(() => {
		if (pageData?.validZipcode && pageData?.zipCode?.length === 5) {
			setIsConfirm(true);
		} else if (pageData?.validZipcode === false) {
			setIsConfirm(false);
			setErrors({
				...errors,
				["zipCode"]: `${t("app.zipCodePopup.invalidZip", { ns: "app" })}`,
			});
		}
	}, [pageData?.zipCode, pageData?.validZipcode]);

	useEffect(() => {
		confirmRef.current = isConfirm && !(errors.zipCode?.length > 0);
	}, [isConfirm, errors]);

	useEffect(() => {
		zipRef.current = pageData?.zipCode;
		dispatch(setValidZipcode(null));
		if (zipRef.current?.length === 5) {
			handleZipcode(pageData?.zipCode);
			dispatch(
				validateZipcode({
					zipcode: zipRef.current,
					isEmailEnable: isEmailEnable,
				})
			);
		} else {
			isValidZipCode(zipRef.current);
		}
	}, [pageData?.zipCode]);

	useEffect(() => {
		isValidZipCode(zipCode);
	}, [zipCode]);

	const confirmClicked = (zipVal) => {
		if (!!confirmRef.current) {
			setIsZipcode(true);
			dispatch(setZipcode(zipCode));
			dispatch(setConfirmZipcode(zipCode));
			if (enableEmailThrottle) {
				dispatch(sendEmailDetails(email));
			}
			onClose();
		}
	};

	const isValidZipCode = (zip) => {
		const invalidZipCodes = t("zipCode.invalidZipcodes", {
			returnObjects: true,
		});
		let errorTexts = "";
		// dispatch(setZipcode(zip));
		errorTexts =
			!zip || !zip?.trim()
				? `${t("app.zipCodePopup.zipRequired", { ns: "app" })}`
				: "";
		if (zip?.length < 5 && !(!zip || !zip?.trim())) {
			setIsConfirm(false);
			errorTexts = `${t("app.zipCodePopup.zipLength", { ns: "app" })}`;
		}
		if (zip?.length === 5) {
			if (!invalidZipCodes.includes(zip)) {
				setIsConfirm(true);
			} else {
				setIsConfirm(false);
				if (pageData.validZipcode === false) {
					errorTexts = `${t("app.zipCodePopup.invalidZip", { ns: "app" })}`;
				} else {
					dispatch(validateZipcode({ zipcode: zip, isEmailEnable: isEmailEnable }));
				}
			}
		}
		setErrors({ ...errors, ["zipCode"]: errorTexts });
	};

	const onOpenedChange = (e) => {
		if (e === false) {
			// on modal close
			window.removeEventListener("keydown", handleKeyDown);
			window.history.back();
		}
	};

	const isValidEmail = (key, val) => {
		if (key === "emailID") {
			if (!val?.trim().length) {
				setEmailValid(false);
				setEmailEmpty(true);
			} else {
				const pattern =
					/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				pattern.test(val) ? setEmailValid(true) : setEmailValid(false);
			}
			handleEmail(val);
			if (val.length == 0) {
				setEmailEmpty(true);
			} else {
				setEmailEmpty(false);
			}

			dispatch(setZipEmail(val));
		}
	};

	return (
		<Modal
			ariaLabel=""
			disableOutsideClick={true}
			hideCloseButton={true}
			closeButton={<></>}
			opened={!isZipcode || (editZip && !pageData?.zipEmail)}
			onOpenedChange={onOpenedChange}
		>
			<ModalTitle>
				<TitleLockup
					data={{
						title: {
							bold: true,
							size: "titleMedium",
							children: t("app.zipCodePopup.heading", { ns: "app" }),
						},
					}}
				/>
			</ModalTitle>
			<ZipcodeModalBody>
				<Body size="medium">
					{t("app.zipCodePopup.description", { ns: "app" })}
				</Body>
				<MarginSpacerS />
				<div>
					<Input
						label={t("app.zipCodePopup.inputLabel", { ns: "app" })}
						maxLength={5}
						name="zipcode"
						width={"100%"}
						value={zipCode}
						// onChange={(e) => isValidZipCode(e.target.value)}
						onChange={(e) => {
							const zipVal = e.target.value.trim();
							if (!isNaN(zipVal)) handleZipcode(zipVal);
						}}
						onKeyDown={(e) => {
							if (e?.keyCode === 13) {
								const zipVal = e.target.value.trim();
								if (!isNaN(zipVal)) handleZipcode(zipVal);
							}
						}}
						error={errors?.zipCode?.length > 0 ?? false}
						errorText={errors?.zipCode ?? ""}
					/>
				</div>
				{isEmailEnable && (
					<div>
						<MarginSpacerM />
						<Input
							name="email"
							type="text"
							label="Enter your email id(optional)"
							value={email}
							readOnly={false}
							error={email.length && !emailValid}
							success={emailValid}
							onChange={(e) => isValidEmail("emailID", e.target.value)}
							errorText={
								email.length &&
								!emailValid &&
								t("app.common.emailCapture.errorText", { ns: "app" })
							}
						/>
					</div>
				)}
			</ZipcodeModalBody>
			<ModalFooter
				children={
					<ButtonWrapper
						width={isMobile ? "100%" : "226px"}
						disabled={
							!isConfirm ||
							errors.zipCode?.length > 0 ||
							(emailEmpty ? !emailEmpty : !emailValid)
						}
						onClick={() => confirmClicked(pageData?.zipCode)}
					>
						{isEmailEnable
							? t("app.zipCodePopup.submitBtn", { ns: "app" })
							: t("app.zipCodePopup.confirmBtn", { ns: "app" })}
					</ButtonWrapper>
				}
			/>
		</Modal>
	);
};

export default ZipCodeModal;
