export const globalData = (state) => state.ProspectWidgets_GlobalData;
export const getFlowConfig = (state) => state.ShoppingCartData.flowConfig;
export const pageData = (state) => state.ShoppingCartData;
export const fetchCart = (state) => state.ShoppingCartData.fetchCart;
export const flowState = (state) => state.CheckoutFlow;
export const flowType = (state) => state.ShoppingCartData.flowType;
export const availableDevices = (state) =>
  state.ShoppingCartData.npaNxx.availableDevices;
export const npaNxxData = (state) => state.ShoppingCartData.npaNxx;
export const lqData = (state) => state.ProspectWidgets_LQPage;
