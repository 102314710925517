import { createSlice, createAction, createSelector } from "@reduxjs/toolkit";
import * as REF from "./ref";

const initialState = {
	isVerifyBusiness: false,
	showError: false,
	shippingCode: "",
	globalErrorMessage: "",
	notification: {},
	suspendFlow: false,
	suspendScenario: "",
	flowType: REF.CONFIG_SHIPPING_ONLY,
	flowFlags: {},
	creditPath: "BLENDED",
	creditEmailFlow: false,
	routingWithoutGuard: false,
	creditCheckStatus: "FAILURE",
	creditComparison: false,
	addressSuggestionList: [],
	fetchCart: null,
	fetchCartStatus: null,
	redirectTo4gPostAddressValidation: false,
	redirectTo5gPostAddressValidation: false,
	redirectToClearCart: false,
	invalidZipCode: false,
	hasCbandPresent: true,
	unifiedCartParams: {},
	isShoppingCartApiCallInProgress: {
		getFetchCart5G: false,
		getSessionCart: false,
		getRetrieveCart5G: false,
		getMetaInformation: false,
		postBusinessInformation1: false,
		getAEMInformation: false,
		getShippingMethods: false,
		validateCreditDetails: false,
		shipping: false,
		resumeEcpdDocs: false,
		checkAddressValidation: false,
		getCreditReadAndSpoDetails: false,
	},
	isCheckUnifiedCart: false,
	isRetrieveCreditCartSuccess: false,
	isSaveCreditHoldCartSuccess: false,
	isCheckoutPageApiCallInProgress: {
		storeTermsLoading: false,
		generateInstallment: false,
	},
	businessInfo: {},
	creditCheck5g: {},
	otp: {
		otpLoading: false,
		otpPhoneNumber: {},
		otpSendCode: {},
		otpValidatePasscode: {},
	},
	ddia: {
		linkUrlResponse: {},
		checkVerificationResult: {},
	},
	npaNxx: {
		loading: false,
		availableDevices: [],
		existingNumberState: {},
		npaNxxResponse: {},
		portInEligibilityResponse: {},
		releaseMdnResponse: {},
		retrieveMdnResponse: {},
		retrieveNpaNxxResponse: {},
		updateNicknameResponse: {},
		skipNpaNxx: false,
	},
	Installation: {
		skipInstallation: false,
		installationInfoResp: {},
	},
	Docusign: {
		loading: false,
		url: "",
	},
	safetechSessionId: "",
	blackBox: {
		bb: "",
		complete: false,
	},
	totalActiveSections: 1,
	currentSection: 1,
	activeSection: "",
	aboutBusinessInfor: {},
  authSignerInfo: {},
  authSignerInfoTemp: {},
  SSNInfo: "",
	postBusinessInfoResponse: {},
	validateBusinessInfoResponse: {},
	cartId: "",
	cartApiError: false,
	cleartCartDetails: {},
	promoCodeDetails: {},
	removeStatus: false,
	promoCodeLoading: false,
	orderQuoteFetchSavedCartResponse: {},
	saveCartEmailAddress: "",
	isAddressUpdated: false,
	showProgressOverlay: false,
	retrieveLoader: false,
	promoCodeParam: "",
	showMitekModal: true,
	showOneTimePassModal: false,
	creditCheckState: {},
	businessState: {},
	shippingState: {},
	showValidationOverlay: false,
	activeAccordian: 0,
	sameAsCompanyAddress: {},
	isSelected: true,
	manualCompanyAddress: {},
	exisitingCustomer: false,
	billingInformation:{}
};

const CheckoutSlice = createSlice({
	name: "checkout",
	initialState,
	reducers: {
		setBillingInformation: (state, action) => {
			state["billingInformation"] = action.payload;
		},
		setServiceInformation: (state, action) => {
			state["serviceInformation"] = action.payload;
		},
		setIsVerifyBusiness: (state, action) => {
			state["isVerifyBusiness"] = action.payload;
		},
		resetCheckoutState: () => {
			return initialState;
		},
		getPageInfo: () => {},
		setBusinessInfo: (state, action) => {
			state["businessInfo"] = action.payload;
		},
		setCreditPath: (state, action) => {
			state["creditPath"] = action.payload;
		},
		setCreditEmailFlow: (state, action) => {
			state["creditEmailFlow"] = action.payload;
		},
		setEnableRoutingWithoutGaurd: (state, action) => {
			state["routingWithoutGuard"] = action.payload;
		},
		setCreditCheckInfo: (state, action) => {
			state["creditCheckInfo"] = action.payload;
		},
		setCredit5gInfo: (state, action) => {
			state["creditCheck5g"] = action.payload;
		},
		setInstallmentsInfo: (state, action) => {
			state["installmentsInfo"] = action.payload;
		},
		setInstallationInfo: (state, action) => {
			state["installationInfo"] = action.payload;
		},
		setDepositInfo: (state, action) => {
			state["depositInfo"] = action.payload;
		},
		setShippingMethodInfo: (state, action) => {
			state["shippingMethodsInfo"] = action.payload;
		},
		setShippingResponse: (state, action) => {
			state["shippingResponse"] = action.payload;
		},
		setAddressValidationInfo: (state, action) => {
			state["addressValidationInfo"] = action.payload;
		},
		setStoreInfo: (state, action) => {
			state["storeInfo"] = action.payload;
		},
		// setMAADocUrlMethodInfo: (state, action) => {
		// state['maaDocUrlMethodInfo'] = action.payload
		//},
		setTOSInfo: (state, action) => {
			state["tosInfo"] = action.payload;
		},
		setGenericSection: (state, action) => {
			if (action?.payload?.data) {
				state[action.payload.componentName] = action.payload.data;
			}
		},
		setGlobalErrorMessage: (state, action) => {
			state["showError"] = action.payload.showError;
			state["globalErrorMessage"] = action.payload.message;
		},
		setFlowConfig: (state, action) => {
			state["flowConfig"] = action.payload;
		},
		setFlowType: (state, action) => {
			state["flowType"] = action.payload;
		},
		setRetrieveCart5G: (state, action) => {
			state[REF.RETRIVECART5GDATA] = action.payload;
		},
		setBISubmissionResponse: (state, action) => {
			state["biResponse"] = action.payload;
		},
		setAddressSuggestionList: (state, action) => {
			state["addressSuggestionList"] = action.payload;
		},
		setFetchCartResponse: (state, action) => {
			state["fetchCart"] = action.payload;
		},
		setFetchCartStatus: (state, action) => {
			state["fetchCartStatus"] = action.payload;
		},
		setUpdatedPackages: (state, action) => {
			state["fetchCart"]["cart"]["packages"] = action.payload;
		},
		setMetaInformation: (state, action) => {
			state["metaInformation"] = action.payload;
		},
		setvalidateCreditDetails: (state, action) => {
			state["creditDetails"] = action.payload;
		},
		setIsShoppingCartApiCallInProgress: (state, action) => {
			state["isShoppingCartApiCallInProgress"][action.payload.apiName] =
				action.payload.status;
		},
		setRetrieveCreditCartSuccess: (state, action) => {
			state["isRetrieveCreditCartSuccess"] = action.payload.status;
		},
		setSaveCreditHoldCartSuccess: (state, action) => {
			state["isSaveCreditHoldCartSuccess"] = action.payload.status;
		},
		setUnifiedCartParams: (state, action) => {
			state["unifiedCartParams"] = action.payload;
		},
		setPromoCodeParam: (state, action) => {
			state["promoCodeParam"] = action.payload;
		},
		setOtpLoading: (state, action) => {
			state["otp"]["otpLoading"] = action.payload;
		},
		setEligiblePhoneNumberList: (state, action) => {
			state["eligiblePhoneNumberList"] = action.payload;
		},
		setIsUnifiedCart: (state, action) => {
			state["isCheckUnifiedCart"] = action.payload;
		},
		setOtpPhoneNumber: (state, action) => {
			state["otp"]["otpPhoneNumber"] = action.payload;
		},
		setVerificationApiRetryCount: (state, action) => {
			state["ddia"]["verificationApiRetryCount"] = action.payload;
		},
		setRunningMitekCount: (state, action) => {
			state["mitekRunningCount"] = action.payload;
		  },
		setOtpSendCode: (state, action) => {
			state["otp"]["otpSendCode"] = action.payload;
		},
		setOtpValidatePasscode: (state, action) => {
			state["otp"]["otpValidatePasscode"] = action.payload;
		},
		resetOtp: (state, action) => {
			state["otp"] = {
				otpLoading: false,
				otpPhoneNumber: {},
				otpSendCode: {},
				otpValidatePasscode: {},
			};
		},
		setDDIALinkResponse: (state, action) => {
			state["ddia"]["linkUrlResponse"] = action.payload;
		},
		setDDIACheckVerificationResult: (state, action) => {
			state["ddia"]["checkVerificationResult"] = action.payload;
		},

		setMitekCheckVerificationResult: (state, action) => {
			state["mitekCheckVerificationResult"] = action.payload;
		},
		setMaaTncCheckVerificationResult: (state, action) => {
			state["maaTncCheckVerificationResult"] = action.payload;
		},
		setMaaTncCheckVerificationResultLoading: (state, action) => {
			state["maaTncCheckVerificationResultLoading"] = action.payload;
		},
		setUpdateDeclinedStatusResult: (state, action) => {
			state["updateDeclinedStatusResult"] = action.payload;
		},

		setValidateSSNResult: (state, action) => {
			state["ValidateSSNResult"] = action.payload;
		},

		setMitekCheckVerificationProcess: (state, action) => {
			state["mitekCheckVerificationProcess"] = action.payload;
		},
		setSendMitekVerification: (state, action) => {
			state["sendMitekVerification"] = action.payload;
		},
		setGenerateMitekLink: (state, action) => {
			state["mitekVerificationLinkResponse"] = action.payload;
		},
		setNpaNxxLoading: (state, action) => {
			state["npaNxx"]["loading"] = action.payload;
		},
		setAvailableDevices: (state, action) => {
			state["npaNxx"]["availableDevices"] = action.payload;
		},
		setExistingNumberState: (state, action) => {
			state["npaNxx"]["existingNumberState"] = action.payload;
		},
		setNpaNxxNpaNxxResponse: (state, action) => {
			state["npaNxx"]["npaNxxResponse"] = action.payload;
		},
		setNpaNxxPortInEligibilityResponse: (state, action) => {
			state["npaNxx"]["portInEligibilityResponse"] = action.payload;
		},
		setNpaNxxReleaseMdnResponse: (state, action) => {
			state["npaNxx"]["releaseMdnResponse"] = action.payload;
		},
		setNpaNxxRetrieveMdnResponse: (state, action) => {
			state["npaNxx"]["retrieveMdnResponse"] = action.payload;
		},
		setNpaNxxRetrieveNpaNxxResponse: (state, action) => {
			state["npaNxx"]["retrieveNpaNxxResponse"] = action.payload;
		},
		setNpaNxxUpdateNicknameResponse: (state, action) => {
			state["npaNxx"]["updateNicknameResponse"] = action.payload;
		},
		setSkipNpaNxx: (state, action) => {
			state["npaNxx"]["skipNpaNxx"] = action.payload;
		},
		setSkipInstallation: (state, action) => {
			state["npaNxx"]["skipInstallation"] = action.payload;
		},
		setReviewTermsAgreementResponse: (state, action) => {
			state["reviewTermsAgreement"] = action.payload;
		},
		setSuspendFlow: (state, action) => {
			state["suspendFlow"] = action.payload;
		},
		setIsCreditResult: (state, action) => {
			state["isCreditResult"] = action.payload;
		},
		setSuspendScenario: (state, action) => {
			state["suspendScenario"] = action.payload;
		},
		setDocusignLoading: (state, action) => {
			state["Docusign"]["loading"] = action.payload;
		},
		setDocusignURL: (state, action) => {
			state["Docusign"]["url"] = action.payload;
		},
		setTNCDetailMethodInfo: (state, action) => {
			state["TNCDetailMethodInfo"] = action.payload;
		},
		setBussTNCDetailMethodInfo: (state, action) => {
			state["BussTNCDetailMethodInfo"] = action.payload;
		},
		setOneTalkEnrollment: (state, action) => {
			state["oneTalkEnrollment"] = action.payload;
		},
		setresumeEcpdDocsInfo: (state, action) => {
			state["resumeEcpdDocsInfo"] = action.payload;
		},
		setSafetechSessionId: (state, action) => {
			state["safetechSessionId"] = action.payload;
		},
		setBlackBox: (state, action) => {
			state["blackBox"] = action.payload;
		},
		setSessionCartData: (state, action) => {
			state["sessionCartData"] = action.payload;
		},
		setIsCheckoutPageApiCallInProgress: (state, action) => {
			state["isCheckoutPageApiCallInProgress"][action.payload.apiName] =
				action.payload.status;
		},
		setProgressState: (state, action) => {
			state["totalActiveSections"] = action.payload.totalSections;
			state["currentSection"] = action.payload.currentSection;
		},
		setActiveSection: (state, action) => {
			state["activeSection"] = action.payload.activeSection;
		},
		setE911AddressResponse: (state, action) => {
			state["e911AddressResponse"] = action.payload;
		},
		setFlowFlags: (state, action) => {
			state["flowFlags"] = action.payload;
		},
		setPostBusinessInfoResponse: (state, action) => {
			state["postBusinessInfoResponse"] = action.payload;
		},
		setSaveBusinessInfoResponse: (state, action) => {
			state["saveBusinessInfoResponse"] = action.payload;
		},
		setValidateBusinessInfoResponse: (state, action) => {
			state["validateBusinessInfoResponse"] = action.payload;
		},
		setE911UnitAddress: (state, action) => {
			state["e911UnitAddress"] = action.payload;
		},
		setNotification: (state, action) => {
			state["notification"] = action.payload;
		},
		setCreditComparison: (state, action) => {
			state["creditComparison"] = action.payload;
		},
		setDeleteDirectoryResponse: (state, action) => {
			state["deleteDirectoryResponse"] = action.payload;
		},
		setViewDocumentResponse: (state, action) => {
			state["viewDocumentResponse"] = action.payload;
		},
		setRedirectTo4gPostAddressValidation: (state, action) => {
			state["redirectTo4gPostAddressValidation"] = action.payload;
		},
		setRedirectTo5gPostAddressValidation: (state, action) => {
			state["redirectTo5gPostAddressValidation"] = action.payload;
		},
		setRedirectToClearCart: (state, action) => {
			state["redirectToClearCart"] = action.payload;
		},
		setinvalidZipCode: (state, action) => {
			state["invalidZipCode"] = action.payload;
		},
		setHasCbandPresent: (state, action) => {
			state["hasCbandPresent"] = action.payload;
		},
		setCartId: (state, action) => {
			state["cartId"] = action.payload;
		},
		setCartApiError: (state, action) => {
			state["cartApiError"] = action.payload;
		},
		setCleartCartDetails: (state, action) => {
			state["cleartCartDetails"] = action.payload;
		},
		setPromoCodeDetails: (state, action) => {
			state["promoCodeDetails"] = action.payload;
		},
		setPromoCodeLoading: (state, action) => {
			state["promoCodeLoading"] = action.payload;
		},
		setRemoveStatus: (state, action) => {
			state["removeStatus"] = action.payload;
		},
		setSaveCartSuccess: (state, action) => {
			state["saveCartSuccess"] = action.payload;
		},
		setMultiShippingDetails: (state, action) => {
			state["multiShippingDetails"] = action.payload;
		},
		setOrderQuoteFetchSavedCartResponse: (state, action) => {
			state["orderQuoteFetchSavedCartResponse"] = action.payload;
		},
		setShippingCode: (state, action) => {
			state["shippingCode"] = action.payload;
		},
		setShowVisaOffer: (state, action) => {
			state["isShowVisaOffer"] = action.payload;
		},
		setInstallationInfoResp: (state, action) => {
			state["Installation"]["installationInfoResp"] = action.payload;
		},
		setConsetFlowSSN: (state, action) => {
			state["consetFlowSSN"] = action.payload;
		},
		setMitekVerficationResultLoading: (state, action) => {
			state["mitekVerficationResultLoading"] = action.payload;
		},
		setFromPartnersReward: (state, action) => {
			state["isFromPartnersReward"] = action.payload;
		},
		setSaveCartEmailAddress: (state, action) => {
			state["saveCartEmailAddress"] = action.payload;
		},
		setAddressUpdated: (state, action) => {
			state["isAddressUpdated"] = action.payload;
		},
		setShowProgressOverlay: (state, action) => {
			state["showProgressOverlay"] = action.payload;
		},
		setRetrieveLoader: (state, action) => {
			state["retrieveLoader"] = action.payload;
		},
		setShowMitekModal: (state, action) => {
			state["showMitekModal"] = action.payload;
		},
		setShowOneTimePassModal: (state, action) => {
			state["showOneTimePassModal"] = action.payload;
		},
		setCreditCheckState: (state, action) => {
			state["creditCheckState"] = action.payload;
		},
		setBusinessState: (state, action) => {
			state["businessState"] = action.payload;
		},
		setShippingState: (state, action) => {
			state["shippingState"] = action.payload;
		},
		setShowValidationOverlay: (state, action) => {
			state["showValidationOverlay"] = action.payload;
		},
		setActiveAccordian: (state, action) => {
			state["activeAccordian"] = action.payload;
		},
		setAccordionDisplayOrder: (state, action) => {
			state["activeAccordionDisplayOrder"] = action.payload;
		},
		resetVerifyBusinessSlice: (state) => {
			return initialState;
		},
		setSameAsCompanyAddress: (state, { payload }) => {
			state.sameAsCompanyAddress = payload;
		},
		setManualCompanyAddress: (state, { payload }) => {
			state.manualCompanyAddress = payload;
		},
		setIsSelected: (state, { payload }) => {
			state.isSelected = payload;
		},
		setShowCreditHoldSection: (state, { payload }) => {
			state.showCreditHoldSection = payload;
		},
		setSameAsCompanyAddressNode: (state, { payload }) => {
			state.sameAsCompanyAddress[payload["key"]] = payload["value"];
		},
		setExisitingCustomer: (state, { payload }) => {
			state.exisitingCustomer = payload;
		},
		setAboutBusinessInfor: (state, action) => {
			state["aboutBusinessInfor"] = action.payload;
		  },
		  setAuthSignerInfo: (state, action) => {
			state["authSignerInfo"] = action.payload;
		  },
		  setAuthSignerInfoTemp: (state, action) => {
			state["authSignerInfoTemp"] = action.payload;
		  },
		  setSSNInfo: (state, action) => {
			state["SSNInfo"] = action.payload;
		  },
		  setZipChangeInfoShown: (state, action) => {
			state["zipChangeInfoShown"] = action.payload;
		  },
		  setShowNotification: (state, action) => {
			state["showNotification"] = action.payload;
		  },
		  setShowVZOfferNotification: (state, action) => {
			state["showVZOfferNotification"] = action.payload;
		  },
		  setIsBizCompleted: (state, action) => {
			state["bizCompleted"] = action.payload;
		  },
		  setIsBlendedCompleted: (state, action) => {
			state["blendedCompleted"] = action.payload;
		  },
		  setSavedAddressesWithDevices: (state ,action) =>{
			state["savedAddressesWithDevices"] = action.payload;
		  },
		  setSaveInstallationResponse: (state , action)=>{
			state["saveInstallationResponse"] = action.payload;
		  },
		  setOneTimeCharge: (state, action) => {
			state["oneTimeCharge"] = action.payload;
		  },
		  setShowMaaSuccessMessage: (state, action) => {
			state["Docusign"]["showMaaSuccessMessage"] = action.payload;
		  },
		  setLoadBussAccountDetails: (state,action) =>{
			state["loadBussAccountDetails"] = action.payload
		  },
		  setStoredAccountNumber: (state,action) =>{
			state["storedAccountNumber"] = action.payload
		  },
		  setSelectedAccountInfo: (state,action) =>{
			state["selectedAccountInfo"] = action.payload
		  },
		  setSalesRepDetails: (state,action) =>{
			state["salesRepDetails"] = action.payload
		  },
		  setInitializeCheckoutData: (state,action) =>{
			state["initializeCheckoutData"] = action.payload
		  },
	},
});

export const reducer = CheckoutSlice.reducer;
export const updateSectionComplete = createAction(
	"checkout/updateSectionComplete"
);
export const getBillingInformation = createAction("checkout/getBillingInformation");
export const getRetrieveCart5G = createAction("checkout/getRetrieveProspect5G");
export const getStreetAddress = createAction("checkout/getStreetAddress");
export const getFetchCart5G = createAction("checkout/getFetchCart5G");
export const getSessionCart = createAction("checkout/getSessionCart");
export const getMetaInformation = createAction("checkout/getMetaInformation");
export const getAEMInformation = createAction("checkout/getAEMInformation");
export const getShippingMethods = createAction("checkout/getShippingMethods");
export const getInstallation = createAction("checkout/getInstallation");
export const postInstallation = createAction("checkout/postInstallation");
export const postShippingMethod = createAction("checkout/postShippingMethod");
export const getStoreData = createAction("checkout/getStoreData");
export const getMAADocUrl = createAction("checkout/getMAADocUrl");
export const validateCreditDetails = createAction(
	"checkout/callValidateCreditDetails"
);
export const getTNCDetails = createAction("checkout/getTNCDetails");
export const getBussTNCDetails = createAction("checkout/getBussTNCDetails");
export const acceptBussTnc = createAction("checkout/acceptBussTnc");
export const getOneTalkEnrollment = createAction(
	"checkout/getOneTalkEnrollment"
);
export const resumeEcpdDocs = createAction("checkout/resumeEcpdDocs");
export const postBusinessInformation = createAction(
	"checkout/postBusinessInformation"
);
export const postBusinessInfoBussAlone = createAction(
	"checkout/postBusinessInfoBussAlone"
  );
export const saveBusinessInformation = createAction(
	"checkout/saveBusinessInformation"
);
export const postOtpPhoneNumber = createAction("checkout/postOtpPhoneNumber");
export const postOtpSendCode = createAction("checkout/postOtpSendCode");
export const postOtpValidatePasscode = createAction(
	"checkout/postOtpValidatePasscode"
);
export const postNpaNxxNpaNxx = createAction("checkout/postNpaNxxNpaNxx");
export const postNpaNxxPortInEligibility = createAction(
	"checkout/postNpaNxxPortInEligibility"
);
export const postNpaNxxReleaseMdn = createAction(
	"checkout/postNpaNxxReleaseMdn"
);
export const postNpaNxxRetrieveMdn = createAction(
	"checkout/postNpaNxxRetrieveMdn"
);
export const postNpaNxxRetrieveNpaNxx = createAction(
	"checkout/postNpaNxxRetrieveNpaNxx"
);
export const postNpaNxxUpdateNickname = createAction(
	"checkout/postNpaNxxUpdateNickname"
);
export const storeTermsConditons = createAction("checkout/storeTermsConditons");
export const storeTermsConditonsReview = createAction(
	"checkout/storeTermsConditonsReview"
);
export const validateE911Address = createAction("checkout/validateE911Address");
export const postSharedCartSaveUrl = createAction(
	"checkout/postSharedCartSaveUrl"
);
export const getPersonalGuranteeDoc = createAction(
	"checkout/getPersonalGuranteeDoc"
);
export const generateDDIALink = createAction("checkout/generateDDIALink");
export const ddiaCheckVerificationResult = createAction(
	"checkout/ddiaCheckVerificationResult"
);

export const mitekCheckVerificationResult = createAction(
	"checkout/mitekCheckVerificationResult"
);
export const maaTncCheckVerificationResult = createAction(
	"checkout/maaTncCheckVerificationResult"
);
export const updateDeclinedStatus = createAction(
	"checkout/updateDeclinedStatus"
);

export const mitekSMSVerification = createAction(
	"checkout/mitekSMSVerification"
);
export const sendMitekVerification = createAction(
	"checkout/sendMitekVerification"
);
export const generateMitekVerificationLink = createAction(
	"checkout/generateMitekVerificationLink"
);
export const validateSSN = createAction("checkout/validateSSN");

export const deleteDirectory = createAction("checkout/deleteDirectory");
export const uploadFile = createAction("checkout/uploadFile");
export const readFile = createAction("checkout/readFile");
export const postClearCart = createAction("checkout/postClearCart");
export const postSaveCart = createAction("checkout/postSaveCart");
export const accessoriesRemove = createAction("checkout/accessoriesRemove");
export const packageRemove = createAction("checkout/packageRemove");
export const packageRemoveCband = createAction("checkout/packageRemoveCband");
export const getRetrieveCreditCart = createAction(
	"checkout/getRetrieveCreditCart"
);
export const saveCreditHoldCart = createAction("checkout/saveCreditHoldCart");
export const postApplyPromoCode = createAction("checkout/postApplyPromoCode");
export const updatePackages = createAction("checkout/updatePackages");

export const checkAddressValidation = createAction(
	"checkout/checkAddressValidation"
);
export const getOrderQuoteFetchSavedCart = createAction(
	"checkout/getOrderQuoteFetchSavedCart"
);
export const skipBizValidateCreditDetails = createAction(
	"checkout/skipBizValidateCreditDetails"
);
export const saveAuthorizeResponse = createAction(
	"checkout/saveAuthorizeResponse"
);
export const logSuspendData = createAction("checkout/logSuspendData");
export const loadAccountsForBussProducts = createAction("checkout/loadAccountsForBussProducts");
export const sendSelectedAccount = createAction("checkout/sendSelectedAccount");
export const {
	setIsVerifyBusiness,
	resetCheckoutState,
	setBusinessInfo,
	setCreditCheckInfo,
	setInstallmentsInfo,
	setCreditPath,
	setNotification,
	setCreditComparison,
	setInstallationInfo,
	setDepositInfo,
	setShippingMethodInfo,
	setAddressValidationInfo,
	setStoreInfo,
	setTNCDetailMethodInfo,
	setBussTNCDetailMethodInfo,
	setresumeEcpdDocsInfo,
	setMAADocUrlMethodInfo,
	setTOSInfo,
	setGlobalErrorMessage,
	getPageInfo,
	setAddressSuggestionList,
	setFlowConfig,
	setGenericSection,
	setCredit5gInfo,
	setFlowType,
	setRetrieveCart5G,
	setBISubmissionResponse,
	setvalidateCreditDetails,
	callValidateCreditDetails,
	setFetchCartResponse,
	setFetchCartStatus,
	setMetaInformation,
	setIsShoppingCartApiCallInProgress,
	setRetrieveCreditCartSuccess,
	setSaveCreditHoldCartSuccess,
	setUnifiedCartParams,
	setOtpLoading,
	setIsUnifiedCart,
	setOtpPhoneNumber,
	setOtpSendCode,
	setOtpValidatePasscode,
	resetOtp,
	setNpaNxxLoading,
	setSkipNpaNxx,
	setSkipInstallation,
	setNpaNxxNpaNxxResponse,
	setNpaNxxPortInEligibilityResponse,
	setNpaNxxReleaseMdnResponse,
	setNpaNxxRetrieveMdnResponse,
	setNpaNxxRetrieveNpaNxxResponse,
	setNpaNxxUpdateNicknameResponse,
	setReviewTermsAgreementResponse,
	setSuspendScenario,
	setDocusignLoading,
	setDocusignURL,
	setSafetechSessionId,
	setBlackBox,
	setSessionCartData,
	setAvailableDevices,
	setIsCheckoutPageApiCallInProgress,
	setLoopQualLoading,
	setLoopQualFixedWirelessResp,
	setLoopQualStreetLookupResp,
	setLoopQualUnitLookupResp,
	setLoopQualFiosQualResp,
	setE911AddressResponse,
	setProgressState,
	setActiveSection,
	setFlowFlags,
	setPostBusinessInfoResponse,
	setSaveBusinessInfoResponse,
	setExistingNumberState,
	setIsCreditResult,
	setVerificationApiRetryCount,
	setRunningMitekCount,
	setValidateBusinessInfoResponse,
	setRedirectTo4gPostAddressValidation,
	setRedirectTo5gPostAddressValidation,
	setRedirectToClearCart,
	setinvalidZipCode,
	setHasCbandPresent,
	setOneTalkEnrollment,
	setSuspendFlow,
	setOneTimeCharge,
	setE911UnitAddress,
	setDDIALinkResponse,
	setDDIACheckVerificationResult,
	setMitekCheckVerificationResult,
	setMaaTncCheckVerificationResult,
	setMaaTncCheckVerificationResultLoading,
	setUpdateDeclinedStatusResult,
	setMitekCheckVerificationProcess,
	setSendMitekVerification,
	setGenerateMitekLink,
	setValidateSSNResult,
	setDeleteDirectoryResponse,
	setViewDocumentResponse,
	setCreditEmailFlow,
	setEnableRoutingWithoutGaurd,
	setUpdatedPackages,
	setCartId,
	setCartApiError,
	setCleartCartDetails,
	setPromoCodeDetails,
	setRemoveStatus,
	setSaveCartSuccess,
	setMultiShippingDetails,
	setOrderQuoteFetchSavedCartResponse,
	setShippingResponse,
	setShippingCode,
	setShowVisaOffer,
	setInstallationInfoResp,
	setConsetFlowSSN,
	setMitekVerficationResultLoading,
	setPromoCodeLoading,
	setPromoCodeParam,
	setFromPartnersReward,
	setSaveCartEmailAddress,
	setAddressUpdated,
	setShowProgressOverlay,
	setRetrieveLoader,
	setShowMitekModal,
	setShowOneTimePassModal,
	setCreditCheckState,
	setBusinessState,
	setShippingState,
	setShowValidationOverlay,
	setActiveAccordian,
	setAccordionDisplayOrder,
	resetVerifyBusinessSlice,
	setSameAsCompanyAddress,
	setManualCompanyAddress,
	setIsSelected,
	setShowCreditHoldSection,
	setEligiblePhoneNumberList,
	setSameAsCompanyAddressNode,
	setExisitingCustomer,
	setAboutBusinessInfor,
	setAuthSignerInfo,
	setAuthSignerInfoTemp,
	setSSNInfo,
	setZipChangeInfoShown,
	setShowNotification,
	setShowVZOfferNotification,
	setShipAddressesWithDevices,
	setIsBizCompleted,
	setIsBlendedCompleted,
	setByodPortInDeviceValue,
	setOnIntialLoadSet,
	setSavedAddressesWithDevices,
	setSaveInstallationResponse,
	setShowMaaSuccessMessage,
	setBillingInformation,
	setServiceInformation,
	setLoadBussAccountDetails,
	setStoredAccountNumber,
	setSelectedAccountInfo,
	setInitializeCheckoutData,
	setSalesRepDetails
} = CheckoutSlice.actions;
