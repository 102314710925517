import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Col, Grid, Row } from "@vds3/grids";
import { Line } from "@vds3/lines";
import { Tabs, Tab } from "@vds3/tabs";
import { Title, Body } from "@vds3/typography";
import { Button } from "@vds3/buttons";
import {
	MarginSpacer2XL,
	MarginSpacerL,
	MarginSpacerM,
	MarginSpacerS,
} from "common/components/styleTag";
import AddonCards from "./AddonCards";
// import { ChatLink, SEOTagger, StickyHeaderVds3 } from "common/components";
// import { useNavigate } from "react-router-dom";
import { useGlobalData } from "hooks";
import { ChangeAddress } from "common/components/FWA/Modals";
import { isMobile } from "react-device-detect";
import StickyHeaderVds3 from "common/components/StickyHeader/StickyHeaderVds3";
import { setLoadBussPDPPage, setLoadShoppingCartWidget } from "app/ducks/GlobalSlice";
import SpinnerComponent from "common/components/Spinner/Spinner";
// import FWAAddons from "pages/LandingAddons/LandingAddons";

const BussProductsAddOnPage = () => {
	const { cmp } = useGlobalData({ skipSessionCart: true });
	const dispatch = useDispatch();
	let cmpVal = cmp?.cmpValue;
	const isLowerenv = window?.location?.pathname?.includes("/mbt/prospect/");
	const bussAemText = !isLowerenv ? "marketPlaceQA" : "marketPlace";
	const { t } = useTranslation([bussAemText]);

	const aemContent = t("buss-product", {
		ns: bussAemText,
		returnObjects: true,
	});
	const tabsRef = useRef();
	const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
	const bussData = useSelector(
		(state) => state.ProspectWidgets_BussProductsData
	);

	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	const [enableContinue, setEnableContinue] = useState(false);

	// useEffect(() => {
	//   if (bussData?.bussResponse?.viewid) {
	//     setLoadBussPDPWidget(true);
	//   }
	// }, [bussData?.bussResponse]);

	const onTabChangeFn = (event) => {
		const clicked = aemContent?.marketPlaceProductLandingPage?.tabs?.find(
			(tab) => tab.tabTitle === event.target.textContent
		);
		const scrollTarget = clicked?.ref?.current?.offsetTop;
		const tabsSize = tabsRef?.current?.clientHeight;

		window.scrollTo({
			top: scrollTarget - tabsSize,
			behavior: "smooth",
		});
	};
	const onClickContinue = () => {
		dispatch(setLoadShoppingCartWidget(false));
		dispatch(setLoadBussPDPPage(true));
	};

	const onCancel = () => {
		// SiteAnalytics.eventAddtocartTracking('Add To Cart','','4g5g','4g')
		window.dispatchEvent(
			new CustomEvent("QuoteCanceledFromWidget", {
				detail: {
					flow: "plans",
				},
			})
		);
	};

	return (
		<>
			{bussData?.loading && <SpinnerComponent disabled={false} />}
			<StickyHeaderVds3
				onContinue={onClickContinue}
				onCancel={onCancel}
				disabledContinueBtn={true}
				hideAddress={true}
				backClickHandler={() => {}}
				enableBackLink={false}
				onAddressChange={() => {}}
				continueBtnText={"Continue Order"}
				hideContinueButton={true}
			/>
			<MarginSpacer2XL />
			{/* {!isMobile && <ChatDiv><ChatLink /></ChatDiv>} */}
			<LandingPageBuss>
				<Grid colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
					<Row>
						<Col>
							<Title size="large" bold>
								{aemContent?.marketPlaceProductLandingPage?.title}
							</Title>
							<MarginSpacerL />
							<Title size="medium">
								{aemContent?.marketPlaceProductLandingPage?.subTitle1}
							</Title>
							<MarginSpacerS />
							<Body size="large" color="#6F7171">
								{aemContent?.marketPlaceProductLandingPage?.subTitle2}
							</Body>
							<SeparatorLine type="secondary" />
						</Col>
					</Row>
					{/* <Row>
            <Col>
            {(landingAddonsPage?.isFromFwa ==='4g' || landingAddonsPage?.isFromFwa === '5g') &&
          <> <FWAAddons
              isFromBuss = {true}
              flow={landingAddonsPage?.isFromFwa}
          />
          </>}
            </Col>
          </Row> */}
					<Row>
						{/* {(landingAddonsPage?.isFromFwa ==='') && */}
						<Col>
							<TabContainer ref={tabsRef}>
								<Tabs
									indicatorPosition="bottom"
									size="medium"
									onTabChange={onTabChangeFn}
								>
									{aemContent?.marketPlaceProductLandingPage?.tabs?.map((tab) => (
										<Tab key={tab.tabTitle} label={tab.tabTitle}>
											<div className="tab-text-wrapper">
												<MarginSpacerM />
												{/* <Title size="small" primitive="h3">
													{tab.tabTitle}
												</Title> */}
												<MarginSpacerS />
												<Body size="small">{tab.tabSubTitle}</Body>
												<AddonCards
													tabList={tab}
													ctaBtnText={aemContent?.marketPlaceProductLandingPage?.ctaBtnText}
													setEnableContinue={setEnableContinue}
													tabs={aemContent?.marketPlaceProductLandingPage?.tabs}
												/>
											</div>
										</Tab>
									))}
								</Tabs>
							</TabContainer>
						</Col>
						{/* } */}
					</Row>
					<Row>
						<Col>
							<MarginSpacerL />
							<CheckoutButton
								onClick={onClickContinue}
								disabled={!enableContinue ? true : false}
								data-track={aemContent?.continue}
							>
								{aemContent?.marketPlaceProductLandingPage?.continue}
							</CheckoutButton>
							<MarginSpacer2XL />
						</Col>
					</Row>
				</Grid>
			</LandingPageBuss>
			{/* {showChangeAddressModal && (
        <ChangeAddress
          opened={showChangeAddressModal}
          onOpenedChange={setChangeAddressModal}
          url={""}
        />
      )} */}
		</>
	);
};

const LandingPageBuss = styled.div`
	margin: 1rem 0 0 0;
`;
const SeparatorLine = styled(Line)`
	margin: 1rem 0;
`;
const TabContainer = styled.div`
	width: 100%;

	> div a {
		border-bottom: 1px solid #d8dada;
	}
`;

export const CheckoutButton = styled(Button)`
	width: 145px;
	height: 44px;
	padding: 12px 0;
	@media screen and (max-width: 576px) {
		width: 100%;
	}
`;

const ChatDiv = styled.div`
	margin-top: 8px;
	margin-right: 20px;
`;

export default BussProductsAddOnPage;
