import React, { useCallback, useEffect } from 'react'
import { Body, Micro } from "@vds3/typography";
import { MarginSpacerL, MarginSpacerM } from 'common/components/styleTag';
import { Line } from "@vds3/lines";
import { Checkbox } from '@vds3/checkboxes';
import { useTranslation } from "react-i18next";
import { Input } from "@vds3/inputs";
import { Notification } from '@vds3/notifications';
import { getDeviceCategory, getDeviceKey, getDisplayName } from 'utils/ItemDescription';
import { formatPriceString } from 'utils/commonUtils';
import { isEmpty } from "lodash";
import ReCAPTCHA from "react-google-recaptcha";
const styles = {
  display:'flex',
  justifyContent:'flex-end'
}
export const DueToday = ({creditCheckResult, selectedValue, creditApproved, creditLimitExceeded = false, dppToFrp = false, dueToday = "0.00", tax = "0.00", showCartOnly = false, refundableDeposit = 0, downPayment = 0, cartData ,securityDeposit}) => {
  let cartDataToSend = cartData
  if(!!creditCheckResult[selectedValue] && creditCheckResult[selectedValue]['cart'] !==null && creditCheckResult[selectedValue]['cart'] && Object.keys(creditCheckResult[selectedValue]['cart']).length) cartDataToSend = creditCheckResult[selectedValue]['cart']
  return (
    <div style={{maxWidth:'600px',borderBottom:'0.5px solid #d8dada',paddingBottom:'6px'}}>
      <Common widthWhole='600px' style={styles} title="Due today" data={dueToday} bold={true} />
      <Common widthWhole='600px' style={styles} title="Estimated tax" data={tax} />
      {showCartOnly ? <></> :
        <>
          {(creditLimitExceeded && Number(downPayment) > 0)? <Common style={styles} widthWhole='600px' bold={true} title="Required down payment" data={Number(downPayment).toFixed(2)} color="#006FC1" /> : <></>}
          {(!cartData?.securityDepositWaveOff && !creditApproved && (!!securityDeposit && !isNaN(securityDeposit) && securityDeposit > 0) ) ? <Common style={styles} widthWhole='600px' bold={true} title="Security Deposit" data={Number(securityDeposit)} color="#006FC1"/> : <></>}
          {(cartData?.securityDepositWaveOff && !creditApproved ) ? <Common style={styles} widthWhole='600px' bold={true} title="Refundable deposit waived" data={Number(refundableDeposit)} color="#006FC1" strikeThrough={true} /> : <></>}
          {(!creditApproved && dppToFrp && Number(refundableDeposit) > 0) && <Common style={styles} widthWhole='600px' size="medium" noPrice={true} title={`If you purchased device(s) at full price or brought your own compatible device(s), your deposit is waived for up to 10 devices.`} />}
        </>}
      <GetDeviceForDueToday cartData={cartDataToSend} />
    </div>
  )
}
export const DueMonthly = ({ creditCheckResult, selectedValue, dueMonthly = "0.00", cartData = {}, sessionCartData }) => {
  let cartDataToSend = sessionCartData
  if(!!creditCheckResult[selectedValue] && creditCheckResult[selectedValue]['cart'] !==null && creditCheckResult[selectedValue]['cart'] && Object.keys(creditCheckResult[selectedValue]['cart']).length) cartDataToSend = creditCheckResult[selectedValue]['cart']
  return (
    <div>
      <Common widthWhole='600px' style={styles} title="Due monthly" data={dueMonthly} bold={true} />
      <GetDevice cartData={cartData} sessionCartData={cartDataToSend} />
      <MarginSpacerL />
    </div>
  )
}
export const Common = ({titleBold = false,style = {},widthWhole="100%", title = '', data = '', bold = false, color = "black", size = "large", month = '', isNan = true, subText = "", noPrice = false, strikeThrough = false }) => {
  return (
    <div style={{ marginBottom: '10px' }}>
    <div style={{ maxWidth:widthWhole,width: '100%', display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ width: '65%' }}>
        <Body size={size} bold={bold || titleBold} color={color}>{title}</Body>
      </div>
      <div style={{ ...style, width: '35%', color: strikeThrough ? color : '', textDecoration: strikeThrough ? "line-through" : "",display:'flex',justifyContent:'flex-end' }}><Body size={size} bold={bold} color={color}>
        {!isNan ? data : data !== null && data && !isNaN(data) ? ('$' + Number(data).toFixed(2)) : noPrice ? '' : '$0.00'}{month}</Body></div>
    </div>
    {subText && <Body size={size} color='#6F7171'> {subText}</Body>}
    </div>
  )
}
const GetDevice = ({ cartData = {}, sessionCartData }) => {
  const { t } = useTranslation(["shoppingCart"]);
  const aemCategories = t("shopping-cart.cart-category-names", { returnObjects: true }) || {};
  const list = []
  const { groupPackages, packages } = cartData
  const { orderSummaryMontlyDueMap } = sessionCartData
  const [filteredData, setFilteredDueMonthly] = React.useState(orderSummaryMontlyDueMap)
  const [eacCount,setEACCount] = React.useState(0)
  const [eacPrice,setEacPrice] = React.useState(0)
  const starkEnabled = JSON.parse(window?.sessionStorage?.getItem("starkEnabled")) || (cartData?.prospect5gLoopQualAddress?.flow === "5g" && cartData?.prospect5gLoopQualAddress?.starkEnabled) || false;
  const isOnlyOneTalk = packages !== null && packages && Object.values(packages).filter(data => data.packageType === "ONETALK").length === 1
  useEffect(() => {
    if (orderSummaryMontlyDueMap && Object.keys(orderSummaryMontlyDueMap)?.length) {
      let filteredValue = {};
      let starkReciverWithByor = {};
      let eacCountLocal = 0
      let eacPriceLocal = 0
      Object.keys(orderSummaryMontlyDueMap)?.forEach((key) => {
        const prevList = filteredValue[getDeviceKey(key)];
        let isDevices = key?.toLowerCase()?.includes("device");
        let isOnetalkFeature = (key?.toLowerCase()?.includes("onetalkfeature"));
        let features = (key?.toLowerCase()?.includes("feature"));
        let dueMonthlyMap = orderSummaryMontlyDueMap[key];
        if (!!isDevices) {
          dueMonthlyMap = orderSummaryMontlyDueMap?.[key]?.map((device) => {
            if (device?.byod) {
              return { ...device, isDevices: !!isDevices }
            }
            if (starkEnabled && device?.itemId?.includes('LV65BIDU') && device?.byor && !device?.byod) {
              starkReciverWithByor = { ...device, itemName: 'Your own router' }
              return { ...device, byor: false }
            }
            return device;
          });
        }
        const eac = dueMonthlyMap.filter(device => device.itemName === "ECONOMIC ADJUSTMENT CHARGE")
        dueMonthlyMap = dueMonthlyMap.filter(device => device.itemName !== "ECONOMIC ADJUSTMENT CHARGE")
        if (!!features) {
          let isFeatures = orderSummaryMontlyDueMap?.[key]?.filter((device) => {
            if (device?.itemName && device?.itemName === "ECONOMIC ADJUSTMENT CHARGE") {
              eacCountLocal += device?.count
              eacPriceLocal += device?.itemPrice ?? 0
              return device;
            }
          });
          if (!isFeatures?.length && !isOnlyOneTalk && !isOnetalkFeature) {
            dueMonthlyMap = [...dueMonthlyMap, {
              byod: false,
              byor: false,
              count: 1,
              itemPrice: 0,
              itemName: t("smart-phones.shopping-cart.economicAdjustmentMessage")
            }];
          }
        }
        if (prevList) {
          filteredValue[getDeviceKey(key)] = [
            ...prevList,
            ...dueMonthlyMap,
          ];
        } else {
          filteredValue[getDeviceKey(key)] = [...dueMonthlyMap];
        }
        if (eac !== null && eac && eac.length) {
          filteredValue[getDeviceKey(key)].push({ ...eac[0] })
        }
      });
      Object.keys(filteredValue)?.map((key) => {
      let itemPrice = 0
      let eacIndex = -1
      filteredValue[key].map((device,i) => { 
        if(device['itemName'] && !(String(device['itemName']).toLowerCase().includes('subtotal') ||
        (String(device['itemName']).toLowerCase().includes('economic') && String(device['itemName']).toLowerCase().includes('adjustment'))))
        itemPrice += device['itemPrice'] ?? 0 
        if(String(device['itemName']).toLowerCase().includes('economic') && String(device['itemName']).toLowerCase().includes('adjustment')) eacIndex = i
      })
      if(!isNaN(itemPrice)){
        if(eacIndex > -1){
          filteredValue[key].splice(eacIndex,0,{
            itemName: "Subtotal*",
            itemPrice: itemPrice
          })
        }
        else{
      filteredValue[key].push({
        itemName: "Subtotal*",
        itemPrice: itemPrice
      })
    }
    }
    })
      if (starkEnabled && Object.keys(starkReciverWithByor)?.length > 0 && filteredValue?.BusinessInternet) {
        filteredValue?.BusinessInternet?.splice(1, 0, { ...starkReciverWithByor });
      }
      setEacPrice(eacPriceLocal)
      setEACCount(eacCountLocal)
      setFilteredDueMonthly(filteredValue);
    }
  }, [orderSummaryMontlyDueMap])
  const displayPrice = (price, byor = false, byod = false, item = "", itemName = "") => {
    if (
      item?.includes("BusinessInternet") &&
      !itemName?.includes("Protection") &&
      !itemName?.includes("Economic") &&
      !byor &&
      !byod &&
      price <= 0
    ) {
      return t("shopping-cart.included");
    }
    if (byor && starkEnabled && itemName?.includes('Your own router')) {
      return formatPriceString(0);
    }
    return formatPriceString(price) || 0.0;
  }
  const displayName = (deviceRawName, count, byor = false, byod = false, isDevices = false, eacDisclaimerEnabled) => {
    const byorDisplayName = t("4g.shopping-cart.cartBYOR.name");
    if (deviceRawName === "ECONOMIC ADJUSTMENT CHARGE") {
      deviceRawName = eacDisclaimerEnabled && eacDisclaimerEnabled === 'Y' && !isOnlyOneTalk
        ? `${t("smart-phones.shopping-cart.economicAdjustmentMessage")}**`
        : t("smart-phones.shopping-cart.economicAdjustmentMessage");
    } else if (deviceRawName?.includes("Protection") || deviceRawName?.includes("Equipment Coverage")
      || deviceRawName?.includes("Extended Warranty") || deviceRawName?.includes("Protect")) {
      const DPPChangeNotificationShow = t(
        "shopping-cart.cart.disclaimerDPPChangesInfoShow",
        { ns: "shoppingCart" }
      );
      const DPChangeNotiIndicatorSymbol = t(
        "shopping-cart.cart.disclaimerDPPChangesIndicator",
        { ns: "shoppingCart" }
      );
      deviceRawName = deviceRawName + (DPPChangeNotificationShow === "Y" ? DPChangeNotiIndicatorSymbol : "")
    }
    if (deviceRawName === "PLAN RATE ADJUSTMENT") {
      deviceRawName = t("smart-phones.shopping-cart.planRateAdjustmentMessage");
    }
    let deviceName = getDisplayName({ description: deviceRawName || "" });
    if (byor) {
      deviceName = byorDisplayName;
    }
    if (byod && isDevices) {
      deviceName = `Your own device - ${deviceName}`;
    }
    if (Number(count) > 1) {
      deviceName += `(${count})`;
    }
    deviceName = (deviceName.includes("Flexible") && eacDisclaimerEnabled === 'Y' && !isOnlyOneTalk) ? deviceName + "***" : deviceName;
    return deviceName;
  };
  
  return (
    <div>
{filteredData !== null && filteredData && Object.keys(filteredData)?.map((item, index) => {
    if (filteredData[item] !== null && filteredData[item] && filteredData[item].length) {
      return(
        <div>
          <MarginSpacerM />
          <Micro color='#A7A7A7'> {aemCategories[getDeviceCategory(item)] || getDeviceCategory(item)}</Micro>
          {filteredData[item]?.map(
            ({ itemId, itemName, itemPrice, count, byor, byod, isDevices = false }) => {
              if(itemName && !String(itemName).toUpperCase().includes("ECONOMIC ADJUSTMENT CHARGE")) return(
              <Common widthWhole='600px' style={styles} isNan={false} title={displayName(itemName, count, byor, byod, isDevices)} data={displayPrice(itemPrice, byor, byod, item, itemName)} />
            )})}
        </div>
      )
    }
  })}
  {eacCount > 0 ? 
    <Common widthWhole='600px' style={styles} isNan={false} title={displayName("ECONOMIC ADJUSTMENT CHARGE", eacCount)} data={displayPrice(eacPrice, "", "", "", "ECONOMIC ADJUSTMENT CHARGE")} />
  : <></>}
    </div>
  )
}
export const SSN = ({ changedInput = () => { }, checkboxCliked = () => { }, showNotificationError = false, ssnError = false, value = '' }) => {
  const { t, i18n } = useTranslation(["verification"]);
  const [checkbox,handlecheckBox] = React.useState(false)
  const authorizeTextForFullAutomation = t("verification.authorizeTextForFullAutomation", { ns: "verification" });
  const recaptchaRef = React.createRef();
  const captcha =  window?.mbtGlobal?.sk || "6LdMkcAZAAAAANaphnrFMaEl9Msqqls3xWmAX3yy";
  let hasCaptcha = false;
  if (!isEmpty(captcha)) hasCaptcha = true;
  const OnCheckboxClick = (addressState, captchaToken)=>{
    if ((!(recaptchaRef?.current?.getValue() || captchaToken)) && window?.grecaptcha  && window?.grecaptcha?.execute ) {
            window?.grecaptcha?.execute();
    }else checkboxCliked(checkbox)
  }
  return (
    <div>
      <MarginSpacerL />
      <Line type="secondary" />
      <MarginSpacerL />
      <div style={{maxWidth:"490px"}}>
      <Input
        type="password"
        label="Your Social Security Number"
        mask="toggle"
        readOnly={false}
        required={true}
        disabled={false}
        error={ssnError}
        value={value}
        errorText='Enter valid 9-digit social security number'
        // defaultValue={'123456789'}
        onChange={(e) => { if (!isNaN(e.target.value) && e.target.value.length < 10) changedInput(e.target.value) }}
        helperText='###-##-####'
        helperTextPlacement="bottom"
        tooltipContent={t('verification.softCreditEnquiry',{ns:"verification"})}
      />
      {showNotificationError ?
        <>
          <MarginSpacerM />
          <Notification type="error" title="Complete checkbox to continue"
            fullBleed={false} inlineTreatment={false} />
          <MarginSpacerM />
        </> : <></>}
      <MarginSpacerM />
        <Checkbox
          disabled={false}
          error={false}
          errorText=""
          label=""
          // selected={isCheckBoxSelected}
          onChange={(e) => {
            if(hasCaptcha){
            handlecheckBox(e.target.checked)
            OnCheckboxClick()
            }
            else checkboxCliked(e.target.checked)
          }}
          width="100%"
        >
          <Body size="small" primitive="p">
            <span
              dangerouslySetInnerHTML={{
                __html: authorizeTextForFullAutomation,
              }}
            />
          </Body>
        </Checkbox>
        <div style={{display:'flex',alignItems:'center',margin:'1rem 0'}} className='captcha-img-cr'>
        {hasCaptcha  ?
            <LoadCaptcha onContinue = {OnCheckboxClick} hasCaptcha={hasCaptcha} recaptchaRef={recaptchaRef} captcha={captcha}/>
            : <></> }&nbsp;
        <div className="digicertContainer-cre">
          <img src="https://ss7.vzw.com/is/image/VerizonWireless/digital-digicert-seal-2023?scl=1" alt="digicert-Seal Icon" />
        </div>
        </div>
      </div>
    </div>
  )
}
export const LoadCaptcha = ({onContinue = ()=>{},hasCaptcha = false,recaptchaRef,captcha})=>{
  const [captchaValue,setIsCaptchaValue] = React.useState("")
  return(
    hasCaptcha ? (
      <div className="captchaContainer-consent">
          <ReCAPTCHA
              ref={recaptchaRef}
              height="60"
              role="presentation"
              name="a-avb9eym4nsmt"
              frameBorder="0"
              scrolling="no"
              size="invisible"
              badge="inline"
              sitekey={captcha}
              onChange={(e) => {
                  const recaptchaValue =
                      recaptchaRef?.current?.getValue();
                  setIsCaptchaValue(recaptchaValue);
                  onContinue(e, recaptchaValue);
              }}
              onExpired={() => {
                  setIsCaptchaValue("");
              }}
          />
      </div>
    ) : <></>
  )
}
const GetDeviceForDueToday = ({ cartData }) => {
  const list = []
  const { t } = useTranslation(["shoppingCart"]);
  const aemCategories = t("shopping-cart.cart-category-names", { returnObjects: true }) || {};
  const dueTodaySummary = cartData?.orderSummaryRetailDueMap || "";
  const [filteredData, setFilteredDueToday] = React.useState(dueTodaySummary);
  const starkEnabled = JSON.parse(window?.sessionStorage?.getItem("starkEnabled")) || (cartData?.prospect5gLoopQualAddress?.flow === "5g" && cartData?.prospect5gLoopQualAddress?.starkEnabled) || false;

  useEffect(() => {
    if (dueTodaySummary && Object.keys(dueTodaySummary)?.length) {
      let filteredValue = {};
      let starkReciverWithByor = {};
      Object.keys(dueTodaySummary)?.forEach((key) => {
        const prevList = filteredValue[getDeviceKey(key)];
        if (prevList) {
          filteredValue[getDeviceKey(key)] = [
            ...prevList,
            ...dueTodaySummary[key],
          ];
        } else {
          filteredValue[getDeviceKey(key)] = [...dueTodaySummary[key]];
        }
        if (starkEnabled && key?.includes('FWAFiveGdevices')) {
          dueTodaySummary[key]?.forEach((device) => {
            if (device?.itemId?.includes("LV65BIDU") && device?.byor) {
              starkReciverWithByor = { ...device, itemName: 'Use your own router', itemPrice: 0.00 }
            }
          })
        }
      });
      if (starkEnabled && Object.keys(starkReciverWithByor)?.length > 0 && filteredValue?.BusinessInternet) {
        filteredValue?.BusinessInternet?.splice(1, 0, { ...starkReciverWithByor });
      }
      setFilteredDueToday(filteredValue);
    }
  }, [dueTodaySummary]);
  const displayPrice = (price, byor = false, byod = false, item = "", itemName = "", starkEnabled) => {
    if (
      item?.includes("BusinessInternet") &&
      !itemName?.includes("Protection") &&
      !itemName?.includes("Economic") &&
      !byor &&
      !byod &&
      price <= 0
    ) {
      return t("shopping-cart.included");
    }
    if (byor && starkEnabled && itemName?.includes('Your own router')) {
      return formatPriceString(0);
    }
    return formatPriceString(price) || 0.0;
  }
  const displayName = (deviceRawName, count, byor = false, byod = false, isDevices = false, eacDisclaimerEnabled) => {
    const byorDisplayName = t("4g.shopping-cart.cartBYOR.name");
    if (deviceRawName === "ECONOMIC ADJUSTMENT CHARGE") {
      deviceRawName = eacDisclaimerEnabled && eacDisclaimerEnabled === 'Y' && !isOnlyOneTalk
        ? `${t("smart-phones.shopping-cart.economicAdjustmentMessage")}**`
        : t("smart-phones.shopping-cart.economicAdjustmentMessage");
    } else if (deviceRawName?.includes("Protection") || deviceRawName?.includes("Equipment Coverage")
      || deviceRawName?.includes("Extended Warranty") || deviceRawName?.includes("Protect")) {
      const DPPChangeNotificationShow = t(
        "shopping-cart.cart.disclaimerDPPChangesInfoShow",
        { ns: "shoppingCart" }
      );
      const DPChangeNotiIndicatorSymbol = t(
        "shopping-cart.cart.disclaimerDPPChangesIndicator",
        { ns: "shoppingCart" }
      );
      deviceRawName = deviceRawName + (DPPChangeNotificationShow === "Y" ? DPChangeNotiIndicatorSymbol : "")
    }
    if (deviceRawName === "PLAN RATE ADJUSTMENT") {
      deviceRawName = t("smart-phones.shopping-cart.planRateAdjustmentMessage");
    }
    let deviceName = getDisplayName({ description: deviceRawName || "" });
    if (byor) {
      deviceName = byorDisplayName;
    }
    if (byod && isDevices) {
      deviceName = `Your own device - ${deviceName}`;
    }
    if (Number(count) > 1) {
      deviceName += `(${count})`;
    }
    deviceName = (deviceName.includes("Flexible") && eacDisclaimerEnabled === 'Y' && !isOnlyOneTalk) ? deviceName + "***" : deviceName;
    return deviceName;
  };
  filteredData !== null && filteredData && Object.keys(filteredData).length && Object.keys(filteredData)?.map((item, index) => {
    if (filteredData[item].length > 0) {
      list.push(
        <div>
          <Micro color='#A7A7A7'> {aemCategories[getDeviceCategory(item)] || getDeviceCategory(item)}</Micro>
          {filteredData[item]?.map(
            ({ itemId, itemName, itemPrice, count, byor, byod, isDevices = false }) => {
              const getDisplayName = displayName(itemName, count, byor, byod, isDevices)
              if(!(String(getDisplayName).toUpperCase().includes('SECURITY') || String(getDisplayName).toUpperCase().includes('DEPOSIT')
               || String(getDisplayName).toUpperCase().includes('OFF') || String(getDisplayName).toUpperCase().includes('DOWNPAYMENT'))){
              return(
              <Common widthWhole='600px' style={styles} bold={true} color="#006FC1" isNan={false} title={getDisplayName} data={displayPrice(itemPrice, byor, byod, item, itemName)} />
            )}})}
        </div>
      )
    }
  })
  return list
}
