import React, { useEffect, useState } from "react";
import { Body } from "@vds3/typography";
import { Title } from "@vds3/typography";
import styled from "styled-components";
import {
  MarginSpacerM,
  MarginSpacerXL,
  MarginSpacerXS,
} from "common/components/styleTag";
import { Button } from "@vds3/buttons";
import { formatPriceString } from "utils/commonUtils";
import _ from "lodash";
import { BUSSModal } from "./BussModal";
import {InputStepper} from '@vds/input-stepper'


const PdpPlanList = ({
  bussData,
  aemContent,
  planDetails,
  handleContinueClick,
  frequency,
  slicedPlansList,
  type,
  selectedProducts,
  dataTosend={}
}) => {
  const [selectedPlanCat,setSelectedPlanCat] = useState([])
  const [defaultData,setDefaultData] = useState({})
  const [openModal,setModal] = useState(false)
  const [currentId,setCurrentId] = useState('')
  const [storeData,setStoreData] = useState({})
  const getTitle = (item, desc) => {
    if (item?.includes("-")) {
      return desc?.slice(0, 60);
    }
    return item?.slice(0, 60);
  };
  const getLineText = (frequency) => {
    if (frequency === "MONTHLY") {
      return planDetails?.perLine;
    } else if (frequency === "ANNUAL") {
      return planDetails?.annually;
    } else if (frequency === "ONE TIME") {
      return planDetails?.oneTime;
    }
    return planDetails?.perLine;
  };
  useEffect(()=>{
    if(!!dataTosend && Object.keys(dataTosend).length){
        setStoreData({...storeData,...dataTosend})
    }
},[dataTosend])
  return (
    <>
    {selectedPlanCat!==null&&selectedPlanCat&&selectedPlanCat.length && openModal  ? 
    <BUSSModal opened={openModal} 
    displayItems = {selectedPlanCat}
    sendResultData = {(data)=>{
      const {itemId,frequency,vewName,quantity, selectedItem} = defaultData[currentId]
      handleContinueClick(itemId,frequency,vewName,data,quantity, selectedItem);
      setStoreData({...storeData,[currentId]:data})
      setModal(false)
    }}
    setModalsState = {val=>setModal(val)}
    />: <></>}
      {slicedPlansList?.map((item) => {
        let planData = item;
          const isCatPresent = item?.productOfferingCharacteristics !==null && item?.productOfferingCharacteristics && item?.productOfferingCharacteristics.length ? item?.productOfferingCharacteristics : []
        return item?.pricePlanPackageDetails
          ?.filter((fre) => type ? (fre?.frequency === frequency) && item?.contentID?.includes(type)  : fre?.frequency === frequency)
          ?.map((price) => {
            if (!!planData) {
              return (
                <TileContainer key={item?.itemID}>
                  <TileWrapper>
                    <CutomTitle>
                      <Title size="small">
                        {getTitle(planData?.itemName, item?.shortDescription)}
                      </Title>
                    </CutomTitle>
                    <MarginSpacerM />
                    <PriceLockup>
                      <Title size="large" bold={true}>
                        {formatPriceString(price?.purchasePrice)}
                      </Title>
                      <Body size="small">{getLineText(frequency)}</Body>
                      <MarginSpacerXS />
                    </PriceLockup>
                    <MarginSpacerXL />
                    <InputStepper
                            defaultValue={selectedProducts[item?.itemID] ?? 0}
                            maxValue={99}
                            minValue={1}
                            surface="light"
                            trailingText=""
                            width="auto"
                            onChange = {(value)=>{
                              const dtaToSend = storeData[item?.itemID] ?? []
                              if(!!isCatPresent && isCatPresent.length && value === 1){
                                setSelectedPlanCat(isCatPresent)
                                setModal(true)
                                setCurrentId(item?.itemID)
                                setDefaultData({...defaultData,[item?.itemID]:{itemId:item?.itemID || item?.catID,frequency:price?.frequency,vewName:bussData?.bussResponse?.viewName,quantity:value, selectedItem: item}})
                              }else{
                              handleContinueClick(
                                item?.itemID || item?.catID,
                                price?.frequency,
                                bussData?.bussResponse?.viewName,
                                dtaToSend,
                                value,
                                item
                              );
                            }
                            }}/>
                    {/* <Button
                      use="secondary"
                      size="large"
                      width="100%"
                      onClick={() => {
                        if(!!isCatPresent && isCatPresent.length){
                          setSelectedPlanCat(isCatPresent)
                          setModal(true)
                          setDefaultData({itemId:item?.itemID || item?.catID,frequency:price?.frequency,vewName:bussData?.bussResponse?.viewName})
                        }else{
                        handleContinueClick(
                          item?.itemID || item?.catID,
                          price?.frequency,
                          bussData?.bussResponse?.viewName
                        );
                      }
                      }}
                    >
                      {aemContent?.marketPlaceProductLandingPage?.btnAddPlan}
                    </Button> */}
                    <MarginSpacerXL />
                    <Body>
                      {item?.longDescription || item?.shortDescription}
                    </Body>
                  </TileWrapper>
                </TileContainer>
              );
            }
          });
      })}
    </>
  );
};

const TileContainer = styled.div`
  min-width: 220px;
  min-height: 252px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #d8dada;
  background: #fff;
  padding: 1.5rem 1rem;
  margin-bottom: 1rem;
`;

const TileWrapper = styled.div`
  flex-direction: column;
`;

const CutomTitle = styled.div`
  min-height: 4rem;
`;

const PriceLockup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
`;

export default PdpPlanList;
