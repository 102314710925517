import { DEVICETYPES } from "containers/constants";
import { initialPriceSummary } from "containers/models";

let deviceTypeInCart = "";

const addTradeInTotal = (totalsFromResp, priceSummary) => {
  if (!priceSummary["total"]) {
    priceSummary["total"] = {};
  }
  if (totalsFromResp && totalsFromResp?.tradeInTotal) {
    priceSummary["total"]["tradeInTotal"] = totalsFromResp.tradeInTotal;
  } else {
    priceSummary["total"]["tradeInTotal"] = 0;
  }
  return priceSummary;
};

const createAccessories = (
  accessories,
  priceSummary = { ...initialPriceSummary },
  from = ""
) => {
  const newAccessories = {};
  if (from === "") {
    priceSummary = JSON.parse(JSON.stringify(initialPriceSummary));
  }
  Object.keys(accessories).forEach((key) => {
    const description = accessories[key].description
      ? accessories[key].description.split(":_:")
      : [];
    newAccessories[key] = {
      id: accessories[key].accessoryId,
      brandname: description[0] === "undefined" ? null : description[0],
      productName: description[1],
      colorDisplay: description[2],
      colorHex: description[3],
      image: description[4],
      category: description[5],
      filePath: description[6],
      accessoryId: description[7],
      prodCode4: accessories[key].prodCode4,
      finalBestPrice: accessories[key].itemPrice,
      finalBestPriceBefore:
        accessories[key]?.itemPriceWithOutDiscount ||
        accessories[key].itemPrice,
      retailPrice: accessories[key].itemRetailPrice,
      quantity: accessories[key].quantity,
      outOfStock: accessories[key].outOfStock,
      offers: accessories[key].offers,
      hideOnUI: accessories[key]?.hideOnUI,
      router: accessories[key]?.router,
      notPresent: accessories[key]?.notPresent,
    };
    priceSummary.dueToday.accessories =
      priceSummary.dueToday.accessories +
      Number(accessories[key].itemPrice) * Number(accessories[key].quantity);
  });
  return newAccessories;
};

const createBusinessInfo = (businessInfoResp) => {
  let businessInfo = {};
  businessInfo = {
    companyInfo: {
      name: businessInfoResp.bussinessName,
      address: { ...businessInfoResp.address },
      federalTaxId: "",
    },
    shoppingPath: "PROSPECT",
    signerInfo: {
      address: {
        ...businessInfoResp.pocAddress,
        emailAddress: businessInfoResp?.email,
        phoneNumber: businessInfoResp?.phoneNumber,
      },
      dob: businessInfoResp.dateOfBirth,
      firstname: businessInfoResp.firstName,
      lastName: businessInfoResp.lastName,
      ssn: "",
      title: businessInfoResp.title,
    },
    termsNconditions: businessInfoResp.termsNconditions,
    shallCustomerId: businessInfoResp?.shallCustomerId || null,
  };
  return businessInfo;
};

const createBusinessInfoFromLoopQual = (businessInfoResp) => {
  let businessInfo = {};
  businessInfo = {
    companyInfo: {
      name: businessInfoResp?.bussinessName,
      address: { ...businessInfoResp },
      federalTaxId: "",
    },
    shoppingPath: "PROSPECT",
    signerInfo: {
      address: {
        ...businessInfoResp,
        emailAddress: businessInfoResp?.email,
        phoneNumber: businessInfoResp?.phoneNumber,
      },
    },
  };
  return businessInfo;
};

const createDevices = (
  packages,
  priceSummary = { ...initialPriceSummary },
  from = "",
  isDSP = false
) => {
  const imageCDN = "//ss7.vzw.com/is/image/VerizonWireless/";
  let devices = {};
  if (from === "") {
    priceSummary = JSON.parse(JSON.stringify(initialPriceSummary));
  }
  Object.keys(packages).forEach((key) => {
    let description = packages[key]?.description
      ? packages[key].description.split(":_:")
      : [];
    let {
      deviceSku,
      deviceId,
      prodCode4,
      simSku,
      priceType,
      priceType: userSelectedPayment,
      itemPrice: selectedPrice,
      sorDeviceType: technologyType,
      totalDueMonthly,
      totalDueToday,
      preOrder,
      backOrder,
      outOfStock,
      offers,
      shipmentDate,
      tenure,
      byod,
      byod: isByod,
      imei,
      newSim,
      simNumber,
      phoneId,
      nickName,
      deviceOtherSkus,
      otherSkusOutOfStock,
      packageType,
      deviceType,
      byor,
      dummy,
      featuresTotal,
      esimCapable,
      psimCapable,
      downPayment,
      itemPriceWithOutDiscount,
      esimSku,
      eid,
      priceTypeBefore,
      newDeviceWithEsim,
      securityDepositAmount,
      securityDepositBeforeWaiveOff,
      securityDepositAfterWaiveOff,
      itemPriceBeforeCreditEligibility,
      percentage,
      upgradeOptionCode,
      categoryCode,
      itemPriceBeforeStrikeThrough,
      displayDiscountPrice,
    } = packages[key];
    let [
      brandName,
      productName,
      capacity,
      colorDisplay,
      cssColor,
      image,
      category,
      seoUrlName,
    ] = description;
    let [mea, min, mtnActive, mtnReservationDate, npa, nxx, mdn] = [
      packages[key]?.mea,
      packages[key]?.min,
      packages[key]?.mtnActive,
      packages[key]?.mtnReservationDate,
      packages[key]?.npa,
      packages[key]?.nxx,
      packages[key]?.fullMtn,
    ];
    if (!productName || productName === "PackageType:ONETALK") {
      productName = packages[key].productName || "";
      brandName = packages[key].brandName || "";
      category = packages[key].category || "";
    }

    if (
      deviceType === DEVICETYPES.MOBILEAPPCLIENT &&
      packages[key]?.plan?.offers
    ) {
      const oneTalkPromo = packages[key]?.plan?.offers;
      offers = {
        ...offers,
        onetalkPlanPromo: oneTalkPromo,
      };
    }

    if (displayDiscountPrice) {
      totalDueMonthly = displayDiscountPrice.totalDueMonthly;
      selectedPrice = displayDiscountPrice.itemPrice;
    }

    const carouselImageset = [];
    if (image) {
      carouselImageset.push(imageCDN + image + "?$acc-lg$&hei=138&wid=138");
    }

    devices[key] = {
      quantity: 1,
      deviceFullDetail: {
        id: key.split("_")[0],
        brandName,
        productName,
        capacity,
        colorDisplay,
        cssColor,
        image,
        carouselImageset,
        category,
        deviceId,
        prodCode4,
        parentId: deviceId,
        simSku,
        userSelectedPayment,
        selectedPrice,
        technologyType,
        preOrder,
        backOrder,
        outOfStock,
        shipmentDate,
        seoUrlName,
        tenure,
        byod,
        isByod,
        imei,
        newSim,
        simNumber,
        phoneId,
        nickName,
        offers,
        deviceOtherSkus,
        otherSkusOutOfStock,
        packageType,
        deviceType,
        byor,
        dummy,
        deviceSku,
        downPayment,
        itemPriceWithOutDiscount,
        esimSku,
        eid,
        priceTypeBefore,
        priceType,
        totalDueMonthly,
        totalDueToday,
        newDeviceWithEsim,
        securityDepositAmount,
        securityDepositBeforeWaiveOff,
        securityDepositAfterWaiveOff,
        itemPriceBeforeCreditEligibility,
        percentage,
        upgradeOptionCode,
        categoryCode,
        itemPriceBeforeStrikeThrough,
        displayDiscountPrice,
      },
      plan: {},
      features: {},
      featuresTotal: featuresTotal,
      mea,
      min,
      mtnActive,
      mtnReservationDate,
      npa,
      nxx,
      mdn,
      porting: packages[key]?.portInInfo,
    };
    if (esimCapable != null && psimCapable != null) {
      devices[key].deviceFullDetail["esimCapable"] = esimCapable;
      devices[key].deviceFullDetail["psimCapable"] = psimCapable;
    }
    if (packages[key]["tradInInfo"]) {
      devices[key]["tradInInfo"] = packages[key]["tradInInfo"];
    }
    if (packages[key].plan && Object.keys(packages[key].plan).length) {
      let {
        pricePlanCode: billingCode,
        pricePlanDesc: b2bDisplayName,
        planPrice: monthlyFee,
        pricePlanCatCode: categoryCode,
        sharePlanLACAmount,
        sharePlanCategoryCode,
        sharePlanId,
        planPreviousPrice: previousMonthlyFee,
        offerApplied,
        autoPayPromotion,
        primaryCategory,
        bgsa: bgsa,
        sharedPlan: sharedPlan,
        offers,
        planDiscountAmount,
      } = packages[key].plan;
      devices[key].plan = {
        billingCode,
        b2bDisplayName,
        monthlyFee,
        displayName: b2bDisplayName,
        categoryCode,
        sharePlanLACAmount,
        sharePlanCategoryCode,
        sharePlanId,
        previousMonthlyFee,
        offerApplied,
        autoPayPromotion,
        primaryCategory,
        bgsa,
        sharedPlan,
        offers,
        planDiscountAmount,
      };
    }
    if (packages[key].priceType === "MONTH_TO_MONTH") {
      if (packages[key].displayDiscountPrice) {
        priceSummary.dueMonthly.devices =
          priceSummary.dueMonthly.devices +
          Number(packages[key].displayDiscountPrice.itemPrice);
      } else {
        priceSummary.dueMonthly.devices =
          priceSummary.dueMonthly.devices + Number(packages[key].itemPrice);
      }
      if (packages[key].downPayment && packages[key].downPayment > 0) {
        priceSummary.dueToday.devices += Number(packages[key].downPayment);
      }
      if (packages[key]["tradInInfo"]) {
        priceSummary.dueMonthly.deductions -=
          packages[key]["tradInInfo"].dueMonthlyDedutibleAmt || 0;
      }
    } else {
      priceSummary.dueToday.devices =
        priceSummary.dueToday.devices + Number(packages[key].itemPrice);
    }

    if (key.split("_")[0] !== "LINE") {
      if (packages[key].backOrder) {
        deviceTypeInCart = "BACK_ORDER";
      } else if (packages[key].preOrder) {
        deviceTypeInCart = "PRE_ORDER";
      } else {
        deviceTypeInCart = "NORMAL";
      }
    }

    if (packages[key].features && Object.keys(packages[key].features).length) {
      Object.keys(packages[key].features).forEach((feature) => {
        devices[key].features[feature] = {
          billingCode: packages[key].features[feature].visFeatureCode,
          name: packages[key].features[feature].featureDesc,
          bestPrice: packages[key].features[feature].featurePrice,
          level: packages[key].features[feature].level,
          spoCategoryCode: packages[key].features[feature].sfopackageGroupCode,
          ...packages[key].features[feature],
        };
      });
    }
    if (isDSP) {
      devices[key].deviceFullDetail["packageType"] = "DSP";
    }
  });
  return devices;
};

const routeToExistingCustomer = (route) => {
  let host = window.location.origin;
  let temp = route?.split(".com")[1];
  if (host.indexOf("vzwcorp.com") > -1 && temp) {
    route = host + temp;
  }
  window.location.href = route;
};

const setISPDetails = (shippingMethod, ISPUDetails, businessInfo) => {
  shippingMethod["storeName"] = ISPUDetails?.storeName;
  shippingMethod["address1"] = ISPUDetails?.address1;
  shippingMethod["city"] = ISPUDetails?.city;
  shippingMethod["state"] = ISPUDetails?.state;
  shippingMethod["zip"] = ISPUDetails?.zip;
  shippingMethod["phoneNumber"] = ISPUDetails?.phoneNumber;
  shippingMethod["storeHours"] = ISPUDetails?.storeHours;
  shippingMethod["pocName"] =
    businessInfo?.firstName + " " + businessInfo?.lastName;
};

const setNewCustomerModalFlag = (devices) => {
  let devicesKey = Object.keys(devices);
  if (devicesKey.length > 0)
    window.localStorage.setItem("addFirstItem", "true");
};

const setShippingMethod = (data) => {
  if (data.shipping && Object.keys(data.shipping).length > 0) {
    let shippingMethod = {};
    shippingMethod = { ...data.shipping };
    shippingMethod["shippingCostWithoutTax"] = +data.shipping.shippingCost;
    shippingMethod["shippingCost"] = +data.totalAmounts.shippingTotal;
    shippingMethod["shippingDescription"] = shippingMethod["shippingDesc"];
    setISPDetails(shippingMethod, data?.shipping?.ispuData, data?.businessInfo);
    delete shippingMethod["shippingDesc"];
    return shippingMethod;
  }
  return null;
};

const getSortedPackages = (pkgs, groupPkgs) => {
  let packageTypes = [];
  let packages = {};
  let groupPackages = [];
  if (
    pkgs &&
    groupPkgs &&
    Object.keys(pkgs).length &&
    Object.keys(groupPkgs).length
  ) {
    Object.keys(groupPkgs).forEach((element) => {
      const pkg = { ...groupPkgs[element] };
      groupPackages.push(pkg);
    });
    let tempPackages = { ...pkgs };
    groupPackages.forEach((element) => {
      for (let i = 1; i < element.packageIds.length; i++) {
        delete tempPackages[element.packageIds[i]];
      }
    });
    packages = { ...tempPackages };
  } else if (pkgs && Object.keys(pkgs).length) {
    packages = { ...pkgs };
    for (const key of Object.keys(packages)) {
      packageTypes.push(packages[key].packageType);
    }
  }
  window.sessionStorage.setItem("packageTypes", packageTypes);
  return packages;
};

const setStoreFromRetrieve = (resp) => {
  let priceSummary = JSON.parse(JSON.stringify(initialPriceSummary));
  let isDSP = Object.keys(resp?.prospectFlowData?.dspData || {}).length > 0;
  if (isDSP) {
    let { locationCode, outletId, billingState, billingCity } =
      resp?.prospectFlowData?.cart || {};
    let planObjectData = {
      locationCode: locationCode,
      outletId: outletId,
      billingState: billingState,
      billingCity: billingCity,
    };
    window.sessionStorage.setItem(
      "planObjectData",
      JSON.stringify(planObjectData)
    );

    let salesRepId = resp?.prospectFlowData?.salesRepId;
    let dspSource = resp?.prospectFlowData?.dspData?.vendorName;
    let dspMembershipNumber = resp?.prospectFlowData?.dspData?.partnerCode;
    let zipCode = resp?.prospectFlowData?.dspData?.zipCode;
    let dspMetaData = {
      salesRepId: salesRepId,
      dspSource: dspSource,
      dspMembershipNumber: dspMembershipNumber,
      zipCode: zipCode,
    };
    window.sessionStorage.setItem("dspMetaData", JSON.stringify(dspMetaData));
  }
  let devices =
    resp?.prospectFlowData?.cart.packages &&
    Object.keys(resp?.prospectFlowData?.cart.packages).length
      ? createDevices(
          resp?.prospectFlowData?.cart?.packages,
          priceSummary,
          "effects",
          isDSP
        )
      : {};
  let accessories =
    resp.response?.cart?.accessories &&
    Object.keys(resp?.prospectFlowData?.cart?.accessories).length
      ? createAccessories(
          resp?.prospectFlowData?.cart?.accessories,
          priceSummary,
          "effects"
        )
      : {};
  setNewCustomerModalFlag(devices);
  let businessInfo = null;
  let isAddressExist =
    (resp?.prospectFlowData?.businessInfo?.address?.addressLine1 || false) &&
    (resp?.prospectFlowData?.businessInfo?.address?.city || false);
  if (isAddressExist) {
    if (!resp?.prospectFlowData?.businessInfo?.address?.addressLine1) {
      resp["prospectFlowData"]["businessInfo"]["pocAddress"]["addressLine1"] =
        resp?.prospectFlowData?.businessInfo?.pocAddress?.streetNum +
        ", " +
        resp?.prospectFlowData?.businessInfo?.pocAddress?.streetName;
      resp["prospectFlowData"]["businessInfo"]["address"]["addressLine1"] =
        resp["prospectFlowData"]["businessInfo"]["pocAddress"]["addressLine1"];

      resp["prospectFlowData"]["businessInfo"]["address"]["city"] =
        resp?.prospectFlowData?.businessInfo?.pocAddress?.city;
      resp["prospectFlowData"]["businessInfo"]["address"]["state"] =
        resp?.prospectFlowData?.businessInfo?.pocAddress?.state || "";
    }
    businessInfo =
      resp.prospectFlowData?.businessInfo &&
      Object.keys(resp?.prospectFlowData?.businessInfo).length
        ? createBusinessInfo(resp?.prospectFlowData?.businessInfo)
        : {};
  } else if (!resp?.prospectFlowData?.businessInfo?.address?.addressLine1) {
    businessInfo =
      resp.prospectFlowData?.prospect5gLoopQualAddress &&
      Object.keys(resp.prospectFlowData?.prospect5gLoopQualAddress).length
        ? createBusinessInfoFromLoopQual(
            resp?.prospectFlowData?.prospect5gLoopQualAddress
          )
        : {};
  }

  if (resp?.prospectFlowData?.businessInfo?.address?.zipCode) {
    window.localStorage.setItem(
      "zipcodeFromBusiness",
      resp?.prospectFlowData?.businessInfo?.address?.zipCode || ""
    );
  }
  let outletId = resp?.prospectFlowData?.cart?.outletId || "";
  if (outletId !== "") {
    window.localStorage.setItem(
      "zipcode",
      resp?.prospectFlowData?.cart?.zipCodeFromPlan || ""
    );
    window.localStorage.setItem(
      "billingState",
      resp?.prospectFlowData.cart?.billingState || ""
    );
    window.localStorage.setItem(
      "billingCity",
      resp?.prospectFlowData.cart?.billingCity || ""
    );
    window.localStorage.setItem(
      "outletId",
      resp?.prospectFlowData.cart?.outletId || ""
    );
  }
  window.localStorage.setItem("customerModal", "true");
  priceSummary = addTradeInTotal(
    resp?.prospectFlowData?.totalAmounts,
    priceSummary
  );
  priceSummary.tax = {
    dueToday: resp?.prospectFlowData?.totalAmounts?.totalTaxAmount || 0,
    taxUpdateComplete:
      resp?.prospectFlowData?.totalAmounts?.totalTaxAmount > 0 ? true : false,
  };
  priceSummary.securityDeposit = resp?.prospectFlowData?.securityDeposit;

  // Code for BOVV-61501, shipping info pricing update
  let shippingMethod = setShippingMethod(resp?.prospectFlowData);
  let isShipping = shippingMethod && Object.keys(shippingMethod) ? true : null;
  let totalAmounts = { ...resp?.prospectFlowData?.totalAmounts };
  let e911Address = { ...(resp?.prospectFlowData?.e911Address || {}) };
  let securityDeposit = resp?.prospectFlowData?.securityDeposit || 0;
  let sharedCartId = resp?.prospectFlowData?.cart?.cartId || "";
  let listOfFileNames = resp?.prospectFlowData?.listOfFileNames || [];
  let uploadFileName = resp?.prospectFlowData?.uploadFileName || "";
  let mtnPageApplicable = resp.prospectFlowData?.cart.mtnPageApplicable;
  let groupOfferData = resp.prospectFlowData?.groupOffer || {};
  let groupPackages = resp.prospectFlowData?.cart?.groupPackages || {};
  return {
    apiResponse: resp,
    accessories,
    businessInfo,
    devices,
    e911Address,
    groupOfferData,
    groupPackages,
    isShipping,
    listOfFileNames,
    mtnPageApplicable,
    priceSummary,
    shippingMethod,
    totalAmounts,
    securityDeposit,
    sharedCartId,
    uploadFileName,
  };
};

const methods = {
  routeToExistingCustomer,
  setStoreFromRetrieve,
  getSortedPackages
};

export default methods;

export function loadScript(url){
  const scriptElem = document.createElement("script");
  scriptElem.type = "text/javascript";
  scriptElem.defer = true;
  scriptElem.src = url;
  document.body.appendChild(scriptElem);
}

export const base64ToJson = (base64String) => {
  const json = Buffer.from(base64String, "base64").toString();
  return JSON.parse(json);
}
