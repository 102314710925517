export const globalData = (state) => state.ProspectWidgets_GlobalData;
export const availableDevices = (state) =>
	state.ProspectWidgets_WirelessPlansData.availableDevices;
export const selectedDevices = (state) =>
	state.ProspectWidgets_WirelessPlansData.selectedDevices;
export const isSelectAllChecked = (state) =>
	state.ProspectWidgets_WirelessPlansData.isSelectAllChecked;
export const featuresSelectedDevices = (state) =>
	state.ProspectWidgets_WirelessPlansData.featuresSelectedDevices;
export const featuresAvailableDevices = (state) =>
	state.ProspectWidgets_WirelessPlansData.featuresAvailableDevices;
export const devicePlanList = (state) =>
	state.ProspectWidgets_WirelessPlansData.devicePlanList;
export const featuresDevicePlanList = (state) =>
	state.ProspectWidgets_WirelessPlansData.featuresDevicePlanList;
export const pageData = (state) => state.ProspectWidgets_WirelessPlansData;
export const mobilityPage = (state) => state.ProspectWidgets_MobilityData;
export const planFirst = (state) => state.PlanFirst;
export const zipCode = (state) => state.ProspectWidgets_MobilityData.zipCode;
export const isAllFeaturesSelectedOnRevisit = (state) =>
	state.ProspectWidgets_WirelessPlansData.isAllFeaturesSelectedOnRevisit;
