import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { TextLink } from "@vds3/buttons";
import { isTradeInOfferEligible } from "./util";
import { Notification } from "@vds3/notifications";

const StyledText = styled.div`
  font-size: 0.75rem !important;
  font-weight: 400 !important
`;

const TradeInValue = ({ skuDetails, tradeInValueInfo, pack, ...props }) => {
  const { t } = useTranslation(["pdp"]);
  const location = useLocation();
  const navigate = useNavigate();
  const tradeInEligible = isTradeInOfferEligible(tradeInValueInfo);
  const tradeInItem = tradeInValueInfo?.tradeInItems?.length
    ? tradeInValueInfo?.tradeInItems[0]
    : {};

  const getPriceAfterTrade = () => {
    const { nofInstallements, finalPrice } = skuDetails?.pricingDetails;
    let pricingNode = {};
    if (skuDetails?.priceType === "MONTH_TO_MONTH") {
      pricingNode = tradeInValueInfo?.pricing?.find(
        (i) => i.noOfInstallments === nofInstallements
      );
    } else {
      pricingNode = tradeInValueInfo?.pricing?.find(
        (i) => i.noOfInstallments === 1
      );
    }
    return Number.isSafeInteger(pricingNode?.price) 
      ? pricingNode?.price 
      : Number(pricingNode?.price).toFixed(2);
  };

  const goToTradeIn = () => {
    navigate("/shop/trade-in-new", {
      state: {
        isFromPDP: true,
        pdpUrl: location.pathname,
        pdpSkuDetails: skuDetails,
        tradeInItem,
        pack
      },
    });
  };

  const TradeInNotificationWrap = styled.div`    
    & > div > div {
      margin-top:24px;
    }
  `;

  return (
    <>
      <TradeInNotificationWrap>
        <Notification
          type={tradeInEligible ? "success" : "error"}
          title={<StyledText> {tradeInEligible
            ? t("device-pdp.moreWaysToSavingOptions.planDetails", {
              oldDeviceName: `${tradeInItem?.equipModel}`,
              newDeviceName: `${skuDetails?.brandName} ${skuDetails?.name}`,
              price: `${getPriceAfterTrade()}`,
            }) + " "
            : t("device-pdp.moreWaysToSavingOptions.ifNoPlan") + " "}
            <TextLink
              color="#000000"
              size="small"
              onClick={goToTradeIn}
            >
              {t("device-pdp.moreWaysToSavingOptions.editTradeIn")}
            </TextLink></StyledText>}
          fullBleed={false}
          inline={false}
          disableFocus={true}
          layout={"vertical"}
        />
      </TradeInNotificationWrap>
    </>
  );
};

export default TradeInValue;
