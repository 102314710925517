import styled from "styled-components";
import { spacers } from "@vds-core/theme";
import { LayoutTokens } from "@vds-tokens/layout";
import { Col } from "@vds3/grids";

// import { Col, Row } from '@vds/grids';

export const MarginSpacer = styled.div`
	margin-bottom: ${LayoutTokens.space["4X"].value};
`;
export const MarginSpacerXL = styled.div`
	margin-bottom: ${LayoutTokens.space["8X"].value} !important;
`;
export const MarginSpacer2XL = styled.div`
	margin-bottom: ${LayoutTokens.space["12X"].value} !important;
`;
export const MarginSpacerL = styled.div`
	margin-bottom: ${LayoutTokens.space["6X"].value} !important;
`;
export const MarginSpacerS = styled.div`
	margin-bottom: ${LayoutTokens.space["2X"].value} !important;
`;
export const MarginSpacerM = styled.div`
	margin-bottom: ${LayoutTokens.space["4X"].value} !important;
`;
export const MarginSpacerXS = styled.div`
	margin-bottom: ${LayoutTokens.space["1X"].value} !important;
`;
export const MarginTopSpacerL = styled.div`
	margin-top: ${LayoutTokens.space["6X"].value} !important;
`;
export const MarginTopSpacerS = styled.div`
	margin-top: ${LayoutTokens.space["2X"].value} !important;
`;
export const MarginTopSpacerM = styled.div`
	margin-top: ${LayoutTokens.space["4X"].value} !important;
`;
export const MarginSpacerRightM = styled.span`
	margin-right: ${LayoutTokens.space["4X"].value} !important;
`;
export const MarginSpacerNoLabel = styled.div`
	margin-bottom: ${spacers.large} !important;
	margin-top: 12px;
`;
export const RowFlexDiv = styled.div`
	display: flex;
	flex-direction: row;
`;
// export const CustomCol = styled(Col)`
// 	padding-left: 0px;
// 	padding-right: 0.8rem;
// `;
// export const CustomRow = styled(Row)`
// 	margin-left: 0px;
// 	margin-right: 0px;
// `;
export const PhonePlanWrapper = styled.div`
	height: 130px;
`;
export const PhoneTextWrapper = styled.div`
	min-height: 100px;
`;
export const ProductContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: ${spacers.medium};
`;
export const SmallTileWrapper = styled.div`
	height: 40px;
`;
export const CheckoutGrid = styled.div`
	margin-top: ${spacers.large} !important;
`;
export const OwnerShipContainer = styled.div`
	background: #f6f6f6;
	padding-top: 20px;
`;
export const Calendarsection = styled.div`
	display: block;
	position: relative;
`;
export const CheckoutContainer = styled.div`
	margin-top: 50px;
	margin-left: 10px;
`;
export const Label = styled.label`
	cursor: pointer;
`;
export const CustomRadio = styled.div`
 	width: 1.25rem;
 	height:1.25rem
 	border-radius: 50%;
 	border: 1px solid #000; 
 	display:flex;
 	justify-content:center;
 	align-items:center;
 	cursor:pointer;
 	span.selected {
    	width: 0.5rem;height:0.5rem;
    	background-color:#000;  
    	border-radius: 50%;
 	}
`;
export const Container = styled.div`
	padding: 0.75rem 0.75rem;
	// position: -webkit-sticky;
	// position: sticky;
	top: 1rem;
	border-left: 1px solid #aeaeaea1;
	border-right: 1px solid #aeaeaea1;
	border-bottom: 1px solid #aeaeaea1;
	.title {
		margin-bottom: 1rem;
	}
	.header {
		display: flex;
		justify-content: space-between;
		// text-align: center;
	}
	.noSidePadding {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.products {
		.product {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			margin: 1rem 0;
			padding: 0.5rem;
			width: 100%;
			.collapse {
				width: 100%;
			}
			.product-name {
				flex: 1;
			}
			.product-total-monthly {
				flex: 1;
				text-align: right;
			}
			.product-total-today {
				flex: 1;
				text-align: right;
			}
			.product-details {
				width: 100%;
				.product-detail {
					display: flex;
					flex-flow: row wrap;
					justify-content: space-between;
					margin: 1rem 0;
					.product-image {
						width: 100%;
						margin: 0.5rem 0;
					}
					.product-name {
						flex: 1;
					}
					.product-monthly {
						flex: 1;
						text-align: right;
					}
					.product-today {
						flex: 1;
						text-align: right;
					}
				}
			}
		}
	}
`;
export const ColNoPadding = styled(Col)`
	@media screen and (max-width: 540px) {
		${({ isNoPadding }) =>
			isNoPadding &&
			`padding-left: 0;
       padding-right: 0;`}
	}
`;
export const ColNoPad = styled(Col)`
	padding-right: 0;
	padding-left: 0;
	padding-top: ${({ padTop }) => (padTop ? padTop : "")};
	margin-left: ${({ padTop }) => (padTop ? "10px" : "")};
	@media screen and (max-width: 1100px) {
		margin-bottom: ${({ buttonData }) =>
			buttonData ? "10px !important" : "auto"};
		display: ${({ buttonDataDisplay }) =>
			buttonDataDisplay ? "none !important" : "auto"};
	}
	.width-fix {
		width: 590px;
	}
	.remove_link {
		font-size: 0.75rem !important;
	}
`;
export const MarginSpacerRightXS = styled.span`
  margin-right: ${LayoutTokens.space['1X'].value} !important;
`;
