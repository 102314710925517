import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { TextLinkCaret } from "@vds3/buttons";
import { Notification } from "@vds3/notifications";
import { Title } from "@vds3/typography";
import PackageTile from "../CartPackage/PackageTile";
import SavingsNotification from "../Notifications/SavingsNotification";
import CartContext from "pages/ShoppingCart/cartContext";
import { getSavingsData } from "pages/ShoppingCart/util";
import {
  setIsEditPackageFlow,
} from "app/ducks/GlobalSlice";
import { useDispatch } from "react-redux";
import { MarginSpacerM } from "common/components/styleTag";
import { AccountLevelFeatureTile } from "../AccountLevelFeatureTile";
// import { setFilterSelected } from "pages/Mobility/ducks/MobilitySlice";
import { TileContent } from "../Modals/OneTalk5G";

const CartDetailsSection = ({
  type,
  packages,
  isEditable,
  showSavings,
  starkEnabled,
  ...props
}) => {
  const {
    accessoriesCount,
    allowedAccessoriesCount,
    cartData,
    cartProperties,
    isDSP,
    serviceProvider,
    setMaximumLimitReachedAccessories,
    setMaxDeviceModal,
    isOneTalkFlow,
    oneTalkFlowAdded,
    selectedPack,
    fromBUSS
  } = React.useContext(CartContext);
  const { t } = useTranslation(["shoppingCart"]);
  // const navigate = useNavigate();
  const TITLES = t("shopping-cart.sectionHeadings", { returnObjects: true });
  const enableCTASection =
    window?.mbtGlobal?.flags?.ENABLE_VDS3_SHOPPINGCART_MOBILITY_EDIT === "Y"
      ? true
      : false;
  const cart = cartData?.cart;
  const visFeatureCode = t("shopping-cart.vis-feature-code.featureCode", {
    returnObjects: true,
  });
  const visFeatureCodeEdit = (code) =>
    visFeatureCode?.length && visFeatureCode?.includes(code);
  const savingsData = useMemo(() => {
    const savingsAemData = t("shopping-cart.savings-notification", {
      returnObjects: true,
    });
    return getSavingsData(cart, savingsAemData);
  }, [cartData]);

  const getCTASection = () => {
    switch (type) {
      case "REGULAR":
        return (
          <MobilityCTAs
            accessoriesCount={accessoriesCount}
            allowedAccessoriesCount={allowedAccessoriesCount}
            isDSP={isDSP}
            packages={packages}
            cartPackages={cart?.packages}
            serviceProvider={serviceProvider}
            setMaximumLimitReachedAccessories={
              setMaximumLimitReachedAccessories
            }
            setMaxDeviceModal={setMaxDeviceModal}
          />
        );
      case "ONETALK":
        return <OneTalkCTAs />;
      default:
        return false;
    }
  };
  
  return (
    <div style={{ marginTop: "1.5rem" }}>
      <Title size="medium">{TITLES[type]}</Title>
      <MarginSpacerM />
      {oneTalkFlowAdded&&props.firstIndex ? <>
      <Notification
          type="success"
          inline={true}
          fullBleed={true}
          title={t(`mixed-cart.${selectedPack[0]}.notification-content`)}
        />
        <MarginSpacerM/></>:<></>}
      {!!showSavings &&
        cart?.unifiedCart &&
        TITLES[type]?.includes("Wireless") && (
          <SavingsNotification savingsData={savingsData} />
        )}
      {cartData?.cart?.unifiedCart &&
        TITLES[type]?.includes("Wireless") &&
        !!cartProperties?.AccountLevelFeatures?.length &&
        cartProperties?.AccountLevelFeatures.filter(
          (feature) => feature?.displaySection !== "CartPromotion"
        ).map((feature) => (
          <AccountLevelFeatureTile
            key={feature?.visFeatureCode}
            feature={feature}
            isEditable={visFeatureCodeEdit(feature?.visFeatureCode)}
            onEditClick={() => {
              //navigate("/shop/features");
            }}
          />
        ))}
      {packages.map((pack, index) => {
        return (
          (!pack?.emptyByodFromDQ || pack?.byor) && (
            <PackageTile
              key={pack?.packageId}
              pack={{ ...pack }}
              isEditable={isEditable}
              starkEnabled={starkEnabled}
              fromBUSS = {fromBUSS}
            />
          )
        );
      })}
      {packages
        ?.filter((pack) => !!pack?.emptyByodFromDQ && !pack?.byor)
        ?.map((pack, index) => {
          return (
            pack?.emptyByodFromDQ &&
            !pack?.byor && (
              <>
                {index === 0 && enableCTASection && (
                  <>
                    <MarginSpacerM />
                    <Notification
                      type="info"
                      title={t(
                        "shopping-cart.cart-byod.emptyDQNotification.title"
                      )}
                      subtitle={t(
                        "shopping-cart.cart-byod.emptyDQNotification.message"
                      )}
                    />
                  </>
                )}
                <PackageTile
                  key={pack?.packageId}
                  pack={{ ...pack }}
                  isEditable={isEditable}
                  showRightSection={!enableCTASection}
                  starkEnabled={starkEnabled}
                  fromBUSS = {fromBUSS}
                />
              </>
            )
          );
        })}
      {/* disabling until upper funnel is ready */}
      {enableCTASection&&(!isOneTalkFlow&&!oneTalkFlowAdded) && getCTASection()}
      {false && props.lastIndex&&isOneTalkFlow ? <TileContent/> : <></>}
    </div>
  );
};

const CartDetailsCTAs = ({ links }) => {
  return (
    <CTAContainer>
      {links.map((link) => (
        <TextLinkCaret
          surface="light"
          disabled={false}
          iconPosition="right"
          onClick={link.onClick}
        >
          {link.text}
        </TextLinkCaret>
      ))}
    </CTAContainer>
  );
};

const MobilityCTAs = ({
  accessoriesCount,
  allowedAccessoriesCount,
  isDSP,
  packages,
  serviceProvider,
  setMaximumLimitReachedAccessories,
  setMaxDeviceModal,
  cartPackages
}) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  let navigationPath = "/shop/products/devices/all";
  const onAddMore = () => {
    // dispatch(
    //   setFilterSelected({
    //     paymentList: [
    //       {
    //         key: "Device Payment",
    //       },
    //     ],
    //   })
    // );
    if (packages && isDSP && serviceProvider) {
      navigationPath = `/shop/dsp/${serviceProvider}/devices`;
    }
    //  else if (packages && packages.find(d => d.deviceCategory === "Smartphones")) {
    //   navigationPath = "/shop/products/devices/smartphones";
    // } else if (packages && packages.find(d => d.deviceCategory === "Tablets")) {
    //   navigationPath = "/shop/products/devices/tablets";
    // } else if (packages && !!packages.find(d => d.deviceCategory === "Smart Watches")) {
    //   navigationPath = "/shop/products/devices/smart-watches";
    // }
    //navigate(navigationPath);
  };

  const onAddAccessories = () => {
    if (accessoriesCount < allowedAccessoriesCount || accessoriesCount === 0) {
      //navigate("/shop/accessories");
    } else {
      setMaximumLimitReachedAccessories(true);
    }
  };

  return (
    <CartDetailsCTAs
      links={[
        {
          text: "Add more devices",
          onClick: () => {
            if (cartPackages && Object.keys(cartPackages)?.length >= 9) {
              setMaxDeviceModal(true);
            } else {
              onAddMore();
            }
          },
        },
        {
          text: "Add accessories",
          onClick: () => onAddAccessories(),
        },
        {
          text: "Bring your own device",
          onClick: () => {
            if (cartPackages && Object.keys(cartPackages)?.length >= 9) {
              setMaxDeviceModal(true);
            } else if (packages && isDSP && serviceProvider) {
              //navigate(`/shop/dsp/${serviceProvider}/byod`);
            } else {
              dispatch(setIsEditPackageFlow(true));
             // navigate(`/shop/products/devices/smartphones/byod`);
            }
          },
        },
      ]}
    />
  );
};

const OneTalkCTAs = () => {
  // const navigate = useNavigate();
  return (
    <CartDetailsCTAs
      links={[
        {
          text: "Add desk phones",
          onClick: () =>
            {}
        },
      ]}
    />
  );
};

const CTAContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem 4rem;
  margin-top: 1.5rem;
  padding-bottom: 0.75rem;
`;
const NotificationWrapper = styled(Notification)`
p{
  font-weight:normal !important;
}
`
export default CartDetailsSection;
