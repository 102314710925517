import styled from "styled-components";

export const StyledFooterContainer = styled.div`
  footer {
    padding-top: 0 !important;
  }
  button {
    max-width: 21% !important;
  }
  @media only screen and (max-width: 545px) {
    button {
      max-width: 100% !important;
    }
  }
`;