import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { Body, Title } from "@vds3/typography";
import { MarginSpacerL, MarginSpacerS } from "/common/components/styleTag";


const txtStyle = {
  width: "808px",
  height: "40px"
}

const txtStyle1 = {
  width: "620px",
  height: "40px"
}

const IncludedInOrder = ({ heading, content, showAll, isfromAuth, isCheckoutNew, ...props }) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 545px)",
  });
  const displayContent = showAll ? content : content?.slice(0, 1);
  const numOfCols = isMobile ? 1 : Math.min(Math.max(displayContent?.length, 4), 4);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Title size="medium" bold>
        {heading}
      </Title>
      <MarginSpacerL />
      <IncludedContainer numOfCols={numOfCols}>
        {displayContent?.map((item) => (
          <IncludedItem key={item.title}>
            <Body size="large" bold>
              {item?.title}
            </Body>
            <MarginSpacerS />
            <div style= {isfromAuth && isCheckoutNew ? txtStyle1 : isfromAuth ? txtStyle : {}}>
            <Body size="large" color="#6F7171">{item?.description}</Body>
            </div>
            
          </IncludedItem>
        ))}
      </IncludedContainer>
    </div>
  );
};

const IncludedContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${({ numOfCols }) => numOfCols},
    minmax(158px, 1fr)
  );
  grid-gap: 10px;
  row-gap: 32px;
  padding: 0;
  // min-height: 204px;
`;

const IncludedItem = styled.div``;

export default IncludedInOrder;
