import React,{useCallback, useEffect} from 'react'
import { Body, Micro } from "@vds3/typography";
import { MarginSpacer2XL, MarginSpacerL, MarginSpacerM, MarginSpacerS, MarginSpacerXL } from 'common/components/styleTag';
import { RadioBoxGroup } from "@vds3/radio-boxes";
import { Button, TextLink } from "@vds3/buttons";
import { Icon } from "@vds3/icons";
import { Line } from "@vds3/lines";
import { Common, DueMonthly, DueToday, SSN } from './Common';
import styled from 'styled-components';
import { Notification } from '@vds3/notifications';

const CreditResultSSN = ({showNotificationErrorFromCR=fale,hideNotification=()=>{},errorTextCr="",showZipcodeContent,aemData,creditStatus,creditCheckResult,cartData,showCartOnly,onContinueClick,replaceNewDevice=()=>{},sessionCartData,setNoificationFalse=()=>{},cleStandaloneHeading=false}) => {
    const {dppToFrp,creditLimitExceeded,ssn,blended,bizOnly,dba,bizOnlyHold,blendedHold,dbaHold,isCreditHoldNeeded,creditApproved} = creditStatus
    const [showDue, setShowDue] = React.useState(false)
    const [selectedValue,setSelectedValue] = React.useState(' ')
    const [ssnValue,setSSNValue] = React.useState('')
    const [refundableDeposit,setRefundableDeposit] = React.useState(0)
    const dueToday = (creditCheckResult?.mainCartData?.cart?.displayTotalAmounts?.dueToday ? creditCheckResult?.mainCartData?.cart?.displayTotalAmounts?.dueToday : creditCheckResult?.mainCartData?.dueToday ? Number(creditCheckResult?.mainCartData?.dueToday).toFixed(2) : "0.00")
    const dueMonthly = (creditCheckResult?.mainCartData?.cart?.displayTotalAmounts?.dueMonthly ? creditCheckResult?.mainCartData?.cart?.displayTotalAmounts?.dueMonthly : creditCheckResult?.mainCartData?.dueMonthly ? Number(creditCheckResult?.mainCartData?.dueMonthly).toFixed(2) : "0.00")
    const tax = Number(creditCheckResult?.mainCartData?.cart?.displayTotalAmounts?.totalTaxAmount ?? creditCheckResult?.mainCartData?.cart?.taxDetails?.totalTaxAmount).toFixed(2)
    const [downPayment,setDownPayment] = React.useState(Number(creditCheckResult?.mainCartData?.cart?.displayTotalAmounts?.downPayment ?? creditCheckResult?.mainCartData?.cart?.totalAmounts?.downPayment)?.toFixed(2))
    const amountAfterDownPayment = Number(creditCheckResult?.mainCartData?.cart?.totalAmounts?.amountSavedMonthlyAfterDownPayment)?.toFixed(2)
    const [checkboxChecked,setCheckboxClicked] = React.useState(false)
    const [showNotificationError,setShowNotificationError] = React.useState(false)
    const [ssnError, setSSNError] = React.useState(false)
    const [cleForBlended,setCleForBlended] = React.useState(false)
    const [dppToFrpPresent,setDppToFrpPresent] = React.useState(false)
    useEffect(()=>{
        if(creditCheckResult?.mainCartData?.cart?.totalAmounts?.downPayment && downPayment !== Number(creditCheckResult?.mainCartData?.cart?.totalAmounts?.downPayment)?.toFixed(2))
        setDownPayment(Number(creditCheckResult?.mainCartData?.cart?.displayTotalAmounts?.downPayment ?? creditCheckResult?.mainCartData?.cart?.totalAmounts?.downPayment)?.toFixed(2))
    },[creditCheckResult])
    useEffect(()=>{
        const val = String(selectedValue).trim()
        if(!!val && !!creditCheckResult[val] && Object.keys(creditCheckResult[val]).length && String(creditCheckResult[val]['result']).toLowerCase().includes("securitydeposit")){
            setDownPayment(Number(creditCheckResult[val]["cart"]["displayTotalAmounts"]['downPayment'] ?? creditCheckResult[val]['cart']['totalAmounts']['downPayment'])?.toFixed(2))
        }else{
            setCleForBlended(false)
            setDownPayment(Number(creditCheckResult?.mainCartData?.cart?.displayTotalAmounts?.downPayment ?? creditCheckResult?.mainCartData?.cart?.totalAmounts?.downPayment)?.toFixed(2))
        }
        if(!!val && !!creditCheckResult[val] && Object.keys(creditCheckResult[val]).length && String(creditCheckResult[val]['result']).toLowerCase().includes("dpptofrp")){
            setDppToFrpPresent(true)
        }else{
            setDppToFrpPresent(false)
        }
    },[selectedValue])
    const disabledButton = ()=>{
        let disabled = false
        if(selectedValue === 'SSN' && (((ssnValue === null || ssnValue === undefined || ssnValue === '' || ssnValue.length < 9)))) disabled = true
        return disabled
    }
    React.useEffect(()=>{
        let data = 0
        if(cartData!==null&&cartData){
            const { packages } = cartData
            if(packages!==null&&packages){
                Object.values(packages).map((datum)=>{
                    data += datum['securityDepositBeforeWaiveOff']
                })
            }
        }
        setRefundableDeposit(data)
    },[cartData])
    const valueSelected = String(selectedValue).trim()
    const blendedData = getBlendedData({aemData,selectedValue,dueToday,dueMonthly,...creditStatus,downPayment,amountAfterDownPayment,creditCheckResult})
    const ssnData = getSSNData({aemData,selectedValue,dueToday,dueMonthly,downPayment,amountAfterDownPayment,...creditStatus,creditCheckResult})
    const creditLimitExceededRadioOption = creditLimitOption({cartData,dppToFrp,dueToday,downPayment,amountAfterDownPayment})
    const onContinueClickLocal = ()=>{
        if(selectedValue !== 'SSN'){
            onContinueClick(selectedValue)
            setSelectedValue(' ')
        }
        else {
        if(disabledButton()){
            setSSNError(true)
            return 
        }else setSSNError(false)
        if(selectedValue === 'SSN' &&  !checkboxChecked){
            setShowNotificationError(true)
            return
        }else{
        setShowNotificationError(false)
        setSelectedValue(' ')
        onContinueClick(selectedValue,ssnValue)
        }
    }
    }
    const cartDetailsKey = selectedValue === "BizOnlyCredit" ? ' for EIN' : (selectedValue === 'BLENDED' || selectedValue === 'SSN') ? ' with EIN and SSN' :
    selectedValue === "DBA" ? ' for SSN' : ''
    return (
        <>
        {getTitle({sessionCartData,showZipcodeContent,aemData,isCreditHoldNeeded,creditLimitExceeded,creditApproved,showCartOnly,...creditStatus,cleStandaloneHeading})}
        {showNotificationErrorFromCR ?
        <MarginSpacerXL>
          <Notification type="error" title={errorTextCr}
            onCloseButtonClick={()=>hideNotification(false)}
            fullBleed={false} inlineTreatment={false} /> 
            </MarginSpacerXL>
            : <MarginSpacerL/>}
        {(!showCartOnly&&!creditApproved && (blended || bizOnly || dba || creditLimitExceeded || dppToFrp || ssn)) ? 
            <RadioBoxGroupWrapper
                defaultValue={selectedValue}
                value={selectedValue}
                childWidth={'100%'}
                viewport={'desktop'}
                rowQuantity={{
                    mobile: 1,
                    tablet: 2,
                    desktop: 2,
                }}
                data={(blended || bizOnly || dba ) ? blendedData : ssn ? ssnData : (creditLimitExceeded || dppToFrp) ? creditLimitExceededRadioOption : ""}
                onChange = {(e)=>{
                    setNoificationFalse(false)
                    setShowDue(false)
                    setSelectedValue(e.target.value)}}
            />:<></>}
            <MarginSpacerL />
            {(((selectedValue!==null&&selectedValue&&selectedValue!==' ')|| (showCartOnly || creditApproved))&&selectedValue !== "SSN") ? 
            <>
            <TextLink onClick={() => setShowDue(!showDue)}>{`Cart details ${cartDetailsKey}` }  <Icon size="small" name={showDue ? "up-caret" : "down-caret" } /> </TextLink>
            <MarginSpacerL />
            </>:<></>}
            {showDue && selectedValue !== "SSN" && ((selectedValue!==null&&selectedValue&&selectedValue!==' ')|| (showCartOnly || creditApproved || creditLimitExceeded)) &&
                <>
                    <DueToday creditCheckResult={creditCheckResult} selectedValue={selectedValue} creditApproved = {showCartOnly || creditApproved} creditLimitExceeded={creditLimitExceeded || cleForBlended} cartData={sessionCartData} dppToFrp={dppToFrpPresent || dppToFrp } 
                    dueToday={!!valueSelected && !!creditCheckResult[valueSelected] && Object.keys(creditCheckResult[valueSelected]).length &&
                        !!creditCheckResult[valueSelected]["cart"]["displayTotalAmounts"]&& Object.keys(creditCheckResult[valueSelected]["cart"]["displayTotalAmounts"]).length ? creditCheckResult[valueSelected]["cart"]["displayTotalAmounts"]['dueToday'] :
                        !!valueSelected && !!creditCheckResult[valueSelected] && Object.keys(creditCheckResult[valueSelected]).length && creditCheckResult[valueSelected]['dueToday'] ? creditCheckResult[valueSelected]['dueToday'] : dueToday} 
                    securityDeposit = {sessionCartData?.securityDeposit} tax = {!isNaN(tax) ? tax : '0.00'} 
                    downPayment={!!valueSelected && !!creditCheckResult[valueSelected] && Object.keys(creditCheckResult[valueSelected]).length &&
                        !!creditCheckResult[valueSelected]["cart"]["displayTotalAmounts"]&& Object.keys(creditCheckResult[valueSelected]["cart"]["displayTotalAmounts"]).length ? creditCheckResult[valueSelected]["cart"]["displayTotalAmounts"]['downPayment'] :
                        !!valueSelected && !!creditCheckResult[valueSelected] && Object.keys(creditCheckResult[valueSelected]).length && creditCheckResult[valueSelected]['downPayment'] ? creditCheckResult[valueSelected]['downPayment'] : downPayment} 
                    refundableDeposit={refundableDeposit} showCartOnly={showCartOnly}/>
                    {/* <MarginSpacerL /> */}
                    {/* <Line type="secondary" /> */}
                    <MarginSpacerM />
                    <DueMonthly creditCheckResult={creditCheckResult} selectedValue={selectedValue}
                    dueMonthly = {!!valueSelected && !!creditCheckResult[valueSelected] && Object.keys(creditCheckResult[valueSelected]).length &&
                        !!creditCheckResult[valueSelected]["cart"]["displayTotalAmounts"]&& Object.keys(creditCheckResult[valueSelected]["cart"]["displayTotalAmounts"]).length ? creditCheckResult[valueSelected]["cart"]["displayTotalAmounts"]['dueMonthly'] :
                        !!valueSelected && !!creditCheckResult[valueSelected] && Object.keys(creditCheckResult[valueSelected]).length && creditCheckResult[valueSelected]['dueMonthly'] ? creditCheckResult[valueSelected]['dueMonthly'] : dueMonthly} 
                    cartData={cartData} sessionCartData={sessionCartData}/>
                </>}
            {(showCartOnly || creditApproved) ? <ButtonWrapper data-track={`Business Verification:Credit results:${aemData['ContinueCTA']}`} onClick={()=>{onContinueClick(selectedValue,ssnValue)}}>{aemData['ContinueCTA']}</ButtonWrapper> : <>
            {selectedValue === 'SSN' ? <SSN value={ssnValue} changedInput={val=>{
                if(val.length === 9) setSSNError(false)
                setSSNValue(val)
                }} checkboxCliked={val=>{
                    if(val) setShowNotificationError(false)
                    setCheckboxClicked(val)
                }} showNotificationError={showNotificationError} ssnError={ssnError}/>:<></>}
            {(selectedValue !== 'CHOOSE_ANOTHER' && selectedValue !== 'BYOD') ?  <ButtonWrapper data-track={`Business Verification:Credit results:${aemData['ContinueCTA']}`} onClick={onContinueClickLocal}>{aemData['ContinueCTA']}</ButtonWrapper>:<></>}
            </>}
        </>
    )
}
const getBlendedData = ({aemData,selectedValue,dueToday,dueMonthly,blended,bizOnly,dba,isCreditHoldNeeded,creditLimitExceeded,dppToFrp,downPayment,amountAfterDownPayment,creditCheckResult})=>{
    const {BLENDED,BizOnlyCredit,DBA} = creditCheckResult
    const downPaymentBiz = (!(dppToFrp || String(BizOnlyCredit.result).toLowerCase().includes("dpptofrp"))) ? (Number(BizOnlyCredit?.cart?.displayTotalAmounts?.downPayment ?? BizOnlyCredit?.cart?.totalAmounts?.downPayment)?.toFixed(2)) :downPayment
    const downPaymentBlended = (!(dppToFrp || String(BLENDED.result).toLowerCase().includes("dpptofrp"))) ? Number(BLENDED?.cart?.displayTotalAmounts?.downPayment ?? BLENDED?.cart?.totalAmounts?.downPayment)?.toFixed(2) : downPayment
    const downPaymentDBA = (!(dppToFrp || String(DBA.result).toLowerCase().includes("dpptofrp"))) ? Number(DBA?.cart?.displayTotalAmounts?.downPayment ?? DBA?.cart?.totalAmounts?.downPayment)?.toFixed(2) : downPayment
    const amountAfterDownPaymentBiz = (!(dppToFrp || String(BizOnlyCredit.result).toLowerCase().includes("dpptofrp"))) ? Number(BizOnlyCredit?.cart?.totalAmounts?.amountSavedMonthlyAfterDownPayment)?.toFixed(2) : amountAfterDownPayment
    const amountAfterDownPaymentBlended = (!(dppToFrp || String(BLENDED.result).toLowerCase().includes("dpptofrp"))) ? Number(BLENDED?.cart?.totalAmounts?.amountSavedMonthlyAfterDownPayment)?.toFixed(2) : amountAfterDownPayment
    const amountAfterDownPaymentDBA = (!(dppToFrp || String(DBA.result).toLowerCase().includes("dpptofrp"))) ? Number(DBA?.cart?.totalAmounts?.amountSavedMonthlyAfterDownPayment)?.toFixed(2) : amountAfterDownPayment
    const dueTodayBiz = (bizOnly && !!BizOnlyCredit?.dueToday) ? Number(BizOnlyCredit?.cart?.displayTotalAmounts?.dueToday ?? BizOnlyCredit?.dueToday)?.toFixed(2) : dueToday
    const dueTodayBlended = (blended && !!BLENDED?.dueToday) ? Number(BLENDED?.cart?.displayTotalAmounts?.dueToday ?? BLENDED?.dueToday)?.toFixed(2) : dueToday
    const dueTodayDBA = (dba && !!DBA?.dueToday) ? Number(DBA?.cart?.displayTotalAmounts?.dueToday ?? DBA?.dueToday)?.toFixed(2) : dueToday
    const dueMonthlyBiz = (bizOnly && !!BizOnlyCredit?.dueMonthly) ? Number(BizOnlyCredit?.cart?.displayTotalAmounts?.dueMonthly ?? BizOnlyCredit?.dueMonthly)?.toFixed(2) : dueMonthly
    const dueMonthlyBlended = (blended && !!BLENDED?.dueMonthly) ? Number(BLENDED?.cart?.displayTotalAmounts?.dueMonthly ?? BLENDED?.dueMonthly)?.toFixed(2) : dueMonthly
    const dueMonthlyDBA = (dba && !!DBA?.dueMonthly) ? Number(DBA?.cart?.displayTotalAmounts?.dueMonthly ?? DBA?.dueMonthly)?.toFixed(2) : dueMonthly

    const data = [
        {
            id: 'BizOnlyCredit',
            value: 'BizOnlyCredit',
            text: <div className='hei-adj-title'>{aemData['options']['bizCredit']}</div>,
            type: 'radiobox',
            width: '48%',
            selected:selectedValue === 'BizOnlyCredit',
            subtext: (isCreditHoldNeeded || BizOnlyCredit.isHold) ?
            <>
                <MarginSpacerS/>
                <Body size="large">{aemData['options']['hold']["subtext1"]}</Body>
                <MarginSpacerS />
                <Body size="large">{aemData['options']['hold']["subText2"]}</Body>
            </>
            :<>
                <MarginSpacerS/>
                <div className='hei-adjuster'>
                <MarginSpacerS/>
                {(dppToFrp || String(BizOnlyCredit.result).toLowerCase().includes("dpptofrp")) ? 
                <Body size="large">{aemData['options']['non-hold']["subtext2"]}</Body>
                :
                (creditLimitExceeded || BizOnlyCredit?.cart?.spendingLimitExceeded) ?
                <>
                <Body size="large">{String(aemData['options']['creditLimitExceededText']).replace("{{downPayment}}",downPaymentBiz).replace("{{payment}}",amountAfterDownPaymentBiz)}</Body>
                <MarginSpacerS/>
                <Body size="large">{aemData['options']['deviceOption']}</Body>
                </> :<></>
                }
                </div>
                <Common titleBold = {true} title="Due today*" data={ dueTodayBiz} subText='Incl. est. taxes; fees apply.'/>
                <Common titleBold = {true} title="Due monthly*" data={ dueMonthlyBiz} month = {"/mo"} subText={"Monthly surcharges, taxes and fees apply."}/>
            </>,
        },
        {
            id: 'BLENDED',
            value: 'BLENDED',
            text: <div className='hei-adj-title'>{aemData['options']['blended']}</div>,
            type: 'radiobox',
            width: '48%',
            selected:selectedValue === 'BLENDED',
            subtext: (isCreditHoldNeeded || BLENDED.isHold) ? 
            <>
                <MarginSpacerS/>
                <Body size="large">{aemData['options']['hold']["subtext1"]}</Body>
                <MarginSpacerS />
                <Body size="large">{aemData['options']['hold']["subText2"]}</Body>
            </>
            :<>
                <MarginSpacerS/>
                <div className='hei-adjuster'>
                <Body size='large'>{aemData['options']['hold']["subtext1"]}</Body>
                <MarginSpacerS/>
                {(dppToFrp || String(BLENDED.result).toLowerCase().includes("dpptofrp")) ? 
                <Body size="large">{aemData['options']['non-hold']["subtext2"]}</Body>
                :
                (creditLimitExceeded || BLENDED?.cart?.spendingLimitExceeded) ? 
                <>
                <Body size="large">{String(aemData['options']['creditLimitExceededText']).replace("{{downPayment}}",downPaymentBlended).replace("{{payment}}",amountAfterDownPaymentBlended)}</Body>
                <MarginSpacerS/>
                <Body size="large">{aemData['options']['deviceOption']}</Body>
                </> :<></>
                }
                </div>
                <Common titleBold = {true} title="Due today*" data={ dueTodayBlended} subText='Incl. est. taxes; fees apply.'/>
                <Common titleBold = {true} title="Due monthly*" data={ dueMonthlyBlended} month = {"/mo"} subText={"Monthly surcharges, taxes and fees apply."}/>
            </>,
        },
        {
            id: 'DBA',
            value: 'DBA',
            text: <div className='hei-adj-title'>{aemData['options']['dba']}</div>,
            type: 'radiobox',
            width: '48%',
            selected:selectedValue === 'DBA',
            subtext: (isCreditHoldNeeded || DBA.isHold) ? 
            <>
                <MarginSpacerS/>
                <Body size="large">{aemData['options']['non-hold']["subtext1"]}</Body>
                <MarginSpacerS />
                <Body size="large">{aemData['options']['hold']["subText2"]}</Body>
            </>
            : <>
                <MarginSpacerS/>
                <div className='hei-adjuster'>
                <Body size='large'>{aemData['options']['non-hold']["subtext1"]}</Body>
                <MarginSpacerS/>
                {(dppToFrp || String(DBA.result).toLowerCase().includes("dpptofrp")) ? 
                <Body size="large">{aemData['options']['non-hold']["subtext2"]}</Body>
                :
                (creditLimitExceeded || DBA?.cart?.spendingLimitExceeded) ? 
                <>
                <Body size="large">{String(aemData['options']['creditLimitExceededText']).replace("{{downPayment}}",downPaymentDBA).replace("{{payment}}",amountAfterDownPaymentDBA)}</Body>
                <MarginSpacerS/>
                <Body size="large">{aemData['options']['deviceOption']}</Body>
                </> : <></>
                }
                </div>
                <Common titleBold = {true} title="Due today*" data={ dueTodayDBA} subText='Incl. est. taxes; fees apply.'/>
                <Common titleBold = {true} title="Due monthly*" data={ dueMonthlyDBA} month = {"/mo"} subText={"Monthly surcharges, taxes and fees apply."}/>
            </>,
        }
    ]
    if(!dba) data.splice(2,1)
    if(!blended) data.splice(1,1)
    if(!bizOnly) data.splice(0,1)
    return data
}
const getSSNData = ({aemData,selectedValue,downPayment,amountAfterDownPayment,dueToday,dueMonthly,isCreditHoldNeeded,dppToFrp,creditLimitExceeded,creditCheckResult})=>{
    const {BLENDED,BizOnlyCredit} = creditCheckResult
    const downPaymentBiz = (!(dppToFrp || String(BizOnlyCredit.result).toLowerCase().includes("dpptofrp"))) ? (Number(BizOnlyCredit?.cart?.displayTotalAmounts?.downPayment ?? BizOnlyCredit?.cart?.totalAmounts?.downPayment)?.toFixed(2)) :downPayment
    const amountAfterDownPaymentBiz = (!(dppToFrp || String(BizOnlyCredit.result).toLowerCase().includes("dpptofrp"))) ? Number(BizOnlyCredit?.cart?.totalAmounts?.amountSavedMonthlyAfterDownPayment)?.toFixed(2) : amountAfterDownPayment
    const dueTodayBiz = (!!BizOnlyCredit?.dueToday) ? Number(BizOnlyCredit?.cart?.displayTotalAmounts?.dueToday ?? BizOnlyCredit?.dueToday)?.toFixed(2) : dueToday
    const dueMonthlyBiz = (!!BizOnlyCredit?.dueMonthly) ? Number(BizOnlyCredit?.cart?.displayTotalAmounts?.dueMonthly ?? BizOnlyCredit?.dueMonthly)?.toFixed(2) : dueMonthly
    
    const ssnData = [
        {
            id: 'BizOnlyCredit',
            value: 'BizOnlyCredit',
            text: aemData['options']['bizCredit'],
            type: 'radiobox',
            width: '48%',
            selected:selectedValue === 'BizOnlyCredit',
            subtext: 
            isCreditHoldNeeded ? 
            <>
            <MarginSpacerS/>
            <Body size='large'>{aemData['options']['hold']["subText2"]}</Body>
            </>
            :
            <>
                <MarginSpacerS/>
                {dppToFrp ? 
                <Body size='large'>{aemData['bizOnlySSN']['ssn']['subText4']}</Body>
                    :
                (creditLimitExceeded || BizOnlyCredit?.cart?.spendingLimitExceeded) ? 
                <>
                <Body size='large'>{`Make a down payment of $${downPaymentBiz}, and you’ll reduce your monthly payment to $${amountAfterDownPaymentBiz}.`} </Body>
                <MarginSpacerS/>
                <Body size="large">{aemData['bizOnlySSN']['ssn']['subtext3']}</Body>
                </> : <></>}
                <MarginSpacer2XL />
                <Common titleBold = {true} title="Due today*" data={ dueTodayBiz} subText='Incl. est. taxes; fees apply.'/>
                <Common titleBold = {true} title="Due monthly*" data={ dueMonthlyBiz} month = {"/mo"} subText={"Monthly surcharges, taxes and fees apply."}/>
            </>,
        },
        {
            id: 'SSN',
            value: 'SSN',
            text: aemData['options']['blended'],
            type: 'radiobox',
            width: '48%',
            selected:selectedValue === 'SSN',
            subtext: 
            <>
            <MarginSpacerS/>
            <Body size='large'>{dppToFrp&&isCreditHoldNeeded ? aemData['options']['non-hold']['subtext1'] : aemData['bizOnlySSN']['ssn']['subtext1']}</Body>
            <MarginSpacerS/>
            <Body size='large'>{aemData['bizOnlySSN']['ssn']['subtext2']}</Body>
            {(dppToFrp && !isCreditHoldNeeded && (BLENDED!==null&&BLENDED&&String(BLENDED.result).toLowerCase().includes("dpptofrp")))  ? 
            <>
            <MarginSpacer2XL />
                <Common titleBold = {true} title="Due today*" data={ dueToday} subText='Incl. est. taxes; fees apply.'/>
                <Common titleBold = {true} title="Due monthly*" data={ dueMonthly} month = {"/mo"} subText={"Monthly surcharges, taxes and fees apply."}/>
            </>:<></>}
            </>
        }
    ]
    return ssnData
}
const getTitle=({sessionCartData,showZipcodeContent = false,aemData,creditLimitExceeded,creditApproved,showCartOnly,ssn,blended,bizOnly,dba,isCreditHoldNeeded,dppToFrp,cleStandaloneHeading})=>{
    if(!creditApproved&&!showCartOnly){
    return(
        <div style={{maxWidth:'500px'}}>
        {
        ((((blended || bizOnly) && dba) && creditLimitExceeded) &&!isCreditHoldNeeded ) ?
            <>
             <Body size='large'>  {aemData['cleForBlendedAll']}  </Body>
             <MarginSpacerS/>
             <Body size='large'>{aemData['blended2']}</Body>
             </>
        : 
        ((((ssn || blended||bizOnly||dba) && creditLimitExceeded))&&!isCreditHoldNeeded) ?
        <>
             <Body size='large'>  {aemData['creditLimitExceeded']}  </Body>
             <MarginSpacerS/>
             <Body size='large'>{ssn ? aemData['chooseOption'] : (blended||bizOnly||dba) ? aemData["blended2"] : ''}</Body>
             </>
             :  creditLimitExceeded ? 
              <>
              <Body size='large'> {cleStandaloneHeading ? aemData['cleForBlendedAll'] : aemData['creditLimitExceeded']} </Body>
              <MarginSpacerS/>
            <Body size='large'>{aemData['cleOption']}</Body> 
            </> :
              (isCreditHoldNeeded && (blended||bizOnly||dba||ssn)) ? 
              <>
              <Body size='large'>{aemData['creditHoldSSN']}</Body> 
              <MarginSpacerS/>
             <Body size='large'>{aemData['chooseOption']}</Body>
             </>
              :
              ((blended||bizOnly||dba) && dppToFrp) ? 
              <>
              <Body size='large'>{aemData['blended1']}</Body>
              <MarginSpacerS/>
              <Body size='large'>{aemData['blended2']}</Body>
              </> : dppToFrp ? 
            <>
            <Body size='large'>{aemData['blended1']}</Body>
              <MarginSpacerS/>
            <Body size='large'>{aemData['chooseOption']}</Body>
            </>:<>
            <Body size='large'>{aemData['creditHoldSSN']}</Body> 
              <MarginSpacerS/>
            <Body size='large'>{aemData['chooseOption']}</Body>
            </>}

            <MarginSpacerL/>
        </div>
    )
    }else if(creditApproved || showCartOnly){
        let frpOrTwoYear = false
        if(sessionCartData?.cart!==null&&sessionCartData?.cart&&sessionCartData?.cart?.packages!==null&&sessionCartData?.cart?.packages&&Object.keys(sessionCartData?.cart?.packages).length){
        frpOrTwoYear = Object.values(sessionCartData?.cart?.packages).filter(data=>(String(data.priceType).toUpperCase().includes('RETAIL') || String(data.priceType).toUpperCase().includes('TWO_YEAR'))).length ? true : false
        }
        return(
        <div style={{maxWidth:'500px'}}>
        <Body size='large'>{frpOrTwoYear ? aemData['creditApproved']  : aemData['ssnTitle']}</Body>
        {showZipcodeContent ? 
        <>
        <MarginSpacerM/>
        <Body size='large'>{aemData['zipCodeText']}</Body>
        </>
        : <></>}
        </div>
    )
    }
}
const creditLimitOption = ({cartData,dppToFrp,dueToday,downPayment,amountAfterDownPayment})=>{
    let data =  [
        {
            id: 'PDP',
            value: dppToFrp ? "dppToFrp" : 'PAY_DOWN_PAYMENT',
            text: dppToFrp ? <Body size='large' bold={true}>Pay full retail price</Body> :<Body size='large' bold={true}>Pay down payment</Body>,
            type: 'radiobox',
            width: '30%',
            subtext: dppToFrp ? '$'+dueToday : `Pay $${downPayment}, which will reduce your monthly payment to $${amountAfterDownPayment}.`
        },
        {
            id: 'NSE',
            value: 'CHOOSE_ANOTHER',
            text: 'Choose a more affordable device',
            type: 'radiobox',
            width: '30%',
            subtext: ' '
        },
        {
            id: 'BYOD',
            value: 'BYOD',
            text: 'Bring your own device',
            type: 'radiobox',
            width: '30%',
            subtext: ' '
        }
    ]
    if(cartData!==null&&cartData&&cartData.packages!==null&&cartData.packages&&Object.keys(cartData.packages).length){
        let count = 0 
        Object.entries(cartData.packages).map(([key,data])=>{
            if(String(data.packageType).toUpperCase().includes('FOURG') || String(data.packageType).toUpperCase().includes('FIVEG')) count++
        })
        if(count === Object.keys(cartData.packages).length){
            data.pop()
            data.pop()
        }
    }

return data
}
export default CreditResultSSN
const RadioBoxGroupWrapper = styled(RadioBoxGroup)`
@media screen and (max-width:1024px){
    display:block;
    [class^=RadioBoxLabelWrapper-VDS]{
        flex:0 0 100% !important;
        margin-top:12px;
        width:100%;
        min-height:8vh;
    }
}
`
const ButtonWrapper = styled(Button)`
@media screen and (max-width:768px){
    width:100%;
  }
`