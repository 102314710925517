import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Title, Body } from "@vds3/typography";
import "./Confirmation.css";
import { Button, TextLink } from "@vds3/buttons";
import * as Styled from "common/components/styleTag";
import { getProspectCartPDF } from "../ReviewOrder/ducks/ReviewSlice";
import { Grid, Row, Col } from "@vds3/grids";
import { Line } from "@vds3/lines";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Notification } from "@vds3/notifications";
import SessionCartId from "common/components/SessionCartId/SessionCartId";
import { MarginSpacerL, MarginSpacerXL } from "common/components/styleTag";
import AccountSignUpModal from "common/components/Modals/AccountSignUpModal";
import { useMediaQuery } from "react-responsive";

function Confirmation() {
	const { t, i18n } = useTranslation(["app", "checkout", "thank-you"]);
	const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
	const completeOrderResponse = useSelector(
		(state) => state.ProspectWidgets_PaymentData
	);
	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	const unifiedCartFlow = sessionCartData?.cart?.unifiedCart;
	//const isDigitalQuote = window.sessionStorage.getItem("isDigitalQuote");
	const tysFlow = sessionCartData?.tys;
	const businessInfo = sessionCartData?.businessInfo;
	const orderDetails = useSelector((state) => state.ProspectWidgets_ReviewData);
	const unifiedCartOrderDetails = orderDetails?.UnifiedCartorderSuccessDetails;
	const todaysDate = new Date().toLocaleDateString();
	const dispatcher = useDispatch();
	const [pdfClicked, setPdfClicked] = useState(false);
	const [isSDD, setisSSDD] = useState(false);
	const showAgreement =
		completeOrderResponse?.completeOrderResponse?.showOwnerAgreement;
	const [showSignUpModal, setShowSignUpModal] = useState(false);
	const [userCreated, setUserCreated] = useState({ status: false });
	const isMobile = useMediaQuery({
		query: "(max-width: 545px)",
	});
	useEffect(() => {
		let prospectPDF = orderDetails?.prospectPDF;
		if (prospectPDF !== undefined && pdfClicked == true) {
			const linkSource = `data:application/pdf;base64,${prospectPDF.pdfContent}`;
			const link = document.createElement("a");
			link.href = linkSource;
			link.download = prospectPDF?.filename;
			link.click();
		}
		setPdfClicked(false);
	}, [orderDetails?.prospectPDF]);

	useEffect(() => {
		if (sessionCartData?.shipping?.shippingCode === "SDD_SAMEDAY") {
			setisSSDD(true);
		}
	}, [sessionCartData?.shipping?.shippingCode]);

	useEffect(() => {
		if (orderDetails?.orderSuccessDetails?.orderNumber || orderDetails?.orderSuccessDetails?.orderId) {
			window.dispatchEvent(
				new CustomEvent("QuoteSubmittedFromWidget", {
					detail: {
						orderNumber: orderDetails?.orderSuccessDetails?.orderNumber ?? orderDetails?.orderSuccessDetails?.orderId,
					},
				})
			);
		}
	}, [orderDetails?.orderSuccessDetails?.orderNumber, orderDetails?.orderSuccessDetails?.orderId]);

	const generatePdf = () => {
		dispatcher(getProspectCartPDF());
		setPdfClicked(true);
	};

	const routeToLearnPage = () => {
		window.location = t("thankYou.automation.verizonLearnPage", {
			ns: "thank-you",
		});
	};

	const getUcOrderDetails = (unifiedCartOrderDetails) => {
		const html = [];
		let packageDetails = null;

		let submitDetails = [];
		if (unifiedCartOrderDetails && unifiedCartOrderDetails.submitOrderResponse) {
			packageDetails = unifiedCartOrderDetails.submitOrderResponse;
		}

		if (!packageDetails || Object.keys(packageDetails).length === 0) {
			return html;
		}
		if (packageDetails) {
			for (const key of Object.keys(packageDetails)) {
				submitDetails.push(packageDetails[key].submitDetails);
			}
		}

		for (const key of Object.keys(packageDetails)) {
			const pack = packageDetails[key];
			html.push(
				<Grid rowGutter="1rem" colGutter="1rem">
					<Row className="">
						<Col colSizes={{ mobile: 4, tablet: 9, desktop: 12 }}>
							<Grid rowGutter="1rem" colGutter="1rem">
								<Row className="">
									<Col
										className="custom noSidePadding"
										colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
									>
										<Grid rowGutter="1rem" colGutter="1rem">
											<Row className="">
												<Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
													<div className="">
														<Body size="large" bold>
															{t("thankYou.automation.orderDetails", {
																ns: "thank-you",
															})}
														</Body>
													</div>
												</Col>
											</Row>
										</Grid>
										<Line type="heavy" inverted={false} />
										<Grid rowGutter="1rem" colGutter="1rem">
											<Row className="">
												<Col
													colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}
													className="noLeftPadding customRightPadding mar-bt-0"
												>
													<div className="cellstyling">
														<Body bold>
															{t("thankYou.automation.orderNumber", {
																ns: "thank-you",
															})}
														</Body>
														<Body> {pack?.orderNumber ?? ""}</Body>
													</div>
												</Col>

												<Col
													colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}
													className="noLeftPadding noRightPadding mar-bt-0"
												>
													<div className="cellstyling">
														<Body bold>
															{t("thankYou.automation.purchasedDevices", {
																ns: "thank-you",
															})}
														</Body>
														<Body>{pack?.cartSummary?.totalDevicesCount ?? ""}</Body>
													</div>
												</Col>
											</Row>
											<Row className="">
												<Col
													colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}
													className="noLeftPadding customRightPadding mar-bt-0"
												>
													<div className="cellstyling">
														<Body bold>
															{t("thankYou.automation.orderDate", {
																ns: "thank-you",
															})}
														</Body>
														<Body>{todaysDate ? todaysDate : ""}</Body>
													</div>
												</Col>

												{pack?.packageType === "FOURG" || pack?.packageType === "FIVEG" ? (
													<Col
														colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}
														className="noLeftPadding noRightPadding mar-bt-0"
													>
														<div className="cellstyling">
															<Body bold>
																{t("thankYou.automation.bringYourOwnRouter", {
																	ns: "thank-you",
																})}
															</Body>
															<Body> {pack?.cartSummary?.totalByodCount ?? ""}</Body>
														</div>
													</Col>
												) : (
													<Col
														colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}
														className="noLeftPadding noRightPadding mar-bt-0"
													>
														<div className="cellstyling">
															<Body bold>
																{t("thankYou.automation.bringYourOwnDevice", {
																	ns: "thank-you",
																})}
															</Body>
															<Body> {pack?.cartSummary?.totalByodCount ?? ""}</Body>
														</div>
													</Col>
												)}
											</Row>
											<Row>
												<Col
													colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}
													className="noLeftPadding customRightPadding mar-bt-0"
												>
													<div className="cellstyling">
														<Body bold>
															{t("thankYou.automation.billedMonthly", {
																ns: "thank-you",
															})}
														</Body>
														<Body> ${pack?.cartSummary?.totalAmounts?.dueMonthly ?? ""}</Body>
													</div>
												</Col>
												{pack?.packageType !== "FOURG" && pack?.packageType !== "FIVEG" ? (
													<Col
														colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}
														className="noLeftPadding noRightPadding mar-bt-0"
													>
														<div className="cellstyling">
															<Body bold>
																{t("thankYou.automation.accessories", {
																	ns: "thank-you",
																})}
															</Body>
															<Body> {pack?.cartSummary?.totalAccessoriesCount ?? ""}</Body>
														</div>
													</Col>
												) : (
													<Col
														colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}
														className="noLeftPadding noRightPadding mar-bt-0"
													>
														<div className="cellstyling">
															<Body bold>
																{t("thankYou.automation.dueToday", {
																	ns: "thank-you",
																})}
															</Body>
															<Body> ${pack?.cartSummary?.totalAmounts?.dueToday ?? ""}</Body>
														</div>
													</Col>
												)}
											</Row>
											<Row>
												{pack?.packageType !== "FOURG" && pack?.packageType !== "FIVEG" ? (
													<Col
														colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}
														className="noLeftPadding customRightPadding mar-bt-0"
													>
														<div className="cellstyling">
															<Body bold>
																{t("thankYou.automation.dueToday", {
																	ns: "thank-you",
																})}
															</Body>
															<Body> ${pack?.cartSummary?.totalAmounts?.dueToday ?? ""}</Body>
														</div>
													</Col>
												) : (
													<Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}></Col>
												)}
												{pack?.packageType !== "FOURG" && pack?.packageType !== "FIVEG" ? (
													<Col
														colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}
														className="noLeftPadding noRightPadding mar-bt-0"
													>
														<div className="cellstyling">
															<Body bold>
																{t("thankYou.automation.tradeInDevices", {
																	ns: "thank-you",
																})}
															</Body>
															<Body> {pack?.cartSummary?.totalTradeInCount ?? ""}</Body>
														</div>
													</Col>
												) : (
													<Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}></Col>
												)}
											</Row>
											{pack?.cartSummary?.packagesCount ? (
												<Row>
													{Object.keys(pack?.cartSummary?.packagesCount).map(
														(key, index) => {
															return (
																<Col
																	colSizes={{
																		mobile: 4,
																		tablet: 6,
																		desktop: 6,
																	}}
																	className={`noLeftPadding ${
																		index % 2 > 0 ? "noRightPadding" : "customRightPadding"
																	} mar-bt-0`}
																>
																	<div className="cellstyling">
																		<Body bold>{key}</Body>
																		<Body>{pack?.cartSummary?.packagesCount[key]}</Body>
																	</div>
																</Col>
															);
														}
													)}
												</Row>
											) : (
												""
											)}
										</Grid>
									</Col>
								</Row>
							</Grid>
						</Col>
					</Row>
				</Grid>
			);
		}
		return html;
	};
	const getRightPanelDetails = () => {
		const html = [];
		html.push(
			<Col colSizes={{ mobile: 4, tablet: 3, desktop: 3 }}>
				<Styled.Container className="noBorder customPadding customMargin">
					<Grid rowGutter="1rem" colGutter="1rem">
						<Row>
							<Col
								colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
								className="mar-bt-0"
							>
								<div className="mgr">
									<div className="sc-content customTopMargin">
										<Body size="large" bold>
											{t("review.terms.policyLabel", { ns: "review-order" })}
										</Body>
									</div>
									<div className="sc-content">
										<Body size="large">
											{t("review.terms.policyLabel2", {
												ns: "review-order",
											})}
										</Body>
									</div>
									<div className="sc-content">
										<Body size="large">
											{t("review.terms.policyLabel3", {
												ns: "review-order",
											})}
										</Body>
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col colSizes={{ mobile: 4, tablet: 8, desktop: 12 }}>
								<Body size="large">
									<TextLink
										target="_blank"
										href="https://b2b.verizonwireless.com/content/my-business-portal/support/howtoguides/device-return-policy.html"
									>
										{t("thankYou.automation.returnPolicyLabel", {
											ns: "thank-you",
										})}
									</TextLink>
								</Body>
							</Col>
						</Row>
					</Grid>
				</Styled.Container>
			</Col>
		);
		return html;
	};

	const registerBtnClicked = (data) => {
		userCreated.status = data.status;
	};
	return (
		<>
			{/* {showAgreement && (
        <div className="agreement-notification">
        <Notification
          type="info"
          title={
            <span
              dangerouslySetInnerHTML={{
                __html: t("thankYou.automation.notification.headerMsg", {
                  ns: "checkout",
                }),
              }}
            />
          }
          fullBleed={true}
        />
        </div>
      )} */}
			{/* {isDigitalQuote && ( */}
			
              <MarginSpacerL />
              <Title size="XLarge">{"Order confirmation"}</Title>
              {!isMobile && <MarginSpacerL />}
			{showAgreement && sessionCartData?.cart?.mwwCart && (
				<div className="confirmation-notification">
					<Notification
						type="info"
						title={
							<span className="boa">
								<a
									style={{ color: "white" }}
									target="_blank"
									href="https://b2b.verizonwireless.com/content/dam/mb_asset_folder/smb-json/ui-assets/5g/5GBIBuildingOwnerAgreement.pdf" rel="noreferrer"
								>
									Download
								</a>{" "}
								and complete your Building Owner Agreement for the Installation of your
								equipment.
							</span>
						}
						fullBleed={true}
					/>
				</div>
			)}
			{/* <div className="confirmation-notification">
				<Notification
					type="info"
					title={
						<span
							dangerouslySetInnerHTML={{
								__html: t("thankYou.automation.thankYounotification.headerMsg", {
									ns: "thank-you",
								}),
							}}
						/>
					}
					fullBleed={true}
				/>
			</div> */}

			<Grid rowGutter="1rem" colGutter="1rem">
				<Row>
					<Col
						className="custom noSidePadding"
						colSizes={{
							mobile: 4,
							tablet: 6,
							desktop: 6,
						}}
					>
						<Grid rowGutter="1rem" colGutter="1rem">
							<Row className="">
								<Col
									colSizes={{
										mobile: 2,
										tablet: 6,
										desktop: 6,
									}}
									className="noSidePadding"
								>
									<div className="">
										<Body size="large" bold>
											{t("thankYou.automation.orderDetails", {
												ns: "thank-you",
											})}
											{/* Order Details */}
										</Body>
									</div>
								</Col>
							</Row>
						</Grid>
						<Line type="heavy" inverted={false} />
						<Grid rowGutter="1rem" colGutter="1rem">
							<Row className="">
								<Col
									colSizes={{
										mobile: 4,
										tablet: 6,
										desktop: 6,
									}}
									className="noLeftPadding customRightPadding mar-bt-0"
								>
									<div className="cellstyling">
										<Body bold>
											{t("thankYou.automation.orderNumber", {
												ns: "thank-you",
											})}
										</Body>
										<Body> {orderDetails?.orderSuccessDetails?.orderNumber ?? orderDetails?.orderSuccessDetails?.orderId ?? ""}</Body>
									</div>
								</Col>
								<Col
									colSizes={{
										mobile: 4,
										tablet: 6,
										desktop: 6,
									}}
									className="noLeftPadding noRightPadding mar-bt-0"
								>
									<div className="cellstyling">
										<Body bold>
											{t("thankYou.automation.purchasedDevices", {
												ns: "thank-you",
											})}
										</Body>
										<Body>
											{orderDetails?.orderSuccessDetails?.cartSummary?.totalDevicesCount ??
												""}
										</Body>
									</div>
								</Col>
							</Row>
							<Row className="">
								<Col
									colSizes={{
										mobile: 4,
										tablet: 6,
										desktop: 6,
									}}
									className="noLeftPadding customRightPadding mar-bt-0"
								>
									<div className="cellstyling">
										<Body bold>
											{t("thankYou.automation.orderDate", {
												ns: "thank-you",
											})}
										</Body>
										<Body>{todaysDate ? todaysDate : ""}</Body>
										{/* Order Date */}
									</div>
								</Col>
								<Col
									colSizes={{
										mobile: 4,
										tablet: 6,
										desktop: 6,
									}}
									className="noLeftPadding noRightPadding mar-bt-0"
								>
									<div className="cellstyling">
										<Body bold>
											{t("thankYou.automation.bringYourOwnDevice", {
												ns: "thank-you",
											})}
										</Body>
										<Body>
											{" "}
											{orderDetails?.orderSuccessDetails?.cartSummary?.totalByodCount ??
												""}
										</Body>
									</div>
								</Col>
							</Row>
							<Row>
								<Col
									colSizes={{
										mobile: 4,
										tablet: 6,
										desktop: 6,
									}}
									className="noLeftPadding customRightPadding mar-bt-0"
								>
									<div className="cellstyling">
										<Body bold>
											{t("thankYou.automation.billedMonthly", {
												ns: "thank-you",
											})}
										</Body>
										<Body>
											{" "}
											$
											{orderDetails?.orderSuccessDetails?.cartSummary?.displayTotalAmounts
												? orderDetails?.orderSuccessDetails?.cartSummary
														?.displayTotalAmounts?.dueMonthly
												: orderDetails?.orderSuccessDetails?.cartSummary?.totalAmounts
														?.dueMonthly ?? ""}
										</Body>
									</div>
								</Col>
								<Col
									colSizes={{
										mobile: 4,
										tablet: 6,
										desktop: 6,
									}}
									className="noLeftPadding noRightPadding mar-bt-0"
								>
									<div className="cellstyling">
										<Body bold>
											{t("thankYou.automation.accessories", {
												ns: "thank-you",
											})}
										</Body>
										<Body>
											{" "}
											{orderDetails?.orderSuccessDetails?.cartSummary
												?.totalAccessoriesCount ?? ""}
										</Body>
									</div>
								</Col>
							</Row>
							<Row>
								<Col
									colSizes={{
										mobile: 4,
										tablet: 6,
										desktop: 6,
									}}
									className="noLeftPadding customRightPadding mar-bt-0"
								>
									<div className="cellstyling">
										<Body bold>
											{t("thankYou.automation.dueToday", {
												ns: "thank-you",
											})}
										</Body>
										<Body>
											{" "}
											$
											{orderDetails?.orderSuccessDetails?.cartSummary?.displayTotalAmounts
												? orderDetails?.orderSuccessDetails?.cartSummary
														?.displayTotalAmounts?.dueToday
												: orderDetails?.orderSuccessDetails?.cartSummary?.totalAmounts
														?.dueToday ?? ""}
										</Body>
									</div>
								</Col>
								<Col
									colSizes={{
										mobile: 4,
										tablet: 6,
										desktop: 6,
									}}
									className="noLeftPadding noRightPadding mar-bt-0"
								>
									<div className="cellstyling">
										<Body bold>
											{t("thankYou.automation.tradeInDevices", {
												ns: "thank-you",
											})}
										</Body>
										<Body>
											{" "}
											{orderDetails?.orderSuccessDetails?.cartSummary?.totalTradeInCount ??
												""}
										</Body>
									</div>
								</Col>
							</Row>
						</Grid>
					</Col>
				</Row>
			</Grid>
		</>
	);
}

export default Confirmation;
