import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Modal, ModalTitle, ModalFooter } from "@vds3/modals";

const ApplyToAllRemainingLinesModal = ({
	opened,
	onOpenedChange,
	onClickContinue,
	...props
}) => {
	const { t } = useTranslation(["pdp"]);
	return (
		<StyledModal
			ariaLabel="Apply to All Remaining Lines Modal"
			opened={opened}
			onOpenedChange={onOpenedChange}
		>
			<ModalTitle>{t("plans-first-applyToAllLinesModal.heading")}</ModalTitle>
			<ModalFooter
				buttonData={{
					primary: {
						width: "100%",
						children: t("plans-first-applyToAllLinesModal.content1"),
						onClick: () => {
							onClickContinue(true);
						},
					},
					close: {
						width: "100%",
						children: t("plans-first-applyToAllLinesModal.content2"),
						onClick: () => {
							onClickContinue();
						},
					},
				}}
			/>
		</StyledModal>
	);
};

const StyledModal = styled(Modal)`
	@media (min-width: 546px) {
		width: 690px;
	}
`;

export default ApplyToAllRemainingLinesModal;
