import React from "react";
import { UnorderedList, ListItem } from "@vds3/lists";
import { Modal, ModalBody, ModalTitle } from "@vds3/modals";
import { Body, Title } from "@vds3/typography";
import { Button } from "@vds3/buttons";
import {
  InternetGatewayModal as IGModal,
  InternetGatewayModalBody,
  InternetGatewayModalFooter,
} from "../EquipmentSection/styles";
import ErrorBoundary from "common/components/ErrorBoundary/ErrorBoundary";

export default function InternetGatewayModal(props) {
  return (
    <ErrorBoundary>
      <IGModal
        ariaLabel=""
        opened={props.internetGatewayModal}
        onOpenedChange={props.setInternetGatewayModal}
      >
        <ModalTitle>
          <Title size="large" bold={true}>
            <div dangerouslySetInnerHTML={{ __html: props.modalTitle }}></div>
          </Title>
        </ModalTitle>
        <InternetGatewayModalBody>
          {props.modalSubHeading && (
            <>
              <Body size="large" bold={!props?.starkEnabled}>
                <div
                  dangerouslySetInnerHTML={{ __html: props.modalSubHeading }}
                ></div>
              </Body>
              <br></br>
            </>
          )}
          <div style={{ marginLeft: "0.5rem", marginBottom: "1.5rem" }}>
            <UnorderedList>
              {props.internetGatewayModalHTML?.map((element, idx) => {
                return (
                  <ListItem key={idx} size="bodyLarge">
                    <span dangerouslySetInnerHTML={{ __html: element }} />
                  </ListItem>
                );
              })}
            </UnorderedList>
          </div>
        </InternetGatewayModalBody>
        <Button
          width={props?.isMobile ? "100%" : ""}
          onClick={() => {
            props.handleClick();
          }}
          disabled={false}
        >
          Close
        </Button>
      </IGModal>
    </ErrorBoundary>
  );
}
