import React from "react";
import { Title, Body } from "@vds3/typography";
import { MarginSpacerS, MarginSpacerM } from "common/components/styleTag";
import { formatPriceString } from "utils/commonUtils";
import { getDescriptionInfo } from "./../../ItemDescription";
import {
  PackageDetailContainer,
  PackageDetailDiv,
  PackagePriceDiv,
} from "./styles";

const PackageEquipmentDetail = ({ equipment,starkEnabled }) => {
  const descriptionInfo = getDescriptionInfo(equipment);
  const starkRouterPresent = !!(starkEnabled && equipment?.productName?.includes('Router')); 
  return (
    <>
    <Title
      size="medium"
      bold
    >{`${descriptionInfo?.brand} ${descriptionInfo?.name ? ( starkRouterPresent? 'Verizon Router' :descriptionInfo?.name) : ""}`}</Title>
    <MarginSpacerS />
    <PackageDetailContainer>
      <PackageDetailDiv>
        <Body size="small" color="#6F7171">
          {starkRouterPresent ? "Router" : descriptionInfo?.category}
        </Body>
      </PackageDetailDiv>
      <PackagePriceDiv>
        {starkRouterPresent ? equipment?.itemPrice > 0 ? 
        <Body size="large">{formatPriceString(equipment?.itemPrice)}</Body> : <Body size="large">Included</Body>
         : 
        <>
        
        {equipment?.itemPrice !== equipment?.itemPriceWithOutDiscount && (
          <Body size="large" color="#6F7171">
            {"was "}
            <Body size="large" color="#6F7171" primitive="span" strikethrough>
              {formatPriceString(equipment?.itemPriceWithOutDiscount)}
            </Body>
          </Body>
        )}
        </>
        }
      </PackagePriceDiv>
    </PackageDetailContainer>
    <MarginSpacerM />
  </>
  );
};

export default PackageEquipmentDetail;
