import React from "react";
import isEmpty from "lodash/isEmpty";
import {
	AccordionDetail,
	AccordionItem,
	AccordionTitle,
	AccordionSubTitle,
} from "@vds3/accordions";
import { Title, Body } from "@vds3/typography";
import { MarginSpacerL, MarginSpacerM } from "common/components/styleTag";
import {
	ProductBorder,
	ProductTitle,
	ProductSubTitle,
	CustomAccordionHeader,
	AccordionPlaceholder,
} from "./styles";

const PDPAccordionSection = ({
	opened,
	setOpened,
	titleText,
	subTitleText,
	showSubTitleWhenOpened,
	children,
	dataTrack,
	disable = false,
	buss = false,
	...props
}) => {
	return disable ? (
		<ProductBorder>
			<AccordionPlaceholder>
				<MarginSpacerL/>
				<Title bold size="medium">
					{titleText}
				</Title>
				<MarginSpacerM />
				<Body size="large">{isEmpty(subTitleText) ? "" : subTitleText}</Body>
			</AccordionPlaceholder>
		</ProductBorder>
	) : (
		<ProductBorder>
			<AccordionItem data-track={dataTrack} opened={opened}>
				<CustomAccordionHeader
					onClick={(e) => setOpened(!opened)}
					triggerType={buss ? "link" :"icon"}
				>
					<AccordionTitle>
						<ProductTitle className="productTitle">{titleText}</ProductTitle>
						{(!opened || showSubTitleWhenOpened) && (
							<AccordionSubTitle>
								<ProductSubTitle>
									{isEmpty(subTitleText) ||
									String(subTitleText).toLowerCase().includes("undefined")
										? ""
										: subTitleText}
								</ProductSubTitle>
							</AccordionSubTitle>
						)}
					</AccordionTitle>
				</CustomAccordionHeader>
				<AccordionDetail>{children}</AccordionDetail>
			</AccordionItem>
		</ProductBorder>
	);
};

export default PDPAccordionSection;
