// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shippingInfo {
  margin: 0rem;
}
.shippingInfo .container .form-col {
  margin: 0.5rem 1rem 0.5rem 0;
}
.shippingInfo .container .Street-Address .helper-text p{
  color: #747676;
  margin-top: 8px;
}
.shippingInfo .container .form-col .helper-text p{
  color: #747676;
  margin-top: 8px;
}
.shippingInfo .container .continue-btn {
  margin-top: 1rem;
}
.shippingInfo .container .continue-btn {
  margin-top: 1rem;
}
.shippingInfo .container > div > div {
  margin: 0;
}
[class*=StyledRow-VDS], [class*=StyledGridContainer-VDS]{
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.shippingInfo .container .Prod-Discription {
  margin-bottom: 1rem;
}
.clFtcO {
  font-size: 12px !important;
}
@media (max-width: 767px){ 
.shippingInfo .container .form-col {
  margin: 1rem 1rem 0.5rem 0;
}
}
@media (max-width: 767px){ 
  .ttip-wrapper{
    height: 92px;
  }
  .form-col.comp-name{
    position: absolute;
    width: 88%;
    overflow: unset !important;
  }
}
@media screen and (max-width:413px) {
  .form-col.comp-name {
    width: 87.3%;
 }
}

@media screen and (min-width:767px) {
  .form-col.comp-name{
    position: absolute;
    width: 287px;
    overflow: unset !important;
  }
  
}`, "",{"version":3,"sources":["webpack://./src/pages/Checkout/ShippingInfo/ShippingInfo.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,SAAS;AACX;AACA;EACE,2BAA2B;EAC3B,4BAA4B;AAC9B;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,0BAA0B;AAC5B;AACA;AACA;EACE,0BAA0B;AAC5B;AACA;AACA;EACE;IACE,YAAY;EACd;EACA;IACE,kBAAkB;IAClB,UAAU;IACV,0BAA0B;EAC5B;AACF;AACA;EACE;IACE,YAAY;CACf;AACD;;AAEA;EACE;IACE,kBAAkB;IAClB,YAAY;IACZ,0BAA0B;EAC5B;;AAEF","sourcesContent":[".shippingInfo {\n  margin: 0rem;\n}\n.shippingInfo .container .form-col {\n  margin: 0.5rem 1rem 0.5rem 0;\n}\n.shippingInfo .container .Street-Address .helper-text p{\n  color: #747676;\n  margin-top: 8px;\n}\n.shippingInfo .container .form-col .helper-text p{\n  color: #747676;\n  margin-top: 8px;\n}\n.shippingInfo .container .continue-btn {\n  margin-top: 1rem;\n}\n.shippingInfo .container .continue-btn {\n  margin-top: 1rem;\n}\n.shippingInfo .container > div > div {\n  margin: 0;\n}\n[class*=StyledRow-VDS], [class*=StyledGridContainer-VDS]{\n  margin-left: 0px !important;\n  margin-right: 0px !important;\n}\n\n.shippingInfo .container .Prod-Discription {\n  margin-bottom: 1rem;\n}\n.clFtcO {\n  font-size: 12px !important;\n}\n@media (max-width: 767px){ \n.shippingInfo .container .form-col {\n  margin: 1rem 1rem 0.5rem 0;\n}\n}\n@media (max-width: 767px){ \n  .ttip-wrapper{\n    height: 92px;\n  }\n  .form-col.comp-name{\n    position: absolute;\n    width: 88%;\n    overflow: unset !important;\n  }\n}\n@media screen and (max-width:413px) {\n  .form-col.comp-name {\n    width: 87.3%;\n }\n}\n\n@media screen and (min-width:767px) {\n  .form-col.comp-name{\n    position: absolute;\n    width: 287px;\n    overflow: unset !important;\n  }\n  \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
