import { useState, useEffect } from "react";
import cloneDeep from "lodash/cloneDeep";
import { getNumDevices, isCreditResultHold } from "../creditUtils";

const useCreditCheckResult = ({ creditResultsData, ...props }) => {
  const [creditCheckResult, setCreditCheckResult] = useState({
    mainCartData: {
      cart: {},
      dueMonthly: "0.00",
      dueToday: "0.00",
      isHold: false,
      numDevices: 0,
      result: "",
      securityDeposit: "0",
    },
    BizOnlyCredit: {
      cart: {},
      dueMonthly: "0.00",
      dueToday: "0.00",
      isHold: false,
      numDevices: 0,
      result: "",
      securityDeposit: "0",
    },
    BLENDED: {
      cart: {},
      dueMonthly: "0.00",
      dueToday: "0.00",
      isHold: false,
      numDevices: 0,
      result: "",
      securityDeposit: "0",
    },
    DBA: {
      cart: {},
      dueMonthly: "0.00",
      dueToday: "0.00",
      isHold: false,
      numDevices: 0,
      result: "",
      securityDeposit: "0",
    },
  });

  const mainCartData = creditResultsData?.data?.cartData ?? "";
  const bizOnlyCredData = creditResultsData?.bizOnlyCreditCart ?? "";
  const blendedCredData = creditResultsData?.blendedCart ?? "";
  const dbaCreditData = creditResultsData?.dbaCreditCart ?? "";

  const getCreditCheckResult = (data, status) => {
    const statusCode = status?.statusCode?.toUpperCase();
    if (status?.success) {
      if (statusCode === "APPROVED" || statusCode === "200") {
        if (data?.dppToFppChangedFlag) {
          //case 2
          if (Number(data?.securityDeposit) === 0) {
            return "dppToFRP";
          } else {
            return "dppToFRPWithDeposit";
          }
        } else if (data?.withinFinanceLimit == false) {
          if (Number(data?.requiredDownPaymentPercentage) === 0) {
            return "finance"; //case 5
          } else if (Number(data?.requiredDownPaymentPercentage) > 0) {
            return "partialFinance"; //case 6
          }
        } else {
          // 0 maxDownPaymentPercent means approved //case 4
          if (
            Number(data?.requiredDownPaymentPercentage) === 0 &&
            Number(data?.securityDeposit) === 0
          ) {
            return "approved";
          } else if (Number(data?.securityDeposit) > 0) {
            return "securityDeposit";
          } else {
            // > 0 maxDownPaymentPercent means approved but customer representative will call the customer case1
            return "conditionallyApproved";
          }
        }
      }
    } else {
      if (statusCode === "UNAPPROVED") {
        return "unApproved";
      } else {
        return "pendingReview";
      }
    }
    return '';
  };

  useEffect(() => {
    if (creditResultsData) {
      let tempCreditResultsData = cloneDeep(creditCheckResult);
      if (mainCartData) {
        let result = getCreditCheckResult(
          mainCartData,
          creditResultsData?.data?.status
        );
        tempCreditResultsData.mainCartData = {
          cart: mainCartData,
          dueMonthly: mainCartData?.totalAmounts?.dueMonthly,
          dueToday: mainCartData?.totalAmounts?.dueToday,
          isHold: isCreditResultHold(mainCartData),
          numDevices: getNumDevices(mainCartData?.cart, result),
          result: result,
          securityDeposit: mainCartData?.securityDeposit,
        };
      }
      if (bizOnlyCredData) {
        let result = getCreditCheckResult(
          bizOnlyCredData,
          creditResultsData?.data?.status
        );
        tempCreditResultsData.BizOnlyCredit = {
          cart: bizOnlyCredData,
          dueMonthly: bizOnlyCredData?.totalAmounts?.dueMonthly,
          dueToday: bizOnlyCredData?.totalAmounts?.dueToday,
          isHold: isCreditResultHold(bizOnlyCredData),
          numDevices: getNumDevices(bizOnlyCredData?.cart, result),
          result: result,
          securityDeposit: bizOnlyCredData?.securityDeposit,
        };
      }
      if (blendedCredData) {
        let result = getCreditCheckResult(
          blendedCredData,
          creditResultsData?.data?.status
        );
        tempCreditResultsData.BLENDED = {
          cart: blendedCredData,
          dueMonthly: blendedCredData?.totalAmounts?.dueMonthly,
          dueToday: blendedCredData?.totalAmounts?.dueToday,
          isHold: isCreditResultHold(blendedCredData),
          numDevices: getNumDevices(blendedCredData?.cart, result),
          result: result,
          securityDeposit: blendedCredData?.securityDeposit,
        };
      }
      if (dbaCreditData) {
        let result = getCreditCheckResult(
          dbaCreditData,
          creditResultsData?.data?.status
        );
        tempCreditResultsData.DBA = {
          cart: dbaCreditData,
          dueMonthly: dbaCreditData?.totalAmounts?.dueMonthly,
          dueToday: dbaCreditData?.totalAmounts?.dueToday,
          isHold: isCreditResultHold(dbaCreditData),
          numDevices: getNumDevices(dbaCreditData?.cart, result),
          result: result,
          securityDeposit: dbaCreditData?.securityDeposit,
        };
      }
      setCreditCheckResult(tempCreditResultsData);
    }
  }, [creditResultsData]);

  return creditCheckResult;
};

export default useCreditCheckResult;
