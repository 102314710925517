import React, { useState, useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import { TextLink } from "@vds3/buttons";
import { Body } from "@vds3/typography";
import { MarginSpacerS, MarginSpacerL } from "common/components/styleTag";
import FWAContext from "../fwaContext";
import SpeedPlanModal4G from "../Modals/SpeedPlanModal4G";
import PromotionsModal from "../Modals/PromotionsModal";
import { DisclaimersContainer } from "./styles";
import JointOfferPromotionModal from "../Modals/JointOfferPromotionModal";

const PlanDisclaimers = ({
  isHSIQualified,
  broadBandRedirectUrl,
  isCband,
  isMMW,
  selectedPlan,
  showVisaOffer,
  planType,
  isfromAuth = false,
  starkEnabled,
  maxSpeed,
  isMixedCartEligble,
  fwaBundleSelected
}) => {
  const { flow, isMobile } = useContext(FWAContext);
  const [showSpeedPlanModal, setSpeedPlanModal] = useState(false);
  const [promoModal, setPromoModal] = useState(false);
  const { t } = useTranslation(["4G-5G"]);
  const aemKey = flow === "4g" ? "fourG" : "fiveG";

  const redirectToHsi = () => {
    window.location.href = broadBandRedirectUrl;
  };

  return (
    <DisclaimersContainer>
      <MarginSpacerL />
      <Body size="small">
        {planType === 'backupPlan' ?
       <> 
       <div>
       <Trans
        ns="4G-5G"
        i18nKey={`4G-5G.${aemKey}.backup-plan-content.plansDisclaimer`}
        values={{ dagger: "†" }}
        shouldUnescape={true}
      >
        <sup />
      </Trans>
      </div>
      <Trans
      ns="4G-5G"
      i18nKey={`4G-5G.${aemKey}.backup-plan-content.plansDetailsLink`}
      values={{ dagger: "†" }}
      shouldUnescape={true}
    >
      <sup />
      <TextLink style={{fontWeight:'600'}}
        onClick={() => {
          setSpeedPlanModal(true);
        }}
      />
    </Trans>
    </>

        :<Trans
          ns="4G-5G"
          i18nKey={`4G-5G.${aemKey}.${planType === 'backupPlan' ? 'backup-plan-content':'plan-content'}.${fwaBundleSelected ? 'fwaBundledPlansDisclaimer' : 'plansDisclaimer'}`}
        >
          <TextLink style={{fontWeight:'600'}}
            onClick={() => {
              setSpeedPlanModal(true);
            }}
          />
        </Trans>
       }
      </Body>
      <Body size="small">
      {!isCband && <MarginSpacerL />}
      {planType !== 'backupPlan' && <TextLink style={{fontWeight:'600'}}
          onClick={() => {
            setPromoModal(true);
          }}
          size="small"
          type="standAlone"
        >
          {t("4G-5G.fourG.plan-content.promotionsModal.linkText")}
        </TextLink>
       }
       </Body>
      {isMMW ? <MarginSpacerL /> : <MarginSpacerS />}
      {/* VBGHSA-20253:remove HSI info in fwa */}
      {/* <>
        
        {isHSIQualified && flow === "5g" && (
          <TextLink
            onClick={() => {
              redirectToHsi();
            }}
            size="small"
            style={{ marginLeft: "16px" }}
            type="standAlone"
          >
            {t("4G-5G.fiveG.plan-content.basicInternetAccess.label", {
              ns: "4G-5G",
            })}
          </TextLink>
        )}
      </> */}
      {!!showSpeedPlanModal && (
        <SpeedPlanModal4G
          opened={showSpeedPlanModal}
          onOpenedChange={setSpeedPlanModal}
          isCband={isCband}
          isMMW={isMMW}
          planType={planType}
          isfromAuth={isfromAuth}
        />
      )}
      {!!promoModal && !isMixedCartEligble ? (
        <PromotionsModal
          opened={promoModal}
          onOpenedChange={setPromoModal}
          isCband={isCband}
          isMMW={isMMW}
          selectedPlan={selectedPlan}
          showVisaOffer={showVisaOffer}
          isfromAuth={isfromAuth}
          maxSpeed={maxSpeed}
          starkEnabled={starkEnabled}
        />
      ) :
      (<JointOfferPromotionModal 
      opened={promoModal}
      onOpenedChange={setPromoModal}
      starkEnabled={starkEnabled}
           />)
      }
      
    </DisclaimersContainer>
  );
};

export default PlanDisclaimers;
