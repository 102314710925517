import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { TextLink, Button } from "@vds3/buttons";
import { Checkbox } from "@vds3/checkboxes";
import { Grid, Row, Col } from "@vds3/grids";
import { Modal, ModalBody } from "@vds3/modals";
import { Body, Micro } from "@vds3/typography";
import styled from "styled-components";
import ColorSwatch from "common/components/ColorSwatch/ColorSwatch";
import {
	getStartsAtPrice,
	getDefaultSku,
} from "../../MobilityProductDetails/pdpUtils";
import { useTranslation } from "react-i18next";
import "./DeviceCard.css";
import { useImageUtils } from "hooks";
import { MarginSpacerL, MarginSpacerS, MarginSpacerXS, MarginTopSpacerM } from "common/components/styleTag";

const DetailsModal = styled(Modal)`
	padding: 1.25rem;
	width: 560px;
	height: 596px;
	min-height: auto;
	outline: none !important;
	@media only screen and (min-width: 768px) {
		padding: 4rem 2rem;
		> div:first-child {
			overflow: hidden;
			margin-right: 1.5rem;
		}
	}
	> div:first-child {
		overflow: hidden;
	}
	> div {
		padding: 0px !important;

		@media only screen and (max-width: 420px) {
			overflow: auto;
			::-webkit-scrollbar {
				width: 4px;
				height: 20px;
				background: #d8dada;
				border-radius: 10px;
			}
			::-webkit-scrollbar-thumb {
				background: #6f7171;
				border-radius: 10px;
			}
		}

		iframe {
			min-height: 250px !important;
			width: 100%;
			height: 100vh;
			border: none !important;

			@media only screen and (max-width: 420px) {
				overflow: auto;
				::-webkit-scrollbar {
					width: 4px;
					height: 20px;
					background: #d8dada;
					border-radius: 10px;
				}
				::-webkit-scrollbar-thumb {
					background: #6f7171;
					border-radius: 10px;
				}
			}
		}

		> span {
			height: 100%;
			width: 100%;

			& > div {
				height: 100% !important;

				& > div {
					padding-right: 20px !important;
				}
			}
		}
	}
	@media only screen and (max-width: 767px) {
		position: absolute;
		height: 100vh;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 95vw;
		> div:first-child {
			margin-top: 3rem !important;
		}
		[data-testid="modal-body"] {
			margin-top: 2.5rem !important;
		}
	}

	@media only screen and (max-width: 420px) {
		width: 100vw;
	}
`;
const DetailsModalBody = styled(ModalBody)`
	padding: 0;
	outline: none !important;
	height: 100%;
`;

const TextLinkXS = styled(TextLink)`
	text-decoration: none;
	margin-left: 6px;
	span {
		border-bottom: none !important;
	}
`;
const DeviceContainer = styled.div`
	min-height:${({buss})=>buss ? '530px !important':'400px'};
	@media screen and (min-width: 601px) and (max-width: 954px) {
		& {
			min-width: ${window.innerWidth / 3 - 30}px !important;
		}
	}
	@media screen and (max-width: 650px) {
	min-height:${({buss})=>buss ? '620px !important':'400px'};
	}
	@media screen and (min-width: 651px) and (max-width: 767px) {
	min-height:${({buss})=>buss ? '550px !important':'400px'};
	}
	@media screen and (min-width: 1024px) and (max-width: 1200px) {
	min-height:${({buss})=>buss ? '550px !important':'400px'};
	}
`;
const DeviceCard = React.memo(function DeviceCard(props) {
	const { ref, inView, entry } = useInView({
		rootMargin: "1000px",
		triggerOnce: false,
	});
	const { t } = useTranslation(["gridwall"]);
	const [selectedSku, setSelectedSku] = useState({});
	const [deviceOptions, setDeviceOptions] = useState([]);
	const [colorName, setColorName] = useState("");
	const [uniqueColorsList, setUniqueColorsList] = useState([]);
	const [colorsList, setColorList] = useState([]);
	const [colorCount, setColorCount] = useState("");
	const [promo, setPromo] = useState("");
	const [showModal, setShowModal] = useState(false);
	const { getImageLink } = useImageUtils();
	const dpp =
		selectedSku?.pricingDetails?.devicePayments?.length &&
		selectedSku?.pricingDetails?.devicePayments[0];
	const discountAmount = Number(dpp?.offerDetails?.discountAmount);
	const finalPrice = dpp?.finalPrice;

	const skuNotAvailable = (sku) =>
		sku.outOfStock || sku.preOrder || sku.backOrder;
	let preOrderShimentDate = selectedSku?.shipmentDate?.replace(/\b0/g, "");

	useEffect(() => {
		const skulist = props?.data?.skulist;
		let deviceOptions = skulist;
		let defaultSkuObj = deviceOptions && getDefaultSku(deviceOptions, props.sortBy);
		let defaultProduct =
			defaultSkuObj?.length > 0 &&
			defaultSkuObj[1]?.filter((item) => item.defaultSku == true);
		// Prod defect fix for Smart-watch default Sku
		if (
			defaultProduct === null ||
			defaultProduct === undefined ||
			defaultProduct.length === 0
		)
			defaultProduct = [skulist[0]];
		let defaultProductObj = { ...defaultProduct[0], color: colorName };
		let sortedDeviceOptions =
			defaultSkuObj && defaultSkuObj[1] != undefined
				? defaultSkuObj[1]
				: deviceOptions;
		defaultProduct?.length && setSelectedSku(defaultProductObj);
		setDeviceOptions(sortedDeviceOptions);

		const dppDetail = defaultProduct?.[0]?.pricingDetails?.devicePayments?.[0];

		if (
			Array.isArray(dppDetail?.promoDetails) &&
			dppDetail?.promoDetails?.length
		) {
			const promoDetail = dppDetail.promoDetails[0].promoMessages;
			if (Array.isArray(promoDetail) && promoDetail?.length) {
				setPromo({
					header: promoDetail[0]?.badgeText,
					tooltipText: promoDetail[1] && promoDetail[1]?.badgeText,
					modalContentUrl: promoDetail[1] && promoDetail[1]?.badgeToolTipUrl,
				});
			}
		}
	}, [props?.data]);

	useEffect(() => {
		if (deviceOptions) {
			let colorsList = deviceOptions && getUniqueColors(deviceOptions);
			let limitedColorList =
				colorsList.length > 6 ? colorsList.slice(0, 5) : colorsList.slice(0, 6);
			let colorCount = colorsList?.filter(
				(item) => !limitedColorList.includes(item)
			);
			setColorCount(colorCount.length);
			setColorList(colorsList);
			setUniqueColorsList(limitedColorList);
		}
	}, [deviceOptions]);

	const onCardSelect = (e) => {
		for (const cls of e.target?.classList) {
			// ignore elements of modal
			if (cls.startsWith("IconWrapper") || cls.startsWith("Overlay")) {
				return;
			}
		}
		if (
			e.target.nodeName !== "circle" &&
			e.target.nodeName !== "line" &&
			e.target.nodeName !== "SPAN"
		) {
			!!props?.onClickHandler &&
				props.onClickHandler(
					props.data.seoUrlName,
					props.data.deviceId,
					selectedSku,
					props.data?.category,
					props?.data?.name,
					props?.data?.groupId
				);
		}
	};

	const onCompareSelect = () => {
		let sel = { ...props.data };
		delete sel["skulist"];
		sel.selectedSku = selectedSku;
		props?.onCompareSelect && props.onCompareSelect(sel);
	};

	const getUniqueColors = (list) => {
		const colors = new Map();
		list.forEach((sku, index) => {
			const existing = colors.get(sku.cssColor);
			if (!existing || skuNotAvailable(existing)) {
				colors.set(sku.cssColor, {
					index: index,
					cssColor: sku.cssColor,
					skuId: sku.sku,
					outOfStock: sku.outOfStock,
					defaultSku: sku.defaultSku,
				});
			}
		});
		return Array.from(colors.values());
	};

	const getDisplayPrice = (sku) => {
		const price = getStartsAtPrice(sku);
		return (
			"$" +
			t("gridwall.labels.deviceFinalPrice", { ns: "gridwall", finalPrice: price })
		);
	};

	const getInstallments = (devicePayments) => {
		const num = (devicePayments && devicePayments?.nofInstallements) ?? 0;
		let text = t("gridwall.labels.deviceFinalPriceInMonths", {
			ns: "gridwall",
			paymentTenure: num,
		}).replaceAll(";", "");
		return text;
	};

	const getRetailPrice = (sku) => {
		const price = Number(sku?.pricingDetails?.zeroYear?.price).toFixed(2);
		return t("gridwall.labels.retailPrice", {
			ns: "gridwall",
			retailPriceZeroYear: `$${isNaN(price) ? 0 : price}`,
		});
	};

	const onMouseOverFun = (e) => {
		setColorName(deviceOptions[e]?.color);
	};

	const onMouseLeaveFun = () => {
		setColorName("");
	};

	const Offers = styled.div`
		margin-left: 0px;
		width: 100%;
		height: 44px;
	`;

	const OfferText = styled.div`
		width: 100%;
		background: #000;
		padding: 0.5rem 1rem;
		display: flex;
		margin-bottom: 1rem !important;
		border-radius: 8px 8px 0 0 !important;
		letter-spacing: 0px;
		text-align: left;

		@media (max-width: 767px) {
			margin-bottom: 1rem !important;
			padding: 0.5rem !important;
		}
	`;

	const ImageContainer = styled.div`
		height: 134px;
		left: 0px;
		top: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		position: relative;

		@media (max-width: 767px) {
			top: 0px;
			img {
				width: 134px !important;
				height: 134px !important;
				left: 13px;
				top: 60px;
			}
		}
	`;
	const DeviceImgContainer = styled.div`
		position: relative;
		height: 134px;
		width: 134px;
		@media screen and (min-device-width: 320px) and (max-device-width: 767px) {
			width: 134px !important;
			height: 134px !important;
			left: 13px;
			top: 76px;
			position: absolute;
		}
	`;

	const DevicePicture = styled.div`
		position: relative;
		background-image: ${({ url }) => `url("${url}")`};
		height: 110px;
		width: 110px;
		background-size: auto 100%;
		background-repeat: no-repeat;
		background-position: left top;
	`;

	const DeviceDetailsContainer = styled.div`
		padding: 1rem;
		position: relative;
		top: 35px;
		@media only screen and (min-width: 767px) {
			padding-left: 1.5rem;
		}
		@media (max-width: 767px) {
			position: relative;
			top: 10px;
			padding: 5px;
		}
	`;
	const PricingContainer = styled.div``;

	const PricingInfo = styled.div``;
	const ActionsContainer = styled.div`
		margin-top: auto;
		padding: 0.5rem;
		padding-top: 1.5rem;
		margin-bottom: 14px;
		padding-left: 10px;
		@media only screen and (min-width: 767px) {
			padding-left: 1.5rem;
			margin-bottom: 1.5rem;
		}
		position: absolute;
		bottom: 0px;
	`;

	const DeviceTitle = styled.div`
line-height:18px !important; 
margin-top:4px;
@media only screen and (min-width: 767px) {
  margin-bottom: 0.5rem;
}
@media (max-width: 767px){
  {
    margin-top:  ${({ isOutOfStock }) => (isOutOfStock ? ".5rem" : "4rem")}
  }
`;

	const Swatches = styled.div`
		padding-top: 1.5rem;
		padding-bottom: 0rem;
		display: flex;
		position: relative;
		cursor: auto;
		margin-bottom: 3rem;

		> svg {
			width: 16px;
			height: 16px;
			margin: 0px 8px 0px 0px;
		}
		.swatch-icon-tooltip {
			visibility: hidden;
			opacity: 0;
		}

		:hover {
			.swatch-icon-tooltip {
				opacity: 1;
				visibility: visible;
			}
		}

		@media (max-width: 767px) {
			 {
				padding-top: 2rem !important;
				padding-bottom: 3rem !important;
				display: flex;
			}
		}
	`;

	const ShipmentDate = styled.div`
width:230px;
@media (max-width: 767px){
  {
    width:130px;
    margin-top: 3rem;
    margin-bottom:-2rem;
  }
`;
	const OutofStockText = styled.div`
@media (max-width: 767px){
  {
    margin-top: 4rem;
  }

`;
	const OutofStockEmptyText = styled.div`
		min-height: 20px;
	`;

	return (
		<>
			<DeviceContainer
				ref={ref}
				className="device-card"
				data-click={true}
				onClick={onCardSelect}
				buss={props.isBuss}
			>
				{inView ? (
					<>
						<Offers>
							{promo && !props.hidePromoBadge && (
								<OfferText>
									<Body size="small" color="#FFFFFF">
										{promo?.header}
									</Body>
								</OfferText>
							)}
						</Offers>
						<ImageContainer>
							<picture className="image-container">
								<source
									type="image/webp"
									srcset={getImageLink(selectedSku?.filePath, 130, 130, true)}
								/>
								<DeviceImgContainer>
									<DevicePicture
										url={!!props.data?.imageUrl ? (process.env.PROSPECT_WIDGETS_APP_ASSESTS_BASE_URL + props.data?.imageUrl) : props.data?.imgUrl}
									></DevicePicture>
								</DeviceImgContainer>
							</picture>
						</ImageContainer>
						<DeviceDetailsContainer className="device-container">
							<div style={{minHeight:'240px'}}>
							<Grid colGutter={false} rowGutter={0}>
								<Row>
									<Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
										{selectedSku.preOrder ? (
											<Row>
												<Col colSizes={{ mobile: 4, tablet: 1, desktop: 12 }}>
													<ShipmentDate>
														{selectedSku.outOfStock ? (
															<OutofStockText>
																<Body size="small" color="#6F7171">
																	{t("gridwall.labels.outofstock", {
																		ns: "gridwall",
																	})}
																</Body>
															</OutofStockText>
														) : (
															<Body color="#6F7171" size="small">
																{`${t("gridwall.labels.preorder-date", {
																	ns: "gridwall",
																})} ${preOrderShimentDate}`}
															</Body>
														)}
													</ShipmentDate>
												</Col>
											</Row>
										) : (
											<></>
										)}
										{selectedSku.outOfStock ? (
											<div style={{ marginTop: "5px" }}>
												<OutofStockText>
													<Body size="small" color="#6F7171">
														{t("gridwall.labels.outofstock", {
															ns: "gridwall",
														})}
													</Body>
												</OutofStockText>
											</div>
										) : (
											<OutofStockEmptyText></OutofStockEmptyText>
										)}
										<DeviceTitle isOutOfStock={selectedSku.outOfStock}>
											<Body bold size="medium">
												{props.isBuss ? "" : props.data?.brandName}
												{props.data?.name}
											</Body>
										</DeviceTitle>
										{props?.isBuss ?
										<>
										<MarginSpacerS/>
										<Body size="small" color="#6F7171">{props.data?.description}</Body>
										</>
										: <></>}
										{/* {props.isBuss ?
										<>
										<MarginSpacerXS />
										<Body size="medium">
												{props.data?.description} 
										</Body>
										<MarginTopSpacerM/>
										</>
										:<></>} */}
										<MarginSpacerXS />
										<Body size="medium">
											{props.data?.title}
										</Body>
										<MarginSpacerL />
									</Col>
									<Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
										<PricingContainer>
											{props?.showDPP && (
												<>
													<PricingInfo>
														<Body size="small">
															{t("gridwall.labels.startsAt", { ns: "gridwall" })}{" $"}
															{/* {getDisplayPrice(selectedSku)} */}
															{Number(props.data?.startsPriceAt).toFixed(2)}{"/mo"}
														</Body>
														<Body size="small">Plus taxes & fees</Body>
													</PricingInfo>
													<MarginTopSpacerM />
													{/* {discountAmount >= 0 &&
														Object.keys(promo)?.length > 0 &&
														!props?.isBogo && (
															<>
																<Micro size="large">
																	{t("", { ns: "gridwall" })}
																	&nbsp;
																	<span className="strikethrough">
																		${finalPrice}
																		{t("gridwall.labels.per-month", { ns: "gridwall" })}
																	</span>
																	<TextLinkXS
																		size="small"
																		onClick={(e) => {
																			e.stopPropagation();
																			setShowModal(true);
																		}}
																	>
																		{t("gridwall.labels.details", {
																			ns: "gridwall",
																		})}
																	</TextLinkXS>
																</Micro>
															</>
														)}

													{!selectedSku.outOfStock && (
														<Micro>
															<PricingInfo>{getInstallments(dpp)}</PricingInfo>
														</Micro>
													)} */}
												</>
											)}
											{/* <Micro>
												<div className="pricing-info">{getRetailPrice(selectedSku)}</div>
											</Micro> */}
										</PricingContainer>
										{/* <Swatches>
											{uniqueColorsList?.map((sku) => (
												<ColorSwatch
													key={sku.cssColor}
													cssColor={sku.cssColor}
													isSelected={
														!!(
															selectedSku.sku === sku.skuId ||
															selectedSku.cssColor === sku.cssColor
														)
													}
													value={sku.index}
													colorName={colorName}
													isOutOfStock={sku.outOfStock}
													onClick={() => {
														setSelectedSku(deviceOptions[sku.index]);
													}}
													onMouseOver={() => onMouseOverFun(sku.index)}
													onMouseLeave={() => onMouseLeaveFun()}
													isUniqueColor={true}
													width={23}
													height={23}
												/>
											))}
											<>
												{colorsList.length > 6 && (
													<>
														{" "}
														<Body size="small">+{colorCount}</Body>
													</>
												)}
											</>
										</Swatches> */}
									</Col>
								</Row>
							</Grid>
							</div>
							<div style={{display:'flex',justifyContent:'center'}}>
							<Button
							disabled={false}
							onClick={(e) => onCardSelect(e)}
							size="small"
							use={props?.isBuss ? 'secondary':'primary'}
							>
							{" "}
							Shop now{" "}
						</Button>
						</div>
						</DeviceDetailsContainer>

						{/* {!props?.isBogo ? (
							<ActionsContainer onClick={(e) => e.stopPropagation()}>
								<Checkbox
									name="compare"
									width="auto"
									label=""
									disabled={false}
									error={false}
									errorText=""
									selected={props?.isSelected}
									onChange={onCompareSelect}
								>
									{t("gridwall.labels.compare", { ns: "gridwall" })}
								</Checkbox>
							</ActionsContainer>
						) : (
							<></>
						)} */}
						{showModal && (
							<DetailsModal
								opened={showModal}
								onOpenedChange={setShowModal}
								className="card-modal"
							>
								<DetailsModalBody className="card-modal-body">
									<iframe
										height="100%"
										width="100%"
										src={promo?.modalContentUrl}
									></iframe>
								</DetailsModalBody>
							</DetailsModal>
						)}
					</>
				) : null}
			</DeviceContainer>
		</>
	);
});

export default DeviceCard;
