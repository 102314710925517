import styled from "styled-components";

export const NotificationContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;
  justify-content: space-between;
  margin-right: 10px;
`;

export const NotificationMessage = styled.div`
  max-width: 590px;
`;

export const NotificationButton = styled.div`
  max-width: 80px;
  margin-bottom: 2px;
`;
