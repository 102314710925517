import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "@vds3/modals";
import { Title, Body } from "@vds3/typography";
import { MarginSpacerS } from "common/components/styleTag";
//import IFrameModal from "common/components/Modals/IFrameModal";
import PDPContext from "../pdpContext";
import OfferCard from "./OfferCard";

function OffersInfo({ promo, ...props }) {
	const { t } = useTranslation(["pdp", "gridwall"]);
	const [isSeeDetails, setIsSeeDetails] = useState(false);
	const {
		product,
		selectedSku: { selectedSku },
		selectedPayment: { selectedPayment },
		selectedPromo: { selectedPromo, setSelectedPromo },
		tradeInValueInfo: { tradeInValueInfo },
		sessionCartData,
	} = React.useContext(PDPContext);
	const bogoPromoTypes =
		t("device-pdp.bogoPromoTypes", { returnObjects: true }) || [];
	const aemPromoConfigs =
		t("device-pdp.promoConfig", { returnObjects: true }) || {};
	const aemPromoConfig = aemPromoConfigs[promo?.promoType]
		? aemPromoConfigs[promo?.promoType]
		: {
				heading: "Online exclusive",
				actionable: false,
				added: false,
		  };
	const isTradeInPromo = promo?.promoType === "TRADEIN_PER_MONTH";
	const isAutoAdd = aemPromoConfig?.added;
	const isSelected =
		isAutoAdd ||
		(selectedPromo && selectedPromo?.promotionId === promo?.promotionId);
	const isActionable =
		tradeInValueInfo && bogoPromoTypes.includes(promo?.promoType)
			? false
			: aemPromoConfig?.actionable;
	const brandName = props?.brandName.toLowerCase();
	const title = t(`gridwall.promo-banner.smartphones/${brandName}.title`, {
		ns: "gridwall",
	});
	const description = t(
		`gridwall.promo-banner.smartphones/${brandName}.modal.description`,
		{ ns: "gridwall", returnObjects: true }
	);

	const showSeeDetails = () => {
		if (promo?.promoMessages?.badgeToolTipUrl) {
			return (
				<></>
				// <IFrameModal
				//   opened={isSeeDetails}
				//   onOpenedChange={setIsSeeDetails}
				//   iframeUrl={promo?.promoMessages?.badgeToolTipUrl}
				// />
			);
		} else {
			return (
				<Modal ariaLabel="" opened={isSeeDetails} onOpenedChange={setIsSeeDetails}>
					<Title size="small">{title}</Title>
					<Body>
						{description.map((desc) => (
							<div>
								<p>{desc.title}</p>
								<MarginSpacerS />
								<ul>{descData(desc.items)}</ul>
							</div>
						))}
					</Body>
				</Modal>
			);
		}
	};

	const descData = (desc) => {
		return (
			<div>
				{desc.map((d) => (
					<li>{d}</li>
				))}
			</div>
		);
	};

	const getPromoHeading = () => {
		const config = aemPromoConfigs[promo?.promoType];
		if (config && config?.heading) {
			return config.heading;
		}
		return isTradeInPromo ? "Trade-in promotion" : "Online exclusive";
	};

	return (
		<>
			<OfferCard
				sessionCartData={sessionCartData}
				eyebrow={getPromoHeading()}
				title={promo?.promoMessages?.badgeText}
				onDetailsClick={() => setIsSeeDetails(true)}
				isActionable={isActionable}
				isAutoAdded={isAutoAdd}
				isBogo={props?.isBogo}
				isBogoFlag={props?.isBogoFlag}
				onAddClick={() => setSelectedPromo(promo)}
				onRemoveClick={() => setSelectedPromo(null)}
				isAdded={isSelected}
				editPackagePackageId={props.editPackagePackageId}
			/>
			{isSeeDetails && showSeeDetails()}
		</>
	);
}

export default OffersInfo;
