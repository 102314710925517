import React, { useState, useEffect, useMemo, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
// import {
// 	useNavigate,
// 	useParams,
// 	useLocation,
// 	useSearchParams,
// } from "react-router-dom";
import { Grid, Col, Row } from "@vds3/grids";
import { useMediaQuery } from "react-responsive";
import { Notification } from "@vds3/notifications";
import { useTranslation } from "react-i18next";
import PromoBanner from "common/components/PromoBanner/PromoBanner";
import ErrorBoundary from "common/components/ErrorBoundary/ErrorBoundary";
import Header from "common/components/Header/Header";

import SpinnerComponent from "common/components/Spinner/Spinner";
import CustomBreadcrumb from "common/components/CustomBreadcrumb/CustomBreadcrumb";

import StickyHeaderVds3 from "common/components/StickyHeader/StickyHeaderVds3";

import {
	FLOWS,
	setIsEditPackageFlow,
	setLoadMobilityPlansPage,
	setLoadMobilityProductDetails,
	setLoadShoppingCartWidget,
} from "app/ducks/GlobalSlice";
import {
	addToCart,
	getProductDetailPageInfo,
	getReviews,
	setProductDetail,
	setSelectedSku,
	setDeviceName,
	setBogoOfferId,
	setBogoDeviceUrl,
	setIsByodZipcode,
	setZipcode,
	clearCartAndAddToCart,
	setDeviceId,
	setIsBogoFlag,
	replaceDeviceForExceedingcreditLimit,
	setPegaRecommendations,
	getGridwallPegaRecommendation,
	setPdpDeviceDetails,
	setSkuData,
} from "../../ducks/MobilitySlice";
import "./MobilityProductDetails.css";
//import * as SiteAnalytics from "../../../../sitecatalyst";
import ProductDetails from "./ProductDetails";
import ZipCodeModal from "common/components/ZipCodeModal/ZipCodeModal";
import NewCustomerModal from "common/components/NewCustomerModal/NewCustomerModal";
//import { navigateAction } from "../../../../app/FlowActions";
import { setLocalCookie } from "utils/commonUtils";
import { useGlobalData, useStickyHeaderHeight } from "hooks";
import SubscribeEmail from "common/components/SubscribeEmail/SubscribeEmail";
import IncompatiblePackageModal from "common/components/Modals/IncompatiblePackageModal";
import { get } from "lodash";
//import { setSelectedPlansNew } from "pages/Plans/ducks/PlansSlice";
import { setFeaturePageVisited } from "app/ducks/GlobalSlice";
import { TextLinkCaret } from "@vds3/buttons";
import styled from "styled-components";
import {Icon} from '@vds3/icons';



const MobilityProductDetails = (props) => {
	//const [searchParams, setSearchParams] = useSearchParams();
	const { t, ready } = useTranslation(["pdp"]);
	const isBogo = false; //searchParams.get("type") && searchParams.get("type") === "GET";
	const {
		combinePricing,
		editPackagePackageId,
		editPackageGrpId,
		isEditPackageFlow,
		metaInformation,
	} = useGlobalData({ isOneTalk: props?.isOneTalk, skipSessionCart: true });
	const dispatch = useDispatch();
	//const navigate = useNavigate();
	//const location = useLocation();
	const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
	const selectedPlans = useSelector(
		(state) => state.ProspectWidgets_WirelessPlansData?.selectedPlansNew
	);
	const hasCmp = globalData?.cmp?.cmpValue;
	const globalDataLoading = useSelector(
		(state) => state.ProspectWidgets_GlobalData?.loading
	);
	const pageData = useSelector((state) => state.ProspectWidgets_MobilityData);
	const pegaRecommendations = get(
		pageData,
		"pegaRecommendations.compareDeviceInfoList",
		[]
	);
	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	const identifier =
		globalData?.sessionCartData?.apiResponse?.sessionCartMetaData?.identifier;
	const tradeInValues = globalData?.tradeInValues;
	const { stickyHeaderRef, headerHeight } =
		useStickyHeaderHeight(sessionCartData);
	const availZip = pageData?.confirmZipcode != "" ? true : false;
	const planFirstPageData = useSelector((state) => state?.PlanFirst);

	const [isAddToCartClicked, setIsAddToCartClicked] = useState(false);
	const [isFirstDeviceClicked, setIsFirstDeviceClicked] = useState(false);
	const [isZipcode, setIsZipcode] = useState(availZip);
	const [selectedDeviceDetails, setSelectedDeviceDetails] = useState({});
	const [showCartDetailsModal, setshowCartDetailsModal] = useState(false);
	const [isQuantityChanged, setQuantityChanged] = useState(false);
	const [showIncompatibleModal, setIncompatibleModal] = useState(false);
	const [addToCartPayload, setAddToCartPayload] = useState("");
	const [tradeInValueInfo, setTradeInValuInfo] = useState(null);
	const [tradeInfoDeviceSku, setTradeInfoDeviceSku] = useState(null);
	const [isPegaTriggered, setIsPegaTriggered] = useState(false);
	const [isAddToCartClickedModal, setIsAddToCartClickedModal] = useState(false);
	const [priceTypeChanged, setPriceTypeChanged] = useState(false);
	const [deviceChanged, setDeviceChanged] = useState(false);

	const PriceTypeOld_Exceedcredit =
		window.sessionStorage.getItem("PriceTypeOld");
	const BASE_PATH = "/products/devices/";
	const currentPath = ""; //location.pathname.replace(BASE_PATH, "");
	const promoData = metaInformation?.globalPromos?.devicePDP;
	const deviceName = pageData?.selectedDeviceName;
	let sectionName = pageData?.categoryName; //currentPath.split("/")[0];
	let deviceCategoryName = pageData?.selectedDeviceName
		?.split("/")[1]
		?.split("-")[0];
	let globalPromos =
		sectionName && deviceCategoryName ? promoData && promoData[sectionName] : {};
	const isMobile = useMediaQuery({
		query: "(max-width: 545px)",
	});
	const BackLink = styled.div`
	margin-left:1.5rem;
	margin-right: 2rem;
	@media screen and (max-width: 768px) {
		margin-bottom: 20px;
	}
`;
	const isOneTalk = props.isOneTalk ? props.isOneTalk : false;
	const is5gsmartphones = props.is5gsmartphones ?? false;
	const data = pageData?.productDetail && pageData?.productDetail?.data;
	const pdpPersonalisation = metaInformation?.pdpPersonalisation;
	const tradeFromLocal = JSON.parse(sessionStorage.getItem("TRADE_INFO_VALUE"));
	const isAPIError = () =>
		!!(
			pageData?.apiError &&
			pageData?.addToCartResponse?.status?.statusMessage !== "PACKAGE_INCOMPATIBLE"
		);

	const isPlanFirstFlow = useMemo(
		() =>
			globalData?.flow === FLOWS.PLANFIRST &&
			Object.values(globalData?.sessionCartData?.devices || {})?.filter(
				(i) => i?.deviceFullDetail?.dummy
			)?.length,
		[globalData?.flow, globalData?.sessionCartData?.devices]
	);
	const showZipcodeModal =
		metaInformation?.showZipCodeModal && !isZipcode && !isPlanFirstFlow;
	const getSimType = (selectedSku) => {
		let defaultSimType = selectedSku?.defaultSimType;
		let defaultSku = selectedSku?.supportedSims?.filter(
			(sim) => !!sim?.defaultSim
		);
		if (defaultSku?.length && !selectedSku?.simType?.length) {
			if (defaultSku[0]?.simSku === selectedSku?.psimSku) {
				defaultSimType = "psim";
			} else if (defaultSku[0]?.simSku === selectedSku?.esimSku) {
				defaultSimType = "esim";
			}
		} else if (selectedSku?.simType?.length) {
			defaultSimType = selectedSku?.simType;
		}
		return defaultSimType;
	};

	useEffect(() => {
		let tradeInValue = tradeInValueInfo;
		if (isEditPackageFlow && editPackagePackageId) {
			if (
				sessionCartData?.cart?.packages[editPackagePackageId]?.tradInInfo &&
				Object.values(tradeInValues)?.length
			) {
				tradeInValue =
					tradeInValues[
						sessionCartData?.cart?.packages[editPackagePackageId]?.tradInInfo
							?.deviceId
					];
				setTradeInfoDeviceSku(String(editPackagePackageId).split("_")[0]);
			} else if (
				!sessionCartData?.cart?.packages[editPackagePackageId]?.tradInInfo &&
				sessionCartData?.cart?.packages[editPackagePackageId]?.eligibleTradeIn &&
				Object.values(tradeInValues)?.length &&
				tradeFromLocal &&
				tradeFromLocal.tradeInfoValue !== null &&
				tradeFromLocal.tradeInfoValue &&
				Object.keys(tradeFromLocal.tradeInfoValue).length > 0
			) {
				setTradeInfoDeviceSku(tradeFromLocal.sku);
				tradeInValue = tradeFromLocal.tradeInfoValue;
			} else tradeInValue = null;
		} else if (!(isEditPackageFlow && editPackagePackageId)) {
			if (
				tradeFromLocal &&
				tradeFromLocal.tradeInfoValue !== null &&
				tradeFromLocal.tradeInfoValue &&
				Object.keys(tradeFromLocal.tradeInfoValue).length > 0
			) {
				setTradeInfoDeviceSku(tradeFromLocal.sku);
				tradeInValue = tradeFromLocal.tradeInfoValue;
			} else tradeInValue = null;
		}
		setTradeInValuInfo(tradeInValue);
	}, [globalData]);
	useEffect(() => {
		if (pageData?.zipCode == "") {
			let defaultZip = t("defaultZipcode", { ns: "pdp" });
			if (
				sessionCartData?.cart !== null &&
				sessionCartData?.cart &&
				sessionCartData?.cart?.zipCodeFromPlan !== null &&
				sessionCartData?.cart?.zipCodeFromPlan
			)
				defaultZip = sessionCartData?.cart?.zipCodeFromPlan;
			dispatch(setZipcode(defaultZip));
		}
	}, [pageData?.zipCode]);
	useEffect(() => {
		dispatch(setPegaRecommendations(null));
		window.sessionStorage.setItem("_flowName", "Device");
		// const categoryName = location?.pathname?.includes("smart-watches")
		// 	? "smart-watches"
		// 	: location?.pathname?.includes("basic-phones")
		// 	? "basic-phones"
		// 	: location?.pathname?.includes("mobile-hotspots")
		// 	? "mobile-hotspots"
		// 	: location?.pathname?.includes("tablets")
		// 	? "tablets"
		// 	: location?.pathname?.includes("laptops")
		// 	? "laptops"
		// 	:
		const categoryName = pageData?.categoryName;

		dispatch(
			getProductDetailPageInfo({
				deviceName: deviceName,
				deviceId: pageData?.deviceId,
				categoryName,
				selectedSkuDetails: pageData?.selectedSku,
				isOneTalk: isOneTalk,
				identifier: identifier,
				pdpPersonalisation: pdpPersonalisation,
				bussName:globalData?.selectedGWProduct
			})
		);
		//SiteAnalytics.trackPageInfo();
		return () => {
			// clean up product detail on unmount, to avoid showing stale data
			dispatch(setProductDetail(""));
			dispatch(setSelectedSku(""));
			dispatch(setDeviceName(""));
			dispatch(setPegaRecommendations(null));
			dispatch(setPdpDeviceDetails(null));
			setIsPegaTriggered(false);
		};
	}, [deviceName]);

	useEffect(() => {
		if (
			pdpPersonalisation &&
			pageData?.pdpProductDetails?.deviceDetails &&
			pageData?.pdpProductDetails?.deviceDetails?.seoUrlName === deviceName &&
			// location?.pathname?.includes &&
			// location?.pathname?.includes("smartphones") &&
			!isPegaTriggered
		) {
			let defaultZip = pageData?.zipCode;
			if (pageData?.zipCode == "" || defaultZip == "") {
				defaultZip = t("defaultZipcode", { ns: "pdp" });
			}
			if (
				defaultZip?.includes &&
				defaultZip?.includes("app.common.defaultZipcode")
			) {
				defaultZip = localStorage.getItem("zipCode");
			}
			const skuList = pageData?.pdpProductDetails?.deviceDetails?.skulist?.filter(
				(sku) => sku?.defaultSku
			);
			const reqPayload = {
				deviceId:
					pageData?.pdpProductDetails?.deviceDetails?.deviceId || pageData?.deviceId,
				deviceSku: skuList?.[0]?.sku || pageData?.selectedSku?.sku,
				contextInfo: {
					transactionType: "SimilarDevice",
					callReasonCode: "SDR",
					category: "Prospect",
					prospectInfo: {
						targetSKU: skuList?.[0]?.sku || pageData?.selectedSku?.sku,
						ecID: identifier || "",
						pageContext: "PDP",
						propositionCount: "3",
						locationCode: defaultZip || "30004",
					},
				},
			};
			dispatch(getGridwallPegaRecommendation(reqPayload));
			setIsPegaTriggered(true);
		}
	}, [pdpPersonalisation, pageData?.pdpProductDetails]);

	useEffect(() => {
		if (pageData?.productDetail?.data) {
			const skuList =
				pageData?.productDetail?.data?.deviceDetails?.skulist?.filter(
					(sku) => sku?.defaultSku
				);
			// pageData?.productDetail?.data?.deviceDetails?.deviceId &&
			// 	SiteAnalytics.eventProductViewTracking("deviceInfo", {
			// 		productDetail: pageData?.productDetail?.data?.deviceDetails,
			// 		selectedSku: pageData?.selectedSku || skuList[0],
			// 	});
			dispatch(
				getReviews({
					productId: pageData?.productDetail?.data?.deviceDetails?.deviceId,
					limit: t("reviewLimit", { ns: "pdp" }),
					offset: 0,
					sort: "rating:desc",
				})
			);
			dispatch(setDeviceId(""));
		}
	}, [pageData?.productDetail?.data]);

	useEffect(() => {
		if (
			pageData?.addToCartResponse?.status?.statusCode === 200 &&
			pageData?.addToCartResponse?.status?.statusMessage !== ""
		) {
			sessionStorage.removeItem("TRADE_INFO_VALUE");
			setIsAddToCartClicked(false);
			dispatch(setLoadMobilityPlansPage(true));

			if (
				pageData?.deviceSelectedForExceedginCreditLimit !== null &&
				pageData?.deviceSelectedForExceedginCreditLimit
			) {
				if (
					String(addToCartPayload?.lines[0]?.device?.priceType).toUpperCase() !==
					String(PriceTypeOld_Exceedcredit).toUpperCase()
				) {
					dispatch(setFeaturePageVisited(true));
					dispatch(setLoadMobilityPlansPage(true));
					//navigate("/shop/plans");
				} else {
					dispatch(replaceDeviceForExceedingcreditLimit(undefined));
					//navigate("/shop/products/devices/device-review");
				}
			} else {
				if (pageData?.bogoOfferId && !isBogo) {
					//navigate("/shop/products/devices/smartphones?type=GET");
					dispatch(setIsBogoFlag(true));
				} else {
					dispatch(setIsByodZipcode(false));
					if (isEditPackageFlow && !isQuantityChanged) {
						let navigateToPlans = false;
						const { response } = pageData?.addToCartResponse;
						if (response !== null && response) {
							const { cart } = response;
							if (cart !== null && cart) {
								const { packages } = cart;
								if (packages !== null && packages && Object.keys(packages).length) {
									Object.values(packages).map((data) => {
										if (data.plan !== null && data.plan) {
											if (
												String(data?.priceType).toUpperCase().includes("TWO_YEAR") &&
												!String(data.plan?.pricePlanDesc).toUpperCase().includes("1.0")
											)
												navigateToPlans = true;
											else if (
												!String(data?.priceType).toUpperCase().includes("TWO_YEAR") &&
												String(data.plan?.pricePlanDesc).toUpperCase().includes("1.0")
											)
												navigateToPlans = true;
										}
										if (priceTypeChanged || deviceChanged) {
											navigateToPlans = true;
										}
									});
								}
							}
						}
						if (isPlanFirstFlow) {
							dispatch(setIsEditPackageFlow(false));
							//navigate("/shop/products/plans");
						} else {
							if (navigateToPlans) {
								let plans = { ...selectedPlans };
								delete plans[editPackagePackageId];
								//setTimeout(() => setSelectedPlansNew(plans), 5000);
								//navigateAction(globalData, navigate);
							} else {
								setTimeout(() => {
									//navigate("/shop/shopping-cart");
								}, 300);
							}
						}
					} else if (window.location.pathname.includes("smart-watches")) {
						//	navigate("/shop/address-activation");
					} // else navigateAction(globalData, navigate);
				}
			}
		} else if (
			isAddToCartClicked &&
			pageData?.addToCartResponse?.status?.statusMessage === "PACKAGE_INCOMPATIBLE"
		) {
			setIncompatibleModal(true);
		}
	}, [pageData?.addToCartResponse]);

	useEffect(() => {
		if (
			pageData?.addToCartResponse?.status?.statusCode === 200 &&
			pageData?.addToCartResponse?.status?.statusMessage != "" &&
			isAddToCartClickedModal
		) {
			sessionStorage.removeItem("TRADE_INFO_VALUE");
			setIsAddToCartClickedModal(false);
			if (pageData?.bogoOfferId) {
				//navigate("/shop/products/devices/smartphones?type=GET");
				dispatch(setIsBogoFlag(true));
			} else if (window.location.pathname.includes("smart-watches")) {
				dispatch(setSkuData(selectedDeviceDetails[0]?.selectedSku[0]));
				//navigate("/shop/address-activation");
			} else {
				//navigate("/shop/plans");
			}
		}
	}, [pageData?.addToCartResponse]);

	const addToCartClicked = React.useCallback(
		(
			priceType,
			quantity,
			selectedSku,
			selectedOffers,
			operation,
			addToAllLines
		) => {
			if (operation === "regular") {
				let devices = sessionCartData?.cart?.packages;
				if (devices === undefined) {
					setIsFirstDeviceClicked(true);
					let dataObj = [];
					dataObj.push({
						priceType: priceType,
						quantity: quantity,
						selectedSku: selectedSku,
						selectedOffers,
					});
					setSelectedDeviceDetails(dataObj);
					// SiteAnalytics.eventAddtocartTracking(
					// 	"Shopping Cart Open",
					// 	{
					// 		productDetail: pageData?.productDetail?.data?.deviceDetails,
					// 		selectedSku: selectedSku[0],
					// 		requestPayload: {
					// 			priceType: priceType,
					// 			quantity: quantity,
					// 			selectedSku: selectedSku,
					// 			selectedOffers,
					// 		},
					// 	},
					// 	"deviceInfo",
					// 	"prospect"
					// );
				}
				if (devices !== undefined) {
					newCustomerClicked(priceType, quantity, selectedSku, selectedOffers);
				}
				// SiteAnalytics.eventAddtocartTracking(
				// 	"Add To Cart",
				// 	{
				// 		productDetail: pageData?.productDetail?.data?.deviceDetails,
				// 		selectedSku: selectedSku[0],
				// 		requestPayload: {
				// 			priceType: priceType,
				// 			quantity: quantity,
				// 			selectedSku: selectedSku,
				// 			selectedOffers,
				// 		},
				// 	},
				// 	"deviceInfo",
				// 	"prospect"
				// );
			} else if (operation == "plans-first") {
				let productDetailsObj = {
					selectedDeviceDetails: selectedSku,
					addToAllLines: addToAllLines,
					selectedPayment: priceType,
				};
				const payload = {
					cartMeta: {
						zipCode: pageData?.zipCode,
						shoppingPath: "prospect",
					},
					approach: "plans-first",
					productDetailsObj: productDetailsObj,
					planFirstFlag: true,
					lines: [],
					addendaCode: globalData?.cmp?.cmpValue ?? "",
				};
				const tradeInItem =
					tradeInValueInfo?.tradeInItems?.length &&
					tradeInValueInfo?.tradeInItems[0];
				let tradeIn = {};
				if (
					tradeInItem?.recycleDeviceOfferType?.buyItemCode === selectedSku[0]?.sku
				) {
					tradeIn = {
						deviceId: tradeInItem?.deviceId,
					};
				}
				let quantityChanged = false;
				if (isEditPackageFlow && (editPackageGrpId || quantity > 1)) {
					const groupInfo =
						sessionCartData?.cart?.groupPackages &&
						sessionCartData?.cart?.groupPackages[editPackageGrpId];
					if (groupInfo && Number(quantity) !== Number(groupInfo?.quantity)) {
						quantityChanged = true;
					}
				}
				setQuantityChanged(quantityChanged);

				let dataForUpdateQuantity = {};
				if (quantityChanged) {
					dataForUpdateQuantity = {
						operation: "UPDATEBULKQTY",
						groupId: editPackageGrpId,
						packageId: "",
						plan: {
							planId:
								sessionCartData?.cart?.packages[editPackagePackageId].plan
									?.pricePlanCode,
						},
					};
				}
				const dummyLines = Object.entries(
					globalData?.sessionCartData?.devices || {}
				)?.filter(([_key, line]) => line?.deviceFullDetail?.dummy);
				const linesToUpdate = new Set();
				linesToUpdate.add(
					isEditPackageFlow && editPackagePackageId
						? editPackagePackageId
						: dummyLines[0][0]
				);
				// linesToUpdate.add(
				//   isEditPackageFlow && editPackagePackageId
				//     ? editPackagePackageId
				//     : planFirstPageData?.clickedLineId ?? dummyLines[0][0]
				// );
				if (addToAllLines) {
					dummyLines?.forEach(([key, _line]) => linesToUpdate.add(key));
				}

				for (const line of linesToUpdate) {
					payload.lines.push({
						packageId: line,
						packageType: "REGULAR",
						operation: "UPDATE",
						selectedOffers,
						tradeIn,
						device: {
							deviceSku: selectedSku?.sku,
							deviceId: data?.deviceDetails?.deviceId,
							deviceCategory: data?.deviceDetails?.category,
							quantity: quantity,
							priceType:
								priceType === "monthly" &&
								selectedSku?.pricingDetails?.devicePayments?.[0]?.nofInstallements
									? "MONTH_TO_MONTH"
									: priceType === "twoyears"
									? "TWO_YEAR"
									: "RETAIL",
							installmentTerm:
								priceType === "monthly" &&
								selectedSku?.pricingDetails?.devicePayments?.[0]?.nofInstallements
									? selectedSku?.pricingDetails?.devicePayments?.[0]?.nofInstallements
									: 0,
							simType: getSimType(selectedSku) === "esim" ? "E" : "P",
							deviceType: "SMARTPHONE",
						},
						...dataForUpdateQuantity,
					});
				}

				if (payload.lines.length) {
					setIsAddToCartClicked(true);
					dispatch(setSkuData(selectedSku[0]));
					dispatch(addToCart(payload));
				}
			}
		},
		[sessionCartData, pageData, planFirstPageData]
	);

	const newCustomerClicked = (
		priceTypeValue,
		quantityValue,
		selectedSkuValue,
		selectedOffers
	) => {
		let dataObj = selectedDeviceDetails;
		let priceType = priceTypeValue ? priceTypeValue : dataObj[0]?.priceType;
		let quantity = quantityValue ? quantityValue : dataObj[0]?.quantity;
		let selectedSku = selectedSkuValue
			? selectedSkuValue
			: dataObj[0]?.selectedSku;
		let quantityChanged = false;
		if (isEditPackageFlow && (editPackageGrpId || quantity > 1)) {
			const groupInfo =
				sessionCartData?.cart?.groupPackages &&
				sessionCartData?.cart?.groupPackages[editPackageGrpId];
			if (groupInfo && Number(quantity) !== Number(groupInfo?.quantity)) {
				quantityChanged = true;
			}
		}
		const tradeInItem =
			tradeInValueInfo?.tradeInItems?.length && tradeInValueInfo?.tradeInItems[0];
		// Update Flow Payload
		let lines = [];
		let dataForUpdateQuantity = {};
		if (isEditPackageFlow) {
			const oldPriceType =
				sessionCartData &&
				sessionCartData["cart"]["packages"][editPackagePackageId]["priceType"];
			const newPriceType =
				priceType === "monthly" &&
				selectedSku[0]?.pricingDetails?.devicePayments?.[0]?.nofInstallements
					? "MONTH_TO_MONTH"
					: priceType === "twoyears"
					? "TWO_YEAR"
					: "RETAIL";

			const isPriceTypeChanged =
				String(oldPriceType).toUpperCase() !== String(newPriceType).toUpperCase();
			setPriceTypeChanged(isPriceTypeChanged);

			const oldDeviceId =
				sessionCartData["cart"]["packages"][editPackagePackageId]["deviceId"];
			const newDeviceId = pageData?.productDetail?.data?.deviceDetails?.deviceId;
			const isDeviceChanged =
				String(oldDeviceId).toUpperCase() !== String(newDeviceId).toUpperCase();
			setDeviceChanged(isDeviceChanged);
			if (!quantityChanged || (quantityChanged && isPriceTypeChanged)) {
				const packageId = String(editPackagePackageId).split("_");
				Array(quantity)
					.fill(0)
					.map((data, i) => {
						lines.push({
							packageId: isPriceTypeChanged
								? ""
								: packageId[0] + "_" + (Number(packageId[1]) + i),
							packageType: isOneTalk ? "ONETALK" : "REGULAR",
							operation: isPriceTypeChanged || isDeviceChanged ? "ADD" : "UPDATE",
							selectedOffers,
							tradeIn:
								tradeInItem &&
								tradeInItem?.recycleDeviceOfferType &&
								tradeInItem?.recycleDeviceOfferType?.buyItemCode ===
									selectedSku[0]?.sku &&
								i === 0
									? {
											deviceId: tradeInItem?.deviceId,
									  }
									: {},
							device: {
								deviceSku: selectedSku[0]?.sku,
								deviceId: pageData?.productDetail?.data?.deviceDetails?.deviceId,
								deviceCategory:
									pageData?.productDetail?.data?.deviceDetails?.deviceCategory ||
									pageData?.productDetail?.data?.deviceDetails?.category,
								quantity: 1,
								priceType:
									priceType === "monthly" &&
									selectedSku[0]?.pricingDetails?.devicePayments?.[0]?.nofInstallements
										? "MONTH_TO_MONTH"
										: priceType === "twoyears"
										? "TWO_YEAR"
										: "RETAIL",
								installmentTerm:
									priceType === "monthly" &&
									selectedSku[0]?.pricingDetails?.devicePayments?.[0]?.nofInstallements
										? selectedSku[0]?.pricingDetails?.devicePayments?.[0]
												?.nofInstallements
										: 0,
								seoUrlName: selectedSku.seoUrlName,
								simType: getSimType(selectedSku[0]) === "esim" ? "E" : "P",
								deviceType: "SMARTPHONE",
							},
						});
					});
			} else {
				dataForUpdateQuantity = {
					operation: "UPDATEBULKQTY",
					groupId: editPackageGrpId,
					packageId: "",
					plan: {
						planId:
							sessionCartData?.cart?.packages[editPackagePackageId].plan
								?.pricePlanCode,
					},
				};
			}
		}
		setQuantityChanged(quantityChanged);
		setIsAddToCartClicked(true);
		const payload = {
			cartMeta: {
				zipCode: pageData.zipCode,
				shoppingPath: "prospect",
				flow: isOneTalk ? "one-talk" : "prospect",
			},
			lines:
				lines !== null && lines && lines.length
					? lines
					: [
							{
								packageId: isEditPackageFlow ? editPackagePackageId : "",
								packageType: isOneTalk ? "ONETALK" : "REGULAR",
								operation: isEditPackageFlow ? "UPDATE" : "ADD",
								selectedOffers,
								tradeIn:
									tradeInItem &&
									tradeInItem?.recycleDeviceOfferType &&
									tradeInItem?.recycleDeviceOfferType?.buyItemCode ===
										selectedSku[0]?.sku
										? {
												deviceId: tradeInItem?.deviceId,
										  }
										: {},
								device: {
									deviceSku: selectedSku[0]?.sku,
									deviceId: pageData?.productDetail?.data?.deviceDetails?.deviceId,
									deviceCategory:
										pageData?.productDetail?.data?.deviceDetails?.deviceCategory ||
										pageData?.productDetail?.data?.deviceDetails?.category,
									quantity: quantity,
									priceType:
										priceType === "monthly" &&
										selectedSku[0]?.pricingDetails?.devicePayments?.[0]?.nofInstallements
											? "MONTH_TO_MONTH"
											: priceType === "twoyears"
											? "TWO_YEAR"
											: "RETAIL",
									installmentTerm:
										priceType === "monthly" &&
										selectedSku[0]?.pricingDetails?.devicePayments?.[0]?.nofInstallements
											? selectedSku[0]?.pricingDetails?.devicePayments?.[0]
													?.nofInstallements
											: 0,
									seoUrlName: selectedSku.seoUrlName,
									simType: getSimType(selectedSku[0]) === "esim" ? "E" : "P",
									deviceType: "SMARTPHONE",
								},
								...dataForUpdateQuantity,
							},
					  ],
			addendaCode: globalData?.cmp?.cmpValue ?? "",
		};

		if (isEditPackageFlow && editPackagePackageId) {
			const oldPriceType =
				sessionCartData &&
				sessionCartData["cart"]["packages"][editPackagePackageId]["priceType"];
			const newPriceType =
				priceType === "monthly" &&
				selectedSku[0]?.pricingDetails?.devicePayments?.[0]?.nofInstallements
					? "MONTH_TO_MONTH"
					: priceType === "twoyears"
					? "TWO_YEAR"
					: "RETAIL";

			const isPriceTypeChanged =
				String(oldPriceType).toUpperCase() !== String(newPriceType).toUpperCase();

			const oldDeviceId =
				sessionCartData["cart"]["packages"][editPackagePackageId]["deviceId"];
			const newDeviceId = pageData?.productDetail?.data?.deviceDetails?.deviceId;
			const isDeviceChanged =
				String(oldDeviceId).toUpperCase() !== String(newDeviceId).toUpperCase();
			if (isDeviceChanged) {
				payload.lines.push({
					packageId: editPackagePackageId,
					packageType: "REGULAR",
					operation: "DELETE",
				});
			} else if (isPriceTypeChanged) {
				if (editPackageGrpId || quantity > 1) {
					const groupInfo =
						sessionCartData?.cart?.groupPackages &&
						sessionCartData?.cart?.groupPackages[editPackageGrpId];
					let quantityChanged = false;
					if (groupInfo && Number(quantity) !== Number(groupInfo?.quantity)) {
						quantityChanged = true;
					}
					setQuantityChanged(quantityChanged);
					for (let pkId of groupInfo["packageIds"]) {
						payload.lines.push({
							packageId: pkId,
							packageType: "REGULAR",
							operation: "DELETE",
						});
					}
				} else {
					payload.lines.push({
						packageId: editPackagePackageId,
						packageType: "REGULAR",
						operation: "DELETE",
					});
				}
			}
		}
		if (
			pageData?.deviceSelectedForExceedginCreditLimit !== null &&
			pageData?.deviceSelectedForExceedginCreditLimit &&
			sessionCartData !== null &&
			sessionCartData
		) {
			const { cart } = sessionCartData;
			if (
				cart !== null &&
				cart &&
				cart?.packages !== null &&
				cart?.packages &&
				cart?.packages[pageData?.deviceSelectedForExceedginCreditLimit] !== null &&
				cart?.packages[pageData?.deviceSelectedForExceedginCreditLimit]
			) {
				const packageSelected =
					cart?.packages[pageData?.deviceSelectedForExceedginCreditLimit];
				const features = Object.values(packageSelected["features"]).filter(
					(data) => data.type === "SFO"
				)[0];
				payload.lines.map((line) => {
					if (
						String(line.device?.priceType).toUpperCase() ==
						String(PriceTypeOld_Exceedcredit).toUpperCase()
					) {
						line["plan"] = { planId: packageSelected["plan"]["pricePlanCode"] };
					}
					line["features"] = [
						{
							featureCode: features["visFeatureCode"],
							featureLevel: features["level"],
							featureType: features["type"],
							operation: "ADD",
							quantity: "1",
						},
					];
				});
				payload.lines.push({
					packageId: pageData?.deviceSelectedForExceedginCreditLimit,
					packageType: "REGULAR",
					operation: "DELETE",
				});
				payload.exceedingCreditLimitData = payload.lines[0]["device"]["deviceSku"];
				payload.imageSets = selectedSku[0]["imageSet"];
			}
		}
		setAddToCartPayload(payload);
		setLocalCookie("SMB_PROSPECT_CARTQTY", quantity, 1);
		dispatch(addToCart(payload));
		dispatch(setSkuData(selectedSku[0]));
	};

	const bogoSelected = (e) => {
		dispatch(setBogoDeviceUrl(""));
		dispatch(setBogoOfferId(e?.offerId));
	};

	const bogoRemoved = () => {
		dispatch(setBogoDeviceUrl(""));
		dispatch(setBogoOfferId(""));
		if (isBogo) {
			//navigateAction(globalData, navigate);
		}
	};

	return !ready || globalDataLoading ? (
		<SpinnerComponent />
	) : (
		<ErrorBoundary>
			<div className="onetalk-product-details mobility-pdp-wrapper">
				<Header />
				{/* <SEOTagger
					pdpMeta={{
						...pageData?.productDetail?.data?.meta,
						brandName: pageData?.productDetail?.data?.deviceDetails?.brandName,
						isCPO: pageData?.productDetail?.data?.deviceDetails?.isCPO,
					}}
				/> */}
				{globalPromos?.active && !isBogo && (
					<div className="promo-mobility">
						<PromoBanner
							bgColor={"#333"}
							showClose={globalPromos?.showClose}
							promoData={globalPromos}
						/>
					</div>
				)}
				<BackLink>
						
					{/* <Icon name="left-caret"	color={'black'} size="large" bold onClick={() => {
													dispatch(setLoadMobilityProductDetails(false));
													dispatch(setLoadShoppingCartWidget(false));
													//navigateToLTEPlans();
												}} /> */}
											<TextLinkCaret
												iconPosition="left"
												size="medium"
												onClick={() => {
													dispatch(setLoadMobilityProductDetails(false));
													dispatch(setLoadShoppingCartWidget(false));
													//navigateToLTEPlans();
												}}
											>
												Back
											</TextLinkCaret>
										</BackLink>
				{/* {globalData?.sessionCartData?.apiResponse?.serviceStatus?.success ? (
					<StickyHeaderVds3
						backClickHandler={() => {}}
						disabledContinueBtn={true}
						enableBackLink={!!isBogo}
						hideAddress={false}
						hideContinueButton={true}
						notSticky={isMobile}
						onCartClick={() => {
							//navigate("/shop/shopping-cart");
						}}
						pdp={true}
					/>
				) : (
					<></>
				)} */}
				{pageData?.loading && <SpinnerComponent />}
				{isAPIError() && (
					<Notification
						type="error"
						title={t("device-pdp.error", { ns: "pdp" })}
						fullBleed={true}
						inlineTreatment={false}
						className="notification"
					/>
				)}
				{pageData?.productDetail ? (
					<div className="mobility-pdp-section">
						<Grid colGutter={false} rowGutter="1rem">
							<Row className="breadcrumbsection">
								<Col
									colSizes={{
										mobile: 4,
										tablet: 12,
										desktop: `${hasCmp ? 8 : 11}`,
									}}
									className="breadcrumb-wrapper"
								>
									{!isPlanFirstFlow && false && (
										<CustomBreadcrumb
											isProspect={!isOneTalk}
											is5gsmartphones={is5gsmartphones}
											productName={`${pageData?.productDetail?.data?.deviceDetails?.brandName} ${pageData?.productDetail?.data?.deviceDetails?.name}`}
										/>
									)}
								</Col>
								{!isMobile ? (
									<Col
										colSizes={{ mobile: 1, tablet: 2, desktop: 1 }}
										className="chat-link-wrappper"
									>
										{/* <ChatLink /> */}
									</Col>
								) : (
									<></>
								)}
								<>
									<Col
										colSizes={{ mobile: 1, tablet: 2, desktop: 2 }}
										className="chat-link-wrappper"
									>
										{/* <ContactSales
											showPhoneNumber={!isOneTalk}
											isMobile={isMobile}
											showCartDetailsModal={showCartDetailsModal}
											setshowCartDetailsModal={setshowCartDetailsModal}
										/> */}
									</Col>
								</>
							</Row>
							<Row className="pdp-section-wrapper">
								<Col
									colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
									className="main-wrapper"
								>
									<ProductDetails
										fromProspect = {props?.fromProspect}
										buss={!!globalData?.selectedGWProduct ? true : false}
										selectedBUSS = {globalData?.selectedGWProduct}
										productDetail={pageData?.productDetail}
										isEditPackageFlow={isEditPackageFlow}
										editPackagePackageId={editPackagePackageId}
										isOneTalk={isOneTalk}
										combinePricing={combinePricing}
										addToCart={addToCartClicked}
										reviews={pageData?.getReviews}
										headerHeight={headerHeight}
										preselectedColor={""}
										preselectedSku={""}
										preselectedSimType={""}
										setIsAddToCartClicked={setIsAddToCartClicked}
										isBogo={isBogo}
										isBogoFlag={pageData?.isBogoFlag}
										bogoSelected={bogoSelected}
										bogoRemoved={bogoRemoved}
										tradeInValueInfoDefault={tradeInValueInfo}
										pack={{
											...sessionCartData?.cart?.packages?.[editPackagePackageId],
											packageId: editPackagePackageId ?? undefined,
										}}
										editPackageGrpId={editPackageGrpId}
										tradeInfoDeviceSku={tradeInfoDeviceSku}
										compareDeviceName={deviceName}
										compareSelectedSku={pageData?.selectedSku}
										pegaRecommendations={pegaRecommendations}
									/>
								</Col>
							</Row>
						</Grid>
						{showZipcodeModal && !pageData?.zipEmail && (
							<Suspense fallback={<></>}>
								<ZipCodeModal pageData={pageData} />
							</Suspense>
						)}

						{isFirstDeviceClicked && (
							<NewCustomerModal
								pageData={pageData}
								isOneTalk={isOneTalk}
								selectedDeviceDetails={selectedDeviceDetails}
								closeNCModal={setIsFirstDeviceClicked}
								isFirstDeviceClicked={isFirstDeviceClicked}
								cmpValue={globalData?.cmp?.cmpValue}
								tradeInValueInfo={tradeInValueInfo}
								setIsAddToCartClickedModal={setIsAddToCartClickedModal}
								setIsFirstDeviceClicked={setIsFirstDeviceClicked}
							/>
						)}
						{showIncompatibleModal && (
							<IncompatiblePackageModal
								opened={showIncompatibleModal}
								onOpenedChange={setIncompatibleModal}
								onContinue={() => {
									dispatch(clearCartAndAddToCart(addToCartPayload));
								}}
							/>
						)}
					</div>
				) : (
					// loading placeholder, so footer doesn't rise up too far
					<div style={{ minHeight: "37.5rem" }} />
				)}
			</div>
			<SubscribeEmail />
		</ErrorBoundary>
	);
};

export default MobilityProductDetails;
