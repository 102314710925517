import RatingStar from "common/components/RatingStar/RatingStar";
import React from "react";
import styled from "styled-components";

const DeviceRatings = styled.div`
	display: inline-block;
	position: relative;
`;
const Fullrating = styled.div`
	position: absolute;
	z-index: 1;
	overflow: hidden;
	display: inline-block;
	white-space: nowrap;
`;
const Emptyrating = styled.div`
	position: relative;
	display: flex;
`;

const getFullRatingWidth = (rating) => {
	const size = Math.round(20 * rating);
	return size + "%";
};

function Ratings({ points, height, width }) {
	return (
		<DeviceRatings>
			<Fullrating
				style={{
					width: getFullRatingWidth(points),
				}}
			>
				{Array(5)
					.fill(1)
					.map((item, index) => (
						<RatingStar
							key={`fullstar-${index}`}
							fillColor="#000000"
							height={height}
							width={width}
						/>
					))}
			</Fullrating>
			<Emptyrating>
				{Array(5)
					.fill(1)
					.map((item, index) => (
						<RatingStar
							key={`emptystar-${index}`}
							fillColor="#FFFFFF"
							height={height}
							width={width}
						/>
					))}
			</Emptyrating>
		</DeviceRatings>
	);
}

export default React.memo(Ratings);
