import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TextLink, Button } from "@vds3/buttons";
import { Modal, ModalTitle, ModalBody, ModalFooter } from "@vds3/modals";
import { Title } from "@vds3/typography";
import { Icon } from "@vds3/icons";

export const CAResidentModal = ({ ...props }) => {
  const { t } = useTranslation(["4G-5G"]);
  const modalSubHeading = t("4G-5G.common.prop65WarningModal.modalSubHeading", { ns: "4G-5G", returnObjects: true});
  const {label, description, link} = modalSubHeading;
  const {text, href}= link;

  const handleClick = () => {
    props.setCAWarningModal(false);
    //window.scrollTo(0, 0);
  };

  return (
    <Modal
      opened={props.cAWarningModal}
      onOpenedChange={props.setCAWarningModal}
      surface="light"
      hideCloseButton={false}
      // toggleButton={<TextLink>{t("app.common.prop65WarningModal.modalHeading")}</TextLink>}

    >
      <ModalTitle>
        <Title size="large" bold={true}>
          <div
            dangerouslySetInnerHTML={{
              __html: `${t("4G-5G.common.prop65WarningModal.modalHeading", {
                ns: "4G-5G",
              })}`,
            }}
          ></div>
        </Title>
      </ModalTitle>
      <ModalBody>
        <div>
          <Icon
            name="warning"
            color="#000000"
            size="medium"
          />
          <span style={{  "position": "absolute", "marginLeft": "10px" }} >
            <span style={{ "fontWeight": "bold" }} dangerouslySetInnerHTML={{ __html: label, }}></span>:&nbsp;
            <span
              dangerouslySetInnerHTML={{
                __html: description?.length && description[0],
              }}
            ></span>
           
          </span>
         
        </div>
        <br></br>
            <div> <span 
              dangerouslySetInnerHTML={{
                __html: description?.length && description[1],
              }}
            ></span> <TextLink href={href} >{text}</TextLink>
            </div>
      </ModalBody>
      {!!props.isMobile && (
        <ModalFooter
          buttonData={{
            primary: {
              width: "100%",
              children: "Close",
              onClick: () => props.setCAWarningModal(false),
            },
          }}
        />
      )}
      {!props.isMobile && (
        <ModalFooter
          children={
            <div>
              <div>
                <Button onClick={e => handleClick()}>{t("4G-5G.common.page-stepper.close")}</Button>
              </div>
            </div>
          }
        />
      )}
    </Modal>
  );
};
