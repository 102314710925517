/* API URLS */
export const MBT_BASE_URL = window?.mbtGlobal?.marketPlaceHostUrl + window?.mbtGlobal?.marketPlaceHostSuffix

export const PROSPECT_WIDGETS_APP_BASE_PATH = window?.mbtGlobal?.marketPlaceHostUrl ? MBT_BASE_URL : process.env.PROSPECT_WIDGETS_APP_BASE_PATH;

//export const PROSPECT_WIDGETS_APP_BASE_PATH = process.env.PROSPECT_WIDGETS_APP_BASE_PATH;

// GLOBAL URLS
export const CARTCOMP_CART_SAVE_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/save";
export const CARTCOMP_SESSION_CART_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/sessionCart";
export const DEVICE_FIXED_WIRELESS_COVERAGE_CHECK_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"devicesvc/shop/product/fixedWirelessCoverageCheck";
export const DEVICE_META_INFORMATION_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/mbt/prospect/metainformation";

// LQ URLS
export const CARTCOMP_CLEAR_4G_5G_CART_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/clear4g5gcart";
export const CARTCOMP_FETCH_BBA_LQ_DETAILS_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/fetchBBALQDetails";
export const CARTCOMP_FETCH_BBA_LQ_BULK_ADDRESS_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/fetchBBALQDetailsLoopBulkQualAddress";
export const CARTCOMP_LOOP_QUAL_ADDRESS_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/shop/product/loopQualAddress";
export const DEVICE_FIOS_STREET_LOOKUP_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"devicesvc/mbt/prospect/qualify/fios/streetLookup";
export const DEVICE_FIOS_SUBMIT_FIOS_QUALIFICATION_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"devicesvc/mbt/prospect/qualify/fios/submitFiosQualification";
export const DEVICE_FIOS_UNIT_LOOKUP_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"devicesvc/mbt/prospect/qualify/fios/unitLookup";

// ONETALK URLS
export const CARTCOMP_ONETALK_COMBINE_PRICING_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"cartcompsvc/prospect/cart/one-talk/combinePricing";

// 4G & 5G URLS
export const CARTCOMP_ADD_OR_UPDATE_CART_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/shop/product/addOrUpdateCart";
export const CARTCOMP_PLAN_PRESELECTION_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/shop/product/planPreselection";
export const DEVICE_ROUTER_DEVICES_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/shop/product/routerDevices";
export const DEVICE_SIM_VALIDATE_ID_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/mbt/prospect/sim/validateId";
export const DEVICE_VALIDATE_DEVICE_ID_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"devicesvc/mbt/prospect/device/validateDeviceId";
export const PLAN_PLANS_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "plansvc/shop/product/plans";

	export const GET_5G_PLAN =
	PROSPECT_WIDGETS_APP_BASE_PATH + "plansvc/shop/product/plans";
export const GET_4G_PLAN =
PROSPECT_WIDGETS_APP_BASE_PATH + "plansvc/shop/product/plans";
export const GET_5G_ROUTER_DEVICES =
PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/shop/product/routerDevices";
export const GET_4G_ROUTER_DEVICES =
PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/shop/product/routerDevices";
export const FCC_COMBINED_AEM_DATA = PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/mbt/prospect/getCombinedAemData";

// Shopping cart URLS

export const RETRIEVE_PROSPECT_5G_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/retrieve";
export const SESSION_CART_PROSPECT_5G_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/sessionCart";
export const FETCH_CART_API =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/fetchSavedCart";
export const FETCH_META_INFORMATION_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/mbt/prospect/metainformation";
export const FETCH_AEM_INFORMATION_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/mbt/prospect/aem/en-us/";
export const ORDER_QUOTE_FETCH_SAVED_CART_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/fetchSavedCart";
export const UPDATE_CREDIT_READ_AND_SPO_DETAILS =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/updateCreditReadAndSpoDetails";
export const APPLY_PROMO_CODE =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/validateBarcode";
export const REMOVE_ACCESSORY =
	PROSPECT_WIDGETS_APP_BASE_PATH + "accessorysvc/shop/product/accessories";
export const CLEAR_4G5G_CART_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/clear4g5gcart";
export const SHARED_CART_SAVE_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/sharedCart/save";
//Clear Cart
export const UPDATE_CLEAR_CART =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/shop/product/clearCart";

//Save Cart
export const UPDATE_SAVE_CART =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/save";

// lower funnerl

export const VALIDATE_CREDIT_DETAILS_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/skipBusinessInfo/validateCreditDetails";
export const BUSINESS_INFORMATION_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/v1/business-information";
export const SAVE_BUSINESS_INFORMATION_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/saveBusinessInformation";
export const VALIDATE_BUSINESS_INFO_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/validateBusinessInfo";
export const VALIDATE_SSN_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "checkoutsvc/mbt/prospect/validate-ssn";
export const VALIDATE_BUSINESS_CREDIT_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/validateBusinessCreditDetails";
export const RETRIEVE_BUSINESS_CREDIT_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/retrieveBusinessCreditDetails";
export const VALIDATE_BLENDED_CREDIT_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/validateBlendedCreditDetails";
export const RETRIEVE_BLENDED_CREDIT_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/retrieveBlendedBizCreditDetails";
export const RETREIVE_CREDIT_HOLD_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/v1/retrieveCreditHoldCart";
export const SAVE_CREDIT_HOLD_CART_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/saveCreditHoldOrderDetailsAfterSessionExpired";
export const UPDATE_PROSPECT_CREDIT_DETAIL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/updateProspectCreditDetails";
export const UPLOAD_FILE =
	PROSPECT_WIDGETS_APP_BASE_PATH + "checkoutsvc/mbt/prospect/uploadFile";

export const SHIPPING_METHODS_INFO_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"cartcompsvc/prospect/cart/shipping/getShippingMethods";

export const ADDRESS_VALIDATION_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/validateAddress";

export const SEND_SHIPPING_METHOD_INFO_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/shipping";
export const SEND_MULTI_SHIPPING_METHOD_INFO_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/multiShipping";
export const STORE_INFORMATION_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/getStoreDetailsByLocation";

export const INSTALLATION_INFO_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/installApptAvailability";
export const POST_INSTALLATION_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/installationInfo";

export const OTP_PHONE_NUMBER_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/otp/phoneNumber";
export const OTP_SEND_CODE_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/otp/sendCode";
export const OTP_VALIDATE_PASSCODE_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"cartcompsvc/prospect/cart/otp/validatePasscode";

export const DDIA_CHECK_VERIFICATION_RESULT =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"cartcompsvc/prospect/ddia/check-verification-result";

// Mitek

export const MITEK_CHECK_VERIFICATION_RESULT =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"cartcompsvc/prospect/mitek/mitekVerificationStatus";
export const MAATNC_CHECK_VERIFICATION_RESULT =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/reviewAgreements";
export const MITEK_UPDATE_DECLINE_STATUS =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"cartcompsvc/prospect/mitek/updateDeclinedStatus";
export const MITEK_VERIFICATION_SMS =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"cartcompsvc/prospect/mitek/sendMitekVerificationSMS";
export const SEND_MITEK_VERIFICATION =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"cartcompsvc/prospect/mitek/sendCustomNotification";
export const MITEK_QR_CODE =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/mitek/getQRCode";
export const GENERATE_MITEK_LINK =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"cartcompsvc/prospect/mitek/generateMitekVerificationLink";

// Checkout - NpaNxx
export const NPA_NXX_NPA_NXX_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/devices/npanxx";
export const NPA_NXX_PORT_IN_ELIGIBILITY_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"subscribersvc/mbt/prospect/subscriber/portInEligibility";
export const NPA_NXX_RELEASE_MDN_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"subscribersvc/mbt/prospect/subscriber/releaseMdn";
export const NPA_NXX_RETRIEVE_MDN_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"subscribersvc/mbt/prospect/subscriber/retrieveMdn";
export const NPA_NXX_RETRIEVE_NPA_NXX_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"subscribersvc/mbt/prospect/subscriber/retrieveNpaNxx";
export const NPA_NXX_UPDATE_NICKNAME_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/updatenickname";
// MapQuestAPI
export const GET_MAPQUEST_ADDRESS_URL =
	"https://www.mapquestapi.com/search/v3/prediction?key=C8fIN18Qbo5rRaWgbxhJXg3ktbYp3HiV&limit=5&collection=poi,address&q=";
//locus
export const GET_LOCUS_ADDRESS_URL =
	process.env.PROSPECT_WIDGETS_APP_LOCUS_URL +
	"/locus-typeahead/address/typeahead-address?countrycode=USA&";
export const GET_LOCUS_URL = '/locus-typeahead/address/typeahead-loc-unit?locusID='
export const GET_UNITS_URL = process.env.PROSPECT_WIDGETS__APP_GET_UNITS_URL;
export const GET_APIGEE_TOKEN_URL = '/foryoursmallbiz/smb/api/MicroBiz/GetApigeeToken'


export const GET_FIOS_ADDRESS_SEARCH_URL =
	process.env.PROSPECT_WIDGETS_APP_FIOS_ADDRESS_URL;
//MapBox or HereMaps
export const GET_MAPBOX_ADDRESS_URL =
	"https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?apiKey=si8qtAEuJyAsc6mTc54GEX0muboeFkYusK_FYeagkNM";
export const DOCUSIGN_INFO_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/getMAADocUrl";
export const TNCDetail_INFO_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/generateInstallmentContractForProspect";
export const BUSS_TNCDetail_INFO_UC_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"busssvc/mbt/buss/getTsAndCs";
export const ACCEPT_BUSS_TNC_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"busssvc/mbt/buss/acceptTsAndCs";
export const TNCDetail_INFO_UC_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/generateInstallmentContractForUC";
export const GENERATE_PERSONAL_GUARANTEEDOC =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/generatePersonalGuaranteeDoc";
export const ResumeDocusign_INFO_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/resumeEcpdDocs";

// Review
export const REVIEW_CHECK_CART_INVENTORY_STATUS_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"cartcompsvc/prospect/cart/checkCartInventoryStatus";
export const REVIEW_COMPLETE_ORDER_WITHOUT_PAYMENT_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/completeOrderWithoutPayment";
export const REVIEW_PROCESS_ECPD_DETAILS_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/processEcpdDetails";
export const REVIEW_UPDATE_INSTALLMENT_CONTRACT_DETAILS_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/updateInstallmentContractDetails";

// Payment
export const PAYMENT_INITIALIZE_PAYMENT =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/3dsecure/initializePayment";
export const PAYMENT_VALIDATE_AND_SAVE_CARD =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/validateAndSaveCard";
export const GET_SUBMIT_PURCHASE_INFO =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/bussCompleteOrder";

export const PAYMENT_COMPLETE_ORDER =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/completeOrder";
export const UPDATE_BUSS_CHECKOUT_PRICE =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/updateBussCheckoutPrice";
export const PAYMENT_FAILURE_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/updatePymtCounterFail";
export const SEND_PAYMENT_LINK =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/sendClientPaymentLink";
export const RECIEVE_PAYMENT_DETAILS =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/recieveClientPaymentDetails";
// Confirmation
export const GET_PROSPECT_CART_PDF =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/getProspectCartPDF";

export const ONTALK_ENROLLMENT_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/oneTalkEnrollment";

// Checkout - Review agree
export const REVIEW_AND_AGREE_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/storeTermsConditons";

//uc submit
export const UNIFIED_CART_COMPLETE_ORDER_WITHOUT_PAYMENT_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/completeUCWithoutPayment";
export const UNIFIED_CART_COMPLETE_ORDER_PAYMENT_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/checkout/completeUCOrder";
//Emergency
export const VALIDATE_E911_ADDRESS =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/validateE911Address";

// Remove Document
export const DELETE_DIRECTORY =
	PROSPECT_WIDGETS_APP_BASE_PATH + "checkoutsvc/mbt/prospect/deleteDirectory";

//Read Document
export const READ_FILE =
	PROSPECT_WIDGETS_APP_BASE_PATH + "checkoutsvc/mbt/prospect/readFile";

//Account Creation

export const ACCOUNT_CREATION_USERID_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/user/verify/username";

export const ACCOUNT_CREATION_PASS_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/user/validatePasswordRules";

export const ACCOUNT_CREATION_SECURITYQUE_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/profile/registration/getSecurityQuestions";

export const ACCOUNT_CREATION_REGISTER_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "checkoutsvc/mbt/prospect/user/registration";

export const SAVE_AUTHORIZE_INFO_URL =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/saveAuthorizeResponse";

export const LOG_SUSPEND_DATA =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/shop/product/logSuspendData";

//FCC
export const FCC_FWA_TEMPLATE_URL = PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/mbt/prospect/aem/fcc/en-us/FWA/react";
export const FCC_FWA_JSON_URL = PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/mbt/prospect/aem/en-us/fccFwa/react";
export const GET_TAX_CHARGES = PROSPECT_WIDGETS_APP_BASE_PATH + "plansvc/mbt/prospect/plans/calculateTaxAndCharges";
export const FCC_STORE_DETAILS = PROSPECT_WIDGETS_APP_BASE_PATH + "plansvc/mbt/prospect/plans/storeFccDetails";

//wireless

//OneTalk
export const ONETALK_META_INFO =
	PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/mbt/prospect/metainformation";
export const ONETALK_COMBINE_PRICING =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"cartcompsvc/prospect/cart/one-talk/combinePricing";
export const ONETALK_DESKPHONES =
	PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/mbt/prospect/devices/deskphones";
export const ONETALK_GETDEVICE_DETAILS =
	PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/shop/product/deviceDetails";
export const ONETALK_SEARCH_DEVICES =
	PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/mbt/prospect/device/searchDevices";
export const ONETALK_ADD_TO_CART =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/shop/product/addOrUpdateCart";
export const ONETALK_DEVICELIST =
	PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/shop/product/deviceList";
export const ONETALK_GETADDONS =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/shop/product/addons";

export const MOBILITY_ADD_TO_CART =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/shop/product/addOrUpdateCart";
export const MOBILITY_SHARED_CART_SAVE =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/sharedCart/save";
// Mobility Wireless
// export const MOBILITY_SMARTPHONES = PROSPECT_WIDGETS_APP_BASE_PATH + '/devicesvc/mbt/prospect/devices/smartphones';
export const MOBILITY_SMARTPHONES =
	PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/shop/product/deviceList";
export const MOBILITY_SMARTPHONE =
	PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/shop/product/deviceDetails";

export const MOBILITY_COMPARE_DEVICES =
	PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/shop/product/compareDevices";
export const MOBILITY_ZIPCODE =
	PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/mbt/device/bulk/validateZipcode/";
export const STORE_APPOINMENT =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"subscribersvc/mbt/prospect/appointments/loadStore";
export const STORE_SCHEDULE_APPOINMENT =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"subscribersvc/mbt/prospect/appointments/scheduleAppointment";
export const SALES_REP_AVAILABILITY =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"subscribersvc/mbt/prospect/appointments/salesRepAvailability";

// Common
export const GET_REVIEWS = `https://api.bazaarvoice.com/data/reviews.json?apiversion=5.4&passkey=cakJdua1gWc8PzlDyj3EWoFjSPrp7RbtrmyZw3PIxR0Ss&include=Products&stats=Reviews&Filter=ProductId:`;
export const GET_FEEDBACK = `https://api.bazaarvoice.com/data/submitfeedback.json?apiversion=5.4&passkey=cakJdua1gWc8PzlDyj3EWoFjSPrp7RbtrmyZw3PIxR0Ss&ContentType=review&ContentId=`;
export const MOBILITY_DEVICE_LIST =
	PROSPECT_WIDGETS_APP_BASE_PATH + "marketplacesvc/marketplace/getMarketPlaceProductsGridwall";
export const VALIDATE_EMERGENCY_ADDRESS =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"checkoutsvc/mbt/prospect/validateEmergency911Address";

//Plans Page
export const AVAILABLE_PLANS =
	PROSPECT_WIDGETS_APP_BASE_PATH + "mbt/plans/availablePlan";
export const GET_DEVICE_COMPATIBLE_PLANS =
	PROSPECT_WIDGETS_APP_BASE_PATH + "plansvc/shop/product/plans";
export const GET_DEVICE_PROTECTION_PLANS =
	PROSPECT_WIDGETS_APP_BASE_PATH + "featuressvc/shop/product/retrieveFeatures";
// export const GET_DEVICE_PROTECTION_PLANS = PROSPECT_WIDGETS_APP_BASE_PATH + "featuressvc/mbt/prospect/features/deviceProtection";

export const DEVICES = PROSPECT_WIDGETS_APP_BASE_PATH + "devices";
export const GET_DEVICE_PLAN_PROMOS =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"devicesvc/mbt/prospect/device/getDevicePlanPromos";
export const GET_PLANS_AEM =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"devicesvc/mbt/prospect/aem/en-us/plans/react";
export const GET_FEATURES_AEM =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"devicesvc/mbt/prospect/aem/en-us/features/react";

export const GET_DEVICES_COMPATIBLE_PLANS =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"plansvc/mbt/prospect/plans/getDeviceCompatiblePlans";

export const PLAN_FIRST_SELECTLINES =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"cartcompsvc/prospect/cart/planfirst/selectLines";

// Accessories
export const GET_ACCESSORIES =
	PROSPECT_WIDGETS_APP_BASE_PATH + "accessorysvc/mbt/prospect/accessory/all";
export const GET_ACCESSORY_LIST =
	PROSPECT_WIDGETS_APP_BASE_PATH + "accessorysvc/shop/product/accessoryList";
export const GET_ACCESSORY_DETAILS =
	PROSPECT_WIDGETS_APP_BASE_PATH + "accessorysvc/shop/product/accessoryDetails";
// TYS
export const TRANSFER_CASE =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"devicesvc/mbt/prospect/device/transferServiceCases";
export const TRANSFER_CASE_DETAILS =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"devicesvc/mbt/prospect/device/transferServiceCaseDetails";
export const VALIDATE_MTN_DETAILS =
	PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/mbt/device/validateMTN";

// Trade In New
export const VALIDATE_TRADE_IN_NEW_DEVICE =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"cartcompsvc/shop/product/tradein/validateTradeInDevice";
export const GET_TRADE_IN_VALUE =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"cartcompsvc/shop/product/tradein/tradeInValue";
export const UPDATE_CART_TRADE_IN =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/shop/product/addOrUpdateCart";

export const MOBILITY_REMOVE_TRADE_IN =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"cartcompsvc/shop/product/tradein/removeTradeIn";

// Trade In Device List
export const TRADE_IN_DEVICE_LIST =
	PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/shop/product/tradeInDeviceList";

// Trade In valuesList
export const TRADE_IN_VALUES_LIST =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"cartcompsvc/shop/product/tradein/tradeInValue";

export const REMOVE_TRADE_IN =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"cartcompsvc/shop/product/tradein/removeTradeIn";

// DSP Pages

export const USER_DATA =
	PROSPECT_WIDGETS_APP_BASE_PATH + "checkoutsvc/mbt/prospect/dsp/user";
export const DEVICE_PROMO =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"devicesvc/mbt/prospect/aem/en-us/dsp-devicespromo/react";
// export const GET_SHOPPING_DEVICES =
//   PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/mbt/prospect/devices/dsp";
export const USER_DATA_V2 =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/user/validateMembership";

export const GET_SHOPPING_DEVICES =
	PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/shop/product/deviceList";

export const ORDER_STATUS =
	PROSPECT_WIDGETS_APP_BASE_PATH + "orderstatussvc/mbt/prospect/orderdetails";

//funnel analysis
export const PROSPECT_API_FETCH_FALLOUT_SESSION =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"documentUploadsvc/mbt/prospect/readOrderMetrics";

export const PROSPECT_API_FETCH_POSORDER_DETAILS =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"documentUploadsvc/mbt/prospect/retrieveMultiLineItemDetails";
export const VALIDATE_DEVICE_ID =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"devicesvc/mbt/prospect/device/validateDeviceId";
export const VALIDATE_SIM_ID =
	PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/mbt/prospect/sim/validateId";
export const GET_SIM_DETAILS =
	PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/mbt/device/skipImei/getSimDetails";
export const VALIDATE_SIM_TYPE_SELECTION =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"devicesvc/mbt/prospect/device/validateSimTypeSelection";
export const GET_PLAN_PRESELECTION =
	PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/shop/product/planPreselection";
export const GET_BUSS_PRODUCTS =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"cartcompsvc/prospect/cart/devices/getBussProducts";
export const GET_MARKETPLACE_PRODUCTS =
	PROSPECT_WIDGETS_APP_BASE_PATH + "marketplacesvc/marketplace/productPlans";

export const GET_GRIDWALL_PEGA_SUGGESTION =
	PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/shop/product/deviceSuggestions";

//PDP page Email Throttle
export const SEND_EMAIL_THROTTLE =
	PROSPECT_WIDGETS_APP_BASE_PATH +
	"cartcompsvc/prospect/cart/prospectThrottle/auditMetrics";


//Lower funnel checkout
export const GET_LOWER_FUNNEL_SECTIONS = PROSPECT_WIDGETS_APP_BASE_PATH + "cartcompsvc/prospect/cart/getLowerFunnelSections"

//Marketplace
export const MARKETPLACE_AUTHENTICATION =
	PROSPECT_WIDGETS_APP_BASE_PATH + "marketplacesvc/marketplace/checkMarketPlaceAccess";
export const MARKETPLACE_SAVE_PRODUCT =
	PROSPECT_WIDGETS_APP_BASE_PATH + "marketplacesvc/marketplace/saveMarketPlaceProduct";
export const MARKETPLACE_ADD_TO_CART =
	PROSPECT_WIDGETS_APP_BASE_PATH + "marketplacesvc/marketplace/addOrUpdateCartMarketPlaceProduct";
export const MARKETPLACE_PDP =
	PROSPECT_WIDGETS_APP_BASE_PATH + "marketplacesvc/marketplace/getProductDetails";
export const MARKETPLACE_GRIDWALL =
	PROSPECT_WIDGETS_APP_BASE_PATH + "marketplacesvc/marketplace/getMarketPlaceProductsGridwall";
export const MARKETPLACE_LOAD_ACCOUNTS =
	PROSPECT_WIDGETS_APP_BASE_PATH + "marketplacesvc/marketplace/getAccounts";
export const GET_SALES_REP_DETAILS =
	PROSPECT_WIDGETS_APP_BASE_PATH + "marketplacesvc/marketplace/checkout/getSalesRepDetails";
export const INITIALIZE_CHECKOUT =
	PROSPECT_WIDGETS_APP_BASE_PATH + "marketplacesvc/marketplace/checkout/initializeCheckout";	
export const MARKETPLACE_SEND_ACCOUNT =
	PROSPECT_WIDGETS_APP_BASE_PATH + "marketplacesvc/marketplace/fetchAccountByAccountNumber";

export const GET_COMBINED_PLANROUTER_URL = PROSPECT_WIDGETS_APP_BASE_PATH + "devicesvc/shop/product/combinePlansRouter"
