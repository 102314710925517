import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
  } from "react";
  import { useTranslation } from "react-i18next";
  import { useMediaQuery } from "react-responsive";
  import { useDispatch } from "react-redux";
  import {
    AccordionHeader,
    AccordionItem,
    AccordionDetail,
  } from "@vds3/accordions";
  import { TextLink } from "@vds3/buttons";
  import { Body, Title } from "@vds3/typography";
  import { Notification } from "@vds3/notifications";
  import {
    MarginSpacerS,
    MarginSpacerM,
    MarginSpacerXL,
    MarginTopSpacerS,
  } from "common/components/styleTag";
  import EquipmentCard from "./EquipmentCard";
  import {
    ByorDisclaimerModal,
    CAResidentModal,
    EquipmentLearnMoreModal,
  } from "../Modals";
  import IMEICheckModal5G from "pages/Landing5G/components/IMEICheckModal/IMEICheckModal";
  import InternetGatewayModal from "../Modals/InternetGatewayModal";
  import FWAContext from "../fwaContext";
  import { CustomAccordion, AccordionWrapper, AccordionTitleFlex, CustomAccordionItem } from "../styles.js";
  import { EquipmentCardGroup, LinksSection, LinksSubSection } from "./styles";
  import { GridContainer, GridSpanItem } from "../PlanSelection/styles";
  
  const EquipmentSectionReceiver = ({
    backupPlanRouters,
    isCollapsed,
    isEditLTEEquipmentFlow = false,
    isEdit5GEquipmentFlow = false,
    isHavingBackupPlans = false,
    onRouterSelect,
    routers,
    setIsCollapsed,
    setCollapsePayment,
    isfromAuth,
    planDetails,
    selectReceiverErr,
    setSelectReceiverErr,
    starkEnabled =false,
    ...props
  }) => {
    const { flow, primaryPlan, backupPlan, sessionCartData } = useContext(FWAContext);
    const { t } = useTranslation(["app", "4G-5G"]);
    const aemKey = flow === "4g" ? "fourG" : "fiveG";
    const aemContent = t("4G-5G", { ns: "4G-5G", returnObjects: true })[aemKey][
      "router-content"
    ];
    const disableBackupPlansFeature = t("4G-5G", {
      ns: "4G-5G",
      returnObjects: true,
    })[aemKey]["disableBackupPlansFeature"];
    const routerContent = aemContent[flow === "4g" ? "routers" : "router"];
    const isMobile = useMediaQuery({
      query: "(max-width: 545px)",
    });
    const dispatch = useDispatch();
    const [internetGatewayModal, setInternetGatewayModal] = useState(false);
    const [byorDisclaimerModal, setByorDisclaimerModal] = useState(false);
    const [showImeiCheckModal, setImeiCheckModal] = useState(false);
    const [isValidIMEI, setIsValidIMEI] = useState(false);
    const [cAWarningModal, setCAWarningModal] = useState(false);
    const [equipmentLearmMoreModal, setEquipmentLearmMoreModal] = useState(false);
    const [equipmentType, setEquipmentType] = useState("");
    const [reintiaterFlag, setReintiaterFlag] = useState(false);
    const disabledAccordion=(!!primaryPlan?.selectedReceiver);
    const onEquipmentSelection = (planType, e) => {
      setReintiaterFlag(!reintiaterFlag);
      const val = e;
      if (flow === "5g" && val === "byor") {
       setImeiCheckModal(true);
        setEquipmentType(planType);
      } else {
        onRouterSelect(val, planType);
        setIsCollapsed(false);
        if(val !== primaryPlan?.selectedReceiver) {
          setCollapsePayment(false);
        }
        if(isfromAuth){
          setIsCollapsed(false);
        }
      }
    };
  
    useEffect(() => {
      if(primaryPlan.selectedReceiver || backupPlan?.selectedReceiver){
        setSelectReceiverErr(false);
      }
      if (!isfromAuth) {
        (!isEditLTEEquipmentFlow || !isEdit5GEquipmentFlow) && setIsCollapsed(
          false
          // (primaryPlan?.selectedPlan &&
          //   !backupPlan?.selectedPlan &&
          //   primaryPlan?.selectedReceiver) ||
          // (!primaryPlan?.selectedPlan &&
          //   backupPlan?.selectedPlan &&
          //   backupPlan?.selectedReceiver) ||
          // (primaryPlan?.selectedPlan &&
          //   backupPlan?.selectedPlan &&
          //   primaryPlan?.selectedReceiver &&
          //   backupPlan?.selectedReceiver)
        );
      }
    }, [
      primaryPlan.selectedReceiver,
      backupPlan?.selectedReceiver,
      primaryPlan.selectedPlan,
      backupPlan?.selectedPlan,
      reintiaterFlag,
      isEditLTEEquipmentFlow,
      isEdit5GEquipmentFlow,
      isfromAuth
    ]);
  
    const getByorCard = (enableStarkReceiver, planType) => {
      let aemContentByor = aemContent["ownRouter"];
  
      if (isfromAuth) {
        aemContentByor = aemContent["ownRouter"];
      } else if (enableStarkReceiver) {
        aemContentByor = aemContent["ownRouterWithStarkReceiver"];
      }  else if(starkEnabled){
        aemContentByor = aemContent["ownReceiverStarkEnabled"];
      } else if (!props.isCband && flow === "5g") {
        aemContentByor = aemContent["ownRouterWithoutReceiver"];
      } else if (props?.mwwCart) {
        aemContentByor = aemContent["ownRouterWithoutReceiver"];
      }
      const commonPlan = planType === "primaryPlan" ? primaryPlan : backupPlan;
      const isDisabled = commonPlan && commonPlan.quantity && Number(commonPlan.quantity) > 1;
  
      // if (isDisabled && commonPlan.selectedReceiver === "byor") {
      //   dispatch(setSelectedRouter({ planType, value: "" }));
      // }
  
      return (
        <EquipmentCard
          id={"byor"}
          key={"byor"}
          isByor={true}
          price={0}
          strikethroughPrice={aemContentByor?.price}
          description={aemContentByor?.desc1}
          isRecommended={aemContentByor?.recommended}
          quantity={commonPlan?.quantity}
          selected={commonPlan?.selectedReceiver === "byor"}
          onChange={onEquipmentSelection.bind(this, planType)}
          isMobile={isMobile}
          aemContent={aemContentByor}
          isDisabled={isDisabled}
          mwwCart={props?.mwwCart}
          starkEnabled={starkEnabled}
        />
      );
    };
  
    const getEquipRadioBoxes = (planType) => {
      const commonPlan = planType === "primaryPlan" ? primaryPlan : backupPlan;
      const commonRouters =
        planType === "primaryPlan" ? routers : backupPlanRouters;
      let list = [],
        enableStarkReceiver = false;
      if (commonRouters?.deviceList) {
        commonRouters.deviceList.map((router) => {
          const sku = router.skulist[0]?.sku;
          const aemRouter = routerContent[sku];
          const promo =
            router.skulist[0]?.pricingDetails?.devicePayments?.length &&
            router.skulist[0]?.pricingDetails?.devicePayments[0]?.offerDetails;
          const zeroYearPromo =
            router.skulist[0]?.pricingDetails?.zeroYear?.offerDetails;
          const finalPrice =
            promo?.discountAmount ?? zeroYearPromo?.discountAmount ??
            router.skulist[0]?.pricingDetails?.zeroYear?.price;
          const strikethroughPrice = promo?.itemOriginalPrice ?? zeroYearPromo?.itemOriginalPrice;
          list.push(
            <EquipmentCard
              id={starkEnabled ? "receiver" : "router"}
              key={sku}
              price={finalPrice}
              strikethroughPrice={strikethroughPrice}
              description={planType === 'primaryPlan' ? aemRouter?.desc1 : aemRouter?.backDesc}
              isRecommended={aemRouter?.recommended}
              quantity={commonPlan?.quantity}
              selected={commonPlan?.selectedReceiver === "receiver"}
              onChange={onEquipmentSelection.bind(this, planType)}
              isMobile={isMobile}
              imgUri={router.skulist[0]?.filePath}
              aemContent={aemRouter}
              mwwCart={props?.mwwCart}
              starkEnabled={starkEnabled}
              fromReceiver={true}
            />
          );
        });
        list.push(getByorCard(false, planType));
      }
      return list;
    };

    const showInternetGatewayModal = () => {
      let aem = aemContent?.starkReceiverGatewayModal,
        internetGatewayModalHTML = [],
        modalSubHeading,
        showOption0 = false,
        modalTitle = "";
        modalTitle = aem?.title;
      // internetGatewayModalHTML = [
      //   aem?.option1,
      //   aem?.option2,
      // ]
      modalSubHeading = aem?.content?.subHeading
      const modalArr = [
        {
          "subHeading" : modalSubHeading,
          "subContent" : internetGatewayModalHTML
        }
      ]
  
      const handleClick = () => {
        setInternetGatewayModal(false);
      };
  
      return (
        <InternetGatewayModal
          internetGatewayModalHTML={internetGatewayModalHTML}
          modalSubHeading={modalSubHeading}
          modalTitle={modalTitle}
          modalArr={modalArr}
          internetGatewayModal={internetGatewayModal}
          setInternetGatewayModal={setInternetGatewayModal}
          handleClick={handleClick}
          isMobile={isMobile}
          starkEnabled={starkEnabled}
        />
      );
    };
  
    const showBYORDisclaimerModal = () => {
      let aem = aemContent.byodAndByorDisclaimerModal,
        byorDisclaimerModalHTML = [],
        modalSubHeading,
        modalTitle = "";

        modalTitle = aem?.heading;
        const {subHeading,ListItem1,ListItem2,ListItem3} = aem?.content;
        modalSubHeading = subHeading;
        byorDisclaimerModalHTML = [ListItem1, ListItem2, ListItem3];
        
      return (
        <ByorDisclaimerModal
          showBYORDisclaimerModal={byorDisclaimerModal}
          setShowBYORDisclaimerModal={setByorDisclaimerModal}
          isMobile={isMobile}
          modalTitle={modalTitle}
          modalSubHeading={modalSubHeading}
          byorDisclaimerModalHTML={byorDisclaimerModalHTML}
          isMMW={props?.isMMW}
        />
      );
    };
  
    const showLearnMoreModal = () => {
      return (
        <EquipmentLearnMoreModal
          showEquipmentLearnMoreModal={equipmentLearmMoreModal}
          setShowEquipmentLearnMoreModal={setEquipmentLearmMoreModal}
          isMobile={isMobile}
        />
      );
    };
  
    const showProp65WarningModal = () => {
      return (
        <CAResidentModal
          cAWarningModal={cAWarningModal}
          setCAWarningModal={setCAWarningModal}
          isMobile={isMobile}
        />
      );
    };
  
    const onIMEIModalChange = (val) => {
      if (val === false) {
        setImeiCheckModal(false);
        equipmentType && onRouterSelect("byor", equipmentType);
        setIsCollapsed(!isCollapsed);
        setIsValidIMEI(false);
        setCollapsePayment(false);
      }
    };
  
    const selectionDisplayText = useCallback(
      (planType) => {
        const commonPlan = planType === "primaryPlan" ? primaryPlan : backupPlan;
        const commonRouters =
          planType === "primaryPlan" ? routers : backupPlanRouters;
        if (commonPlan?.selectedReceiver) {
          if (commonPlan?.selectedReceiver === "byor") {
            return starkEnabled ? aemContent?.byodandByor : aemContent?.byodStarkHeading
          } else if (commonRouters?.deviceList?.length) {
            const sku = commonRouters?.deviceList[0]?.skulist[0]?.sku;
            let displayName =
              routerContent[sku]?.title && routerContent[sku]?.title.trim();
            // if (displayName && displayName.slice(-6) !== "router") {
            //   displayName += " router";
            // }
            return displayName ?? "";
          }
        }
      },
      [routers, backupPlanRouters, primaryPlan, backupPlan]
    );
  
    const CustomAccordionDetail = (planType) => (
      <>
        {flow === "4g" && (
          <LinksSection>
            <Body>
              {primaryPlan?.quantity > 1 ? (
                <>
                  {aemContent.equipmentLearnMoreModal.descriptionMultiPlans + " "}
                  <TextLink
                    onClick={() => {
                      setEquipmentLearmMoreModal(true);
                    }}
                    size="small"
                    type="standAlone"
                  >
                    {aemContent.learnMore}
                  </TextLink>
                </>
              ) : (
                aemContent.equipmentLearnMoreModal.description
              )}
            </Body>
            <MarginSpacerM />
          </LinksSection>
        )}
        <EquipmentCardGroup
          isMobile={isMobile}
          span={getEquipRadioBoxes(planType)?.length}
        >
          {getEquipRadioBoxes(planType)}
        </EquipmentCardGroup>
        <LinksSection>
          <MarginSpacerXL />
          <LinksSubSection>
            <TextLink
              onClick={() => {
                setInternetGatewayModal(true);
              }}
              size="small"
              type="standAlone"
            >
              {aemContent?.headingStarkReceiverModal}
            </TextLink>
            <TextLink
              onClick={() => {
                setByorDisclaimerModal(true);
              }}
              size="small"
              type="standAlone"
            >
              {aemContent?.byodByorDisclaimarStark}
  
            </TextLink>
          </LinksSubSection>
          {/* <MarginSpacerXL />
          <Body>
            {t("app.common.prop65WarningModal.headingInfo.primary") + " "}
            <TextLink
              onClick={() => {
                setCAWarningModal(true);
              }}
              size="small"
              type="standAlone"
            >
              {t("app.common.prop65WarningModal.headingInfo.secondary")}{" "}
              <b>{t("app.common.prop65WarningModal.headingInfo.warning")}</b>
            </TextLink>
          </Body> */}
        </LinksSection>
      </>
    );
    
    return (
      <>
    
        <AccordionWrapper
          disabledAccordion={false}
        >
          <CustomAccordion surface="light">
            <CustomAccordionItem opened={!isCollapsed} alwaysOpen={disabledAccordion ? false : true}>
              <AccordionHeader
                triggerType={
                  (!!primaryPlan?.selectedReceiver || !!backupPlan?.selectedReceiver) && !isfromAuth
                    ? "link"
                    : ""
                }
                closeAccordionTriggerLabel="Collapse"
                onClick={() => {
                  setIsCollapsed(!isCollapsed);
                }}
                children={
                  <>
                  <AccordionTitleFlex isMobile={isMobile}>
                    <Title size={"large"} bold>
                      {starkEnabled ? ((primaryPlan?.selectedReceiver) ? aemContent?.selectedReceiver : aemContent?.headingReceiver) : (primaryPlan?.selectedRouter) ? aemContent?.selectedRouter : aemContent?.headingRouter}
                    </Title>
                    {!primaryPlan?.selectedPlan && !backupPlan?.selectedPlan && (
                      <MarginTopSpacerS>
                        <Body size="large" color="#6F7171">
                          {aemContent?.requiredPlanSelectionReceiver}
                        </Body>
                      </MarginTopSpacerS>
                    )}
                  </AccordionTitleFlex>
                    {!!isCollapsed && (
                      <>
                        <MarginSpacerS />
                        {primaryPlan?.selectedReceiver && (
                          <Title size="small" color="#6F7171">
                              <span
                                style={{ fontWeight: "bold", paddingRight: 5 }}
                              >
                                {`${starkEnabled ? "Receiver" : flow === "4g"
                                    ? ""
                                    : aemContent?.equipmentCollapseHeading
                                }:`}
                              </span>
                            {selectionDisplayText("primaryPlan")}
                          </Title>
                        )}
                        <MarginSpacerS />
                        {backupPlan?.selectedReceiver && (
                          <Title size="small" color="#6F7171">
                            {isHavingBackupPlans && (
                              <span
                                style={{ fontWeight: "bold", paddingRight: 5 }}
                              >{`${aemContent?.backupEquipmentCollapseHeading}:`}</span>
                            )}
                            {selectionDisplayText("backupPlan")}
                          </Title>
                        )}
                      </>
                    )}
                  </>
                }
              />
              <AccordionDetail
                children={
                  <>
                   {selectReceiverErr ?
                   <> 
                       <Notification
                        type="error"
                        inline={true}
                        fullBleed={true}
                        hideCloseButton={true}
                        title={t("4G-5G.common.page-stepper.receiver-err-msg", {
                          ns: "4G-5G",
                        })}
                        />
                        <MarginSpacerM></MarginSpacerM>
                    </>
                    : <></>}
                    {!!routers && !!primaryPlan?.selectedPlan && (
                      <GridContainer
                        isMobile={isMobile}
                        style={{ rowGap: "1rem" }}
                      >
                        <GridSpanItem
                          span={(!isMobile && isfromAuth) ? getEquipRadioBoxes("primaryPlan")?.length + 1 : 
                            getEquipRadioBoxes("primaryPlan")?.length}
                        >
                          {CustomAccordionDetail("primaryPlan")}
                        </GridSpanItem>
                      </GridContainer>
                    )}
                    {/* {!!primaryPlan?.selectedPlan &&
                      !!backupPlan?.selectedPlan && (
                        <>
                          <div id="backupEquipmentSel">
                            <MarginSpacerXL />
                            <Line type="secondary" />
                            <MarginSpacerXL />
                          </div>
                        </>
                      )} */}
                    {/* {!!backupPlanRouters &&
                      flow === "4g" &&
                      isHavingBackupPlans &&
                      !!backupPlan?.selectedPlan && (
                        <GridContainer
                          isMobile={isMobile}
                          style={{ rowGap: "1rem" }}
                        >
                          <GridSpanItem
                            span={(!isMobile && isfromAuth) ? getEquipRadioBoxes("backupPlan")?.length + 1 :
                            getEquipRadioBoxes("backupPlan")?.length}
                          >
                            {get4gEquipmentTitle(aemContent?.backupEquipHeading)}
                            {CustomAccordionDetail("backupPlan", "", true)}
                          </GridSpanItem>
                        </GridContainer>
                      )} */}
              </>
                }
              />
            </CustomAccordionItem>
          </CustomAccordion>
        </AccordionWrapper>
        
        {showInternetGatewayModal()}
        {showBYORDisclaimerModal()}
        {showProp65WarningModal()}
        {showLearnMoreModal()}
        {/* {showImeiCheckModal && flow === "4g" && (
          <IMEICheckModal
            opened={showImeiCheckModal}
            onOpenedChange={onIMEIModalChange}
            setIsValidIMEI={setIsValidIMEI}
            isfromAuth={isfromAuth}
            isMobile={isMobile}
            sessionCartData={sessionCartData}
  
          />
        )} */}
        {showImeiCheckModal && flow === "5g" && (
          <IMEICheckModal5G
            opened={showImeiCheckModal}
            onOpenedChange={onIMEIModalChange}
            isMobile={isMobile}
            isCband={props.isCband}
            isMMW={props.isMMW}
            setImeiCheckModal={setImeiCheckModal}
            isfromAuth={isfromAuth}
          />
        )}
      </>
    );
  };
  
  EquipmentSectionReceiver.defaultProps = {
    quantity: 1,
  };
  
  export default EquipmentSectionReceiver;
  