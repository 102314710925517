import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@vds3/buttons";
import { Col, Grid, Row } from "@vds3/grids";
import MapQStreetAddress from "pages/Checkout/ShippingInfo/components/MapQStreetAdress";
import { DropdownSelect } from "@vds3/selects";
import { MarginSpacerL, MarginSpacerM } from "../../common/components/styleTag";
import MapQUnit from "pages/Checkout/ShippingInfo/components/MapQUnit";
import {
	setAddress,
	setUnit,
	setMultipleAddress,
	getBulkLQSessionDetails,
	setQualifiedSelectedAddress
} from "pages/Qualification/ducks/QualificationSlice";
import { Body, Title } from "@vds/typography";
import { SeparatorLine } from "common/components/FWA/styles";
import { setCurrentFlow, setLoadFWAQualifiedPlans, setLoadQualficationLanding, setLoadQualficationSelection } from "app/ducks/GlobalSlice";
import SpinnerComponent from "common/components/Spinner/Spinner";

const QualificationLanding = ({ ...props }) => {
	const { t } = useTranslation(["app"]);
	const [newAddress, setNewAddress] = useState();
	const [unitsAvailable, setUnitsAvailable] = useState(null);
	const [units, setUnits] = useState({});
	const [continueClicked, setContinueClicked] = useState(false);
	const dispatch = useDispatch();
	const [numOfLocations, setNumOfLocations] = useState("1");
	const lqPageData = useSelector((state) => state.ProspectWidgets_LQPage);
	const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
	const widgetInputParams = globalData?.widgetInputParams;
	const addresses = lqPageData?.multipleAddresses;


	const handleAddressSubmit = () => {

		const currentUrl = window.location.href;
		const newUrl = new URL(currentUrl);
		newUrl.searchParams.set("pageName", "QualificationSelection");
		window.history.pushState({}, '', newUrl);

		const payload = addresses?.map((address, index) => {

			const addressProperties = address?.[`addressLine${index + 1}`]?.place?.properties
			return {
				addressLine1: addressProperties?.street,
				state: addressProperties?.state,
				city: addressProperties?.city,
				zipCode: addressProperties?.postalCode,
				ecpdId: widgetInputParams?.ecpdId?.value ?? "",
				userId: widgetInputParams?.mbLoginId?.value ?? widgetInputParams?.loginUserId?.value ?? widgetInputParams?.mbaUserId?.value ?? "",
			}
		}
		);
		setContinueClicked(true)
		dispatch(getBulkLQSessionDetails(payload));
		//dispatch(setLoadQualficationSelection(true));
		//dispatch(setLoadQualficationLanding(false));
	}

	useEffect(() => {

		if (lqPageData?.BBALQBulkAddressResp?.response?.prospectBulkLoopQualAddress && continueClicked) {
			const bulkAddressObj = lqPageData?.BBALQBulkAddressResp?.response?.prospectBulkLoopQualAddress
				const bulkAddressArr = Object.values(bulkAddressObj);
			//if (bulkAddressArr.length > 1) {
				dispatch(setLoadQualficationSelection(true));
			dispatch(setLoadQualficationLanding(false));
			dispatch(setMultipleAddress([]))
			setContinueClicked(false)
			//}
			// else {
				
			// 	let qualifedAddress = []
			// 	bulkAddressArr?.map((address) => {
			// 		qualifedAddress.push({
			// 			id: address?.eventCorrelationId,
			// 			"qualified4G": true, // 4g support
			// 			"qualifiedCbandforLoggedin": address?.qualified5GCband, //5g support
			// 			"qualified": null,
			// 			"qualifiedFiosHome": null,
			// 			"eventCorrelationId": address?.eventCorrelationId,
			// 			"address": address?.addressLine1,
			// 			"suiteUnit": address?.unit,
			// 			"city": address?.city,
			// 			"state": address?.state,
			// 			"zipcode": address?.zipCode
			// 		});
			// 	});
			// dispatch(setQualifiedSelectedAddress(qualifedAddress))
			// dispatch(setCurrentFlow(bulkAddressArr?.[0]?.qualified5GCband ? "5g" : "4g"))
			// dispatch(setLoadFWAQualifiedPlans(true));
			// dispatch(setLoadQualficationSelection(false));
			// dispatch(setLoadQualficationLanding(false));
			// const currentUrl = window.location.href;
			// const newUrl = new URL(currentUrl);
			// newUrl.searchParams.set("pageName", "FWAPlansLanding");
			// window.history.pushState({}, '', newUrl);
			// }
		} 
	}, [lqPageData?.BBALQBulkAddressResp]);

	return (
		<>
			{lqPageData?.loading && <SpinnerComponent />}
			<Grid >
				<Row>
					<Col colSizes={{ mobile: 4, tablet: 8, desktop: 8 }}>
						<Grid >
							<Row>
								<Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
									<Title size="large" bold>
										Check eligiblty for up to 5 business locations							</Title>
								</Col>
							</Row>
							<MarginSpacerL />
							<Row>
								<Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
									<Body size="large" color="#6F7171">
										This page uses autocomplete address results to validate service eligiblty. Start typing the address and select from the available results.							</Body>
									<SeparatorLine type="secondary" />

								</Col></Row>

							<Row>
								<Col colSizes={{ mobile: 4, tablet: 4, desktop: 4 }}>
									<DropdownSelect
										label="Number of locations"
										errorText='Number of locations'
										error={false}
										disabled={false}
										readOnly={false}
										inlineLabel={false}
										onChange={(e) => {
											console.log(e.target.value);
											setNumOfLocations(e.target.value)
										}}
									>
										<option>1</option>
										<option>2</option>
										<option>3</option>
										<option>4</option>
										<option>5</option>

									</DropdownSelect>
								</Col>
							</Row>
							<MarginSpacerL />



							{Array.from({ length: numOfLocations }, (_, index) =>
							(
								<Row>
									<MarginSpacerM />
									<Col colSizes={{ mobile: 12, tablet: 6, desktop: 6 }}>
										<MapQStreetAddress
											errorMessage={""}
											label={`Street Address ${index + 1}`}
											onSelectionChange={(value) => {
												setNewAddress(value)
												dispatch(setAddress(value));
												dispatch(setMultipleAddress({
													[`addressLine${index + 1}`]: value
												}))
												setUnitsAvailable(-Infinity);
											}}
											// isError={state?.errorTexts?.homeAdressInfoErr}
											handleAddressInput={(e) => console.log("adddinput", e)}
											isAddressEmty={true}
											setAddressFieldError={(value) => {
												console.log("value", value)
											}}
											isNameOnly
											helperText={"helper"}
										/></Col>

									<Col colSizes={{ mobile: 4, tablet: 4, desktop: 4 }}>
										<MapQUnit
											label="Apt, suite, or unit"
											locusID={addresses?.[index]?.[`addressLine${index + 1}`]?.place?.properties?.locusID}
											ntasAddrID={addresses?.place?.properties?.ntasAddrID}
											onSelectionChange={value => {
												setUnits(value);
												dispatch(setUnit(value));
											}}
											setTotalAvailableUnits={value => {
												if (addresses?.[index]?.[`addressLine${index + 1}`]?.place?.properties) {
													setUnitsAvailable(Number(value));
												}
											}}
										/>


										<MarginSpacerM />



									</Col>


								</Row>
							))}
							<MarginSpacerL />
							<Row>
								<Col colSizes={{ mobile: 4, tablet: 4, desktop: 4 }}>
									<Button
										size="large"
										width="100%"
										disabled={addresses?.length < 1}
										onClick={() => {
											handleAddressSubmit();
										}}
									>
										Check eligibility                </Button>
								</Col>
							</Row>

						</Grid>
					</Col>
				</Row>
			</Grid>
		</>
	)
};

export default QualificationLanding;
