import React from 'react';
const ProgressBar = (props) => {
   const ParentDiv = {
        height: props.height,
        width: props.width,
        backgroundColor: 'silver',
        margin: 5,
        borderRadius:5
    }

    const ChildDiv = {
        height: '100%',
        maxWidth:`${(props?.reviewCount * 100) / props?.totalItems}%`,
        backgroundColor: props.bgColor,
        textAlign: 'right',
        borderRadius:5
    }
    return (
        <div style={ParentDiv}>
            <div style={ChildDiv}>
            </div>
        </div>
    )
}

export default ProgressBar;