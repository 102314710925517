import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Title, Body } from "@vds3/typography";
import { TextLink } from "@vds3/buttons";
import { useGlobalData } from "hooks";
import {
  MarginSpacerS,
  MarginSpacerL,
  MarginSpacerRightM,
} from "../../../../common/components/styleTag";
import { getSection } from "./util";
import CartContext from "pages/ShoppingCart/cartContext";
import CartPromoBanner from "../Promos/CartPromoBanner";
import {
  AccountLevelFeatureTile,
  AccountLevelPromoTile,
} from "../AccountLevelFeatureTile";
import CartDetailsSection from "./CartDetailsSection";
import SavingsNotification from "../Notifications/SavingsNotification";
import { Notification } from "@vds3/notifications";
import { getSavingsData } from "pages/ShoppingCart/util";

const CartDetails = ({ isEditable, overrideCartData,starkEnabled, ...props }) => {
  const { t } = useTranslation(["shoppingCart"]);
  const {
    cartData,
    cartPackages,
    cartProperties,
    isDigitalQuote,
    isMobile,
    setClearCart,
    setSaveCart,
    onBussSelection,
    isOneTalkFlow,
    handleOneTalkFlow,
    oneTalkFlowAdded,
    handleOneTalkFlowAdded,
    handleSelectedPack,
    setOpenAccountModal,
    fromBUSS
  } = React.useContext(CartContext);
  // const itemCount = Object.keys(cartPackages)?.length;
  const { sessionCartData } = useGlobalData();
  // const navigate = useNavigate();
  const cartObj = cartData ?? sessionCartData?.apiResponse?.prospectFlowData;
  const cart = cartObj?.cart;
  const itemCount = cart && cart.packages ? Object.keys(cart.packages).length : 0;
  const showJointOffer = false;
  const isUnifiedCart = !!sessionCartData?.cart?.unifiedCart;
  const isMobilityEdit = window?.mbtGlobal?.flags?.ENABLE_VDS3_SHOPPINGCART_MOBILITY_EDIT === 'Y' ? true : false;
  const isDSP = !!sessionCartData?.dspData?.dspSource;
  const [cartHasFiveG, setCartHasFiveG] = useState(false);
  const [cartHasFourG, setCartHasFourG] = useState(false);
  const [cartHasMobility, setCartHasMobility] = useState(false);
  const [cartHasOneTalk, setCartHasOneTalk] = useState(false);
  const visFeatureCode = t("shopping-cart.vis-feature-code.featureCode", { returnObjects: true });
  const visFeatureCodeEdit = (code) => (visFeatureCode?.length && visFeatureCode?.includes(code));

  const groupPackages = useMemo(() => {
    const cartGroupsInitial = cartData?.cart?.groupPackages;
    const cartAccessories = cartData?.cart?.accessories;
    const groups = {};
   const cartGroups = cartGroupsInitial&& Object.keys(cartGroupsInitial).sort().reduce(
      (obj, key) => { 
        obj[key] = cartGroupsInitial[key]; 
        return obj;
      }, 
      {}
    );
    if (cartGroups && Object.keys(cartGroups)?.length) {
      Object.entries(cartGroups).map(([key, item], index) => {
        const grp = item;
        const packages = [];
        for (const p of item?.packageIds) {
          packages.push({ ...cartData?.cart?.packages[p], packageId: p });
        }
        const pkg = {
          ...grp,
          packages,
          ...packages[0],
        };
        const section = getSection(pkg?.packageType);
        if (groups[section]) {
          groups[section].unshift(pkg);
        } else {
          groups[section] = [pkg];
        }
        if (packages && packages?.length) {
          for (const pkg in packages) {
            const pkgObj = packages[pkg];
            if (pkgObj?.packageType === "FIVEG") {
              setCartHasFiveG(true);
            }
            if (pkgObj?.packageType === "FOURG") {
              setCartHasFourG(true);
            }
            if (pkgObj?.packageType === "REGULAR") {
              setCartHasMobility(true);
            }
            if (pkgObj?.packageType === "ONETALK") {
              setCartHasOneTalk(true);
            }
          }
        }
      });
    }
    if (cartAccessories && Object.keys(cartAccessories)?.length) {
      const section = "ACCESSORIES";
      Object.entries(cartAccessories).map(([key, item], index) => {
        const acc = item;
        if (!item?.hideOnUI && !item?.router) {
          if (groups[section]) {
            groups[section].unshift(acc);
          } else {
            groups[section] = [acc];
          }
        }
      });
    }
    return groups;
  }, [cartData]);

  const savingsData = useMemo(() => {
    const savingsAemData = t("shopping-cart.savings-notification", {
      returnObjects: true,
    });
    return getSavingsData(cart, savingsAemData);
  }, [cartData]);
  const showSavings = (cartHasMobility || cartHasOneTalk) &&
    (savingsData?.totalAmount > 0 || savingsData?.bicOneTimeAmount > 0);
  React.useEffect(()=>{
    if(cart!==null&&cart&&cart.mixedCart!==null&&cart.mixedCart){
      if((cart['mixedcartAppliedWithoutOffers']!==null&&cart['mixedcartAppliedWithoutOffers']&&cart['mixedcartAppliedWithoutOffers'].length > 0)){
        handleOneTalkFlowAdded(true)
        handleSelectedPack(cart['mixedcartAppliedWithoutOffers'])
      }else if(oneTalkFlowAdded) handleOneTalkFlowAdded(false)
    }else if(oneTalkFlowAdded)  handleOneTalkFlowAdded(false)
      
    if(cart!==null&&cart&&cart['mixedCartEligible']!==null&&cart['mixedCartEligible']&&cart['mixedCartEligible'].length > 0){
      handleOneTalkFlow(true)
      handleSelectedPack(cart['mixedCartEligible'])
    } else if(isOneTalkFlow) handleOneTalkFlow(false)
  },[cart])
  return (
    <CartDetailsContainer>
      <Title size="XLarge">{t("shopping-cart.cart.heading")}</Title>
      <MarginSpacerS />
      <LinksFlexContainer>
        <ItemCountDiv>
          <Body size="small">
            {t("shopping-cart.cart.items", { count: itemCount })}
          </Body>
        </ItemCountDiv>
        <TextLink
            size="large"
            type="standAlone"
            onClick={setClearCart?.bind(this, true)}
          >
            {t("shopping-cart.cart.clear")}
          </TextLink>
        {false && (!isUnifiedCart && (cartHasFourG || cartHasFiveG)) || isDSP || isMobilityEdit ? (<TextLinksDiv>
          {!cartData?.cart?.digitalQuote && !isUnifiedCart && (
            <TextLink
              size="large"
              type="standAlone"
              onClick={setSaveCart?.bind(this, true)}
            >
              {t("shopping-cart.cart.save")}
            </TextLink>
          )}
          <MarginSpacerRightM />
          <TextLink
            size="large"
            type="standAlone"
            onClick={setClearCart?.bind(this, true)}
          >
            {t("shopping-cart.cart.clear")}
          </TextLink>
        </TextLinksDiv>) : (
          <></>
        )}
      </LinksFlexContainer>
      <MarginSpacerL />
      {/* <Notification
					fullBleed={true}
					inline={true}
					layout={"horizontal"}
					onCloseButtonClick={() => {}}
					title={t("shopping-cart.shoppingcart-data.checkoutNotification", {
						ns: "shoppingCart",
					})}
					type="info"
				/> */}
      {/* End title section */}
      {!!showSavings && !cartData?.cart?.unifiedCart && <SavingsNotification savingsData={savingsData} />}
      {!!showJointOffer && (
        <CartPromoBanner
          titleText={t("shopping-cart.promo-banner.title")}
          bodyText={t("shopping-cart.promo-banner.description")}
          ctaText={t("shopping-cart.promo-banner.button.text")}
          onCTAClick={() => { }}
        />
      )}
      {cartHasMobility && cartObj?.showBussProducts && (
        <>
          <MarginSpacerS />
          <CartPromoBanner
            titleText={t("shopping-cart.bussProduct.packageHeading")}
            bodyText={t("shopping-cart.bussProduct.packageText")}
            ctaText="Add"
            onCTAClick={onBussSelection}
          />
        </>
      )}
      {!!cartProperties?.AccountLevelFeatures?.length &&
        cartProperties?.AccountLevelFeatures.filter(
          (feature) => feature?.displaySection === "CartPromotion"
        ).map((feature) => (
          <AccountLevelPromoTile
            key={feature?.visFeatureCode}
            feature={feature}
          />
        ))}
      {!cartData?.cart?.unifiedCart &&
        !!cartProperties?.AccountLevelFeatures?.length &&
        cartProperties?.AccountLevelFeatures.filter(
          (feature) => feature?.displaySection !== "CartPromotion"
        ).map((feature) => (
          <AccountLevelFeatureTile
            key={feature?.visFeatureCode}
            feature={feature}
            isEditable={visFeatureCodeEdit(feature?.visFeatureCode)}
            onEditClick={() => {  }}
          />
        ))}
        {fromBUSS ? 
        props?.accountInfo ? 
        <div style={{display:'flex',alignItems:'center'}}>
          <Body size="large" bold={true}>Account Name:</Body>
          <div style={{marginLeft:'5px'}}/>
           <Body size="large">{props?.accountInfo?.response?.businessInfo?.shellAccountNumber}</Body>
           <div style={{marginLeft:'10px'}}/>
          <TextLink onClick = {()=>setOpenAccountModal(true)}>Edit</TextLink>
        </div>
        :
        <Notification
        hideCloseButton = {true}
        type="info"
        title="Select an eligible account you want to add these products to."
        subtitle="Conatct us at 1-866-927-279 if you need any assitance."
        fullBleed={false}
        inline={false}
        disableFocus={true}
        buttonData={[
          {
            children: 'Select account',
            onClick: e => setOpenAccountModal(true)
          }
        ]}
        />:<></>}
      {!!cartPackages && !!Object.keys(cartPackages).length && (
        <>
          {Object.keys(groupPackages).map((key, i) => (
            <CartDetailsSection
              key={key}
              type={key}
              packages={groupPackages[key]}
              isEditable={isEditable}
              showSavings={showSavings}
              lastIndex={i + 1 === Object.keys(groupPackages).length}
              firstIndex = {i===0}
              starkEnabled={starkEnabled}
            />
          ))}
        </>
      )}
    </CartDetailsContainer>
  );
};

const CartDetailsContainer = styled.div`
  margin-bottom: 4rem;
  @media (max-width: 1263px) {
    margin-bottom: 1rem;
  }
`;

const LinksFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ItemCountDiv = styled.div``;
const TextLinksDiv = styled.div``;

export default CartDetails;
