import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Row, Col } from "@vds3/grids";
import { Pagination } from "@vds3/pagination";
import DeviceCard from "../DeviceCard/DeviceCard";
import PromoCard from "../DeviceCard/PromoCard";

import styled from "styled-components";
import { useDispatch } from "react-redux";
import {
	setss7Domains,
	setGWDeviceType,
	setGWPlansID,
} from "app/ducks/GlobalSlice";
import "./Gridwall.css";
import { Body, Title } from "@vds3/typography";
import { Button } from "@vds3/buttons";
import { MarginSpacerL, MarginSpacerS } from "common/components/styleTag";
//import { useNavigate, useSearchParams } from "react-router-dom";

const Gridwall = React.memo(function Gridwall(props) {
	const { t } = useTranslation(["app", "gridwall"]);
	const aem = t("gridwall", { ns: "gridwall", returnObjects: true });
	const aem1 = t("app", { ns: "app", returnObjects: true });
	const dispatch = useDispatch();
	const [pageSelected, handlePageSelected] = useState(1);
	const [pagenoclick, setPagenoclick] = useState("");
	//const [searchParams, setSearchParams] = useSearchParams();
	const paramsPlanId = ""; //(searchParams.get("planId"))?.match(/\d+/)[0];
	if (paramsPlanId) {
		dispatch(setGWPlansID(paramsPlanId));
	}
	const paramsDeviceType = ""; // (searchParams.get("deviceType"));
	if (paramsDeviceType) {
		dispatch(setGWDeviceType(paramsDeviceType));
	}
	const onClickHandler = useCallback(
		(data, selectedSku) =>
			props.onClickHandler(item.seoUrlName, item.deviceId, selectedSku),
		[]
	);

	useEffect(() => {
		try {
			if (
				typeof window["lpTag"] != "undefined" &&
				lpTag?.section &&
				window?.lpTag?.newPage
			) {
				lpTag.section = manipulateLpTagSections();
				setTimeout(() => {
					window?.lpTag?.newPage(window?.location?.href, {
						section: lpTag.section,
					});
				}, 8000);
			}
		} catch (e) {
			console.error(e);
		}
	}, []);

	useEffect(() => {
		dispatch(setss7Domains(aem1?.common?.ss7Domains));
	}, []);
	let isSmartPhones = props?.gridData[0]?.category?.includes("PDA/SmartPhones");
	let isTablets = props?.gridData[0]?.category?.includes("Tablets");
	let isBasicPhones = props?.gridData[0]?.category?.includes("Phones");
	let isMobileHotspots = props?.gridData[0]?.category?.includes(
		"BroadbandAccess Devices"
	);
	let isLaptops = props?.gridData[0]?.category?.includes("Connected Laptops");
	let isSmartWatches =
		props?.gridData[0]?.category?.includes("Smart Watches") ||
		props?.gridData[0]?.category?.includes("Connected Smartwatches");

	const filterProducts = (item)=>{
		if(!!props?.deviceFilters && Object.values(props?.deviceFilters).length){
			let count = 0
			let noFilter = 0
			let allFilter = 0
			Object.values(props?.deviceFilters).map(itemF=>{
				if(itemF.length === 0) noFilter++
				allFilter++
				itemF.map(listItem=>{
				if(listItem?.type === "brandList"){
					if(String(item?.brandName).toLowerCase() === String(listItem?.name).toLowerCase()) count++
				}
				if(listItem?.type === "categoryList"){
					if(String(item?.category).toLowerCase() === String(listItem?.name).toLowerCase()) count++
				}
			})
			})

			if(count > 0 || noFilter === allFilter) return true
			else return false
		}
	return true
	}	
	const getGridwallCards = (selectedPage, isBogo) => {
		let cards = props.gridData.map((item,i) => {
			const inFilter = filterProducts(item)
			if(inFilter && (i < (props?.pageNumber * 18)) && (i >= ((props?.pageNumber-1) * 18))){
			return(
			<Col key={item.seoUrlName} className="product-box-wrap one-talk-product">
				<DeviceCard
					isBuss = {props.isBuss}
					key={item.deviceId}
					data={item}
					aemContent={props.aemContent}
					onClickHandler={props.onClickHandler}
					showDPP={props.showDPP}
					sortBy={props.sortBy}
					onCompareSelect={props.onCompareSelect}
					isSelected={props.selectedCompare.includes(item.deviceId)}
					hidePromoBadge={props.hidePromoBadges}
					ss7Domains={props.ss7Domains}
					isBogo={isBogo || false}
				/>
			</Col>
		)
	}else return <></>
	});
		if (!props?.isBogo) {
			if (props?.byodCard && !isSmartWatches && (isLaptops || isMobileHotspots)) {
				let byodcardplacement = !props?.hotspotPromo
					? cards.slice(0, props?.byodCard?.position - 1)
					: cards.slice(0, props?.byodCard?.position - 1);
				let byodcardplacementalign = !props?.hotspotPromo
					? cards.slice(props?.byodCard?.position - 1)
					: cards.slice(props?.byodCard?.position - 1);
				cards = [
					...byodcardplacement,
					<Col key="byod" className="product-box-wrap">
						<PromoCard
							aemContent={
								isSmartPhones
									? aem?.byod?.smartPhone
									: isTablets
									? aem?.byod?.tablet
									: isBasicPhones
									? aem?.byod?.basicPhone
									: isMobileHotspots
									? aem?.byod?.hotspot
									: isLaptops
									? aem?.byod?.laptops
									: aem["non-smartPhone"]
							}
							type={
								isSmartPhones
									? "smartPhone"
									: isTablets
									? "tablet"
									: isLaptops
									? "laptops"
									: isMobileHotspots
									? "hotspot"
									: isBasicPhones
									? "basicPhone"
									: "non-smartPhone"
							}
							category={props?.gridData[0]?.category}
						/>
					</Col>,
					...byodcardplacementalign,
				];
			}
			if (props?.hotspotPromo) {
				cards = [
					...cards.slice(0, 3),
					<Col key="hotspot-promo">
						<PromoCard aemContent={aem?.hotspot} aem={aem} type="hotspot" />
					</Col>,
					...cards.slice(3),
				];
			} else if (props?.laptopPromo) {
				cards = [
					...cards.slice(0, aem?.connectedLaptops?.position?.laptops),
					<Col key="hotspot-promo">
						<PromoCard
							aemContent={aem?.connectedLaptops}
							aem={aem}
							type="connectedLaptops"
						/>
					</Col>,
					...cards.slice(aem?.connectedLaptops?.position?.laptops),
				];
			} else if (props?.hasMore && !isSmartWatches) {
				let displayPromoCard = true;
				if (
					props.selectedCategory !== null &&
					props.selectedCategory &&
					props.selectedCategory.length
				) {
					displayPromoCard = props.selectedCategory.filter(
						(data) =>
							String(data.name).toLowerCase().includes("watches") ||
							String(data.name).toLowerCase().includes("broadband")
					).length
						? false
						: true;
				}
				if (displayPromoCard) {
					const place =
						props.gridData.length > 2
							? props?.byodCard?.position - 1
							: props.gridData.length;
					cards = [
						...cards.slice(0, place),
						<Col key={"smartphone-promo" + place}>
							<PromoCard
								aemContent={
									isSmartPhones
										? aem?.smartPhone
										: isTablets
										? aem?.tablet
										: isBasicPhones
										? aem?.basicPhone
										: isMobileHotspots
										? aem?.hotspot
										: isLaptops
										? aem?.connectedLaptops
										: aem["non-smartPhone"]
								}
								type={
									isSmartPhones
										? "smartPhone"
										: isTablets
										? "tablet"
										: isBasicPhones
										? "basicPhone"
										: "non-smartPhone"
								}
								category={props?.gridData[0]?.category}
							/>
						</Col>,
						...cards.slice(place),
					];
				}
			}
		}
		return cards;
	};

	const handlePageSelection = (e) => {
		setPagenoclick(Math.random());
		props.loadFunc(e);
	};

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, [pageSelected, pagenoclick]);

	return (
		<>
			<CustomGridWallContainer>
				<Grid colSizes={{ mobile: 2, tablet: 6, desktop: 4 }}>
					<CustomRow>
						{props?.gridData?.length ? (
							getGridwallCards(pageSelected, props?.isBogo)
						) : props.loadEmptyDataContainer ? (
							<div>
								<Title size="medium" bold={false}>
									No results found
								</Title>
								<MarginSpacerS />
								<Body size="large">Clear your filters and start over.</Body>
								<MarginSpacerL />
								<Button
									size="medium"
									disabled={false}
									use="secondary"
									onClick={() => {
										props.onClearFilter();
									}}
								>
									{aem?.labels?.clearAllFilters}
									{/* //Clear all test */}
								</Button>
							</div>
						) : (
							<></>
						)}
					</CustomRow>
				</Grid>
			</CustomGridWallContainer>

			{/* {props.totalResults > aem?.defaultPageInfo?.wireless?.pageSize && (
				<CustomPagination
					selectPage={(e) => {
						handlePageSelected(e);
						handlePageSelection(e);
					}}
					selectedPage={Number(props.pageNumClicked)}
					total={Math.ceil(
						props.totalResults / aem.defaultPageInfo.wireless.pageSize
					)}
					showArrow={true}
				/>
			)} */}
		</>
	);
});

const CustomRow = styled(Row)`
	@media (max-width: 545px) {
		padding: 0px !important;
		margin: 0px !important;
	}
`;

const CustomGridWallContainer = styled.div`
	div > {
		margin: auto !important;
	}
	background-color: #f6f6f6;
	padding-bottom: 1rem;
`;

const CustomPagination = styled(Pagination)`
	margin-left: auto;
	margin-right: -6px;
	@media screen and (min-width: 230px) and (max-width: 767px) {
		margin-left: -1rem;
		margin-right: -5rem;
		margin-top: 1rem;
		position: relative;
	}
`;

export default Gridwall;
