import {
	all,
	put,
	takeLatest,
	select,
	call,
	fork,
	join,
	delay,
} from "redux-saga/effects";
import * as API from "../../../containers/APIurls";
import apiClient from "../../../utils/apiClient";
import * as GlobalSlice from "../../../app/ducks/GlobalSlice";
import * as ShoppingCartSlice from "./ShoppingCartSlice";
import * as QualificationSlice from "../../../pages/Qualification/ducks/QualificationSlice";
import i18next from "i18next";
import i18n from "i18next";
import Utils from "./utils";
import * as selectors from "./selectors";
import * as SiteAnalytics from "../../../sitecatalyst";
// import { navigationRef } from 'AppWrapper';

function* getCheckoutPageInfo() {
	yield put(
		ShoppingCartSlice.setGlobalErrorMessage({ showError: false, message: "" })
	);
	try {
		const globalData = yield select(selectors.globalData);
		const pageData = yield select(selectors.pageData);
		const sessionCartData =
			globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	} catch (err) {}
}

function* getRetrieveCart5GInfo(params) {
	yield put(GlobalSlice.setRetrieveLoader(true));
	yield put(
		ShoppingCartSlice.setIsShoppingCartApiCallInProgress({
			apiName: "getFetchCart5G",
			status: true,
		})
	);
	yield put(
		ShoppingCartSlice.setGlobalErrorMessage({ showError: false, message: "" })
	);
	// const { flow } = params.payload;
	const ZipChangedRequestBody = {
		cartMeta: {
			sourcePath: "ZIPCHANGED",
			zipCode: params?.payload?.zipCode,
		},
		shoppingPath: "PROSPECT",
		approach: "",
		flow: "prospect",
	};
	try {
		const retriveRequestBody = {
			cartMeta: { sourcePath: "CART" },
			shoppingPath: "PROSPECT",
			approach: "",
			flow: (params?.payload ?? params) || "",
		};
		const requestBody =
			params?.payload?.sourcePath === "ZIPCHANGED"
				? ZipChangedRequestBody
				: retriveRequestBody;
		const response = yield apiClient.post(
			API.RETRIEVE_PROSPECT_5G_URL,
			requestBody
		);
		// const retriveCartResponse = yield call(() => Promise.resolve(retriveCartAPI));
		if (response?.data?.status?.statusMessage === "Invalid Zip Code") {
			yield put(ShoppingCartSlice.setinvalidZipCode(true));
		} else {
			yield put(ShoppingCartSlice.setinvalidZipCode(false));
		}
		if (response?.data?.status?.success) {
			yield put(ShoppingCartSlice.setFetchCartResponse(response?.data?.response));
			yield put(ShoppingCartSlice.setFetchCartStatus(response?.data?.status));
			yield* getSessionCartInfo();
			if (window.location.href.indexOf("shopping") > -1) {
				SiteAnalytics.eventShoppingCartInfoTracking(
					"Unified Shopping Cart Page View",
					response?.data?.response,
					"shopping-cart",
					"cart_switch"
				);
			}
			// yield put(ShoppingCartSlice.setRetrieveCart5G(response?.response));
			if (
				params?.payload?.getFeatures !== null &&
				params?.payLoad?.getFeatures &&
				typeof params?.payLoad?.getFeatures == "function"
			)
				yield params?.payload?.getFeatures();
		} else
			throw Object.assign(new Error("Failed to retrieve cart"), {
				status: response?.status,
				data: response?.data,
			});
	} catch (err) {
		yield put(
			ShoppingCartSlice.setSuspendScenario(err?.data?.status?.statusCode)
		);
		yield put(ShoppingCartSlice.setSuspendFlow(true));
		yield put(
			ShoppingCartSlice.setGlobalErrorMessage({ showError: true, message: "" })
		);
		yield put(GlobalSlice.setRetrieveLoader(false));
	} finally {
		yield put(GlobalSlice.setRetrieveLoader(false));
		yield put(
			ShoppingCartSlice.setIsShoppingCartApiCallInProgress({
				apiName: "getFetchCart5G",
				status: false,
			})
		);
	}
}

function* getSessionCartInfo(payload) {
	const pageData = yield select(selectors.pageData);
	yield put(
		ShoppingCartSlice.setIsShoppingCartApiCallInProgress({
			apiName: "getSessionCart",
			status: true,
		})
	);
	yield put(
		ShoppingCartSlice.setGlobalErrorMessage({ showError: false, message: "" })
	);
	try {
		// const { flow } = params.payload;
		const checkoutData = yield select(
			(state) => state.ProspectWidgets_CheckoutData
		);
		let currentSectionName;
		if (checkoutData) {
			if (
				checkoutData?.activeAccordian ===
				checkoutData?.activeAccordionDisplayOrder?.creditResult
			) {
				currentSectionName = "credit-result";
			} else if (
				checkoutData?.activeAccordian ===
				checkoutData?.activeAccordionDisplayOrder?.reviewOrder
			) {
				currentSectionName = "review";
			} else if (
				checkoutData?.activeAccordian ===
				checkoutData?.activeAccordionDisplayOrder?.maa
			) {
				currentSectionName = "agreement";
			} else if (
				checkoutData?.activeAccordian ===
				checkoutData?.activeAccordionDisplayOrder?.shipping
			) {
				currentSectionName = "shipping";
			}
		}
		const requestBody = {
			cartMeta: {
				sourcePath: "CART",
			},
			shoppingPath: "PROSPECT",
			approach: payload?.payload?.approach || "",
			flow: payload?.flow || pageData?.unifiedCartParams?.flow,
			currentSectionName: currentSectionName,
		};

		const response = yield apiClient.post(
			API.SESSION_CART_PROSPECT_5G_URL,
			requestBody
		);

		if (response?.data) {
			const newStore = response?.data?.prospectFlowData
				? Utils.setStoreFromRetrieve(response.data)
				: {};
			yield put(GlobalSlice.setSessionCartData(newStore));
			window?.localStorage?.setItem("_cartData", JSON.stringify(newStore));

			if (
				window.location.href.indexOf("review") > -1 &&
				window["digitalData"]?.isUniqROS
			) {
				SiteAnalytics.eventShoppingCartInfoTracking(
					"Unified Review Order Page Viewed",
					response?.data?.prospectFlowData,
					"review-page",
					"cart_switch"
				);
			}
		} else
			throw Object.assign(new Error("Failed to fetch sessionCart"), {
				status: response?.status,
				data: response?.data,
			});
	} catch (err) {
		yield put(
			ShoppingCartSlice.setGlobalErrorMessage({ showError: true, message: "" })
		);
	} finally {
		yield put(
			ShoppingCartSlice.setIsShoppingCartApiCallInProgress({
				apiName: "getSessionCart",
				status: false,
			})
		);
	}
}

function* getMetaInformationInfo(params) {
	yield put(
		ShoppingCartSlice.setIsShoppingCartApiCallInProgress({
			apiName: "getMetaInformation",
			status: true,
		})
	);
	const { digitalQuote, flow } = params.payload;
	const requestBody = {
		approach: "",
		digitalQuote: digitalQuote ?? false,
		shoppingPath: "PROSPECT",
		flow: flow || "5g",
	};
	try {
		const response = yield apiClient.post(
			API.FETCH_META_INFORMATION_URL,
			requestBody
		);
		if (response.status === 200 && response?.data?.serviceStatus?.success) {
			yield put(ShoppingCartSlice.setMetaInformation(response?.data?.data));
		} else
			throw Object.assign(new Error("Failed to fetch MetaInformation"), {
				status: response?.status,
				data: response?.data,
			});
	} catch (err) {
		// error handling
	} finally {
		yield put(
			ShoppingCartSlice.setIsShoppingCartApiCallInProgress({
				apiName: "getMetaInformation",
				status: false,
			})
		);
	}
}

function* getAEMInformation(params) {
	yield put(
		ShoppingCartSlice.setIsShoppingCartApiCallInProgress({
			apiName: "getAEMInformation",
			status: true,
		})
	);
	const { flow, type } = params.payload;
	const requestBody = {
		cartMeta: { sourcePath: "CART" },
		shoppingPath: "PROSPECT",
		approach: "",
		flow: flow,
	};
	try {
		const response = {};
		const data = yield apiClient
			.post(API.FETCH_AEM_INFORMATION_URL + type, requestBody)
			.then((res) => {
				response = JSON.parse(JSON.stringify(res?.data?.data));
				i18next.addResourceBundle("enUS", type, response, true, true);
			});
		i18n.changeLanguage("enUS");

		yield put(
			ShoppingCartSlice.setIsShoppingCartApiCallInProgress({
				apiName: "getAEMInformation",
				status: false,
			})
		);
	} catch (err) {
		yield put(
			ShoppingCartSlice.setIsShoppingCartApiCallInProgress({
				apiName: "getAEMInformation",
				status: false,
			})
		);
	}
}

function* getFetchCart5GInfo(params) {
	yield put(
		ShoppingCartSlice.setIsShoppingCartApiCallInProgress({
			apiName: "getFetchCart5G",
			status: true,
		})
	);
	yield put(
		ShoppingCartSlice.setGlobalErrorMessage({ showError: false, message: "" })
	);
	const { signature, cartId, quoteId, retrieve, flow } = params.payload;
	const requestBody = {
		cartMeta: { signature: signature },
		cartId: cartId,
		automation: "",
		digitalQuote: retrieve === "digitalQuote" ? true : false,
		digitalQuoteId: quoteId,
		cmpData: "",
		shoppingPath: "PROSPECT",
		approach: "",
		flow: flow,
	};
	try {
		const response = yield apiClient.post(API.FETCH_CART_API, requestBody);
		if (response?.data?.status?.statusCode === 417) {
			yield put(ShoppingCartSlice.setRedirectToClearCart(true));
		} else {
			yield put(
				ShoppingCartSlice.setIsShoppingCartApiCallInProgress({
					apiName: "getFetchCart5G",
					status: false,
				})
			);
		}
		yield put(ShoppingCartSlice.setFetchCartResponse(response?.data?.response));
		yield put(ShoppingCartSlice.setFetchCartStatus(response?.data?.status));
		let sessionCartResponse = {
			prospectFlowData: response?.data?.response,
			serviceStatus: response?.data?.status,
		};
		let packageTypes = [];
		if (response && response.data && response.data.response) {
			const newStore = Utils.setStoreFromRetrieve(sessionCartResponse);
			yield put(GlobalSlice.setSessionCartData(newStore));
			if (response.data.response.cart.packages) {
				for (const key of Object.keys(response.data.response.cart.packages)) {
					packageTypes.push(response.data.response.cart.packages[key].packageType);
				}
			}
			window?.localStorage?.setItem("_cartData", JSON.stringify(newStore));
			window.sessionStorage.setItem("packageTypes", packageTypes);
			// VBGHSA-10921 -  Setting up session attributes for Unified cart and Digital Quote for chat implmentation
			if (retrieve === "digitalQuote") {
				window?.sessionStorage?.setItem("isDigitalQuote", "true");
			}
			if (response.data.response?.cart?.unifiedCart) {
				window?.sessionStorage?.setItem("unified-cart", "yes");
			} else {
				window?.sessionStorage?.setItem("unified-cart", "no");
			}
			const pageData = yield select(selectors.pageData);
		}
		if (!response?.data?.response?.emptyByodFromDQ) {
			const forks = [];
			// forks.push(yield fork(getSessionCartInfo, flow));
			forks.push(yield fork(getRetrieveCart5GInfo, flow));
			forks.push(yield fork(getMetaInformationInfo, params));
			yield join([...forks]);
		}

		if (response?.data?.response?.cart?.abandonedCart) {
			const reqPayload = {
				approach: "",
				flow: "prospect",
				shoppingPath: "PROSPECT",
			};
			yield put(
				ShoppingCartSlice.setIsShoppingCartApiCallInProgress({
					apiName: "getFetchCart5G",
					status: true,
				})
			);
			const abandonedResponse = yield apiClient.post(
				API.UPDATE_CREDIT_READ_AND_SPO_DETAILS,
				reqPayload
			);
			if (abandonedResponse && abandonedResponse?.data) {
				yield put(
					ShoppingCartSlice.setIsShoppingCartApiCallInProgress({
						apiName: "getCreditReadAndSpoDetails",
						status: true,
					})
				);
			}
		}
		if (response?.data?.response?.cart?.packages) {
			const packages = response?.data?.response?.cart?.packages;
			const filteredPackages = Object.values(packages)?.filter(
				(pack) => pack?.byod && !pack?.byor && pack?.emptyByodFromDQ
			);
			if (filteredPackages?.length === Object.keys(packages)?.length) {
				yield put(GlobalSlice.setIsEditPackageFlow(true));
				//navigationRef?.current("/shop/products/devices/smartphones/byod");
			}
		}
	} catch (err) {
		// yield put(ShoppingCartSlice.setSuspendFlow(true));
		yield put(
			ShoppingCartSlice.setGlobalErrorMessage({ showError: true, message: "" })
		);
	} finally {
		yield put(
			ShoppingCartSlice.setIsShoppingCartApiCallInProgress({
				apiName: "getFetchCart5G",
				status: false,
			})
		);
	}
}

function* postSharedCartSaveUrl() {
	const pageData = yield select(selectors.pageData);
	try {
		const requestBody = {
			shoppingPath: "PROSPECT",
			approach: "",
			flow: pageData?.unifiedCartParams?.flow,
		};

		const response = yield apiClient.post(API.SHARED_CART_SAVE_URL, requestBody);
		// yield put(ReviewSlice.setProspectCartPDF(response.data));
		if (response) {
			const cartId = response?.cartID;
			yield put(ShoppingCartSlice.setCartId(response?.data?.cartID));
		}
		// end live API
	} catch (err) {
		// error handling
	}
}

function* postClearCart(params) {
	yield put(
		ShoppingCartSlice.setIsShoppingCartApiCallInProgress({
			apiName: "getFetchCart5G",
			status: true,
		})
	);
	const pageData = yield select(selectors.pageData);
	const lqData = yield select(selectors.lqData);
	const flow = pageData?.unifiedCartParams?.flow ?? lqData?.flow;
	const isDSP = !!pageData?.fetchCart?.dspData;
	let response;
	try {
		const requestBody = {
			shoppingPath: "PROSPECT",
			approach: "",
			flow: flow,
		};
		if ((flow == "4g" || flow == "5g") && !isDSP) {
			response = yield apiClient.post(API.CLEAR_4G5G_CART_URL, requestBody);
		} else {
			response = yield apiClient.post(API.UPDATE_CLEAR_CART, requestBody);
		}
		// yield put(ReviewSlice.setProspectCartPDF(response.data));
		yield put(ShoppingCartSlice.setCleartCartDetails(response));
		yield put(GlobalSlice.setSessionCartData(""));
		// end live API
		if (params?.payload?.routeToDevices) {
			params?.payload?.routeToDevices();
		}
	} catch (err) {
		// error handling
		yield put(ShoppingCartSlice.setCartApiError(true));
	} finally {
		yield put(
			ShoppingCartSlice.setIsShoppingCartApiCallInProgress({
				apiName: "getFetchCart5G",
				status: false,
			})
		);
	}
}

function* postSaveCart(params) {
	yield put(
		ShoppingCartSlice.setIsShoppingCartApiCallInProgress({
			apiName: "getFetchCart5G",
			status: true,
		})
	);
	const requestBody = params?.payload;
	try {
		const response = yield apiClient.post(API.UPDATE_SAVE_CART, requestBody);
		// yield put(ReviewSlice.setProspectCartPDF(response.data));
		if (response) {
			yield put(ShoppingCartSlice.setSaveCartSuccess(response));
			yield put(ShoppingCartSlice.setCartId(response?.data?.cartId));
			SiteAnalytics.eventSaveCartInfoTracking(
				"Save Cart Success",
				response,
				"save cart pop-up",
				""
			);
		}
		// end live API
	} catch (err) {
		// error handling
	} finally {
		yield put(
			ShoppingCartSlice.setIsShoppingCartApiCallInProgress({
				apiName: "getFetchCart5G",
				status: false,
			})
		);
	}
}

function* accessoriesRemove() {
	const pageData = yield select(selectors.pageData);
	const flow = pageData?.unifiedCartParams?.flow;
	try {
		const requestBody = {
			accessories: null,
			shoppingPath: "PROSPECT",
			cartMeta: { sourcePath: "CART" },
			approach: "",
			flow: flow,
		};

		const response = yield apiClient.post(API.REMOVE_ACCESSORY, requestBody);
		// yield put(ReviewSlice.setProspectCartPDF(response.data));
		if (response) {
			yield* getRetrieveCart5GInfo(flow);
		}
		// end live API
	} catch (err) {
		// error handling
	}
}

function* postApplyPromoCode(params) {
	yield put(ShoppingCartSlice.setPromoCodeLoading(true));
	const pageData = yield select(selectors.pageData);
	const lqData = yield select(selectors.lqData);
	const flow = pageData?.unifiedCartParams?.flow ?? lqData?.flow;
	const barCode = params?.payload;
	try {
		const requestBody = {
			barcodeId: barCode,
			shoppingPath: "PROSPECT",
			cartMeta: { sourcePath: "CART" },
			approach: "",
			flow: flow,
		};

		const response = yield apiClient.post(API.APPLY_PROMO_CODE, requestBody);
		yield put(ShoppingCartSlice.setPromoCodeDetails(response.data));
		// yield put(ReviewSlice.setProspectCartPDF(response.data));
		if (response?.data?.status?.success) {
			yield* getRetrieveCart5GInfo(flow);
		}
		// end live API
		yield put(ShoppingCartSlice.setPromoCodeLoading(false));
	} catch (err) {
		// error handling
		yield put(ShoppingCartSlice.setPromoCodeLoading(false));
	}
}

function* packageRemove() {
	yield put(QualificationSlice.setLoopQualLoading(true));
	const globalData = yield select(selectors.globalData);
	const pageData = yield select(selectors.pageData);
	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	// const flow = pageData?.unifiedCartParams?.flow;
	const packages = sessionCartData?.cart?.packages;
	const lqData = yield select(selectors.lqData);
	const zip = lqData?.address?.place?.properties?.postalCode?.split("-");
	const lines = [];
	for (const pkg in packages) {
		const pkgObj = packages[pkg];
		if (pkgObj?.packageType === "FIVEG") {
			lines.push({
				operation: "DELETE",
				packageId: pkg,
				packageType: "FIVEG",
			});
		}
	}
	try {
		const requestBody = {
			cartMeta: {
				zipCode: zip[0],
				shoppingPath: "prospect",
			},
			lines,
		};
		const response = yield apiClient.post(
			API.CARTCOMP_ADD_OR_UPDATE_CART_URL,
			requestBody
		);
		yield put(QualificationSlice.setLoopQualLoading(false));
		if (response?.data?.status?.statusCode === 200) {
			yield put(ShoppingCartSlice.setRedirectTo4gPostAddressValidation(true));
		} else {
			yield put(
				ShoppingCartSlice.setSuspendScenario(response?.data?.status?.statusCode)
			);
			yield put(ShoppingCartSlice.setSuspendFlow(true));
		}
	} catch (err) {
		yield put(QualificationSlice.setLoopQualLoading(false));
	}
}

function* packageRemoveCband() {
	yield put(QualificationSlice.setLoopQualLoading(true));
	const globalData = yield select(selectors.globalData);
	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	const packages = sessionCartData?.cart?.packages;
	const lqData = yield select(selectors.lqData);
	const zip = lqData?.address?.place?.properties?.postalCode?.split("-");
	const lines = [];
	for (const pkg in packages) {
		if (sessionCartData.cart.cbandCart === true) {
			lines.push({
				operation: "UPDATE",
				packageId: pkg,
				packageType: "FIVEG",
			});
		}
	}
	try {
		const requestBody = {
			cartMeta: {
				zipCode: zip[0],
				shoppingPath: "prospect",
				flow: "5g",
			},
			lines,
		};

		const response = yield apiClient.post(
			API.CARTCOMP_ADD_OR_UPDATE_CART_URL,
			requestBody
		);
		yield put(QualificationSlice.setLoopQualLoading(false));
		if (response?.data?.status?.statusCode === 200) {
			yield put(ShoppingCartSlice.setRedirectTo5gPostAddressValidation(true));
			yield put(ShoppingCartSlice.setHasCbandPresent(false));
		} else {
			yield put(
				ShoppingCartSlice.setSuspendScenario(response?.data?.status?.statusCode)
			);
			yield put(ShoppingCartSlice.setSuspendFlow(true));
		}
	} catch (err) {
		yield put(QualificationSlice.setLoopQualLoading(false));
	}
}

function* updatePackages(params) {
	try {
		yield put(ShoppingCartSlice.setUpdatedPackages(params?.payload));
	} catch (err) {
		// error handling
	}
}

function* getOrderQuoteFetchSavedCart(params) {
	const globalData = yield select((state) => state.ProspectWidgets_GlobalData);
	const lqData = yield select((state) => state.ProspectWidgets_LQPage);
	yield put(
		ShoppingCartSlice.setIsShoppingCartApiCallInProgress({
			apiName: "getFetchCart5G",
			status: true,
		})
	);
	yield put(QualificationSlice.setLoopQualLoading(true));
	const { cartId, signature, flow, intend } = params.payload;
	const requestBody = {
		approach: "",
		automation: "",
		cartId: cartId,
		cartMeta: {
			signature: null,
			email: null,
			locationCode:
				globalData?.widgetInputParams?.locid?.value ||
				lqData?.BBALQResp?.locationId,
			salesCode: null,
			checkOutLocationId:
				globalData?.widgetInputParams?.checkOutLocationCode?.value ||
				globalData?.widgetInputParams?.checkoutlocationcode?.value ||
				lqData?.BBALQResp?.checkOutLocationId,

			userName:
				globalData?.widgetInputParams?.userid?.value || lqData?.BBALQResp?.userName,
		},
		cmpData: null,
		flow: null,
		reviewQuote: intend === "review" ? true : false,
		shoppingPath: "PROSPECT",
		vendorName: "Optix",
		digitalQuote: false,
		autoSavedCart: true,
		// optionalMeta: {
		// 	present: true,
		// },
	};
	try {
		const payload = {
			approach: "",
			flow: params?.payload?.flow || "5g",
			shoppingPath: "PROSPECT",
			launchType: "new",
		};
		// const cartResp = yield apiClient.post(
		// 	API.CARTCOMP_CLEAR_4G_5G_CART_URL,
		// 	payload
		// );

		//yield put(QualificationSlice.setClear4g5gCartResp(cartResp?.data));
		const response = yield apiClient.post(
			API.ORDER_QUOTE_FETCH_SAVED_CART_URL,
			requestBody
		);
		if (response?.data?.response?.cart?.abandonedCart) {
			const reqPayload = {
				approach: "",
				flow: "prospect",
				shoppingPath: "PROSPECT",
			};
			yield put(
				ShoppingCartSlice.setIsShoppingCartApiCallInProgress({
					apiName: "getFetchCart5G",
					status: true,
				})
			);
			const abandonedResponse = yield apiClient.post(
				API.UPDATE_CREDIT_READ_AND_SPO_DETAILS,
				reqPayload
			);
			if (abandonedResponse && abandonedResponse?.data) {
				yield put(
					ShoppingCartSlice.setIsShoppingCartApiCallInProgress({
						apiName: "getCreditReadAndSpoDetails",
						status: true,
					})
				);
			}
		}
		yield put(
			ShoppingCartSlice.setIsShoppingCartApiCallInProgress({
				apiName: "getFetchCart5G",
				status: false,
			})
		);
		yield put(QualificationSlice.setLoopQualLoading(false));
		yield put(
			ShoppingCartSlice.setOrderQuoteFetchSavedCartResponse(response?.data)
		);
		let sessionCartResponse = {
			prospectFlowData: response?.data?.response,
			serviceStatus: response?.data?.status,
		};
		let packageTypes = [];
		if (response && response.data && response.data.response) {
			yield put(GlobalSlice.setIsOrderQuoteFlow(true));
			const newStore = Utils.setStoreFromRetrieve(sessionCartResponse);
			yield put(GlobalSlice.setSessionCartData(newStore));
			if (response.data.response.cart.packages) {
				for (const key of Object.keys(response.data.response.cart.packages)) {
					packageTypes.push(response.data.response.cart.packages[key].packageType);
				}
			}
			window?.localStorage?.setItem("_cartData", JSON.stringify(newStore));
			window?.sessionStorage?.setItem("packageTypes", packageTypes);
			window?.sessionStorage?.setItem("isOrderQuote", "yes");
			if (response.data.response?.cart?.unifiedCart) {
				window?.sessionStorage?.setItem("unified-cart", "yes");
			} else {
				window?.sessionStorage?.setItem("unified-cart", "no");
			}
			const pageData = yield select(selectors.pageData);
		}
	} catch (err) {
	} finally {
		yield put(
			ShoppingCartSlice.setIsShoppingCartApiCallInProgress({
				apiName: "getFetchCart5G",
				status: false,
			})
		);
		yield put(QualificationSlice.setLoopQualLoading(false));
	}
}

export default function* actionWatcher() {
	yield takeLatest(ShoppingCartSlice.getPageInfo.type, getCheckoutPageInfo);
	yield takeLatest(
		ShoppingCartSlice.getRetrieveCart5G.type,
		getRetrieveCart5GInfo
	);
	yield takeLatest(
		ShoppingCartSlice.getMetaInformation.type,
		getMetaInformationInfo
	);
	yield takeLatest(ShoppingCartSlice.getAEMInformation.type, getAEMInformation);
	yield takeLatest(ShoppingCartSlice.getFetchCart5G.type, getFetchCart5GInfo);
	yield takeLatest(ShoppingCartSlice.getSessionCart.type, getSessionCartInfo);
	yield takeLatest(
		ShoppingCartSlice.postSharedCartSaveUrl.type,
		postSharedCartSaveUrl
	);
	yield takeLatest(ShoppingCartSlice.postClearCart.type, postClearCart);
	yield takeLatest(ShoppingCartSlice.postSaveCart.type, postSaveCart);
	yield takeLatest(ShoppingCartSlice.accessoriesRemove.type, accessoriesRemove);
	yield takeLatest(ShoppingCartSlice.packageRemove.type, packageRemove);
	yield takeLatest(
		ShoppingCartSlice.packageRemoveCband.type,
		packageRemoveCband
	);
	yield takeLatest(
		ShoppingCartSlice.postApplyPromoCode.type,
		postApplyPromoCode
	);
	yield takeLatest(ShoppingCartSlice.updatePackages.type, updatePackages);
	yield takeLatest(
		ShoppingCartSlice.getOrderQuoteFetchSavedCart.type,
		getOrderQuoteFetchSavedCart
	);
}
