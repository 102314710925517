export const getCategoryName = (deviceCategory) => {
  const cat = deviceCategory?.toLowerCase();
  const val =
    cat?.includes("smartphone")
      ? "Smartphones"
      : cat?.toLowerCase()?.includes("laptop")
      ? "Connected Laptops"
      : cat?.toLowerCase()?.includes("tablet")
      ? "Tablets"
      : cat?.toLowerCase()?.includes("broadbandaccess")
      ? "Business Hotspot Devices"
      : cat?.toLowerCase()?.includes("5g connected device")
      ? "5G Connected Devices"
      : cat;
  return val;
}

export const getUniqueCategories = (deviceArr) => {
  let cat = [];
  if (deviceArr?.length) {
    deviceArr.forEach((dev) => {
      const deviceType = getCategoryName(dev?.deviceCategory);
      if (cat.indexOf(deviceType) === -1) {
        cat.push(deviceType);
      }
    });
  }
  return cat;
};

export const getCartType = (cartData) => {
  let cartType = "";
  const devices = Object.values(cartData.devices);
  const otherTypes = devices.find(
    (device) =>
      device.deviceFullDetail.userSelectedPayment === "RETAIL" ||
      device.deviceFullDetail.userSelectedPayment === "MONTH_TO_MONTH" ||
      device.deviceFullDetail.isByod
  );
  const contractTypes = devices.find(
    (device) => device.deviceFullDetail.userSelectedPayment === "TWO_YEAR"
  );
  if (otherTypes && contractTypes) {
    cartType = "mixed";
  } else if (otherTypes) {
    cartType = "other";
  } else if (contractTypes) {
    cartType = "onlyContract";
  }
  return cartType;
};

export const areSameType = (cat1, cat2) => {
  if (cat1 && cat2) {
    return (cat1 === cat2) 
      || (cat1?.toLowerCase()?.includes("smartphone") && cat2?.toLowerCase()?.includes("smartphone"))
      || (cat1?.toLowerCase()?.includes("tablet") && cat2?.toLowerCase()?.includes("tablet"))
      || (cat1?.toLowerCase()?.includes("laptop") && cat2?.toLowerCase()?.includes("laptop"))
  }
  return false;
}