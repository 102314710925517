import React, { Fragment, useEffect, useMemo, useReducer, useRef, useState } from "react";
import VerticalProgressContext from "common/components/VerticalProgress/verticalProgressContext";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { getShippingMethods, postShippingMethod, setSavedAddressesWithDevices, getStoreData, setMultiShippingDetails, setShippingResponse, setShippingCode, setShipAddressesWithDevices, getSessionCart } from "../../../Checkout/ducks/CheckoutSlice";
import SpinnerComponent from "common/components/Spinner/Spinner";
import { Col, Grid, Row } from "@vds3/grids";
import styled from "styled-components";
import { MarginSpacerL, MarginSpacerM, MarginSpacerS, MarginSpacerXS } from "common/components/styleTag";
import { Body } from "@vds3/typography";
import { useImageUtils } from "hooks";
import { RadioBoxGroup } from "@vds3/radio-boxes";
import { Line } from "@vds3/lines";
import { Button, TextLink } from "@vds3/buttons";
import AddNewAddress from "./AddNewAddress";
import { v4 } from "uuid";
import { Icon } from "@vds3/icons";
import { setEditableSections } from "pages/CheckoutNew/ducks/LowerFunnelSlice";
import { checkIfAemExists } from "utils/commonUtils";
import { Notification } from "@vds3/notifications";
import * as SiteAnalytics from "../../../../sitecatalyst";
// import sessioncart from "./sessioncart.json"
import cloneDeep from "lodash/cloneDeep";
import { UnorderedList, ListItem } from "@vds3/lists";
import { Tooltip } from "@vds3/tooltips";

const Shipping = ({ ...props }) => {
    const {
        isComplete,
        onComplete,
        setModifier,
        isEditMode: { isEditMode, setIsEditMode }
    } = React.useContext(VerticalProgressContext);
    const dispatcher = useDispatch();
    const { t, i18n } = useTranslation(["app", "shipping", "checkout", "mock-response"]);
    const hideSDD = t("delivery.hideSDD", { ns: "shipping", returnObjects: true });
    const shippingOptionsAem = t("checkout.shipping.shippingMethods", { ns: "checkout", returnObjects: true });
    const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
    const pageData = useSelector((state) => state.ProspectWidgets_CheckoutData);
    const useMock = checkIfAemExists(t("shipping-method", { ns: "mock-response" }));
    const sessionCartData = globalData?.sessionCartData?.apiResponse?.prospectFlowData;
    // const sessionCartData = sessioncart?.prospectFlowData;
    let businessAddress = sessionCartData?.businessInfo?.address;
    const isOrderToQuote = sessionCartData?.cart?.reviewQuote;
    const showMultiShippingRouterModal = t("delivery.showMultiShippingRouterModal", { ns: "shipping", returnObjects: true });
    const packagesAllowedToAnotherAddress = t("checkout.shipping.packagesAllowedToAnotherAddress", { ns: "checkout", returnObjects: true });
    if (useMock) {
        const address = String(pageData?.aboutBusinessInfor?.businessStreetAddress?.displayString).split(',')
        businessAddress = {
            ...businessAddress,
            addressLine1: address[0],
            addressLine2: address[1],
            city: address[address.length - 4],
            state: address[address.length - 3],
            zipCode: address[address.length - 2],
            streetNum: String(address[0]).split(' ')[0],
            streetName: pageData?.aboutBusinessInfor?.businessStreetAddress?.name
        }
    }

    const loopQualAddress = sessionCartData?.prospect5gLoopQualAddress;
    //const packages = sessionCartData?.cart?.packages;
    const is4GFlow = pageData?.flowFlags?.is4G || false;
    const isLoading = pageData?.isShoppingCartApiCallInProgress?.getShippingMethods;
    const isMultiShipping = sessionCartData?.cart?.multishippingEnabled;
    const addLine2 = sessionCartData?.businessInfo?.address?.addressLine2;
    const isMobile = useMediaQuery({
        query: "(max-width: 545px)",
    });
    const shipAddressesWithDevices = pageData?.shipAddressesWithDevices;
    const [addressesWithDevices, setAddressesWithDevices] = useState([]);
    const [shippingMethodsUpdated, setShippingMethodsUpdated] = useState({});
    const [refreshShippingMethods, setRefreshShippingMethods] = useState(false);
    const [haveDevices, setHaveDevices] = useState(false);
    const [haveRouters, setHaveRouters] = useState(false);
    const [showNewAddressModal, setShowNewAddressModal] = useState(false);
    const [currentAddressItem, setCurrentAddressItem] = useState({});
    const imageCDN = "//ss7.vzw.com/is/image/VerizonWireless/";
    const [summaryMessage, setSummaryMessage] = useState(false);
    const devicesInOrder = { "PDA/SmartPhones": 1, "Tablets": 2, "Connected Laptops": 3, "BroadbandAccess Devices": 4, "Smart Watches": 5, "Phones": 6, "Connected Devices": 10 }
    const [showMultiShippingWarning, setShowMultiShippingWarning] = useState(false);
    const [backOrder, setBackOrder] = useState(false)
    const isStateIncludes = (methods, state) => {
        const filteredmethods = Array.from(methods).filter((method) => {
            let inEligibleState = method?.ineligibleStates?.includes(state);
            if (!inEligibleState) {
                return method;
            }
        });
        return filteredmethods;
    }
    const isSDDOptions = (methods) => {
        const filteredmethods = Array.from(methods).filter((method) => {
            let isSDDShipping = method?.shippingCode === "SDD_SAMEDAY";
            if (!isSDDShipping) {
                return method;
            }
        });
        return filteredmethods;
    }

    useEffect(() => {
        try {
            if (typeof window['lpTag'] != 'undefined' && lpTag?.section && window?.lpTag?.newPage) {
                lpTag.section = manipulateLpTagSections();
                lpTag.section.push("sec:shipping")
                setTimeout(() => {
                    window?.lpTag?.newPage(window?.location?.href, {
                        section: lpTag.section,
                    });
                }, 8000);
            }
        } catch (e) {
            console.error(e);
        }
    }, []);

    useEffect(() => {
        // call getShippingMethods API when component is opened
        if (refreshShippingMethods && !isLoading) {
            let addressCountMap = prepareAddressCountMap();
            dispatcher(getShippingMethods({ addressCountMap: addressCountMap, multiShipping: true, useMock: useMock }));
        }
    }, [refreshShippingMethods]);

    useEffect(() => {
        if (shippingMethodsUpdated) {
            onSelectedShipping();
            setShippingMethodsUpdated(false);
            if ((sessionCartData?.shipping?.shippingCode || isMultiShipping && sessionCartData?.multiShippingAddress) && addressesWithDevices?.length > 0)
                dispatcher(setSavedAddressesWithDevices({ addressesWithDevices: addressesWithDevices }));
        }
    }, [shippingMethodsUpdated]);

    useEffect(() => {
      //  SiteAnalytics.dynamicComponentRendered({ order: 11, sectiontName: "Shipping and payment", subSection: "Shipping details", isEditMode: false });
        const element = document.getElementById("shipping");
        element?.scrollIntoView({ behavior: "smooth", block: "start" });
        dispatcher(getSessionCart());
    }, []);

    useEffect(() => {
        if (!isEditMode && Object.keys(pageData?.savedAddressesWithDevices?.addressesWithDevices || {}).length) {
            setAddressesWithDevices(pageData?.savedAddressesWithDevices?.addressesWithDevices)
        }
    }, [isEditMode])

    useEffect(() => {
        let addressAndDevices = JSON.parse(JSON.stringify(addressesWithDevices));
        let shippingOptionForAddress = pageData?.shippingMethodsInfo?.shippingMethodResponse?.shippingOptionForAddress;
        if (shippingOptionForAddress && addressAndDevices?.length > 0) {
            const updatedAddressAndDevices = addressAndDevices.map(addressItem => {
                Object.keys(shippingOptionForAddress || {}).map((key) => {
                    if (key === addressItem?.addressName?.replaceAll(" ", "_")) {
                        let shippingOptions = shippingOptionForAddress[key]?.shippingopt;
                        shippingOptions = Array.from(shippingOptions).sort((a, b) =>
                            a.shippingCost > b.shippingCost ? 1 : -1
                        );
                        if (hideSDD) {
                            shippingOptions = isSDDOptions(shippingOptions);
                        }
                        shippingOptions = shippingOptions.map(data => ({
                            ...data,
                            ...shippingOptionsAem.find(o => o.shippingCode === data.shippingCode)
                        }))
                        addressItem.shippingMethods = shippingOptions;
                    }
                })
                return { ...addressItem };
            })
            dispatcher(setShippingResponse({}));
            setRefreshShippingMethods(false);
            setAddressesWithDevices(updatedAddressAndDevices);
            setShippingMethodsUpdated(true);
        }
    }, [
        pageData?.shippingMethodsInfo?.shippingMethodResponse
            ?.shippingOptionForAddress,
    ]);

    useEffect(() => {
        if (!sessionCartData?.showShippingPage) {
            dispatcher(setEditableSections({ sections: ["shipping"], isEditable: false }));
            setModifier(null);
            onComplete();
        }
        else {
            let addressesDevices = addressesWithDevices?.length > 0 ? [...addressesWithDevices] : shipAddressesWithDevices?.length > 0 ? [...shipAddressesWithDevices] : [];
            if (Object.keys(sessionCartData?.cart?.packages || {})?.length > 0 && (addressesDevices || []).length <= 0) {
                let packages = Object.keys(sessionCartData?.cart?.packages || {}).map((key, index) => {
                    if (sessionCartData?.cart?.packages[key].backOrder) setBackOrder(true)
                    if (!sessionCartData?.cart?.packages[key]?.byod && !sessionCartData?.cart?.packages[key]?.byor && !sessionCartData?.cart?.packages[key]?.buss) {
                        return { ...sessionCartData?.cart?.packages[key], packageKey: key, order: devicesInOrder[sessionCartData?.cart?.packages[key]?.deviceCategory] || 10 }
                    }
                    else if (sessionCartData?.cart?.packages[key]?.byod &&
                        sessionCartData?.cart?.packages[key]?.psimCapable === "Y") {
                        return {
                            deviceSku: sessionCartData?.cart?.packages[key]?.deviceSku,
                            isByodNewSim: true,
                            brand: "Physical SIM Card for your ",
                            productName: sessionCartData?.cart?.packages[key]?.productName,
                            packageKey: key
                        }
                    }
                })
                packages = packages.filter(item => item);
                let routers = packages.filter((v) => v?.packageType === "FOURG" || v?.packageType === "FIVEG" || v?.packageType == "ONETALK");
                let devices = packages.filter((v) => v?.packageType !== "FOURG" && v?.packageType !== "FIVEG" && v?.packageType !=="ONETALK")?.sort((a, b) => a.order > b.order ? 1 : -1);
                setHaveDevices(devices?.length > 0);
                setHaveRouters(routers?.length > 0);
                if (isMultiShipping && sessionCartData?.multiShippingAddress) {
                    let multiShippingAddress = sessionCartData?.multiShippingAddress;
                    multiShippingAddress?.forEach(item => {
                        let addressdevices = devices.filter(p => item?.linePackageIds?.includes(p?.packageKey));
                        let addressRouters = routers.filter(p => item?.linePackageIds?.includes(p?.packageKey));
                        let groupOfDevices = groupByDeviceSku(addressdevices);
                        let groupOfRouters = groupByDeviceSku(addressRouters);
                        let addressName = item?.shippingNickName || "Business address";
                        let addressesAndDevices = {
                            id: v4(),
                            addressName: addressName,
                            isDefaultAddress: _.lowerCase(addressName) === "business address" || _.lowerCase(addressName) === "service address",
                            address: item?.address,
                            devices: { ...groupOfDevices },
                            routers: { ...groupOfRouters },
                            accessories: { ...sessionCartData?.cart?.accessories },
                            selectedShipping: item?.shippingCode
                        }
                        addressesDevices.push(addressesAndDevices);
                    })
                }
                else {
                    let groupOfDevices = groupByDeviceSku(devices);
                    let groupOfRouters = groupByDeviceSku(routers);
                    const addressInfo = getAddress(devices, routers);
                    const existingShipping = sessionCartData?.shipping?.shippingCode || "";
                    let addressesAndDevices = {
                        id: v4(),
                        addressName: addressInfo?.addressName,
                        isDefaultAddress: true,
                        address: addressInfo?.address,
                        devices: { ...groupOfDevices },
                        routers: { ...groupOfRouters },
                        accessories: { ...sessionCartData?.cart?.accessories },
                        selectedShipping: existingShipping
                    }
                    addressesDevices.push(addressesAndDevices);
                }
                setAddressesWithDevices(addressesDevices);
                setRefreshShippingMethods(true);
            }
            else {
                setAddressesWithDevices(addressesDevices);
            }
        }
    }, [sessionCartData, sessionCartData?.cart?.packages]);

    useEffect(() => {
        if (loopQualAddress?.cbandCart) {
            const haveMultiShipRouterAdress = !!addressesWithDevices?.filter(item => (
                haveRoutersToShip(item) && !item?.isDefaultAddress))?.length > 0;
            if (haveMultiShipRouterAdress) {
                setShowMultiShippingWarning(true);
            } else {
                setShowMultiShippingWarning(false);
            }
        } else {
            setShowMultiShippingWarning(false);
        }
    }, [addressesWithDevices])

    useEffect(() => {
        if (pageData?.shippingResponse && pageData?.shippingResponse?.status?.success) {
            setModifier(null);
            onComplete();
        } else {
            setModifier("Error while updating shipping information.");
        }
    }, [pageData?.shippingResponse]);

    useEffect(() => {
        if (shipAddressesWithDevices?.length > 0) {
            let haveMoreThanOneAddress = shipAddressesWithDevices?.length > 1;
            let isExpeditedShipping = shipAddressesWithDevices.some(a => a?.selectedShipping?.shippingCode === "SHP004" || a?.selectedShipping?.shippingCode === "SHP006");
            setSummaryMessage(haveMoreThanOneAddress && isExpeditedShipping ? t("checkout.shipping.additionalExpeditedMsg", { ns: "checkout" }) :
                haveMoreThanOneAddress ? t("checkout.shipping.additionalAddressMsg", { ns: "checkout" }) :
                    isExpeditedShipping ? t("checkout.shipping.additonalTaxMsg", { ns: "checkout" }) : "");
        }
    }, [shipAddressesWithDevices]);

    const getAddress = (devices, routers) => {
        if (!(devices?.length > 0) && routers?.length > 0 && loopQualAddress && Object.keys(loopQualAddress ||{}).length > 0 && loopQualAddress?.addressLine1?.length > 0) {
            const lqAddress = {
                addressLine1: loopQualAddress?.addressLine1,
                addressLine2: loopQualAddress?.addressLine2 || addLine2,
                city: loopQualAddress?.city,
                state: loopQualAddress?.state,
                zipCode: loopQualAddress?.zipCode,
                zipPlus4: loopQualAddress?.zipPlus4,
                streetNum: loopQualAddress?.streetNum,
                streetName: loopQualAddress?.streetName,
                type: loopQualAddress?.type
            }
            return { addressName: "Service address", address: lqAddress }
        }
        else {
            return { addressName: "Business address", address: businessAddress }
        }
    }

    const groupByDeviceSku = (devices) => {
        let devicesByName = devices.reduce((group, device) => {
            const { deviceSku } = device;
            group[deviceSku] = group[deviceSku] ?? [];
            group[deviceSku].push(device);
            return group;
        }, {});
        return devicesByName;
    }

    const prepareAddressCountMap = (() => {
        let addressCountMap = {};
        if (addressesWithDevices && addressesWithDevices.length > 0) {
            addressesWithDevices.map(item => {
                let quantity = getDevicesCountByAddress(item, true);
                addressCountMap[item?.addressName?.replaceAll(" ", "_")] = {
                    address: item?.address,
                    quantity: quantity
                }
            })
        }
        return addressCountMap;
    })

    const getDevicesCountByAddress = ((addressItem, excludeAccessoriesCount = false) => {
        let devicesCount = 0;
        let routersCount = 0;
        let accessoriesCount = 0;
        let tysCount = 0;
        if (Object.values(addressItem?.devices || {}).length > 0) {
            Object.values(addressItem?.devices || {}).forEach(devices => {
                if(excludeAccessoriesCount && devices?.[0].packageType == "TYS"){
                    tysCount = tysCount + devices?.length
                }else{
                    devicesCount = devicesCount + devices?.length;
                }

            });
        }
        if (Object.values(addressItem?.routers || {}).length > 0) {
            Object.values(addressItem?.routers || {}).forEach(devices => {
                routersCount = routersCount + devices?.length;
            });
        }
        if (!excludeAccessoriesCount && Object.values(addressItem?.accessories || {}).length > 0) {
            Object.values(addressItem?.accessories || {}).forEach(accessories => {
                accessoriesCount = accessoriesCount + accessories?.quantity;
            });
        }
        return devicesCount + routersCount + accessoriesCount
    })

    const onSelectedShipping = ((item, shippingCode) => {
        let addressAndDevices = [...addressesWithDevices];
        const updatedAddressAndDevices = addressAndDevices.map(addressItem => {
            const selectedItem = (addressItem && item) ? ((addressItem['id'] === item?.id) || !addressItem['id'] || !item.id) : true
            if (addressItem?.addressName === item?.addressName && selectedItem) {
                let selectedShipping = Array.from(addressItem?.shippingMethods).find(s => s.shippingCode === shippingCode);
                return { ...addressItem, selectedShipping: selectedShipping };
            }
            if (addressItem?.selectedShipping && typeof addressItem?.selectedShipping === 'string') {
                let selectedShipping = Array.from(addressItem?.shippingMethods).find(s => s?.shippingCode === addressItem?.selectedShipping);
                return { ...addressItem, selectedShipping: selectedShipping };
            }
            else if (!addressItem?.selectedShipping) {
                let defaultShipping = Array.from(addressItem?.shippingMethods).find(s => s.defaultShipping);
                return { ...addressItem, selectedShipping: defaultShipping };
            }
            return { ...addressItem };
        })
        setAddressesWithDevices(updatedAddressAndDevices);
    })

    const onNewAddressClick = (() => {
        let newAddressCount = addressesWithDevices?.filter(a => a?.addressName?.includes("Shipping address"))?.length || 0;
        let routersCount = 0;
        addressesWithDevices?.filter(item => item?.isDefaultAddress).map(addressItem => {
            routersCount = routersCount + (Object.values(addressItem?.routers)[0] || [])?.length;
        })
        setCurrentAddressItem({ isEditAddress: false, id: v4(), addressName: "", availRoutersCount: routersCount, address: {}, newAddressName: `Shipping address ${newAddressCount > 0 ? newAddressCount + 1 : ""}` });
        setShowNewAddressModal(true);
    })

    const addOrUpdateNewAddress = ((newAddressItem) => {
        let addressAndDevices = JSON.parse(JSON.stringify(addressesWithDevices));
        if (newAddressItem?.isEditAddress) {
            addressAndDevices.map(addressItem => {
                if (addressItem?.id === newAddressItem?.id) {
                    addressItem.addressName = newAddressItem?.addressName;
                    addressItem.address = newAddressItem?.address;
                    let currentRoutersCount = Object.values(addressItem?.routers)[0].length;
                    if (currentRoutersCount < newAddressItem?.selectedQuantity) {
                        let newRouters = {}
                        addressAndDevices.filter(a => a.isDefaultAddress && Object.keys(a?.routers || {})?.length > 0).map(item => {
                            Object.keys(item?.routers).map((key) => {
                                newRouters = { [key]: item?.routers[key]?.splice(0, newAddressItem?.selectedQuantity - currentRoutersCount) }
                            })
                        })
                        Object.keys(addressItem?.routers).map(key => {
                            addressItem.routers[key] = [...Object.values(addressItem?.routers[key]), ...Object.values(newRouters[key])]
                        })
                    }
                    else if (currentRoutersCount > newAddressItem?.selectedQuantity) {
                        let excessRouters = {}
                        Object.keys(addressItem?.routers).map((key) => {
                            excessRouters = { [key]: addressItem?.routers[key]?.splice(0, currentRoutersCount - newAddressItem?.selectedQuantity) }
                        })
                        addressAndDevices.filter(a => a.isDefaultAddress && Object.keys(a?.routers || {})?.length > 0).map(item => {
                            Object.keys(item?.routers).map((key) => {
                                item.routers[key] = [...Object.values(item?.routers[key]), ...Object.values(excessRouters[key])]
                            })
                        })
                    }
                }
            });
        }
        else {
            let newAddressWithRouters = {};
            addressAndDevices.map(addressItem => {
                let newAddressRouters = {}
                if (addressItem.isDefaultAddress && Object.keys(addressItem?.routers || {})?.length > 0) {
                    Object.keys(addressItem?.routers || {}).map((key) => {
                        newAddressRouters = { [key]: addressItem?.routers[key]?.splice(0, newAddressItem?.selectedQuantity) }
                    })
                    newAddressWithRouters = {
                        id: newAddressItem?.id,
                        addressName: newAddressItem?.addressName || newAddressItem?.newAddressName,
                        isDefaultAddress: false,
                        address: { ...newAddressItem?.address },
                        routers: { ...newAddressRouters }
                    }
                }
            });
            if (Object.keys(newAddressWithRouters)?.length > 0) {
                addressAndDevices.push(newAddressWithRouters);
            }
        }
        setAddressesWithDevices(addressAndDevices);
        setRefreshShippingMethods(true);
        setShowNewAddressModal(false);           
    })

    const multiShippingRouterAddorUpdate = (newAddressItem) => {
        const selectedRoutersInfo = newAddressItem?.selectedRoutersInfo;
        let addressAndDevices = JSON.parse(JSON.stringify(addressesWithDevices));
        
        if (newAddressItem?.isEditAddress) {                       
            if(newAddressItem?.availRoutersCount > 0 && newAddressItem?.selectedQuantity == 0 && selectedRoutersInfo?.length == 0){
                removeAddress(addressAndDevices.filter( x => x.id == newAddressItem.id)[0])
            }
            selectedRoutersInfo?.length > 0 && addressAndDevices.map(addressItem => {
                if (addressItem?.id === newAddressItem?.id) {
                    addressItem.addressName = newAddressItem?.addressName;
                    addressItem.address = newAddressItem?.address;
                    let currentRoutersCount = 0;
                    Object.keys(addressItem?.routers ||{}).forEach(key => {
                        if((Object.values(addressItem?.routers[key]) || []).length > 0){
                            if(addressItem?.routers[key][0]?.packageType == "ONETALK"){
                                currentRoutersCount = currentRoutersCount + 1; 
                            }else{
                                currentRoutersCount = currentRoutersCount + addressItem?.routers[key]?.length;
                            }
                        }
                    })
                    if(currentRoutersCount < selectedRoutersInfo?.length){                        
                        let newRouters = {}
                        addressAndDevices.filter(a => a.isDefaultAddress && Object.keys(a?.routers || {})?.length > 0).map(item => {
                            Object.keys(item?.routers || {}).map((key) => {
                                newRouters = { [key]: [...Object.values(addressItem?.routers[key] ||{}), ...addressItem?.routers[key]?.filter(x => selectedRoutersInfo?.includes(x.packageKey))] }
                                item.routers[key] =  item?.routers[key]?.filter(x => !selectedRoutersInfo?.includes(x.packageKey))
                            })
                        })
                        Object.keys(addressItem?.routers || {}).map(key => {                            
                            addressItem.routers[key] = [...Object.values(addressItem?.routers[key]), ...Object.values(newRouters[key] ||{})]
                        })                 
                    }
                    else if(currentRoutersCount > selectedRoutersInfo?.length){
                        let excessRouters = {}
                        Object.keys(addressItem?.routers || {}).map((key) => {
                             excessRouters[key] = addressItem?.routers[key]?.filter(x => !selectedRoutersInfo?.includes(x.packageKey)) 
                             addressItem.routers[key] = addressItem?.routers[key]?.filter(x => selectedRoutersInfo?.includes(x.packageKey))           
                        })
                        for(let key in excessRouters){
                            if(Array.isArray(excessRouters[key]) && excessRouters[key].length === 0){
                                delete excessRouters[key];
                            }
                        }
                        if(Object.keys(addressItem?.routers || {}).length){
                        for(let key in addressItem?.routers){
                            if(Array.isArray(addressItem?.routers[key]) && addressItem?.routers[key].length === 0){
                                delete addressItem?.routers[key];
                            }
                        }}
                        addressAndDevices.filter(a => a.isDefaultAddress && a.hasOwnProperty("routers")).map(item => {
                            if(Object.keys(item?.routers || {}).length){
                               Object.keys(excessRouters || {}).map((key) => {
                                if(item?.routers.hasOwnProperty(key) && excessRouters?.hasOwnProperty(key)){
                                    item.routers[key] = [...Object.values(item?.routers[key]), ...Object.values(excessRouters[key])]
                                }else{
                                    item.routers[key] = cloneDeep(excessRouters[key])
                                }
                            }) 
                            }else{
                                item.routers = cloneDeep(excessRouters)
                            }
                            
                        })

                    }
                }
            });
        }
        else {            
            let newAddressWithRouters = {};
            let newAddressRouters = {}
            let orginalAddressRouters = {}
            addressAndDevices.map(addressItem => {
                if (addressItem.isDefaultAddress && Object.keys(addressItem?.routers || {})?.length > 0) {
                    Object.keys(addressItem?.routers || {}).map((key_item) => {

                        newAddressRouters[key_item] = addressItem?.routers[key_item]?.filter(x => {
                            if(selectedRoutersInfo?.includes(x.packageKey)){
                                x.selected = true;
                                return x;
                            } 
                        })
                        if(addressItem?.routers[key_item]?.length > 0){
                            orginalAddressRouters[key_item]  = addressItem?.routers[key_item]?.filter(x => !selectedRoutersInfo?.includes(x.packageKey))   
                        }
                    });
                    for(let key in newAddressRouters){
                        if(Array.isArray(newAddressRouters[key]) && newAddressRouters[key].length === 0){
                            delete newAddressRouters[key];
                        }
                    }

                    for(let key in orginalAddressRouters){
                        if(Array.isArray(orginalAddressRouters[key]) && orginalAddressRouters[key].length === 0){
                            delete orginalAddressRouters[key];
                        }
                    }
          
                    newAddressWithRouters = {
                        id: newAddressItem?.id,
                        addressName: newAddressItem?.addressName || newAddressItem?.newAddressName,
                        isDefaultAddress: false,
                        address: { ...newAddressItem?.address },
                        routers: { ...newAddressRouters }
                    }
                    addressItem.routers = { ...orginalAddressRouters}
                }
            });
            if (Object.keys(newAddressWithRouters || {})?.length > 0) {
                addressAndDevices.push(newAddressWithRouters);
            }
        }
        if(!newAddressItem?.isEditAddress || !(newAddressItem?.availRoutersCount > 0 && newAddressItem?.selectedQuantity == 0 && selectedRoutersInfo?.length == 0)){
            setAddressesWithDevices(addressAndDevices);
            setRefreshShippingMethods(true);            
        }     
        setShowNewAddressModal(false);   
    }

    const editAddress = ((item) => {
        // let routersCount = (Object.values(item?.routers).length > 0 ? Object.values(item?.routers)[0] : [])?.length;
        let routerCountSelected = 0;
        let OneTalkCountSelected = 0;
        Object.keys(item?.routers ||{}).forEach(key => {
            if((Object.values(item?.routers[key]) || []).length > 0){
                if(item?.routers[key][0]?.packageType == "ONETALK"){
                    OneTalkCountSelected = OneTalkCountSelected + 1; 
                }else{
                    routerCountSelected = item?.routers[key]?.length;
                }
            }
        })
        routerCountSelected = OneTalkCountSelected + routerCountSelected;

        let totalAvailCount = 0;
        let OneTalkCount = 0;
        let routersCount = 0;
         addressesWithDevices?.filter(item => item?.isDefaultAddress).map(addressItem => {
            Object.keys(addressItem?.routers ||{}).forEach(key => {
                if((Object.values(item?.routers) || {})?.length > 0){
                    if(addressItem?.routers[key][0]?.packageType == "ONETALK"){
                        OneTalkCount = OneTalkCount + 1; 
                    }else{
                        routersCount = item?.routers[key]?.length
                    }
                }
                
            })
            totalAvailCount = OneTalkCount + routersCount + routerCountSelected +  (Object.values(addressItem?.routers).length > 0 ? Object.values(addressItem?.routers)[0] : [])?.length;

        })
        setCurrentAddressItem({ isEditAddress: true, id: item?.id, addressName: item?.addressName, availRoutersCount: totalAvailCount, selectedQuantity: routerCountSelected, address: item?.address });
        setShowNewAddressModal(true);
    })

    const removeAddress = ((item) => {
        let addressAndDevices = JSON.parse(JSON.stringify(addressesWithDevices));
        addressAndDevices.map(addressItem => {
            if (addressItem.isDefaultAddress && Object.values(item?.routers || {}).length >= 0) {
                Object.keys(item?.routers || {}).map(key => {
                    addressItem.routers[key] = [...Object.values(addressItem?.routers[key] || {}), ...Object.values(item?.routers[key])]
                })
            }
        })
        addressAndDevices = addressAndDevices.filter(a => a.id !== item.id)
        setAddressesWithDevices(addressAndDevices);
        setRefreshShippingMethods(true);
    })

    const onContinue = (() => {
        if (isMultiShipping) {
            submitMultiShipping();
        }
        else {
            let selectedShipping = {}
            addressesWithDevices.map(addressItem => {
                selectedShipping = { ...addressItem?.selectedShipping, shippingCost: addressItem?.selectedShipping?.flatRate || addressItem?.selectedShipping?.shippingCost };
            })
            dispatcher(setSavedAddressesWithDevices({ addressesWithDevices: addressesWithDevices }))
            dispatcher(postShippingMethod({ ...selectedShipping, addressesWithDevices: addressesWithDevices, useMock: useMock }));
        }
        // BOVV-147186 Fix
        // isEditMode && setIsEditMode(false);
    })

    const submitMultiShipping = () => {
        const packagePayload = {};
        addressesWithDevices.map(addressItem => {
            if (Object.keys(addressItem?.routers || {})?.length > 0) {
                Object.values(addressItem?.routers).map(routers => {
                    routers.map(router => {
                        const { packageKey } = router;
                        packagePayload[packageKey] = mapAddressDevicesForSave(addressItem);
                    })
                });
            }
            if (Object.keys(addressItem?.devices || {})?.length > 0) {
                Object.values(addressItem?.devices).map(devices => {
                    devices.map(device => {
                        const { packageKey } = device;
                        packagePayload[packageKey] = mapAddressDevicesForSave(addressItem);
                    })
                })
            }
        });
        if (Object.keys(packagePayload).length === 0) {
            return;
        }
        const requestPayload = {
            operations: !sessionCartData?.multiShippingAddress ? "ADD" : "UPDATE",
            taxCalculationRequired: true,
            packages: packagePayload,
        };
        dispatcher(setSavedAddressesWithDevices({ addressesWithDevices: addressesWithDevices }))
        dispatcher(postShippingMethod({ requestPayload, is4G: isMultiShipping, addressesWithDevices: addressesWithDevices, useMock: useMock }));
    }

    const mapAddressDevicesForSave = (addressItem) => {
        const { selectedShipping, address, addressName } = addressItem;
        return {
            shippingCode: selectedShipping.shippingCode,
            shippingCost: selectedShipping.flatRate || selectedShipping.shippingCost,
            shippingDesc: selectedShipping.shippingDescription,
            shippingPrioripackagePayloadty: selectedShipping.shipper,
            serviceType: selectedShipping.shippingType,
            selectedSlot: {},
            shoppingPath: "PROSPECT",
            shippingNickName: addressName ?? 'Shipping Address',
            approach: "",
            flow: "flow",
            address: {
                addressLine1: address?.addressLine1 ?? "",
                streetNum: address?.streetNum ?? "",
                streetName: address?.streetName,
                type: address?.type,
                city: address?.city,
                state: address?.state,
                zipCode: address?.zipCode,
                country: address?.country,
                locationCode: address?.locationCode,
                addressLine2: address.addressLine2 || addLine2,
                zipPlus4: address?.zipPlus4,
            }
        }
    }

    const canShowNewAddressLink = useMemo(() => {
        if (isMultiShipping && addressesWithDevices && addressesWithDevices?.length > 0) {
            let haveDefaultAddressRouters = false;
            let haveFWARouter = false;
            //check if any FWA device is present selected or not selected
            addressesWithDevices.map(item => {
                Object.keys(item?.routers || {}).map(key => {
                    if((Object.values(item?.routers[key]) || []).length > 0){
                        if(item?.routers[key][0]?.packageType == "FOURG" || item?.routers[key][0]?.packageType == "FIVEG"){
                            haveFWARouter = true; 
                        }
                    }
                })
            })

            // check if any item yet to be selected
            addressesWithDevices.filter(a => a.isDefaultAddress).map(item => {
                Object.keys(item?.routers || {}).map(key => {
                    if((Object.values(item?.routers || {}).length > 0)){
                            haveDefaultAddressRouters = true; 
                    }else{
                        haveDefaultAddressRouters = false; 
                    }
                })
            })
            return (haveFWARouter && haveDefaultAddressRouters);
        }
    }, [addressesWithDevices]);

    const serviceAddressFivegCband = useMemo(() => {
        return `${loopQualAddress?.addressLine1 ? loopQualAddress?.addressLine1 : ''} ${loopQualAddress?.city ? loopQualAddress?.city : ''}  ${loopQualAddress?.state ? loopQualAddress?.state : ''}  ${loopQualAddress?.zipCode ? loopQualAddress?.zipCode : ''} `
    }, [loopQualAddress]);

    const haveDevicesToShip = (item) => {
        let haveDevices = false;
        if (item?.devices) {
            Object.keys(item?.devices || {}).forEach(key => {
                haveDevices = (Object.values(item?.devices[key]) || []).length > 0;
            })
        }
        //check for onetalk devices
        if (item?.routers) {
            Object.keys(item?.routers ||{}).forEach(key => {

                if(item?.routers[key][0]?.packageType == "ONETALK"){
                    haveDevices = true;
                }
                // haveDevices = (Object.values(item?.devices[key]) || []).length > 0;
            })
        }
        return haveDevices;
    }

    const haveRoutersToShip = (item) => {
        let haveRouters = false;
        let noRouter = false;
        if (item?.routers) {
            Object.keys(item?.routers ||{}).forEach(key => {
                if((Object.values(item?.routers[key]) || []).length > 0){
                    haveRouters = (Object.values(item?.routers[key]) || []).length > 0;
                }
            })
        }
        return haveRouters;
    }
    const haveRoutersInObj = (item) =>{
        let haveRouter = false;
        let currentCount = 0;
        if (item?.routers) {
            Object.keys(item?.routers || {}).forEach(key => {
                if((Object.values(item?.routers[key]) || []).length > 0){
                    
                    if(item?.routers[key][0]?.packageType == "FOURG" || item?.routers[key][0]?.packageType == "FIVEG"){
                        currentCount = currentCount + item?.routers[key].length
                        haveRouter = true; 
                    }
                }
                
            })
        }
        return currentCount; 
    }


    const getImage = (device) => {
        let carouselImageset = [];
        if (device) {
            if (device && device.image) {
                carouselImageset.push(imageCDN + device.image + "?$acc-lg$&hei=72&wid=72");
            } else if (device.description) {
                const parts = device.description.split(":_:");
                let imgSrc = device?.isAccessorie ? parts[4] : parts[5];
                if (imgSrc) {
                    carouselImageset.push(imageCDN + imgSrc + "?$acc-lg$&hei=72&wid=72");
                }
            }
        }
        if (carouselImageset && carouselImageset.length > 0) {
            return (
                <div><img style={{ width: '72px', height: '72px' }} src={carouselImageset[0]} alt={device?.brand + "-" + device?.productName} /></div>
            );
        }
        else if (device?.isByodNewSim) {
            return (
                <Icon name="sim-card" size={72} color="#000000" surface="light" />
            );
        } else if (device?.byor) {
            return (
                <ByodContainer width={100} height={100}><ByorImg /><ByorSpan /></ByodContainer>
            );
        }
        else if (device?.isAccessorie) {
            return (
                <Icon name="accessories" size={72} color="#000000" surface="light" />
            );
        } else {
            return (
                <ByodContainer width={100} height={100}><ByodImg /></ByodContainer>
            );
        }
    };

    const getDeviceName = (device) => {
       
        let deviceName = device?.byor ? "Use your own router" : "Your own device";
        if (device?.brand && device?.productName) {
            deviceName = (`${device.brand}  ${device?.brand?.includes("Physical SIM") ? "<br/>" : " "} ${device.productName}`);
        }
        else if (device?.productName && !device?.accessoryId) {
            deviceName = device?.productName;
        } else if (device?.description) {
            const parts = device.description.split(":_:");
            if (parts?.length > 1) {
                deviceName = `${parts[0]} ${parts[1]}`;
            }
        }
        return deviceName;
    };
    const VBCCart = ({packageKey=""})=>{
        let accessoryId = []
        const {groupPackages,packages,accessories} = sessionCartData?.cart
        Object.values(groupPackages).map(grpPackage=>{
            const {packageIds=[],accessoryIds=[]} = grpPackage
            if(packageIds.includes(packageKey)) accessoryId = [...accessoryIds]
        })
        
        if(!!accessories && typeof accessories == 'object' && Object.keys(accessories).length && !!accessoryId && accessoryId.length){
            return(
            <div>
            <MarginSpacerM/>
            <Body size="small">{t("delivery.accesoryTitle", { ns: "shipping"})}</Body>
            <MarginSpacerS/>
            <UnorderedList id="unorder-lt-shp">
            {accessoryId.map(aId=>{
            const parts = accessories[aId]?.description.split(":_:");
            if(!!parts && parts.length > 1){
            return(
                <ListItem size="bodySmall" spacing="compact">{`${parts[0]} ${parts[1]}`}</ListItem>
            )
        }else return <></>
        })}
            </UnorderedList>
            <MarginSpacerM/>
            </div>
            )
            
        }else return <></>
        
    }

    const getDefaultContent = () => {
        return (
            <>
                {isLoading && <SpinnerComponent />}
                {showNewAddressModal && (
                    <AddNewAddress
                        showNewAddressModal={showNewAddressModal}
                        setShowNewAddressModal={setShowNewAddressModal}
                        currentAddressItem={currentAddressItem}
                        addOrUpdateNewAddress={!showMultiShippingRouterModal ? addOrUpdateNewAddress : multiShippingRouterAddorUpdate}
                        restrictedAddresses={addressesWithDevices.filter(i => i.id != currentAddressItem.id).map(i => i.address)}
                        isMultiShipping={isMultiShipping}
                        addressesWithDevices={addressesWithDevices}
                    />
                )}               
                <div>
                    <Grid colGutter={false} rowGutter="24px" colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
                        {backOrder ?
                        <>
                        <MarginSpacerM/>
                        <Notification
                            type="info"
                            title={t("delivery.backOrder", { ns: "shipping" })}
                            subtitle={serviceAddressFivegCband}
                            fullBleed={"true"}
                            inlineTreatment={false}
                            hideCloseButton={true}
                        />
                        <MarginSpacerM/>
                        </>
                        :<></>}
                        {addressesWithDevices?.map(item => {
                                let isVBCCart = false
                                if(!!item && !!item?.devices && typeof item?.devices == 'object' && Object.values(item.devices).length){
                                    Object.values(item.devices)?.map(devices => devices?.slice(0, 1).map(device=>{
                                        if(String(device.packageType).includes('RENTAL_REGULAR')) isVBCCart = true
                                    }))
                                }
                                
                            
                            return(
                            (haveDevicesToShip(item) || haveRoutersToShip(item)) ? <>
                                <StyledRow>
                                    <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }} style={{ "width": "100%" }}>
                                        <Body size="large" primitive="p"id="businessAddress">
                                            <b>{item?.addressName}</b>
                                            {isVBCCart ? 
                                            <Tooltip 
                                            title=""
                                            size="small"
                                            surface="light">
                                            {t("delivery.tooltipForBusinessAddress", { ns: "shipping" })}
                                        </Tooltip>:<></>}

                                        </Body>
                                        <Body size="large" primitive="p">
                                            {`${_.startCase(_.toLower(item?.address?.addressLine1))}${item?.address?.addressLine2 ? ', ' :''} ${_.startCase(_.toLower(item?.address?.addressLine2))}${item?.address?.addressLine2 ? ', ' :''} ${ item?.address?.city ? _.startCase(_.toLower(item?.address?.city)) : ''} ${item?.address?.city ? ', ' :''}${item?.address?.state ? item?.address?.state: ""} ${item?.address?.zipCode}`}
                                        </Body>
                                    </Col>
                                </StyledRow>
                                {!item?.isDefaultAddress ? <StyledRow>
                                    <Col colSizes={{ mobile: 2, tablet: 3, desktop: 3 }} style={{ "padding-right": "15px" }}>
                                        <TextLink size="large" onClick={() => { editAddress(item) }}>
                                            {"Edit"}
                                        </TextLink>
                                    </Col>
                                    <Col colSizes={{ mobile: 2, tablet: 3, desktop: 3 }}>
                                        <TextLink size="large" onClick={() => { removeAddress(item) }}>
                                            {"Remove address"}
                                        </TextLink>
                                    </Col>
                                </StyledRow> : <></>}
                                {haveDevicesToShip(item) ?
                                    <StyledRow>
                                        <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }} style={{ "width": "100%" }}>
                                            {item?.isDefaultAddress ? <Body size="large" primitive="p">
                                                {t("checkout.shipping.deviceShipMessage", { ns: "checkout", })}
                                            </Body> : <Body size="large" primitive="p">
                                                {t("checkout.shipping.routerShipNewAddressMessage", { ns: "checkout", })}
                                            </Body> }
                                           {item?.isDefaultAddress && <MarginSpacerM />}
                                            {Object.values(item?.devices || {}).length ? Object.values(item?.devices || {})?.map(devices => devices?.slice(0, 1).map(device => {
                                            const tierValue = (String(device?.tier).charAt(0).toUpperCase() + String(device?.tier).toLowerCase().slice(1))
                                            return(
                                              <>
                                                    <div style={{ display: 'flex' }}>
                                                        {getImage(device)}
                                                        <div style={{ marginLeft: '20px' }}>
                                                            {isVBCCart ?<>
                                                            <Body size="small" color="#6F7171">{t("delivery.vbcCompleted", { ns: "shipping"}).replace("{{tier}}",tierValue)}</Body>
                                                            <MarginSpacerS/>
                                                            </> : <></>}
                                                            <Body size="large">
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: getDeviceName(device)
                                                                    }}
                                                                ></div>
                                                            </Body>

                                                            <Body size="medium" color="#6F7171">{`${(devices || {})?.length} ${(devices || {})?.length > 1 ? " devices" : " device"}`}</Body>
                                                            {isVBCCart ? <VBCCart packageKey={device?.packageKey}/>:<></>}
                                                        </div>
                                                    </div>
                                            </>
                                            )})) : <span/>}
                                            {/* adding this here because onrtalt devices are part of roters obj but need to be shown in wireless device section */}
                                            {(item?.routers && Object.keys(item?.routers || {})?.length > 0) &&
                                                Object.values(item?.routers || {})?.map(routers => routers?.slice(0, 1).map(router => (
                                                   <>
                                                        {(router?.packageType == "ONETALK" && item?.isDefaultAddress) ?  <div style={{ display: 'flex' }}>
                                                            {getImage(router)}
                                                            <div style={{ marginLeft: '20px' }}>
                                                                <Body size="large">{getDeviceName(router)}</Body>
                                                                <Body size="medium" color="#6F7171">{`${(routers || {})?.length} ${(routers || {})?.length > 1 ? " devices" : " device"}`}</Body>
                                                            </div>
                                                        </div> : <span/>}
                                                    </> 
                                                )))}
                                            {!isVBCCart && Object.values(item?.accessories ||{})?.filter(a => !a?.router)?.map(accessorie => (
                                                <>
                                                    <div style={{ display: 'flex' }}>
                                                        {getImage({ ...accessorie, isAccessorie: true })}
                                                        <div style={{ marginLeft: '20px' }}>
                                                            <Body size="large">{getDeviceName(accessorie)}</Body>
                                                            <Body size="medium" color="#6F7171">{`${accessorie?.quantity} ${accessorie?.quantity > 1 ? " devices" : " device"}`}</Body>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </Col>
                                    </StyledRow> : <></>
                                }
                                {(haveRoutersInObj(item)  && item?.isDefaultAddress ) ||(!item?.isDefaultAddress && haveRoutersToShip(item)) ?
                                    <StyledRow>
                                        <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }} align={"center"} style={{ "width": "100%" }}>
                                            {haveRoutersInObj(item) && item?.isDefaultAddress ?
                                                <><MarginSpacerM />
                                                    <Body size="large" primitive="p">
                                                        { (haveRoutersInObj(item) > 1)
                                                        ? t("checkout.shipping.routerShipMessage", { ns: "checkout", })
                                                            : t("checkout.shipping.singleRouterShipMessage", { ns: "checkout", })}
                                                             {/* t("checkout.shipping.routerShipNewAddressMessage", { ns: "checkout", }) */}

                                                    </Body>
                                                </> : !haveDevicesToShip(item) && haveRoutersInObj(item) && !item?.isDefaultAddress ?
                                                <><MarginSpacerM />
                                                    <Body size="large" primitive="p">
                                                        {t("checkout.shipping.routerShipNewAddressMessage", { ns: "checkout" })}
                                                    </Body>
                                                    <MarginSpacerM />
                                                </> :
                                                <span/>}
                                           {item?.isDefaultAddress && <MarginSpacerM />} 
                                            {(item?.routers && Object.keys(item?.routers || {})?.length > 0) &&
                                                Object.values(item?.routers || {})?.map(routers => routers?.slice(0, 1).map(router => (
                                                   <>
                                                        {((router?.packageType !== "ONETALK" && item?.isDefaultAddress) || !item?.isDefaultAddress ) ? 
                                                         <div style={{ display: 'flex' }}>
                                                            {getImage(router)}
                                                            <div style={{ marginLeft: '20px' }}>
                                                                <Body size="large">{getDeviceName(router)}</Body>
                                                                <Body size="medium" color="#6F7171">{`${(routers || {})?.length} ${(routers || {})?.length > 1 ? " devices" : " device"}`}</Body>
                                                            </div>
                                                        </div> :
                                                        <span/>}
                                                    </> 
                                                )))}
                                            {item?.accessories && item?.accessories?.length > 0 &&
                                                Object.values(item?.accessories || {})?.filter(a => a?.router)?.map(accessorie => (
                                                    <>
                                                        <div style={{ display: 'flex' }}>
                                                            {getImage({ ...accessorie, isAccessorie: true })}
                                                            <div style={{ marginLeft: '20px' }}>
                                                                <Body size="large">{getDeviceName(accessorie)}</Body>
                                                                <Body size="medium" color="#6F7171">{`${accessorie?.quantity} ${accessorie?.quantity > 1 ? " devices" : " device"}`}</Body>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))}
                                        </Col>
                                    </StyledRow> : <></>
                                }
                                {item?.shippingMethods?.length > 0 ?
                                    <StyledRow>
                                        <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }} style={{ "width": "100%" }}>
                                            <Body size="medium">{t("checkout.shipping.shippingMethodText", { ns: "checkout", })}</Body>
                                            <MarginSpacerM />
                                            <RadioBoxGroup
                                                childWidth={'200px'}
                                                defaultValue={item?.selectedShipping?.shippingCode}
                                                orientation={isMobile ? "vertical" : "horizontal"}
                                                onChange={e => { onSelectedShipping(item, e.target.value) }}
                                                data={item?.shippingMethods?.map((method, index) => {
                                                    return {
                                                        id: method.shippingCode,
                                                        text: method?.text,
                                                        subtext: method?.subText ? "(" + method?.subText + ") $" + method.shippingCost : method.shippingCost === 0 ? method?.displayPrice : method.shippingCost,
                                                        value: method.shippingCode,
                                                        ariaLabel: "radio " + index,
                                                    };
                                                })}
                                            />
                                            {t("checkout.shipping.shippingMethodNotice", { ns: "checkout", }) &&
                                                <Fragment>
                                                    <MarginSpacerM />
                                                    <Body size="small" primitive="p" color="gray">
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: t("checkout.shipping.shippingMethodNotice", { ns: "checkout", }),
                                                            }}
                                                        />
                                                    </Body>
                                                </Fragment>
                                            }
                                        </Col>
                                    </StyledRow> : <></>
                                }
                            </> : <></>
                        )})}
                        <StyledRow>
                            <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
                                <Line type="secondary"></Line>
                            </Col>
                        </StyledRow>
                        {canShowNewAddressLink ?
                            <StyledRow>
                                <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }} style={{ "width": "100%" }}>
                                    <div style={{ "width": "66%" }}>
                                        <TextLink size="large" onClick={() => { onNewAddressClick() }}>
                                            {t("checkout.shipping.newAddressLink", { ns: "checkout", })}
                                        </TextLink>
                                        {/* <MarginSpacerM /> */}
                                        {/* {haveRouters && haveDevices && <>
                                            <Body size="medium">{t("checkout.shipping.routersShipNotice", { ns: "checkout", })}</Body>
                                        </>
                                        } */}
                                    </div>
                                    <MarginSpacerL />
                                    <Line type="secondary"></Line>
                                </Col>
                            </StyledRow> : <></>
                        }
                        <StyledRow>
                            <Col colSizes={{ mobile: 4, tablet: 8, desktop: 8 }} style={{ "width": "100%" }}>
                                <Body size="medium">{t("checkout.shipping.shippingNotice", { ns: "checkout" })}</Body>
                                <MarginSpacerM />
                            </Col>
                        </StyledRow>
                        {showMultiShippingWarning ?
                            <Row>
                                <Col colSizes={{ mobile: 4, tablet: 8, desktop: 12 }} style={{ "width": "100%" }}>
                                    <StyledDivNotification className="notification-shipping">
                                        <Notification
                                            type="info"
                                            title={`${t("checkout.shipping.multiShippingWarning", { ns: "checkout", })} :`}
                                            subtitle={serviceAddressFivegCband}
                                            fullBleed={"true"}
                                            inlineTreatment={false}
                                            hideCloseButton={true}
                                        />
                                        <MarginSpacerL />
                                    </StyledDivNotification>
                                </Col>
                            </Row>
                            : <></>}
                        <StyledRow>
                            <Col colSizes={{ mobile: 4, tablet: 3, desktop: 3 }} style={{ "width": "100%" }}>
                                <Button size="large" use="primary" width={"100%"} onClick={onContinue} data-track={`Shipping and payment:Shipping details:${isEditMode ? "Save" : "Continue"}`}>
                                    {isEditMode ? "Save" : "Continue"}
                                </Button>
                            </Col>
                        </StyledRow>
                    </Grid>
                </div>
            </>
        );
    };

    const getCompletedContent = () => {
        return (
            <>
                {((sessionCartData?.showShippingPage && !isOrderToQuote) || (sessionCartData?.showShippingPage && isOrderToQuote && !sessionCartData?.shipping?.shippingCost)) ?
                    <Grid colGutter={false} rowGutter={false} colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
                        {pageData?.savedAddressesWithDevices?.addressesWithDevices?.map(item => (
                            <>
                                {(haveDevicesToShip(item) || haveRoutersToShip(item)) ?
                                    <StyledRow>
                                        <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }} style={{ "width": "100%" }}>
                                            <Body size="large" primitive="p">
                                                {`${getDevicesCountByAddress(item)} items shipping to:`}
                                            </Body>
                                            <Body size="large">
                                                {_.upperCase(item?.address?.addressLine1)}{item?.address?.addressLine2 ? ', ' :''} {_.upperCase(item?.address?.addressLine2)}
                                            </Body>
                                            <Body size="large">{_.upperCase(item?.address?.city)} {' '}
                                                {_.upperCase(item?.address?.state)} {' '}
                                                {item?.address?.zipCode}
                                            </Body>
                                            {item?.selectedShipping?.shippingCost ? <Body size="large" primitive="p">
                                                {`Shipping method: ${item?.selectedShipping?.shippingCost <= 0 ? "Free" : "$" + item?.selectedShipping?.shippingCost} ${item?.selectedShipping?.text}`}
                                            </Body>
                                                : sessionCartData?.shipping?.shippingCode ? <Body size="large" primitive="p">
                                                    {`Shipping method: ${sessionCartData?.shipping?.shippingCost <= 0 ? "Free" : "$" + sessionCartData?.shipping?.shippingCost} ${sessionCartData?.shipping?.shippingDesc}`}
                                                </Body> : <></>}
                                            <MarginSpacerL />
                                        </Col>
                                    </StyledRow> : <></>
                                }
                            </>)
                        )}
                        {showMultiShippingWarning || summaryMessage ? <StyledRow>
                            <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
                                <Notification type="info" title={summaryMessage}
                                    subtitle="" fullBleed={false}
                                    inlineTreatment={false} hideCloseButton={true} width={"100%"}
                                ></Notification>
                            </Col>
                        </StyledRow> : <></>
                        }
                    </Grid>
                    : (isOrderToQuote && sessionCartData?.shipping?.shippingCost) ? 
                    <Grid colGutter={false} rowGutter={false} colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
                        <StyledRow>
                            <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }} style={{ "width": "100%" }}>
                                <Body size="large" primitive="p">
                                    {`${sessionCartData?.cart?.packages !== null && sessionCartData?.cart?.packages && Object.values(sessionCartData?.cart?.packages || {}).length} items shipping to:`}
                                </Body>
                                <Body size="large">
                                    {_.upperCase(sessionCartData?.businessInfo?.address?.addressLine1)}{sessionCartData?.businessInfo?.address?.addressLine2 ? ', ' :''} {_.upperCase(sessionCartData?.businessInfo?.address?.addressLine2)}
                                </Body>
                                <Body size="large">{_.upperCase(sessionCartData?.businessInfo?.address?.city)} {' '}
                                    {_.upperCase(sessionCartData?.businessInfo?.address?.state)} {' '}
                                    {sessionCartData?.businessInfo?.address?.zipCode}
                                </Body>
                                <Body size="large" primitive="p">
                                    {`Shipping method: ${sessionCartData?.shipping?.shippingCost <= 0 ? "Free" : "$" + sessionCartData?.shipping?.shippingCost} ${sessionCartData?.shipping?.shippingDesc}`}
                                </Body>
                                <MarginSpacerL />
                            </Col>
                        </StyledRow> 
                    </Grid>
                    : <></>
                }
                {((!sessionCartData?.showShippingPage && !isOrderToQuote) || (!sessionCartData?.showShippingPage && isOrderToQuote && !sessionCartData?.shipping?.shippingCost)) ?
                    <Grid colGutter={false} rowGutter={false} colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
                        <StyledRow>
                            <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }} style={{ "width": "100%" }}>
                                <Body size="large" primitive="p">
                                    {t("checkout.shipping.nothingToShip", { ns: "checkout" })}
                                </Body>
                            </Col>
                        </StyledRow>
                    </Grid>
                    :<></>
                }
                
                {showMultiShippingWarning ?
                    <StyledDivNotification className="notification-shipping">
                        <Notification
                            type="info"
                            title={`${t("checkout.shipping.multiShippingWarning", { ns: "checkout", })} :`}
                            subtitle={serviceAddressFivegCband}
                            fullBleed={"true"}
                            inlineTreatment={false}
                            hideCloseButton={true}
                        />
                    </StyledDivNotification>
                    : <></>}
            </>
        );
    };

    if (isComplete) {
        if (isEditMode) {
            return getDefaultContent();
        } else {
            return getCompletedContent();
        }
    } else {
        return getDefaultContent();
    }
}
const StyledRow = styled(Row)`
  > div {
    padding: 0rem;
  }
  padding: 0rem !important;
`;

const StyledDivNotification = styled.div`
    p{
        font-weight:400 !important;
    }
`
export const ByodContainer = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ center = true }) => center ? 'center' : 'flex-start'};
  @media only screen and (max-width: 545px){
    width: auto;
  }
`;

export const ByodImg = styled.div`
  width: 36px;
  height: 72px;
  border-style: solid;
  border-radius: 9px;
  border-width: 2px;
  border-color: #A7A7A7;
`;

export const ByorImg = styled.div`
  width: 78px;
  height: 78px;
  border-style: solid;
  border-radius: 4px;
  border-width: 2px;
  border-color: #A7A7A7;
`;

export const ByorSpan = styled.span`
  width: 40px !important;  
  height: 3px;
  background-color: #A7A7A7;
  border-radius: 1px;
`;

export default Shipping;
