import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Modal, ModalTitle, ModalBody } from "@vds3/modals";
import { Button } from "@vds3/buttons";
import { Body } from "@vds3/typography";
import { MarginSpacerL, MarginSpacerM } from "common/components/styleTag";
import { Micro } from "@vds3/typography";

const JointOfferDetailsNew = (props) => {
  const  {
    jointOfferList
  } = props
  const getUnorderListTop = ()=>{
    return(
      <>
         <ol>
          {jointOfferList.bulletPointsContent1.map((item)=>{
            return <li size="bodyLarge" >
            <Body size="large">{item?.bulletDesc}</Body>
          </li>
          })}
        </ol>
      </>
    )
  }

  const getUnorderListBottom = ()=>{
    return(
      <>
         <ol>
          {jointOfferList.bulletPointsContent2.map((item)=>{
            return <li size="bodyLarge" >
              <Body size="large">{item?.bulletDesc}</Body>
          </li>
          })}
        </ol>
      </>
    )
  }


  return(
    <>
      <NCModal
        ariaLabel=""
        opened={true}
        onOpenedChange={props?.setJointOfferModal}
        disableOutsideClick={true}
      >  

        <ModalTitle>
          {jointOfferList?.modalHeading}
        </ModalTitle>
        <MarginSpacerM />
       
        <ModalBody>
          <Body size= "large">
            <span dangerouslySetInnerHTML={{
                    __html: jointOfferList?.modalSubHeading,
            }}/>
            {/* {jointOfferList?.modalSubHeading} */}
                    
          </Body>

          <ReviewOfferListItemsHeading>
            
            <MarginSpacerL />
            <Body size="large" bold>{jointOfferList?.content1}</Body>
          </ReviewOfferListItemsHeading>
          
          <ListItemsWrapper>{getUnorderListTop()}</ListItemsWrapper>       

          {/* <ReviewModalDesc>
            <Body size="large">{jointOfferList?.content2}</Body>
          </ReviewModalDesc> */}

          {jointOfferList?.content2 && 
            <>
              <ReviewOfferListItemsHeading>
                <Body size="large" bold>{jointOfferList?.content2}</Body>
              </ReviewOfferListItemsHeading>

              <ListItemsWrapper>{getUnorderListBottom()}</ListItemsWrapper>
            </>
          }
          <ReviewModalDesc>
                <Body size="large">{jointOfferList?.content3}</Body>
                <MarginSpacerM />
                <Body size="large">{jointOfferList?.content4}</Body>
                <MarginSpacerL />
                <Body size="large">{jointOfferList?.disclaimer}</Body>
          </ReviewModalDesc> 
                    

        </ModalBody>

        <ReviewModalFooter>
          <Button 
            size="large"
            disabled={false}
            use="primary"
            onClick={()=>props?.setJointOfferModal(false)}
            >
            Close
          </Button>
        </ReviewModalFooter>


      </NCModal>
    </>
  )
}

const NCModal = styled(Modal)`
  height: auto;
  width: 560px;
  min-height: auto;
  outline: none !important;
  overflow: hidden;
  z-index:9999;
  > div:first-child {
    overflow: hidden;
  }
  @media only screen and (max-width:1030px) {
    width:60%
  }
  
  @media only screen and (max-width: 767px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95vw;
    > div:first-child {
      padding: 2px;
    }
  }

  @media only screen and (max-width:420px) {    
    bottom: 0px;
    position: fixed;
    top: 0px;
    left: 0px;
    transform:translate(0px,0px);
  }
`;

const ReviewOfferListItemsHeading = styled.div`
  margin:0.5rem 0px 1rem 0px;
`

const ReviewModalDesc = styled.div`
  margin:2rem 0px;
`

const ReviewModalFooter = styled.div`
`

const ListItemsWrapper = styled.div`
  ol {padding-left:20px;
    li {margin-top:1rem;    
      & > p {padding-left:8px;}
    }
  }
`



export default JointOfferDetailsNew;