import { createSlice, createAction, createSelector } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  apiError: false,
  cartId: null,
  multiDeviceSelectionInfo: false,
  multiDeviceSelectionInfoFeature: false,
  isSelectAllChecked: true,
  disablePlanSelection: false,
  isFeaturePageVisted: false,
  isCartPageVisted: false,
  devicePlanList: [],
  availableDevices: [],
  featuresAvailableDevices: [],
  featuresDevicePlanList: [],
  featuresSelectedDevices: [],
  deviceTypeCompatiblePlans: [],
  compatiblePlans: [],
  isAllFeaturesSelectedOnRevisit: false
};

const PlansSlice = createSlice({
  name: "Plans",
  initialState,
  reducers: {
    resetPlansSlice: (state) => {
      return initialState;
    },
    setDeviceTypeCompatiblePlans: (state, action) => {
      state["deviceTypeCompatiblePlans"] = action.payload;
    },
    setDeviceTypeCompatiblePlansNew: (state, action) => {
      state["DeviceTypeCompatiblePlansNew"] = action.payload;
    },
    setSelectedPlansNew: (state, action) => {
      state["selectedPlansNew"] = action.payload;
    },
    setDeviceTypeFeaturesNew: (state, action) => {
      state["DeviceTypeFeaturesNew"] = action.payload;
    },
    setSelectedFeatureNew: (state, action) => {
      state["SelectedFeatureNew"] = action.payload;
    },
    setIsAllFeaturesSelectedOnRevisit: (state, action) => {
      state["isAllFeaturesSelectedOnRevisit"] = action.payload;
    },
    setCartId: (state, action) => {
      state["cartId"] = action.payload;
    },
    setPageLoading: (state, action) => {
      state["loading"] = action.payload;
    },
    setAPIError: (state, action) => {
      state["apiError"] = action.payload;
    },
    setMultiDeviceSelectionInfo: (state, action) => {
      state["multiDeviceSelectionInfo"] = action.payload;
    },
    setMultiDeviceSelectionInfoFeature: (state, action) => {
      state["multiDeviceSelectionInfoFeature"] = action.payload;
    },
    setDeviceCompatiblePlansInfo: (state, action) => {
      state["deviceCompatiblePlansInfo"] = action.payload;
    },
    setFeaturesDetails: (state, action) => {
      state["featuresData"] = action.payload;
    },
    setSelectedFeatures: (state, action) => {
      state["selectedFeatures"] = action.payload;
    },
    setAvailableDevices: (state, action) => {
      state["availableDevices"] = action.payload;
    },
    setFeaturesAvailableDevices: (state, action) => {
      state["featuresAvailableDevices"] = action.payload;
    },
    setDevicePlanPromosInfo: (state, action) => {
      state["devicePlanPromosInfo"] = action.payload;
    },
    setMetaInfo: (state, action) => {
      state["metaInformation"] = action.payload;
    },
    setPlanAEM: (state, action) => {
      state["plansAEM"] = action.payload;
    },
    setFeaturesAEM: (state, action) => {
      state["featuresAEM"] = action.payload;
    },
    setdevicePlanList: (state, action) => {
      state["devicePlanList"] = action.payload;
    },
    setFeaturesDevicePlanList: (state, action) => {
      state["featuresDevicePlanList"] = action.payload;
    },
    setSelectedDevices: (state, action) => {
      state["selectedDevices"] = action.payload;
    },
    setFeaturesSelectedDevices: (state, action) => {
      state["featuresSelectedDevices"] = action.payload;
    },
    setIsSelectAllChecked: (state, action) => {
      state["isSelectAllChecked"] = action.payload;
    },
    setDisablePlanSelection: (state, action) => {
      state["disablePlanSelection"] = action.payload;
    },
    setIsFeaturePageVisted: (state, action) => {
      state["isFeaturePageVisted"] = action.payload;
    },
    setIsCartPageVisted: (state, action) => {
      state["isCartPageVisted"] = action.payload;
    },
    setCompatiblePlans: (state, action) => {
      state["compatiblePlans"] = action.payload;
    }
  },
});

export const reducer = PlansSlice.reducer;
export const getMetaInformation = createAction("PlansSlice/getMetaInformation");
export const getInitPageLoadInfo = createAction(
  "PlansSlice/getInitPageLoadInfo"
);
export const getPlansAEM = createAction("PlansSlice/getPlansAEM");
export const getDevicePlanPromos = createAction(
  "PlansSlice/getDevicePlanPromos"
);
export const getFeatures = createAction("PlansSlice/getFeatures");
export const getDeviceCompatiblePlans = createAction(
  "PlansSlice/getDeviceCompatiblePlans"
);
export const setPlanForDevices = createAction("PlansSlice/setPlanForDevices");
export const setFeatureForDevices = createAction(
  "PlansSlice/setFeatureForDevices"
);
export const {
  resetPlansSlice,
  setPageLoading,
  setAPIError,
  setDeviceCompatiblePlansInfo,
  setAvailableDevices,
  setDevicePlanPromosInfo,
  setMetaInfo,
  setPlanAEM,
  setdevicePlanList,
  setFeaturesDevicePlanList,
  setFeaturesSelectedDevices,
  setFeaturesAvailableDevices,
  setSelectedDevices,
  setIsSelectAllChecked,
  setFeaturesDetails,
  setSelectedFeatures,
  setDisablePlanSelection,
  setMultiDeviceSelectionInfo,
  setFeaturesAEM,
  setIsFeaturePageVisted,
  setIsCartPageVisted,
  setDeviceTypeCompatiblePlans,
  setCartId,
  setMultiDeviceSelectionInfoFeature,
  setCompatiblePlans,
  setDeviceTypeCompatiblePlansNew,
  setSelectedPlansNew,
  setDeviceTypeFeaturesNew,
  setSelectedFeatureNew,
  setIsAllFeaturesSelectedOnRevisit
} = PlansSlice.actions;
