import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import {
  AccordionHeader,
  AccordionItem,
  AccordionDetail,
} from "@vds3/accordions";
import { TextLink } from "@vds3/buttons";
import { Body, Title } from "@vds3/typography";
import { Notification } from "@vds3/notifications";
import {
  MarginSpacerS,
  MarginSpacerM,
  MarginSpacerXL,
  MarginTopSpacerS,
  MarginSpacerL,
} from "common/components/styleTag";
import EquipmentCard from "./EquipmentCard";
import {
  ByorDisclaimerModal,
  CAResidentModal,
  EquipmentLearnMoreModal,
} from "../Modals";
import IMEICheckModal from "pages/Landing4G-V2/components/IMEICheckModal/IMEICheckModal";
import IMEICheckModal5G from "pages/Landing5G-V2/components/IMEICheckModal/IMEICheckModal";
import InternetGatewayModal from "../Modals/InternetGatewayModal";
import FWAContext from "../fwaContext";
import { CustomAccordion, AccordionWrapper, AccordionTitleFlex, CustomAccordionItem, EquipmentAccordionContainer } from "../styles.js";
import { EquipmentCardGroup, LinksSection, LinksSubSection } from "./styles.js";
import { GridContainer, GridSpanItem } from "../PlanSelection/styles";
import { setSelectedRouter, setToggleForBandRouter } from "pages/Landing4G/ducks/4GSlice";
import { setSelectedRouterPayment, setToggleForCBandRouter } from "pages/Landing5G/ducks/5GSlice";

const EquipmentSection = ({
  backupPlanRouters,
  isCollapsed,
  isEditLTEEquipmentFlow = false,
  isEdit5GEquipmentFlow = false,
  isHavingBackupPlans = false,
  onRouterSelect,
  routers,
  setIsCollapsed,
  setCollapsePayment,
  isfromAuth,
  planDetails,
  selectRouterErr,
  setSelectRouterErr,
  starkEnabled,
  installmentTermStark,
  collapseEquipmentReceiver,
  fromInstallation = false,
  onlyCBandQualified=false,
  toggleStatusForCBandRouter,
  updateCart=()=>{},
  is4G  =false,
  ...props
}) => {
  const { flow, primaryPlan, backupPlan, sessionCartData } = useContext(FWAContext);
  const { t } = useTranslation(["app", "4G-5G"]);
  const aemKey = flow === "4g" ? "fourG" : "fiveG";
  const aemContent = t("4G-5G", { ns: "4G-5G", returnObjects: true })[aemKey][
    "router-content"
  ];
  const disableBackupPlansFeature = t("4G-5G", {
    ns: "4G-5G",
    returnObjects: true,
  })[aemKey]["disableBackupPlansFeature"];
  const routerContent = aemContent[flow === "4g" ? "routers" : "router"];
  const isMobile = useMediaQuery({
    query: "(max-width: 545px)",
  });
  const isTablet=useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const dispatch = useDispatch();
  const [internetGatewayModal, setInternetGatewayModal] = useState(false);
  const [byorDisclaimerModal, setByorDisclaimerModal] = useState(false);
  const [showImeiCheckModal, setImeiCheckModal] = useState(false);
  const [isValidIMEI, setIsValidIMEI] = useState(false);
  const [cAWarningModal, setCAWarningModal] = useState(false);
  const [equipmentLearmMoreModal, setEquipmentLearmMoreModal] = useState(false);
  const [equipmentType, setEquipmentType] = useState("");
  const [reintiaterFlag, setReintiaterFlag] = useState(false);
  const [equipmentClicked, setEquipmentClicked] = useState(undefined);
  const disabledAccordion=(!!primaryPlan.selectedRouter);
 
  useEffect(()=>{
    if(equipmentClicked){
      setEquipmentClicked(undefined)
      updateCart(toggleStatusForCBandRouter[equipmentClicked] ? "devicePayments-0" : "zeroYear",false,"primaryPlan")
    }
  },[equipmentClicked])
  const onEquipmentSelection = (planType, e) => {
    setReintiaterFlag(!reintiaterFlag);
    const val = e;
    if ((flow === "4g" || flow === "5g") && val === "byor" && (!sessionCartData?.cart?.mwwCart || isfromAuth)) {
     setImeiCheckModal(true);
      setEquipmentType(planType);
    } else {
      onRouterSelect(val, planType,installmentTermStark);
      setIsCollapsed(false);
      if(val !== primaryPlan?.selectedRouter) {
        setCollapsePayment && setCollapsePayment(false);
      }
      if(isfromAuth){
        setIsCollapsed(false);
      }
    }
    if(onlyCBandQualified && val !== "byor"){
      setEquipmentClicked(e)
    }
  };

  useEffect(() => {
    if(primaryPlan.selectedRouter || backupPlan?.selectedRouter){
      setSelectRouterErr(false);
    }
    // if (!isfromAuth) {
      (!isEditLTEEquipmentFlow || !isEdit5GEquipmentFlow) && !starkEnabled &&  setIsCollapsed(
        false
        // (primaryPlan?.selectedPlan &&
        //   !backupPlan?.selectedPlan &&
        //   primaryPlan?.selectedRouter) ||
        // (!primaryPlan?.selectedPlan &&
        //   backupPlan?.selectedPlan &&
        //   backupPlan?.selectedRouter) ||
        // (primaryPlan?.selectedPlan &&
        //   backupPlan?.selectedPlan &&
        //   primaryPlan?.selectedRouter &&
        //   backupPlan?.selectedRouter)
      );
    // }
  }, [
    primaryPlan.selectedRouter,
    backupPlan?.selectedRouter,
    primaryPlan.selectedPlan,
    backupPlan?.selectedPlan,
    reintiaterFlag,
    isEditLTEEquipmentFlow,
    isEdit5GEquipmentFlow,
    isfromAuth
  ]);
  useEffect(()=>{
    if(routers && routers.deviceList!==null&&routers.deviceList&&routers.deviceList.length && onlyCBandQualified){
      let toggleSku = {...toggleStatusForCBandRouter} || {}
      routers.deviceList.map(router=>{
        const sku = router.skulist[0]?.sku

        if(toggleSku!==null&&toggleSku && (toggleSku[sku] === undefined || toggleSku[sku] === null)){
          toggleSku[sku] = true
        } 
        // else if(sku === primaryPlan?.selectedRouter){
        //   if(sessionCartData?.cart?.packages && Object.values(sessionCartData?.cart?.packages)?.length > 0){
        //     const routerData = Object.values(sessionCartData?.cart?.packages).filter(item => item.deviceSku === sku);
        //     toggleSku[sku] = routerData[0]?.priceType === 'RETAIL' ? false : true;
        //   }
        // }
        // else {
        //   toggleSku[sku] = true
        // }
      })
      if(!is4G) dispatch(setToggleForCBandRouter(toggleSku))
        if(is4G) dispatch(setToggleForBandRouter(toggleSku))
    }

  },[onlyCBandQualified,routers])

  const getByorCard = (enableStarkReceiver, planType) => {
    let aemContentByor = aemContent["ownRouter"];

    if (isfromAuth) {
      aemContentByor = aemContent["ownRouter"];
    } else if (enableStarkReceiver) {
      aemContentByor = aemContent["ownRouterWithStarkReceiver"];
    } else if (starkEnabled){
      aemContentByor = aemContent["ownRouterWithStarkReceiverNew"];
    } else if (!props.isCband && flow === "5g") {
      aemContentByor = aemContent["ownRouterWithoutReceiver"];
    } else if (props?.mwwCart) {
      aemContentByor = aemContent["ownRouterWithoutReceiver"];
    }
    const commonPlan = planType === "primaryPlan" ? primaryPlan : backupPlan;
    const isDisabled = commonPlan && commonPlan.quantity && Number(commonPlan.quantity) > 1;

    if (isDisabled && commonPlan.selectedRouter === "byor") {
      dispatch(setSelectedRouter({ planType, value: "" }));
    }

    return (
      <EquipmentCard
        id={"byor"}
        key={"byor"}
        isByor={true}
        price={0}
        strikethroughPrice={aemContentByor?.price}
        description={aemContentByor?.desc1}
        isRecommended={aemContentByor?.recommended}
        quantity={commonPlan?.quantity}
        selected={commonPlan?.selectedRouter === "byor"}
        onChange={onEquipmentSelection.bind(this, planType)}
        isMobile={isMobile}
        aemContent={aemContentByor}
        isDisabled={isDisabled}
        mwwCart={props?.mwwCart}
        isfromAuth={isfromAuth}
      />
    );
  };

  const getEquipRadioBoxes = (planType) => {
    const commonPlan = planType === "primaryPlan" ? primaryPlan : backupPlan;
    const commonRouters =
      planType === "primaryPlan" ? routers : backupPlanRouters;
    let list = [],
      enableStarkReceiver = false;
    let offerDiscount=0;
    let sbdDiscAmt = 0;
    if (commonRouters?.deviceList) {
      const mapToggleValueToRouter = (toggle)=>{
        let toggleList = {}
        commonRouters.deviceList.map((router)=>{
          if(!!router.skulist && router.skulist.length){
          const sku = router.skulist[0]?.sku
          toggleList = {...toggleList,[sku]:toggle}
          }
        })
        if(!is4G) dispatch(setToggleForCBandRouter({...toggleList}))
        if(is4G){
          dispatch(setToggleForBandRouter({...toggleList}))
          const equipment = document.getElementById("primaryEquipmentSel")
          //setTimeout(()=>{if(equipment) equipment.scrollIntoView({behavior:'smooth'})},500)
        }

      }
      commonRouters.deviceList.map((router) => {
        const getDevicePayments = ()=>{
          if(!!router.skulist && router.skulist.length && !!router.skulist[0]?.pricingDetails && !!router.skulist[0]?.pricingDetails?.devicePayments &&
            router.skulist[0]?.pricingDetails?.devicePayments.length && router.skulist[0]?.pricingDetails?.devicePayments[0]
          ) return true
          return false
        }
        const sku = router.skulist[0]?.sku;
        const aemRouter = routerContent[sku];
        const promo =
          router.skulist[0]?.pricingDetails?.devicePayments?.length &&
          router.skulist[0]?.pricingDetails?.devicePayments[0]?.offerDetails;
        const zeroYearPromo =
          router.skulist[0]?.pricingDetails?.zeroYear?.offerDetails;
        const pricingDetails = router?.skulist[0]?.pricingDetails;
        if (pricingDetails && pricingDetails?.devicePayments) {
          pricingDetails?.devicePayments?.forEach((dppPrice, index) => {
            if (dppPrice?.offerDetails) {
              offerDiscount = dppPrice?.offerDetails?.discountAmount
            }
          }
          )
        };
        const pricingDetailssbd = router?.skulist[0]?.pricingDetails;
        
        if (pricingDetailssbd && pricingDetailssbd?.devicePayments) {
          pricingDetailssbd?.devicePayments?.forEach((dppPrice, index) => {
            if (dppPrice?.sbdOffer) {
              sbdDiscAmt = dppPrice?.sbdOffer?.discAmt
            }
            else{
              sbdDiscAmt = 0;
            }
          }
          )
        };      
        let finalPrice =
          promo?.discountAmount ?? zeroYearPromo?.discountAmount ??
          router.skulist[0]?.pricingDetails?.zeroYear?.price;
        let strikethroughPrice = promo?.itemOriginalPrice ?? zeroYearPromo?.itemOriginalPrice;
        if(onlyCBandQualified){
          if(!!toggleStatusForCBandRouter && toggleStatusForCBandRouter[sku]){
            finalPrice = (promo && getDevicePayments()) ? router.skulist[0]?.pricingDetails?.devicePayments[0]?.discountAmount : getDevicePayments() ? router.skulist[0]?.pricingDetails?.devicePayments[0]?.finalPrice : finalPrice
            strikethroughPrice = (promo && getDevicePayments()) ? router.skulist[0]?.pricingDetails?.devicePayments[0]?.finalPrice : strikethroughPrice
          }else{
            finalPrice = router.skulist[0]?.pricingDetails?.zeroYear?.price ?? 0
            strikethroughPrice = 0
          }
        }
       
        list.push(
          <EquipmentCard
            id={sku}
            key={sku}
            price={finalPrice}
            totalPriceFromEquipment = {router.skulist[0]?.pricingDetails?.zeroYear?.price ?? 0}
            strikethroughPrice={strikethroughPrice}
            description={planType === 'primaryPlan' ? aemRouter?.desc1 : aemRouter?.backDesc}
            features={aemRouter?.features}
            isRecommended={aemRouter?.recommended}
            quantity={commonPlan?.quantity}
            selected={commonPlan?.selectedRouter === sku}
            onChange={onEquipmentSelection.bind(this, planType)}
            isMobile={isMobile}
            imgUri={router.skulist[0]?.filePath}
            aemContent={aemRouter}
            mwwCart={props?.mwwCart}
            starkEnabled={starkEnabled}
            description2={aemRouter?.description2}
            description3={aemRouter?.description3}
            fromReceiver={false}
            isfromAuth={isfromAuth}
            fromInstallation = {fromInstallation}
            onlyCBandQualified={onlyCBandQualified}
            discountAmt = {strikethroughPrice > 0 ? strikethroughPrice : finalPrice}
            installments = {getDevicePayments() ? router.skulist[0]?.pricingDetails?.devicePayments[0]?.nofInstallements : 0}
            dicountamounttotalprice = {getDevicePayments() ? router.skulist[0]?.pricingDetails?.devicePayments[0]?.price : 0}
            toggled = {(!!toggleStatusForCBandRouter && toggleStatusForCBandRouter[sku]) ? toggleStatusForCBandRouter[sku] : false}
            selectedValue = {(toggle)=>mapToggleValueToRouter(toggle)}
            offerDiscount= {offerDiscount}
            sbdDiscAmt={sbdDiscAmt}
          />
        );
      });
      list.push(getByorCard(false, planType));
    }
    return list;
  };

  const showInternetGatewayModal = () => {
    const routerContains = primaryPlan?.routerDetails?.deviceList?.map((device)=>
      {
        return device?.skulist[0]?.sku;
      }
    )
    let aem = aemContent,
      internetGatewayModalHTML = [],
      modalSubHeading,
      showOption0 = false,
      modalTitle = "";
      let modalArr=[];
    if (planDetails && planDetails?.length) {
      planDetails?.map((plan) => {
        if (plan?.billingCode === primaryPlan?.selectedPlan) {
          plan?.cband ? showOption0 = true : showOption0 = false;
        }
      })
    }
    if (flow === "5g") {
      if(starkEnabled){
        if(aem?.VBGCR1000AModal){
          const { title, info } = aem?.VBGCR1000AModal;
          modalTitle = title;
          modalSubHeading = info;
          internetGatewayModalHTML = [];
           modalArr = [
            {
              "subHeading" : modalSubHeading,
              "subContent" : internetGatewayModalHTML
            }
          ]
        }
      }else{
      if ((props?.isMMW && props?.isCband && (primaryPlan.selectedPlan === "32781")) || (props?.isMMW && !props.isCband)) {
        // const { title, option1, option2, option3, info } =
        //   aem?.arrisNVG558Modal;
        // modalTitle = title;
        // modalSubHeading = info;
        // internetGatewayModalHTML = [option1, option2, option3];
        modalTitle = aem.headingGatewayEquipmentModal;
        modalArr = aem?.gatewayModal || [];
        modalArr = modalArr?.map((data)=>{
          const routerSku = Object.keys(data)[0];
          if(routerContains?.includes(routerSku)){
            return {
            subHeading: data[routerSku]?.subHeading,
            subContent : Object.values(data[routerSku]?.subContent)
           } 
          }
        })
      } else {
        modalTitle = aem.headingGatewayEquipmentModal;
        modalArr = aem?.gatewayModal || [];
        modalArr = modalArr?.map((data)=>{
          const routerSku = Object.keys(data)[0];
          if(routerContains?.includes(routerSku)){
            return {
            subHeading: data[routerSku]?.subHeading,
            subContent : Object.values(data[routerSku]?.subContent)
           } 
          }
        })
        // const {
        //   title,
        //   option0,
        //   option1,
        //   option2,
        //   option3,
        //   option4,
        //   option5,
        //   option6,
        //   option7,
        //   info,
        // } = aem.verizonInternetGatewayModal;
        // modalTitle = title;
        // modalSubHeading = info;
        // (showOption0) ?
        //   internetGatewayModalHTML = [
        //     option0,
        //     option1,
        //     option2,
        //     option3,
        //     option4,
        //     option5,
        //     option6,
        //   ]
        //   :
        //   internetGatewayModalHTML = [
        //     option1,
        //     option2,
        //     option3,
        //     option4,
        //     option5,
        //     option6,
        //     option7,
        //   ];
      }
      // modalArr = [
      //   {
      //     "subHeading" : modalSubHeading,
      //     "subContent" : internetGatewayModalHTML
      //   }
      // ]
    }
    } else {
      modalTitle = aem.headingGatewayEquipmentModal;
      modalArr = aem?.gatewayModal || [];
      modalArr = modalArr?.map((data)=>{
        const routerSku = Object.keys(data)[0];
        if(routerContains?.includes(routerSku)){
          return {
          subHeading: data[routerSku]?.subHeading,
          subContent : Object.values(data[routerSku]?.subContent)
         } 
        }
      })

      // const {item1,item2}
      // const { subHeading, content } = aem?.gatewayModalContent1 || {};
      // modalSubHeading = subHeading;
      // {
      //   (flow === "4g" && (primaryPlan?.selectedPlan === "48423" || primaryPlan?.selectedPlan === "48445")) ?
      //   internetGatewayModalHTML = [
      //     content?.ListItem0,
      //     content?.ListItem1,
      //     content?.ListItem2,
      //     content?.ListItem3,
      //   ] :
      //   internetGatewayModalHTML = [
      //     content?.ListItem1,
      //     content?.ListItem2,
      //     content?.ListItem3,
      //   ];
      // }
    }

    const handleClick = () => {
      setInternetGatewayModal(false);
    };

    return (
      <InternetGatewayModal
        internetGatewayModalHTML={internetGatewayModalHTML}
        modalSubHeading={modalSubHeading}
        modalTitle={modalTitle}
        modalArr={modalArr}
        internetGatewayModal={internetGatewayModal}
        setInternetGatewayModal={setInternetGatewayModal}
        handleClick={handleClick}
        isMobile={isMobile}
        starkEnabled={starkEnabled}
      />
    );
  };

  const showBYORDisclaimerModal = () => {
    let aem = aemContent,
      byorDisclaimerModalHTML = [],
      modalSubHeading,
      modalTitle = "";

    if (flow === "5g") {
      if(starkEnabled){
        if(aem?.byorStarkDisclaimerModal){
        let byorAem = aem?.byorStarkDisclaimerModal;
        const { heading, content, label } = byorAem;
        const { subHeading, ListItem1, ListItem2 } = content;

        modalTitle = heading;
        modalSubHeading = subHeading;
        byorDisclaimerModalHTML = [ListItem1, ListItem2];
        }
      }else{
      let byorAem = aem.byorDisclaimerModal;
      if ((props?.isMMW && props?.isCband && (primaryPlan.selectedPlan === "32781")) || (props?.isMMW && !props.isCband)) {
        byorAem = aem.byorMMWDisclaimerModal;
      }
      const { heading, content, label } = byorAem;
      const { subHeading, ListItem1, ListItem2 } = content;

      modalTitle = heading;
      modalSubHeading = subHeading;
      byorDisclaimerModalHTML = [ListItem1, ListItem2];
    }
    } else {
      const { heading, content, label } = aem.byorUpdatedDisclaimerModal;
      const { subHeading, ListItem1, ListItem2} = content;

      modalTitle = heading;
      modalSubHeading = subHeading;
      byorDisclaimerModalHTML = [ListItem1, ListItem2];
    }

    return (
      <ByorDisclaimerModal
        showBYORDisclaimerModal={byorDisclaimerModal}
        setShowBYORDisclaimerModal={setByorDisclaimerModal}
        isMobile={isMobile}
        modalTitle={modalTitle}
        modalSubHeading={modalSubHeading}
        byorDisclaimerModalHTML={byorDisclaimerModalHTML}
        isMMW={props?.isMMW}
      />
    );
  };

  const showLearnMoreModal = () => {
    return (
      <EquipmentLearnMoreModal
        showEquipmentLearnMoreModal={equipmentLearmMoreModal}
        setShowEquipmentLearnMoreModal={setEquipmentLearmMoreModal}
        isMobile={isMobile}
         />
    );
  };

  const showProp65WarningModal = () => {
    return (
      <CAResidentModal
        cAWarningModal={cAWarningModal}
        setCAWarningModal={setCAWarningModal}
        isMobile={isMobile}
       />
    );
  };

  const onIMEIModalChange = (val) => {
    if (val === false) {
      setImeiCheckModal(false);
      equipmentType && onRouterSelect("byor", equipmentType);
      setIsCollapsed(!isCollapsed);
      setIsValidIMEI(false);
      setCollapsePayment && setCollapsePayment(false);
    }
  };

  const selectionDisplayText = useCallback(
    (planType) => {
      const commonPlan = planType === "primaryPlan" ? primaryPlan : backupPlan;
      const commonRouters =
        planType === "primaryPlan" ? routers : backupPlanRouters;
      if (commonPlan?.selectedRouter) {
        if (commonPlan?.selectedRouter === "byor") {
          return "Bring your own router";
        } else if (commonRouters?.deviceList?.length) {
          const routerSelected = commonPlan?.selectedRouter;
          let index = commonRouters?.deviceList?.findIndex(obj=>obj?.skulist[0]?.sku?.includes(routerSelected));      
          index = (index == -1) ? 0 : index;
          const sku = commonRouters?.deviceList[index]?.skulist[0]?.sku;
          let displayName =
            routerContent[sku]?.title && routerContent[sku]?.title.trim();
            if (displayName && displayName?.slice(-6)?.toLowerCase() !== "router") {
            displayName += " router";
          }
          return displayName ?? "";
        }
      }
    },
    [routers, backupPlanRouters, primaryPlan, backupPlan]
  );

  const CustomAccordionDetail = (planType) => (
    <>
      {flow === "4g" && (
        <LinksSection>
          <Body>
            {primaryPlan?.quantity > 1 ? (
              <>
                {aemContent.equipmentLearnMoreModal.descriptionMultiPlans + " "}
                <TextLink
                  onClick={() => {
                    setEquipmentLearmMoreModal(true);
                  }}
                  size="small"
                  type="standAlone"
                >
                  {aemContent.learnMore}
                </TextLink>
              </>
            ) : (
              flow === "4g"?aemContent.equipmentLearnMoreModal.descriptionForLte:
              aemContent.equipmentLearnMoreModal.description
            )}
          </Body>
          <MarginSpacerL />
        </LinksSection>
      )}
      {props?.isCband && (
        <LinksSection>
        <Body>
        {aemContent.routerDescription}
        </Body>
        <MarginSpacerL />
        </LinksSection>
      )}
      <EquipmentCardGroup
        isMobile={isMobile}
        span={getEquipRadioBoxes(planType)?.length}
        isfromAuth={isfromAuth}
        fromEquip = {true}
      >
        {getEquipRadioBoxes(planType)}
      </EquipmentCardGroup>
      <LinksSection>
        <MarginSpacerXL />
        <LinksSubSection>
          <TextLink
            onClick={() => {
              setInternetGatewayModal(true);
            }}
            size="small"
            type="standAlone"
          >
            {starkEnabled ? aemContent?.headingStarkRouterModal :  !!props?.mwwCart && !isfromAuth
              ? aemContent?.headingArrisEquipmentModal
              : props?.isCband ? aemContent?.headingGatewayEquipmentCbandModal : aemContent?.headingGatewayEquipmentText}
          </TextLink>
          <TextLink
            onClick={() => {
              setByorDisclaimerModal(true);
            }}
            size="small"
            type="standAlone"
          >
            {starkEnabled ? aemContent?.starkRouterHyperlinkHeading : flow === "4g" ? aemContent?.fourgRouterHyperLinkHeading : aemContent?.fivegRouterHyperLinkHeading}

          </TextLink>
        </LinksSubSection>
        <MarginSpacerXL />
        <Body>
          {t("app.common.prop65WarningModal.headingInfo.primary") + " "}
          <TextLink
            onClick={() => {
              setCAWarningModal(true);
            }}
            size="small"
            type="standAlone"
          >
            {t("app.common.prop65WarningModal.headingInfo.secondary")}{" "}
            <b>{t("app.common.prop65WarningModal.headingInfo.warning")}</b>
          </TextLink>
        </Body>
      </LinksSection>
    </>
  );
  return (
    <EquipmentAccordionContainer>
  
      <AccordionWrapper
        disabledAccordion={false}
       >
        <CustomAccordion surface="light" >
          <CustomAccordionItem opened={starkEnabled ? !isCollapsed && collapseEquipmentReceiver : !isCollapsed} alwaysOpen={(disabledAccordion)? false : true}>
            <AccordionHeader
              triggerType={
                disabledAccordion
                  ? "link"
                  : ""
              }
              closeAccordionTriggerLabel="Collapse"
              onClick={() => {
                setIsCollapsed(!isCollapsed);
              }}
              children={
                <>
                <AccordionTitleFlex isMobile={isMobile} >
                <Title size={"large"} bold>
                    <>
  {(primaryPlan?.selectedRouter && !starkEnabled) ? (aemContent?.selectedRouter) : (primaryPlan?.selectedRouter && starkEnabled) ?(aemContent?.headingStarkSelectedRouter):(!primaryPlan?.selectedRouter && starkEnabled)?(aemContent?.headingStarkRouter):(aemContent?.headingRouter)}
                  
                  </>
                  </Title>
                  {starkEnabled && !primaryPlan?.selectedRouterPayment && (
                    <MarginTopSpacerS>
                      <Body size="large" color="#6F7171">
                      {aemContent?.requiredReceiverPaymentSelection}
                      </Body>
                    </MarginTopSpacerS>
                  )}
                   {!starkEnabled && !primaryPlan?.selectedPlan && !backupPlan?.selectedPlan && (
                    <MarginTopSpacerS>
                      <Body size="large" color="#6F7171">
                      {aemContent?.requiredPlanSelection}
                      </Body>
                    </MarginTopSpacerS>
                  )}
                </AccordionTitleFlex>
                  {!!isCollapsed && (
                    <>
                      <MarginSpacerS />
                      {primaryPlan?.selectedRouter && (
                        <Title size="small" color="#6F7171">
                            <span
                              style={{ fontWeight: "bold", paddingRight: 5 }}
                            >
                              {`${flow === "4g"
                                  ? aemContent?.primaryEquipmentCollapseHeading
                                  : aemContent?.equipmentCollapseHeading
                              }:`}
                            </span>
                          {selectionDisplayText("primaryPlan")}
                        </Title>
                      )}
                      <MarginSpacerS />
                      {backupPlan?.selectedRouter && (backupPlan?.selectedRouter === "byor" || backupPlanRouters.deviceList?.length) && (
                        <Title size="small" color="#6F7171">
                          {isHavingBackupPlans && (
                            <span
                              style={{ fontWeight: "bold", paddingRight: 5 }}
                            >{`${aemContent?.backupEquipmentCollapseHeading}:`}</span>
                          )}
                          {selectionDisplayText("backupPlan")}
                        </Title>
                      )}
                    </>
                  )}
                </>
              }
            />
            <AccordionDetail
              children={
                <>
                 {selectRouterErr ?
                 <> 
                     <Notification
                      type="error"
                      inline={true}
                      fullBleed={true}
                      hideCloseButton={true}
                      title={t("4G-5G.common.page-stepper.router-err-msg", {
                        ns: "4G-5G",
                      })}
                      />
                      <MarginSpacerM></MarginSpacerM>
                  </>
                  : <></>}
                  {starkEnabled ? !!routers && !!primaryPlan?.selectedPlan && !!primaryPlan?.selectedRouterPayment && (
                    <GridContainer
                      isMobile={isMobile}
                      isTablet={isTablet}
                      style={{ rowGap: "1rem" }}
                    >
                      <GridSpanItem
                        span={(!isMobile && isfromAuth) ? getEquipRadioBoxes("primaryPlan")?.length + 1 : 
                          getEquipRadioBoxes("primaryPlan")?.length}
                      >
                        {CustomAccordionDetail("primaryPlan")}
                      </GridSpanItem>
                    </GridContainer>
                  ) : !!routers && !!primaryPlan?.selectedPlan && (
                    <GridContainer
                      isMobile={isMobile}
                      isTablet={isTablet}
                      style={{ rowGap: "1rem" }}
                    >
                      <GridSpanItem
                        span={(!isMobile && isfromAuth) ? getEquipRadioBoxes("primaryPlan")?.length + 1 : 
                          getEquipRadioBoxes("primaryPlan")?.length}
                      >
                        {CustomAccordionDetail("primaryPlan")}
                      </GridSpanItem>
                    </GridContainer>
                  )}
                  {/* {!!primaryPlan?.selectedPlan &&
                    !!backupPlan?.selectedPlan && (
                      <>
                        <div id="backupEquipmentSel">
                          <MarginSpacerXL />
                          <Line type="secondary" />
                          <MarginSpacerXL />
                        </div>
                      </>
                    )} */}
                  {/* {!!backupPlanRouters &&
                    flow === "4g" &&
                    isHavingBackupPlans &&
                    !!backupPlan?.selectedPlan && (
                      <GridContainer
                        isMobile={isMobile}
                        style={{ rowGap: "1rem" }}
                      >
                        <GridSpanItem
                          span={(!isMobile && isfromAuth) ? getEquipRadioBoxes("backupPlan")?.length + 1 :
                          getEquipRadioBoxes("backupPlan")?.length}
                        >
                          {get4gEquipmentTitle(aemContent?.backupEquipHeading)}
                          {CustomAccordionDetail("backupPlan", "", true)}
                        </GridSpanItem>
                      </GridContainer>
                    )} */}
            </>
              }
            />
          </CustomAccordionItem>
        </CustomAccordion>
      </AccordionWrapper>
      
      {showInternetGatewayModal()}
      {showBYORDisclaimerModal()}
      {showProp65WarningModal()}
      {showLearnMoreModal()}
      {showImeiCheckModal && flow === "4g" && (
        <IMEICheckModal
          opened={showImeiCheckModal}
          onOpenedChange={onIMEIModalChange}
          setIsValidIMEI={setIsValidIMEI}
          isfromAuth={isfromAuth}
          isMobile={isMobile}
          sessionCartData={sessionCartData}

        />
      )}
      {showImeiCheckModal && flow === "5g" && (
        <IMEICheckModal5G
          opened={showImeiCheckModal}
          onOpenedChange={onIMEIModalChange}
          isMobile={isMobile}
          isCband={props.isCband}
          isMMW={props.isMMW}
          setImeiCheckModal={setImeiCheckModal}
          isfromAuth={isfromAuth}
        />
      )}
    </EquipmentAccordionContainer>
  );
};

EquipmentSection.defaultProps = {
  quantity: 1,
};

export default EquipmentSection;
