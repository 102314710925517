import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
	apiError: false,
	clientId: "",
	cmp: {
		cmpValue: "",
		isFromGoogleShop: false,
	},
	editPackagePackageId: "",
	flow: "prospect",
	isEmptyByodFromDQFlow: false,
	isEditPackageFlow: false,
	isInitialAppLoadComplete: false,
	isNavigatedFrom5GToLTEPage: false,
	isNavigatedFromLTETo5GPage: false,
	isOrderQuoteFlow: false,
	loading: false,
	modals: {
		showNewCustomerModal: false,
	},
	notification: {
		closable: true,
		open: false,
		subtitle: "",
		title: "Not Provided",
		type: "warning",
	},
	sessionCartData: {},
	showStickyHeaderValues: true,
	ss7Domains: [],
	loadCheckoutWidget: false,
	retrieveLoader: false,
};

const GlobalSlice = createSlice({
	name: "Application",
	initialState,
	reducers: {
		resetGlobalSlice: (state, action) => {
			return initialState;
		},

		setApiError: (state, action) => {
			state["apiError"] = action.payload;
		},
		setCmpValue: (state, action) => {
			state["cmp"]["cmpValue"] = action.payload;
		},
		setCombinePricingInfo: (state, action) => {
			state["combinePricing"] = action.payload;
		},
		setEditPackagePackageId: (state, action) => {
			state["editPackagePackageId"] = action.payload;
		},
		setFlow: (state, action) => {
			state["flow"] = action.payload;
		},
		setIsEditPackageFlow: (state, action) => {
			state["isEditPackageFlow"] = action.payload;
		},
		setIsEmptyByodFromDQFlow: (state, action) => {
			state["isEmptyByodFromDQFlow"] = action.payload;
		},
		setIsFromGoogleShop: (state, action) => {
			state["cmp"]["isFromGoogleShop"] = action.payload;
		},
		setIsOrderQuoteFlow: (state, action) => {
			state["isOrderQuoteFlow"] = action.payload;
		},
		setLoading: (state, action) => {
			state["loading"] = action.payload;
		},
		setMetaInfo: (state, action) => {
			state["metaInformation"] = action.payload;
		},
		setModalsState: (state, action) => {
			const { modalName, show } = action.payload;
			state["modals"][modalName] = show || false;
		},
		setNotification: (state, action) => {
			const { title, subtitle, closable, type, open } = action.payload;
			state["notification"] = {
				title: title || "NA",
				subtitle: subtitle || "",
				closable: closable || true,
				type: type || "warning",
				open: open || false,
			};
		},
		setSessionCartData: (state, action) => {
			state["sessionCartData"] = action.payload;
		},
		setShowStickyHeaderValues: (state, action) => {
			state["showStickyHeaderValues"] = action.payload;
		},
		setIsInitialAppLoadComplete: (state, action) => {
			state["isInitialAppLoadComplete"] = action.payload;
		},
		setss7Domains: (state, action) => {
			state["ss7Domains"] = action.payload;
		},
		setSaveCartSuccess: (state, action) => {
			state["saveCartSuccess"] = action.payload;
		},
		setCartId: (state, action) => {
			state["cartId"] = action.payload;
		},
		setClientId: (state, action) => {
			state["clientId"] = action.payload;
		},
		setWidgetInputParams: (state, action) => {
			state["widgetInputParams"] = action.payload;
		},
		setIsNavigatedFrom5GToLTEPage: (state, action) => {
			state["isNavigatedFrom5GToLTEPage"] = action.payload;
			state["isNavigatedFromLTETo5GPage"] = false;
		},
		setIsNavigatedFromLTETo5GPage: (state, action) => {
			state["isNavigatedFromLTETo5GPage"] = action.payload;
			state["isNavigatedFrom5GToLTEPage"] = false;
		},
		setLoadShoppingCartWidget: (state, action) => {
			state["loadShoppingCartWidget"] = action.payload;
		},
		setLoadCheckoutWidget: (state, action) => {
			state["loadCheckoutWidget"] = action.payload;
		},
		setLoadQualficationLanding: (state, action) => {
			state["loadQualficationLanding"] = action.payload;
		},
		setLoadQualficationSelection: (state, action) => {
			state["loadQualficationSelection"] = action.payload;
		},
		setLoadFWAQualifiedPlans: (state, action) => {
			state["loadFWAQualifiedPlans"] = action.payload;
		},
		setShowSimCompatible: (state, action) => {
			state["showSimCompatible"] = action.payload;
		},
		setRetrieveLoader: (state, action) => {
			state["retrieveLoader"] = action.payload;
		},
		setBbloadError: (state, action) => {
			state["bbloadError"] = action.payload;
		},
		setContainerHeightBB: (state, action) => {
		state["containerHeightBB"] = action.payload;
		},
		setFeaturePageVisited: (state, action) => {
			state["featurePageVisted"] = action.payload;
		},
		setGWPlansID: (state, action) => {
			state["GWPlansID"] = action.payload;
		},
		setGWDeviceType: (state, action) => {
			state["GWDeviceType"] = action.payload;
		},
		setIsEditPlansFlow: (state, action) => {
			state["isEditPlansFlow"] = action.payload;
		},
		setIsEditPackageType: (state, action) => {
			state["isEditPackageType"] = action.payload;
		},
		setIsEditFeaturesFlow: (state, action) => {
			state["isEditFeaturesFlow"] = action.payload;
		},
		setEditFeaturesPackageId: (state, action) => {
			state["editFeaturesPackageId"] = action.payload;
		},
		setLoadMobilityProductDetails: (state, action) => {
			state["loadMobilityProductDetails"] = action.payload;
		},
		setSelectedGWProduct: (state, action) => {
			state["selectedGWProduct"] = action.payload;
		},
		setLoadMobilityPlansPage: (state, action) => {
			state["loadMobilityPlansPage"] = action.payload;
		},
		setLoadMobilityFeaturesPage: (state, action) => {
			state["loadMobilityFeaturesPage"] = action.payload;
		},
		setLoadBussProductsPage: (state, action) => {
			state["loadBussProductsPage"] = action.payload;
		},
		setLoadBussPDPPage: (state, action) => {
			state["loadBussPDPPage"] = action.payload;
		},
		setLoadConfirmationWidget: (state, action) => {
			state["loadConfirmationWidget"] = action.payload;
		},
		setCurrentFlow: (state, action) => {
			state["currentFlow"] = action.payload;
		},
		setNoThanksClicked : (state,action)=>{
			state["IsNothanksClicked"] = action.payload;
		  },
	},
});

export const getFlow = (flow) => {
	try {
		if (FLOWS[flow]) return FLOWS[flow];
		else throw "Flow Not Found";
	} catch (ex) {
		return FLOWS.REGULAR;
	}
};

export const FLOWS = {
	dsp: "dsp",
	PLANFIRST: "PLANFIRST",
	REGULAR: "REGULAR",
	TYS: "TYS",
};

export const reducer = GlobalSlice.reducer;

export const showLoading = createAction("GlobalSlice/showLoading");
export const hideLoading = createAction("GlobalSlice/hideLoading");
export const showStickyHeaderValues = createAction(
	"GlobalSlice/showStickyHeaderValues"
);
export const hideStickyHeaderValues = createAction(
	"GlobalSlice/hideStickyHeaderValues"
);
export const changeApiError = createAction("GlobalSlice/changeApiError");
export const changeFlow = createAction("GlobalSlice/changeFlow");
export const resetFlow = createAction("GlobalSlice/resetFlow");
export const showNotification = createAction("GlobalSlice/showNotification");
export const hideNotification = createAction("GlobalSlice/hideNotification");
export const getMetaInformation = createAction(
	"GlobalSlice/getMetaInformation"
);
export const getSessionCart = createAction("GlobalSlice/getSessionCart");
export const getGlobalPageLoadData = createAction(
	"GlobalSlice/getGlobalPageLoadData"
);
export const getCombinePricing = createAction("GlobalSlice/getCombinePricing");
export const postSaveCart = createAction("GlobalSlice/postSaveCart");
export const callAddressValidation = createAction("GlobalSlice/callAddressValidation");

export const {
	resetGlobalSlice,
	setApiError,
	setCmpValue,
	setCombinePricingInfo,
	setEditPackagePackageId,
	setFlow,
	setLoading,
	setIsEditPackageFlow,
	setIsEmptyByodFromDQFlow,
	setIsFromGoogleShop,
	setIsOrderQuoteFlow,
	setMetaInfo,
	setModalsState,
	setNotification,
	setSessionCartData,
	setShowStickyHeaderValues,
	setIsInitialAppLoadComplete,
	setss7Domains,
	setSaveCartSuccess,
	setCartId,
	setIsNavigatedFrom5GToLTEPage,
	setClientId,
	setIsNavigatedFromLTETo5GPage,
	setLoadShoppingCartWidget,
	setLoadCheckoutWidget,
	setShowSimCompatible,
	setWidgetInputParams,
	setRetrieveLoader,
	setBbloadError,
	setContainerHeightBB,
	setGWPlansID,
	setGWDeviceType,
	setIsEditPlansFlow,
	setIsEditPackageType,
	setIsEditFeaturesFlow,
	setEditFeaturesPackageId,
	setLoadMobilityProductDetails,
	setLoadMobilityPlansPage,
	setLoadMobilityFeaturesPage,
	setLoadBussPDPPage,
	setLoadBussProductsPage,
	setFeaturePageVisited,
	setSelectedGWProduct,
	setLoadConfirmationWidget,
	setLoadQualficationLanding,
	setLoadQualficationSelection,
	setLoadFWAQualifiedPlans,
	setCurrentFlow,
	setNoThanksClicked
} = GlobalSlice.actions;
