import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {  useSelector } from "react-redux";
import {
  Accordion,
  AccordionHeader,
  AccordionTitle,
  AccordionSubTitle,
  AccordionItem,
  AccordionDetail,
} from "@vds3/accordions";
import CartContext from "pages/ShoppingCart/cartContext";
import {
  LineItemPriceGrid,
  LineItem,
  LineItemPriceGridSection,
} from "../LineItemPriceGrid/LineItemPriceGrid";
import {
  getDisplayName,
  getDeviceCategory,
  getDeviceKey,
} from "./../../ItemDescription";
import { formatPriceString } from "utils/commonUtils";
import { MarginSpacerM, MarginSpacerS, MarginSpacerL } from "common/components/styleTag";
import styled from "styled-components";
import { Body } from "@vds3/typography";
import SpinnerComponent from "common/components/Spinner/Spinner";

const DueYearly = ({ isOpenByDefault, eacDisclaimerEnabled, ...props }) => {
  const { t } = useTranslation(["shoppingCart"]);
  const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
  const sessionCartData =
  globalData?.sessionCartData?.apiResponse?.prospectFlowData;

  const cartContextData = useContext(CartContext);
  const cartData = sessionCartData ?? cartContextData.cartData

  const [isOpened, setOpened] = useState(isOpenByDefault);
  const dueMonthlySummary = cartContextData?.dueYearlyLocal || [];
  const aemCategories =
    t("shopping-cart.cart-category-names", {
      returnObjects: true,
    }) || {};
  const byorDisplayName = t("4g.shopping-cart.cartBYOR.name");
  const [filteredDueMonthly, setFilteredDueMonthly] =
    useState(dueMonthlySummary);
  const [wirelessFeature, setWirelessFeature] = useState(null);
  const [eacCharge, setEacCharge] = useState(null);
  const [isBusinessByor, setIsBusinessByor] = useState(false);
  useEffect(() => {
    if (dueMonthlySummary && Object.keys(dueMonthlySummary)?.length) {
      let filteredValue = {};
      Object.keys(dueMonthlySummary)?.forEach((key) => {
        const prevList = filteredValue[getDeviceKey(key)];
        let isDevices = key?.toLowerCase()?.includes("device");
        let isWirelessFeature = 
        (key?.toLowerCase()?.includes("onetalkfeature") || 
        key?.toLowerCase()?.includes("wirelessfeature"));
        let features = (key?.toLowerCase()?.includes("feature"));
        let dueMonthlyMap = dueMonthlySummary[key];
        if(isDevices){
          dueMonthlyMap = dueMonthlySummary?.[key]?.map((device) => {
            if(device?.byod){
              return {...device, isDevices: !!isDevices}
            }
            return device;
          });
        }
        if(isWirelessFeature){
          dueMonthlyMap = dueMonthlySummary?.[key]?.map((device) => {
            if(device?.itemName && device?.itemName !== "ECONOMIC ADJUSTMENT CHARGE"){
              return {...device, itemName: `${device?.itemName} **`}
            }
            return device;
          });
        }
        if(features) {
          let isFeatures = dueMonthlySummary?.[key]?.filter((device) => {
            if(device?.itemName && device?.itemName === "ECONOMIC ADJUSTMENT CHARGE"){
              return device;
            }
          });
          if(!isFeatures?.length) {
            dueMonthlyMap = [...dueMonthlyMap, {
              byod: false,
              byor: false,
              count: 1,
              itemPrice: 0,
              itemName: t("smart-phones.shopping-cart.economicAdjustmentMessage")
            }];
          }
        }
        if (prevList) {
          filteredValue[getDeviceKey(key)] = [
            ...prevList,
            ...dueMonthlyMap,
          ];
        } else {
          filteredValue[getDeviceKey(key)] = [...dueMonthlyMap];
        }
      });
      if (filteredValue && Object.keys(filteredValue)?.length) {
        let wirelessEconomic = [], price = 0.00, count = 0;
        Object.keys(filteredValue).map((key) => {
          filteredValue[key]?.map((list) => {
            if(list?.itemName?.toUpperCase() === "ECONOMIC ADJUSTMENT CHARGE"){
              price = price + list?.itemPrice;
              count = count + list?.count;
              wirelessEconomic.push(list);
            }
          });
        });
        const isByor = filteredValue?.BusinessInternet?.filter(
          (list) => list?.byor === true
        );
        isByor?.length && setIsBusinessByor(true);
        if(wirelessEconomic?.length) {
          setWirelessFeature(wirelessEconomic[0]);
          setEacCharge({count: count, price: price});
        } 
        // else {
        //   setWirelessFeature({itemName:  t("smart-phones.shopping-cart.economicAdjustmentMessage")});
        //   setEacCharge({count: 0, price: 0.00});
        // }
      }
      setFilteredDueMonthly(filteredValue);
    }
  }, [dueMonthlySummary]);

  const displayName = useCallback((deviceRawName, count, byor = false, byod = false, isDevices = false) => {
    if (deviceRawName === "ECONOMIC ADJUSTMENT CHARGE") {
      deviceRawName = eacDisclaimerEnabled && eacDisclaimerEnabled === 'Y'
                      ? `${t("smart-phones.shopping-cart.economicAdjustmentMessage")}**`
                      : t("smart-phones.shopping-cart.economicAdjustmentMessage");
    }
    let deviceName = getDisplayName({ description: deviceRawName || "" });
    if (byor) {
      deviceName = byorDisplayName;
    }
    if(byod && isDevices) {
      deviceName = `Your own device - ${deviceName}`;
    }
    if (Number(count) > 1) {
      deviceName += `(${count})`;
    }
    deviceName = (deviceName.includes("Flexible") && eacDisclaimerEnabled === 'Y') ? deviceName + "***" : deviceName ;
    return deviceName;
  }, []);

  const displayPrice = useCallback((price, byor = false, byod = false, key = "", itemName = "") => {
    if (
      key?.includes("BusinessInternet") &&
      !itemName?.includes("Protection") &&
      !itemName?.includes("Economic") &&
      !byor &&
      !byod &&
      price <= 0
    ) {
      return t("shopping-cart.included");
    }
    return formatPriceString(price) || 0.0;
  }, []);

  const getMonthlyTotal = () => 
    (cartContextData?.dueYearlyTotalLocal > -1) ? Number(cartContextData?.dueYearlyTotalLocal).toFixed(2) :
    cartData?.discAmountPresentInCart && cartData?.displayTotalAmounts?.dueMonthly
      ? Number(cartData?.displayTotalAmounts?.dueMonthly).toFixed(2)
      : Number(cartData?.totalAmounts?.dueMonthly ?? 0).toFixed(2);
    const totalPrice = Number(cartContextData?.dueYearlyTotalLocal).toFixed(2)
  return (
    <Accordion
      surface="light"
      topLine={false}
      bottomLine={true}
      viewport="desktop"
    >
      <AccordionItem opened={isOpened} data-track="Due Yearly">
        <AccordionHeader
          triggerType="icon"
          onClick={() => setOpened(!isOpened)}
        >
          <AccordionTitle>
             Due Yearly: ${totalPrice}
            {globalData?.retrieveLoader ? <SpinnerComponent fullscreen = {false}/>:<></>}
          </AccordionTitle>
          <AccordionSubTitle
            children={
              !isOpened && wirelessFeature ? (
                <><LineItemPriceGrid>
                  <LineItem
                    // maxWidth="299"
                    displayName={displayName(
                      wirelessFeature?.itemName,
                      eacCharge?.count
                    )}
                    price={displayPrice(eacCharge?.price, true)}
                    eacItem={true}
                  />
                </LineItemPriceGrid>
                {eacDisclaimerEnabled && eacDisclaimerEnabled === 'Y' ? 
                <>
                 <MarginSpacerS />
                 <Body size="small">{t("shopping-cart.shoppingcart-data.eacDisclaimer")}</Body></>
                :<></>
               }</>
              ) : (
                <></>
              )
            }
          />
        </AccordionHeader>
        <AccordionDetail>
          <LineItemPriceGrid>
            {cartContextData?.fromBUSS ? 
            <Body size="small" color="#6F7171">Professional Service</Body> : <></>}
            {Object.keys(filteredDueMonthly)?.map((key, index) => (
              <>
                <LineItemPriceGridSection
                  title={
                    (aemCategories[getDeviceCategory(key)] ||
                      getDeviceCategory(key))
                  }
                  key={key}
                  letterSpacing={false}
                >
                  {filteredDueMonthly[key]?.map(
                    ({ itemId, itemName, itemPrice, count, byor, byod, isDevices = false }) => (
                      <LineItem
                        // maxWidth="299"
                        displayName={displayName(itemName, count, byor, byod, isDevices)}
                        price={displayPrice(itemPrice, byor, byod, key, itemName)}
                        key={itemId}
                      />
                    )
                  )}
                </LineItemPriceGridSection>
              </>
            ))}
            {eacDisclaimerEnabled && eacDisclaimerEnabled === 'Y' && wirelessFeature
              ? <EacDescription>
                 <MarginSpacerS />
                 <Body size="small">{t("shopping-cart.shoppingcart-data.eacDisclaimer")}</Body>
                 <MarginSpacerL />
                </EacDescription> 
              : <></>
            }
            <MarginSpacerM />
            <LineItem
              displayName={t("shopping-cart.cart.subtotal")}
              price={`$${getMonthlyTotal()}`}
            />
            <MarginSpacerS />
            <LineItem
              displayName={t("shopping-cart.cart.total")}
              price={`$${getMonthlyTotal()}*`}
              bold
            />
          </LineItemPriceGrid>
        </AccordionDetail>
      </AccordionItem>
    </Accordion>
  );
};

const EacDescription = styled.div``

export default DueYearly;
