import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./WidgetsDecider.css";
import ShoppingCart from "pages/ShoppingCart/ShoppingCart";
import Checkout from "pages/Checkout";
import CheckoutNew from "pages/CheckoutNew/CheckoutNew";
import { MobilityGridwall, MobilityProductDetails } from "pages/Mobility";
import { accessToShowMarketplace } from "pages/BussProductsPage/ducks/BussProductSlice";
import SpinnerComponent from "common/components/Spinner/Spinner";
import Confirmation from "pages/Checkout/Confirmation/Confirmation";
import ManagePage from "pages/Manage/Manage";

export const MarketplaceGridwallWidget = (props) => {
	const dispatch = useDispatch();
	const marketPlace = useSelector(state => state.ProspectWidgets_BussProductsData)
	const [oldCheckout, setCheckoutSwitch] = React.useState(false)

	const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
	const widgetInputParams = globalData?.widgetInputParams;
	useEffect(() => {
		// const payload = {
		// 	"ecpdId": widgetInputParams?.ecpdId?.value ?? "",
		// 	"loginUserId": widgetInputParams?.mbLoginId?.value ?? widgetInputParams?.loginUserId?.value ?? widgetInputParams?.mbaUserId?.value ?? "",
		// 	"rootEcpdId": widgetInputParams?.ecpdId?.value ?? "",
		// 	"channel": widgetInputParams?.clientName?.value ?? ""
		// }
		// dispatch(accessToShowMarketplace(payload))
		if (String(props.flow).toLowerCase() === 'assisted') {
			setCheckoutSwitch(true)
		}
	},[]);
	const allowAccess = window?.mbtGlobal?.softwaresFlowEnabled;
	if(allowAccess){
	if(props.flow === "manage"){
		return(
			<ManagePage/>
		)
	}else{
	return (
		globalData?.loadConfirmationWidget  ? <Confirmation/> :
		globalData?.loadCheckoutWidget && oldCheckout ? <Checkout /> :
		globalData?.loadCheckoutWidget && !oldCheckout ? <CheckoutNew fromBUSS = {true}/> :
		globalData?.loadShoppingCartWidget ? (
			<ShoppingCart fromBUSS = {true}/>)
		: globalData?.loadMobilityProductDetails ? (
			<MobilityProductDetails fromProspect={props?.fromProspect}/>
		) : (
		//<BussProductsAddOnPage />
		<MobilityGridwall fromBUSS = {true}/>
	)
	);
	}
   }else if(!allowAccess){
	return(
		<div style={{width:'100%',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
		<h1>Permission Denied</h1>
		</div>
	)
   } 
   else return <SpinnerComponent/>
};
