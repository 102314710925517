import React, { useState } from "react";
import { Modal, ModalBody } from "@vds3/modals";
import { Micro } from "@vds3/typography";
import styled from "styled-components";
import { stripHTML } from "../../../utils/commonUtils";
import { CloseIcon, InfoCircle } from "../SVGIcons";
import "./PromoBanner.css";

const IconSpan = styled.span`
	margin-left: 0.4375rem;
	position: relative;
	top: 5px;
`;
const ModalWrapper = styled(Modal)`
	overflow-y: auto;
`;
const PromoBanner = ({ promoData, showClose, bgColor, color }) => {
	const [isVisible, setIsVisible] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const textColor = color || "#ffffff";

	if (isVisible && promoData?.heading) {
		return (
			<>
				{/* VBGHSA-14260: in case of gray background, open modal on click of gray banner itself */}
				<div
					className={`promo-banner ${
						bgColor && promoData?.tooltipText ? "cursor-pointer" : ""
					}`}
					onClick={() => !!bgColor && promoData?.tooltipText && setShowModal(true)}
					style={{ backgroundColor: bgColor || "#333333" }}
				>
					<div className="body-text body-text-promo-banner">
						<Micro color={textColor}>
							{stripHTML(promoData?.heading)?.replaceAll("&nbsp;", " ")}
							{promoData?.showTooltip && promoData?.tooltipText && (
								<IconSpan
									className="cursor-pointer"
									style={{ color: color || "#ffffff" }}
									onClick={() => setShowModal(true)}
								>
									<InfoCircle height="16" width="16" />
								</IconSpan>
							)}
						</Micro>
					</div>
					{showClose && <CloseIcon onClickFn={() => setIsVisible(false)} />}
				</div>
				{showModal && (
					<ModalWrapper
						opened={promoData?.tooltipText && showModal}
						onOpenedChange={setShowModal}
					>
						<ModalBody>
							<span dangerouslySetInnerHTML={{ __html: promoData?.tooltipText }} />
						</ModalBody>
					</ModalWrapper>
				)}
			</>
		);
	} else return <></>;
};

export default PromoBanner;
