import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../containers/rootsagas";
import {
	persistStore,
	persistReducer,
	createTransform,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducer from "../reducers";
import { useState } from "react";
const sagaMiddleware = createSagaMiddleware();

const plansTransform = createTransform(
	null,
	(state, key) => {
		const newState = { ...state };
		newState.apiError = false;
		return newState;
	},
	{ whitelist: ["ProspectWidgets_4GPlansData, ProspectWidgets_5GPlansData"] }
);

const globalTransform = createTransform(
	null,
	(state, key) => {
		const newState = { ...state };
		newState.metaInformation = null;
		return newState;
	},
	{ whitelist: ["ProspectWidgets_GlobalData"] }
);

const mobilityTransform = createTransform(
	null,
	(state, key) => {
		const newState = { ...state };
		newState.metaInformation = null;
		return newState;
	},
	{ whitelist: ["ProspectWidgets_MobilityData"] }
);

const WirelessPlansTransform = createTransform(
	null,
	(state, key) => {
		const newState = { ...state };
		newState.metaInformation = null;
		return newState;
	},
	{ whitelist: ["ProspectWidgets_WirelessPlansData"] }
);
const bussProductTransform = createTransform(
	null,
	(state, key) => {
		const newState = { ...state };
		newState.metaInformation = null;
		return newState;
	},
	{ whitelist: ["ProspectWidgets_BussProductsData"] }
);

const checkoutTransform = createTransform(
	null,
	(state, key) => {
		const newState = { ...state };
		newState.metaInformation = null;
		return newState;
	},
	{ whitelist: ["ProspectWidgets_CheckoutData"] }
);

const persistConfig = {
	key: "root",
	version: 1,
	storage,
	blacklist: [],
	transforms: [
		plansTransform,
		globalTransform,
		checkoutTransform,
		mobilityTransform,
		WirelessPlansTransform,
		bussProductTransform,
	],
};
const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: [
		...getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
			thunk: false,
		}),
		sagaMiddleware,
	],
	devTools: process.env.NODE_ENV !== "production",
	enhancers: [],
});
sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

// Get store date outside
let currentState = store.getState();
store.subscribe(() => {
	currentState = store.getState();
});

export const getStoreState = () => currentState;
