import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "@vds3/grids";
import { Title, Micro } from "@vds3/typography";
import { MarginSpacerM, MarginSpacerL } from "common/components/styleTag";

const SEOFooter = ({ data, ...props }) => {
	if (!data) return <></>;
	else
		return (
			<Grid>
				<Row>
					<Col colSizes={{ mobile: 1, tablet: 1, desktop: 3 }} />
					<StyledCol colSizes={{ mobile: 4, tablet: 9, desktop: 9 }}>
						<SEOFooterContainer>
							<Title size="large" primitive="h2" bold>
								{data?.header}
							</Title>
							<MarginSpacerM />
							<ContentGrid>
								{data?.sections?.map((i) => (
									<ContentDiv>
										{!!i?.header && (
											<>
												<Title size="small" primitive="h3">
													{i?.header}
												</Title>
												<MarginSpacerM />
											</>
										)}
										{!!i?.headerTag && (
											<>
												<Title size="small" primitive="h2">
													{i?.headerTag}
												</Title>
												<MarginSpacerM />
											</>
										)}
										{i?.content?.map((j) => (
											<Micro>
												<span
													dangerouslySetInnerHTML={{
														__html: j,
													}}
												/>
											</Micro>
										))}
										{!!i?.footnotes?.length && (
											<>
												<MarginSpacerL />
												{i?.footnotes?.map((j) => (
													<Micro>
														<span
															dangerouslySetInnerHTML={{
																__html: j,
															}}
														/>
													</Micro>
												))}
											</>
										)}
									</ContentDiv>
								))}
							</ContentGrid>
						</SEOFooterContainer>
					</StyledCol>
				</Row>
			</Grid>
		);
};

const StyledCol = styled(Col)`
	padding-right: 0;
`;
const SEOFooterContainer = styled.div`
	padding-left: 1rem;
	margin-top: 56px;
	margin-bottom: 40px;
	div:not(:first-of-type) {
		margin-top: 1.5rem;
	}
	@media (max-width: 545px) {
		margin-top: 47px;
		padding-left: 0;
		div:not(:first-of-type) {
			margin-top: 2rem;
		}
	}
`;
const ContentGrid = styled.div`
	// display: grid;
	// gap: 1.5rem;
	columns: 2;
	@media (max-width: 545px) {
		columns: 1;
	}
`;
const ContentDiv = styled.div`
	max-width: 27.375rem;
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid;
	a {
		color: black;
	}
	p:not(:last-of-type) {
		margin-bottom: 1rem;
	}
	sup {
		font-size: 0.6rem;
		vertical-align: super;
		line-height: 0;
	}
`;

export default SEOFooter;
