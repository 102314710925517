// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-overlay-body .description {
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}

.progress-overlay-body .footer {
  padding-bottom: 1.5rem;
}

.progress-overlay-body .footer {
  padding-bottom: 1.5rem;
}

.progress-overlay-body .progressBarWrapper {
  position: relative;
  height: 10px;
}
.progress-overlay-body .progressBarWrapper .progress {
  width: 0;
  position: absolute;
  z-index: 1;
  height: 6px;
  background-color: #d52b1e;
  transition: 0.5s all;
}
.progress-overlay-body .progressBarWrapper .unprogress {
  width: 100%;
  position: absolute;
  z-index: 0;
  height: 6px;
  background-color: #d6d6d6;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Checkout/ProgressOverlay/ProgressOverlay.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,QAAQ;EACR,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,yBAAyB;EACzB,oBAAoB;AACtB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,yBAAyB;AAC3B","sourcesContent":[".progress-overlay-body .description {\n  padding-top: 1rem;\n  padding-bottom: 1.5rem;\n}\n\n.progress-overlay-body .footer {\n  padding-bottom: 1.5rem;\n}\n\n.progress-overlay-body .footer {\n  padding-bottom: 1.5rem;\n}\n\n.progress-overlay-body .progressBarWrapper {\n  position: relative;\n  height: 10px;\n}\n.progress-overlay-body .progressBarWrapper .progress {\n  width: 0;\n  position: absolute;\n  z-index: 1;\n  height: 6px;\n  background-color: #d52b1e;\n  transition: 0.5s all;\n}\n.progress-overlay-body .progressBarWrapper .unprogress {\n  width: 100%;\n  position: absolute;\n  z-index: 0;\n  height: 6px;\n  background-color: #d6d6d6;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
