import styled from "styled-components";
import { Accordion, AccordionDetail, AccordionHeader, AccordionItem } from "@vds3/accordions";
import { Line } from "@vds3/lines";
// import { TileContainer } from "@vds/tiles";

export const SeparatorLine = styled(Line)`
  margin-top: 32px;
  margin-bottom: 42px;
`;

export const CustomAccordion = styled(Accordion)`
  width: 50% !important;
`;

export const CustomAccordionItem = styled(AccordionItem)`
  padding-bottom: 0;
`;

export const CustomAccordionHeader = styled(AccordionHeader)`
  margin: 1rem;
`;

export const AccordionWrapper = styled.div`
@media only screen and (min-width: 786px){div {
    overflow: unset ;
  }
}
  [data-tooltip] > span{
    overflow-y: auto !important;
  ::-webkit-scrollbar {
      width: 0px;
      
  }
  ${({disabledAccordion}) => (
    disabledAccordion && `button.accordionButton {
      pointer-events: none !important;
      padding-right:0px;
    }`
  )}
`;
export const CustomTileContainer=styled.div`
 background-color:#f6f6f6;
 padding: 16px;

`;

export const AccordionTitleFlex = styled.div`
  ${({isMobile}) => isMobile && `
    display: flex;
    flex-direction: column;
    min-width: 20rem;
    @media only screen and (max-width: 300px){
      min-width: 17rem;
    }
  `}
  ${({isfromAuth}) => isfromAuth && `min-width: 25rem;`}
`;
export const EquipmentAccordionContainer = styled.div`
  [class^=StyledAccordionButton-VDS]{
    padding-right: 10rem !important;
  }
`;

export const ShowDetailsStyle = styled.div`
    box-sizing: border-box;
    background-color: #f6f6f6;
    min-height: 2rem;
    display:flex;
    align-items:center;
    justify-content: flex-end;
    padding-right: 12px;
}
`;