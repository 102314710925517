
/* API CLIENT CONFIG */
export const API_MAX_RETRIES = process.env.PROSPECT_WIDGETS_APP_API_MAX_RETRIES;
export const API_RETRY_DELAY = process.env.PROSPECT_WIDGETS_APP_API_RETRY_DELAY;
export const API_TIMEOUT = process.env.PROSPECT_WIDGETS_APP_API_TIMEOUT;
export const CLIENT_TIMEOUT = process.env.PROSPECT_WIDGETS_APP_CLIENT_TIMEOUT;

/* GENERIC ERROR MESSAGE */
export const ERROR_MESSAGE = {
    GENERIC_MESSAGE: 'Something went wrong. Please refresh the page.', 
    TECHNICAL_ISSUE: 'Something went wrong. please try again. If issue persists, please contact the customer support.'
};
 

export const STATE_SELECT = [
    {
        abbreviation: 'AL',
        stateName: 'Alabama'
    },
    {
        abbreviation: 'AK',
        stateName: 'Alaska'
    },
    {
        abbreviation: 'AS',
        stateName: 'American Samoa'
    },
    {
        abbreviation: 'AZ',
        stateName: 'Arizona'
    },
    {
        abbreviation: 'AR',
        stateName: 'Arkansas'
    },
    {
        abbreviation: 'CA',
        stateName: 'California'
    },
    {
        abbreviation: 'CO',
        stateName: 'Colorado'
    },
    {
        abbreviation: 'CT',
        stateName: 'Connecticut'
    },
    {
        abbreviation: 'DE',
        stateName: 'Delaware'
    },
    {
        abbreviation: 'DC',
        stateName: 'District of Columbia'
    },
    {
        abbreviation: 'FM',
        stateName: 'Federated States Of Micronesia'
    },
    {
        abbreviation: 'FL',
        stateName: 'Florida'
    },
    {
        abbreviation: 'GA',
        stateName: 'Georgia'
    },
    {
        abbreviation: 'GU',
        stateName: 'Guam'
    },
    {
        abbreviation: 'HI',
        stateName: 'Hawaii'
    },
    {
        abbreviation: 'ID',
        stateName: 'Idaho'
    },
    {
        abbreviation: 'IL',
        stateName: 'Illinois'
    },
    {
        abbreviation: 'IN',
        stateName: 'Indiana'
    },
    {
        abbreviation: 'IA',
        stateName: 'Iowa'
    },
    {
        abbreviation: 'KS',
        stateName: 'Kansas'
    },
    {
        abbreviation: 'KY',
        stateName: 'Kentucky'
    },
    {
        abbreviation: 'LA',
        stateName: 'Louisiana'
    },
    {
        abbreviation: 'ME',
        stateName: 'Maine'
    },
    {
        abbreviation: 'MH',
        stateName: 'Marshall Islands'
    },
    {
        abbreviation: 'MD',
        stateName: 'Maryland'
    },
    {
        abbreviation: 'MA',
        stateName: 'Massachusetts'
    },
    {
        abbreviation: 'MI',
        stateName: 'Michigan'
    },
    {
        abbreviation: 'MN',
        stateName: 'Minnesota'
    },
    {
        abbreviation: 'MS',
        stateName: 'Mississippi'
    },
    {
        abbreviation: 'MO',
        stateName: 'Missouri'
    },
    {
        abbreviation: 'MT',
        stateName: 'Montana'
    },
    {
        abbreviation: 'NE',
        stateName: 'Nebraska'
    },
    {
        abbreviation: 'NV',
        stateName: 'Nevada'
    },
    {
        abbreviation: 'NH',
        stateName: 'New Hampshire'
    },
    {
        abbreviation: 'NJ',
        stateName: 'New Jersey'
    },
    {
        abbreviation: 'NM',
        stateName: 'New Mexico'
    },
    {
        abbreviation: 'NY',
        stateName: 'New York'
    },
    {
        abbreviation: 'NC',
        stateName: 'North Carolina'
    },
    {
        abbreviation: 'ND',
        stateName: 'North Dakota'
    },
    {
        abbreviation: 'MP',
        stateName: 'Northern Mariana Islands'
    },
    {
        abbreviation: 'OH',
        stateName: 'Ohio'
    },
    {
        abbreviation: 'OK',
        stateName: 'Oklahoma'
    },
    {
        abbreviation: 'OR',
        stateName: 'Oregon'
    },
    {
        abbreviation: 'PW',
        stateName: 'Palau'
    },
    {
        abbreviation: 'PA',
        stateName: 'Pennsylvania'
    },
    {
        abbreviation: 'PR',
        stateName: 'Puerto Rico'
    },
    {
        abbreviation: 'RI',
        stateName: 'Rhode Island'
    },
    {
        abbreviation: 'SC',
        stateName: 'South Carolina'
    },
    {
        abbreviation: 'SD',
        stateName: 'South Dakota'
    },
    {
        abbreviation: 'TN',
        stateName: 'Tennessee'
    },
    {
        abbreviation: 'TX',
        stateName: 'Texas'
    },
    {
        abbreviation: 'UT',
        stateName: 'Utah'
    },
    {
        abbreviation: 'VT',
        stateName: 'Vermont'
    },
    {
        abbreviation: 'VI',
        stateName: 'Virgin Islands'
    },
    {
        abbreviation: 'VA',
        stateName: 'Virginia'
    },
    {
        abbreviation: 'WA',
        stateName: 'Washington'
    },
    {
        abbreviation: 'WV',
        stateName: 'West Virginia'
    },
    {
        abbreviation: 'WI',
        stateName: 'Wisconsin'
    },
    {
        abbreviation: 'WY',
        stateName: 'Wyoming'
    },
];

export const DEVICETYPES = {
    SMARTPHONE : "SMARTPHONE", // available in: wireless, onetalk
    DESKPHONE : "DESKPHONE", // available in: wireless, onetalk
    TABLET : "TABLET", // available in: wireless
    BASICPHONE : "BASICPHONE", // available in: wireless
    SMARTWATCH : "SMARTWATCH",


    MOBILEAPPCLIENT : "MOBILEAPPCLIENT", // available in: onetalk
    ADDON : "ADDON", // available in: onetalk
    LINE : "LINE", // available in: onetalk. used as dummy

    FOURGRECEIVER : "FOURGRECEIVER", // available in: 4g
    FIVEGRECEIVER : "FIVEGRECEIVER", // available in: 5g

    FOURGROUTER : "FOURGROUTER", // available in: 4g
    PACKAGE_INCOMPATIBLE : 'PACKAGE_INCOMPATIBLE' // for mixed cart situation
};
