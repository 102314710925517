import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import styled from "styled-components";
import { Modal, ModalTitle, ModalBody } from "@vds3/modals";
import { ModalFooter } from "@vds3/modals";
import { Body } from "@vds3/typography";
import { MarginSpacerL, MarginSpacerM,MarginSpacerS } from "common/components/styleTag";
//import { useNavigate} from "react-router-dom";
import { useDispatch } from "react-redux";
import {
setBBALQResp,
} from "pages/Qualification/ducks/QualificationSlice";
import { Button } from "@vds3/buttons";
import { setLoadFWAQualifiedPlans, setLoadQualficationSelection } from "app/ducks/GlobalSlice";

const FiveGCbandModal = ({
  opened,
  onOpenedChange,
  isCband,
  isMMW,
  isFourG,
  pageData,
  LQData,
  BBALQResp,
 sessionCartData,
 shoppingCartRedirect=false
}) => {

  const { t } = useTranslation(["4G-5G"]);
 // const navigate = useNavigate();
  const aemKey = (!!isFourG)?"fourG":(!!isCband)?"cband":"mmw";
  //const goBack = () => navigate(-2);
  const aemContent = t("4G-5G", { ns: "4G-5G", returnObjects: true });
  const dispatch = useDispatch();
   const aem = t(`4G-5G.ModalPopupContent.${aemKey}`, {
    returnObjects: true});
    const [landLen,setLandLen]= useState(window?.history?.length);
   const getUnorderListTop = ()=>{
    return(
      <>
         <ul>
          {aem[0]?.bulletPointsContent1?.map((item)=>{
            return <li size="bodyLarge" >
            <Body size="large">{item?.bulletDesc}</Body>
          </li>
          })}
        </ul>
      </>
    )
  }

//   const goBack1 = () =>{
//   if(LQData?.flow==="4g" || LQData?.flow==="5g"){
//  if (LQData?.BBALQResp?.redirectedFrom === "SMBPortal") {
//   dispatch(setBBALQResp({}));
//                       if (window.mbtGlobal.reactUibasePath === "/mbt/prospect/") {
//                         window.location.href = aemContent?.common?.plansSMB?.nonProd;
//                       } else {
//                         window.location.href = aemContent?.common?.plansSMB?.prod;
//                       }
//                     } else if (BBALQResp?.referralUrl) {
//                       dispatch(setBBALQResp({}));
//                       window.location.href = BBALQResp?.referralUrl;
//                     }
//                      else if (pageData?.isFrom4GLearn) {
//                       window.location.href = aemContent?.fourG["learn-page"]?.url;
//                     } else if(shoppingCartRedirect || 
//                       (sessionCartData?.selectFwaBundletabForJO && (pageData?.showInternetMobilePlanFiveG || pageData?.showInternetMobilePlan ||sessionCartData?.prospect5gLoopQualAddress?.cbandCart)))
//                     {
//                       navigate("/shop/shopping-cart");
//                     }
          
                    
//                    else {
//                     dispatch(setBBALQResp({}));
//                       window.history.go(-landLen+1);
//                     }


//   }
//       else{
//      if (LQData?.BBALQResp?.redirectedFrom === "SMBPortal") {
//                       if (window.mbtGlobal.reactUibasePath === "/mbt/prospect/") {
//                         window.location.href = aemContent?.common?.plansSMB?.nonProd;
//                       } else {
//                         window.location.href = aemContent?.common?.plansSMB?.prod;
//                       }
//                     } else if (LQData?.BBALQResp?.referralUrl && !sessionCartData?.cart?.packages) {
//                       dispatch(setBBALQResp({}));
//                       window.location.href = LQData?.BBALQResp?.referralUrl;
//                     } 
//                    else {
//                       dispatch(setBBALQResp({}));
//                         window.history.go(-landLen+1)
//                     }
// }
// }

 return (
    <>
    <Modal
          surface="light"
          hideCloseButton={false}
          opened={opened}
          onOpenedChange={onOpenedChange}
          disableFocusLock= {true}
        >
  <ModalTitle> {aem[0]?.modalHeading}</ModalTitle>
          <MarginSpacerS />
          <Body size= "large">{aem[0]?.modalSubHeading}</Body>
       <ModalBodyContainer>
      <Body size="large">
      <div>
         <div style={{ paddingTop: "10px" }}>{aem[0]?.content1}</div>
        <ListItemsWrapper>{getUnorderListTop()}</ListItemsWrapper>
 <Body size= "large">{aem[0]?.content2}</Body>
 <Body size= "large">{aem[0]?.content3}</Body>
</div>
</Body> 
</ModalBodyContainer>
  <ModalFooter
    buttonData={{
      primary: {
        children: 'Keep shopping',
        onClick: () => onOpenedChange(false)
      },
      close: {
        children: 'Go back',
        onClick:(e) => {
          dispatch(setLoadFWAQualifiedPlans(false))
          dispatch(setLoadQualficationSelection(true))
          onOpenedChange(false)
        }
      },
    }}
  />
  </Modal>

    </>
  );
};


const ModalBodyContainer = styled.div`
  li:not(:last-of-type) {
    margin-bottom: 1rem;
    padding:0px;
  }
`;

const ListItemsWrapper = styled.div`
  ul {padding-left:20px;
    li {margin-top:1rem;    
      & > p {padding-left:8px;}
    }
  }`;
  

export default FiveGCbandModal;
