import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody } from "@vds3/modals";
import { Body } from "@vds3/typography";
import { MarginSpacerS, MarginSpacerL } from "common/components/styleTag";
import styled from "styled-components";
import { Icon } from "@vds3/icons";

const SaveCartConfirmationModal = ({ opened, onOpenedChange, ...props }) => {
	const { t } = useTranslation(["shoppingCart"]);

	return (
		<>
			{opened && (
				<Modal
					hideCloseButton={false}
					disableOutsideClick={true}
					onOpenedChange={onOpenedChange}
					opened={opened}
				>
					<MarginSpacerL />
					<ModalBody>
						<div style={{ textAlign: "center" }}>
							<Body size="large" bold>
								{" "}
								<Icon name="checkmark-alt" color={"rgb(0, 184, 69)"} size="medium" />
								CartID saved successfully
							</Body>
						</div>
						<MarginSpacerS />
					</ModalBody>
				</Modal>
			)}
		</>
	);
};

export default SaveCartConfirmationModal;
