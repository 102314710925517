import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "@vds3/buttons";
import { Input } from "@vds3/inputs";
import { Body, Micro } from "@vds3/typography";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
	MarginSpacerM,
	MarginSpacerXL,
	MarginSpacerXS,
	MarginSpacerRightM,
	MarginSpacerS,
} from "common/components/styleTag";
import DueToday from "./DueToday";
import DueMonthly from "./DueMonthly";
import PotentialSavings from "./PotentialSavings";
import { TextLink } from "@vds3/buttons";
import CartContext from "pages/ShoppingCart/cartContext";
import {
	postApplyPromoCode,
	setRemoveStatus,
} from "pages/ShoppingCart/ducks/ShoppingCartSlice";
import { Title } from "@vds3/typography";
import DueYearly from "./DueYearly";
// import { ChatBubble } from "common/components/SVGIcons";
// import { ChatLink } from "common/components";

const TitleContainer = styled.div`
@media screen and (min-width:768px) {
    top: 64px;
    padding-top: 10px;
    padding-bottom: 10px;
    width:100%;
    z-index: 6;
    background-color: white;
}`
const OrderSummary = ({
	onContinueClick,
	disableContinueButton,
	defaultOpenAccordions,
	isShoppingCart,
	excludeFooterContent = false,
	...props
}) => {
	const { t } = useTranslation(["shoppingCart"]);

	const shoppingCartPageData = useSelector((state) => state.ShoppingCartData);
	const isMobile = useMediaQuery({
		query: "(max-width: 545px)",
	});

	const cartContext = React.useContext(CartContext);
	const pageData = shoppingCartPageData ?? cartContext.pageData;

	const dispatch = useDispatch();

	const defaultPromoCode = pageData?.unifiedCartParams?.promoCode;
	const promoCodeDetails = pageData?.promoCodeDetails;
	const removeStatus = pageData?.removeStatus;

	const [showPromoCode, setShowPromoCode] = useState(false);
	const [promoCode, setPromoCode] = useState(defaultPromoCode || "");
	const [promoCodeDataDetails, setPromoCodeDataDetails] = useState({});
	const eacDisclaimerEnabled = t("shopping-cart.cart.eacDisclaimerEnabled", {
		ns: "shoppingCart",
	});

	// const errorText =
	//   promoCode?.length > 1 && !promoCodeDataDetails?.status?.success
	//     ? "Promo code expired"
	//     : "Please enter promo code";

	const onApplyPromoCode = (type = "apply") => {
		if (promoCode.length > 1 && type === "apply") {
			setPromoCodeDataDetails({});
			dispatch(postApplyPromoCode(promoCode));
			dispatch(setRemoveStatus(true));
		} else if (type === "remove") {
			setPromoCodeDataDetails({});
			dispatch(postApplyPromoCode(""));
			dispatch(setRemoveStatus(false));
		}
	};

	useEffect(() => {
		if (promoCodeDetails) {
			setPromoCodeDataDetails(promoCodeDetails);
			if (promoCodeDetails?.status?.success) {
				setShowPromoCode(false);
			}
		} else {
			setPromoCodeDataDetails({});
		}
	}, [promoCodeDetails]);

	useEffect(() => {
		try {
			if (typeof window["lpTag"] != "undefined" && lpTag?.section) {
				lpTag.section = manipulateLpTagSections();
				setTimeout(() => {
					window?.lpTag?.newPage(window?.location?.href, {
						section: lpTag.section,
					});
				}, 8000);
			}
		} catch (e) {
			console.error(e);
		}
	}, []);

	const onSetPromoCode = (e) => {
		setPromoCode(e.target.value);
		setPromoCodeDataDetails({});
	};

	const isPromoApplied =
		removeStatus && promoCodeDataDetails?.status?.success && promoCode;
	return (
		<>
			{isMobile ? (
				false
			) : (
				<ChatSpacer isShoppingCart={isShoppingCart}>
					<ChatContainer isShoppingCart={isShoppingCart}>
						{/* <ChatBubble />
            <TextLink
              type="standAlone"
              id="eCom5GRouterInstallationAuth"
              inverted={false}
              disabled={false}
            >
              Chat
            </TextLink> */}
						{/* <ChatLink /> */}
					</ChatContainer>
				</ChatSpacer>
			)}
			<>
			{isShoppingCart && <TitleContainer><Title size="small" bold={true}>{t("app.common.orderSummary.orderSummaryText", { ns: "app" })}</Title></TitleContainer>}

				{isShoppingCart ? (
					<>
						<DueToday isOpenByDefault={defaultOpenAccordions} />
						<MarginSpacerXS />
						<DueMonthly
							isOpenByDefault={defaultOpenAccordions}
							eacDisclaimerEnabled={eacDisclaimerEnabled}
							fromBUSS = {props?.fromBUSS}
						/>
						<MarginSpacerXS />
						{props?.fromBUSS ? 
						<DueYearly isOpenByDefault={defaultOpenAccordions}
							eacDisclaimerEnabled={eacDisclaimerEnabled}
						/>:<></>}
						<MarginSpacerXL />
					</>
				) : (
					<>
						<DueMonthly
							isOpenByDefault={defaultOpenAccordions}
							eacDisclaimerEnabled={eacDisclaimerEnabled}
						/>
						<MarginSpacerXS />
						<DueToday isOpenByDefault={defaultOpenAccordions} topLine={false} />
						<MarginSpacerXS />
					</>
				)}
			</>
			{/* <PotentialSavings /> */}
			{/* <div>
        {false ? (
          <TextLink
            type="standAlone"
            size="large"
            onClick={setShowPromoCode.bind(this, true)}
          >
            {t("shopping-cart.promo-code.promoPrompt")}
          </TextLink>
        ): (isPromoApplied ? (
            <>
              <Body size="large">
                The Promo Code has been applied.
              </Body>
              <MarginSpacerS />
              <PromoFlexContainer>
                <MarginSpacerRightM>
                  <Body size="large">{promoCode}</Body>
                </MarginSpacerRightM>
                <TextLink
                  type="standAlone"
                  size="small"
                  onClick={() => {
                    setPromoCode("");
                    onApplyPromoCode("remove");
                  }}
                >
                  Remove
                </TextLink>
              </PromoFlexContainer>
              <MarginSpacerS />
              <Body size="large" color="#0088ce">
                You will be receiving promo credit in 2-3 business
                cycles.
              </Body>
              <MarginSpacerS />
            </>
          ):(
            <Input
              type="inlineAction"
              name="promoCode"
              value={promoCode}
              label={t("shopping-cart.promo-code.enterCode")}
              onChange={onSetPromoCode}
              maxLength={20}
              onClickInlineAction={onApplyPromoCode.bind(this, "apply")}
              error={
                promoCode?.length > 1 &&
                promoCodeDataDetails?.status &&
                !promoCodeDataDetails?.status?.success
              }
              errorText={promoCode?.length > 1 && !promoCodeDataDetails?.status?.success
                ? "Promo code expired"
                : "Please enter promo code"}
            />
          ))}
      </div> */}
			<MarginSpacerXL />
			{/* <Button
        size="large"
        use="primary"
        width="100%"
        onClick={onContinueClick}
        disabled={disableContinueButton}
      >
        {t("shopping-cart.promo-code.continue")}
      </Button> */}
	  {excludeFooterContent ? <></>:<>
			<MarginSpacerXL />
			<Micro color="#6F7171">
				<Trans ns="shoppingCart" i18nKey="shopping-cart.cart.dppChangeDisclaimer">
					<Micro color="#6F7171" bold primitive="span" />
					<TextLink
						type="inline"
						size="small"
						target="_blank"
						href={t("shopping-cart.cart.dppChangeDisclaimerURL")}
					/>
				</Trans>
			</Micro>
			<MarginSpacerM />
			{eacDisclaimerEnabled && eacDisclaimerEnabled === "Y" ? (
				<>
					<Micro color="#6F7171">
						{t("shopping-cart.cart.eacPriceChangeDisclaimers")}
					</Micro>
					<MarginSpacerS />
				</>
			) : (
				<></>
			)}
			<Micro color="#6F7171">{t("shopping-cart.cart.taxAndFeeDisclaimers")}</Micro>
			</>}
		</>
	);
};

const ChatContainer = styled.div`
	display: ${({ isShoppingCart }) => (!isShoppingCart ? "none" : "flex")};
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	align-self: flex-end;
	margin-top: 2.5rem;
	svg {
		width: 1rem;
		height: 1rem;
		margin-right: 5px;
	}
`;

const ChatSpacer = styled.div`
	height: ${({ isShoppingCart }) => (!isShoppingCart ? "5.25rem" : "4rem")};
`;

const PromoFlexContainer = styled.div`
	display: flex;
	align-items: center;
`;

export default OrderSummary;
