import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
import { TextLink } from "@vds3/buttons";
import { Modal, ModalBody, ModalTitle } from "@vds3/modals";
import { TileContainer } from "@vds3/tiles";
import { Body, Micro } from "@vds3/typography";
import { MarginSpacerS, MarginSpacerM } from "common/components/styleTag";
import CartPromoBanner from "../Promos/CartPromoBanner";
import { RemoveTradeInModal } from "../Modals/RemoveTradeInModal";
import {
  LeftTradeInSection,
  RightTradeInSection,
  TileDiv,
  TradeInDetailContainer,
} from "./styles";
import CartPkgContext from "./cartPkgContext";

const TradeInTile = ({ tradeInOffer, ...props }) => {
  const { pack } = React.useContext(CartPkgContext);
  const { t } = useTranslation(["app", "shoppingCart"]);
  // const navigate = useNavigate();
  const tradeInInfo = pack?.tradInInfo;

  const [showHowToModal, setHowToModal] = useState(false);
  const [removeTradeInModal, setRemoveTradeInModal] = useState(false);

  const goToTradeIn = () => {
    
  };

  if (tradeInInfo) {
    return (
      <div>
        <TileContainer
          padding="1.5rem"
          aspectRatio="none"
          backgroundColor="gray"
        >
          <TileDiv>
            <LeftTradeInSection>
              <TradeInDetailContainer>
                <Body size="small" color="#6F7171">
                  {t("shopping-cart.trade-in-entry.title", {
                    ns: "shoppingCart",
                  })}
                </Body>
                <Body size="large">{tradeInInfo?.equipModel}</Body>
                {tradeInInfo?.deviceId ? (
                  <div>
                    <MarginSpacerS />
                    <Body size="small" color="#6F7171">
                      {t("app.common.trade-in-appraised.imei-label", {
                        ns: "app",
                      })}
                    </Body>
                    <Body size="large">{tradeInInfo?.deviceId}</Body>
                    <MarginSpacerM />
                    <TextLink onClick={() => setRemoveTradeInModal(true)}>
                      {t("app.common.trade-in-appraised.remove-btn", {
                        ns: "app",
                      })}
                    </TextLink>
                    <MarginSpacerS />
                  </div>
                ) : (
                  // <Body size="small">{`${t(
                  //   "app.common.trade-in-appraised.imei-label",
                  //   {
                  //     ns: "app",
                  //   }
                  // )}${tradeInInfo?.deviceId}`}</Body>
                  <div>
                    <Body>{`Please provide the IMEI for the trade-in device in order to continue: `}</Body>
                    <MarginSpacerS />
                    <TextLink onClick={goToTradeIn}>
                      {t("app.common.trade-in-appraised.update-btn", {
                        ns: "app",
                      })}
                    </TextLink>
                  </div>
                )}
              </TradeInDetailContainer>
            </LeftTradeInSection>
            <RightTradeInSection>
              {!!tradeInInfo?.optionalRecycleDeviceOffer?.present && tradeInInfo?.recycleDeviceOffer?.offerDesc ? (
                <TradeInDetailContainer maxWidth="350px">
                  {/* <Body size="large">{`Saving $${tradeInInfo?.recycleDeviceOffer?.discAmt} on the ${tradeInInfo?.equipModel}`}</Body> */}
                  <Body size="large">{tradeInInfo?.recycleDeviceOffer?.offerDesc}</Body>
                </TradeInDetailContainer>
              ) : (
                <TradeInDetailContainer maxWidth="211px">
                  <Body size="large">
                    {t("app.common.trade-in-appraised.organicValueMessage", {
                      ns: "app",
                      organicPrice: tradeInInfo?.organicPrice,
                    })}
                  </Body>
                </TradeInDetailContainer>
              )}
              <div style={{ marginTop: "1.5rem" }}>
                <TextLink
                  type="standAlone"
                  size="large"
                  onClick={() => setHowToModal(true)}
                >
                  {t("app.common.send-tradein-modal.link-text", {
                    ns: "app",
                  })}
                </TextLink>
              </div>
            </RightTradeInSection>
          </TileDiv>
        </TileContainer>
        <Modal opened={showHowToModal} onOpenedChange={setHowToModal}>
          <ModalTitle>
            {t("app.common.send-tradein-modal.heading", {
              ns: "app",
            })}
          </ModalTitle>
          <ModalBody style={{ padding: 0 }}>
            <span
              dangerouslySetInnerHTML={{
                __html: t("app.common.send-tradein-modal.bodyHtml", {
                  ns: "app",
                }),
              }}
            />
          </ModalBody>
        </Modal>
        {removeTradeInModal && (
          <RemoveTradeInModal
            opened={removeTradeInModal}
            setOpened={setRemoveTradeInModal}
            lineItemId={pack?.packageId}
            deviceId={tradeInInfo?.deviceId}
          />
        )}
      </div>
    );
  } else {
    return (
      <CartPromoBanner
        icon={tradeInOffer ? "tag" : ""}
        children={
          tradeInOffer ? (
            <Body>
              <Body bold primitive="span">
                {"Get Up to $800 off select devices with High Trade-In"}
              </Body>
              {
                " - Premium Unlimited Plans - Business Only - Activations Only - Good Condition Not Required"
              }
            </Body>
          ) : (
            <>
              <Body size="small" color="#6F7171">
                {t("shopping-cart.trade-in-entry.title", { ns: "shoppingCart" })}
              </Body>
              <Body size="large">{t("shopping-cart.trade-in-entry.available", { ns: "shoppingCart" })}</Body>
            </>
          )
        }
        ctaTextChildren={t("shopping-cart.trade-in-entry.add", { ns: "shoppingCart" })}
        // onCTAClick={() =>
        //   navigate("/shop/trade-in-new", {
        //     state: { isFromCart: true, pack },
        //   })
        // }
      />
    );
  }
};

export default TradeInTile;
