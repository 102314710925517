import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalTitle, ModalFooter } from "@vds3/modals";
import { Body,  } from "@vds3/typography";
import {  setpackageCompatible } from "pages/Landing4G-V2/ducks/4GSlice";
import { MarginSpacerM } from "common/components/styleTag";
const PackageCompatibleModal = ({
  opened,sessionCartData,addToCartPayload,onContinue,
  ...props
}) => {
  const { t } = useTranslation(["app"]);
  const dispatch = useDispatch();
  const vbcInCart = sessionCartData?.cart?.packages !== null && sessionCartData?.cart?.packages !== undefined && Object.values(sessionCartData?.cart?.packages).filter( packageData => packageData?.packageType == "RENTAL_REGULAR").length > 0;
  const vbcToCart = addToCartPayload !== "" && addToCartPayload?.lines[0]?.packageType == "RENTAL_REGULAR";
  const clearCartResp = useSelector(state => state?.ProspectWidgets_CheckoutData?.cleartCartDetails);
  let payload={};
  const vbcclearCart = payload={"vbcclearCart":true};
  // const onClickContinue = () => {
  //   if(vbcInCart || vbcToCart){
  //    if(addToCartPayload.lines[0].packageType == "FOURG"){
  //      // dispatch(addOrUpdate4GPage(addToCartPayload));
  //      dispatch(clearCartAndAdd4G())
  //      }
  //   }else{
  //   dispatch(reset4GState());
  //   dispatch(reset5GState());
  //   dispatch(postClearCart());
  //   }
  //   if(addToCartPayload.lines[0].packageType == "RENTAL_REGULAR"){
  //    dispatch(addToCartRental(addToCartPayload));
  //   }
   
  //   else if(addToCartPayload.lines[0].packageType == "FIVEG"){
  //     dispatch(update5GADDORUpdate(addToCartPayload));
  //     }
  // };
  const onOpenedChange = (modalState) => {
    if(!modalState) { 
      dispatch(setpackageCompatible(false)); 
    }
  }
  return (
    <>
        <Modal
          hideCloseButton={true}
          disableOutsideClick={true}
          onOpenedChange={onOpenedChange}
          opened={opened}
          disableFocusLock= {true}
        >
          {vbcInCart ? <ModalTitle> {t("app.common.cartIncompatibleModalDAAS.heading")}</ModalTitle> : 
          <ModalTitle>{t("app.common.cartIncompatibleModal.heading")}</ModalTitle>}
      
          <ModalBody>
           {!vbcInCart && <Body>
            Currently, our shopping cart cannot support this combination of items. To combine them in a single order, please chat or call one of our small business expert at <span style={{fontWeight: 'bold'}}>866.822.9565</span>
            </Body>}
            <MarginSpacerM />
            {vbcInCart ? <Body size="large">{t("app.common.cartIncompatibleModalDAAS.description2")}</Body> :
            <Body size="large">{t("app.common.cartIncompatibleModal.description2")}</Body>}
          </ModalBody>
          {vbcInCart ? 
          <ModalFooter
            buttonData={{
              primary: {
                children: t("app.common.cartIncompatibleModalDAAS.continue"),
                onClick: () => {
                  onContinue();
                },
              },
              close: {
                children: t("app.common.cartIncompatibleModalDAAS.cancel"),
                onClick: () => {
                  onOpenedChange(false);
                },
              },
            }}
          /> :
           <ModalFooter
            buttonData={{
              primary: {
                children: "Continue",
                onClick: () => {
                  onContinue();
                },
              },
              close: {
                children: "Cancel",
                onClick: () => {
                  onOpenedChange(false);
                },
              },
            }}
          />}
        </Modal>
    </>
  );
};

export default PackageCompatibleModal;
