import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Button } from "@vds3/buttons";
import { Input } from "@vds3/inputs";
import { Modal, ModalTitle, ModalBody, ModalFooter } from "@vds3/modals";
import { RadioBox } from "@vds3/radio-boxes";
import { Body } from "@vds3/typography";
import { validateDeviceId, setValidateDeviceIdResp, setImeiCheckAttempt, validateSimId, setValidateSimIdResp } from "../../ducks/4GSlice";
import { isValidIMEIFormat, isValidICCIDFormat } from "utils/byodUtils";
import { useDebounce } from "hooks";

const IMEIModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  letter-spacing: 0;
`;

const SIMModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  button {
    width: 95%;
    margin-right: 0.5rem;
  }
  @media only screen and (max-width: 546px) {
    position: absolute;
    bottom: 16px;
    width: 95%;
    flex-direction: column;
    justify-content: center;
    button {
      width: 100%;
      margin-bottom: 0.75rem;
    }
  }
`;

const SIMRadioBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.75rem;
`;

const SimModalTitle = styled.div`
  @media only screen and (min-width: 700px) {
    > div {
      padding-right: 0 !important;
    }
  }
`;

const numericRegex = new RegExp(/[^\d]/, "ig");

const IMEICheckModal = ({
  isMobile,
  opened,
  onOpenedChange,
  setIsValidIMEI,
  isfromAuth = false,
  sessionCartData,
  ...props
}) => {
  const { t } = useTranslation(["app", "4G-5G"]);
  const dispatch = useDispatch();
  const [imei, setImei] = useState("");
  const [showSimId, setShowSimId] = useState(false);
  const [iccid, setIccid] = useState("");
  const [simSelection, setSimSelection] = useState("");
  const [continueClicked, setContinueClicked] = useState(false);
  const aemContent = t("4G-5G", { ns: "4G-5G", returnObjects: true });
  const IMEI_MAX_LENGTH = 18;
  const SIM_MAX_LENGTH = 20;

  const validateDeviceIdResp = useSelector(
    (state) => state?.ProspectWidgets_4GPlansData?.validateDeviceIdResp
  );
  const isImeiCompatible = validateDeviceIdResp?.serviceStatus?.success;
  const validateSimIdResp = useSelector(
    (state) => state?.ProspectWidgets_4GPlansData?.validateSimIdResp
  );
  const isSimCompatible =
    validateSimIdResp?.serviceStatus?.success &&
    validateSimIdResp?.simValidationInfo?.compatible;

  const imeiCheckAttempt = useSelector(
    (state) => state?.ProspectWidgets_4GPlansData?.imeiCheckAttempt
  );

  useEffect(() => {
    if (validateDeviceIdResp) {
      setIsValidIMEI(true);
    }
  }, [validateDeviceIdResp]);

  useEffect(() => {
    if(imei?.length && isValidIMEIFormat(imei) && !isImeiCompatible) {
      let failedCount = imeiCheckAttempt + 1;
      dispatch(setImeiCheckAttempt(failedCount));
    }
  }, [validateDeviceIdResp]);

  const setImeiValue = (value) => {
    if (value) {
      value = value.trim();
    }
    // setImeiCompatible(false);
    // setIMEIError(false);
    if (value?.length <= IMEI_MAX_LENGTH) {
      setImei(value);
    }
  };

  const validateImei = () => {
    const val = imei.trim();
    if (val) {
      dispatch(validateDeviceId({ deviceId: val, router: true, flow: "4g" }));
      window.sessionStorage.setItem("ISBYOR", true);
    }
  };

  const validateIccid = () => {
    const simId = iccid.trim();
    const imeiNo = imei.trim();
    if (simId && imeiNo) {
      dispatch(
        validateSimId({
          newSim: simSelection === "newSim",
          simNumber: simId,
          deviceId: imeiNo,
          router: true,
          flow: "4g",
        })
      );
    }
  };

  useDebounce(() => {
    if(iccid?.length && isValidICCIDFormat(iccid)){
      validateIccid();
    }
  }, 1000, [iccid]);

  const isContinueDisabled = !imei?.length || !isValidIMEIFormat(imei) || imeiCheckAttempt >= 9;
  const isCheckSimIdDisabled =
    !simSelection || (simSelection === "existingSim" && !isSimCompatible) ||
    (simSelection === "existingSim"  &&
      (!iccid?.length || !isValidICCIDFormat(iccid)));

  const onModalClose = (modalState) => {
    if(!modalState) {
      onOpenedChange(false);
      if(!isfromAuth) {
        dispatch(setValidateDeviceIdResp(""));
      }
    }
  }
  
  const setInputValue = useCallback((maxLength, updateState, inputValue) => {
    let value = inputValue.replace(numericRegex, "");
    if (value) {
      value = value.trim();
    }
    if (value?.length <= maxLength) {
      updateState(value);
    }
  });

  const imeiErrorMsg = imeiCheckAttempt >= 9 ?
  aemContent?.fourG["router-content"]?.byorModal?.imeiFailedAttempt:
  aemContent?.fourG["router-content"]?.byorModal?.imeiNotOkStatus
  const isImeiError = (imei?.length && imeiCheckAttempt >= 9) || continueClicked && validateDeviceIdResp && !isImeiCompatible;
  const isIccidError = iccid?.length && validateSimIdResp && !isSimCompatible;

  useEffect(() => {
    if(continueClicked && isImeiCompatible) {
      setShowSimId(true);
    }
  }, [validateDeviceIdResp]);

  // const addBYORouter = () => {
  //   const deviceInfo = validateDeviceIdResp?.deviceInfo;
  //   const simNumber = validateSimIdResp?.simValidationInfo?.simNumber;
  //   const request = {
  //     flow: "4g",
  //     sessionCartData: sessionCartData?.apiResponse?.prospectFlowData,
  //     deviceInfo: deviceInfo,
  //     pricingType: "zeroYear",
  //     byor: true,
  //     byod: true,
  //     newSim: simSelection === "newSim",
  //     byodInfo: {
  //       imei: deviceInfo?.imei,
  //       name: deviceInfo?.name,
  //       simSku: deviceInfo?.simSku,
  //       pSimCapable: deviceInfo?.pSimCapable,
  //       eSimCapable: deviceInfo?.eSimCapable,
  //       newSim: simSelection === "newSim",
  //       simNumber: simNumber,
  //     },
  //   };
  //   dispatch(update4GRouter(request));
  // };

  const getImeiModalBody = () => {
    return <>
    <ModalTitle>{t("4g.byorModal.heading")}</ModalTitle>
      <ModalBody
        children={
          <IMEIModalBody>
            <Body>
              {aemContent?.fourG["router-content"]?.byorModal?.enterImeiInfo}
            </Body>
            <Body>{t("4g.byorModal.findImeiNumber")}</Body>
            <Input
              name="imei"
              type="number"
              maxLength={IMEI_MAX_LENGTH}
              label={t("4g.byorModal.label")}
              helperText={t("4g.byorModal.helperText")}
              helperTextPlacement="bottom"
              error={isImeiError}
              errorText={imeiErrorMsg}
              success={
                continueClicked && validateDeviceIdResp && isImeiCompatible
              }
              successText={t("4g.byorModal.imeiOkStatus")}
              value={imei}
              onChange={(e) => {
                setImeiValue(e.target.value);
              }}
            />
          </IMEIModalBody>
        }
      />
      <ModalFooter
        buttonData={{
          primary: {
            children: "Continue",
            disabled: isContinueDisabled,
            onClick: () => {
              setContinueClicked(true);
              validateImei();
            },
          },
          close: {
            children: "Done",
            onClick: () => {
              if(!isfromAuth){
                dispatch(setValidateDeviceIdResp(""));
              }
              onOpenedChange(false);
            },
          },
        }}
      />
    </>
  };

  const getSimIdModalBody = () => (
    <>
    <SimModalTitle>
      <ModalTitle>
        {/* {t("4g.byorModal.sim.heading")} */}
        { aemContent?.fiveG["router-content"]?.byorModal?.sim?.heading}
      </ModalTitle>
    </SimModalTitle>
      <ModalBody
        children={
          <IMEIModalBody>
            <Body size="large">
            { aemContent?.fiveG["router-content"]?.byorModal?.sim?.haveSimQuestion}
              <Body size="large">
              { aemContent?.fiveG["router-content"]?.byorModal?.sim?.chooseOne}
              </Body>
            </Body>
            <SIMRadioBoxContainer>
              <RadioBox
                id={"newSim"}
                width={isMobile ? "100%" : ""}
                text= {aemContent?.fiveG["router-content"]?.byorModal?.sim?.radioBoxes?.newSim?.text}
                subtext={aemContent?.fiveG["router-content"]?.byorModal?.sim?.radioBoxes?.newSim?.subtext}
                value={"newSim"}
                selected={simSelection === "newSim"}
                onChange={(e) => setSimSelection(e?.target?.value)}
              />
              <RadioBox
                id={"existingSim"}
                width={isMobile ? "100%" : ""}
                text={aemContent?.fiveG["router-content"]?.byorModal?.sim?.radioBoxes?.existingSim?.text}
                subtext={aemContent?.fiveG["router-content"]?.byorModal?.sim?.radioBoxes?.existingSim?.subtext}
                value={"existingSim"}
                selected={simSelection === "existingSim"}
                onChange={(e) => setSimSelection(e?.target?.value)}
              />
            </SIMRadioBoxContainer>
            {simSelection === "existingSim" && (
              <Input
                name="ICCID"
                type={isIccidError? "inlineAction" : "number"}
                maxLength={SIM_MAX_LENGTH}
                label={aemContent?.fiveG["router-content"]?.byorModal?.sim?.label}
                helperText={aemContent?.fiveG["router-content"]?.byorModal?.sim?.helperText}
                helperTextPlacement="bottom"
                error={iccid?.length && validateSimIdResp && !isSimCompatible}
                errorText={aemContent?.fiveG["router-content"]?.byorModal?.sim?.simNotOkStatus}
                success={(iccid?.length && validateSimIdResp && isSimCompatible) || false}
                successText={aemContent?.fiveG["router-content"]?.byorModal?.sim?.simOkStatus}
                value={iccid}
                onChange={(e) => {
                  setInputValue(SIM_MAX_LENGTH, setIccid, e.target.value);
                }}
                inlineActionButtonLabel={aemContent?.fiveG["router-content"]?.byorModal?.clear}
                onClickInlineAction={() => {
                  setInputValue(SIM_MAX_LENGTH, setIccid, "");
                  dispatch(setValidateSimIdResp(""));
                }}
              />
            )}
          </IMEIModalBody>
        }
      />
      <SIMModalFooter isMobile={isMobile}>
        <Button
          disabled={isCheckSimIdDisabled}
          onClick={() => {
              if(!isfromAuth) {
                dispatch(setValidateSimIdResp(""));
              }
              onOpenedChange(false);
          }}
        >
          {aemContent?.fiveG["router-content"]?.byorModal?.continue}
        </Button>
        <Button use="secondary" onClick={() => {
          if(!isfromAuth){
            dispatch(setValidateDeviceIdResp(""));
          }
          onOpenedChange(false);
        }}>
          {aemContent?.fiveG["router-content"]?.byorModal?.cancel}
        </Button>
      </SIMModalFooter>
    </>
  );

  return (
    <Modal
      opened={opened}
      onOpenedChange={onModalClose}
      surface="light"
      ariaLabel="IMEI Check Modal"
      disableFocusLock= {true}
    >
      {showSimId ? getSimIdModalBody() : getImeiModalBody()}
    </Modal>
  );
};

export default IMEICheckModal;
