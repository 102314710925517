import React, { useMemo } from "react";
import { BYORIcon } from "common/components/SVGIcons";
import {
  ByodContainer,
  ByodImg,
  ByorImg,
  ByorSpan,
} from "./styles";

const PackageImage = ({ pack, description, width = 100, height = 100 }) => {
  const brand = pack?.brand || description?.brand;
  const name = pack?.productName || description?.name;
  const imageSet = useMemo(() => {
    const imageCDN = "//ss7.vzw.com/is/image/VerizonWireless/";
    const carouselImageset = [];
    if (pack && pack?.image) {
      carouselImageset.push(
        `${imageCDN}${pack.image}?$acc-lg$&hei=${height}&wid=${width}`
      );
    } else if (description) {
      if (description?.image) {
        carouselImageset.push(
          `${imageCDN}${description?.image}?$acc-lg$&hei=${height}&wid=${width}`
        );
      }
    }
    return carouselImageset;
  }, [pack?.image, pack?.description]);

  if (imageSet?.length && (!pack?.byor || pack?.deviceType === "FIVEGRECEIVER")) {
    return (
      <img
        src={imageSet[0]}
        alt={`${brand} - ${name}`}
        width={width}
        height={height}
      />
    );
  } else if (pack?.byor) {
    return (
      <ByodContainer width={width} height={height}>
        <ByorImg />
        <ByorSpan/>
      </ByodContainer>
    );
  } else if (pack?.byod) {
      return (
        <ByodContainer width={width} height={height}>
          <ByodImg />
        </ByodContainer>
      );
  } else {
    // return <div style={{ width: width, height: height }} />;
    return <></>;
  }
};

export default PackageImage;
