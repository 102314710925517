import React from "react";
import { Body } from "@vds3/typography";
import styled from "styled-components";
import CloseIcon from "common/components/SVGIcons/CloseIcon";
import { Row, Col } from "@vds3/grids";

const GridwallFilterBox = (props) => {
	return (
		<FilterBox list={props.filterList === "priceList"}>
			<Body>{props?.filter?.name ?? props?.data?.name ?? props?.filter?.key}</Body>
			<CloseIcon onClickFn={props.onClick} />
		</FilterBox>
	);
};

const GridwallFilterBoxes = (props) => {
	const getFilters = (allFilterLists) => {
		const filters = [];
		let filtersAll = { ...allFilterLists };
		Object.keys(filtersAll).map((key) =>
			filtersAll[key].length === 0 ? delete filtersAll[key] : ""
		);
		if (location?.pathname?.includes("certified-pre-owned"))
			delete filtersAll["sorSkuTypeList"];
		for (const list in filtersAll) {
			const referenceData = props?.filterData?.[list];
			filters.push(
				filtersAll[list].map((filter) => {
					let thisData = null;
					if (Array.isArray(referenceData))
						thisData = referenceData.find((i) => filter?.key === i?.key);
					else if (referenceData instanceof Map)
						thisData = referenceData.get(filter?.key);
					return (
						<ColContainer colSizes={{ mobile: 2, tablet: 2, desktop: 2 }}>
							<GridwallFilterBox
								key={filter.key}
								data={thisData}
								filterList={list}
								filter={filter}
								onClick={() => removeFilter(filter.key, list)}
							/>
						</ColContainer>
					);
				})
			);
		}
		if (filters !== null && filters && filters.length > 0) {
			return (
				<FilterBoxContainer>
					<RowContainer>{filters}</RowContainer>
				</FilterBoxContainer>
			);
		} else return <></>;
	};

	const removeFilter = (filterKey, filterList) => {
		let brandFilter = false,
			simFilter = false;
		if (
			props.brandFilter &&
			filterList === "brandList" &&
			props.setCurrentPath !== null &&
			props.setCurrentPath &&
			typeof props.setCurrentPath == "function"
		) {
			props.setCurrentPath();
			props.setBrandFilter("All");
			brandFilter = true;
		}
		if (
			props.simFilter &&
			filterList === "simTypeList" &&
			props.setCurrentPath !== null &&
			props.setCurrentPath &&
			typeof props.setCurrentPath == "function"
		) {
			props.setCurrentPath();
			simFilter = true;
		}
		let newFilters = { ...props.filters };
		newFilters[filterList] = newFilters[filterList].filter(
			(i) => i.key !== filterKey
		);
		props.setFilters(newFilters, brandFilter || simFilter);
	};

	return <>{getFilters(props.filters)}</>;
};

const FilterBox = styled.div`
	padding: ${({ list }) => (list ? "10px 3px" : "10px 10px")};
	border: 1px solid #d8dada;
	display: flex;
	flex-direction: row;
	gap: 1rem;
	span {
		padding-top: 0;
	}
`;

const FilterBoxContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1rem;
`;
const ColContainer = styled(Col)`
	padding-top: 20px;
`;

const RowContainer = styled(Row)`
	padding-left: 0 !important;
`;

export default GridwallFilterBoxes;
