import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalTitle, ModalBody } from "@vds3/modals";
import { Body, Title } from "@vds3/typography";
import { MarginSpacerL } from "common/components/styleTag";
import { TextLink, Button } from "@vds3/buttons";
import { useParams } from "react-router-dom";

const ShowLimitModal = ({ onOpenedChange = () => {}, isOpen }) => {
	const { flow, serviceProvider } = useParams();
	const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	const vendorName = sessionCartData?.dspData?.vendorName;
	let flowType = serviceProvider === "amz" ? "dsp" : serviceProvider;

	const { t, i18n } = useTranslation(["app", flowType]);
	let planSelected = t(`amazon-${flowType}.selectedPlan`, { ns: flowType });
	return (
		<Modal
			opened={isOpen}
			disableOutsideClick={true}
			onOpenedChange={onOpenedChange}
		>
			<ModalTitle>
				{(flowType || vendorName) == "afp" ? (
					<Title size="large">
						To purchase more than 20 devices, please contact us to complete your
						order.
					</Title>
				) : (
					<Title size="small">
						{t(`amazon-${flowType}.exitModal.heading`, { ns: flowType })}
					</Title>
				)}
			</ModalTitle>
			<ModalBody>
				{(flowType || vendorName) == "afp" ? "" : <MarginSpacerL />}
				{(flowType || vendorName) == "afp" ? (
					""
				) : (
					<Body bold>
						{" "}
						{t(`amazon-${flowType}.exitModal.chatExpert`, { ns: flowType })}
					</Body>
				)}
				<div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
					<Body primitive="span" size="large">
						{(flowType || vendorName) == "afp" ? (
							<>
								{" "}
								Email{" "}
								<TextLink type="inline" onClick={(e) => {}}>
									amazon.afp.sales@verizonwireless.com
								</TextLink>{" "}
								for assistance{" "}
							</>
						) : (
							<span
								dangerouslySetInnerHTML={{
									__html: t(`amazon-${flowType}.exitModal.timing3`, {
										ns: flowType,
									}),
								}}
							></span>
						)}
					</Body>
				</div>
				<Button
					size="large"
					inverted={false}
					disabled={false}
					secondary={false}
					className="close-limit-btn"
					onClick={(e) => onOpenedChange(false)} //to do -- add chat implementation
				>
					{(serviceProvider || vendorName) === "afp" ? "Close" : "Chat now"}
				</Button>
			</ModalBody>
		</Modal>
	);
};

export default ShowLimitModal;
