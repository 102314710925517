import React from "react";
import { Col, Row } from "@vds3/grids";
import { Body } from "@vds3/typography";
import styled from "styled-components";

const SessionCartId = (props) => {
    const SessionCartIdContainer = styled.div`
    margin-top: ${props && props?.isCartId ? "0rem" : "3rem"};
    margin-bottom: ${props && props?.isCartId ? "2rem" : "0rem"};
    `
    return (
      <SessionCartIdContainer>
            <Row>
                <Col colSizes={{ mobile: 4, tablet: 8, desktop: 12 }}>
                    <div className="font-12">
                    {props.sessionCartData?.cart?.cartId && (
                        <Body>Cart Id : {props.sessionCartData?.cart?.cartId} </Body>
                    )}
                    </div>
                </Col>
            </Row>
            </SessionCartIdContainer>
    );
};

export default SessionCartId;
