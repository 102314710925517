import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, TextLink } from "@vds3/buttons";
import { Grid, Row, Col } from "@vds3/grids";
import { Body } from "@vds3/typography";

import CloseIcon from "common/components/SVGIcons/CloseIcon";
import { getProductType } from "utils/commonUtils";
import {
	getCompareDevices,
	setCompareDevices,
	setOneTalk,
} from "../../ducks/MobilitySlice";

import styled from "styled-components";
import { useImageUtils } from "hooks";
import { TileletCarousel } from "@vds3/tiles";
import { isMobile } from "react-device-detect";
import { Title } from "@vds3/typography";
import { ellipsisShorten } from "../../../../utils/commonUtils";
const CompareTile = (props) => {
	const { getImageLink } = useImageUtils();
	return (
		<CompareTileContainer
			style={{
				backgroundColor: props.item ? "#f6f6f6" : "#ffffff",
				borderColor: props.item ? "#f6f6f6" : "#A7A7A7",
			}}
		>
			{props.item && (
				<>
					<CompareTileContent>
						<img
							width="90px"
							height="90px"
							src={getImageLink(props.item?.selectedSku?.filePath, 250, 250)}
						/>
						<CompareTileText>
							<Body size="large">
								{ellipsisShorten(props.item?.brandName + " " + props.item?.name, 23)}
							</Body>
						</CompareTileText>
					</CompareTileContent>
					<CloseIconContainer>
						<CloseIcon onClickFn={() => props.onCloseFn(props.item?.deviceId)} />
					</CloseIconContainer>
				</>
			)}
			{!props.item && (
				<CompareTileContentNew>
					<div className="WithoutCt">
						{props.index === props.count && <p>Add device</p>}
					</div>
					<CompareTileText />
				</CompareTileContentNew>
			)}
		</CompareTileContainer>
	);
};

const GridwallCompare = ({
	aem,
	selectedProducts = [],
	tileCloseFn,
	count,
	onClearFn,
	isOneTalk,
}) => {
	const dispatch = useDispatch();
	//const navigate = useNavigate();
	const [isVisible, setIsVisible] = useState(true);
	const selectedKeys = Object.keys(selectedProducts ?? {});

	const handleCompareBtnClick = () => {
		const productType = getProductType();
		if (isOneTalk) {
			dispatch(setOneTalk(true));
			dispatch(getCompareDevices());
			//navigate(`/shop/products/voice-collaboration/${productType}/compare`);
		} else {
			dispatch(getCompareDevices());
			//navigate(`/shop/products/devices/${productType}/compare`);
		}
	};

	const clearFun = () => {
		setIsVisible(false);
		if (onClearFn) {
			onClearFn();
		} else dispatch(setCompareDevices());
		setIsVisible(true);
	};
	const scroll = () => {
		return Array(4)
			.fill(1)
			.map((item, index) => {
				return {
					title: {
						size: "titleLarge",
						primitive: "div",
						children: (
							<Carousel>
								<CompareTile
									key={selectedProducts[selectedKeys[index]]?.deviceId ?? index}
									item={selectedProducts[selectedKeys[index]]}
									onCloseFn={tileCloseFn}
									index={index}
									count={count}
								/>
							</Carousel>
						),
					},
					backgroundColor: selectedProducts[selectedKeys[index]] ? "gray" : "white",
				};
			});
	};
	return isVisible && selectedKeys?.length ? (
		<Grid>
			<Row>
				<Col colSizes={{ mobile: 6, tablet: 12, desktop: 12 }}>
					<CompareHeading>
						{count > 0 ? (
							<>
								<Title size="small" bold>
									{aem?.labels?.compareDevices}{" "}
									<span style={{ fontWeight: "400" }}>({count})</span>
								</Title>
							</>
						) : (
							""
						)}
					</CompareHeading>
					<CompareContainer>
						<TileWrapper>
							{!isMobile ? (
								<>
									{Array(4)
										.fill(1)
										.map((item, index) => (
											<CompareTile
												key={selectedProducts[selectedKeys[index]]?.deviceId ?? index}
												item={selectedProducts[selectedKeys[index]]}
												onCloseFn={tileCloseFn}
												index={index}
												count={count}
											/>
										))}
								</>
							) : (
								<TileletCarousel
									layout="2UP"
									peek="none"
									surface="light"
									paginationFill="light"
									paginationDisplay="onHover"
									data={scroll()}
								/>
							)}
						</TileWrapper>
						<ButtonWrapper>
							<CompareButton
								size="large"
								use="secondary"
								disabled={selectedKeys?.length < 2}
								onClick={handleCompareBtnClick}
							>
								{aem?.labels?.compare ?? "Compare"}
							</CompareButton>
							<ClearAll>
								<TextLink
									type="standAlone"
									size="small"
									role="button"
									onClick={clearFun}
								>
									{aem?.labels?.close123 ?? "Clear all"}
								</TextLink>
							</ClearAll>
						</ButtonWrapper>
					</CompareContainer>
				</Col>
			</Row>
		</Grid>
	) : (
		<></>
	);
};

const CompareButton = styled(Button)`
	height: 2.9rem;
	width: 11.5rem;
	@media (max-width: 767px) {
		 {
			margin-right: 35% !important;
		}
	}
`;
const Carousel = styled.div``;

const CompareContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 1rem;
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 2;
	max-width: 1273px;
	margin-left: auto;
	margin-right: auto;
	@media (max-width: 545px) {
		 {
			display: flex;
			flex-direction: column;
		}
	}
`;
const CloseIconContainer = styled.div`
	@media (max-width: 767px) {
		position: absolute;
		right: 0;
		top: 0;
		padding: 1px;
		font-size: 14px;
	}
`;
const TileWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	flex: 1;
	max-width: 80% !important;
	@media (max-width: 767px) {
		> div > div > div {
			width: 100%;
		}
		.slotContainer {
			width: 75px;
		}
		.slotContainer > div > div > div {
			aspect-ratio: 2;
			border: 2px dashed #f6f6f6;
		}
	}
	@media (max-width: 767px) {
		 {
			max-width: 100% !important;
		}
	}
`;

const ClearAll = styled.div`
	margin-top: 0.6rem;
	a {
		margin-top: 7%;
	}
`;

const ButtonWrapper = styled.div`
	width: 100%;
	min-width: 100px;
	max-width: 20% !important;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding-top: 10px;

	@media (max-width: 767px) {
		max-width: 100% !important;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row-reverse;
		align-content: stretch;
		align-items: baseline;
		justify-content: space-around;
	}
`;

const CompareTileContainer = styled.div`
  max-width: 25%;
  padding: 15px;
  flex: 1;
  margin:5px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 100px;
  border-radius: 8px;
  margin-right: 1rem;
  background-color:#f6f6f6;
  border: 1px dashed #f6f6f6;
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.02);
  @media (max-width: 767px) {
     {
      width: 152px;
      max-height: 64px;
      border-radius: 8px;
      display: flex;
    align-items: center;
    border: none;
}
    }
  }
`;

const CompareTileContentNew = styled.div`
	display: flex;
	margin: 5px;
	background-color: #ffffff !important;
	img {
		height: 48px;
		width: 48px;
	}
	@media (max-width: 767px) {
		.WithoutCt {
			position: absolute !important;
			top: 4px !important;
			font-size: 12px !important;
			font-weight: 400 !important;
			left: 25px;
		}
	}
`;
const CompareTileContent = styled.div`
	background-color: #f6f6f6 !important;
	display: flex;
	align-items: flex-start;
	margin: 5px;
	img {
		height: 48px;
		width: 48px;
	}

	@media (max-width: 767px) {
		margin: -5px !important;
		img {
			max-width: 100%;
			width: 28px;
			height: 28px;
			object-fit: contain;
			position: absolute;
			top: 10px;
			left: 5px;
		}
	}
`;
const CompareTileText = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 5px !important;

	@media (max-width: 767px) {
		font-family: "Verizon NHG eTX";
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		width: 78px;
		height: 48px;
		margin-left: 32px !important;
		position: absolute;
		top: 7px;
		left: 10px;
	}
`;
const CompareHeading = styled.div`
	margin-top: 1.5rem;
	margin-bottom: 1rem;
`;

export default GridwallCompare;
