import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "@vds3/modals";
import { Body, Title } from "@vds3/typography";
import styled from "styled-components";
import { MarginSpacerL, MarginSpacerM } from "common/components/styleTag";

const CustomBody = styled(Body)`
	span {
		font-size: 0.99rem !important;
	}
`;

const DeviceUnlockModal = ({ opened, onOpenedChange, ...props }) => {
	const { t } = useTranslation(["pdp"]);
	return (
		<Modal ariaLabel="" opened={opened} onOpenedChange={onOpenedChange}>
			<Title bold size="large">
				{t("device-pdp.deviceUnlockingPolicy")}
			</Title>
			<MarginSpacerL />
			<CustomBody size="large">
				<span
					dangerouslySetInnerHTML={{
						__html: t("device-pdp.deviceUnlocking"),
					}}
				></span>
			</CustomBody>
			<MarginSpacerM />
			<CustomBody size="large">
				<span
					dangerouslySetInnerHTML={{
						__html: t("device-pdp.deviceLocked"),
					}}
				></span>
			</CustomBody>
		</Modal>
	);
};

export default DeviceUnlockModal;
