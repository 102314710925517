import React, { useEffect, useState } from 'react'
import { Body, Title } from "@vds3/typography";
import { MarginSpacer2XL, MarginSpacerS } from 'common/components/styleTag';
import { DropdownSelect } from "@vds3/selects";
import { Input } from "@vds3/inputs";
import {useSelector,useDispatch} from 'react-redux'
import { getPurchaseInfo } from 'pages/Mobility/ducks/MobilitySlice';
import ProductsPage from './Products';


const ManagePage = ()=>{
    const [purchaseItem,setPurchaseItem] = useState(undefined)
    const dispatch = useDispatch()
    const mobilityData = useSelector(state=>state.ProspectWidgets_MobilityData)
    useEffect(()=>{
        dispatch(getPurchaseInfo())
    },[])
    useEffect(()=>{
        if(!!mobilityData.purchaseInfo && mobilityData.purchaseInfo?.purchaseInfo  && !!mobilityData.purchaseInfo.purchaseInfo.purchaseLineItem && mobilityData.purchaseInfo.purchaseInfo.purchaseLineItem.length){
            let products = {}
            mobilityData.purchaseInfo.purchaseInfo.purchaseLineItem.map(data=>{
                const companyName = data?.companyName
                if(!products[companyName])  products[companyName] = []
                
                products[companyName].push({...data})
            })
            setPurchaseItem(products)
        }
    },[mobilityData.purchaseInfo])
    const handleSearch = (val)=>{

    }
    return(
        <div>
            <Title size="large" bold={true}>Manage business solutions</Title>
            <MarginSpacerS/>
            <Body size="medium" color="#a1a2a2">Select a business solutions to view details and make changes.</Body>
            <MarginSpacer2XL/>
            <div style={{display:'flex',justifyContent:'flex-start'}}>
            <div style={{width:'300px'}}>
                <Input type="text" onChange={e=>handleSearch(e.target.value)} label="Search" required={false}/>
            </div>
            <div style={{marginLeft:'2rem',width:'300px'}}>
            <DropdownSelect 
            label="View by"
            error={false}
            disabled={false}
            readOnly={false}
            inlineLabel={false}
            required={false}
            >
            <option value="account">Accounts</option>
            <option value="products">Products</option>
            </DropdownSelect>
            </div>
            </div>
            <MarginSpacer2XL/>
            <ProductsPage purchaseItem={purchaseItem}/>
        </div>
    )
}

export default ManagePage