// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-input {
  position: relative; 
}
.search-input input {
  width: 100%;
  height: 2.5rem;
  border-top: none;
  border-left: none;
  border-right: none;
}
.search-input span {
  border-top: none;
  border-left: none;
  border-right: none;
}

.buss-search{
width: 229px;
}
.buss-search > input{
  border-radius: 4px;
  border: 0.5px solid grey;
  padding-left: 2.5rem;
}`, "",{"version":3,"sources":["webpack://./src/common/components/SearchInput/SearchInput.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,cAAc;EACd,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;AACA,YAAY;AACZ;AACA;EACE,kBAAkB;EAClB,wBAAwB;EACxB,oBAAoB;AACtB","sourcesContent":[".search-input {\n  position: relative; \n}\n.search-input input {\n  width: 100%;\n  height: 2.5rem;\n  border-top: none;\n  border-left: none;\n  border-right: none;\n}\n.search-input span {\n  border-top: none;\n  border-left: none;\n  border-right: none;\n}\n\n.buss-search{\nwidth: 229px;\n}\n.buss-search > input{\n  border-radius: 4px;\n  border: 0.5px solid grey;\n  padding-left: 2.5rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
