import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalTitle, ModalBody, ModalFooter } from "@vds3/modals";
import { Body, Title } from "@vds3/typography";
import { MarginSpacerL, MarginSpacerM, MarginSpacerS, MarginTopSpacerL } from "common/components/styleTag";
import { RadioButtonGroup } from "@vds3/radio-buttons";
import { Input } from "@vds3/inputs";
import { Col, Grid, Row } from "@vds3/grids";
import { Line } from "@vds3/lines";

const StaticIpModal = ({
	deviceQuantity,
	opened,
	onOpenedChange,
	...props
}) => {
	const { t } = useTranslation(["app"]);
	const [ipAssignType, setIpAssignType] = useState("");
	const [ipRestrictedType, setIpRestrictedType] = useState("");

	return (
		<Modal ariaLabel="" opened={opened} onOpenedChange={onOpenedChange}>
			<ModalTitle>
				<Title size="large">
					Ip Address assignment
				</Title>
				<MarginSpacerM />
			</ModalTitle>
			<ModalBody>
				<RadioButtonGroup
					onChange={(e) =>
						setIpAssignType(e.target.value)
					}
					selected={ipAssignType}
					data={[
						{
							children: 'Automatically assign',
							value: 'autoAssign',
							ariaLabel: 'Auto Assign',
							disabled: false
						},
						{
							children: 'Manually assign',
							value: 'manualAssign',
							ariaLabel: 'Manual Assign',
						}
					]}
				/>
				<MarginSpacerL />
				<Grid>
  <Row colSizes={{ mobile: 4, tablet: 12, desktop: 12}} >
                           <Col colSizes={{ mobile: 2, tablet: 3, desktop: 3 }}>
						   <Input
								onBlur={() => {}}
								maxLength={3}
								readOnly={false}
								required={true}
								disabled={false}
								value={""}
								onChange={(e) => {}}
								error={false}
								width={"100%"}
								errorText={"errorText"}
								onClickInlineAction={() => {}}
							/>
						   </Col>
						   <Col colSizes={{ mobile: 2, tablet: 3, desktop: 3 }}>
						   <Input
								onBlur={() => {}}
								maxLength={3}
								readOnly={false}
								required={true}
								disabled={false}
								value={""}
								onChange={(e) => {}}
								error={false}
								width={"100%"}
								errorText={"errorText"}
								onClickInlineAction={() => {}}
							/>
						   </Col>
						   <Col colSizes={{ mobile: 2, tablet: 3, desktop: 3}}>
						   <Input
								onBlur={() => {}}
								maxLength={3}
								readOnly={false}
								required={true}
								disabled={false}
								value={""}
								onChange={(e) => {}}
								error={false}
								width={"100%"}
								errorText={"errorText"}
								onClickInlineAction={() => {}}
							/>
						   </Col>
						   <Col colSizes={{ mobile: 2, tablet: 3, desktop: 3 }}>
						   <Input
								onBlur={() => {}}
								maxLength={3}
								readOnly={false}
								required={true}
								disabled={false}
								value={""}
								onChange={(e) => {}}
								error={false}
								width={"100%"}
								errorText={"errorText"}
								onClickInlineAction={() => {}}
							/>
						   </Col>
						   </Row>
						   <MarginSpacerS />
						   <Row colSizes={{ mobile: 4, tablet: 12, desktop: 12}} >
						   <Body>Please enter IP address.</Body>
						   <MarginTopSpacerL/>
						   <Line type="secondary" />
						   <MarginSpacerL />
						   </Row>
						   </Grid>
						   <RadioButtonGroup
					onChange={(e) =>
						setIpRestrictedType(e.target.value)
					}
					selected={ipRestrictedType}
					data={[
						{
							children: 'Restricted',
							value: 'Restricted',
							ariaLabel: 'Restricted',
							disabled: false
						},
						{
							children: 'Unrestricted',
							value: 'Unrestricted',
							ariaLabel: 'Unrestricted',
						}
					]}
				/>
			</ModalBody>
			<ModalFooter
				buttonData={{
				primary: {
					children: 'Assign',
					onClick: () => onOpenedChange(false)
				},
				close: {
					children: 'Cancel',
					onClick:(e) => onOpenedChange(false)
				},
				}}
			/>
		</Modal>
	);
};

export default StaticIpModal;
