import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Modal, ModalTitle, ModalBody, ModalFooter } from "@vds3/modals";
import { Body } from "@vds3/typography";

const IncompatiblePackageModal = ({
	opened,
	onOpenedChange,
	onContinue,
	...props
}) => {
	const { t } = useTranslation(["app"]);
	return (
		<>
			<Modal opened={opened} onOpenedChange={onOpenedChange}>
				<ModalTitle>{t("app.common.cartIncompatibleModal.heading")}</ModalTitle>
				<ModalBody>
					<ModalBodyContent>
						<Body size="large">
							<span
								dangerouslySetInnerHTML={{
									__html: `${t("app.common.cartIncompatibleModal.description1a")} ${t(
										"app.common.cartIncompatibleModal.description1b"
									)}`,
								}}
							/>
						</Body>
						<Body size="large">
							{t("app.common.cartIncompatibleModal.description2")}
						</Body>
					</ModalBodyContent>
				</ModalBody>
				<ModalFooter
					buttonData={{
						primary: {
							children: t("app.common.cartIncompatibleModal.continue"),
							onClick: () => onContinue(),
						},
						close: {
							children: t("app.common.cartIncompatibleModal.cancel"),
							onClick: () => onOpenedChange(false),
						},
					}}
				/>
			</Modal>
		</>
	);
};

const ModalBodyContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	a {
		color: black;
	}
`;

export default IncompatiblePackageModal;
