
export function reducer(state, action) {
  switch (action.type) {
    case 'SET_FIRST_NAME':
      return {
        ...state,
        firstName: action.payload
      };
    case 'SET_ERROR_TEXTS':
      return { ...state, errorTexts: action.payload }

    case 'SET_CORPORATION':
      return { ...state, corporation: action.payload }
    case 'SET_RETRY_COUNT':
      return { ...state, retryCount: action.payload }
    case 'SET_SHOW_ONE_TIME_PASS_MODAL':
      return {
        ...state,
        showOneTimePassModal: action.payload
      };
    default:
      throw new Error();
  }
}


function setErrorTexts(data) { return { type: 'SET_ERROR_TEXTS', payload: data } }
function setCustomerType(data) { return { type: 'SET_CUSTOMER_TYPE', payload: data } }
function setCorporation(data) { return { type: 'SET_CORPORATION', payload: data } }
function setRetryCount(data) { return { type: 'SET_RETRY_COUNT', payload: data } }
function setShowOneTimePassModal(data) { return { type: 'SET_SHOW_ONE_TIME_PASS_MODAL', payload: data } }

export const actions = {
  setErrorTexts,
  setCustomerType,
  setCorporation,
  setRetryCount,
  setShowOneTimePassModal
};



