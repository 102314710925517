import React, { useState, useEffect } from 'react'
import { Title,Body } from "@vds3/typography";
import { isMobile } from "react-device-detect";
import { MarginSpacerL, MarginSpacerS, MarginSpacerXS } from 'common/components/styleTag';
import { useDispatch, useSelector } from 'react-redux'
import { getSessionCart, saveCreditHoldCart } from 'pages/Checkout/ducks/CheckoutSlice';
import { useTranslation } from "react-i18next";
import { setCreditHoldCode, setCreditHoldIsFirstCallInvoked, setCreditHoldIsVisited, setCreditHoldRedirectedFromCreditHold, setIsFromCreditHold, setIsLowerFinancedAmountOptionSelected, validateCreditHoldCreditDetails } from 'pages/Checkout/CreditResult/CreditResultsNew/CreditSlice';
import cloneDeep from "lodash/cloneDeep";
import { Button, TextLink } from "@vds3/buttons";
import { ButtonWrapper } from './CreditResult';
import Utils from "../../../Checkout/ducks/utils";
import styled from 'styled-components';

const CreditHold = ({creditHoldNeeded=()=>{}}) => {
    const dispatch = useDispatch()
    const { t } = useTranslation(["app", "credit-hold"]);
    const aemData = t("credit-hold-new", { ns: "credit-hold", returnObjects: true });
    const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
    const pageData = useSelector((state) => state.ProspectWidgets_CheckoutData);
    const creditData = useSelector((state) => state.ProspectWidgets_CreditData);
    const sessionCartData = globalData?.sessionCartData?.apiResponse?.prospectFlowData;
    const [nextBtnClicked, setNextBtnClicked] = useState(false);
    const [showCallNowModal, setShowCallNowModal] = useState(false);
    const [messageData, setMessageData] = useState({});
    const [isCreditHoldVisited, setIsCreditHoldVisited] = useState(false);
    const [disabledContinueCTA, setDisabledContinueCTA] = useState(true);
    
    useEffect(() => {
        const element = document.getElementById("credit-results");
        if(element) element?.scrollIntoView({ behavior: "smooth", block: "start" });
      setIsCreditHoldVisited(true);
      dispatch(getSessionCart());
      dispatch(setCreditHoldIsVisited(true))
      dispatch(setCreditHoldRedirectedFromCreditHold(false));
      dispatch(setIsFromCreditHold(true));
      const aemContent = t("app.common.sessiontimeout", {
        ns: "app",
        returnObjects: true,
      });
      const isBizHours = Utils?.isBusinessHours(aemContent?.businessHours || []);
      if (!isBizHours) {
        dispatch(saveCreditHoldCart({ sendEmail: "Y" }));
      } else {
        dispatch(saveCreditHoldCart({ sendEmail: "N" }));
      }
      return () => {
        dispatch(setCreditHoldIsFirstCallInvoked(false));
      };
    }, []);
    useEffect(() => {
        const resp = creditData?.creditDetailsResponse;
        if (nextBtnClicked && resp && Object.keys(resp)?.length) {
          dispatch(getSessionCart());
          if (
            resp?.serviceStatus?.statusCode === "CREDIT_HOLD_NEEDED" ||
            resp?.serviceStatus?.statusCode === "CREDIT_RESULTS_FOR_HOLD_NEEDED" ||
            resp?.serviceStatus?.statusCode === "SUSPENDED_FLOW_CREDITHOLD_NOT_SUPPORTED"
          ) {
            let code = `${resp?.data?.creditApprovalStatus}-${resp?.data?.creditStatusReason}`;
            dispatch(setCreditHoldCode(code));
          } else if (resp?.serviceStatus?.success) {
            if (resp?.data?.creditReadStatus === "COMPLETED") {
              if (resp?.data?.spendingLimitExceded) {
                dispatch(setIsLowerFinancedAmountOptionSelected(true));
                creditHoldNeeded(false)
              }
              dispatch(saveCreditHoldCart({ sendEmail: "N" }));
              messageData?.disableCTA && setDisabledContinueCTA(false);
              if (messageData?.disableCTA) {
                creditHoldNeeded(false)
                // dispatch(setActiveSection({ activeSection: "credit-result" }));
                // setNavigateToPath("/shop/unified-checkout/credit-result");
              } else {
                creditHoldNeeded(false)
                // dispatch(setActiveSection({ activeSection: "credit-result" }));
                // navigate("/shop/unified-checkout/credit-result", { replace: true });
              }
            }
          // } else if (resp?.serviceStatus?.statusCode === "SUSPENDED_USER_FLOW") {
          //   // dispatch(setSuspendFlow(false));
          //   // navigate("/shop/contact-us", {state: {refUrl : window.location.pathname}});
          // } else {
          //   if (resp?.serviceStatus?.statusCode === "ERROR_NEED_AUTHENTICATION") {
          //     navigate("/shop/unified-checkout/verifyDocuments", { replace: true });
          //   }
          }
        }
      }, [creditData?.creditDetailsResponse]);

    const getApplicationNumber = () => {
        let appNumber = "";
        if (sessionCartData?.businessInfo?.creditApplicationNum) {
            appNumber = sessionCartData?.businessInfo?.creditApplicationNum;
        } else if (sessionCartData?.businessInfo?.creditWriteDetails) {
            let creditWriteDetails = Object.values(
                sessionCartData?.businessInfo?.creditWriteDetails
            );
            creditWriteDetails?.forEach((element) => {
                appNumber = appNumber
                    ? appNumber + ", " + element?.creditApplicationNum
                    : element?.creditApplicationNum;
            });
        } else {
            appNumber = "xxxxxx";
        }
        return appNumber;
    };
    const replaceFileNames = (messageDataObj) => {
      let str = "";
      files.forEach((name, index) => {
        let marginClass =
          index < files.length - 1 ? "u-marginBottomExtraSmall" : "";
        str = str + `<div class='bold font-16 ${marginClass}'>${name}</div>`;
      });
      return (messageDataObj.body.value = messageDataObj.body.value.replace(
        "{{filenames}}",
        str
      ));
    };
    const assignMessageData = (node) => {
        let messageDataObj = cloneDeep({
            ...node?.messageData,
            disableCTA: node?.disableCTA,
        });
        if (messageDataObj?.uploadDocument?.show && files?.length) {
            messageDataObj = replaceFileNames(messageDataObj);
        }
        if (messageDataObj?.body?.value) {
            messageDataObj.body.value = messageDataObj?.body?.value?.replace(
                "{{applicationNumber}}",
                getApplicationNumber()
            );
            messageDataObj.body.value = messageDataObj?.body?.value?.replace(
              "{{email}}",
              creditData?.updateProspectCreditDetailsResponse?.data?.cartData?.businessInfo?.email ?? creditData?.creditDetailsResponse?.data?.businessInfo?.email ?? ''
          );
        }
        setMessageData(messageDataObj);
        !isCreditHoldVisited && messageDataObj?.disableCTA && disabledContinueCTA && onContinue(messageDataObj?.disableCTA);
    };
    const onContinue = (disableCTA) => {
      setNextBtnClicked(true);
      const req = {
        flow: pageData?.unifiedCartParams?.flow,
        disableCTA: disableCTA,
        isInvokedFirstTime: creditData?.creditHold?.isFirstCallInvoked
          ? false
          : true,
        repeatTimer: aemData?.repeatTimer ?? 30,
        startTimer: aemData?.startTimer ?? 60,
        isBlended: false,
      };
      dispatch(validateCreditHoldCreditDetails(req));
    };
    useEffect(() => {
        let node;
        if (creditData?.creditHold?.code) {
            let codes = creditData?.creditHold?.code?.split("-");
            let mainNode = aemData?.creditHoldMessages.find(
                (data) =>
                    !data?.isDisabled &&
                    data?.statusCodes?.includes(codes[0]) &&
                    data?.codes?.includes(codes[1])
            );
            if (mainNode) {
                node = mainNode;
            } else {
                node = aemData?.defaultMessages.find((data) =>
                    data?.statusCodes?.includes(codes[0])
                );
            }
            if (node) {
                assignMessageData(node);
            }
        }
    }, [creditData?.creditHold?.code, sessionCartData]);
return(
  <div style={{maxWidth:'650px'}}>
    <Title size='medium' bold={true}> <div dangerouslySetInnerHTML={{__html:messageData?.title?.value}}></div></Title>
    <MarginSpacerXS/>
    <MarginSpacerS/>
    <Body size='large'>{messageData?.subTitle?.value}</Body>
    {messageData?.subTitle2?.value ? 
    <>
    <MarginSpacerXS/>
    <MarginSpacerS/>
    <Body size='large'>{messageData?.subTitle2?.value}</Body>
    </>:<></>}
    <MarginSpacerL/>
    {messageData?.documentHeading ? <>
    <Body size='large' bold={true}>{messageData?.documentHeading}</Body>
    <MarginSpacerS/></>:<></>}
    {messageData?.documentsList ? <>
    <Body size="large"><SpanWrapper  dangerouslySetInnerHTML={{ __html: messageData?.documentsList?.value}}/> </Body>
    <MarginSpacerL/></>:<></>}
    <Body size='large' bold={true}>{messageData?.nextSteps}</Body>
    <MarginSpacerS/>
    <Body size="large"><SpanWrapper  dangerouslySetInnerHTML={{ __html: messageData?.body?.value}}/> </Body>
      
    <MarginSpacerL/>
  <ButtonWrapper>
             {messageData?.callCTA?.show && (
                  <Button
                    className="credit-hold-page-content__call-cta-button"
                    disabled={!disabledContinueCTA}
                    onClick={() => {
                      !isMobile && setShowCallNowModal(true);
                    }}
                    primary
                    width={"150px"}
                  >
                    {messageData?.callCTA?.value
                      ? messageData?.callCTA?.value
                      : "Call now"}
                  </Button>
                )}&nbsp;&nbsp;
                <Button
                      className="credit-hold-page-content__continue-cta-button"
                      disabled={messageData?.disableCTA && disabledContinueCTA}
                      onClick={() => {
                        if (messageData?.disableCTA) {
                          creditHoldNeeded(false)
                          dispatch(setCreditHoldIsVisited(false))
                        }
                      }}
                      use="secondary"
                      width={"150px"}
                    >
                      {messageData?.cta?.value}
                    </Button>
  </ButtonWrapper>
  <MarginSpacerS/>
  {/* <CallNowModal
        onOpenedChange={setShowCallNowModal}
        opened={showCallNowModal}
        title={`Please call us at <a class="cr-hold-num" href='tel:${
          messageData?.callCTA?.phoneNumber
            ? messageData?.callCTA?.phoneNumber
            : `XXX.XXX.XXXX`
        }'>${messageData?.callCTA?.phoneNumber ?? "XXX.XXX.XXXX"}</a>.`}
      /> */}
  </div>
  
)
}
export default CreditHold
const SpanWrapper = styled.span`
.sub-section-container > div:first-child{
  padding-bottom:8px;
}
.sub-section-container > ul {
  padding-left: 1rem;
  margin-top:0px;
}
.u-marginBottomMedium{
  margin-bottom:8px;
}
`