import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { RadioBox } from "@vds3/radio-boxes";
import { Body, Title } from "@vds3/typography";
import { MarginSpacerM, MarginSpacerS } from "common/components/styleTag";
import { formatPriceString } from "utils/commonUtils";
import FWAContext from "../fwaContext";
import { Button } from "@vds3/buttons";

const PaymentCard = ({
  id,
  heading,
  selected,
  enablePromotion,
  isPromotion,
  promoPrice,
  isDPP,
  price,
  installments,
  isMobile,
  onChange,
  planType,
  ...props
}) => {
  const { flow, primaryPlan, backupPlan } = useContext(FWAContext);
  const { t } = useTranslation(["4G-5G"]);
  const aemKey = flow === "4g" ? "fourG" : "fiveG";
  const aemObject = t(`4G-5G.${aemKey}.router-content`, {
    returnObjects: true,
  });
  const commonPlan = planType === "primaryPlan" ? primaryPlan : backupPlan;
  const actualPrice = isPromotion ? promoPrice : price;
  const totalPrice = (Number(actualPrice) * Number(commonPlan?.quantity ?? 1)).toFixed(2);
  const discountAmt = (price * Number(commonPlan?.quantity ?? 1)).toFixed(2) - totalPrice;

  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onChange(id);
    },
    [id, primaryPlan, backupPlan]
  );


  return (
    <div onClick={onClick} data-loc={`${flow}:${heading}`}>
      <StyledBox
        id={id}
        value={id}
        name={id}
        selected={selected}
        isMobile={isMobile}
        // onChange={onChange}
        children={
          <FourGFiveGPaymentBoxWrap>
            <CardContainer>
              {!!enablePromotion && (
                <>
                  <PromoTag>
                    {!!isPromotion && (
                      <Body>
                        {(aemObject?.paymentHeading &&
                          aemObject?.paymentHeading[commonPlan?.selectedPlan] &&
                          aemObject?.paymentHeading[commonPlan?.selectedPlan][
                            installments
                          ]) ||
                          (aemObject?.paymentHeading?.receiverDefault &&
                            aemObject?.paymentHeading?.receiverDefault[installments]) ||
                          "Promotion*"}
                      </Body>
                    )}
                  </PromoTag>
                  <MarginSpacerS />
                </>
              )}
              <Title size="medium">
                {heading}
              </Title>
              <MarginSpacerS />
              <RouterPaymentPriceDesc>
                <div style={{ minHeight: "2rem" }}>
                  <Title size="medium" bold style={{lineHeight: "0rem"}}>
                    {formatPriceString(actualPrice)}
                    {!!isPromotion && (
                      <>
                        <span>&nbsp;&nbsp;</span>
                        <Body size="small" strikethrough primitive="span">
                          {formatPriceString(price)}
                        </Body>
                      </>
                    )}
                    {' '}
                    <Body size="small" primitive="span">
                      {isDPP
                        ? t(`4G-5G.${aemKey}.router-content.priceInfoReceiver`, {
                            installments,
                          })
                        : flow === "4g"
                        ? t(`4G-5G.${aemKey}.router-content.perRouter`)
                        : t(`4G-5G.${aemKey}.router-content.priceInfoDesc`)}
                    </Body>
                  </Title>
                </div>
              
                <MarginSpacerM />
                {flow === "4g" && (
                  <Body size="small" bold>
                    {`Total: ${commonPlan?.quantity} ${commonPlan?.quantity > 1 ? "routers" : "router"}`}
                  </Body>
                )}
                {isDPP ? (
                  isPromotion ? (
                    <>
                      <Body color="#6F7171">
                        {t(`4G-5G.${aemKey}.router-content.dppPromoDetail`, {
                          discountAmt,
                          installments,
                        })}
                      </Body>
                    </>
                  ) : (
                    <>
                      <Body color="#6F7171">
                        {t(`4G-5G.${aemKey}.router-content.priceInfoDetail`, {
                          totalPrice,
                          installments,
                        })}
                      </Body>
                    </>
                  )
                ) : (
                  <>
                    {flow === "4g" && (
                      <Body color="#6F7171">
                        {t(`4G-5G.${aemKey}.router-content.totalPlusTaxes`, {
                          totalPrice,
                        })}
                      </Body>
                    )}
                    <Body color="#6F7171">
                      {t(`4G-5G.${aemKey}.router-content.noDPPRequired`)}
                    </Body>
                  </>
                )}
              </RouterPaymentPriceDesc>

              <FourGFiveGPaymentbtn>
                <Button 
                  size="large"
                  disabled={!!selected}
                  use="secondary"
                  width="100%">  
                  {selected ? "Selected" : heading.includes("Pay today") ? "Pay today" : "Pay monthly"}
                </Button>
              </FourGFiveGPaymentbtn>
            </CardContainer>
          </FourGFiveGPaymentBoxWrap>
        }
      />
    </div>
  );
};

PaymentCard.defaultProps = {
  quantity: 1,
};

const StyledBox = styled(RadioBox)`
  label {
    padding:1rem;
    min-height:290px; 
    
    @media only screen and (max-width: 545px) {
      min-height: auto;
      padding-bottom: 70px;
    }



    span {
      height: 100%;
      > div {
        height: 100%;
      }
    }
  }

  border: ${props => (props.selected ? "3px solid #000000" : "1px solid #6f7171")}
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const PromoTag = styled.div`
  width: 100%;
  min-height: 0.75rem;
`;

const RouterPaymentPriceDesc = styled.div`
  h4 {
    line-height:0px;
    margin-top:8px;
  }
  p {
    color:#000000;
  }

  @media only screen and (max-width: 545px) {
    margin-bottom:12px;
  }

`;


const FourGFiveGPaymentBoxWrap = styled.div`
  * {
    letter-spacing:0px !important;
  }
`

const FourGFiveGPaymentbtn = styled.div`
  position: absolute;
  bottom: 1.5rem;
  left: 1rem;
  right: 1rem;
`;


export default PaymentCard;
