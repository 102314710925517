import React from 'react';
import { Notification } from '@vds3/notifications';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true, error };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      // this.props.postError({error, errorInfo})
      // this.props.postUIEventLog({ eventType: 'error', eventName: "Error", message: `${error.toString()}, ${JSON.stringify(errorInfo.componentStack)}, - ${JSON.stringify(this.state.error)}`});
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return 	<Notification
        type="error">
           Something went wrong. Please try again <a onClick={()=> this.props.history.replace({ pathname: "/", state: { prevPage: '/' } })}>  Start again</a>.
      </Notification>;
      }
  
      return this.props.children; 
    }
  }

  export default ErrorBoundary;