import React, { useState, useContext, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
	Accordion,
	AccordionHeader,
	AccordionTitle,
	AccordionSubTitle,
	AccordionItem,
	AccordionDetail,
} from "@vds3/accordions";
import { formatPriceString } from "utils/commonUtils";
import CartContext from "pages/ShoppingCart/cartContext";
import {
	LineItem,
	LineItemPriceGrid,
	EstimatedTax,
	LineItemPriceGridSection,
} from "../LineItemPriceGrid/LineItemPriceGrid";
import { getRetrieveCart5G } from "pages/ShoppingCart/ducks/ShoppingCartSlice";
import {
	getDisplayName,
	getDeviceCategory,
	getDeviceKey,
} from "./../../ItemDescription";
import styled from "styled-components";
import { MarginSpacerM } from "common/components/styleTag";
import SpinnerComponent from "common/components/Spinner/Spinner";

const PATTERN_ZIPCODE = /^\d{5}$/;

const DueToday = ({
	isOpenByDefault,
	topLine = true,
	bottomLine = false,
	...props
}) => {
	const { t } = useTranslation(["shoppingCart"]);
	const dispatch = useDispatch();
	const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);

	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;

	const cartContextData = useContext(CartContext);
	const cartData = sessionCartData ?? cartContextData.cartData;

	const defaultZipCode = cartData?.taxDetails?.taxlocation?.zipCode;
	const dueTodaySummary = cartData?.orderSummaryRetailDueMap || "";
	const multiShippingInfo = cartData?.multiShippingInfo?.[0]?.shippingInfo;
	const shippingDetails = cartData?.multiShipping
		? multiShippingInfo
		: cartData?.shipping;

	const aemCategories =
		t("shopping-cart.cart-category-names", {
			returnObjects: true,
		}) || {};

	const [filteredDueToday, setFilteredDueToday] = useState(dueTodaySummary);
	const [zipCode, setZipcode] = useState("");
	const [isOpened, setOpened] = useState(isOpenByDefault);
	const [zipCodeEdit, setZipCodeEdit] = useState(false);
	const [zipCodeValid, setzipCodeValid] = useState(true);
	const [zipCodeError, setZipCodeError] = useState(false);

	const invalidZipCode = useSelector(
		(state) => state.ShoppingCartData?.invalidZipCode
	);

	const errorText =
		zipCode > 0 && !zipCodeValid
			? "Enter valid Zip Code"
			: "Zip code is required";

	useEffect(() => {
		if (dueTodaySummary && Object.keys(dueTodaySummary)?.length) {
			let filteredValue = {};
			Object.keys(dueTodaySummary)?.forEach((key) => {
				const prevList = filteredValue[getDeviceKey(key)];
				if (prevList) {
					filteredValue[getDeviceKey(key)] = [...prevList, ...dueTodaySummary[key]];
				} else {
					filteredValue[getDeviceKey(key)] = [...dueTodaySummary[key]];
				}
			});
			setFilteredDueToday(filteredValue);
		}
	}, [dueTodaySummary]);

	const displayName = useCallback((deviceRawName, count) => {
		let deviceName = getDisplayName({ description: deviceRawName || "" });
		if (deviceName?.includes('VBGCR1000A')) {
			deviceName = 'VERIZON ROUTER';
		  }
		if (Number(count) > 1) {
			deviceName += `(${count})`;
		}
		return deviceName;
	}, []);

	const displayPrice = useCallback((price) => {
		if (Number(price) > 0) {
			return formatPriceString(price);
		}
		return t("shopping-cart.included");
	}, []);

	useEffect(() => {
		if (defaultZipCode !== zipCode) {
			setZipcode(defaultZipCode);
		}
	}, [defaultZipCode]);

	const onZipCodeChange = (e) => {
		const inputValue = e?.target?.value;
		setZipCodeError(false);
		if (inputValue) {
			setzipCodeValid(PATTERN_ZIPCODE.test(inputValue));
		}
		setZipcode(inputValue);
	};

	useEffect(() => {
		if (invalidZipCode) {
			setZipCodeError(true);
			setZipCodeEdit(true);
		} else {
			setZipCodeError(false);
			setZipCodeEdit(false);
		}
	}, [sessionCartData?.cart]);

	const calculateHandler = () => {
		if (zipCode.length === 0) {
			setZipcode("");
		} else {
			const payload = {
				zipCode: zipCode,
				sourcePath: "ZIPCHANGED",
			};
			dispatch(getRetrieveCart5G(payload));
			setZipCodeEdit(false);
		}
	};

	return (
		<DueTodayWrapper>
			<Accordion
				surface="light"
				topLine={topLine}
				bottomLine={bottomLine}
				id="tootltip-set"
				viewport="desktop"
			>
				<AccordionItem opened={isOpened} data-track="Due Today">
					<AccordionHeader triggerType="icon" onClick={() => setOpened(!isOpened)}>
						<AccordionTitle>
							{t("shopping-cart.cart.dueTodayTotal", {
								total: Number(cartData?.totalAmounts?.dueToday ?? 0).toFixed(2),
							})}
							{globalData?.retrieveLoader ? (
								<SpinnerComponent fullscreen={false} />
							) : (
								<></>
							)}
						</AccordionTitle>
					</AccordionHeader>
					<AccordionDetail>
						<LineItemPriceGrid>
							{Object.keys(filteredDueToday)?.map((key) =>
								filteredDueToday[key]?.length ? (
									<>
										<LineItemPriceGridSection
											title={
												aemCategories[getDeviceCategory(key)] || getDeviceCategory(key)
											}
											key={key}
											letterSpacing={false}
										>
											{filteredDueToday[key]?.map(
												({ itemId, itemName, itemPrice, count }) => (
													<LineItem
														// maxWidth="299"
														displayName={displayName(itemName, count)}
														price={displayPrice(itemPrice)}
														key={itemId}
													/>
												)
											)}
										</LineItemPriceGridSection>
										<MarginSpacerM />
									</>
								) : (
									<></>
								)
							)}
							{!!shippingDetails &&
								Number(cartData?.totalAmounts?.shippingTotal) > 0 && (
									<>
										<LineItem
											displayName={t("shopping-cart.cart-devices.shipping")}
											subText={shippingDetails?.shippingDesc || ""}
											price={
												Number(cartData?.totalAmounts?.shippingTotal) > 0
													? formatPriceString(cartData?.totalAmounts?.shippingTotal)
													: t("shopping-cart.cart-devices.free")
											}
											key="shippingDetails"
										/>
										<MarginSpacerM />
									</>
								)}
							<EstimatedTax
								price={formatPriceString(cartData?.taxDetails?.totalTaxAmount)}
								city={cartData?.taxDetails?.taxlocation?.city}
								state={cartData?.taxDetails?.taxlocation?.state}
								zipCode={zipCode}
								onZipCodeChange={onZipCodeChange}
								setZipCodeEdit={setZipCodeEdit}
								zipCodeEdit={zipCodeEdit}
								zipCodeValid={zipCodeValid}
								calculateHandler={calculateHandler}
								zipCodeError={zipCodeError}
								errorText={errorText}
							/>
						</LineItemPriceGrid>
					</AccordionDetail>
				</AccordionItem>
			</Accordion>
		</DueTodayWrapper>
	);
};

const DueTodayWrapper = styled.div`
	#tootltip-set div.rah-static {
		overflow: unset !important;
	}
`;

export default DueToday;
