import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Input } from "@vds3/inputs";
import { Modal, ModalBody, ModalTitle, ModalFooter } from "@vds3/modals";
import { Body, Title } from "@vds3/typography";
import { MarginSpacerL, MarginSpacerXS } from "common/components/styleTag";
import { postSaveCart } from "app/ducks/GlobalSlice";
import styled from "styled-components";

const StyledInput = styled(Input)`
	input {
		background-color: transparent !important;
	}
`;
const SendCartModal = ({ opened, onOpenedChange, ...props }) => {
	const { t } = useTranslation(["shoppingCart"]);
	const dispatch = useDispatch();

	const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
	const lqData = useSelector((state) => state.ProspectWidgets_LQPage);
	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;

	const [email, setEmail] = useState(
		lqData?.BBALQResp?.emailLQ || sessionCartData?.businessInfo?.email || ""
	);
	const [isSubmit, setIsSubmit] = useState(
		!!(lqData?.BBALQResp?.emailLQ || sessionCartData?.businessInfo?.email)
	);
	const [showSuccessModal, setShowSuccessModal] = useState(false);

	const saveCartSucess = globalData?.saveCartSuccess;
	const success = saveCartSucess?.data?.serviceStatus?.success;
	const cartId = saveCartSucess?.data?.cartId;

	const savecart = t("savecart", {
		returnObjects: true,
	});

	const emailAddressLabel = savecart["email-address-label"];
	const sendBtn = success
		? savecart["resend-btn"] || "Resend"
		: savecart["send-btn"] || "Send";
	const cancelBtn = savecart["cancel-btn"];

	useEffect(() => {
		setShowSuccessModal(true);
		window.dispatchEvent(
			new CustomEvent("QuoteSentSuccessfulFromWidget", {
				detail: {
					quoteId: cartId,
				},
			})
		);
	}, [saveCartSucess]);

	const onSaveClick = (overrideCart) => {
		let sectionLastEntered = ''
		Object.entries(props?.accordionDisplayOrder).map(([key,value])=>{
			if(value === props?.checkoutData?.activeAccordian) sectionLastEntered = key
		})
		const payload = {
			cartMeta: {
				creditApplicationNumber: "",
				locationCode:
					lqData?.BBALQResp?.locationId ||
					globalData?.widgetInputParams?.locationId?.value,
				salesCode:
					lqData?.BBALQResp?.salesCode ||
					globalData?.widgetInputParams?.salesCode?.value,
				email: email,
				sourcePath: "SAVE",
				zipCode: sessionCartData?.businessInfo?.address?.zipCode,
			},
			overrideCart: true,
			cartId: "",
			sendMail: "true",
			shoppingPath: "PROSPECT",
			approach: "",
			flow: lqData.flow,
			currentSectionName: sectionLastEntered
		};
		window.dispatchEvent(
			new CustomEvent("customerEmailInfoFromWidget", {
				detail: {
					customerEmail: email,
				},
			})
		);
		dispatch(postSaveCart(payload));
	};

	const isValidEmail = (key, val) => {
		if (key === "emailID") {
			if (!val?.trim()) {
				setIsSubmit(false);
			} else {
				const pattern =
					/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				pattern.test(val) ? setIsSubmit(true) : setIsSubmit(false);
			}
			setEmail(val);
		}
	};

	const handleClick = () => {
		onOpenedChange(false);
	};

	return (
		<>
			{opened && (
				<Modal
					hideCloseButton={false}
					disableOutsideClick={true}
					onOpenedChange={onOpenedChange}
					opened={opened}
				>
					<ModalTitle>
						<Title size="large" bold>
							Send Cart
						</Title>
					</ModalTitle>
					<MarginSpacerXS />
					<ModalBody>
						<Body size="large">
							This cart is eligible to be completed digitally by customer{" "}
						</Body>

						<MarginSpacerL />
						<InputContainer>
							<StyledInput
								label={emailAddressLabel}
								value={email}
								onChange={(e) => isValidEmail("emailID", e.target.value)}
								success={success}
								successText={`Email sent successfully`}
							/>
						</InputContainer>
					</ModalBody>
					<ModalFooter
						buttonData={{
							primary: {
								children: sendBtn,
								disabled: !isSubmit,
								size: "large",
								onClick: () => {
									onSaveClick(false);
								},
							},
							close: {
								children: cancelBtn,
								size: "large",
								onClick: () => {
									handleClick();
								},
							},
						}}
					/>
				</Modal>
			)}
		</>
	);
};

const InputContainer = styled.div`
	max-width: 328px;
	@media only screen and (max-width: 528px) {
		max-width: 100%;
	}
`;

export default SendCartModal;
