import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//import { useNavigate } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";
import { Button } from "@vds3/buttons";
import { Grid, Row, Col } from "@vds3/grids";
import { Input } from "@vds3/inputs";
import { Input as InputVDS1 } from "@vds3/inputs";
import { Notification } from "@vds3/notifications";
import { RadioButtonGroup } from "@vds3/radio-buttons";
import { Body, Title } from "@vds3/typography";
import OrderSummary from "pages/ShoppingCart/components/OrderSummary/OrderSummary";
import SpinnerComponent from "common/components/Spinner/Spinner";
//import { IdleTimer } from "../../../../common/components/IdleTimer/IdleTimer";
//import Header from "./../Header/Header";
import StickyHeader from "common/components/StickyHeader/StickyHeaderVds3";
import MapQStreetAddress from "../ShippingInfo/components/MapQStreetAdress";
import {
	postInitializePayment,
	postValidateAndSaveCard,
	doUpdatePymtCounterFail,
	setAddress,
	setAddressType,
	setCardHolderName,
	setCardNumber,
	setCardType,
	setCvv,
	setExpirationDate,
	setPaymentInfo,
	setErrorResponse,
	setNotification,
} from "./ducks/PaymentSlice";
import "./Payment.css";
import { setOosDevices } from "../ReviewOrder/ducks/ReviewSlice";
import {
	checkAddressValidation,
	getSessionCart,
	setActiveAccordian,
	setAddressValidationInfo,
} from "pages/Checkout/ducks/CheckoutSlice";
import MaxExceededModal from "./MaxExceededModal";
import SessionCartId from "common/components/SessionCartId/SessionCartId";
import { MarginSpacerM } from "common/components/styleTag";
import { addScrollView } from "utils/commonUtils";
import { DeviceDataCollector } from "../DeviceDataCollector/DeviceDataCollector";

const StyleRadioButtonGroup = styled(RadioButtonGroup)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const StyledSpan = styled.span`
	> a {
		color: black;
	}
`;

function Payment(props) {
	const { t, i18n } = useTranslation(["checkout"]);
	const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
	const checkoutPageData = useSelector(
		(state) => state.ProspectWidgets_CheckoutData
	);
	const paymentPageData = useSelector(
		(state) => state.ProspectWidgets_PaymentData
	);
	const orderSuccessDetails = useSelector(
		(state) => state.ProspectWidgets_ReviewData?.orderSuccessDetails
	);
	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	const sddSlot = sessionCartData?.shipping?.selectedSlot?.deliverySlot;
	const dueTodayZero = sessionCartData?.totalAmounts?.dueToday !== 0;
	const [errors, setErrors] = useState({});
	const [isNewAddress, setIsNewAddress] = useState(false);
	const [showAddrsErr, setShowAddrsErr] = useState(false);
	const [continueClick, setContinueClick] = useState(false);
	const [businessAddressValue, setBusinessAddressValue] = useState('');
  	const [homeAddressValue, setHomeAddressValue] = useState('');
	const dispatch = useDispatch();
	//const navigate = useNavigate();
	const addressRef = useRef();
	let cardType;
	const assetsBaseUrl = process.env.PROSPECT_WIDGETS_APP_ASSESTS_BASE_URL;
	const businessAddress = sessionCartData?.businessInfo?.address;
	const homeAddress = sessionCartData?.businessInfo?.pocAddress;
	const [showModal, setShowModal] = useState(false);
	const paymentAem = t("payment", { ns: "checkout", returnObjects: true });
	const addressConfirmaionContent = t("verification.addressConfirmationModal", {
		ns: "checkout",
		returnObjects: true,
	});
	useEffect(() => {
		dispatch(getSessionCart());
		window?.Cardinal?.configure({
			logging: {
				level: "on",
			},
		});
		dispatch(postInitializePayment());
		dispatch(setOosDevices([]));
		const address = paymentPageData?.address;
		if (!address || !Object.keys(address)?.length) {
			let addr = {};
			switch (paymentPageData?.addressType) {
				case "BUSINESS":
					addr = { addressType: "BUSINESS", ...businessAddress };
					break;
				case "HOME":
					addr = { addressType: "HOME", ...homeAddress };
					break;
				default:
					addr = {
						addressType: "",
						addressLine1: "",
						addressLine2: "",
						city: "",
						state: "",
						zipCode: "",
					};
			}
			dispatch(setAddress(addr));
		}
		setIsNewAddress(
			paymentPageData?.addressType !== "BUSINESS" && paymentPageData !== "HOME"
		);
		if (businessAddress) {
			const { addressLine1, city, state, zipCode } = businessAddress;
			const tempBusinessAdress = `${addressLine1}, ${city}, ${state} ${zipCode}`;
			setBusinessAddressValue(tempBusinessAdress);
		  }
		  if (homeAddress) {
			const { addressLine1, city, state, zipCode } = homeAddress;
			const tempHomeAdress = `${addressLine1}, ${city}, ${state} ${zipCode}`;
			setHomeAddressValue(tempHomeAdress);
		  }
		  addScrollView("paymanet-page-accor")
	}, []);

	useEffect(() => {
		if (!sessionCartData?.cart?.packages) {
			//navigate("/shop/products/devices/smartphones");
		}
		if (
			sessionCartData?.cart?.packages &&
			Object.keys(sessionCartData?.cart?.packages).length &&
			sessionCartData?.showPaymentPage === false
		) {
			// navigate("/shop/shopping-cart");
		}
	}, [sessionCartData]);

	useEffect(() => {
		if (isEmpty(window?.Cardinal)) {
			const notification = {
				show: true,
				subtitle: "",
				title: t("payment.notifications.paymentScriptNotLoaded.title", {
					ns: "checkout",
				}),
				type: "error",
			};
			dispatch(setNotification(notification));
		} else if (
			sessionCartData?.showZeroDueTodayMessage &&
			!isEmpty(window?.Cardinal)
		) {
			const notification = {
				show: true,
				subtitle: t("payment.notifications.zeroDueTodayMessage.subtitle", {
					ns: "checkout",
				}),
				title: t("payment.notifications.zeroDueTodayMessage.title", {
					ns: "checkout",
				}),
				type: "info",
			};
			dispatch(setNotification(notification));
		} else {
			const notification = {
				show: false,
				subtitle: "",
				title: "",
				type: "info",
			};
			dispatch(setNotification(notification));
		}
	}, [window?.Cardinal, sessionCartData?.showZeroDueTodayMessage]);

	useEffect(() => {
		if (
			paymentPageData?.paymentInfo?.stage === "stage1:INITIALIZE_PAYMENT" &&
			paymentPageData?.paymentInfo?.status === "SUCCESS"
		) {
			window?.Cardinal?.on("payments.validated", function (data, jwt) {
				switch (data.ActionCode) {
					case "NOACTION":
						if (data.Payment.ExtendedData.SignatureVerification === "N") {
							dispatch(
								setPaymentInfo({
									...paymentPageData?.paymentInfo,
									stage: "stage3:3D_SECURE",
									status: "FAILED",
									stage3: { ...data },
									currentStage: "stage3",
									currentStatus: "FAILED",
									errorStatusCode:
										data.serviceStatus?.statusCode || "PAYMENT_ERROR_GENERIC",
								})
							);
							dispatch(doUpdatePymtCounterFail());
						} else {
							dispatch(
								setPaymentInfo({
									...paymentPageData?.paymentInfo,
									stage: "stage3:3D_SECURE",
									status: "SUCCESS",
									stage3: { ...data },
									currentStage: "stage3",
									currentStatus: "SUCCESS",
								})
							);
							dispatch(postValidateAndSaveCard({ jwt: jwt }));
						}
						break;
					case "SUCCESS":
						dispatch(
							setPaymentInfo({
								...paymentPageData?.paymentInfo,
								stage: "stage3:3D_SECURE",
								status: "SUCCESS",
								stage3: { ...data },
								currentStage: "stage3",
								currentStatus: "SUCCESS",
							})
						);
						dispatch(postValidateAndSaveCard({ jwt: jwt }));
						break;
					case "FAILURE":
						dispatch(
							setPaymentInfo({
								...paymentPageData?.paymentInfo,
								stage: "stage3:3D_SECURE",
								status: "FAILED",
								stage3: { ...data },
								currentStage: "stage3",
								currentStatus: "FAILED",
								errorStatusCode:
									data.serviceStatus?.statusCode ||
									"PAYMENT_ERROR_FAILED_AUTHENTICATION",
							})
						);
						dispatch(doUpdatePymtCounterFail());
						break;
					case "ERROR":
						dispatch(
							setPaymentInfo({
								...paymentPageData?.paymentInfo,
								stage: "stage3:3D_SECURE",
								status: "FAILED",
								stage3: { ...data },
								currentStage: "stage3",
								currentStatus: "FAILED",
								errorStatusCode:
									data.serviceStatus?.statusCode || "PAYMENT_ERROR_GENERIC",
							})
						);
						dispatch(doUpdatePymtCounterFail());
						break;
				}
			});
		} else if (
			paymentPageData?.paymentInfo?.stage === "stage4:FULL_AUTH" &&
			paymentPageData?.paymentInfo?.status === "SUCCESS"
		) {
			dispatch(
				setActiveAccordian(
					checkoutPageData?.activeAccordionDisplayOrder?.orderConfirmation
				)
			);
			// if (window['digitalData'].isUniqBOS) {
			//   SiteAnalytics.eventOrderInfoTracking(
			//     "Unified Business Order Submitted",
			//     checkoutPageData?.fetchCart,
			//     "review and submit",
			//     "",
			//     orderSuccessDetails?.submitOrderResponse
			//   );
			// }
			//navigate("/shop/confirmation");
		}
	}, [paymentPageData?.paymentInfo]);

	useEffect(() => {
		if (paymentPageData?.errorResponse) {
			dispatch(setErrorResponse(false));
			if (
				paymentPageData?.paymentInfo?.errorStatusCode ===
					"PAYMENT_BILLING_ADDRESS_VALIDATION_FAILED" ||
				paymentPageData?.validateAndSaveCardResponse?.statusCode ===
					"PAYMENT_BILLING_ADDRESS_VALIDATION_FAILED"
			) {
				const notification = {
					show: true,
					subtitle: "",
					title: t(
						"payment.paymentErrorMessages.PAYMENT_BILLING_ADDRESS_VALIDATION_FAILED",
						{
							ns: "checkout",
						}
					),
					type: "error",
				};
				dispatch(setNotification(notification));
			} else if (
				paymentPageData?.paymentInfo?.errorStatusCode ===
					"PAYMENT_ERROR_FAILED_AUTHENTICATION" ||
				paymentPageData?.validateAndSaveCardResponse?.statusCode ===
					"PAYMENT_ERROR_FAILED_AUTHENTICATION"
			) {
				const notification = {
					show: true,
					subtitle: "",
					title: t(
						"payment.paymentErrorMessages.PAYMENT_ERROR_FAILED_AUTHENTICATION",
						{
							ns: "checkout",
						}
					),
					type: "error",
				};
				dispatch(setNotification(notification));
			} else if (
				paymentPageData?.paymentInfo?.errorStatusCode ===
					"PAYMENT_ERROR_MAXIMUM_ATTEMPTS_EXHAUSTED" ||
				paymentPageData?.validateAndSaveCardResponse?.statusCode ===
					"PAYMENT_ERROR_MAXIMUM_ATTEMPTS_EXHAUSTED"
			) {
				const notification = {
					show: true,
					subtitle: "",
					title: t(
						"payment.paymentErrorMessages.PAYMENT_ERROR_MAXIMUM_ATTEMPTS_EXHAUSTED",
						{
							ns: "checkout",
						}
					),
					type: "error",
				};
				setShowModal(true);
				dispatch(setNotification(notification));
			} else if (
				paymentPageData?.paymentInfo?.errorStatusCode === "PAYMENT_ERROR_GENERIC" ||
				paymentPageData?.validateAndSaveCardResponse?.statusCode ===
					"PAYMENT_ERROR_GENERIC"
			) {
				const notification = {
					show: true,
					subtitle: "",
					title: t("payment.paymentErrorMessages.PAYMENT_ERROR_GENERIC", {
						ns: "checkout",
					}),
					type: "error",
				};
				dispatch(setNotification(notification));
			} else if (
				paymentPageData?.paymentInfo?.errorStatusCode ===
					"PAYMENT_ERROR_SAFETECH_VERIFY_DECLINE" ||
				paymentPageData?.validateAndSaveCardResponse?.statusCode ===
					"PAYMENT_ERROR_SAFETECH_VERIFY_DECLINE"
			) {
				const notification = {
					show: true,
					subtitle: "",
					title: t(
						"payment.paymentErrorMessages.PAYMENT_ERROR_SAFETECH_VERIFY_DECLINE",
						{
							ns: "checkout",
						}
					),
					type: "error",
				};
				dispatch(setNotification(notification));
			} else if (
				paymentPageData?.paymentInfo?.errorStatusCode ===
					"PAYMENT_ERROR_CREDIT_FUND_NOTAVAIL" ||
				paymentPageData?.validateAndSaveCardResponse?.statusCode ===
					"PAYMENT_ERROR_CREDIT_FUND_NOTAVAIL"
			) {
				const notification = {
					show: true,
					subtitle: "",
					title: t(
						"payment.paymentErrorMessages.PAYMENT_ERROR_CREDIT_FUND_NOTAVAIL",
						{
							ns: "checkout",
						}
					),
					type: "error",
				};
				dispatch(setNotification(notification));
			} else if (
				paymentPageData?.paymentInfo?.errorStatusCode ===
					"AUTOMATION_STEPS_INCOMPLETE" ||
				paymentPageData?.validateAndSaveCardResponse?.serviceStatus === false
			) {
				const notification = {
					show: true,
					subtitle: "",
					title: "Something went wrong.",
					type: "error",
				};
				dispatch(setNotification(notification));
			}
		}
	}, [paymentPageData?.errorResponse]);

	const hasErrors = () => {
		let error = false;
		for (const key of Object.keys(errors)) {
			if (errors[key] && errors[key].length > 0) {
				error = true;
			} else if (
				isNewAddress &&
				(!paymentPageData?.address?.addressLine1 ||
					!paymentPageData?.address?.city ||
					!paymentPageData?.address?.state ||
					!paymentPageData?.address?.zipCode)
			) {
				error = true;
			}
		}

		if (
			!paymentPageData.cardNumber ||
			!paymentPageData.expirationDate ||
			!paymentPageData.cardHolderName ||
			!paymentPageData.cvv ||
			!paymentPageData.address
		) {
			error = true;
		}
		return error;
	};
	// const validateBusinessAddress = () => {
	// 	setShowAddrsErr(false);
	// 	let payload;
	// 	if (paymentPageData?.address?.addressLine1) {
	// 		payload = {
	// 			state: paymentPageData?.address?.state,
	// 			addressLine1: paymentPageData?.address?.addressLine1,
	// 			addressLine2: "",
	// 			city: paymentPageData?.address?.city,
	// 			zipCode: paymentPageData?.address?.zipCode,
	// 		};
	// 	}
	// 	dispatch(checkAddressValidation(payload));
	// 	setContinueClick(true);
	// };

	// useEffect(() => {
	// 	if (continueClick) {
	// 		setContinueClick(false);
	// 		if (checkoutPageData?.addressValidationInfo?.serviceStatus?.success) {
	// 			setShowAddrsErr(false);
	// 			// handleAddressSelection(temp);
	// 			onClickCheck();
	// 		} else if (
	// 			!checkoutPageData?.addressValidationInfo?.serviceStatus?.success
	// 		) {
	// 			setShowAddrsErr(true);
	// 		}
	// 		dispatch(setAddressValidationInfo(null));
	// 	}
	// }, [checkoutPageData?.addressValidationInfo?.serviceStatus]);

	const onClickCheck = () => {
		const tokenjwt = "";
		//Check expiration date is in correct format.
		if (paymentPageData?.expirationDate?.replace("/", "").length < 4) {
			const errorTexts =
				t("payment.expirationDateInput.errors.invalidExpirationdate", {
					ns: "checkout",
				}) || "";
			setErrors({ ...errors, expirationDate: errorTexts });
			return;
		}
		if (hasErrors()) {
			return;
		}
		dispatch(postValidateAndSaveCard({ jwt: tokenjwt }));
	};

	const detectCardType = (value) => {
		const regExVisa = new RegExp("^4"),
			regExAmex = new RegExp("^3[47]"),
			regExMaster = new RegExp("^(5[1-5]|222[1-9]|2[3-6])|27[0-2]"),
			regExDisc = new RegExp("^(6011|65|64[4-9])");
		if (regExVisa.test(value)) {
			return "visa";
		} else if (regExAmex.test(value)) {
			return "amex";
		} else if (regExMaster.test(value)) {
			return "mastercard";
		} else if (regExDisc.test(value)) {
			return "discover";
		} else {
			return "none";
		}
	};

	const isValidPaymentInfoField = (key, e) => {
		let errorTexts = "";
		let val = e?.target?.value;
		// card number
		if (key === "cardNumber") {
			if (val >= 4) {
				cardType = detectCardType(val);
				dispatch(setCardType(cardType));
			} else {
				dispatch(setCardType("none"));
			}
			let maxlength = cardType === "amex" ? 15 : 16;
			val = e.target.value
				.replace(/[^0-9]/g, "")
				.replace(
					cardType === "amex"
						? /(\d{4})(\d{4})(\d{4})(\d{3})/
						: /(\d{4})(\d{4})(\d{4})(\d{4})/,
					"$1 $2 $3 $4"
				);
			errorTexts =
				!val || !val?.trim() || val?.trim().length < maxlength
					? t("payment.cardNumberInput.errors.invalid", { ns: "checkout" })
					: "";
			const cardVal = val?.trim()?.replace(/[^0-9]/g, "");
			if (cardVal.length === maxlength) {
				window?.Cardinal?.trigger("bin.process", cardVal?.substring(0, 6));
			}
			dispatch(setCardNumber(val));
			dispatch(setExpirationDate(""));
			dispatch(setCardHolderName(""));
			dispatch(setCvv(""));
		}

		// expiration date
		if (key === "expirationDate") {
			if (!val || !val.trim()) {
				errorTexts = t(
					"payment.expirationDateInput.errors.requiredExpirationDate",
					{
						ns: "checkout",
					}
				);
			} else {
				val = e.target.value.replace(/[^0-9]/g, "");

				if (e.target.value === "/") {
					val = " ";
				}

				if (val.length < 5 && val.length > 0) {
					let temp = val.match(/.{1,2}/g).join("/");
					val = temp;
				}

				if (val?.trim() && val?.trim().length > 0) {
					if (val?.trim().length === 2) {
						val += "/";
					}
				}

				if (val.length === 5) {
					let temp = val.split("/");
					const date = new Date();
					const currentMonth = date.getMonth() + 1;
					const currentYear = String(date.getFullYear()).slice(-2);
					if (
						Number(temp[0]) > 12 ||
						(Number(temp[0]) < Number(currentMonth) &&
							Number(temp[1]) === Number(currentYear))
					) {
						dispatch(setExpirationDate(""));
						val = "";
					}
					if (Number(temp[1]) < Number(currentYear)) {
						dispatch(setExpirationDate(""));
						val = "";
					}
				}

				errorTexts =
					!val || !val?.trim()
						? t("payment.expirationDateInput.errors.invalidExpirationdate", {
								ns: "checkout",
						  })
						: "";
			}
			dispatch(setExpirationDate(val));
		}

		// card holder name
		if (key === "cardHolderName") {
			if (!val?.trim()) {
				errorTexts = t("payment.cardholderNameInput.errors.invalidCardHolderName", {
					ns: "checkout",
				});
			} else {
				const pattern = /^[a-zA-Z-\s]+$/;
				if (!pattern.test(val)) {
					errorTexts = t(
						"payment.cardholderNameInput.errors.invalidCardHolderName",
						{ ns: "checkout" }
					);
				} else {
					errorTexts = "";
				}
			}
			dispatch(setCardHolderName(val));
		}

		// cvv input
		if (key === "cvv") {
			val = e.target.value.replace(/[^0-9]/g, "");
			let cvvMaxlength = paymentPageData.cardType === "amex" ? 4 : 3;
			errorTexts =
				!val || !val?.trim() || val?.trim().length < cvvMaxlength
					? t("payment.CVVInput.errors.invalidLength", {
							length: cvvMaxlength,
							ns: "checkout",
					  })
					: "";
			dispatch(setCvv(val));
		}

		// address line 1
		if (key === "addresssel") {
			errorTexts = !e ? `Address is required.` : "";
			const addr = {
				addressLine1: e?.place?.properties?.street ?? e?.target?.value ?? "",
				addressLine2: paymentPageData.address?.addressLine2 ?? "",
				city: e?.place?.properties?.city!==null&&e?.place?.properties?.city ? e?.place?.properties?.city : paymentPageData.address?.city ?? "",
				state: e?.place?.properties?.stateCode!==null&&e?.place?.properties?.stateCode ? e?.place?.properties?.stateCode : paymentPageData.address?.state ?? "",
				zipCode: e?.place?.properties?.postalCode!==null&&e?.place?.properties?.postalCode ? e?.place?.properties?.postalCode?.substring(0, 5) : paymentPageData.address?.zipCodes ?? "",
			};
			if (!!e?.place?.properties.street || !!e?.target?.value) {
				setErrors({
					...errors,
					address: "Address is required",
				});
			}
			dispatch(setAddress(addr));
		}
		if (key === "address") {
			errorTexts = !e ? `Address is required.` : "";
			const addr = {
				addressLine1: e?.place?.properties?.street ?? e?.target?.value ?? "",

				city: paymentPageData?.address?.city ?? "",
				state: paymentPageData?.address?.state ?? "",
				zipCode: paymentPageData?.address?.zipCode ?? "",
			};
			if (!!e?.place?.properties.street || !!e?.target?.value) {
				setErrors({
					...errors,
					address: "Address is required",
				});
			}
			dispatch(setAddress(addr));
		}

		setErrors({ ...errors, [key]: errorTexts });
	};

	const onChangeAddressType = (e) => {
		setErrors({ ...errors, address: null });

		const addressType = e.target.value;
		let address = {
			addressType: "",
			addressLine1: "",
			addressLine2: "",
			city: "",
			state: "",
			zipCode: "",
		};

		if (addressType === "BUSINESS") {
			setIsNewAddress(false);
			address = { addressType: address.addressType, ...businessAddress };
		}
		if (addressType === "HOME") {
			setIsNewAddress(false);
			address = { addressType: address.addressType, ...homeAddress };
		}
		if (addressType === "NEW") {
			setIsNewAddress(true);
		}
		dispatch(setAddressType(addressType));
		dispatch(setAddress(address));
	};

	const handleAddressInput = (e, name) => {
		const value = e.target.value;
		const { ...address } = paymentPageData.address;
		if (name === "city") {
			address.city = value;
			if (!value) {
				setErrors({
					...errors,
					city: "City is required",
				});
			} else {
				setErrors({
					...errors,
					city: null,
				});
			}
		} else if (name === "state") {
			const PATTERN_STATE = /^[a-zA-Z]+$/.test(value);
			if (value.length > 5) {
				return;
			}
			address.state = value;
			if (!value) {
				setErrors({
					...errors,
					state: "State is required",
				});
			} else if (!PATTERN_STATE) {
				setErrors({
					...errors,
					state: "State code is invalid",
				});
			} else {
				setErrors({
					...errors,
					state: null,
				});
			}
		} else if (name === "zipCode") {
			const PATTERN_ZIPCODE = /^\d{5}(-\d{4})?$/.test(value);
			if (value.length > 9) {
				return;
			}
			address.zipCode = value;
			if (!value) {
				setErrors({
					...errors,
					zipCode: "Zip code is required",
				});
			} else if (!PATTERN_ZIPCODE) {
				setErrors({
					...errors,
					zipCode: "Zip code is invalid",
				});
			} else {
				setErrors({
					...errors,
					zipCode: null,
				});
			}
		}
		dispatch(setAddress(address));
	};

	const formatDisplayAddress = (address) => {
		return address
			? `${address?.addressLine1}, ${address?.city}, ${address?.state}, ${address?.zipCode}`
			: "";
	};
	const businessAdressChildren = () => {
		return (
		  <div>
			{"Same as business address"}<br />
			{/* <Body size="medium">{businessAddressValue}</Body> */}
			{businessAddressValue ? businessAddressValue : ''}
		  </div>
		)
	  }
	
	  const HomeAdressChildren = () => {
		return (
		  <div>
			{t("payment.homeAddress", {
			  ns: "checkout",
			})}<br />
			{/* <Body size="medium">{homeAddressValue}</Body> */}
			{homeAddressValue ? homeAddressValue : ''}
		  </div>
		)
	  }
	return (
		<div className="paymanet-page-accor">
			{paymentPageData?.loading && <SpinnerComponent />}
			{/* {showModal && (
				<MaxExceededModal showModal={showModal} setShowModal={setShowModal} />
			)} */}
			{/* <StickyHeader
        continueBtnText={t("payment.makePayment", { ns: "payment" })}
        disabledContinueBtn={hasErrors()}
        onContinue={onClickCheck}
        hideContinueButton={true}
        hideShoppingBagIcon={true}
      /> */}
			<Grid rowGutter="1rem" colGutter="1rem">
				<Row>
					<Col colSizes={{ mobile: 4, tablet: 9, desktop: 9 }}>
						<div className="paymentInfo">
							<div className="container">
								<form
									onSubmit={(e) => {
										e.preventDefault();
									}}
									autoComplete="off"
								>
									<Grid
										colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
										colGutter={false}
										rowGutter="5"
									>
										<Row>
											<Col colSizes={{ mobile: 4, tablet: 8, desktop: 8 }}>
												<div className="Prod-Discription">
													<Title size="large" primitive="h1" bold>
														{t("payment.paymentMethod", { ns: "checkout" })}
													</Title>
												</div>
												<div className="required-field">
													<Body size="small" primitive="p">
														{t("payment.infomsg1", {
															ns: "checkout",
														})}
													</Body>
												</div>
											</Col>
											{/* <Col colSizes={{ mobile: 4, tablet: 4, desktop: 4 }}><ChatLink /></Col> */}
										</Row>
										<Row>
											<Col colSizes={{ mobile: 3, tablet: 6, desktop: 6 }}>
												<div
													className="form-col"
													style={{
														position: "relative",
														marginBottom: "0.5rem",
													}}
												>
													<Input
														label={`${t("payment.cardNumber", {
															ns: "checkout",
														})}*`}
														maxLength={16}
														placeholder={`${t("payment.cardNumber", {
															ns: "checkout",
														})}`}
														name="cardNumber"
														width={"100%"}
														value={paymentPageData.cardNumber}
														onChange={(e) => isValidPaymentInfoField("cardNumber", e)}
														error={errors.cardNumber?.length > 0}
														errorText={errors.cardNumber}
													/>
													<img
														className="selected-card"
														src={`${assetsBaseUrl}/assets/images/payment/${
															paymentPageData.cardType === "none"
																? "cardPlaceholder"
																: paymentPageData.cardType === "mastercard"
																? "master"
																: paymentPageData.cardType
														}.png`}
														alt="selected card image"
													/>
												</div>
												<div className="card-images-wrapper">
													<div className="card-images">
														<img
															className="visa"
															src={`${assetsBaseUrl}/assets/images/payment/visa.png`}
															alt="Visa card image"
														/>
														<img
															className="master"
															src={`${assetsBaseUrl}/assets/images/payment/master.png`}
															alt="Master card image"
														/>
														<img
															className="amex"
															src={`${assetsBaseUrl}/assets/images/payment/amex.png`}
															alt="Amex card image"
														/>
														<img
															className="discover"
															src={`${assetsBaseUrl}/assets/images/payment/discover.png`}
															alt="Discover card image"
														/>
													</div>
												</div>
											</Col>
											<Col colSizes={{ mobile: 1, tablet: 6, desktop: 6 }}>
												<img
													src={`${assetsBaseUrl}/assets/images/payment/norton_secure_seal.png`}
													className="norton-secure-seal"
													alt="selected card image"
												/>
											</Col>
										</Row>
										<Row>
											<Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
												<div className="form-col">
													<InputVDS1
														label={`${t("payment.expirationDateInput.label", {
															ns: "checkout",
														})}*`}
														maxLength={5}
														type="date"
														dateFormat="mmyy"
														placeholder={"MM/YY"}
														name="expirationDate"
														width={"100%"}
														onKeyDown={(e) => {
															if (e?.key === "Backspace") {
																if (e?.target?.value?.length === 3) {
																	let val = e?.target?.value;
																	val = val?.split("/")[0] || "";
																	dispatch(setExpirationDate(val || ""));
																}
															} else if (e?.key === "Delete") {
																dispatch(setExpirationDate(""));
															}
														}}
														value={paymentPageData.expirationDate}
														onChange={(e) => isValidPaymentInfoField("expirationDate", e)}
														error={errors.expirationDate?.length > 0}
														errorText={
															errors.expirationDate ||
															`${t(
																"payment.expirationDateInput.errors.requiredExpirationDate",
																{
																	ns: "checkout",
																}
															)}`
														}
													/>
												</div>
											</Col>
										</Row>
										<Row>
											<Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
												<div className="form-col">
													<Input
														label={`${t("payment.cardholderNameInput.label", {
															ns: "checkout",
														})}*`}
														maxLength={64}
														placeholder=""
														name="cardHolderName"
														width={"100%"}
														value={paymentPageData.cardHolderName}
														onChange={(e) => isValidPaymentInfoField("cardHolderName", e)}
														error={errors.cardHolderName?.length > 0}
														errorText={errors.cardHolderName}
													/>
												</div>
											</Col>
										</Row>
										<Row>
											<Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
												<div className="form-col" style={{ marginBottom: "2rem" }}>
													<Input
														label={`${t("payment.CVVInput.label", {
															ns: "checkout",
														})}*`}
														maxLength={paymentPageData.cardType === "amex" ? 4 : 3}
														type="password"
														placeholder={`${t("payment.CVVInput.label", {
															ns: "checkout",
														})}`}
														name="cvv"
														width={"50%"}
														value={paymentPageData.cvv}
														tooltipContent={t("payment.CVVInput.tooltip.content", {
															ns: "checkout",
														})}
														onChange={(e) => isValidPaymentInfoField("cvv", e)}
														error={errors.cvv?.length > 0}
														errorText={errors.cvv}
													/>
												</div>
											</Col>
										</Row>
									</Grid>
									<hr />
									<Grid
										colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
										colGutter={false}
										rowGutter="5"
									>
										<Row>
											<Col>
												<div className="billing-address">
													<Body primitive="h1" bold>
														{t("payment.billingAddress", { ns: "checkout" })}
													</Body>
												</div>
											</Col>
										</Row>
										<Row>
											<div className="addressOptionsSection" style={{ marginTop: "1rem" }}>
												<div className="checkboxgrp">
													<StyleRadioButtonGroup
														selected={paymentPageData.addressType}
														onChange={(e) => {
															onChangeAddressType(e);
														}}
														defaultValue={paymentPageData.addressType}
														data={[
															{
																name: "sameAsCompany",
																children: businessAdressChildren(),
																value: "BUSINESS",
															},
															{
																name: "sameAsHome",
																children: HomeAdressChildren(),
																value: "HOME",
															},
															{
																name: "newAddress",
																children: t("payment.newAddress", {
																	ns: "checkout",
																}),
																value: "NEW",
															},
														]}
													/>
												</div>
											</div>
										</Row>
										{isNewAddress ? (
										<Row className="address-section">
											<Col colSizes={{ mobile: 4, tablet: 6, desktop: 4 }}>
												
													<div className="address-container form-col">
														<MapQStreetAddress
															isNewAddress={isNewAddress}
															errorMessage="Address is required."
															isError={errors.address?.length > 0}
															label="Address line 1*"
															handleAddressInput={(value, street) => {
																isValidPaymentInfoField("address", value);
															}}
															isAddressEmty={false}
															onSelectionChange={(value) =>
																isValidPaymentInfoField("addresssel", value)
															}
															ref={addressRef}
															setAddressFieldError={() => {}}
															isNameOnly
														/>
													</div>
											</Col>
											<Col colSizes={{ mobile: 4, tablet: 6, desktop: 5 }}>
												<div className="form-col">
													<Input
														disabled={!isNewAddress}
														type="text"
														label="Address line 2"
														placeholder="Enter Unit number"
														required={false}
														value={paymentPageData?.address?.addressLine2}
														onChange={(e) => handleAddressInput(e, "addressLine2")}
													/>
												</div>
											</Col>
										</Row>):<></>}
										{isNewAddress ? (
										<Row className="address-section">
											<Col colSizes={{ mobile: 4, tablet: 6, desktop: 4 }}>
												<div className="form-col">
													<Input
														type="text"
														name="city"
														label="City*"
														placeholder="Enter City"
														disabled={!isNewAddress}
														error={!!errors.city}
														errorText={errors.city}
														required={true}
														value={paymentPageData?.address?.city}
														onChange={(e) => handleAddressInput(e, "city")}
													/>
												</div>
											</Col>
											<Col colSizes={{ mobile: 4, tablet: 6, desktop: 3 }}>
												<div className="form-col">
													<Input
														type="text"
														name="state"
														label="State*"
														placeholder="Enter State"
														disabled={!isNewAddress}
														error={!!errors.state}
														errorText={errors.state}
														required={true}
														value={paymentPageData?.address?.state}
														onChange={(e) => handleAddressInput(e, "state")}
													/>
												</div>
											</Col>
											<Col colSizes={{ mobile: 4, tablet: 6, desktop: 2 }}>
												<div className="form-col">
													<Input
														type="text"
														name="zipCode"
														label="Zip Code*"
														placeholder="Enter Zip Code"
														disabled={!isNewAddress}
														error={!!errors.zipCode}
														errorText={errors.zipCode}
														required={true}
														value={paymentPageData?.address?.zipCode}
														onChange={(e) => handleAddressInput(e, "zipCode")}
													/>
												</div>
											</Col>
										</Row>):<></>}
										<Row className="payment-submit-section">
											<Col colSizes={{ mobile: 4, tablet: 9, desktop: 9 }}>
												<div
													className="continue-btn"
													style={{ marginTop: "1rem", marginBottom: "1rem" }}
												>
													{showAddrsErr && (
														<Notification
															type="error"
															title={addressConfirmaionContent?.widigetNotificationErr}
															fullBleed={true}
															inlineTreatment={false}
														/>
													)}
													<MarginSpacerM />
													{(paymentPageData?.notification?.show || !dueTodayZero) && (
														<Notification
															className="payment-page-notification"
															fullBleed={true}
															subtitle={paymentPageData?.notification?.subtitle}
															title={paymentPageData?.notification?.title}
															type={paymentPageData?.notification?.type}
														/>
													)}
													<MarginSpacerM/>
													<Button onClick={onClickCheck} disabled={hasErrors()}>
														{t("payment.makePayment", { ns: "checkout" })}
													</Button>
												</div>
											</Col>
										</Row>
										<Row>
											<Col>
												<div className="Prod-Discription">
													<Body size="small" primitive="h3">
														{t("payment.disclaimer", { ns: "checkout" })}
													</Body>
												</div>
												{paymentAem?.privacyDisclaimer && (
													<div className="Prod-Discription">
														<Body size="small" primitive="h3">
															<StyledSpan
																dangerouslySetInnerHTML={{
																	__html: paymentAem?.privacyDisclaimer,
																}}
															></StyledSpan>
														</Body>
													</div>
												)}
											</Col>
											{/* {sessionCartData?.cart?.cartId &&
                       <SessionCartId sessionCartData={sessionCartData}
                      />} */}
										</Row>
									</Grid>
								</form>
							</div>
						</div>
					</Col>
					<Col
						colSizes={{ mobile: 4, tablet: 3, desktop: 3 }}
						className="noSidePadding"
					>
						<OrderSummary defaultOpenAccordions={false} isShoppingCart={false} excludeFooterContent = {true} />{" "}
					</Col>
				</Row>
			</Grid>
			{/* <IdleTimer /> */}
			<DeviceDataCollector />
		</div>
	);
}

export default Payment;
