import * as CONSTANTS from './constants'

export const InitialState = {
  businessStreetAddress: '',
  companyAddressUnit: '',
  companyName: "",
  phoneNumber: "",
  corporation: {
    fedTaxId: '',
    taxIdNumber: '',
  },
  errorTexts: {
    companyName: "",
    phoneNumber: "",
    corporation: {}
  },
  accountNumber:'',
  errors: {}
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ERROR_TEXTS':
      return { ...state, errorTexts: action.payload };
    case 'SET_CORPORATION':
      return { ...state, corporation: action.payload };
    case CONSTANTS.SET_BUSINESS_STREET_ADDRESS:
      return {
        ...state,
        businessStreetAddress: action.payload
      };
    case CONSTANTS.SET_COMPANY_NAME:
      return {
        ...state,
        companyName: action.payload,
      }
    case CONSTANTS.SET_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.payload,
      }
      case CONSTANTS.SET_ACCOUNT_NUMBER:
      return {
        ...state,
        accountNumber: action.payload,
      }
      case CONSTANTS.SET_BILLING_INFORMATION:
        return {
          ...state,
          billingInformation: action.payload,
        }
    case CONSTANTS.SET_SHIPPING_UNITS:
      return {
        ...state,
        companyAddressUnit: action.payload
      }
    default:
      return state
  }
}
export default reducer;

function setErrorTexts(data) { return { type: 'SET_ERROR_TEXTS', payload: data } }
function setAddressUnits(data) { return { type: 'SET_UNITS', payload: data } }
function setCorporation(data) { return { type: 'SET_CORPORATION', payload: data } }

export const actions = {
  setErrorTexts,
  setAddressUnits,
  setCorporation
};


