import { delay, fork, put, takeLatest, select } from "redux-saga/effects";
import apiClient from "../../../../utils/apiClient";
import * as API from "../../../../containers/APIurls";
import * as CheckoutSlice from "../../ducks/CheckoutSlice";
import * as CreditSlice from "./CreditSlice";
import Utils from "../../ducks/utils";
//import { navigationRef } from "AppWrapper";

const DEFAULT_RETRY_INTERVAL = 15;
const MAX_RETRIES = 8;

function* handleCreditDetailsResponse(params, resp, retryCount = 0) {
	const globalData = yield select((state) => state.GlobalData);
	yield put(CreditSlice.setValidateBlendedCreditDetailsResponse({}));
	yield put(CreditSlice.setUpdateProspectCreditDetailsResponse({}));
	if (resp?.serviceStatus?.success) {
		if (
			resp?.serviceStatus?.statusCode === "CREDIT_HOLD_NEEDED" ||
			resp?.serviceStatus?.statusCode === "CREDIT_RESULTS_FOR_HOLD_NEEDED"
		) {
			yield put(CreditSlice.setCreditDetailsResponse(resp));
			if (params?.payload?.isMitekDeclined) {
				const requestBody = {
					flow: params?.payload?.flow,
					creditPath: "BLENDED",
					isLowerFinancedAmountOptionSelected: false,
					isMitekDeclined: params?.payload?.isMitekDeclined,
					componentName: params?.payload?.componentName,
				};
				if (globalData?.cmp?.isFromGoogleShop) {
					requestBody.cmpCode = globalData?.cmp?.cmpValue;
				}
				yield fork(updateProspectCreditDetails, { payload: requestBody });
			}
			yield put(CreditSlice.setCreditResultLoading(false));
			yield put(CreditSlice.setShowProgressOverlay(false));
		} else if (resp?.data?.creditReadStatus === "INPROGRESS") {
			const waitSeconds = Number(resp?.data?.interval || DEFAULT_RETRY_INTERVAL);
			yield delay(waitSeconds * 1000);
			yield fork(validateCreditDetails, params, retryCount);
		} else if (resp?.data?.creditReadStatus === "COMPLETED") {
			yield put(CreditSlice.setCreditDetailsResponse(resp));
			if (params?.payload?.isMitekDeclined) {
				const requestBody = {
					flow: params?.payload?.flow,
					creditPath: "BLENDED",
					isLowerFinancedAmountOptionSelected: false,
					isMitekDeclined: params?.payload?.isMitekDeclined,
					componentName: params?.payload?.componentName,
				};
				if (globalData?.cmp?.isFromGoogleShop) {
					requestBody.cmpCode = globalData?.cmp?.cmpValue;
				}
				yield fork(updateProspectCreditDetails, { payload: requestBody });
			}
			yield put(CreditSlice.setCreditResultLoading(false));
			yield put(CreditSlice.setShowProgressOverlay(false));
		}
	} else {
		if (resp?.serviceStatus?.statusCode === "ERROR_NEED_AUTHENTICATION") {
			yield put(CreditSlice.setCreditDetailsResponse(resp));
			yield put(CreditSlice.setCreditResultLoading(false));
			yield put(CreditSlice.setShowProgressOverlay(false));
		} else if (
			resp?.serviceStatus?.statusCode === "ERROR_AUTHENTICATION_NEEDED"
		) {
			yield put(CreditSlice.setCreditDetailsResponse(resp));
			yield put(CreditSlice.setCreditResultLoading(false));
			yield put(CreditSlice.setShowProgressOverlay(false));
		} else if (resp?.serviceStatus?.statusCode === "SUSPENDED_USER_FLOW") {
			// yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_USER_FLOW"));
			// yield put(CheckoutSlice.setSuspendFlow(true));
			yield put(CreditSlice.setCreditResultLoading(false));
			yield put(CreditSlice.setShowProgressOverlay(false));
		} else {
			// yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_CREDIT_CHECK"));
			// yield put(CheckoutSlice.setSuspendFlow(true));
			yield put(CreditSlice.setCreditResultLoading(false));
			yield put(CreditSlice.setShowProgressOverlay(false));
		}
	}
}

function* validateCreditDetails(params, retryCount = 0) {
	yield put(CreditSlice.setCreditResultLoading(true));
	yield put(CreditSlice.setShowProgressOverlay(true));
	yield put(CreditSlice.setIsLoadingCheckCreditOptions(true));
	const { flow, isBlended, isCreditHoldPage, ssn } = params.payload;
	const unformatedSSN = (ssn || "").replaceAll("-", "");
	const requestBody = {
		approach: "",
		creditHoldPage: isCreditHoldPage ? true : false,
		flow: flow,
		shoppingPath: "PROSPECT",
	};
	if (ssn) {
		requestBody.ssn = Utils.pieProtectSPI(unformatedSSN);
	}
	let apiURL = "";
	if (isBlended) {
		apiURL =
			retryCount > 0
				? API.RETRIEVE_BLENDED_CREDIT_URL
				: API.VALIDATE_BLENDED_CREDIT_URL;
	} else {
		apiURL =
			retryCount > 0
				? API.RETRIEVE_BUSINESS_CREDIT_URL
				: API.VALIDATE_BUSINESS_CREDIT_URL;
	}
	try {
		if (retryCount < MAX_RETRIES) {
			yield put(CreditSlice.setRetries(retryCount + 1));
			const validateCreditDetailsResponse = yield apiClient.post(
				apiURL,
				requestBody
			);
			const resp = validateCreditDetailsResponse?.data;
			yield fork(handleCreditDetailsResponse, params, resp, retryCount + 1);
		} else {
			// yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_CREDIT_CHECK"));
			// yield put(CheckoutSlice.setSuspendFlow(true));
			yield put(CreditSlice.setCreditResultLoading(false));
			yield put(CreditSlice.setShowProgressOverlay(false));
		}
	} catch (err) {
		yield put(CreditSlice.setIsLoadingCheckCreditOptions(false));
		yield put(CreditSlice.setCreditResultLoading(false));
		yield put(CreditSlice.setShowProgressOverlay(false));
		// yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_CREDIT_CHECK"));
		// yield put(CheckoutSlice.setSuspendFlow(true));
	} finally {
		yield put(CreditSlice.setIsLoadingCheckCreditOptions(false));
	}
}

function* updateProspectCreditDetails(params) {
	yield put(CreditSlice.setIsLoadingCheckCreditOptions(true));
	const {
		flow,
		creditPath,
		cmpCode,
		isLowerFinancedAmountOptionSelected,
		isMitekDeclined,
		componentName,
	} = params.payload;
	const requestBody = {
		prospectCreditPath:
			creditPath === "PAY_DOWN_PAYMENT" ? "BizOnlyCredit" : creditPath,
		shoppingPath: "PROSPECT",
		approach: "",
		flow: flow,
	};
	if (creditPath === "PAY_DOWN_PAYMENT") {
		requestBody.flexCreditOption = creditPath;
	}
	if (cmpCode) {
		requestBody.cmpCode = cmpCode;
	}
	try {
		const updateProspectCreditInfo = yield apiClient.post(
			API.UPDATE_PROSPECT_CREDIT_DETAIL,
			requestBody
		);
		const resp = updateProspectCreditInfo?.data;
		yield put(
			CreditSlice.setIsLowerFinancedAmountOptionSelected(
				isLowerFinancedAmountOptionSelected
			)
		);
		yield put(CreditSlice.setIsLoadingCheckCreditOptions(false));
		if (
			resp?.serviceStatus?.statusCode !== "CREDIT_HOLD_NEEDED" &&
			resp?.serviceStatus?.statusCode !== "CREDIT_RESULTS_FOR_HOLD_NEEDED"
		) {
			yield put(CreditSlice.setCreditDetailsResponse(resp));
			if (componentName === "PersonalValidation" && isMitekDeclined) {
				if (resp?.serviceStatus?.statusCode === "ERROR_NEED_AUTHENTICATION") {
					// navigationRef?.current("/shop/unified-checkout/verifyDocuments");
				} else if (
					resp?.serviceStatus?.statusCode === "ERROR_AUTHENTICATION_NEEDED"
				) {
					//navigationRef?.current("/shop/unified-checkout/verifyDocuments");
				} else if (resp?.serviceStatus?.statusCode === "SUSPENDED_USER_FLOW") {
					// yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_USER_FLOW"));
					// yield put(CheckoutSlice.setSuspendFlow(true));
					//navigationRef?.current("/shop/contact-us");
				} else if (resp?.serviceStatus?.success) {
					yield put(CheckoutSlice.setEnableRoutingWithoutGaurd(true));
					yield put(
						CheckoutSlice.setActiveAccordian(
							pageData?.activeAccordionDisplayOrder?.maa
						)
					);
					// navigationRef?.current("/shop/unified-checkout/docusign");
				}
			}
		} else {
			let code = `${resp?.data?.creditApprovalStatus}-${resp?.data?.creditStatusReason}`;
			yield put(CreditSlice.setCreditHoldCode(code));
			yield put(CheckoutSlice.setShowCreditHoldSection(true));
			// navigationRef?.current("/shop/credit-hold");
		}
	} catch (err) {
		// yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_CREDIT_CHECK"));
		// yield put(CheckoutSlice.setSuspendFlow(true));
		yield put(CreditSlice.setIsLoadingCheckCreditOptions(false));
	}
}

function* validateCreditHoldCreditDetails(params) {
	yield put(CreditSlice.setCreditHoldRedirectedFromCreditHold(true));
	yield put(CreditSlice.setCreditHoldShowError(false));
	const {
		flow,
		disableCTA,
		isInvokedFirstTime,
		repeatTimer,
		ssn,
		startTimer,
		isBlended,
	} = params.payload;
	const requestBody = {
		approach: "",
		creditHoldPage: true,
		flow: flow,
		shoppingPath: "PROSPECT",
	};
	if (ssn) {
		requestBody.ssn = ssn;
	}
	const apiURL = isBlended
		? API.VALIDATE_BLENDED_CREDIT_URL
		: API.VALIDATE_BUSINESS_CREDIT_URL;
	try {
		if (isInvokedFirstTime && disableCTA) {
			yield delay(startTimer * 1000);
		}
		yield put(CreditSlice.setCreditHoldLoading(true));
		const creditDetailsResponse = yield apiClient.post(apiURL, requestBody);
		const resp = creditDetailsResponse?.data;
		yield put(CreditSlice.setCreditHoldLoading(false));
		yield put(CreditSlice.setCreditHoldIsFirstCallInvoked(true));
		yield put(CreditSlice.setCreditDetailsResponse(resp));
		if (!disableCTA) {
			yield put(CreditSlice.setCreditHoldShowError(true));
		} else if (disableCTA && resp?.data?.creditReadStatus !== "COMPLETED") {
			yield delay(repeatTimer * 1000);
			let paramsObj = { ...params };
			paramsObj.payload.isInvokedFirstTime = false;
			yield fork(validateCreditHoldCreditDetails, paramsObj);
		}
	} catch (err) {
		yield put(CreditSlice.setCreditHoldLoading(false));
		yield put(CreditSlice.setCreditHoldShowError(true));
		yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_CREDIT_CHECK"));
		yield put(CheckoutSlice.setSuspendFlow(true));
	}
}

export default function* actionWatcher() {
	yield takeLatest(
		CreditSlice.validateCreditDetails.type,
		validateCreditDetails
	);
	yield takeLatest(
		CreditSlice.updateProspectCreditDetails.type,
		updateProspectCreditDetails
	);
	yield takeLatest(
		CreditSlice.validateCreditHoldCreditDetails.type,
		validateCreditHoldCreditDetails
	);
}
