import axios from "axios";
import isEmpty from "lodash/isEmpty";
import {
  API_TIMEOUT,
  API_MAX_RETRIES,
  API_RETRY_DELAY,
} from "../containers/constants";
import {
  PROSPECT_WIDGETS_APP_BASE_PATH,
  CARTCOMP_SESSION_CART_URL,
} from "containers/APIurls";

const IS_DEBUG = false;

const sleep = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

const isSessionCartAPICall = (url) =>
  url?.includes(CARTCOMP_SESSION_CART_URL);

const setNewRelicParams = (response) => {
  // Adapted from Angular implementation
  if (typeof window !== undefined && window?.newrelic?.setCustomAttribute) {
    const identifier = response?.data?.sessionCartMetaData?.identifier;
    const automationEnable =
      !!response?.data?.sessionCartMetaData?.automationEnable &&
      !!response?.data?.sessionCartMetaData?.automationThrottleEnable;
    const isSessionCart = false;
    //isSessionCartAPICall(response?.config?.url);

    // Do not send if value is same as previous
    if (
      isSessionCart &&
      identifier !== window.sessionStorage.getItem("_smbIdentifier")
    ) {
      window.newrelic.setCustomAttribute("jsessionId", identifier);
      window.newrelic.setCustomAttribute("fw", "R");
      window.sessionStorage.setItem("_smbIdentifier", identifier);
    }

    // Do not send if value is same as previous
    if (automationEnable !== window.sessionStorage.getItem("_smbAutomation")) {
      window.newrelic.setCustomAttribute(
        "automation",
        String(automationEnable)
      );
      window.sessionStorage.setItem("_smbAutomation", String(automationEnable));
    }

    //BOVV-80887 add flow type for sre dashboard
    window.newrelic.setCustomAttribute(
      "flow",
      window.sessionStorage.getItem("_smbFlowNewRelic")
    );
  }
};

const getClient = (baseUrl = null) => {
  const options = {
    baseURL: baseUrl,
    timeout: API_TIMEOUT
  };

  const client = axios.create(options);
  const safeStringify = require("fast-safe-stringify");
  const max_retries = API_MAX_RETRIES;
  const retry_delay = API_RETRY_DELAY;
  let counter = 0;

  // Add a request interceptor
  client.interceptors.request.use(
    (requestConfig) => {
      IS_DEBUG &&
        console.debug(`making request: ${safeStringify(requestConfig)}`);

      // add pageid header for New Relic
      if (requestConfig?.url?.includes(PROSPECT_WIDGETS_APP_BASE_PATH)) {         
        requestConfig.headers["withCredentials"] = true;
        if (
          typeof window !== "undefined" &&
          typeof window["NREUM"] === "object" &&
          window.sessionStorage.getItem("nr_hash") !== null
        ) {
          requestConfig.headers["pageid"] =
            window.sessionStorage.getItem("nr_hash");
        }
        if (
          typeof window !== "undefined" &&
          window.sessionStorage.getItem("cId") !== null
        ) {
          requestConfig.headers["clientId"] =
            window.sessionStorage.getItem("cId");
        }
      }

      // add "ecommLPConversationId" cookie before sessionCart api call invoke
      // if (isSessionCartAPICall(requestConfig?.url)) {
      //   window && window?.lpTag && updateLPConversationId();
      // }

      return requestConfig;
    },
    (requestError) => {
      // Do something with error
      console.error(requestError);
      return Promise.reject(requestError);
    }
  );

  // Add a response interceptor
  client.interceptors.response.use(
    (response) => {
      // Do something with response
      IS_DEBUG && console.debug(`Response success: ${safeStringify(response)}`);
      // if (isSessionCartAPICall(response?.config?.url)) {
      //   IS_DEBUG && console.debug("is session cart call");
      //   setNewRelicParams(response);
      // }
      return response;
    },
    async (error) => {
      // Any status codes that fall outside the range of 2xx cause this function to trigger
      // retry the request for certain errors
      if (isRetryable(error?.response?.status)) {
        const config = error.config;
        if (counter < max_retries) {
          let res = null;
          counter++;
          res = await sleep(retry_delay).then(async () => {
            return await new Promise(async (resolve) => {
              resolve(client(config));
            });
          });
          if (res && res.data) {
            return Promise.resolve(res);
          }
        } else {
          return handleError(error);
        }
      } else {
        return handleError(error);
      }
      // Do something with response error
    }
  );

  return client;
};

function handleError(error) {
  let errJson;
  const safeStringify = require("fast-safe-stringify");
  try {
    errJson = error.toJSON();
  } catch (err) {
    errJson = {
      message: "Something went wrong. Please try again",
      config: { url: error?.config?.url || "" },
    };
  }
  IS_DEBUG && console.debug(safeStringify(errJson));
  const requestDt = { message: errJson?.message, url: errJson?.config?.url };
  console.error(`Response error: ${error}`);
  return Promise.reject(error);
}

const isRetryable = (httpErrorCode) => {
  return !httpErrorCode || httpErrorCode === 408 || httpErrorCode >= 500;
};

function getDomainTLD(domain) {
  if (undefined === domain || typeof domain != "string") {
    return null;
  }
  try {
    var domainParts = domain.split(".");
    var domainTLD =
      domainParts.length > 1
        ? domainParts[domainParts.length - 2] +
          "." +
          domainParts[domainParts.length - 1]
        : domain;
    return domainTLD ? domainTLD : null;
  } catch (error) {
    return error;
  }
}

function setLocalCookie(
  cookieName,
  cookieValue,
  expiryDays = 5,
  secureOnly = true
) {
  var date = new Date();
  date.setTime(date.getTime() + expiryDays * 1000 * 60 * 60 * 24);
  var cookieStr =
    cookieName +
    "=" +
    cookieValue +
    "; expires=" +
    date.toUTCString() +
    (secureOnly ? ";secure" : "");
  var rootDomain = getDomainTLD(window.location.hostname);
  cookieStr = cookieStr + ";domain=" + rootDomain + ";path=/";
  IS_DEBUG && console.log("CookieService: Local cookie being set, ", cookieStr);
  document.cookie = cookieStr;
}

function updateLPConversationId() {
  const conversationInfoList =
    window?.lpTag?.events.hasFired("lpUnifiedWindow", "conversationInfo") || [];
  for (let i = conversationInfoList?.length - 1; i >= 0; i--) {
    const conversationId = conversationInfoList[i]?.data?.conversationId;
    if (conversationId) {
      setLocalCookie("ecommLPConversationId", conversationId, 1);
      break;
    }
  }
}

class ApiClient {
  constructor(baseUrl = null) {
    this.client = getClient(baseUrl);
  }

  async get(url, conf = {}) {
    try {
      const response = await this.client.get(url, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async delete(url, conf = {}) {
    try {
      const response = await this.client.delete(url, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async head(url, conf = {}) {
    try {
      const response = await this.client.head(url, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async options(url, conf = {}) {
    try {
      const response = await this.client.options(url, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async post(url, data = {}, conf = {}) {
    try {
      const response = await this.client.post(url, data, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async put(url, data = {}, conf = {}) {
    try {
      const response = await this.client.put(url, data, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async patch(url, data = {}, conf = {}) {
    try {
      const response = await this.client.patch(url, data, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return await Promise.reject(error);
    }
  }
}

export { ApiClient };

/**
 * Base HTTP Client
 */
export default {
  // Provide request methods with the default base_url
  async get(url, conf = {}) {
    try {
      const response = await getClient().get(url, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return await Promise.reject(error);
    }
  },

  async delete(url, conf = {}) {
    try {
      const response = await getClient().delete(url, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return await Promise.reject(error);
    }
  },

  async head(url, conf = {}) {
    try {
      const response = await getClient().head(url, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return await Promise.reject(error);
    }
  },

  async options(url, conf = {}) {
    try {
      const response = await getClient().options(url, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return await Promise.reject(error);
    }
  },

  async post(url, data = {}, conf = {}) {
    try {
      const response = await getClient().post(url, data, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return await Promise.reject(error);
    }
  },

  async put(url, data = {}, conf = {}) {
    try {
      const response = await getClient().put(url, data, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return await Promise.reject(error);
    }
  },

  async patch(url, data = {}, conf = {}) {
    try {
      const response = await getClient().patch(url, data, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return await Promise.reject(error);
    }
  },

  asyncPost(url, data = {}, conf = {}) {
    try {
      getClient().post(url, data, conf);
    } catch (error) {
      // return await Promise.reject(error);
    }
  },
};
