import { createAction, createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
	apiError: false,
	apiErrorMessage: false,
	confirmZipcode: "",
	deviceId: "",
	loading: false,
	validDevices: [],
	validZipcode: "",
	zipCode: "",
	zipEmail: "",
	bogoOfferId: "",
	bogoDeviceUrl: "",
	isByodZipcode: false,
	deviceListBy: null,
	isColorFilter: false,
	fetchCartByodUpdated: false,
	showByodDqNotify: false,
	brandsList: [],
	newSelectedDevice: "",
	oneTalk: false,
	storeAppointmentInfo: {},
	selectedAppointmentDetails: {},
	salesRepAvailability: {},
	storeScheduleAppoinmentInfo: {},
	storeQueryParams: {},
	bussResponse: {},
	isBogoFlag: false,
	deviceSelectedForExceedginCreditLimit: undefined,
	selectedDeviceForCreditExceeded: undefined,
	pegaRecommendations: [],
	pdpProductDetails: {},
	categoryOpen: false,
	pagination:1
};

const MobilitySlice = createSlice({
	name: "Mobility",
	initialState,
	reducers: {
		resetMobilitySlice: (state) => {
			return initialState;
		},
		clearComparedDevices: (state) => {
			state["productCompare"]["compareDeviceInfoList"] = [];
		},
		clearValidDevices: (state) => {
			state["validDevices"] = [];
		},
		removeComparedDevice: (state, { payload }) => {
			state["productCompare"]["compareDeviceInfoList"] = state["productCompare"][
				"compareDeviceInfoList"
			].filter((device) => device.deviceId !== payload);
			delete state["compareDevices"][payload];
		},
		setAddToCartResponse: (state, action) => {
			state["addToCartResponse"] = action.payload;
		},
		setAPIError: (state, action) => {
			state["apiError"] = action.payload;
		},
		setAPIErrorMessage: (state, action) => {
			state["apiErrorMessage"] = action.payload;
		},
		setBrandList: (state, action) => {
			state["deviceFilters"]["brandList"] = action.payload;
		},
		setBrandsList: (state, action) => {
			state["brandsList"] = action.payload;
		},
		setCarrierDetailsByDevice: (state, { payload }) => {
			state["carrierDetailsByDevice"] = payload;
		},
		setCategoryName: (state, action) => {
			state["categoryName"] = action.payload;
		},
		setCombinePricingInfo: (state, action) => {
			state["combinepricing"] = action.payload;
		},
		setCompareDevices: (state, action) => {
			state["compareDevices"] = action.payload;
		},
		setConfirmZipcode: (state, action) => {
			state["confirmZipcode"] = action.payload;
		},
		setDeviceFilters: (state, action) => {
			state["deviceFilters"] = action.payload;
		},
		setDeviceId: (state, action) => {
			state["deviceId"] = action.payload;
		},
		setDeviceName: (state, action) => {
			state["selectedDeviceName"] = action.payload;
		},
		setFlowType: (state, action) => {
			state["flowType"] = action.payload;
		},
		setGetReviewsResponse: (state, action) => {
			state["getReviews"] = action.payload;
		},
		setGridwallList: (state, action) => {
			state["gridwall"] = action.payload;
		},
		setMetaInfo: (state, action) => {
			state["metaInformation"] = action.payload;
		},
		setPageLoading: (state, action) => {
			state["loading"] = action.payload;
		},
		setPaymentList: (state, action) => {
			state["deviceFilters"]["paymentList"] = action.payload;
		},
		setPlans: (state, { payload }) => {
			state["plans"] = payload;
		},
		setProductCompareResp: (state, action) => {
			state["productCompare"] = action.payload;
		},
		setProductDetail: (state, action) => {
			state["productDetail"] = action.payload;
		},
		setRemoveTradeInDeviceResponse: (state, { payload }) => {
			state["removeTradeInDeviceResponse"] = payload;
		},
		setSelectedSku: (state, action) => {
			state["selectedSku"] = action.payload;
		},
		setSkuData: (state, action) => {
			state["skuData"] = action.payload;
		},
		setSmartphones: (state, action) => {
			state["smartphones"] = action.payload;
		},
		setPdpDeviceDetails: (state, action) => {
			state["pdpProductDetails"] = action.payload;
		},
		setOneTalk: (state, action) => {
			state["oneTalk"] = action.payload;
		},
		setSortBy: (state, action) => {
			state["sortBy"] = action.payload;
		},
		setValidDevices: (state, { payload }) => {
			const validDevices = state.validDevices || [];
			state["validDevices"] = [...validDevices, payload];
		},
		setValidDevicesPlans: (state, { payload }) => {
			state["selectedDevicePlans"] = payload;
		},
		setValidZipcode: (state, action) => {
			state["validZipcode"] = action.payload;
		},
		setZipcode: (state, action) => {
			state["zipCode"] = action.payload;
		},
		setIsByodZipcode: (state, action) => {
			state["isByodZipcode"] = action.payload;
		},
		setBogoOfferId: (state, action) => {
			state["bogoOfferId"] = action.payload;
		},
		setBogoDeviceUrl: (state, action) => {
			state["bogoDeviceUrl"] = action.payload;
		},
		setDeviceListBy: (state, action) => {
			state["deviceListBy"] = action.payload;
		},
		setIsColorFilter: (state, action) => {
			state["isColorFilter"] = action.payload;
		},
		setFetchCartByodUpdated: (state, action) => {
			state["fetchCartByodUpdated"] = action.payload;
		},
		setShowByodDqNotify: (state, action) => {
			state["showByodDqNotify"] = action.payload;
		},
		setNewSelectedDevice: (state, action) => {
			state["newSelectedDevice"] = action.payload;
		},
		setFilterSelected: (state, action) => {
			state["filterApplied"] = action.payload;
		},
		setBussResponse: (state, action) => {
			state["bussResponse"] = action.payload;
		},
		setAddressEmergencyInfo: (state, action) => {
			state["addressEmergencyInfo"] = action.payload;
		},
		setEmergecyAddressInfo: (state, action) => {
			state["emergecyAddressInfo"] = action.payload;
		},
		setStoreAppointmentInfo: (state, action) => {
			state["storeAppointmentInfo"] = action.payload;
		},
		setSelectedAppointmentDetails: (state, action) => {
			state["selectedAppointmentDetails"] = action.payload;
		},
		setSalesRepAvailability: (state, action) => {
			state["salesRepAvailability"] = action.payload;
		},
		setStoreScheduleAppoinmentInfo: (state, action) => {
			state["storeScheduleAppoinmentInfo"] = action.payload;
		},
		setZipEmail(state, action) {
			state["zipEmail"] = action.payload;
		},
		setStoreQueryParams: (state, action) => {
			state["storeQueryParams"] = action.payload;
		},
		setReviewsForCompareDevices: (state, action) => {
			state["reviewsForCompareDevices"] = action.payload;
		},
		setIsBogoFlag: (state, action) => {
			state["isBogoFlag"] = action.payload;
		},
		replaceDeviceForExceedingcreditLimit: (state, action) => {
			state["deviceSelectedForExceedginCreditLimit"] = action.payload;
		},
		addedDeviceForExceededCredit: (state, action) => {
			state["selectedDeviceForCreditExceeded"] = action.payload;
		},
		setPegaRecommendations: (state, action) => {
			state["pegaRecommendations"] = action.payload;
		},
		setDeviceSuggestionResp: (state, action) => {
			state["deviceSuggestionResp"] = action.payload;
		},
		setError: (state, action) => {
			state["apiFail"] = action.payload;
		},
		setCategoryOpen: (state, action) => {
			state["categoryOpen"] = action.payload;
		},
		setPurchaseInfo: (state, action) => {
			state["purchaseInfo"] = action.payload;
		},
		setPagination: (state, action) => {
			state["pagination"] = action.payload;
		},
		setStopNavigateToPlans: (state, action) => {
			state["stopNavigateToPlans"] = action.payload;
		  },
	},
});

export const reducer = MobilitySlice.reducer;

export const addToCart = createAction("MobilitySlice/addToCart");
export const clearComparedDevicesAct = createAction(
	"MobilitySlice/clearComparedDevicesAct"
);
export const getCarrierDetailsByDevice = createAction(
	"MobilitySlice/getCarrierDetailsByDevice"
);
export const getCombinePricingInfo = createAction(
	"MobilitySlice/getCombinePricingInfo"
);
export const getCompareDevices = createAction(
	"MobilitySlice/getCompareDevices"
);
export const getGridwallList = createAction("MobilitySlice/getGridwallList");
export const getInitPageLoadInfo = createAction(
	"MobilitySlice/getInitPageLoadInfo"
);
export const getMetaInformation = createAction(
	"MobilitySlice/getMetaInformation"
);
export const getPlans = createAction("MobilitySlice/getPlans");
export const getPlansForValiDevices = createAction(
	"MobilitySlice/getPlansForValiDevices"
);
export const getProductDetailPageInfo = createAction(
	"MobilitySlice/getProductDetailPageInfo"
);
export const getReviews = createAction("MobilitySlice/getReviews");
export const getSharedCartSave = createAction(
	"MobilitySlice/getSharedCartSave"
);
export const getSmartphones = createAction("MobilitySlice/getSmartphones");
export const getZipcode = createAction("MobilitySlice/getZipcode");
export const removeComparedDevicesAct = createAction(
	"MobilitySlice/removeComparedDevicesAct"
);
export const removeTradeInDevice = createAction(
	"MobilitySlice/removeTradeInDevice"
);
export const updateValidDevices = createAction(
	"MobilitySlice/updateValidDevices"
);
export const validateZipcode = createAction("MobilitySlice/validateZipcode");
export const validateAddress911 = createAction(
	"MobilitySlice/validateAddress911"
);
export const clearCartAndAddToCart = createAction(
	"MobilitySlice/clearCartAndAddToCart"
);
export const getStoreAppointmentDetails = createAction(
	"MobilitySlice/getStoreAppointmentDetails"
);
export const getStoreScheduleAppointmentDetails = createAction(
	"MobilitySlice/getStoreScheduleAppointmentDetails"
);
export const getSalesRepAvailability = createAction(
	"MobilitySlice/getSalesRepAvailability"
);
export const getFeedBack = createAction("MobilitySlice/getFeedBack");
export const getReviewsForComparePage = createAction(
	"MobilitySlice/getReviewsForComparePage"
);
export const getBussProductPlans = createAction(
	"MobilitySlice/getBussProductPlans"
);
export const getGridwallPegaRecommendation = createAction(
	"MobilitySlice/getGridwallPegaRecommendation"
);
export const sendEmailDetails = createAction("MobilitySlice/sendEmailDetails");
export const getPurchaseInfo = createAction("MobilitySlice/getPurchaseInfo");

export const {
	resetMobilitySlice,
	clearComparedDevices,
	clearValidDevices,
	removeComparedDevice,
	setAddToCartResponse,
	setAEMInfo,
	setAPIError,
	setAPIErrorMessage,
	setCarrierDetailsByDevice,
	setCategoryName,
	setCompareDevices,
	setCombinePricingInfo,
	setConfirmZipcode,
	setDeviceFilters,
	setDeviceId,
	setDeviceName,
	setFlowType,
	setGetReviewsResponse,
	setGridwallList,
	setMetaInfo,
	setPageLoading,
	setPlans,
	setZipEmail,
	setProductCompareResp,
	setProductDetail,
	setRemoveTradeInDeviceResponse,
	setSelectedSku,
	setSkuData,
	setSmartphones,
	setOneTalk,
	setValidDevices,
	setValidDevicesPlans,
	setValidZipcode,
	setZipcode,
	setIsByodZipcode,
	setBogoOfferId,
	setBogoDeviceUrl,
	setDeviceListBy,
	setIsColorFilter,
	setFetchCartByodUpdated,
	setShowByodDqNotify,
	setBrandsList,
	setNewSelectedDevice,
	setFilterSelected,
	setBussResponse,
	setAddressEmergencyInfo,
	setEmergecyAddressInfo,
	setStoreAppointmentInfo,
	setSalesRepAvailability,
	setSelectedAppointmentDetails,
	setStoreScheduleAppoinmentInfo,
	setStoreQueryParams,
	setReviewsForCompareDevices,
	setIsBogoFlag,
	replaceDeviceForExceedingcreditLimit,
	addedDeviceForExceededCredit,
	setPegaRecommendations,
	setPdpDeviceDetails,
	setDeviceSuggestionResp,
	setError,
	setCategoryOpen,
	setPurchaseInfo,
	setPagination,
	setStopNavigateToPlans
} = MobilitySlice.actions;
