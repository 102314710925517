import React, { Fragment, useEffect, useState } from "react";
import { Button, ButtonGroup } from "@vds3/buttons";
import { MarginSpacerM, MarginSpacerL, MarginSpacerS, MarginSpacerXL } from "common/components/styleTag";
import { Input } from "@vds3/inputs";
import { Grid, Row, Col } from "@vds3/grids";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { mitekSMSVerification, setRunningMitekCount } from "pages/Checkout/ducks/CheckoutSlice";
import { MITEK_QR_CODE } from "containers/APIurls";
import { TileContainer } from "@vds3/tiles";
import { TitleLockup } from "@vds3/type-lockups";
import { useMediaQuery } from "react-responsive";
import MitekDeclined from "./MitekDeclined";
import SpinnerComponent from "common/components/Spinner/Spinner";

const MitekVerification = ({ ...props }) => {
    const { t, i18n } = useTranslation(["app", "checkout"]);
    const pageData = useSelector((state) => state.ProspectWidgets_CheckoutData);
    const isLoading = pageData?.ddia?.isLoading || pageData?.isShoppingCartApiCallInProgress.getFetchCart5G;
    const dispatch = useDispatch();
    const [agreeChecked, setAgreeChecked] = useState(false);
    const [mobileNumber, setMobileNumber] = useState("");
    const [mitekSMSError, setMitekSMSError] = useState(false);
    const [continueBtnClicked, setContinueBtnClicked] = useState(false);
    const mitekAemRes = t("ddiaVerification.DDIA", {
        ns: "app",
        returnObjects: true,
    });
    const isMobile = useMediaQuery({ query: "(max-width: 544px)" });

    useEffect(() => {
        if (!pageData?.mitekCheckVerificationProcess?.status?.success && continueBtnClicked) {
            if (props.setShowProgressModal !== null && props.setShowProgressModal && typeof props.setShowProgressModal == 'function') props.setShowProgressModal(false)
            setMitekSMSError(true);
        }
    }, [pageData?.mitekCheckVerificationProcess]);

    const handleProcessOTP = () => {
        if (props.setShowProgressModal !== null && props.setShowProgressModal && typeof props.setShowProgressModal == 'function') props.setShowProgressModal(true)
        setContinueBtnClicked(true);
        dispatch(
            mitekSMSVerification({
                mtn: mobileNumber,
                shoppingPath: "PROSPECT",
                approach: "",
                flow: "prospect",
            })
        );
    };

    return (
        <div>
            {isLoading && <SpinnerComponent />}
            {mitekSMSError && <Notification
                type="error"
                title={`${t(
                    "ddiaVerification.DDIA.mitek.modal.messages.wireless_number",
                    { ns: "app" }
                )}`}
                fullBleed={false}
                inlineTreatment={false}
                hideCloseButton={true}
                layout="vertical"
            />}
            {mitekAemRes?.mitek?.enableMitekSMSFlow &&
                <Body size="large">
                    {" "}
                    {t("ddiaVerification.DDIA.mitek.modal.content3")}
                    {" "}
                    <b>{t("ddiaVerification.DDIA.mitek.modal.content4")}</b>
                    {" "}
                    {t("ddiaVerification.DDIA.mitek.modal.content5")}
                </Body>
            }
            {!isMobile && !props.mitekDeclined && !props?.mitekFailed && !props?.mitekRetry && <Fragment>
                <Body size="large">
                    {t("checkout.businessInfo-section.mitek.subHeading", { ns: "checkout" })}
                </Body>
                <MarginSpacerM />
                <TileContainer
                    padding='0px'
                    aspectRatio='1:1'
                    width='535px'
                    height='222px'
                    backgroundColor="white"
                    showBorder={true}
                >
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Grid
                            colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
                            colGutter={false}
                            rowGutter={false}>
                            <Row>
                                <Col colSizes={{ mobile: 4, tablet: 4, desktop: 4 }}>
                                    <img width="190px" height="190px" style={{ "border-radius": '8px' }} src={MITEK_QR_CODE + "?ismobile=false"} />
                                </Col>
                                <StyledCol colSizes={{ mobile: 4, tablet: 8, desktop: 8 }}>
                                    <StyledOl>
                                        {t("checkout.businessInfo-section.mitek.steps", { ns: "checkout", returnObjects: true }).map(s =>
                                            <li>
                                                {s}
                                            </li>
                                        )}
                                    </StyledOl>
                                </StyledCol>
                            </Row>
                        </Grid>
                        <StyledDiv>
                            <Body size="small" color="#FFFFFF">
                                {t("checkout.businessInfo-section.mitek.content1", { ns: "checkout" })}
                            </Body>
                        </StyledDiv>
                    </div>

                </TileContainer>
                <MarginSpacerM />
                <Body size="large">
                    {t("checkout.businessInfo-section.mitek.content2", { ns: "checkout" })}
                </Body>
                <MarginSpacerS />
            </Fragment>}
            {isMobile && !props.mitekDeclined && !props?.mitekFailed && !props?.mitekRetry && <Fragment>
                <Body size="large">
                    {t("checkout.businessInfo-section.mitek.mobileMitek.heading", { ns: "checkout" })}
                </Body>
                <Body size="small">
                    {t("checkout.businessInfo-section.mitek.mobileMitek.subHeading", { ns: "checkout" })}
                </Body>
                {/* <TitleLockup surface="light"
                    textAlignment="left"
                    data={{
                        title: {
                            bold: false,
                            size: 'titleMedium',
                            children: t("checkout.businessInfo-section.mitek.mobileMitek.heading", { ns: "checkout" }),
                        },
                        subtitle: {
                            size: 'titleSmall',
                            children: t("checkout.businessInfo-section.mitek.mobileMitek.subHeading", { ns: "checkout" }),
                        }
                    }}
                /> */}
                <MarginSpacerM />
                <Body size="small" primitive="p">
                    <StyledSpan
                        dangerouslySetInnerHTML={{
                            __html: t("checkout.businessInfo-section.mitek.mobileMitek.authorizeConsent", { ns: "checkout" }),
                        }}
                    />
                </Body>
                <MarginSpacerL />
                <ButtonGroup
                    childWidth={'100%'}
                    viewport={'mobile'}
                    rowQuantity={{ mobile: 1 }}
                    data={[
                        {
                            children: t("checkout.businessInfo-section.mitek.mobileMitek.agreeBtn", { ns: "checkout" }),
                            size: 'large',
                            use: 'primary',
                            width: '100%',
                            onClick: () => { props.setLoadMobileMitek(true) },
                        },
                        {
                            children: t("checkout.businessInfo-section.mitek.mobileMitek.declineBtn", { ns: "checkout" }),
                            size: 'large',
                            use: 'secondary',
                            width: '100%',
                            onClick: () => { props.setLoadMobileMitek(false); props.setMitekDeclined(true) },
                        },

                    ]}
                    alignment={'center'}
                />
            </Fragment>}
            {((props.mitekDeclined || props?.mitekFailed) && !props?.mitekRetry) && <Fragment>
                <MitekDeclined proceedToOTPFromMitekDeclined={props?.proceedToOTPFromMitekDeclined} mitekFailed={props?.mitekFailed} />
            </Fragment>}
            {props?.mitekRetry &&
                <Grid>
                    <StyledRow>
                        <Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
                            <Body size="large">
                                {t("checkout.businessInfo-section.mitek.retryHeading", { ns: "checkout" })}
                            </Body>
                            <MarginSpacerL />
                            <Body size="large">
                                {t("checkout.businessInfo-section.mitek.retryDesc", { ns: "checkout" })}
                            </Body>
                            <MarginSpacerL />
                        </Col>
                    </StyledRow>
                    <StyledRow>
                        <Col colSizes={{ mobile: 4, tablet: 3, desktop: 3 }}>
                            <Button
                                size="large"
                                secondary
                                onClick={() => {
                                    props.setMitekRetry(false);
                                    !isMobile && dispatch(setRunningMitekCount((pageData?.mitekRunningCount || 0) + 1));
                                    isMobile && dispatch(generateMitekVerificationLink());
                                }}
                            >
                                {t("checkout.businessInfo-section.mitek.retrybtnTxt", { ns: "checkout" })}
                            </Button>
                        </Col>
                    </StyledRow>
                </Grid>}
            {mitekAemRes?.mitek?.enableMitekSMSFlow &&
                <Fragment>
                    <Title size="small">{t("ddiaVerification.DDIA.mitek.modal.mobile.heading")}</Title>
                    <MarginSpacerS />
                    <Body size="medium">
                        {t("ddiaVerification.DDIA.mitek.modal.mobile.content1")}
                    </Body>
                    <Body size="medium">
                        {mitekAemRes?.mitek?.modal?.mobile?.links[0]?.heading}{" "} {mitekAemRes?.mitek?.modal?.mobile?.links[0].link}
                    </Body>
                    <Body size="medium">
                        {mitekAemRes?.mitek?.modal?.mobile?.links[1]?.heading}{" "} {mitekAemRes?.mitek?.modal?.mobile?.links[1]?.link}
                    </Body>
                    <MarginSpacerXL />
                    <Checkbox
                        name="agee"
                        width="auto"
                        label=""
                        disabled={false}
                        error={false}
                        errorText=""
                        selected={agreeChecked}
                        onChange={(e) => setAgreeChecked(!agreeChecked)}
                    >
                        <Micro>
                            {t("ddiaVerification.DDIA.mitek.modal.mobile.ack")}
                        </Micro>
                    </Checkbox>

                    <MarginSpacerXL />
                    <Input
                        label="Mobile number"
                        placeholder=""
                        name="mobileNumber"
                        maxLength={10}
                        width={"50%"}
                        type="text"
                        disabled={false}
                        value={mobileNumber}
                        onChange={(e) => setMobileNumber(e.target.value?.replace(/[^\d]/g, ""))}
                    />
                    <MarginSpacerXL />
                    <Button
                        size="medium"
                        primary
                        className="calBtn"
                        disabled={!agreeChecked || !mobileNumber}
                        onClick={handleProcessOTP}
                    >
                        {t("ddiaVerification.DDIA.mitek.modal.mobile.actionLabel")}
                    </Button>
                </Fragment>
            }
        </div>
    );
};

const StyledOl = styled.ol`
li::marker {
    font-weight: bold;
}
`;

const StyledRow = styled(Row)`
  > div {
    padding: 0rem;
  }
  padding: 0rem !important;
`;

const StyledCol = styled(Col)`
padding: 15px 20px 15px 5px;
`;

const StyledDiv = styled.div`
background-color: black;
height: 30px;
padding: 5px !important;
border-radius: 0px 0px 8px 8px;
`;

const StyledSpan = styled.span`
  > a {
    color: black;
  }
`;

export default MitekVerification;



