import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import isEmpty from "lodash/isEmpty";
import { Modal, ModalBody, ModalTitle, ModalFooter } from "@vds3/modals";
import {
	Accordion,
	AccordionDetail,
	AccordionHeader,
	AccordionItem,
	AccordionTitle,
} from "@vds3/accordions";
import { Button } from "@vds3/buttons";
import { Checkbox } from "@vds3/checkboxes";
import { Col, Grid, Row } from "@vds3/grids";
import { Body } from "@vds3/typography";
import { showStickyHeaderValues } from "app/ducks/GlobalSlice";
import OneTimePasscodeOptix from "../OneTimePasscode/OneTimePasscodeOptix";
import MitekOptix from "../Mitek/MitekOptix";
// import BusinessInfoExistModal from "../../../../common/components/BusinessInfoExistModal/BusinessInfoExistModal";
import SpinnerComponent from "common/components/Spinner/Spinner";
// import * as SiteAnalytics from "../../../../sitecatalyst";
import {
	ddiaCheckVerificationResult,
	generateDDIALink,
	getSessionCart,
	postBusinessInformation,
	setNotification,
	setSuspendFlow,
	setSuspendScenario,
	mitekCheckVerificationResult,
	generateMitekVerificationLink,
	setCreditPath,
	setIsVerifyBusiness,
	setShowMitekModal,
	setCreditCheckState,
	setBusinessState,
	setShippingState,
	setShowValidationOverlay,
	setActiveAccordian,
	setSameAsCompanyAddress,
	setShowOneTimePassModal,
	setExisitingCustomer,
	saveBusinessInformation,
	logSuspendData,
} from "../ducks/CheckoutSlice";
import Utils from "../../Checkout/ducks/utils";
import BusinessInfo from "../BusinessInfo/BusinessInfo";
import SignerAdd from "../BusinessInfo/SignerAdd";
import CreditCheck5g from "../CreditCheck5g/CreditCheck5g";
import ProgressOverlay from "../ProgressOverlay/ProgressOverlay";
import ShippingInfo from "../ShippingInfo/ShippingInfo";
import "./VerifyBusiness.css";
import MitekModal from "../Mitek/MitekModal";
import {
	MarginSpacerM,
	MarginSpacerS,
	MarginSpacerXL,
} from "common/components/styleTag";
//import { useNotification } from "../CreditResultsNew/hooks";
import SessionCartId from "common/components/SessionCartId/SessionCartId";
import { useGlobalData } from "hooks";
import {
	setRetries,
	validateCreditDetails,
} from "../CreditResult/CreditResultsNew/CreditSlice";
import { Icon } from "@vds3/icons";
import { Notification } from "@vds3/notifications";
// import {
//   setRetries,
//   validateCreditDetails,
//   setShowProgressOverlay
// } from "../CreditResultsNew/CreditSlice";
const StyledSpan = styled.span`
	> a {
		color: black;
	}
`;

const VerifyBusiness = (props) => {
	const { metaInformation } = useGlobalData();
	const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
	const pageData = useSelector((state) => state.ProspectWidgets_CheckoutData);
	const lqData = useSelector((state) => state.ProspectWidgets_LQPage);
	const shoppingCartData = useSelector(
		(state) => state.ProspectWidgets_ShoppingCartData
	);
	
	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	const creditData = {};
	const signerState = JSON.parse(localStorage.getItem("signerState"));
	const standAloneBuSS = sessionCartData?.standAloneBuSS;

	//const [shippingState, setShippingState] = useState({});
	//const [businessInfoState, setBusinessState] = useState({});
	const shippingState = pageData?.shippingState;
	const creditCheck5gState = pageData?.creditCheck5gState;
	const businessInfoState = pageData?.businessState;
	const showValidationOverlay = pageData?.showValidationOverlay;
	const [signerAddState, setSignerAddState] = useState(
		signerState ? signerState : {}
	);
	const [scenarioContent, setScenarioContent] = useState("");
	//const [creditCheck5gState, setCreditCheckState] = useState({});
	const [showBusinessInfo, setShowBusinessInfo] = useState(false);
	const [showSignerAdd, setShowSignerAdd] = useState(false);
	const [isBusinessInfoComplete, setBusinessInfoComplete] = useState(false);
	const [isSignerAddComplete, setSignerAddComplete] = useState(false);
	const [showShippingInfo, setShowShippingInfo] = useState(true);
	const [isShippingInfoComplete, setShippingInfoComplete] = useState(false);
	const [showCreditCheck, setShowCreditCheck] = useState(false);
	const [isCreditSectionComplete, setCreditSectionComplete] = useState(false);
	const [showAcceptAndContinue, setShowAcceptAndContinue] = useState(false);
	const [continueClicked, setContinueClicked] = useState(false);
	const [showOneTimePassCode, setShowOneTimePassCode] = useState(false);
	const [oneTimePassCodeComplete, setOneTimePassCodeComplete] = useState(false);
	const [otpFailed, setOtpFailed] = useState(false);
	const [isOTPNotEligible, setIsOTPNotEligible] = useState(false);
	const [showDDIAModal, setShowDDIAModal] = useState(false);
	const [showMitekSection, setShowMitekSection] = useState(false);
	//const [showValidationOverlay, setShowValidationOverlay] = useState(false);
	const [showZipCodeModal, setShowZipCodeModal] = useState(false);
	const [verificationApiRetryCount, setVerificationApiRetryCount] = useState(1);
	const [captchaValue, setIsCaptchaValue] = useState("");
	const [isCheckBoxSelected, setIsCheckBoxSelected] = useState(false);
	const [disableAcceptContinue, setDisableAcceptContinue] = useState(false);
	const [continueBtnClicked, handleContinueBtnClicked] = useState(false);
	const [progressOverlaycount, handleProgressOverlayCount] = useState(20);
	const validateResponse = pageData?.otp?.otpValidatePasscode;
	const ddiaLinkResponse = pageData?.ddia?.linkUrlResponse;
	const isDigitalQuote = window.sessionStorage.getItem("isDigitalQuote");
	const isMobile = useMediaQuery({ query: "(max-width: 544px)" });
	const isMitek = false;
	// const { clearNotification } = useNotification();
	const zipcodebyod = sessionCartData?.cart?.zipCodeFromPlan;
	const zipcodebusiness =
		shippingState?.businessStreetAddress?.place?.properties?.postalCode;
	const billingState = sessionCartData?.cart?.billingState;
	const packages = sessionCartData?.cart?.packages;
	const byodDeviceList =
		packages &&
		Object.keys(packages).filter(
			(key) => packages[key]?.byod == true && packages[key]?.portIn == true
		);

	const sameAsCompanyAddress = {
		company: shippingState?.companyName,
		firstName: businessInfoState?.contactInfo?.firstName,
		lastName: businessInfoState?.contactInfo?.lastName,
		phoneNumber: shippingState?.phoneNumber,
		email: businessInfoState?.contactInfo?.email,
	};

	const showOtpModal =
		sessionCartData?.showOtpModal ||
		pageData?.postBusinessInfoResponse?.showOtpModal;
	const showDdiaPage =
		sessionCartData?.showDdiaPage || validateResponse?.data?.showDdiaPage;
	const mitekEnabled = sessionCartData?.mitekEnabled && !isMobile;
	const captcha =
		window?.mbtGlobal?.sk || "6LdMkcAZAAAAANaphnrFMaEl9Msqqls3xWmAX3yy";
	const recaptchaRef = React.createRef();
	let hasCaptcha = false;
	if (!isEmpty(captcha)) {
		hasCaptcha = true;
	}

	const dispatch = useDispatch();
	//const navigate = useNavigate();
	const flow = pageData?.unifiedCartParams?.flow ?? lqData?.flow;
	const { t, i18n } = useTranslation(["checkout", "app", "suspend"]);
	const apiErrors = t("verification.api_errors", {
		ns: "checkout",
		returnObjects: true,
	});
	const suspendScenario = pageData?.suspendScenario;
	const suspendContent = t("suspend.dynScenario", {
		ns: "suspend",
		returnObjects: true,
	});
	const DEFAULT_SCENARIO = "HARD_STOP";
	const authorizeTextForFullAutomation = t(
		"verification.authorizeTextForFullAutomation",
		{ ns: "checkout" }
	);
	const tabletOfferZipCode =
		t("verification.specialOfferZipStates.tablets", {
			ns: "checkout",
			returnObjects: true,
		}) || [];
	const creditPathSelected = pageData?.creditPath;
	const isDDIA = false;
	const isTaxIdOptional = globalData?.cmp?.isFromGoogleShop;
	const isBlendedCredit = sessionCartData?.enableBlendedCredit;
	const isBizOnlyCredit = sessionCartData?.enableBizOnlyCredit;
	const isCreditChoicePageActive = isTaxIdOptional
		? true
		: isBlendedCredit && isBizOnlyCredit;
	useEffect(() => {
		dispatch(setIsVerifyBusiness(true));
	}, []);

	useEffect(() => {
		if(suspendScenario){
		const scenario = suspendContent?.hasOwnProperty(suspendScenario)
			? suspendScenario
			: DEFAULT_SCENARIO;
		setScenarioContent(`suspend.dynScenario.${scenario}`);
		const payload = {
			shoppingPath: "PROSPECT",
			pageUrl: "",
			referrerUrl: "",
			suspendReason: suspendScenario,
		};
		dispatch(logSuspendData(payload));
	}
	}, [suspendScenario]);

	const hasErrors = () => {
		let error = false;
		if (shippingState?.errors) {
			for (const key of Object.keys(shippingState?.errors)) {
				if (shippingState?.errors[key] && shippingState?.errors[key]?.length > 0) {
					error = true;
				}
			}
		}
		if (businessInfoState?.errorTexts?.contactInfo) {
			for (const key of Object.keys(businessInfoState?.errorTexts?.contactInfo)) {
				if (
					businessInfoState?.errorTexts?.contactInfo[key] &&
					businessInfoState?.errorTexts?.contactInfo[key]?.length > 0
				) {
					error = true;
				}
			}
		}
		if (creditCheck5gState?.errorTexts?.corporation) {
			for (const key of Object.keys(creditCheck5gState?.errorTexts?.corporation)) {
				if (
					creditCheck5gState?.errorTexts?.corporation[key] &&
					creditCheck5gState?.errorTexts?.corporation[key]?.length > 0
				) {
					error = true;
				}
			}
		}
		if (
			!shippingState?.businessStreetAddress ||
			(!isTaxIdOptional && !creditCheck5gState?.corporation?.fedTaxId) ||
			!shippingState?.companyName ||
			!shippingState?.phoneNumber ||
			!businessInfoState?.contactInfo?.firstName ||
			!businessInfoState?.contactInfo?.lastName ||
			!businessInfoState?.contactInfo?.email ||
			(!signerAddState?.isSameAddress &&
				!signerAddState?.homeAdressInfo?.displayString) ||
			((isTaxIdOptional ||
				(isCreditChoicePageActive && pageData?.creditPath === "BLENDED")) &&
				!creditCheck5gState?.corporation?.SSN) ||
			!creditCheck5gState?.corporation?.DateOfBirth ||
			!isCheckBoxSelected
		) {
			error = true;
		}

		props?.eventHandlers?.emit(
			error ? "disableContinueBtn" : "enableContinueBtn"
		);
		return error;
	};

	useEffect(() => {
		window.addEventListener("verifyBusiness_continue", onContinue);
		if (sessionCartData?.skipBusinessInfo) {
			//clearNotification();
		} else {
			dispatch(showStickyHeaderValues());
		}
		return () => {
			window.removeEventListener("verifyBusiness_continue", onContinue);
		};
	}, [shippingState, creditCheck5gState]);

	useEffect(() => {
		if (isTaxIdOptional) {
			const creditPath = "BLENDED";
			dispatch(setCreditPath(creditPath));
		}
		dispatch(getSessionCart());
		setShowDDIAModal(false);
		const isDDIA = false;
		const enableVerification = t("ddiaVerification.DDIA.enableVerification", {
			ns: "app",
			returnObjects: true,
		});
		const gemaltoRetryCount = t("ddiaVerification.DDIA.gemaltoRetryCount", {
			ns: "app",
			returnObjects: true,
		});
		if (isDDIA === "1" && pageData?.ddia?.linkUrlResponse?.gemaltoSessionId) {
			if (!enableVerification) {
				verifyCreditDetails();
				dispatch(setShowValidationOverlay(false));
				//navigate("/shop/unified-checkout/credit-result");
			} else {
				dispatch(setShowValidationOverlay(true));
				dispatch(
					ddiaCheckVerificationResult({
						gemaltoSessionId: pageData?.ddia?.linkUrlResponse?.gemaltoSessionId,
						gemaltoRetryCount,
					})
				);
			}
		}
		// SiteAnalytics.businessInfo();
	}, []);

	// useEffect(() => {
	// 	//dispatch(getSessionCart()); //Duplicate session cart api call
	// 	setShowDDIAModal(false);

	// 	if (isMitek === "1") {
	// 		const mitekAemRes = t("ddiaVerification.DDIA", {
	// 			ns: "app",
	// 			returnObjects: true,
	// 		});
	// 		dispatch(setShowValidationOverlay(true));
	// 		dispatch(
	// 			mitekCheckVerificationResult({
	// 				mitekTimeToWaitForResult: mitekAemRes?.mitekTimeToWaitForResult,
	// 				mitekStatusPollInterval: mitekAemRes?.mitekStatusPollInterval,
	// 			})
	// 		);
	// 	}
	// }, []);

	// useEffect(() => {
	// 	const gemaltoProceedOnVerificationNotFound = t(
	// 		"verification.DDIA.gemaltoProceedOnVerificationNotFound",
	// 		{ ns: "checkout", returnObjects: true }
	// 	);
	// 	if (
	// 		pageData?.ddia?.checkVerificationResult?.verificationStatus === "PASSED"
	// 	) {
	// 		verifyCreditDetails();
	// 		dispatch(setShowValidationOverlay(false));
	// 		//navigate("/shop/unified-checkout/credit-result");
	// 	} else if (
	// 		pageData?.ddia?.checkVerificationResult?.verificationStatus ===
	// 			"NOT_FOUND" &&
	// 		gemaltoProceedOnVerificationNotFound
	// 	) {
	// 		verifyCreditDetails();
	// 		dispatch(setShowValidationOverlay(false));
	// 		//navigate("/shop/unified-checkout/credit-result");
	// 	} else if (
	// 		pageData?.ddia?.checkVerificationResult?.verificationStatus === "FAIL" &&
	// 		pageData?.ddia?.checkVerificationResult?.verificationStatus === "FAILED"
	// 	) {
	// 		dispatch(setSuspendScenario("SUSPENDED_FLOW_OTP"));
	// 		dispatch(setShowValidationOverlay(false));
	// 		dispatch(setSuspendFlow(true));
	// 	}
	// 	setVerificationApiRetryCount(verificationApiRetryCount + 1);
	// }, [pageData?.ddia?.checkVerificationResult]);

	// useEffect(() => {
	// 	if (
	// 		sessionCartData?.mitekEnabled &&
	// 		!isMitek &&
	// 		isMobile &&
	// 		continueClicked &&
	// 		pageData?.validateBusinessInfoResponse &&
	// 		Object.keys(pageData?.validateBusinessInfoResponse)?.length &&
	// 		pageData?.validateBusinessInfoResponse?.serviceStatus?.success
	// 	) {
	// 		if (sessionCartData?.businessInfo?.creditApplicationNum) {
	// 			setDisableAcceptContinue(true);
	// 			dispatch(generateMitekVerificationLink());
	// 		} else {
	// 			dispatch(getSessionCart());
	// 		}
	// 	}
	// }, [
	// 	sessionCartData?.mitekEnabled,
	// 	isMitek,
	// 	continueClicked,
	// 	pageData?.validateBusinessInfoResponse,
	// 	sessionCartData,
	// ]);

	// useEffect(() => {
	// 	if (
	// 		sessionCartData?.mitekEnabled &&
	// 		pageData?.mitekVerificationLinkResponse &&
	// 		isMobile &&
	// 		continueClicked
	// 	) {
	// 		window.location.href = pageData?.mitekVerificationLinkResponse?.link;
	// 	}
	// }, [pageData?.mitekVerificationLinkResponse, continueClicked]);

	// useEffect(() => {
	// 	if (pageData?.mitekCheckVerificationProcess?.status?.success) {
	// 		dispatch(setShowValidationOverlay(false));
	// 		dispatch(setShowMitekModal(false));
	// 		setShowMitekSection(false);
	// 	}
	// }, [pageData?.mitekCheckVerificationProcess]);

	// useEffect(() => {
	// 	if (pageData?.mitekCheckVerificationResult?.verificationStatus === "PASSED") {
	// 		dispatch(setShowValidationOverlay(false));
	// 		verifyCreditDetails();
	// 	} else if (
	// 		pageData?.mitekCheckVerificationResult?.verificationStatus === "STARTED"
	// 	) {
	// 		dispatch(setShowMitekModal(false));
	// 		setShowMitekSection(false);
	// 		dispatch(setShowValidationOverlay(true));
	// 	} else if (
	// 		pageData?.mitekCheckVerificationResult?.verificationStatus === "NOT_FOUND"
	// 	) {
	// 		dispatch(setShowMitekModal(false));
	// 		setShowMitekSection(false);
	// 		dispatch(setShowValidationOverlay(false));
	// 	} else if (
	// 		continueClicked &&
	// 		(pageData?.mitekCheckVerificationResult?.verificationStatus ===
	// 			"STORE_VISIT_ENABLED" ||
	// 			pageData?.mitekCheckVerificationResult?.verificationStatus ===
	// 				"INITIATE_BLENDED")
	// 	) {
	// 		dispatch(setShowMitekModal(false));
	// 		setShowMitekSection(false);
	// 		dispatch(setShowValidationOverlay(false));
	// 		//navigate("/shop/uc/personal-validation");
	// 	} else if (
	// 		pageData?.mitekCheckVerificationResult?.verificationStatus === "FAIL" ||
	// 		pageData?.mitekCheckVerificationResult?.verificationStatus === "FAILED" ||
	// 		pageData?.mitekCheckVerificationResult?.verificationStatus === "DECLINED"
	// 	) {
	// 		dispatch(setShowValidationOverlay(false));
	// 		//navigate("/shop/store-search");
	// 	}
	// 	setVerificationApiRetryCount(verificationApiRetryCount + 1);
	// }, [pageData?.mitekCheckVerificationResult]);

	const disableApply = () => {
		if (
			pageData?.creditCheckState?.corporation?.fedTaxId !== "" &&
			(pageData?.creditCheckState?.corporation?.SSN ===
				pageData?.creditCheckState?.corporation?.confirmSSN ||
				(pageData?.creditCheckState?.corporation?.SSN === "" &&
					pageData?.creditCheckState?.corporation?.confirmSSN === ""))
		) {
			return false;
		} else {
			return true;
		}
	};

	const verifyCreditDetails = () => {
		if (
			!creditData?.creditDetailsResponse?.serviceStatus?.success &&
			!creditData?.creditResult?.loading
		) {
			dispatch(setRetries(0));
			dispatch(
				validateCreditDetails({
					flow: flow,
					isCreditHoldPage: false,
					isBlended: sessionCartData?.mitekConsentDeclined || false,
					ssn: pageData?.consetFlowSSN,
					isMitekDeclined: sessionCartData?.mitekConsentDeclined || false,
				})
			);
			dispatch(
				setActiveAccordian(pageData?.activeAccordionDisplayOrder?.creditResult)
			);
		}
	};

	// const dispatchSetShowProgressOverlayAction = (value) => {
	//   dispatch(setShowProgressOverlay(value));
	// };

	useEffect(() => {
		const resp = creditData?.creditDetailsResponse;
		if (resp && resp?.serviceStatus?.success && Object.keys(resp).length) {
			props?.eventHandlers?.emit("updateVerifyBusiness", creditCheck5gState);
		}
	}, [creditData?.creditDetailsResponse]);

	useEffect(() => {
		// setShippingInfoComplete(true);
		// setBusinessInfoComplete(true);
		// setSignerAddComplete(true)
		// setShowBusinessInfo(true);
		// setShowSignerAdd(true);
		// setShowShippingInfo(true);
		// setShowCreditCheck(true);
		const element = document?.getElementById("verifyBusiness");
			element?.scrollIntoView();
	}, []);

	useEffect(() => {
		if (ddiaLinkResponse?.gemaltoLink && !isDDIA) {
			setShowDDIAModal(true);
		}
	}, [ddiaLinkResponse]);

	const onContinueZIP = () => {
		window.sessionStorage.setItem("isZIPmodal", true);
		if (showZipCodeModal) {
			dispatch(setNotification({}));
			const flow = pageData?.unifiedCartParams?.flow ?? lqData?.flow;
			const safetechSessionId = pageData?.safetechSessionId;
			const blackBox = pageData?.blackBox;
			const newCreditCheck5gState = { ...creditCheck5gState?.corporation };
			if (
				(!newCreditCheck5gState?.fedTaxId ||
					newCreditCheck5gState?.fedTaxId === "") &&
				isTaxIdOptional
			) {
				newCreditCheck5gState.fedTaxId = creditCheck5gState?.corporation?.SSN;
				dispatch(setCreditCheckState(newCreditCheck5gState));
			}
			const data = {
				...JSON.parse(localStorage.getItem("shippingState")),
				...JSON.parse(localStorage.getItem("businessState")),
				...JSON.parse(localStorage.getItem("signerState")),
				...JSON.parse(localStorage.getItem("creditCheckState")),
				...businessInfoState,
				...signerAddState,
				...shippingState,
				...newCreditCheck5gState,
				flow,
				safetechSessionId: safetechSessionId,
				blackBox: blackBox,
				prospectCreditPath: pageData?.creditPath,
			};
			// if (data && data?.contactInfo) {
			// 	data.contactInfo["jobTitle"] = businessInfoState?.contactInfo["jobTitle"];
			// }
			setContinueClicked(true);

			//commenting API call for test Purpose
			dispatch(postBusinessInformation(data));
			// SiteAnalytics.eventBusInfoTracking(
			//   "Business Credit Details Submitted",
			//   data
			// );
		}
		setShowZipCodeModal(false);
	};
	const enableCaptcha = false;

	const businessPayload = () => {
		const flow = pageData?.unifiedCartParams?.flow ?? lqData?.flow;
		const safetechSessionId = pageData?.safetechSessionId;
		const blackBox = pageData?.blackBox;
		const newCreditCheck5gState = { ...creditCheck5gState?.corporation };
		if (
			(!newCreditCheck5gState?.fedTaxId ||
				newCreditCheck5gState?.fedTaxId === "") &&
			isTaxIdOptional
		) {
			newCreditCheck5gState.fedTaxId = creditCheck5gState?.corporation?.SSN;
			dispatch(setCreditCheckState(newCreditCheck5gState));
		}
		const data = {
			...JSON.parse(localStorage.getItem("shippingState")),
			...JSON.parse(localStorage.getItem("businessState")),
			...JSON.parse(localStorage.getItem("signerState")),
			...JSON.parse(localStorage.getItem("creditCheckState")),
			...businessInfoState,
			...signerAddState,
			...shippingState,
			...newCreditCheck5gState,
			flow,
			safetechSessionId: safetechSessionId,
			blackBox: blackBox,
			prospectCreditPath: pageData?.creditPath,
		};
		return data;
	};

	const onContinue = (e, captchaToken) => {
		// dispatch(setActiveAccordian(1))
		handleContinueBtnClicked(true);
		if (
			zipcodebyod !== zipcodebusiness &&
			(byodDeviceList?.length > 0 || tabletOfferZipCode?.includes(billingState))
		) {
			setShowZipCodeModal(true);
		} else {
			if (
				enableCaptcha &&
				!(recaptchaRef?.current?.getValue() || captchaToken) &&
				!window?.location?.pathname?.includes("/mbt/prospect/")
			) {
				if (window?.grecaptcha && window?.grecaptcha?.execute) {
					window?.grecaptcha?.execute();
				}
			} else {
				dispatch(setNotification({}));

				// if (data && data?.contactInfo) {
				// 	data.contactInfo["jobTitle"] = businessInfoState?.contactInfo["jobTitle"];
				// }
				setContinueClicked(true);
				const data = businessPayload();
				//commenting API call for test Purpose
				dispatch(postBusinessInformation(data));
				// props?.eventHandlers?.emit('updateVerifyBusiness', creditCheck5gState);

				// SiteAnalytics.eventBusInfoTracking(
				//   "Business Credit Details Submitted",
				//   data
				// );
			}
			dispatch(setSameAsCompanyAddress(sameAsCompanyAddress));
		}
	};

	const saveBusinessInfo = () => {
		const data = businessPayload();
		dispatch(saveBusinessInformation(data));
	};
	const onShippingContinue = () => {
		setShippingInfoComplete(true);
		setShowBusinessInfo(true);
		// setShowSignerAdd(true);
		setShowShippingInfo(false);
		if (isMobile) {
			const element = document.getElementById("accordion-anchor");
			element.scrollIntoView();
		}
	};

	const onBusinessInfoContinue = () => {
		setBusinessInfoComplete(true);
		//setShowShippingInfo(true);
		setShowSignerAdd(true);
		setShowBusinessInfo(false);
		setShowCreditCheck(false);
		if (isMobile) {
			const element = document.getElementById("accordion-anchor");
			element.scrollIntoView();
		}
	};

	const onSignerAddContinue = () => {
		setSignerAddComplete(true);
		setShowCreditCheck(true);
		setShowBusinessInfo(false);
		setShowBusinessInfo(false);
		setShowSignerAdd(false);
		if (isMobile) {
			const element = document.getElementById("accordion-anchor");
			element.scrollIntoView();
		}
	};

	const onOtpSkip = () => {
		setOneTimePassCodeComplete(true);
		setShowOneTimePassCode(false);
		if (mitekEnabled) {
			dispatch(setShowMitekModal(true));
			setShowMitekSection(true);
		} else {
			otpSuccess();
		}
	};

	const onCreditInfoContinue = () => {
		setCreditSectionComplete(true);
		setShowAcceptAndContinue(true);
		setShowCreditCheck(false);
	};

	const onOTPPhoneRecommendationStop = () => {
		//setOneTimePassCodeComplete(true);
		setOtpFailed(true);
		// if (mitekEnabled) {
		// 	dispatch(setShowMitekModal(true));
		// 	setShowOneTimePassCode(false);
		// 	setShowMitekSection(true);
		// } else {
		// 	otpSuccess();
		// }
	};

	const onOTPValidateResponseFail = () => {
		//setOneTimePassCodeComplete(true);
		setOtpFailed(true);
		// if (mitekEnabled) {
		// 	dispatch(setShowMitekModal(true));
		// 	setShowOneTimePassCode(false);
		// 	setShowMitekSection(true);
		// } else {
		// 	verifyCreditDetails();
		// }
	};
	const onOTPNotEligible = () => {
		setIsOTPNotEligible(true);
	};

	const onOTPValidateResponseSuccess = () => {
		setOneTimePassCodeComplete(true);
		if (mitekEnabled && pageData?.creditPath === "BizOnlyCredit") {
			dispatch(setShowMitekModal(true));
			setShowOneTimePassCode(false);
			setShowMitekSection(true);
		} else if (validateResponse?.data?.svcCode === "M120") {
			otpSuccess();
		} else if (
			sessionCartData?.enableBizOtpSuspend &&
			pageData?.creditPath === "BizOnlyCredit" &&
			!showDdiaPage &&
			!mitekEnabled
		) {
			suspendOTPFlow();
		} else {
			onOTPValidateResponseFail();
		}
	};

	const otpSuccess = () => {
		setOneTimePassCodeComplete(true);
		// if (mitekEnabled) {
		// 	dispatch(setShowOneTimePassModal(false));
		// 	setShowOneTimePassCode(false);
		// 	setShowMitekSection(true);
		// 	dispatch(setIsVerifyBusiness(false));
		// } else {
		verifyCreditDetails();
		//}
	};

	const suspendOTPFlow = (scenario = null) => {
		dispatch(setSuspendScenario(scenario ?? "SUSPENDED_FLOW_OTP"));
		dispatch(setSuspendFlow(true));
	};

	useEffect(() => {
		if (pageData?.fetchData?.businessInfo) {
			dispatch(
				setBusinessState({
					...businessInfoState,
					...pageData?.fetchData?.businessInfo,
				})
			);
		}
	}, [pageData]);

	useEffect(() => {
		if (pageData?.fetchData?.businessInfo) {
			setSignerAddState({
				...signerAddState,
				...pageData?.fetchData?.businessInfo,
			});
		}
	}, [pageData]);

	useEffect(() => {
		if (
			pageData?.activeAccordian ===
				pageData?.activeAccordionDisplayOrder?.businessInfo &&
			!pageData?.isShoppingCartApiCallInProgress?.postBusinessInformation &&
			pageData?.postBusinessInfoResponse &&
			pageData?.validateBusinessInfoResponse &&
			Object.keys(pageData?.postBusinessInfoResponse)?.length &&
			Object.keys(pageData?.validateBusinessInfoResponse)?.length &&
			(isMobile ? !sessionCartData?.mitekEnabled : true) &&
			pageData?.postBusinessInfoResponse?.serviceStatus?.success &&
			pageData?.validateBusinessInfoResponse?.serviceStatus?.success
		) {
			if(standAloneBuSS){
				dispatch(setActiveAccordian(pageData?.activeAccordionDisplayOrder?.payment));
			}else if (sessionCartData?.businessInfo?.creditApplicationNum) {
				if (!Utils.is5G4GQuote(pageData) && continueClicked) {
					if (
						flow === "prospect" &&
						isDigitalQuote &&
						pageData?.creditPath === "BLENDED" &&
						!pageData?.postBusinessInfoResponse?.mitekEnabled
					) {
						otpSuccess();
					} else {
						if (showOtpModal) {
							dispatch(setShowOneTimePassModal(true));
							setShowOneTimePassCode(true);
						} else if (mitekEnabled) {
							setOneTimePassCodeComplete(true);
							dispatch(setShowMitekModal(true));
							setShowOneTimePassCode(false);
							setShowMitekSection(true);
						} else {
							verifyCreditDetails();
						}
						onCreditInfoContinue();
						// dispatch(setActiveAccordian(sessionCartData?.showOtpModal? pageData?.activeAccordionDisplayOrder?.otp : pageData?.activeAccordionDisplayOrder?.creditResult))
					}
				} else if (
					continueClicked &&
					(flow === "5g" || flow === "4g" || Utils.is5G4GQuote(pageData)) &&
					pageData?.validateBusinessInfoResponse &&
					Object.keys(pageData?.validateBusinessInfoResponse)?.length
				) {
					if (showOtpModal) {
						dispatch(setShowOneTimePassModal(true));
						setShowOneTimePassCode(true);
					} else if (mitekEnabled) {
						setOneTimePassCodeComplete(true);
						dispatch(setShowMitekModal(true));
						setShowOneTimePassCode(false);
						setShowMitekSection(true);
					} else {
						verifyCreditDetails();
					}
					onCreditInfoContinue();
					// dispatch(setActiveAccordian(sessionCartData?.showOtpModal? pageData?.activeAccordionDisplayOrder?.otp : pageData?.activeAccordionDisplayOrder?.creditResult))
				}
			} else {
				dispatch(getSessionCart());
			}
		}
	}, [
		pageData?.isShoppingCartApiCallInProgress?.postBusinessInformation,
		pageData?.postBusinessInfoResponse,
		pageData?.validateBusinessInfoResponse,
		sessionCartData?.mitekEnabled,
		sessionCartData,
	]);

	useEffect(() => {
		if (
			!pageData?.postBusinessInfoResponse?.serviceStatus?.success &&
			pageData?.postBusinessInfoResponse?.serviceStatus?.statusCode ===
				"SUSPENDED_ECPDLOOKUP_CHECK"
		) {
			dispatch(setSuspendScenario("SUSPENDED_ECPDLOOKUP_CHECK"));
			dispatch(setSuspendFlow(true));
		} else if (
			!pageData?.postBusinessInfoResponse?.serviceStatus?.success &&
			pageData?.postBusinessInfoResponse?.serviceStatus?.statusCode?.includes(
				"SUSPENDED_ECPDLOOKUP_EXISTING_USER"
			)
		) {
			dispatch(setExisitingCustomer(true));
			// Not to show modal only to show inline msg
			// dispatch(
			// 	setSuspendScenario(
			// 		pageData?.postBusinessInfoResponse?.serviceStatus?.statusCode
			// 	)
			// );
			// dispatch(setSuspendFlow(true));
		} else if (
			!pageData?.postBusinessInfoResponse?.serviceStatus?.success &&
			pageData?.postBusinessInfoResponse?.serviceStatus?.statusCode ===
				"SUSPENDED_FLOW_ECPD"
		) {
			dispatch(setSuspendScenario("SUSPENDED_FLOW_ECPD"));
			dispatch(setSuspendFlow(true));
		} else if (
			pageData?.postBusinessInfoResponse?.serviceStatus &&
			continueClicked &&
			!pageData?.postBusinessInfoResponse?.serviceStatus?.success &&
			pageData?.postBusinessInfoResponse?.serviceStatus?.statusMessage !== "200"
		) {
			let notification = {
				show: true,
				type: "error",
				title: t(`verification.api_errors.something-went-wrong`, {
					ns: "checkout",
				}),
				subtitle: "",
			};
			if (
				apiErrors &&
				Object.keys(apiErrors)?.length &&
				Object.keys(apiErrors)?.includes(
					pageData?.postBusinessInfoResponse?.serviceStatus?.statusMessage
				)
			) {
				notification = {
					show: true,
					type: "error",
					title: t(
						`verification.api_errors.${pageData?.postBusinessInfoResponse?.serviceStatus?.statusMessage}`,
						{
							ns: "checkout",
						}
					),
					subtitle: "",
				};
			}
			dispatch(setNotification(notification));
		} else if (
			pageData?.postBusinessInfoResponse?.serviceStatus &&
			continueClicked &&
			!pageData?.postBusinessInfoResponse?.serviceStatus?.success &&
			pageData?.postBusinessInfoResponse?.serviceStatus?.statusCode ==
				"ERROR_RC2_001"
		) {
			let notification = {
				show: true,
				type: "error",
				title: t(`verification.api_errors.something-went-wrong`, {
					ns: "checkout",
				}),
				subtitle: "",
			};
			dispatch(setNotification(notification));
		}
	}, [pageData?.postBusinessInfoResponse]);

	return (
		<div id="verifyBusiness" className="verifyBusiness">
			{pageData?.isShoppingCartApiCallInProgress?.postBusinessInformation && (
				<SpinnerComponent />
			)}
			{/* <div>
        {(sessionCartData?.skipBusinessInfo) && (
          <BusinessInfoExistModal></BusinessInfoExistModal>
        )}
      </div> */}
			<div className={isDDIA ? "container hide-verifyBusiness" : "container"}>
				<Grid
					colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
					colGutter={false}
					rowGutter="5"
				>
					<Row>
						<Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
							<MarginSpacerXL />
							<Body size="large" primitive="h2">
								{t("verification.softCreditText", { ns: "checkout" })}
							</Body>
							<MarginSpacerXL />
						</Col>
					</Row>
				</Grid>
				<div id="accordion-anchor">
					<Accordion type="multi">
						{
							<AccordionItem
								opened={showShippingInfo}
								type="multi"
								className="br-btm tootltip-set"
							>
								<AccordionHeader
									triggerType="icon"
									className="pad-tp-4 acc-head br-tp"
									onClick={(e) => {
										e.stopPropagation();
										e.preventDefault();
										if (isShippingInfoComplete) {
											setShowShippingInfo(!showShippingInfo);
										}
										return false;
									}}
									data-analyticstrack="Company Information"
								>
									<AccordionTitle>
										{t("verification.businessTitle", { ns: "checkout" })}
										{showShippingInfo && !isShippingInfoComplete && (
											<span className="required-arrow">
												<span className="arrow-left"></span>
												<span className="required-label">Required</span>
											</span>
										)}
									</AccordionTitle>
								</AccordionHeader>
								<AccordionDetail>
									<ShippingInfo
										{...props}
										hideContinue={isShippingInfoComplete}
										isTaxIdOptional={isTaxIdOptional}
										prospect5gLoopQualAddress={sessionCartData?.prospect5gLoopQualAddress}
										setShippingState={(data) => {
											dispatch(setShippingState(data));
											localStorage.setItem("shippingState", JSON.stringify(data));
										}}
										onContinue={onShippingContinue}
										shippingPageData={pageData}
										shoppingCartData={shoppingCartData}
										saveBusinessInfo={saveBusinessInfo}
										sessionCartData={sessionCartData}
									/>
								</AccordionDetail>
							</AccordionItem>
						}
						{!!isShippingInfoComplete && (
							<AccordionItem
								opened={showBusinessInfo}
								type="multi"
								className="pad-tp-8 tootltip-set br-btm"
							>
								<AccordionHeader
									triggerType="icon"
									className="pad-tp-4 acc-head"
									data-analyticstrack="Contact information"
									onClick={(e) => {
										e.stopPropagation();
										e.preventDefault();
										if (isBusinessInfoComplete) {
											setShowBusinessInfo(!showBusinessInfo);
										}
										return false;
									}}
								>
									<AccordionTitle>
										{t("verification.contactTitle", { ns: "checkout" })}
										{showBusinessInfo && (
											<span className="required-arrow">
												<span className="arrow-left"></span>
												<span className="required-label">Required</span>
											</span>
										)}
									</AccordionTitle>
								</AccordionHeader>
								<AccordionDetail>
									<Grid
										colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
										colGutter={false}
										rowGutter="5"
									>
										<Row>
											<Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
												<MarginSpacerS />
												<Body size="medium" primitive="h2">
													{t("verification.contactInfoText", { ns: "checkout" })}
												</Body>
												<MarginSpacerM />
											</Col>
										</Row>
									</Grid>
									<BusinessInfo
										{...props}
										data={pageData?.businessInfo}
										shippingInfo={shippingState}
										prospect5gLoopQualAddress={sessionCartData?.prospect5gLoopQualAddress}
										setBusinessState={(data) => {
											dispatch(setBusinessState(data));
											localStorage.setItem("businessState", JSON.stringify(data));
										}}
										hideContinue={true}
										onContinue={onBusinessInfoContinue}
										shoppingCartData={shoppingCartData}
										sessionCartData={sessionCartData}
									/>
									<SignerAdd
										{...props}
										data={pageData?.businessInfo}
										shippingInfo={shippingState}
										businessInfoState={businessInfoState}
										prospect5gLoopQualAddress={sessionCartData?.prospect5gLoopQualAddress}
										setSignerAddState={(data) => {
											setSignerAddState(data);
											localStorage.setItem("signerState", JSON.stringify(data));
										}}
										hideContinue={isSignerAddComplete}
										onContinue={onSignerAddContinue}
										shippingPageData={pageData}
										saveBusinessInfo={saveBusinessInfo}
										sessionCartData={sessionCartData}
									/>
								</AccordionDetail>
							</AccordionItem>
						)}
						{/* {!!isBusinessInfoComplete && (
              <AccordionItem
                opened={showSignerAdd}
                type="multi"
                className="br-btm tootltip-set"
              >
                <AccordionHeader
                  triggerType="icon"
                  className="pad-tp-4 acc-head br-tp"
                  data-analyticstrack="Enter authorized signer's home address"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (isSignerAddComplete) {
                      setShowSignerAdd(!showSignerAdd);
                    }
                    return false;
                  }}
                >
                  <AccordionTitle>Enter authorized signer's home address.</AccordionTitle>
                </AccordionHeader>
                <AccordionDetail>
                  <SignerAdd
                    {...props}
                    data={pageData?.businessInfo}
                    shippingInfo={shippingState}
                    businessInfoState={businessInfoState}
                    prospect5gLoopQualAddress={
                      sessionCartData?.prospect5gLoopQualAddress
                    }
                    setSignerAddState={(data) => {
                      setSignerAddState(data);
                      localStorage.setItem("signerState", JSON.stringify(data));
                    }}
                    hideContinue={isSignerAddComplete}
                    onContinue={onSignerAddContinue}
                  />
                </AccordionDetail>
              </AccordionItem>
            )} */}
						{!!isSignerAddComplete && (
							<AccordionItem
								opened={showCreditCheck}
								type="multi"
								className="pad-tp-8 tootltip-set br-btm"
							>
								<AccordionHeader
									triggerType="icon"
									className="pad-tp-4 acc-head"
									data-analyticstrack="Credit approval information"
									onClick={(e) => {
										e.stopPropagation();
										e.preventDefault();
										if (isCreditSectionComplete) {
											setShowCreditCheck(!showCreditCheck);
										}
										return false;
									}}
								>
									<AccordionTitle>
										{t("verification.creditApprovalTitle", { ns: "checkout" })}
										{showCreditCheck && (
											<span className="required-arrow">
												<span className="arrow-left"></span>
												<span className="required-label">Required</span>
											</span>
										)}
									</AccordionTitle>
								</AccordionHeader>
								<AccordionDetail>
									<Grid
										colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
										colGutter={false}
										rowGutter="5"
									>
										<Row>
											<Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
												<MarginSpacerS />
												<Body size="medium" primitive="h2">
													{t("verification.creditApprovalText", { ns: "checkout" })}
												</Body>
												<MarginSpacerM />
											</Col>
										</Row>
									</Grid>
									<CreditCheck5g
										{...props}
										data={pageData?.creditCheck5g}
										hideContinue={false}
										isCreditChoicePageActive={isCreditChoicePageActive}
										isTaxIdOptional={isTaxIdOptional}
										setCreditCheckState={(data) => {
											dispatch(setCreditCheckState(data));
											localStorage.setItem("creditCheckState", JSON.stringify(data));
										}}
										onContinue={onCreditInfoContinue}
										continueBtnClicked={continueBtnClicked}
									/>
									{!!hasErrors() && <></>}
									{/* {!!isSignerAddComplete && (
										<div className="container">
											<Grid colGutter={false} style={{ paddingTop: "1rem" }}>
												<Row>
													<Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
														<Checkbox
															disabled={false}
															error={false}
															errorText=""
															label=""
															onChange={(e) => setIsCheckBoxSelected(e?.target?.checked)}
															onKeyDown={(e) => setIsCheckBoxSelected(e?.target?.checked)}
															selected={isCheckBoxSelected}
															width="100%"
														>
															<Body size="small" primitive="p">
																<StyledSpan
																	dangerouslySetInnerHTML={{
																		__html: authorizeTextForFullAutomation,
																	}}
																/>
															</Body>
														</Checkbox>
													</Col>
												</Row>
											</Grid>
										</div>
									)} */}
									<Grid
										colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
										colGutter={false}
										rowGutter="5"
									>
										{/* <div className="digicaptcha">
											{isSignerAddComplete ? (
												<Row>
													{hasCaptcha ? (
														<Col colSizes={{ mobile: 4, tablet: 9, desktop: 9 }}>
															<div className="captchaContainer">
																<ReCAPTCHA
																	ref={recaptchaRef}
																	height="60"
																	role="presentation"
																	name="a-avb9eym4nsmt"
																	frameBorder="0"
																	scrolling="no"
																	size="invisible"
																	badge="inline"
																	sitekey={captcha}
																	onChange={(e) => {
																		const recaptchaValue = recaptchaRef?.current?.getValue();
																		setIsCaptchaValue(recaptchaValue);
																		onContinue(e, recaptchaValue);
																	}}
																	onExpired={() => {
																		setIsCaptchaValue("");
																	}}
																/>
															</div>
														</Col>
													) : null}
													<Col colSizes={{ mobile: 4, tablet: 9, desktop: 9 }}>
														<div className="digicertContainer">
															<img
																src="https://ss7.vzw.com/is/image/VerizonWireless/digital-digicert-seal-2023?scl=1"
																alt="digicert-Seal Icon"
															/>
														</div>
													</Col>
												</Row>
											) : (
												<></>
											)}
										</div> */}
										{scenarioContent ? (
											<Row>
												<Col colSizes={{ mobile: 4, tablet: 9, desktop: 9 }}>
												<Notification
													type={t(`${scenarioContent}.widgetErrorType`, { ns: "suspend" })}
													title={
														<span
															dangerouslySetInnerHTML={{
																__html: t(`${scenarioContent}.heading`, { ns: "suspend" }),
															}}
														/>
													}
													subtitle={
														<span
															dangerouslySetInnerHTML={{
																__html: t(`${scenarioContent}.heading`, { ns: "suspend" }),
															}}
														/>
													}
													fullBleed={false}
													inlineTreatment={false}
													hideCloseButton={false}
													layout="vertical"
												/>
												</Col>
											</Row>
										) : (
											<></>
										)}
										{isSignerAddComplete ? (
											<Row className="credit-continue-section">
												<Col colSizes={{ mobile: 4, tablet: 9, desktop: 9 }}>
													<div
														className="continue-btn"
														style={{ marginTop: "1rem" }}
													>
														<Button onClick={onContinue} disabled={disableApply()}>
															{t("verification.acceptContinue", { ns: "checkout" })}
														</Button>
													</div>
												</Col>
											</Row>
										) : (
											<></>
										)}
										{sessionCartData?.cart?.cartId ? (
											<SessionCartId sessionCartData={sessionCartData} />
										) : (
											<></>
										)}
									</Grid>
								</AccordionDetail>
							</AccordionItem>
						)}

{!!isCreditSectionComplete && showOtpModal && !standAloneBuSS && (
							<AccordionItem
								opened={showOneTimePassCode}
								type="multi"
								className="pad-tp-8 tootltip-set br-btm"
							>
								<AccordionHeader
									triggerType="icon"
									className="pad-tp-4 acc-head"
									data-analyticstrack="Otp modal"
									onClick={(e) => {
										e.stopPropagation();
										e.preventDefault();
										// if (isCreditSectionComplete) {
										//   setShowCreditCheck(!showCreditCheck);
										// }
										return false;
									}}
								>
									<AccordionTitle>
										{t("verification.oneTimePasscode", { ns: "checkout" })}
										{otpFailed && !isOTPNotEligible ? (
											<span className="otp-failed">
												<Icon name="error" color={"rgb(255, 128, 39)"} size="medium" />
												{t("verification.optixCheckout.otpFailed", {
													ns: "checkout",
												})}
											</span>
										) : (
											""
										)}
										{showOneTimePassCode && !oneTimePassCodeComplete && (
											<span className="required-arrow">
												<span className="arrow-left"></span>
												<span className="required-label">Required</span>
											</span>
										)}
									</AccordionTitle>
								</AccordionHeader>
								<AccordionDetail>
									<OneTimePasscodeOptix
										businessInfo={businessInfoState}
										creditCheckInfo={
											creditCheck5gState ||
											JSON.parse(localStorage.getItem("creditCheckState"))
										}
										creditPath={pageData?.creditPath}
										mobileNumber={""}
										onNextBtnClick={otpSuccess}
										onOTPPhoneRecommendationStop={onOTPPhoneRecommendationStop}
										onOTPValidateResponseSuccess={onOTPValidateResponseSuccess}
										onOTPValidateResponseFail={onOTPValidateResponseFail}
										onOTPNotEligible={onOTPNotEligible}
										onOtpSkip={onOtpSkip}
										shippingInfo={shippingState}
										showOneTimePassModal={pageData?.showOneTimePassModal}
										sessionCartData={sessionCartData}
										setShowProgressModal={(val) => {
											setShowValidationOverlay(val);
											dispatch(setShowOneTimePassModal(false));
										}}
										otpFailed={otpFailed}
									/>
								</AccordionDetail>
							</AccordionItem>
						)}
						{!!isCreditSectionComplete && oneTimePassCodeComplete && mitekEnabled && !standAloneBuSS && (
							<AccordionItem
							opened={showMitekSection}
							type="multi"
								className="pad-tp-8 tootltip-set br-btm"
							>
								<AccordionHeader
									triggerType="icon"
									className="pad-tp-4 acc-head"
									data-analyticstrack="Mitek"
									onClick={(e) => {
										e.stopPropagation();
										e.preventDefault();
										// if (isCreditSectionComplete) {
										//   setShowCreditCheck(!showCreditCheck);
										// }
										return false;
									}}
								>
									<AccordionTitle>
										{t("verification.mitekVerification", { ns: "checkout" })}{" "}
										{showMitekSection &&
											pageData?.activeAccordian <
												pageData?.activeAccordionDisplayOrder?.businessInfo && (
												<span className="required-arrow">
													<span className="arrow-left"></span>
													<span className="required-label">Required</span>
												</span>
											)}
									</AccordionTitle>
								</AccordionHeader>
								<AccordionDetail>
									<MitekOptix sessionCartData={sessionCartData} />
								</AccordionDetail>
							</AccordionItem>
						)}
					</Accordion>
				</div>
			</div>
			{/* <OneTimePassModal
        businessInfo={businessInfoState}
        creditCheckInfo={creditCheck5gState}
        creditPath={creditPathSelected}
        mobileNumber={""}
        onOpenedChange={{}}
        onNextBtnClick={otpSuccess}
        onOTPPhoneRecommendationStop={onOTPPhoneRecommendationStop}
        onOTPValidateResponseSuccess={onOTPValidateResponseSuccess}
        onOTPValidateResponseFail={onOTPValidateResponseFail}
        shippingInfo={shippingState}
        showOneTimePassModal={showOneTimePassModal}
        setShowProgressModal={(val) => {
          setShowValidationOverlay(val)
          dispatch(setShowOneTimePassModal(false))
        }}
      /> */}
			{/* <MitekModal
        onOpenedChange={setShowMitekModal}
        showMitekModal={showMitekModal}
        setShowProgressModal={(val) => {
          //setShowValidationOverlay(val)
          dispatch(setShowMitekModal(!val))
        }}
      /> */}
			{showZipCodeModal && (
				<Modal onOpenedChange={setShowZipCodeModal} opened={showZipCodeModal}>
					<ModalTitle>
						{t("verification.differentZipModalBYOD.header", {
							ns: "checkout",
						})}
					</ModalTitle>
					<ModalBody className="noPadding">
						<Body size="large" primitive="h2">
							{t("verification.differentZipModalBYOD.info", {
								ns: "checkout",
							})}
						</Body>
						<br></br>
						<Body size="large" primitive="h2">
							{t("verification.differentZipModalBYOD.confirmMsg", {
								ns: "checkout",
							})}
						</Body>
					</ModalBody>
					<ModalFooter
						buttonData={{
							primary: {
								children: "Go back",
								onClick: () => setShowZipCodeModal(false),
							},
							close: {
								children: "Continue",
								onClick: () => onContinueZIP(),
							},
						}}
					/>
				</Modal>
			)}

			<ProgressOverlayWrapper>
				<ProgressOverlay
					modalDesc={""}
					modalFooter={t("ddiaVerification.DDIA.mitek.progress.subtext", {
						ns: "app",
					})}
					modalHeading={t("ddiaVerification.DDIA.mitek.progress.heading", {
						ns: "app",
					})}
					onOpenedChange={setShowValidationOverlay}
					onNextBtnClick={() => {}}
					progress={progressOverlaycount}
					showProgressOverlay={showValidationOverlay}
				/>
				<ProgressOverlay
					modalDesc={t("automation.creditFraud.desc", { ns: "checkout" })}
					modalFooter={t("automation.creditFraud.footer", { ns: "checkout" })}
					modalHeading={t("automation.creditFraud.heading", { ns: "checkout" })}
					onOpenedChange={() => {}}
					onNextBtnClick={() => {}}
					progress={(creditData?.retries || 1) * 12.5}
					showProgressOverlay={creditData?.showProgressOverlay}
				/>
			</ProgressOverlayWrapper>
		</div>
	);
};

export const ProgressOverlayWrapper = styled.div`
	@media only screen and (max-width: 600px) {
		> div > div > div > div > div {
			top: 150px;
			padding: 0 12px;
		}
	}
`;

export default VerifyBusiness;
