import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { Button } from "@vds3/buttons";
import { Grid, Row, Col } from "@vds3/grids";
import { Body } from "@vds3/typography";
import { useGlobalData } from "hooks";
import CartContext from "pages/ShoppingCart/cartContext";

const CartStickyFooter = ({
	continueBtnText,
	disabledContinueBtn,
	onContinue,
	checkoutBtnText,
	onCheckout,
	...props
}) => {
	const { t } = useTranslation(["app"]);
	const isMobile = useMediaQuery({
		query: "(max-width: 992px)",
	});
	const { sessionCartData } = useGlobalData();
	// const cartData = sessionCartData;
	const { cartData } = useContext(CartContext);
	const billing =
		cartData?.discAmountPresentInCart && cartData?.displayTotalAmounts
			? cartData?.displayTotalAmounts
			: cartData?.totalAmounts;

	// if (!isMobile) {
	//   return false;
	// } else
	return (
		<StickyWrapper>
			<StickyContainer
				className={
					isMobile &&
					continueBtnText === t("app.common.page-stepper.select-protection-btn")
						? "prot-footer"
						: ""
				}
			>
				<Grid colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
					<Row>
						<Col>
							<FlexContainer className="flex-col">
								{!!isMobile && (
									<Totals
										dueMonthly={billing?.dueMonthly}
										dueToday={billing?.dueToday}
									/>
								)}
								{props?.enableSendQuote && 
								<Button
									use="primary"
									onClick={() => onContinue()}
									disabled={disabledContinueBtn}
									size={isMobile ? "small" : "large"}
									style={{
										height: isMobile ? "32px" : "",
										width: !isMobile
											? continueBtnText !==
											  t("app.common.page-stepper.select-protection-btn")
												? "202px"
												: ""
											: "",
									}}
								>
									{continueBtnText
										? continueBtnText
										: t("app.common.page-stepper.continue-btn", {
												ns: "app",
										  })}
								</Button>
                               }

								{props?.enableCheckout && (
									<Button
										use="primary"
										disabled={props?.checkoutDisable}
										onClick={() => onCheckout()}
										size={isMobile ? "small" : "large"}
										style={{
											height: isMobile ? "32px" : "",
										}}
									>
										{checkoutBtnText}
									</Button>
								)}
							</FlexContainer>
						</Col>
					</Row>
				</Grid>
			</StickyContainer>
		</StickyWrapper>
	);
};

const Totals = ({ dueMonthly, dueToday }) => {
	const { t } = useTranslation(["shoppingCart"]);
	return (
		<TotalsDiv>
			<div>
				<div style={{ width: "max-content", marginRight: "8px" }}>
					<Body size="small" color="#000">
						{t("shopping-cart.cart.dueMonthly")}
					</Body>
				</div>
				<Body bold size="large" color="#000">
					{`$${(dueMonthly ?? 0).toFixed(2)}`}
				</Body>
			</div>
			<div>
				<div style={{ width: "max-content", marginRight: "8px" }}>
					{/* <Body  size="small">{t('app.common.orderSummary.dueToday', { ns: "app" })} </Body> */}
					<Body size="small" color="#000">
						{t("shopping-cart.cart.dueToday")}
					</Body>
				</div>
				<Body bold size="large" color="#000">
					{`$${(dueToday ?? 0).toFixed(2)}`}
				</Body>
			</div>
		</TotalsDiv>
	);
};

const StickyWrapper = styled.div`
	.prot-footer {
		height: 6.75rem;
		.flex-col {
			flex-direction: column;
			button {
				margin-top: 0.5rem;
				width: 100%;
			}
		}
	}
`;

const StickyContainer = styled.div`
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 999;
	background-color: white;
	width: 100%;
	height: 4.75rem;
	margin: 0 auto;
	padding: 1rem;
`;

const FlexContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
`;

const TotalsDiv = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1.5rem;
`;

export default CartStickyFooter;
