import React, { useEffect, useState } from "react";
import { Body } from "@vds3/typography";
import { Title } from "@vds3/typography";
import styled from "styled-components";
import {
    MarginSpacerM,
    MarginSpacerXL,
    MarginSpacerXS,
} from "common/components/styleTag";
import { Button } from "@vds3/buttons";
import { formatPriceString } from "utils/commonUtils";
import _ from "lodash";
import { InputStepper } from '@vds/input-stepper'
import { BUSSModal } from "pages/BussProductsPage/BussModal";
import { Carousel } from "@vds3/carousels";
import { Tilelet } from "@vds3/tiles";
import { RadioBoxGroup } from "@vds3/radio-boxes";
import { Tabs, Tab } from "@vds3/tabs";
import { BussFeature, BussFeatureHalf, BussGCFeature, BussLookoutSection, GreyContainer } from "pages/BussProductsPage/BussProductPDP";
import { Toggle } from "@vds3/toggles";
import { Table,  TableHead,  TableHeader,  TableBody,  TableRow,  Cell } from "@vds3/tables";
import { useMediaQuery } from "react-responsive";


const PdpPlanCard = ({
    planDetails,
    handleContinueClick,
    planCard,
    dataTosend = {},
    type = "",
    frequency = "",
    selectedPlanID = ""
}) => {
    const [selectedPlanCat, setSelectedPlanCat] = useState([])
    const [defaultData, setDefaultData] = useState()
    const [openModal, setModal] = useState(false)
    const [currentId, setCurrentId] = useState('')
    const [storeData, setStoreData] = useState({})
	const [toggleOn, setToggleOn] = useState(false)
    const isMobile = useMediaQuery({query: "(max-width: 600px)"})
    const getTitle = (item, desc) => {
        if (item?.includes("-")) {
            return desc?.slice(0, 60);
        }
        return item?.slice(0, 60);
    };
    const getLineText = (frequency) => {
        if (frequency === "MONTHLY") {
            return planDetails?.perLine;
        } else if (frequency === "ANNUAL") {
            return planDetails?.annually;
        } else if (frequency === "ONE TIME") {
            return planDetails?.oneTime;
        }
        return planDetails?.perLine;
    };
    useEffect(() => {
        if (!!dataTosend && Object.keys(dataTosend).length) {
            setStoreData({ ...storeData, ...dataTosend })
        }
    }, [dataTosend])
    const slicedPlansList = React.useMemo(() => {
        let plansDta = planCard;

        let plans = [];
        if (plansDta?.length ) {
            if(Array.isArray(plansDta)){
                plans = [...plansDta]
            }else{
            Object.values(plansDta).forEach((item) => {
                if (item && Object.values(item)?.length) {
                    let items = Object.values(item);
                    plans = [...plans, ...items];
                }
            });
        }
        }
        plans = _.flatten(plans);
        let filteredPlans = [],
            yearlyPlan = false;
        plans?.forEach((item) => {
            let planData = item;
            if (!!planData) {
                if (item?.pricingOptions?.length) {
                    item?.pricingOptions?.some((pack) => {
                        if (pack?.frequency === "ANNUAL") {
                            yearlyPlan = true;
                            return true;
                        }
                    });
                }
                filteredPlans.push(item);
            }
        });

        return filteredPlans;
    }, [planCard]);
    const handleButtonClick = (isCatPresent=[],item,data=undefined)=>{
        if (!!isCatPresent && isCatPresent.length) {
            setSelectedPlanCat(isCatPresent)
            setModal(true)
            setDefaultData(item?.id || item?.catID)
        } else {
        handleContinueClick(item?.id || item?.catID,data);
        }
    }
    const cardData = () => {
        let data = []
        slicedPlansList?.map((item) => {
            let planData = item;
            const plasnSelected = selectedPlanID && selectedPlanID === item['id']
            const isCatPresent = item?.productOfferingCharacteristics !== null && item?.productOfferingCharacteristics && item?.productOfferingCharacteristics.length ? item?.productOfferingCharacteristics : []
                if (!!planData) {
                    data.push({
                        title: {
                            children: <TileContainer key={item?.id}>
                                <TileWrapper>
                                    <CutomTitle>
                                        <Body size="medium" bold>
                                            {getTitle(planData?.name, item?.shortDescription)}
                                        </Body>
                                    </CutomTitle>
                                    <MarginSpacerM />
                                    {/* <PriceLockup>
                                        <Title size="large" bold={true}>
                                            {formatPriceString(price?.purchasePrice)}
                                        </Title>
                                        <Body size="small">{getLineText(frequency)}</Body>
                                        <MarginSpacerXS />
                                    </PriceLockup>
                                    <MarginSpacerXL /> */}

                                    <Button
                                        use="secondary"
                                        size="small"
                                        width="100%"
                                        disabled = {plasnSelected}
                                        onClick={() => handleButtonClick(isCatPresent,item)}>
                                        {plasnSelected ? "Selected" : "Select"}
                                    </Button>
                                    {/* <MarginSpacerXL />
                                    <Body>
                                        {item?.longDescription || item?.shortDescription}
                                    </Body> */}
                                </TileWrapper>
                            </TileContainer>
                        }
                    });
                }
            });
        return data
    }
    const TableView = ()=>{
        return(
            <div className="tableView-container">
                <Table
                    striped={false}
                    bottomLine="secondary"
                    padding="standard"
                >
                    <TableHead bottomLine="primary">
                    <TableHeader>Plans</TableHeader>
                    <TableHeader>Quantity </TableHeader>
                        {/* <TableHeader>PRICE</TableHeader> */}
                        {/* <TableHeader>TOTAL</TableHeader> */}
                    </TableHead>
                    <TableBody>
                        {slicedPlansList.map(slicedPlan => {
                            const isCatPresent = slicedPlan?.productOfferingCharacteristics !== null && slicedPlan?.productOfferingCharacteristics && slicedPlan?.productOfferingCharacteristics.length ? slicedPlan?.productOfferingCharacteristics : []
                            const frequencySelected = frequency
                            const price = (slicedPlan?.pricingOptions && frequency === slicedPlan?.pricingOptions[0]?.priceType) ? slicedPlan?.pricingOptions[0]?.price : slicedPlan?.pricingOptions ? slicedPlan?.pricingOptions[1]?.price : ''
                            const plasnSelected = selectedPlanID && selectedPlanID === slicedPlan['id']
                            if (price) {
                                return (
                                    <TableRow>
                                        <Cell>
                                        <Body size="medium" bold> {slicedPlan?.name}</Body>
                                            <MarginSpacerM />
                                            <Body size="medium" color="grey">{slicedPlan?.shortDescription}</Body>
                                        </Cell>
                                        <Cell>
                                            <Button
                                                use="secondary"
                                                size="large"
                                                width="100%"
                                                disabled = {plasnSelected}
                                                onClick={() => handleButtonClick(isCatPresent, slicedPlan)}>
                                                {plasnSelected ? "Selected" :"Select"}
                                            </Button>
                                        </Cell>
                                        {/* <Cell>${Number(price).toFixed(2)}</Cell> */}
                                        {/* <Cell>{Number((price))}</Cell> */}
                                    </TableRow>
                                )
                            }
                        })}

                    </TableBody>
                </Table>
            </div>
        )
    }
    return (
        <>
            {selectedPlanCat !== null && selectedPlanCat && selectedPlanCat.length && openModal ?
                <BUSSModal opened={openModal}
                    displayItems={selectedPlanCat}
                    sendResultData={(data) => {
                        handleButtonClick([],{id:defaultData}, data);
                        setStoreData({ ...storeData, [defaultData]: data })
                        setModal(false)
                    }}
                    setModalsState={val => setModal(val)}
                /> : <></>}
            <Toggle
                value={toggleOn}
                surface="light"
                disabled={false}
                showText={true}
                textWeight="regular"
                textSize="small"
                textPosition="right"
                statusText={(e) => toggleOn ? "List View" : "Grid View"}
                onChange={(e, name) => {
                    setToggleOn(name.on)
                }}
                on={toggleOn}
            />
            <MarginSpacerM/>
            {toggleOn ? 
            <TableView/>
            :
            <Carousel
                peek="standard"
                layout={isMobile ? "1UP" : "2UP"}
                gutter="8px"
                surface="light"
                paginationInset="12px"
                paginationDisplay="onHover"
                data={cardData()}
                renderItem={(props) => <div className="carousel-plan-card">{props['title']['children']}</div>}
            />
            }
        </>
    );
};

const TileContainer = styled.div`
  min-width: 220px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #d8dada;
  background: #fff;
  padding: 1.5rem 1rem;
  margin-bottom: 1rem;
`;

const TileWrapper = styled.div`
  flex-direction: column;
`;

const CutomTitle = styled.div`
min-height: 4rem;
`;

const PriceLockup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
`;
const TileletContainer = styled(Tilelet)`
[class^=OuterTileContainerDiv-VDS]{
    background-color:none;
}
`
const RadioBoxGroupWrapper = styled(RadioBoxGroup)`
[class^=RadioBoxLabelWrapper-VDS]{
    max-width:200px;
}
`

export default PdpPlanCard;


export const PriceContainer = ({ valueSelected = "",pricingDetails,selectedValue=()=>{}, selectedId=()=>{} }) => {
    if (pricingDetails && pricingDetails.length) {
        let result = []
        pricingDetails.map(data => {
            result.push({
                id: data?.priceType,
                value:data?.id,
                text: <div>
                    <Body size="medium" bold={true}>{data?.priceType}</Body>
                    <Body size="medium">${Number(data?.price).toFixed(2)}/mo</Body>
                </div>,
                selected: valueSelected === data?.value
            })
        })
        return (
            <RadioBoxGroupWrapper
                childWidth={'200px'}
                defaultValue='32GB'
                surface="light"
                data={result}
                // selected={valueSelected}
                // value={valueSelected}
				onChange={(e) =>{selectedId(e.target?.value), selectedValue(e.target?.offsetParent?.id)}} />
        )
    } else return <></>

}

export const TabsSection = ({ pageTemplate }) => {
    const [tabs, setTabs] = useState(["Feature", "Specs"]);
    const [tabIndex, setTabIndex] = useState(0)
    const onTabChangeFunc = (event, index) => {
        setTabIndex(index);
    };
    return (
        <>
            <Tabs
                indicatorPosition="bottom"
                size="medium"
                onTabChange={onTabChangeFunc}
            >
                {tabs.map((item) => (
                    <Tab label={item} key={item} />
                ))}
            </Tabs>
            {tabIndex === 0 ?
                <>
                <MarginSpacerM/>
                <Features feature={pageTemplate?.feature}/>
                {/* {!!pageTemplate?.feature && pageTemplate?.feature.length ? 
                    <GreyContainer flexWrap={true} >
                        {pageTemplate?.feature?.map((viewItem) => (
                            <BussFeature item={viewItem} />
                        ))}
                    </GreyContainer> : <></>}
                    <>
                        {pageTemplate?.featureHalf && (
                            <BussFeatureHalf item={pageTemplate?.featureHalf} />
                        )}
                    </>
                    {pageTemplate?.GcFeatures && (
                        <BussGCFeature item={pageTemplate?.GcFeatures} />
                    )}
                    {pageTemplate?.lookoutFeature?.map((viewItem) => (
                        <BussLookoutSection item={viewItem} />
                    ))} */}
                </>
                : <></>}
        </>
    )
}


const Features = ({feature = []})=>{
    return(
            <Carousel
                peek="standard"
                layout={"3UP"}
                gutter="24px"
                surface="light"
                paginationInset="12px"
                paginationDisplay="onHover"
                data={feature}
                renderItem={(props) =>{
                return (<div style={{backgroundColor:'#F6F6F6',width:'400px',height:'240px',padding:'1rem'}}>
                    <Title size="medium">{props['contentTitle']}</Title>
                    <MarginSpacerM/>
                    <Body size="large">{props['contentText']}</Body>
                </div>)}}
            />
    )
}
  
