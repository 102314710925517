import React, { useEffect, useReducer, useRef, useState } from "react";
import { Button } from "@vds3/buttons";
import VerticalProgressContext from "common/components/VerticalProgress/verticalProgressContext";
import { Title, Body, Micro } from "@vds3/typography";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import OTPVerification from "./OTPVerification";
import MitekVerification from "./MitekVerification";
import { Col } from "@vds3/grids";
import Utils from "../../../Checkout/ducks/utils";
import { setRetries, validateCreditDetails, setShowProgressOverlay } from "../../../Checkout/CreditResult/CreditResultsNew/CreditSlice";
import { getSessionCart, generateMitekVerificationLink, mitekCheckVerificationResult, setIsVerifyBusiness, setMitekCheckVerificationResult, setRunningMitekCount, setVerificationApiRetryCount } from "pages/Checkout/ducks/CheckoutSlice";
import { useMediaQuery } from "react-responsive";
import { setEditableSections } from "pages/CheckoutNew/ducks/LowerFunnelSlice";
import { checkIfAemExists } from "utils/commonUtils";
import ProgressOverlay from "pages/Checkout/ProgressOverlay/ProgressOverlay";

const IdentityVerification = ({ ...props }) => {
    const {
        isComplete,
        onComplete,
        setModifier,
        isEditMode: { isEditMode, setIsEditMode },
    } = React.useContext(VerticalProgressContext);
    const dispatch = useDispatch();
    const { t } = useTranslation(["app", "verification", "automation", "mock-response"]);
    const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
    const pageData = useSelector((state) => state.ProspectWidgets_CheckoutData);
    const creditData = useSelector((state) => state.ProspectWidgets_CreditData);
      const lqData = useSelector((state) => state.ProspectWidgets_LQPage);
    const [disableContinueBtn, setDisableContinueBtn] = useState(false);
    const sessionCartData = globalData?.sessionCartData?.apiResponse?.prospectFlowData;
    const validateResponse = pageData?.otp?.otpValidatePasscode;
    const otpEnabled = sessionCartData?.showOtpModal || pageData?.postBusinessInfoResponse?.showOtpModal;
    const [mitekEnabled, setMitekEnabled] = useState(sessionCartData?.mitekEnabled || pageData?.postBusinessInfoResponse?.mitekEnabled || false);
    const isMobile = useMediaQuery({ query: "(max-width: 544px)" });
    const [showMitekSection, setShowMitekSection] = useState(false);
    const [showOTPSection, setShowOTPSection] = useState(false);
    const flow = pageData?.unifiedCartParams?.flow ?? lqData?.flow;
    const [loadMobileMitek, setLoadMobileMitek] = useState(false);
    const [mitekDeclined, setMitekDeclined] = useState(false);
    const [mitekFailed, setMitekFailed] = useState(false);
    const [mitekRetry, setMitekRetry] = useState(false);
    const [mitekSkippedToOTP, setMitekSkippedToOTP] = useState(false);
    const isFromMitek = false;
    const [showValidationOverlay, setShowValidationOverlay] = useState(false);
    const [progressOverlaycount, handleProgressOverlayCount] = useState(20)
    const [verificationApiRetryCount, setVerificationApiRetryCount] = useState(1);
    const useMock = checkIfAemExists(t("validate-businessInfo-mock", { ns: "mock-response" }));
    useEffect(() => {
        const element = document.getElementById("identity-verification");
        element?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, []);

    useEffect(() => {
        if (
            !pageData?.isShoppingCartApiCallInProgress?.postBusinessInformation &&
            !pageData?.isShoppingCartApiCallInProgress?.postBusinessInfoBussAlone &&
            pageData?.postBusinessInfoResponse &&
            pageData?.validateBusinessInfoResponse &&
            Object.keys(pageData?.postBusinessInfoResponse)?.length &&
            Object.keys(pageData?.validateBusinessInfoResponse)?.length &&
            //(isMobile ? !sessionCartData?.mitekEnabled : true) &&
            !isFromMitek && !mitekSkippedToOTP &&
            pageData?.postBusinessInfoResponse?.serviceStatus?.success &&
            pageData?.validateBusinessInfoResponse?.serviceStatus?.success
        ) {
            pageData?.postBusinessInfoResponse?.mitekEnabled && setMitekEnabled(true);
            if ((useMock && pageData?.postBusinessInfoResponse?.creditApplicationNum) || sessionCartData?.businessInfo?.creditApplicationNum || sessionCartData?.businessInfo?.creditWriteDetails?.TYS?.creditApplicationNum) {
                if (!Utils.is5G4GQuote(pageData)) {
                    if (
                        flow === "prospect" &&
                        isDigitalQuote &&
                        pageData?.creditPath === "BLENDED" && !pageData?.postBusinessInfoResponse?.mitekEnabled
                    ) {
                        otpSuccess();
                    } else {
                        if (otpEnabled) {
                            setShowOTPSection(true);
                        } else if (pageData?.postBusinessInfoResponse?.mitekEnabled) {
                            setShowMitekSection(true);
                        } else {
                            verifyCreditDetails();
                        }
                    }
                } else if (
                    (flow === "5g" || flow === "4g" || Utils.is5G4GQuote(pageData)) &&
                    pageData?.validateBusinessInfoResponse &&
                    Object.keys(pageData?.validateBusinessInfoResponse)?.length
                ) {
                    if (pageData?.postBusinessInfoResponse?.mitekEnabled) {
                        setShowMitekSection(true);
                    } else {
                        verifyCreditDetails();
                    }
                }
            }
            else {
                dispatch(getSessionCart());
            }
        }

    }, [
        pageData?.isShoppingCartApiCallInProgress?.postBusinessInformation,
        pageData?.postBusinessInfoResponse,
        pageData?.validateBusinessInfoResponse,
        sessionCartData?.mitekEnabled,
        sessionCartData
    ]);

    const onOTPPhoneRecommendationStop = () => {
        setShowOTPSection(false);
        if (mitekEnabled) {
            setShowMitekSection(true);
        } else {
            otpSuccess();
        }
    };

    const onOTPValidateResponseFail = () => {
        if (mitekEnabled) {
            setShowOTPSection(false);
            setShowMitekSection(true);
        } else {
            verifyCreditDetails();
        }
    };

    const onOTPValidateResponseSuccess = () => {
        if (mitekEnabled && pageData?.creditPath === "BizOnlyCredit") {
            setShowOTPSection(false);
            setShowMitekSection(true);
        } else if (validateResponse?.data?.svcCode === "M120") {
            otpSuccess();
        }
        else {
            onOTPValidateResponseFail();
        }
    };

    const otpSuccess = () => {
        setShowOTPSection(false);
        dispatch(setIsVerifyBusiness(false));
        verifyCreditDetails();
    };

    const verifyCreditDetails = () => {
        if (
            !creditData?.creditDetailsResponse?.serviceStatus?.success &&
            !creditData?.creditResult?.loading
        ) {
            const useMockCredit = checkIfAemExists(t("credit-result-mock", { ns: "mock-response" }));
            dispatch(setRetries(0));
            dispatch(
                validateCreditDetails({
                    flow: flow,
                    isCreditHoldPage: false,
                    isBlended: sessionCartData?.mitekConsentDeclined || false,
                    ssn: pageData?.consetFlowSSN,
                    isMitekDeclined: mitekSkippedToOTP ? false : sessionCartData?.mitekConsentDeclined || false,//BOVV-142041
                    componentName: mitekSkippedToOTP ? "PersonalValidation" : "",
                    fromCheckoutNew: true,
                    useMock: useMockCredit
                })
            );
        }
    };

    const proceedToOTPFromMitekDeclined = () => {
        setShowMitekSection(false)
        setShowOTPSection(true);
        setMitekSkippedToOTP(true);
        setMitekEnabled(false);
    };

    useEffect(() => {
        const resp = creditData?.creditDetailsResponse;
        if (resp && resp?.serviceStatus?.success && Object.keys(resp)?.length) {
            setShowMitekSection(false);
            setModifier(null);
            onComplete();
            if (lqData?.mmw) {
                dispatch(setEditableSections({ sections: ["authorization-confirmation", "about-business", "signer-info", "ssn-info", "signer-consent", "fedtax-info"], isEditable: false }));
            } else {
                dispatch(setEditableSections({ sections: ["about-business", "signer-info", "ssn-info", "signer-consent", "fedtax-info"], isEditable: false }));
            }
        }
    }, [creditData?.creditDetailsResponse]);

    const dispatchSetShowProgressOverlayAction = (value) => {
        dispatch(setShowProgressOverlay(value));
    };

    useEffect(() => {
        if (
            sessionCartData?.mitekEnabled &&
            loadMobileMitek &&
            !isFromMitek &&
            isMobile &&
            pageData?.validateBusinessInfoResponse &&
            Object.keys(pageData?.validateBusinessInfoResponse)?.length &&
            pageData?.validateBusinessInfoResponse?.serviceStatus?.success &&
            !pageData?.isBussAloneReview
        ) {
            if ((useMock && pageData?.postBusinessInfoResponse?.creditApplicationNum) || sessionCartData?.businessInfo
                ?.creditApplicationNum || sessionCartData?.businessInfo?.creditWriteDetails?.TYS?.creditApplicationNum) {
                dispatch(generateMitekVerificationLink());
            } else {
                dispatch(getSessionCart());
            }
        }
    }, [
        loadMobileMitek,
        isFromMitek,
        pageData?.validateBusinessInfoResponse,
        sessionCartData
    ]);

    useEffect(() => {
        if (
            sessionCartData?.mitekEnabled &&
            pageData?.mitekVerificationLinkResponse &&
            isMobile &&
            !isFromMitek &&
            !pageData?.isBussAloneReview
        ) {
            dispatch(setRunningMitekCount((pageData?.mitekRunningCount || 0) + 1));
            window.location.href = pageData?.mitekVerificationLinkResponse?.link;
        }
    }, [pageData?.mitekVerificationLinkResponse]);

    useEffect(() => {
        if (
            pageData?.mitekCheckVerificationResult?.verificationStatus === "PASSED"
        ) {
            setShowValidationOverlay(false);
            verifyCreditDetails();
        } else if (
            pageData?.mitekCheckVerificationResult?.verificationStatus === "STARTED"
        ) {
            setShowValidationOverlay(true);
        } else if (
            pageData?.mitekCheckVerificationResult?.verificationStatus === "NOT_FOUND"
        ) {
            setShowValidationOverlay(false);
        } else if (pageData?.mitekCheckVerificationResult?.verificationStatus === "STORE_VISIT_ENABLED" ||
            pageData?.mitekCheckVerificationResult?.verificationStatus === "INITIATE_BLENDED" ||
            pageData?.mitekCheckVerificationResult?.verificationStatus === "DECLINED"
        ) {
            setShowValidationOverlay(false);
            setMitekDeclined(true);
            setMitekFailed(false);
            setMitekRetry(false);
        } else if (pageData?.mitekCheckVerificationResult?.verificationStatus === "RETRY") {
            setShowValidationOverlay(false);
            setMitekDeclined(false);
            setMitekFailed(false);
            setMitekRetry(true);
        } else if (pageData?.mitekCheckVerificationResult?.verificationStatus === "FAIL" ||
            pageData?.mitekCheckVerificationResult?.verificationStatus === "FAILED") {
            setShowValidationOverlay(false);
            setMitekDeclined(false);
            setMitekFailed(true);
            setMitekRetry(false);
        }
        setVerificationApiRetryCount(verificationApiRetryCount + 1);
    }, [pageData?.mitekCheckVerificationResult]);

    useEffect(() => {
        if (((showMitekSection && !isMobile) || isFromMitek === "1") && !mitekRetry && !pageData?.isBussAloneReview && !isComplete) {
            const mitekAemRes = t("ddiaVerification.DDIA", {
                ns: "app",
                returnObjects: true,
            });
            dispatch(setMitekCheckVerificationResult({}));
            const useMockMitek = checkIfAemExists(t("mitek-declined-mock", { ns: "mock-response" }));
            dispatch(
                mitekCheckVerificationResult({
                    mitekTimeToWaitForResult: mitekAemRes?.mitekTimeToWaitForResult,
                    mitekStatusPollInterval: mitekAemRes?.mitekStatusPollInterval,
                    mitekRetryCount: mitekAemRes?.mitekRetryCount,
                    mitekRunningCount: pageData?.mitekRunningCount,
                    fromVDS3Checkout: true,
                    useMock: useMockMitek
                })
            );
        }
    }, [showMitekSection, isFromMitek, mitekRetry, isComplete]);

    const getDefaultContent = () => {
        return (
            <>
                <div>
                    {
                        showOTPSection ?
                            <OTPVerification
                                onComplete={onComplete}
                                setModifier={setModifier}
                                onNextBtnClick={otpSuccess}
                                onOTPPhoneRecommendationStop={onOTPPhoneRecommendationStop}
                                onOTPValidateResponseSuccess={onOTPValidateResponseSuccess}
                                onOTPValidateResponseFail={onOTPValidateResponseFail} /> :
                            showMitekSection ?
                                <MitekVerification
                                    onComplete={onComplete}
                                    setModifier={setModifier}
                                    showMitekSection={showMitekSection}
                                    setLoadMobileMitek={setLoadMobileMitek}
                                    mitekDeclined={mitekDeclined}
                                    mitekFailed={mitekFailed}
                                    mitekRetry={mitekRetry}
                                    setMitekRetry={setMitekRetry}
                                    setMitekDeclined={setMitekDeclined}
                                    proceedToOTPFromMitekDeclined={proceedToOTPFromMitekDeclined} /> : <></>
                    }
                </div>
                <ProgressOverlay
                    modalDesc={""}
                    modalFooter={t("ddiaVerification.DDIA.mitek.progress.subtext", { ns: "app" })}
                    modalHeading={t("ddiaVerification.DDIA.mitek.progress.heading", { ns: "app" })}
                    onOpenedChange={setShowValidationOverlay}
                    onNextBtnClick={() => { }}
                    progress={progressOverlaycount}
                    showProgressOverlay={showValidationOverlay}
                    boldTitle={true}
                />
                <ProgressOverlay
                    modalDesc={t("automation.creditFraud.desc", { ns: "automation" })}
                    modalFooter={t("automation.creditFraud.footer", { ns: "automation" })}
                    modalHeading={t("automation.creditFraud.heading", { ns: "automation" })}
                    onOpenedChange={dispatchSetShowProgressOverlayAction}
                    onNextBtnClick={() => { }}
                    progress={(creditData?.retries || 1) * 12.5}
                    showProgressOverlay={creditData?.showProgressOverlay}
                    boldTitle={true}
                />
            </>
        );
    };

    const getCompletedContent = () => {
        return (
            <>
                {/* <div>
                    {console.log("test")}
                    <Body>{state?.businessStreetAddress?.name}</Body>
                    <Body>{state?.businessStreetAddress?.place?.properties?.city}{' '}
                        {state?.businessStreetAddress?.place?.properties?.state}{' '}
                        {state?.businessStreetAddress?.place?.properties?.postalCode
                        }</Body>
                    <Body>{state?.phoneNumber}</Body>
                    <Body>{state?.corporation?.fedTaxId}</Body>
                </div> */}
            </>
        );
    };

    const getEditModeContent = () => {
        return (
            <>
                <>{getDefaultContent()}</>
                <div>
                    <Button
                        size="large"
                        use="primary"
                        onClick={() => setIsEditMode(false)}
                        data-track="Save"
                    >
                        {"Save"}
                    </Button>
                </div>
            </>
        );
    };

    if (isComplete) {
        if (isEditMode) {
            return getEditModeContent();
        } else {
            return getCompletedContent();
        }
    } else {
        return getDefaultContent();
    }
};

const ColPad = styled(Col)`
  margin-top: 2rem !important;
`;

export default IdentityVerification;
