import React, { useState } from "react";
import styled from "styled-components";
import { TextLink } from "@vds/buttons";
import { Title, Body } from "@vds/typography";
import {
  default as VerticalProgressContext,
  VerticalProgressContextProvider,
} from "./verticalProgressContext";
import { useDispatch, useSelector } from "react-redux";
import { setEditSection, setSaveSectionsConfig } from "pages/CheckoutNew/ducks/LowerFunnelSlice";
import { MarginSpacerXS } from "common/components/styleTag";

const VerticalProgressSubsection = ({
  title,
  subsectionId,
  combosecId,
  isActive,
  isComplete,
  isEditable,
  children,
  isInProgress,
  order,
  ...props
}) => {
  const {
    sectionId,
    isSectionComplete,
    isSectionActive,
    setComplete,
    setInProgress,
    onComplete,
    onInProgress,
    setModifier,
    setRemoveSection,
    onUpdateSection,
  } = React.useContext(VerticalProgressContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useDispatch();
  const sectionsConfig = useSelector(
    (state) => state.LowerFunnelSlice?.sectionsConfig
  );
    const completedSection = useSelector(state => state.LowerFunnelSlice.completedSection)
    const completedSectionType = useSelector(state => state.LowerFunnelSlice.completedSectionType)
    const inProgressSection = useSelector(state => state.LowerFunnelSlice.inProgressSection)
    const comboSection = useSelector(state => state.LowerFunnelSlice.comboSectionInProgress)
    const currentOrder = useSelector(state => state.LowerFunnelSlice.currentOrder)
    const checkInstalltionDeatils = (title ==="Installation details") ;

  const onEditclick =() => {  
    if(isEditMode){
      if(inProgressSection === sectionId && completedSection === subsectionId && comboSection === combosecId)
      setComplete({ sectionId :inProgressSection ?? sectionId, subsectionId:completedSection ?? subsectionId, combosecId:comboSection ?? combosecId})
    else setComplete({sectionId: inProgressSection ?? sectionId,
      subsectionId :  completedSection ?? subsectionId,
      combosecId : comboSection ?? combosecId
  })
    }else{
      dispatch(setEditSection({sectionId, subsectionId, combosecId }));
    }
    dispatch(setSaveSectionsConfig(sectionsConfig))
    setIsEditMode(!isEditMode);
  }
  const onCompleteSection = ()=>{
    if(isEditMode){
      setComplete({sectionId: inProgressSection ?? sectionId,
      subsectionId :  completedSection ?? subsectionId,
      combosecId : comboSection ?? combosecId
    })
    setIsEditMode(!isEditMode);
    }
    else{
      if(currentOrder <= order || !currentOrder){
    setComplete({
      sectionId,
      subsectionId,
      combosecId,
      order
    })
  }
  }
  }

  return (
    <VerticalProgressContextProvider
      value={{
        sectionId,
        isSectionComplete,
        isSectionActive,
        setComplete,
        onUpdateSection,
        onComplete,
        setModifier,
        isComplete,
        setRemoveSection,
        isEditMode: { isEditMode, setIsEditMode },
        onComplete: () =>onCompleteSection(),
        onInProgress: (isInProgress) => {
            setInProgress({
              sectionId,
              subsectionId,
              isInProgress,
            })
          },
          onUpdateSection: () =>
          setRemoveSection({
            sectionId,
            subsectionId,
            combosecId,
          }),
      }}
    >
      <TitleSection id={combosecId}>
      <> {combosecId && <MarginSpacerXS/>}
        {!!isComplete && (
        
          <CompletedContainer>
            <Body size="large" bold color="#008331">
              {"Completed"}
            </Body>
            {!!isEditable && (
              <TextLink
                type="standAlone"
                size="small"
                onClick={() => onEditclick()}
              >
                {isEditMode ? "Cancel" : "Edit"}
              </TextLink>
            )}
          </CompletedContainer>
         
        )}

        {!!isInProgress && !isComplete && !!isActive && (
            <Body size="large" bold color="#006fc1">
              {"In progress"}
            </Body>
        )}
        <Title
          size="medium"
          color={isSectionActive || isComplete ? "#000000" : "#d8dada"}
        >
          {checkInstalltionDeatils && isComplete && !isEditMode ? "Appointment scheduled" : isComplete && !isEditMode && title ==="Authorization confirmation"  ? "" : isEditMode ? title : title}
        </Title>
        {combosecId && !isActive && !isComplete && <MarginSpacerXS/>}
        </>
      </TitleSection>
      {!!(isActive || isComplete) && <>{children}</>}
      
      
    </VerticalProgressContextProvider>
  );
};

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 24px 0px;
`;

const CompletedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default VerticalProgressSubsection;
