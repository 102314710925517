// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-modal-container {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.video-modal-container .modal-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
}

.video-modal-container .close-btn-container {
  background: transparent;
  height: 40px;
  opacity: 0.75;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
}

.video-modal-container .close-btn-container .close-btn {
  border: none;
  color: #fff;
  height: 100%;
  width: 100%;
}

.video-modal-container .close-btn-container .close-btn svg{
  height: 100%;
  position: absolute;
  left: 8px;
}

.video-modal-container .close-btn-container .close-btn::after {
  display: none;
}

.video-modal-container .video-iframe-container {
  height: 50%;
  margin: 0 auto;
  position: fixed;
  top: 0;
  transform: translate(50%, 50%);
  width: 50%;
  z-index: 1;
}

.video-modal-container .video-iframe-container iframe {
  height: 100%;
  width: 100%;
}

@media (max-width: 767px){
  .video-modal-container .video-iframe-container {
    transform: translate(0, 50%);
    width: 100%
  }
}`, "",{"version":3,"sources":["webpack://./src/common/components/VideoModal/VideoModal.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,OAAO;EACP,eAAe;EACf,MAAM;EACN,WAAW;EACX,aAAa;AACf;;AAEA;EACE,oCAAoC;EACpC,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,QAAQ;EACR,MAAM;EACN,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,cAAc;EACd,eAAe;EACf,MAAM;EACN,8BAA8B;EAC9B,UAAU;EACV,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE;IACE,4BAA4B;IAC5B;EACF;AACF","sourcesContent":[".video-modal-container {\n  height: 100%;\n  left: 0;\n  position: fixed;\n  top: 0;\n  width: 100%;\n  z-index: 9999;\n}\n\n.video-modal-container .modal-overlay {\n  background-color: rgba(0, 0, 0, 0.8);\n  height: 100%;\n}\n\n.video-modal-container .close-btn-container {\n  background: transparent;\n  height: 40px;\n  opacity: 0.75;\n  position: absolute;\n  right: 0;\n  top: 0;\n  width: 40px;\n}\n\n.video-modal-container .close-btn-container .close-btn {\n  border: none;\n  color: #fff;\n  height: 100%;\n  width: 100%;\n}\n\n.video-modal-container .close-btn-container .close-btn svg{\n  height: 100%;\n  position: absolute;\n  left: 8px;\n}\n\n.video-modal-container .close-btn-container .close-btn::after {\n  display: none;\n}\n\n.video-modal-container .video-iframe-container {\n  height: 50%;\n  margin: 0 auto;\n  position: fixed;\n  top: 0;\n  transform: translate(50%, 50%);\n  width: 50%;\n  z-index: 1;\n}\n\n.video-modal-container .video-iframe-container iframe {\n  height: 100%;\n  width: 100%;\n}\n\n@media (max-width: 767px){\n  .video-modal-container .video-iframe-container {\n    transform: translate(0, 50%);\n    width: 100%\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
