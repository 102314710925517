import React from "react";
import styled from 'styled-components';

const CloseIconContainer = styled.span`
  padding-left: 0.2rem;
  padding-top: 0.2rem;
  margin-left: auto;
  cursor: pointer;
`

const CloseIcon = (props) => {
  return <CloseIconContainer onClick={props.onClickFn}>
    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width={props.width || "1em"} height={props.height || "1em"} preserveAspectRatio="xMidYMid meet" viewBox="0 0 50 50"><path fill="currentColor" d="m37.304 11.282l1.414 1.414l-26.022 26.02l-1.414-1.413z"/><path fill="currentColor" d="m12.696 11.282l26.022 26.02l-1.414 1.415l-26.022-26.02z"/></svg>
  </CloseIconContainer>;
}

export default CloseIcon;