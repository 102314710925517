import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const ColorSwatch = React.memo(function ColorSwatch(props) {
  const radius = 9;

  const ColorHoverContent = styled.div`
    position: absolute;
    opacity: ${props?.colorName !== "" ? "1" : "0"};
    visibility: ${props?.colorName !== "" ? "visible" : "hidden"};
    top: 110%;
    left: 0px;
    background: #fff;
    border: 1px solid #e8e8e8;
    padding: 2px 5px;
    font-size: 13px;
    @media only screen and (max-width: 767px) {
      top: 60%;
    }
  `;
  const ColorHoverContentAccessory = styled.div`
    position: absolute;
    margin-top: 15px;
    margin-left: -10px;
    opacity: ${props?.colorName !== "" ? "1" : "0"};
    visibility: ${props?.colorName !== "" ? "visible" : "hidden"};
    background: #fff;
    border: 1px solid #e8e8e8;
    padding: 2px 5px;
    font-size: 13px;
    height: 20px;
  `;

  const notHoverCapable = useMediaQuery({
    query: "(hover: none)",
  });

  const callFn = (fn) => typeof fn === "function" && fn();

  return (
    <>
      {props?.colorName !== null &&
        props?.colorName &&
        props?.colorName !== "" &&
        !props?.fromAccessory && (
          <ColorHoverContent className="swatch-icon-tooltip">{props?.colorName}</ColorHoverContent>
        )}

      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-circle"
        width={props?.width || "25"}
        height={props?.height || "25"}
        viewBox="0 0 24 24"
        stroke-width="1"
        stroke="#000"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        // NOTE: if you change any of this you MUST test in iOS — desktop simulation is not sufficient
        onMouseOver={() =>
          notHoverCapable ? callFn(props?.onClick) : callFn(props?.onMouseOver)
        }
        onMouseLeave={() => callFn(props?.onMouseLeave)}
        onClick={() => callFn(props?.onClick)}
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        {props?.isSelected && (
          <circle cx="50%" cy="50%" r={radius + 2.6} fill={"#FFF"} />
        )}
        <circle
          className="StrokeCircle"
          cx="50%"
          cy="50%"
          r={radius}
          fill={props?.cssColor}
          value={props?.value}
          stroke={props?.isSelected ? "#000" : "#6F7171"}
        />
        {props?.isOutOfStock && (
          <circle cx="50%" cy="50%" r={radius} stroke="#6F7171" />
        )}
        {props?.isOutOfStock && (
          <line
            x1={12 - radius + 2}
            y1={12 + radius - 2}
            x2={12 + radius - 2}
            y2={12 - radius + 2}
            stroke="#6F7171"
          />
        )}
      </svg>
      {props?.colorName !== "" &&
        props?.color === props?.colorName &&
        props?.fromAccessory && (
          <ColorHoverContentAccessory className="swatch-icon-tooltip">
            {props?.colorName}
          </ColorHoverContentAccessory>
        )}
    </>
  );
});

export default ColorSwatch;
