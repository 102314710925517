import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//import { useNavigate } from "react-router-dom";
import { Button } from "@vds3/buttons";
import { Grid, Row, Col } from "@vds3/grids";
import { Input } from "@vds3/inputs";
import { RadioButtonGroup } from "@vds3/radio-buttons";
import { Modal, ModalBody, ModalTitle } from "@vds3/modals";
import { Title, Body } from "@vds3/typography";
import styled from "styled-components";
import {
    postOtpPhoneNumber,
    postOtpSendCode,
    postOtpValidatePasscode,
    resetOtp,
    setActiveAccordian,
    setNotification,
    setSuspendFlow,
    setSuspendScenario,
} from "../ducks/CheckoutSlice";
import { MarginSpacerL, MarginSpacerS, MarginSpacerXL } from "../VerifyBusiness/styleTag";
import SpinnerComponent from "common/components/Spinner/Spinner";
import { EyeUnderline } from "../CreditCheck5g/styledComponents";
import { isMobile } from "react-device-detect";

const FModal = styled(Modal)`
  height: auto;
  > div:first-child {
    overflow: hidden;
    min-height: unset;
  }
  width: 596px !important;
  max-width: 596px !important;
  padding: 3rem 0 3rem 3rem;
  a {
    text-decoration: underline;
  }
  button[class^=StyledButton-sc] {
    width: 40%;
  }

  @media only screen and (max-width: 767px) {
    > div:first-child {
      padding: 2px;
    }
    padding: 1rem 0 1rem 1rem;
    button[class^=StyledButton-sc] {
      width: 100%;
    }
    .pad-rt-custom {
      padding-right: 0rem;
    }
  }
`;

const OneTimePassModal = (props) => {
    const { t } = useTranslation(["app", "checkout"]);

    const [mobileNumber, setMobileNumber] = useState("");
    const [profileNumbers, setProfileNumbers] = useState([]);
    const [passcode, setPasscode] = useState("");
    const [clickedNext, setClickedNext] = useState(false);
    const [codeSendCount, setCodeSendCount] = useState(0);
    const [flowComplete, setFlowComplete] = useState(false);
    const pageData = useSelector((state) => state.ProspectWidgets_CheckoutData);
    const flow = pageData?.unifiedCartParams?.flow;
    const isLoading = pageData?.otp?.otpLoading;
    const phoneNumberResp = pageData?.otp?.otpPhoneNumber;
    const sendCodeResponse = pageData?.otp?.otpSendCode;
    const validateResponse = pageData?.otp?.otpValidatePasscode;
    const creditEmailFlow = pageData?.creditEmailFlow;
    const isCreditCartFetched = pageData?.isRetrieveCreditCartSuccess;
    const dispatch = useDispatch();
    //const navigate = useNavigate();

    const phoneNumberSuccess = phoneNumberResp?.data?.serviceStatus?.success;
    const phoneRecommendationStop =
        phoneNumberResp?.data?.phoneRecommendation === "STOP" ? true : false;
    const MAX_RETRIES = 3;
    const [isOtherBtnEnabled, setOtherBtnEnabled] = useState(false);
    const [firstInputMobileNum, setFirstInputMobileNum] = useState("");
    const [errors, setErrors] = useState("");

    useEffect(() => {
        dispatch(resetOtp());
    }, []);

    useEffect(() => {
        setMobileNumber(props.businessInfo?.contactInfo?.phoneNumber ?? "");
    }, [props.businessInfo]);

    useEffect(() => {
        if (clickedNext) {
            let notification = {};
            if (phoneNumberSuccess && !phoneRecommendationStop) {
                const resp = phoneNumberResp?.data;
                if (resp?.matchFoundForInputNumber) {
                    sendCode();
                } else if (resp?.matchFoundForInputNumber === false) {
                    setMobileNumber("");
                    setNumberList(resp?.phoneList);

                    if(!resp?.phoneList.length >0){
                        OTPUpdateForNotEligible();
                    }
                    if (resp?.phoneList && resp.phoneList.length > 0) {
                        if(isOtherBtnEnabled){
                            OTPUpdateForNotEligible();
                        }
                    }
                }
            } else if (phoneRecommendationStop) {
                OTPUpdateForNotEligible();
            } else if (!phoneNumberSuccess && phoneNumberResp?.data?.serviceStatus?.statusCode === "ERROR_BUSINESS_INFO") {
                notification = {
                    show: true,
                    type: "error",
                    title: t(`verification.api_errors.ERROR_BUSINESS_INFO`, {
                        ns: "checkout",
                      }),
                    subtitle: "",
                };
            } else {
                dispatch(
                    setSuspendScenario(phoneNumberResp?.data?.serviceStatus?.statusCode)
                );
                //SiteAnalytics.contactUsLanding();
                //props.onOTPValidateResponseFail()

               // navigate("/shop/contact-us");
            }
            dispatch(setNotification(notification));
        }
    }, [phoneNumberResp]);

    useEffect(() => {
        if (flowComplete) {
            if (validateResponse?.data?.serviceStatus?.success === true && phoneRecommendationStop) {
                props.onOTPPhoneRecommendationStop()
            } else if (validateResponse?.data?.serviceStatus?.success === true) {
                props.onOTPValidateResponseSuccess()
            } else {
                props.onOTPValidateResponseFail()
            }
        }
    }, [validateResponse]);

    const getAddress = () => {
        const addr = pageData?.postBusinessInfoResponse?.companyInfo?.address || props?.businessInfo?.address;
        const { addressLine1, addressLine2, city, state, zipCode } = addr || {};
        return {
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            state: state,
            zipcode: zipCode,
        };
    };

    const setNumberList = (phoneList) => {
        const options = [];
        for (const num of phoneList) {
            if(num?.phoneSource === "I"){
                setFirstInputMobileNum(num.phone);
            }
            if (num?.phoneType === "MOBL" && num?.phoneSource === "O") {
                options.push({
                    children: num.phone,
                    value: num.phone,
                    disabled: false,
                });
            }
        }
        if(options?.length > 0){
            options.push({
                children: "Other",
                value: "Other",
                disabled: false,
                name:"Other"
            })
        }
        if (options?.length === 0) {
            props.onNextBtnClick();
        }
        setProfileNumbers(options);
    };

    const mobileNext = () => {
        setClickedNext(true);
      if(props.setShowProgressModal!==null&&props.setShowProgressModal&&typeof props.setShowProgressModal == 'function') props.setShowProgressModal(true)
        if(mobileNumber === firstInputMobileNum) {
            let errorTexts =t("verification.errorMsgs.alternateNumber", { ns: "checkout" })
            setErrors(errorTexts);
        }else{
            dispatch(setNotification({}));
            if (creditEmailFlow) {
                if ((props?.otpPhoneNumber === mobileNumber || props?.otpPhoneNumber === props?.businessInfo?.pocAddress?.phoneNumber) && isCreditCartFetched) {
                props.onNextBtnClick();
                } else {
                suspendOTPFlow('SUSPENDED_FLOW_CREDIT_HOLD');
                }
            } else {
                const params = {
                    flow: flow,
                    firstName: props?.businessInfo?.contactInfo?.firstName || props?.businessInfo?.firstName,
                    lastName: props?.businessInfo?.contactInfo?.lastName || props?.businessInfo?.lastName,
                    phoneNumber: mobileNumber,
                    dob: props?.creditCheckInfo?.corporation?.DateOfBirth,
                    address: getAddress(),
                    functionInd: props?.creditPath === "BLENDED" ? "" : "MB_PROS_NOSSN",
                    retryCount: 0
                };
                dispatch(postOtpPhoneNumber(params));
            }
            dispatch(setActiveAccordian(pageData?.activeAccordionDisplayOrder?.creditResult));
        }
    };
    const mobileSkip = () => {
        suspendOTPFlow('SUSPENDED_FLOW_OTP');
    };
    const suspendOTPFlow = (scenario = null) => {
        dispatch(setSuspendScenario(scenario ?? "SUSPENDED_FLOW_OTP"));
        dispatch(setSuspendFlow(true));
      };
    const sendCode = () => {
        if (codeSendCount < MAX_RETRIES) {
            const resp = phoneNumberResp?.data;
            const number = resp?.phoneList?.filter(t => t.phoneSource === "I")[0].phone;
            setMobileNumber(number);
            setCodeSendCount(codeSendCount + 1);
            dispatch(postOtpSendCode({ phoneNumber: number, flow: flow }));
        }
        else{
            suspendOTPFlow('SUSPENDED_FLOW_OTP');
        }
    };

    const codeNext = () => {
        setFlowComplete(true);
        dispatch(setActiveAccordian(pageData?.activeAccordionDisplayOrder?.creditResult))
        const params = {
            flow: flow,
            otpStatus: "VALID",
            passcode: passcode,
            phoneNumber: mobileNumber,
            trackingNumber: sendCodeResponse?.data?.trackingNumber,
            
        };
        dispatch(postOtpValidatePasscode(params));
    };

    const OTPUpdateForNotEligible = () => {
        setFlowComplete(true);
        const params = {
            flow: flow,
            otpStatus: "NOT ELIGIBLE",
            passcode: "",
            phoneNumber: "",
            trackingNumber: "",
        };
        dispatch(postOtpValidatePasscode(params));
    }

    const getMobileNumber = (e) => {
        if(e?.target?.value === "Other"){
            setMobileNumber("");
            setErrors("");
            setOtherBtnEnabled(true);
        }else{
            setOtherBtnEnabled(false);
            setMobileNumber(e?.target?.value)
        }
        
    }

    return (
   <>
            {isLoading && <SpinnerComponent />}
            {!sendCodeResponse?.data?.serviceStatus?.success && (
                <>
                        <Title size="medium">
                            {!creditEmailFlow ?
                                t("verification.modalHeaderText", { ns: "checkout" }) :
                                t("creditHoldOtpModal.modalHeaderText",  { ns: "app"})}
                        </Title>
                        <Grid
                            colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
                            colGutter={false}
                            rowGutter="5"
                        >
                            {!phoneNumberSuccess ? (
                                <Row>
                                    <Col
                                        colSizes={{ mobile: 4, tablet: 4, desktop: 4 }}
                                        className="pad-tp-24 pad-bt-8"
                                    >
                                        <Input
                                            label={!creditEmailFlow ?
                                                t("verification.enterTelephoneText", { ns: "checkout" }) :
                                                t("creditHoldOtpModal.enterTelephoneText",  { ns: "app"})} d
                                            placeholder=""
                                            maxLength="10"
                                            value={
                                                mobileNumber?.length === 10
                                                    ? mobileNumber.includes("-")
                                                        ? mobileNumber
                                                        : mobileNumber?.replace(
                                                            /(\d{3})(\d{3})(\d{4})/,
                                                            "$1-$2-$3"
                                                        )
                                                    : mobileNumber
                                            }
                                            width={"100%"}
                                            onChange={(e) => setMobileNumber(e.target.value?.replace(/[^\d]/g, ""))}
                                            error={phoneNumberSuccess === false}
                                            errorText={t(
                                                "verification.api_errors.something-went-wrong",
                                                { ns: "checkout" }
                                            )}
                                        />
                                    </Col>
                                </Row>
                            ):(
                                <>
                                    <MarginSpacerL />
                                    <Body size="large">
                                        {t("verification.associatedSelectText", {
                                            ns: "checkout",
                                        })}
                                    </Body>
                                    <MarginSpacerS />
                                        <RadioButtonGroup
                                            id="otpNumberRadioGroup"
                                            onChange={(e) => getMobileNumber(e)}
                                            error={false}
                                            data={profileNumbers}
                                        />
                                </>
                            )}
                        </Grid>
                        <MarginSpacerL />
                        {isOtherBtnEnabled && (
                            <Row>
                            <Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
                              <div className="form-col" style={{ position: "relative" , left:"65px"}}>
                                <Input
                                  placeholder=""
                                  required={true}
                                  name="altNumber"
                                  width={"100%"}
                                  maxLength={10}
                                  value={
                                    mobileNumber?.length === 10
                                        ? mobileNumber.includes("-")
                                            ? mobileNumber
                                            : mobileNumber?.replace(
                                                /(\d{3})(\d{3})(\d{4})/,
                                                "$1-$2-$3"
                                            )
                                        : mobileNumber
                                }
                                  onChange={(e) => setMobileNumber(e.target.value?.replace(/[^\d]/g, ""))}
                                  error={errors?.length > 0}
                                  errorText={t("verification.errorMsgs.alternateNumber", { ns: "checkout" })
                                   }
                                />
                                <EyeUnderline
                                  style={
                                    mobileNumber > 0 ? { marginTop: "0px" } : {}
                                  }
                                >
                                </EyeUnderline>
                              </div>
                            </Col>
                          </Row>
                        )}
                        <MarginSpacerL />
                        <div className="otpContinuegroup">
                            <Button width={isMobile ? "100%" : "150px"}  onClick={mobileNext}>
                                {!creditEmailFlow ?
                                    t("verification.next", { ns: "checkout" }) : 
                                    t("creditHoldOtpModal.next",  { ns: "app"})}
                            </Button>
                        { phoneNumberSuccess && <Button secondary  width={isMobile ? "100%" : "50px"}  style = {{marginLeft : '10px'}}onClick={mobileSkip}>
                            {!creditEmailFlow ?
                                t("verification.skip", { ns: "checkout" }) : 
                                t("creditHoldOtpModal.skip",  { ns: "app"})}
                        </Button>}
                        </div>
                </>
            )}
            {sendCodeResponse?.data?.serviceStatus?.success && (
                <>
                        <Title size="large">
                            {`${t("verification.modalOTPSent", {
                                ns: "checkout",
                            })} ${mobileNumber}.`}
                        </Title>
                        <Grid
                            colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
                            colGutter={false}
                            rowGutter="5"
                        >
                            <Row>
                                <Col
                                    colSizes={{ mobile: 4, tablet: 9, desktop: 9 }}
                                    className="pad-tp-16"
                                >
                                    <Input
                                        label={t("verification.modalOTP", { ns: "checkout" })}
                                        placeholder=""
                                        maxLength="5"
                                        type="password"
                                        value={passcode}
                                        width={"100%"}
                                        onChange={(e) => setPasscode(e.target.value?.replace(/[^\d]/g, ""))}
                                        error={
                                            validateResponse?.data?.serviceStatus?.success === false
                                        }
                                        errorText={"Incorrect or expired passcode"}
                                    />
                                </Col>
                            </Row>
                        </Grid>
                        {codeSendCount > 1 && (
                            <>
                                <Body size="medium">
                                    {t("verification.passCodeReSentText", { ns: "checkout" })}
                                </Body>
                            </>
                        )}
                        <MarginSpacerXL />
                        <div className="otpResendLink">
                            {/* {codeSendCount < MAX_RETRIES ? ( */}
                                <>
                                    <Body size="medium">
                                        {t("verification.modalOTPDidntGet", { ns: "checkout" })}
                                        &nbsp;
                                        <a onClick={sendCode}>
                                            {t("verification.modalResendCode", {
                                                ns: "checkout",
                                            })}
                                        </a>
                                    </Body>
                                </>
                            {/* ) : (
                                <Body size="medium">{`${t("verification.modalOTPDidntGet", {
                                    ns: "checkout",
                                })} ${t("verification.modalOTPClosePopUp", {
                                    ns: "checkout",
                                })}`}</Body>
                            )} */}
                        </div>
                        <MarginSpacerXL />
                        <Button disabled={!passcode?.length} onClick={codeNext}>
                            {t("verification.next", { ns: "checkout" })}
                        </Button>
                </>
            )}
            </>
    );
};

export default OneTimePassModal;
