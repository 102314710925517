import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { TileContainer } from "@vds3/tiles";
import { Title, Body } from "@vds3/typography";
import { TextLinkCaret } from "@vds3/buttons";
import {
	MarginSpacerM,
	MarginSpacerS,
	MarginSpacerXS,
} from "common/components/styleTag";
import { useGlobalData } from "hooks";
import { getBussProductPlans, setBussId } from "./ducks/BussProductSlice";
import { setLoadBussPDPPage, setLoadShoppingCartWidget } from "app/ducks/GlobalSlice";
import { Input } from "@vds3/inputs";

const AddonCards = ({
	tabList,
	isFwa,
	hideViewMore = false,
	setEnableContinue,
	tabs
}) => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [optionsList, setOptionsList] = useState(false);
	const [isSkipSelected, setIsSkipSelected] = useState(false);
	const [allProductList, setAllProductList] = useState([]);
	const [search,handleSearch] = useState('')
	// const { t } = useTranslation(["4G-5G"]);
	const isMobile = useMediaQuery({
		query: "(max-width: 545px)",
	});
	const { sessionCartData, cmp } = useGlobalData({ skipSessionCart: true });
	let cmpVal = cmp?.cmpValue;
	const cartData = sessionCartData?.apiResponse?.prospectFlowData;
	const qualOptions = tabList?.products;
	const allOptions = tabList?.tabTitle === "All Products" ? allProductList : [...qualOptions];
	const assetsBaseUrl = process.env.PROSPECT_WIDGETS_APP_ASSESTS_BASE_URL;

	const handleCartClick = async (cartId) => {
		dispatch(getBussProductPlans({ cartId: cartId }));
		dispatch(setBussId(cartId));
		dispatch(setLoadShoppingCartWidget(false));
		dispatch(setLoadBussPDPPage(true));
		setEnableContinue(true);
	};
	useEffect(() => {
		if ((cartData === null || cartData === undefined) && !optionsList)
			setOptionsList(true);
	}, [cartData]);

	useEffect(() => {
		if (tabs){
			let productsList = [];
		     for (const tab in tabs) {
			productsList.push(...tabs[tab]?.products);
		}
		setAllProductList(productsList);
	}
}, [tabs]);

	const onMoreClick = () => {
		if (!optionsList) {
			setOptionsList(true);
		} else {
			setOptionsList(false);
		}
	};
	return (
		<>
		<div style={{maxWidth:'400px'}}>
		<MarginSpacerM/>
		<Input type="text" onChange={e=>handleSearch(e.target.value)} label="Search" required={false}/>
		<MarginSpacerM/>
		</div>
			{/* {isLoading && <SpinnerComponent />} */}
			<BussGridContainer
				isMobile={isMobile}
				isFwa={isFwa}
				isSkipSelected={isSkipSelected}
			>
				{allOptions?.length > 0 &&
					allOptions?.map((item) => {
						const allowShow = (!search || (search && String(item?.cartTitle).toLowerCase().includes(search.toLowerCase()))) 
						if (
							!!item?.enabled &&
							(((cartData === null || cartData === undefined) && item.cartId) ||
								(cartData !== null && cartData)) && allowShow
						) {
							return (
								<TileContainer
									padding="1rem"
									aspectRatio="none"
									showBorder
									backgroundColor="#fff"
									surface={"dark"}
									onClick={() => {
										if (!item?.skipOption) {
											handleCartClick(item.cartId);
											setIsLoading(true);
										} else {
											setIsSkipSelected(true);
										}
										// else {
										//   cmpVal ? navigate(`/shop/shopping-cart?cmp=${cmpVal}`) :navigate(`/shop/shopping-cart`);
										// }
									}}
								>
									<TileContents>
										<MarginSpacerS />
										<TitleContainer>
											<ImageContainer>
												{item?.imageUrl && (
													<img
														className="img-wid"
														src={`${assetsBaseUrl}${item?.imageUrl}`}
														alt={item?.cartTitle}
													/>
												)}
											</ImageContainer>
											<Title size="small" bold primitive="h4">
												{item?.cartTitle}
											</Title>
										</TitleContainer>
										<MarginSpacerS />
										{item?.skipOption ? (
											<Title size="small" bold primitive="h4">
												{item?.cartSubTitle}
											</Title>
										) : (
											<Body size="small">{item?.cartSubTitle}</Body>
										)}
										<MarginSpacerM />
										<Body bold={false} size="small">
											{item?.cartText}
										</Body>
										<MarginSpacerXS />
									</TileContents>
								</TileContainer>
							);
						}
					})}
			</BussGridContainer>
			{/* {!hideViewMore && <TextLinkCaret
        iconPosition="right"
        onClick={() => {
          onMoreClick()
        }}>
        {!optionsList ? "View More" : "View Less"}
      </TextLinkCaret>
      } */}
			{/* <BussGridContainer isMobile={isMobile} isFwa={isFwa} isSkipSelected={isSkipSelected}>
        {optionsList && qualOptions?.length > 0 &&
          qualOptions?.map(
            (item) =>
              !!item?.enabled && (
                <TileContainer
                  padding="1rem"
                  aspectRatio="none"
                  showBorder
                  backgroundColor="#fff"
                  surface={"dark"}
                  onClick={() => {
                    if (!item?.skipOption) {
                      handleCartClick(item.cartId);
                      setIsLoading(true);
                      setIsSkipSelected(false);
                    } else {
                      setIsSkipSelected(true);
                    }
                    //  else {
                    //   cmpVal ? navigate(`/shop/shopping-cart?cmp=${cmpVal}`) :navigate(`/shop/shopping-cart`);
                    // }
                  }}
                >
                  <TileContents>
                    <MarginSpacerS />
                    <TitleContainer>
                      <ImageContainer>
                        {item?.imageUrl && (
                          <img
                            className="img-wid"
                            src={`${window?.mbtGlobal?.assetsBaseUrl}${item?.imageUrl}`}
                            alt={item?.cartTitle}
                          />
                        )}
                      </ImageContainer>
                      <Title size="small" bold primitive="h4">
                        {item?.cartTitle}
                      </Title>
                    </TitleContainer>
                    <MarginSpacerS />
                    {item?.skipOption ? (
                      <Title size="small" bold primitive="h4">
                        {item?.cartSubTitle}
                      </Title>
                    ) : (
                      <Body size="small">{item?.cartSubTitle}</Body>
                    )}
                    <MarginSpacerM />
                    <Body bold={false} size="small">
                      {item?.cartText}
                    </Body>
                    <MarginSpacerXS />
                  </TileContents>
                </TileContainer>
              )
          )}</BussGridContainer> */}
		</>
	);
};

const ImageContainer = styled.div`
	width: 60px;
	img {
		border-radius: 2px;
		max-width: 95%;
	}
`;

const BussGridContainer = styled.div`
	display: grid;
	grid-template-columns: ${({ isMobile }) =>
		isMobile ? "repeat(1, minmax(250px, 1fr))" : "repeat(3, minmax(250px, 1fr))"};
	gap: 1rem;
	margin: 2rem 0;
	width: 75%;
	@media only screen and (max-width: 767px) {
		width: 100%;
	}
	> div:last-child {
		border: ${({ isSkipSelected }) =>
			isSkipSelected ? "3px solid #000000" : "1px solid #6f7171"};
	}
`;
const TileContents = styled.div`
	display: flex;
	flex-direction: column;
	.shop-btn {
		font-weight: 700;
		border-bottom: none;
		position: absolute;
		bottom: 7%;
		right: 7%;
	}
`;
const TitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
	margin-bottom: 1rem;
`;

export default AddonCards;
