import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextLink } from "@vds3/buttons";
import { Body } from "@vds3/typography";
import { formatPriceString } from "utils/commonUtils";
import { MarginSpacerM, MarginSpacerS, MarginSpacerXS } from "common/components/styleTag";
import CartPkgContext from "./cartPkgContext";
import {
  LineItemPriceGrid,
  LineItem,
} from "../LineItemPriceGrid/LineItemPriceGrid";
import { Toggle } from "@vds3/toggles";
import StaticIpModal from "common/components/Modals/StaticIpModal";

// removed ONETALK and Smartphones edit as per AC
const ALLOWED_EDIT_TYPES = ["REGULAR"];

const PackageFeatures = ({ onEditClick, onRemoveSecurityAddOnClick, ...props }) => {
  const { t } = useTranslation(["shoppingCart", "app"]);
  const {
    pack,
    quantity,
    features,
    isEditable,
    isMobilityEdit,
    isDigitalQuote,
    enableMobilityRemove,
  } = React.useContext(CartPkgContext);
  const sectionTitle = t("shopping-cart.cart-features.heading");
  const DPChangeNotiIndicatorSymbol = t(
    "app.common.orderSummary.disclaimerDPPChangesIndicator",
    { ns: "app" }
  );
  const [toggleOn, setToggleOn] = useState(false)
  const isLaporHotspot = !(String(pack.deviceCategory).toLowerCase().includes('laptop'))
  const canEditFeatures =
    isEditable && isLaporHotspot &&
    ALLOWED_EDIT_TYPES.includes(pack?.packageType) &&
    ((pack?.byod && (!isDigitalQuote || enableMobilityRemove)) ||
      isMobilityEdit);
  const isFWA = pack?.packageType === "FOURG" || pack?.packageType === "FIVEG";
  const visFeatureType = t("shopping-cart.removeFeatureTypes", {
    returnObjects: true,
  });
  const visFeatureCodeRemove = (section) =>
    visFeatureType?.length && visFeatureType?.includes(section?.toLowerCase());
  const isLtePlusWithDQ = pack?.plan?.planType == "UNCAPPED" && isDigitalQuote;
  if (features?.length) {
    return (
      <>
        <LineItemPriceGrid title={sectionTitle} rowGap={isFWA ? "0px" : false}>
          {features.map((feature) => (
            <Fragment>
              <LineItem
                key={feature?.visFeatureCode}
                displayName={!isFWA ? `${feature?.featureDesc} ${DPChangeNotiIndicatorSymbol}` : feature?.featureDesc}
                price={
                  (feature?.featurePrice || feature.featurePrice === 0) &&
                  `${formatPriceString(
                    Number(feature?.featurePrice) * quantity
                  )}${t("shopping-cart.cart.perMonth")}`
                }
                subText={
                  !isFWA && quantity > 1 &&
                  t("shopping-cart.cart.productNouns.deviceWithCount", {
                    count: quantity,
                  })
                }
                subTextSize="small"
              />
              {visFeatureCodeRemove(feature?.displaySection) && (
                <div>
                  <MarginSpacerXS />
                  <TextLink onClick={() => onRemoveSecurityAddOnClick(pack)}>
                    {t("shopping-cart.cart.remove")}
                  </TextLink>
                  <MarginSpacerM />
                </div>
              )}
            </Fragment>
          ))}
          {isLtePlusWithDQ && t("shopping-cart.cart.unlimitedData")}
        </LineItemPriceGrid>
        <MarginSpacerM />
        <Toggle
                value={toggleOn}
                surface="light"
                disabled={false}
                showText={true}
                textWeight="regular"
                textSize="small"
                textPosition="right"
                statusText={(e) => toggleOn ? "Add Static Ip" : "Add Static Ip"}
                onChange={(e, name) => {
                    setToggleOn(name.on)
                }}
                on={toggleOn}
            />

{toggleOn && (
              <StaticIpModal
                deviceQuantity={"1"}
                opened={toggleOn}
                onOpenedChange={setToggleOn}
              />
            )}
        {false && !!canEditFeatures && (
          <div>
            <MarginSpacerM />
            <TextLink onClick={() => onEditClick(pack)}>
              {t("shopping-cart.cart.edit")}
            </TextLink>
            <MarginSpacerXS />
          </div>
        )}
      </>
    );
  } else return false;
};

export default PackageFeatures;
