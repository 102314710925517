import React, { useMemo } from "react";
import styled from "styled-components";
import { RadioBox } from "@vds3/radio-boxes";
import { Body } from "@vds3/typography";
import PDPContext from "./pdpContext";

const SimTypes = ({ ...props }) => {
	const {
		selectedSku: { selectedSku, setSelectedSku },
		selectedSimType: { selectedSimType },
	} = React.useContext(PDPContext);
	const { esimSku, psimSku, defaultSimType } = selectedSku || {};
	let simType = selectedSimType ?? defaultSimType;
	const selectedSimSku = simType === "esim" ? esimSku : psimSku;

	const simItems = useMemo(() => {
		const items = [
			{
				label: "PSIM",
				text: "physical",
				simType: "p",
				simTypeLong: "psim",
				simSku: psimSku,
			},
			{
				label: "ESIM",
				text: "esim",
				simType: "e",
				simTypeLong: "esim",
				simSku: esimSku,
			},
		];
		if (simType === "esim") {
			items.sort((a, b) => (simType === a?.simTypeLong ? -1 : 1));
		}
		return items;
	}, [psimSku, esimSku, simType]);

	const handleClick = (simTypeObj) => {
		const updatedSelectedSkuObj = {
			...selectedSku,
			simSku: simTypeObj?.simSku,
			defaultSimType: simTypeObj?.simTypeLong,
			simType: simTypeObj?.simTypeLong,
		};
		setSelectedSku(updatedSelectedSkuObj);
	};

	return (
		<SectionContainer>
			<SimBoxContainer>
				{simItems?.map((simTypeObj) => {
					return (
						<SimBox
							key={simTypeObj?.simType}
							selected={simTypeObj?.simSku === selectedSimSku}
							onChange={() => handleClick(simTypeObj)}
							children={
								<>
									<Body bold size="large">
										{simTypeObj?.simType === "e" ? "Embedded SIM" : "Physical SIM card"}
									</Body>
									{simTypeObj?.simType == "e" ? (
										<Body size="large">
											An embedded SIM (eSIM) is a non-removeable part of your device that
											connects to the Verizon network.
										</Body>
									) : (
										<Body size="large">
											A physical SIM (pSIM) is a small card inserted into the device that
											connects to the Verizon network.
										</Body>
									)}
								</>
							}
						/>
					);
				})}
			</SimBoxContainer>
		</SectionContainer>
	);
};

const SectionContainer = styled.div`
	margin: 1rem 0 1.5rem 0;
	.device-info {
		display: inline-block;
		margin-bottom: 0.5rem !important;
	}
`;
const SimBoxContainer = styled.div`
	margin-bottom: -2rem !important;
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	margin-top: 1rem;
	max-width: 596px;
	margin-left: 3px;
	@media screen and (min-width: 230px) and (max-width: 767px) {
		margin-left: 0px;
	}
`;
const SimBox = styled(RadioBox)`
	flex: 1;
	min-height: 5rem;
	margin: 5px 12px 17px 0;
	@media screen and (min-width: 230px) and (max-width: 767px) {
		display: flex;
		flex: 2 1 auto;
		width: 328px;
		margin: 5px 1px 12px 0;
		max-width: 100%;
	}
`;

export default React.memo(SimTypes);
