import React from 'react';
import { CloseIconSVG } from '../SVGIcons/SVGIcons';
import './VideoModal.css'

const VideoModal = (props) => {
    const { onCloseBtnClick, srcVideoURL, title } = props;
    return (
        <div className="video-modal-container">
            <div className="modal-overlay"></div>
            <div className="video-iframe-container">
                <div className="close-btn-container">
                    <div className="close-btn" onClick={onCloseBtnClick}>
                        <CloseIconSVG
                            height={28}
                            width={28}
                            stroke={"#ffffff"}
                        />
                    </div>
                </div>
                <iframe
                    src={srcVideoURL}
                    title={title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen="true">
                </iframe>
            </div>
        </div>
    );
}

export default VideoModal;