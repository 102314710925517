import { all } from "redux-saga/effects";

import ApplicationSagas from '../app/ducks/sagas';
import QualificationSagas from "../pages/Qualification/ducks/QualificationSagas";
import UpperFunnel4G from "../pages/Landing4G-V2/ducks/sagas";
import UpperFunnel5G from "../pages/Landing5G-V2/ducks/sagas";
import ShoppingCart from "../pages/ShoppingCart/ducks/sagas";
import CheckoutSagas from "../pages/Checkout/ducks/sagas";
import CreditResultSagas from "../pages/Checkout/CreditResult/CreditResultsNew/sagas";
import MobilitySagas from "../pages/Mobility/ducks/sagas";
import WirelessPlansSagas from "../pages/Mobility/components/PlansNew/ducks/sagas";
import BussSagas from "../pages/BussProductsPage/ducks/BussSagas";
import LowerFunnelSagas from "../pages/CheckoutNew/ducks/sagas";



export default function* rootSaga() {
  yield all([
    ApplicationSagas(),
    QualificationSagas(),
    UpperFunnel4G(),
    UpperFunnel5G(),
    ShoppingCart(),
    CheckoutSagas(),
    CreditResultSagas(),
    MobilitySagas(),
		WirelessPlansSagas(),
		BussSagas(),
    LowerFunnelSagas()
  ]);
}
