import React, { useEffect, useState } from 'react'
import CreditResultSSN from './CreditResultSSN';
import { useDispatch, useSelector } from 'react-redux'
import { getSessionCart, setIsBizCompleted, setIsBlendedCompleted } from 'pages/Checkout/ducks/CheckoutSlice';
import isEmpty from "lodash/isEmpty";
import CreditUtils from "pages/Checkout/CreditResult/CreditResultsNew/creditUtils";
import { setCreditHold, setCreditHoldCode, setIsFromCreditHold, setIsLowerFinancedAmountOptionSelected, setRetries, updateProspectCreditDetails, validateCreditDetails } from 'pages/Checkout/CreditResult/CreditResultsNew/CreditSlice';
import { addToCart, setDeviceListBy } from 'pages/Mobility/ducks/MobilitySlice';
import CreditHold from './CreditHold';
import styled from 'styled-components';
import ProgressOverlay from './ProgressOverlay';
import { useTranslation } from "react-i18next";
import VerticalProgressContext from 'common/components/VerticalProgress/verticalProgressContext';
import { setEditableSections } from 'pages/CheckoutNew/ducks/LowerFunnelSlice';
import { checkIfAemExists } from 'utils/commonUtils';
import { useCreditCheckResult } from 'pages/Checkout/CreditResult/CreditResultsNew/hooks';

const CreditResultPage = () => {
  const {
    isComplete,
    onComplete,
    setModifier,
    setComplete,
    isEditMode: { isEditMode, setIsEditMode },
  } = React.useContext(VerticalProgressContext);

  const { t } = useTranslation(["app", "credit-result","mock-response"]);
  const dispatch = useDispatch()
  const [creditLimitExceeded, setIsCreditLimitExceeded] = React.useState(false)
  const [showNotificationError, setNotificationError] = React.useState(false)
  const [updateProspect, setShowUpdateProspect] = React.useState(false)
  const [showCreditHold, setShowCreditHold] = useState(false)
  const [creditApproved, setCreditApproved] = useState(false)
  const [validateBusiCalled, setValidateBusinCalled] = useState(false)
  const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
  const pageData = useSelector((state) => state.ProspectWidgets_CheckoutData);
  const creditData = useSelector((state) => state.ProspectWidgets_CreditData);
	const lqData = useSelector((state) => state.ProspectWidgets_LQPage);
  const sessionCartData = globalData?.sessionCartData?.apiResponse?.prospectFlowData;
  const creditCheckResult = useCreditCheckResult({ creditResultsData: creditData?.creditDetailsResponse });
  const isMultipleCart = CreditUtils?.isCreditResultMultipleCart(creditData?.creditDetailsResponse);
  const showBlendedOption = sessionCartData?.showBlendedOption
  const useMock = checkIfAemExists(t("credit-result-mock", { ns: "mock-response" }));
  useEffect(() => {
    const element = document.getElementById("credit-results");
    if(element) element?.scrollIntoView({ behavior: "smooth", block: "start" });
}, []);

  useEffect(() => {
    if(sessionCartData?.redirectPageTo !== "checkout"){
      //dispatch(replaceDeviceForExceedingcreditLimitNew(undefined))
      dispatch(getSessionCart());
      if (!creditData?.creditDetailsResponse?.serviceStatus?.success && !creditData?.creditResult?.loading) {
        dispatch(setRetries(0));
        dispatch(
          validateCreditDetails({
            flow: pageData?.unifiedCartParams?.flow ?? lqData?.flow,
            isCreditHoldPage: false,
            isBlended: sessionCartData?.mitekConsentDeclined || false,
            ssn: pageData?.consetFlowSSN,
            isMitekDeclined: sessionCartData?.mitekConsentDeclined || false,
            fromCheckoutNew: true,
            useMock: useMock
          })
        );
      }
    }
  }, [])
  useEffect(() => {
    const resp1 = creditData?.updateProspectCreditDetailsResponse;
    if (
      updateProspect && creditCheckResult?.mainCartData?.result === "approved" &&
      creditCheckResult?.mainCartData?.result !== "dppToFRP" &&
      creditCheckResult?.mainCartData?.result !== "dppToFRPWithDeposit" &&
      resp1?.serviceStatus?.statusCode !== "CREDIT_LIMIT_EXCEEDED" &&
      !resp1?.data?.spendingLimitExceded &&
      !sessionCartData?.showBlendedOption &&
      !isMultipleCart &&
      !creditData?.creditHoldVisited) {
      setCreditApproved(true)
    } else if (!creditData?.creditHoldVisited && updateProspect && resp1?.serviceStatus?.statusCode === "CREDIT_LIMIT_EXCEEDED" || resp1?.data?.spendingLimitExceded) {
      setIsCreditLimitExceeded(true);
    } else if (updateProspect && !(resp1?.serviceStatus?.statusCode === "CREDIT_LIMIT_EXCEEDED" || resp1?.data?.spendingLimitExceded)) {
      setIsCreditLimitExceeded(false)
      dispatch(setIsLowerFinancedAmountOptionSelected(false));
    }
    if (updateProspect && (resp1?.serviceStatus?.statusCode === "CREDIT_HOLD_NEEDED" ||
      ((pageData?.bizCompleted && pageData?.blendedCompleted) && resp1?.serviceStatus?.statusCode === "CREDIT_RESULTS_FOR_HOLD_NEEDED"))) {
      let code = `${resp1?.data?.creditApprovalStatus}-${resp1?.data?.creditStatusReason}`;
      setShowCreditHold(true)
      dispatch(setCreditHoldCode(code));
    }else if(updateProspect && resp1?.serviceStatus?.statusCode === "CREDIT_RESULTS_FOR_HOLD_NEEDED" &&
    ((sessionCartData?.prospectCreditPath === 'BLENDED' || sessionCartData?.prospectCreditPath === 'BizOnlyCredit') && (((resp1?.bizOnlyCreditCart === null || resp1?.bizOnlyCreditCart === undefined) ||
    (resp1?.bizOnlyCreditCart!==null&&resp1?.bizOnlyCreditCart&&Object.keys(resp1?.bizOnlyCreditCart).length && sessionCartData?.mitekConsentDeclined)) && 
    (resp1?.dbaCreditCart === null || resp1?.dbaCreditCart === undefined) && (resp1?.blendedCart !==null && resp1?.blendedCart && Object.keys(resp1?.blendedCart).length) )) ){
      let code = `${resp1?.data?.creditApprovalStatus}-${resp1?.data?.creditStatusReason}`;
      setShowCreditHold(true)
      dispatch(setCreditHoldCode(code));
    } 

  }, [updateProspect, creditData?.updateProspectCreditDetailsResponse])
  useEffect(() => {
    const resp1 = creditData?.creditDetailsResponse
    if (
      !updateProspect && creditCheckResult?.mainCartData?.result === "approved" &&
      creditCheckResult?.mainCartData?.result !== "dppToFRP" &&
      creditCheckResult?.mainCartData?.result !== "dppToFRPWithDeposit" &&
      resp1?.serviceStatus?.statusCode !== "CREDIT_LIMIT_EXCEEDED" &&
      !resp1?.data?.spendingLimitExceded &&
      !sessionCartData?.showBlendedOption &&
      !isMultipleCart &&
      !creditData?.creditHoldVisited) {
      setCreditApproved(true)
    } else if (!updateProspect && resp1?.serviceStatus?.statusCode === "CREDIT_LIMIT_EXCEEDED" || resp1?.data?.spendingLimitExceded) {
      setIsCreditLimitExceeded(true);
    } else if (!updateProspect && !(resp1?.serviceStatus?.statusCode === "CREDIT_LIMIT_EXCEEDED" || resp1?.data?.spendingLimitExceded)) {
      setIsCreditLimitExceeded(false)
      dispatch(setIsLowerFinancedAmountOptionSelected(false));
    }
    if (!updateProspect && (resp1?.serviceStatus?.statusCode === "CREDIT_HOLD_NEEDED" ||
      ((pageData?.bizCompleted && pageData?.blendedCompleted) && resp1?.serviceStatus?.statusCode === "CREDIT_RESULTS_FOR_HOLD_NEEDED"))) {
      let code = `${resp1?.data?.creditApprovalStatus}-${resp1?.data?.creditStatusReason}`;
      setShowCreditHold(true)
      dispatch(setCreditHoldCode(code));
    } else if(!updateProspect && resp1?.serviceStatus?.statusCode === "CREDIT_RESULTS_FOR_HOLD_NEEDED" &&
    ((sessionCartData?.prospectCreditPath === 'BLENDED' || sessionCartData?.prospectCreditPath === 'BizOnlyCredit') && (((resp1?.bizOnlyCreditCart === null || resp1?.bizOnlyCreditCart === undefined) ||
    (resp1?.bizOnlyCreditCart!==null&&resp1?.bizOnlyCreditCart&&Object.keys(resp1?.bizOnlyCreditCart).length && sessionCartData?.mitekConsentDeclined)) && 
    (resp1?.dbaCreditCart === null || resp1?.dbaCreditCart === undefined) && (resp1?.blendedCart !==null && resp1?.blendedCart && Object.keys(resp1?.blendedCart).length) )) ){
      let code = `${resp1?.data?.creditApprovalStatus}-${resp1?.data?.creditStatusReason}`;
      setShowCreditHold(true)
      dispatch(setCreditHoldCode(code));
    } else if (!creditData?.creditHoldVisited && resp1?.serviceStatus?.success && resp1?.serviceStatus?.statusCode !== "CREDIT_RESULTS_FOR_HOLD_NEEDED" &&
      String(resp1?.serviceStatus?.message).toLowerCase().includes('approved')) {
      onComplete();
      dispatch(setEditableSections({ sections: ["about-business", "signer-info", "ssn-info", "signer-consent", "fedtax-info"], isEditable: false }));
      if (!isMultipleCart) setCreditApproved(true)
    }
  }, [updateProspect, creditData?.creditDetailsResponse])
  useEffect(() => {
    let creditRslt = {
      sessionCartData: sessionCartData,
      creditData: creditData,
    };
  }, [creditData?.creditDetailsResponse?.data?.businessInfo?.creditApplicationNum]);
  const checkIfSSNORCreditLimitExceeded = () => {
    const { BizOnlyCredit, BLENDED, DBA } = creditCheckResult;
    let cle = false
    let ssn = false
    let bizOnly = false
    let blended = false
    let dba = false
    let creditApprovedNew = false
    let creditHoldData = { bizOnlyHold: false, blendedHold: false, dbaHold: false }
    const isCreditLimitExceeded = creditData?.creditDetailsResponse.serviceStatus?.statusCode === "CREDIT_LIMIT_EXCEEDED" || creditData?.creditDetailsResponse?.data?.spendingLimitExceded
    const isCreditHoldNeeded = creditData?.creditDetailsResponse.serviceStatus?.statusCode === "CREDIT_RESULTS_FOR_HOLD_NEEDED"
    let dppToFrp = String(creditCheckResult?.mainCartData?.result).toLowerCase().includes("dpptofrp")
    if (!creditData?.creditHoldVisited) {
      if (window?.mbtGlobal?.flags?.ENABLE_PROSPECT_FLEXCREDIT === "Y" &&
        (creditData?.isLowerFinancedAmountOptionSelected || (sessionCartData?.prospectCreditPath === "BLENDED" && isCreditLimitExceeded))) {
        cle = true
      }
      if (!pageData?.blendedCompleted && !creditApproved && isMultipleCart ) {
        if (!isEmpty(BizOnlyCredit?.cart) && !sessionCartData?.mitekConsentDeclined) bizOnly = true
        if (!isEmpty(BLENDED?.cart)) blended = true
        if (!isEmpty(DBA?.cart)) dba = true
      } else if (!pageData?.bizCompleted && !validateBusiCalled && !creditApproved && !cle && sessionCartData?.prospectCreditPath === "BizOnlyCredit" && (showBlendedOption || isCreditLimitExceeded)) {
        ssn = true
      }
      if (!(ssn || blended || dba || bizOnly) && dppToFrp && Number(creditCheckResult?.mainCartData?.dueToday) === 0) dppToFrp = false

      if (!(showCreditHold || dppToFrp || cle || isCreditHoldNeeded || ssn || blended || bizOnly || dba || creditLimitExceeded)) creditApprovedNew = true
    }
    return { dppToFrp: dppToFrp, creditApproved: creditApprovedNew, isCreditHoldNeeded, creditLimitExceeded: cle || creditLimitExceeded, ssn: ssn, blended, bizOnly, dba, ...creditHoldData }
  }
  const onContinue = (sel, ssn) => {
    const val = String(sel).trim()
    if ((sel === undefined || sel === null || sel === '' || !val) && !checkIfSSNORCreditLimitExceeded().creditApproved) {
      setNotificationError(true)
      return
    }
    dispatch(setIsFromCreditHold(false));
    if (sel === "dppToFrp") {
      onComplete();
      dispatch(setEditableSections({ sections: ["about-business", "signer-info", "ssn-info", "signer-consent", "fedtax-info"], isEditable: false }));
    }
    else {
      if (sel === "SSN") {
        const req = {
          flow: pageData?.unifiedCartParams?.flow,
          isBlended: true,
          isCreditHoldPage: false,
          ssn: ssn,
          fromCheckoutNew: true
        };
        dispatch(setRetries(0));
        dispatch(validateCreditDetails(req));
        dispatch(setIsBizCompleted(true))
      } else {
        const requestBody = {
          flow: pageData?.unifiedCartParams?.flow,
          creditPath: sel && sel !== " " ? sel : sessionCartData?.prospectCreditPath ? sessionCartData?.prospectCreditPath : "BLENDED",
          isLowerFinancedAmountOptionSelected:
            window?.mbtGlobal?.flags?.ENABLE_PROSPECT_FLEXCREDIT === "Y"
              ? sel === "BizOnlyCredit" || sel === "BLENDED" || sel === "DBA" ? creditLimitExceeded ? true : false : false : false,
          fromCheckoutNew: true,
          useMock: useMock
        };
        if (globalData?.cmp?.isFromGoogleShop) {
          requestBody.cmpCode = globalData?.cmp?.cmpValue;
        }
        if (checkIfSSNORCreditLimitExceeded().ssn) dispatch(setIsBizCompleted(true))
        else dispatch(setIsBlendedCompleted(true))
        setShowUpdateProspect(true)
        setValidateBusinCalled(true)
        dispatch(updateProspectCreditDetails(requestBody));
        if (sel === "PAY_DOWN_PAYMENT" || checkIfSSNORCreditLimitExceeded().creditApproved) {
          onComplete()
          dispatch(setEditableSections({ sections: ["about-business", "signer-info", "ssn-info", "signer-consent", "fedtax-info"], isEditable: false }));
        }
        setModifier(null);
      }
    }
  };
  if (!isComplete) {
    return (
      <div className='credit-results'>
        {((showCreditHold && creditData?.isConfirmcreditHold && !creditApproved) || creditData?.creditHoldVisited) ?
          <CreditHold creditHoldNeeded={value => setShowCreditHold(value)} /> :
          <>
            {(Object.values(checkIfSSNORCreditLimitExceeded()).includes(true)) ?
              <CreditResultSSN
                hideNotification = {val=>setNotificationError(val)}
                showNotificationErrorFromCR = {showNotificationError}
                errorTextCr = {t("creditResultNew.errorText.optionsNotSelected", { ns: 'credit-result' })}
                sessionCartData={sessionCartData}
                aemData={t("creditResultNew", { ns: 'credit-result', returnObjects: true })}
                showCartOnly={creditApproved}
                onContinueClick={onContinue}
                creditStatus={checkIfSSNORCreditLimitExceeded()}
                creditCheckResult={creditCheckResult} cartData={sessionCartData?.cart}
                replaceNewDevice={({ devices, selectedOption }) => {
                  dispatch(setIsBizCompleted(true))
                  dispatch(setIsBlendedCompleted(true))
                  dispatch(setDeviceListBy(null))
                 // dispatch(replaceDeviceForExceedingcreditLimitNew(devices))
                  // if (selectedOption === 'BYOD') navigate("/shop/products/devices/smartphones/byod")
                  // else navigate("/shop/products/devices/all")
                }}
                showZipcodeContent={pageData?.zipChangeInfoShown}
                setNoificationFalse={val => setNotificationError(val)}
                cleStandaloneHeading = {!pageData?.blendedCompleted && !pageData?.bizCompleted}
              /> : <></>}
          </>}
        <ProgressOverlay
          showProgressOverlay={creditData?.showProgressOverlay}
          modalHeading={t("creditResultNew.progressOverlay.modalHeading", { ns: 'credit-result' })}
          modalDesc={t("creditResultNew.progressOverlay.modalDesc", { ns: 'credit-result' })}
        // modalFooter={}
          newLFflow = {true}
        />
      </div>
    )
  } else return <></>
}
export const ButtonWrapper = styled.div`
display:flex;
`
export default CreditResultPage