import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
//import { useNavigate } from "react-router";
import styled from "styled-components";
import { Button } from "@vds3/buttons";
import { Modal, ModalTitle, ModalBody, ModalFooter } from "@vds3/modals";
import { reset4GState } from "pages/Landing4G-V2/ducks/4GSlice";
import { reset5GState } from "pages/Landing5G-V2/ducks/5GSlice";
import { setBBALQResp,setAddressChange } from "pages/Qualification/ducks/QualificationSlice";

const ChangeAddress = ({ opened, onOpenedChange, url, ...props }) => {
  const { t } = useTranslation(["4G-5G"]);
  //const navigate = useNavigate();
  const dispatch = useDispatch();

  const onChangeClick = () => {
    window.localStorage.removeItem("lqsessionId");
    dispatch(reset4GState());
    dispatch(reset5GState());
    localStorage.clear();
    dispatch(setAddressChange(true));
   // navigate(url || "/shop/solutions/qualification");
    dispatch(setBBALQResp({}));
  };

  const onCancelClick = () => onOpenedChange(false);

  return (
    <Modal
      surface="light"
      hideCloseButton={false}
      opened={opened}
      onOpenedChange={onOpenedChange}
      disableFocusLock= {true}
    >
      <ModalTitle>{t("4G-5G.common.changeAddressModal.heading")}</ModalTitle>
      <ModalBody>{t("4G-5G.common.changeAddressModal.bodyText")}</ModalBody>
      <ModalFooter
        children={
          <ButtonGroup>
            <Button
              size="large"
              use="primary"
              width="100%"
              onClick={onChangeClick}
            >
              {t("4G-5G.common.changeAddressModal.btnYes")}
            </Button>
            <Button
              size="large"
              use="secondary"
              width="100%"
              onClick={onCancelClick}
            >
              {t("4G-5G.common.changeAddressModal.btnNo")}
            </Button>
          </ButtonGroup>
        }
      />
    </Modal>
  );
};

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  @media (max-width: 545px) {
    flex-direction: column;
  }
`;

export default ChangeAddress;
