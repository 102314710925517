import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { RadioBox } from "@vds3/radio-boxes";
import { Body, Micro, Title } from "@vds3/typography";
import { MarginSpacerS } from "common/components/styleTag";
import { formatPriceString } from "utils/commonUtils";

const RadioBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const RouterBoxContainer = styled.div`
  display: flex;
`;
const RouterBoxBody = styled.div`
  width: 80%;
  padding: 16px 24px;
`;
const RouterBoxPricing = styled.div`
  display: flex;
`
const RouterBoxPricingStrikethrough = styled.div`
  margin-left: .875rem;
  > p {
    text-decoration-line: line-through;
  }
`

const EquipmentSelection = ({
  routers,
  selectedRouter,
  setSelectedRouter,
  ...props
}) => {
  const { t } = useTranslation(["app", "4G-5G"]);
  const aem = t("4G-5G.fiveG.router-content", {
    ns: "4G-5G",
    returnObjects: true,
  });

  const radioBoxes = useMemo(() => {
    let list = [];
    routers?.deviceList?.forEach((router) => {
      const sku = router.skulist[0]?.sku;
      const aemContent = aem["router"][`${sku}-STARK`];
      const routerBox = {
        id: "router",
        name: "router",
        value: "router",
        text: aemContent?.title,
        type: "radiobox",
        subtext: ".",
        selected: selectedRouter === "router",
        children: (
          <RouterBox
            aemContent={aemContent}
            price={`$${router.skulist[0]?.pricingDetails?.zeroYear?.price}`}
            strikethroughPrice={aemContent?.strikethroughPrice}
          />
        ),
      };
      list.push(routerBox);
    });
    const aemContentByor = aem["ownRouterWithStarkReceiver"];
    const receiverOnly = {
      id: "byor",
      name: "byor",
      value: "byor",
      text: aemContentByor?.title,
      type: "radiobox",
      subtext: ".",
      selected: selectedRouter === "byor",
      children: (
        <RouterBox
          aemContent={aemContentByor}
          price={0}
          strikethroughPrice={aemContentByor?.price}
        />
      ),
    };
    list.push(receiverOnly);
    return list;
  }, [routers, selectedRouter]);

  const onRouterSelect = (e) => {
    setSelectedRouter(e.target.value);
  }

  return (
    <>
      <RadioBoxWrapper>
        {radioBoxes.map(box => 
          <RadioBox 
            id={box.id}
            name={box.name}
            value={box.value}
            text={box.text}
            orientation="vertical"
            selected={!!box.selected}
            onChange={(e) => onRouterSelect(e)}
            children={box.children}
          />
        )}
      </RadioBoxWrapper>
    </>
  );
};

const RouterBox = ({ aemContent, price, strikethroughPrice, ...props }) => {
  return (
    <RouterBoxContainer>
      <div>
        <img height="100" width="100" src={aemContent.imgSrc} />
      </div>
      <RouterBoxBody>
        <Title>{aemContent?.title}</Title>
        <MarginSpacerS />
        <RouterBoxPricing>
          <Body bold size="large">{formatPriceString(price)}</Body>
          <RouterBoxPricingStrikethrough>
            <Body size="large">{formatPriceString(strikethroughPrice)}</Body>
          </RouterBoxPricingStrikethrough>
        </RouterBoxPricing>
        <MarginSpacerS />
        <Micro>{aemContent?.subTitle}</Micro>
      </RouterBoxBody>
    </RouterBoxContainer>
  );
};

export default EquipmentSelection;
