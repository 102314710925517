import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Modal, ModalTitle, ModalBody } from "@vds3/modals";
import { Body } from "@vds3/typography";
import { MarginSpacerL } from "common/components/styleTag";
import { StyledFooterContainer } from "./styles";
import FWAContext from "../fwaContext.js";
import { Button } from "@vds3/buttons";

const JointOfferPromotionModal = ({
  opened,
  onOpenedChange,
  starkEnabled,
}) => {
  const { flow } = useContext(FWAContext);
  const { t } = useTranslation(["4G-5G"]);
  const aemKey = flow === "4g" ? "promotionModal4g" : starkEnabled ? "promotionModal5gStarkReciver" : "promotionModal5g";
  
  const aem = t(`4G-5G.${aemKey}`, {
    returnObjects: true,
  });
  return (
    <>
        <Modal
          surface="light"
          hideCloseButton={false}
          opened={opened}
          onOpenedChange={onOpenedChange}
        >
          <ModalTitle>{aem?.heading}</ModalTitle>
          <ModalBody>
            <ModalBodyContainer>
              {<Body size="large">
                { aem?.content?.map((i) => (
                    <>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: i,
                        }}
                      />
                    </>
                  ))}
              </Body>
              }
            </ModalBodyContainer>
          </ModalBody>
          <MarginSpacerL />
          <StyledFooterContainer>
            <Button
              width="100%"
              onClick={() => {
                onOpenedChange(false);
              }}
            >
              Close
            </Button>
          </StyledFooterContainer>
        </Modal>

    </>
  );
};

const ModalBodyContainer = styled.div`
  li:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;

export default JointOfferPromotionModal;
