import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TextLink, Button } from "@vds3/buttons";
import { DropdownSelect } from "@vds3/selects";
import { Body, Micro } from "@vds3/typography";
import { MarginSpacerL } from "common/components/styleTag";
import { FLOWS } from "../../../../app/ducks/GlobalSlice";
import CalifProp65Modal from "common/components/Modals/CalifProp65Modal";
//import RecieveVZOffersModal from "common/components/Modals/RecieveVZOffersModal";
import MaximumDevicesModal from "common/components/Modals/MaxDevicesModal";
import ApplyToAllRemainingLinesModal from "common/components/Modals/ApplyToAllRemainingLinesModal";
import PDPContext from "./pdpContext";
import { useSelector } from "react-redux";
import { Notification } from "@vds3/notifications";
import { Input } from "@vds3/inputs";

const Quantity = ({
	addToCartFn,
	maxDeviceQuantity,
	showProp65Warning,
	isEditPackageFlow,
	hideQuantityDropdown,
	editPackagePackageId,
	editPackageGrpId,
	isPlanFirstFlow,
	isPricingAvailable,
	selectedFrequency = "",
	addToCartBuss = ()=>{},
	...props
}) => {
	const { t } = useTranslation(["pdp"]);
	const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
	const pageData = useSelector((state) => state.ProspectWidgets_MobilityData);
	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	const {
		flow,
		isChangeDevice,
		selectedPayment: { selectedPayment },
		selectedQuantity: { selectedQuantity, setSelectedQuantity },
		selectedSku: { selectedSku },
		selectedPromo: { selectedPromo },
		combinePricing,
	} = React.useContext(PDPContext);
	const [isVerizonOffer, setIsVerizonOffer] = useState(false);
	const [showMaxDeviceModal, setMaxDeviceModal] = useState(false);
	const [showProp65Modal, setShowProp65Modal] = useState(false);
	const [showAllLinesModal, setShowAllLinesModal] = useState(false);
	const packagesObj = sessionCartData?.cart?.packages;
	let preObrderShimentDate = selectedSku?.shipmentDate?.replace(/\b0/g, "");

	const getAccessoryQuantity = (quan, maxQuantitySelected) => {
		let quantity = quan || 0;
		if (props.fromAccessory) {
			quantity = 0;
			const { accessories } = sessionCartData?.cart;
			if (accessories !== null && accessories && Object.keys(accessories).length) {
				Object.keys(accessories).map((key) => {
					if (key !== editPackagePackageId) quantity += accessories[key]["quantity"];
				});
			}
			if (quan * 10 - (quantity + maxQuantitySelected) >= 0) return -1;
			else return 10 - maxQuantitySelected;
		}
		return quantity;
	};

	const addSmartPhoneToCart = (addToAllLines = false) => {
		let quantity = packagesObj ? Object.keys(packagesObj)?.length : 0;
		let maxQuantitySelected = 1;
		if (typeof selectedQuantity === "number") {
			maxQuantitySelected = selectedQuantity;
		} else if (typeof selectedQuantity === "string") {
			maxQuantitySelected = JSON.parse(selectedQuantity.replace("+", ""));
		}
		if (
			editPackagePackageId !== null &&
			editPackagePackageId &&
			packagesObj !== null &&
			packagesObj
		) {
			const { groupPackages } = sessionCartData.cart;
			const packageId = String(editPackagePackageId).split("_")[0];
			let exitingQuantity = 0;
			if (
				groupPackages !== null &&
				groupPackages &&
				Object.keys(groupPackages).length
			) {
				Object.keys(groupPackages).map((key) => {
					if (key !== editPackageGrpId)
						exitingQuantity += groupPackages[key]["quantity"];
				});
			}
			quantity = exitingQuantity;
		}
		if (
			pageData?.deviceSelectedForExceedginCreditLimit !== null &&
			pageData?.deviceSelectedForExceedginCreditLimit &&
			packagesObj !== null &&
			packagesObj
		) {
			quantity -= 1;
		}
		quantity = getAccessoryQuantity(quantity, maxQuantitySelected);
		if (
			quantity + (hideQuantityDropdown ? 0 : maxQuantitySelected) >=
			maxDeviceQuantity
		) {
			setMaxDeviceModal(true);
		} else {
			const selectedOffers =
				selectedPromo && selectedPromo?.offerId
					? [
							{
								offerId: selectedPromo?.offerId,
								type: selectedPromo?.promoType,
							},
					  ]
					: null;
			if (isPlanFirstFlow || flow === FLOWS.PLANFIRST) {
				addToCartFn(
					selectedPayment,
					selectedQuantity,
					selectedSku,
					selectedOffers,
					"plans-first",
					addToAllLines
				);
			} else {
				addToCartFn(
					selectedPayment,
					selectedQuantity,
					[selectedSku],
					selectedOffers,
					"regular",
					addToAllLines
				);
			}
		}
	};

	const getButtonText = (sku) => {
		if(props?.buss){
			return t("device-pdp.addtocart")
		}else if (!sku || sku?.outOfStock || props.isoutOfStock) {
			return t("device-pdp.outOfStock1");
		} else if (sku?.preOrder) {
			return t("device-pdp.preOrderCart");
		} else if (isChangeDevice || isEditPackageFlow) {
			return t("device-pdp.update");
		} else return t("device-pdp.addtocart");
	};

	const onAddToCartButtonClick = () => {
		if (flow === FLOWS.PLANFIRST) {
			// TODO: check if remaining lines > 1
			const devices = Object.values(globalData?.sessionCartData?.devices || {});
			const dummyPkgCount = devices.filter(
				(i) => i?.deviceFullDetail?.dummy
			).length;
			if (!isChangeDevice && !isEditPackageFlow && dummyPkgCount > 1) {
				setShowAllLinesModal(true);
			} else {
				addSmartPhoneToCart();
			}
		} else addSmartPhoneToCart();
	};

	return (
		<>
			{props.isOneTalk ? (
				<>
					<CustomNotification
						type="info"
						title={`${t(
							"device-pdp.oneTalk.device-info.combinePricing.deskphones.headerMsg",
							{ ns: "pdp" }
						)}`}
						subtitle={`${t(
							"device-pdp.oneTalk.device-info.combinePricing.deskphones.contentMsg",
							{
								ns: "pdp",
								deskPhonePrice: combinePricing?.deskPhonePrice,
							}
						)}`}
						fullBleed={true}
						inlineTreatment={false}
						hideCloseButton={true}
						viewport="mobile"
					/>
					<MarginSpacerL />
				</>
			) : (
				<></>
			)}
			{selectedSku?.preOrder && (
				<Body bold size="medium" className="title">
					{t("device-pdp.PreOrderEstimatedDate", {
						ns: "pdp",
						shippingDate: preObrderShimentDate,
					})}
				</Body>
			)}
			<MarginSpacerL />
			{props.deviceDetails !== null &&
				props.deviceDetails &&
				Object.keys(props.deviceDetails).length > 0 &&
				t("disclaimer", { returnObjects: true })[props.deviceDetails.deviceId] && (
					<>
						<Body bold size="medium" className="title">
							<span
								dangerouslySetInnerHTML={{
									__html: `${
										t("disclaimer", { returnObjects: true })[
											props.deviceDetails.deviceId
										]["message"]
									}`,
								}}
							></span>
						</Body>
						<MarginSpacerL />
					</>
				)}
			{hideQuantityDropdown ? "" : <Font12>{t("device-pdp.quantity")}</Font12>}
			<AddCartContainer className="add-cart-wrapper">
				{hideQuantityDropdown ? (
					""
				) : (
					<Input
						width={"82px"}
						className="numLines-dropdownSelect"
						maxLength={3}
						onChange={(e) => setSelectedQuantity(Number(e.target.value))}
						value={selectedQuantity}
					>
						</Input>
				)}
				{props.buss ? 
				<StyledButton
				data-polymap={getButtonText(selectedSku)}
				size="large"
				onClick={addToCartBuss}
				data-track="Add to cart"
				disabled={!selectedFrequency || selectedQuantity < 1}
			>
				{getButtonText(selectedSku)}
			</StyledButton>
				:
				<StyledButton
					data-polymap={getButtonText(selectedSku)}
					size="large"
					onClick={onAddToCartButtonClick}
					data-track="Add to cart"
					disabled={
						!selectedSku ||
						selectedSku?.outOfStock ||
						props.isoutOfStock ||
						(!props?.fromAccessory && !isPricingAvailable)
					}
				>
					{getButtonText(selectedSku)}
				</StyledButton>}
			</AddCartContainer>
			<Links>
				{!props.isOneTalk && (
					<Micro>
						<TextLink
							className="device-info"
							onClick={() => setIsVerizonOffer(true)}
						></TextLink>
					</Micro>
				)}
			</Links>
			{/* {isVerizonOffer && (
        <RecieveVZOffersModal
          opened={isVerizonOffer}
        />
      )} */}
			{showMaxDeviceModal && (
				<MaximumDevicesModal
					deviceQuantity={maxDeviceQuantity}
					opened={showMaxDeviceModal}
					onOpenedChange={setMaxDeviceModal}
				/>
			)}
			{showProp65Modal && (
				<CalifProp65Modal
					opened={showProp65Modal}
					onOpenedChange={setShowProp65Modal}
				/>
			)}
			{showAllLinesModal && (
				<ApplyToAllRemainingLinesModal
					opened={showAllLinesModal}
					onOpenedChange={setShowAllLinesModal}
					onClickContinue={addSmartPhoneToCart}
				/>
			)}
		</>
	);
};

const AddCartContainer = styled.div`
	display: flex;
	align-items: center;
`;
const StyledButton = styled(Button)`
	display: flex;
	margin-left: 1rem;
	width: 230px;
	span {
		font-size: 16px;
	}
	@media screen and (max-width: 768px) {
		flex-grow: 1;
	}
`;
const Links = styled.div`
	padding-top: 0rem;
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
`;
const CustomNotification = styled(Notification)`
	div[type="info"] {
		border-radius: 4px;
	}
`;
const Font12 = styled.div`
	margin-bottom: 4px;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	font-family: "Verizon-NHG-eTX";
`;

export default React.memo(Quantity);
