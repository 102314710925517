import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getGlobalPageLoadData,
  getSessionCart,
  getCombinePricing,
} from "app/ducks/GlobalSlice";

const useGlobalData = ({ isOneTalk } = {}) => {
  const dispatch = useDispatch();

  const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
  
  const combinePricing = globalData?.combinePricing;
  const editPackagePackageId = globalData?.editPackagePackageId;
  const isEditPackageFlow = globalData?.isEditPackageFlow;
  const isEmptyByodFromDQFlow = globalData?.isEmptyByodFromDQFlow;
  const isOrderQuoteFlow = globalData?.isOrderQuoteFlow;
  const isLoading = globalData?.loading;
  const modals = globalData?.modals;
  const metaInformation = globalData?.metaInformation;
  const sessionCartData = globalData?.sessionCartData;
  const cmp = globalData?.cmp;

  useEffect(() => {
    if (!metaInformation) {
      dispatch(getGlobalPageLoadData());
    } else {
      dispatch(getSessionCart());
    }
    if (isOneTalk) {
      dispatch(getCombinePricing());
    }
  }, []);

  return {
    combinePricing,
    editPackagePackageId,
    isEditPackageFlow,
    isEmptyByodFromDQFlow,
    isOrderQuoteFlow,
    isLoading,
    metaInformation,
    modals,
    sessionCartData,
    cmp,
  };
};

export default useGlobalData;
