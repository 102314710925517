import React, { useMemo } from "react";
import styled from "styled-components";
import { Line } from "@vds3/lines";
import { VzFormProgress } from "@vz-shop/ui-react";
import VerticalProgressSection from "./VerticalProgressSection";
import VerticalProgressSubsection from "./VerticalProgressSubsection";
import { VerticalProgressContextProvider } from "./verticalProgressContext";
import { sortSectionEntries } from "./util";

const getStateModifier = (mod) => {
  const value = typeof mod === "string" && mod.toLowerCase();
  switch (value) {
    case "error":
    case "warn":
    case "info":
      return value;
    default:
      return null;
  }
};
const getActiveSectionProgress = (section) => {
  const subsectionArr = Object.values(section.subsections || {});
  const progress = subsectionArr.reduce((total, subsection) => {
    let subProgress = 0;
    const comboArr = Object.values(subsection.comboSections || {});
    if (comboArr.length) {
      comboArr.forEach((sect) => {
        if (sect.isComplete) {
          subProgress +=
            sect.progress ??
            Math.floor(100 / Math.max(subsectionArr.length - 1 + comboArr.length, 1));
        }
      });
    } else if (subsection.isComplete) {
      subProgress =
        subsection.progress ??
        Math.floor(100 / Math.max(subsectionArr.length, 1));
    }
    return total + subProgress;
  }, 0);
  return Math.min(Math.max(progress, 1), 100);
};

const getSectionsProgress = (sections) => {
  const sectionsProgress = {};
  const sectionsArr = Array.isArray(sections)
    ? sections
    : Object.entries(sections || {}).sort(sortSectionEntries);

  sectionsArr.forEach(([_id, sec], index) => {
    sectionsProgress[`.section-group-${index + 1}`] = {
      progress: sec.isActive ? getActiveSectionProgress(sec) : sec.isComplete ? 100 : 0,
      expanded: !!sec.isActive,
      stateModifier: getStateModifier(sec.stateModifier),
      excludeProgressBar: sec.excludeProgressBar,
    };
  });
  return sectionsProgress;
};

const VerticalProgress = ({
  sectionState,
  sectionComponents,
  setComplete,
  setInProgress,
  setModifier,
  children,
  setRemoveSection,
  ...props
}) => {
  const sortedSectionEntries = useMemo(
    () => Object.entries(sectionState || {}).sort(sortSectionEntries),
    [sectionState]
  );

  const sectionsProgress = useMemo(
    () => getSectionsProgress(sortedSectionEntries),
    [sortedSectionEntries]
  );

  return (
    <StyledFormProgress
      sectionsSelector=".sectionContainerBuss"
      sectionsProgress={sectionsProgress}
    >
      {sortedSectionEntries.map(([sectionId, section]) => (
        <VerticalProgressContextProvider
          key={`${sectionId}-ctx`}
          value={{
            sectionId:sectionId,
            isSectionComplete: section.isComplete,
            isSectionInProgress: section.isInProgress,
            isSectionActive: section.isActive,
            setComplete,
            setInProgress,
            setRemoveSection,
            onUpdateSection: () => setRemoveSection({ sectionId }),
            onComplete: () => setComplete({ sectionId }),
            onInProgress: () => setInProgress({ sectionId }),
            setModifier: (modifier) => setModifier({ sectionId, modifier }),
          }}
        >
          <VerticalProgressSection
            key={sectionId}
            title={section.title}
            isButton={section.isButton}
            isComponent={section.isComponent}
            onButtonClick={sectionComponents?.[sectionId]}
          >
            {section.isComponent
              ? sectionComponents?.[sectionId]
              : Object.entries(section?.subsections || {})
                .sort(sortSectionEntries)
                .map(([subsectionId, subsection]) => (
                  <TileContainer id={subsectionId} key={subsectionId}>
                    {subsection.comboSections ? (
                      Object.entries(subsection.comboSections)
                        .sort(sortSectionEntries)
                        .map(([combosecId, comboSection], index) => (
                          <>
                            {index !== 0 && <Line type="secondary" />}
                            <VerticalProgressSubsection
                              key={combosecId}
                              subsectionId={subsectionId}
                              combosecId={combosecId}
                              title={comboSection.title}
                              isActive={comboSection.isActive}
                              isComplete={comboSection.isComplete}
                              isInProgress={comboSection.isInProgress}
                              isEditable={comboSection.isEditable}
                              order = {section['order'] + comboSection.order}
                            >
                              {sectionComponents?.[combosecId]}
                            </VerticalProgressSubsection>
                          </>
                        ))
                    ) : (
                      <VerticalProgressSubsection
                        key={subsectionId}
                        subsectionId={subsectionId}
                        title={subsection.title}
                        isActive={subsection.isActive}
                        isComplete={subsection.isComplete}
                        isInProgress={subsection.isInProgress}
                        isEditable={subsection.isEditable}
                        order = {section['order'] + subsection.order}
                      >
                        {sectionComponents?.[subsectionId]}
                      </VerticalProgressSubsection>
                    )}
                  </TileContainer>
                ))}
          </VerticalProgressSection>
        </VerticalProgressContextProvider>
      ))}
    </StyledFormProgress>
  );
};

const StyledFormProgress = styled(VzFormProgress)`
@media screen and (max-width:767px) {
  .bDsesi{
    margin-left:24px !important;
  }
}
@media screen and (min-width: 768px) {
.bDsesi{
  margin-left:8px !important;
}
}
  > div {
    margin-top: 0.5rem;
    width: 100%;
  }
  > div > div {
    position: initial !important;
  }
`;

const TileContainer = styled.div`
  background-color: #f6f6f6;
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
`;

export default VerticalProgress;
