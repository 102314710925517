export const COMMON = 'common'
export const BUSINESS = 'businessInfo'
export const SHIPPING = 'shippingInfo'
export const CREDIT = 'creditCheck'
export const INSTALLMENTS = 'installmentsInfo'
export const INSTALLATION = 'Installation'
export const TOS = 'tosInfo'
export const DEPOSIT = 'depositInfo'
export const DOCUSIGN = 'docusign'
export const SHIPPINGMETHOD = 'shippingMethod'
export const CREDIT5G = 'creditCheck5g'
export const VERIFY_DOCUMENTS = 'verifyDocuments'
export const VERIFY_BUSINESS = 'verifyBusiness'
export const NPA_NXX = 'npaNxx'
export const REVIEWTAC = 'ReviewTAC'
export const CREDITRESULT='credit-result'
export const EMERGENCY = 'emergency'
export const CREDITCHOICE = 'creditChoice'
export const CHECK_CREDIT_OPTIONS = 'checkCreditOptions'


// Flow State Variables
export const COMPLETED = 'completed'
export const OPEN = 'open'
export const CLOSE = 'close'
export const LOADING = 'loading'
export const ERROR = 'error'
export const ACTIVE = 'active'
export const DISABLE = 'disable' 
export const BACK = 'back' 

 
// Flows
export const FIOS = 'FIOS'
export const BUSINESS_5G = 'BUSINESS_5G' 
export const BUSINESS_4G = 'BUSINESS_4G' 
export const FLOW_5G = '5G' 
export const FLOW_5G_MMW = '5G_MMW' 
export const FLOW_5G_CBAND = '5G_CBAND' 
export const FLOW_4G = '4G' 
export const FLOW_WIRELESS = 'WIRELESS' 
export const FLOW_ONETALK = 'ONETALK'  
export const FLOW_PROSPECT = 'PROSPECT'  

// API 
export const RETRIVECART5GDATA = 'retriveCart5gData'

//ConfigTypes
export const CONFIG_SHIPPING_ONLY = 'ShippingOnlyFlow'  
export const CONFIG_INSTALLATION_ONLY = 'InstallationOnlyFlow'  
export const CONFIG_REVIEW_ONLY = 'ReviewOnlyFlow'  
export const CONFIG_NPA_SHIPPING = 'NpaShippingFlow'  
export const CONFIG_NPA_E911_SHIPPING = 'NpaE911ShippingFlow'  
export const CONFIG_NPA_SHIPPING_INSTALLATION = 'NpaShippingInstallationFlow'  
export const CONFIG_NPA_E911_SHIPPING_INSTALLATION = 'NpaE911ShippingInstallationFlow'  
