import styled from "styled-components";
import { RadioBox } from "@vds3/radio-boxes";
import { Modal, ModalBody, ModalFooter } from "@vds3/modals";
import { ColorTokens } from "@vds-tokens/color";

export const InternetGatewayModal = styled(Modal)`
  height: auto;
  width: ${({ starkEnabled }) => (starkEnabled ? "40%" : "50%")};
  min-height: auto;
  outline: none !important;
  > div:first-child {
    overflow: hidden;
  }
  @media only screen and (max-width: 767px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95vw;
    > div:first-child {
      padding: 2px;
    }
  }
  @media only screen and (max-width:545px) {    
    bottom: 0px;
    position: fixed;
    top: 0px;
    left: 0px;
    transform:translate(0px,0px);
  }
`;
export const ButtonContainer = styled.div`
  @media screen and (max-width:767px) {
    bottom: 2rem;
    width: 100%;
    padding: 0px;
    margin-left: 0;
    flex-direction: column;
    row-gap: 15px;
    span {
      margin:0px !important;
    }
  }
`;

export const InternetGatewayModalBody = styled(ModalBody)`
  padding: 0 0px 0;
  outline: none !important;
  height: auto;
`;

export const InternetGatewayModalFooter = styled(ModalFooter)`
  padding: 0 0px 0;
  outline: none !important;
  height: auto;
`;

export const BYORModal = styled(Modal)`
  height: auto;
  width: 50%;
  min-height: auto;
  outline: none !important;
  > div:first-child {
    overflow: hidden;
  }
  @media only screen and (max-width: 767px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95vw;
    > div:first-child {
      padding: 2px;
    }
  }
`;

export const BYORModalBody = styled(ModalBody)`
  padding: 0 0px 0;
  outline: none !important;
  height: auto;
`;

export const BYORModalFooter = styled(ModalFooter)`
  padding: 0 0px 0;
  outline: none !important;
  height: auto;
`;
export const EquipmentCardGroup = styled.div`
  display: grid;
  // grid-template-columns: repeat(
  //   ${({ isMobile, span }) => (isMobile ? 1 : span ? span: 4)},
  //   ${({ isFromAuth }) => (isFromAuth ? "minmax(230px, 1fr)" : "minmax(158px, 1fr)")}
  // );
  grid-auto-rows: ${({ isMobile ,fromEquip }) => (isMobile ? "" : fromEquip ? "" : "1fr")};
  grid-gap: 16px;
  padding: 0;
  min-height: ${({ isMobile }) => (isMobile ? "244px" : "204px")};
  @media screen and (min-width:545px) and (max-width:768px){
    grid-template-columns: repeat(1, ${({ isFromAuth }) => (isFromAuth ? "minmax(230px, 1fr)" : "minmax(158px, 1fr)")}
    );
  }
  @media screen and (min-width:768px) and (max-width:940px){
    grid-template-columns: repeat(2, ${({ isFromAuth }) => (isFromAuth ? "minmax(230px, 1fr)" : "minmax(158px, 1fr)")}
    );
  }
`;

export const LinksSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LinksSubSection = styled.div`
  column-gap: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 2rem
`;

export const EquipmentRadioBox = styled(RadioBox)`
  label {
    span {
      height: 100%;
      > div {
        height: 100%;
      }
    }
  }

  border: ${props => (props.selected ? "3px solid #000000" : "1px solid #6f7171")}
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  row-gap: 8px;
  * {
    color: ${({ isDisabled }) =>
      isDisabled ? ColorTokens?.palette?.gray85?.value : ""};
  }
  @media only screen and (max-width:545px) {
    row-gap: 0px;

  }
`;

export const RecommendedBadge = styled.div`
  width: 100%;
  height: 1rem;
`;

export const CardContent = styled.div`
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: 10rem;
  @media only screen and (max-width: 545px) {
    flex-basis: unset;
  }
`;

export const CardDescription = styled.div`
  width: ${({onlyCBandQualified})=>onlyCBandQualified ? '35%' : '100%'};
  // ${({mwwCart, isByor,fromInstallation}) => fromInstallation ? 'margin-top:30px' : (mwwCart && isByor) ? 'margin-top: 20px' : 'margin-top: 16px;'}
`;

export const PriceBlock = styled.div`
  display: ${({disp})=>disp ? 'inline' : 'flex'};
  align-items: baseline;
  // flex-direction: column;
  // margin-top: 4px;
  // height: 6rem;
  @media only screen and (max-width: 545px) {
    white-space: unset;
    display: unset;
  }

`;
export const CardContentWrapper = styled.div`
  //  min-height: 150px;
  // padding: 0 10px;
   padding:  ${({ isMobile }) => isMobile ? '' : '0 1rem'}};

`

export const ImageContent = styled.div`
  width: fit-content;
`;

export const ImgCheckmark = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  margin-top: 3px;
`;
