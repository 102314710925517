import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "@vds3/modals";
import { Title } from "@vds3/typography";
import { TextLink } from "@vds3/buttons";
// import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BussProductList } from "../BussProduct/BussProductList";
import { useTranslation } from "react-i18next";

const ModalBodyContainer = styled.div`
> div h5{
  font-size: 2rem;
  font-weight: 700;
  line-height: 2rem;
}
> div p{
  font-size: 1rem;
}
`;

export const BussProductModal = ({
  setBussProductModal,
  bussProductModal,
  pageData,
  handleAddBussProduct,
  ...props}) => {
    // const navigate = useNavigate();
    const[selectedItemId,setSelectedItemId] = useState("");
    const[selectedCategoryId,setSelectedCategoryId] = useState("");

    const { t } = useTranslation(["shoppingCart"]);

  return (
    <Modal
      opened={props?.opened}
      hideCloseButton={true}
      disableOutsideClick={true}
      onOpenedChange={props?.onOpenedChange}
    >
      <ModalBodyContainer>
      <ModalTitle>
        <Title size="small">{t("shopping-cart.bussProduct.packageHeading")}</Title>
      </ModalTitle>
      <ModalBody>
      <TextLink >{"View More"}</TextLink>
      <BussProductList
        setSelectedItemId={setSelectedItemId}
        setSelectedCategoryId={setSelectedCategoryId}
        selectedItemId={selectedItemId}
        />    
      </ModalBody>
      <ModalFooter
        buttonData={{
          primary: {
            disabled:selectedItemId.length > 0 ? false :true,
            children:"Add to cart",
            onClick: () => {handleAddBussProduct(selectedItemId, selectedCategoryId)} ,
          },
          close: {
             children:"Cancel",
            onClick: () => {setBussProductModal(false)},
          },
        }}
      />
      </ModalBodyContainer>
    </Modal>
  );
};
