import React, { useMemo,FC } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./app/store";
import WidgetsDecider from "./WidgetsDecider";
import SpinnerComponent from "common/components/Spinner/Spinner";

const WidgetsDeciderWrapper = (props) => {

  useMemo(() => {
    window.localStorage.setItem("clientName", props?.container?.attributes?.clientName?.value);
  })
  return (
    <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <React.Suspense fallback={<SpinnerComponent />}>
          <WidgetsDecider
            clientId={props?.container?.attributes?.clientId?.value}
            sessionId={props?.container?.attributes?.sessionId?.value}
            inputAttributes={props?.container?.attributes}
            type={props?.container?.attributes?.type?.value}
            flow = {props?.container?.attributes?.flow?.value}
            clientName={props?.container?.attributes?.clientName?.value}
            fromProspect = {props?.container?.attributes?.fromProspect?.value}
          />
        </React.Suspense>
      </PersistGate>
    </Provider>
    </>
  );
};

export default WidgetsDeciderWrapper;
