import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import "./WidgetsDecider.css";
import ShoppingCart from "pages/ShoppingCart/ShoppingCart";
import CheckoutNew from "pages/CheckoutNew/CheckoutNew";
// import LandingPage4G from "pages/Landing4GNew/LandingPage4G";
// import LandingPage5G from "pages/Landing5GNew/LandingPage5G";
import QualificationLanding from "pages/Qualification/QualificationLanding";
import QualificationSelection from "pages/Qualification/QualificationSelection";
import LandingPage5G from "pages/Landing5G-V2/LandingPage5G";
import LandingPage4G from "pages/Landing4G-V2/LandingPage4G";

export const MarketplaceFWA = (props) => {

	const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
	
	return (
		globalData?.loadCheckoutWidget ? <CheckoutNew /> :
			globalData?.loadShoppingCartWidget ? (
				<ShoppingCart />) :
				globalData?.loadFWAQualifiedPlans ? (globalData?.currentFlow === '5g' ? <LandingPage5G /> : <LandingPage4G />)
					: globalData?.loadQualficationSelection ? (
						<QualificationSelection />)
						: <QualificationLanding />
		//(props?.currentFlow === '5G' ? <LandingPage5G/> : 	<LandingPage4G/>)
	);
};
