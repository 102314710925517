import { createSlice, createAction, createSelector } from "@reduxjs/toolkit";
import * as REF from "./ref";

const initialState = {
	showError: false,
	shippingCode: "",
	globalErrorMessage: "",
	notification: {},
	suspendFlow: false,
	suspendScenario: "",
	flowType: REF.CONFIG_SHIPPING_ONLY,
	flowFlags: {},
	creditPath: "BLENDED",
	creditEmailFlow: false,
	routingWithoutGuard: false,
	creditCheckStatus: "FAILURE",
	creditComparison: false,
	addressSuggestionList: [],
	fetchCart: null,
	fetchCartStatus: null,
	redirectTo4gPostAddressValidation: false,
	redirectTo5gPostAddressValidation: false,
	redirectToClearCart: false,
	invalidZipCode: false,
	hasCbandPresent: true,
	unifiedCartParams: {},
	isShoppingCartApiCallInProgress: {
		getFetchCart5G: false,
		getSessionCart: false,
		getRetrieveCart5G: false,
		getMetaInformation: false,
		postBusinessInformation: false,
		getAEMInformation: false,
		getShippingMethods: false,
		validateCreditDetails: false,
		shipping: false,
		resumeEcpdDocs: false,
		checkAddressValidation: false,
		getCreditReadAndSpoDetails: false,
	},
	isRetrieveCreditCartSuccess: false,
	isCheckoutPageApiCallInProgress: {
		storeTermsLoading: false,
		generateInstallment: false,
	},
	businessInfo: {},
	creditCheck5g: {},
	otp: {
		otpLoading: false,
		otpPhoneNumber: {},
		otpSendCode: {},
		otpValidatePasscode: {},
	},
	ddia: {
		linkUrlResponse: {},
		checkVerificationResult: {},
	},
	npaNxx: {
		loading: false,
		availableDevices: [],
		existingNumberState: {},
		npaNxxResponse: {},
		portInEligibilityResponse: {},
		releaseMdnResponse: {},
		retrieveMdnResponse: {},
		retrieveNpaNxxResponse: {},
		updateNicknameResponse: {},
		skipNpaNxx: false,
	},
	Installation: {
		skipInstallation: false,
		installationInfoResp: {},
	},
	Docusign: {
		loading: false,
		url: "",
	},
	safetechSessionId: "",
	blackBox: {
		bb: "",
		complete: false,
	},
	totalActiveSections: 1,
	currentSection: 1,
	activeSection: "",
	postBusinessInfoResponse: {},
	validateBusinessInfoResponse: {},
	cartId: "",
	cartApiError: false,
	cleartCartDetails: {},
	promoCodeDetails: {},
	removeStatus: false,
	promoCodeLoading: false,
	orderQuoteFetchSavedCartResponse: {},
	saveCartEmailAddress: "",
	isAddressUpdated: false,
	showProgressOverlay: false,
};

const ShoppingCartSlice = createSlice({
	name: "checkout",
	initialState,
	reducers: {
		resetShoppingCartState: () => {
			return initialState;
		},
		getPageInfo: () => {},
		setBusinessInfo: (state, action) => {
			state["businessInfo"] = action.payload;
		},
		setCreditPath: (state, action) => {
			state["creditPath"] = action.payload;
		},
		setCreditEmailFlow: (state, action) => {
			state["creditEmailFlow"] = action.payload;
		},
		setEnableRoutingWithoutGaurd: (state, action) => {
			state["routingWithoutGuard"] = action.payload;
		},
		setCreditCheckInfo: (state, action) => {
			state["creditCheckInfo"] = action.payload;
		},
		setCredit5gInfo: (state, action) => {
			state["creditCheck5g"] = action.payload;
		},
		setInstallmentsInfo: (state, action) => {
			state["installmentsInfo"] = action.payload;
		},
		setInstallationInfo: (state, action) => {
			state["installationInfo"] = action.payload;
		},
		setDepositInfo: (state, action) => {
			state["depositInfo"] = action.payload;
		},
		setShippingMethodInfo: (state, action) => {
			state["shippingMethodsInfo"] = action.payload;
		},
		setShippingResponse: (state, action) => {
			state["shippingResponse"] = action.payload;
		},
		setAddressValidationInfo: (state, action) => {
			state["addressValidationInfo"] = action.payload;
		},
		setStoreInfo: (state, action) => {
			state["storeInfo"] = action.payload;
		},
		// setMAADocUrlMethodInfo: (state, action) => {
		// state['maaDocUrlMethodInfo'] = action.payload
		//},
		setTOSInfo: (state, action) => {
			state["tosInfo"] = action.payload;
		},
		setGenericSection: (state, action) => {
			if (action?.payload?.data) {
				state[action.payload.componentName] = action.payload.data;
			}
		},
		setGlobalErrorMessage: (state, action) => {
			state["showError"] = action.payload.showError;
			state["globalErrorMessage"] = action.payload.message;
		},
		setFlowConfig: (state, action) => {
			state["flowConfig"] = action.payload;
		},
		setFlowType: (state, action) => {
			state["flowType"] = action.payload;
		},
		setRetrieveCart5G: (state, action) => {
			state[REF.RETRIVECART5GDATA] = action.payload;
		},
		setBISubmissionResponse: (state, action) => {
			state["biResponse"] = action.payload;
		},
		setAddressSuggestionList: (state, action) => {
			state["addressSuggestionList"] = action.payload;
		},
		setFetchCartResponse: (state, action) => {
			state["fetchCart"] = action.payload;
		},
		setFetchCartStatus: (state, action) => {
			state["fetchCartStatus"] = action.payload;
		},
		setUpdatedPackages: (state, action) => {
			state["fetchCart"]["cart"]["packages"] = action.payload;
		},
		setMetaInformation: (state, action) => {
			state["metaInformation"] = action.payload;
		},
		setvalidateCreditDetails: (state, action) => {
			state["creditDetails"] = action.payload;
		},
		setIsShoppingCartApiCallInProgress: (state, action) => {
			state["isShoppingCartApiCallInProgress"][action.payload.apiName] =
				action.payload.status;
		},
		setRetrieveCreditCartSuccess: (state, action) => {
			state["isRetrieveCreditCartSuccess"] = action.payload.status;
		},

		setUnifiedCartParams: (state, action) => {
			state["unifiedCartParams"] = action.payload;
		},
		setOtpLoading: (state, action) => {
			state["otp"]["otpLoading"] = action.payload;
		},
		setOtpPhoneNumber: (state, action) => {
			state["otp"]["otpPhoneNumber"] = action.payload;
		},
		setVerificationApiRetryCount: (state, action) => {
			state["ddia"]["verificationApiRetryCount"] = action.payload;
		},
		setOtpSendCode: (state, action) => {
			state["otp"]["otpSendCode"] = action.payload;
		},
		setOtpValidatePasscode: (state, action) => {
			state["otp"]["otpValidatePasscode"] = action.payload;
		},
		resetOtp: (state, action) => {
			state["otp"] = {
				otpLoading: false,
				otpPhoneNumber: {},
				otpSendCode: {},
				otpValidatePasscode: {},
			};
		},
		setDDIALinkResponse: (state, action) => {
			state["ddia"]["linkUrlResponse"] = action.payload;
		},
		setDDIACheckVerificationResult: (state, action) => {
			state["ddia"]["checkVerificationResult"] = action.payload;
		},

		setMitekCheckVerificationResult: (state, action) => {
			state["mitekCheckVerificationResult"] = action.payload;
		},

		setUpdateDeclinedStatusResult: (state, action) => {
			state["updateDeclinedStatusResult"] = action.payload;
		},

		setValidateSSNResult: (state, action) => {
			state["ValidateSSNResult"] = action.payload;
		},

		setMitekCheckVerificationProcess: (state, action) => {
			state["mitekCheckVerificationProcess"] = action.payload;
		},
		setGenerateMitekLink: (state, action) => {
			state["mitekVerificationLinkResponse"] = action.payload;
		},
		setNpaNxxLoading: (state, action) => {
			state["npaNxx"]["loading"] = action.payload;
		},
		setAvailableDevices: (state, action) => {
			state["npaNxx"]["availableDevices"] = action.payload;
		},
		setExistingNumberState: (state, action) => {
			state["npaNxx"]["existingNumberState"] = action.payload;
		},
		setNpaNxxNpaNxxResponse: (state, action) => {
			state["npaNxx"]["npaNxxResponse"] = action.payload;
		},
		setNpaNxxPortInEligibilityResponse: (state, action) => {
			state["npaNxx"]["portInEligibilityResponse"] = action.payload;
		},
		setNpaNxxReleaseMdnResponse: (state, action) => {
			state["npaNxx"]["releaseMdnResponse"] = action.payload;
		},
		setNpaNxxRetrieveMdnResponse: (state, action) => {
			state["npaNxx"]["retrieveMdnResponse"] = action.payload;
		},
		setNpaNxxRetrieveNpaNxxResponse: (state, action) => {
			state["npaNxx"]["retrieveNpaNxxResponse"] = action.payload;
		},
		setNpaNxxUpdateNicknameResponse: (state, action) => {
			state["npaNxx"]["updateNicknameResponse"] = action.payload;
		},
		setSkipNpaNxx: (state, action) => {
			state["npaNxx"]["skipNpaNxx"] = action.payload;
		},
		setSkipInstallation: (state, action) => {
			state["npaNxx"]["skipInstallation"] = action.payload;
		},
		setReviewTermsAgreementResponse: (state, action) => {
			state["reviewTermsAgreement"] = action.payload;
		},
		setSuspendFlow: (state, action) => {
			state["suspendFlow"] = action.payload;
		},
		setIsCreditResult: (state, action) => {
			state["isCreditResult"] = action.payload;
		},
		setSuspendScenario: (state, action) => {
			state["suspendScenario"] = action.payload;
		},
		setDocusignLoading: (state, action) => {
			state["Docusign"]["loading"] = action.payload;
		},
		setDocusignURL: (state, action) => {
			state["Docusign"]["url"] = action.payload;
		},
		setTNCDetailMethodInfo: (state, action) => {
			state["TNCDetailMethodInfo"] = action.payload;
		},
		setOneTalkEnrollment: (state, action) => {
			state["oneTalkEnrollment"] = action.payload;
		},
		setresumeEcpdDocsInfo: (state, action) => {
			state["resumeEcpdDocsInfo"] = action.payload;
		},
		setSafetechSessionId: (state, action) => {
			state["safetechSessionId"] = action.payload;
		},
		setBlackBox: (state, action) => {
			state["blackBox"] = action.payload;
		},
		setSessionCartData: (state, action) => {
			state["sessionCartData"] = action.payload;
		},
		setIsCheckoutPageApiCallInProgress: (state, action) => {
			state["isCheckoutPageApiCallInProgress"][action.payload.apiName] =
				action.payload.status;
		},
		setProgressState: (state, action) => {
			state["totalActiveSections"] = action.payload.totalSections;
			state["currentSection"] = action.payload.currentSection;
		},
		setActiveSection: (state, action) => {
			state["activeSection"] = action.payload.activeSection;
		},
		setE911AddressResponse: (state, action) => {
			state["e911AddressResponse"] = action.payload;
		},
		setFlowFlags: (state, action) => {
			state["flowFlags"] = action.payload;
		},
		setPostBusinessInfoResponse: (state, action) => {
			state["postBusinessInfoResponse"] = action.payload;
		},
		setValidateBusinessInfoResponse: (state, action) => {
			state["validateBusinessInfoResponse"] = action.payload;
		},
		setE911UnitAddress: (state, action) => {
			state["e911UnitAddress"] = action.payload;
		},
		setNotification: (state, action) => {
			state["notification"] = action.payload;
		},
		setCreditComparison: (state, action) => {
			state["creditComparison"] = action.payload;
		},
		setDeleteDirectoryResponse: (state, action) => {
			state["deleteDirectoryResponse"] = action.payload;
		},
		setViewDocumentResponse: (state, action) => {
			state["viewDocumentResponse"] = action.payload;
		},
		setRedirectTo4gPostAddressValidation: (state, action) => {
			state["redirectTo4gPostAddressValidation"] = action.payload;
		},
		setRedirectTo5gPostAddressValidation: (state, action) => {
			state["redirectTo5gPostAddressValidation"] = action.payload;
		},
		setRedirectToClearCart: (state, action) => {
			state["redirectToClearCart"] = action.payload;
		},
		setinvalidZipCode: (state, action) => {
			state["invalidZipCode"] = action.payload;
		},
		setHasCbandPresent: (state, action) => {
			state["hasCbandPresent"] = action.payload;
		},
		setCartId: (state, action) => {
			state["cartId"] = action.payload;
		},
		setCartApiError: (state, action) => {
			state["cartApiError"] = action.payload;
		},
		setCleartCartDetails: (state, action) => {
			state["cleartCartDetails"] = action.payload;
		},
		setPromoCodeDetails: (state, action) => {
			state["promoCodeDetails"] = action.payload;
		},
		setPromoCodeLoading: (state, action) => {
			state["promoCodeLoading"] = action.payload;
		},
		setRemoveStatus: (state, action) => {
			state["removeStatus"] = action.payload;
		},
		setSaveCartSuccess: (state, action) => {
			state["saveCartSuccess"] = action.payload;
		},
		setMultiShippingDetails: (state, action) => {
			state["multiShippingDetails"] = action.payload;
		},
		setOrderQuoteFetchSavedCartResponse: (state, action) => {
			state["orderQuoteFetchSavedCartResponse"] = action.payload;
		},
		setShippingCode: (state, action) => {
			state["shippingCode"] = action.payload;
		},
		setShowVisaOffer: (state, action) => {
			state["isShowVisaOffer"] = action.payload;
		},
		setInstallationInfoResp: (state, action) => {
			state["Installation"]["installationInfoResp"] = action.payload;
		},
		setConsetFlowSSN: (state, action) => {
			state["consetFlowSSN"] = action.payload;
		},
		setMitekVerficationResultLoading: (state, action) => {
			state["mitekVerficationResultLoading"] = action.payload;
		},
		setFromPartnersReward: (state, action) => {
			state["isFromPartnersReward"] = action.payload;
		},
		setSaveCartEmailAddress: (state, action) => {
			state["saveCartEmailAddress"] = action.payload;
		},
		setAddressUpdated: (state, action) => {
			state["isAddressUpdated"] = action.payload;
		},
		setShowProgressOverlay: (state, action) => {
			state["showProgressOverlay"] = action.payload;
		},
	},
});

export const reducer = ShoppingCartSlice.reducer;
export const getRetrieveCart5G = createAction(
	"ShoppingCart/getRetrieveProspect5G"
);
export const getStreetAddress = createAction("ShoppingCart/getStreetAddress");
export const getFetchCart5G = createAction("ShoppingCart/getFetchCart5G");
export const getSessionCart = createAction("ShoppingCart/getSessionCart");
export const getMetaInformation = createAction(
	"ShoppingCart/getMetaInformation"
);
export const getAEMInformation = createAction("ShoppingCart/getAEMInformation");
export const postSharedCartSaveUrl = createAction(
	"ShoppingCart/postSharedCartSaveUrl"
);
export const postClearCart = createAction("ShoppingCart/postClearCart");
export const postSaveCart = createAction("ShoppingCart/postSaveCart");
export const accessoriesRemove = createAction("ShoppingCart/accessoriesRemove");
export const packageRemove = createAction("ShoppingCart/packageRemove");
export const packageRemoveCband = createAction(
	"ShoppingCart/packageRemoveCband"
);
export const postApplyPromoCode = createAction(
	"ShoppingCart/postApplyPromoCode"
);
export const updatePackages = createAction("ShoppingCart/updatePackages");
export const saveAuthorizeResponse = createAction(
	"ShoppingCart/saveAuthorizeResponse"
);

export const getOrderQuoteFetchSavedCart = createAction(
	"ShoppingCart/getOrderQuoteFetchSavedCart"
);

export const {
	resetShoppingCartState,
	setBusinessInfo,
	setCreditCheckInfo,
	setInstallmentsInfo,
	setCreditPath,
	setNotification,
	setCreditComparison,
	setInstallationInfo,
	setDepositInfo,
	setShippingMethodInfo,
	setAddressValidationInfo,
	setStoreInfo,
	setTNCDetailMethodInfo,
	setresumeEcpdDocsInfo,
	setMAADocUrlMethodInfo,
	setTOSInfo,
	setGlobalErrorMessage,
	getPageInfo,
	setAddressSuggestionList,
	setFlowConfig,
	setGenericSection,
	setCredit5gInfo,
	setFlowType,
	setRetrieveCart5G,
	setBISubmissionResponse,
	setvalidateCreditDetails,
	callValidateCreditDetails,
	setFetchCartResponse,
	setFetchCartStatus,
	setMetaInformation,
	setIsShoppingCartApiCallInProgress,
	setRetrieveCreditCartSuccess,
	setUnifiedCartParams,
	setOtpLoading,
	setOtpPhoneNumber,
	setOtpSendCode,
	setOtpValidatePasscode,
	resetOtp,
	setNpaNxxLoading,
	setSkipNpaNxx,
	setSkipInstallation,
	setNpaNxxNpaNxxResponse,
	setNpaNxxPortInEligibilityResponse,
	setNpaNxxReleaseMdnResponse,
	setNpaNxxRetrieveMdnResponse,
	setNpaNxxRetrieveNpaNxxResponse,
	setNpaNxxUpdateNicknameResponse,
	setReviewTermsAgreementResponse,
	setSuspendScenario,
	setDocusignLoading,
	setDocusignURL,
	setSafetechSessionId,
	setBlackBox,
	setSessionCartData,
	setAvailableDevices,
	setIsCheckoutPageApiCallInProgress,
	setLoopQualLoading,
	setLoopQualFixedWirelessResp,
	setLoopQualStreetLookupResp,
	setLoopQualUnitLookupResp,
	setLoopQualFiosQualResp,
	setE911AddressResponse,
	setProgressState,
	setActiveSection,
	setFlowFlags,
	setPostBusinessInfoResponse,
	setExistingNumberState,
	setIsCreditResult,
	setVerificationApiRetryCount,
	setValidateBusinessInfoResponse,
	setRedirectTo4gPostAddressValidation,
	setRedirectTo5gPostAddressValidation,
	setRedirectToClearCart,
	setinvalidZipCode,
	setHasCbandPresent,
	setOneTalkEnrollment,
	setSuspendFlow,
	setE911UnitAddress,
	setDDIALinkResponse,
	setDDIACheckVerificationResult,
	setMitekCheckVerificationResult,
	setUpdateDeclinedStatusResult,
	setMitekCheckVerificationProcess,
	setGenerateMitekLink,
	setValidateSSNResult,
	setDeleteDirectoryResponse,
	setViewDocumentResponse,
	setCreditEmailFlow,
	setEnableRoutingWithoutGaurd,
	setUpdatedPackages,
	setCartId,
	setCartApiError,
	setCleartCartDetails,
	setPromoCodeDetails,
	setRemoveStatus,
	setSaveCartSuccess,
	setMultiShippingDetails,
	setOrderQuoteFetchSavedCartResponse,
	setShippingResponse,
	setShippingCode,
	setShowVisaOffer,
	setInstallationInfoResp,
	setConsetFlowSSN,
	setMitekVerficationResultLoading,
	setPromoCodeLoading,
	setFromPartnersReward,
	setSaveCartEmailAddress,
	setAddressUpdated,
	setShowProgressOverlay,
} = ShoppingCartSlice.actions;
