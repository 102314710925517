import { useState, useEffect, useRef } from "react";

const useStickyHeaderHeight = (updateTrigger) => {
  const stickyHeaderRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    window.addEventListener("resize", getHeaderSize);
  }, []);

  useEffect(() => {
    getHeaderSize();
  }, [updateTrigger, stickyHeaderRef]);

  const getHeaderSize = () => {
    setHeaderHeight(stickyHeaderRef?.current?.clientHeight ?? 0);
  };

  return { stickyHeaderRef, headerHeight };
}

export default useStickyHeaderHeight;