import React, { useState, useEffect } from "react";
//import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { TextLinkCaret } from "@vds3/buttons";
import { Title, Body } from "@vds3/typography";

const PromoCard = ({ aem, aemContent, promoType, ...props }) => {
	const [additionalURL, handleAdditionalURL] = useState("");
	//const navigate = useNavigate();

	const getImageUrl = (url) => {
		if (!(typeof url === "string" && url?.length)) {
			return "";
		} else if (url.charAt(0) === "/") {
			return `${window?.mbtGlobal?.assetsBaseUrl}${url}`;
		} else {
			return url;
		}
	};

	const getImageHeight = () => {
		if ("connectedLaptops" === promoType) {
			return "98%";
		}
		return null;
	};

	useEffect(() => {
		if (String(props.type).includes("non-smartPhone")) {
			const addURL = String(props.category)?.toLowerCase().split(" ");
			const resultURL =
				addURL.length > 1 ? addURL[0] + "-" + addURL[1] : addURL[0];
			handleAdditionalURL(resultURL + "/byod");
		}
	}, [props.category]);

	return (
		<>
			<a
				onClick={(e) => {
					e.preventDefault();
					let URL = aemContent?.externalUrl
						? aemContent?.url
						: aemContent?.relativeUrl + additionalURL;
					if (aemContent?.externalUrl) {
						window.open(URL, "_blank");
					} else {
						// navigate(`/${URL}`);
					}
				}}
			>
				<ProductBoxWrap
					imgHeight={getImageHeight()}
					url={getImageUrl(aemContent?.ss7ImgUrl)}
				>
					<TextOnImageWrapper>
						{!!aemContent?.headerText && (
							<TextOnImage size="small" color="#ffffff" bold>
								{aemContent?.headerText}
							</TextOnImage>
						)}
						{!!aemContent?.subHeaderText && (
							<SubtextOnImage size="medium" color="#ffffff">
								{aemContent?.subHeaderText}
							</SubtextOnImage>
						)}
						{!!aemContent?.linkText && (
							<LinkTextOnImage>
								{aemContent?.linkText}
								<TextLinkCaret surface="dark" iconPosition="right"></TextLinkCaret>
							</LinkTextOnImage>
						)}
					</TextOnImageWrapper>
				</ProductBoxWrap>
			</a>
		</>
	);
};

const ProductBoxWrap = styled.div`
	margin-top: 10px;
	background-image: ${({ url }) => `url("${url}")`};
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	min-height: 542px;
	max-width: 290px;
	height: 95%;
	left: 0px;
	top: 0px;
	box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.02);
	border-radius: 8px;
	position: relative;
	cursor: pointer;
	transition-duration: 0.2s;
	@media screen and (min-device-width: 768px) {
		:hover {
			transform: scale(1.02);
		}
	}
	@media screen and (min-device-width: 320px) and (max-device-width: 767px) {
		background-size: 100% 100%;
		height: 98.5%;
	}
`;

const TextOnImageWrapper = styled.div`
	position: absolute;
	left: 20px;
	right: 20px;
	top: 55%;

	@media (max-width: 768px) {
		top: 40%;
	}
	@media (max-width: 420px) {
		left: 10px;
		right: 10px;
	}

	p {
		margin: 12px 0px;
	}
`;

const TextOnImage = styled(Title)`
	display: flex;
`;

const SubtextOnImage = styled(Body)`
	display: flex;
	letter-spacing: 0.5px;
`;

const LinkTextOnImage = styled.div`
	display: flex;
	align-items: center;
	display: flex;
	font-weight: 700;
	color: #ffffff;

	* {
		stroke: aliceblue !important;
	}
	& > a {
		padding-left: 0px;
	}
`;

export default PromoCard;
