import React, { useEffect, useState } from 'react'
import { Body, Title } from "@vds3/typography";
import { MarginSpacer2XL, MarginSpacerL, MarginSpacerM, MarginSpacerS } from 'common/components/styleTag';
import { Button } from '@vds3/buttons';
import { Table,  TableHead,  TableHeader,  TableBody, TableRow,   Cell } from "@vds3/tables";
import Information from './Information';
import { Modal, ModalBody, ModalTitle, ModalFooter } from "@vds3/modals";
import { DropdownSelect } from "@vds3/selects";


const ProductsPage = ({ purchaseItem }) => {
    const [selectedId,setSelectedId] = useState(undefined)
    const [showModal,setShowModal] = useState(false)
    const [licenseSelected,setLicenseSelected] = useState(0)
    const getZeros = (val) => {
        let zero = ''
        for (var i = 0; i < (5 - (val.length)); i++) zero = zero + '0'
        return ('-' + zero + val)
    }
    if (!!purchaseItem && Object.keys(purchaseItem).length) {
        return Object.entries(purchaseItem).map(([key, value]) => {
            return (
                <div>
                    <DeletModal licenseNumer={licenseSelected} opened={showModal} onOpenedChange={(val)=>{
                        if(!val) setShowModal(val)
                    }}/>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Title size="small" bold={true}>{key}</Title>
                        &nbsp;&nbsp;
                        <Body size="medium">{value[0]['customerId'] + getZeros(value[0]['accountNumber'])}</Body>
                    </div>
                    <Table
                        striped={false}
                        bottomLine="secondary"
                        padding="compact"
                        surface="light"
                        >
                        <TableHead bottomLine="primary">
                            <TableHeader >Product Name </TableHeader>
                            <TableHeader>License </TableHeader>
                            <TableHeader>Plan</TableHeader>
                        </TableHead>
                        <TableBody>
                            {value.map(product => (
                                <Products accNo = {value[0]['customerId'] + getZeros(value[0]['accountNumber'])} product={product} onManageClick={(val)=>{
                                    // let selected = [...selectedId]
                                    // if(selected.includes(val) ) selected = selected.filter(data=>data!==val)
                                    // else selected.push(val)
                                    setSelectedId(val)
                                }} selectedId={selectedId} onDeleteClicked={(val)=>{
                                    setLicenseSelected(val)
                                    setShowModal(true)
                                }}/>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            )
        })

    } else return <Title bold={true} size="large">No items found</Title>
}

const Products = ({accNo="",selectedId, product,onManageClick = ()=>{} ,onDeleteClicked=()=>{}}) => {
    const isSelected = (selectedId && (selectedId === product['item']['itemID'])) ? true : false
    const onManageclick = ()=>{
        if(isSelected){
            onDeleteClicked(product['item']['licenseInfo']['licensePurchased'])
        }else{
            onManageClick(product['item']['itemID'])
        }
    }
    const isMonthly = Number(product['item']['pricePlanPackageDetails'][0]['purchasePrice']) < Number(product['item']['purchasePrice'])
    return (
        <>
        <TableRow>
            <Cell>{product['item']['itemName']} </Cell>
            <Cell> {product['item']['licenseInfo']['licensePurchased']}</Cell>
            <Cell>{isMonthly ? 'Monthly':'Yearly'}{`(${product['item']['licenseInfo']['licensePurchased']})`}</Cell>
            <Cell><Button size="small" onClick={()=>onManageclick()}>{isSelected ? 'Delete License' : 'Manage'}</Button></Cell>
        </TableRow>
        {isSelected ?
        <TableRow>
            <Information accNo={accNo} purchaseItem={product}/>
        </TableRow>
        
        : <></>}
        </>
    )
}
const DeletModal = ({licenseNumer =10,opened=false,onOpenedChange=()=>{},onClicked=()=>{}})=>{
    const [valueSelected,setValueSelected] = useState(1)
    const option = new Array(licenseNumer).fill(1) 
return(
    <Modal
    width="560px"
    onOpenedChange={onOpenedChange}
    opened={opened}
  >
    <ModalTitle>
      <Title size="large" bold>
        Are you sure you want to delete licenses?
      </Title>
    </ModalTitle>
    <ModalBody>
        <Body size="large">Select the number of licenses you want to delete</Body>
        <MarginSpacerM/>
        <Body size="large">You are trrying to delete all your licenses. This will be considered as unsubscribing your license. Are you sure you want to delete?</Body>
        <MarginSpacerM/>
        <div style={{maxWidth:'200px'}}>
        <DropdownSelect 
        label="License"
        error={false}
        disabled={false}
        readOnly={false}
        inlineLabel={false}
        >
            {option.map((val,i)=>(
                <option value={i+1}>{i+1}</option>
            ))}
        
    </DropdownSelect>
    </div>
    </ModalBody>
    <ModalFooter
      buttonData={{
        primary: {
          children: "Delete",
          onClick: () => {
            onClicked();
          },
          'data-track':"Continue"
        },
        close: {
          children: "Cancel",
          onClick: () => {
            onOpenedChange(false);
          },
          'data-track':"Cancel"
        },
      }}
    />
  </Modal>
)
}


export default ProductsPage