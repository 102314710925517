import React, { useEffect, useReducer, useRef, useState } from "react";
import { Button } from "@vds3/buttons";
import VerticalProgressContext from "common/components/VerticalProgress/verticalProgressContext";
import { Title, Body, Micro } from "@vds3/typography";
import { MarginSpacerM, MarginSpacerS } from "common/components/styleTag";
import { Input } from "@vds3/inputs";
import { Grid, Row, Col } from "@vds3/grids";
import MapQStreetAddress from "./containers/MapQStreetAdress";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { InitialState, reducer, actions as Actions } from "./containers/FormReducer";
import { useTranslation } from "react-i18next";
import * as CONSTANTS from "./containers/constants";
import { checkAddressValidation, setAddressValidationInfo, setAboutBusinessInfor, setZipChangeInfoShown } from "../../../../pages/Checkout/ducks/CheckoutSlice";
import AddressVerificationModal from "./AddressVerificationModal";
import { useMediaQuery } from 'react-responsive'
import { Notification } from "@vds3/notifications";
import { checkIfAemExists } from "utils/commonUtils";

const AboutBusiness = ({ ...props }) => {
    const {
        isComplete,
        onComplete,
        setModifier,
        isEditMode: { isEditMode, setIsEditMode },
    } = React.useContext(VerticalProgressContext);
    const { t } = useTranslation(["app", "verification", "checkout", "mock-response"]);
    const isMobile = useMediaQuery({
        query: "(max-width: 525px)",
    });
    const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
    const pageData = useSelector((state) => state.ProspectWidgets_CheckoutData);
    const sessionCartData =
        globalData?.sessionCartData?.apiResponse?.prospectFlowData;
    const isDigitalQuote = sessionCartData?.cart?.digitalQuote;
    const isAbandonedCart = sessionCartData?.cart?.abandonedCart;
    const isTaxIdOptional = globalData?.cmp?.isFromGoogleShop;
    // const isTaxIdOptional = false;
    const [taxErrors, setTaxErrors] = useState({});
    const [phoneErrors, setPhoneErrors] = useState({});
    const [companyErrors, setCompanyErrors] = useState({ "companyName": "" });
    const [streetErrors, setStreetErrors] = useState({});
    const [businessSaStreetAddressObj, setBusinessStreetADressObj] = useState(null);
    const [businessAddress, setBusinessAddress] = useState("");
    const [disableContinueBtn, setDisableContinueBtn] = useState(false);
    const [businessRawAddress, setBusinessRawAddress] = useState("");
    const [taxIDLabel, setTaxIDLabel] = useState(true);
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [showZipCodeNotification, setShowZipCodeNotification] = useState(false);
    const [unitError,setUnitError] = useState("");
    const unitErrorMessage =
        t("checkout.businessInfo-section.addressConfirmationModal.unitErrorMessage", {
            ns: "checkout"
        }) || '';
    const billingState = sessionCartData?.cart?.billingState;
    const isOrderQuoteFlow = globalData.isOrderQuoteFlow;
    const addressConfirmaionContent = t("checkout.businessInfo-section.addressConfirmationModal", {
        ns: "checkout",
        returnObjects: true,
    });
    const businessStreetRef = useRef();
    const packages = sessionCartData?.cart?.packages;
    const byodDeviceList =
        packages &&
        Object.keys(packages).filter(
            (key) => packages[key]?.byod == true && packages[key]?.portIn == true
        );
    const tabletDeviceList =
        packages &&
        Object.keys(packages)?.filter(
            (key) => packages[key]?.deviceCategory?.toLowerCase()?.includes("tablet")
        );
    const tabletOfferStateList =
        t("checkout.businessInfo-section.specialOfferZipStates.tablets", {
            ns: "checkout",
            returnObjects: true,
        }) || [];
    const byodOfferStateList =
        t("checkout.businessInfo-section.byodOfferZipStates.byod", {
            ns: "checkout",
            returnObjects: true,
        }) || [];
    const byodZipOfferToggle = t("checkout.businessInfo-section.byodZipOfferToggle", {
        ns: "checkout",
        returnObjects: true,
    })
    const [state, dispatch] = useReducer(
        reducer,
        Object.keys(pageData?.aboutBusinessInfor || {}).length === 0 ? InitialState : pageData?.aboutBusinessInfor
    );
    const dispatch1 = useDispatch();
    const useMock = checkIfAemExists(t("validate-address-mock", { ns: "mock-response" }));
    useEffect(() => {
        if (pageData?.addressValidationInfo?.serviceStatus?.success) {
            // do something on sucess
            setShowAddressModal(false);
            let temp = Object.assign({}, state)
            setBusinessAddress(temp?.businessStreetAddress?.displayString)
            handleAddressSelection(temp?.businessStreetAddress);
            dispatch1(setAboutBusinessInfor(state))
            // setModifier(null);
            onComplete();
            // setIsEditMode(false)
        } else if (
            pageData?.addressValidationInfo?.serviceStatus &&
            !pageData?.addressValidationInfo?.serviceStatus?.success
        ) {
            setStreetErrors({
                ...streetErrors,
                businessStreetAddress: addressConfirmaionContent?.errors?.addrInvalid,
            });
            if (pageData?.addressValidationInfo?.serviceStatus?.statusMessage == "For security and fraud reasons, we do not ship to PO Boxes") {
                let tempErrors = {};
                tempErrors = {
                    ...streetErrors,
                    businessStreetAddress:
                        t("checkout.businessInfo-section.errorMsgs.poNotAllowed", {
                            ns: "checkout",
                        }),
                };
                setStreetErrors(tempErrors);
            } else {
                if (!showAddressModal) {
                    setShowAddressModal(true);
                }
            }
        }
        dispatch1(setAddressValidationInfo(null));
        if (!state.companyName) {
            const compName = document.getElementById('busin-name')
            if (compName) compName.focus()
        }
    }, [pageData?.addressValidationInfo?.serviceStatus]);

    useEffect(() => {
        if(globalData?.widgetInputParams?.companyName?.value){
            dispatch({ type: CONSTANTS.SET_COMPANY_NAME, payload: globalData?.widgetInputParams?.companyName?.value });
        }
        try {
          if (typeof window['lpTag'] != 'undefined' && lpTag?.section && window?.lpTag?.newPage) {
            lpTag.section = manipulateLpTagSections();
            lpTag.section.push("sec:biz-info")
            setTimeout(() => {
              window?.lpTag?.newPage(window?.location?.href, {
                section: lpTag.section,
              });
            }, 8000);
          }
        } catch (e) {
          console.error(e);
        }
      }, []);
    // eliminating loopqual address as per new requirements

    // useEffect(() => {
    //     const address = sessionCartData?.prospect5gLoopQualAddress;
    //     if (address !== null && typeof address === 'object') {
    //         if (Object.keys(address)?.length > 0 && !businessAddress) {
    //             let currentAddress = {
    //                 place: {
    //                     properties: {
    //                         country: address?.country,
    //                         countryCode: address?.country,
    //                         state: address?.state,
    //                         stateCode: address?.state,
    //                         street: address?.addressLine1,
    //                         city: address?.city,
    //                         postalCode: address?.zipCode,
    //                         propertyType: address?.propertyType,
    //                     },
    //                 },
    //                 displayString: `${address?.addressLine1 || ""}, ${address?.city || ""}, ${address?.state || ""}, ${address?.zipCode || ""}`,
    //                 name: address?.addressLine1,
    //             };
    //             if (currentAddress) {
    //                 handleAddressSelection(currentAddress);
    //                 dispatch({
    //                     type: CONSTANTS.SET_BUSINESS_STREET_ADDRESS,
    //                     payload: currentAddress,
    //                 });
    //                 setBusinessAddress(currentAddress?.displayString || "")
    //                 // setAddresLineOne(address?.addressLine1 || "");
    //             }
    //         }
    //     }
    //     if (address?.phoneNumber) {
    //         dispatch({
    //             type: CONSTANTS.SET_PHONE_NUMBER,
    //             payload: address?.phoneNumber,
    //         });
    //     }
    //     // if(address?.email){
    //     //     dispatch(
    //     //       Actions.setContactInformation({ ...state?.contactInfo, ["email"]: props?.prospect5gLoopQualAddress?.email })
    //     //     );
    //     //   }
    // }, [sessionCartData?.prospect5gLoopQualAddress]);

    useEffect(() => {
        if (!isEditMode && Object.keys(pageData?.aboutBusinessInfor || {}).length) {
            dispatch({ type: CONSTANTS.SET_COMPANY_NAME, payload: pageData?.aboutBusinessInfor?.companyName });
            dispatch({ type: CONSTANTS.SET_PHONE_NUMBER, payload: pageData?.aboutBusinessInfor?.phoneNumber });
            dispatch(Actions.setCorporation({ ...state?.corporation, ["fedTaxId"]: pageData?.aboutBusinessInfor?.corporation?.fedTaxId }));
            dispatch({
                type: CONSTANTS.SET_BUSINESS_STREET_ADDRESS,
                payload: pageData?.aboutBusinessInfor?.businessStreetAddress,
            });
            let tempErrors = {
                ...streetErrors,
                businessStreetAddress: "",
            };
            dispatch({
                type: CONSTANTS.SET_SHIPPING_ERRORS,
                payload: { ...tempErrors },
            });
            setStreetErrors(tempErrors);
            dispatch(Actions.setAddressUnits(pageData?.aboutBusinessInfor?.companyAddressUnit))
            setBusinessAddress(pageData?.aboutBusinessInfor?.businessStreetAddress?.displayString)
        }
        else {
            //SiteAnalytics.dynamicComponentRendered({ order: 1, sectiontName: "Business Verification",subSection: "About Business",isEditMode:false});
            setShowAddressModal(false);
            // dispatch1(setAddressValidationInfo(null));
        }
    }, [isEditMode])

    const checkForSpecialChar = (string) => {
        //Regular expression that allows [',.$-] in a string.
        const pattern = /^[A-Za-z0-9&\-',.\s]+$/;
        return pattern.test(string);
    };

    const isValidContactInfoField = (key, e) => {
        let errorTexts = "";
        let val = e;
        // businessName
        if (key === "companyName") {
            errorTexts =
                !val || !val?.trim().length
                    ? t("checkout.businessInfo-section.errorMsgs.companyName", { ns: "checkout" })
                    : val === val.replace(/[^0-9!@#\$%\^\&*]/g, "")
                        ? t("checkout.businessInfo-section.errorMsgs.companyNameInValid", {
                            ns: "checkout",
                        })
                        : "";
            const specialCharactersNotAllowed = t(
                "checkout.businessInfo-section.errorMsgs.specialCharactersNotAllowed",
                { ns: "checkout" }
            );
            if (val && specialCharactersNotAllowed && specialCharactersNotAllowed === true) {
                let errorVal = false;
                const splChar = t("checkout.businessInfo-section.errorMsgs.specialCharactersForCompanyName", {
                    ns: "checkout",
                    returnObjects: true,
                });
                if (
                    splChar !== null &&
                    splChar &&
                    splChar?.length &&
                    typeof splChar == "object"
                ) {
                    // splChar.map(char=>{
                    //   if(String(val).includes(char)) errorVal  = true
                    //   return
                    // })
                    if (!checkForSpecialChar(val)) {
                        errorVal = true;
                    }
                    if (errorVal)
                        errorTexts = t("checkout.businessInfo-section.errorMsgs.validCompanyName", {
                            ns: "checkout",
                        });
                }
            }
            setCompanyErrors({ ...companyErrors, [key]: errorTexts });
            dispatch({ type: CONSTANTS.SET_COMPANY_NAME, payload: val });
            if (errorTexts?.length) return true;
            else return false;
        }
        if (key === "phoneNumber") {
            var checkChars = /[^0-9]/gi;
            if (checkChars?.test(val)) {
                val = val?.replace(checkChars, "");
            }
            const phoneNumber = val?.replace(/[^\d]/g, "");
            if (/^[0-9]{0,10}$/.test(phoneNumber)) {
                val = phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
            }
            if (!val?.trim()) {
                errorTexts = t("checkout.businessInfo-section.errorMsgs.phoneReq", {
                    ns: "checkout",
                });
            } else {
                const pattern = /^\d{10}$/;
                if (!pattern.test(phoneNumber)) {
                    errorTexts = t("checkout.businessInfo-section.errorMsgs.phoneReq", {
                        ns: "checkout",
                    });
                } else {
                    errorTexts = "";
                }
            }
            setPhoneErrors({ ...phoneErrors, [key]: errorTexts });
            dispatch({ type: CONSTANTS.SET_PHONE_NUMBER, payload: val });
            if (errorTexts?.length) return true;
            else return false;
        }
        if (key === "unit") {
            const PATTERN_ADDRESS = /^[A-Za-z0-9 ]+$/.test(val);
            if(!PATTERN_ADDRESS && val?.length){
                errorTexts = t("checkout.businessInfo-section.errorMsgs.unitError", {
                    ns: "checkout",
                });
                dispatch(Actions.setAddressUnits(val))
                setUnitError({ ...unitError, [key]: errorTexts });
            }
            else{
                dispatch(Actions.setAddressUnits(val))
                setUnitError({ ...unitError, [key]: "" });
            }
        }
    }

    const isValidTaxIdInfo = (key, e) => {
        let val = "";
        let errors = "";
        if (key === "fedTaxId") {
            val = e ? e.replace(/[^0-9]/g, "").replace(/(\d{2})(\d{7})/, "$1-$2") : "";
            if (isTaxIdOptional && val === "") {
                errors = ""
            }
            else {
                errors =
                    (!isTaxIdOptional && !val) || !val?.trim() || val?.length < 10 || val == undefined
                        ? t("checkout.businessInfo-section.errorMsgs.validTaxId", { ns: "checkout" })
                        : val?.length > 10 ? t("checkout.businessInfo-section.errorMsgs.validTaxId", { ns: "checkout" }) : "";
            }
            dispatch(Actions.setCorporation({ ...state?.corporation, [key]: val }));
            setTaxErrors({ ...taxErrors, [key]: errors });
            if (errors?.length) return true;
            else return false;
        }
    }

    const handleAddressSelection = (value) => {
        let tempErrors = {};
        if (!value) {
            tempErrors = {
                ...streetErrors,
                businessStreetAddress: t("checkout.businessInfo-section.errorMsgs.addressLine1Req", { ns: "checkout" }),
            };
        } else {
            tempErrors = {
                ...streetErrors,
                businessStreetAddress: "",
            };
        }
        const properties = value?.place?.properties;
        if (
            properties?.street?.toUpperCase &&
            properties?.street?.toUpperCase()?.includes("PO BOX")
        ) {
            tempErrors = {
                ...streetErrors,
                businessStreetAddress:
                    t("checkout.businessInfo-section.errorMsgs.poNotAllowed", {
                        ns: "checkout",
                    }),
            };
        }
        setBusinessStreetADressObj(value);
        setBusinessAddress(properties ? (properties?.displayString ? properties?.displayString : value?.displayString) : value?.businessStreetAddress?.displayString);
        if (properties?.unit || properties?.unit == "") {
            isValidContactInfoField("unit", properties?.unit)
        }
        setStreetErrors(tempErrors);
        dispatch({
            type: CONSTANTS.SET_BUSINESS_STREET_ADDRESS,
            payload: value,
        });
        dispatch({
            type: CONSTANTS.SET_SHIPPING_ERRORS,
            payload: { ...tempErrors },
        });
        let bizInfoState = properties?.stateCode || properties?.state;
        if (
            billingState !== bizInfoState &&
            ((byodDeviceList?.length > 0 && byodZipOfferToggle && !(byodOfferStateList?.includes(billingState) && byodOfferStateList?.includes(bizInfoState)))
                || tabletDeviceList?.length > 0 && !(tabletOfferStateList?.includes(billingState) && tabletOfferStateList?.includes(bizInfoState)))
        ) {
            setShowZipCodeNotification(true);
            dispatch1(setZipChangeInfoShown(true));
        } else {
            setShowZipCodeNotification(false);
            dispatch1(setZipChangeInfoShown(false));
        }
        if (tempErrors?.businessStreetAddress?.length) return true;
        else return false;
        // if(isShippingInfoComplete && !showContinueBtn){
        //   setDisableAcceptContinue(true);
        //   setShowContinueBtn(true);
        //   props?.eventHandlers?.emit(
        //     "disableContinueBtn"
        //   );
        // } 
    };
    const handleAddressInput = (e, name) => {
        const value = String(e?.target?.value).trimStart();
        setBusinessStreetADressObj(null);
        dispatch({
            type: CONSTANTS.SET_BUSINESS_STREET_ADDRESS,
            payload: "",
        });
        if (!value?.toUpperCase()?.includes("PO BOX")) {
            dispatch({
                type: CONSTANTS.SET_SHIPPING_ERRORS,
                payload: { ...streetErrors, businessStreetAddress: "" },
            });
            setStreetErrors({
                ...streetErrors,
                businessStreetAddress: ""
            })
        }
        if (value?.length > 0 && disableContinueBtn) {
            setDisableContinueBtn(false);
        }
        setBusinessRawAddress(value);
        // if (isShippingInfoComplete && !showContinueBtn) {
        //     setDisableAcceptContinue(true);
        //     setShowContinueBtn(true);
        //     props?.eventHandlers?.emit(
        //         "disableContinueBtn"
        //     );
        // }
    };

    const validateBusinessAddress = (add = null, unit = null) => {
        // handleContinueClicked(true)
        let payload;
        const businessSaStreetAddressObj = state?.businessStreetAddress;
        // eleminating loop qual address
        const address = { ...add };
        if (add?.addressLine1) {
            payload = {
                addressLine1: add?.addressLine1,
                addressLine2: add?.addressLine2,
                city: add?.city,
                state: add?.state,
                zipCode: add?.zipCode,
            };
            // set state for businessInfor
            let objValue = {
                place: {
                    properties: {
                        country: "",
                        countryCode: "",
                        state: add?.state,
                        stateCode: add?.stateCode,
                        street: add?.addressLine1,
                        unit: add?.addressLine2 || "",
                        city: add?.city,
                        postalCode: add?.zipCode,
                        propertyType: "",
                    },
                },
                displayString: add?.addressLine1,
                name: add?.addressLine1,
            };
            handleAddressSelection(objValue)
        }
        else if (businessSaStreetAddressObj) {
            const properties = businessSaStreetAddressObj?.place?.properties;
            payload = {
                addressLine1: properties?.street || properties?.addressLine1,
                addressLine2: state?.companyAddressUnit,
                city: properties?.city,
                state: properties?.stateCode || properties?.state,
                zipCode: properties?.postalCode || properties?.zipCode,
                useMock: useMock
            };
        }else if(businessRawAddress){
            payload ={
                addressLine1 : businessRawAddress,
                addressLine2: state?.companyAddressUnit,
                city: "",
                state: "",
                zipCode: "",
            }
        }
        dispatch1(checkAddressValidation(payload));
    };
    const checkAllFeilds = () => {
        let checkCompany = isValidContactInfoField("companyName", state?.companyName ?? " ")
        let checkPhone = isValidContactInfoField("phoneNumber", state?.phoneNumber ?? " ")
        let checkfedTaxId = isValidTaxIdInfo("fedTaxId", state?.corporation?.fedTaxId ?? " ")
        let checkStreetAdd = handleAddressSelection(state?.businessStreetAddress ?? " ")
        if (businessRawAddress?.length) { checkStreetAdd = false }
        return ([checkCompany, checkPhone, checkfedTaxId, checkStreetAdd])
    }

    const hasErrors = () => {
        let checkAll = checkAllFeilds()
        let error = false;
        for (let temp of checkAll) {
            if (temp) {
                error = true;
            }
        }
        if (!state?.companyName
            || !state?.phoneNumber
            || (!isTaxIdOptional && !state?.corporation?.fedTaxId)
            || (!businessRawAddress?.length && !state?.businessStreetAddress?.displayString?.length)
            || (businessRawAddress?.toUpperCase().includes("PO BOX"))
        ) {
            error = true;
        }
        if (!error) {
            validateBusinessAddress()
        } else {
            let element = document.getElementById('aboutBusiness');
            let elementPosition = element?.offsetTop;
            window.scrollTo({
                top: elementPosition,
                behavior: "smooth"
            })
        }
    }

    const getDefaultContent = () => {
        return (
            <>
                <div id="aboutBusiness">
                    {isMobile ?
                        <Body size="large"><StyledSpan
                            dangerouslySetInnerHTML={{
                                __html: t("checkout.businessInfo-section.messages.buss-info-mobile", {
                                    ns: "checkout",
                                }),
                            }}
                        /></Body> :
                        <Body size="large"><StyledSpan
                            dangerouslySetInnerHTML={{
                                __html: t("checkout.businessInfo-section.messages.buss-info-desktop", {
                                    ns: "checkout",
                                }),
                            }}
                        /></Body>}
                    <MarginSpacerM />
                    {isMobile ? <Body size="large"><StyledSpan
                        dangerouslySetInnerHTML={{
                            __html: t("checkout.businessInfo-section.messages.buss-info1-mobile", {
                                ns: "checkout",
                            }),
                        }}
                    /></Body> :
                        <Body size="large"><StyledSpan
                            dangerouslySetInnerHTML={{
                                __html: t("checkout.businessInfo-section.messages.buss-info1-desktop", {
                                    ns: "checkout",
                                }),
                            }}
                        /></Body>}

                    <MarginSpacerM />
                    <AboutBusinessWrapper>
                     <Body size="small"><span>{t("checkout.businessInfo-section.messages.infomsg1", {
                        ns: "checkout",
                     })}</span></Body>
                     <Grid
                        colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
                        colGutter={false}
                        rowGutter="5"
                    >
                        <Row>
                            <ColPad colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
                                <div className="form-col ">
                                    <Input
                                        inputId="busin-name"
                                        label={`${t("checkout.businessInfo-section.labelTexts.companyName", {
                                            ns: "checkout",
                                        })} *`}
                                        required={true}
                                        placeholder=""
                                        name="companyName"
                                        width={"100%"}
                                        maxLength={30}
                                        value={state?.companyName}
                                        onChange={(e) => isValidContactInfoField("companyName", e?.target?.value)}
                                        error={companyErrors?.companyName?.length > 0}
                                        errorText={
                                            companyErrors?.companyName ||
                                            t("checkout.businessInfo-section.errorMsgs.companyName", {
                                                ns: "checkout",
                                            })
                                        }
                                        tooltipContent={t("checkout.businessInfo-section.labelTexts.companyNameTooltip", {
                                            ns: "checkout",
                                        })}
                                    />
                                </div>
                            </ColPad>
                        </Row>
                        {!isTaxIdOptional ? <Row>
                            <ColPad colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
                                <div className="form-col show-label">
                                    <Input
                                        label={`${t("checkout.businessInfo-section.labelTexts.federalTaxID", {
                                            ns: "checkout",
                                        })}${isTaxIdOptional ? "" : " *"}`}
                                        placeholder=""
                                        required={!isTaxIdOptional}
                                        name="fedTaxId"
                                        width={"100%"}
                                        maxLength={9}
                                        value={state?.corporation?.fedTaxId}
                                        onChange={(e) => isValidTaxIdInfo("fedTaxId", e?.target?.value)}
                                        error={taxErrors?.fedTaxId?.length > 0}
                                        errorText={
                                            taxErrors?.fedTaxId ||
                                            t("checkout.businessInfo-section.errorMsgs.validTaxId", { ns: "checkout" })
                                        }
                                        tooltipContent={
                                            isTaxIdOptional
                                                ? t("checkout.businessInfo-section.labelTexts.FederalIdOptionalTooltip", {
                                                    ns: "checkout",
                                                })
                                                : t("checkout.businessInfo-section.labelTexts.FederalIdTooltip", {
                                                    ns: "checkout",
                                                })
                                        }
                                        type={"password"}
                                        onClickInlineAction={() =>
                                            taxIDLabel ? setTaxIDLabel(false) : setTaxIDLabel(true)
                                        }
                                        onBlur={(e) => {
                                            if (!isTaxIdOptional) isValidTaxIdInfo("fedTaxId", e?.target?.value)
                                        }}
                                    />
                                    <div className="helper-text">
                                        <Body size="small" color="#6F7171">
                                            {t("checkout.businessInfo-section.helperTexts.federalTaxID", {
                                                ns: "checkout",
                                            })}
                                        </Body>
                                    </div>
                                </div>
                            </ColPad>
                        </Row> : <></>}

                        <Row>
                            <ColPad colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
                                <div className="form-col">
                                    <Input
                                        label={`${t("checkout.businessInfo-section.labelTexts.bussinessPhone", {
                                            ns: "checkout",
                                        })} *`}
                                        placeholder=""
                                        maxLength={10}
                                        name="phoneNumber"
                                        value={
                                            state?.phoneNumber?.length === 10
                                                ? state?.phoneNumber?.includes("-")
                                                    ? state?.phoneNumber
                                                    : state?.phoneNumber?.replace(
                                                        /(\d{3})(\d{3})(\d{4})/,
                                                        "$1-$2-$3"
                                                    )
                                                : state?.phoneNumber
                                        }
                                        helperText=""
                                        width={"100%"}
                                        onChange={(e) => isValidContactInfoField("phoneNumber", e?.target?.value)}
                                        error={phoneErrors?.phoneNumber?.length > 0}
                                        errorText={
                                            phoneErrors?.phoneNumber ||
                                            t("checkout.businessInfo-section.errorMsgs.phoneReq", { ns: "checkout" })
                                        }
                                    />
                                    <div className="helper-text">
                                        <Body size="small" color="#6F7171">
                                            {t("checkout.businessInfo-section.helperTexts.phoneNumber", {
                                                ns: "checkout",
                                            })}
                                        </Body>
                                    </div>
                                </div>
                            </ColPad>
                        </Row>
                        <Row>
                            <ColPad colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
                                <div className="form-col">
                                    <MapQStreetAddress
                                        errorMessage={streetErrors?.businessStreetAddress}
                                        label={`${t("checkout.businessInfo-section.labelTexts.bussinessAddress", {
                                            ns: "checkout",
                                        })} *`}
                                        onSelectionChange={handleAddressSelection}
                                        handleAddressInput={(e) => handleAddressInput(e, "street")}
                                        isAddressEmty={true}
                                        isError={!!streetErrors?.businessStreetAddress}
                                        value={state?.businessStreetAddress}
                                        setAddressFieldError={(value) => {
                                            setStreetErrors({ ...streetErrors, businessStreetAddress: value ? t("checkout.businessInfo-section.errorMsgs.addressLine1Req", {
                                                ns: "checkout",
                                            }) : ""}) ;
                                            dispatch({
                                                type: CONSTANTS.SET_SHIPPING_ERRORS,
                                                payload: { ...streetErrors, businessStreetAddress: value? t("checkout.businessInfo-section.errorMsgs.addressLine1Req", {
                                                    ns: "checkout",
                                                }) : ""
                                                },
                                                });
                                         }}
                                        helperText={t("checkout.businessInfo-section.labelTexts.addressLine1Helper", { ns: "checkout" })}
                                        //   // setAutoFocus={setAutoFocus}
                                        ref={businessStreetRef}
                                        isNameOnly
                                        addressLineOne={businessAddress || businessRawAddress || ""}
                                        isVerifyBussiness={true}
                                        isShippingInfo={true}
                                        tooltipContent={t("checkout.businessInfo-section.labelTexts.bussinessAddtoolTip", {
                                            ns: "checkout",
                                        })}
                                    />
                                </div>
                            </ColPad>
                        </Row>
                        <Row>
                            <ColPad colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
                                <div className="form-col">
                                    <Input
                                        label={t("checkout.businessInfo-section.labelTexts.unit", {
                                            ns: "checkout",
                                        })}
                                        name="unit"
                                        type="text"
                                        maxLength={20}
                                        placeholder="Enter Unit number"
                                        value={state?.companyAddressUnit}
                                        required={false}
                                        onChange={(e) => {
                                            isValidContactInfoField("unit", e?.target?.value)
                                        }}
                                        error={unitError?.unit?.length > 0}
                                        errorText={
                                            unitErrorMessage || ''
                                        }
                                        helperText={t("checkout.businessInfo-section.helperTexts.signerUnitHelper", { ns: "checkout" })}
                                    />
                                </div>
                            </ColPad>
                        </Row>
                     </Grid>
                    </AboutBusinessWrapper>
                    <MarginSpacerM />
                    <Grid
                        colSizes={{ mobile: 4, tablet: 10, desktop: 10 }}
                        colGutter={false}
                    >
                        <Row>
                            <Col>
                                {showZipCodeNotification ? <Notification
                                    type="info"
                                    title={t("checkout.businessInfo-section.messages.buss-notify-title", {
                                        ns: "checkout",
                                    })}
                                    subtitle={t("checkout.businessInfo-section.messages.buss-notify-subtitle", {
                                        ns: "checkout",
                                    })}
                                    fullBleed={"false"}
                                    inlineTreatment={false}
                                    hideCloseButton={true}
                                /> : <></>}
                            </Col>
                        </Row>

                    </Grid>
                </div>
                {!isEditMode &&
                    <>
                        <div style={{ display: "flex", justifyContent: isMobile ? "center" : "flex-start" }}>
                            <Button width={isMobile ? "90%" : "22%"} size="large" use="primary" onClick={() => {
                                hasErrors()
                            }}
                                data-track="Business Verification:Tell us about your business:Continue"
                            >
                                {"Continue"}
                            </Button>
                        </div>
                        <MarginSpacerS />
                    </>}

                {showAddressModal && (
                    <AddressVerificationModal
                        opened={showAddressModal}
                        setAddressConfirmationModal={setShowAddressModal}
                        address={
                            pageData?.postBusinessInfoResponse?.serviceStatus?.statusMessage
                        }
                        setShippingState={(data) => {
                            setBusinessAddress(data?.businessStreetAddress?.displayString)
                            localStorage.setItem("shippingState", JSON.stringify(data));
                        }}
                        handleAddressSelect={(state) => handleAddressSelection(state)}
                        aemContent={addressConfirmaionContent}
                        displayAddress={businessSaStreetAddressObj?.place?.properties}
                        businessRawAddress={businessRawAddress}
                        addressLine2={state?.companyAddressUnit ? state?.companyAddressUnit : " "}
                        showCloseButton={true}
                        fromBusinessAddress={true}
                        validateBusinessAddress={validateBusinessAddress}
                    />
                )}
            </>
        );
    };

    const getCompletedContent = () => {
        return (
            ((isOrderQuoteFlow || isDigitalQuote || isAbandonedCart) && sessionCartData?.redirectPageTo && sessionCartData?.redirectPageTo === "checkout") ?
                <div>
                    <Body size="large">{sessionCartData?.businessInfo?.bussinessName}</Body>
                    <Body size="large">{_.upperCase(sessionCartData?.businessInfo?.address?.addressLine1)}</Body>
                    <Body size="large">{_.upperCase(sessionCartData?.businessInfo?.address?.city)}{' '}
                        {sessionCartData?.businessInfo?.address?.state}{' '}
                        {sessionCartData?.businessInfo?.address?.zipCode
                        }</Body>
                    <Body size="large">{sessionCartData?.businessInfo?.phoneNumber}</Body>
                    {!isTaxIdOptional && <Body size="large"> {'#########'}</Body>}
                    <MarginSpacerM />
                </div>
                : <div>
                    <Body size="large">{pageData?.aboutBusinessInfor?.companyName}</Body>
                    <Body size="large">{_.upperCase(pageData?.aboutBusinessInfor?.businessStreetAddress?.name) || _.upperCase(pageData?.aboutBusinessInfor?.businessStreetAddress?.place?.properties?.street)}{pageData?.aboutBusinessInfor?.companyAddressUnit ? ', ' :""}{_.upperCase(pageData?.aboutBusinessInfor?.companyAddressUnit)}</Body>
                    <Body size="large">{_.upperCase(pageData?.aboutBusinessInfor?.businessStreetAddress?.place?.properties?.city)}{' '}
                        {pageData?.aboutBusinessInfor?.businessStreetAddress?.place?.properties?.state || pageData?.aboutBusinessInfor?.businessStreetAddress?.place?.properties?.stateCode}{' '}
                        {pageData?.aboutBusinessInfor?.businessStreetAddress?.place?.properties?.postalCode
                        }</Body>
                    <Body size="large">{pageData?.aboutBusinessInfor?.phoneNumber}</Body>
                    {!isTaxIdOptional && <Body size="large"> {formatTaxID()}</Body>}
                    <MarginSpacerM />
                </div>
        );
    };

    const getEditModeContent = () => {
        return (
            <>
                <>{getDefaultContent()}</>
                <div style={{ display: "flex", padding: isMobile ? "0 16px" : "0",justifyContent: isMobile ? "center" : "flex-start" }}>
                    <Button
                        width={isMobile ? "90%" : "15%"}
                        size="large"
                        use="primary"
                        onClick={() => {
                            hasErrors();
                        }}
                        data-track="Save"
                    >
                        {"Save"}
                    </Button>
                </div>
                <MarginSpacerS />
            </>
        );
    };
    const formatTaxID = () => {
        if (state?.corporation?.fedTaxId) {
            let digits = pageData?.aboutBusinessInfor?.corporation?.fedTaxId ?? state?.corporation?.fedTaxId;
            let firstTwo = digits.slice(0, 2)
            let hashdigit = digits.slice(2).replace(/\d/g, '#')
            let formated = firstTwo + hashdigit
            return formated;
        }
    }

    if (isComplete) {
        if (isEditMode) {
            return getEditModeContent();
        } else {
            
            return getCompletedContent();
        }
    } else {
        return getDefaultContent();
    }
};

const AboutBusinessWrapper = styled.div`
.form-col .helper-text p{ 
    color: #747676;
    margin-top: 8px;
}
@media screen and (max-width:767px) {
    padding: 0 16px 0 16px; 
}
`;

const StyledSpan = styled.span`
  > a {
    color: black;
  }
`;

const ColPad = styled(Col)`
  margin-top: 1rem !important;
`;

export default AboutBusiness;
