import {
	put,
	takeLatest,
	select,
	call,
	fork,
	join,
	delay,
} from "redux-saga/effects";
import * as API from "../../../containers/APIurls";
import apiClient from "utils/apiClient";
import apiClientForAddress from "utils/apiClientForAddress";
import * as GlobalSlice from "../../../app/ducks/GlobalSlice";
import * as AEMContentSlice from "./AEMContentSlice";
import * as CheckoutSlice from "./CheckoutSlice";
//import * as CreditSlice from '../components/CreditResultsNew/CreditSlice'
import { resetCreditSlice } from "../CreditResult/CreditResultsNew/CreditSlice";
import * as PaymentSlice from "../Payment/ducks/PaymentSlice";
import * as ReviewSlice from "../ReviewOrder/ducks/ReviewSlice";
import i18n from "i18next";
import Utils from "./utils";
import * as selectors from "./selectors";
import Platform from "react-platform-js";
import cloneDeep from "lodash/cloneDeep";
import BillingInformation from './BillingInformation.json'

function* getSessionCartInfo(payload) {
	const pageData = yield select(selectors.pageData);
	yield put(
		CheckoutSlice.setIsShoppingCartApiCallInProgress({
			apiName: "getSessionCart",
			status: true,
		})
	);
	yield put(
		CheckoutSlice.setGlobalErrorMessage({ showError: false, message: "" })
	);
	try {
		const checkoutData = yield select(
			(state) => state.ProspectWidgets_CheckoutData
		);
		let currentSectionName;
		if (checkoutData) {
			if (
				checkoutData?.activeAccordian ===
				checkoutData?.activeAccordionDisplayOrder?.creditResult
			) {
				currentSectionName = "credit-result";
			} else if (
				checkoutData?.activeAccordian ===
				checkoutData?.activeAccordionDisplayOrder?.reviewOrder
			) {
				currentSectionName = "review";
			} else if (
				checkoutData?.activeAccordian ===
				checkoutData?.activeAccordionDisplayOrder?.maa
			) {
				currentSectionName = "docusign";
			} else if (
				checkoutData?.activeAccordian ===
				checkoutData?.activeAccordionDisplayOrder?.shipping
			) {
				currentSectionName = "shipping";
			}
		}
		// const { flow } = params.payload;
		const requestBody = {
			cartMeta: {
				sourcePath: "CART",
			},
			shoppingPath: "PROSPECT",
			approach: payload?.payload?.approach || "",
			flow: payload?.flow || pageData?.unifiedCartParams?.flow,
			currentSectionName: currentSectionName,
		};

		const response = yield apiClient.post(
			API.SESSION_CART_PROSPECT_5G_URL,
			requestBody
		);

		if (response?.data) {
			if (currentSectionName) {
				const payload = {
					shoppingPath: "PROSPECT",
					fromUCSubmitOrder: false,
				}; //yield fork(GlobalSlice.postSaveCart(), payload?.payload?.savePayload);
				const response1 = yield apiClient.post(API.CARTCOMP_CART_SAVE_URL, payload);
			}
			const newStore = response?.data?.prospectFlowData
				? Utils.setStoreFromRetrieve(response.data)
				: {};
			yield call(getBillingInformation, response?.data?.prospectFlowData)
			yield put(GlobalSlice.setSessionCartData(newStore));
			window?.localStorage?.setItem("_cartData", JSON.stringify(newStore));

			if (
				window.location.href.indexOf("review") > -1 &&
				window["digitalData"]?.isUniqROS
			) {
				// SiteAnalytics.eventShoppingCartInfoTracking('Unified Review Order Page Viewed', response?.data?.prospectFlowData, 'review-page', 'cart_switch')
			}
			if (response?.data?.prospectFlowData?.ecpdLookupCartData) {
				window?.sessionStorage?.setItem(
					"ecpdId",
					response?.data?.prospectFlowData?.ecpdLookupCartData?.ecpdLookupCartData[0]
						?.ecpdProfileId
				);
				window["digitalData"].visitorProfile["ecpdid"] =
					response?.data?.prospectFlowData?.ecpdLookupCartData?.ecpdLookupCartData[0]?.ecpdProfileId;
			}
			yield put(
				CheckoutSlice.setSameAsCompanyAddressNode({
					key: "phoneNumber",
					value: newStore["businessInfo"]["signerInfo"]["address"]["phoneNumber"],
				})
			);
			yield put(
				CheckoutSlice.setSameAsCompanyAddressNode({
					key: "email",
					value: newStore["businessInfo"]["signerInfo"]["address"]["email"],
				})
			);
			yield put(
				CheckoutSlice.setSameAsCompanyAddressNode({
					key: "lastName",
					value: response?.data?.prospectFlowData?.businessInfo?.lastName,
				})
			);
			yield put(
				CheckoutSlice.setSameAsCompanyAddressNode({
					key: "firstName",
					value: response?.data?.prospectFlowData?.businessInfo?.firstName,
				})
			);
			yield put(
				CheckoutSlice.setSameAsCompanyAddressNode({
					key: "email",
					value: response?.data?.prospectFlowData?.businessInfo?.email,
				})
			);
		} else
			throw Object.assign(new Error("Failed to fetch sessionCart"), {
				status: response?.status,
				data: response?.data,
			});
	} catch (err) {
		yield put(
			CheckoutSlice.setGlobalErrorMessage({ showError: true, message: "" })
		);
	} finally {
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "getSessionCart",
				status: false,
			})
		);
	}
}

function* saveCreditHoldCart(params) {
	const creditData = yield select(selectors.creditData);
	yield put(CheckoutSlice.setSaveCreditHoldCartSuccess({ status: false }));
	const creditHoldData = creditData?.creditHold || {};
	if (creditHoldData?.code) {
		let codes = creditHoldData?.code?.split("-");
		const requestBody = {
			creditApprovalStatus: codes[0],
			creditStatusReason: codes[1],
		};
		if (params?.payload?.sendEmail) {
			requestBody.sendEmail = params?.payload?.sendEmail;
		}
		try {
			const response = yield apiClient.post(
				API.SAVE_CREDIT_HOLD_CART_URL,
				requestBody
			);
			if (response?.data?.serviceStatus?.success) {
				yield put(CheckoutSlice.setSaveCreditHoldCartSuccess({ status: true }));
			} else {
				yield put(CheckoutSlice.setSaveCreditHoldCartSuccess({ status: false }));
			}
		} catch (err) {
			yield put(CheckoutSlice.setSaveCreditHoldCartSuccess({ status: false }));
		}
	}
}
function* getRetrieveCreditCart(params) {
	yield put(CheckoutSlice.setRetrieveCreditCartSuccess({ status: false }));
	const data = params?.payload;
	const requestBody = {
		cartId: data?.cartId,
		cartMeta: { sourcePath: "CART" },
		shoppingPath: "PROSPECT",
		approach: "",
		flow: data?.flow,
	};
	try {
		const response = yield apiClient.post(
			API.RETREIVE_CREDIT_HOLD_URL,
			requestBody
		);
		let sessionCartResponse = {
			prospectFlowData: response?.data?.response,
			serviceStatus: response?.data?.status,
		};
		let packageTypes = [];
		if (response?.data?.status?.success && response?.data?.response) {
			const newStore = Utils.setStoreFromRetrieve(sessionCartResponse);
			yield put(GlobalSlice.setSessionCartData(newStore));
			yield put(CheckoutSlice.setRetrieveCreditCartSuccess({ status: true }));
			if (response.data.response?.cart?.packages) {
				for (const key of Object.keys(response.data.response.cart.packages)) {
					packageTypes.push(response.data.response.cart.packages[key].packageType);
				}
			}
			window?.localStorage?.setItem("_cartData", JSON.stringify(newStore));
			window.sessionStorage.setItem("packageTypes", packageTypes);
			if (response.data.response?.cart?.unifiedCart) {
				window?.sessionStorage?.setItem("unified-cart", "yes");
			} else {
				window?.sessionStorage?.setItem("unified-cart", "no");
			}
		} else
			yield put(CheckoutSlice.setRetrieveCreditCartSuccess({ status: false }));
	} catch (err) {
		yield put(CheckoutSlice.setRetrieveCreditCartSuccess({ status: false }));
	}
}

function* getStreetAddressInfo(params) {
	const getMapQuestAddress = async function () {
		const response = await apiClient.get(
			API.GET_MAPQUEST_ADDRESS_URL + streetAddr
		);
		return response?.data;
	};

	const getLocusAddressInfo = async function () {
		const LOCUSAPI_KEY = process.env.PROSPECT_WIDGETS_APP_ATOM_APIKEY;
		const config = {
			headers: {
				apikey: LOCUSAPI_KEY,
			},
		};
		const requestBody = {
			streetAddress: streetAddr,
			QueryType: "typeahead",
			GUID: "12345",
			ClientID: "Optix",
		};
		const response = await apiClientForAddress.get(
			API.GET_LOCUS_ADDRESS_URL + "streetterm=" + streetAddr,
			config
		);
		return response?.data;
	};

	const getMapBoxAddress = async function () {
		const response = await apiClientForAddress.get(
			API.GET_MAPBOX_ADDRESS_URL +
			"&query=" +
			streetAddr +
			"&country=USA&language=en"
		);
		return response?.data;
	};

	const processAPI = async function () {
		try {
			let mapquestData = undefined;
			let locusData = undefined;
			let mapboxData = undefined;
			let searchResult = [];
			const addressSearchConfig =
				i18n?.store?.data["en-US"]?.app?.app?.addressSearch;
			if (addressSearchConfig?.enableMapQuest) {
				mapquestData = await getMapQuestAddress();
				searchResult = mapquestData?.results || [];
			}
			if (
				addressSearchConfig?.enableLocus &&
				(!addressSearchConfig?.enableMapQuest || searchResult?.length === 0)
			) {
				mapboxData = undefined;
				locusData = await getLocusAddressInfo();
				searchResult =
					locusData?.addresses?.map((obj) => ({
						place: {
							properties: {
								country: "",
								countryCode: "",
								state: obj.state,
								stateCode: obj.state,
								postalCode: obj.zip,
								city: obj.muni,
								street: obj.dispstr,
								propertyType: obj?.propertyType,
								locusID:obj?.locusID,
								ntasAddrID: obj.ntasAddrID,
							},
						},
						displayString: obj.disprawstr,
						name: obj.dispstr,
					})) || [];
			}
			if (
				addressSearchConfig?.enableMapBox &&
				(!addressSearchConfig?.enableLocus || searchResult?.length === 0)
			) {
				mapquestData = undefined;
				locusData = undefined;
				mapboxData = await getMapBoxAddress();
				searchResult =
					mapboxData?.suggestions?.map((obj) => {
						const displayText =
							(obj.address.houseNumber
								? obj.address.houseNumber + " " + obj.address.street
								: obj.address.street) +
							", " +
							obj.address.city +
							", " +
							obj.address.state +
							", " +
							obj.address?.postalCode +
							", " +
							obj.countryCode;
						const streetName = obj.address.houseNumber
							? obj.address.houseNumber + " " + obj.address.street
							: obj.address.street;
						return {
							place: {
								properties: {
									country: obj.address.country,
									countryCode: "US",
									state: obj.address.state,
									stateCode: obj.address.state,
									street: streetName,
									city: obj.address.city,
									postalCode: obj.address.postalCode,
								},
							},
							displayString: displayText,
							name: streetName,
						};
					}) || [];
			}
			return searchResult;
		} catch {
			return [];
		}
	};

	const streetAddr = params.payload;
	if (!streetAddr || streetAddr?.length === 0) {
		yield put(
			CheckoutSlice.setAddressSuggestionList(retriveCartResponse.response)
		);
		return;
	}
	let response = yield processAPI();
	yield put(CheckoutSlice.setAddressSuggestionList(response));
}

function* postBusinessInformation(params) {
	yield put(
		CheckoutSlice.setIsShoppingCartApiCallInProgress({
			apiName: "postBusinessInformation",
			status: true,
		})
	);
	const pageData = yield select(selectors.pageData);
	const data = params?.payload;
	const federalTaxID = (
		data?.fedTaxId ||
		data?.corporation?.fedTaxId ||
		""
	)?.replaceAll("-", "");
	const unformatedSSN = (
		data?.SSN ||
		data?.ssn ||
		data?.corporation?.SSN ||
		""
	).replaceAll("-", "");
	const ecpdLookupData =
		window.PIE && window.ProtectString
			? {
				pieTaxId: window.ProtectString(federalTaxID)?.[0],
				keyId: window.PIE.key_id,
				phase: window.PIE.phase,
			}
			: {};
	const requestBody = {
		companyInfo: {
			name: data?.companyName,
			partialTaxId: federalTaxID?.substring(5, 9),
			federalTaxId: Utils.pieProtectSPI(federalTaxID),
			address: {
				addressLine1: data?.businessStreetAddress?.place?.properties?.street,
				addressLine2: data?.businessStreetAddress2 || "",
				city: data?.businessStreetAddress?.place?.properties?.city,
				propertyType:
					data?.businessStreetAddress?.place?.properties?.propertyType ?? "",
				state: data?.businessStreetAddress?.place?.properties?.stateCode,
				zipCode:
					data?.businessStreetAddress?.place?.properties?.postalCode?.substring(
						0,
						5
					) ?? "",
			},
			...ecpdLookupData,
		},
		signerInfo: {
			firstname: data?.contactInfo?.firstName,
			lastName: data?.contactInfo?.lastName,
			title: data?.contactInfo?.jobTitle ?? "Prospect",
			dob: data?.contactInfo?.DateOfBirth?.replaceAll("-", "/"),
			address: {
				addressLine1: data?.homeAdressInfo?.place?.properties?.street,
				homeAddress2: data?.homeAdressInfo2 || "",
				city: data?.homeAdressInfo?.place?.properties?.city,
				propertyType: data?.homeAdressInfo?.place?.properties?.propertyType ?? "",
				state: data?.homeAdressInfo?.place?.properties?.stateCode,
				zipCode:
					data?.homeAdressInfo?.place?.properties?.postalCode?.substring(0, 5) ?? "",
				emailAddress: data?.contactInfo?.email,
				phoneNumber: data?.phoneNumber?.replaceAll("\\.", "")?.replaceAll(".", ""),
			},
			ssn: Utils.pieProtectSPI(unformatedSSN),
		},
		lineE911AddressList: {},
		lineE911AddressType: {},
		shoppingPath: "PROSPECT",
		termsNconditions: true,
		approach: "",
		flow: data?.flow?.flow ?? data?.flow,
		safeTechData: {
			sfSessionId: data?.safetechSessionId,
			//  JSON.parse(window.localStorage.getItem('_cartData'))?.safetech?.sessionId
		},
		ditBlackBox: {
			value: data?.blackBox?.bb,
		},
	};
	requestBody.prospectCreditPath = data?.prospectCreditPath;
	if (data.isSameAddress) {
		requestBody.signerInfo.address = {
			addressLine1: data?.businessStreetAddress?.place?.properties?.street,
			homeAddress2: data?.businessStreetAddress2 || "",
			city: data?.businessStreetAddress?.place?.properties?.city,
			propertyType:
				data?.businessStreetAddress?.place?.properties?.propertyType ?? "",
			state: data?.businessStreetAddress?.place?.properties?.stateCode,
			zipCode:
				data?.businessStreetAddress?.place?.properties?.postalCode?.substring(
					0,
					5
				) ?? "",
			emailAddress: data?.contactInfo?.email,
			phoneNumber: data?.phoneNumber?.replaceAll("\\.", "")?.replaceAll(".", ""),
		};
	}
	try {
		const response = yield apiClient.post(
			API.BUSINESS_INFORMATION_URL,
			requestBody
		);
		if (response?.data?.serviceStatus?.success) {
			yield put(CheckoutSlice.setPostBusinessInfoResponse(response?.data));
			yield fork(postValidateBusinessInfo, data);
			yield fork(getSessionCartInfo, requestBody.flow);
		} else if (
			!response?.data?.serviceStatus?.success &&
			response?.data?.serviceStatus?.statusMessage !== "200"
		) {
			yield put(
				CheckoutSlice.setIsShoppingCartApiCallInProgress({
					apiName: "postBusinessInformation",
					status: false,
				})
			);
			if (
				response?.data?.serviceStatus?.statusCode?.includes(
					"SUSPENDED_ECPDLOOKUP_EXISTING_USER"
				)
			) {
				yield put(CheckoutSlice.setExisitingCustomer(true));
				// Not to show modal only to show inline msg
				// yield put(
				// 	CheckoutSlice.setSuspendScenario(response?.data?.serviceStatus?.statusCode)
				// );
				// yield put(CheckoutSlice.setSuspendFlow(true));
			}
			yield put(CheckoutSlice.setPostBusinessInfoResponse(response?.data));
		} else {
			yield put(
				CheckoutSlice.setIsShoppingCartApiCallInProgress({
					apiName: "postBusinessInformation",
					status: false,
				})
			);
			yield put(
				CheckoutSlice.setSuspendScenario(response?.data?.serviceStatus?.statusCode)
			);
			if (
				response?.data?.serviceStatus?.statusCode?.includes(
					"SUSPENDED_ECPDLOOKUP_EXISTING_USER"
				)
			) {
				yield put(CheckoutSlice.setExisitingCustomer(true));
				// Not to show modal only to show inline msg
				// yield put(
				// 	CheckoutSlice.setSuspendScenario(response?.data?.serviceStatus?.statusCode)
				// );
				// yield put(CheckoutSlice.setSuspendFlow(true));
			} else {
				yield put(CheckoutSlice.setSuspendFlow(true));
			}
		}
	} catch (err) {
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "postBusinessInformation",
				status: false,
			})
		);
		yield put(CheckoutSlice.setSuspendFlow(true));
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "postBusinessInformation",
				status: false,
			})
		);
	}
}

function* saveBusinessInformation(params) {
	const pageData = yield select(selectors.pageData);
	const data = params?.payload;
	const federalTaxID = (
		data?.fedTaxId ||
		data?.corporation?.fedTaxId ||
		""
	)?.replaceAll("-", "");
	const unformatedSSN = (
		data?.SSN ||
		data?.ssn ||
		data?.corporation?.SSN ||
		""
	).replaceAll("-", "");
	const ecpdLookupData =
		window.PIE && window.ProtectString
			? {
				pieTaxId: window.ProtectString(federalTaxID)?.[0],
				keyId: window.PIE.key_id,
				phase: window.PIE.phase,
			}
			: {};
	const requestBody = {
		companyInfo: {
			name: data?.companyName,
			partialTaxId: federalTaxID?.substring(5, 9),
			federalTaxId: Utils.pieProtectSPI(federalTaxID),
			address: {
				addressLine1: data?.businessStreetAddress?.place?.properties?.street,
				addressLine2: data?.businessStreetAddress2 || "",
				city: data?.businessStreetAddress?.place?.properties?.city,
				propertyType:
					data?.businessStreetAddress?.place?.properties?.propertyType ?? "",
				state: data?.businessStreetAddress?.place?.properties?.stateCode,
				zipCode:
					data?.businessStreetAddress?.place?.properties?.postalCode?.substring(
						0,
						5
					) ?? "",
			},
			...ecpdLookupData,
		},
		signerInfo: {
			firstname: data?.contactInfo?.firstName,
			lastName: data?.contactInfo?.lastName,
			title: data?.contactInfo?.jobTitle ?? "Prospect",
			dob: data?.contactInfo?.DateOfBirth?.replaceAll("-", "/"),
			address: {
				addressLine1: data?.homeAdressInfo?.place?.properties?.street,
				homeAddress2: data?.homeAdressInfo2 || "",
				city: data?.homeAdressInfo?.place?.properties?.city,
				propertyType: data?.homeAdressInfo?.place?.properties?.propertyType ?? "",
				state: data?.homeAdressInfo?.place?.properties?.stateCode,
				zipCode:
					data?.homeAdressInfo?.place?.properties?.postalCode?.substring(0, 5) ?? "",
				emailAddress: data?.contactInfo?.email,
				phoneNumber: data?.phoneNumber?.replaceAll("\\.", "")?.replaceAll(".", ""),
			},
			ssn: Utils.pieProtectSPI(unformatedSSN),
		},
		lineE911AddressList: {},
		lineE911AddressType: {},
		shoppingPath: "PROSPECT",
		termsNconditions: true,
		approach: "",
		flow: data?.flow?.flow ?? data?.flow,
		safeTechData: {
			sfSessionId: data?.safetechSessionId,
			//  JSON.parse(window.localStorage.getItem('_cartData'))?.safetech?.sessionId
		},
		ditBlackBox: {
			value: data?.blackBox?.bb,
		},
	};
	requestBody.prospectCreditPath = data?.prospectCreditPath;
	if (data.isSameAddress) {
		requestBody.signerInfo.address = {
			addressLine1: data?.businessStreetAddress?.place?.properties?.street,
			homeAddress2: data?.businessStreetAddress2 || "",
			city: data?.businessStreetAddress?.place?.properties?.city,
			propertyType:
				data?.businessStreetAddress?.place?.properties?.propertyType ?? "",
			state: data?.businessStreetAddress?.place?.properties?.stateCode,
			zipCode:
				data?.businessStreetAddress?.place?.properties?.postalCode?.substring(
					0,
					5
				) ?? "",
			emailAddress: data?.contactInfo?.email,
			phoneNumber: data?.phoneNumber?.replaceAll("\\.", "")?.replaceAll(".", ""),
		};
	}
	try {
		const response = yield apiClient.post(
			API.SAVE_BUSINESS_INFORMATION_URL,
			requestBody
		);
		yield put(CheckoutSlice.setSaveBusinessInfoResponse(response?.data));
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "postBusinessInformation",
				status: false,
			})
		);
	} catch (err) {
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "postBusinessInformation",
				status: false,
			})
		);
	}
}

function* postValidateBusinessInfo(params) {
	yield put(
		CheckoutSlice.setIsShoppingCartApiCallInProgress({
			apiName: "postBusinessInformation",
			status: true,
		})
	);
	const data = params;
	const federalTaxID = (
		data?.fedTaxId ||
		data?.corporation?.fedTaxId ||
		""
	)?.replaceAll("-", "");
	const ecpdLookupData =
		window.PIE && window.ProtectString
			? {
				pieTaxId: window.ProtectString(federalTaxID)?.[0],
				keyId: window.PIE.key_id,
				phase: window.PIE.phase,
			}
			: {};
	const requestBody = {
		companyInfo: {
			name: data?.companyName,
			partialTaxId: federalTaxID?.substring(5, 9),
			federalTaxId: Utils.pieProtectSPI(federalTaxID),
			address: {
				addressLine1: data?.businessStreetAddress?.place?.properties?.street,
				addressLine2: "",
				city: data?.businessStreetAddress?.place?.properties?.city,
				propertyType:
					data?.businessStreetAddress?.place?.properties?.propertyType ?? "",
				state: data?.businessStreetAddress?.place?.properties?.stateCode,
				zipCode:
					data?.businessStreetAddress?.place?.properties?.postalCode?.substring(
						0,
						5
					) ?? "",
			},
			...ecpdLookupData,
		},
		signerInfo: {
			firstname: data?.contactInfo?.firstName,
			lastName: data?.contactInfo?.lastName,
			title: data?.contactInfo?.jobTitle ?? "Prospect",
			dob: data?.DateOfBirth?.replaceAll("-", "/"),
			address: {
				addressLine1: data?.homeAdressInfo?.place?.properties?.street,
				homeAddress2: "",
				city: data?.homeAdressInfo?.place?.properties?.city,
				propertyType: data?.homeAdressInfo?.place?.properties?.propertyType ?? "",
				state: data?.homeAdressInfo?.place?.properties?.stateCode,
				zipCode:
					data?.homeAdressInfo?.place?.properties?.postalCode?.substring(0, 5) ?? "",
				emailAddress: data?.contactInfo?.email,
				phoneNumber: data?.phoneNumber?.replaceAll("\\.", "")?.replaceAll(".", ""),
			},
		},
		shoppingPath: "PROSPECT",
		termsNconditions: true,
		approach: "",
		flow: data?.flow?.flow ?? data?.flow,
		prospectCreditPath: data?.prospectCreditPath,
	};
	try {
		const response = yield apiClient.post(
			API.VALIDATE_BUSINESS_INFO_URL,
			requestBody
		);
		yield fork(getSessionCartInfo, requestBody.flow);
		yield put(CheckoutSlice.setValidateBusinessInfoResponse(response?.data));
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "postBusinessInformation",
				status: false,
			})
		);
	} catch (err) {
		yield put(CheckoutSlice.setSuspendFlow(true));
	} finally {
		yield put(resetCreditSlice());
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "postBusinessInformation",
				status: false,
			})
		);
	}
}

function* getShippingMethods(params) {
	yield put(
	  CheckoutSlice.setIsShoppingCartApiCallInProgress({
		apiName: "getShippingMethods",
		status: true,
	  })
	);
	try {
	  let requestBody = {
		groundShipping: false,
		thirdPartyOwnedDevice: true,
		shoppingPath: "PROSPECT",
		approach: "",
		flow: "prospect",
		addressCountMap: params?.payload?.addressCountMap || {},
		multishipping: params?.payload?.multiShipping
	  };
  
	  //localhost
	  // const shippingMethodResponse = yield call(() => Promise.resolve(shippingMethodAPI));
	  // yield put(CheckoutSlice.setShippingMethodInfo(shippingMethodResponse?.response));
  
	  //qa1 code
	  const response = params?.payload?.useMock ? ShippingMethod : yield apiClient.post(
		API.SHIPPING_METHODS_INFO_URL,
		requestBody
	  );
	  yield put(CheckoutSlice.setShippingMethodInfo(response.data));
	  yield put(
		CheckoutSlice.setIsShoppingCartApiCallInProgress({
		  apiName: "getShippingMethods",
		  status: false,
		})
	  );
	} catch (err) {
	  yield put(
		CheckoutSlice.setIsShoppingCartApiCallInProgress({
		  apiName: "getShippingMethods",
		  status: false,
		})
	  );
	}
  }
  
function* validateCreditDetails(flow) {
	yield put(
		CheckoutSlice.setIsShoppingCartApiCallInProgress({
			apiName: "validateCreditDetails",
			status: true,
		})
	);
	//yield put(CreditSlice.setIsLoadingCheckCreditOptions(true));
	try {
		let requestBody = {
			shoppingPath: "PROSPECT",
			approach: "",
			flow: "prospect",
		};

		const response = yield apiClient.post(
			API.VALIDATE_CREDIT_DETAILS_URL,
			requestBody
		);
		yield put(CheckoutSlice.setvalidateCreditDetails(response));

		// if (response?.data?.serviceStatus?.message === "SUCCESS") {
		//   yield put(validateBusinessCreditDetails(flow));
		// }

		// yield put(CheckoutSlice.setIsShoppingCartApiCallInProgress({ apiName: "validateCreditDetails", status: false }));
	} catch (err) {
		//yield put(CreditSlice.setIsLoadingCheckCreditOptions(false));
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "validateCreditDetails",
				status: false,
			})
		);
	} finally {
		// yield put(CreditSlice.setIsLoadingCheckCreditOptions(false));
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "validateCreditDetails",
				status: false,
			})
		);
	}
}

function* postShippingMethod(params) {
	const pageData = yield select(selectors.pageData);
	const flow = pageData?.unifiedCartParams?.flow;
	yield put(
		CheckoutSlice.setIsShoppingCartApiCallInProgress({
			apiName: "getShippingMethods",
			status: true,
		})
	);
	try {
		let data = params.payload;
		let requestBody;
		if (data.shippingCode == "SDD_SAMEDAY") {
			requestBody = {
				shippingCode: data.shippingCode,
				shippingCost: data.shippingCost,
				shippingDesc: data.shippingDescription,
				shippingPriority: data.shipper,
				serviceType: data.shippingType,
				selectedSlot: {
					delivKey: data.delivKey,
					startTime: data.startTime,
					endTime: data.endTime,
					deliverySlot: data.deliverySlot,
					userTimeZone: data.userTimeZone,
					depletionCode: data.depletionCode,
				},
				shoppingPath: "PROSPECT",
				approach: "",
				flow: flow,
			};
		} else if (data.shippingCode == "ISPU") {
			requestBody = {
				shippingCode: data.shippingCode,
				shippingCost: data.shippingCost,
				shippingDesc: data.shippingDescription,
				shippingPriority: data.shipper,
				serviceType: data.shippingType,
				shoppingPath: "PROSPECT",
				ispuData: {
					storeName: data.store_NAME,
					distance: data.distance,
					address1: data.address1,
					city: data.city,
					zip: data.zip,
					phoneNumber: data.phone_NUMBER,
					state: data.state,
					storeStatus: data.storeStatus,
					storeHours: data.mon_OPEN + "-" + data.mon_CLOSE,
					ispuLocationCode: data.nettaceLocation,
				},
				approach: "",
				flow: flow,
			};
		} else if (data.is4G) {
			requestBody = data.requestPayload;
		} else {
			requestBody = {
				shippingCode: data.shippingCode,
				shippingCost: data.shippingCost,
				shippingDesc: data.shippingDescription,
				shippingPriority: data.shipper,
				serviceType: data.shippingType,
				selectedSlot: {},
				shoppingPath: "PROSPECT",
				approach: "",
				flow: flow,
			};
		}
		//localhost
		// const shippingMethodResponse = yield call(() => Promise.resolve(postShippingAPI));
		// yield put(CheckoutSlice.setShippingMethodInfo(shippingMethodResponse?.response));

		//qa1 code
		let url = API.SEND_SHIPPING_METHOD_INFO_URL;
		if (data.is4G) {
			url = API.SEND_MULTI_SHIPPING_METHOD_INFO_URL;
		}
		const response = yield apiClient.post(url, requestBody);
		yield put(CheckoutSlice.setShippingResponse(response.data));
		if (response.data.status.success) {
			yield* getSessionCartInfo();
		} else {
			yield put(
				CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_ORDER_VALIDATION")
			);
			yield put(CheckoutSlice.setSuspendFlow(true));
		}
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "getShippingMethods",
				status: false,
			})
		);
	} catch (err) {
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "getShippingMethods",
				status: false,
			})
		);
	}
}

function* getStoreData(params) {
	yield put(
		CheckoutSlice.setIsShoppingCartApiCallInProgress({
			apiName: "getStoreDetailsByLocation",
			status: true,
		})
	);
	try {
		let data = params.payload;
		let requestBody = {
			zipCode: data,
			storeType: "direct",
			shoppingPath: "PROSPECT",
			approach: "",
			flow: "prospect",
		};

		// localhost
		// const getStoreResponse = yield call(() => Promise.resolve(storeDataAPI));
		// yield put(CheckoutSlice.setStoreInfo(getStoreResponse));

		//qa1 code
		const response = yield apiClient.post(API.STORE_INFORMATION_URL, requestBody);
		yield put(CheckoutSlice.setStoreInfo(response.data));
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "getStoreDetailsByLocation",
				status: false,
			})
		);
	} catch (err) {
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "getStoreDetailsByLocation",
				status: false,
			})
		);
	}
}

function* getMAADocUrl(params) {
	try {
		yield put(CheckoutSlice.setDocusignLoading(true));
		const requestBody = {
			retryCount: params?.retryCount ?? 0,
			shoppingPath: "PROSPECT",
			approach: "",
			flow: "5g",
		};

		//localhost
		//const maaDocResponse = yield call(() => Promise.resolve(maaDocUrlAPI));
		//yield put(CheckoutSlice.setMAADocUrlMethodInfo(maaDocResponse));

		// live API call
		const resp = yield apiClient.post(API.DOCUSIGN_INFO_URL, requestBody);
		// end live API

		if (resp?.status === 200) {
			if (
				resp?.data?.serviceStatus?.success &&
				resp?.data?.serviceStatus?.statusCode === "RETRY_MMA_DOC"
			) {
				const waitSeconds = resp?.data?.retryInterval ?? 3;
				yield delay(waitSeconds * 1000);
				yield fork(getMAADocUrl, { retryCount: resp?.data?.retryCount });
			} else if (resp?.data?.serviceStatus?.success) {
				yield put(CheckoutSlice.setDocusignURL(resp?.data));
				yield put(CheckoutSlice.setDocusignLoading(false));
			} else {
				yield put(
					CheckoutSlice.setSuspendScenario(resp?.data?.serviceStatus?.statusCode)
				);
				yield put(CheckoutSlice.setSuspendFlow(true));
				yield put(CheckoutSlice.setDocusignLoading(false));
			}
		} else {
			yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_MAA"));
			yield put(CheckoutSlice.setSuspendFlow(true));
			yield put(CheckoutSlice.setDocusignLoading(false));
		}
	} catch (err) {
		yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_MAA"));
		yield put(CheckoutSlice.setSuspendFlow(true));
		yield put(CheckoutSlice.setDocusignLoading(false));
	}
}

function* getInstallation() {
	yield put(
		CheckoutSlice.setIsShoppingCartApiCallInProgress({
			apiName: "installApptAvailability",
			status: true,
		})
	);
	try {
		let requestBody = {
			shoppingPath: "PROSPECT",
			approach: "",
			flow: "5g",
		};
		//localhost
		//const installationResponse = yield call(() => Promise.resolve(installationInfoAPI));
		//yield put(CheckoutSlice.setInstallationInfo(installationResponse?.response));

		const response = yield apiClient.post(API.INSTALLATION_INFO_URL, requestBody);
		yield put(CheckoutSlice.setInstallationInfo(response.data));
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "installApptAvailability",
				status: false,
			})
		);
	} catch (err) {
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "installApptAvailability",
				status: false,
			})
		);
	}
}

function* postInstallation(params) {
	yield put(
		CheckoutSlice.setIsShoppingCartApiCallInProgress({
			apiName: "installationInfo",
			status: true,
		})
	);
	try {
		let data = params.payload;
		let requestBody;
		requestBody = {
			slotLength: data.slotLength,
			availableDay: data.availableDay,
			availableType: data.availableType,
			serviceProviderAccount: data.serviceProviderAccount,
			availableDate: data.availableDate?.replace(
				/(\d\d)\/(\d\d)\/(\d{4})/,
				"$3$1$2"
			),
			serviceProviderName: data.serviceProviderName,
			slotStartTime: data.slotStartTime,
			firstname: data.firstname,
			lastname: data.lastname,
			email: data.email,
			phone: data.phone?.replaceAll("\\.", "")?.replaceAll(".", ""),
			requestControlID: data.requestControlID,
			preferredContactMethod: data.preferredContactMethod,
			shoppingPath: "PROSPECT",
			approach: "",
			flow: "prospect",
		};
		const response = yield apiClient.post(API.POST_INSTALLATION_URL, requestBody);
		yield put(
			CheckoutSlice.setInstallationInfoResp({
				status: response?.status,
				data: response?.data,
			})
		);
	} catch (err) {
		console.error(err);
	} finally {
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "installationInfo",
				status: false,
			})
		);
	}
}

function* postOtpPhoneNumber(params) {
	try {
		yield put(CheckoutSlice.setOtpLoading(true));
		const {
			flow,
			firstName,
			lastName,
			address,
			phoneNumber,
			dob,
			functionInd,
			retryCount,
		} = params.payload;
		const requestBody = {
			address: address,
			firstName: firstName,
			lastName: lastName,
			phoneNumber: phoneNumber,
			approach: "",
			flow: flow || "PROSPECT",
			shoppingPath: "PROSPECT",
			retryCount: retryCount,
			dob: dob,
			functionInd: functionInd,
		};

		// localhost mock
		// const resp = (phoneNumber === "9176X84XX8" || phoneNumber === "9726X36XX0") ?
		//   yield call(() => Promise.resolve(OtpPhoneNumberMatch))
		//   : yield call(() => Promise.resolve(OtpPhoneNumberNoMatch));
		// const phoneNumberResponse = { data: resp };
		// const resp = yield call(() => Promise.resolve(OtpPhoneNumberRetry));
		// const phoneNumberResponse = { data: resp, status: 200 };
		// end localhost

		// live API call
		const phoneNumberResponse = yield apiClient.post(
			API.OTP_PHONE_NUMBER_URL,
			requestBody
		);
		// end live API

		if (phoneNumberResponse?.status === 200) {
			const data = phoneNumberResponse?.data;
			if (data?.serviceStatus?.success) {
				yield put(
					CheckoutSlice.setOtpPhoneNumber({
						status: phoneNumberResponse.status,
						data: data,
					})
				);
			} else {
				if (data?.serviceStatus?.statusCode === "RETRY_PHONE_NUMBER") {
					const waitSeconds = data?.interval ?? 3;
					yield delay(waitSeconds * 1000);
					let updatedParams = cloneDeep(params);
					updatedParams.payload.retryCount = retryCount + 1;
					yield fork(postOtpPhoneNumber, updatedParams);
				}
				if (data?.serviceStatus?.statusCode === "ERROR_BUSINESS_INFO") {
					yield put(
						CheckoutSlice.setOtpPhoneNumber({
							status: phoneNumberResponse.status,
							data: data,
						})
					);
				} else {
					yield put(
						CheckoutSlice.setSuspendScenario(data?.serviceStatus?.statusCode)
					);
					yield put(CheckoutSlice.setSuspendFlow(true));
					// yield put(CheckoutSlice.setOtpPhoneNumber({ status: phoneNumberResponse.status, data: data }));
				}
			}
		} else {
			throw Object.assign(new Error("OTP phoneNumber failed"), {
				status: phoneNumberResponse.status,
				data: phoneNumberResponse.data,
			});
		}
	} catch (err) {
		yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_OTP"));
		yield put(CheckoutSlice.setSuspendFlow(true));
	} finally {
		yield put(CheckoutSlice.setOtpLoading(false));
	}
}

function* postOtpSendCode(params) {
	try {
		yield put(CheckoutSlice.setOtpLoading(true));
		const { phoneNumber, flow } = params.payload;
		const requestBody = {
			approach: "",
			flow: flow,
			phoneNumber: phoneNumber,
			shoppingPath: "PROSPECT",
		};

		// localhost mock
		// const resp = yield call(() => Promise.resolve(OtpSendCode));
		// const sendCodeResponse = { data: resp };
		// end localhost

		// live API call
		const sendCodeResponse = yield apiClient.post(
			API.OTP_SEND_CODE_URL,
			requestBody
		);
		// end live API

		if (sendCodeResponse?.status === 200) {
			const data = sendCodeResponse?.data;
			if (data?.serviceStatus?.success) {
				yield put(
					CheckoutSlice.setOtpSendCode({
						status: sendCodeResponse.status,
						data: data,
					})
				);
			} else {
				yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_OTP"));
				yield put(CheckoutSlice.setSuspendFlow(true));
			}
		} else {
			yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_OTP"));
			yield put(CheckoutSlice.setSuspendFlow(true));
		}
	} catch (err) {
		// error handling
	} finally {
		yield put(CheckoutSlice.setOtpLoading(false));
	}
}

function* postOtpValidatePasscode(params) {
	try {
		yield put(CheckoutSlice.setOtpLoading(true));
		const {
			otpStatus,
			passcode,
			phoneNumber,
			trackingNumber,
			resendCount,
			flow,
		} = params.payload;
		const requestBody =
			otpStatus === "VALID"
				? {
					approach: "",
					flow: flow,
					otpStatus: otpStatus,
					request: {
						onetimePasscode: passcode,
						phoneNumber: phoneNumber,
						trackingNumber: trackingNumber,
					},
					resendCount: resendCount ?? 0,
					shoppingPath: "PROSPECT",
				}
				: {
					approach: "",
					flow: flow,
					otpStatus: otpStatus,
					request: {
						onetimePasscode: passcode,
					},
					resendCount: 0,
					shoppingPath: "PROSPECT",
				};

		// localhost mock
		// const resp = yield call(() => Promise.resolve(OtpValidatePasscode));
		// const validatePasscodeResponse = { data: resp };
		// end localhost

		// live API call
		const validatePasscodeResponse = yield apiClient.post(
			API.OTP_VALIDATE_PASSCODE_URL,
			requestBody
		);
		// end live API

		yield put(
			CheckoutSlice.setOtpValidatePasscode({
				status: validatePasscodeResponse.status,
				data: validatePasscodeResponse.data,
			})
		);
		//based on response call will move the call in if else condition, for testing purpose
		// yield* validateBusinessCreditDetailsInfo(flow)
	} catch (err) {
		// error handling
	} finally {
		yield put(CheckoutSlice.setOtpLoading(false));
	}
}

function* postNpaNxxRetrieveNpaNxx(params) {
	const pageData = yield select(selectors.pageData);
	try {
		yield put(CheckoutSlice.setNpaNxxLoading(true));
		const { zip } = params.payload;
		const requestBody = {
			approach: "",
			flow: pageData?.unifiedCartParams?.flow,
			shoppingPath: "PROSPECT",
			zip: zip,
		};

		const resp = yield apiClient.post(
			API.NPA_NXX_RETRIEVE_NPA_NXX_URL,
			requestBody
		);

		// if (resp?.status === 200) {
		const data = resp?.data;
		// TODO: this should not be a hard stop for all errors
		// if (data?.serviceStatus?.success) {
		yield put(CheckoutSlice.setNpaNxxRetrieveNpaNxxResponse(data));
		// } else {
		//   yield put(CheckoutSlice.setSuspendFlow(true));
		// }
		// } else {
		//   yield put(CheckoutSlice.setSuspendFlow(true));
		// }
	} catch (err) {
		// error handling
		yield put(CheckoutSlice.setSuspendFlow(true));
	} finally {
		yield put(CheckoutSlice.setNpaNxxLoading(false));
	}
}

function* postNpaNxxRetrieveMdn(params) {
	const pageData = yield select(selectors.pageData);
	const availableDevices = yield select(selectors.availableDevices);
	try {
		yield put(CheckoutSlice.setNpaNxxLoading(true));
		const { packageWithNpaNxx, successMessage } = params.payload;
		const requestBody = {
			approach: "",
			flow: pageData?.unifiedCartParams?.flow,
			shoppingPath: "PROSPECT",
			packageWithNpaNxx: packageWithNpaNxx,
		};

		const resp = yield apiClient.post(API.NPA_NXX_RETRIEVE_MDN_URL, requestBody);
		const npaNxxRetrieveMdnResponse = resp.data;

		yield put(
			CheckoutSlice.setNpaNxxRetrieveMdnResponse(npaNxxRetrieveMdnResponse)
		);

		if (npaNxxRetrieveMdnResponse?.serviceStatus?.success) {
			let successDevices = npaNxxRetrieveMdnResponse?.mdnResponseInfo.filter(
				(device) => device.success
			);
			const updatedAvailableDevices = availableDevices.map((device) => {
				let found = successDevices.find((d) => d.packageId === device.packageName);
				if (found) {
					return {
						...device,
						...found,
					};
				}
				return device;
			});
			if (successDevices?.length > 0) {
				yield put(CheckoutSlice.setAvailableDevices(updatedAvailableDevices));
				let devicesObj = {};
				updatedAvailableDevices
					.filter((device) => device.mtnActive && device.selected)
					.map((device) => {
						devicesObj[device?.packageName] = {
							npaNxx: {
								npa: device?.mdn?.substring(0, 3),
								nxx: device?.mdn?.substring(3, 6),
								fullMtn: device?.mdn,
								min: device?.min,
								mea: device?.mea,
								mtnReservationDate: device?.mtnReservationDate,
							},
							portInInfo: {
								phoneNumber: "",
								accountNumber: "",
								accountPin: "",
								authorizedSigner: "",
								billingAddress: {
									addressLine1: "",
									addressLine2: "",
									city: "",
									state: "",
									zipCode: "",
									country: "",
								},
								numberGroup: "",
								omin: "",
								sid: "",
								spidOcn: "",
							},
							portableNumber: false,
						};
					});
				const notification = {
					show: true,
					type: "success",
					title: successMessage,
				};
				yield put(CheckoutSlice.setNotification(notification));
				yield* postNpaNxxNpaNxx({
					payload: {
						devicesObj,
					},
				});
			}
		}
	} catch (err) {
		// error handling
	} finally {
		yield put(CheckoutSlice.setNpaNxxLoading(false));
	}
}

function* postNpaNxxReleaseMdn(params) {
	const pageData = yield select(selectors.pageData);
	const availableDevices = yield select(selectors.availableDevices);
	try {
		yield put(CheckoutSlice.setNpaNxxLoading(true));
		const { packageWithMdn } = params.payload;
		const requestBody = {
			approach: "",
			flow: pageData?.unifiedCartParams?.flow,
			shoppingPath: "PROSPECT",
			packageWithMdn: packageWithMdn,
		};

		const resp = yield apiClient.post(API.NPA_NXX_RELEASE_MDN_URL, requestBody);
		const npaNxxReleaseMdnResponse = resp.data;

		yield put(
			CheckoutSlice.setNpaNxxReleaseMdnResponse(npaNxxReleaseMdnResponse)
		);

		if (npaNxxReleaseMdnResponse?.serviceStatus?.success) {
			let successDevices = npaNxxReleaseMdnResponse?.mdnResponseInfo.filter(
				(device) => device.success
			);
			const updatedAvailableDevices = availableDevices.map((device) => {
				let found = successDevices.find((d) => d.packageId === device.packageName);
				if (found) {
					return {
						...device,
						mdn: "",
						mea: "",
						min: "",
						mtnActive: false,
						mtnReservationDate: "",
						npa: "",
						nxx: "",
					};
				}
				return device;
			});
			if (successDevices?.length > 0) {
				yield put(CheckoutSlice.setAvailableDevices(updatedAvailableDevices));
			}
		}
	} catch (err) {
		// error handling
	} finally {
		yield put(CheckoutSlice.setNpaNxxLoading(false));
	}
}

function* postNpaNxxUpdateNickname(params) {
	try {
		yield put(CheckoutSlice.setNpaNxxLoading(true));
		const { nicknames } = params.payload;
		const requestBody = {
			...nicknames,
		};

		const response = yield apiClient.post(
			API.NPA_NXX_UPDATE_NICKNAME_URL,
			requestBody
		);
		const npaNxxUpdateNicknameResponse = response.data;
		yield put(
			CheckoutSlice.setNpaNxxUpdateNicknameResponse(npaNxxUpdateNicknameResponse)
		);
		let sessionCartResponse = {
			prospectFlowData: response?.data?.response,
			serviceStatus: response?.data?.status,
		};
		if (response?.data?.status?.success && response?.data?.response) {
			const newStore = Utils.setStoreFromRetrieve(sessionCartResponse);
			yield put(GlobalSlice.setSessionCartData(newStore));
			window?.localStorage?.setItem("_cartData", JSON.stringify(newStore));
		}
	} catch (err) {
		// error handling
	} finally {
		yield put(CheckoutSlice.setNpaNxxLoading(false));
	}
}

function* postNpaNxxNpaNxx(params) {
	const pageData = yield select(selectors.pageData);
	try {
		yield put(CheckoutSlice.setNpaNxxLoading(true));
		const { devicesObj } = params.payload;
		const requestBody = {
			approach: "",
			flow: pageData?.unifiedCartParams?.flow,
			shoppingPath: "PROSPECT",
			npanxxs: {
				...devicesObj,
			},
		};

		// localhost mock
		// const resp = yield call(() => Promise.resolve(npaNxxNpaNxxAPI));
		// const npaNxxNpaNxxResponse = resp;
		// end localhost

		// live API call
		const resp = yield apiClient.post(API.NPA_NXX_NPA_NXX_URL, requestBody);
		const npaNxxNpaNxxResponse = resp.data;
		// end live API

		yield put(CheckoutSlice.setNpaNxxNpaNxxResponse(npaNxxNpaNxxResponse));

		// live API call
		if (npaNxxNpaNxxResponse.success) {
			yield* getSessionCartInfo(pageData?.unifiedCartParams?.flow);
		}
		// end live API call
	} catch (err) {
		// error handling
	} finally {
		yield put(CheckoutSlice.setNpaNxxLoading(false));
	}
}

function* postNpaNxxPortInEligibility(params) {
	const pageData = yield select(selectors.pageData);
	const npaNxxData = yield select(selectors.npaNxxData);
	let npaNxxExistingNumberState = cloneDeep(npaNxxData.existingNumberState);
	let npaNxxPortInEligibilityResponse = cloneDeep(
		npaNxxData.portInEligibilityResponse
	);
	try {
		yield put(CheckoutSlice.setNpaNxxLoading(true));
		const { mtn, deviceId } = params.payload;
		const requestBody = {
			approach: "",
			flow: pageData?.unifiedCartParams?.flow,
			shoppingPath: "PROSPECT",
			mtn: mtn,
		};

		// localhost mock
		// const resp = yield call(() => Promise.resolve(npaNxxPortInEligibilityAPI));
		// const npaNxxPortInEligibilityResponse = resp;
		// end localhost

		// live API call
		const resp = yield apiClient.post(
			API.NPA_NXX_PORT_IN_ELIGIBILITY_URL,
			requestBody
		);
		const data = resp?.data;
		npaNxxPortInEligibilityResponse[deviceId] = data;

		if (
			!data?.serviceStatus?.success &&
			data?.serviceStatus?.statusCode === "3099"
		) {
			npaNxxExistingNumberState[deviceId].errorTexts.phoneNumber = data
				?.serviceStatus?.statusMessage
				? data?.serviceStatus?.statusMessage?.split(
					"Etni service returned the error :"
				)[1]
				: data?.serviceStatus?.statusMessage;
			npaNxxExistingNumberState[deviceId].mtn = "";
			npaNxxExistingNumberState[deviceId].portInDetail = {};
			npaNxxExistingNumberState[deviceId].portInEligible = "";
		} else if (data?.serviceStatus?.success) {
			npaNxxExistingNumberState[deviceId].errorTexts.phoneNumber = "";
			npaNxxExistingNumberState[deviceId].mtn = data?.mtn;
			npaNxxExistingNumberState[deviceId].portInDetail = data?.portInDetail;
			npaNxxExistingNumberState[deviceId].portInEligible = data?.portInEligible;
		}
		// end live API

		yield put(CheckoutSlice.setExistingNumberState(npaNxxExistingNumberState));
		yield put(
			CheckoutSlice.setNpaNxxPortInEligibilityResponse(
				npaNxxPortInEligibilityResponse
			)
		);
	} catch (err) {
		// error handling
	} finally {
		yield put(CheckoutSlice.setNpaNxxLoading(false));
	}
}

function* getOneTalkEnrollment() {
	yield put(
		CheckoutSlice.setIsCheckoutPageApiCallInProgress({
			apiName: "oneTalkEnrollment",
			status: true,
		})
	);
	const pageData = yield select(selectors.pageData);
	try {
		const requestBody = {
			oneTalk: true,
			shoppingPath: "PROSPECT",
			approach: "",
			flow: "one-talk",
		};

		const tncUrl =
			pageData?.unifiedCartParams?.flow == "unified"
				? API.ONTALK_ENROLLMENT_URL
				: API.ONTALK_ENROLLMENT_URL;
		const resp = yield apiClient.post(tncUrl, requestBody);
		// end live API
		yield put(CheckoutSlice.setOneTalkEnrollment(resp?.data));
		yield put(
			CheckoutSlice.setIsCheckoutPageApiCallInProgress({
				apiName: "oneTalkEnrollment",
				status: false,
			})
		);
	} catch (err) {
		yield put(
			CheckoutSlice.setIsCheckoutPageApiCallInProgress({
				apiName: "oneTalkEnrollment",
				status: false,
			})
		);
		yield put(CheckoutSlice.setSuspendFlow(true));
	}
}

function* getTNCDetails() {
	yield put(
		CheckoutSlice.setIsCheckoutPageApiCallInProgress({
			apiName: "generateInstallment",
			status: true,
		})
	);
	const pageData = yield select(selectors.pageData);
	const globalData = yield select(selectors.globalData);
	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	try {
		const requestBody = {
			shoppingPath: "PROSPECT",
			approach: "",
			flow: pageData?.unifiedCartParams?.flow,
		};
		// localhost mock
		// const Response = yield call(() => Promise.resolve(tncDetailsAPI));
		// yield put(CheckoutSlice.setTNCDetailMethodInfo(Response));

		// live API call
		const tncUrl =
			sessionCartData?.cart?.unifiedCart ||
				(sessionCartData?.cart?.mixedcartAppliedWithoutOffers !== null &&
					sessionCartData?.cart?.mixedcartAppliedWithoutOffers &&
					sessionCartData?.cart?.mixedcartAppliedWithoutOffers.length > 0) ||
				pageData?.unifiedCartParams?.flow == "unified"
				? API.TNCDetail_INFO_UC_URL
				: API.TNCDetail_INFO_URL;
		const resp = yield apiClient.post(tncUrl, requestBody);
		// end live API
		yield put(CheckoutSlice.setTNCDetailMethodInfo(resp?.data));
		yield* getSessionCartInfo();
		// yield put(CheckoutSlice.setIsCheckoutPageApiCallInProgress({ apiName: "generateInstallment", status: false }));
	} catch (err) {
		// yield put(CheckoutSlice.setIsCheckoutPageApiCallInProgress({ apiName: "generateInstallment", status: false }));
	} finally {
		yield put(
			CheckoutSlice.setIsCheckoutPageApiCallInProgress({
				apiName: "generateInstallment",
				status: false,
			})
		);
	}
}


function* getBussTNCDetails() {
	yield put(
		CheckoutSlice.setIsCheckoutPageApiCallInProgress({
			apiName: "generateInstallment",
			status: true,
		})
	);
	const globalData = yield select(selectors.globalData);
	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	const pageData = yield select(selectors.pageData);
	try {
		const ecpdId = sessionCartData?.ecpdLookupCartData?.ecpdLookupCartData[0].ecpdProfileId;
		let clientName;

		if (
			sessionCartData !== null &&
			sessionCartData &&
			sessionCartData?.cart?.packages !== null &&
			sessionCartData?.cart?.packages
		) {
			Object.keys(sessionCartData?.cart?.packages).map((data) => {
				clientName = sessionCartData?.cart?.packages[data].sorDeviceType;
			});
		}
		const requestBody = {
			shoppingPath: "PROSPECT",
			approach: "",
			flow: pageData?.unifiedCartParams?.flow,
			ecpdid:ecpdId,
			channelid:47,
			clientName:clientName,
			clientVersion:null
		};
		const resp = yield apiClient.post(API.BUSS_TNCDetail_INFO_UC_URL, requestBody);
		// end live API
		yield put(CheckoutSlice.setBussTNCDetailMethodInfo(resp?.data));
		yield* getSessionCartInfo();
		// yield put(CheckoutSlice.setIsCheckoutPageApiCallInProgress({ apiName: "generateInstallment", status: false }));
	} catch (err) {
		// yield put(CheckoutSlice.setIsCheckoutPageApiCallInProgress({ apiName: "generateInstallment", status: false }));
	} finally {
		yield put(
			CheckoutSlice.setIsCheckoutPageApiCallInProgress({
				apiName: "generateInstallment",
				status: false,
			})
		);
	}
}

function* acceptBussTnc(params) {
	yield put(
		CheckoutSlice.setIsCheckoutPageApiCallInProgress({
			apiName: "generateInstallment",
			status: true,
		})
	);
	const globalData = yield select(selectors.globalData);
	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	const pageData = yield select(selectors.pageData);
	try {
		const ecpdId = sessionCartData?.ecpdLookupCartData?.ecpdLookupCartData[0].ecpdProfileId;

		const requestBody = {
			shoppingPath: "PROSPECT",
			approach: "",
			flow: pageData?.unifiedCartParams?.flow,
			ecpdid:ecpdId,
			channelid:47,
			accepted:"Y",
			termid:params?.payload?.termid
		};
		const resp = yield apiClient.post(API.ACCEPT_BUSS_TNC_URL, requestBody);
		// end live API
		if(resp?.data?.serviceStatus?.success){
		yield* postCompleteOrder();
		}
		// yield put(CheckoutSlice.setIsCheckoutPageApiCallInProgress({ apiName: "generateInstallment", status: false }));
	} catch (err) {
		// yield put(CheckoutSlice.setIsCheckoutPageApiCallInProgress({ apiName: "generateInstallment", status: false }));
	} finally {
		yield put(
			CheckoutSlice.setIsCheckoutPageApiCallInProgress({
				apiName: "generateInstallment",
				status: false,
			})
		);
	}
}

function* resumeEcpdDocs(params) {
	yield put(CheckoutSlice.setMaaTncCheckVerificationResultLoading(true));

	try {
		const { flow, isOneTalkOnly } = params.payload;
		const requestBody = {
			shoppingPath: "PROSPECT",
			approach: "",
			flow: "prospect",
		};

		// // localhost mock
		// const Response = yield call(() => Promise.resolve(tncDetailsAPI));
		// yield put(CheckoutSlice.setresumeEcpdDocsInfo(Response));

		// live API call
		const resp = yield apiClient.post(API.ResumeDocusign_INFO_URL, requestBody);
		// end live API

		if (resp?.status === 200) {
			if (resp?.data?.serviceStatus?.success) {
				yield put(CheckoutSlice.setresumeEcpdDocsInfo(resp?.data));
				// TO REVISIT FLOW
				if (flow === "OneTalk" || isOneTalkOnly) {
					yield* getOneTalkEnrollment();
				}
				yield* getTNCDetails();
			} else {
				yield put(
					CheckoutSlice.setSuspendScenario(resp?.data?.serviceStatus?.statusCode)
				);
				yield put(CheckoutSlice.setSuspendFlow(true));
				yield put(CheckoutSlice.setShowProgressOverlay(false));
			}
		} else {
			yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_ECPD"));
			yield put(CheckoutSlice.setSuspendFlow(true));
			yield put(CheckoutSlice.setShowProgressOverlay(false));
		}
	} catch (err) {
		yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_ECPD"));
		yield put(CheckoutSlice.setSuspendFlow(true));
		yield put(CheckoutSlice.setShowProgressOverlay(false));
		yield put(CheckoutSlice.setMaaTncCheckVerificationResultLoading(false));
	} finally {
		yield put(CheckoutSlice.setMaaTncCheckVerificationResultLoading(false));
		yield put(CheckoutSlice.setShowProgressOverlay(false));
	}
}

function* postProcessEcpdDetails(params) {
	const pageData = yield select(selectors.pageData);
	try {
		yield put(ReviewSlice.setLoading(true));
		const requestBody = {
			approach: "",
			flow: pageData?.unifiedCartParams?.flow,
			shoppingPath: "PROSPECT",
		};

		// localhost mock
		// const resp = yield call(() => Promise.resolve(reviewProcessEcpdDetailsAPI));
		// const reviewProcessEcpdDetailsResponse = resp;
		// end localhost

		// live API call
		const resp = yield apiClient.post(
			API.REVIEW_PROCESS_ECPD_DETAILS_URL,
			requestBody
		);
		const reviewProcessEcpdDetailsResponse = resp.data;
		// end live API

		yield put(
			ReviewSlice.setProcessEcpdDetailsResponse(reviewProcessEcpdDetailsResponse)
		);
	} catch (err) {
		// error handling
	} finally {
		yield put(ReviewSlice.setLoading(false));
	}
}

function* postCheckCartInventoryStatus(params) {
	const pageData = yield select(selectors.pageData);
	try {
		yield put(ReviewSlice.setLoading(true));
		const { inventoryFailErrorCode, sourcePath } = params.payload;
		const requestBody = {
			approach: "",
			cartMeta: {
				sourcePath: sourcePath,
			},
			flow: pageData?.unifiedCartParams?.flow,
			shoppingPath: "PROSPECT",
		};

		// localhost mock
		// const resp = yield call(() => Promise.resolve(reviewCheckCartInventoryStatusAPI));
		// const reviewCheckCartInventoryStatusResponse = resp;
		// end localhost

		// live API call
		const resp = yield apiClient.post(
			API.REVIEW_CHECK_CART_INVENTORY_STATUS_URL,
			requestBody
		);
		const reviewCheckCartInventoryStatusResponse = resp.data;
		// end live API

		yield put(
			ReviewSlice.setCheckCartInventoryStatusResponse(
				reviewCheckCartInventoryStatusResponse
			)
		);
		if (reviewCheckCartInventoryStatusResponse?.serviceStatus?.success) {
			yield* postUpdateInstallmentContractDetails();
		} else if (
			reviewCheckCartInventoryStatusResponse?.serviceStatus?.statusCode === 99 &&
			reviewCheckCartInventoryStatusResponse?.serviceStatus?.statusMessage ===
			inventoryFailErrorCode
		) {
			yield* onInventoryFailedModal(
				reviewCheckCartInventoryStatusResponse?.outOfStockPackages
			);
		}
	} catch (err) {
		// error handling
	} finally {
		yield put(ReviewSlice.setLoading(false));
	}
}

function* onInventoryFailedModal(packages) {
	const globalData = yield select(selectors.globalData);
	try {
		yield put(ReviewSlice.setLoading(true));
		if (packages && packages?.length > 0) {
			let oosDevices = Utils.retrieveOOSDevices(
				packages,
				globalData?.sessionCartData
			);
			yield put(ReviewSlice.setOosDevices(oosDevices));
		}
	} catch (err) {
		// error handling
	} finally {
		yield put(ReviewSlice.setLoading(false));
	}
}

function* postUpdateInstallmentContractDetails(params) {
	const globalData = yield select(selectors.globalData);
	const pageData = yield select(selectors.pageData);
	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	try {
		yield put(ReviewSlice.setLoading(true));

		const requestBody = {
			approach: "",
			flow: pageData?.unifiedCartParams?.flow,
			shoppingPath: "PROSPECT",
		};

		// localhost mock
		// const resp = yield call(() => Promise.resolve(reviewUpdateInstallmentContractDetailsAPI));
		// const reviewUpdateInstallmentContractDetailsResponse = resp;
		// end localhost

		// live API call
		const resp = yield apiClient.post(
			API.REVIEW_UPDATE_INSTALLMENT_CONTRACT_DETAILS_URL,
			requestBody
		);
		const reviewUpdateInstallmentContractDetailsResponse = resp.data;
		// end live API

		yield put(
			ReviewSlice.setUpdateInstallmentContractDetailsResponse(
				reviewUpdateInstallmentContractDetailsResponse
			)
		);
		if (reviewUpdateInstallmentContractDetailsResponse?.serviceStatus?.success) {
			if (sessionCartData?.showPaymentPage || true) {
				yield put(ReviewSlice.setRedirectToPayment(true));
			} else {
				yield* postCompleteOrderWithoutPayment();
			}
		}
	} catch (err) {
		// error handling
	} finally {
		yield put(ReviewSlice.setLoading(false));
	}
}

function* postCompleteOrderWithoutPayment(params) {
	const globalData = yield select(selectors.globalData);
	const pageData = yield select(selectors.pageData);
	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	try {
		yield put(ReviewSlice.setLoading(true));
		const requestBody = {
			approach: "",
			flow: pageData?.unifiedCartParams?.flow,
			shoppingPath: "PROSPECT",
			safeTechSessionId: pageData?.safetechSessionId,
			browserInfo: {
				device: Platform.product || "Unknown",
				deviceType: window?.outerWidth < 767 ? "mobile" : "desktop",
				browser: Platform?.Browser || "Unknown",
				browserVersion: Platform?.BrowserVersion || "Unknown",
				os: Platform?.OS || "Unknown",
				osVersion: Platform?.OSVersion || "Unknown",
			},
		};

		// localhost mock
		// end localhost

		// live API call
		if (sessionCartData?.cart?.unifiedCart || sessionCartData?.tys) {
			const resp = yield apiClient.post(
				API.UNIFIED_CART_COMPLETE_ORDER_WITHOUT_PAYMENT_URL,
				requestBody
			);
			const reviewCompleteOrderWithoutPaymentResponse = resp.data;
			// end live API

			yield put(
				ReviewSlice.setCompleteOrderWithoutPaymentResponse(
					reviewCompleteOrderWithoutPaymentResponse
				)
			);
			if (reviewCompleteOrderWithoutPaymentResponse?.serviceStatus?.success) {
				yield put(
					ReviewSlice.setUcOrderSuccessDetails(
						reviewCompleteOrderWithoutPaymentResponse
					)
				);
				yield put(ReviewSlice.setRedirectToConfirmation(true));
			} else {
				yield put(
					CheckoutSlice.setSuspendScenario(
						reviewCompleteOrderWithoutPaymentResponse?.serviceStatus?.statusCode
					)
				);
				yield put(ReviewSlice.setRedirectToFallout(true));
			}
			if (window["digitalData"]?.isUniqBOS) {
				// SiteAnalytics.eventOrderInfoTracking('Unified Business Order Submitted', sessionCartData, 'review and submit', '', reviewCompleteOrderWithoutPaymentResponse?.submitOrderResponse)
			}
		} else {
			const resp = yield apiClient.post(
				API.REVIEW_COMPLETE_ORDER_WITHOUT_PAYMENT_URL,
				requestBody
			);
			const reviewCompleteOrderWithoutPaymentResponse = resp.data;
			// end live API

			yield put(
				ReviewSlice.setCompleteOrderWithoutPaymentResponse(
					reviewCompleteOrderWithoutPaymentResponse
				)
			);
			if (reviewCompleteOrderWithoutPaymentResponse?.serviceStatus?.success) {
				yield put(
					ReviewSlice.setOrderSuccessDetails(
						reviewCompleteOrderWithoutPaymentResponse
					)
				);
				yield put(ReviewSlice.setRedirectToConfirmation(true));
			} else {
				yield put(
					CheckoutSlice.setSuspendScenario(
						reviewCompleteOrderWithoutPaymentResponse?.serviceStatus?.statusCode
					)
				);
				yield put(ReviewSlice.setRedirectToFallout(true));
			}
			if (window["digitalData"]?.isUniqBOS) {
				// SiteAnalytics.eventOrderInfoTracking('Unified Business Order Submitted', sessionCartData, 'review and submit', '', reviewCompleteOrderWithoutPaymentResponse?.submitOrderResponse)
			}
		}
	} catch (err) {
		// error handling
	} finally {
		yield put(ReviewSlice.setLoading(false));
	}
}

function* postInitializePayment(params) {
	const pageData = yield select(selectors.pageData);
	const paymentData = yield select(selectors.paymentData);
	try {
		yield put(PaymentSlice.setLoading(true));
		const requestBody = {
			approach: "",
			flow: pageData?.unifiedCartParams?.flow,
			isDSP: false,
			shoppingPath: "PROSPECT",
		};

		const resp = yield apiClient.post(
			API.PAYMENT_INITIALIZE_PAYMENT,
			requestBody
		);
		const paymentInitializePaymentResponse = resp.data;

		yield put(
			PaymentSlice.setInitializePaymentResponse(paymentInitializePaymentResponse)
		);

		if (paymentInitializePaymentResponse?.status?.serviceStatus) {
			window?.Cardinal?.setup("init", {
				jwt: paymentInitializePaymentResponse?.jwtToken,
			});
			yield put(
				PaymentSlice.setPaymentInfo({
					...paymentData?.paymentInfo,
					stage: "stage1:INITIALIZE_PAYMENT",
					status: "SUCCESS",
					stage1: { ...paymentInitializePaymentResponse },
					currentStage: "stage1",
					currentStatus: "SUCCESS",
				})
			);
		} else {
			yield put(
				PaymentSlice.setPaymentInfo({
					...paymentData?.paymentInfo,
					stage: "stage1:INITIALIZE_PAYMENT",
					status: "FAILED",
					stage1: { ...paymentInitializePaymentResponse },
					currentStage: "stage1",
					currentStatus: "FAILED",
					errorStatusCode:
						paymentInitializePaymentResponse.status?.statusCode ||
						"PAYMENT_ERROR_GENERIC",
				})
			);
		}
	} catch (err) {
		// error handling
	} finally {
		yield put(PaymentSlice.setLoading(false));
	}
}

function* postValidateAndSaveCard(params) {
	const globalData = yield select(selectors.globalData);
	const pageData = yield select(selectors.pageData);
	const paymentData = yield select(selectors.paymentData);
	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	try {
		yield put(PaymentSlice.setLoading(true));
		const { jwt } = params.payload;
		const cardRaw = paymentData?.cardNumber?.split(" ").join("");
		const parts = window?.ProtectPANandCVV(cardRaw, paymentData?.cvv) ?? "";
		const requestBody = {
			secureInfo: {
				jwt: jwt,
			},
			referenceId:
				paymentData?.paymentInfo[paymentData?.paymentInfo?.currentStage]
					?.referenceId ?? paymentData?.initializePaymentResponse?.referenceId,
			card: {
				valid: true,
				nameOnCard: paymentData?.cardHolderName,
				encData1: parts[0],
				encData2: parts[1],
				expirationDate: paymentData?.expirationDate,
				zipCode:
					paymentData?.address?.place?.properties?.postalCode?.substring(0, 5) ??
					paymentData?.address?.zipCode?.substring(0, 5),
				last4: cardRaw?.substring(cardRaw?.length - 4, cardRaw?.length),
				type: paymentData?.cardType,
				verificationCode: Utils.pieProtectSPI(paymentData?.cvv),
			},
			approach: "",
			address: {
				addressLine1:
					paymentData?.address?.place?.properties?.street ??
					paymentData?.address?.addressLine1,
				addressLine2: "",
				city:
					paymentData?.address?.place?.properties?.city ??
					paymentData?.address?.city,
				state:
					paymentData?.address?.place?.properties?.stateCode ??
					paymentData?.address?.state,
				zipCode:
					paymentData?.address?.place?.properties?.postalCode?.substring(0, 5) ??
					paymentData?.address?.zipCode,
			},
			paymentAmount: sessionCartData?.totalAmounts?.dueToday,
			addressType: paymentData?.addressType,
			browserJavaScriptEnabled: "True",
			flow: pageData?.unifiedCartParams?.flow,
			shoppingPath: "PROSPECT",
			safeTechSessionId: pageData?.safetechSessionId,
		};
		// const paymentValidateAndSaveCardResponse = yield call(() => Promise.resolve(orderSubmissionAPI));
		// yield put(PaymentSlice.setValidateAndSaveCardResponse(paymentValidateAndSaveCardResponse))
		const resp = yield apiClient.post(
			API.PAYMENT_VALIDATE_AND_SAVE_CARD,
			requestBody
		);
		const paymentValidateAndSaveCardResponse = resp.data;

		yield put(
			PaymentSlice.setValidateAndSaveCardResponse(
				paymentValidateAndSaveCardResponse
			)
		);

		if (paymentValidateAndSaveCardResponse.acsURL) {
			window.Cardinal.continue(
				"cca",
				{
					AcsUrl: paymentValidateAndSaveCardResponse.acsURL,
					Payload: paymentValidateAndSaveCardResponse.payLoad,
				},
				{
					OrderDetails: {
						TransactionId: paymentValidateAndSaveCardResponse.transactionId,
					},
				}
			);
		} else {
			if (
				paymentValidateAndSaveCardResponse.serviceStatus &&
				paymentValidateAndSaveCardResponse.valid
			) {
				yield put(
					PaymentSlice.setPaymentInfo({
						...paymentData?.paymentInfo,
						stage: "stag2:FULL_AUTH",
						status: "SUCCESS",
						stage2: { ...paymentValidateAndSaveCardResponse },
						currentStage: "stage2",
						currentStatus: "SUCCESS",
					})
				);
				yield* postCompleteOrder({
					payload: {
						paymentType: "CREDITCARD",
						...requestBody,
					},
				});
			} else {
				if (
					paymentValidateAndSaveCardResponse.statusCode ===
					"PAYMENT_ERROR_MAXIMUM_ATTEMPTS_EXHAUSTED" ||
					paymentValidateAndSaveCardResponse.statusCode ===
					"PAYMENT_ERROR_CREDIT_FUND_NOTAVAIL" ||
					paymentValidateAndSaveCardResponse.statusCode ===
					"PAYMENT_ERROR_SAFETECH_VERIFY_DECLINE" ||
					data.statusCode === "PAYMENT_ERROR_GENERIC" ||
					paymentValidateAndSaveCardResponse.statusCode ===
					"PAYMENT_ERROR_FAILED_AUTHENTICATION" ||
					paymentValidateAndSaveCardResponse.statusCode ===
					"PAYMENT_BILLING_ADDRESS_VALIDATION_FAILED"
				) {
					// this.paymentExhausted.next(true);
					yield put(PaymentSlice.setErrorResponse(true));
				}
				if (
					!paymentValidateAndSaveCardResponse.serviceStatus &&
					!paymentValidateAndSaveCardResponse.valid
				) {
					yield put(PaymentSlice.setErrorResponse(true));
				}
				yield put(
					PaymentSlice.setPaymentInfo({
						...paymentData?.paymentInfo,
						stage: "stag2:FULL_AUTH",
						status: "FAILED",
						stage2: { ...paymentValidateAndSaveCardResponse },
						currentStage: "stage2",
						currentStatus: "FAILED",
						errorStatusCode: data.statusCode
							? data.statusCode
							: "AUTOMATION_STEPS_INCOMPLETE",
					})
				);
			}
		}
	} catch (err) {
		// error handling
		yield put(PaymentSlice.setErrorResponse(true));
	} finally {
		yield put(PaymentSlice.setLoading(false));
	}
}

function* doUpdatePymtCounterFail() {
	const pageData = yield select(selectors.pageData);
	const paymentData = yield select(selectors.paymentData);
	try {
		yield put(PaymentSlice.setLoading(true));
		const requestBody = {
			approach: "",
			flow: pageData?.unifiedCartParams?.flow,
			shoppingPath: "PROSPECT",
		};

		const resp = yield apiClient.post(API.PAYMENT_FAILURE_URL, requestBody);
		const paymentFailureResponse = resp.data;
	} catch (err) {
		// error handling
		yield put(PaymentSlice.setErrorResponse(true));
	} finally {
		yield put(PaymentSlice.setLoading(false));
	}
}

function* updateBussCheckoutPrice() {

	try {
		yield put(PaymentSlice.setLoading(true));
		const requestBody = {
			approach: "",
			shoppingPath: "PROSPECT",
			orderUserID: window?.mbtGlobal?.userId,
			gon: window?.mbtGlobal?.gon
		};

		const resp = yield apiClient.post(API.UPDATE_BUSS_CHECKOUT_PRICE, requestBody);
		const bussCheckoutPriceResp = resp.data;
		yield put(PaymentSlice.setBussCheckoutPriceResp(bussCheckoutPriceResp));

	} catch (err) {
		// error handling
		yield put(PaymentSlice.setErrorResponse(true));
	} finally {
		yield put(PaymentSlice.setLoading(false));
	}
}

function* postCompleteOrder(params) {
	const globalData = yield select(selectors.globalData);
	const paymentData = yield select(selectors.paymentData);
	const pageData = yield select(selectors.pageData);
	const requestPayload = params?.payload?.isInspicio ? params?.payload?.requestBody : params?.payload;
	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	try {
		yield put(PaymentSlice.setLoading(true));
		// const { paymentType } = params.payload;
		const requestBody = {
			browserInfo: {
				device: Platform.product || "Unknown",
				deviceType: window?.outerWidth < 767 ? "mobile" : "desktop",
				browser: Platform?.Browser || "Unknown",
				browserVersion: Platform?.BrowserVersion || "Unknown",
				os: Platform?.OS || "Unknown",
				osVersion: Platform?.OSVersion || "Unknown",
			},
			paymentType: paymentData?.selectedPaymentType,
			...requestPayload,
		};
		//local Testing purpose
		// const resp = yield call(() => Promise.resolve(confirmationAPI));
		// const reviewCompleteOrderWithoutPaymentResponse = resp;
		// yield put(ReviewSlice.setUcOrderSuccessDetails(reviewCompleteOrderWithoutPaymentResponse));

		if (sessionCartData?.cart?.unifiedCart || sessionCartData?.tys) {
			const resp = yield apiClient.post(
				API.UNIFIED_CART_COMPLETE_ORDER_PAYMENT_URL,
				requestBody
			);
			const paymentCompleteOrderResponse = resp.data;

			yield put(
				PaymentSlice.setCompleteOrderResponse(paymentCompleteOrderResponse)
			);

			if (paymentCompleteOrderResponse?.serviceStatus?.success) {
				yield put(
					ReviewSlice.setUcOrderSuccessDetails(paymentCompleteOrderResponse)
				);
				yield put(
					PaymentSlice.setPaymentInfo({
						...paymentData?.paymentInfo,
						stage: "stage4:FULL_AUTH",
						status: "SUCCESS",
						stage4: { ...paymentCompleteOrderResponse },
						currentStage: "stage4",
						currentStatus: "SUCCESS",
					})
				);
			} else {
				yield put(
					PaymentSlice.setPaymentInfo({
						...paymentData?.paymentInfo,
						stage: "stag2:FULL_AUTH",
						status: "FAILED",
						stage2: { ...paymentCompleteOrderResponse },
						currentStage: "stage2",
						currentStatus: "FAILED",
						errorStatusCode: paymentCompleteOrderResponse.serviceStatus.statusCode
							? paymentCompleteOrderResponse.serviceStatus.statusCode
							: "AUTOMATION_STEPS_INCOMPLETE",
					})
				);
				if (
					paymentCompleteOrderResponse.serviceStatus.statusCode === "PAYMENT_ERROR_MAXIMUM_ATTEMPTS_EXHAUSTED" ||
					paymentCompleteOrderResponse.serviceStatus.statusCode === "PAYMENT_ERROR_CREDIT_FUND_NOTAVAIL" ||
					paymentCompleteOrderResponse.serviceStatus.statusCode === "PAYMENT_ERROR_SAFETECH_VERIFY_DECLINE" ||
					paymentCompleteOrderResponse.serviceStatus.statusCode === "PAYMENT_ERROR_GENERIC" ||
					paymentCompleteOrderResponse.serviceStatus.statusCode === "PAYMENT_ERROR_FAILED_AUTHENTICATION" ||
					paymentCompleteOrderResponse.serviceStatus.statusCode === "PAYMENT_BILLING_ADDRESS_VALIDATION_FAILED"
				) {
					// this.paymentExhausted.next(true);
					yield put(PaymentSlice.setErrorResponse(true));
				}
				if (!paymentCompleteOrderResponse.serviceStatus.success) {
					yield put(PaymentSlice.setErrorResponse(true));
				}

				// if (
				// 	data.serviceStatus.statusCode ===
				// 	"PAYMENT_ERROR_MAXIMUM_ATTEMPTS_EXHAUSTED"
				// ) {
				// 	// this.paymentExhausted.next(true);
				// }
				// yield put(
				// 	PaymentSlice.setPaymentInfo({
				// 		...paymentData?.paymentInfo,
				// 		stage: "stage4:FULL_AUTH",
				// 		status: "FAILED",
				// 		stage4: { ...paymentCompleteOrderResponse },
				// 		currentStage: "stage4",
				// 		currentStatus: "FAILED",
				// 		errorStatusCode: data.serviceStatus.statusCode,
				// 	})
				// );
			}
			if (window["digitalData"].isUniqBOS) {
				// SiteAnalytics.eventOrderInfoTracking('Unified Business Order Submitted', sessionCartData, 'review and submit', '', paymentCompleteOrderResponse?.submitOrderResponse)
			}
		} else {
			const ecpdId = sessionCartData?.ecpdLookupCartData?.ecpdLookupCartData[0].ecpdProfileId;
			let clientName;
			if (
				sessionCartData !== null &&
				sessionCartData &&
				sessionCartData?.cart?.packages !== null &&
				sessionCartData?.cart?.packages
			) {
				Object.keys(sessionCartData?.cart?.packages).map((data) => {
					clientName = sessionCartData?.cart?.packages[data].sorDeviceType;
				});
			}
			const requestBodyBUSSAlone = {
				accountNumber: sessionCartData?.businessInfo?.shellAccountNumber,
				confirmationId: sessionCartData?.cart?.clientReferenceNumber,
				ecpdId: ecpdId,
				serviceId: clientName,
				gon: "",
			};
			const resp = sessionCartData?.standAloneBuSS
				? yield apiClient.post(API.GET_SUBMIT_PURCHASE_INFO, requestBodyBUSSAlone)
				: yield apiClient.post(API.PAYMENT_COMPLETE_ORDER, requestBody);
			const paymentCompleteOrderResponse = resp.data;
			yield put(
				PaymentSlice.setCompleteOrderResponse(paymentCompleteOrderResponse)
			);
			if (paymentCompleteOrderResponse?.serviceStatus?.success) {
				yield put(ReviewSlice.setOrderSuccessDetails(paymentCompleteOrderResponse));
				yield put(
					PaymentSlice.setPaymentInfo({
						...paymentData?.paymentInfo,
						stage: "stage4:FULL_AUTH",
						status: "SUCCESS",
						stage4: { ...paymentCompleteOrderResponse },
						currentStage: "stage4",
						currentStatus: "SUCCESS",
					})
				);
			} else {
				yield put(
					PaymentSlice.setPaymentInfo({
						...paymentData?.paymentInfo,
						stage: "stag2:FULL_AUTH",
						status: "FAILED",
						stage2: { ...paymentCompleteOrderResponse },
						currentStage: "stage2",
						currentStatus: "FAILED",
						errorStatusCode: paymentCompleteOrderResponse.serviceStatus.statusCode
							? paymentCompleteOrderResponse.serviceStatus.statusCode
							: "AUTOMATION_STEPS_INCOMPLETE",
					})
				);
				if (
					paymentCompleteOrderResponse.serviceStatus.statusCode === "PAYMENT_ERROR_MAXIMUM_ATTEMPTS_EXHAUSTED" ||
					paymentCompleteOrderResponse.serviceStatus.statusCode === "PAYMENT_ERROR_CREDIT_FUND_NOTAVAIL" ||
					paymentCompleteOrderResponse.serviceStatus.statusCode === "PAYMENT_ERROR_SAFETECH_VERIFY_DECLINE" ||
					paymentCompleteOrderResponse.serviceStatus.statusCode === "PAYMENT_ERROR_GENERIC" ||
					paymentCompleteOrderResponse.serviceStatus.statusCode === "PAYMENT_ERROR_FAILED_AUTHENTICATION" ||
					paymentCompleteOrderResponse.serviceStatus.statusCode === "PAYMENT_BILLING_ADDRESS_VALIDATION_FAILED"
				) {
					// this.paymentExhausted.next(true);
					yield put(PaymentSlice.setErrorResponse(true));
				}
				if (!paymentCompleteOrderResponse.serviceStatus.success) {
					yield put(PaymentSlice.setErrorResponse(true));
				}

				// if (
				// 	data.serviceStatus.statusCode ===
				// 	"PAYMENT_ERROR_MAXIMUM_ATTEMPTS_EXHAUSTED"
				// ) {
				// 	// this.paymentExhausted.next(true);
				// }
				// yield put(
				// 	PaymentSlice.setPaymentInfo({
				// 		...paymentData?.paymentInfo,
				// 		stage: "stage4:FULL_AUTH",
				// 		status: "FAILED",
				// 		stage4: { ...paymentCompleteOrderResponse },
				// 		currentStage: "stage4",
				// 		currentStatus: "FAILED",
				// 		errorStatusCode: data.serviceStatus.statusCode,
				// 	})
				// );
			}
			if (window["digitalData"].isUniqBOS) {
				//SiteAnalytics.eventOrderInfoTracking('Unified Business Order Submitted', sessionCartData, 'review and submit', '', paymentCompleteOrderResponse?.submitOrderResponse)
			}
		}
	} catch (err) {
		// error handling
	} finally {
		yield put(PaymentSlice.setLoading(false));
	}
}

function* storeTermsConditons(params) {
	yield put(
		CheckoutSlice.setIsCheckoutPageApiCallInProgress({
			apiName: "storeTermsLoading",
			status: true,
		})
	);
	// const globalData = yield select(selectors.globalData);
	// const sessionCartData = globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	// const isUnifiedCart = sessionCartData?.cart?.unifiedCart
	//   || flow === "unified";
	const pageData = yield select(selectors.pageData);
	const globalData = yield select(selectors.globalData);
	const flow = pageData?.unifiedCartParams?.flow;
	let requestBody = {};
	let payload = params.payload.tncItems;
	if (payload?.length) {
		let terms = {};
		payload.map((term) => {
			terms[term?.id] = term.signed;
		});
		payload.tncInfo = terms;
	}
	// if (payload.id == "dppTnC")
	// { payload.tncInfo = { "dppTnC": true } }
	try {
		// yield put(CheckoutSlice.setNpaNxxLoading(true));
		requestBody = {
			tncInfo: payload.tncInfo,
			shoppingPath: "PROSPECT",
			approach: "",
			packageType: payload.packageType,
			flow: pageData?.unifiedCartParams?.flow,
		};
		if (!requestBody?.tncInfo) {
			requestBody.tncInfo = {};
			requestBody.tncInfo[payload?.id] = payload?.signed;
		}

		const resp = yield apiClient.post(API.REVIEW_AND_AGREE_URL, requestBody);
		yield* getSessionCartInfo();
		const reviewAgreementResp = resp.data;
		//not neccesary as we are redirecting user to installation page first for mmw
		// if (!globalData?.isOrderQuoteFlow && Utils.is5G4GQuote(pageData) && Utils.is5GMwwCart(pageData)) {
		//   yield* getInstallation()
		// }
		yield put(CheckoutSlice.setReviewTermsAgreementResponse(reviewAgreementResp));
		params?.payload?.callback && params?.payload?.callback();
	} catch (err) {
	} finally {
		yield put(
			CheckoutSlice.setIsCheckoutPageApiCallInProgress({
				apiName: "storeTermsLoading",
				status: false,
			})
		);
	}
}

function* storeTermsConditonsReview(params) {
	yield put(
		CheckoutSlice.setIsCheckoutPageApiCallInProgress({
			apiName: "storeTermsLoading",
			status: true,
		})
	);
	// const globalData = yield select(selectors.globalData);
	// const sessionCartData = globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	// const isUnifiedCart = sessionCartData?.cart?.unifiedCart
	//   || flow === "unified";
	const pageData = yield select(selectors.pageData);
	const globalData = yield select(selectors.globalData);
	const flow = pageData?.unifiedCartParams?.flow;
	let requestBody = {};
	let payload = params.payload;
	if (payload.id == "dppTnC") {
		payload.tncInfo = { dppTnC: true };
	}
	try {
		// yield put(CheckoutSlice.setNpaNxxLoading(true));
		requestBody = {
			tncInfo: payload.tncInfo,
			shoppingPath: "PROSPECT",
			approach: "",
			packageType: payload.packageType,
			flow: pageData?.unifiedCartParams?.flow,
		};
		if (!requestBody?.tncInfo) {
			requestBody.tncInfo = {};
			requestBody.tncInfo[payload?.id] = payload?.signed;
		}

		const resp = yield apiClient.post(API.REVIEW_AND_AGREE_URL, requestBody);
		yield* getSessionCartInfo();
		const reviewAgreementResp = resp.data;
		//not neccesary as we are redirecting user to installation page first for mmw
		// if (!globalData?.isOrderQuoteFlow && Utils.is5G4GQuote(pageData) && Utils.is5GMwwCart(pageData)) {
		//   yield* getInstallation()
		// }
		yield put(CheckoutSlice.setReviewTermsAgreementResponse(reviewAgreementResp));
	} catch (err) {
	} finally {
		yield put(
			CheckoutSlice.setIsCheckoutPageApiCallInProgress({
				apiName: "storeTermsLoading",
				status: false,
			})
		);
	}
}

function* getProspectCartPDF() {
	try {
		const requestBody = {
			shoppingPath: "PROSPECT",
			approach: "",
			flow: "5g",
		};

		//localhost
		// const prospectPDFResponse = yield call(() => Promise.resolve(prospectPDFAPI));
		// yield put(ReviewSlice.setProspectCartPDF(prospectPDFResponse));

		// live API call
		const response = yield apiClient.post(API.GET_PROSPECT_CART_PDF, requestBody);
		yield put(ReviewSlice.setProspectCartPDF(response.data));
		// end live API
	} catch (err) {
		// error handling
	}
}

function* validateE911Address(params) {
	yield put(
		CheckoutSlice.setIsShoppingCartApiCallInProgress({
			apiName: "validateE911Address",
			status: true,
		})
	);
	try {
		let data = params.payload;
		let requestBody;
		requestBody = {
			lineE911AddressList: data.lineE911AddressList,
			lineE911AddressType: data.lineE911AddressType,
			shoppingPath: "PROSPECT",
			approach: "",
			flow: "one-talk",
		};

		//localhost
		// const validateE911AddressResponse = yield call(() => Promise.resolve(validateE911Address));
		// yield put(CheckoutSlice.setE911AddressResponse(validateE911AddressResponse?.response));

		//qa1 code
		const response = yield apiClient.post(API.VALIDATE_E911_ADDRESS, requestBody);
		yield put(CheckoutSlice.setE911AddressResponse(response.data));
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "validateE911Address",
				status: false,
			})
		);
	} catch (err) {
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "validateE911Address",
				status: false,
			})
		);
	}
}

function* postSharedCartSaveUrl() {
	const pageData = yield select(selectors.pageData);
	try {
		const requestBody = {
			shoppingPath: "PROSPECT",
			approach: "",
			flow: pageData?.unifiedCartParams?.flow,
		};

		const response = yield apiClient.post(API.SHARED_CART_SAVE_URL, requestBody);
		// yield put(ReviewSlice.setProspectCartPDF(response.data));
		if (response) {
			const cartId = response?.cartID;
			yield put(CheckoutSlice.setCartId(response?.data?.cartID));
		}
		// end live API
	} catch (err) {
		// error handling
	}
}

function* getPersonalGuranteeDoc() {
	try {
		const response = yield apiClient.post(API.SHARED_CART_SAVE_URL, requestBody);
		if (response?.serviceStatus?.success) {
			yield put(CheckoutSlice.setDocusignURL(response?.guaranteedDocuSignUrl));
		} else {
			yield put(
				CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_PERSONAL_GUARANTEE")
			);
			yield put(CheckoutSlice.setSuspendFlow(true));
		}
	} catch (err) {
		// error handling
		yield put(
			CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_PERSONAL_GUARANTEE")
		);
		yield put(CheckoutSlice.setSuspendFlow(true));
	}
}
function* validateSSN(params) {
	const unformatedSSN = (params?.payload?.ssn || "").replaceAll("-", "");
	yield put(CheckoutSlice.setMitekVerficationResultLoading(true));
	const requestBody = {
		pieSSN: window.ProtectString(unformatedSSN)?.[0],
		keyId: window.PIE.key_id,
		phase: window.PIE.phase,
	};
	try {
		const response = yield apiClient.post(API.VALIDATE_SSN_URL, requestBody);
		yield put(CheckoutSlice.setValidateSSNResult(response?.data));
		yield put(CheckoutSlice.setMitekVerficationResultLoading(false));
	} catch (err) {
		yield put(CheckoutSlice.setMitekVerficationResultLoading(false));
	}
}

function* mitekSMSVerification(params) {
	try {
		const response = yield apiClient.post(
			API.MITEK_VERIFICATION_SMS,
			params.payload
		);
		yield put(CheckoutSlice.setMitekCheckVerificationProcess(response?.data));
	} catch (err) {
		yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_OTP"));
		yield put(CheckoutSlice.setSuspendFlow(true));
	}
}
function* sendMitekVerification(params) {
	try {
		yield put(CheckoutSlice.setMitekVerficationResultLoading(true));
		const response = yield apiClient.post(
			API.SEND_MITEK_VERIFICATION,
			params.payload
		);
		yield put(CheckoutSlice.setSendMitekVerification(response?.data));
		yield put(CheckoutSlice.setMitekVerficationResultLoading(false));
	} catch (err) {
		yield put(CheckoutSlice.setMitekVerficationResultLoading(false));
		yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_MITEK"));
		yield put(CheckoutSlice.setSuspendFlow(true));
	}
}

function* sendPaymentLink(params) {
	try {
		yield put(PaymentSlice.setLoading(true));
		const response = yield apiClient.post(
			API.SEND_PAYMENT_LINK,
			params.payload
		);
		yield put(PaymentSlice.setSendPaymentLinkResp(response?.data));
		yield put(PaymentSlice.setLoading(false));
	} catch (err) {
		yield put(PaymentSlice.setLoading(false));

	}
}


function* generateMitekVerificationLink(params) {
	try {
		const response = yield apiClient.post(API.GENERATE_MITEK_LINK);
		yield put(CheckoutSlice.setGenerateMitekLink(response?.data));
	} catch (err) {
		yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_OTP"));
		yield put(CheckoutSlice.setSuspendFlow(true));
	}
}

function* mitekCheckVerificationResult() {
	yield put(CheckoutSlice.setMitekVerficationResultLoading(true));
	try {
		const response = yield apiClient.post(API.MITEK_CHECK_VERIFICATION_RESULT);
		yield put(CheckoutSlice.setMitekCheckVerificationResult(response?.data));
		yield put(CheckoutSlice.setMitekVerficationResultLoading(false));
	} catch (err) {
		yield put(CheckoutSlice.setMitekVerficationResultLoading(false));
		yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_MITEK"));
		yield put(CheckoutSlice.setSuspendFlow(true));
	}
}

function* recieveClientPaymentDetails(params, retryCount = 0) {
	const maxRetries =
		params?.payload?.paymentTimeToWaitForResult /
		params?.payload?.paymentStatusPollInterval || 66;
	yield put(PaymentSlice.setLoading(true));
	try {
		const response = yield apiClient.post(API.RECIEVE_PAYMENT_DETAILS);
		if (
			response?.data?.status &&
			response?.data?.payload?.dsTransactionID && response?.data?.payload?.action == "enableSubmit") {
			yield put(PaymentSlice.setRecievePaymentInfoResp(response?.data));
		} else if (
			(response?.data?.status == "Timeout" || response?.data?.status == "Success") &&
			retryCount < maxRetries
		) {
			if (response?.data?.payload?.value) yield put(PaymentSlice.setRecievePaymentInfoResp(response?.data));
			yield delay(params?.payload?.paymentStatusPollInterval * 1000);
			//yield put(PaymentSlice.setRecievePaymentApiRetryCount(retryCount + 1));
			yield fork(recieveClientPaymentDetails, params, retryCount + 1);
		} else if (
			response?.data?.status === "PAYMENT_ERROR_MAXIMUM_ATTEMPTS_EXHAUSTED" ||
			response?.data?.status === "PAYMENT_ERROR_CREDIT_FUND_NOTAVAIL" ||
			response?.data?.status === "PAYMENT_ERROR_SAFETECH_VERIFY_DECLINE" ||
			response?.data?.status === "PAYMENT_ERROR_GENERIC" ||
			response?.data?.status === "PAYMENT_ERROR_FAILED_AUTHENTICATION" ||
			response?.data?.status === "PAYMENT_BILLING_ADDRESS_VALIDATION_FAILED"
		) {
			yield put(PaymentSlice.setPaymentInfo({
				stage: "stag5:FULL_AUTH",
				status: "FAILED",
				stage2: { ...response },
				currentStage: "stage2",
				currentStatus: "FAILED",
				errorStatusCode: response?.data?.status,
			}))
			yield put(PaymentSlice.setErrorResponse(true));
			yield put(PaymentSlice.setRecievePaymentInfoResp(response?.data));
		}
		else if (
			response?.data?.status == "FAILED" ||
			response?.data?.status == "FAIL" ||
			response?.data?.status == "DECLINED"
		) {
			yield put(PaymentSlice.setRecievePaymentInfoResp(response?.data));
		} else {
			yield put(PaymentSlice.setRecievePaymentInfoResp(response?.data));
		}
		yield put(PaymentSlice.setLoading(false));
	} catch (err) {
		yield put(PaymentSlice.setLoading(false));
	}
}

function* maaTncCheckVerificationResult(params) {
	yield put(CheckoutSlice.setMaaTncCheckVerificationResultLoading(true));
	try {
		const response = yield apiClient.post(
			API.MAATNC_CHECK_VERIFICATION_RESULT,
			params?.payload
		);
		yield put(CheckoutSlice.setMaaTncCheckVerificationResult(response?.data));
		yield put(CheckoutSlice.setMaaTncCheckVerificationResultLoading(false));
	} catch (err) {
		yield put(CheckoutSlice.setMaaTncCheckVerificationResultLoading(false));
		yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_MAA"));
		yield put(CheckoutSlice.setSuspendFlow(true));
	}
}

function* updateDeclinedStatus() {
	yield put(CheckoutSlice.setMitekVerficationResultLoading(true));
	try {
		const response = yield apiClient.post(API.MITEK_UPDATE_DECLINE_STATUS);
		if (response?.data?.status?.success) {
			yield put(CheckoutSlice.setUpdateDeclinedStatusResult(response?.data));
		} else {
			yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_OTP"));
			yield put(CheckoutSlice.setSuspendFlow(true));
		}
		yield put(CheckoutSlice.setMitekVerficationResultLoading(false));
	} catch (err) {
		yield put(CheckoutSlice.setMitekVerficationResultLoading(false));
		yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_OTP"));
		yield put(CheckoutSlice.setSuspendFlow(true));
	}
}

function* deleteDirectory() {
	try {
		const response = yield apiClient.post(API.DELETE_DIRECTORY, "");
		if (response?.data?.status === 200) {
			yield put(CheckoutSlice.setDeleteDirectoryResponse(response?.data));
		} else {
			console.error("Error while removing the Document");
		}
	} catch (err) {
		yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_OTP"));
		yield put(CheckoutSlice.setSuspendFlow(true));
	}
}

function* uploadFile(params) {
	try {
		let files = params.payload;
		const requestBody = {};
		const response = yield apiClient.post(API.UPLOAD_FILE, params?.payload);
	} catch (err) {
		yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_OTP"));
		yield put(CheckoutSlice.setSuspendFlow(true));
	}
}

function* readFile(params) {
	try {
		const requestBody = {};
		const response = yield apiClient.post(API.READ_FILE, requestBody);
		if (response?.data?.serviceStatus?.statusCode === "200") {
			yield put(CheckoutSlice.setViewDocumentResponse(response?.data));
		} else {
			console.error("Error while Reading the uploaded Document");
		}
	} catch (err) {
		yield put(CheckoutSlice.setSuspendScenario("SUSPENDED_FLOW_OTP"));
		yield put(CheckoutSlice.setSuspendFlow(true));
	}
}

const aemResourceAlreadyFetched = {};
function* getAEMContentResource(flow, resource) {
	const requestBody = {
		cartMeta: {
			sourcePath: "CART",
		},
		shoppingPath: "PROSPECT",
		approach: "",
		flow: flow,
	};
	try {
		let res = { data: {} };
		// To avoid multiple API calls for same resources
		if (!aemResourceAlreadyFetched[resource]) {
			aemResourceAlreadyFetched[resource] = true;
			if (mbtGlobal?.isAEMDirectCall) {
				const response = yield apiClient.get(
					`${mbtGlobal?.aemBaseUrl}en-us-${resource}.json`
				);
				res.data.data = response.data;
			} else {
				res = yield apiClient.post(
					API.FETCH_AEM_INFORMATION_URL + resource,
					requestBody
				);
			}
			yield put(
				AEMContentSlice.setTimestamp({ resourceName: resource, status: Date.now() })
			);
			yield put(
				AEMContentSlice.setResource({
					resourceName: resource,
					data: res?.data?.data,
				})
			);
		}
	} catch (err) {
		yield put(
			AEMContentSlice.setResource({ resourceName: resource, data: null })
		);
		console.error(err);
		aemResourceAlreadyFetched[resource] = false;
	}
}

function* getAEMContentResources(params) {
	const { flow, resourceNames } = params.payload;
	try {
		const forks = [];
		for (const resource of resourceNames) {
			forks.push(yield fork(getAEMContentResource, flow, resource));
		}
		yield join([...forks]);
	} catch (err) {
		console.error(err);
	}
}

function* postClearCart(params) {
	yield put(
		CheckoutSlice.setIsShoppingCartApiCallInProgress({
			apiName: "getFetchCart5G",
			status: true,
		})
	);
	const pageData = yield select(selectors.pageData);
	const lqData = yield select(selectors.lqData);
	const flow = pageData?.unifiedCartParams?.flow ?? lqData?.flow;
	const isDSP = !!pageData?.fetchCart?.dspData;
	let response;
	try {
		const requestBody = {
			shoppingPath: "PROSPECT",
			approach: "",
			flow: flow,
		};
		if ((flow == "4g" || flow == "5g") && !isDSP) {
			response = yield apiClient.post(API.CLEAR_4G5G_CART_URL, requestBody);
		} else {
			response = yield apiClient.post(API.UPDATE_CLEAR_CART, requestBody);
		}
		// yield put(ReviewSlice.setProspectCartPDF(response.data));
		yield put(CheckoutSlice.setCleartCartDetails(response));
		yield put(GlobalSlice.setSessionCartData(""));
		// end live API
		if (params?.payload?.routeToDevices) {
			params?.payload?.routeToDevices();
		}
	} catch (err) {
		// error handling
		yield put(CheckoutSlice.setCartApiError(true));
	} finally {
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "getFetchCart5G",
				status: false,
			})
		);
	}
}

function* updatePackages(params) {
	try {
		yield put(CheckoutSlice.setUpdatedPackages(params?.payload));
	} catch (err) {
		// error handling
	}
}
function* checkAddressValidation(params) {
	yield put(
		CheckoutSlice.setIsShoppingCartApiCallInProgress({
			apiName: "postBusinessInformation",
			status: true,
		})
	);
	try {
		const data = params.payload;
		let requestBody = {
			address: {
				addressLine1: data?.addressLine1,
				city: data?.city,
				state: data?.state,
				zipCode: data?.zipCode,
				addressLine2: data?.addressLine2,
			},
		};
		const response = yield apiClient.post(
			API.ADDRESS_VALIDATION_URL,
			requestBody
		);
		yield put(CheckoutSlice.setAddressValidationInfo(response.data));
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "postBusinessInformation",
				status: false,
			})
		);
	} catch (err) {
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "postBusinessInformation",
				status: false,
			})
		);
	}
}

function* getValidateUserId(params) {
	const requestBody = params.payload;

	try {
		const response = yield apiClient.post(
			API.ACCOUNT_CREATION_USERID_URL,
			requestBody
		);
		if (response?.data?.success) {
			yield put(ReviewSlice.setAccountUserIDRes({ status: true }));
		} else {
			yield put(ReviewSlice.setAccountUserIDRes({ status: false }));
		}
	} catch (err) {
		yield put(ReviewSlice.setAccountUserIDRes({ status: false }));
	}
}

function* getValidateAccountPassword(params) {
	const requestBody = params.payload;
	try {
		const response = yield apiClient.post(
			API.ACCOUNT_CREATION_PASS_URL,
			requestBody
		);
		if (response?.data?.statusCode == 200) {
			yield put(ReviewSlice.setAccountPasswordRes({ status: true }));
		} else {
			yield put(ReviewSlice.setAccountPasswordRes({ status: false }));
		}
	} catch (err) {
		yield put(ReviewSlice.setAccountPasswordRes({ status: false }));
	}
}
function* getSecurityQuestions() {
	// const requestBody = {}

	try {
		const response = yield apiClient.get(API.ACCOUNT_CREATION_SECURITYQUE_URL);
		//change condition here once have api working
		if (response?.status == 200) {
			yield put(ReviewSlice.setAccountSecurityQueRes(response.data));
		} else {
			yield put(ReviewSlice.setAccountSecurityQueRes({ status: false }));
		}
	} catch (err) {
		yield put(ReviewSlice.setAccountSecurityQueRes({ status: false }));
	}
}

function* sendAccountCreationReq(params) {
	const requestBody = params.payload;

	try {
		const response = yield apiClient.post(
			API.ACCOUNT_CREATION_REGISTER_URL,
			requestBody
		);

		if (
			response?.data?.serviceStatus?.statusCode == 200 &&
			!response?.data?.validationErrors?.length
		) {
			yield put(ReviewSlice.setRegisterRes({ userCreated: true }));
		} else {
			yield put(ReviewSlice.setRegisterRes({ userCreated: false }));
		}
	} catch (err) {
		yield put(ReviewSlice.setRegisterRes({ userCreated: false }));
	}
}

function* saveAuthorizeResponse(params) {
	let authorize5GReceiver = "";
	let authorizePropertyManager = "";
	if (params.payload.isCheck === "auth-yes") {
		authorize5GReceiver = "Y";
	} else if (
		params.payload.isCheck === "auth-no" &&
		params.payload.isPermission === "permission-yes"
	) {
		(authorize5GReceiver = "N"), (authorizePropertyManager = "Y");
	}
	try {
		const requestBody = {
			digitalQuote: false,
			shoppingPath: "PROSPECT",
			approach: "",
			flow: "5G",
			authorize5GReceiver,
			authorizePropertyManager,
		};
		yield apiClient.post(API.SAVE_AUTHORIZE_INFO_URL, requestBody);
	} catch (err) {
		// error handling
	}
}

function* logSuspendData(params) {
	const requestBody = params.payload;

	try {
		const response = yield apiClient.post(API.LOG_SUSPEND_DATA, requestBody);
	} catch (err) {
		// error handling
	}
}
function* getBillingInformation(params) {
	try {
		const { businessInfo } = params
		const billingInformation = {
			...businessInfo.address,
			bussinessName: businessInfo.bussinessName,
			phoneNumber: businessInfo.phoneNumber,
			firstName: businessInfo?.firstName,
			lastName: businessInfo?.lastName,
			accountNumber: businessInfo?.shellAccountNumber
		}
		const serviceInformation = {
			...businessInfo.billingAddress,
			firstName: businessInfo?.firstName,
			lastName: businessInfo?.lastName,
			phoneNumber: businessInfo.phoneNumber,
			domain: businessInfo.domain,
			email: businessInfo.email,
			bussinessName: businessInfo.bussinessName,
		}
		const businessInfoAddress = {
			businessStreetAddress: {
				place: {
					properties: {
						...businessInfo.billingAddress,
						stateCode: businessInfo.billingAddress.state,
						postalCode: businessInfo.billingAddress.zipCode
					}
				}
			}
		}
		yield put(CheckoutSlice.setAboutBusinessInfor(businessInfoAddress))
		yield put(CheckoutSlice.setBillingInformation(billingInformation))
		yield put(CheckoutSlice.setServiceInformation(serviceInformation))
	} catch (e) {
		console.log(e)
	} finally {

	}
}
function* loadAccounts(params) {
	try {
		const requestBody = params.payload
		const response = yield apiClient.post(API.MARKETPLACE_LOAD_ACCOUNTS, requestBody);
		if (response?.status === 200 && !!response?.data) {
			yield put(CheckoutSlice.setLoadBussAccountDetails(response?.data))
		}
	} catch (e) {
		console.log(e)
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "postBusinessInformation",
				status: false,
			})
		);
	} finally {
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "postBusinessInformation",
				status: false,
			})
		);
	}
}

function* getSalesRepDetails() {
	try {
		const response = yield apiClient.post(API.GET_SALES_REP_DETAILS, {});
		if (response?.status === 200) {
			yield put(CheckoutSlice.setSalesRepDetails(response?.data))
		}
	} catch (e) {
		console.log(e)
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "postBusinessInformation",
				status: false,
			})
		);
	} finally {
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "postBusinessInformation",
				status: false,
			})
		);
	}
}

function* initializeCheckout(params) {
	try {
		const response = yield apiClient.post(API.INITIALIZE_CHECKOUT, {});
		if (response?.status === 200) {
			yield put(CheckoutSlice.setInitializeCheckoutData(response?.data))
		}
	} catch (e) {
		console.log(e)
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "postBusinessInformation",
				status: false,
			})
		);
	} finally {
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "postBusinessInformation",
				status: false,
			})
		);
	}
}

function* sendSelectedAccount(params) {
	try {
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "getSessionCart",
				status: true,
			})
		);
		const requestBody = params.payload
		const response = yield apiClient.post(API.MARKETPLACE_SEND_ACCOUNT, requestBody);
		if (response?.status === 200 && !!response?.data && (response?.data?.status?.success || response?.data?.response?.businessInfo?.bussinessName)) {
			yield put(CheckoutSlice.setSelectedAccountInfo(response?.data))
			yield put(CheckoutSlice.setStoredAccountNumber(true))
			yield fork(getSalesRepDetails)
			yield fork(initializeCheckout)
			yield fork(getSessionCartInfo)
		}else yield put(CheckoutSlice.setStoredAccountNumber(false))
	} catch (e) {
		yield put(CheckoutSlice.setStoredAccountNumber(false))
		console.log(e)
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "getSessionCart",
				status: false,
			})
		);
	} finally {
		yield put(
			CheckoutSlice.setIsShoppingCartApiCallInProgress({
				apiName: "getSessionCart",
				status: false,
			})
		);
	}
}


export default function* actionWatcher() {
	yield takeLatest(CheckoutSlice.getBillingInformation.type, getBillingInformation)
	yield takeLatest(CheckoutSlice.getStreetAddress.type, getStreetAddressInfo);

	yield takeLatest(CheckoutSlice.getSessionCart.type, getSessionCartInfo);
	yield takeLatest(
		CheckoutSlice.getRetrieveCreditCart.type,
		getRetrieveCreditCart
	);
	yield takeLatest(CheckoutSlice.saveCreditHoldCart.type, saveCreditHoldCart);
	yield takeLatest(CheckoutSlice.getShippingMethods.type, getShippingMethods);
	yield takeLatest(CheckoutSlice.getInstallation.type, getInstallation);
	yield takeLatest(CheckoutSlice.postInstallation.type, postInstallation);
	yield takeLatest(CheckoutSlice.getMAADocUrl.type, getMAADocUrl);
	yield takeLatest(CheckoutSlice.getTNCDetails.type, getTNCDetails);
	yield takeLatest(CheckoutSlice.getBussTNCDetails.type, getBussTNCDetails);
	yield takeLatest(CheckoutSlice.acceptBussTnc.type, acceptBussTnc);
	yield takeLatest(
		CheckoutSlice.getOneTalkEnrollment.type,
		getOneTalkEnrollment
	);
	yield takeLatest(CheckoutSlice.resumeEcpdDocs.type, resumeEcpdDocs);
	yield takeLatest(CheckoutSlice.postShippingMethod.type, postShippingMethod);
	yield takeLatest(CheckoutSlice.getStoreData.type, getStoreData);
	yield takeLatest(
		CheckoutSlice.postBusinessInformation.type,
		postBusinessInformation
	);
	yield takeLatest(
		CheckoutSlice.saveBusinessInformation.type,
		saveBusinessInformation
	);
	yield takeLatest(
		CheckoutSlice.validateCreditDetails.type,
		validateCreditDetails
	);
	yield takeLatest(CheckoutSlice.postOtpPhoneNumber.type, postOtpPhoneNumber);
	yield takeLatest(CheckoutSlice.postOtpSendCode.type, postOtpSendCode);
	yield takeLatest(
		CheckoutSlice.postOtpValidatePasscode.type,
		postOtpValidatePasscode
	);
	yield takeLatest(CheckoutSlice.postNpaNxxNpaNxx.type, postNpaNxxNpaNxx);
	yield takeLatest(
		CheckoutSlice.postNpaNxxPortInEligibility.type,
		postNpaNxxPortInEligibility
	);
	yield takeLatest(
		CheckoutSlice.postNpaNxxReleaseMdn.type,
		postNpaNxxReleaseMdn
	);
	yield takeLatest(
		CheckoutSlice.postNpaNxxRetrieveMdn.type,
		postNpaNxxRetrieveMdn
	);
	yield takeLatest(
		CheckoutSlice.postNpaNxxRetrieveNpaNxx.type,
		postNpaNxxRetrieveNpaNxx
	);
	yield takeLatest(
		CheckoutSlice.postNpaNxxUpdateNickname.type,
		postNpaNxxUpdateNickname
	);
	yield takeLatest(
		ReviewSlice.postCheckCartInventoryStatus.type,
		postCheckCartInventoryStatus
	);
	yield takeLatest(
		ReviewSlice.postProcessEcpdDetails.type,
		postProcessEcpdDetails
	);
	yield takeLatest(
		ReviewSlice.postUpdateInstallmentContractDetails.type,
		postUpdateInstallmentContractDetails
	);
	yield takeLatest(ReviewSlice.getProspectCartPDF.type, getProspectCartPDF);
	yield takeLatest(ReviewSlice.getValidateUserId.type, getValidateUserId);
	yield takeLatest(
		ReviewSlice.getValidateAccountPassword.type,
		getValidateAccountPassword
	);
	yield takeLatest(ReviewSlice.getSecurityQuestions.type, getSecurityQuestions);
	yield takeLatest(
		ReviewSlice.sendAccountCreationReq.type,
		sendAccountCreationReq
	);
	yield takeLatest(PaymentSlice.postCompleteOrder.type, postCompleteOrder);
	yield takeLatest(
		PaymentSlice.postInitializePayment.type,
		postInitializePayment
	);
	yield takeLatest(
		PaymentSlice.postValidateAndSaveCard.type,
		postValidateAndSaveCard
	);
	yield takeLatest(
		PaymentSlice.doUpdatePymtCounterFail.type,
		doUpdatePymtCounterFail
	);
	yield takeLatest(
		PaymentSlice.updateBussCheckoutPrice.type,
		updateBussCheckoutPrice
	);
	yield takeLatest(CheckoutSlice.storeTermsConditons.type, storeTermsConditons);
	yield takeLatest(
		CheckoutSlice.storeTermsConditonsReview.type,
		storeTermsConditonsReview
	);
	yield takeLatest(CheckoutSlice.validateE911Address.type, validateE911Address);
	yield takeLatest(
		CheckoutSlice.postSharedCartSaveUrl.type,
		postSharedCartSaveUrl
	);
	yield takeLatest(
		CheckoutSlice.getPersonalGuranteeDoc.type,
		getPersonalGuranteeDoc
	);
	yield takeLatest(
		CheckoutSlice.mitekCheckVerificationResult.type,
		mitekCheckVerificationResult
	);
	yield takeLatest(
		CheckoutSlice.maaTncCheckVerificationResult.type,
		maaTncCheckVerificationResult
	);
	yield takeLatest(
		CheckoutSlice.updateDeclinedStatus.type,
		updateDeclinedStatus
	);
	yield takeLatest(
		CheckoutSlice.mitekSMSVerification.type,
		mitekSMSVerification
	);
	yield takeLatest(
		CheckoutSlice.sendMitekVerification.type,
		sendMitekVerification
	);
	yield takeLatest(
		PaymentSlice.sendPaymentLink.type,
		sendPaymentLink
	);
	yield takeLatest(
		PaymentSlice.recieveClientPaymentDetails.type,
		recieveClientPaymentDetails
	);
	yield takeLatest(
		CheckoutSlice.generateMitekVerificationLink.type,
		generateMitekVerificationLink
	);
	yield takeLatest(CheckoutSlice.validateSSN.type, validateSSN);
	yield takeLatest(CheckoutSlice.deleteDirectory.type, deleteDirectory);
	yield takeLatest(CheckoutSlice.uploadFile.type, uploadFile);
	yield takeLatest(CheckoutSlice.readFile.type, readFile);
	yield takeLatest(
		AEMContentSlice.getAEMContentResources.type,
		getAEMContentResources
	);
	yield takeLatest(CheckoutSlice.postClearCart.type, postClearCart);
	yield takeLatest(CheckoutSlice.updatePackages.type, updatePackages);
	yield takeLatest(
		CheckoutSlice.checkAddressValidation.type,
		checkAddressValidation
	);
	yield takeLatest(
		CheckoutSlice.skipBizValidateCreditDetails.type,
		validateCreditDetails
	);
	yield takeLatest(
		CheckoutSlice.saveAuthorizeResponse.type,
		saveAuthorizeResponse
	);
	yield takeLatest(CheckoutSlice.logSuspendData.type, logSuspendData);
	yield takeLatest(CheckoutSlice.loadAccountsForBussProducts.type, loadAccounts);
	yield takeLatest(CheckoutSlice.sendSelectedAccount.type, sendSelectedAccount);
}
