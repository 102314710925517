import CustomEvent from 'custom-event';
import compact from 'lodash/compact';
import {PageInfo} from './dataObjects';
import * as SiteAnalytics from "./index";

export function getPageNameOld() {
    try {
        let pathUrl = window.location.pathname.split('/').filter(item => item), path;
        if ((pathUrl.indexOf("devices") !== -1) && (pathUrl.indexOf("shop") < 0)) {
            pathUrl = pathUrl.slice(0, pathUrl.length);
        }
        pathUrl = pathUrl.filter(item => item !== 'unified-checkout');
        pathUrl[pathUrl.length -1] = getFriendlyPageName(pathUrl.join(''));
        let pageName = 'vbg:' + pathUrl.join(':');
        return pageName;
    } catch(err) {
        console.log(err)
    }
}
export function getPageName() {
    try {
        const pagePrefix = 'vbg';
        let pathname = window.location.pathname;
        pathname = compact(pathname.split("/")).join(":");
        return `${pagePrefix}:${pathname}`;
    } catch(err) {
        console.log(err)
    }
}

export function nullCheck(value) {
    return (value ? value : 'undefined');
}

export function setPageInfo(pageInfoTemplate, pageName) {
    try {
        const pageInfo = {};
        const sections = window.location.pathname.split('/').filter(item => item).slice(0, window.location.pathname.split('/').length);
        // const pageRef = getFriendlyNameInfo();
        pageInfo.siteDomain = window.location.hostname;
        pageInfo.siteName = window.location.hostname + window.location.pathname;
        pageInfo.siteLocale = "en";
        pageInfo.languageSelected = "en";
        pageInfo.country ="us"; //Details about this object are in next few pages
        pageInfo.region = "na"; //Details about this object are in next few pages
        pageInfo.url = window.location.href;
        //pageInfo.flowName = 'Prospect Unified';
        pageInfo.flowName = 'Prospect Wirless:'+ window.sessionStorage.getItem('_flowName');

        pageInfo.pageName = getPageName();
        pageInfo.siteSectionsComplete= sections.join("-");
        pageInfo.siteSectionsLevel1 = nullCheck(sections[0]);
        pageInfo.siteSectionsLevel2 = nullCheck(sections[1]);
        pageInfo.siteSectionsLevel3 = nullCheck(sections[2]);
        pageInfo.siteSectionsLevel4 = nullCheck(sections[3]);
        pageInfo.siteSectionsLevel5 = nullCheck(sections[4]);
        pageInfo.siteSectionsLevel6 = nullCheck(sections[5]);
        pageInfo.pendingOrder = ""; //sample value (Yes or No based on customer response on gborder page) 
        pageInfo.businessUnit= "smb"; // Sample value (SMB) (If all new fios pages are under SMB then use SMB as default value)
        pageInfo.siteCategory = "Prospect Business Internet" // sample value (FIOS Business Internet)(Check with devs and archs)
         
        pageInfo.friendlyPageName = '';// example (check availability, out-of-franchise, customize-order etc.,)
        pageInfo.componentName = "";//Name of the component (refer to Tagging strategy component name field) 
        pageInfo.pageType = '';
        pageInfo.isReact = true;
        pageInfo.isAngular = false;
        pageInfo.previousPageURL = window?.digitalData?.pageInfo && window?.digitalData?.pageInfo?.url ? window?.digitalData?.pageInfo?.url : '';
        pageInfo.brand = '';
        if (window?.localStorage?.getItem("automationFlowType") === 'BLENDED') {
            pageInfo.businessCredit = 'Social Security Number';
        } else if(window?.localStorage?.getItem("automationFlowType") === 'BizOnlyCredit') {
            pageInfo.businessCredit = 'Federal Tax ID EIN';
        }
        window['digitalData'] && (window['digitalData'].pageInfo = pageInfo);
        return pageInfo;
    } catch(err) {
        console.log(err)
    }
}

export function updateDataLayer(data) {
   // setPageInfo();
    //const pageInfo = setPageInfo(PageInfo);
       // window.digitalData.pageInfo =  pageInfo;
    // const event = new CustomEvent("Virtual Page View", {
    //     details: data,
    //     bubbles: true,
    //     cancelable: true
    // });
    // window.dispatchEvent(event);
    SiteAnalytics.trackPageInfo();
    //window['digitalData'] && window['digitalData'].events.push({eventName: "Virtual Page View", eventPayload:pageInfo});
    try {
        if (typeof window['lpTag'] != 'undefined' && lpTag?.section) {
          lpTag.section = manipulateLpTagSections();
        }
      } catch (e) {
        console.error(e);
      }
}
 
export function getFriendlyPageName(path) {
    let name = '';
    if(path.indexOf('qualification') > -1) {
        name="qualification";
    }
    if(path.indexOf('shopping-cart') > -1) {
        name="shopping-cart";
    }
    if(path.indexOf('verifyBusiness') > -1) {
        name="business-info"
    }
    if(path.indexOf('credit-result') > -1) {
        name="credit-result"
    }
    if(path.indexOf('ReviewTAC') > -1) {
        name="terms"
    }
    if(path.indexOf('docusign') > -1) {
        name="agreement"
    }
    if(path.indexOf('contact-us') > -1) {
        name="contact-us"
    }
    if(path.indexOf('npaNxx') > -1) {
        name="generate-mtns"
    }
    if(path.indexOf('emergency') > -1) {
        name="emergency-address"
    }
    if(path.indexOf('shippingMethod') > -1) {
        name="shipping-info"
    }
    if(path.indexOf('review') > -1) {
        name="review-order"
    }
    if(path.indexOf('payment') > -1) {
        name="payment"
    }
    if(path.indexOf('confirmation') > -1) {
        name="thank-you"
    }
    if(path.indexOf('accessories') > -1) {
        name="accessories"
    }
    if(path.indexOf('business-credit') > -1) {
        name="business-credit"
    }
    if(path.indexOf('verify-credit-score') > -1) {
        name="verify-credit-score"
    }
    if(path.indexOf('upload-documents') > -1) {
        name="upload-documents"
    }
    if(path.indexOf('profile-creation') > -1) {
        name="profile-creation"
    }
    if(path.indexOf('schedule-installation') > -1) {
        name="schedule-installation"
    }
    if(path.indexOf('credit-choice') > -1) {
        name="credit-choice"
    }
    if(path.indexOf('landing4G') > -1) {
        name="4g-plans"
    }
    if(path.indexOf('landing5G') > -1) {
        name="5g-plans"
    }
    if(path.indexOf('verifyDocuments') > -1) {
        name="upload-documents"
    }
    if(path.indexOf('checkCreditOptions') > -1) {
        name="checkCreditOptions"
    }
    return name || path;
}