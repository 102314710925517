import React from "react";
import { Loader } from "@vds3/loaders";

const SpinnerComponent = ({fullscreen = true}) => {
  return (
    <div className="loading-spinner">
      <Loader active fullscreen={fullscreen} disableFocusLock />
    </div>
  );
};

export default SpinnerComponent;
