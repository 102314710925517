/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useReducer,
} from "react";
import { Micro } from "@vds3/typography";
import {
  SuggestionListDiv,
  StreetInputDiv,
  StreetInput,
} from "../containers/styledComponents";
import { useDispatch, useSelector } from "react-redux";
import { getStreetAddress } from "../../../../../pages/Checkout/ducks/CheckoutSlice";
import { setAPIError } from "../../../../../pages/Mobility/ducks/MobilitySlice";
import { MarginSpacerS } from "common/components/styleTag";
// import { reducer } from '../../../ducks/CheckoutSlice'

const MapQStreetAddress = forwardRef((props, ref) => {
  // const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const defaultValue = props?.placeHolder ?? "";
  const [userInput, setUserInput] = useState(props?.isVerifyBussiness ? "" : defaultValue);
  const [isDirty, setIsDirty] = useState(false);
  const [outSideClick, setOutsideClick] = useState(true);
  // const suggestionList = useFetchStreetAddr(userInput)
  const [isSuccess, setIsSuccess] = useState(false);
  const [streetAdd, setIsStreetAdd] = useState(null);
  const inputRef = useRef();
  const dispatch = useDispatch();
  const pageData = useSelector((state) => state.ProspectWidgets_CheckoutData);

  useEffect(()=> {
    if(props?.addressLineOne && (props?.isVerifyBussiness || props?.pdp)) {
      setUserInput(props?.addressLineOne || "");
      setIsSuccess(!!props?.addressLineOne || false);
    } else if(props?.addressLineOne && props?.isMultiShipping && !props?.isVerifyBussiness){
      setUserInput(props?.addressLineOne || "");
      // setIsSuccess(!!props?.addressLineOne || false);
    } else if(props?.isFromPayment) {
      setUserInput(props?.addressLineOne || "");
    } else if(!props?.fromNpaNxx && (!props?.pdp || props.emptyAddress)){
      setUserInput(defaultValue);
    } else if(props?.fromNpaNxx){
      setUserInput(props?.value);
      setIsSuccess(!!props?.value || false);
    }
  },[props?.addressLineOne]);

  const onChangeHandler = (e) => {
    const value = e.target.value;
    props?.fromShoppingCart && dispatch(setAPIError(false));
    if(!value) props.setAddressFieldError(true);
    if (value.length > 1) {
      dispatch(getStreetAddress(value));
    }
    if(!!props?.handleAddressInput && typeof props?.handleAddressInput == 'function' && value ) props?.handleAddressInput(e, "street")
    setIsDirty(true);
    setUserInput(value);
    setOutsideClick(false);
    if((props?.isAddressEmty || props.isNewAddress) && streetAdd !==null){
      props.onSelectionChange(null) 
      setIsStreetAdd(null)
    }
    setIsSuccess(false);
    matchStreetAddress(value);
    if (!value && userInput !== defaultValue && !props?.isVerifyBussiness && !props?.isMultiShipping && !props?.addressLineOne && !props?.pdp && !props?.isFromPayment && !props?.isEmergencyAdd) {
      setUserInput(defaultValue);
      props.setAddressFieldError(true);
      setOutsideClick(true);              
    }
  };
  const selectThisStreetAddress = (selectedStreetAddress) => {
    const selection = props.isNameOnly
      ? selectedStreetAddress.name
      : selectedStreetAddress.displayString;
    setOutsideClick(true);
    setUserInput(selection);
    // selectedStreetAddress.displayString = selectedStreetAddress.displayString
    setIsStreetAdd(selectedStreetAddress)
    props.onSelectionChange(selectedStreetAddress);
    props.setAddressFieldError(false)
    setIsSuccess(!!selectedStreetAddress);
  };

  useEffect(() => {
    if (userInput) matchStreetAddress(userInput);
  }, [pageData.addressSuggestionList]);

  const matchStreetAddress = (userInput) => {
    if (pageData.addressSuggestionList && userInput) {
      pageData.addressSuggestionList.map((value) => {
        if (formatString(value.displayString) === formatString(userInput)) {
          setOutsideClick(true);
          if (value.ntasAddrID) {
            value.baseAddressId = value.ntasAddrID;
          }
          setIsStreetAdd(value)
                    props.onSelectionChange(value);
          setIsSuccess(true);
        }
      });
    }
  };

  const formatString = (str) => {
    return str
      ? str.replace(/\s/g, "").replace(/,/g, "").toLowerCase().trim()
      : "";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  window.addEventListener("click", function (e) {
		if (
			document?.getElementById("addressInput")?.contains(e?.target) ||
			document?.getElementById("addressListSection")?.contains(e?.target)
		) {
			// Clicked in box
		} else {
			// Clicked outside the box
			setOutsideClick(true);
		}
	});

  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
      key={"lq-widget-street-Address" + props.isError}
    >
      <div
        className="Street-Address"
        key={"lq-widget-street-Address" + props.isError}
        ref={ref}
      >
        <StreetInputDiv
          key={"lq-widget-street-Address" + props.isError}
          ref={inputRef}
        >
          <div id="addressInput" className={userInput === defaultValue ? "placholder-text" : ""}>
          <StreetInput         
            key={"lq-widget-street-Address" + props.isError}
            label={props.label}
            onChange={onChangeHandler}
            onBlur={(e) => {
              const targetValue = e?.target?.value;
              props?.isAddressEmty &&
              setTimeout(() => {
                setOutsideClick(true) 
              },1000) 
              if (!targetValue && !props?.isVerifyBussiness) {
                setUserInput(defaultValue);
                props.setAddressFieldError(true);
              } else if(!targetValue){
                props.setAddressFieldError(true);
              }
            }}
            onFocus={(e) => {
              if(userInput === defaultValue){
                setUserInput("");
               }
              if(!props?.isVerifyBussiness || props?.isShippingInfo){
              props.setAddressFieldError(false);
              }
              if(props?.isAddressEmty) setOutsideClick(false)
              setTimeout(() => {
                if (
                  inputRef?.current &&
                  inputRef.current.getElementsByTagName
                ) {
                  inputRef.current.getElementsByTagName("input")[0].focus();
                }
              });
            }}
            value={userInput}
            name="lq-widget-street-Address"
            width="100%"
            errorEvent={"change"}
            error={props.isError}
            errorText={props.isError ? props.errorMessage : ""}
            autoFocus={props.setAutoFocus}
            id="lq-widget-street-Address"
            helperText=""
            success={false}
            tooltipContent={!props?.fromShoppingCart && props.label === 'Address line 1*' ?
            "Current Business Address based on Tax ID (EIN)." : props?.tooltipContent }
          />
          </div>
          {userInput && userInput !== defaultValue && (
            <AddressSuggestionList
              streetAddressSuggestionList={pageData.addressSuggestionList}
              outSideClick={outSideClick}
              selectThisStreetAddress={selectThisStreetAddress}
            />
          )}
          {!props?.fromShoppingCart && props?.isVerifyBussiness ? <div className="helper-text">
            <Micro color="#6F7171" size="small">
              {props.helperText ? props.helperText : props.isMultiShipping ? "" : ""}
            </Micro>
          </div> : <></>}
          { props?.isEmergencyAdd ? <div className="helper-text">
            <Micro size="small">
              <MarginSpacerS/>
              {props.helperText ? props.helperText :  ""}
            </Micro>
          </div> : <></>}
        </StreetInputDiv>
      </div>
    </form>
  );
});

const AddressSuggestionList = ({
  streetAddressSuggestionList,
  outSideClick,
  selectThisStreetAddress,
}) => {
  return (
    <>
      {!outSideClick && streetAddressSuggestionList ? (
        <SuggestionListDiv>
          {Object.keys(streetAddressSuggestionList).map((key, index) => {
            return (
              <div
                className="suggestion-list-item"
                key={index}
                id="addressListSection"
              >
                <span
                  // key={index}
                  key={streetAddressSuggestionList[key].ntasAddrID}
                  onClick={() =>
                    selectThisStreetAddress(streetAddressSuggestionList[key])
                  }
                >
                  {streetAddressSuggestionList[key].displayString}
                </span>
              </div>
            );
          })}
        </SuggestionListDiv>
      ) : (
        ""
      )}
    </>
  );
};
export default MapQStreetAddress;
