import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Notification } from "@vds3/notifications";
import { TileContainer } from "@vds3/tiles";
import CartContext from "pages/ShoppingCart/cartContext";
import { CartPkgContextProvider } from "./cartPkgContext";
import PackagePlan from "./PackagePlan";
import PackageInfo from "./PackageInfo";
import PackageFeatures from "./PackageFeatures";
import PackageOffers from "./PackageOffers";
import TradeInTile from "./TradeInTile";
import {
	StyledTileContainer,
	TileDiv,
	RightSection,
	SeparatorLine,
} from "./styles";
import { setShowSimCompatible } from "app/ducks/GlobalSlice";
import { LicenseType, ProfessionalService } from "./LicenseAndProfessionType";
import { MarginSpacer2XL } from "common/components/styleTag";

const UPPER_FUNNEL_ENABLED = ["FOURG", "FIVEG"];
const SUPPRESS_DPP = ["FOURG", "FIVEG", "IOT"];

const PackageTile = ({
	pack,
	isEditable,
	showRightSection = true,
	starkEnabled = false,
	...props
}) => {
	const { t } = useTranslation(["shoppingCart", "app", "checkout"]);
	const {
		accessoriesCount,
		allowedAccessoriesCount,
		cartData,
		onEditDeviceClick,
		onEditPlanClick,
		onEditFeatureClick,
		onRemoveSecurityAddOnClick,
		onRemoveClick,
		onUpdateClick,
		onUpdateDspClick,
		onUpdateRegularQty,
		isDSP,
		cartProperties,
		onOwnSimSelection,
		setSelectedImei,
		setbyodPack,
		isDigitalQuote,
		isMobile,
		showSimCompatible,
		isOneTalkFlow,
		oneTalkFlowAdded,
	} = React.useContext(CartContext);
	const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
	const [plansForBuss,setPlansForBuss] = React.useState(undefined)
	const dispatch = useDispatch();
	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	const isMobilityEdit =
		(pack?.packageType === "REGULAR" ||
			(pack?.accessoryId !== null && pack?.accessoryId)) &&
		!pack?.byod &&
		window?.mbtGlobal?.flags?.ENABLE_VDS3_SHOPPINGCART_MOBILITY_EDIT === "Y"
			? true
			: false;
	const isOneTalkEdit =
		(pack?.packageType === "ONETALK" ||
			(pack?.accessoryId !== null && pack?.accessoryId)) &&
		!pack?.byod &&
		window?.mbtGlobal?.flags?.ENABLE_VDS3_SHOPPINGCART_MOBILITY_EDIT === "Y"
			? true
			: false;
	const enableMobilityRemove =
		window?.mbtGlobal?.flags?.ENABLE_VDS3_SHOPPINGCART_MOBILITY_EDIT === "Y"
			? true
			: false;
	const isByodEditable =
		pack?.byod ||
		(pack?.psimCapable === "Y" && (!isDigitalQuote || enableMobilityRemove));
	const isUpperFunnel =
		UPPER_FUNNEL_ENABLED.includes(pack?.packageType) ||
		isByodEditable ||
		isMobilityEdit;
	//5g flow Bulk quantity via DQ doesn't allow edit
	const is5GMultiPlan =
		Number(pack?.quantity) > 1 && pack?.packageType === "FIVEG";
	const disabledQty =
		isDSP ||
		pack?.packageType === "FOURG" ||
		(isMobilityEdit && !pack?.outOfStock) ||
		(isOneTalkEdit && !pack?.outOfStock) ||
		(!(oneTalkFlowAdded && pack.packageType === "ONETALK") &&
			pack?.packageType !== "FIVEG");
	let isAmexOfferAvailable, promoAmex;
	const isOfferAvailable = (startDateTime, endDateTime, dateFormat = "UTC") => {
		let startDate = new Date(`${startDateTime}  ${dateFormat}`);
		let endDate = new Date(`${endDateTime}  ${dateFormat}`);
		let currentDate = new Date();
		return (
			startDate.getTime() < currentDate.getTime() &&
			currentDate.getTime() < endDate.getTime()
		);
	};
	if (
		sessionCartData?.offerCmpCode === "olv:amex:ac:vbg:mkt_part:wls_2q23_wls"
	) {
		const aemContent = t("partners-reward", {
			ns: "checkout",
			returnObjects: true,
		});
		promoAmex = aemContent[sessionCartData?.offerCmpCode]?.cartPromotions[0];
		isAmexOfferAvailable =
			isOfferAvailable(promoAmex?.startDateTime, promoAmex?.endDateTime) &&
			promoAmex?.message !== "";
	}
	const equipment = useMemo(() => {
		const equip = [];
		if (pack?.deviceType === "FIVEGRECEIVER" && cartData?.cart?.accessories) {
			const accessories = cartData.cart.accessories;
			for (const key in accessories) {
				if (!accessories[key]?.hideOnUI && accessories[key]?.router) {
					equip.push(accessories[key]);
				}
			}
		}
		return equip;
	}, [pack, cartData]);

	const suppressDPP = SUPPRESS_DPP.includes(pack?.packageType);
	const features = useMemo(() => {
		const featureList = [];
		if (pack?.features) {
			for (const f of Object.values(pack.features)) {
				if (
					f?.level === "L" &&
					f?.displaySection !== "Plans" &&
					f?.displaySection !== "CartPromotion"
				) {
					if (suppressDPP && f?.featureDesc === "Decline Device Protection") {
						continue;
					} else {
						featureList.push(f);
					}
				}
			}
		}
		switch (pack?.packageType) {
			case "FIVEG":
				featureList.push({
					featureDesc: t("5g.shopping-cart.plan.dynamicIP"),
				});
				featureList.push({
					featureDesc: t("5g.shopping-cart.plan.activationFee"),
				});
				break;
			case "FOURG":
				featureList.push({
					featureDesc: t("4g.shopping-cart.plan.dynamicIP"),
				});
				featureList.push({
					featureDesc: t("4g.shopping-cart.plan.activationFee"),
				});
				break;
			default:
		}
		return featureList;
	}, [pack]);

	const offers = useMemo(() => {
		const offerList = [];
		if (pack?.plan?.offerApplied?.length) {
			pack?.plan?.offerApplied?.map((offer, index) => {
				let appliedOffers = {
					offerId: pack?.plan?.pricePlanCode + index,
					offerName: offer,
				};
				offerList.push(appliedOffers);
			});
		}
		if (pack?.offers?.sbdOffer?.length) {
			let sbd = null;
			for (const offer of Object.values(pack.offers.sbdOffer)) {
				if (offer?.bicNoOfOccurences === 1) {
					if (!sbd) {
						sbd = offer;
					}
				} else {
					offerList.push(offer);
				}
				if (sbd) offerList.push(sbd);
			}
		}
		if (pack?.offers?.sedOffer) offerList.push(pack.offers.sedOffer);
		if (equipment?.length) {
			for (const equip of equipment) {
				if (equip?.offers) {
					for (const offer of Object.values(equip.offers)) {
						offerList.push(offer);
					}
				}
			}
		}
		const jsonObject = offerList.map(JSON.stringify);
		const uniqueSet = new Set(jsonObject);
		const uniqueOfferList = Array.from(uniqueSet).map(JSON.parse);
		return uniqueOfferList;
	}, [pack, equipment]);
	React.useEffect(()=>{
		if(pack.buss){
			setPlansForBuss({
				"planPrice": pack.itemPrice,
				"planPreviousPrice": pack.itemPrice,
				"planDiscountAmount": 0,
				"pricePlanCode": "67793",
				"pricePlanDesc": pack.description,
				"sharePlanLACAmount": 0,
				"pricePlanCatCode": "82",
				"offerApplied": [],
				"autoPayPromotion": {},
				"primaryCategory": "buePlans",
				"bgsa": "668",
				"sharedPlan": false,
				"planOriginalPrice": 0
			})
		}
	},[pack])
	const updateQuantity = (quantity) => {
		if (isDSP) {
			onUpdateDspClick(pack, "UPDATEBULKQTY", quantity);
		} else if (isMobilityEdit) {
			onUpdateRegularQty(pack, "UPDATEBULKQTY", quantity);
		} else {
			onUpdateClick(pack, "UPDATEBULKQTY", quantity);
		}
	};

	// Logic added to support dynamic dropdown options in hybrid primary & backup plans combo.
	const primaryPlanData = useMemo(() => {
		if (
			sessionCartData?.cart?.groupPackages &&
			Object.keys(sessionCartData?.cart?.groupPackages).length
		) {
			return Object.values(sessionCartData?.cart?.groupPackages)?.find(
				(el) => !el?.backUpPlanPresent
			);
		} else {
			return [];
		}
	}, [sessionCartData?.cart?.groupPackages]);

	const backupPlanData = useMemo(() => {
		if (
			sessionCartData?.cart?.groupPackages &&
			Object.keys(sessionCartData?.cart?.groupPackages).length
		) {
			return Object.values(sessionCartData?.cart?.groupPackages)?.find(
				(el) => el?.backUpPlanPresent
			);
		} else {
			return [];
		}
	}, [sessionCartData?.cart?.groupPackages]);

	const byodFromDQ = pack?.byod && pack?.emptyByodFromDQ;
	return (
		<CartPkgContextProvider
			value={{
				accessoriesCount,
				allowedAccessoriesCount,
				backupPlanData,
				disabledQty,
				enableMobilityRemove,
				equipment,
				features,
				is5GMultiPlan,
				isAccessory: pack?.accessoryId,
				isDigitalQuote,
				isDSP,
				isEditable,
				isMMW: cartProperties?.isMMW,
				isMobile,
				isMobilityEdit,
				isUpperFunnel,
				offers,
				onOwnSimSelection,
				pack:{...pack,plan:!!plansForBuss ? plansForBuss : pack.plan},
				primaryPlanData,
				quantity:
					oneTalkFlowAdded && pack.packageType === "ONETALK"
						? 0
						: Number(pack?.quantity),
				setbyodPack,
				setSelectedImei,
				showRightSection,
				isOneTalkFlow,
				oneTalkFlowAdded,
			}}
		>
			<StyledTileContainer>
				{!!(pack?.byod && pack?.simNumber && showSimCompatible) && (
					<Notification
						type="success"
						title={t("app.notifications.byod.simCompatible", { ns: "app" })}
						onCloseButtonClick={() => dispatch(setShowSimCompatible(false))}
					/>
				)}
				<TileContainer padding="1.5rem" aspectRatio="none" backgroundColor="gray">
					<TileDiv>
						<PackageInfo
							isEditable={isEditable}
							onEditClick={onEditDeviceClick}
							onRemoveClick={onRemoveClick}
							onQuantityChange={updateQuantity}
							byodFromDQ={byodFromDQ}
							enableMobilityRemove={enableMobilityRemove}
							cartData={cartData}
							starkEnabled = {starkEnabled}
						/>
						<TileRightSection>
							{showRightSection &&
								((!!pack?.plan &&
								pack?.plan?.pricePlanDesc !== null &&
								pack?.plan?.pricePlanDesc) || pack.buss) && (
									<PackagePlan onEditClick={onEditPlanClick} />
								)}
							{(showRightSection && props?.fromBUSS)  ?
							<>
							<LicenseType/>
							<MarginSpacer2XL/>
							<ProfessionalService/>
							</>
							: <></>}
							{showRightSection && !!features?.length && (
								<PackageFeatures
									onEditClick={onEditFeatureClick}
									onRemoveSecurityAddOnClick={onRemoveSecurityAddOnClick}
								/>
							)}
							{showRightSection &&
								(!!offers?.length || isAmexOfferAvailable !== undefined) && (
									<PackageOffers
										isAmexOfferAvailable={isAmexOfferAvailable}
										promoAmex={promoAmex?.message}
									/>
								)}
						</TileRightSection>
					</TileDiv>
				</TileContainer>
				{/* START TRADE-IN SECTION */}
				{/* TODO: trade-in offer? */}
				{pack?.packageType === "REGULAR" && !pack?.byod && (
					<TradeInTile tradeInOffer={null} />
				)}
				{/* END TRADE-IN SECTION */}
			</StyledTileContainer>
		</CartPkgContextProvider>
	);
};

const TileRightSection = ({ children }) => {
	return (
		<RightSection>
			{children
				.filter((i) => !!i)
				.map((item, i, { length }) => (
					<>
						{item}
						{item && i < length - 1 && <SeparatorLine type="secondary" />}
					</>
				))}
		</RightSection>
	);
};

export default PackageTile;
