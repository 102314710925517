import React from "react";
import { Col, Grid, Row } from "@vds3/grids";
import { Modal, ModalBody, ModalTitle } from "@vds3/modals";
import { Title, Body } from "@vds3/typography";

const ProgressOverlay = ({
    showProgressOverlay,
    modalHeading,
    modalDesc,
    modalFooter,
    newLFflow,
    zIndex
  }) => {
    const [progressIncrement,handleProgressIncrement] = React.useState(0)
    const [showOverlay,setShowOverlay] = React.useState(false)
    React.useEffect(()=>{
      if(showProgressOverlay) {
        setShowOverlay(true)
        handleProgressIncrement(0)
      }
      if(showOverlay && showProgressOverlay === false) setTimeout(()=>setShowOverlay(false),1000)
    },[showProgressOverlay,showOverlay])
    React.useEffect(()=>{
       setTimeout(()=>{
        if(progressIncrement < 90&&showOverlay) handleProgressIncrement(progressIncrement+(Math.floor(Math.random() * 6) + 1))
      },400)
    if(!showProgressOverlay) handleProgressIncrement(100)
    },[progressIncrement,showOverlay,showProgressOverlay])
    const onChange = (value)=>{
        setShowOverlay(value)
    }
    return (
      <Modal
        hideCloseButton={true}
        disableOutsideClick={true}
        // onOpenedChange={onChange}
        opened={showOverlay}
        zIndex={zIndex}
      >
        <ModalTitle>
          <Title size="large" {...newLFflow ? {bold : "true"} : {}}>{`${modalHeading}`}</Title>
        </ModalTitle>
        <ModalBody>
          <div className="progress-overlay-body">
            <Grid
              colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
              colGutter={false}
              rowGutter="5"
            >
              <Row>
                {modalDesc!==null&&modalDesc?
                <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
                  <div className="description">
                    <Body size="large" primitive="span">{modalDesc}</Body>{newLFflow ? <div style={{display: "inline"}} id="bcproChatLink"></div> : <></>}
                  </div>
                </Col>:<></>}
                <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
                  <div className="footer">
                    <Body size="large">{modalFooter}</Body>
                  </div>
                </Col>
                <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
                  <div className="progressBarWrapper">
                    <div
                      className="progress"
                      style={{ width: `${progressIncrement}%`, backgroundColor: '#008330' }}
                    ></div>
                    <div className="unprogress"></div>
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>
        </ModalBody>
      </Modal>
    );
  };
  
  export default ProgressOverlay;