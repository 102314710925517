import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./WidgetsDecider.css";
import ShoppingCart from "pages/ShoppingCart/ShoppingCart";
import BussProductsAddOnPage from "pages/BussProductsPage/BussProductsAddOnPage";
import BussProductPDP from "pages/BussProductsPage/BussProductPDP";
import Checkout from "pages/Checkout";
import CheckoutNew from "pages/CheckoutNew/CheckoutNew";

export const MarketplaceWidget = (props) => {
	const dispatch = useDispatch();
	const [oldCheckout,setCheckoutSwitch] = React.useState(false)

	const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
	useEffect(()=>{
		if(String(props.flow).toLowerCase() === 'assisted'){
			setCheckoutSwitch(true)
		}
	},[]);

	return (
		globalData?.loadCheckoutWidget && oldCheckout ? <Checkout /> :
		globalData?.loadCheckoutWidget && !oldCheckout ? <CheckoutNew/> :
		globalData?.loadShoppingCartWidget ? (
			<ShoppingCart />)
		: globalData?.loadBussPDPPage ? (
		<BussProductPDP clientId = {props.clientId}/>
	) : (
		<BussProductsAddOnPage />
	)
	);
};
