import React from "react";
import styled from "styled-components";
import { RadioBox } from "@vds3/radio-boxes";
import { Body } from "@vds3/typography";

const RadioBoxSection = ({
	data,
	selected,
	onChange,
	compactView,
	...props
}) => {
	return (
		<SectionContainer>
			<RadioBoxContainer>
				{data?.map((item) => {
					return (
						<StyledRadioBox
							key={item?.id}
							selected={item?.id === selected}
							onChange={() => onChange(item?.id)}
							name={item?.title}
							value={item?.id}
							outOfStock={item?.outOfStock}
							disabled={item?.outOfStock}
							children={
								<>
									<Body
										bold
										size="large"
										color={item?.outOfStock ? "#D8DADA" : "#000000"}
									>
										{item?.title}
									</Body>
									<Body size="large" color={item?.outOfStock ? "#D8DADA" : "#000000"}>
										{item?.subtitle}
									</Body>
								</>
							}
							compactView={compactView}
						/>
					);
				})}
			</RadioBoxContainer>
		</SectionContainer>
	);
};

const SectionContainer = styled.div`
	margin: 1rem 0 1.5rem 0;
	.device-info {
		display: inline-block;
		margin-bottom: 0.5rem !important;
	}
`;
const RadioBoxContainer = styled.div`
	margin-bottom: -2rem !important;
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	margin-top: 1rem;
	max-width: 596px;
	margin-left: 3px;
	@media screen and (min-width: 230px) and (max-width: 767px) {
		margin-left: 0px;
	}
	> div {
		height: auto;
	}
`;
const StyledRadioBox = styled(RadioBox)`
	flex: 1;
	min-height: ${({ compactView }) => (compactView ? "0" : "5rem")};
	margin: 5px 12px 17px 0;
	@media screen and (min-width: 230px) and (max-width: 767px) {
		display: flex;
		flex: 2 1 auto;
		width: 328px;
		margin: 5px 1px 12px 0;
		max-width: 100%;
	}
`;

export default React.memo(RadioBoxSection);
