import React, { useState, useEffect, useRef, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import {

  validateZipcode,

  addToCart,
  setStopNavigateToPlans,
} from "../../../pages/Mobility/ducks/MobilitySlice";
import { Body } from "@vds3/typography";
import { Input } from "@vds3/inputs";
import { Modal, ModalTitle, ModalBody, ModalFooter } from "@vds3/modals";
import { Button } from "@vds3/buttons";
import {
  MarginSpacerL, MarginSpacerXL, MarginSpacerXS
} from "../styleTag";
import { TitleLockup } from "@vds3/type-lockups";
import {  setSuspendFlow, setSuspendScenario } from "pages/Checkout/ducks/CheckoutSlice";
import {
  setAddress,
  setUnit,
  setTotalAvailableUnit,
  postLoopQualAddress,
} from "pages/Qualification/ducks/QualificationSlice";
import { callAddressValidation } from "app/ducks/GlobalSlice";
import MapQStreetAddress from "pages/Checkout/ShippingInfo/components/MapQStreetAdress";
import { setLocalCookie } from "utils/commonUtils";
import MapQUnit from "pages/Checkout/ShippingInfo/components/MapQUnit";
import { getTaxesCharges } from "pages/Landing4G-V2/ducks/4GSlice";

const ZipcodeModalBody = styled(ModalBody)`
  padding: 0 0px 0;
  outline: none !important;
  height: auto;
`;
const ButtonWrapper = styled(Button)`
  @media screen and (max-width: 767px) {
    position: absolute;
    bottom: 2px;
    width: 97%;
    right: 3%;
  }
`;

const NewCustomerAddressModal = ({
  pageData = {},
  hideZipCode = false,
  onClose = () => { },
  setIsAddToCartClickedModal,
  setIsFirstDeviceClicked,
  GWJointOfferSelected=false,
  ...props
}) => {
  const { t } = useTranslation(["app", "4G-5G"]);
  const showTradeInToggle = t('showTradeInToggle', { ns: "app" });
  const mobilityPageData = useSelector((state) => state.ProspectWidgets_MobilityData);
  const globalPageData = useSelector((state) => state.ProspectWidgets_GlobalData);
  const landing4gPageData = useSelector((state) => state.ProspectWidgets_4GPlansData);
  const checkoutPageData = useSelector((state) => state.ProspectWidgets_CheckoutData);
  const wirelessPlanPageData = useSelector((state) => state.ProspectWidgets_WirelessPlansData);
  const landingAddonsPage = useSelector((state) => state.LandingAddonsPage);

  const [newAddress, setNewAddress] = useState();
  const dispatch = useDispatch();
  const availZip = pageData?.confirmZipcode != "" ? true : false;
  const [isZipcode, setIsZipcode] = useState(availZip);
  const [errors, setErrors] = useState({});
  const [isConfirm, setIsConfirm] = useState(false);
  const [zipCode, handleZipcode] = useState();
  const [hidZipCode, setHidZipCode] = useState(hideZipCode);
  const [unitsAvailable, setUnitsAvailable] = useState(null);
  const zipRef = useRef(pageData?.zipCode);
  const confirmRef = useRef(isConfirm);
  const [email, handleEmail] = useState("");
  const isEmailEnable = t("app.common.isEmailEnable", { ns: "app" });
  const showAddress2 = t("app.addressCodePopup.showAddress2", { ns: "app" });
  const unifiedCartParams = useSelector(
    (state) => state.ProspectWidgets_CheckoutData?.unifiedCartParams
  );
  const lqPageData = useSelector((state) => state.ProspectWidgets_LQPage);
  const aemContent = t("4G-5G", { ns: "4G-5G", returnObjects: true });
  const [errorMessages, setErrorMessages] = useState({});
  const [contentObj, setContentObj] = useState(aemContent.loopQual.landing);
  const [units, setUnits] = useState({});
  // const isDigitalQuote = sessionCartData?.cart?.digitalQuote || window.sessionStorage.getItem("isDigitalQuote");
  const isUnitFieldAvailable = lqPageData?.totalAvailableUnit?.length > 0 ? '' : 'none';
  const address = lqPageData?.address;
  const zip = lqPageData?.address?.place?.properties?.postalCode?.split("-");

  const isMobile = useMediaQuery({
    query: "(max-width: 725px)",
  });

  // const handleKeyDown = (event) => {
  //   if (event?.code === "Enter") {
  //     confirmClicked(pageData?.zipCode);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("keydown", handleKeyDown);

  //   // populate default zipcode if none is present from BE
  //   if (!pageData?.validZipcode) {
  //     const defaultZip = t("app.common.defaultZipcode", { ns: "app" });
  //     if (defaultZip) {
  //       dispatch(setZipcode(defaultZip));
  //       dispatch(setValidZipcode(true));
  //       setIsConfirm(true);
  //     }
  //   }

  //   // cleanup this component on unmount
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  useEffect(() => {
    if (pageData?.validZipcode && pageData?.zipCode?.length === 5) {
      setIsConfirm(true);
    } else if (pageData?.validZipcode === false) {
      setIsConfirm(false);
      setErrors({
        ...errors,
        ["zipCode"]: `${t("app.zipCodePopup.invalidZip", { ns: "app" })}`,
      });
    }
  }, [pageData?.zipCode, pageData?.validZipcode]);

  // useEffect(() => {
  //   confirmRef.current = isConfirm && !(errors.zipCode?.length > 0);
  // }, [isConfirm, errors]);

  // useEffect(() => {
  //   zipRef.current = pageData?.zipCode;
  //   dispatch(setValidZipcode(null));
  //   if (zipRef.current?.length === 5) {
  //     handleZipcode(pageData?.zipCode);
  //     dispatch(
  //       validateZipcode({
  //         zipcode: zipRef.current,
  //         isEmailEnable: isEmailEnable,
  //       })
  //     );
  //   } else {
  //     !hidZipCode && isValidZipCode(zipRef.current);
  //   }
  // }, [pageData?.zipCode]);

  useEffect(() => {
    !hidZipCode && isValidZipCode(zipCode);
  }, [zipCode]);

  const confirmClicked = (zipVal) => {
    if (!zipCode && !hideZipCode) {
      const errorTexts = `${t("app.addressCodePopup.zipRequired", { ns: "app" })}`;
      setErrors({ ...errors, ["zipCode"]: errorTexts });
    } else if(!zipCode && !newAddress && hideZipCode){
      setErrorMessages({
        ...errorMessages,
        addressInfoErr: contentObj.errors.address.required,
      });
    }else {

      if (newAddress) {
        const payload = {
          addressLine1: lqPageData?.address?.place?.properties?.street,
          city: lqPageData?.address?.place?.properties?.city,
          state: lqPageData?.address?.place?.properties?.state,
          zipCode: lqPageData?.address?.place?.properties?.postalCode,
          zipPlus4: lqPageData?.address?.place?.properties?.zipPlus4,
          unit: lqPageData?.unit?.unit
        }
        dispatch(callAddressValidation(payload));
        dispatch(validateZipcode({ zipcode: zipCode }));

      }
      else if (!hidZipCode) {
        dispatch(validateZipcode({ zipcode: zipCode }));
      }
      // hidZipCode ? newCustomerPdpClicked() : newCustomerClicked();


      if (hidZipCode) {
        dispatch(setStopNavigateToPlans(true));
      }

      // if (!!confirmRef.current) {
      //   setIsZipcode(true);
      //   dispatch(setZipcode(zipCode));
      //   dispatch(setConfirmZipcode(zipCode));
      //   onClose();
      // }

    }
  };
  useEffect(() => {

    if ((globalPageData?.addressValidation?.serviceStatus?.success) || (zipCode && pageData?.validZipcode)) {
      hidZipCode ? newCustomerPdpClicked() : newCustomerClicked();
      onClose();
      setHidZipCode(false)
    }

  }, [globalPageData?.addressValidation?.serviceStatus?.success, pageData?.validZipcode])

  const newCustomerPdpClicked = () => {
    let payload;
    const address = {
      addressLine1: lqPageData?.address?.place?.properties?.street,
      city: lqPageData?.address?.place?.properties?.city,
      state: lqPageData?.address?.place?.properties?.state,
      zipCode: lqPageData?.address?.place?.properties?.postalCode,
      zipPlus4: lqPageData?.address?.place?.properties?.zipPlus4,
      unit: lqPageData?.unit?.unit
    }

    if (lqPageData?.flow === "4g") {
      const req = {
        flow: lqPageData?.flow,
        street: lqPageData?.address?.place?.properties?.street,
        city: lqPageData?.address?.place?.properties?.city,
        state: lqPageData?.address?.place?.properties?.stateCode,
        zipCode: lqPageData?.address?.place?.properties?.postalCode,
        zipPlus4: lqPageData?.address?.place?.properties?.zipPlus4,
        eventCorrelationId: lqPageData?.fixedWirelessResponse?.eventCorrelationId,
        cband: lqPageData?.cband,
        mmw: lqPageData?.mmw,
        digitalQuote: !!unifiedCartParams?.quoteId,
        addressModal: true
      };
      dispatch(postLoopQualAddress(req));
      payload = {
        planIds: landing4gPageData?.planDetails?.plans,
        address
      }
    } else {
      const payloadPlansPage = {
        cartMeta: {
          zipCode: zipCode,
          pdpBillingAddress: address,
          email: pageData?.email,
          shoppingPath: "prospect",
          billingAddressFromPlansPage: true,
          gwJointOfferFlowEnabled  : GWJointOfferSelected || false
        }
      }
      dispatch(addToCart(payloadPlansPage));
      payload = {
        planIds: wirelessPlanPageData?.deviceCompatiblePlansInfo?.plans || landingAddonsPage?.backUpPlansDetails?.plans,
        address
      }
    }

    // const planIds = Object.keys(wirelessPlanPageData?.deviceCompatiblePlansInfo?.plans);
    // { payload: resp?.data?.plans, pdpBillingAddress}
    dispatch(getTaxesCharges(payload))
    props?.closeNCModal(false);
    // onClose();
  };
  const newCustomerClicked = () => {
    let dataObj = mobilityPageData?.selectedDeviceDetailsSlice;
    let priceType = dataObj[0]?.priceType;
    let quantity = dataObj[0]?.quantity;
    let portIn = dataObj[0]?.portIn;
    let portInType = dataObj[0]?.portInType;
    let selectedSku = dataObj[0]?.selectedSku;
    let selectedOffers = dataObj[0]?.selectedOffers;
    let selectedSimType = dataObj[0]?.selectedSimType;
    if (setIsAddToCartClickedModal) {
      setIsAddToCartClickedModal(true);
    }
    if (
      (props?.pageData?.categoryName == "smart-watches" ||
        props?.pageData?.productDetail?.data?.deviceDetails?.category ==
        "Smart Watches" ||
        props?.pageData?.productDetail?.data?.deviceDetails?.category ==
        "Connected Smartwatches") &&
      selectedSku[0]?.numberShareEligibilityFlag &&
      selectedSku[0]?.numberShareEligibilityFlag == "Y"
    ) {
      dispatch(setSuspendScenario("SUSPENDED_FLOW_MTN_SHARE_Y"));
      dispatch(setSuspendFlow(true));
      navigate("/shop/contact-us", { state: { refUrl: window.location.pathname } });
    }
    const tradeInItem = mobilityPageData?.tradeInValueInfoSlice?.tradeInItems?.length
      && mobilityPageData?.tradeInValueInfoSlice?.tradeInItems[0];
    const address = {
      addressLine1: lqPageData?.address?.place?.properties?.street,
      city: lqPageData?.address?.place?.properties?.city,
      state: lqPageData?.address?.place?.properties?.state,
      zipCode: lqPageData?.address?.place?.properties?.postalCode,
      zipPlus4: lqPageData?.address?.place?.properties?.zipPlus4,
      unit: lqPageData?.unit?.unit
    }

    const payload = {
      cartMeta: {
        zipCode: zipCode || lqPageData?.address?.place?.properties?.postalCode || "",
        pdpBillingAddress: address,
        email: pageData?.email,
        shoppingPath: "prospect",
        gwJointOfferFlowEnabled  : GWJointOfferSelected || false
      },
      lines: [
        {
          packageId: "",
          packageType: props.isOneTalk ? "ONETALK" : "REGULAR",
          operation: "ADD",
          enableTradeinOfferToggle: (priceType === "monthly" && showTradeInToggle === "Y" && mobilityPageData?.tradeInToggle) ? true : false,
          tradeIn: tradeInItem
            && tradeInItem?.recycleDeviceOfferType
            && tradeInItem?.recycleDeviceOfferType?.buyItemCode === selectedSku[0]?.sku
            ? {
              deviceId: tradeInItem?.deviceId
            }
            : {},
          device: {
            deviceSku: selectedSku[0]?.sku,
            deviceId: pageData?.productDetail?.data?.deviceDetails?.deviceId,
            deviceCategory:
              pageData?.productDetail?.data?.deviceDetails?.deviceCategory ||
              pageData?.productDetail?.data?.deviceDetails?.category,
            quantity: quantity,
            portIn: portIn,
            portInType: portInType,
            priceType: priceType === "monthly" &&
              selectedSku[0]?.pricingDetails?.devicePayments?.[0]?.nofInstallements ? "MONTH_TO_MONTH" : priceType === "twoyears" ? "TWO_YEAR" : "RETAIL",
            installmentTerm:
              priceType === "monthly" &&
                selectedSku[0]?.pricingDetails?.devicePayments?.[0]?.nofInstallements
                ? selectedSku[0]?.pricingDetails?.devicePayments?.[0]
                  ?.nofInstallements
                : 0,
            seoUrlName: selectedSku.seoUrlName,
            simType: getSimType(selectedSku[0], selectedSimType) === "esim" ? "E" : "P",
            deviceType: props.isOneTalk ? "DESKPHONE" : "SMARTPHONE",
          },
          selectedOffers,
        },
      ],
      addendaCode: props?.cmpValue ?? '',
    };
    setLocalCookie("SMB_PROSPECT_CARTQTY", quantity, 1);
    if (setIsFirstDeviceClicked) {
      setIsFirstDeviceClicked(false);
    }
    dispatch(addToCart(payload));
  };
  const getSimType = (selectedSku, selectedSimType) => {
    if (selectedSimType) return selectedSimType;
    let defaultSimType = selectedSku?.defaultSimType;
    let defaultSku = selectedSku?.supportedSims?.filter((sim) => !!sim?.defaultSim);
    if (defaultSku?.length && !selectedSku?.simType?.length) {
      if (defaultSku[0]?.simSku === selectedSku?.psimSku) {
        defaultSimType = "psim";
      } else if (defaultSku[0]?.simSku === selectedSku?.esimSku) {
        defaultSimType = "esim";
      }
    } else if (selectedSku?.simType?.length) {
      defaultSimType = selectedSku?.simType;
    }
    return defaultSimType;
  }

  const isValidZipCode = (zip) => {
    const invalidZipCodes = t("zipCode.invalidZipcodes", {
      returnObjects: true,
    });
    let errorTexts = "";
    // dispatch(setZipcode(zip));
    errorTexts =
      !zip || !zip?.trim()
        ? `${t("app.addressCodePopup.zipRequired", { ns: "app" })}`
        : "";
    if (zip?.length < 5 && !(!zip || !zip?.trim())) {
      setIsConfirm(false);
      errorTexts = `${t("app.addressCodePopup.zipLength", { ns: "app" })}`;
    }
    if (zip?.length === 5) {
      if (!invalidZipCodes.includes(zip)) {
        setIsConfirm(true);
      } else {
        setIsConfirm(false);
        if (pageData.validZipcode === false) {
          errorTexts = `${t("app.addressCodePopup.invalidZip", { ns: "app" })}`;
        } else {
          dispatch(
            validateZipcode({ zipcode: zip })
          );
        }
      }
    }

    zip !== undefined && setErrors({ ...errors, ["zipCode"]: errorTexts });
  };

  // const onOpenedChange = (e) => {
  //   if (e === false) {
  //     // on modal close
  //     window.removeEventListener("keydown", handleKeyDown);
  //     window.history.back();
  //   }
  // };


  useEffect(() => {
    if (pageData?.isFios) {
      setContentObj(aemContent.loopQual.fios);
    } else {
      setContentObj(aemContent.loopQual.landing);
    }
  }, [pageData?.isFios]);

  useEffect(() => {
    const postalCode = lqPageData?.address?.place?.properties?.postalCode;
    if (postalCode) {
      zipRef.current = postalCode;
      handleZipcode(lqPageData?.address?.place?.properties?.postalCode)
    }

  }, [lqPageData?.address])

  const existingCustomerBtnClicked = () => {
    let deviceId = pageData?.productDetail?.data?.deviceDetails?.deviceId;
    let dataObj = props?.selectedDeviceDetails;
    let skuId = dataObj[0]?.selectedSku[0]?.sku;
    let redirectUrl = `${t("app.common.deepLinkUrls.pdp", { ns: "app" })}`;
    if (deviceId && skuId && redirectUrl) {
      let finalUrl =
        redirectUrl +
        encodeURIComponent(deviceId) +
        "/" +
        encodeURIComponent(skuId);
      window.location.href = finalUrl;
    } else {
      redirectUrl = `${t("app.common.deepLinkUrls.byod", { ns: "app" })}`;
      window.location.href = redirectUrl;
    }
  }


  return (
    <Modal
      ariaLabel=""
      disableOutsideClick={true}
      hideCloseButton={false}
      // closeButton={<></>}
      opened={!isZipcode || hidZipCode}
      // onOpenedChange={onOpenedChange}
      onOpenedChange={props?.closeNCModal}
      disableFocusLock= {true}
    >
      <ModalTitle>
        {
          hidZipCode ? <TitleLockup
            data={{
              title: {
                bold: true,
                size: "titleLarge",
                children: t("app.addressCodePopupPlansPage.heading", { ns: "app" }),
              },
            }}
          /> :
            <TitleLockup
              data={{
                title: {
                  bold: true,
                  size: "titleLarge",
                  children: GWJointOfferSelected ? t("app.addressCodePopupGWJointOffer.heading", { ns: "app" }) :  t("app.addressCodePopup.heading", { ns: "app" }),
                },
              }}
            />
        }

      </ModalTitle>
      <ZipcodeModalBody>
        {hidZipCode ? <Body size="medium">
          {t("app.addressCodePopupPlansPage.description", { ns: "app" })}
        </Body> :
          <Body size="medium">
            {GWJointOfferSelected ? t("app.addressCodePopupGWJointOffer.description", { ns: "app" }) : t("app.addressCodePopup.description", { ns: "app" })}
          </Body>
        }
        <MarginSpacerL />
        <Body size="medium">
          {t("app.addressCodePopup.requiredFieldDesc", { ns: "app" })}
        </Body>
        <MarginSpacerL />
        <MarginSpacerL />
        <div>
          <MapQStreetAddress
            errorMessage={contentObj.errors.address.required}
            required={hidZipCode}
            placeHolder=""
            label={GWJointOfferSelected ? t("app.addressCodePopupGWJointOffer.inputAddressLabel", { ns: "app" }) : t("app.addressCodePopup.inputAddressLabel", { ns: "app" })}
            helperText=""
            onSelectionChange={(value) => {
              setNewAddress(value)
              dispatch(setAddress(value));
              setUnitsAvailable(-Infinity);
            }}
            handleAddressInput={() => { }}
            isAddressEmty={false}
            isError={errorMessages.addressInfoErr}
            setAddressFieldError={(value) => {
              setErrorMessages({
                ...errorMessages,
                addressInfoErr: value,
              });
            }} />
        </div>
        {showAddress2 && (<ColWidth isUnitFieldAvailable={isUnitFieldAvailable}>
          <MapQUnit
            label="Apt, suite, or unit"
            locusID={address?.place?.properties?.locusID}
            ntasAddrID={address?.place?.properties?.ntasAddrID}
            onSelectionChange={value => {
              setUnits(value);
              dispatch(setUnit(value));
            }}
            setTotalAvailableUnits={value => {
              if (address?.place?.properties) {
                setUnitsAvailable(Number(value));
              }
            }}
          // isError={errorMessages?.addressInfoErr}
          />
        </ColWidth>)}
        {!hideZipCode && <>
          <ColWidth>
            <Input
              label={t("app.addressCodePopup.inputLabel", { ns: "app" })}
              maxLength={5}
              name="zipcode"
              disabled={newAddress != undefined || newAddress != null}
              width={"100%"}
              value={zipCode}
              onChange={(e) => {
                isValidZipCode(e.target.value)
                const zipVal = e.target.value.trim();
                if (!isNaN(zipVal)) handleZipcode(zipVal);
              }
              }
              // onChange={(e) => {
              //   const zipVal = e.target.value.trim();
              //   if (!isNaN(zipVal)) handleZipcode(zipVal);
              // }}
              // onKeyDown={(e) => {
              //   if (e?.keyCode === 13) {
              //     const zipVal = e.target.value.trim();
              //     if (!isNaN(zipVal)) handleZipcode(zipVal);
              //   }
              // }}
              error={errors?.zipCode?.length > 0 ?? false}
              errorText={errors?.zipCode ?? ""}
            />
          </ColWidth>
        </>}


      </ZipcodeModalBody>
      <MarginSpacerXL />
      <div
        children={
          <>
            <Button
              size="large"
              disabled={false}
              use="primary"
              width={isMobile && "100%"}
              onClick={() => confirmClicked()}
            >
              Continue
            </Button>
            {!hideZipCode && <>
              <MarginSpacerXL />
              <Login>
                <Body bold size="large">
                  Already a Verizon Business customer?
                </Body>
                <Button
                  use="secondary"
                  size="small"
                  onClick={() => {
                    existingCustomerBtnClicked();
                  }}
                  secondary={true}
                >
                  Login
                </Button>

              </Login>
            </>}
          </>
        }

      >

      </div>
      {/* <ModalFooter
        
        children={
          <ButtonWrapper
            width={isMobile ? "100%" : "200px"}
            
            // disabled={!isConfirm || errors.zipCode?.length > 0}
            disabled={false}
            onClick={() => confirmClicked()}
          >
            Continue
          </ButtonWrapper>
        }
      /> */}


    </Modal>
  );
};
const Login = styled.div`
display:flex;
margin-bottom: 6px;
flex-direction: row;
align-items: center;
column-gap: 15px;
justify-content: flex-start;
@media screen and (max-width: 725px) {
  display:flex;
  flex-direction: column;
  row-gap: 15px;
  align-items: flex-start;
}
`
const ColWidth = styled.div`
width:50%;
padding-top:24px;
display : ${props => props.isUnitFieldAvailable};
`



export default NewCustomerAddressModal;
