// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.businessInfo { 
    /* margin: 1rem 0rem; */
    margin: 0rem;
}
.businessInfo .container .form-col{ 
     margin: 1rem 1rem 0.5rem 0;
}
.businessInfo .container .form-col .helper-text p{ 
     color: #747676;
     margin-top: 8px;
} 
.businessInfo .container .captchaContainer-consent {
     margin-top: 35px;
   }
.businessInfo .container .continue-btn{ 
     margin-top: 1rem;
}
.businessInfo .container .continue-btn{ 
     margin-top: 1rem;
}
.businessInfo .container >div >div{ 
     margin: 0;
}
.businessInfo .container .Prod-Discription{ 
     margin-bottom: 0.5rem;
}
.businessInfo input:disabled {
     color: gray;
}
.fontnormal{
     font-weight: lighter;
}
.notification{
     width : 490px;
     margin-left:8px;
}
.digicertContainer-consent img {
     margin-top: 35px;
     width: 40%;
 }


 @media (min-width: 320px) and (max-width: 545px) {
     .notification{
          width : 90%;
          margin-left:14px;
     }
     .digicertContainer-consent img {
          margin-top: 35px;
          width: 40%;
      }
      .businessInfo .container .captchaContainer-consent  {
          width : 20%
        }
     
}

 @media screen and (min-width: 768px) {
     .digicertContainer-consent img {
       width: 40%;
     }
     
}`, "",{"version":3,"sources":["webpack://./src/pages/CheckoutNew/Components/BusinessInfo/AuthorisedSigner.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;AAChB;AACA;KACK,0BAA0B;AAC/B;AACA;KACK,cAAc;KACd,eAAe;AACpB;AACA;KACK,gBAAgB;GAClB;AACH;KACK,gBAAgB;AACrB;AACA;KACK,gBAAgB;AACrB;AACA;KACK,SAAS;AACd;AACA;KACK,qBAAqB;AAC1B;AACA;KACK,WAAW;AAChB;AACA;KACK,oBAAoB;AACzB;AACA;KACK,aAAa;KACb,eAAe;AACpB;AACA;KACK,gBAAgB;KAChB,UAAU;CACd;;;CAGA;KACI;UACK,WAAW;UACX,gBAAgB;KACrB;KACA;UACK,gBAAgB;UAChB,UAAU;MACd;MACA;UACI;QACF;;AAER;;CAEC;KACI;OACE,UAAU;KACZ;;AAEL","sourcesContent":[".businessInfo { \n    /* margin: 1rem 0rem; */\n    margin: 0rem;\n}\n.businessInfo .container .form-col{ \n     margin: 1rem 1rem 0.5rem 0;\n}\n.businessInfo .container .form-col .helper-text p{ \n     color: #747676;\n     margin-top: 8px;\n} \n.businessInfo .container .captchaContainer-consent {\n     margin-top: 35px;\n   }\n.businessInfo .container .continue-btn{ \n     margin-top: 1rem;\n}\n.businessInfo .container .continue-btn{ \n     margin-top: 1rem;\n}\n.businessInfo .container >div >div{ \n     margin: 0;\n}\n.businessInfo .container .Prod-Discription{ \n     margin-bottom: 0.5rem;\n}\n.businessInfo input:disabled {\n     color: gray;\n}\n.fontnormal{\n     font-weight: lighter;\n}\n.notification{\n     width : 490px;\n     margin-left:8px;\n}\n.digicertContainer-consent img {\n     margin-top: 35px;\n     width: 40%;\n }\n\n\n @media (min-width: 320px) and (max-width: 545px) {\n     .notification{\n          width : 90%;\n          margin-left:14px;\n     }\n     .digicertContainer-consent img {\n          margin-top: 35px;\n          width: 40%;\n      }\n      .businessInfo .container .captchaContainer-consent  {\n          width : 20%\n        }\n     \n}\n\n @media screen and (min-width: 768px) {\n     .digicertContainer-consent img {\n       width: 40%;\n     }\n     \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
