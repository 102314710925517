import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Button, TextLink } from "@vds3/buttons";
import { Grid, Row, Col } from "@vds3/grids";
import { Icon } from "@vds3/icons";
import { Body } from "@vds3/typography";
import {
	StickyContainer,
	FlexContainer,
	LeftContainer,
	TopLine,
	BottomLine,
	TotalsContainer,
	TotalsDiv,
	ShoppingBagContainer,
	AddressContainer,
	BackIconWrapper,
	RowBreak,
	TextLinkWrapper,
	DivBodyWarpper,
} from "./styles";
import ShoppingBag from "../SVGIcons/ShoppingBag";
import ShoppingBagEmpty from "../SVGIcons/ShoppingBagEmpty";
import { setLoadCheckoutWidget, setLoadFWAQualifiedPlans, setLoadQualficationSelection, setLoadShoppingCartWidget } from "app/ducks/GlobalSlice";
import { clear4g5gCart } from "pages/Qualification/ducks/QualificationSlice";

const StickyHeaderVds3 = ({
	pageName,
	headerRef,
	onContinue,
	onCancel,
	onCartClick,
	enableBackLink,
	backClickHandler,
	hideAddress,
	hideTotals,
	hideContinueButton,
	disabledContinueBtn,
	continueBtnText,
	hideShoppingBagIcon,
	onAddressChange,
	hideBottomLine,
	...props
}) => {
	const { t } = useTranslation(["app"]);
	const isMobile = useMediaQuery({
		query: "(max-width: 639px)",
	});
	const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
	const BBALQResp = useSelector(
		(state) => state.ProspectWidgets_LQPage?.BBALQResp
	);
	const dispatch = useDispatch();
	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	const sessionId = globalData?.sessionCartData?.apiResponse?.sessionCartMetaData?.identifier ?? ""
	const billing =
		sessionCartData?.discAmountPresentInCart &&
		sessionCartData?.displayTotalAmounts
			? sessionCartData?.displayTotalAmounts
			: sessionCartData?.totalAmounts;

	const getAddressLine1 = (lqAddressData) =>
		lqAddressData?.addressLine1 ||
		`${lqAddressData?.streetNum || ""} ${lqAddressData?.streetName || ""}`;

	const getAddressLine2 = (lqAddressData) =>
		lqAddressData?.addressLine2 ||
		`${lqAddressData?.city || ""}, ${lqAddressData?.state || ""} ${
			lqAddressData?.zipCode || ""
		}`;

	const addressLine1 =
		(sessionCartData &&
			getAddressLine1(sessionCartData?.prospect5gLoopQualAddress)) ||
		(BBALQResp && getAddressLine1(BBALQResp));
	const addressLine2 =
		(sessionCartData &&
			getAddressLine2(sessionCartData?.prospect5gLoopQualAddress)) ||
		(BBALQResp && getAddressLine2(BBALQResp));
	const zipCode = sessionCartData?.cart?.zipCodeFromPlan;
	const hasAddress = !!(addressLine1 && addressLine1.trim());
	const sessionCartId = sessionCartData?.cart?.cartId || globalData?.cartId;
	return (
		<StickyContainer ref={headerRef}>
			<TopLine surface="dark" />
			<Grid colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
				<Row>
					<Col>
						<FlexContainer hasAddress={hasAddress}>
							<LeftContainer>
								<span style={{ margin: "auto", display: "flex" }}>
									{!!enableBackLink && (
										<BackIconWrapper onClick={() => backClickHandler()}>
											<Icon name="left-caret" color="#000" surface="dark" size="medium" />
										</BackIconWrapper>
									)}
									{!hideAddress && hasAddress && (
										<AddressContainer>
											<div>
												{addressLine1 && (
													<div>
														<Body size="small" color="#000">
															{addressLine1}
														</Body>
													</div>
												)}
												{addressLine2 && addressLine2.trim() !== "," && (
													<div>
														<Body size="small" color="#000">
															{addressLine2}&nbsp;
															<TextLink
																surface="dark"
																color="#000"
																size="small"
																type="inline"
																onClick={onAddressChange}
															>
																{"Change"}
															</TextLink>
														</Body>
													</div>
												)}
											</div>
										</AddressContainer>
									)}
								</span>
							</LeftContainer>
							{!!isMobile && (
								<>
									{!hideShoppingBagIcon && hasAddress && (
										<ShoppingBagContainer onClick>
											<Icon
												name="shopping-bag"
												color="#FFFFFF"
												surface="dark"
												size="large"
											/>
										</ShoppingBagContainer>
									)}
									{hasAddress && <RowBreak />}
									<TotalsContainer hasAddress={hasAddress}>
										{hasAddress && (
											<BackIconWrapper>
												<Icon name="left-caret" color="#FFFFFF" size="medium" />
											</BackIconWrapper>
										)}
										{!hideTotals && (
											<Totals
												dueMonthly={billing?.dueMonthly}
												dueToday={billing?.dueToday}
											/>
										)}
									</TotalsContainer>
								</>
							)}

							{!isMobile && props?.showSaveCart && (
								<TextLinkWrapper
									onClick={() => {
										props?.saveCart();
									}}
									style={{
										height: isMobile ? "16px" : "",
									}}
									size="medium"
									type="standAlone"
								>
									Save&#8202;cart
								</TextLinkWrapper>
							)}

							{!isMobile && props?.showSendCart && (
								<TextLinkWrapper
									onClick={() => {
										props?.sendCart();
									}}
									style={{
										height: isMobile ? "16px" : "",
									}}
									size="medium"
									type="standAlone"
								>
									Send&#8202;cart
								</TextLinkWrapper>
							)}

							{!isMobile && props?.showEditCart && (
								<TextLinkWrapper
									onClick={() => {
										props?.editCart();
									}}
									size="medium"
									type="standAlone"
								>
									Edit&#8202;cart
								</TextLinkWrapper>
							)}
							<div style={{display:'inline',position:'relative',top:'-13px'}}>
							{!isMobile && sessionCartId && props.showCartID && (
								<DivBodyWarpper>
									<Body size="medium">Cart Id:&nbsp;{sessionCartId}</Body>
								</DivBodyWarpper>
							)}
							{!isMobile && sessionId && props.showSessionId && (
								<DivBodyWarpper>
									<Body size="medium">Session Id:&nbsp;{sessionId}</Body>
								</DivBodyWarpper>
							)}
							</div>
							{!isMobile && !hideTotals && (
								<Totals dueMonthly={billing?.dueMonthly} dueToday={billing?.dueToday} />
							)}
							{!hideContinueButton && (
								<>
									<Button
										use="primary"
										onClick={() => onContinue()}
										disabled={disabledContinueBtn}
										size="small"
										style={{ height: "32px" }}
									>
										{continueBtnText
											? continueBtnText
											: t("app.common.page-stepper.continue-btn", {
													ns: "app",
											  })}
									</Button>
									<Button
										use="secondary"
										onClick={(e) => { onCancel;
											dispatch(setLoadFWAQualifiedPlans(false));
											dispatch(setLoadShoppingCartWidget(false));
											dispatch(setLoadCheckoutWidget(false));
											dispatch(setLoadQualficationSelection(false));
											dispatch(clear4g5gCart({ launchType: "new" }));
										}}
										size="small"
										style={{ height: "32px" }}
									>
										{"Cancel order"}
									</Button>
								</>
							)}
							{!disabledContinueBtn &&
								!hideShoppingBagIcon &&
								!isMobile &&
								hasAddress && (
									<div className="cart-image" onClick={() => {}}>
										<ShoppingBag width="18px" height="18px" />
									</div>
								)}
							{disabledContinueBtn &&
								!hideShoppingBagIcon &&
								!isMobile &&
								hasAddress && (
									<div className="cart-image">
										<ShoppingBagEmpty width="18px" height="18px" />
									</div>
								)}
						</FlexContainer>
					</Col>
				</Row>
			</Grid>
			{!hideBottomLine && <BottomLine type="secondary" surface="dark" />}
		</StickyContainer>
	);
};

const Totals = ({ dueMonthly, dueToday, ...props }) => {
	return (
		<TotalsDiv>
			<div>
				<div style={{ width: "max-content", marginRight: "8px" }}>
					<Body size="small" color="#000">
						Billed Monthly
					</Body>
				</div>
				<Body bold size="large" color="#000">
					{`$${(dueMonthly ?? 0).toFixed(2)}`}
				</Body>
			</div>
			<div>
				<div style={{ width: "max-content", marginRight: "8px" }}>
					<Body size="small" color="#000">
						Due Today
					</Body>
				</div>
				<Body bold size="large" color="#000">
					{`$${(dueToday ?? 0).toFixed(2)}`}
				</Body>
			</div>
		</TotalsDiv>
	);
};

export default StickyHeaderVds3;
