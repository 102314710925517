import { createSlice, createAction, createSelector } from "@reduxjs/toolkit";

const initialState = {
  apiError: false,
  backupPlans: [],
  cappedPlans: [],
  initialLoad: false,
  loading: false,
  quantity: 1,
  tabIndex: 0,
  uncappedPlans: [],
  imeiCheckAttempt: 0,
  primaryPlan: {
    quantity: 1,
    routerDetails: {},
    selectedPlan: "",
    selectedRouter: "",
    selectedRouterPayment: "",
    prevSelectedRouterPayment: "",
  },
  backupPlan: {
    quantity: 1,
    routerDetails: {},
    selectedPlan: "",
    selectedRouter: "",
    selectedRouterPayment: "",
    prevSelectedRouterPayment: "",
  },
  isEditLTEPrimaryPlansFlow: false,
  isEditLTEBackupPlansFlow: false,
  isEditLTEEquipmentFlow: false,
  isFrom4GLearn: false,
  isIntcmpLearn:false,
  isPackageCompatible: false,
  grpId:"",
  backupGrpId:"",
  htmlTemplateFcc:'',
  planDetailsFcc: {},
  taxesCharges : {},
  lteLearnShowAddressModal: false,
  storeFCCDetails: {},
  showCheckAvailabiltyLink: false,
  saveCartLteAvailble:false,
  addressMicroUnit:{},
  loopCallFromLearnPageCompleted:false,
  toggleStatusForCBandRouter:{},
  showInternetMobilePlan:false,
  addtoCartResponse:{},
  storeApiCalled : false
  };

const Landing4GSlice = createSlice({
  name: "Landing4G",
  initialState,
  reducers: {
    reset4GState: () => {
      return initialState;
    },
    reset4GSelectedPlan: (state) => {
      state["primaryPlan"] = { ...initialState.primaryPlan };
      state["backupPlan"] = { ...initialState.backupPlan };
    },
    setQuantity: (state, action) => {
      state[action.payload.planType]["quantity"] = action.payload.value;
      state[action.payload.planType]["isDefault"] = action.payload.isDefault;
    },
    setRouterDeviceResp: (state, action) => {
      state[action.payload.planType]["routerDetails"] = action.payload.response;
    },
    setSelectedPlan: (state, action) => {
      state[action.payload.planType]["selectedPlan"] = state[action.payload.planType]["quantity"] === "0" ? "" :action.payload.value;
    },
    setSelectedRouter: (state, action) => {
      state[action.payload.planType]["selectedRouter"] = action.payload.value;
    },
    setSelectedRouterPayment: (state, action) => {
      state[action.payload.planType]["selectedRouterPayment"] =
        action.payload.value;
    },
    setPrevSelectedRouterPayment: (state, action) => {
      state[action.payload.planType]["prevSelectedRouterPayment"] =
        action.payload.value;
    },
    set4GLandingPageLoading: (state, action) => {
      state["loading"] = action.payload;
    },
    setAPIError: (state, action) => {
      state["apiError"] = action.payload;
    },
    setBackupPlans: (state, action) => {
      state["backupPlans"] = action.payload;
    },
    setCappedPlans: (state, action) => {
      state["cappedPlans"] = action.payload;
    },
    setPlansDetails: (state, action) => {
      state["planDetails"] = action.payload;
    },
    setRouterUpdateResp: (state, action) => {
      state["routerUpdateResp"] = action.payload;
    },
    setTabIndex: (state, action) => {
      state["tabIndex"] = action.payload;
    },
    setUncappedPlans: (state, action) => {
      state["uncappedPlans"] = action.payload;
    },
    setValidateDeviceIdResp: (state, action) => {
      state["validateDeviceIdResp"] = action.payload;
    },
    setValidateSimIdResp: (state, action) => {
      state["validateSimIdResp"] = action.payload;
    },
    setInitialLoad: (state, action) => {
      state["initialLoad"] = action.payload;
    },
    setPlanPreselection: (state, action) => {
      state["planPreselection"] = action.payload;
    },
    setImeiCheckAttempt: (state, action) => {
      state["imeiCheckAttempt"] = action.payload;
    },
    setAuthCartSuccess: (state, action) => {
      state["authCartSuccess"] = action.payload;
    },
    setIsEditLTEPrimaryPlansFlow: (state, action) => {
      state["isEditLTEPrimaryPlansFlow"] = action.payload;
    },
    setIsEditLTEBackupPlansFlow: (state, action) => {
      state["isEditLTEBackupPlansFlow"] = action.payload;
    },
    setIsEditLTEEquipmentFlow: (state, action) => {
      state["isEditLTEEquipmentFlow"] = action.payload;
    },
    setIsFrom4GLearn: (state, action) => {
      state["isFrom4GLearn"] = action.payload;
    },
    setIsIntcmpLearn: (state, action) => {
      state["isIntcmpLearn"] = action.payload;
    },
    setpackageCompatible: (state, action) => {
      state["isPackageCompatible"] = action.payload;
    },
    setGrpId: (state, action) => {
      state["grpId"] = action.payload;
    },
    setBackupGrpId: (state, action) => {
      state["backupGrpId"] = action.payload;
    },
    sethtmlTemplateFcc:(state, action) => {
      state["htmlTemplateFcc"] = action.payload;
    },
    setPlansDetailsFcc: (state, action) => {
      state["planDetailsFcc"] = action.payload;
    },
    setTaxesCharges: (state, action) => {
      state["taxesCharges"] = action.payload;
    },
    setStoreApiCalled : (state, action) => {
      state["storeApiCalled"] = action.payload
    },
    setLteLearnShowAddressModal: (state, action) => {
      state["lteLearnShowAddressModal"] = action.payload;
    },
    setStoreFCCDetails: (state, action) => {
      state["storeFCCDetails"] = action.payload;
    },
    setShowCheckAvailabiltyLink: (state, action) => {
      state["showCheckAvailabiltyLink"] = action.payload;
    },
    setShowInternetMobilePlan: (state, action) => {
      state["showInternetMobilePlan"] = action.payload;
    },
    setSaveCartLteAvailble : (state, action) => {
      state["saveCartLteAvailble"] = action.payload;
    },
    setCheckAvailabilityRedirection: (state, action) => {
      state["checkAvailabilityRedirection"] = action.payload;
    },
    setAddressMicroUnit: (state, action) => {
      state["addressMicroUnit"] = action.payload;
    },
    setLoopCallFromLearnPageCompleted: (state, action) => {
      state["loopCallFromLearnPageCompleted"] = action.payload;
    },
    setToggleForBandRouter: (state,action) => {
      state["toggleStatusForCBandRouter"] = action.payload;
  },
  setFWABundleTabSelected: (state, action) => {
    state["fwaBundleTabSelected"] = action.payload;
  },
  setAddtoCartResponse: (state, action) => {
    state["addtoCartResponse"] = action.payload;
  },
  setCombinedResponse: (state, action) => {
    state["combinedResponse"] = action.payload;
  },
}});

export const reducer = Landing4GSlice.reducer;
export const get4gPlans = createAction("Landing4G/get4gPlans");
export const get4gTabPlans = createAction("Landing4G/get4gTabPlans");
export const getTaxesCharges = createAction("Landing4G/getTaxesCharges");
export const get4gRouterDevices = createAction("Landing4G/get4gRouterDevices");
export const getFixedWirelessCoverageCheck = createAction(
  "Landing4G/getFixedWirelessCoverageCheck"
);
export const update4GPlan = createAction("Landing4G/update4GPlan");
export const update4GRouter = createAction("Landing4G/update4GRouter");
export const addOrUpdate4GPage = createAction("Landing4G/addOrUpdate4GPage");
export const validateDeviceId = createAction("Landing4G/validateDeviceId");
export const validateSimId = createAction("Landing4G/validateSimId");
export const get4GPageLoadData = createAction("Landing4G/get4GPageLoadData");
export const getPlanPreselection = createAction(
  "Landing4G/getPlanPreselection"
);
export const clearCartAndAdd4G = createAction("Landing4G/clearCartAndAdd4G");
export const addPlanAndRouter = createAction("Landing4G/addPlanAndRouter");
export const update4GPlanAndFetchRouters = createAction(
  "Landing4G/update4GPlanAndFetchRouters"
);
export const updateFCCTemplate = createAction("Landing4G/updateFCCTemplate");
export const updateFCCJson = createAction("Landing4G/updateFCCJson");
export const storeFccDetails = createAction("Landing4G/storeFccDetails");
export const getComibnedPlanAndRouters = createAction("Landing4G/getComibnedPlanAndRouters");
export const getPlandAndRouters = createAction("Landing4G/getPlandAndRouters");

export const {
  reset4GState,
  reset4GSelectedPlan,
  setQuantity,
  setSelectedPlan,
  setSelectedRouter,
  setSelectedRouterPayment,
  setPrevSelectedRouterPayment,
  set4GLandingPageLoading,
  setAPIError,
  setBackupPlans,
  setCappedPlans,
  setPlansDetails,
  setRouterDeviceResp,
  setRouterUpdateResp,
  setTabIndex,
  setUncappedPlans,
  setValidateDeviceIdResp,
  setValidateSimIdResp,
  setInitialLoad,
  setPlanPreselection,
  setImeiCheckAttempt,
  setAuthCartSuccess,
  setIsEditLTEPrimaryPlansFlow,
  setIsEditLTEBackupPlansFlow,
  setIsEditLTEEquipmentFlow,
  setIsFrom4GLearn,
  setIsIntcmpLearn,
  setpackageCompatible,
  setGrpId,
  setBackupGrpId,
  sethtmlTemplateFcc,
  setPlansDetailsFcc,
  setTaxesCharges,
  setLteLearnShowAddressModal,
  setStoreFCCDetails,
  setShowCheckAvailabiltyLink,
  setShowInternetMobilePlan,
  setSaveCartLteAvailble,
  setCheckAvailabilityRedirection,
  setAddressMicroUnit,
  setLoopCallFromLearnPageCompleted,
  setToggleForBandRouter,
  setAddtoCartResponse,
  setFWABundleTabSelected,
  setStoreApiCalled,
  setCombinedResponse
} = Landing4GSlice.actions;
