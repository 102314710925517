import React from "react";
import { Button, TextLink } from "@vds3/buttons";
import { Modal, ModalBody, ModalTitle } from "@vds3/modals";
import { Body, Title } from "@vds3/typography";
import styled from "styled-components";
import { Icon } from "@vds3/icons";
import { BlackCircleShape } from "common/components/SVGIcons";
import { MarginSpacerM, MarginSpacerS } from "../../../../common/components/styleTag";

const TCModal = styled(Modal)`
  width: 70vw;
  height:100%;
  max-height:600px;
  [class^=Wrapper-VDS]{
    height:100% !important;
  }
  [class^=Wrapper-VDS]>div{
    height:100% !important;
  }
  min-height: auto;
  outline: none !important;
  > div:first-child {
    overflow: hidden;
  }
  .tc-header {
    margin-bottom: 10px;
    .tc-subheader {
      display: flex;
      justify-content: space-between;
      padding: 0.75rem 0;
      margin: 0 1rem;
      align-items: center;
      border-bottom: 4px solid #000;
      p {
        font-weight: bold;
        margin: 0;
        font-size: 14px;
      }
    }
    .btn-grp {
      padding-top: 0.75rem;
      display: flex;
      a:first-child {
        margin-right: 10px;
      }
    }
  }
  .tc-body {
    padding: 0 2rem 0;
    .font-16 {
      &.bold {
        font-weight: bold;
      }
    }
    li {
      &.margin-bottom-li {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
      div span.dot {
        width:6px;
        height:6px;
        color: red;
      }
    }
    table,
    .Table {
      border-collapse: collapse;
      border-spacing: 0;
      text-align: left;
      width: 100%;
      margin-bottom: 1.3125rem;
      border: 0.0625rem solid #000000;
      display: block;
      overflow-x: auto;
      border: none;
      td {
        padding: 0.875rem;
        color: #000000;
        border: 0.0625rem solid #000000;
      }
      th,
      td {
        border-collapse: collapse;
        border-spacing: 0;
        vertical-align: center;
      }
    }
    thead {
      td {
        background-color: #d3d3d3;
      }
    }
    .important-things-wraper table {
      border: none;
    }
    .important-things-wraper .dp-table tr td {
      width: 35%;
      padding: 3px 5px;
      border: 0px solid #000;
      word-break: break-word;
    }
    .businesssInternetBackupPlansLte {
      .backup-plan-table {
        text-align: center;
        tbody {
          width: 100%;
          display: table;
        }
        th {
          background: #000000;
          border: 1px solid #000000;
          color: #ffffff;
          h4, p {
            margin: 0;
            padding: 0;
          }
        }
        td {
          padding: 0;
          &.bold {
            font-weight: bold !important;
          }
          &.backup-plan-content {
            text-align: left;
            padding: 0px 10px;
          }
        }
      }
      .bold {
        font-weight: bold !important;
      }
      .font-14 {
        font-size: 14px;
      }
      .font-16 {
        font-size: 16px;
      }
      .font-18 {
        font-size: 18px;
      }
      .font-20 {
        font-size: 20px;
      }
      .text-decoration-underline {
        text-decoration: underline;
      }
    }
  }
  .tc-btn {
    display: flex;
    justify-content: flex-start;
    padding-top: 1rem;
    position: sticky;
    bottom: 0px;
    z-index: 999;
    outline: none;
    background-color: #fff;    
  }
  @media only screen and (max-width: 767px) {
    .LyFRG, .ktOYqZ {
      padding-bottom: 0px !important;
      padding-left: 0px !important;
    }
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    > div:first-child {
      padding: 2px;
    }
    .tc-subheader {
      padding: 1rem 0 !important;
      align-items: unset !important;
      flex-direction: column;
      gap: 1rem;
      .btn-grp {
        margin: 0;
      }
    }
    .tc-btn {
      flex-direction: column;
      padding: 2rem 0;
      position: fixed;
      width: 100%;
      button{
        width: 93%;
      }
    }
  }
  }
`;

const TCModalBody = styled(ModalBody)`
  outline: none !important;
  overflow-y: scroll;
  padding: 0.5rem 0.5rem 0;
`;

const cdataRegex = /<!\[CDATA\[(.*)\]\]>/;

const base64ToArrayBuffer = (base64) => {
  var tmp = window.btoa(encodeURIComponent(base64));
  var binaryString = decodeURIComponent(window.atob(tmp));
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

const sanitizeHtml = (html) => {
  const matches = html && html.match(cdataRegex);
  return matches?.length && matches?.length > 1
    ? matches[1]
    : html;
}

const getTermsItems = (
  aemContent,
  flow,
  flowFlags,
  isUnifiedCart,
  isMixedCart,
  response,
  isOnlyBussProduct
) => {
  let items = [];
  let itemKey = "tncItems";
  let aemTnC = aemContent[itemKey];

  const is4gOnly = !isUnifiedCart && !isMixedCart && flowFlags?.is4G;
  const is5gOnly = !isUnifiedCart && !isMixedCart && flowFlags?.is5G;
  const isOneTalkOnly = !isUnifiedCart && !isMixedCart && flowFlags?.isOneTalk;
  const isWirelessOnly = !isUnifiedCart && !isMixedCart && flowFlags?.isWireless;

  if (isUnifiedCart || flow === "unified") {
    for (let terms in response?.tncMap) {
      let validTncInfo = removeFalseAgreement(response?.tncMap[terms]);
      if (terms) {
        switch (terms) {
          case "FIVEG":
            itemKey = "tncItems5g";
            aemTnC = aemContent[itemKey];
            if (response?.tncMap[terms].businesssInternetPlans5g ||
              response?.tncMap[terms].businesssInternet5gPlansMMW) {
              items.push({
                ...aemTnC["businessInternetPlans5gMMW"],
                packageType: terms,
                tncInfo: validTncInfo,
              });
            }
            if (response?.tncMap[terms].businesssInternet5gPlansStarkMMW) {
              items.push({
                ...aemTnC["businesssInternet5gPlansStarkMMW"],
                packageType: terms,
                tncInfo: validTncInfo,
              });
            }
            if (response?.tncMap[terms].cbandTnC) {
              items.push({
                ...aemTnC["cbandTnC"],
                packageType: terms,
                tncInfo: validTncInfo,
              });
            }
            if (response?.tncMap[terms].dppTnC) {
              items.push({
                ...aemTnC["dppTnC"],
                packageType: terms,
                tnc: sanitizeHtml(response?.tncMap[terms]?.agreementHtml),
              });
            }
            if (response?.bussTnC) {
              items.push({
                ...aemTnC["bussTnC"],
                packageType: terms,
                tnc: sanitizeHtml(response?.bussIOTTnCAgreementHTML),
              });
            }
            break;
          case "FOURG":
            itemKey = "tncItems4g";
            aemTnC = aemContent[itemKey];
            if (response?.tncMap[terms].businesssInternetPlansLte) {
              items.push({
                ...aemTnC["businesssInternetPlansLte"],
                packageType: terms,
                tncInfo: validTncInfo,
              });
            }
            if (response?.tncMap[terms].businesssInternetBackupPlansLte) {
              items.push({
                ...aemTnC["businesssInternetBackupPlansLte"],
                packageType: terms,
                tncInfo: validTncInfo,
              });
            }
            if (response?.tncMap[terms].businesssInternetPlusPlansLte) {
              items.push({
                ...aemTnC["businesssInternetPlusPlansLte"],
                packageType: terms,
                tncInfo: validTncInfo,
              });
            }
            if (response?.tncMap[terms].dppTnC) {
              items.push({
                ...aemTnC["dppTnC"],
                packageType: terms,
                tnc: sanitizeHtml(response?.tncMap[terms]?.agreementHtml),
              });
            }
            break;
          case "ONETALK":
            itemKey = "tncItemsOT";
            aemTnC = aemContent[itemKey];
            if (response?.tncMap[terms].businesssInternetPlansOneTalk) {
              items.push({
                ...aemTnC["onetalkVerizon"],
                packageType: terms,
                tncInfo: validTncInfo,
              });
            }
            if (response?.tncMap[terms].dppTnC) {
              items.push({
                ...aemTnC["dppTnC"],
                packageType: terms,
                tnc: sanitizeHtml(response?.tncMap[terms]?.agreementHtml),
              });
            }
            break;
          case "REGULAR":
            itemKey = "tncItems";
            aemTnC = aemContent[itemKey];
            if (response?.tncMap[terms].businessUnlimitedTnC) {
              items.push({
                ...aemTnC["businessUnlimitedTnC"],
                packageType: terms,
                tncInfo: validTncInfo,
              });
            }
            if (response?.tncMap[terms].newBusinessUnlimited5gTnc) {
              items.push({
                ...aemTnC["newBusinessUnlimited5gTnc"],
                packageType: terms,
                tncInfo: validTncInfo,
              });
            }
            if (response?.tncMap[terms].bussTnC) {
              items.push({
                ...aemTnC["bussTnC"],
                packageType: terms,
                tncInfo: validTncInfo,
              });
            }
            if (response?.tncMap[terms]?.deviceTradeInTnC) {
              items.push({
                ...aemTnC["deviceTradeInTnC"],
                packageType: terms,
                tncInfo: validTncInfo,
              });
            }
            if (response?.tncMap[terms].dppTnC) {
              items.push({
                ...aemTnC["dppTnC"],
                packageType: terms,
                tnc: sanitizeHtml(response?.tncMap[terms]?.agreementHtml),
              });
            }
            if (flowFlags?.isWireless) {
              if (response?.tncMap[terms].businesssMobileSecureTnC) {
                items.push({
                  ...aemTnC["businesssMobileSecureTnC"],
                  packageType: terms,
                  tncInfo: validTncInfo,
                });
              }
              if (response?.tncMap[terms].businessDataDeviceUnlimitedTnC) {
                items.push({
                  ...aemTnC["businessDataDeviceUnlimitedTnC"],
                  packageType: terms,
                  tncInfo: validTncInfo,
                });
              }
              if (response?.tncMap[terms].deviceProtectionTnC) {
                items.push({
                  ...aemTnC["deviceProtectionTnC"],
                  packageType: terms,
                  tncInfo: validTncInfo,
                });
              }
            }
            break;
          case "IOT":
            itemKey = "tncItemsBussAlone";
            aemTnC = aemContent[itemKey];
            if (response?.tncMap[terms].bussTnC) {
              items.push({
                ...aemTnC["bussTnC"],
                packageType: terms,
                tncInfo: validTncInfo,
              });
            }
            if (response?.tncMap[terms].dppTnC) {
              items.push({
                ...aemTnC["dppTnC"],
                packageType: terms,
                tnc: sanitizeHtml(response?.tncMap[terms]?.agreementHtml),
              });
            }
            break;
          default:
            break;
        }
      }
    }
  } else {
    if (flow === "4g" || is4gOnly) {
      itemKey = "tncItems4g";
      aemTnC = aemContent[itemKey];
      if (response?.businesssInternetPlansLte) {
        items.push({ ...aemTnC["businesssInternetPlansLte"] });
      }
      if (response?.businesssInternetBackupPlansLte) {
        items.push({ ...aemTnC["businesssInternetBackupPlansLte"] });
      }
      if (response?.businesssInternetPlusPlansLte) {
        items.push({ ...aemTnC["businesssInternetPlusPlansLte"] });
      }
      if (response?.dppTnC) {
        items.push({
          ...aemTnC["dppTnC"],
          tnc: sanitizeHtml(response?.agreementHtml),
        });
      }
      if (response?.bussTnC) {
        items.push({
          ...aemTnC["bussTnC"],
          tnc: sanitizeHtml(response?.bussIOTTnCAgreementHTML),
        });
      }
    } else if (flow === "5g" || is5gOnly) {
      itemKey = "tncItems5g";
      aemTnC = aemContent[itemKey];
      if (response?.businesssInternetPlans5g || response?.businesssInternet5gPlansMMW) {
        items.push({ ...aemTnC["businessInternetPlans5gMMW"] });
      }
      if (response?.businesssInternet5gPlansStarkMMW) {
        items.push({ ...aemTnC["businesssInternet5gPlansStarkMMW"] });
      }
      if (response?.cbandTnC) {
        items.push({ ...aemTnC["cbandTnC"] });
      }
      if (response?.dppTnC) {
        items.push({
          ...aemTnC["dppTnC"],
          tnc: sanitizeHtml(response?.agreementHtml),
        });
      }
      if (response?.bussTnC) {
        items.push({
          ...aemTnC["bussTnC"],
          tnc: sanitizeHtml(response?.bussIOTTnCAgreementHTML),
        });
      }
    } else if (flow === "OneTalk" || isOneTalkOnly) {
      itemKey = "tncItemsOT";
      aemTnC = aemContent[itemKey];
      if (response?.businesssInternetPlansOneTalk) {
        items.push({ ...aemTnC["onetalkVerizon"] });
      }
      if (response?.dppTnC) {
        items.push({
          ...aemTnC["dppTnC"],
          tnc: sanitizeHtml(response?.agreementHtml),
        });
      }
    } else if (flow === "Regular" || isWirelessOnly) {
      itemKey = "tncItems";
      aemTnC = aemContent[itemKey];
      if (response?.businesssMobileSecureTnC) {
        items.push(aemTnC["businesssMobileSecureTnC"]);
      }
      if (response?.businessUnlimitedTnC) {
        items.push(aemTnC["businessUnlimitedTnC"]);
      }
      if (response?.newBusinessUnlimited5gTnc) {
        items.push(aemTnC["newBusinessUnlimited5gTnc"]);
      }
      if (response?.businessDataDeviceUnlimitedTnC) {
        items.push(aemTnC["businessDataDeviceUnlimitedTnC"]);
      }
      if (response?.deviceProtectionTnC) {
        items.push(aemTnC["deviceProtectionTnC"]);
      }
      if (response?.deviceTradeInTnC) {
        items.push(aemTnC["deviceTradeInTnC"]);
      }
      if (response?.dppTnC) {
        items.push({
          ...aemTnC["dppTnC"],
          tnc: sanitizeHtml(response?.agreementHtml),
        });
      }
      if (response?.bussTnC) {
        items.push({
          ...aemTnC["bussTnC"],
          tnc: sanitizeHtml(response?.bussIOTTnCAgreementHTML),
        });
      }
    } else if (isOnlyBussProduct) {
      if (response?.terms?.termid) {
        items.push({
          ...aemContent["tncItemsBussAlone"]["bussTnC"],
          tnc: sanitizeHtml(response?.terms?.termText),
        });
      }
    }
    else {
      if (response?.businessDataDeviceUnlimitedTnC) {
        items.push(aemContent["tncItems"]["businessDataDeviceUnlimitedTnC"]);
      }
      if (response?.businessUnlimitedTnC) {
        items.push(aemContent["tncItems"]["businessUnlimitedTnC"]);
      }
      if (response?.newBusinessUnlimited5gTnc) {
        items.push(aemContent["tncItems"]["newBusinessUnlimited5gTnc"]);
      }
      if (response?.businesssInternetPlans5g || response?.businesssInternet5gPlansMMW) {
        items.push(aemContent["tncItems5g"]["businesssInternet5gPlansMMW"]);
      }
      if (response?.businesssInternet5gPlansStarkMMW) {
        items.push(aemContent["tncItems5g"]["businesssInternet5gPlansStarkMMW"]);
      }
      if (response?.businesssInternetPlansLte) {
        items.push(aemContent["tncItems4g"]["businesssInternetPlansLte"]);
      }
      if (response?.businesssInternetBackupPlansLte) {
        items.push(aemContent["tncItems4g"]["businesssInternetBackupPlansLte"]);
      }
      if (response?.businesssInternetPlansOneTalk) {
        items.push(aemContent["tncItemsOT"]["onetalkVerizon"]);
      }
      if (response?.businesssInternetPlusPlansLte) {
        items.push(aemContent["tncItems4g"]["businesssInternetPlusPlansLte"]);
      }
      if (response?.businesssMobileSecureTnC) {
        items.push(aemContent["tncItems"]["businesssMobileSecureTnC"]);
      }
      if (response?.bussTnC) {
        items.push({
          ...aemContent["tncItems"]["bussTnC"],
          tnc: sanitizeHtml(response?.bussIOTTnCAgreementHTML),
        });
      }
      if (response?.cbandTnC) {
        items.push(aemContent["tncItems5g"]["cbandTnC"]);
      }
      if (response?.deviceProtectionTnC) {
        items.push(aemContent["tncItems"]["deviceProtectionTnC"]);
      }
      if (response?.deviceTradeInTnC) {
        items.push(aemTnC["deviceTradeInTnC"]);
      }
      if (response?.dppTnC) {
        items.push({
          ...aemContent["tncItems"]["dppTnC"],
          tnc: sanitizeHtml(response?.agreementHtml),
        });
      }
    }
  }
  return items;
};

const handlePrint = (title) => {
  const tandCPrintContent = document.querySelector("#termsConditions");
  const printWindow = window.open("", "", "popup");
  printWindow.document.open();
  printWindow.document.write(`
    <html>
    <head>
      <title>${title}</title>
      <style>
      @media print {
        table {
          border-collapse: collapse;
          border-spacing: 0;
          text-align: left;
          width: 100%;
        }
        table td {
          padding: 0.875rem;
          background-color: #ffffff;
          color: #000000;
          border: 0.0625rem solid #000000;
      }
      }
      </style>
    </head>
    <body>
    ${tandCPrintContent.innerHTML}
    </body>
    </html>
    `);
  printWindow.print();
  printWindow.document.close();
  printWindow.close();
};

const handleSave = (tncItems, activeIndex) => {
  if (tncItems[activeIndex].id === "dppTnC" || tncItems[activeIndex].id === "bussTnC") {
    printSaveBlob(tncItems[activeIndex].tnc);
  } else {
    window.open(tncItems[activeIndex].url, "_blank");
  }
};

const printSaveBlob = (content) => {
  let byte = base64ToArrayBuffer(content);
  var blob = new Blob([byte], { type: "text/html" });
  var url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.target = "blank";
  a.click();
  a.remove();
};

const removeFalseAgreement = (tncInfo) => {
  let tncInfoObj = {};
  for (let key in tncInfo) {
    if (
      tncInfo[key] === false ||
      key === "agreementGenerationSuccess" ||
      key === "agreementHtml" ||
      key === "agreementPDF" ||
      key === "serviceStatus"
    ) {
    } else {
      tncInfoObj[key] = "Yes";
    }
  }
  return tncInfoObj;
};

const renderTermsList = (tncItems, isHavingUncappedPlan, setIndex, isAutomationComplete, isfromQuote = false) => {
  let lastPkgType = "";
  let renderPackageType = false;
  return tncItems.map((element) => {
    renderPackageType = false;
    if (element?.packageType && element?.packageType !== lastPkgType) {
      renderPackageType = true;
    }
    lastPkgType = element?.packageType;

    let Tag = isfromQuote ? 'li' : 'div';
    let ParentTag = isfromQuote ? 'div' : "li";
    return (
      <ParentTag style={{ listStyleType: "none" }} key={`${element?.packageType}-${element?.heading}`}>
        {!!renderPackageType && (
          <div>
            <Title size="medium" primitive="h4" bold>
              {element?.packageType === "FOURG"
                ? isHavingUncappedPlan
                  ? "LTE Business internet plus"
                  : "LTE Business internet"
                : element?.packageType === "FIVEG"
                  ? "5G Business internet"
                  : element?.packageType === "REGULAR"
                    ? "Wireless"
                    : element?.packageType === "ONETALK"
                      ? "Onetalk"
                      : element?.packageType}
            </Title>
            <MarginSpacerS />
          </div>
        )}
        <Tag>
          {element?.signed ? (
            <div style={{ paddingRight: "0.5rem", display: "inline" }}><Icon name="Checkmark" color="#000" size="12px" surface="dark" /></div>
          ) : (
            <div style={{ paddingRight: "1rem", display: "inline" }}><BlackCircleShape width={6} height={6} /></div>
          )}
          {element?.signed || isAutomationComplete ? (
            <TextLink role="link" type="standAlone" onClick={() => setIndex(element)}>
              {element?.heading}
            </TextLink>
          ) : (
            <Body size="large" primitive="span">{element?.heading}</Body>
          )}
          <MarginSpacerM />
        </Tag>
      </ParentTag>
    );
  });
};

const businessContractsTermModal = (
  activeIndex,
  isOrderQuoteFlow = false,
  onContinueClick,
  setShowModal,
  showModal,
  t,
  tncItems,
  isAbandonedCart = false,
) => {

  const clickContinue = (e) => {
    onContinueClick(e?.target?.innerText);
    let ab = document.getElementsByClassName("tc-subheader")
    if(ab && ab.length ){
      setTimeout(()=>ab[0].scrollIntoView({ behavior: "smooth"}),100)
    }
  };

  return (
    <TCModal
      ariaLabel=""
      opened={showModal}
      onOpenedChange={setShowModal}
      disableOutsideClick={true}
      id="termsModal"
      surface="light"
    >
      <div className="tc-subheader">
        <p>
          Agreement {activeIndex + 1} of{" "}
          {tncItems?.length > 0 ? tncItems?.length : 1}
        </p>

        <div className="tc-header" id="termsHeader">
          <ModalTitle>
            <Title size="medium" bold>{tncItems[activeIndex]?.heading}</Title>
          </ModalTitle>

          <div className="btn-grp">
            <TextLink
              size="large"
              role="button"
              onClick={() => handlePrint(tncItems[activeIndex]?.heading)}
            >
              {t("terms.print", { ns: "terms" })}
            </TextLink>
            <TextLink
              size="large"
              role="button"
              onClick={() => handleSave(tncItems, activeIndex)}
            >
              {t("terms.save", { ns: "terms" })}
            </TextLink>
          </div>
        </div>
      </div>
      <TCModalBody> 
        <div
          id="termsConditions"
          className="tc-body"
          dangerouslySetInnerHTML={{ __html: tncItems[activeIndex]?.tnc }}
          />
      </TCModalBody>
      <div className="tc-btn">
        <Button onClick={clickContinue} data-track={`Business contracts:terms:Retail Account Agreement:${(isOrderQuoteFlow || isAbandonedCart)
            ? !tncItems[activeIndex]?.signed
              ? t("terms.approve", { ns: "terms" })
              : t("terms.close", { ns: "terms" })
            : !tncItems[activeIndex]?.signed
              ? t("terms.approve", { ns: "terms" })
              : t("terms.close", { ns: "terms" })}`}>
          {(isOrderQuoteFlow || isAbandonedCart)
            ? !tncItems[activeIndex]?.signed
              ? t("terms.approve", { ns: "terms" })
              : t("terms.close", { ns: "terms" })
            : !tncItems[activeIndex]?.signed
              ? t("terms.approve", { ns: "terms" })
              : t("terms.close", { ns: "terms" })}
        </Button>
      </div>
    </TCModal>
  );
};

const functions = {
  base64ToArrayBuffer,
  getTermsItems,
  handlePrint,
  handleSave,
  removeFalseAgreement,
  renderTermsList,
  businessContractsTermModal,
};

export default functions;
