import { TextLink, Button } from "@vds3/buttons";
import { Modal, ModalBody } from "@vds3/modals";
import { Body, Micro, Title } from "@vds3/typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
//import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useImageUtils } from "hooks";
import CloseIcon from "common/components/SVGIcons/CloseIcon";
import { getProductType } from "utils/commonUtils";
import {
	setCategoryName,
	setDeviceId,
	setSelectedSku,
} from "../../ducks/MobilitySlice";
import { ellipsisShorten } from "../../../../utils/commonUtils";
import { useMediaQuery } from "react-responsive";

const DetailsModal = styled(Modal)`
	iframe {
		min-height: 250px !important;
		width: 100%;
		height: 100vh;
		border: none !important;

		@media only screen and (max-width: 420px) {
			overflow: auto;
			::-webkit-scrollbar {
				width: 4px;
				height: 20px;
				background: #d8dada;
				border-radius: 10px;
			}
			::-webkit-scrollbar-thumb {
				background: #6f7171;
				border-radius: 10px;
			}
		}
	}
`;
const DetailsModalBody = styled(ModalBody)``;

const CloseIconContainer = styled.div`
	padding-left: 0.2rem;
	padding-top: 0rem;
	margin: 10px 10px 10px auto;
	cursor: pointer;
	font-size: 1.5rem;
	font-weight: 400;

	@media only screen and (max-width: 520px) {
		margin: 0px;
		right: 10px;
		top: 10px;
		position: absolute;
		padding: 0px;
	}
`;

const DeviceContainer = styled.div`
	border-radius: 8px;
	background-color: #f6f6f6;
	margin: 5px;
	width: 290px;
	${({ fromPdp }) => fromPdp && `height: 502px;`}
	position:relative;
	padding-bottom: 5rem;
	box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.02);
	@media only screen and (max-width: 767px) {
		width: 100%;
	}

	@media only screen and (max-width: 520px) {
		padding-left: 12px;
		padding-right: 12px;
		margin: 0px;
	}
`;

const DeviceImage = styled.div`
	max-width: 100%;
	padding-bottom: 20px;
	cursor: pointer;
	img {
		width: 186px;
		height: 186px;
		left: 52px;
		top: 1.25rem;
		position: relative;
		margin-top: 30px;
	}

	@media only screen and (max-width: 767px) {
		img {
			width: 134px !important;
			height: 134px;
			left: 0px;
			top: 15px;
		}
	}

	@media only screen and (max-width: 520px) {
		img {
			width: 130px !important;
			margin-top: 50px;
		}
	}
`;

const DeviceContent = styled.div`
	padding-top: 1.25rem;
	margin-bottom: 20px;
	display: flex;
	min-height: 30px;

	@media only screen and (max-width: 545px) {
		min-height: 192px;
	}
`;

const DeviceContentText = styled.div`
	display: flex;

	flex-direction: column;
	text-align: center;
	h1 {
		margin-bottom: 20px;
	}
	@media only screen and (max-width: 767px) {
		h1 {
			font-size: 12px;
		}
	}
	text {
		font-family: "Verizon NHG eDS";
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 24px;
	}

	@media only screen and (max-width: 520px) {
		h1 {
			${({ maxDeviceLength, deviceTitleMaxLimit }) =>
				maxDeviceLength > 62
					? "min-height:72px"
					: deviceTitleMaxLimit?.length > 20
					? "min-height:42px"
					: "min-height:48px"}
		}
	}
`;

const DevicePriceInformation = styled.div`
	@media only screen and (max-width: 520px) {
		min-height: 64px;
	}
`;
const TextLinkXS = styled(TextLink)`
	font-size: 12px !important;
	border-bottom: none !important;
	text-decoration: underline;
	margin-left: 6px;
`;

const PricingDetails = styled.div`
	color: #6f7171 !important;
`;

const StrikeThrough = styled.span`
	text-decoration: line-through;
`;

const PricingData = styled.div`
	font-size: 12px;
`;
const SelectDeviceButton = styled(Button)`
	position: absolute;
	left: 50%;
	${({ fromPdp }) => !fromPdp && `bottom: 30px;`}
	transform: translate(-50%, 0px);
	span {
		padding: 0.6875rem 1.75rem;
	}
	.noOutline:focus-visible {
		outline: none;
	}
	.noOutline:focus {
		outline: none;
	}
	@media only screen and (max-width: 767px) {
		height: 2rem !important;
		span {
			padding: 6px 15px !important;
			margin: 7px !important;
			font-size: 12px !important;
		}
	}
`;

const DeviceDetails = ({
	deviceId,
	phoneOverview: { brandName, phoneName, imageset, seoUrlName },
	pricingDetails = null,
	seoURL,
	outOfStock,
	device,
	props,
	onCloseClick = () => {},
	aem,
	fromPdp = false,
	hideDetailsBtn = false,
	maxDeviceLength,
	modelName,
}) => {
	const dispatch = useDispatch();
	//const navigate = useNavigate();
	const { t, i18n } = useTranslation(["app", "gridwall"]);
	const [showModal, setShowModal] = useState(false);
	const [promo, setPromo] = useState("");
	const { getImageLink } = useImageUtils();

	const devicePaymentDetail =
		pricingDetails?.devicePayments?.length && pricingDetails?.devicePayments[0];

	const isMobile = useMediaQuery({
		query: "(max-width: 725px)",
	});

	let deviceTitleMaxLimit = isMobile
		? ellipsisShorten(brandName + " " + phoneName, 62)
		: ellipsisShorten(brandName + " " + phoneName, 90);

	useEffect(() => {
		const dppDetail =
			pricingDetails?.devicePayments?.length && pricingDetails?.devicePayments[0];
		if (
			Array.isArray(dppDetail?.promoDetails) &&
			dppDetail?.promoDetails?.length
		) {
			const promoDetail = dppDetail.promoDetails[0].promoMessages;
			if (Array.isArray(promoDetail) && promoDetail?.length) {
				setPromo({
					header: promoDetail[0]?.badgeText,
					tooltipText: promoDetail[1] && promoDetail[1]?.badgeText,
					modalContentUrl: promoDetail[1] && promoDetail[1]?.badgeToolTipUrl,
				});
			}
		}
	}, [pricingDetails]);

	const onCardSelect = () => {
		dispatch(setDeviceId(deviceId));
		dispatch(setSelectedSku(device));
		const productType = getProductType();
		dispatch(setCategoryName(productType));
		if (
			fromPdp &&
			typeof window !== undefined &&
			window?.newrelic?.setCustomAttribute
		) {
			window.newrelic.setCustomAttribute("pdpcomp", "Y");
		}
		let devicePath = seoUrlName || seoURL;
		// navigate(`/products/devices/${productType}/${devicePath}`, {
		// 	replace: !!fromPdp,
		// });
	};

	const getStartPrice = () => {
		if (!pricingDetails) {
			return "";
		}
		let price = 0;
		const pricing = pricingDetails;
		if (pricing?.devicePayments?.length && pricing?.devicePayments[0]) {
			price = Number(pricing?.devicePayments[0]?.offerDetails?.discountAmount)
				? Number(pricing?.devicePayments[0]?.offerDetails?.discountAmount)
				: Number(pricing?.devicePayments[0]?.finalPrice);
		}
		return Number(price).toFixed(2);
	};

	const getDisplayPrice = () => {
		if (!pricingDetails) {
			return "";
		}
		const price = getStartPrice();
		return (
			"$" +
			t("gridwall.labels.deviceFinalPrice", { ns: "gridwall" }).replace(
				"{{finalPrice}}",
				price
			)
		);
	};

	const getInstallments = () => {
		if (!pricingDetails) {
			return "";
		}
		const num = pricingDetails?.devicePayments?.[0]?.nofInstallements;
		let text = t("gridwall.labels.deviceFinalPriceInMonths", { ns: "gridwall" });
		text = text?.replace("{{paymentTenure}}", num).replaceAll(";", "");
		return text;
	};

	const getRetailPrice = () => {
		if (!pricingDetails) {
			return "";
		}
		const price = Number(pricingDetails?.zeroYear?.price).toFixed(2);
		return t("gridwall.labels.retailPrice", { ns: "gridwall" }).replace(
			"{{retailPriceZeroYear}}",
			`$${price}`
		);
	};

	const showDiscount = (devicePaymentDetail) => {
		const { offerDetails = { discountAmount: 0 }, finalPrice = 0 } =
			devicePaymentDetail || {
				offerDetails: { discountAmount: 0 },
				finalPrice: 0,
			};

		return (
			Number(offerDetails?.discountAmount) >= 0 &&
			Number(offerDetails?.discountAmount) < finalPrice
		);
	};

	return (
		<DeviceContainer data-loc={modelName} fromPdp={fromPdp}>
			<div>
				{!fromPdp && (
					<div style={{ display: "flex", height: "0px", marginBottom: "0px" }}>
						<CloseIconContainer>
							<CloseIcon onClickFn={() => onCloseClick(deviceId)} />
						</CloseIconContainer>
					</div>
				)}
				<DeviceImage>
					<img
						alt=""
						aria-label=""
						loading="lazy"
						src={getImageLink(imageset, 250, 250)}
						onClick={onCardSelect}
					/>
				</DeviceImage>
				<DeviceContent>
					<DeviceContentText
						maxDeviceLength={maxDeviceLength}
						style={{ width: "100%" }}
					>
						<Title size="medium" bold={true} viewport="mobile" primitive="h1">
							{deviceTitleMaxLimit}
						</Title>
						<>
							<DevicePriceInformation>
								<PricingData>
									<Micro>
										<Body size={fromPdp && !isMobile ? "large" : "small"}>
											{t("gridwall.labels.startsAt", { ns: "gridwall" })}{" "}
											{getDisplayPrice()}
										</Body>
									</Micro>
								</PricingData>
								{Number(devicePaymentDetail?.discountAmount) >= 0 &&
									Object.keys(promo)?.length > 0 &&
									!props?.isBogo && (
										<>
											<PricingData>
												{fromPdp ? (
													<Body size={isMobile ? "small" : "large"}>
														{t("gridwall.labels.was", { ns: "gridwall" })}&nbsp;
														<StrikeThrough>${devicePaymentDetail?.finalPrice} </StrikeThrough>
														{promo && Object.keys(promo)?.length > 0 ? (
															<TextLink
																size={isMobile ? "small" : "large"}
																onClick={() => setShowModal(true)}
															>
																{t("gridwall.labels.details", {
																	ns: "gridwall",
																})}
															</TextLink>
														) : (
															""
														)}
													</Body>
												) : (
													<Micro size="large">
														{t("gridwall.labels.was", { ns: "gridwall" })}&nbsp;
														<StrikeThrough>${devicePaymentDetail?.finalPrice}</StrikeThrough>
														{promo && Object.keys(promo)?.length > 0 ? (
															<TextLinkXS size={"small"} onClick={() => setShowModal(true)}>
																{t("gridwall.labels.details", {
																	ns: "gridwall",
																})}
															</TextLinkXS>
														) : (
															""
														)}
													</Micro>
												)}
											</PricingData>
										</>
									)}
								{!outOfStock && (
									<Micro>
										<PricingDetails>
											{fromPdp ? (
												<Body size={isMobile ? "small" : "large"} color={"#6F7171"}>
													{getInstallments(pricingDetails)}
												</Body>
											) : (
												getInstallments(pricingDetails)
											)}
										</PricingDetails>
									</Micro>
								)}
							</DevicePriceInformation>
						</>
						{outOfStock ? (
							<div style={{ marginTop: "5px" }}>
								<Body>{t("gridwall.labels.outofstock", { ns: "gridwall" })}</Body>
							</div>
						) : (
							<Micro>
								<PricingDetails>
									{fromPdp ? (
										<Body size={isMobile ? "small" : "large"} color={"#6F7171"}>
											{getRetailPrice(pricingDetails)}
										</Body>
									) : (
										getRetailPrice(pricingDetails)
									)}
								</PricingDetails>
							</Micro>
						)}
					</DeviceContentText>
				</DeviceContent>
				{!hideDetailsBtn && (
					<SelectDeviceButton
						className="noOutline"
						size="large"
						disabled={false}
						use="secondary"
						onClick={onCardSelect}
						fromPdp={fromPdp}
					>
						{fromPdp ? "Go to details" : aem?.labels?.selectDevice}
					</SelectDeviceButton>
				)}
			</div>
			{showModal && (
				<DetailsModal
					opened={showModal}
					onOpenedChange={setShowModal}
					className="card-modal"
				>
					<DetailsModalBody className="card-modal-body">
						<iframe height="100%" width="100%" src={promo?.modalContentUrl}></iframe>
					</DetailsModalBody>
				</DetailsModal>
			)}
		</DeviceContainer>
	);
};

export default DeviceDetails;
