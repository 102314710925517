// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disable-accordian{
    pointer-events: none !important;
    opacity: 0.4;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Checkout/checkout.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,YAAY;EACd","sourcesContent":[".disable-accordian{\n    pointer-events: none !important;\n    opacity: 0.4;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
