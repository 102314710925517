import React from "react";
import { useTranslation } from "react-i18next";
import { Body } from "@vds3/typography";
import { formatPriceString } from "utils/commonUtils";
import CartPkgContext from "./cartPkgContext";
import {
  PackageDetailContainer,
  PackageDetailDiv,
  PackagePriceDiv,
  OosDiv,
} from "./styles";
import {
  LineItem,
  LineItemPriceGrid,
} from "../LineItemPriceGrid/LineItemPriceGrid";

const PackageDetail = ({ cartData, ...props }) => {
  const { t } = useTranslation(["shoppingCart"]);
  const { pack, quantity, equipment, isEditable,oneTalkFlowAdded } = React.useContext(CartPkgContext);
  const isDPP = pack?.priceType === "MONTH_TO_MONTH";
  const isFWA = pack?.packageType === "FIVEG" || pack?.packageType === "FOURG";

  const totalPrice =
    pack?.groupDisplayPrice?.totalItemPrice ??
    Number(pack?.itemPrice) * quantity;
  const isDiscounted = !!pack?.displayDiscountPrice;
  const strikethroughPrice =
    pack?.groupDisplayPrice?.totalItemPriceWithOutDiscount ??
    Number(pack?.itemPriceWithOutDiscount) * quantity;

  const getPackageDisplayType = () => {
    if (pack?.byod) {
      if(pack?.packageType === 'REGULAR'){
        return t("shopping-cart.cart-devices.byod");
      } else {
        return 'Router';
      }
    } else if (pack?.packageType) {
      if (pack.packageType === "FOURG" || pack.packageType === "FIVEG") {
        if (pack?.deviceType === "FIVEGRECEIVER" && equipment?.length) {
          return t("shopping-cart.cart.productNouns.receiver", {
            count: quantity,
          });
        } else
          return t("shopping-cart.cart.productNouns.router", {
            count: quantity,
          });
      } else if (pack.packageType === "REGULAR") {
        return t("shopping-cart.cart.productNouns.device", { count: quantity });
      } else if (pack.packageType === "ONETALK") {
        return t("shopping-cart.cart.productNouns.equipment", {
          count: quantity,
        });
      }
    } else if (pack?.accessoryId) {
      return t("shopping-cart.cart.productNouns.accessory", {
        count: quantity,
      });
    }
  };
  const getOutOfStock = () => {
    return <OosDiv> 
            <Body size="small" color="#FFFFFF">{t("shopping-cart.cart-devices.outofstock")}</Body>
           </OosDiv>
  };

  const getDetailItems = () => {
    const items = [];
    items.push(getPackageDisplayType());
    if (pack?.packageType === "REGULAR" && !pack?.byod) {
      if (pack?.outOfStock) {
        items.push(getOutOfStock());
      } else if (pack?.esimCapable === "Y") {
        items.push(
          `${t("shopping-cart.cart-deviceitem.simType")}${t(
            "shopping-cart.cart-deviceitem.embeddedSim"
          )}`
        );
      } else if (pack?.psimCapable === "Y") {
        items.push(
          `${t("shopping-cart.cart-deviceitem.simType")}${t(
            "shopping-cart.cart-deviceitem.physicalSim"
          )}`
        );
      }
    }
    if (isDPP && !pack?.byod) {
      if (isFWA) {
        items.push(
          t("shopping-cart.cart.dppRouterPricingText", {
            tenure: pack?.tenure,
            price: pack?.itemRetailPrice,
          })
        );
        items.push(
          t("shopping-cart.cart.dppPerRouterPricingText", {
            tenure: pack?.tenure,
            price: pack?.itemRetailPrice,
          })
        );
      } else {
        if(oneTalkFlowAdded && pack.packageType === 'ONETALK'){
          items.push(
            t("shopping-cart.cart.dppRouterPricingText", {
              tenure: pack?.tenure,
              price: pack?.itemRetailPrice,
            })
          );
        }else{
        items.push(
          t("shopping-cart.cart.dppPricingText", {
            tenure: pack?.tenure,
            price: pack?.itemRetailPrice,
          })
        );
        }
      }
    }
    return items;
  };

  const getDisplayPrice = () => {
    if ((isFWA && !pack?.byor && totalPrice <= 0) || (pack?.byor && cartData?.cart?.mwwCart  && pack?.deviceType === "FIVEGRECEIVER")) {
      return t("shopping-cart.included");
    }  else {
      return `${formatPriceString(totalPrice)}${
        isDPP && !pack?.byod ? t("shopping-cart.cart.perMonth") : ""
      }`;
    }
  };

  return (
    <PackageDetailContainer>
      <PackageDetailDiv>
        {pack?.byor && cartData?.cart?.mwwCart && pack?.deviceType === "FIVEGRECEIVER" ?
          <><Body size="small" color="#6F7171">
            {t("shopping-cart.cart.productNouns.receiver", {
              count: quantity,
            })}
          </Body></>
          :
          getDetailItems().map((item) => (
            <Body key={item} size="small" color="#6F7171">
              {item}
            </Body>
          ))}
      </PackageDetailDiv>
      <PackagePriceDiv>
        <Body size="large">{getDisplayPrice()}</Body>
        {!!isDiscounted && (
          <Body size="large" color="#6F7171">
            {`${t("shopping-cart.cart.was")} `}
            <Body size="large" color="#6F7171" primitive="span" strikethrough>
              {formatPriceString(strikethroughPrice)}
            </Body>
          </Body>
        )}
      </PackagePriceDiv>
    </PackageDetailContainer>
  );
};

export default PackageDetail;
