import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { TextLink } from "@vds3/buttons";
import { RadioBox } from "@vds3/radio-boxes";
import { Tabs, Tab } from "@vds3/tabs";
import { Body, Micro } from "@vds3/typography";
import { MarginSpacerS } from "common/components/styleTag";
import { formatPriceString } from "utils/commonUtils";
import DeviceUnlockModal from "common/components/Modals/DeviceUnlockModal";
import PDPContext from "./pdpContext";
//import { useLocation } from "react-router-dom";

const StorageAndPrice = ({ ...props }) => {
	const { t } = useTranslation(["pdp"]);
	const {
		selectedSku: { selectedSku, setSelectedSku },
		selectedPayment: { selectedPayment, setSelectedPayment },
		addToAllLines: { addToAllLines, setAddToAllLines },
		memoryOptions,
		isOneTalk,
		tradeInValueInfo: { tradeInValueInfo },
	} = React.useContext(PDPContext);
	const devicePayments =
		selectedSku?.pricingDetails?.devicePayments &&
		selectedSku?.pricingDetails?.devicePayments[0];
	const fullRetailPricing = selectedSku?.pricingDetails?.zeroYear;
	const twoYearPricing = selectedSku?.pricingDetails?.twoYear;
	const [tabIndex, setTabIndex] = useState(undefined);
	const [isDeviceUnlock, setIsDeviceUnlock] = useState(false);
	//const location = useLocation();
	const isMobile = useMediaQuery({
		query: "(max-width: 725px)",
	});

	const PriceBoxContainer = styled.div`
		width: 100%;
		@media screen and (min-width: 230px) and (max-width: 767px) {
			margin-bottom: 1rem;
		}
		.strikethrough {
			font-size: 16px;
			font-weight: 400;
			line-height: 20px;
			letter-spacing: 0.5px;
			text-align: left;
		}

		${!isMobile &&
		`display:flex !important;
    flex-wrap:wrap;    
    `}
		margin-top: 1rem;
	`;

	const AmountTitle = styled.div`
		font-size: 16px;
		font-weight: ${isOneTalk ? "700" : "400"};
		line-height: 20px;
		letter-spacing: 0.5px;
		text-align: left;
	`;

	const CustomTab = styled(Tab)`
		margin: 0 24px 0 0;
	`;
	const isPriceBox = memoryOptions?.length == 1;
	const isPriceBoxLength4 = memoryOptions?.length > 4;
	const PriceBox = styled(RadioBox)`
		height: auto;
		flex: ${!isMobile
			? isPriceBoxLength4
				? "0 0 22%"
				: isPriceBox
				? "0.52"
				: "1"
			: " 2 1 auto"};
		max-width: ${!isMobile ? (isPriceBox ? "292px" : "100%") : " 100%"};
		margin: ${!isMobile ? "5px 12px 17px 0" : "5px 1px 12px 0"};
		${isMobile && `width:100%`}
	`;

	const getPricingDetails = (pricingDetails) => {
		if (selectedPayment === "fullpayment") {
			return pricingDetails?.zeroYear;
		} else if (selectedPayment === "twoyears") {
			return pricingDetails?.twoYear;
		} else {
			return (
				pricingDetails?.devicePayments?.length && pricingDetails?.devicePayments[0]
			);
		}
	};

	const onTabChangeFunc = (_event, index) => {
		setTabIndex(index);
		setSelectedPayment(
			index === 0
				? "monthly"
				: twoYearPricing?.contractTerm && index == 1
				? "twoyears"
				: "fullpayment"
		);
	};

	useEffect(() => {
		if (
			(props?.editPackageDeviceSku &&
				selectedSku &&
				props.editPackageDeviceSku !== selectedSku.sku) ||
			!props.editPackageDeviceSku
		) {
			if (selectedSku && tabIndex === undefined) {
				onTabChangeFunc(null, devicePayments ? 0 : 1);
			}
		} else if (
			props?.editPackageDeviceSku &&
			selectedSku &&
			props.editPackageDeviceSku === selectedSku.sku
		) {
			let index = String(selectedPayment).includes("fullpayment")
				? 2
				: String(selectedPayment).includes("twoyears")
				? 1
				: 0;
			setTabIndex(index);
		}
	}, [selectedSku]);

	return (
		<SectionContainer>
			<Tabs
				orientation="horizontal"
				indicatorPosition="bottom"
				linePosition="bottom"
				onTabChange={onTabChangeFunc}
				selectedIndex={tabIndex}
				bold={false}
			>
				<CustomTab
					label={`${devicePayments?.nofInstallements} months`}
					hide={!devicePayments}
				/>

				<CustomTab
					label={
						twoYearPricing?.contractTerm &&
						t("device-pdp.twoyearscontract", { ns: "pdp" })
					}
					hide={props?.isPlanFirstFlow || !twoYearPricing?.contractTerm}
				/>

				<CustomTab label="Full retail price" hide={!fullRetailPricing} />
			</Tabs>
			<PriceBoxContainer>
				{memoryOptions.map((sku) => {
					if (sku?.pricingDetails !== null && sku?.pricingDetails) {
						const pricingDetails = getPricingDetails(sku?.pricingDetails);
						const hasTradeInPromo =
							pricingDetails?.promoDetails?.length &&
							pricingDetails.promoDetails.find(
								(i) => i?.promoType === "TRADEIN_PER_MONTH"
							);
						const tradeInPromoPrice =
							hasTradeInPromo &&
							tradeInValueInfo?.pricing?.find(
								(i) =>
									i?.sku === sku?.sku &&
									i?.noOfInstallments === pricingDetails?.nofInstallements
							)?.price;
						if (
							selectedSku?.sku === sku?.sku &&
							hasTradeInPromo &&
							props?.tradeInForSubtitle &&
							typeof props.tradeInForSubtitle === "function" &&
							tradeInPromoPrice > -1
						) {
							props.tradeInForSubtitle(tradeInPromoPrice);
						}
						return (
							<PriceBox
								key={sku?.sku}
								selected={selectedSku?.sku === sku?.sku}
								onChange={() => setSelectedSku(sku)}
								disabled={sku?.outOfStock}
								outOfStock={sku?.outOfStock}
								children={
									<>
										<Capcitytitle data-track={sku?.capacity}>
											{sku?.capacity}
										</Capcitytitle>
										<AmountTitle bold={isOneTalk} size="small">{`${formatPriceString(
											hasTradeInPromo && tradeInPromoPrice > -1
												? tradeInPromoPrice
												: pricingDetails?.offerDetails?.discountAmount ??
														pricingDetails?.finalPrice
										)}${
											selectedPayment === "fullpayment" || selectedPayment === "twoyears"
												? ""
												: "/mo"
										}`}</AmountTitle>
										{!!(
											pricingDetails?.offerDetails?.discountAmount >= 0 ||
											hasTradeInPromo?.discAmount > 0 ||
											tradeInPromoPrice > -1
										) &&
											!props?.isBogo && (
												<Micro color="">
													<span className="strikethrough">
														{`${formatPriceString(pricingDetails?.finalPrice)}${
															selectedPayment === "fullpayment" ||
															selectedPayment === "twoyears"
																? ""
																: "/mo"
														}`}
													</span>
												</Micro>
											)}
									</>
								}
							/>
						);
					} else return <></>;
				})}
			</PriceBoxContainer>
			<Priceinfotext>
				<Body>
					{selectedPayment == "monthly" && (
						<>
							Monthly payments shown are for customers who qualify to pay $
							{devicePayments?.minimumPlanAccessFee} down, {!isMobile && <br />}
							<span>
								{" "}
								{`${formatPriceString(
									props.tradinPrice !== null && props.tradinPrice > -1
										? props.tradinPrice
										: devicePayments?.offerDetails?.discountAmount ??
												devicePayments?.finalPrice
								)}/mo`}
							</span>{" "}
							for {devicePayments?.nofInstallements} months; {isMobile && <br />}0%
							APR. Retail price: ${fullRetailPricing?.finalPrice}.
						</>
					)}
					{selectedPayment == "twoyears" && (
						<span>One time payment and 2-year service agreement required.</span>
					)}
					{selectedPayment == "fullpayment" && ""}
				</Body>
			</Priceinfotext>
			{selectedPayment == "monthly" && (
				<Links>
					{/* {!location?.pathname?.includes("mobile-hotspots") && (
            <TextLink
              className="device-info"
              onClick={() => setIsDeviceUnlock(true)}
            >
              {t("device-pdp.deviceUnlockingPolicy", { ns: "pdp" })}
            </TextLink>
          )} */}
				</Links>
			)}
			{isDeviceUnlock && (
				<DeviceUnlockModal
					opened={isDeviceUnlock}
					onOpenedChange={setIsDeviceUnlock}
				/>
			)}
			<MarginSpacerS />
		</SectionContainer>
	);
};

const Priceinfotext = styled.div`
	font-family: Verizon-NHG-eTX;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0px;
	text-align: left;
`;

const SectionContainer = styled.div`
	.device-info {
		display: inline-block;
		margin-bottom: -1rem !important;
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		margin-top: 0.3rem;
	}
`;

const Links = styled.div`
	padding-top: 0.5rem;
	display: block;
	gap: 0.75rem;
	font-family: Verizon-NHG-eTX;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0px;
	text-align: left;
`;

const Capcitytitle = styled.div`
	font-size: 16px;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 0.5px;
	text-align: left;
`;

export default React.memo(StorageAndPrice);
