import React, { useState, useEffect, useMemo, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Button, TextLink } from "@vds3/buttons";
import { Col, Grid, Row } from "@vds3/grids";
import { Input } from "@vds3/inputs";
import { Body, Micro, Title } from "@vds3/typography";
import { MarginSpacerL, MarginSpacerM, MarginSpacerXS } from "common/components/styleTag";
import { RadioButtonGroup } from "@vds3/radio-buttons";
import { Checkbox } from "@vds3/checkboxes";
import {
    getSessionCart,
    mitekCheckVerificationResult,
    setCreditPath,
    setConsetFlowSSN,
    validateSSN,
    setValidateSSNResult,
    updateDeclinedStatus,
    setSuspendFlow,
    postSaveCart
} from "../../../Checkout/ducks/CheckoutSlice";
import { useMediaQuery } from "react-responsive";
import { validateZipcode } from "../../../Mobility/ducks/MobilitySlice";
import { resetCreditSlice, setCreditHoldCode, updateProspectCreditDetails } from "../../../Checkout/CreditResult/CreditResultsNew/CreditSlice";
import { RadioBox, RadioBoxGroup } from "@vds3/radio-boxes";
import { Notification } from "@vds3/notifications";
import SaveCartModal from "common/components/SaveCartModal/SaveCartModal";
import { checkIfAemExists } from "utils/commonUtils";
import SpinnerComponent from "common/components/Spinner/Spinner";

const FormWrapper = styled.div`
  margin-left: 0px !important;
  > div {
    padding: 0px;
  }
`;
const ValditionWrapper = styled.div`
  margin: 0px 0px !important;
`;
const DigicertContainer = styled.div`

> img {
  margin-top: 30px;
  width: 44%;
}`;

const StyledLink = styled(TextLink)`
  color: #000 !important;
   border-bottom-color: #000 !important;
`;
const restrictedSSNValues = [
    "123456789",
    "987654321"
]

const StyledSpan = styled.span`
  > a {
    color: black;
  }
`;

const StyledRow = styled(Row)`
  > div {
    padding: 0rem;
  }
  padding: 0rem !important;
`;

const RadioBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const MitekDeclined = ({ ...props }) => {
    const [zipCodeErrorValue, setZipCodeErrorValue] = useState("");
    const [SSNErrorValue, setSSNErrorValue] = useState("");
    const [ssnLabel, setSSNLabel] = useState(true);
    const [zipCodeInputValue, setZipCodeInputValue] = useState("");
    const [shouldAgree, setShouldAgree] = useState(false);
    const [zipCodeInProgress, setZipCodeInProgress] = useState(false);
    const [SSNValid, setSSNValid] = useState(false);
    const [validationSelect, setValidationSelect] = useState(!props?.mitekFailed ? "addSSN" : "visitStore");
    const [SSN, setSSN] = useState("");
    const [showNotificationError, setShowNotificationError] = useState("");
    const [showStoreError, setShowStoreError] = useState(false);

    const dispatch = useDispatch();
    const isMobile = useMediaQuery({ query: "(max-width: 544px)" });
    const mobilityPageData = useSelector((state) => state.ProspectWidgets_MobilityData);
    const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
    const pageData = useSelector((state) => state.ProspectWidgets_CheckoutData);
    const creditData = useSelector((state) => state.ProspectWidgets_CreditData);
      const lqData = useSelector((state) => state.ProspectWidgets_LQPage);
    const sessionCartData =
        globalData?.sessionCartData?.apiResponse?.prospectFlowData;
    const flow = pageData?.unifiedCartParams?.flow ?? lqData?.flow;

    const { t } = useTranslation(["verification", "checkout", "mock-response"]);
    const authorizeTextForFullAutomation = t(
        "verification.authorizeTextForFullAutomation",
        { ns: "verification" }
    );
    const [showSaveCart, setShowSaveCart] = useState(false);

    useEffect(() => {
        const useMock = checkIfAemExists(t("mitek-declined-mock", { ns: "mock-response" }));
        dispatch(resetCreditSlice());
        dispatch(getSessionCart());
        if (sessionCartData?.prospectCreditPath !== "BLENDED") {
            setSSNValid(true);
        }
        dispatch(setValidateSSNResult({}))
        // BOVV-143548:Fix
        // setTimeout(() => {
        //     if (isMobile) {
        //         dispatch(
        //             mitekCheckVerificationResult({
        //                 mitekTimeToWaitForResult: 10,
        //                 mitekStatusPollInterval: 10,
        //                 useMock: useMock
        //             }));
        //     }
        // }, 3000)
    }, []);

    useMemo(() => {
        if (
            pageData?.mitekCheckVerificationResult?.verificationStatus ===
            "STORE_VISIT_ENABLED"
        ) {
            setValidationSelect("visitStore")
        } else if (
            pageData?.mitekCheckVerificationResult?.verificationStatus ===
            "INITIATE_BLENDED"
        ) {
            setValidationSelect("addSSN")
        }
    }, [pageData?.mitekCheckVerificationResult?.verificationStatus]);

    const isSSNError = useMemo(() => (SSN?.length > 0 && SSN?.length < 9) || (restrictedSSNValues.indexOf(SSN?.replaceAll("-", "")) !== -1), [SSN]);


    const canProceed = () => {
        if ((validationSelect === "addSSN" && SSNValid && SSN && shouldAgree && !SSNErrorValue && !isSSNError) ||
            (validationSelect === "visitStore" && zipCodeInputValue && zipCodeInputValue?.length === 5 && !zipCodeInProgress && !zipCodeErrorValue)) {
            return true;
        }
        return false;
    }

    const validateSSNVal = (value) => {
        setSSNErrorValue('');
        let val = value
            .replace(/[^0-9]/g, "")
            .replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
        if (!val) {
            setSSNErrorValue(t("checkout.businessInfo-section.mitek.errorMsgs.ssnReq", { ns: "checkout" }));
        }
        else if (val.length < 11) {
            setSSNErrorValue(t("checkout.businessInfo-section.mitek.errorMsgs.validSSNError", { ns: "checkout" }));
        }
        else if (val.length == 11 && (sessionCartData?.prospectCreditPath === "BLENDED" || sessionCartData?.prospectCreditPath === "BizOnlyCredit")) {
            // BOVV-141691
            if (pageData?.SSNInfo?.length && pageData?.SSNInfo == val) {
                setSSNValid(true);
                dispatch(setConsetFlowSSN(val));
            } else if (pageData?.SSNInfo == null || pageData?.SSNInfo == undefined || pageData?.SSNInfo?.length == 0) {
                setSSNValid(true);
                dispatch(setConsetFlowSSN(val));
            }
            else {
                setSSNErrorValue(t("checkout.businessInfo-section.mitek.errorMsgs.ssnNotMatch", { ns: "checkout" }))
                setSSNValid(false);
            }

            // dispatch(validateSSN({ ssn: val }));
        }
        setSSN(val);
    };

    const validateZipCode = (value) => {
        if (checkZipCodeValue(value)) {
            let val = value.replace(/[^0-9]/g, "");
            setZipCodeErrorValue('');
            setZipCodeInProgress(true);
            dispatch(validateZipcode({ zipcode: val }));
        }
    };
    const checkZipCodeValue = (value) => {
        let val = value.replace(/[^0-9]/g, "");
        setZipCodeErrorValue("");
        setZipCodeInputValue(val);
        if (!val || val?.length < 5 || val?.length > 5) {
            setZipCodeErrorValue(t("checkout.businessInfo-section.mitek.errorMsgs.zipCodeNotValid", { ns: "checkout" }));
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (mobilityPageData?.validZipcode == true && zipCodeInputValue?.length == 5 && !mobilityPageData?.loading) {
            setZipCodeErrorValue("");
            setZipCodeInProgress(false);
        }
        if (mobilityPageData?.validZipcode === false && zipCodeInputValue?.length == 5 && !mobilityPageData?.loading) {
            setZipCodeErrorValue('Invalid zipcode');
            setZipCodeInProgress(false);

        }
    }, [mobilityPageData?.zipCode, mobilityPageData?.validZipcode, mobilityPageData?.loading]);

    useEffect(() => {
        if (pageData?.ValidateSSNResult?.success && SSN?.length == 11) {
            dispatch(setConsetFlowSSN(SSN));
            setSSNValid(true);
        } else if (pageData?.ValidateSSNResult?.success === false && SSN?.length == 11) {
            setSSNErrorValue(t("checkout.businessInfo-section.mitek.errorMsgs.ssnNotMatch", { ns: "checkout" }))
            setSSNValid(false);
        }
    }, [pageData?.ValidateSSNResult]);

    useEffect(() => {
        if (pageData?.updateDeclinedStatusResult?.status?.success && canProceed()) {
            zipCodeSearch();
        }
    }, [pageData?.updateDeclinedStatusResult]);


    const zipCodeSearch = () => {
        window.location.href = `https://www.verizon.com/business/locations/search?q=${zipCodeInputValue}&qp=${zipCodeInputValue}&r=25&l=en)`;
    };

    const onContinue = () => {
        if (validationSelect === "visitStore") {
            if (!checkZipCodeValue(zipCodeInputValue)) {
                return;
            }
            canProceed() && dispatch(updateDeclinedStatus())
        } else {
            validateSSNVal(SSN);
            if (!shouldAgree) {
                setShowNotificationError(t("checkout.businessInfo-section.mitek.errorMsgs.agreeConsent", { ns: "checkout" }));
                return;
            }
            canProceed() && props.proceedToOTPFromMitekDeclined();
        }

    };
    const addSSN = (validationSelected) => {
        return (
            <div>
                {validationSelected === "addSSN" && (
                    <Grid
                        colGutter={false}
                        rowGutter={false}>
                        <StyledRow>
                            <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
                                {showNotificationError ? <Fragment>
                                    <Notification
                                        type="error"
                                        title={showNotificationError}
                                        subtitle=""
                                        fullBleed={true}
                                        inlineTreatment={false}
                                        hideCloseButton={false}
                                        width={"100%"}
                                    ></Notification>
                                    <MarginSpacerM />
                                </Fragment> : <></>}
                            </Col>
                        </StyledRow>
                        <StyledRow>
                            <Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
                                <Input
                                    label={`${t("verification.labelTexts.ssn", {
                                        ns: "verification",
                                    })}`}
                                    name={t("verification.labelTexts.ssn", {
                                        ns: "verification",
                                    })}
                                    width={"100%"}
                                    maxLength={9}
                                    value={SSN}
                                    error={!!SSNErrorValue}
                                    errorText={SSNErrorValue}
                                    onChange={(e) => validateSSNVal(e?.target?.value)}
                                    tooltipContent={t("verification.softCreditEnquiry", {
                                        ns: "verification",
                                    })}
                                    type={"password"}
                                    inlineActionButtonLabel={ssnLabel ? "Show" : "Hide"}
                                    onClickInlineAction={() =>
                                        ssnLabel ? setSSNLabel(false) : setSSNLabel(true)
                                    }
                                    mask="toggle"
                                    helperText="###-##-####"
                                    helperTextPlacement="bottom"
                                />
                            </Col>
                        </StyledRow>
                        <MarginSpacerM />
                        <StyledRow>
                            <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
                                <Checkbox
                                    name="default"
                                    width="auto"
                                    label=""
                                    disabled={false}
                                    error={false}
                                    checked={setShouldAgree}
                                    onChange={(e) => { setShouldAgree(e.target.checked); e.target.checked && setShowNotificationError("") }}
                                >
                                    <Body size="small" primitive="p">
                                        <StyledSpan
                                            dangerouslySetInnerHTML={{
                                                __html: authorizeTextForFullAutomation,
                                            }}
                                        />
                                    </Body>
                                </Checkbox>
                            </Col>
                        </StyledRow>
                        <StyledRow>
                            <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
                                <DigicertContainer className="digicertContainer">
                                    <img
                                        src="https://ss7.vzw.com/is/image/VerizonWireless/digital-digicert-seal-2023?scl=1"
                                        alt="digicert-Seal Icon"
                                    />
                                </DigicertContainer>
                            </Col>
                        </StyledRow>
                    </Grid>
                )}
            </div>
        );
    };

    const visitStore = (validationSelected) => {
        return (
            <>
                {validationSelected === "visitStore" && (
                    <Grid
                        colGutter={false}
                        rowGutter={false}>
                        <StyledRow>
                            <Col colSizes={{ mobile: 4, tablet: 10, desktop: 10 }}>
                                {showStoreError && <Fragment>
                                    <Notification
                                        type="error"
                                        title={"Error"}
                                        subtitle=""
                                        fullBleed={true}
                                        inlineTreatment={false}
                                        hideCloseButton={false}
                                        width={"100%"}
                                    ></Notification>
                                    <MarginSpacerM />
                                </Fragment>
                                }
                            </Col>
                        </StyledRow>
                        <StyledRow>
                            <Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
                                <Input
                                    error={!!zipCodeErrorValue}
                                    errorText={zipCodeErrorValue}
                                    label={t("verification.store-search.zipLabel", { ns: "verification" })}
                                    name="zipCode"
                                    onChange={(e) => validateZipCode(e?.target?.value)}
                                    placeholder=""
                                    required={true}
                                    maxLength={5}
                                    type="text"
                                    value={zipCodeInputValue}
                                />
                            </Col>
                        </StyledRow>
                    </Grid>
                )}
            </>
        );
    };

    const onMiteKChange = (e) => {
        setSSN("");
        setZipCodeInputValue("");
        setZipCodeErrorValue("");
        setShouldAgree(false);
        setValidationSelect(e.target.value);
    };

    useEffect(() => {
        const resp = creditData?.creditDetailsResponse;
        if (
            resp?.serviceStatus?.success && Object.keys(resp)?.length &&
            resp?.data?.creditReadStatus === "COMPLETED") {
            if (resp?.dbaCreditCart && Object.keys(resp?.dbaCreditCart)?.length) {
                //navigate("/shop/unified-checkout/credit-result");
            }
            else {
                const requestBody = {
                    flow: flow,
                    creditPath: "BLENDED",
                    isLowerFinancedAmountOptionSelected: false,
                    // BOVV-142041
                    // isMitekDeclined: sessionCartData?.mitekConsentDeclined,
                    componentName: "PersonalValidation"
                };
                if (globalData?.cmp?.isFromGoogleShop) {
                    requestBody.cmpCode = globalData?.cmp?.cmpValue;
                }
                dispatch(updateProspectCreditDetails(requestBody));
            }
        }
        else if (
            resp?.serviceStatus?.success && Object.keys(resp)?.length && (resp?.serviceStatus?.statusCode === "CREDIT_HOLD_NEEDED" ||
                resp?.serviceStatus?.statusCode === "CREDIT_RESULTS_FOR_HOLD_NEEDED")
        ) {
            let code = `${resp?.data?.creditApprovalStatus}-${resp?.data?.creditStatusReason}`;
            dispatch(setCreditHoldCode(code));
           // navigate(`/shop/credit-hold`, { replace: true });
        }
        else if (resp?.serviceStatus?.statusCode === "SUSPENDED_USER_FLOW") {
            dispatch(setSuspendFlow(false));
            //navigate("/shop/contact-us", { state: { refUrl: window.location.pathname } });
        }
        else if (resp?.serviceStatus?.success && Object.keys(resp)?.length &&
            (resp?.serviceStatus?.statusCode === "CREDIT_HOLD_NEEDED" ||
                resp?.serviceStatus?.statusCode === "CREDIT_RESULTS_FOR_HOLD_NEEDED")) {
            let code = `${resp?.data?.creditApprovalStatus}-${resp?.data?.creditStatusReason}`;
            dispatch(setCreditHoldCode(code));
            //navigate(`/shop/credit-hold`, { replace: true });
        }
        else if (resp?.serviceStatus?.statusCode?.includes("SUSPENDED")) {
            dispatch(setSuspendFlow(false));
            //navigate('/shop/contact-us', { state: { refUrl: window.location.pathname } })
        }
    }, [creditData?.creditDetailsResponse]);

    return (
        <ValditionWrapper>
            {pageData?.mitekVerficationResultLoading && <SpinnerComponent />}
            <Grid rowGutter={false} colGutter={false} colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
                <StyledRow>
                    <Col colSizes={{ mobile: 4, tablet: 10, desktop: 10 }}>
                        <Body size="large">
                            {props?.mitekFailed ? t("checkout.businessInfo-section.mitek.failedHeading", { ns: "checkout" }) : t("checkout.businessInfo-section.mitek.declineHeading", { ns: "checkout" })}
                        </Body>
                    </Col>
                </StyledRow>
                <MarginSpacerL />
                {!props?.mitekFailed ?
                    <StyledRow>
                        <Col colSizes={{ mobile: 4, tablet: 10, desktop: 10 }}>
                            {isMobile && <Fragment>
                                <RadioBoxWrapper>
                                    <RadioBox
                                        selected={validationSelect === "addSSN"}
                                        onChange={(e) => onMiteKChange(e)}
                                        text={'Add Social Security Number'}
                                        subtext={'We\'ll use your SSN to run a soft credit check for secure verification and accurate financing for your order'}
                                        value="addSSN"
                                        height='auto'
                                    />
                                    {validationSelect === "addSSN" && addSSN(validationSelect)}
                                    <RadioBox
                                        selected={validationSelect === "visitStore"}
                                        onChange={(e) => onMiteKChange(e)}
                                        text={'Visit a Verizon Store'}
                                        subtext={'We won\'t be able to process your order online at this time'}
                                        value="visitStore"
                                        height='auto'
                                    />
                                    {validationSelect === "visitStore" &&
                                        <><MarginSpacerXS />
                                            <Body size="large" bold="true">{t("checkout.businessInfo-section.mitek.nearLocationMsg", { ns: "checkout" })}</Body>
                                        </>}
                                    {validationSelect === "visitStore" && visitStore(validationSelect)}
                                </RadioBoxWrapper>
                            </Fragment>}
                            {/* {t("checkout.businessInfo-section.mitek.mitekDeclineOptions", { ns: "checkout", returnObjects: true }).map(option => {
                            <RadioBox
                                selected={validationSelect === option?.value}
                                onChange={(e) => onMiteKChange(e)}
                                text={option?.text}
                                subtext={option?.subText}
                                value={option?.value}
                                height={'auto'}
                            />
                        })} */}
                            {!isMobile && <Fragment>
                                <RadioBoxGroup
                                    onChange={(e) => onMiteKChange(e)}
                                    error={false}
                                    value={validationSelect}
                                    orientation={"horizontal"}
                                    data={[
                                        {
                                            name: "group",
                                            text: "Add Social Security Number",
                                            subtext: "We'll use your SSN to run a soft credit check for secure verification and accurate financing for your order",
                                            //children: addSSN(validationSelect),
                                            value: "addSSN",
                                            disabled: false,
                                        },
                                        {
                                            name: "group",
                                            text: "Visit a Verizon store",
                                            subtext: "We won't be able to process your order online at this time",
                                            //children: visitStore(validationSelect),
                                            value: "visitStore"
                                        },
                                    ]}
                                />
                                <MarginSpacerL />
                                {validationSelect === "addSSN" && addSSN(validationSelect)}
                                {validationSelect === "visitStore" &&
                                    <><MarginSpacerM />
                                        <Body size="large" bold="true">{t("checkout.businessInfo-section.mitek.nearLocationMsg", { ns: "checkout" })}</Body>
                                        <MarginSpacerM /> </>}
                                {validationSelect === "visitStore" && visitStore(validationSelect)}
                            </Fragment>}
                        </Col>
                    </StyledRow>
                    : <></>
                }
                {props?.mitekFailed ?
                    <StyledRow>
                        <Col colSizes={{ mobile: 4, tablet: 10, desktop: 10 }}>
                            <Notification type="info" title={t("checkout.businessInfo-section.mitek.storeInfoMsg", { ns: "checkout" })}
                                subtitle="" fullBleed={false}
                                inlineTreatment={false} hideCloseButton={true} width={"100%"}
                            ></Notification>
                        </Col>
                    </StyledRow> : <></>
                }
                {props?.mitekFailed ?
                    <StyledRow>
                        <Col colSizes={{ mobile: 4, tablet: 3, desktop: 3 }}>
                            <MarginSpacerM />
                            <Button
                                size="large"
                                use="secondary"
                                onClick={() => setShowSaveCart(true)}
                                width={"100%"}
                            >
                                {t("checkout.businessInfo-section.mitek.saveCartBtnTxt", { ns: "checkout" })}
                            </Button>
                        </Col>
                    </StyledRow> : <></>
                }
                {props?.mitekFailed ?
                    <StyledRow>
                        <Col colSizes={{ mobile: 4, tablet: 10, desktop: 10 }}>
                            <MarginSpacerM />
                            <Body size="large" bold="true">{t("checkout.businessInfo-section.mitek.nearLocationMsg", { ns: "checkout" })}</Body>
                            <MarginSpacerM />
                            {visitStore(validationSelect)}
                        </Col>
                    </StyledRow> : <></>
                }
                <MarginSpacerL />
                <StyledRow>
                    <Col colSizes={{ mobile: 4, tablet: 3, desktop: 3 }}>
                        <Button
                            size="large"
                            use="primary"
                            onClick={() => onContinue()}
                            width={"100%"}
                        >
                            {validationSelect === "visitStore" ? "Search" : "Continue"}
                        </Button>
                    </Col>
                </StyledRow>
            </Grid>
            <SaveCartModal opened={showSaveCart} onOpenedChange={setShowSaveCart} pageData={pageData} />
        </ValditionWrapper>
    );
};

export default MitekDeclined;
