const DELIMITER = ":_:";

export class AccessoryInfo {
  // Apple:_:EarPods with Lightning Connector:_:White:_:#F9F8F3:_:apple-earpods-lightning-connector-mmtn2am-a-iset:_:audio:_:apple-earpods-lightning-connector-mmtn2am-a-iset:_:6760030
  constructor(descString) {
    const tokens = descString.split(DELIMITER);
    this.brand = tokens[0];
    this.name = tokens[1];
    this.color = tokens[2];
    this.colorHex = tokens[3];
    this.image = tokens[4];
    this.category = tokens[5];
    this.filePath = tokens[6];
    this.accessoryId = tokens[7];
  }
}

export class DeviceInfo {
  // Apple:_:iPhone 14 Pro Max:_:128 GB:_:Space Black:_:#1E1D1B:_:iphone-14-pro-max-space-black-fall22-a_IMAGESETS:_:PDA/SmartPhones:_:apple-iphone-14-pro-max
  constructor(descString) {
    const tokens = descString.split(DELIMITER);
    this.brand = tokens[0];
    this.name = tokens[1];
    this.capacity = tokens[2];
    this.color = tokens[3];
    this.colorHex = tokens[4];
    this.image = tokens[5];
    this.category = tokens[6];
    this.filePath = tokens[7];
  }
}

export const getDeviceInfo = (descStr) => {
  return new DeviceInfo(descStr);
}

export const getDescriptionInfo = (pack) => {
  if (!pack?.description || pack?.byor) {
    return {};
  } else if (pack?.accessoryId) {
    return new AccessoryInfo(pack?.description);
  } else {
    return new DeviceInfo(pack?.description);
  }
}

export const getDisplayName = (pack) => {
  const { brand, name, capacity, color } = getDescriptionInfo(pack);
  const hexCodeCheck = /#(([0-9a-fA-F]{2}){3,4}|([0-9a-fA-F]){3,4})/g;
  let deviceName = "";
  if(brand) {
    deviceName+= brand + " ";
  }
  if(name) {
    deviceName+= name + " ";
  }
  if(capacity) {
    deviceName+= " - " + capacity + " ";
  }
  if(color) {
    deviceName+= color + " ";
  }
  if(deviceName.match(hexCodeCheck)){
    deviceName = deviceName.replace(hexCodeCheck, "");
  }
  return deviceName;
}
export const getDeviceName = (pack) => {
  const { brand, name } = getDescriptionInfo(pack);
  const hexCodeCheck = /#(([0-9a-fA-F]{2}){3,4}|([0-9a-fA-F]){3,4})/g;
  let deviceName = "";
  if(brand) {
    deviceName+= brand + " ";
  }
  if(name) {
    deviceName+= name + " ";
  }
  if(deviceName.match(hexCodeCheck)){
    deviceName = deviceName.replace(hexCodeCheck, "");
  }
  return deviceName;
}

export const getDeviceCategory = (pack) => {
  let deviceCategory = "";
  if(pack?.includes("BusinessInternet")){
    deviceCategory = "Business Internet";
  } else if(pack.includes("Wireless")){
    deviceCategory = "Wireless";
  } else if(pack?.includes("OneTalk")){
    deviceCategory = "One Talk";
  } else if(pack?.includes("BlueJeans")){
    deviceCategory = "Blue Jeans";
  } else if(pack?.includes("Accessories")){
    deviceCategory = "Accessories";
  }
  return deviceCategory;
}

export const getDeviceKey = (pack) => {
  let deviceKey = "";
  if(pack?.includes("FWAFourG") || pack?.includes("FWAFive")){
    deviceKey = "BusinessInternet";
  } else if(pack.includes("Wireless")){
    deviceKey = "Wireless";
  } else if(pack?.includes("OneTalk")){
    deviceKey = "OneTalk";
  } else if(pack?.includes("BlueJeans")){
    deviceKey = "BlueJeans";
  } else if(pack?.includes("Accessories")){
    deviceKey = "Accessories";
  }
  return deviceKey;
}

export const isCartHaveFourG = (cartPackages) => {
  for (let pkg in cartPackages) {
    let pkgObj = cartPackages[pkg];
    if (
      pkgObj?.packageType === "FOURG" 
    ) {
      return true;
    }
  }
  return false;
};
