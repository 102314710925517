import React, { useMemo, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Body, Title } from "@vds3/typography";
import { UnorderedList, ListItem } from "@vds3/lists";
import { MarginSpacerM, MarginSpacerS } from "common/components/styleTag";
import { formatPriceString } from "utils/commonUtils";
import { useImageUtils } from "hooks";
import { useSelector } from "react-redux";
import FWAContext from "../fwaContext";
import {
  EquipmentRadioBox,
  CardContainer,
  RecommendedBadge,
  CardContent,
  PriceBlock,
  ImageContent,
  CardDescription,
  ImgCheckmark,
  CardContentWrapper,
} from "./styles";

import { Button } from "@vds3/buttons";
import styled from "styled-components";
import { Toggle } from "@vds3/toggles";
import { Tooltip } from "@vds3/tooltips";

const EquipmentCard = ({
  id,
  displayName,
  price,
  strikethroughPrice,
  description,
  features,
  isRecommended,
  selected,
  onChange,
  isMobile,
  imgUri,
  aemContent,
  enableStarkReceiver,
  isByor,
  isDisabled,
  quantity,
  mwwCart,
  starkEnabled,
  description2,
  fromReceiver,
  description3,
  isfromAuth,
  fromInstallation,
  onlyCBandQualified = false,
  toggled = false,
  discountAmt,
  installments,
  dicountamounttotalprice,
  totalPriceFromEquipment = 0,
  offerDiscount,
  sbdDiscAmt = 0,
  selectedValue = ()=>{},
  ...props
}) => {
  const { t } = useTranslation(["4G-5G"]);
  const { flow, sessionCartData } = useContext(FWAContext);
  const isPlanBasedToolTipFlag = useSelector((state) => state.ProspectWidgets_4GPlansData.isPlanDisplayNameFlag);
  const isPlanDisplayNameForMbps = useSelector((state) => state.ProspectWidgets_4GPlansData.isPlanDisplayNameForMbps);
  const aemKey = flow === "4g" ? "fourG" : "fiveG";
  const flowTooltip = flow === "4g" ? "LTE" : "5G";
  const offerDiscountamt=Number(offerDiscount);
  const isBulkOrderEnabled = flow === "4g";
  const {getImageLink} = useImageUtils();
  const strikethroughVal =
    enableStarkReceiver && !isByor
      ? aemContent.strikethroughPrice
      : strikethroughPrice ?? aemContent?.strikethroughPrice;

  const displayPrice = useMemo(
    () => formatPriceString(price ?? aemContent?.price),
    [price, aemContent]
  );

  const totalPrice = useMemo(
    () =>
      formatPriceString(Number(price ?? aemContent?.price) * Number(quantity)),
    [price, quantity, aemContent]
  );

  const isCrownOffer = aemContent.name == "CROWN" ? ( Number(sbdDiscAmt) != 0 ? true : false) : false;
  const billcredit =  ((Number(sbdDiscAmt))/Number(installments));
  const billcreditOffer =  billcredit?.toFixed(2);  

  const getImage = () => {
    if (isByor) {
      return <></>;
    } else {
      return (
        <ImageContent>
          <img
            height= {isfromAuth ? "60" :"80"}
            width={isfromAuth ? "40" :"80"}
            src={imgUri ? getImageLink(imgUri, 80, 80) : aemContent?.imgSrc}
          />
        </ImageContent>
      );
    }
  };

  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!isDisabled) {
        onChange(id);
      }
    },
    [id, isDisabled, sessionCartData]
  );

  return (
    <FourGFiveGRouterMainWrapper mwwCart={mwwCart} isfromAuth={isfromAuth}>
      <div onClick={onClick} data-loc={`${flow}:${aemContent?.title}`}>
        <EquipmentRadioBox
          id={id}
          value={id}
          name={id}
          selected={selected}
          isMobile={isMobile}
          disabled={isDisabled}
          children={
            <FourGFiveGRouterBoxWrap style = {{ marginBottom: !isMobile && isByor ? "20px": "0px", padding: !isMobile && !isByor ? "4px" : "0px"}}>
              <CardContainer isDisabled={isDisabled}>
                {isRecommended ? (
                  <RecommendedBadge>
                    <Body size="small" color="#EE0000">
                      {t(`4G-5G.${aemKey}.router-content.recommended`) ||
                        "Recommended"}
                    </Body>
                  </RecommendedBadge>
                ) : (
                  ''
                )}
                <CardContent isMobile={isMobile}>
                  <FourGFiveGRouterTitle>
                    <Title size="medium">
                      {aemContent?.title}
                    </Title>
                    {
                      isMobile &&
                      <div style={{paddingRight:15}}>
                       {getImage()}
                      </div>
                    }
                   
                  </FourGFiveGRouterTitle>
                  {/* <MarginSpacerS /> */}
                  {!onlyCBandQualified ? 
                  <>
                  <PriceBlock>
                    <Body size="small">
                      <Title size="medium" bold primitive="span">
                        {displayPrice} 
                      </Title>
                      {!isByor && !!strikethroughPrice && (
                        <>
                          <span>&nbsp;&nbsp;</span>
                          <Body size="small" strikethrough primitive="span">
                            {formatPriceString(strikethroughPrice)}
                          </Body>
                        </>
                      )}
                      {' '}
                      {isBulkOrderEnabled &&
                        !isByor &&
                        t(`4G-5G.${aemKey}.router-content.perRouter`)}
                        <span>&nbsp;&nbsp;</span>
                    </Body>
                    {!isByor && (!!strikethroughPrice || toggled) && (
                      <>
                        {!mwwCart && (<Body size="small">
                          {t(`4G-5G.${aemKey}.router-content.withPaymentPlan`) ||
                            "with monthly payment plan."}
                        
                            
                        </Body>)}
                        <span>&nbsp;</span>
                      </>
                      
                    )}
                    {!isByor && (
                      <Body size="small">
                        {starkEnabled && fromReceiver ? t(`4G-5G.fiveG.router-content.plusTaxesDetailStarkReciverRouter`) 
                        : starkEnabled ? "" 
                        : t(`4G-5G.${aemKey}.router-content.plusTaxesDetail`) ||
                          "Plus taxes. Details below."}
                      </Body>
                    )}
                  </PriceBlock>
                  {(id!=="byor" && onlyCBandQualified) ?
                  <div>
                    <MarginSpacerM/>
                    <Toggle
                    disabled={false}
                    showText={true}
                    statusText={(e)=> e ? 'Pay monthly' : 'Pay today'}
                    textPosition = "right"
                    on={toggled}
                    onChange = {(e,data)=>{
                      selectedValue(data.on)
                    }}
                  />
                  <MarginSpacerM/>
                  </div>
                  :<></>}
                  </>:<></>}
                </CardContent>
                <div style={{display:'flex',marginTop:'8px',marginBottom: !isMobile ? '12px' : '24px'}}>
                  {
                    !isMobile && !onlyCBandQualified &&
                    <div>
                     {getImage()}
                    </div>

                  }
                  <CardContentWrapper isMobile={isMobile}>
                  <CardDescriptionWrap isDisabled={isDisabled} displayFlex={onlyCBandQualified}>
                  {onlyCBandQualified ? 
                  <div className="toggleSection-equip">
                  <div style={{height: "75%"}}>
                  <PriceBlock disp={true}>
                    <div>
                    <Body size="small">
                      <Title size="medium" bold primitive="span">
                        {displayPrice}
                      </Title>
                      {onlyCBandQualified && isCrownOffer? 
                        <Tooltip 
                          title={""}
                          size="small"
                          surface="light" >
                            {
                              isPlanBasedToolTipFlag ? <span
                              dangerouslySetInnerHTML={{
                                  __html: t(`4G-5G.${aemKey}.router-content.routerToolTipContent50`, {ns: '4G-5G',billcreditOffer, installments, offerDiscountamt , actualPrice:price, dicountamounttotalprice:totalPriceFromEquipment, isPlanDisplayNameForMbps, flowTooltip }),
                              }}></span>
                              : <span
                              dangerouslySetInnerHTML={{
                                  __html: t(`4G-5G.${aemKey}.router-content.routerToolTipContent10`, {ns: '4G-5G',billcreditOffer, installments, offerDiscountamt , actualPrice:price, dicountamounttotalprice:totalPriceFromEquipment, isPlanDisplayNameForMbps, flowTooltip }),
                              }}></span>
                            }
                        </Tooltip>
                      :""}
                    </Body>
                    </div>
                    <div>
                      <Body size="small">
                      {!isByor && !!strikethroughPrice && !isCrownOffer && (
                        <>
                          <span>&nbsp;&nbsp;</span>
                          <Body size="small" strikethrough primitive="span">
                            {formatPriceString(strikethroughPrice)}
                          </Body>
                        </>
                      )}
                      {' '}
                      {isBulkOrderEnabled &&
                        !isByor && !isCrownOffer &&
                        t(`4G-5G.${aemKey}.router-content.perRouter`)}
                        {isCrownOffer && t(`4G-5G.${aemKey}.router-content.routersCrownOfferContent`, {ns: '4G-5G',billcreditOffer, installments, offerDiscountamt , actualPrice:price, dicountamounttotalprice:totalPriceFromEquipment })}
                        <span>&nbsp;&nbsp;</span>
                        {!isByor && !isCrownOffer && (!!strikethroughPrice || toggled) && !mwwCart && (
                        t(`4G-5G.${aemKey}.router-content.withPaymentPlan`) || "with monthly payment plan."
                        )}
                        &nbsp;
                        {!isByor && starkEnabled && fromReceiver ? t(`4G-5G.fiveG.router-content.plusTaxesDetailStarkReciverRouter`) 
                        : starkEnabled ? "" 
                        : "Plus taxes**."}
                      </Body>
                    </div>
                  </PriceBlock>
                  {(id!=="byor" && onlyCBandQualified) ?
                  <div style={!isMobile ? {paddingTop:"20px"} : {paddingTop:"0px"}}>
                    <MarginSpacerM/>
                    <Toggle
                    disabled={false}
                    showText={true}
                    statusText={(e)=> e ? 'Pay monthly' : 'Pay today'}
                    textPosition = "right"
                    on={toggled}
                    onChange = {(e,data)=>{
                      selectedValue(data.on)
                    }}
                  />
                  <MarginSpacerM/>
                  </div>
                  :<></>}
                  </div>
                  {!isMobile &&
                    <FourGFiveGRouterbtnnew>
                      <RouterBtnPos isDisabled={isDisabled}>
                        <Button
                          size="large"
                          disabled={!!selected}
                          use="secondary"
                          width={"16.313rem"}>
                          {(!!selected && fromReceiver) ? "Selected receiver" : (!!selected && !fromReceiver) ? "Selected router" : starkEnabled && fromReceiver && !isByor ? "Select receiver" : isByor ? "Bring my own" : "Select router"}
                        </Button>
                      </RouterBtnPos>
                    </FourGFiveGRouterbtnnew>
                  }
                  </div>:<></>}
                  {!isMobile && onlyCBandQualified ?
                  <div style={{width:"20%"}}>
                  {getImage()}
                 </div> : <></>}
                  <CardDescription mwwCart={mwwCart}  isByor={isByor} fromInstallation={fromInstallation} onlyCBandQualified={onlyCBandQualified}>
                    {!isByor && flow === "4g" && (
                      <Body>
                        <Body bold primitive="span">
                          {`${quantity} ${
                            quantity > 1 ? "routers" : "router"
                          } for ${totalPrice}`}
                        </Body>
                        {!isByor && !!strikethroughPrice && (
                          <>
                            <span>&nbsp;&nbsp;</span>
                            <Body size="small" strikethrough primitive="span">
                              {formatPriceString(
                                Number(strikethroughPrice) * quantity
                              )}
                            </Body>
                          </>
                        )}
                      </Body>
                    )}
                    {/* <Body color={`${ mwwCart ? "#000000" : "#6F7171"}`}>{description}</Body> */}
                    <Body color={`${ mwwCart ? "#000000" : "#6F7171"}`}>
                       {
                        features?.length > 0 ?
                          <UnorderedList>
                            <div style={{fontWeight:"bold"}}>{t(`4G-5G.${aemKey}.router-content.featuresHeading`)}</div>
                            {features?.map((device, index) => (
                              <ListItem spacing="compact" size="bodySmall">{device}</ListItem>
                            ))}                            
                          </UnorderedList>
                          :
                          <>
                           {description?.desc1 ?
                            <>
                            <div>{description?.desc1 || ""}</div>
                            <MarginSpacerS></MarginSpacerS>
                            <div>{description?.desc2 || ""}</div>
                             </>
                            :description
                            }
                          </>
                       }
                        
                    </Body>
                    {starkEnabled && <Body color={`${ mwwCart ? "#000000" : "#6F7171"}`}>{description2}</Body>}
                    <MarginSpacerM />
                    {starkEnabled && <Body color={`${ mwwCart ? "#000000" : "#6F7171"}`}>{description3}</Body>}
                    {aemContent?.devicesIncluded?.length > 0 && (
                      <div className="included-devices">
                        <MarginSpacerM />
                        {mwwCart ? 
                          <Body>
                            {aemContent.devicesIncluded.map((device, index) => (
                              <div>
                                <span><ImgCheckmark src={`${window?.mbtGlobal?.assetsBaseUrl}/assets/images/icons/checkmark-alternative.png`} /></span>
                                <span>{device}</span>
                              </div>
                            ))}                   
                          </Body>
                        :
                          <Body>
                            <UnorderedList>
                              {aemContent.devicesIncluded.map((device, index) => (
                                <ListItem size="bodySmall">{device}</ListItem>
                              ))}
                            </UnorderedList>
                          </Body>
                        }
                        <MarginSpacerM />
                      </div>
                    )}
                  </CardDescription>
                </CardDescriptionWrap>        
                  </CardContentWrapper>
                </div>              
                {(isMobile||isByor||!onlyCBandQualified) && <FourGFiveGRouterbtn isDisabled={isDisabled}>
                  <Button 
                    size="large"
                    disabled={!!selected}
                    use="secondary"
                    width={isMobile ? "100%" : "16.313rem"}>
                    {(!!selected && fromReceiver )? "Selected receiver" : (!!selected && !fromReceiver ) ? "Selected router" : starkEnabled && fromReceiver && !isByor ? "Select receiver" : isByor ? "Bring my own" : "Select router"}  
                  </Button>
                </FourGFiveGRouterbtn>}



              </CardContainer>
            </FourGFiveGRouterBoxWrap>
          }
        />
      </div>
    </FourGFiveGRouterMainWrapper>
  );
};

EquipmentCard.defaultProps = {
  quantity: 1,
};


const FourGFiveGRouterMainWrapper = styled.div`
 
`


const FourGFiveGRouterBoxWrap = styled.div`
  * {
    letter-spacing:0px !important;
  }
  
  
`

const CardDescriptionWrap = styled.div`
  display:${({displayFlex})=> displayFlex ? 'flex' : ''};
  p {
    ${({isDisabled}) => !isDisabled && `color:#000000;`}
  }
  @media screen and (max-width:1023px){
    display:inline;
  }
  `
const FourGFiveGRouterbtnnew = styled.div`
  float: left;
  margin: 2px;
  margin-bottom: 4px;
  position: relative;
  button{
    ${({isDisabled}) => isDisabled && `pointer-events: none;`}
    ${({isDisabled}) => isDisabled && `border: .0625rem solid #d8dada;`}
  }
`;

const RouterBtnPos = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding-bottom: 0.5rem
}
`

const FourGFiveGRouterbtn = styled.div`
  align-self: flex-end;
  margin-top: auto;
  //margin-bottom: 10px;
  width: 100%;
  button{
    ${({isDisabled}) => isDisabled && `pointer-events: none;`}
    ${({isDisabled}) => isDisabled && `border: .0625rem solid #d8dada;`}
  }
`;

const FourGFiveGRouterTitle = styled.div`
  h4 {
    // min-height:90px;
  }
  @media only screen and (max-width: 545px) {
    display:flex;
    align-items: center;
  }

`


export default EquipmentCard;
