import React, { useState } from "react";
import styled from "styled-components";
import { RadioBox } from "@vds3/radio-boxes";
import { Body } from "@vds3/typography";
import PDPContext from "./pdpContext";
import { TextLink } from "@vds3/buttons";
import VideoModal from "common/components/VideoModal/VideoModal";
import PlaySvg from "./PlaySVG";

const SectionContainer = styled.div`
  margin: 0;
  .device-info {
    display: inline-block;
    margin-bottom: 0.5rem !important;
  }
  
  `;
const SimBoxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  max-width:596px;
 
`;

const VideoModalContainer = styled.div`
padding-top:1rem;
display:flex;
align-items:center;
`
const SectioniconText = styled.div`
display:flex;
margin-bottom: 0rem;
align-items: flex-start;
    flex-wrap: nowrap;
    flex-direction: row;

`
const CustomTextLink = styled(TextLink)`

`
const PreOwned = ({ preOwnedDeviceOptions }) => {
  const [showSetupVideoModal, setShowSetupVideoModal] = useState(false);
  const {
    selectedSku: { selectedSku, setSelectedSku },
    setSelectedConditionShort: { selectedConditionShort, setSelectedConditionShort },
  } = React.useContext(PDPContext);

  const SimBox = styled(RadioBox)`
  max-width:190.67px;
  width:190.67px;
  height:auto;
  margin: 5px 12px 17px 0;
  @media screen and (min-width:230px) and (max-width:767px){
    display:flex;
    flex:2 1 auto;
    width:328px;
    margin: 5px 1px 12px 0;
    max-width:100%;
  }
`;
const openVideoModal = () => {
  setShowSetupVideoModal(true);
};
  return (
    <SectionContainer>
      {preOwnedDeviceOptions.length > 1 ?
        <SimBoxContainer>
          {preOwnedDeviceOptions?.map((preObj) => {
            return (
              <SimBox
                key={preObj?.simType}
                selected={preObj?.conditionShort === selectedConditionShort}
                onChange={() => setSelectedConditionShort(preObj?.conditionShort)}
                children={
                  <>              
                    <Body bold size="large">
                      {preObj?.conditionShort}
                      </Body>                 
                      <Body size="large">
                      {preObj?.conditionLong}
                      </Body>
                   
                  </>
                }
              />
            );
          })}
        </SimBoxContainer> :
        <>
          {preOwnedDeviceOptions?.map((preObj) => {
            return (
              <>         
                <Body bold size="large">
                  {preObj?.conditionShort}
                  </Body>
                  <Body size="large">
                  {preObj?.conditionLong}
                  </Body>
                 
              </>
            )
          })}
        </>
      }
   {showSetupVideoModal && (
        <VideoModal
          onCloseBtnClick={() => setShowSetupVideoModal(false)}
          srcVideoURL={
            selectedSku?.conditionUrl
          }
          title={
            selectedSku?.conditionShort
          }
        />
      )}
        <VideoModalContainer onClick={openVideoModal}>
          <SectioniconText>
          <PlaySvg />
      <CustomTextLink>Learn more</CustomTextLink>
      </SectioniconText>
      </VideoModalContainer>

    </SectionContainer>
  );
};

export default React.memo(PreOwned);