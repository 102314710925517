import { useDispatch, useSelector } from "react-redux";
import {
	Accordion,
	AccordionDetail,
	AccordionHeader,
	AccordionItem,
	AccordionTitle,
} from "@vds3/accordions";
import VerifyBusiness from "./VerifyBusiness/VerifyBusiness";
import {
	setAccordionDisplayOrder,
	setActiveAccordian,
	setIsVerifyBusiness,
	setShowMitekModal,
	setShowOneTimePassModal,
	setShowValidationOverlay,
} from "./ducks/CheckoutSlice";
import OneTimePassModal from "./OneTimePasscode/OneTimePassModal";
import DocusignOptix from "./Docusign/DocusignOptix";
import ReviewTAC from "./ReviewTAC/ReviewTAC";
import ShippingMethod from "./ShippingMethod/ShippingMethod";
import Review from "./ReviewOrder/Review";
import Payment from "./Payment/Payment";
import Confirmation from "./Confirmation/Confirmation";
import CreditResultsNew from "./CreditResult/CreditResultsNew/CreditResultsNew";
import {
	resetCreditSlice,
	setRetries,
	validateCreditDetails,
} from "./CreditResult/CreditResultsNew/CreditSlice";
import StickyHeaderVds3 from "common/components/StickyHeader/StickyHeaderVds3";
import Installation from "./Installation/Installation";
import { useState, useEffect } from "react";
import InstallationAuthorization from "./InstallationAuthorization/InstallationAuthorization";
import MitekModal from "./Mitek/MitekOptix";

import { resetReviewSlice } from "./ReviewOrder/ducks/ReviewSlice";
import { resetPaymentSlice } from "./Payment/ducks/PaymentSlice";
import { resetCheckoutState } from "pages/Checkout/ducks/CheckoutSlice";
import CreditHold from "./CreditHold/CreditHoldOptix";
import { useTranslation } from "react-i18next";
import {
	postSaveCart,
	setLoadCheckoutWidget,
	setLoadShoppingCartWidget,
	setSaveCartSuccess,
} from "app/ducks/GlobalSlice";
import { DeviceDataCollector } from "./DeviceDataCollector/DeviceDataCollector";
import "./checkout.css";
import SaveCartConfirmationModal from "common/components/SaveCartModal/SaveCartConfirmationModal";
import SpinnerComponent from "common/components/Spinner/Spinner";
import SendCartModal from "common/components/SaveCartModal/SendCartModal";
import PaymentNew from "./Payment/PaymentNew";
import NpaNxx from "./NpaNxx/NpaNxx";
const isMobile = false;

export const accordionOrder = {
	default: {
		businessInfo: 0,
		otp: 1,
		creditResult: 2,
		maa: 3,
		shipping: 4,
		reviewOrder: 5,
		payment: 6,
		orderConfirmation: 7,
	},
	mmw: {
		installationAuth: 0,
		businessInfo: 1,
		otp: 2,
		creditResult: 3,
		maa: 4,
		installation: 5,
		reviewOrder: 6,
		payment: 7,
		orderConfirmation: 8,
	},
	wireless: {
		businessInfo: 0,
		otp: 1,
		creditResult: 2,
		maa: 3,
		npaNxx:4,
		shipping: 5,
		reviewOrder: 6,
		payment: 7,
		orderConfirmation: 8,
	},
	buss:{
		businessInfo: 0,
		payment: 1,
		orderConfirmation: 2
	}
};

const Checkout = ({ ...props }) => {
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation(["checkout"]);
	const pageData = useSelector((state) => state.ProspectWidgets_CheckoutData);
	const creditData = useSelector((state) => state.ProspectWidgets_CreditData);
	const lqData = useSelector((state) => state.ProspectWidgets_LQPage);
	const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
	const shoppingData = useSelector((state) => state.ShoppingCartData);

	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;
	const saveCartSucess = globalData?.saveCartSuccess;
	const mitekEnabled = sessionCartData?.mitekEnabled && !isMobile;
	const isMmwCart = sessionCartData?.cart?.mwwCart;
	const isWirelessExsist = !sessionCartData?.cart?.mwwCart && !sessionCartData?.cart?.cbandCart
	const standAloneBuSS = sessionCartData?.standAloneBuSS;
	const accordionDisplayOrder = standAloneBuSS ? accordionOrder?.buss : isWirelessExsist ? accordionOrder?.wireless
	  :isMmwCart
		? accordionOrder?.mmw
		: accordionOrder?.default;
	const [creditHold, setCreditHold] = useState(false);
	const [saveCart, setSaveCart] = useState(false);
	const [saveCartLinkClicked, setSaveCartLinkClicked] = useState(false);
	const [showSaveCartSuccessModal, setShowSaveCartSuccessModal] =
		useState(false);
	const [navigateToFromCart, setNavigateTo] = useState(-1);
	const enableInspicio=t("payment.enableInspicio", { ns: "checkout" })

	useEffect(() => {
		localStorage.setItem("shippingState", JSON.stringify({}));
		localStorage.setItem("businessState", JSON.stringify({}));
		localStorage.setItem("signerState", JSON.stringify({}));
		localStorage.setItem("creditCheckState", JSON.stringify({}));
		dispatch(resetCheckoutState());
		dispatch(resetCreditSlice());
		dispatch(resetReviewSlice());
		dispatch(resetPaymentSlice());
		dispatch(setAccordionDisplayOrder(accordionDisplayOrder));
		setRetrieveActiveAccordian();
		window.scroll(0, 0);
	}, []);

	const setRetrieveActiveAccordian = () => {
		const redirectPage = sessionCartData?.redirectPageTo;
		if (redirectPage === "BUSINESSINFO_PAGE") {
			setNavigateTo(accordionDisplayOrder?.businessInfo);
			dispatch(setActiveAccordian(accordionDisplayOrder?.businessInfo));
		} else if (redirectPage === "CREDIT_RESULT_PAGE") {
			setNavigateTo(accordionDisplayOrder?.creditResult);
			dispatch(setActiveAccordian(accordionDisplayOrder?.creditResult));
		} else if (redirectPage === "REVIEW_ORDER") {
			setNavigateTo(accordionDisplayOrder?.reviewOrder);
			dispatch(setActiveAccordian(accordionDisplayOrder?.reviewOrder));
		} else if (redirectPage === "AGREEMENT_PAGE") {
			setNavigateTo(accordionDisplayOrder?.maa);
			dispatch(setActiveAccordian(accordionDisplayOrder?.maa));
		} else if (redirectPage === "INSTALLATION_PAGE") {
			setNavigateTo(accordionDisplayOrder?.installation);
			dispatch(setActiveAccordian(accordionDisplayOrder?.installation));
		} else if (redirectPage === "SHIPPING_PAGE") {
			setNavigateTo(accordionDisplayOrder?.shipping);
			dispatch(setActiveAccordian(accordionDisplayOrder?.shipping));
		} else if (props.cartId) {
			setNavigateTo(accordionDisplayOrder?.businessInfo);
			dispatch(setActiveAccordian(accordionDisplayOrder?.businessInfo));
		}
	};

	useEffect(() => {
		if (saveCartSucess?.data?.cartId) {
			window.dispatchEvent(
				new CustomEvent("cartDetailsFromWidget", {
					detail: {
						cartId: saveCartSucess?.data?.cartId,
					},
				})
			);
			if (saveCartLinkClicked) {
				setShowSaveCartSuccessModal(true);
				setSaveCartLinkClicked(false);
			}
		}
	}, [saveCartSucess]);

	const onOTPPhoneRecommendationStop = () => {
		if (mitekEnabled) {
			dispatch(setShowMitekModal(true));
		} else {
			otpSuccess();
		}
	};

	const onOTPValidateResponseFail = () => {
		if (mitekEnabled) {
			dispatch(setShowMitekModal(true));
		} else {
			verifyCreditDetaiils();
		}
	};

	const onOTPValidateResponseSuccess = () => {
		if (mitekEnabled && pageData?.creditPath === "BizOnlyCredit") {
			dispatch(setShowMitekModal(true));
		} else if (validateResponse?.data?.svcCode === "M120") {
			otpSuccess();
		} else if (
			sessionCartData?.enableBizOtpSuspend &&
			pageData?.creditPath === "BizOnlyCredit" &&
			!showDdiaPage &&
			!mitekEnabled
		) {
			suspendOTPFlow();
		} else {
			onOTPValidateResponseFail();
		}
	};

	const otpSuccess = () => {
		dispatch(setShowOneTimePassModal(false));
		dispatch(setIsVerifyBusiness(false));
		//verifyCreditDetails();
	};

	const editCart = () => {
		dispatch(setLoadCheckoutWidget(false));
		dispatch(setLoadShoppingCartWidget(false));
	};

	const sendCart = () => {
		dispatch(setSaveCartSuccess({}));
		setSaveCart(true);
	};
	const handleSaveCart = () => {
		setSaveCartLinkClicked(true);
		dispatch(setSaveCartSuccess({}));
		let sectionLastEntered = ''
		Object.entries(accordionDisplayOrder).map(([key,value])=>{
			if(value === pageData?.activeAccordian) sectionLastEntered = key
		})
		const payload = {
			cartMeta: {
				creditApplicationNumber: "",
				locationCode:
					lqData?.BBALQResp?.locationId ||
					globalData?.widgetInputParams?.locationId?.value,
				salesCode:
					lqData?.BBALQResp?.salesCode ||
					globalData?.widgetInputParams?.salesCode?.value,
				email: "",
				sourcePath: "SAVE",
				zipCode: sessionCartData?.businessInfo?.address?.zipCode,
			},
			overrideCart: true,
			cartId: "",
			sendMail: "false",
			shoppingPath: "PROSPECT",
			approach: "",
			flow: lqData.flow,
			currentSectionName: sectionLastEntered,
			clientId : props.clientId
		};
		dispatch(postSaveCart(payload));
	};

	useEffect(() => {
		if (
			pageData?.showCreditHoldSection !== null &&
			pageData?.showCreditHoldSection
		)
			setCreditHold(pageData?.showCreditHoldSection);
	}, [pageData?.showCreditHoldSection]);
	return (
		<div className="landing5g">
			{globalData?.loading && <SpinnerComponent />}
			<StickyHeaderVds3
				onContinue={() => {}}
				onCancel={() => {}}
				disabledContinueBtn={true}
				hideContinueButton={true}
				hideShoppingBagIcon={true}
				hideAddress={true}
				backClickHandler={() => {}}
				enableBackLink={false}
				onAddressChange={() => {}}
				continueBtnText={""}
				editCart={() => editCart()}
				sendCart={() => sendCart()}
				saveCart={() => handleSaveCart()}
				showEditCart={
					pageData?.activeAccordian <= accordionDisplayOrder.businessInfo &&
					t("verification.showEditCart", { ns: "checkout" })
				}
				showSaveCart={
					pageData?.activeAccordian !== accordionDisplayOrder.orderConfirmation &&
					t("verification.showSaveCart", { ns: "checkout" })
				}
				showSendCart={
					pageData?.activeAccordian !== accordionDisplayOrder.orderConfirmation &&
					t("verification.showSendCart", { ns: "checkout" })
				}
				showCartID={t("verification.showCartID", { ns: "checkout" })}
				showSessionId={t("verification.showSessionID", { ns: "checkout" })}
			/>
			<Accordion className="test" type="multi">
				<div
					className={
						pageData?.activeAccordian === accordionDisplayOrder.orderConfirmation ||
						(props.cartId && accordionDisplayOrder.businessInfo < navigateToFromCart)
							? "disable-accordian"
							: ""
					}
				>
					<AccordionItem
						opened={pageData?.activeAccordian === accordionDisplayOrder.businessInfo}
					>
						<AccordionHeader triggerType="icon" bold="true">
							<AccordionTitle>
								Business Information{" "}
								{accordionDisplayOrder.businessInfo < pageData?.activeAccordian
									? "- Completed"
									: ""}
								{pageData?.activeAccordian <= accordionDisplayOrder.businessInfo && (
									<span className="required-arrow">
										<span className="arrow-left"></span>
										<span className="required-label">Required</span>
									</span>
								)}
							</AccordionTitle>
						</AccordionHeader>
						<AccordionDetail>
							{accordionDisplayOrder.businessInfo <= pageData?.activeAccordian && (
								<VerifyBusiness />
							)}
						</AccordionDetail>
					</AccordionItem>
				</div>
				{/* {standAloneBuSS ? <></> : 
				<div
					className={
						pageData?.activeAccordian === accordionDisplayOrder.orderConfirmation ||
						(props.cartId && accordionDisplayOrder.creditResult < navigateToFromCart)
							? "disable-accordian"
							: ""
					}
				>
					<AccordionItem
						opened={pageData?.activeAccordian === accordionDisplayOrder.creditResult}
					>
						<AccordionHeader triggerType="icon" bold="true">
							<AccordionTitle>
								Credit Results{" "}
								{accordionDisplayOrder.creditResult < pageData?.activeAccordian ||
								(creditHold && !pageData?.showCreditHoldSection)
									? "- Completed"
									: ""}
								{pageData?.activeAccordian <= accordionDisplayOrder.creditResult && (
									<span className="required-arrow">
										<span className="arrow-left"></span>
										<span className="required-label">Required</span>
									</span>
								)}
							</AccordionTitle>
						</AccordionHeader>
						<AccordionDetail>
							<>
								{accordionDisplayOrder.creditResult <= pageData?.activeAccordian && (
									<>
										{pageData?.showCreditHoldSection ? (
											<CreditHold />
										) : (
											<CreditResultsNew />
										)}
									</>
								)}
							</>
						</AccordionDetail>
					</AccordionItem>
				</div>} */}
				{/* <div
					className={
						pageData?.activeAccordian === accordionDisplayOrder.orderConfirmation ||
						(props.cartId && accordionDisplayOrder.reviewOrder < navigateToFromCart)
							? "disable-accordian"
							: ""
					}
				>
					<AccordionItem
						opened={pageData?.activeAccordian === accordionDisplayOrder.reviewOrder}
					>
						<AccordionHeader triggerType="icon" bold="true">
							<AccordionTitle>
								Review order{" "}
								{accordionDisplayOrder.reviewOrder < pageData?.activeAccordian
									? "- Completed"
									: ""}{" "}
								{pageData?.activeAccordian <= accordionDisplayOrder.reviewOrder && (
									<span className="required-arrow">
										<span className="arrow-left"></span>
										<span className="required-label">Required</span>
									</span>
								)}
							</AccordionTitle>
						</AccordionHeader>
						<AccordionDetail>
							{accordionDisplayOrder.reviewOrder <= pageData?.activeAccordian && (
								<Review />
							)}
						</AccordionDetail>
					</AccordionItem>
				</div> */}
				<div
					className={
						pageData?.activeAccordian === accordionDisplayOrder.orderConfirmation ||
						(props.cartId && accordionDisplayOrder.payment < navigateToFromCart)
							? "disable-accordian"
							: ""
					}
				>
					<AccordionItem
						opened={pageData?.activeAccordian === accordionDisplayOrder.payment}
					>
						<AccordionHeader triggerType="icon" bold="true">
							<AccordionTitle>
								Payment{" "}
								{accordionDisplayOrder.payment < pageData?.activeAccordian
									? "- Completed"
									: ""}{" "}
								{pageData?.activeAccordian <= accordionDisplayOrder.payment && (
									<span className="required-arrow">
										<span className="arrow-left"></span>
										<span className="required-label">Required</span>
									</span>
								)}
							</AccordionTitle>
						</AccordionHeader>
						<AccordionDetail>
						{accordionDisplayOrder.payment <= pageData?.activeAccordian && (
								// enableInspicio ? <PaymentNew /> : 
								<Payment />
							)}
						</AccordionDetail>
					</AccordionItem>
				</div>
				<AccordionItem
					opened={
						pageData?.activeAccordian === accordionDisplayOrder.orderConfirmation
					}
				>
					<AccordionHeader triggerType="icon" bold="true">
						<AccordionTitle>
							Order confirmation{" "}
							{accordionDisplayOrder.orderConfirmation < pageData?.activeAccordian
								? "- Completed"
								: ""}
						</AccordionTitle>
					</AccordionHeader>
					<AccordionDetail>
						{accordionDisplayOrder.orderConfirmation <= pageData?.activeAccordian && (
							<Confirmation />
						)}
					</AccordionDetail>
				</AccordionItem>
			</Accordion>
			{saveCart && (
				<SendCartModal
					opened={saveCart}
					onOpenedChange={setSaveCart}
					pageData={shoppingData}
					accordionDisplayOrder={accordionDisplayOrder}
					checkoutData={pageData}
				/>
			)}
			{showSaveCartSuccessModal && (
				<SaveCartConfirmationModal
					opened={showSaveCartSuccessModal}
					onOpenedChange={setShowSaveCartSuccessModal}
				/>
			)}
			{/* <DeviceDataCollector /> */}
		</div>
	);
};

export default Checkout;
