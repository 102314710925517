import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TextLink } from "@vds3/buttons";
import { Modal, ModalTitle, ModalBody } from "@vds3/modals";
import { Notification } from "@vds3/notifications";
import { Body } from "@vds3/typography";
import { formatPriceString } from "utils/commonUtils";
import CartContext from "pages/ShoppingCart/cartContext";
import {
  NotificationContent,
  NotificationMessage,
  NotificationButton,
} from "./styles";

const SavingsNotification = ({ savingsData, ...props }) => {
  const { isMobile } = React.useContext(CartContext);
  const { t } = useTranslation(["shoppingCart"]);
  const savingsAemData = t("shopping-cart.savings-notification", {
    returnObjects: true,
  });

  const [notificationMessage, setNotificationMessage] = useState("");
  const [modalHead, setModalHead] = useState("");
  const [showModal, setShowModal] = useState("");

  useEffect(() => {
    if (!savingsData) return;
    const { bicOneTimeAmount, totalAmount, maxTenure } = savingsData;
    let bicOneTimeMsg = "";
    let bicOneTimeHeading = "";
    let bicComboHeading = "";
    let bicRecurring = "";
    let totalOneTimeBicSavingsAmount = "";
    let totalSavingsAmount = "";
    let modalHeading = "";

    if (bicOneTimeAmount > 0) {
      bicOneTimeMsg = savingsAemData.bicOneTime.replace(
        "{{bicOneTime}}",
        formatPriceString(bicOneTimeAmount)
      );
      if (!totalAmount) {
        bicOneTimeMsg = savingsAemData.bicOneTimeCombo.replace(
          "{{bicOneTime}}",
          formatPriceString(bicOneTimeAmount)
        );
      }
      bicOneTimeHeading = savingsAemData.bicOneTimeHeading.replace(
        "{{totalAmount}}",
        formatPriceString(bicOneTimeAmount)
      );
      bicComboHeading = savingsAemData.bicComboHeading.replace(
        "{{totalAmount}}",
        formatPriceString(bicOneTimeAmount)
      );
    }
    totalOneTimeBicSavingsAmount = totalAmount
      ? savingsAemData.bicOneTime.replace(
          "{{bicOneTime}}",
          formatPriceString(bicOneTimeAmount)
        )
      : "";
    totalSavingsAmount = totalAmount ? formatPriceString(totalAmount) : "";
    if (totalAmount) {
      bicRecurring = savingsAemData.bicrecurring
        .replace("{{amount}}", totalSavingsAmount)
        .replace("{{tenure}}", maxTenure);
    }
    modalHeading = savingsAemData.modalHeading
      .replace("{{amount}}", totalSavingsAmount)
      .replace("{{duration}}", maxTenure);
    if (bicOneTimeAmount > 0 && totalAmount > 0) {
      modalHeading = modalHeading + bicComboHeading;
    } else if (totalAmount > 0) {
      modalHeading = modalHeading;
    } else {
      modalHeading = bicOneTimeHeading;
    }

    setModalHead(modalHeading);
    setNotificationMessage(
      savingsAemData.notificationMessage
        .replace("{{bicRecurringMessage}}", bicRecurring)
        .replace("{{bicOneTimeMessage}}", bicOneTimeMsg)
    );
    // this.cartStore.dispatch(
    //   SHOW_NOTIFICATION({
    //     message: notificationMessage,
    //     status: "success",
    //     linkCTA: {
    //       label: savingsAemData.notificationButton,
    //       callback: this.toggleSavingsModal.bind(this),
    //     },
    //   })
    // );
  }, [savingsData]);

  return (
    <div>
      <Notification
        type="success"
        fullBleed={false}
        inline={false}
        hideCloseButton={false}
        onCloseButtonClick={() => {}}
        layout={"horizontal"}
        title={t("shopping-cart.savings-notification.notificationTitle")}
        subtitle={
          <NotificationContent>
            <NotificationMessage>
              {/* <Body size="large" bold>
                {t("shopping-cart.savings-notification.notificationTitle")}
              </Body> */}
              <Body size={isMobile ? "small" : "large"}>
                {notificationMessage}
              </Body>
            </NotificationMessage>
            <NotificationButton>
              <TextLink
                type="standAlone"
                size="large"
                onClick={() => setShowModal(true)}
              >
                {t("shopping-cart.savings-notification.notificationButton")}
              </TextLink>
            </NotificationButton>
          </NotificationContent>
        }
      />
      {!!showModal && (
        <Modal opened={showModal} onOpenedChange={setShowModal}>
          <ModalTitle>{modalHead}</ModalTitle>
          <ModalBody>
            <ModalContent>
              {savingsData?.modalContentArray?.map((item) => (
                <Body key={item} size="large">
                  {item}
                </Body>
              ))}
              {savingsData?.modalOneTimeBicContentArray?.map((item) => (
                <Body key={item} size="large">
                  {item}
                </Body>
              ))}
            </ModalContent>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export default SavingsNotification;
