import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
	Accordion,
	AccordionDetail,
	AccordionHeader,
	AccordionItem,
	AccordionTitle,
} from "@vds3/accordions";
import { Micro } from "@vds3/typography";
import { TextLink } from "@vds3/buttons";
import { Carousel } from "@vds3/carousels";
import CompareSelectDevice from "pages/Mobility/components/MobilityGridwall/compareSelectDevice";
import DeviceDetails from "pages/Mobility/components/MobilityGridwall/DeviceDetails";
//import OneTalkDeviceDetails from "pages/OneTalk/components/OneTalkGridwall/OneTalkDeviceDetails";
import {
	getReviewsForComparePage,
	setReviewsForCompareDevices,
} from "pages/Mobility/ducks/MobilitySlice";
import { useDispatch, useSelector } from "react-redux";

const ComparePDPCarousel = function PDPCarousel({
	compareDeviceInfoList,
	handleDeviceTileCloseClick,
	RatingstarRenderer,
	onReviewsClick,
	colorSwatchRenderer,
	aem,
	fromPdp = false,
	fromOnetalk = false,
}) {
	const startArray = [...compareDeviceInfoList.slice(0, 2)];
	const endArray = [...compareDeviceInfoList.slice(2, 4)];
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [compareDeviceList, setCompareDeviceList] = useState(startArray);
	const pageData = useSelector((state) => state.ProspectWidgets_MobilityData);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setReviewsForCompareDevices(undefined));
	}, []);

	useEffect(() => {
		if (
			compareDeviceInfoList !== null &&
			compareDeviceInfoList &&
			compareDeviceInfoList.length > 0
		) {
			dispatch(getReviewsForComparePage(compareDeviceInfoList));
		}
	}, [compareDeviceInfoList]);
	useEffect(() => {
		if (
			selectedIndex === 0 &&
			startArray !== null &&
			startArray &&
			startArray.length &&
			(compareDeviceList === null ||
				compareDeviceList === undefined ||
				compareDeviceList.length === 0)
		)
			setCompareDeviceList(startArray);
	}, [selectedIndex, startArray]);
	const scrollCompare = () => {
		let arrList = [];
		let maxDeviceLength = 0;
		if (
			compareDeviceInfoList !== null &&
			compareDeviceInfoList &&
			compareDeviceInfoList.length
		) {
			compareDeviceInfoList?.map((device, index) => {
				const maxDeviceDetails = (
					device?.phoneOverview?.brandName + device?.phoneOverview?.phoneName
				)?.length;
				if (maxDeviceDetails > maxDeviceLength) maxDeviceLength = maxDeviceDetails;
			});
			compareDeviceInfoList?.map((device, index) => {
				arrList.push({
					children: (
						<div>
							{
								!fromOnetalk ? (
									<DeviceDetails
										{...device}
										key={device.deviceId}
										maxDeviceLength={maxDeviceLength}
										onCloseClick={handleDeviceTileCloseClick}
										aem={aem}
										fromPdp={fromPdp}
										hideDetailsBtn={!!(index === 0) && fromPdp}
									/>
								) : (
									<></>
								)
								// <OneTalkDeviceDetails
								//             {...device}
								//             key={device.deviceId}
								//             onCloseClick={handleDeviceTileCloseClick}
								//             aem={aem}

								//           />
							}
						</div>
					),
				});
			});
			if (!fromPdp && !fromOnetalk) {
				if (!(compareDeviceInfoList.length === 4)) {
					arrList.push({
						children: (
							<div>
								<CompareSelectDevice aem={aem} />
							</div>
						),
					});
				} else if (compareDeviceInfoList.length === 2) {
					arrList.push({
						children: (
							<div>
								<BorderBox></BorderBox>
							</div>
						),
					});
				}
				if (
					compareDeviceInfoList.length === 1 ||
					compareDeviceInfoList.length === 0 ||
					compareDeviceInfoList.length === 2
				) {
					arrList.push({
						children: (
							<div>
								<LastBorderBox></LastBorderBox>
							</div>
						),
					});
					if (compareDeviceInfoList.length !== 2) {
						arrList.push({
							children: (
								<div>
									<LastBorderBox></LastBorderBox>
								</div>
							),
						});
					}
				}
			}
		}
		return arrList;
	};

	return (
		<>
			<ImageContainer>
				<Carousel
					layout="2UP"
					peek="none"
					aspectRatio="none"
					gutter="16px"
					surface="light"
					paginationFill="light"
					paginationDisplay="persistent"
					paginationInset="12px"
					data={scrollCompare() || []}
					renderItem={(tile) => <div>{tile?.children}</div>}
					selectedIndex={selectedIndex}
					onChange={(props, selectedGroupIndex) => {
						setSelectedIndex(selectedGroupIndex);
						if (selectedGroupIndex === 0) {
							setCompareDeviceList(startArray);
						} else if (selectedGroupIndex === 1) {
							setCompareDeviceList(endArray);
						}
					}}
				/>
			</ImageContainer>
			<>
				<ComparingSection>
					<Accordion>
						<AccordionItem
							opened={
								compareDeviceList !== null &&
								compareDeviceList &&
								compareDeviceList.length
							}
						>
							<AccordionHeader triggerType="icon">
								<AccordionTitle>
									<AccordionTitleClass> {aem?.labels?.batteryLife}</AccordionTitleClass>
								</AccordionTitle>
							</AccordionHeader>
							<AccordionDetail>
								<DeviceCompareSection>
									{compareDeviceList.map((device) => (
										<CompareListNotGrayed>
											<CompareTitle>{aem?.labels?.standbyTime}</CompareTitle>
											{device?.batteryDetails?.standbyTime}
										</CompareListNotGrayed>
									))}
								</DeviceCompareSection>
								<DeviceCompareSection>
									{compareDeviceList.map((device) => (
										<CompareListGrayed>
											{" "}
											<CompareTitle>{aem?.labels?.usageTime}</CompareTitle>
											<CompareSubTitle>
												{device?.batteryDetails?.usageTime}
											</CompareSubTitle>
										</CompareListGrayed>
									))}
								</DeviceCompareSection>
							</AccordionDetail>
						</AccordionItem>
					</Accordion>
					<Accordion>
						<AccordionItem
							opened={
								compareDeviceList !== null &&
								compareDeviceList &&
								compareDeviceList.length
							}
						>
							<AccordionHeader triggerType="icon">
								<AccordionTitle>
									<AccordionTitleClass>{aem?.labels?.dimensions}</AccordionTitleClass>
								</AccordionTitle>
							</AccordionHeader>
							<AccordionDetail>
								<DeviceCompareSection>
									{compareDeviceList.map((device) => (
										<CompareListNotGrayed>
											<CompareTitle>{aem?.labels?.height}</CompareTitle>
											{device?.dimensions?.height}
										</CompareListNotGrayed>
									))}
								</DeviceCompareSection>
								<DeviceCompareSection>
									{compareDeviceList.map((device) => (
										<CompareListGrayed>
											<CompareTitle>{aem?.labels?.width}</CompareTitle>
											{device?.dimensions?.width}
										</CompareListGrayed>
									))}
								</DeviceCompareSection>
								<DeviceCompareSection>
									{compareDeviceList.map((device) => (
										<CompareListNotGrayed>
											{" "}
											<CompareTitle>{aem?.labels?.depth}</CompareTitle>
											{device?.dimensions?.depth}
										</CompareListNotGrayed>
									))}
								</DeviceCompareSection>
							</AccordionDetail>
						</AccordionItem>
					</Accordion>
					<Accordion>
						<AccordionItem
							opened={
								compareDeviceList !== null &&
								compareDeviceList &&
								compareDeviceList.length
							}
						>
							<AccordionHeader triggerType="icon">
								<AccordionTitle>
									<AccordionTitleClass>{aem?.labels?.screen}</AccordionTitleClass>
								</AccordionTitle>
							</AccordionHeader>
							<AccordionDetail>
								<DeviceCompareSection mb="40">
									{compareDeviceList.map((device) => (
										<CompareListNotGrayed>
											<Screen> {device.screen}</Screen>
										</CompareListNotGrayed>
									))}
								</DeviceCompareSection>
							</AccordionDetail>
						</AccordionItem>
					</Accordion>
					<Accordion>
						<AccordionItem
							opened={
								compareDeviceList !== null &&
								compareDeviceList &&
								compareDeviceList.length
							}
						>
							<AccordionHeader triggerType="icon">
								<AccordionTitle>
									<AccordionTitleClass>{aem?.labels?.reviews2}</AccordionTitleClass>
								</AccordionTitle>
							</AccordionHeader>
							<AccordionDetail>
								<DeviceCompareSection mb="40">
									{compareDeviceList.map((device) => (
										<>
											<CompareListNotGrayed style={{ height: "160px" }}>
												<RatingstarRenderer
													{...device}
													overallRating={
														MobilityPageData.reviewsForCompareDevices !== null &&
														MobilityPageData.reviewsForCompareDevices &&
														Object.keys(MobilityPageData.reviewsForCompareDevices).length >
															0 &&
														MobilityPageData.reviewsForCompareDevices[device.deviceId] !==
															null &&
														MobilityPageData.reviewsForCompareDevices[device.deviceId]
															? MobilityPageData.reviewsForCompareDevices[device.deviceId][
																	"averageRating"
															  ]
															: 0
													}
												/>
												<Micro>
													<TextLink onClick={onReviewsClick}>
														{" "}
														(
														{MobilityPageData.reviewsForCompareDevices !== null &&
														MobilityPageData.reviewsForCompareDevices &&
														Object.keys(MobilityPageData.reviewsForCompareDevices).length >
															0 &&
														MobilityPageData.reviewsForCompareDevices[device.deviceId] !==
															null &&
														MobilityPageData.reviewsForCompareDevices[device.deviceId]
															? MobilityPageData.reviewsForCompareDevices[device.deviceId][
																	"reviews"
															  ]
															: 0}{" "}
														{aem?.labels?.reviews2})
													</TextLink>
												</Micro>
												<>
													<CompareTitleReview>{aem?.labels?.design}</CompareTitleReview>
													<CompareTitleReview>
														{MobilityPageData?.reviewsForCompareDevices !== null &&
														MobilityPageData?.reviewsForCompareDevices &&
														Object.keys(MobilityPageData?.reviewsForCompareDevices).length >
															0 &&
														MobilityPageData?.reviewsForCompareDevices[device.deviceId] !==
															null &&
														MobilityPageData?.reviewsForCompareDevices[device.deviceId]
															? MobilityPageData?.reviewsForCompareDevices[
																	device.deviceId
															  ]?.secondaryRatings?.Design?.AverageRating.toFixed(1)
															: 0}
													</CompareTitleReview>

													<CompareTitleReview>{aem?.labels?.features}</CompareTitleReview>
													<CompareTitleReview>
														{MobilityPageData?.reviewsForCompareDevices !== null &&
														MobilityPageData?.reviewsForCompareDevices &&
														Object.keys(MobilityPageData?.reviewsForCompareDevices).length >
															0 &&
														MobilityPageData?.reviewsForCompareDevices[device.deviceId] !==
															null &&
														MobilityPageData?.reviewsForCompareDevices[device.deviceId]
															? MobilityPageData?.reviewsForCompareDevices[
																	device.deviceId
															  ]?.secondaryRatings?.Features?.AverageRating.toFixed(1)
															: 0}
													</CompareTitleReview>

													<CompareTitleReview> {aem?.labels?.storage}</CompareTitleReview>
													<CompareTitleReview>{"-"}</CompareTitleReview>

													<CompareTitleReview> {aem?.labels?.display}</CompareTitleReview>
													<CompareTitleReview>{"-"}</CompareTitleReview>

													<CompareTitleReview>
														{" "}
														{aem?.labels?.batteryLife}
													</CompareTitleReview>
													<CompareTitleReview>{"-"}</CompareTitleReview>
												</>
											</CompareListNotGrayed>
										</>
									))}
								</DeviceCompareSection>
							</AccordionDetail>
						</AccordionItem>
					</Accordion>
					<Accordion>
						<AccordionItem
							opened={
								compareDeviceList !== null &&
								compareDeviceList &&
								compareDeviceList.length
							}
						>
							<AccordionHeader triggerType="icon">
								<AccordionTitle>
									<AccordionTitleClass>{aem?.labels?.weight}</AccordionTitleClass>
								</AccordionTitle>
							</AccordionHeader>
							<AccordionDetail>
								<DeviceCompareSection mb="40">
									{compareDeviceList.map((device) => (
										<CompareListNotGrayed>{device.weight}</CompareListNotGrayed>
									))}
								</DeviceCompareSection>
							</AccordionDetail>
						</AccordionItem>
					</Accordion>
					<Accordion>
						<AccordionItem
							opened={
								compareDeviceList !== null &&
								compareDeviceList &&
								compareDeviceList.length
							}
						>
							<AccordionHeader triggerType="icon">
								<AccordionTitle>
									<AccordionTitleClass>{aem?.labels?.storage}</AccordionTitleClass>
								</AccordionTitle>
							</AccordionHeader>
							<AccordionDetail>
								<DeviceCompareSection mb="40">
									{compareDeviceList.map((device) => {
										if (device?.storageOptions?.length > 1) {
											return (
												<CompareListNotGrayed>
													{device?.storageOptions?.map((item) => {
														return <div> {item}</div>;
													})}
												</CompareListNotGrayed>
											);
										}
										return (
											<CompareListNotGrayed>
												{device?.storageOptions?.map((storage, idx) => (
													<div key={idx}>{storage}</div>
												))}
											</CompareListNotGrayed>
										);
									})}
								</DeviceCompareSection>
							</AccordionDetail>
						</AccordionItem>
					</Accordion>
					<Accordion>
						<AccordionItem
							opened={
								compareDeviceList !== null &&
								compareDeviceList &&
								compareDeviceList.length
							}
						>
							<AccordionHeader triggerType="icon">
								<AccordionTitle>
									<AccordionTitleClass>{aem?.labels?.rearCamera}</AccordionTitleClass>
								</AccordionTitle>
							</AccordionHeader>
							<AccordionDetail>
								<DeviceCompareSection mb="40">
									{compareDeviceList.map((device) => (
										<CompareListNotGrayed>
											{device.rearCameraPixelSize}
										</CompareListNotGrayed>
									))}
								</DeviceCompareSection>
							</AccordionDetail>
						</AccordionItem>
					</Accordion>
					<Accordion>
						<AccordionItem
							opened={
								compareDeviceList !== null &&
								compareDeviceList &&
								compareDeviceList.length
							}
						>
							<AccordionHeader triggerType="icon">
								<AccordionTitle>
									<AccordionTitleClass>{aem?.labels?.worldDevice}</AccordionTitleClass>
								</AccordionTitle>
							</AccordionHeader>
							<AccordionDetail>
								<DeviceCompareSection mb="40">
									{compareDeviceList.map((device) => (
										<CompareListNotGrayed>{device.worldDevice}</CompareListNotGrayed>
									))}
								</DeviceCompareSection>
							</AccordionDetail>
						</AccordionItem>
					</Accordion>
					<Accordion>
						<AccordionItem
							opened={
								compareDeviceList !== null &&
								compareDeviceList &&
								compareDeviceList.length
							}
						>
							<AccordionHeader triggerType="icon">
								<AccordionTitle>
									<AccordionTitleClass>{aem?.labels?.colors}</AccordionTitleClass>
								</AccordionTitle>
							</AccordionHeader>
							<AccordionDetail>
								<DeviceCompareSection mb="40">
									{compareDeviceList.map((device) => (
										<CompareListNewNotGrayed style={{ flexDirection: "row" }}>
											{colorSwatchRenderer(device)}
										</CompareListNewNotGrayed>
									))}
								</DeviceCompareSection>
							</AccordionDetail>
						</AccordionItem>
					</Accordion>
				</ComparingSection>
			</>
		</>
	);
};

const Screen = styled.div`
	width: 50% @media only screen and (max-width: 767px) {
		width: 95%;
	}
`;
const CompareSubTitle = styled.div`
	width: 50% @media only screen and (max-width: 767px) {
		width: 95%;
	}
`;
const ComparingSection = styled.div`
width: 100% !important;
.toggleIconWrapper{
  right:10px !important;

`;
const AccordionTitleClass = styled.div`
	margin-left: 10px !important;
`;
const CompareListGrayed = styled.div`
display: flex;
width: 100%;
background-color: #fff;
flex-direction: column;
font-size:14px;
padding-left: 20px;
padding-top: 10px;
padding-bottom: 10px;
}
display: flex;
justify-content: space-between;
font-weight: 400;
background-color:#f6f6f6 !important;
@media only screen and (max-width: 767px) {
  font-size:12px;
}
`;

const CompareListNotGrayed = styled.div`
	display: flex;
	width: 100%;
	background-color: #fff;
	flex-direction: column;
	font-size: 14px;
	padding-left: 20px;
	display: flex;
	justify-content: space-between;
	font-weight: 400;
	@media only screen and (max-width: 767px) {
		height: 100% !important;
		font-size: 12px;
	}
`;

const CompareListNewNotGrayed = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 50%;
	background-color: #fff;
	flex-direction: column;
	font-size: 14px;
	padding: 0px 4%;
	display: flex;
	justify-content: flex-start;
	font-weight: 400;
	align-items: baseline;
	@media only screen and (max-width: 767px) {
		height: 100%;
		font-size: 12px;
	}
`;
const DeviceCompareSection = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	${({ mb = 0 }) => `margin-bottom: ${mb}px;`};
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	margin-top: 20px;
	margin-bottom: 10px;
`;
const CompareTitle = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #6f7171;
	@media only screen and (max-width: 767px) {
		font-size: 12px;
	}
`;
const CompareTitleReview = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	@media only screen and (max-width: 767px) {
		font-size: 12px;
		line-height: 22px;
	}
`;
const ImageContainer = styled.div`
	@media only screen and (max-width: 767px) {
		width: 100%;
		position: relative;
	}

	@media only screen and (max-width: 520px) {
		& > div > div:first-child {
			& > div:first-child {
				& > div > div > div > div {
					padding: 0px !important;
				}
			}
		}
	}

	@media only screen and (max-width: 520px) {
		& > div > div:first-child {
			& > div:nth-child(2) {
				padding-left: 5px !important;
				& > div > div > div > div {
					padding: 0px !important;
				}
			}
		}
	}

	@media only screen and (max-width: 520px) {
		& > div > div > span {
			top: calc(50% - 55px) !important;
		}
	}
`;

const BorderBox = styled.div`
	width: 290px;
	border: 1px dashed #000000;
	border-radius: 8px;
	margin-top: 6px;
	margin-left: 10px;
	margin-bottom: 8px;
	@media only screen and (max-width: 767px) {
		margin: 5px;
	}
`;

const LastBorderBox = styled.div`
	width: 290px;
	border: 1px dashed #000000;
	border-radius: 8px;
	margin-top: 6px;
	margin-left: 10px;
	margin-bottom: 8px;
	height: 100%;
	min-height: 393px;
	@media only screen and (max-width: 767px) {
		margin: 5px;
	}
	@media only screen and (max-width: 545px) {
		width: 152px;
		margin: 0px;
		min-height: 501px;
	}
`;

export default ComparePDPCarousel;
