import { Button } from "@vds3/buttons";
import { Col, Grid, Row } from "@vds3/grids";
import { Input } from "@vds3/inputs";
import { Body, Micro } from "@vds3/typography";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import MapQStreetAddress from "./components/MapQStreetAdress";
import * as CONSTANTS from "./containers/constants";
import { reducer } from "./containers/FormReducer";
import { CAButtonWrapper } from "./styledComponents";
import { useDispatch } from "react-redux";
import {
	checkAddressValidation,
	setAddressValidationInfo,
	setSameAsCompanyAddressNode,
} from "../ducks/CheckoutSlice";
import "./ShippingInfo.css";
import { Notification } from "@vds3/notifications";
import { MarginSpacerM } from "common/components/styleTag";

const ShippingInfo = (props, ref) => {
	const redDisptach = useDispatch();
	const [state, dispatch] = useReducer(
		reducer,
		JSON.parse(localStorage.getItem("shippingState"))
	);
	const [errors, setErrors] = useState({});
	const [businessUnit, setBusinessUnit] = useState("");
	const [businessSaStreetAddressObj, setBusinessStreetADressObj] = useState({});
	const [streetValue, setStreetValue] = useState("");
	const [cityValue, setCityValue] = useState("");
	const [showAddrsErr, setShowAddrsErr] = useState(false);
	const [continueClick, setContinueClick] = useState(false);
	const [propertyType, setPropertyType] = useState("");
	const [stateValue, setStateValue] = useState("");
	const [zipValue, setZipValue] = useState("");
	const [addressLineOne, setAddresLineOne] = useState(null);
	const businessStreetRef = useRef();
	const { t } = useTranslation(["app", "checkout"]);
	const enableAddressContinuebtn = t("verification.enableAddressContinuebtn", {
		ns: "checkout",
	});
	const addressConfirmaionContent = t("verification.addressConfirmationModal", {
		ns: "checkout",
		returnObjects: true,
	});
	useEffect(() => {
		if (props?.setShippingState) {
			props?.setShippingState(state);
			setAddresLineOne(state?.businessStreetAddress?.name);
			handleCompanyInfo();
			if (props?.prospect5gLoopQualAddress?.businessName)
				dispatch({
					type: CONSTANTS.SET_COMPANY_NAME,
					payload: props?.prospect5gLoopQualAddress?.businessName,
				});
		}
		const address = props?.sessionCartData?.businessInfo?.address;
		if (
			address !== null &&
			address &&
			Object.keys(address)?.length &&
			!addressLineOne
		) {
			let currentAddress = {
				place: {
					properties: {
						country: address?.country,
						countryCode: address?.country,
						state: address?.state,
						stateCode: address?.state,
						street: address?.addressLine1,
						city: address?.city,
						postalCode: address?.zipCode,
						propertyType: address?.propertyType,
					},
				},
				displayString: address?.addressLine1,
				name: address?.addressLine1,
			};
			if (currentAddress) {
				handleAddressSelection(currentAddress);
				setAddresLineOne(address?.addressLine1 || "");
			}
		}
		if (props?.sessionCartData?.businessInfo?.phoneNumber) {
			dispatch({
				type: CONSTANTS.SET_PHONE_NUMBER,
				payload: props?.sessionCartData?.businessInfo?.phoneNumber,
			});
		}
		if (props?.sessionCartData?.businessInfo?.bussinessName) {
			dispatch({
				type: CONSTANTS.SET_COMPANY_NAME,
				payload: props?.sessionCartData?.businessInfo?.bussinessName,
			});
		}
	}, []);

	useEffect(() => {
		if (props?.setShippingState) {
			props?.setShippingState(state);
			setAddresLineOne(state?.businessStreetAddress?.name);
			if (streetValue === state?.businessStreetAddress?.name) {
				handleCompanyInfo();
			}
		}
	}, [state]);

	useEffect(() => {
		const { prospect5gLoopQualAddress: address = null } = props;
		// if (address !== null && Object.keys(address)?.length && !addressLineOne) {
		// 	let currentAddress = {
		// 		place: {
		// 			properties: {
		// 				country: address?.country,
		// 				countryCode: address?.country,
		// 				state: address?.state,
		// 				stateCode: address?.state,
		// 				street: address?.addressLine1,
		// 				city: address?.city,
		// 				postalCode: address?.zipCode,
		// 				propertyType: address?.propertyType,
		// 			},
		// 		},
		// 		displayString: address?.addressLine1,
		// 		name: address?.addressLine1,
		// 	};
		// 	if (currentAddress) {
		// 		handleAddressSelection(currentAddress);
		// 		setAddresLineOne(address?.addressLine1 || "");
		// 	}
		// }
		// if (props?.prospect5gLoopQualAddress?.phoneNumber) {
		// 	dispatch({
		// 		type: CONSTANTS.SET_PHONE_NUMBER,
		// 		payload: props?.prospect5gLoopQualAddress?.phoneNumber,
		// 	});
		// }
		if (props?.prospect5gLoopQualAddress?.businessName) {
			dispatch({
				type: CONSTANTS.SET_COMPANY_NAME,
				payload: props?.prospect5gLoopQualAddress?.businessName,
			});
		} else if (props?.shoppingCartData?.fetchCart?.businessInfo?.bussinessName) {
			dispatch({
				type: CONSTANTS.SET_COMPANY_NAME,
				payload: props?.shoppingCartData?.fetchCart?.businessInfo?.bussinessName,
			});
		}
		// if (props?.shoppingCartData?.fetchCart?.businessInfo?.phoneNumber){
		// 	dispatch({
		// 		type: CONSTANTS.SET_PHONE_NUMBER,
		// 		payload: props?.shoppingCartData?.fetchCart?.businessInfo?.phoneNumber,
		// 	});
		// }
	}, [props?.prospect5gLoopQualAddress]);

	const handleCompanyInfo = () => {
		setCityValue(state?.businessStreetAddress?.place?.properties?.city);
		setStateValue(state?.businessStreetAddress?.place?.properties?.state);
		setZipValue(state?.businessStreetAddress?.place?.properties?.postalCode);
	};

	const onClickCheck = async () => {
		if (hasErrors()) {
			return;
		}
		if (state?.businessStreetAddress) {
			if (
				state?.isSameAddress ||
				(state?.isSameAddress && state?.shippingStreetAddress)
			) {
				props.eventHandlers?.emit("updateShippingInfo", state);
			}
		}
	};

	const hasErrors = () => {
		let error = false;
		for (const key of Object.keys(errors)) {
			if (errors[key] && errors[key]?.length > 0) {
				error = true;
			}
		}
		if (
			!state?.businessStreetAddress?.displayString ||
			!state?.companyName ||
			!state?.phoneNumber
		) {
			error = true;
		}
		if (
			!state?.businessStreetAddress?.displayString &&
			enableAddressContinuebtn &&
			streetValue &&
			cityValue?.length > 2 &&
			stateValue?.length > 1 &&
			zipValue?.length > 4
		) {
			error = false;
		}
		return error;
	};
	const validateBusinessAddress = () => {
		setShowAddrsErr(false);
		let payload;
		if (state?.businessStreetAddress?.place?.properties?.street) {
			payload = {
				state: state?.businessStreetAddress?.place?.properties?.state ?? state?.businessStreetAddress?.place?.properties?.stateCode,
				addressLine1: state?.businessStreetAddress?.place?.properties?.street,
				addressLine2: "",
				city: state?.businessStreetAddress?.place?.properties?.city,
				zipCode: state?.businessStreetAddress?.place?.properties?.postalCode,
			};
		}
		redDisptach(checkAddressValidation(payload));
		window.dispatchEvent(
			new CustomEvent("customerPhoneInfoFromWidget", {
				detail: {
					customerPhoneNumber: state?.phoneNumber,
				},
			})
		);
		setContinueClick(true);
	};

	useEffect(() => {
		if (continueClick) {
			if (props?.shippingPageData?.addressValidationInfo?.serviceStatus?.success) {
				setShowAddrsErr(false);
				// handleAddressSelection(temp);
				props?.saveBusinessInfo();
			} else if (
				props?.shippingPageData?.addressValidationInfo?.serviceStatus?.success ==
				false
			) {
				setContinueClick(false);
				setShowAddrsErr(true);
			}
			redDisptach(setAddressValidationInfo(null));
		}
	}, [props?.shippingPageData?.addressValidationInfo?.serviceStatus]);

	useEffect(() => {
		if (
			props?.shippingPageData?.saveBusinessInfoResponse?.serviceStatus?.success &&
			continueClick
		) {
			setContinueClick(false);

			props?.onContinue();
		}
	}, [props?.shippingPageData?.saveBusinessInfoResponse?.serviceStatus]);

	const isValidContactInfoField = (key, e) => {
		let errorTexts = "";
		let val = e?.target?.value;
		// businessName
		if (key === "companyName") {
			errorTexts =
				!val || !val?.trim()
					? t("verification.errorMsgs.companyName", { ns: "checkout" })
					: val === val.replace(/[^0-9!@#\$%\^\&*]/g, "")
					? t("verification.errorMsgs.companyNameInValid", {
							ns: "checkout",
					  })
					: "";
			const specialCharactersNotAllowed = t(
				"verification.specialCharactersNotAllowed",
				{ ns: "checkout" }
			);
			if (specialCharactersNotAllowed && specialCharactersNotAllowed === true) {
				let errorVal = false;
				const splChar = t("verification.specialCharactersForCompanyName", {
					ns: "checkout",
					returnObjects: true,
				});
				if (
					splChar !== null &&
					splChar &&
					splChar.length &&
					typeof splChar == "object"
				) {
					splChar.map((char) => {
						if (String(val).includes(char)) errorVal = true;
						return;
					});
					if (errorVal)
						errorTexts = t("verification.errorMsgs.validCompanyName", {
							ns: "checkout",
						});
				}
			}
			dispatch({ type: CONSTANTS.SET_COMPANY_NAME, payload: val });
		}
		// phoneNumber
		if (key === "phoneNumber") {
			var checkChars = /[^0-9]/gi;
			if (checkChars?.test(val)) {
				val = val?.replace(checkChars, "");
			}
			const phoneNumber = val?.replace(/[^\d]/g, "");
			if (/^[0-9]{0,10}$/.test(phoneNumber)) {
				val = phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, "$1.$2.$3");
			}
			if (!val?.trim()) {
				errorTexts = t("verification.errorMsgs.phoneReq", {
					ns: "checkout",
				});
			} else {
				const pattern = /^\d{10}$/;
				if (!pattern.test(phoneNumber)) {
					errorTexts = t("verification.errorMsgs.phoneLength", {
						ns: "checkout",
					});
				} else {
					errorTexts = "";
				}
			}
			dispatch({ type: CONSTANTS.SET_PHONE_NUMBER, payload: val });
			redDisptach(setSameAsCompanyAddressNode({ key: "phoneNumber", value: val }));
		}
		setErrors({ ...errors, [key]: errorTexts });
		dispatch({
			type: CONSTANTS.SET_SHIPPING_ERRORS,
			payload: { ...errors, [key]: errorTexts },
		});
	};

	const handleAddressInput = (e, name) => {
		const value = e?.target?.value;
		const properties = businessSaStreetAddressObj?.place?.properties;
		let objValue = {
			place: {
				properties: {
					country: properties?.country,
					countryCode: properties?.country,
					state: properties?.state,
					stateCode: properties?.stateCode,
					street: properties?.street,
					city: properties?.city,
					postalCode: properties?.postalCode,
					propertyType: properties?.propertyType,
				},
			},
			displayString: businessSaStreetAddressObj?.displayString,
			name: businessSaStreetAddressObj?.name,
		};
		if (name === "street") {
			objValue.place.properties.street = streetValue?.length
				? value
				: properties?.street;
			objValue.place.properties.city = cityValue?.length
				? cityValue
				: properties?.city;
			objValue.place.properties.stateCode = stateValue?.length
				? stateValue
				: properties?.stateCode;
			objValue.place.properties.postalCode = zipValue?.length
				? zipValue
				: properties?.postalCode;
			objValue.place.properties.propertyType = propertyType?.length
				? propertyType
				: properties?.propertyType;
			setStreetValue(value);
			if (!value) {
				setErrors({
					...errors,
					street: "Street is required",
				});
			} else {
				setErrors({
					...errors,
					street: null,
				});
			}
		} else if (name === "city") {
			objValue.place.properties.street = streetValue?.length
				? streetValue
				: properties?.street;
			objValue.place.properties.city = cityValue?.length
				? value
				: properties?.city;
			objValue.place.properties.stateCode = stateValue?.length
				? stateValue
				: properties?.stateCode;
			objValue.place.properties.postalCode = zipValue?.length
				? zipValue
				: properties?.postalCode;
			objValue.place.properties.propertyType = propertyType?.length
				? propertyType
				: properties?.propertyType;
			setCityValue(value);
			const PATTERN_STATE = /^[a-zA-Z ]+$/.test(value);

			if (!value) {
				setErrors({
					...errors,
					city: "City is required",
				});
			} else if (!PATTERN_STATE) {
				setErrors({
					...errors,
					city: "City is invalid",
				});
			} else {
				setErrors({
					...errors,
					city: null,
				});
			}
		} else if (name === "state") {
			objValue.place.properties.street = streetValue?.length
				? streetValue
				: properties?.street;
			objValue.place.properties.city = cityValue?.length
				? cityValue
				: properties?.city;
			objValue.place.properties.stateCode = stateValue?.length
				? value
				: properties?.stateCode;
			objValue.place.properties.postalCode = zipValue?.length
				? zipValue
				: properties?.postalCode;
			objValue.place.properties.propertyType = propertyType?.length
				? propertyType
				: properties?.propertyType;
			const PATTERN_STATE = /^[a-zA-Z]+$/.test(value);
			if (value?.length > 2) {
				return;
			}
			setStateValue(value);
			if (!value) {
				setErrors({
					...errors,
					state: "State is required",
				});
			} else if (!PATTERN_STATE) {
				setErrors({
					...errors,
					state: "State code is invalid",
				});
			} else {
				setErrors({
					...errors,
					state: null,
				});
			}
		} else if (name === "zipCode") {
			objValue.place.properties.street = streetValue?.length
				? streetValue
				: properties?.street;
			objValue.place.properties.city = cityValue?.length
				? cityValue
				: properties?.city;
			objValue.place.properties.stateCode = stateValue?.length
				? stateValue
				: properties?.stateCode;
			objValue.place.properties.postalCode = zipValue?.length
				? value
				: properties?.postalCode;
			objValue.place.properties.propertyType = propertyType?.length
				? propertyType
				: properties?.propertyType;
			const PATTERN_ZIPCODE = /^\d{5}(-\d{4})?$/.test(value);
			if (value?.length > 9) {
				return;
			}
			setZipValue(value);
			if (!value) {
				setErrors({
					...errors,
					zipCode: "Zip code is required",
				});
			} else if (!PATTERN_ZIPCODE) {
				setErrors({
					...errors,
					zipCode: "Zip code is invalid",
				});
			} else {
				setErrors({
					...errors,
					zipCode: null,
				});
			}
		}
		dispatch({
			type: CONSTANTS.SET_BUSINESS_STREET_ADDRESS,
			payload: objValue,
		});
	};

	const handleAddressSelection = (value) => {
		let tempErrors = {};
		if (!value) {
			tempErrors = {
				...errors,
				city: "City is required",
				state: "State is required",
				zipCode: "Zip code is required",
				businessStreetAddress: "Address line 1 is required.",
			};
		} else {
			tempErrors = {
				...errors,
				city: "",
				state: "",
				zipCode: "",
				businessStreetAddress: "",
			};
		}
		const properties = value?.place?.properties;
		if (
			properties?.street?.toUpperCase &&
			properties?.street?.toUpperCase()?.includes("PO BOX")
		) {
			tempErrors = {
				...errors,
				businessStreetAddress:
					"For security and fraud reasons, we do not allow PO boxes.",
			};
		}
		setBusinessStreetADressObj(value);
		setCityValue(properties ? properties?.city : "");
		setPropertyType(properties ? properties?.propertyType : "");
		setStateValue(properties ? properties?.stateCode : "");
		setZipValue(properties ? properties?.postalCode : "");
		setErrors(tempErrors);
		dispatch({
			type: CONSTANTS.SET_BUSINESS_STREET_ADDRESS,
			payload: value,
		});
		dispatch({
			type: CONSTANTS.SET_SHIPPING_ERRORS,
			payload: { ...tempErrors },
		});
	};

	return (
		<div className="shippingInfo">
			<div className="container">
				<Grid
					colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
					colGutter={false}
					rowGutter="5"
				>
					<Row>
						<Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
							<div className="Prod-Discription font-12">
								<Body size="medium">
									{t("verification.messages.infomsg1", { ns: "checkout" })}
								</Body>
							</div>
						</Col>
					</Row>
					<Row>
						<Col
							colSizes={{ mobile: 4, tablet: 6, desktop: 4 }}
							className="ttip-wrapper"
						>
							<div className="form-col comp-name">
								<Input
									label={`${t("verification.labelTexts.companyName", {
										ns: "checkout",
									})}*`}
									placeholder=""
									name="companyName"
									width={"100%"}
									maxLength={30}
									value={state?.companyName}
									onChange={(e) => isValidContactInfoField("companyName", e)}
									error={errors?.companyName?.length > 0}
									errorText={
										errors?.companyName ||
										t("verification.errorMsgs.companyName", {
											ns: "checkout",
										})
									}
									tooltipContent={t("verification.labelTexts.companyNameTooltip", {
										ns: "checkout",
									})}
								/>
							</div>
						</Col>
						<Col colSizes={{ mobile: 4, tablet: 6, desktop: 4 }}>
							<div className="form-col">
								<Input
									label={`${t("verification.labelTexts.bussinessPhone", {
										ns: "checkout",
									})}*`}
									placeholder=""
									maxLength={10}
									name="phoneNumber"
									value={
										state?.phoneNumber?.length === 10
											? state?.phoneNumber?.includes(".")
												? state?.phoneNumber
												: state?.phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, "$1.$2.$3")
											: state?.phoneNumber
									}
									width={"100%"}
									onChange={(e) => isValidContactInfoField("phoneNumber", e)}
									error={errors?.phoneNumber?.length > 0}
									errorText={
										errors?.phoneNumber ||
										t("verification.errorMsgs.phoneReq", { ns: "checkout" })
									}
								/>
								<div className="helper-text">
									<Micro size="small">
										{t("verification.helperTexts.bussinessPhone", {
											ns: "checkout",
										})}
									</Micro>
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						<Col colSizes={{ mobile: 4, tablet: 6, desktop: 4 }}>
							<div className="form-col">
								<MapQStreetAddress
									errorMessage={errors?.businessStreetAddress}
									label={t("verification.labelTexts.addressLine1", {
										ns: "checkout",
									})}
									onSelectionChange={handleAddressSelection}
									handleAddressInput={(e) => handleAddressInput(e, "street")}
									isAddressEmty={true}
									isError={errors?.businessStreetAddress}
									setAddressFieldError={(value) => {
										setErrors({ ...errors, businessStreetAddress: value });
										dispatch({
											type: CONSTANTS.SET_SHIPPING_ERRORS,
											payload: { ...errors, businessStreetAddress: value },
										});
									}}
									helperText={t("verification.labelTexts.address1Helper", {
										ns: "checkout",
									})}
									setAutoFocus={props?.setAutoFocus}
									ref={businessStreetRef}
									isNameOnly
									addressLineOne={addressLineOne || ""}
									isVerifyBussiness={true}
									hideTooltip={true}
								/>
							</div>
						</Col>
						<Col colSizes={{ mobile: 4, tablet: 6, desktop: 4 }}>
							<div className="form-col">
								<Input
									type="text"
									label={t("verification.labelTexts.addressLine2", {
										ns: "checkout",
									})}
									placeholder="Enter address line 2"
									onChange={(e) => {
										dispatch({
											type: CONSTANTS.SET_BUSINESS_STREET_ADDRESS2,
											payload: e?.target?.value,
										});
										setBusinessUnit(e?.target?.value);
									}}
									value={businessUnit}
								/>
							</div>
							<div className="helper-text" style={{ color: "747676" }}>
								<Micro size="small">
									{t("verification.labelTexts.address2Helper", {
										ns: "checkout",
									})}
								</Micro>
							</div>
						</Col>
					</Row>
					<Row>
						<Col colSizes={{ mobile: 4, tablet: 6, desktop: 4 }}>
							<div className="form-col">
								<Input
									type="text"
									name="city"
									label="City*"
									placeholder="Enter City"
									error={!!errors?.city}
									errorText={errors?.city}
									required={true}
									value={cityValue}
									onChange={(e) => handleAddressInput(e, "city")}
								/>
							</div>
						</Col>
						<Col colSizes={{ mobile: 4, tablet: 6, desktop: 2 }}>
							<div className="form-col">
								<Input
									type="text"
									name="state"
									label="State*"
									placeholder="Enter State"
									error={!!errors?.state}
									errorText={errors?.state}
									required={true}
									value={stateValue}
									onChange={(e) => handleAddressInput(e, "state")}
								/>
							</div>
						</Col>
						<Col colSizes={{ mobile: 4, tablet: 6, desktop: 2 }}>
							<div className="form-col">
								<Input
									type="text"
									name="zipCode"
									label="Zip Code*"
									placeholder="Enter Zip Code"
									error={!!errors?.zipCode}
									errorText={errors?.zipCode}
									required={true}
									value={zipValue}
									onChange={(e) => handleAddressInput(e, "zipCode")}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col colSizes={{ mobile: 4, tablet: 6, desktop: 12 }}>
							{!props.hideContinue && (
								<div className="disclaimer-action">
									{showAddrsErr && (
										<Notification
											type="error"
											title={addressConfirmaionContent?.widigetNotificationErr}
											fullBleed={true}
											inlineTreatment={false}
										/>
									)}
									<MarginSpacerM />

									<CAButtonWrapper>
										<Button
											name="lq-widget-continuebutton"
											id="lq-widget-continuebutton"
											disabled={hasErrors()}
											onClick={() => {
												validateBusinessAddress();
												//props?.onContinue();
											}}
											data-analyticstrack="Continue:Company Information"
										>
											{t("app.common.page-stepper.continue-btn", { ns: "app" })}
										</Button>
									</CAButtonWrapper>
								</div>
							)}
						</Col>
					</Row>
				</Grid>
			</div>
		</div>
	);
};

export default ShippingInfo;
