import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import { useMediaQuery } from "react-responsive";
import { CarouselBars } from "@vds3/carousels";
import LeftCaret from "./Caret/LeftCaret";
import RightCaret from "./Caret/RightCaret";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useImageUtils } from "hooks";

const PDPCarousel = React.memo(function PDPCarousel({ buss,bussImages,imageSetUrl, ...props }) {
	const [activeCarouselSlide, setActiveCarouselSlide] = useState(0);
	const [imageSet, setImageSet] = useState([]);
	const isMobile = useMediaQuery({
		query: "(max-width: 725px)",
	});
	const { getPDPImageLink, getCarouselImagesetData } = useImageUtils();

	const carouselOnChangeHandler = (val) => {
		setActiveCarouselSlide(val);
	};

	const nextHandler = (nextVal) => {
		setActiveCarouselSlide(activeCarouselSlide + nextVal);
	};

	const prevHandler = (preVal) => {
		setActiveCarouselSlide(activeCarouselSlide - preVal);
	};

	useEffect(() => {
		if (imageSetUrl) {
			getCarouselImagesetData(imageSetUrl).then((i) => setImageSet(i));
		}
	}, [imageSetUrl]);

	const carouselImageDivs = React.useMemo(() => {
		if(buss && !!bussImages && bussImages.length){
			const divs = bussImages.map((imageSrc) => (
				<div key={imageSrc}>
					<img
						src={process.env.PROSPECT_WIDGETS_APP_ASSESTS_BASE_URL + imageSrc}
						width="75%"
						height="50%"
					/>
				</div>
			));
			return divs;
		}else if (imageSet?.length) {
			const divs = imageSet.map((imageSrc) => (
				<div key={imageSrc}>
					<img
						src={getPDPImageLink(imageSrc.split("/image/VerizonWireless/")[1])}
						width="75%"
						height="50%"
					/>
				</div>
			));
			return divs;
		} else {
			return <></>;
		}
	}, [imageSet]);

	if (imageSet?.length || (buss && bussImages.length)) {
		return (
			<>
				<div className="image-container">
					{!isMobile && (
						<div onClick={() => prevHandler(1)}>
							<LeftCaret />
						</div>
					)}
					<Carousel
						infiniteLoop={true}
						onChange={carouselOnChangeHandler}
						selectedItem={activeCarouselSlide}
						showArrows={false}
						showIndicators={false}
						showStatus={false}
						showThumbs={false}
					>
						{carouselImageDivs}
					</Carousel>
					{!isMobile && (
						<div onClick={() => nextHandler(1)}>
							<RightCaret />
						</div>
					)}
				</div>
				<div className="image-bars-container">
					<CarouselBars
						activeSlide={activeCarouselSlide}
						inverted={false}
						onChange={carouselOnChangeHandler}
						slideCount={imageSet.length || 1}
						uniqueId="carouselBarId"
					/>
				</div>
			</>
		);
	} else return <></>;
});

export default PDPCarousel;
