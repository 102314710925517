import React, { useEffect, useReducer, useState } from "react";
import { reducer, actions as Actions } from "./reducer";
import INITIAL_STATE from "./initialState";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Body, Micro } from "@vds3/typography";
import { Input } from "@vds3/inputs";
import { Grid, Row, Col } from "@vds3/grids";
import { Notification } from "@vds3/notifications";
import styled from "styled-components";
import {
	MarginSpacerS,
	MarginSpacerXL,
	MarginSpacerM,
} from "../VerifyBusiness/styleTag";
import { CreditCheckContainer, EyeUnderline } from "./styledComponents";
import OneTimePassModal from "../OneTimePasscode/OneTimePassModal";
import "./CreditCheck5g.css";
import { setCreditPath } from "../ducks/CheckoutSlice";
import { addScrollView } from "utils/commonUtils";

const CustomNotification = styled(Notification)`
	opacity: unset;
`;
const CreditCheck5g = (props) => {
	const dispatcher = useDispatch();
	// const [state, dispatch] = useReducer(reducer, JSON.parse(localStorage.getItem("creditCheckState")));
	const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

	const [taxIDLabel, setTaxIDLabel] = useState(true);
	const [ssnLabel, setSSNLabel] = useState(true);
	const [confirmSSNLabel, setConfirmSSNLabel] = useState(true);
	const [ssnConfirmLabel, setSSNConfirmLabel] = useState(true);
	const pageData = useSelector((state) => state.ProspectWidgets_CheckoutData);
	const { t } = useTranslation(["checkout"]);
	useEffect(() => addScrollView("credit-check-container"), []);
	useEffect(() => {
		if (
			props.continueBtnClicked &&
			props.isTaxIdOptional &&
			(state?.corporation?.fedTaxId === null ||
				state?.corporation?.fedTaxId === undefined)
		) {
			dispatch(
				Actions.setCorporation({
					...state?.corporation,
					["fedTaxId"]: state?.corporation?.SSN,
				})
			);
		}
	}, [props.continueBtnClicked]);
	useEffect(() => {
		if (pageData?.businessInfo) {
			let { contactInfo } = pageData?.businessInfo;
			let billingName = contactInfo?.firstName + "  " + contactInfo?.lastName;
			let taxIdNumber = contactInfo?.fedTaxId;
			dispatch(
				Actions.setCorporation({ ...state?.corporation, billingName, taxIdNumber })
			);
		}
	}, [pageData.businessInfo?.contactInfo]);

	useEffect(() => {
		const { corporation } = state;
		if (!props?.isCreditChoicePageActive) {
			if (corporation?.fedTaxId && corporation?.SSN) {
				if (
					corporation?.fedTaxId?.replaceAll("-", "") ===
					corporation?.SSN?.replaceAll("-", "")
				) {
					dispatcher(setCreditPath("BizOnlyCredit"));
				} else if (
					corporation?.fedTaxId?.replaceAll("-", "") !==
					corporation?.SSN?.replaceAll("-", "")
				) {
					dispatcher(setCreditPath("BLENDED"));
				}
			} else if (corporation?.fedTaxId && !corporation?.SSN) {
				dispatcher(setCreditPath("BizOnlyCredit"));
			} else {
				dispatcher(setCreditPath("BLENDED"));
			}
		}
		if (props?.setCreditCheckState) {
			props?.setCreditCheckState(state);
		}
	}, [state]);

	// useEffect(() => {
	// 	if (props?.setCreditCheckState) {
	// 		props?.setCreditCheckState(state);
	// 	}
	// }, [state]);

	const oneTimePassModalNextBtnClick = () => {
		dispatch(Actions.setShowOneTimePassModal(false));
		props?.eventHandlers?.emit("updateCreditCheck", state);
	};

	const formatDate = (e) => {
		let string = e ? e.replaceAll("/", "").replaceAll("-", "") : "";
		if (!string?.trim || string?.length == 6) {
			let mon = "0" + string.substr(0, 1);
			let date = "0" + string.substr(1, 1);
			let year = "" + string.substr(2, 4);
			return `${mon}-${date}-${year}`;
		}
		if (!string?.trim || string?.length == 7) {
			let mon = "" + string.substr(0, 2);
			let date = "0" + string.substr(2, 1);
			let year = "" + string.substr(3, 4);
			return `${mon}-${date}-${year}`;
		}
		if (!string?.trim || string?.length == 8) {
			let mon = "" + string.substr(0, 2);
			let date = "" + string.substr(2, 2);
			let year = "" + string.substr(4, 4);
			return `${mon}-${date}-${year}`;
		}
		return e;
	};

	const findAge = (val) => {
		var today = new Date();
		var birthDate = new Date(val);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	};

	const isValidContactInfoField = (key, e) => {
		let val = "";
		let errors = "";
		const { errorTexts } = state;
		const { corporation } = errorTexts;
		// fedTaxId
		if (key === "fedTaxId") {
			val = e?.target?.value
				.replace(/[^0-9]/g, "")
				.replace(/(\d{2})(\d{7})/, "$1-$2");
			if (props?.isTaxIdOptional && val === "") errors = "";
			else
				errors =
					(!props?.isTaxIdOptional && !val) || !val?.trim() || val?.length < 10
						? t("verification.errorMsgs.validTaxId", { ns: "checkout" })
						: val?.length > 10
						? t("verification.errorMsgs.validTaxId", { ns: "checkout" })
						: "";
		}
		// dob
		if (key === "DateOfBirth") {
			let temp = e ? e.replaceAll("/", "").replaceAll("-", "") : "";
			if (!e.trim || e?.length === 0) {
				errors = t("verification.errorMsgs.dobReq", { ns: "checkout" });
			} else if (temp?.length < 8) {
				/* check if length of formated date lenth is less than 6  */
				val = e;
				errors = t("verification.errorMsgs.invalidDate", {
					ns: "checkout",
				});
			} else {
				val = formatDate(e);
				let inDate = val?.replaceAll("-", "").substr(2, 2);
				let age = findAge(val);
				let isFutureDate = new Date(val?.replace(/-/g, "/")) > new Date();
				/*  future date error */
				if (isFutureDate) {
					errors = t("verification.errorMsgs.futureDate", {
						ns: "checkout",
					});
				} else if (
					/* Invalide date error  */
					new Date(val?.replace(/-/g, "/"))?.toString() === "Invalid Date"
				) {
					errors = t("verification.errorMsgs.invalidDate", {
						ns: "checkout",
					});
				} else if (
					/* Invalide number of days error  */
					new Date(val?.replace(/-/g, "/"))?.getDate() !== parseInt(inDate)
				) {
					errors = t("verification.errorMsgs.invalidDate", {
						ns: "checkout",
					});
				} else if (!isFutureDate && age < 18) {
					/*  check for min age */
					errors = t("verification.errorMsgs.minAge", {
						ns: "checkout",
					});
				}
			}
		}
		// ssn
		if (key === "SSN") {
			val = e?.target?.value;
			val = e?.target?.value
				.replace(/[^0-9]/g, "")
				.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
			errors =
				props?.isCreditChoicePageActive &&
				pageData?.creditPath === "BLENDED" &&
				(!val || !val?.trim())
					? t("verification.errorMsgs.ssnReq", { ns: "checkout" })
					: "";
			errors +=
				pageData?.creditPath === "BLENDED" && val && val?.length != 11
					? t("verification.errorMsgs.validSSN", { ns: "checkout" })
					: "";
		}
		if (key === "confirmSSN") {
			val = e?.target?.value;
			val = e?.target?.value
				.replace(/[^0-9]/g, "")
				.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
			if (val !== state?.corporation?.SSN) {
				errors = t("verification.errorMsgs.validSSN", { ns: "checkout" });
				dispatch(
					Actions.setCorporation({ ...state?.corporation, ["matchSSN"]: false })
				);
			} else {
				errors =
					props?.isCreditChoicePageActive &&
					pageData?.creditPath === "BLENDED" &&
					(!val || !val?.trim())
						? t("verification.errorMsgs.ssnReq", { ns: "checkout" })
						: "";
				errors +=
					pageData?.creditPath === "BLENDED" && val && val?.length != 11
						? t("verification.errorMsgs.validSSN", { ns: "checkout" })
						: "";
			}
			dispatch(
				Actions.setCorporation({
					...state?.corporation,
					[key]: val,
					["matchSSN"]: true,
				})
			);
			dispatch(
				Actions.setErrorTexts({
					...errorTexts,
					corporation: { ...corporation, [key]: errors },
				})
			);
		}
		dispatch(Actions.setCorporation({ ...state?.corporation, [key]: val }));
		dispatch(
			Actions.setErrorTexts({
				...errorTexts,
				corporation: { ...corporation, [key]: errors },
			})
		);
	};

	return (
		<CreditCheckContainer className="credit-check-container">
			{state?.creditCheckStatus === "FAILURE" && (
				<div id="FIOSCreditCheckAssistance"> </div>
			)}
			<Grid
				colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
				colGutter={false}
				rowGutter="5"
			>
				<Row>
					<Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
						<div className="Prod-Discription font-12">
							<Micro size="medium" primitive="h2">
								{t("verification.messages.infomsg1", { ns: "checkout" })}
							</Micro>
						</div>
					</Col>
				</Row>
				<Row>
					<Col
						colSizes={{ mobile: 4, tablet: 6, desktop: 4 }}
						className={
							state?.errorTexts?.corporation?.fedTaxId?.length > 0
								? "ttip-error"
								: "ttip-wrapper"
						}
					>
						<div className="form-col show-label federalTaxID">
							<Input
								label={`${t("verification.labelTexts.federalTaxID", {
									ns: "checkout",
								})}${props?.isTaxIdOptional ? "" : "*"}`}
								placeholder=""
								required={!props?.isTaxIdOptional}
								name="fedTaxId"
								width={"100%"}
								maxLength={10}
								value={state?.corporation?.fedTaxId}
								onChange={(e) => isValidContactInfoField("fedTaxId", e)}
								error={state?.errorTexts?.corporation?.fedTaxId?.length > 0}
								errorText={
									state?.errorTexts?.corporation?.fedTaxId ||
									t("verification.errorMsgs.validTaxId", { ns: "checkout" })
								}
								tooltipContent={
									props?.isTaxIdOptional
										? t("verification.labelTexts.FederalIdOptionalTooltip", {
												ns: "checkout",
										  })
										: t("verification.labelTexts.FederalIdTooltip", {
												ns: "checkout",
										  })
								}
								type={taxIDLabel ? "password" : "text"}
								//inlineActionButtonLabel={taxIDLabel ? "Show" : "Hide"}
								// onClickInlineAction={() =>
								//   taxIDLabel ? setTaxIDLabel(false) : setTaxIDLabel(true)
								// }
								onBlur={(e) => {
									if (!props?.isTaxIdOptional) isValidContactInfoField("fedTaxId", e);
								}}
							/>
							<EyeUnderline
								style={
									state?.errorTexts?.corporation?.fedTaxId?.length > 0
										? { marginTop: "0px" }
										: {}
								}
								onClick={() =>
									taxIDLabel ? setTaxIDLabel(false) : setTaxIDLabel(true)
								}
							>
								{taxIDLabel ? "" : "Hide"}
							</EyeUnderline>
							<div className="helper-text">
								<Micro size="small">
									{t("verification.helperTexts.federalTaxID", {
										ns: "checkout",
									})}
								</Micro>
							</div>
						</div>
					</Col>

					{(props?.isCreditChoicePageActive && pageData?.creditPath === "BLENDED") ||
					!props?.isCreditChoicePageActive ? (
						<Col colSizes={{ mobile: 4, tablet: 6, desktop: 4 }}>
							<div
								className="form-col show-label"
								style={{ marginRight: "1rem", position: "relative" }}
							>
								<Input
									label={`${t("verification.labelTexts.ssn", {
										ns: "checkout",
									})}${!props?.isCreditChoicePageActive ? "" : "*"}`}
									name="SSN"
									width={"100%"}
									required={props?.isCreditChoicePageActive}
									maxLength={9}
									value={state?.corporation?.SSN}
									error={state?.errorTexts?.corporation?.SSN?.length > 0}
									errorText={
										state?.errorTexts?.corporation?.SSN ||
										t("verification.errorMsgs.ssnReq", { ns: "checkout" })
									}
									onChange={(e) => isValidContactInfoField("SSN", e)}
									tooltipContent={t("verification.softCreditEnquiry", {
										ns: "checkout",
									})}
									type={ssnLabel ? "password" : "text"}
									// inlineActionButtonLabel={ssnLabel ? "Show" : "Hide"}
									// onClickInlineAction={() =>
									// 	ssnLabel ? setSSNLabel(false) : setSSNLabel(true)
									// }
									onBlur={(e) => {
										e?.target?.value && isValidContactInfoField("SSN", e);
									}}
									{...(!props?.isCreditChoicePageActive ? { required: false } : {})}
								/>
								<EyeUnderline
									style={
										state?.errorTexts?.corporation?.SSN?.length > 0
											? { marginTop: "0px" }
											: {}
									}
									onClick={() => (ssnLabel ? setSSNLabel(false) : setSSNLabel(true))}
								>
									{ssnLabel ? "" : "Hide"}
								</EyeUnderline>
								<div className="helper-text">
									<Micro size="small">
										{t("verification.helperTexts.ssnEg", {
											ns: "checkout",
										})}
									</Micro>
								</div>
							</div>
						</Col>
					) : (
						<></>
					)}
					{(props?.isCreditChoicePageActive && pageData?.creditPath === "BLENDED") ||
					!props?.isCreditChoicePageActive ? (
						<Col colSizes={{ mobile: 4, tablet: 6, desktop: 4 }}>
							<div
								className="form-col show-label"
								style={{ marginRight: "1rem", position: "relative" }}
							>
								<Input
									label={`Confirm Social Security Number${
										!props?.isCreditChoicePageActive ? "" : "*"
									}`}
									name="SSN"
									width={"100%"}
									required={props?.isCreditChoicePageActive}
									maxLength={9}
									value={state?.corporation?.confirmSSN}
									error={
										state?.corporation?.SSN?.length > 0 &&
										state?.corporation?.confirmSSN?.length > 10 &&
										state?.corporation?.confirmSSN !== state?.corporation?.SSN
									}
									errorText={`SSN should match`}
									success={
										state?.corporation?.confirmSSN?.length > 0 &&
										state?.corporation?.confirmSSN === state?.corporation?.SSN
									}
									successText={`Confirmed`}
									onChange={(e) => isValidContactInfoField("confirmSSN", e)}
									tooltipContent={t("verification.softCreditEnquiry", {
										ns: "checkout",
									})}
									type={ssnConfirmLabel ? "password" : "text"}
									// inlineActionButtonLabel={ssnLabel ? "Show" : "Hide"}
									//   onClickInlineAction={() =>
									//     ssnConfirmLabel ? setSSNConfirmLabel(false) : setSSNConfirmLabel(true)
									//   }
									onBlur={(e) => {
										e?.target?.value && isValidContactInfoField("confirmSSN", e);
									}}
									{...(!props?.isCreditChoicePageActive ? { required: false } : {})}
								/>
								<EyeUnderline
									style={
										state?.errorTexts?.corporation?.SSN?.length > 0
											? { marginTop: "0px" }
											: {}
									}
									onClick={() =>
										confirmSSNLabel ? setConfirmSSNLabel(false) : setConfirmSSNLabel(true)
									}
								>
									{confirmSSNLabel ? "" : "Hide"}
								</EyeUnderline>
								<div className="helper-text">
									<Micro size="small">
										{t("verification.helperTexts.ssnEg", {
											ns: "checkout",
										})}
									</Micro>
								</div>
							</div>
						</Col>
					) : (
						<></>
					)}
				</Row>
			</Grid>
			<Grid
				colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
				colGutter={false}
				rowGutter="5"
			>
				{pageData?.exisitingCustomer ? (
					<Row>
						<Col colSizes={{ mobile: 4, tablet: 9, desktop: 9 }}>
							<div className="credit-fail">
								<MarginSpacerXL/>
									<Body size="medium" bold>
										{t("verification.labelTexts.ecpdNoteToRep", {
											ns: "checkout",
										})}
									</Body>
									<Body size="medium">
										{t("verification.labelTexts.ecpdExisitingCustomer", {
											ns: "checkout",
										})}
									</Body>
							</div>
						</Col>
					</Row>
				) : (
					<React.Fragment></React.Fragment>
				)}
			</Grid>
			{state?.showOneTimePassModal && (
				<OneTimePassModal
					mobileNumber={state?.oneTimePassModal?.mobileNumber}
					errorText={state?.oneTimePassModalErrorTexts}
					showOneTimePassModal={true}
					onOpenedChange={(val) => dispatch(Actions.setShowOneTimePassModal(val))}
					onNextBtnClick={() => oneTimePassModalNextBtnClick()}
				/>
			)}
		</CreditCheckContainer>
	);
};

export default CreditCheck5g;
