import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TextLink } from "@vds3/buttons";
import { TileContainer } from "@vds3/tiles";
import { Title, Body } from "@vds3/typography";
import { MarginSpacerS, MarginSpacerXS } from "common/components/styleTag";
import { formatPriceString } from "utils/commonUtils";
import { StyledTileContainer } from "../CartPackage/styles";

const AccountLevelTile = ({
  eyebrowText,
  content,
  price,
  isEditable,
  onEditClick,
  isShowPrice = true,
  ...props
}) => {
  const { t } = useTranslation(["shoppingCart"]);
  return (
    <StyledTileContainer>
      <TileContainer padding="1.5rem" aspectRatio="none" backgroundColor="gray">
        <div style={{ width: "100%" }}>
          <Body size="small">{eyebrowText}</Body>
          <MarginSpacerS />
          <TileFlexContent>
            <div style={{ maxWidth: "650px" }}>
              {content}
              {false && !!isEditable && (
                <>
                  <MarginSpacerS />
                  <TextLink type="standAlone" onClick={onEditClick}>
                    {t("shopping-cart.cart.edit")}
                  </TextLink>
                  <MarginSpacerXS />
                </>
              )}
            </div>
            <div>
              {isShowPrice && (
                <Body size="large">{`${formatPriceString(price)}/mo`}</Body>
              )}
            </div>
          </TileFlexContent>
        </div>
      </TileContainer>
    </StyledTileContainer>
  );
};

const TileFlexContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

export default AccountLevelTile;
