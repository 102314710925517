import React, { useEffect, useReducer, useRef, useState } from "react";
import { Button } from "@vds3/buttons";
import VerticalProgressContext from "common/components/VerticalProgress/verticalProgressContext";
import { Body, Micro } from "@vds3/typography";
import { MarginSpacerM, MarginSpacerS , MarginSpacerL} from "common/components/styleTag";
import { Grid, Row, Col } from "@vds3/grids";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { SignerState, reducer, actions as Actions } from "./containers/AuthSignerReducer";
import { Checkbox } from "@vds3/checkboxes";
import ReCAPTCHA from "react-google-recaptcha";
import "./AuthorisedSigner.css";
import { useMediaQuery } from 'react-responsive'

import {
    ddiaCheckVerificationResult,
    generateDDIALink,
    getSessionCart,
    postBusinessInformation,
    setNotification,
    setSuspendFlow,
    setSuspendScenario,
    mitekCheckVerificationResult,
    generateMitekVerificationLink,
    setCreditPath,
    setIsVerifyBusiness,
    postBusinessInfoBussAlone,
    setSameAsCompanyAddress,
} from "pages/Checkout/ducks/CheckoutSlice";
import cloneDeep from 'lodash/cloneDeep'
import { Notification } from "@vds3/notifications";
import SpinnerComponent from "../../../../common/components/Spinner/Spinner";
import { isEmpty } from "lodash";
import { checkIfAemExists } from "utils/commonUtils";

const AuthoriseSignerConsent = ({ ...props }) => {
    const {
        isComplete,
        onComplete,
        setModifier,
        isEditMode: { isEditMode, setIsEditMode },
    } = React.useContext(VerticalProgressContext);

    const { t } = useTranslation(["app", "verification", "checkout","mock-response"]);
    const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
	const pageData = useSelector((state) => state.ProspectWidgets_CheckoutData);
	const lqData = useSelector((state) => state.ProspectWidgets_LQPage);
    const sessionCartData =
        globalData?.sessionCartData?.apiResponse?.prospectFlowData;
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const [businessInfoState, businessDispatch] = useReducer(reducer, SignerState);
    const authorizeTextForFullAutomation = t(
        "checkout.businessInfo-section.messages.consent-info",
        { ns: "checkout" }
    );
    const [isCheckBoxSelected, setIsCheckBoxSelected] = useState(false);
    // const [isSignerAddComplete, setSignerAddComplete] = useState(true);
    const [showErrNotification, setShowErrNotification] = useState(false);
    const [captchaValue, setIsCaptchaValue] = useState("");
    const [showMitekModal, setShowMitekModal] = useState(false);
    const captcha =
        window?.mbtGlobal?.sk || "6LdMkcAZAAAAANaphnrFMaEl9Msqqls3xWmAX3yy";
    const [creditCheck5gState, setCreditCheckState] = useState({});
    const [continueClicked, setContinueClicked] = useState(false);
    const businessInforState = pageData?.aboutBusinessInfor
    const authorisedSignerState = pageData?.authSignerInfo;
    const recaptchaRef = React.createRef();
    const isTaxIdOptional = globalData?.cmp?.isFromGoogleShop;
    const isBlendedCredit = sessionCartData?.enableBlendedCredit;
    const isBizOnlyCredit = sessionCartData?.enableBizOnlyCredit;
    const isCreditChoicePageActive = isTaxIdOptional ? true : isBlendedCredit && isBizOnlyCredit;
    let hasCaptcha = false;
    const standAloneBuSS = sessionCartData?.standAloneBuSS;
    const sameAsCompanyAddress = {
        company: businessInforState?.companyName,
        firstName: authorisedSignerState?.contactInfo?.firstName,
        lastName: authorisedSignerState?.contactInfo?.lastName,
        phoneNumber: businessInforState?.phoneNumber,
        email: authorisedSignerState?.contactInfo?.email,
    };
    if (!isEmpty(captcha)) {
        hasCaptcha = true;
    }
    const isMobile = useMediaQuery({
        query: "(max-width: 525px)",
    });

    useEffect(() => {
        const element = document.getElementById("signer-consent");
        element?.scrollIntoView({ behavior: "smooth", block: "start" });
        //SiteAnalytics.dynamicComponentRendered({ order: 4, sectiontName: "Business Verification",subSection:"Authorized signer consent",isEditMode:false });
    },[]);

    const hasErrors = () => {
        let error = false;
        if (businessInforState?.errors) {
            for (const key of Object.keys(businessInforState?.errors)) {
                if (
                    businessInforState?.errors[key] &&
                    businessInforState?.errors[key]?.length > 0
                ) {
                    error = true;
                }
            }
        }
        if (businessInforState?.errorTexts?.corporation) {
            for (const key of Object.keys(
                businessInforState?.errorTexts?.corporation
            )) {
                if (
                    businessInforState?.errorTexts?.corporation[key] &&
                    businessInforState?.errorTexts?.corporation[key]?.length > 0
                ) {
                    error = true;
                }
            }
        }
        if (authorisedSignerState?.errorTexts?.contactInfo) {
            for (const key of Object.keys(
                authorisedSignerState?.errorTexts?.contactInfo
            )) {
                if (
                    authorisedSignerState?.errorTexts?.contactInfo[key] &&
                    authorisedSignerStateS?.errorTexts?.contactInfo[key]?.length > 0
                ) {
                    error = true;
                }
            }
        }
        if (authorisedSignerState?.errorTexts?.corporation) {
            for (const key of Object.keys(
                authorisedSignerState?.errorTexts?.corporation
            )) {
                if (
                    authorisedSignerState?.errorTexts?.corporation[key] &&
                    authorisedSignerState?.errorTexts?.corporation[key]?.length > 0
                ) {
                    error = true;
                }
            }
        }
        if (
            !businessInforState?.businessStreetAddress ||
            (!isTaxIdOptional && !businessInforState?.corporation?.fedTaxId) ||
            !businessInforState?.companyName ||
            !businessInforState?.phoneNumber ||
            !authorisedSignerState?.contactInfo?.firstName ||
            !authorisedSignerState?.contactInfo?.lastName ||
            !authorisedSignerState?.contactInfo?.email ||
            (!(authorisedSignerState?.homeAdressInfo?.displayString || authorisedSignerState?.homeAdressInfo?.place?.properties?.street)) ||
            // ((isTaxIdOptional ||
            //     (isCreditChoicePageActive && pageData?.creditPath === "BLENDED")) &&
            //     !authorisedSignerState?.corporation?.SSN)
            //  ||
            !authorisedSignerState?.corporation?.DateOfBirth
        ) {
            error = true;
        }
        if(!error){
            if(standAloneBuSS) { onContinueStandAloneBuSS()}
            else {onContinue()}
        }
        // return error;
    }
    useEffect(() => {
        if (
            !pageData?.postBusinessInfoResponse?.serviceStatus?.success &&
            pageData?.postBusinessInfoResponse?.serviceStatus?.statusCode ===
            "SUSPENDED_ECPDLOOKUP_CHECK"
        ) {
            dispatch(setSuspendScenario("SUSPENDED_ECPDLOOKUP_CHECK"));
            dispatch(setSuspendFlow(true));
        } else if (
            !pageData?.postBusinessInfoResponse?.serviceStatus?.success &&
            pageData?.postBusinessInfoResponse?.serviceStatus?.statusCode ===
            "SUSPENDED_ECPDLOOKUP_EXISTING_USER"
        ) {
            dispatch(setSuspendScenario("SUSPENDED_ECPDLOOKUP_EXISTING_USER"));
            dispatch(setSuspendFlow(true));
        } else if (
            !pageData?.postBusinessInfoResponse?.serviceStatus?.success &&
            pageData?.postBusinessInfoResponse?.serviceStatus?.statusCode ===
            "SUSPENDED_FLOW_ECPD"
        ) {
            dispatch(setSuspendScenario("SUSPENDED_FLOW_ECPD"));
            dispatch(setSuspendFlow(true));
        } else if (
            pageData?.postBusinessInfoResponse?.serviceStatus &&
            continueClicked &&
            !pageData?.postBusinessInfoResponse?.serviceStatus?.success &&
            pageData?.postBusinessInfoResponse?.serviceStatus?.statusMessage !== "200"
        ) {
            let notification = {
                show: true,
                type: "error",
                title: t(`verification.api_errors.something-went-wrong`, {
                    ns: "verification",
                }),
                subtitle: "",
            };
            if (
                apiErrors &&
                Object.keys(apiErrors)?.length &&
                Object.keys(apiErrors)?.includes(
                    pageData?.postBusinessInfoResponse?.serviceStatus?.statusMessage
                )
            ) {
                if (
                    (pageData?.postBusinessInfoResponse?.serviceStatus?.statusMessage ===
                        "PROSPECT-ERR1" ||
                        pageData?.postBusinessInfoResponse?.serviceStatus?.statusMessage ===
                        "PROSPECT-ERR5") &&
                    enableAddressModal
                ) {
                    // setShowAddressModal(true);
                }
                notification = {
                    show: true,
                    type: "error",
                    title: t(
                        `verification.api_errors.${pageData?.postBusinessInfoResponse?.serviceStatus?.statusMessage}`,
                        {
                            ns: "verification",
                        }
                    ),
                    subtitle: "",
                };
            }
            dispatch(setNotification(notification));
        }
        else if (
            pageData?.postBusinessInfoResponse?.serviceStatus &&
            continueClicked &&
            !pageData?.postBusinessInfoResponse?.serviceStatus?.success &&
            pageData?.postBusinessInfoResponse?.serviceStatus?.statusCode == "ERROR_RC2_001"
        ) {
            let notification = {
                show: true,
                type: "error",
                title: t(`verification.api_errors.something-went-wrong`, {
                    ns: "verification",
                }),
                subtitle: "",
            };
            dispatch(setNotification(notification));
        }
    }, [pageData?.postBusinessInfoResponse]);

    useEffect(() => {
        if (
            pageData?.postBusinessInfoResponse &&
            pageData?.validateBusinessInfoResponse &&
            Object.keys(pageData?.postBusinessInfoResponse)?.length &&
            Object.keys(pageData?.validateBusinessInfoResponse)?.length &&
            pageData?.postBusinessInfoResponse?.serviceStatus?.success &&
            pageData?.validateBusinessInfoResponse?.serviceStatus?.success
        ) {
            setModifier(null);
            onComplete();
        }
    }, [
        pageData?.postBusinessInfoResponse,
        pageData?.validateBusinessInfoResponse,
    ]);

    const onContinue = (addressState, captchaToken, fromAddressModal = false) => {
    const useMock = checkIfAemExists(t("business-information-mock", { ns: "mock-response" }));
    const useMockValidate = checkIfAemExists(t("validate-businessInfo-mock", { ns: "mock-response" }));
        if (isCheckBoxSelected) {
                if ((!(recaptchaRef?.current?.getValue() || captchaToken)) && window?.grecaptcha  && window?.grecaptcha?.execute
                && !window?.location?.pathname?.includes("/mbt/prospect/")
                ) {
                    if (window?.grecaptcha && window?.grecaptcha?.execute) {
                        window?.grecaptcha?.execute();
                    }
                }
                else {
                    dispatch(setNotification({}));
                    const flow = pageData?.unifiedCartParams?.flow ?? lqData?.flow;
                    const safetechSessionId = pageData?.safetechSessionId;
                    const blackBox = pageData?.blackBox;
                    const newCreditCheck5gState = { ...businessInforState?.corporation };
                    //Adding for BOVV-128316 to check if zipcode has been changed
                    const zipcodeChanged = sessionCartData?.businessInfo?.address?.zipCode === businessInforState?.businessStreetAddress?.place?.properties?.postalCode ? false : true;
                    if (
                        (!newCreditCheck5gState?.fedTaxId ||
                            newCreditCheck5gState?.fedTaxId === "") &&
                        isTaxIdOptional
                    ) {
                        newCreditCheck5gState.fedTaxId = pageData?.SSNInfo;
                        setCreditCheckState(newCreditCheck5gState);
                    }
                    let newBusinessInforState = { ...businessInforState, corporation: { ...businessInforState.corporation, DateOfBirth: authorisedSignerState.corporation.DateOfBirth } };
                    let pdpBillingInfo = {}
                    if(sessionCartData?.pdpBillingAddress && Object.keys(sessionCartData?.pdpBillingAddress).length > 0){
                        const address = {...sessionCartData?.pdpBillingAddress,propertyType:"R"}
                        pdpBillingInfo = {address}
                    }
                    const data = {
                        ...newBusinessInforState,
                        ...(fromAddressModal ? addressState : newBusinessInforState),
                        ssn: cloneDeep(pageData?.SSNInfo),
                        contactInfo: {...cloneDeep(authorisedSignerState?.contactInfo),"DateOfBirth":authorisedSignerState?.corporation?.DateOfBirth ?? ""},
                        homeAdressInfo: cloneDeep(authorisedSignerState?.homeAdressInfo),
                        ...newCreditCheck5gState,
                        flow,
                        safetechSessionId: safetechSessionId,
                        blackBox: blackBox,
                        prospectCreditPath: pageData?.creditPath,
                        zipcodeChanged,
                        pdpBillingInfo: pdpBillingInfo,
                        useMock: useMock,
                        useMockValidate:useMockValidate

                    };
                    if (data && data?.contactInfo) {
                        data.contactInfo['jobTitle'] = authorisedSignerState?.contactInfo['jobTitle'];
                    }
                    if (data && data?.phoneNumber) {
                        data.phoneNumber =  data?.phoneNumber?.replaceAll("-", ".")
                    }

                    //commenting API call for test Purpose
                    //Added for BOVV-131957 to check if session cart is present then to initate business-information api
                    if (sessionCartData?.cart?.packages) {
                        dispatch(postBusinessInformation(data));
                        setShowErrNotification(false)
                    }
                    // props?.eventHandlers?.emit('updateVerifyBusiness', creditCheck5gState);

                    // SiteAnalytics.eventBusInfoTracking(
                    //     "Business Credit Details Submitted",
                    //     data
                    // );
                }
                dispatch(setSameAsCompanyAddress(sameAsCompanyAddress));
                // if (fromAddressModal) {
                //     setShowAddressModal(false);
                // }
            }
        else {
            setShowErrNotification(true)
        }
    };

    const onContinueStandAloneBuSS = (addressState, captchaToken, fromAddressModal = false) => {
        if (isCheckBoxSelected) {
            if ((!(recaptchaRef?.current?.getValue() || captchaToken)) && window?.grecaptcha && window?.grecaptcha?.execute
            // / && !window?.location?.pathname?.includes("/mbt/prospect/")
            ) {
                if (window?.grecaptcha && window?.grecaptcha?.execute) {
                    window?.grecaptcha?.execute();
                }
            }
            else {
                dispatch(setNotification({}));
                const flow = pageData?.unifiedCartParams?.flow ?? lqData?.flow;
                const safetechSessionId = pageData?.safetechSessionId;
                const blackBox = pageData?.blackBox;
                const newCreditCheck5gState = { ...businessInforState?.corporation };
                const zipcodeChanged = sessionCartData?.businessInfo?.address?.zipCode === businessInforState?.businessStreetAddress?.place?.properties?.postalCode ? false : true;
                if (
                    (!newCreditCheck5gState?.fedTaxId ||
                        newCreditCheck5gState?.fedTaxId === "") &&
                    isTaxIdOptional
                ) {
                    newCreditCheck5gState.fedTaxId = pageData?.SSNInfo;
                    setCreditCheckState(newCreditCheck5gState);
                }
                let newBusinessInforState = { ...businessInforState, corporation: { ...businessInforState.corporation, DateOfBirth: authorisedSignerState.corporation.DateOfBirth } };
                let pdpBillingInfo = {}
                if(sessionCartData?.pdpBillingAddress && Object.keys(sessionCartData?.pdpBillingAddress).length > 0){
                    const address = {...sessionCartData?.pdpBillingAddress,propertyType:"R"}
                    pdpBillingInfo = {address}
                }
                const data = {
                    ...newBusinessInforState,
                    ...(fromAddressModal ? addressState : newBusinessInforState),
                    ssn: cloneDeep(pageData?.SSNInfo),
                    contactInfo: cloneDeep(authorisedSignerState?.contactInfo),
                    homeAdressInfo: cloneDeep(authorisedSignerState?.homeAdressInfo),
                    ...newCreditCheck5gState,
                    flow,
                    safetechSessionId: safetechSessionId,
                    blackBox: blackBox,
                    prospectCreditPath: pageData?.creditPath,
                    zipcodeChanged,
                    pdpBillingInfo: pdpBillingInfo
                };
                if (data && data?.contactInfo) {
                    data.contactInfo['jobTitle'] = authorisedSignerState?.contactInfo['jobTitle'];
                }

                if (sessionCartData?.cart?.packages) {
                    dispatch(postBusinessInfoBussAlone(data));
                    setShowErrNotification(false)
                }

                // SiteAnalytics.eventBusInfoTracking(
                //     "Business Credit Details Submitted",
                //     data
                // );
            }
            dispatch(setSameAsCompanyAddress(sameAsCompanyAddress));
            // if (fromAddressModal) {
            //     setShowAddressModal(false);
            // }

        } else {
            setShowErrNotification(true)
        }
    };

    const getDefaultContent = () => {
        return (
            <>
                <div className="businessInfo">
                    <div className="container">
                        {showErrNotification &&
                            <Notification
                                type="error"
                                title={"Complete checkbox to continue"}
                                fullBleed={true}
                                inlineTreatment={false}
                                onCloseButtonClick={() => setShowErrNotification(false)}
                            />}
                        {/* {(pageData?.isShoppingCartApiCallInProgress?.postBusinessInformation
                            || pageData?.isShoppingCartApiCallInProgress?.postBusinessInfoBussAlone) && (
                                <SpinnerComponent />
                            )} */}
                        <Grid colGutter={false} style={{ paddingTop: "1rem" }}>
                            <Row>
                                <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
                                    <Checkbox
                                        disabled={false}
                                        error={false}
                                        errorText=""
                                        label=""
                                        onChange={(e) => {
                                            setIsCheckBoxSelected(e?.target?.checked)
                                            if (e?.target?.checked) {
                                                setShowErrNotification(false)
                                            }
                                        }}
                                        onKeyDown={(e) => setIsCheckBoxSelected(e?.target?.checked)}
                                        selected={isCheckBoxSelected}
                                        width="100%"
                                    >
                                        {isMobile ? <Micro size="small">
                                            <StyledSpan
                                                dangerouslySetInnerHTML={{
                                                    __html: t("checkout.businessInfo-section.messages.consent-info-mobile", {
                                                        ns: "checkout",
                                                    }),
                                                }}
                                            />
                                        </Micro> :
                                            <>
                                            <Micro size="small">
                                            <StyledSpan
                                                dangerouslySetInnerHTML={{
                                                    __html: t("checkout.businessInfo-section.messages.consent-info-desktop", {
                                                        ns: "checkout",
                                                    }),
                                                }}
                                            />
                                        </Micro> 
                                                                </>}
                                    </Checkbox>
                                </Col>
                            </Row>
                            <div className="digicaptcha">
                                <MarginSpacerM />
                                <Row>
                                    {hasCaptcha ? (
                                        <Col colSizes={{ mobile: 2, tablet: 6, desktop: 6 }}>
                                            <div className="captchaContainer-consent">
                                                <ReCAPTCHA
                                                    ref={recaptchaRef}
                                                    height="60"
                                                    role="presentation"
                                                    name="a-avb9eym4nsmt"
                                                    frameBorder="0"
                                                    scrolling="no"
                                                    size="invisible"
                                                    badge="inline"
                                                    sitekey={captcha}
                                                    onChange={(e) => {
                                                        const recaptchaValue =
                                                            recaptchaRef?.current?.getValue();
                                                        setIsCaptchaValue(recaptchaValue);
                                                        onContinue(e, recaptchaValue);
                                                    }}
                                                    onExpired={() => {
                                                        setIsCaptchaValue("");
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    ) : null}
                                    <Col colSizes={{ mobile: 2, tablet: 6, desktop: 4 }}>
                                        <div className="digicertContainer-consent">
                                            <img
                                                src="https://ss7.vzw.com/is/image/VerizonWireless/digital-digicert-seal-2023?scl=1"
                                                alt="digicert-Seal Icon"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Grid>
                    </div>
                    <MarginSpacerL/>
                    {isCheckBoxSelected ? 
                                <div className="notification"> <Notification
                                    type="info"
                                    title={t("checkout.businessInfo-section.messages.checkbox-notify-title", {
                                        ns: "checkout",
                                      })}
                                    subtitle={t("checkout.businessInfo-section.messages.checkbox-notify-subtitle", {
                                        ns: "checkout",
                                      })}
                                    fullBleed={"false"}
                                    inlineTreatment={false}
                                    hideCloseButton={true}
                                /> </div>
                           : <></>}
                    <MarginSpacerS />
                </div>

                <div style={{ display: "flex", justifyContent:isMobile ?"center" : "flex-start"}}>
                    <Button width={isMobile? "90%" : "35%"} size="large" use="primary"
                        onClick = {() => hasErrors()}
                        data-track={`Business Verification:Authorized signer consent:${sessionCartData?.standAloneBuSS ? 'Continue' : t("verification.acceptContinue", { ns: "verification" })}`}
                    >
                        {sessionCartData?.standAloneBuSS ? 'Continue' : t("verification.acceptContinue", { ns: "verification" })}
                    </Button>
                </div>
                <MarginSpacerS/>
            </>
        );
    };

    const getCompletedContent = () => {
        return (
            <div>
                <MarginSpacerM />
            </div>
        );
    };

    const getEditModeContent = () => {
        return (
            <>
                <>{getDefaultContent()}</>
                {/* <div>
                    <Button
                        size="large"
                        use="primary"
                        onClick={() => {
                            validateBusinessAddress();
                        }}
                        disabled={hasErrors()}
                    >
                        {"Save"}
                    </Button>
                </div> */}
            </>
        );
    };

    if (isComplete) {
        if (isEditMode) {
            return getEditModeContent();
        } else {
            return getCompletedContent();
        }
    } else {
        return getDefaultContent();
    }
};

const StyledSpan = styled.span`
  > a {
    color: black;
  }
`;

export default AuthoriseSignerConsent;
