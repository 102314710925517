import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import {
  get5GPageLoadData,
  get5gPlans,
  setRouterUpdateResp,
  get5gRouterDevices,
  update5GPlan,
  update5GRouter,
  update5GADDORUpdate,
  setSelectedPlan,
  setSelectedRouter,
  setSelectedReceiver,
  setSelectedRouterPayment,
  reset5GState,
  update5GPlanAndFetchRouters,
  setIsEdit5GPrimaryPlansFlow,
  setIsEdit5GEquipmentFlow,
  updateFCCTemplate,
  updateFCCJson,
  setUpdatedPlanRouterResp,
  setPlanPreselection,
  updateFCCJsonNew,
  setPlansDetailsFcc,
  getComibnedPlanAndRouters,
  setJOInternetSelected5g,
  setpackageCompatible,
  clearCartAndAddToCart
} from "./ducks/5GSlice";
import { setFWABundleTabSelected } from "../Landing4G-V2/ducks/4GSlice";
import { MaxDevicesModal } from "pages/ShoppingCart/components/Modals";
import isEmpty from "lodash/isEmpty";
import { Button, TextLink } from "@vds3/buttons";
import { Grid, Row, Col } from "@vds3/grids";
import { Line } from "@vds3/lines";
import { Notification } from "@vds3/notifications";
import { LayoutTokens } from "@vds-tokens/layout";
import { MarginSpacerL, MarginSpacerXL, MarginSpacerS, MarginSpacerM, MarginSpacer2XL, MarginSpacerRightXS } from "common/components/styleTag";
import { useImageUtils, useStickyHeaderHeight } from "hooks"
import { Body, Title } from "@vds3/typography";
import JointOfferDetails from "../../common/components/FWA/PlanSelection/JointOfferDetails";
const VideoModal = React.lazy(() =>
  import("../../common/components/VideoModal/VideoModal")
);
import CheckCompatibility from "./components/CheckCompatibility/CheckCompatibility";
import {
  HeroSection,
  PlanSelectionNew,
  EquipmentSection,
  RouterPayment,
  IncludedInOrder,
  ReceiverPayment,
  EquipmentSectionNew
} from "common/components/FWA";
import { FWAContextProvider } from "common/components/FWA/fwaContext";
import { clear4g5gCart, getLQSessionDetails, setAddress, setBBALQResp, setLoopQualAddressResp, setLoopQualStreetLookupResp } from "pages/Qualification/ducks/QualificationSlice";
import { setContainerHeightBB, setLoadFWAQualifiedPlans, setLoadQualficationSelection, setLoadShoppingCartWidget, setNoThanksClicked } from "app/ducks/GlobalSlice";
import { useGlobalData } from "hooks";
import styled from "styled-components";
import * as SiteAnalytics from "../../sitecatalyst";
import { Carousel } from "@vds3/carousels";
import { resetCheckoutState } from "pages/Checkout/ducks/CheckoutSlice";
import "./LandingPage5G.css";
import { Tilelet } from "@vds3/tiles";
import { ImageContent } from "common/components/FWA/EquipmentSection/styles";
//import { setIsCustomerModalVisited, setIsFwa, setIsLandingFromInternetWithMobile } from "pages/PlanFirst/ducks/PlanFirstSlice";
import FiveGCbandModal from "common/components/Modals/FiveGCbandModal";
import ChangeAddress from "common/components/FWA/Modals/ChangeAddress";
import JointOfferDetailsNew from "common/components/FWA/PlanSelectionNew/JointOfferDetailsNew";
import PackageCompatibleModal from "common/components/Modals/PackageIncompatibleModal";
import SpinnerComponent from "common/components/Spinner/Spinner";
import StickyHeaderVds3 from "common/components/StickyHeader/StickyHeaderVds3";
import AlreadyACustomer from "common/components/AlreadyACustomer/AlreadyACustomer";
import EquipmentSectionReceiver from "common/components/FWA/EquipmentSectionNew/EquipmentSectionReceiver";


const CheckoutButton = styled(Button)`
  @media screen and (max-width: 576px) {
    width: 100%;
  }
`;

const ChatDiv = styled.div`
  margin-top: 8px;
  margin-right: 20px;
  display:flex;
  justify-content:flex-end;
`;
const authHeaderTxt = "Good news: Alternate Verizon 5G Business Internet plans are available."
const authSubHeaderTxt = "The plan that you selected requires professional installation. Since you’re unable to acquire approval, we recommend that you choose one of the plans below because you’ll be able to install the equipment yourself - no permissions required."

const LandingPage5G = ({ ...props }) => {
  const dispatch = useDispatch();
  //const navigate = useNavigate();
  //const location = useLocation();
  const { isPlanEdit, isRouterEdit } = {}
  //location?.state || {};
  //const [searchParams, setSearchParams] = useSearchParams();
  const lqsessionId = "";// searchParams.get("lqsessionId");
  const redirectedFrom = "";//searchParams.get("redirectedFrom");
  const isLqGbcom = useSelector((state) => state.ProspectWidgets_GlobalData?.queryParams?.redirectedFrom) === "GBCOM" ||
    "";//searchParams.get("GBCOM");
  const [fivegModal, setFiveGModal] = useState(false);
  const { metaInformation } = useGlobalData({ clear4g5g: false });
  const { t, ready } = useTranslation(["app", "4G-5G"], { useSuspense: false });
  const aemContent = t("4G-5G", { ns: "4G-5G", returnObjects: true });
  const appContent = t("app", { ns: "app", returnObjects: true });
  const prePlanSelectedFlowEnabled = aemContent?.prePlanSelectedFlowEnabled;
  const isPDPLayoutFWA = t("4G-5G.isPDPLayoutFWA", { ns: "4G-5G" });

  const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
  const pageData = useSelector((state) => state.ProspectWidgets_5GPlansData);
  const tabselectionData = useSelector((state) => state.ProspectWidgets_4GPlansData);
  const primaryPlan = useSelector((state) => state.ProspectWidgets_5GPlansData.primaryPlan);
  const CheckoutData = useSelector((state) => state.ProspectWidgets_CheckoutData);
  const LQData = useSelector((state) => state.ProspectWidgets_LQPage);
  const BBALQResp = useSelector((state) => state.ProspectWidgets_LQPage?.BBALQResp);

  // const [disableContinueBtn, setDisableContinueBtn] = useState(false);
  const [plans, setPlans] = useState([]);
  const [isCband, setIsCband] = useState(false);
  const [enableStarkReceiver, setEnableStarkReceiver] = useState(false);
  const [imei, setImei] = useState("");
  const [sim, setSim] = useState("");
  const [useOwnReceiver, setUseOwnReceiver] = useState(false);
  const [cannotInstall, setCannotInstall] = useState(false);
  const [faqType, setFaqType] = useState("");
  const [showSetupVideoModal, setShowSetupVideoModal] = useState(false);
  const [showChangeAddressModal, setChangeAddressModal] = useState(false);
  const [showCartNudgeModal, setshowCartNudgeModal] = useState(false);
  const [selectPlanErr, setSelectPlanErr] = useState(false);
  const [changedPlans, setChangedPlans] = useState(false);
  const [selectRouterErr, setSelectRouterErr] = useState(false);
  const [selectPaymentErr, setSelectPaymentErr] = useState(false);
  const [selectReceiverErr, setSelectReceiverErr] = useState(false);
  const [showMaxDeviceModal, setMaxDeviceModal] = useState(false);
  const maxDeviceQuantity = aemContent?.fiveG?.cBand?.maxDeviceModal;
  const { selectedPlan, selectedRouter, selectedRouterPayment, quantity, routerDetails, selectedReceiver } = pageData?.primaryPlan;
  const [collapsePlans, setCollapsePlans] = useState(props?.isfromAuth ? false : !!selectedPlan);
  const { getImageLink } = useImageUtils();
  const routerImage = LQData?.mmw ? aemContent?.fiveG?.mmw?.routerImage : aemContent?.fiveG?.cBand?.routerImage;
  const [addToCartPayload, setAddToCartPayload] = useState("");
  const [continueClicked, setContinueClicked] = useState(false);
  const [clearEquipment, setClearEquipment] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  const validateSimIdResp = useSelector(
    (state) => state?.ProspectWidgets_5GPlansData?.byor?.validateSimIdResp
  );
  const planDetailsFccNewObj = useSelector((state) => state.ProspectWidgets_5GPlansData.planDetailsFccNewObj);

  const sessionCartData =
    globalData?.sessionCartData?.apiResponse?.prospectFlowData;
  const { stickyHeaderRef, headerHeight } = useStickyHeaderHeight(sessionCartData);
  const globalPromos =
    globalData?.metaInformation?.globalPromos &&
    globalData?.metaInformation?.globalPromos["5g-plans"];
  const onlyCBandQualified = (LQData['cband'] || (sessionCartData && ((sessionCartData?.prospect5gLoopQualAddress && sessionCartData?.prospect5gLoopQualAddress?.qualified5GCband) || (sessionCartData.cart && sessionCartData.cart.cbandCart)))) && !(LQData['mmw'] || (sessionCartData && sessionCartData.cart && sessionCartData.cart.mwwCart)) && (aemContent?.fiveG?.enableCBandPayment ?? false)

  const headerType =
    (LQData?.cband === true || LQData?.BBALQResp?.qualifiedForCband) &&
      (LQData?.BBALQResp?.headerType === "BBA" ||
        LQData?.BBALQResp?.headerType === "5G" ||
        LQData?.BBALQResp?.headerType === "FIOS")
      ? LQData?.BBALQResp?.headerType
      : LQData?.mmw === true && LQData?.BBALQResp?.headerType === "FIOS"
        ? LQData?.BBALQResp?.headerType
        : LQData?.mmw === true && LQData?.BBALQResp?.headerType === "5G"
          ? "MMW"
          : "DEFAULT";
  const headerTypeCheck = (BBALQResp?.headerType) ? BBALQResp?.headerType : "DEFAULT";
  const isHeaderType5gorFios = (headerTypeCheck === "FIOS" || headerTypeCheck === "BBA" || headerTypeCheck === "5G" || headerType == "DEFAULT") ? true : false;
  const installNotAllowed =
    props.isCheck === "auth-no" && props.isPermission === "permission-no";
  const isDigitalQuote = window.sessionStorage.getItem("isDigitalQuote");
  const displayOnlyInternetMobileTab = LQData.isAddressChange ? (sessionCartData?.selectFwaBundletabForJO) || false :
    (sessionCartData?.selectFwaBundletabForJO && pageData?.showInternetMobilePlanFiveG) || false;
  const fromPage = "";//searchParams.get("from");
  const [isNewFlow, setisNewFlow] = useState(true);
  const [planSelected, setPlanSelected] = useState(null);
  const [newMMWflow, setNewMMWflow] = useState(sessionCartData?.cart?.cbandCart)
  const hasPackages = sessionCartData?.cart?.packages != null && Object.values(sessionCartData?.cart?.packages)?.length > 0 ? true : false;
  const hasRegularPackage = hasPackages ? Object.keys(sessionCartData?.cart?.packages).filter(packageKey => sessionCartData?.cart?.packages[packageKey]?.packageType == "REGULAR").length > 0 : false;
  const hasInternetPackage = hasPackages ? Object.keys(sessionCartData?.cart?.packages).filter(packageKey => sessionCartData?.cart?.packages[packageKey]?.packageType == "FIVEG").length > 0 : false;

  if (false) {//searchParams.get("pricePlanCode") || searchParams.get("lqsessionId")) {
    let newPricePlanCode = "";// searchParams.get("pricePlanCode")
    if (!window.sessionStorage.getItem("newPricePlanCode")) {
      window.sessionStorage.setItem("newPricePlanCode", newPricePlanCode);
    }
    if (!window.sessionStorage.getItem("lqsessionIdNew")) {
      window.sessionStorage.setItem("lqsessionIdNew", lqsessionId);
    }
  }

  const FLOW = "5g";
  const isOptimizationEnabled = !!(
    window && window?.mbtGlobal?.flags?.ENABLE_REACT_OPTIMISATION === "Y"
  );
  const qualified5GHome = LQData?.fixedWirelessResponse?.response?.qualified5GHome || false;
  const starkEnabled = sessionCartData?.prospect5gLoopQualAddress?.starkEnabled && qualified5GHome || sessionCartData?.prospect5gLoopQualAddress?.starkEnabled && sessionCartData?.cart?.mwwCart || LQData?.clear4g5gCartResp?.starkEnabled || false;
  window.sessionStorage.setItem('starkEnabled', JSON.stringify(starkEnabled));
  const [receiverDetails, setReceiverDetails] = useState({});
  const [routerStarkDetails, setRouterStarkDetails] = useState({});
  const [installmentTermStark, setInstallmentTermStark] = useState('');
  const [collapseEquipment, setCollapseEquipment] = useState(props?.isfromAuth ? false : starkEnabled ? !!selectedRouterPayment : !!selectedRouter);
  const [collapseEquipmentReceiver, setCollapseEquipmentReceiver] = useState(props?.isfromAuth ? false : !!selectedReceiver);
  const [collapsePayment, setCollapsePayment] = useState(
    props?.isfromAuth ? false : !!selectedRouterPayment
  );
  // const [collapsePaymentReceiver, setCollapsePaymentReceiver] = useState(
  //   props?.isfromAuth ? false : !!selectedRouterPayment
  // );

  const oneApiCall = t("app.common.oneApiCall", { ns: "app" });

  const isAddonsEnabled = !!(window && window?.mbtGlobal?.flags?.ENABLE_SECURITY_PLUSPREFERRED?.includes("FIVEG"));
  const showBussProductPage = !!(
    window && window?.mbtGlobal?.flags?.PROSPECT_SHOW_BUSS_PRODUCTS_4G_5G === "Y"
  ) || sessionCartData?.showBussProducts;

  const [isAddonPageNeeded, setIsAddonPageNeeded] = useState(false);
  const [isBussPageNeeded, setIsBussPageNeeded] = useState(false);
  const [isFwaBundleSelected, setIsFwaBundleSelected] = useState(false);
  const [isTabSelected, setIsTabSelected] = useState(false);
  const selectFwaBundletabForJO = sessionCartData?.selectFwaBundletabForJO;
  const edit5gFlow = aemContent?.edit5gFlow;
  const timeOut = aemContent?.timeOutVal;
  const planPreselection = useSelector((state) => state.ProspectWidgets_5GPlansData.planPreselection);
  const showShoppingIcon = sessionCartData?.cart?.packages !== null && sessionCartData?.cart?.packages && Object.values(sessionCartData?.cart?.packages)?.length > 0 ? true : false;
  const [isRecieverSelectionTile, setIsRecieverSelectionTile] = useState(false);
  const [isShoppingIconClicked, setIsShoppingIconClicked] = useState(false);
  // const showShoppingIcon = sessionCartData?.cart?.packages !== null && sessionCartData?.cart?.packages && Object.values(sessionCartData?.cart?.packages)?.length > 0 ? true : false;
  const [jointOfferModal, setJointOfferModal] = useState(false)
  const [jointOfferList, setJointOfferList] = useState([])
  const jointOfferAem = t("4G-5G", { ns: "4G-5G", flow: '5G', returnObjects: true });


  if (false) {//searchParams.get("lqsessionId")) {
    if (!window.localStorage.getItem("lqsessionId")) {
      window.localStorage.setItem("lqsessionId", lqsessionId);
    } else if (lqsessionId !== window.localStorage.getItem("lqsessionId")) {
      window.localStorage.setItem("lqsessionId", lqsessionId);
    }
  }

  const isMobile = useMediaQuery({
    query: "(max-width: 545px)",
  });

  const mobileAnchor = isMobile && document.getElementById('anchored-elem');
  useEffect( () => {
    const currentUrl = window.location.href;
    const newUrl = new URL(currentUrl)
    const searchParams =new URLSearchParams(newUrl?.search)
    // Function to handle the resize event
    const handleBackButon5g = () => {
     if(searchParams.has("pageName")){
       newUrl.searchParams.set("pageName", "QualificationSelection");
       window.history.replaceState({}, '', newUrl);
       dispatch(setLoadQualficationSelection(true))
       dispatch(setLoadFWAQualifiedPlans(false))
     }
     
     };
   
     // Add the event listener
     window.addEventListener('popstate', handleBackButon5g);
   
     // Cleanup function to remove the listener on unmount
     return () => {
     window.removeEventListener('popstate', handleBackButon5g);
     };
 }, [dispatch])

  useEffect(() => {
    dispatch(setAddress(""));
    dispatch(setLoopQualAddressResp({}));
    dispatch(setLoopQualStreetLookupResp({}));
    if (lqsessionId) {
      dispatch(getLQSessionDetails({ lqsessionId, redirectedFrom, isFrom5GPage: true }));
    }
    if (redirectedFrom) {
      dispatch(resetCheckoutState());
    }
    // for (const param of searchParams) {
    //   dispatch(setQueryParam({
    //     name: param[0],
    //     value: param[1] ?? true
    //   }));
    // }
    // if (searchParams?.size) {
    //   setSearchParams({}, { replace: true });
    // }
    window.sessionStorage.setItem("_flowName", "5g Business Internet");
    SiteAnalytics.trackPageInfo("5G plan");

    if (isAddonsEnabled) {
      isAddOnPageVisitNeeded(sessionCartData?.cart);
    }

    if (showBussProductPage) {
      if (!isBussPageNeeded) {
        let isBussAvailable = false;
        if (sessionCartData?.cart?.packages) {
          const packages = sessionCartData?.cart?.packages;
          for (const pkg in packages) {
            const pkgObj = packages[pkg];
            if (pkgObj?.buss) {
              isBussAvailable = true;
            }
          }
        }
        if (isBussAvailable) {
          setIsBussPageNeeded(true);
        }
      }
    }

    return () => {
      //dispatch(resetQueryParams());
      dispatch(setIsEdit5GPrimaryPlansFlow(false));
      dispatch(setIsEdit5GEquipmentFlow(false));
    }
  }, [])

  const isAddOnPageVisitNeeded = (cartData) => {
    const featureIds = aemContent?.["addons-content"]?.featureIds || [];
    let features = [];
    for (let packages in cartData?.packages) {
      const pkg = cartData?.packages[packages];
      if (pkg?.packageType === "FIVEG") {
        features = pkg?.features || [];
      }
    }
    setIsAddonPageNeeded(!Object.keys(features)?.some(r => featureIds.includes(r)));
  };

  useEffect(() => {
    if ((planPreselection?.planId && planPreselection?.routerSku)) {
      if (!primaryPlan?.selectedPlan && !primaryPlan?.selectedRouter && !primaryPlan?.selectedRouterPayment) {
        dispatch(
          setSelectedPlan({
            planType: "primaryPlan",
            value: planPreselection?.planId?.trim(),
          })
        );
      }
      if (!primaryPlan?.selectedReceiver) {
        dispatch(setSelectedReceiver({ planType: "primaryPlan", value: "receiver" }))
      }
      if (!primaryPlan?.selectedRouter) {
        dispatch(setSelectedRouter({ planType: "primaryPlan", value: planPreselection?.routerSku }));
      }
      if (!primaryPlan?.selectedRouterPayment) {
        dispatch(setSelectedRouterPayment({
          planType: "primaryPlan",
          value: "devicePayments-0",
        })
        );
      }
      //setCollapseEquipmentReceiver(true)
      dispatch(setPlanPreselection({}));
    }
  }, [planPreselection])

  useEffect(() => {
    try {
      if (typeof window['lpTag'] != 'undefined' && lpTag?.section && window?.lpTag?.newPage) {
        lpTag.section = manipulateLpTagSections();
        setTimeout(() => {

          window?.lpTag?.newPage(window?.location?.href, {
            section: lpTag.section,
          });
        }, 8000);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (
      globalData?.metaInformation &&
      (!window.localStorage.getItem("lqsessionId") ||
        (!isEmpty(LQData?.BBALQRespFull) && LQData?.BBALQRespFull?.data?.status?.success))
    ) {
      const isfromBBA = !!document?.referrer;
      if (
        !selectedPlan &&
        !isDigitalQuote &&
        !LQData?.BBALQResp &&
        !isfromBBA &&
        !(lqsessionId || globalData.queryParams?.lqsessionId)
      ) {
        let isEmptyList = CheckoutData?.addressSuggestionList?.length;
        if (
          (sessionCartData &&
            !sessionCartData?.prospect5gLoopQualAddress &&
            !isEmptyList) ||
          (!sessionCartData?.prospect5gLoopQualAddress && !isEmptyList)
        ) {
          //navigate("/shop/solutions/qualification");
        }
      }
      const request = {
        plans: {
          enabled: !pageData?.planDetails?.plans?.length,
          request: {
            ...LQData,
            cband: sessionCartData?.cart?.digitalQuote
              ? sessionCartData?.cart?.cbandCart
              : LQData?.cband,
            mmw: sessionCartData?.cart?.digitalQuote
              ? sessionCartData?.cart?.mwwCart
              : LQData?.mmw && !props?.isfromAuth,
            mmwStark: sessionCartData?.cart?.digitalQuote
              ? sessionCartData?.prospect5gLoopQualAddress?.starkEnabled
              : starkEnabled && !props?.isfromAuth,
            internetAndMobile: displayOnlyInternetMobileTab || false
          },
        },
        routers: {
          enabled: true,
          request: {
            pricePlanCode: selectedPlan,
          },
        },
        receiver: starkEnabled,
        mmw: starkEnabled
      }

      if (oneApiCall) {
        dispatch(getComibnedPlanAndRouters(request))
      } else {
        dispatch(get5GPageLoadData(request));
      }
      dispatch(updateFCCTemplate());
      dispatch(updateFCCJsonNew());
    }
  }, [globalData?.metaInformation, LQData?.BBALQRespFull]);

  useEffect(() => {
    if (document.getElementById("fivegSetupVideoLink"))
      document
        .getElementById("fivegSetupVideoLink")
        .addEventListener("click", openVideoModal);
  });

  function openVideoModal() {
    setShowSetupVideoModal(true);
  }
  const getSelectedPlan = (cartData) => {
    let planId = "";
    if (cartData?.packages) {
      for (let packages in cartData.packages) {
        const pkg = cartData.packages[packages];
        if (pkg?.packageType === "FIVEG") {
          planId = pkg?.plan?.pricePlanCode;
        }
      }
    }
    return planId;
  };

  const getSelectedRouter = (cartData) => {
    let router = null;
    if (cartData?.packages) {
      for (const packages in cartData.packages) {
        const pkg = cartData.packages[packages];
        if (pkg?.packageType === "FIVEG") {
          router = {
            byor: pkg?.byor,
            deviceSku: pkg?.deviceSku,
            priceType: pkg?.priceType,
            tenure: pkg?.tenure,
          };
        }
      }
    }
    return router;
  };

  useEffect(() => {
    if (selectPlanErr || primaryPlan?.selectedPlan) {
      let element = document.getElementById('5gPlanSelection');
      let elementPosition = element?.offsetHeight;
      if (isPlanEdit && !changedPlans) {
        element?.scrollIntoView({ behavior: "smooth", block: "start" });
        setNewMMWflow(false);
      }
      else if (isNewFlow && !isPlanEdit && !selectFwaBundletabForJO) {
        window.scrollTo(0, 0)
        if (LQData?.cband) {
          setisNewFlow(false)
        }
      }
      else if (!isNewFlow || changedPlans) {
        window.scrollTo({
          top: elementPosition + headerHeight,
          behavior: "smooth"
        })
      }
      // } else if(isNewFlow && !isMobile && anchorPreSelectedFlagEnabled) {
      //   window.scrollTo({
      //     top: elementPosition + headerHeight,
      //     behavior: "smooth"
      //   })
      //   setisNewFlow(false)
      //   // dispatch(setIsEditPlansFlow(false));
      // } else if(isNewFlow && isMobile && document.getElementById('anchored-elem') && anchorPreSelectedFlagEnabled) {
      //   if(anchorPreSelectedFlagEnabled){
      //     element = document.getElementById('anchored-elem');
      //     element?.scrollIntoView({ behavior: "smooth",block: "center", inline: "center" });
      //     setisNewFlow(false)
      //   }else{
      //     window.scrollTo(0,0);
      //   }
      // }else if(isNewFlow && !anchorPreSelectedFlagEnabled){
      //   window.scrollTo(0,0);
      //   setisNewFlow(false)
      // }
    }

  }, [selectPlanErr, primaryPlan?.selectedPlan, changedPlans])

  useEffect(() => {
    if ((selectRouterErr || primaryPlan?.selectedRouter) && !starkEnabled) {
      let element = document.getElementById('5gEquipmentSectionwithoutstark');
      let planElement = document.getElementById('5gPlanSelection');
      let elementPosition = element?.offsetHeight + planElement?.offsetHeight;
      if (!isNewFlow) {
        window.scrollTo({
          top: elementPosition + headerHeight,
          // behavior: "smooth"
        });
      }
    }
  }, [selectRouterErr, primaryPlan?.selectedRouter])

  useEffect(() => {
    if ((selectRouterErr || primaryPlan?.selectedRouter) && starkEnabled) {
      let element = document.getElementById('5gEquipmentSectionwithstark');
      let planElement = document.getElementById('5gPlanSelection');
      let receiverElement = document.getElementById('5gReceiver');
      let receiverPayElement = document.getElementById('5gReceiverPaymentwithStark');
      let elementPosition = element?.offsetHeight + planElement?.offsetHeight + receiverElement?.offsetHeight + receiverPayElement?.offsetHeight;
      window.scrollTo({
        top: elementPosition + headerHeight,
        // behavior: "smooth"
      });
    }
  }, [selectRouterErr, primaryPlan?.selectedRouter])

  useEffect(() => {
    if ((selectPaymentErr || primaryPlan?.selectedRouterPayment) && !starkEnabled && !isCband) {
      let element = document.getElementById('5gRouterPayment');
      let planElement = document.getElementById('5gPlanSelection');
      let routerElement = document.getElementById('5gEquipmentSectionwithoutstark');
      let elementPosition = element?.offsetHeight + planElement?.offsetHeight + routerElement?.offsetHeight;

      window.scrollTo({
        top: elementPosition + headerHeight,

        // behavior: "smooth"
      });
    }
  }, [selectPaymentErr, primaryPlan?.selectedRouterPayment])

  useEffect(() => {
    if ((selectPaymentErr || primaryPlan?.selectedRouterPayment) && starkEnabled) {
      let element = document.getElementById('5gReceiver');
      let planElement = document.getElementById('5gPlanSelection');
      let routerElement = document.getElementById('5gReceiverPaymentwithStark');
      let elementPosition = element?.offsetHeight + planElement?.offsetHeight + routerElement?.offsetHeight;
      window.scrollTo({
        top: elementPosition + headerHeight,

        // behavior: "smooth"
      });
    }
  }, [selectPaymentErr, primaryPlan?.selectedRouterPayment])

  useEffect(() => {
    if ((selectReceiverErr || primaryPlan?.selectedReceiver) && starkEnabled) {
      let element = document.getElementById('5gReceiver');
      let planElement = document.getElementById('5gPlanSelection');
      // let receiverElement = document.getElementById('5gEquipmentSectionwithstark');
      let elementPosition = element?.offsetHeight + planElement?.offsetHeight;
      if (isNewFlow) {
        window.scrollTo(0, 0);
        setisNewFlow(false)
      } else {
        window.scrollTo({
          top: elementPosition + headerHeight,
          // behavior: "smooth"
        });
        setNewMMWflow(false);
      }
    }
  }, [selectReceiverErr, primaryPlan?.selectedReceiver])

  useEffect(() => {
    if ((selectPaymentErr || primaryPlan?.selectedReciverPaymentVal) && starkEnabled) {
      let element = document.getElementById('5gReceiverPaymentwithStark');
      let planElement = document.getElementById('5gPlanSelection');
      let receiverElement = document.getElementById('5gReceiver');

      let elementPosition = element?.offsetHeight + planElement?.offsetHeight + receiverElement?.offsetHeight;
      window.scrollTo({
        top: elementPosition + headerHeight,
        // behavior: "smooth"
      });
    }

  }, [selectPaymentErr, primaryPlan?.selectedReciverPaymentVal])


  // if (select){

  //   let element = document.getElementById('5gReceiverPayment');
  //   let elementPosition = element?.offsetTop;

  //   window.scrollTo({
  //     top: elementPosition - headerHeight,
  //     behavior: "smooth"
  //   });
  // }

  useEffect(() => {
    if (pageData?.isEdit5GPrimaryPlansFlow) {
      setCollapsePlans(false);
    }
    if (pageData?.isEdit5GEquipmentFlow) {
      setCollapseEquipment(false);
      setCollapseEquipmentReceiver(false);
    }
  }, [pageData]);

  useEffect(() => {
    if (selectFwaBundletabForJO && isPlanEdit && !isRedirect && edit5gFlow) {
      let element = document.getElementById('5gPlanSelection');
      element?.scrollIntoView({ behavior: "smooth", block: "start" });
      setTimeout(() => {
        setIsRedirect(true);
      }, timeOut);
    }
    if (selectFwaBundletabForJO && isRouterEdit && !isRedirect && edit5gFlow && !LQData?.mmw) {
      let element = document.getElementById('5gEquipmentSectionwithoutstark');
      element?.scrollIntoView({ behavior: "smooth", block: "start" });
      setTimeout(() => {
        setIsRedirect(true);
      }, timeOut);
    }

    if (isRouterEdit && !isRedirect && edit5gFlow) {
      let element = document.getElementById('5gReceiver');
      let elementPosition = element?.offsetHeight;
      element.scrollIntoView({ behavior: "smooth", block: "start" })
      //   window.scrollTo({
      //     top: elementPosition + headerHeight,
      //     behavior: "smooth"
      //   })

      setTimeout(() => {
        setIsRedirect(true);
      }, timeOut);
    }


  }, [pageData, selectFwaBundletabForJO]);


  useEffect(() => {
    const sessionCartPlanId = getSelectedPlan(sessionCartData?.cart);
    if (pageData?.planDetails) {
      const sorted = Object.values(pageData?.planDetails?.plans)
        .map((plan) => ({
          ...plan,
          globalPromo: globalPromos ? globalPromos[plan.billingCode] : null,
        }))
        .sort((a, b) => (Number(a.monthlyFee) > Number(b.monthlyFee) ? 1 :
          (Number(a.monthlyFee) < Number(b.monthlyFee) ? -1 : 0)));
      setPlans(sorted);
      setFaqType(getFaqType(sorted));
      if (selectedPlan && !props?.isfromAuth) {
        const planData = sorted.find(
          (i) => i.billingCode === sessionCartPlanId
        );
        if (planData) {
          setIsCband(planData?.cband);
          return;
        }
      }
      setIsCband(sorted[0]?.cband);
      if (!selectedPlan && !props?.isfromAuth) {
        setCollapsePlans(false);
      }
      if (props?.isfromAuth && !selectedPlan) {
        dispatch(setSelectedPlan({ planType: "primaryPlan", value: sorted[0]?.billingCode }));
        dispatch(get5gRouterDevices({ planType: "primaryPlan", pricePlanCode: sorted[0]?.billingCode, receiver: starkEnabled, mmw: starkEnabled }));
        setCollapsePlans(false);
      }
    }
  }, [sessionCartData, pageData?.planDetails, props?.isfromAuth]);
  // useEffect(() => {
  //   if (pageData?.routerUpdateResp?.status?.success) {
  //     dispatch(setRouterUpdateResp({}));
  //     navigate("/shop/shopping-cart");
  //   }
  // }, [pageData?.routerUpdateResp]);
  useEffect(() => {
    if (props.isCheck === "auth-no" && props.isPermission === "permission-no") {
      dispatch(reset5GState());
    }
  }, [props.isCheck, props.isPermission]);

  useEffect(() => {
    if (pageData?.planDetails && routerDetails) {
      const planType = "primaryPlan";
      const sessionCartPlanId = getSelectedPlan(sessionCartData?.cart);
      const sessionCartRouter = getSelectedRouter(sessionCartData?.cart);
      if (!selectedPlan && (sessionCartPlanId) && !props.isfromAuth) {
        if (sessionCartPlanId && !lqsessionId && !globalData?.queryParams?.lqsessionId) {
          setCollapsePlans(false);
          dispatch(setSelectedPlan({ planType, value: sessionCartPlanId }));
        }
      }
      if (
        !selectedRouter &&
        !clearEquipment &&
        selectedPlan &&
        (isDigitalQuote ||
          (sessionCartRouter &&
            !lqsessionId &&
            !globalData?.queryParams?.lqsessionId)) &&
        !props.isfromAuth
      ) {
        if (!!sessionCartRouter?.byor || !!sessionCartRouter?.priceType) {
          setCollapseEquipment(false);
          setCollapsePayment(false);
          setCollapseEquipmentReceiver(false);
          if (sessionCartRouter?.byor) {
            dispatch(setSelectedRouter({ planType, value: "byor" }));
          } else {
            dispatch(setSelectedRouter({ planType, value: sessionCartRouter?.deviceSku }));
            dispatch(
              setSelectedRouterPayment({
                planType,
                value:
                  sessionCartRouter?.priceType === "RETAIL"
                    ? "zeroYear" : sessionCartRouter?.priceType === "twoYear" ? "twoYear"
                    : "devicePayments-0",
              })
            );
          }
        }
      }
    }
  }, [pageData?.planDetails, routerDetails]);

  useEffect(() => {
    if (pageData?.planDetails && routerDetails) {
      if (!selectedRouter && selectedPlan && props?.isfromAuth) {
        dispatch(setSelectedRouter({ planType: "primaryPlan", value: 'router' }));
        dispatch(
          setSelectedRouterPayment({
            planType: "primaryPlan", value: "devicePayments-0"
          }));
      }
    }
  }, [pageData?.planDetails, routerDetails, props?.isfromAuth]);

  useEffect(() => {
    if (starkEnabled && routerDetails && Object.keys(routerDetails)?.length > 1 && !!selectedPlan && !!selectedRouter && Object.keys(routerStarkDetails)?.length == 0) {
      if (routerDetails?.deviceList?.length > 0 && routerDetails?.deviceList[0]?.receiver) {
        if (receiverDetails?.deviceList?.length > 0 && routerDetails?.deviceList[0]?.receiver) {
          //dispatch(get5gRouterDevices({ planType: "primaryPlan", pricePlanCode: selectedPlan ,receiver:false}))
        }
      }
    }
  }, [receiverDetails, selectedRouter]);

  useEffect(() => {
    if (pageData?.updatedPlanRouterResp?.response && pageData?.updatedPlanRouterResp?.status?.success) {
      dispatch(setUpdatedPlanRouterResp({}));
      onContinueClick();
    } else if (pageData?.updatedPlanRouterResp?.status?.success === false && pageData?.updatedPlanRouterResp?.status?.statusMessage === "Quantity validation failed | Number of lines is more than 9") {
      dispatch(setUpdatedPlanRouterResp({}));
      setMaxDeviceModal(true);
    }
  }, [pageData?.updatedPlanRouterResp]);


  useEffect(() => {
    if (starkEnabled && routerDetails && Object.keys(routerDetails)?.length > 1) {
      // if(routerDetails?.deviceList?.length>0 && routerDetails?.deviceList[0]?.receiver){
      //   setReceiverDetails(routerDetails);
      // }else if (routerDetails?.deviceList?.length>0 && !routerDetails?.deviceList[0]?.receiver){
      //   setRouterStarkDetails(routerDetails);
      // }
      if (routerDetails?.deviceList?.length > 0) {
        const reciver = routerDetails?.deviceList?.filter((x) => x?.enableStarkReceiver && x?.receiver);
        const router = routerDetails?.deviceList?.filter((x) => x?.enableStarkReceiver && !x?.receiver);
        if (reciver?.length > 0) {
          setReceiverDetails({
            deviceList: reciver
          });
        }
        if (router?.length > 0) {
          setRouterStarkDetails({
            deviceList: router
          });
        }
      }
    }
  }, [routerDetails]);

  useEffect(() => {
    if (pageData?.toggleStatusForCBandRouter && Object.keys(pageData?.toggleStatusForCBandRouter)?.length > 0) {
      if (primaryPlan?.selectedPlan && primaryPlan?.selectedRouter && primaryPlan?.selectedRouterPayment) {
        if (primaryPlan?.selectedRouter in pageData?.toggleStatusForCBandRouter) {
          dispatch(setSelectedRouterPayment({
            planType: "primaryPlan",
            value: pageData?.toggleStatusForCBandRouter[primaryPlan?.selectedRouter]
              ? "devicePayments-0"
              : "zeroYear",
          })
          );
        }
      }
    }
  }, [pageData?.toggleStatusForCBandRouter])

  const onUpdateContinue = (event, JointOfferOption, IsFromShoppingCart = false) => {
    const planData = plans.find((i) => i.billingCode === primaryPlan?.selectedPlan);
    const cBand = planData?.cband;
    const mmw = !planData?.cband;
    const isByor = primaryPlan?.selectedReceiver === "byor" || false;
    const routerDetails = primaryPlan?.routerDetails?.deviceList;
    let skuDetails = [];
    let selcetedSku = {};
    if (!isByor) {
      skuDetails = routerDetails?.filter((device) => {
        if (device?.skulist && device?.skulist?.length > 0) {
          const checkForRouter = device?.skulist?.filter((skuDetails) => skuDetails?.sku === primaryPlan?.selectedRouter)?.length > 0;
          return checkForRouter;
        }
        return false;
      })
      if (skuDetails && Array.isArray(skuDetails) && skuDetails?.length === 1) {
        selcetedSku = skuDetails[0];
      }
    }

    const byorDevice = pageData?.byor?.validateDeviceIdResp?.deviceInfo ? pageData?.byor?.validateDeviceIdResp?.deviceInfo : {};
    const selected = selectedRouter || primaryPlan?.selectedRouter;
    let pkgId = sessionCartData?.cart?.packages ? Object.keys(sessionCartData?.cart?.packages)?.filter(
      (packageKey) =>
        sessionCartData?.cart?.packages[packageKey].packageType === "FIVEG"
    )[0] : "";
    const operation = pkgId !== "" ? "UPDATE" : "ADD";

    let payLoad = {};
    let selectedBulkAddrKeys=[]
  LQData?.qualifiedselectedAddress?.map((address) => {
    selectedBulkAddrKeys.push(address?.eventCorrelationId)});
    if (cBand && selected === "byor") {
      payLoad = {
        "cartMeta": {
          "zipCode": "",
          "shoppingPath": "prospect",
          "flow": "5g",
          "clientId": globalData?.widgetInputParams?.clientName?.value ?? "",
          "mfeShoppingPath": window?.mbtGlobal?.shoppingPath
        },
        "lines": [
          {
            "backUp": false,
            "packageId": pkgId,
            "packageType": "FIVEG",
            "operation": operation,
            "plan": {
              "planId": primaryPlan?.selectedPlan
            },
            "isFwaBundleSelected": JointOfferOption != null ? false : isFwaBundleSelected,
            "quantity": 1,
            "byor": isByor || false,
            "device": {
              "deviceSku": byorDevice?.deviceSkuId || byorDevice?.skulist[0]?.sku,
              "deviceCategory": selcetedSku?.category,
              "deviceType": "FIVEGCBANDROUTER",
              "quantity": 1,
              "byod": true,
              "byodInfo": {
                imei: byorDevice?.imei,
                name: byorDevice?.name,
                simSku: byorDevice?.simSku,
                psimCapable: byorDevice?.psimCapable,
                esimCapable: byorDevice?.esimCapable,
                newSim: !useOwnReceiver,
              }
            },
            "bulkAddresses":[
              {
                  "operation": "UPDATE",
                  "addressKeys": selectedBulkAddrKeys
              },
              // {
              //     "operation": "DELETE",
              //     "addressKeys": ["30312_139393933930_10"]
              // }
          ]
          }
        ]
      }
    }
    else if (mmw && selected === "byor" && selected !== "ownReceiver") {
      if (primaryPlan?.selectedReceiver !== "receiver") {
        payLoad = {
          "cartMeta": {
            "zipCode": "",
            "shoppingPath": "prospect",
            "flow": "5g",
            "clientId": globalData?.widgetInputParams?.clientName?.value ?? "",
            "mfeShoppingPath": window?.mbtGlobal?.shoppingPath
          },
          "lines": [
            {
              "backUp": false,
              "packageId": pkgId,
              "packageType": "FIVEG",
              "operation": operation,
              "plan": {
                "planId": primaryPlan?.selectedPlan
              },
              "isFwaBundleSelected": isFwaBundleSelected,
              "quantity": 1,
              "device": {
                "deviceCategory": selcetedSku?.category,
                "deviceType": "FIVEGRECEIVER",
                "quantity": 1,
                "byod": true,
                "byor": false,
              },
              "bulkAddresses":[
                {
                    "operation": "UPDATE",
                    "addressKeys": selectedBulkAddrKeys
                },
                // {
                //     "operation": "DELETE",
                //     "addressKeys": ["30312_139393933930_10"]
                // }
            ]
            }
          ]
        }
      } else {
        payLoad = {
          "cartMeta": {
            "zipCode": "",
            "shoppingPath": "PROSPECT",
            "flow": "5g",
            "clientId": globalData?.widgetInputParams?.clientName?.value ?? "",
            "mfeShoppingPath": window?.mbtGlobal?.shoppingPath
          },
          "lines": [
            {
              "packageId": pkgId,
              "packageType": "FIVEG",
              "operation": operation,
              "quantity": 1,
              "plan": {
                "planId": primaryPlan?.selectedPlan,
                "planCategoryType": "MMW"
              },
              "device": {
                "deviceSku": primaryPlan?.selectedRouter,
                "deviceCategory": "5g internet",
                "deviceType": "FIVEGRECEIVER",
                "quantity": "1",
                "priceType": primaryPlan?.selectedRouterPayment === "devicePayments-0" ? "MONTH_TO_MONTH": primaryPlan?.selectedRouterPayment ==="twoYear"? "twoYear" : "RETAIL",
                "installmentTerm": primaryPlan?.selectedRouterPayment === "devicePayments-0" ? 36 : primaryPlan?.selectedRouterPayment ==="twoYear"? 24 : 0,
                "byod": true,
                "byor": false
              },
              "bulkAddresses":[
                {
                    "operation": "UPDATE",
                    "addressKeys": selectedBulkAddrKeys
                },
                // {
                //     "operation": "DELETE",
                //     "addressKeys": ["30312_139393933930_10"]
                // }
            ]
            }
          ],
          "fwaBundleSelected": isFwaBundleSelected
        }
      }
    }
    else if (mmw && isByor) {
      payLoad = {
        "cartMeta": {
          "zipCode": "",
          "shoppingPath": "prospect",
          "flow": "5g",
          "clientId": globalData?.widgetInputParams?.clientName?.value ?? "",
          "mfeShoppingPath": window?.mbtGlobal?.shoppingPath
        },
        "lines": [
          {
            "backUp": false,
            "packageId": pkgId,
            "packageType": "FIVEG",
            "operation": operation,
            "plan": {
              "planId": primaryPlan?.selectedPlan
            },
            "isFwaBundleSelected": isFwaBundleSelected,
            "quantity": 1,
            "byor": isByor || false,
            "device": {
              "deviceSku": byorDevice?.deviceSkuId || byorDevice?.skulist[0]?.sku,
              "deviceCategory": selcetedSku?.category,
              "deviceType": "FIVEGRECEIVER",
              "quantity": 1,
              "byod": true,
              "byodInfo": {
                imei: byorDevice?.imei,
                name: byorDevice?.name,
                simSku: byorDevice?.simSku,
                newSim: false,
                // psimCapable: byorDevice?.psimCapable,
                // esimCapable: byorDevice?.esimCapable,
              }
            },
            "bulkAddresses":[
              {
                  "operation": "UPDATE",
                  "addressKeys": selectedBulkAddrKeys
              },
              // {
              //     "operation": "DELETE",
              //     "addressKeys": ["30312_139393933930_10"]
              // }
          ]
          }
        ]
      }
    } else if (mmw) {
      payLoad = {
        "cartMeta": {
          "zipCode": "",
          "shoppingPath": "PROSPECT",
          "flow": "5g",
          "clientId": globalData?.widgetInputParams?.clientName?.value ?? "",
          "mfeShoppingPath": window?.mbtGlobal?.shoppingPath
        },
        "lines": [
          {
            "packageId": pkgId,
            "packageType": "FIVEG",
            "operation": operation,
            "quantity":  globalData?.widgetInputParams?.clientName?.value == "MBT" ? selectedBulkAddrKeys?.length :1,
            "plan": {
              "planId": primaryPlan?.selectedPlan,
              "planCategoryType": "MMW"
            },
            "device": {
              "deviceSku": primaryPlan?.selectedRouter,
              "deviceCategory": "5g internet",
              "deviceType": "FIVEGRECEIVER",
              "quantity":  globalData?.widgetInputParams?.clientName?.value == "MBT" ? selectedBulkAddrKeys?.length :1,
              "priceType": primaryPlan?.selectedRouterPayment === "devicePayments-0" ? "MONTH_TO_MONTH" : primaryPlan?.selectedRouterPayment ==="twoYear"? "twoYear" : "RETAIL",
              "installmentTerm": primaryPlan?.selectedRouterPayment === "devicePayments-0" ? 36 : primaryPlan?.selectedRouterPayment ==="twoYear"? 24 : 0,
              "byor": false,
              "byod": false
            },
            "bulkAddresses":[
              {
                  "operation": "UPDATE",
                  "addressKeys": selectedBulkAddrKeys
              },
              // {
              //     "operation": "DELETE",
              //     "addressKeys": ["30312_139393933930_10"]
              // }
          ]
          }
        ],
        "fwaBundleSelected": isFwaBundleSelected
      }
    }
    else {
      payLoad = {
        "cartMeta": {
          "zipCode": "",
          "shoppingPath": "PROSPECT",
          "flow": "5g",
          "clientId": globalData?.widgetInputParams?.clientName?.value ?? "",
          "mfeShoppingPath": window?.mbtGlobal?.shoppingPath
        },
        "lines": [
          {
            "packageId": pkgId,
            "packageType": "FIVEG",
            "operation": operation,
            "quantity":  globalData?.widgetInputParams?.clientName?.value == "MBT" ? selectedBulkAddrKeys?.length :1,
            "plan": {
              "planId": primaryPlan?.selectedPlan,
              "planCategoryType": "CBAND"
            },
            "device": {
              "deviceSku": primaryPlan?.selectedRouter,
              "deviceCategory": "5g internet",
              "deviceType": "FIVEGCBANDROUTER",
              "quantity":  globalData?.widgetInputParams?.clientName?.value == "MBT" ? selectedBulkAddrKeys?.length :1,
              "priceType": onlyCBandQualified ? (pageData?.toggleStatusForCBandRouter[primaryPlan?.selectedRouter] ? "MONTH_TO_MONTH" : "RETAIL") : (primaryPlan?.selectedRouterPayment === "devicePayments-0" ? "MONTH_TO_MONTH" : primaryPlan?.selectedRouterPayment ==="twoYear"? "twoYear" : "RETAIL"),
              "installmentTerm": onlyCBandQualified ? (pageData?.toggleStatusForCBandRouter[primaryPlan?.selectedRouter] ? 36 : 0) : (primaryPlan?.selectedRouterPayment === "devicePayments-0" ? 36 : primaryPlan?.selectedRouterPayment ==="twoYear"? 24 : 0),
              "byor": false,
              "byod": false
            },
            "bulkAddresses":[
              {
                  "operation": "UPDATE",
                  "addressKeys": selectedBulkAddrKeys
              },
              // {
              //     "operation": "DELETE",
              //     "addressKeys": ["30312_139393933930_10"]
              // }
          ]
          }
        ],
        "fwaBundleSelected": JointOfferOption != null ? false : isFwaBundleSelected
      }
    }
    if (!IsFromShoppingCart || (isPlanEdit && changedPlans)) {
      setAddToCartPayload(payLoad);
      dispatch(update5GADDORUpdate(payLoad));
      setContinueClicked(true);
    } else {
      //navigate("/shop/shopping-cart");
      dispatch(setLoadShoppingCartWidget(true));
      const currentUrl = window.location.href;
      const newUrl = new URL(currentUrl);
      newUrl.searchParams.set("pageName", "ShoppingCart");
      window.history.pushState({}, '', newUrl);
    }
  }

  const onContinueClick = () => {
    if (!primaryPlan?.selectedPlan) {
      setSelectPlanErr(true);
    } else if (starkEnabled && primaryPlan?.selectedPlan && !primaryPlan?.selectedReceiver) {
      setSelectReceiverErr(true)
    } else if (primaryPlan?.selectedPlan && !primaryPlan?.selectedRouter && !starkEnabled) {
      setSelectRouterErr(true);
    } else if (starkEnabled && primaryPlan?.selectedPlan && primaryPlan?.selectedReceiver && !primaryPlan?.selectedRouterPayment && primaryPlan?.selectedReceiver !== 'byor') {
      setSelectPaymentErr(true);
    } else if (primaryPlan?.selectedPlan && !starkEnabled && primaryPlan?.selectedRouter &&
      ((primaryPlan?.selectedRouter !== "byor" && !primaryPlan?.selectedRouterPayment) && !onlyCBandQualified) && !(isMMWCart())) {
      setSelectPaymentErr(true);
    } else if (starkEnabled && primaryPlan?.selectedPlan && primaryPlan?.selectedReceiver && primaryPlan?.selectedRouterPayment && !primaryPlan?.selectedRouter) {
      setSelectRouterErr(true);
    }
    // else if(primaryPlan?.selectedPlan && primaryPlan?.selectedRouter && primaryPlan?.selectedRouterPayment && prePlanSelectedFlowEnabled){

    // }
    else {
      SiteAnalytics.eventAddtocartTracking('Add To Cart', '', '4g5g', '5g');
      //dispatch(resetQueryParams());
      if (aemContent?.newJointOfferBundleFlow?.isNewJOFlowEnabled && planSelected != null) {
        setIsFwaBundleSelected(false);
        dispatch(setFWABundleTabSelected(false));
        // navigate("/shop/shopping-cart")  
        dispatch(setLoadShoppingCartWidget(true));
        const currentUrl = window.location.href;
        const newUrl = new URL(currentUrl);
        newUrl.searchParams.set("pageName", "ShoppingCart");
        window.history.pushState({}, '', newUrl);
      }
      else if (isFwaBundleSelected && !selectFwaBundletabForJO) {
        //dispatch(setIsCustomerModalVisited(true))
        //dispatch(setIsLandingFromInternetWithMobile(true));
        //navigate("/shop/products/plans?deviceType=smartphones&planid=67792&q=1");
        //dispatch(setIsFwa(true))
      } else if (showBussProductPage && !isBussPageNeeded && !selectFwaBundletabForJO && !isShoppingIconClicked) {
        // dispatch(setIsFromFwa('5g'));
        //navigate("/shop/buss-products");
      } else if (isAddonPageNeeded && !globalData?.isWirelessToFwaFlow && !selectFwaBundletabForJO && !isShoppingIconClicked) {
        // navigate("/shop/solutions/5g/addons");
      } else {
        // navigate("/shop/shopping-cart");
        dispatch(setLoadShoppingCartWidget(true));
        const currentUrl = window.location.href;
        const newUrl = new URL(currentUrl);
        newUrl.searchParams.set("pageName", "ShoppingCart");
        window.history.pushState({}, '', newUrl);
      }
      setIsRedirect(false);
      setIsShoppingIconClicked(false)
      if (aemContent?.newJointOfferBundleFlow?.isNewJOFlowEnabled && planSelected == null) {
        dispatch(setJOInternetSelected5g(null));
      }

    }
  };




  const onModalContinueClick = (selectedRouter = null) => {
    const selected = selectedRouter || primaryPlan?.selectedRouter;
    if (!enableStarkReceiver && selected === "byor") {
      //navigate("../byor");
    } else if (enableStarkReceiver && selected === "byor") {
      addBYORouter();
    } else if (enableStarkReceiver && selected === "ownReceiver") {
      addOwnReceiver();
    } else {
      updateRouter();
    }
  };

  const updatePlan = (planCode, operation, commonPlanType) => {
    const planData = plans.find((i) => i.billingCode === planCode);
    const cband = planData?.cband;
    const mmw = !planData?.cband;
    let planType = "CBAND";
    if (cband === true) {
      planType = "CBAND";
    } else if (mmw === true) {
      planType = "MMW";
    }
    const zipCode = LQData?.BBALQResp?.zipCode !== "" ? LQData?.BBALQResp?.zipCode : null;
    setInstallationNotAllowed(false);

    const request = {
      plan: planCode,
      plansResp: pageData?.planDetails,
      sessionCartData: sessionCartData,
      operation: operation,
      planType: planType,
      commonPlanType: commonPlanType,
      zipCode: zipCode,
      receiver: starkEnabled,
      fwaBundleSelected: isFwaBundleSelected
    };
    dispatch(get5gRouterDevices({ planType: "primaryPlan", pricePlanCode: planCode, receiver: starkEnabled, mmw: starkEnabled }));
    // if (isOptimizationEnabled) {
    //   dispatch(update5GPlanAndFetchRouters(request));
    // } else {
    //   dispatch(update5GPlan(request));
    // }
  };

  const updateRouter = (paymentMethod, isByor, commonPlanType, installmentTermStark = '') => {
    let routerSelected = primaryPlan?.selectedRouter || isByor;
    if (starkEnabled) {
      const byor = !!(isByor === "byor");
      routerSelected = isByor
      // const byor = !!(primaryPlan?.selectedRouter === "byor") || isByor;
      const deviceInfo = byor
        ? pageData?.validateDeviceIdResp?.deviceInfo
        : routerStarkDetails?.deviceList[0];
      const pricingType = byor ? null : paymentMethod ?? selectedRouterPayment;
      const cband = LQData?.cband;
      const mmw = LQData?.mmw;
      const selectedReciverPaymentVal = installmentTermStark === '' ? pageData?.primaryPlan?.selectedRouterPayment === "zeroYear" ? "RETAIL" : "MONTH_TO_MONTH" : '';
      let priceTypeVal = installmentTermStark === '36' ? "MONTH_TO_MONTH" : "RETAIL";
      let installMentTermVal = installmentTermStark
      if (!!selectedReciverPaymentVal) {
        priceTypeVal = selectedReciverPaymentVal;
        installMentTermVal = selectedReciverPaymentVal === "RETAIL" ? "0" : "36"
      }
      const request = {
        flow: FLOW,
        sessionCartData: sessionCartData,
        deviceInfo: deviceInfo,
        pricingType: pricingType,
        byor: false,
        byod: byor,
        newSim: true,
        cband: cband,
        mmw: mmw,
        commonPlanType: commonPlanType,
        installMentTermVal,
        priceTypeVal,
        starkEnabled,
        routerSelected: routerSelected,
      };
      // dispatch(update5GRouter(request));
    } else {

      const byor = !!(isByor === "byor");
      const index = routerDetails?.deviceList.findIndex(obj => obj?.skulist[0]?.sku?.includes(routerSelected));

      // const byor = !!(primaryPlan?.selectedRouter === "byor") || isByor;
      const deviceInfo = byor
        ? pageData?.validateDeviceIdResp?.deviceInfo
        : (index !== -1) ? routerDetails?.deviceList[index] : routerDetails?.deviceList[0];
      const pricingType = byor ? null : paymentMethod ?? selectedRouterPayment;
      const cband = LQData?.cband;
      const mmw = LQData?.mmw;
      const request = {
        flow: FLOW,
        sessionCartData: sessionCartData,
        deviceInfo: deviceInfo,
        pricingType: pricingType,
        byor: byor,
        byod: byor,
        newSim: true,
        cband: cband,
        mmw: mmw,
        commonPlanType: commonPlanType,
        routerSelected: routerSelected,
      };
      // dispatch(update5GRouter(request));
    }
  };

  const addBYORouter = () => {
    const deviceInfo = pageData?.byor?.validateDeviceIdResp?.deviceInfo;
    const request = {
      flow: "5g",
      sessionCartData: sessionCartData,
      deviceInfo: deviceInfo,
      pricingType: "zeroYear",
      byor: true,
      byod: true,
      newSim: !useOwnReceiver,
    };
    dispatch(update5GRouter(request));
  };

  const addOwnReceiver = () => {
    const deviceInfo = pageData?.byor?.validateDeviceIdResp?.deviceInfo;
    const request = {
      flow: "5g",
      sessionCartData: sessionCartData,
      deviceInfo: deviceInfo,
      pricingType: "zeroYear",
      byor: true,
      byod: true,
      bringOwnReceiver: true,
      byodInfo: {
        imei: deviceInfo?.imei,
        name: deviceInfo?.name,
        simSku: deviceInfo?.simSku,
        pSimCapable: deviceInfo?.pSimCapable,
        eSimCapable: deviceInfo?.eSimCapable,
        newSim: false,
        simNumber: sim,
      },
    };
    dispatch(update5GRouter(request));
  };

  const onPlanSelected = (e, planType) => {
    const operation = primaryPlan?.selectedPlan ? "UPDATE" : "ADD";
    const planData = plans.find((i) => i.billingCode === e);
    if (LQData?.cband && LQData?.mmw) {
      if ((sessionCartData?.cart?.cbandCart !== planData?.cband) || (sessionCartData?.cart?.mmwCart !== !planData?.cband)) {
        setClearEquipment(true);
        dispatch(setSelectedRouter({ planType: "primaryPlan", value: "" }));
        dispatch(
          setSelectedRouterPayment({
            planType: "primaryPlan", value: ""
          }));
      } else {
        setClearEquipment(false);
      }
    }
    dispatch(setSelectedPlan({ planType, value: e }));
    setChangedPlans(true);
    setIsCband(planData?.cband);
    // dispatch(setSelectedPlan(e.value));
    if (!props?.isfromAuth) {
      updatePlan(e, operation, planType);
    }
  };

  const setInstallationNotAllowed = (permission) => {
    setCannotInstall(permission);
    // setDisableContinueBtn(permission);
  };

  const getFaqType = (plansList) => {
    if (plansList.every((item) => item.cband === true)) {
      return "cband";
    } else if (plansList.every((item) => item.cband === false)) {
      return "mmw";
    } else {
      return "cband-mmw";
    }
  };

  const isLoading = pageData.loading;

  // const isContinueDisabled =
  //   !primaryPlan?.selectedPlan ||
  //   !primaryPlan?.selectedRouter ||
  //   (primaryPlan?.selectedRouter !== "byor" && !primaryPlan?.selectedRouterPayment && !sessionCartData?.cart?.mwwCart);

  const isMMWCart = () => {
    if (!props?.isfromAuth) {
      if ((LQData?.mmw && !LQData?.cband)) {
        return true;
      } else if ((LQData?.mmw && LQData?.cband && !primaryPlan?.selectedPlan)) {
        return false;
      } else if (LQData?.mmw && LQData?.cband && primaryPlan?.selectedPlan) {
        if (sessionCartData?.cart?.mwwCart || routerDetails?.deviceList?.[0]?.brandName === "ARRIS") {
          return true;
        } else if (sessionCartData?.cart?.cbandCart) {
          return false;
        }
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (true && pageData?.planDetails?.plans && !isTabSelected && planDetailsFccNewObj && Object.keys(planDetailsFccNewObj)?.length > 0) {
      //dispatch(updateFCCTemplate());
      const testPlan = Object.keys(pageData?.planDetails?.plans);
      //dispatch(updateFCCJson(testPlan));
      let obj = [];
      for (let i of testPlan) {
        if (Array.isArray(planDetailsFccNewObj[i]) && planDetailsFccNewObj[i].length > 0) {
          // setPlanDataFwa(resp.planDetails[testPlan]);
          obj.push(planDetailsFccNewObj[i])
        }
      }
      dispatch(setPlansDetailsFcc(obj))
      if ((testPlan?.length != obj?.length) && obj?.length > 0) {
        dispatch(setContainerHeightBB("32.5"));
      }
    }
  }, [pageData?.planDetails, planDetailsFccNewObj])


  const handleJointOfferDetails = () => {
    setJointOfferList(jointOfferAem["newJointOfferBundleFlow"]["ContinueInternetOnly"][0]);
    setJointOfferModal(true);
  }


  return (
    <>
      {!ready ||
        !globalData?.metaInformation ||
        (window &&
          window.localStorage.getItem("lqsessionId") &&
          (!LQData?.BBALQRespFull || isEmpty(LQData?.BBALQRespFull))) ? (
        <SpinnerComponent disabled={false} />
      ) : (
        <FWAContextProvider
          value={{
            flow: FLOW,
            quantity,
            selectedPlan,
            selectedRouter,
            selectedRouterPayment,
            isMobile,
            primaryPlan,
            pageData,
            sessionCartData,
            oneApiCall,
          }}
        >
          <div className="landing5g" >
            {/* <Header pageName={"Landing5G"} /> */}
            {showMaxDeviceModal && (
              <MaxDevicesModal
                deviceQuantity={maxDeviceQuantity}
                opened={showMaxDeviceModal}
                onOpenedChange={setMaxDeviceModal}
              />
            )}
            {props.isCheck !== "auth-no" &&
              props.isPermission !== "permission-no" && (
                <StickyHeaderVds3
                  headerRef={stickyHeaderRef}
                  onContinue={onUpdateContinue}
                  disabledContinueBtn={false}
                  hideAddress={false}
                  hasInternetPackage={hasInternetPackage}
                  backClickHandler={() => {
                    setFiveGModal(true);
                  }}
                  enableBackLink={true}
                  onAddressChange={() => setChangeAddressModal(true)}
                  continueBtnText={
                    t("4G-5G.common.page-stepper.continue-btn", {
                      ns: "4G-5G",
                    })
                    // !aemContent?.newJointOfferBundleFlow?.isNewJOFlowEnabled || !(sessionCartData?.prospect5gLoopQualAddress?.cbandCart || sessionCartData?.cart?.cbandCart || LQData?.cband) ?
                    // t("4G-5G.common.page-stepper.continue-btn", {
                    //   ns: "4G-5G",
                    // })
                    // : hasPackages ?
                    //   hasInternetPackage && hasRegularPackage ? t("4G-5G.common.page-stepper.continue-btn", { ns: "4G-5G" }) :
                    //     (hasRegularPackage ? tabselectionData?.fwaBundleTabSelected == true && !sessionCartData?.selectFwaBundletabForJO ? aemContent?.newJointOfferBundleFlow?.mobileFlowContinue : aemContent?.newJointOfferBundleFlow?.internetFlowContinue : t("4G-5G.common.page-stepper.continue-btn", { ns: "4G-5G" })) :
                    //   tabselectionData?.fwaBundleTabSelected == true ? aemContent?.newJointOfferBundleFlow?.mobileFlowContinue : aemContent?.newJointOfferBundleFlow?.internetFlowContinue
                    }
                  hideShoppingBagIcon={showShoppingIcon ? false : true}
                  fromFwa={!(!!primaryPlan?.selectedPlan && !!primaryPlan?.selectedRouter && !!primaryPlan?.selectedRouterPayment)}
                  isFromLanding5g={true}
                  setIsShoppingIconClicked={setIsShoppingIconClicked}
                  setshowCartNudgeModal={setshowCartNudgeModal}
                  isJOBundle={aemContent?.newJointOfferBundleFlow?.isNewJOFlowEnabled && (sessionCartData?.prospect5gLoopQualAddress?.cbandCart || sessionCartData?.cart?.cbandCart || LQData?.cband)}
                />
              )}
            {isLoading && <SpinnerComponent disabled={false} />}
            {isDigitalQuote &&
              LQData?.fixedWirelessResponse?.response &&
              !LQData?.fixedWirelessResponse?.response?.qualified4GHome &&
              LQData?.fixedWirelessResponse?.response?.qualified5GHome ? (
              <Notification
                type="info"
                inline={true}
                fullBleed={true}
                title={t("4G-5G.common.page-stepper.lte-plus-disabled-msg", {
                  ns: "4G-5G",
                })}
              />
            ) : (
              ""
            )}
            {pageData.isPackageCompatible && (<PackageCompatibleModal
              opened={pageData.isPackageCompatible}
              sessionCartData={sessionCartData}
              addToCartPayload={addToCartPayload}
              onContinue={() => {
                onContinueClick();
                dispatch(clearCartAndAddToCart(addToCartPayload));
              }}
            />)}
            {/* <HeroSection
              titleText={
                props.isCheck === "auth-no" &&
                props.isPermission === "permission-no"  && !isLqGbcom && !isHeaderType5gorFios
                ? authHeaderTxt
                  : aemContent?.fiveG["plan-content"].header[headerType].heading
           
              }
              subtitleText={
                props.isCheck === "auth-no" &&
                  props.isPermission === "permission-no" && !isLqGbcom && !isHeaderType5gorFios
                  ? authSubHeaderTxt
                  : aemContent?.fiveG["plan-content"].header[headerType].subheading
            
                 
              }
           
              subtitleColor="primary"
              isDarkTheme={!props?.isfromAuth}
              isfromAuth={props?.isfromAuth}
              // isLqGbcom={isLqGbcom}
              isHeaderType5gorFios={isHeaderType5gorFios}
            />
            {!isMobile && <ChatDiv><ChatLink /></ChatDiv>} */}
            <Grid colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
              <Row>
                {
                  props?.isfromAuth ?
                    <></> :
                    <Col colSizes={{ mobile: 4, tablet: 12, desktop: 6 }}>
                      <div className={isMobile ? 'image-align' : 'image-align-desktop'}>
                        {LQData?.mmw ? <ImageContent>
                          {isMobile ?
                            <img
                              src={routerImage && getImageLink(routerImage, 181, 179)}
                            />
                            :
                            <img
                              src={routerImage && getImageLink(routerImage, 453, 447)}
                            />
                          }
                        </ImageContent>
                          : <Carousel
                            layout="1UP"
                            gutter="24px"
                            peek={"none"}
                            surface="light"
                            paginationFill="light"
                            paginationInset="0px"
                            paginationDisplay="onHover"
                            aspectRatio="1.37:1"
                            data={[
                              {
                                textAlignment: "left",
                                textPosition: "bottom",
                                surface: "light",
                                title: {
                                  primitive: 'h1',
                                  children: <div style={{ backgroundColor: 'white', padding: '1.5rem', borderRadius: '10px', width: '90%', position: 'absolute', bottom: '1rem', opacity: '0.9' }}>
                                    <Title size="medium" bold={true}>{aemContent?.fiveG?.carousel.imageHeading.plan200mbps}</Title>
                                    <MarginSpacerS />
                                    <Body size="small">{aemContent?.fiveG?.carousel.carcontent.plan200mbps}</Body>
                                  </div>,
                                },

                                backgroundColor: 'white',
                                backgroundImage:
                                  aemContent?.fiveG?.carousel.images.plan200mbps,
                              },
                              {
                                textAlignment: "left",
                                textPosition: "bottom",
                                title: {
                                  size: 'titleSmall',
                                  primitive: 'h1',
                                  children: <div style={{ backgroundColor: 'white', padding: '1.5rem', borderRadius: '10px', width: '90%', position: 'absolute', bottom: '1rem', opacity: '0.9' }}>
                                    <Title size="medium" bold={true}>{aemContent?.fiveG?.carousel.imageHeading.plan100mbps}</Title>
                                    <MarginSpacerS />
                                    <Body size="small">{aemContent?.fiveG?.carousel.carcontent.plan100mbps}</Body>
                                  </div>,
                                },

                                backgroundColor: 'white',
                                backgroundImage:
                                  aemContent?.fiveG?.carousel.images.plan100mbps,
                              },
                              {
                                textAlignment: "left",
                                textPosition: "bottom",
                                title: {
                                  size: 'titleSmall',
                                  primitive: 'h1',
                                  children: <div style={{ backgroundColor: 'white', padding: '1.5rem', borderRadius: '10px', width: '90%', position: 'absolute', bottom: '1rem', opacity: '0.9' }}>
                                    <Title size="medium" bold={true}>{aemContent?.fiveG?.carousel.imageHeading.backupService}</Title>
                                    <MarginSpacerS />
                                    <Body size="small">{aemContent?.fiveG?.carousel.carcontent.backupService}</Body>
                                  </div>,
                                },

                                backgroundColor: 'white',
                                backgroundImage: aemContent?.fiveG?.carousel.images.backupService,
                              },
                              {
                                textAlignment: "left",
                                textPosition: "bottom",
                                title: {
                                  size: 'titleSmall',
                                  primitive: 'h1',
                                  children: <div style={{ backgroundColor: 'white', padding: '1.5rem', borderRadius: '10px', width: '90%', position: 'absolute', bottom: '1rem', opacity: '0.9' }}>
                                    <Title size="medium" bold={true}>{aemContent?.fiveG?.carousel.imageHeading.router}</Title>
                                    <MarginSpacerS />
                                    <Body size="small">{aemContent?.fiveG?.carousel.carcontent.router}</Body>
                                  </div>,
                                },
                                backgroundColor: 'white',
                                backgroundImage: aemContent?.fiveG?.carousel.images.router,
                              },
                            ]}
                            renderItem={(props) => <Tilelet {...props} width="100%" />}
                          />}
                      </div>
                    </Col>

                }

                <Col colSizes={{ mobile: 4, tablet: 12, desktop: props.isfromAuth ? 10 : 6 }}>
                  {pageData.apiError && (
                    <Notification
                      type="error"
                      title="Something went wrong. Please try again later."
                      fullBleed={true}
                      inlineTreatment={false}
                    />
                  )}
                  {((sessionCartData?.showLinkForMixedCart && pageData?.showCheckAvailabiltyLink) ||
                    globalData.isJoBundleEnabled) &&
                    (sessionCartData?.prospect5gLoopQualAddress?.cbandCart || sessionCartData?.cart?.cbandCart || LQData?.cband)
                    && !(hasInternetPackage && hasRegularPackage) && (
                      <>
                        <MarginSpacer2XL />
                        <div style={{ fontSize: '1rem', color: '#6F7171' }}>
                          {t("app.GWToLTEFiveGPromoBanner.heading", { ns: "app" })}
                          <MarginSpacerRightXS />
                          <TextLink
                            type="inline"
                            size="small"
                            onClick={e => handleJointOfferDetails('internetAndMobile')}>
                            Learn More.
                          </TextLink>
                        </div>
                      </>
                    )}
                  <MarginSpacerL />
                  {props?.isfromAuth ? <Line type="secondary" /> : <></>}
                  <div id="5gPlanSelection">
                    <PlanSelectionNew
                      heading={LQData?.mmw ? (!!primaryPlan?.selectedPlan) ?
                        aemContent?.fiveG["plan-content"]?.selectedPlan
                        : aemContent?.fiveG["plan-content"]?.newSelectPlan : ""
                      }
                      isCollapsed={collapsePlans}
                      isHavingBackupPlans={false}
                      setIsCollapsed={setCollapsePlans}
                      isCheckCband={props.isCheckCband}
                      onSelect={onPlanSelected}
                      onNoOfPlansQtyChange={() => { }}
                      onTabChangeApiCall={() => { }}
                      planDetails={plans}
                      showPromoCard={false}
                      showVisaOffer={sessionCartData?.prospect5gLoopQualAddress?.showVisaOffer}
                      globalPromos={globalPromos}
                      setCollapseEquipment={starkEnabled ? setCollapseEquipmentReceiver : setCollapseEquipment}
                      isfromAuth={props?.isfromAuth}
                      selectPlanErr={selectPlanErr}
                      setSelectPlanErr={setSelectPlanErr}
                      starkEnabled={starkEnabled}
                      setIsFwaBundleSelected={setIsFwaBundleSelected}
                      displayOnlyInternetMobileTab={displayOnlyInternetMobileTab}
                      selectFwaBundletabForJO={selectFwaBundletabForJO}
                      setIsTabSelected={setIsTabSelected}
                      isNewFlow={newMMWflow}
                      hasInternetPackage={hasInternetPackage}
                      hasRegularPackage={hasRegularPackage}
                      hasPackages={hasPackages}
                    />
                  </div>
                  <Line type="secondary" />
                  <div id="5gReceiver">
                    {starkEnabled &&
                      <EquipmentSectionReceiver
                        routers={receiverDetails}
                        onRouterSelect={(name, planType) => {
                          //dispatch(setIsEdit5GEquipmentFlow(false));
                          dispatch(setSelectedReceiver({ planType, value: name }));
                          if (name === 'byor') {
                            dispatch(setSelectedRouter({ planType, value: '' }));
                            dispatch(setSelectedRouterPayment({ planType, value: '' }));
                          }
                          if (name !== 'byor') {
                            dispatch(setSelectedRouter({ planType, value: '' }));
                            //dispatch(get5gRouterDevices({ planType: "primaryPlan", pricePlanCode: selectedPlan ,receiver:false}))
                          }
                          // if (isMMWCart()) {
                          //   if (!props.isfromAuth) {
                          //     updateReceiver("zeroYear", name, planType);
                          //   }
                          // }
                          setCollapsePlans(false);
                        }}
                        flow="5g"
                        isCband={LQData?.cband || (isDigitalQuote && sessionCartData?.cart?.cbandCart)}
                        isMMW={LQData?.mmw}
                        setEnableStarkReceiver={setEnableStarkReceiver}
                        isCollapsed={collapseEquipmentReceiver}
                        isEdit5GEquipmentFlow={pageData?.isEdit5GEquipmentFlow}
                        isHavingBackupPlans={false}
                        setIsCollapsed={setCollapseEquipmentReceiver}
                        setCollapsePayment={setCollapsePayment}
                        mwwCart={sessionCartData?.cart?.mwwCart}
                        isfromAuth={props?.isfromAuth}
                        planDetails={plans}
                        selectReceiverErr={selectReceiverErr}
                        setSelectReceiverErr={setSelectReceiverErr}
                        starkEnabled={starkEnabled}
                        installmentTermStark={installmentTermStark}
                      />}
                  </div>
                  <Line type="secondary" />
                  <div id="5gReceiverPaymentwithStark">
                    {receiverDetails &&
                      selectedReceiver !== "byor" &&
                      starkEnabled &&
                      (
                        <>
                          <ReceiverPayment
                            flow="5g"
                            onPaymentSelect={(data, planType) => {
                              if (data !== selectedRouterPayment) {
                                dispatch(setSelectedRouterPayment({ planType, value: data }));
                                //dispatch(get5gRouterDevices({ planType: "primaryPlan", pricePlanCode: selectedPlan,receiver:false}));
                                if (!props.isfromAuth && selectedRouter) {
                                  const installMentTermValue = data === "zeroYear" ? String(0) : String(36);
                                  updateRouter(data, false, planType, installMentTermValue);
                                }
                                // if(data === "zeroYear"){
                                //   await setInstallmentTermStark(String(0));
                                // }else{
                                //   await setInstallmentTermStark(String(36));
                                // }
                                setCollapsePlans(false);
                                if (!selectedRouter) {
                                  setCollapseEquipment(false);
                                }
                              }
                            }}
                            routerDetails={receiverDetails}
                            selected={selectedRouterPayment}
                            isCollapsed={collapsePayment}
                            isHavingBackupPlans={false}
                            setIsCollapsed={setCollapsePayment}
                            isfromAuth={props?.isfromAuth}
                            selectPaymentErr={selectPaymentErr}
                            setSelectPaymentErr={setSelectPaymentErr}
                            setInstallmentTermStark={setInstallmentTermStark}
                          />
                          <Line type="secondary" />
                        </>
                      )}
                  </div>
                  <div id="5gEquipmentSectionwithstark">
                    {starkEnabled && pageData?.primaryPlan?.selectedReceiver !== 'byor' &&
                      <EquipmentSectionNew
                      toggleStatusForCBandRouter = {pageData?.toggleStatusForCBandRouter}
                      onlyCBandQualified = {onlyCBandQualified}
                      updateCart = {(price,byor,planType)=>updateRouter(price,byor,planType)}
                      fromInstallation = {props?.fromInstallation}
                      routers={starkEnabled  ? routerStarkDetails : routerDetails }
                      onRouterSelect={(name, planType) => {
                        dispatch(setIsEdit5GEquipmentFlow(false));
                        dispatch(setSelectedRouter({ planType, value: name }));
                        if (isMMWCart()) {
                          if (!props.isfromAuth) {
                            //updateRouter("zeroYear", name, planType,installmentTermStark);
                          }
                        }
                        setCollapsePlans(false);
                      }}
                      flow="5g"
                      isCband={LQData?.cband || (isDigitalQuote && sessionCartData?.cart?.cbandCart)}
                      isMMW={LQData?.mmw}
                      setEnableStarkReceiver={setEnableStarkReceiver}
                      isCollapsed={false}
                      isEdit5GEquipmentFlow={pageData?.isEdit5GEquipmentFlow}
                      isHavingBackupPlans={false}
                      setIsCollapsed={setCollapseEquipment}
                      //setCollapsePayment={!starkEnabled && setCollapsePayment}
                      mwwCart={sessionCartData?.cart?.mwwCart}
                      isfromAuth={props?.isfromAuth}
                      planDetails={plans}
                      selectRouterErr={selectRouterErr}
                      setSelectRouterErr={setSelectRouterErr}
                      starkEnabled={starkEnabled}
                      installmentTermStark={installmentTermStark}
                      collapseEquipmentReceiver={collapseEquipmentReceiver}
                    />
                      }
                  </div>
                  <div id="5gEquipmentSectionwithoutstark">
                    {!starkEnabled &&
                      <EquipmentSectionNew
                        toggleStatusForCBandRouter={pageData?.toggleStatusForCBandRouter}
                        onlyCBandQualified={onlyCBandQualified}
                        updateCart={(price, byor, planType) => updateRouter(price, byor, planType)}
                        fromInstallation={props?.fromInstallation}
                        routers={routerDetails}
                        onRouterSelect={(name, planType) => {
                          dispatch(setIsEdit5GEquipmentFlow(false));
                          dispatch(setSelectedRouter({ planType, value: name }));
                          if (isMMWCart()) {
                            if (!props.isfromAuth) {
                              updateRouter("zeroYear", name, planType);
                            }
                          }
                          //   if(name !== 'byor'){
                          //     dispatch(setSelectedRouterPayment({ planType, value: '' }));
                          // }
                          setCollapsePlans(false);
                        }}
                        flow="5g"
                        isCband={LQData?.cband || (isDigitalQuote && sessionCartData?.cart?.cbandCart) || sessionCartData?.prospect5gLoopQualAddress?.cbandCart}
                        isMMW={LQData?.mmw}
                        setEnableStarkReceiver={setEnableStarkReceiver}
                        isCollapsed={collapseEquipment}
                        isEdit5GEquipmentFlow={pageData?.isEdit5GEquipmentFlow}
                        isHavingBackupPlans={false}
                        setIsCollapsed={setCollapseEquipment}
                        setCollapsePayment={setCollapsePayment}
                        mwwCart={sessionCartData?.cart?.mwwCart}
                        isfromAuth={props?.isfromAuth}
                        planDetails={plans}
                        selectRouterErr={selectRouterErr}
                        setSelectRouterErr={setSelectRouterErr}
                      />
                    }
                  </div>
                  {enableStarkReceiver && (
                    <>
                      <MarginSpacerL />
                      <CheckCompatibility
                        planInfo={pageData?.planDetails?.plans[selectedPlan]}
                        routers={routerDetails}
                        imei={imei}
                        setImei={setImei}
                        sim={sim}
                        setSim={setSim}
                        onRouterSelect={(name, planType) => {
                          dispatch(setSelectedRouter({ planType, value: name }));
                        }}
                        setUseOwnReceiver={setUseOwnReceiver}
                        onContinue={onModalContinueClick}
                      />
                      <MarginSpacerXL />
                    </>
                  )}
                  <Line type="secondary" />
                  {!onlyCBandQualified && routerDetails &&
                    isCband &&
                    selectedRouter !== "byor" &&
                    !enableStarkReceiver &&
                    !isMMWCart() &&
                    !starkEnabled && (
                      <>
                        <div id="5gRouterPayment">
                          <RouterPayment
                            flow="5g"
                            onPaymentSelect={(data, planType) => {
                              if (data !== selectedRouterPayment) {
                                dispatch(setSelectedRouterPayment({ planType, value: data }));
                                if (!props.isfromAuth) {
                                  updateRouter(data, false, planType);
                                }
                                setCollapsePlans(false);
                              }
                            }}
                            routerDetails={routerDetails}
                            selected={selectedRouterPayment}
                            isCollapsed={collapsePayment}
                            isHavingBackupPlans={false}
                            setIsCollapsed={setCollapsePayment}
                            isfromAuth={props?.isfromAuth}
                            selectPaymentErr={selectPaymentErr}
                            setSelectPaymentErr={setSelectPaymentErr}
                          />
                        </div>
                        <Line type="secondary" />
                      </>

                    )}

                  {/*  {!isCband && <>
              <Line type="xLight" />
             <Installation aemContent={aemContent?.fiveG['router-content']} 
              setInstallationNotAllowed={setInstallationNotAllowed}/> 
              <Line type="xLight" />
            </>}*/}
                  <MarginSpacerXL />
                  <IncludedInOrder
                    heading={aemContent?.fiveG["router-content"]?.connectionHeading}
                    content={starkEnabled ? (!(!selectedRouter || selectedRouter === "byor") ?
                      (!!selectedReceiver && !!selectedRouter ?
                        aemContent?.fiveG["router-content"]?.connectionOptionsStarkEnabledReceiverAndRouter :
                        aemContent?.fiveG["router-content"]?.connectionOptionsStarkEnabledReceiver) :
                      aemContent?.fiveG["router-content"]?.connectionOptionsStarkEnabledReceiverByodByor) :
                      enableStarkReceiver
                        ? aemContent?.fiveG["router-content"]
                          ?.connectionOptionsStark : selectedRouter === "byor" ?
                          aemContent?.fiveG["router-content"]?.connectionOptionsBYOR
                          : aemContent?.fiveG["router-content"]?.connectionOptions
                    }
                    showAll={primaryPlan?.selectedRouter && !isCband}
                  />
                  <MarginSpacerXL />
                  {!(
                    props.isCheck === "auth-no" &&
                    props.isPermission === "permission-no"
                  ) ? (
                    <CheckoutButton
                      onClick={() => onUpdateContinue()}
                      disabled={false}
                      data-track={t("4G-5G.common.page-stepper.continue-btn", { ns: "4G-5G" })}
                    >
                      {
                        t("4G-5G.common.page-stepper.continue-btn", {
                          ns: "4G-5G",
                        })
                      // !aemContent?.newJointOfferBundleFlow?.isNewJOFlowEnabled || !(sessionCartData?.prospect5gLoopQualAddress?.cbandCart || sessionCartData?.cart?.cbandCart || LQData?.cband) ?
                      //   t("4G-5G.common.page-stepper.continue-btn", { ns: "4G-5G" })
                      //   : hasPackages ? hasInternetPackage && hasRegularPackage ? t("4G-5G.common.page-stepper.continue-btn", { ns: "4G-5G" }) :
                      //     (hasRegularPackage ? tabselectionData?.fwaBundleTabSelected == true && !sessionCartData?.selectFwaBundletabForJO ? aemContent?.newJointOfferBundleFlow?.mobileFlowContinue : aemContent?.newJointOfferBundleFlow?.internetFlowContinue : t("4G-5G.common.page-stepper.continue-btn", { ns: "4G-5G" })) :
                      //     tabselectionData?.fwaBundleTabSelected == true ? aemContent?.newJointOfferBundleFlow?.mobileFlowContinue : aemContent?.newJointOfferBundleFlow?.internetFlowContinue
                          }
                    </CheckoutButton>
                  ) : null}
                  {((tabselectionData?.fwaBundleTabSelected == true && aemContent?.newJointOfferBundleFlow?.isNewJOFlowEnabled)
                    || globalData.isJoBundleEnabled) &&
                    !(hasInternetPackage && hasRegularPackage) && (sessionCartData?.prospect5gLoopQualAddress?.cbandCart ||
                      sessionCartData?.cart?.cbandCart || LQData?.cband) && (
                      <div>
                        <MarginSpacerM />
                        {!hasInternetPackage && (
                          <Body size="large">
                            <TextLink
                              type="inline"
                              onClick={() => {
                                if (!sessionCartData?.selectFwaBundletabForJO) {
                                  setPlanSelected("internet");
                                  dispatch(setJOInternetSelected5g("internet"));
                                  onUpdateContinue("", "internetOnlyClicked");
                                }
                                else {
                                  //navigate("/shop/shopping-cart") 
                                  dispatch(setLoadShoppingCartWidget(true));
                                  const currentUrl = window.location.href;
                                  const newUrl = new URL(currentUrl);
                                  newUrl.searchParams.set("pageName", "ShoppingCart");
                                  window.history.pushState({}, '', newUrl);
                                  globalData?.isJoBundleEnabled && dispatch(setNoThanksClicked(true))
                                }
                              }}>
                              {aemContent?.newJointOfferBundleFlow?.BundleTextLink}
                            </TextLink>
                            {!sessionCartData?.selectFwaBundletabForJO ? aemContent?.newJointOfferBundleFlow?.BundleCopy : aemContent?.newJointOfferBundleFlow?.BundleInternetCopy}
                          </Body>)}
                      </div>
                    )}
                  <MarginSpacerXL />
                  {(aemContent?.fiveG?.skipLandingPage || lqsessionId) && false &&
                    <AlreadyACustomer
                      flow={"5g"}
                      fromPartnersReward={CheckoutData?.isFromPartnersReward}
                      cmpValue={globalData?.cmp?.cmpValue}
                      email={sessionCartData?.prospect5gLoopQualAddress?.email || ''}
                      mtn={sessionCartData?.prospect5gLoopQualAddress?.mtn || sessionCartData?.prospect5gLoopQualAddress?.phoneNumber || ''}
                      addressData={sessionCartData?.prospect5gLoopQualAddress || ''}
                      redirectedFrom={redirectedFrom || ''}
                      lqsessionId={window.localStorage.getItem("lqsessionId") || ''}
                    />}
                  <MarginSpacerL />
                  <div style={{ marginBottom: LayoutTokens.space["32X"].value }} />
                </Col>
              </Row>
              {/* <Row>
          <Col colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
            <Line type="secondary" />
          </Col>
          <Col colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
            {(isCband || enableStarkReceiver) &&
            !(
              props.isCheck === "auth-no" &&
              props.isPermission === "permission-no"
            ) ? (
              <>
                <FAQ faqType={faqType} />
              </>
            ) : null}
          </Col>
        </Row> */}
            </Grid>
            {showSetupVideoModal && (
              <VideoModal
                onCloseBtnClick={() => setShowSetupVideoModal(false)}
                srcVideoURL={
                  aemContent?.fiveG?.["plan-content"].header[headerType].videolink
                }
                title={
                  aemContent?.fourG?.["plan-content"].header["setUpVideoModal"]
                    .title
                }
              />
            )}
            {fivegModal && < FiveGCbandModal opened={fivegModal} onOpenedChange={setFiveGModal} isCband={LQData?.cband} isMMW={LQData?.mmw} LQData={LQData} BBALQResp={BBALQResp} sessionCartData={sessionCartData} />}
            {showChangeAddressModal && (
              <ChangeAddress
                opened={showChangeAddressModal}
                onOpenedChange={setChangeAddressModal}
                url={""}
              />
            )}
            {jointOfferModal && !isPDPLayoutFWA && <JointOfferDetails setJointOfferModal={setJointOfferModal} jointOfferList={jointOfferList} flow={"5g"} />}
            {jointOfferModal && isPDPLayoutFWA && <JointOfferDetailsNew setJointOfferModal={setJointOfferModal} jointOfferList={jointOfferList} flow={"5g"} />}
          </div>
        </FWAContextProvider>
      )}
    </>
  );
};

export default LandingPage5G;
