import { createSlice, createAction } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  address: {},
  addressType: "BUSINESS",
  cardHolderName: "",
  cardNumber: "",
  cardType: "none",
  completeOrderResponse: {},
  cvv: "",
  errorTexts: {
    cardNumber: "",
    expirationDate: "",
    cardHoldersName: "",
    cvv: "",
    addressType: "",
    address: "",
  },
  errors: {},
  expirationDate: "",
  notification: {
    show: false,
    subtitle: "",
    title: "",
    type: "info",
  },
  initializePaymentResponse: {},
  paymentInfo: {},
  validateAndSaveCardResponse: {},
  errorResponse: false,
};

const PaymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    resetPaymentSlice: (state, action) => {
      return initialState;
    },
    setLoading: (state, action) => {
      state["loading"] = action.payload;
    },
    setCardNumber: (state, action) => {
      state["cardNumber"] = action.payload;
    },
    setCardType: (state, action) => {
      state["cardType"] = action.payload;
    },
    setExpirationDate: (state, action) => {
      state["expirationDate"] = action.payload;
    },
    setCardHolderName: (state, action) => {
      state["cardHolderName"] = action.payload;
    },
    setCvv: (state, action) => {
      state["cvv"] = action.payload;
    },
    setAddressType: (state, action) => {
      state["addressType"] = action.payload;
    },
    setAddress: (state, action) => {
      state["address"] = action.payload;
    },
    setErrors: (state, action) => {
      state["errors"] = action.payload;
    },
    setCompleteOrderResponse: (state, action) => {
      state["completeOrderResponse"] = action.payload;
    },
    setInitializePaymentResponse: (state, action) => {
      state["initializePaymentResponse"] = action.payload;
    },
    setNotification: (state, action) => {
      state["notification"] = action.payload;
    },
    setPaymentInfo: (state, action) => {
      state["paymentInfo"] = action.payload;
    },
    setValidateAndSaveCardResponse: (state, action) => {
      state["validateAndSaveCardResponse"] = action.payload;
    },
    setErrorResponse: (state, action) => {
      state["errorResponse"] = action.payload;
    },
  },
});

export const reducer = PaymentSlice.reducer;
export const postCompleteOrder = createAction("payment/postCompleteOrder");
export const postInitializePayment = createAction(
  "payment/postInitializePayment"
);
export const postValidateAndSaveCard = createAction(
  "payment/postValidateAndSaveCard"
);
export const doUpdatePymtCounterFail = createAction(
  "payment/doUpdatePymtCounterFail"
);

export const {
  resetPaymentSlice,
  setLoading,
  setCardNumber,
  setCardType,
  setExpirationDate,
  setCardHolderName,
  setCvv,
  setAddressType,
  setAddress,
  setErrors,
  setNotification,
  setCompleteOrderResponse,
  setInitializePaymentResponse,
  setPaymentInfo,
  setValidateAndSaveCardResponse,
  setErrorResponse,
} = PaymentSlice.actions;
