// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ownership-container .form-col .helper-text p{
    color: #747676;
    margin-top: 8px;
  }
.credit-check-container .show-label span:nth-child(2){
    right: 26px !important;
}
.credit-check-container .show-label div span:nth-child(2){
  right: 0 !important;
}
.credit-check-container .show-label div span span span div:nth-child(2){
  padding: 1px 3px 1px 0 !important;
}
  @media (max-width: 767px){ 
    .ttip-wrapper{
      height: 92px;
    }
    .ttip-error {
      height: 115px;
    }
    .dobfield {
      margin-top: 1rem;
    }
    .form-col.show-label.federalTaxID{
      position: absolute;
      width: 87.3%;
      overflow: unset !important;
    }
  }
  @media screen and (max-width:414px) {
    .form-col.federalTaxID {
      width: 88%;
   }
  }

  @media screen and (min-width:767px) {
    .form-col.show-label.federalTaxID{
      position: absolute;
      width: 287px;
      overflow: unset !important;
    }
    
  }
`, "",{"version":3,"sources":["webpack://./src/pages/Checkout/CreditCheck5g/CreditCheck5g.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;EACjB;AACF;IACI,sBAAsB;AAC1B;AACA;EACE,mBAAmB;AACrB;AACA;EACE,iCAAiC;AACnC;EACE;IACE;MACE,YAAY;IACd;IACA;MACE,aAAa;IACf;IACA;MACE,gBAAgB;IAClB;IACA;MACE,kBAAkB;MAClB,YAAY;MACZ,0BAA0B;IAC5B;EACF;EACA;IACE;MACE,UAAU;GACb;EACD;;EAEA;IACE;MACE,kBAAkB;MAClB,YAAY;MACZ,0BAA0B;IAC5B;;EAEF","sourcesContent":[".ownership-container .form-col .helper-text p{\n    color: #747676;\n    margin-top: 8px;\n  }\n.credit-check-container .show-label span:nth-child(2){\n    right: 26px !important;\n}\n.credit-check-container .show-label div span:nth-child(2){\n  right: 0 !important;\n}\n.credit-check-container .show-label div span span span div:nth-child(2){\n  padding: 1px 3px 1px 0 !important;\n}\n  @media (max-width: 767px){ \n    .ttip-wrapper{\n      height: 92px;\n    }\n    .ttip-error {\n      height: 115px;\n    }\n    .dobfield {\n      margin-top: 1rem;\n    }\n    .form-col.show-label.federalTaxID{\n      position: absolute;\n      width: 87.3%;\n      overflow: unset !important;\n    }\n  }\n  @media screen and (max-width:414px) {\n    .form-col.federalTaxID {\n      width: 88%;\n   }\n  }\n\n  @media screen and (min-width:767px) {\n    .form-col.show-label.federalTaxID{\n      position: absolute;\n      width: 287px;\n      overflow: unset !important;\n    }\n    \n  }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
