// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promo-banner {
  color: #fff;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  padding: 10px 10px 10px 20px;
  margin-top: -1px;
}
.promo-banner .body-text {
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.promo-banner-large {
  border: 1px solid #d8dada;
  background: #f6f6f6;
  padding: 25px;
  border-radius: 12px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
}
.promo-banner-large .body-text {
  display: flex;
}
.promo-banner-large .body-text > p {
  font-size: 20px!important;
}
.cursor-pointer{
  cursor: pointer;
}
.body-text-promo-banner > p {
  text-align: center !important;
}`, "",{"version":3,"sources":["webpack://./src/common/components/PromoBanner/PromoBanner.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,4BAA4B;EAC5B,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;AACf;AACA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;AACf;AACA;EACE,aAAa;AACf;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,eAAe;AACjB;AACA;EACE,6BAA6B;AAC/B","sourcesContent":[".promo-banner {\n  color: #fff;\n  font-size: 12px;\n  display: flex;\n  flex-direction: row;\n  padding: 10px 10px 10px 20px;\n  margin-top: -1px;\n}\n.promo-banner .body-text {\n  margin-left: auto;\n  margin-right: auto;\n  display: flex;\n}\n.promo-banner-large {\n  border: 1px solid #d8dada;\n  background: #f6f6f6;\n  padding: 25px;\n  border-radius: 12px;\n  margin-top: 1rem;\n  margin-bottom: 1rem;\n  display: flex;\n}\n.promo-banner-large .body-text {\n  display: flex;\n}\n.promo-banner-large .body-text > p {\n  font-size: 20px!important;\n}\n.cursor-pointer{\n  cursor: pointer;\n}\n.body-text-promo-banner > p {\n  text-align: center !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
