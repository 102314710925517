import { Trans, useTranslation } from "react-i18next";
import React, { useState } from "react";
import styled from "styled-components";
import { Input } from "@vds3/inputs";
import { Grid, Row } from "@vds3/grids";
import { Button, TextLink } from "@vds3/buttons";
import { Notification } from "@vds3/notifications";

function SubscribeEmail() {
	// BOVV-122935
	const emailPattern =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	const [formState, setFormState] = useState({ email: "" });
	const { t } = useTranslation(["app", "pdp"]);
	const [emailValid, setEmailValid] = useState(false);
	const [content, setContent] = useState(true);
	const [emailEmpty, setEmailEmpty] = useState(false);

	const validateEmail = (value, inputName, action) => {
		if (value && inputName === "email" && value?.length) {
			setEmailValid(emailPattern.test(value));
			setEmailEmpty(!emailPattern.test(value));
		}
	};

	const handleFormInputChange = (evt, inputName) => {
		evt.persist();
		validateEmail(evt.target.value, inputName);
		setFormState((formState) => ({
			...formState,
			[inputName]: evt.target.value,
		}));
	};

	const submitClicked = () => {
		if (formState.email.length > 0 && emailValid) {
			setContent(false);
			setEmailEmpty(false);
		} else {
			setEmailEmpty(true);
		}
	};

	return (
		<>
			<SubscribeContainer>
				<Grid rowGutter="0.5rem" colGutter={false}>
					<Row>
						{content ? (
							<SubscribeContent>
								<StayConnectTitle>
									{" "}
									{t(`device-pdp.stayconnect`, {
										ns: "pdp",
									})}
								</StayConnectTitle>
								<EmailContainer>
									<Input
										onBlur={() => {
											validateEmail(formState["email"], "email", "onBlur");
										}}
										name="email"
										type="text"
										label={`${t("device-pdp.email", {
											ns: "pdp",
										})}`}
										readOnly={false}
										disabled={false}
										value={formState.email}
										error={emailEmpty}
										success={formState.email.length > 0 && emailValid}
										onChange={(e) => handleFormInputChange(e, "email")}
										errorText={
											emailEmpty
												? t(`device-pdp.validaddress`, {
														ns: "pdp",
												  })
												: formState.email.length === 0
												? ""
												: t(`device-pdp.validaddress`, {
														ns: "pdp",
												  })
										}
									/>

									<ButtonNew>
										<Button size="large" secondary={true} onClick={() => submitClicked()}>
											{t(`device-pdp.SignUp`, {
												ns: "pdp",
											})}
										</Button>
									</ButtonNew>
								</EmailContainer>
								<DisclaimerNote>
									<Trans
										ns="pdp"
										i18nKey={`device-pdp.subscribenote`}
										shouldUnescape={true}
									>
										<TextLink
											style={{ fontWeight: "600" }}
											target="_blank"
											href="https://www.verizon.com/about/privacy/"
										/>
										<TextLink
											style={{ fontWeight: "600" }}
											target="_blank"
											href="https://www.verizon.com/about/privacy/full-privacy-policy?scrollto=index-1#acc-item-55"
										/>
									</Trans>
								</DisclaimerNote>
							</SubscribeContent>
						) : (
							<CustomNotfication
								type="success"
								title={`${t("device-pdp.NoticationTitle", {
									ns: "pdp",
								})}`}
								fullBleed={false}
								inline={false}
								disableFocus={true}
							/>
						)}
					</Row>
				</Grid>
			</SubscribeContainer>
		</>
	);
}

const SubscribeContainer = styled.div`
	width: 100%;
	background: #f6f6f6;
	height: 100%;
`;
const SubscribeContent = styled.div`
	margin-left: 1rem;
	margin-top: 2.5rem;
	margin-bottom: 2rem;
	@media (min-width: 320px) and (max-width: 767px) {
		margin-left: 16px;
	}
`;

const StayConnectTitle = styled.div`
	font-family: "Verizon-NHG-eDS";
	font-size: 16px;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 0.5px;
	text-align: left;
	color: #000000;
	margin-bottom: 1em;
`;
const DisclaimerNote = styled.div`
	font-family: "Verizon-NHG-eTX";
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0px;
	text-align: left;
	width: 100%;
	margin: 10px 0px;
	max-width: 596px;
`;
const EmailContainer = styled.div`
	display: flex;
	align-items: flex-start;
	margin-bottom: 2rem;
	width: 80%;
	@media (min-width: 320px) and (max-width: 767px) {
		width: 90%;
		display: block;
	}
`;
const ButtonNew = styled.div`
	margin-left: 10px;
	margin-top: 20px;
	@media (min-width: 320px) and (max-width: 767px) {
		margin-top: 10px;
		margin-left: 3px;
	}
`;
const CustomNotfication = styled(Notification)`
	margin-left: 9rem;
	> div {
		margin: 40px 0px;
	}
	> p {
		font-family: "Verizon-NHG-eDS";
		font-size: 16px;
		font-weight: 700;
		line-height: 20px;
		letter-spacing: 0.5px;
		text-align: left;
	}

	@media (min-width: 320px) and (max-width: 767px) {
		margin-left: 20px;
		width: 90%;
	}
`;
export default SubscribeEmail;
