import { put, takeLatest, select, call, all } from "redux-saga/effects";
import * as API from "../../../containers/APIurls";
import apiClient from "../../../utils/apiClient";
import * as Landing5GSlice from "./5GSlice";
import * as GlobalSlice from "../../../app/ducks/GlobalSlice";
import Utils from "app/ducks/utils";
import each from "lodash/each";
import * as selectors from "./selectors";
import { getSharedCartSave } from "pages/Mobility/ducks/sagas";
import { setLocalCookie } from "utils/commonUtils";
import { isFromAgentLinkDealsPage } from "./helper";
// import { setLocalCookie } from "../../../common/components/unifiedCart/utils";
// import { isFromAgentLinkDealsPage } from "./helper";  


function* getPlans(params) {
  yield put(Landing5GSlice.setAPIError(false));
  yield put(Landing5GSlice.set5GLandingPageLoading(true));
  try {
    const lq = params.payload;
    const internetAndMobile = lq?.internetAndMobile ?? false;
    const requestBody = {
      cartMeta: {
        shoppingPath: "PROSPECT",
        flow: "5g",
      },
      internetAndMobile: internetAndMobile
    };
    if (lq?.mmw === true || lq?.mmwStark) {
      if (!requestBody?.lines) {
        requestBody.lines = [];
      }
      requestBody.lines.push({
        packageType: "FIVEG",
        plan: {
          planCategoryType: "MMW",
        },
      });
    }
    if (lq?.cband === true) {
      if (!requestBody?.lines) {
        requestBody.lines = [];
      }
      requestBody.lines.push({
        packageType: "FIVEG",
        plan: {
          planCategoryType: "CBAND",
        },
      });
    }

    if (lq?.mmw !== true && lq?.mmwStark !== true && lq?.cband !== true) {
      if (!requestBody?.lines) {
        requestBody.lines = [];
      }
      requestBody.lines.push({
        packageType: "FIVEG",
        plan: {
          planCategoryType: "CBAND",
        },
      });
    }

  let fiveGPlansInternetAndMobile;
  let fiveGPlansInternetOnly;
  if(internetAndMobile) {
    fiveGPlansInternetAndMobile = window?.localStorage?.getItem("_5gPlansInternetAndMobile");
  } else {
    fiveGPlansInternetOnly = window?.localStorage?.getItem("_5gPlansInternetOnly");
  }

  let resp = {};
  if(internetAndMobile && fiveGPlansInternetAndMobile) {
    resp = JSON.parse(fiveGPlansInternetAndMobile);
  } else if(!internetAndMobile && fiveGPlansInternetOnly) {
    resp = JSON.parse(fiveGPlansInternetOnly);
  } else {
    resp = yield apiClient.post(API.GET_5G_PLAN, requestBody);;
    if(resp?.status === 200) {
      if(internetAndMobile) {
        window?.localStorage?.setItem("_5gPlansInternetAndMobile", JSON.stringify(resp));
      } else {
        window?.localStorage?.setItem("_5gPlansInternetOnly", JSON.stringify(resp));
      }
    }
  }

    yield put(Landing5GSlice.setPlansDetails(resp?.data));
    // if(window?.mbtGlobal?.flags?.ENABLE_FCC_BROADBAND_FACTS === 'Y' && !internetAndMobile){
    //   yield (call(getTaxcharges, { payload: resp?.data?.plans }));
    // }

  } catch (err) {
    yield put(Landing5GSlice.setAPIError(true));
  } finally {
    // yield put(Landing5GSlice.set5GLandingPageLoading(false));
  }
}

function* get5gTabPlans(params) {
  yield put(Landing5GSlice.setAPIError(false));
  yield put(Landing5GSlice.set5GLandingPageLoading(true));
  try {
    const lq = params.payload;
    const internetAndMobile = lq?.internetAndMobile ?? false;
    const requestBody = {
      cartMeta: {
        shoppingPath: "PROSPECT",
        flow: "5g",
      },
      internetAndMobile: internetAndMobile
    };
    if (lq?.mmw === true || lq?.mmwStark) {
      if (!requestBody?.lines) {
        requestBody.lines = [];
      }
      requestBody.lines.push({
        packageType: "FIVEG",
        plan: {
          planCategoryType: "MMW",
        },
      });
    }
    if (lq?.cband === true) {
      if (!requestBody?.lines) {
        requestBody.lines = [];
      }
      requestBody.lines.push({
        packageType: "FIVEG",
        plan: {
          planCategoryType: "CBAND",
        },
      });
    }

    if (lq?.mmw !== true && lq?.mmwStark !== true && lq?.cband !== true) {
      if (!requestBody?.lines) {
        requestBody.lines = [];
      }
      requestBody.lines.push({
        packageType: "FIVEG",
        plan: {
          planCategoryType: "CBAND",
        },
      });
    }

  let fiveGPlansInternetAndMobile;
  let fiveGPlansInternetOnly;
  if(internetAndMobile) {
    fiveGPlansInternetAndMobile = window?.localStorage?.getItem("_5gPlansInternetAndMobile");
  } else {
    fiveGPlansInternetOnly = window?.localStorage?.getItem("_5gPlansInternetOnly");
  }

  let resp = {};
  if(internetAndMobile && fiveGPlansInternetAndMobile) {
    resp = JSON.parse(fiveGPlansInternetAndMobile);
  } else if(!internetAndMobile && fiveGPlansInternetOnly) {
    resp = JSON.parse(fiveGPlansInternetOnly);
  } else {
    resp = yield apiClient.post(API.GET_5G_PLAN, requestBody);;
    if(resp?.status === 200) {
      if(internetAndMobile) {
        window?.localStorage?.setItem("_5gPlansInternetAndMobile", JSON.stringify(resp));
      } else {
        window?.localStorage?.setItem("_5gPlansInternetOnly", JSON.stringify(resp));
      }
    }
  }

    yield put(Landing5GSlice.setPlansDetails(resp));
    // if(window?.mbtGlobal?.flags?.ENABLE_FCC_BROADBAND_FACTS === 'Y' && !internetAndMobile){
    //   yield (call(getTaxcharges, { payload: resp?.data?.plans }));
    // }

  } catch (err) {
    yield put(Landing5GSlice.setAPIError(true));
  } finally {
    yield put(Landing5GSlice.set5GLandingPageLoading(false));
  }
}

function* getTaxcharges(params) {
  yield put(Landing5GSlice.setAPIError(false));
  yield put(Landing5GSlice.set5GLandingPageLoading(true));
  try {
      const globalData = yield select(selectors.globalData);
      const planIds= Object.keys(params?.payload);
      const prospect5gLoopQualAddress =
        globalData?.sessionCartData?.apiResponse?.prospectFlowData?.prospect5gLoopQualAddress;
      const flow = globalData?.sessionCartData?.apiResponse?.prospectFlowData?.prospect5gLoopQualAddress?.flow;
      const address = {
        addressLine1: prospect5gLoopQualAddress?.addressLine1,
        city: prospect5gLoopQualAddress?.city,
        state: prospect5gLoopQualAddress?.state,
        zipCode: prospect5gLoopQualAddress?.zipCode,
        zipPlus4: prospect5gLoopQualAddress?.zipPlus4,
        unit: prospect5gLoopQualAddress?.unit
      }

      const requestBody = {
        planIds,
        address,
        flow,
      }
       const resp = yield apiClient.post(API.GET_TAX_CHARGES, requestBody);
       yield put(Landing5GSlice.setTaxesCharges(resp?.data));
       let taxChangesObj = {};
       if(resp?.data && resp?.data?.offerTaxDetails){
        for(let i =0 ; i < Object.keys(resp?.data?.offerTaxDetails).length; i++){
          const planId = Object.keys(resp?.data.offerTaxDetails)[i];
          if(resp?.data.offerTaxDetails[planId]){
             const arrMap = resp?.data?.offerTaxDetails[planId]?.map(taxItem =>taxItem.geoCode);
             if(Array.isArray(arrMap) && arrMap?.length > 0){
               const geoCode = arrMap[0];
               address.geoCode = geoCode
               break;
              }
            }
          }
          for(let i = 0 ; i < Object.keys(resp?.data?.offerTaxDetails).length; i++){
              const planId = Object.keys(resp?.data?.offerTaxDetails)[i];
              taxChangesObj[planId] = resp?.data?.offerTaxDetails[planId].map((value)=>{
                  return  {
                      label :  value.taxDescription,
                      value :  `$${(Math.round(value.taxAmount* 100) / 100).toFixed(2)}`,
                  }
              })
          }
       }
      yield put(Landing5GSlice.setStoreFCCDetails({address,taxDetails:taxChangesObj}));
            
   }
    catch (err) {
      yield put(Landing5GSlice.setAPIError(true));
    } finally {
      yield put(Landing5GSlice.set5GLandingPageLoading(false));
    }
}

function* update5gPlans(params) {
  const { isParallelCall } = params?.payload || {};
  if (!isParallelCall) {
    yield put(Landing5GSlice.setAPIError(false));
    yield put(Landing5GSlice.set5GLandingPageLoading(true));
  }
  const globalData = yield select((state) => state.GlobalData);
  const sessionCartData =
    globalData?.sessionCartData?.apiResponse?.prospectFlowData;
  let selectedPlan = {};
  let planId;
  let operationVal;
  let packageId = "";
  let zipCodeValue = sessionCartData?.prospect5gLoopQualAddress?.zipCode || "";
  const {
    plansResp,
    operation,
    planType,
    commonPlanType = "primaryPlan",
    zipCode,
  } = params.payload || {};
  try {
    let requestBody = {};
    if (params.pageLoad) {
      each(plansResp.plans, (plan) => {
        if (!selectedPlan?.monthlyMins) {
          selectedPlan = plan;
        } else {
          const selectedSpeed = Number(
            selectedPlan.monthlyMins.replace(/\D/g, "")
          );
          const planSpeed = Number(plan.monthlyMins.replace(/\D/g, ""));
          if (selectedSpeed > planSpeed) {
            selectedPlan = plan;
          }
        }
      });
      planId = selectedPlan.billingCode;
      operationVal = operation;
      if (operation === "UPDATE") {
        packageId = Object.keys(sessionCartData?.cart?.packages)?.filter(
          (packageKey) =>
            sessionCartData?.cart?.packages[packageKey].packageType === "FIVEG"
        )[0];
      }
    } else {
      selectedPlan = plansResp.plans[params.payload.plan];
      planId = selectedPlan.billingCode;
      operationVal = operation;
      if (operation === "UPDATE" && sessionCartData?.cart?.packages) {
        packageId = Object.keys(sessionCartData?.cart?.packages)?.filter(
          (packageKey) =>
            sessionCartData?.cart?.packages[packageKey].packageType === "FIVEG"
        )[0];
      } else {
        operationVal = "ADD";
      }
    }

    if(zipCodeValue === ""){
      if(zipCode !== ""){
        zipCodeValue = zipCode;
      }
    }

    requestBody = {
      cartMeta: {
        zipCode: zipCodeValue,
        shoppingPath: "PROSPECT",
        flow: "5g",
      },
      lines: [
        {
          packageId: packageId,
          packageType: "FIVEG",
          operation: operationVal,
          quantity: 1,
          plan: {
            planId: planId,
            planCategoryType: planType,
          },
        },
      ],
      fwaBundleSelected: params?.payload?.fwaBundleSelected

    };
    const isMixedCart = yield select(state=>state?.GlobalData?.sessionCartData?.isMixedCart)
    if(isMixedCart) requestBody = {...requestBody,"allowMixedCart":true}
    const isFromMyLinkDealsPage = yield select(state=>state?.GlobalData?.fromMyLinkDealsPage)
    if(isFromMyLinkDealsPage) {
      const repDetails = yield select(state=>state?.GlobalData?.repDetails)
      requestBody = {
        ...requestBody,
        fromMyLinkDealsPage: isFromMyLinkDealsPage,
        salesRepDetails: {
          cmpCode: repDetails?.channel,
          locationCode: repDetails?.location,
          repId: repDetails?.ecode,
          repSSO: repDetails?.uswin,
          emailId:repDetails?.email
        }
      }
    }
    const isFromAgentLinkPage = yield select(state=>state?.GlobalData?.fromAgentLinkDealsPage)
    if(isFromAgentLinkPage)
      {
        requestBody = yield call(isFromAgentLinkDealsPage,requestBody);
      } 
    const response = yield apiClient.post(API.MOBILITY_ADD_TO_CART, requestBody);
    let sessionCartResponse = {
      prospectFlowData: response?.data?.response,
      serviceStatus: response?.data?.status,
    };
    if (
      response &&
      response?.data?.status?.success &&
      response?.data?.response
    ) {
      yield call(getSharedCartSave, {
        payload: { shoppingPath: "PROSPECT", fromUCSubmitOrder: false },
      });
      const newStore = Utils.setStoreFromRetrieve(sessionCartResponse);
      yield put(GlobalSlice.setSessionCartData(newStore));
      yield put(
        Landing5GSlice.setSelectedPlan({
          planType: commonPlanType,
          value: planId,
        })
      );
      window?.localStorage?.setItem("_cartData", JSON.stringify(newStore));
    } else if(response &&
      response?.data?.status?.statusMessage === "PACKAGE_INCOMPATIBLE") {
        yield put(Landing5GSlice.setpackageCompatible(true));
    }
    else
      throw Object.assign(
        new Error("Failed to update plan selection in session cart"),
        { status: response?.status, data: response?.data }
      );

    if (!isParallelCall) {
      if (params?.payload) {
        const { plan } = params?.payload || {};
        yield call(get5gRouterDevices, {
          selectedPlan: plansResp?.plans[plan],
          planType: "primaryPlan",
        });
      } else {
        yield call(get5gRouterDevices, {
          planType: "primaryPlan",
          selectedPlan,
        });
      }
    }
  } catch (err) {
    yield put(Landing5GSlice.setAPIError(true));
  } finally {
    if (!isParallelCall) {
      yield put(Landing5GSlice.set5GLandingPageLoading(false));
    }
  }
}

function* get5gRouterDevices(params) {
  const { isParallelCall } = params?.payload || {};
  if (!isParallelCall) {
    yield put(Landing5GSlice.setAPIError(false));
    if(params?.pageLoad){
    yield put(Landing5GSlice.set5GLandingPageLoading(true));
    }
  }

  try {
    const pricePlanCode =
      params?.payload?.pricePlanCode ??
      params?.payload?.selectedPlan?.billingCode;
    const receiver = params?.payload?.hasOwnProperty('receiver') ? params?.payload?.receiver : params?.receiver;
    const requestBody = {
      cartMeta: {
        shoppingPath: "PROSPECT",
        flow: "5g",
      },
      planInfo: {
        planCode: pricePlanCode ?? params?.selectedPlan?.billingCode,
        planPrice: params?.selectedPlan?.monthlyFee,
        cband: params?.selectedPlan?.cband,
        receiver,
        mmw:receiver || false
      },
    };

    const resp = yield apiClient.post(API.GET_5G_ROUTER_DEVICES, requestBody);

    yield put(
      Landing5GSlice.setRouterDeviceResp({
        planType: params?.planType ?? params?.payload?.planType,
        response: resp?.data,
      })
    );
  } catch (err) {
    yield put(Landing5GSlice.setAPIError(true));
  } finally {
    if (!isParallelCall) {
      yield put(Landing5GSlice.set5GLandingPageLoading(false));
    }
  }
}

function* update5GPlanAndFetchRouters(params) {
  yield put(Landing5GSlice.setAPIError(false));
  yield put(Landing5GSlice.set5GLandingPageLoading(true));
  try {
    const { plan } = params?.payload || {};

    const forks = [];
    if (params?.payload) {
      forks.push(
        yield fork(get5gRouterDevices, {
          payload: {
            selectedPlan: params?.payload?.plansResp?.plans[plan],
            isParallelCall: true,
            planType: "primaryPlan",
            receiver: params?.payload?.receiver
          },
        })
      );
      forks.push(
        yield fork(update5gPlans, {
          payload: { ...params?.payload, isParallelCall: true },
        })
      );
    }
    yield join([...forks]);
  } catch (err) {
    console.error(err);
    yield put(Landing5GSlice.setAPIError(true));
  } finally {
    yield put(Landing5GSlice.set5GLandingPageLoading(false));
  }
}

function* update5GRouter(params) {
  const globalData = yield select((state) => state.GlobalData);
  yield put(Landing5GSlice.setAPIError(false));
  yield put(Landing5GSlice.set5GLandingPageLoading(true));
  const { commonPlanType = "primaryPlan" } = params?.payload;
  const sessionCartData =
    globalData?.sessionCartData?.apiResponse?.prospectFlowData;
  try {
    const {
      flow,
      deviceInfo,
      pricingType,
      byor,
      byod,
      newSim,
      simNumber,
      cband,
      mmw,
      bringOwnReceiver,
      byodInfo,
      installMentTermVal,
      priceTypeVal,
      starkEnabled,
      routerSelected,
    } = params.payload;

    let packages = {},
      device = {},
      pricingDetails = {},
      key = "",
      deviceTypeVal = "";
    if (byor) {
      device = { ...deviceInfo };
      key = device.deviceSkuId;
    } else {
      key = deviceInfo?.skulist[0]?.sku;
      const pricingTypeSplit = pricingType?.split("-");
      if (pricingTypeSplit?.length > 1) {
        pricingDetails =Array.isArray(deviceInfo?.skulist[0]?.pricingDetails[pricingTypeSplit[0]]) &&
          deviceInfo?.skulist[0]?.pricingDetails[pricingTypeSplit[0]][
            pricingTypeSplit[1]
          ];
      } else {
        pricingDetails = deviceInfo?.skulist[0].pricingDetails[pricingType];
      }
      device = {
        ...deviceInfo,
        ...deviceInfo?.skulist[0],
        ...pricingDetails,
      };
      delete device.skulist;
      delete device.pricingDetails;
    }
    device.byor = byor;
    device.byod = byod;
    device.newSim = newSim;
    device.bringOwnReceiver = bringOwnReceiver;
    if (newSim) device.simNumber = simNumber;

    packages[key] = device;

    if (mmw === true) {
      deviceTypeVal = "FIVEGRECEIVER";
    }

    if (cband === true) {
      deviceTypeVal = "FIVEGCBANDROUTER";
    }

    if (!mmw && !cband) {
      deviceTypeVal = "FIVEGCBANDROUTER";
    }

    let packageId = "";
    if (sessionCartData?.cart?.packages) {
      packageId = Object.keys(sessionCartData?.cart?.packages)?.filter(
        (packageKey) =>
          sessionCartData?.cart?.packages[packageKey].packageType === "FIVEG"
      )[0];
    }

    const isUpdate = sessionCartData?.cart?.packages;

    const requestBody = {
      cartMeta: {
        zipCode: sessionCartData?.prospect5gLoopQualAddress?.zipCode || "",
        shoppingPath: "PROSPECT",
        flow: flow,
      },
      lines: [
        {
          packageId: isUpdate ? packageId : "",
          packageType: "FIVEG",
          operation: isUpdate ? "UPDATE" : "ADD",
          quantity: 1,
          device: {
            deviceSku: byor ? device.deviceSkuId : device.sku,
            // deviceId: device.deviceId,
            deviceCategory: device.category,
            deviceType: deviceTypeVal,
            quantity: "1",
            priceType: priceTypeVal ? priceTypeVal :
              pricingType === "zeroYear" || (mmw && !cband)
                ? "RETAIL"
                : "MONTH_TO_MONTH",
              installmentTerm:installMentTermVal ? Number(installMentTermVal) :
              pricingType === "zeroYear" || (mmw && !cband)
                ? 0
                : device.nofInstallements,
            seoUrlName: device.seoUrlName,
            byor: device.byor,
            byod: device.byod,
            bringOwnReceiver: device.bringOwnReceiver,
            byodInfo,
          },
        },
      ],
    };
    const isMixedCart = yield select(state=>state?.GlobalData?.sessionCartData?.isMixedCart)
    if(isMixedCart) requestBody = {...requestBody,"allowMixedCart":true}
    const isFromMyLinkDealsPage = yield select(state=>state?.GlobalData?.fromMyLinkDealsPage)
    if(isFromMyLinkDealsPage) {
      const repDetails = yield select(state=>state?.GlobalData?.repDetails)
      requestBody = {
        ...requestBody,
        fromMyLinkDealsPage: isFromMyLinkDealsPage,
        salesRepDetails: {
          cmpCode: repDetails?.channel,
          locationCode: repDetails?.location,
          repId: repDetails?.ecode,
          repSSO: repDetails?.uswin,
          emailId:repDetails?.email
        }
      }
    }
    const isFromAgentLinkPage = yield select(state=>state?.GlobalData?.fromAgentLinkDealsPage)
    if(isFromAgentLinkPage)
      {
        requestBody = yield call(isFromAgentLinkDealsPage,requestBody);
      } 
    const response = yield apiClient.post(
      API.MOBILITY_ADD_TO_CART,
      requestBody
    );

    yield put(Landing5GSlice.setRouterUpdateResp(response?.data));
    let sessionCartResponse = {
      prospectFlowData: response?.data?.response,
      serviceStatus: response?.data?.status,
    };
    if (response && response.data && response.data.response) {
      yield call(getSharedCartSave, {
        payload: { shoppingPath: "PROSPECT", fromUCSubmitOrder: false },
      });
      const newStore = Utils.setStoreFromRetrieve(sessionCartResponse);
      yield put(GlobalSlice.setSessionCartData(newStore));
      window?.localStorage?.setItem("_cartData", JSON.stringify(newStore));
      yield put(
        Landing5GSlice.setSelectedRouter({
          planType: commonPlanType,
          value: starkEnabled ? (byod ? "byor" : routerSelected) : byor ? "byor" : routerSelected,
        })
      );
    }
    else if(response &&
      response?.data?.status?.statusMessage === "PACKAGE_INCOMPATIBLE") {
        yield put(Landing5GSlice.setpackageCompatible(true));
    }
  } catch (err) {
    yield put(Landing5GSlice.setAPIError(true));
  } finally {
    yield put(Landing5GSlice.set5GLandingPageLoading(false));
  }
}
function* clearCartAndAddToCart(params) {
  const globalData = yield select((state) => state.GlobalData);
  yield put(Landing5GSlice.setAPIError(false));
  yield put(Landing5GSlice.set5GLandingPageLoading(true));
  try{
    const requestBody = {
      approach: "",
      flow: "5g",
      shoppingPath: "PROSPECT",
    };
    yield apiClient.post(API.CLEAR_4G5G_CART_URL, requestBody);
    yield call(update5GADDORUpdate, params);
    }
   catch (err) {
    yield put(Landing5GSlice.setAPIError(true));
  } finally {
    yield put(Landing5GSlice.set5GLandingPageLoading(false));
  }
}

function* update5GADDORUpdate(params) {
  const globalData = yield select((state) => state.GlobalData);
  yield put(Landing5GSlice.setAPIError(false));
  yield put(Landing5GSlice.set5GLandingPageLoading(true));
  const { commonPlanType = "primaryPlan" } = params?.payload;
  const sessionCartData =
    globalData?.sessionCartData?.apiResponse?.prospectFlowData;
  try {
    // const {
    //   flow,
    //   deviceInfo,
    //   pricingType,
    //   byor,
    //   byod,
    //   newSim,
    //   simNumber,
    //   cband,
    //   mmw,
    //   bringOwnReceiver,
    //   byodInfo,
    //   installMentTermVal,
    //   priceTypeVal,
    //   starkEnabled,
    //   routerSelected,
    // } = params.payload;

    // let packages = {},
    //   device = {},
    //   pricingDetails = {},
    //   key = "",
    //   deviceTypeVal = "";
    // if (byor) {
    //   device = { ...deviceInfo };
    //   key = device.deviceSkuId;
    // } else {
    //   key = deviceInfo?.skulist[0]?.sku;
    //   const pricingTypeSplit = pricingType?.split("-");
    //   if (pricingTypeSplit?.length > 1) {
    //     pricingDetails =Array.isArray(deviceInfo?.skulist[0]?.pricingDetails[pricingTypeSplit[0]]) &&
    //       deviceInfo?.skulist[0]?.pricingDetails[pricingTypeSplit[0]][
    //         pricingTypeSplit[1]
    //       ];
    //   } else {
    //     pricingDetails = deviceInfo?.skulist[0].pricingDetails[pricingType];
    //   }
    //   device = {
    //     ...deviceInfo,
    //     ...deviceInfo?.skulist[0],
    //     ...pricingDetails,
    //   };
    //   delete device.skulist;
    //   delete device.pricingDetails;
    // }
    // device.byor = byor;
    // device.byod = byod;
    // device.newSim = newSim;
    // device.bringOwnReceiver = bringOwnReceiver;
    // if (newSim) device.simNumber = simNumber;

    // packages[key] = device;

    // if (mmw === true) {
    //   deviceTypeVal = "FIVEGRECEIVER";
    // }

    // if (cband === true) {
    //   deviceTypeVal = "FIVEGCBANDROUTER";
    // }

    // if (!mmw && !cband) {
    //   deviceTypeVal = "FIVEGCBANDROUTER";
    // }

    // let packageId = "";
    // if (sessionCartData?.cart?.packages) {
    //   packageId = Object.keys(sessionCartData?.cart?.packages)?.filter(
    //     (packageKey) =>
    //       sessionCartData?.cart?.packages[packageKey].packageType === "FIVEG"
    //   )[0];
    // }

    // const isUpdate = sessionCartData?.cart?.packages;

    // const requestBody = {
    //   cartMeta: {
    //     zipCode: sessionCartData?.prospect5gLoopQualAddress?.zipCode || "",
    //     shoppingPath: "PROSPECT",
    //     flow: flow,
    //   },
    //   lines: [
    //     {
    //       packageId: isUpdate ? packageId : "",
    //       packageType: "FIVEG",
    //       operation: isUpdate ? "UPDATE" : "ADD",
    //       quantity: 1,
    //       device: {
    //         deviceSku: byor ? device.deviceSkuId : device.sku,
    //         // deviceId: device.deviceId,
    //         deviceCategory: device.category,
    //         deviceType: deviceTypeVal,
    //         quantity: "1",
    //         priceType: priceTypeVal ? priceTypeVal :
    //           pricingType === "zeroYear" || (mmw && !cband)
    //             ? "RETAIL"
    //             : "MONTH_TO_MONTH",
    //           installmentTerm:installMentTermVal ? Number(installMentTermVal) :
    //           pricingType === "zeroYear" || (mmw && !cband)
    //             ? 0
    //             : device.nofInstallements,
    //         seoUrlName: device.seoUrlName,
    //         byor: device.byor,
    //         byod: device.byod,
    //         bringOwnReceiver: device.bringOwnReceiver,
    //         byodInfo,
    //       },
    //     },
    //   ],
    // };
    let requestBody =params.payload;
    const isMixedCart = yield select(state=>state?.GlobalData?.sessionCartData?.isMixedCart)
    if(isMixedCart) requestBody = {...requestBody,"allowMixedCart":true}
    const isFromMyLinkDealsPage = yield select(state=>state?.GlobalData?.fromMyLinkDealsPage)
    if(isFromMyLinkDealsPage) {
      const repDetails = yield select(state=>state?.GlobalData?.repDetails)
      requestBody = {
        ...requestBody,
        fromMyLinkDealsPage: isFromMyLinkDealsPage,
        salesRepDetails: {
          cmpCode: repDetails?.channel,
          locationCode: repDetails?.location,
          repId: repDetails?.ecode,
          repSSO: repDetails?.uswin,
          emailId:repDetails?.email
        }
      }
    }
    const isFromAgentLinkPage = yield select(state=>state?.GlobalData?.fromAgentLinkDealsPage)
    if(isFromAgentLinkPage)
      {
        requestBody = yield call(isFromAgentLinkDealsPage,requestBody);
      } 
    const response = yield apiClient.post(
      API.MOBILITY_ADD_TO_CART,
      requestBody
    );

    yield put(Landing5GSlice.setRouterUpdateResp(response?.data));
    let sessionCartResponse = {
      prospectFlowData: response?.data?.response,
      serviceStatus: response?.data?.status,
    };
    if (response && response.data && response.data.response) {
      yield call(getSharedCartSave, {
        payload: { shoppingPath: "PROSPECT", fromUCSubmitOrder: false },
      });
      const newStore = Utils.setStoreFromRetrieve(sessionCartResponse);
      yield put(Landing5GSlice.setUpdatedPlanRouterResp(response?.data))
      yield put(GlobalSlice.setSessionCartData(newStore));
      window?.localStorage?.setItem("_cartData", JSON.stringify(newStore));
      yield put(
        Landing5GSlice.setSelectedRouter({
          planType: commonPlanType,
          value: starkEnabled ? (byod ? "byor" : routerSelected) : byor ? "byor" : routerSelected,
        })
      );
    }
    else if(response &&
      response?.data?.status?.statusMessage === "PACKAGE_INCOMPATIBLE") {
        yield put(Landing5GSlice.setpackageCompatible(true));
    }
  } catch (err) {
    yield put(Landing5GSlice.setAPIError(true));
  } finally {
    yield put(Landing5GSlice.set5GLandingPageLoading(false));
  }
}

function* validateDeviceId(params) {
  yield put(Landing5GSlice.setAPIError(false));
  yield put(Landing5GSlice.set5GLandingPageLoading(true));
  const { cband, mmw, deviceId, flow, router } = params.payload;
  try {
    const requestBody = {
      approach: "",
      cband: !!cband,
      mmw: !!mmw,
      deviceId: deviceId, // "35088458015697"
      flow: flow, // "5g"
      router: router, // true
      shoppingPath: "PROSPECT",
    };
    const resp = yield apiClient.post(API.VALIDATE_DEVICE_ID, requestBody);

    yield put(Landing5GSlice.setValidateDeviceIdResp(resp?.data));
  } catch (err) {
    yield put(Landing5GSlice.setAPIError(true));
  } finally {
    yield put(Landing5GSlice.set5GLandingPageLoading(false));
  }
}

function* validateSimId(params) {
  yield put(Landing5GSlice.setAPIError(false));
  yield put(Landing5GSlice.set5GLandingPageLoading(true));
  const { cband, deviceId, deviceSku = "", flow, newSim, simNumber } = params.payload;
  try {
    const requestBody = {
      approach: "",
      cband: cband, // true
      deviceId: deviceId, // "35088458015697"
      deviceSku: deviceSku, // "SMG986UZAV"
      flow: flow, // "5g"
      newSim: newSim, // false
      shoppingPath: "PROSPECT",
      simNumber: simNumber, // "89148000007570140018"
    };
    const resp = yield apiClient.post(API.VALIDATE_SIM_ID, requestBody);

    yield put(Landing5GSlice.setValidateSimIdResp(resp?.data));
  } catch (err) {
    yield put(Landing5GSlice.setAPIError(true));
  } finally {
    yield put(Landing5GSlice.set5GLandingPageLoading(false));
  }
}

function* getPlanPreselection(params) {
  let requestType = "CBAND";
  if(params?.payload?.plans?.request?.cband) {
    requestType = "CBAND"
  } else if (params?.payload?.plans?.request?.mmwStark){
    requestType = "MMW_STARK"
  } else if(params?.payload?.plans?.request?.mmw) {
    requestType = "MMW"
  }
  const requestBody = {
    type: requestType,
  };
  const resp = yield apiClient.post(API.GET_PLAN_PRESELECTION, requestBody);
  yield put(Landing5GSlice.setPlanPreselection(resp?.data));
  if (params?.payload?.routers && resp?.data?.serviceStatus?.success) {
    const pricePlanCode =
      resp?.data?.planId && resp?.data?.planId?.split(",")[0];
    yield call(get5gRouterDevices, {
      payload: { planType: "primaryPlan", pricePlanCode ,receiver: params?.payload?.receiver},
    });
  }
}

function* getComibnedPlanAndRouters(params) {
  yield put(Landing5GSlice.setAPIError(false));
  yield put(Landing5GSlice.set5GLandingPageLoading(true));
  const globalData = yield select(selectors.globalData);
  let requestType = "CBAND";
  if (params?.payload?.plans?.request?.cband) {
    requestType = "CBAND"
  }
  // else if (params?.payload?.plans?.request?.mmwStark) {
  //   requestType = "MMW_STARK"
  // } 
  else if (params?.payload?.plans?.request?.mmw || params?.payload?.plans?.request?.mmwStark) {
    requestType = "MMW"
  }
  try {
    const lq = params?.payload;
    const internetAndMobile = (lq?.internetAndMobile || lq?.plans?.request?.internetAndMobile) ?? false;

    const requestBody = {
      cartMeta: {
        shoppingPath: "PROSPECT",
        flow: "5g",
        ecpdId:globalData?.widgetInputParams?.ecpdId?.value ?? "",
      userId : globalData?.widgetInputParams?.mbLoginId?.value ?? globalData?.widgetInputParams?.loginUserId?.value ?? globalData?.widgetInputParams?.mbaUserId?.value ?? "",
      rootEcpdId: globalData?.widgetInputParams?.ecpdId?.value ?? "",
      clientId: globalData?.widgetInputParams?.clientName?.value ?? ""
      },
      lines: [
        {
          packageType: "FIVEG",
          plan: {
            planCategoryType: requestType
          },
          clear4g5g:params?.payload?.clear4g5g
        }
      ]
    };
    const resp = yield apiClient.post(API.GET_COMBINED_PLANROUTER_URL, requestBody);
    if (resp?.status === 200) {
      //if (internetAndMobile) {
        window?.localStorage?.setItem("_5gPlansInternetAndMobile", JSON.stringify(resp?.data?.plans?.internetAndMobile));
      //} else {
        window?.localStorage?.setItem("_5gPlansInternetOnly", JSON.stringify(resp?.data?.plans?.internet));
      // }
    }
    if (resp?.data) {
      yield put(Landing5GSlice.setCombinedResponse(resp?.data));
      yield (call(getPlandAndRouters, { paylod: { ...resp?.data, internetAndMobile } }));
    }
  }
  catch (err) {
    yield put(Landing5GSlice.setAPIError(false));
  } finally {
    yield put(Landing5GSlice.set5GLandingPageLoading(false));
  }
}

function* getPlandAndRouters(params) {
  const combinePlandRoutersData = params?.paylod ?? params?.payload;
  let planDetails = combinePlandRoutersData?.plans?.internet;
  // let cappedPlans =''
  if(combinePlandRoutersData?.internetAndMobile){
    planDetails = combinePlandRoutersData?.plans?.internetAndMobile
  }
  // if(!combinePlandRoutersData?.internetAndMobile &&  !planDetails.cappedPlans && planDetails?.plans) {
  //   cappedPlans = {...planDetails, cappedPlans: Object.keys(planDetails?.plans)}
  // } else if(combinePlandRoutersData?.internetAndMobile && !planDetails.cappedPlans && planDetails?.plans) {
  //   cappedPlans = {...planDetails, cappedPlans: Object.keys(planDetails?.plans)}
  // }
  const routerDetails = combinePlandRoutersData?.routerDevices;
  yield put(Landing5GSlice.setPlansDetails(planDetails));
  yield put(
    Landing5GSlice.setRouterDeviceResp({
      planType: "primaryPlan",
      response: combinePlandRoutersData?.routerDevices,
    })
  );
  let preSelectedPlanId = '';
  for(const key in planDetails?.plans) {
    if(planDetails.plans[key].preSelected) {
      preSelectedPlanId = key;
    }
  }
  let preSelectedRouterSku = '';
  if(routerDetails) {
    for(const router of routerDetails?.deviceList) {
      if(router.skulist && router.skulist[0]?.preSelected && !router.receiver) {
        preSelectedRouterSku = router.skulist[0].sku
        break
      }
    }
  }
  let preSelectedReceiverSku = '';
  if(routerDetails) {
    for(const router of routerDetails?.deviceList) {
      if(router.skulist && router.skulist[0]?.preSelected && router.receiver) {
        preSelectedReceiverSku = router.skulist[0].sku
        break
      }
    }
  }
  yield put(Landing5GSlice.setPlanPreselection({planId: preSelectedPlanId, routerSku: preSelectedRouterSku, receiverSku: preSelectedReceiverSku}));

}

function* get5GPageLoadData(params) {
  yield put(Landing5GSlice.setAPIError(false));
  yield put(Landing5GSlice.setInitialLoad(false));
  yield put(Landing5GSlice.set5GLandingPageLoading(true));
  try {
    const forks = [];
    if (params?.payload?.plans?.enabled) {
      forks.push(call(getPlans, { payload: params?.payload?.plans?.request }));
    }
    if (params?.payload?.routers?.enabled) {
      if (params?.payload?.routers?.request?.pricePlanCode) {
        forks.push(
          call(get5gRouterDevices, {
            payload: params?.payload?.routers?.request,
            planType: "primaryPlan",
            receiver: params?.payload?.receiver,
            pageLoad:true,
          })
        );
      } else {
        forks.push(call(getPlanPreselection, { payload: params?.payload }));
      }
    }
    yield all([...forks]);
  } catch (err) {
    console.error(err);
    yield put(Landing5GSlice.setAPIError(true));
  } finally {
    yield put(Landing5GSlice.setInitialLoad(true));
    // yield put(Landing5GSlice.set5GLandingPageLoading(false));
  }
}

function* clearAuth5gCart(params) {
  try {
    yield put(Landing5GSlice.set5GLandingPageLoading(true));
    const requestBody = {
      approach: "",
      flow: "5g",
      shoppingPath: "PROSPECT",
    };

    yield apiClient.post(API.CLEAR_4G5G_CART_URL, requestBody);
    setLocalCookie("SMB_PROSPECT_CARTQTY", "", 1);
    yield put(Landing5GSlice.reset5GState());
  } catch (err) {
    // error handling
    yield put(Landing5GSlice.set5GLandingPageLoading(false));
  }
}

function* addToCartFromAuth() {
  const globalData = yield select(selectors.globalData);
  const sessionCartData =
    globalData?.sessionCartData?.apiResponse?.prospectFlowData;
  yield put(Landing5GSlice.set5GLandingPageLoading(true));
  try {
    const fiveGData = yield select(selectors.fiveGData);
    const commonPlan = fiveGData?.primaryPlan;
    const isByor = commonPlan?.selectedRouter === "byor";
    let operationVal = "ADD";

    const device =
      isByor && fiveGData?.byor?.validateDeviceIdResp?.deviceInfo
        ? fiveGData?.byor?.validateDeviceIdResp?.deviceInfo
        : commonPlan?.routerDetails?.deviceList?.length &&
          commonPlan?.routerDetails?.deviceList[0];
    const simNumber =
      fiveGData?.byor?.validateSimIdResp?.simValidationInfo?.simNumber;

    const reqDevice = {
      deviceSku: isByor
        ? device?.deviceSkuId || device?.skulist[0]?.sku
        : device?.sku || device?.skulist[0]?.sku,
      deviceCategory: device?.category,
      quantity: 1,
      deviceType: "FIVEGCBANDROUTER",
      priceType:
        commonPlan?.selectedRouterPayment === "zeroYear" || isByor
          ? "RETAIL"
          : "MONTH_TO_MONTH",
      ...(isByor && { installmentTerm: 0 }),
      ...(!isByor && {
        installmentTerm:
          device?.nofInstallements ||
          device?.skulist[0]?.pricingDetails?.devicePayments[0]
            ?.nofInstallements,
        seoUrlName: device?.seoUrlName,
      }),
      byod: !!isByor,
      bringOwnReceiver: device?.bringOwnReceiver,
      ...(isByor && {
        byodInfo: {
          imei: device?.imei,
          name: device?.name,
          simSku: device?.simSku,
          psimCapable: device?.psimCapable,
          esimCapable: device?.esimCapable,
          newSim: !simNumber,
          simNumber: simNumber,
        },
      }),
    };

    const requestBody = {
      cartMeta: {
        zipCode: sessionCartData?.prospect5gLoopQualAddress?.zipCode || "",
        shoppingPath: "PROSPECT",
        flow: "5g",
      },
      lines: [
        {
          packageId: "",
          packageType: "FIVEG",
          operation: operationVal,
          quantity: 1,
          plan: {
            planId: commonPlan?.selectedPlan,
            planCategoryType: "CBAND",
          },
          byor: isByor,
          device: reqDevice,
        },
      ],
    };
    const isMixedCart = yield select(state=>state?.GlobalData?.sessionCartData?.isMixedCart)
    if(isMixedCart) requestBody = {...requestBody,"allowMixedCart":true}
    yield call(clearAuth5gCart);
    const response = yield apiClient.post(API.MOBILITY_ADD_TO_CART, requestBody);
    let sessionCartResponse = {
      prospectFlowData: response?.data?.response,
      serviceStatus: response?.data?.status,
    };

    if (
      response &&
      response?.data?.status?.success &&
      response?.data?.response
    ) {
      const newStore = Utils.setStoreFromRetrieve(sessionCartResponse);
      yield put(GlobalSlice.setSessionCartData(newStore));
      yield put(Landing5GSlice.setAuthCartSuccess(true));
      window?.localStorage?.setItem("_cartData", JSON.stringify(newStore));
    } else if(response &&
      response?.data?.status?.statusMessage === "PACKAGE_INCOMPATIBLE") {
        yield put(Landing5GSlice.setpackageCompatible(true));
    }else {
      throw Object.assign(
        new Error("Failed to update plan selection in session cart"),
        { status: response?.status, data: response?.data }
      );
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(Landing5GSlice.set5GLandingPageLoading(false));
  }
}
function* updateFCCTemplate() {
  
    // yield put(Landing5GSlice.setAPIError(false));
    yield put(GlobalSlice.setBbloadError(false));
    // yield put(Landing5GSlice.set5GLandingPageLoading(true));

  try {
    const resp = yield apiClient.post(API.FCC_FWA_TEMPLATE_URL);
    // const resp = mockFCCTemplate
    if(resp?.data?.data && resp?.data?.serviceStatus?.success){
      const fccTemplate = resp?.data?.data;
      var parser = new DOMParser();
      var DOM = parser.parseFromString(fccTemplate, "text/html");
      DOM = DOM.querySelector(".fcc-bl-main-container").outerHTML;
      // setHtmlTextTemplate(DOM)
      yield put(Landing5GSlice.sethtmlTemplateFcc(DOM))
    }else{
      yield put(GlobalSlice.setBbloadError(true));
    }
    
  } catch (err) {
    yield put(GlobalSlice.setBbloadError(true));
    // yield put(Landing5GSlice.setAPIError(true));
  } finally {
      // yield put(Landing5GSlice.set5GLandingPageLoading(false));
  }
}
function* updateFCCJson(testPlan) {
 
    // yield put(Landing5GSlice.setAPIError(false));
    yield put(GlobalSlice.setBbloadError(false));
    // yield put(Landing5GSlice.set5GLandingPageLoading(true));

  try {

    const resp = yield apiClient.post(API.FCC_FWA_JSON_URL);
      // const resp = mockFCCData;
    let obj=[];
    
    if(resp?.data && resp?.data?.serviceStatus?.success){
      for(let i of testPlan?.payload){
       if(resp?.data?.data?.responseFor && resp?.data?.data?.responseFor == "FWA" && resp?.data?.data?.planDetails && resp?.data?.data?.planDetails[i] && Array.isArray(resp?.data?.data?.planDetails[i]) && resp?.data?.data?.planDetails[i].length > 0){
          if(resp?.data?.data?.planDetails && Object.keys(resp?.data?.data?.planDetails).length >0){
            // setPlanDataFwa(resp.planDetails[testPlan]);
            obj.push(resp?.data?.data?.planDetails[i])
          }  
        }
      }
      yield put(Landing5GSlice.setPlansDetailsFcc(obj))
    }else{
      yield put(GlobalSlice.setBbloadError(true));
    }
    if((testPlan?.payload.length != obj?.length) && obj?.length > 0){
      yield put(GlobalSlice.setContainerHeightBB("32.5"));
    }

  } catch (err) {
    yield put(GlobalSlice.setBbloadError(true));
    // yield put(Landing5GSlice.setAPIError(true));
  } finally {
    yield put(Landing5GSlice.set5GLandingPageLoading(false));
  }
}
function* storeFccDetails(params) {

  try {

    const resp = yield apiClient.post(API.FCC_STORE_DETAILS,params?.payload);

  } catch (err) {
    
  } finally {
  
  }
}

function* updateFCCJsonNew(testPlan) {

  // yield put(Landing4GSlice.setAPIError(false));
    yield put(GlobalSlice.setBbloadError(false));
  yield put(Landing5GSlice.set5GLandingPageLoading(true));

try {

  const resp = yield apiClient.post(API.FCC_FWA_JSON_URL);
  //const resp = mockFCCData;
  
  if(resp?.data && resp?.data?.serviceStatus?.success){
    // for(let i of testPlan?.payload){
    //   if(resp?.data?.data?.responseFor && resp?.data?.data?.responseFor == "FWA" && resp?.data?.data?.planDetails && resp?.data?.data?.planDetails[i] && Array.isArray(resp?.data?.data?.planDetails[i]) && resp?.data?.data?.planDetails[i].length > 0){
    //     if(resp?.data?.data?.planDetails && Object.keys(resp?.data?.data?.planDetails).length >0){
    //       // setPlanDataFwa(resp.planDetails[testPlan]);
    //       obj.push(resp?.data?.data?.planDetails[i])
    //     }  
    //   }
    // }
    if(resp?.data?.data?.responseFor && resp?.data?.data?.responseFor == "FWA" && resp?.data?.data?.planDetails && Object.keys(resp?.data?.data?.planDetails).length >0){
      yield put(Landing5GSlice.setPlansDetailsFccNewObj(resp?.data?.data?.planDetails))
    }
  }else{
    yield put(GlobalSlice.setBbloadError(true));
  }
  // if(resp?.data?.data?.planDetails && Object.keys(resp?.data?.data?.planDetails)?.length >0){
  //   yield put(GlobalSlice.setContainerHeightBB("32.5"));
  // }
} catch (err) {
  yield put(GlobalSlice.setBbloadError(true));
  // yield put(Landing4GSlice.setAPIError(true));
} finally {
 
    // yield put(Landing5GSlice.set5GLandingPageLoading(false));
}
}

export default function* actionWatcher() {
  yield takeLatest(Landing5GSlice.get5gRouterDevices.type, get5gRouterDevices);
  yield takeLatest(Landing5GSlice.get5gPlans.type, getPlans);
  yield takeLatest(Landing5GSlice.get5gTabPlans.type, get5gTabPlans);
  yield takeLatest(Landing5GSlice.getTaxesCharges.type, getTaxcharges);
  yield takeLatest(Landing5GSlice.update5GPlan.type, update5gPlans);
  yield takeLatest(Landing5GSlice.update5GRouter.type, update5GRouter);
  yield takeLatest(Landing5GSlice.update5GADDORUpdate.type, update5GADDORUpdate);
  yield takeLatest(Landing5GSlice.validateDeviceId.type, validateDeviceId);
  yield takeLatest(Landing5GSlice.validateSimId.type, validateSimId);
  yield takeLatest(Landing5GSlice.get5GPageLoadData, get5GPageLoadData);
  yield takeLatest(Landing5GSlice.addToCartFromAuth, addToCartFromAuth);
  yield takeLatest(
    Landing5GSlice.update5GPlanAndFetchRouters.type,
    update5GPlanAndFetchRouters
  );
  yield takeLatest(Landing5GSlice.updateFCCTemplate, updateFCCTemplate);
  yield takeLatest(Landing5GSlice.updateFCCJson, updateFCCJson);
  yield takeLatest(Landing5GSlice.storeFccDetails, storeFccDetails);
  yield takeLatest(Landing5GSlice.updateFCCJsonNew, updateFCCJsonNew);
  yield takeLatest(Landing5GSlice.getComibnedPlanAndRouters, getComibnedPlanAndRouters);
  yield takeLatest(Landing5GSlice.getPlandAndRouters, getPlandAndRouters);
  yield takeLatest(Landing5GSlice.clearCartAndAddToCart, clearCartAndAddToCart);

}
