import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TextLink } from "@vds3/buttons";
import { UnorderedList, ListItem } from "@vds3/lists";
import { Modal, ModalTitle, ModalBody, ModalFooter } from "@vds3/modals";
import { Title, Body } from "@vds3/typography";
import AccountLevelTile from "./AccountLevelTile";
import { MarginSpacerS, MarginSpacerXS } from "common/components/styleTag";
import styled from "styled-components";

const AccountLevelPromoTile = ({ feature }) => {
  const { t } = useTranslation(["shoppingCart"]);
  const [showModal, setShowModal] = useState(false);

  const jointOfferAem = t("shopping-cart.joint-offer", { returnObjects: true });
  const isJointOffer =
    jointOfferAem?.spoId?.length &&
    jointOfferAem.spoId.includes(feature?.visFeatureCode);
  const hasModal = isJointOffer && jointOfferAem?.detailsModal;
  const title = isJointOffer ? jointOfferAem?.title : "Congrats!!!";
  const description = isJointOffer
    ? jointOfferAem?.description
    : feature?.featureDesc;
  const linkText = hasModal ? jointOfferAem?.detailsModal?.linkText : "";
  const modalTitle = hasModal ? jointOfferAem?.detailsModal?.modalHeading : "";
  const modalContent = hasModal
    ? jointOfferAem?.detailsModal?.modalContent
    : [];

  const offerPrice = useMemo(() => {
    if (isJointOffer && jointOfferAem?.[feature?.visFeatureCode]) {
      return jointOfferAem?.[feature?.visFeatureCode]?.price;
    }
    return feature?.featurePrice < 0
      ? feature.featurePrice * 1
      : feature.featurePrice;
  }, [feature]);

  return (
    <>
      <AccountLevelTile
        eyebrowText={t("shopping-cart.cart.accountLevelPromotion")}
        isShowPrice={!isJointOffer}
        price={
          feature?.featurePrice < 0
            ? feature.featurePrice * 1
            : feature.featurePrice
        }
        content={
          <div style={{ maxWidth: "530px" }}>
            <Title size="small" bold>
              {title?.replace("{{offerPrice}}", Math.abs(offerPrice))}
            </Title>
            <MarginSpacerS />
            <Body size="small" primitive="div">
              {description}
              {!!hasModal && (
                <>
                  {" "}
                  <MarginSpacerS />
                  <TextLink type="inline" onClick={() => setShowModal(true)}>
                    {linkText}
                  </TextLink>
                  <MarginSpacerXS />
                </>
              )}
            </Body>
          </div>
        }
      />
      {!!showModal && (
        <Modal opened={showModal} onOpenedChange={setShowModal}>
          <StyledModalTitle>
            <ModalTitle ModalTitle>{modalTitle}</ModalTitle>
          </StyledModalTitle>
          <ModalBody>
            {(modalContent?.length > 1) ? (
              <UnorderedList>
                {modalContent?.map((item) => (
                  <ListItem>{item}</ListItem>
                ))}
              </UnorderedList>
            ) : (
              <Body size="large">{modalContent?.[0]}</Body>
            )}
          </ModalBody>
          <FooterContainer>
            <ModalFooter 
              buttonData={{
                primary: {
                  children: jointOfferAem?.footerButton,
                  onClick: setShowModal,
                },
                close: null,
              }}
            />
          </FooterContainer>
        </Modal>
      )}
    </>
  );
};

const FooterContainer = styled.div`
  @media only screen and (max-width: 770px) {
    max-width: 100%;
  }
  max-width: 160px;
`;

const StyledModalTitle = styled.div`
  @media only screen and (min-width: 700px) {
    > div {
      padding-right: 0 !important;
    }
  }
`;

export default AccountLevelPromoTile;
