import {
  put,
  takeLatest,
  select,
  call,
  fork,
  join,
  all,
} from "redux-saga/effects";
import * as API from "containers/APIurls";
import apiClient from "utils/apiClient";
import * as Landing4GSlice from "./4GSlice";
import * as Landing5GSlice from "pages/Landing5G-V2/ducks/5GSlice";
import * as GlobalSlice from "app/ducks/GlobalSlice";
import * as selectors from "./selectors";
import Utils from "app/ducks/utils";
import { setLocalCookie } from "utils/commonUtils";
import { getSharedCartSave } from "pages/Mobility/ducks/sagas";
import { isFromAgentLinkDealsPage } from "pages/Landing5G-V2/ducks/helper";
// import { setLocalCookie } from "common/components/unifiedCart/utils";
// import { getSharedCartSave } from "pages/Mobility/ducks/sagas";
// import { mockFCCTemplate } from "pages/Landing5G/ducks/api/FCCTemplate";
// import { mockFCCData } from "pages/Landing5G/ducks/api/FCCdata";
// import { getRetrieveCart5GInfo } from "pages/Checkout/ducks/sagas";
// import { isFromAgentLinkDealsPage } from "pages/Landing5G/ducks/helper";


function* getComibnedPlanAndRouters(params) {
  
  yield put(Landing4GSlice.setAPIError(false));
  yield put(Landing4GSlice.set4GLandingPageLoading(true));
  try{
    const globalData = yield select(selectors.globalData);
  const lq = params?.payload;
  const internetAndMobile = (lq?.internetAndMobile || lq?.plans?.request?.internetAndMobile) ?? false;
  const planId = lq?.plans?.request?.planId || null;
  const requestBody = {
    cartMeta: {
      shoppingPath: "PROSPECT",
      flow: "4g",
      zipCode:
        lq?.loopQualAddressResponse?.response?.prospect5gLoopQualAddress
          ?.zipCode,
      qualified4GHome: lq?.isLteBiPlus,
      ecpdId:globalData?.widgetInputParams?.ecpdId?.value ?? "",
      userId : globalData?.widgetInputParams?.mbLoginId?.value ?? globalData?.widgetInputParams?.loginUserId?.value ?? globalData?.widgetInputParams?.mbaUserId?.value ?? "",
      rootEcpdId: globalData?.widgetInputParams?.ecpdId?.value ?? "",
      clientId: globalData?.widgetInputParams?.clientName?.value ?? ""
    },
    lines: [
      {
        packageType: "FOURG",
        plan: {
          planCategoryType: "4G",
          planId
        }
      }
    ]
  };
  const resp = yield apiClient.post(API.GET_COMBINED_PLANROUTER_URL, requestBody);
  if(resp?.status === 200) {
    if(internetAndMobile) {
      window?.localStorage?.setItem("_4gPlansInternetAndMobile", JSON.stringify(resp?.data?.plans?.internet));
    } else {
      window?.localStorage?.setItem("_4gPlansInternetOnly", JSON.stringify(resp?.data));
    }
  }
if(resp?.data){
  yield put(Landing4GSlice.setCombinedResponse(resp?.data));
  yield (call(getPlandAndRouters, {paylod: {...resp?.data, internetAndMobile}}));
  // yield put(Landing4GSlice.set4GLandingPageLoading(false));
  }
  }
  catch(err){
    yield put(Landing4GSlice.setAPIError(false));
  } finally {
    yield put(Landing4GSlice.set4GLandingPageLoading(false));
  }
}

function* getPlandAndRouters(params) {
  const combinePlandRoutersData = params?.paylod ?? params?.payload;
  let planDetails = combinePlandRoutersData?.plans?.internet;
  // let cappedPlans =''
  if(combinePlandRoutersData?.internetAndMobile){
    planDetails = combinePlandRoutersData?.plans?.internetAndMobile
  }
  // if(!combinePlandRoutersData?.internetAndMobile &&  !planDetails.cappedPlans && planDetails?.plans) {
  //   cappedPlans = {...planDetails, cappedPlans: Object.keys(planDetails?.plans)}
  // } else if(combinePlandRoutersData?.internetAndMobile && !planDetails.cappedPlans && planDetails?.plans) {
  //   cappedPlans = {...planDetails, cappedPlans: Object.keys(planDetails?.plans)}
  // }
  const routerDetails = combinePlandRoutersData?.routerDevices?.deviceList;
  yield put(Landing4GSlice.setPlansDetails(planDetails));
  let preSelectedPlanId = '';
  for(const key in planDetails.plans) {
    if(planDetails.plans[key].preSelected) {
      preSelectedPlanId = key;
      break
    }
  }
  let preSelectedRouterSku = '';
  if(routerDetails) {
    for(const router of routerDetails) {
      if(router.skulist && router.skulist[0]?.preSelected) {
        preSelectedRouterSku = router.skulist[0].sku
        break
      }
    }
  }

  yield put(Landing4GSlice.setSelectedPlan({ planType: "primaryPlan", value: preSelectedPlanId }));
  yield put(
    Landing4GSlice.setRouterDeviceResp({
      planType: "primaryPlan",
      response: combinePlandRoutersData?.routerDevices,
    })
  );

  yield put(Landing4GSlice.setPlanPreselection({planId: preSelectedPlanId, routerSku: preSelectedRouterSku}));

}

function* getPlans(params) {
  
  yield put(Landing4GSlice.setAPIError(false));
  yield put(Landing4GSlice.set4GLandingPageLoading(true));
  try{
    const globalData = yield select(selectors.globalData);
  const lq = params?.payload;
  const internetAndMobile = lq?.internetAndMobile ?? false;

  const requestBody = {
    cartMeta: {
      shoppingPath: "PROSPECT",
      flow: "4g",
      zipCode:
        lq?.loopQualAddressResponse?.response?.prospect5gLoopQualAddress
          ?.zipCode,
      qualified4GHome: lq?.isLteBiPlus,
    },
    internetAndMobile: internetAndMobile
  };

  let ltePlansInternetAndMobile;
  let ltePlansInternetOnly;
  if(internetAndMobile) {
    ltePlansInternetAndMobile = window?.localStorage?.getItem("_4gPlansInternetAndMobile");
  } else {
    ltePlansInternetOnly = window?.localStorage?.getItem("_4gPlansInternetOnly");
  }

  let resp = {};
  if(internetAndMobile && ltePlansInternetAndMobile) {
    resp = JSON.parse(ltePlansInternetAndMobile);
  } else if(!internetAndMobile && ltePlansInternetOnly) {
    resp = JSON.parse(ltePlansInternetOnly);
  } else {
    resp = yield apiClient.post(API.GET_4G_PLAN, requestBody);
    if(resp?.status === 200) {
      if(internetAndMobile) {
        window?.localStorage?.setItem("_4gPlansInternetAndMobile", JSON.stringify(resp));
      } else {
        window?.localStorage?.setItem("_4gPlansInternetOnly", JSON.stringify(resp));
      }
    }
  }

  yield put(Landing4GSlice.setPlansDetails(resp?.data));
  const prospect5gLoopQualAddress =
        globalData?.sessionCartData?.apiResponse?.prospectFlowData?.prospect5gLoopQualAddress;
  if(window?.mbtGlobal?.flags?.ENABLE_FCC_BROADBAND_FACTS === 'Y' && prospect5gLoopQualAddress){
    const payload = {
      planIds : resp?.data?.plans,
      address : prospect5gLoopQualAddress
    }
    //yield (call(getTaxcharges, {payload}));
  }

  }
  catch(err){
    yield put(Landing4GSlice.setAPIError(false));
  } finally {
    yield put(Landing4GSlice.set4GLandingPageLoading(false));
  }
}

function* get4gTabPlans(params) {
  
  yield put(Landing4GSlice.setAPIError(false));
  yield put(Landing4GSlice.set4GLandingPageLoading(true));
  try{
    const globalData = yield select(selectors.globalData);
  const lq = params?.payload;
  const internetAndMobile = lq?.internetAndMobile ?? false;

  const requestBody = {
    cartMeta: {
      shoppingPath: "PROSPECT",
      flow: "4g",
      zipCode:
        lq?.loopQualAddressResponse?.response?.prospect5gLoopQualAddress
          ?.zipCode,
      qualified4GHome: lq?.isLteBiPlus,
    },
    internetAndMobile: internetAndMobile
  };

  let ltePlansInternetAndMobile;
  let ltePlansInternetOnly;
  if(internetAndMobile) {
    ltePlansInternetAndMobile = window?.localStorage?.getItem("_4gPlansInternetAndMobile");
  } else {
    ltePlansInternetOnly = window?.localStorage?.getItem("_4gPlansInternetOnly");
  }

  let resp = {};
  if(internetAndMobile && ltePlansInternetAndMobile) {
    resp = JSON.parse(ltePlansInternetAndMobile);
  } else if(!internetAndMobile && ltePlansInternetOnly) {
    resp = JSON.parse(ltePlansInternetOnly);
  } else {
    resp = yield apiClient.post(API.GET_4G_PLAN, requestBody);
    if(resp?.status === 200) {
      if(internetAndMobile) {
        window?.localStorage?.setItem("_4gPlansInternetAndMobile", JSON.stringify(resp));
      } else {
        window?.localStorage?.setItem("_4gPlansInternetOnly", JSON.stringify(resp));
      }
    }
  }

  yield put(Landing4GSlice.setPlansDetails(resp?.data));
  // const prospect5gLoopQualAddress =
  //       globalData?.sessionCartData?.apiResponse?.prospectFlowData?.prospect5gLoopQualAddress;
  // if(window?.mbtGlobal?.flags?.ENABLE_FCC_BROADBAND_FACTS === 'Y' && prospect5gLoopQualAddress){
  //   const payload = {
  //     planIds : resp?.data?.plans,
  //     address : prospect5gLoopQualAddress
  //   }
  //   yield (call(getTaxcharges, {payload}));
  // }

  }
  catch(err){
    yield put(Landing4GSlice.setAPIError(false));
  } finally {
    yield put(Landing4GSlice.set4GLandingPageLoading(false));
  }
}

function* getTaxcharges(params) {
  yield put(Landing4GSlice.setAPIError(false));
  // yield put(Landing4GSlice.set4GLandingPageLoading(true));
  try {
      const globalData = yield select(selectors.globalData);
      const planIds= params?.payload?.isAddons ? params?.payload?.planIds : Object.keys(params?.payload?.planIds);
      const flow = globalData?.sessionCartData?.apiResponse?.prospectFlowData?.prospect5gLoopQualAddress?.flow;
      const address = {
            addressLine1: params?.payload?.address?.addressLine1,
            city: params?.payload?.address?.city,
            state: params?.payload?.address?.state,
            zipCode: params?.payload?.address?.zipCode,
            zipPlus4: params?.payload?.address?.zipPlus4,
            unit: params?.payload?.address?.unit
      }

      const requestBody = {
        planIds,
        address,
        flow,
      }
       const resp = yield apiClient.post(API.GET_TAX_CHARGES, requestBody);
       yield put(Landing4GSlice.setTaxesCharges(resp?.data));
       let taxChangesObj = {};
       if(resp?.data && resp?.data?.offerTaxDetails){
        for(let i =0 ; i < Object.keys(resp?.data?.offerTaxDetails).length; i++){
          const planId = Object.keys(resp?.data.offerTaxDetails)[i];
          if(resp?.data.offerTaxDetails[planId]){
             const arrMap = resp?.data?.offerTaxDetails[planId]?.map(taxItem =>taxItem.geoCode);
             if(Array.isArray(arrMap) && arrMap?.length > 0){
               const geoCode = arrMap[0];
               address.geoCode = geoCode
               break;
             }
          }
        }
        
        for(let i =0 ; i < Object.keys(resp?.data?.offerTaxDetails).length; i++){
            const planId = Object.keys(resp?.data?.offerTaxDetails)[i];
            taxChangesObj[planId] = resp?.data?.offerTaxDetails[planId].map((value)=>{
                return  {
                    label :  value.taxDescription,
                    value :  `$${(Math.round(value.taxAmount* 100) / 100).toFixed(2)}`,
                }
            })
        }

       }
       
       yield put(Landing4GSlice.setStoreFCCDetails({address,taxDetails:taxChangesObj}));
           
   }
    catch (err) {
      yield put(Landing4GSlice.setAPIError(true));
    } finally {
      // yield put(Landing4GSlice.set4GLandingPageLoading(false));
    }
}

function* update4gPlans(params) {
  const { isParallelCall } = params?.payload || {};
  if (!isParallelCall) {
    yield put(Landing4GSlice.setAPIError(false));
    yield put(Landing4GSlice.set4GLandingPageLoading(true));
  }
  try {
    const globalData = yield select(selectors.globalData);
    const sessionCartData =
      globalData?.sessionCartData?.apiResponse?.prospectFlowData;
    const fourGData = yield select(selectors.fourGData);
    let selectedPlan = {};
    let requestBody = {};
    let planId;
    let operationVal = "ADD";
    let packageId = "";
    let groupId = params?.payload?.groupId;
    const { planType = "primaryPlan" } = params?.payload || {};
    const common4gData =
      planType === "primaryPlan"
        ? fourGData?.primaryPlan
        : fourGData?.backupPlan;
    const isBackupPlan = planType === "backupPlan" ? true : false;
    let has4GPackage = false;
    if (sessionCartData?.cart?.packages) {
      has4GPackage = Object.keys(sessionCartData?.cart?.packages)?.filter(
        (packageKey) =>
          sessionCartData?.cart?.packages[packageKey].packageType === "FOURG"
      )[0];
    }
    if (params.payload && params?.payload?.plansResp) {
      const { plansResp, plan, operation = "ADD" } = params?.payload || {};
      planId = plansResp?.plans[plan].billingCode;
      operationVal =
        fourGData?.primaryPlan?.selectedPlan ||
        fourGData?.primaryPlan?.selectedRouter === "byor" ||
        (fourGData?.primaryPlan?.selectedRouter &&
          fourGData?.primaryPlan?.selectedRouterPayment) ||
        fourGData?.backupPlan?.selectedPlan ||
        fourGData?.backupPlan?.selectedRouter === "byor" ||
        (fourGData?.backupPlan?.selectedRouter &&
          fourGData?.backupPlan?.selectedRouterPayment) ||
        has4GPackage
          ? "UPDATEBULKQTY"
          : "ADD";
      if (operation === "UPDATE") {
        if (sessionCartData?.cart?.packages?.length) {
          packageId = Object.keys(sessionCartData?.cart?.packages)[0];
          operationVal = "UPDATEBULKQTY";
        } else {
          operationVal = "ADD";
        }
      }
      if (operation === "UPDATE" && params?.payload?.quantity) {
        operationVal = "UPDATEBULKQTY";
      }
      if (
        operation === "ADD" &&
        params?.payload?.quantity &&
        params?.payload?.quantity > 1
      ) {
        operationVal = "UPDATEBULKQTY";
      }
      if (operationVal === "UPDATEBULKQTY" && groupId === "") {
        const checkPackages = sessionCartData?.cart?.packages && Object.values(sessionCartData?.cart?.packages)?.filter((d) => d.packageType === "FOURG");
        if(checkPackages?.length >0){
          groupId=checkPackages[0]?.groupId ?? "";
        } else {
          groupId = null
        }
      }
    }

    requestBody = {
      cartMeta: {
        zipCode: sessionCartData?.prospect5gLoopQualAddress?.zipCode || "",
        shoppingPath: "PROSPECT",
        flow: "4g",
      },
      lines: [
        {
          packageId: "",
          packageType: "FOURG",
          operation: operationVal,
          ...(operationVal === "UPDATEBULKQTY" && {groupId: groupId}),
          plan: {
            planId: planId,
          },
          quantity: params?.payload?.quantity || common4gData?.quantity,
          backUp: isBackupPlan,
        },
      ],
      fwaBundleSelected: params?.payload?.fwaBundleSelected
    };
    const isMixedCart = yield select(state=>state?.GlobalData?.sessionCartData?.isMixedCart)
    if(isMixedCart) requestBody = {...requestBody,"allowMixedCart":true}
    const isFromMyLinkDealsPage = yield select(state=>state?.GlobalData?.fromMyLinkDealsPage)
    if(isFromMyLinkDealsPage) {
      const repDetails = yield select(state=>state?.GlobalData?.repDetails)
      requestBody = {
        ...requestBody,
        fromMyLinkDealsPage: isFromMyLinkDealsPage,
        salesRepDetails: {
          cmpCode: repDetails?.channel,
          locationCode: repDetails?.location,
          repId: repDetails?.ecode,
          repSSO: repDetails?.uswin,
          emailId:repDetails?.email
        }
      }
    }
    const isFromAgentLinkPage = yield select(state=>state?.GlobalData?.fromAgentLinkDealsPage)
    if(isFromAgentLinkPage)
      {
        requestBody = yield call(isFromAgentLinkDealsPage,requestBody);
      } 
    const response = yield apiClient.post(API.MOBILITY_ADD_TO_CART, requestBody);
    let sessionCartResponse = {
      prospectFlowData: response?.data?.response,
      serviceStatus: response?.data?.status,
    };
    if (
      response &&
      response?.data?.status?.success &&
      response?.data?.response
    ) {
      yield call(getSharedCartSave, {
        payload: { shoppingPath: "PROSPECT", fromUCSubmitOrder: false },
      });
      const { planType } = params?.payload || {};
      const newStore = Utils.setStoreFromRetrieve(sessionCartResponse);
      yield put(GlobalSlice.setSessionCartData(newStore));
      yield put(Landing4GSlice.setSelectedPlan({ planType, value: planId }));
      window?.localStorage?.setItem("_cartData", JSON.stringify(newStore));
    } else if(response &&
      response?.data?.status?.statusMessage === "PACKAGE_INCOMPATIBLE") {
        yield put(Landing4GSlice.setpackageCompatible(true));
    } else
      throw Object.assign(
        new Error("Failed to update plan selection in session cart"),
        { status: response?.status, data: response?.data }
      );

    if (!isParallelCall) {
      if (params?.payload) {
        const { plan } = params?.payload;
        yield call(get4gRouterDevices, {
          planType: !isBackupPlan ? "primaryPlan" : "backupPlan",
          selectedPlan: params?.payload?.plansResp?.plans[plan],
        });
      } else {
        yield call(get4gRouterDevices, {
          planType: !isBackupPlan ? "primaryPlan" : "backupPlan",
          selectedPlan,
        });
      }
    }
  } catch (err) {
    yield put(Landing4GSlice.setAPIError(true));
  } finally {
    // if (!isParallelCall) {
      yield put(Landing4GSlice.set4GLandingPageLoading(false));
    // }
  }
}

function* get4gRouterDevices(params) {
  const { isParallelCall } = params?.payload || {};
  if (!isParallelCall) {
    yield put(Landing4GSlice.setAPIError(false));
    // yield put(Landing4GSlice.set4GLandingPageLoading(true));
  }
  try {
    const pricePlanCode =
      params?.payload?.pricePlanCode ||
      params?.payload?.selectedPlan?.billingCode;
    const requestBody = {
      //category: "4g",
      cartMeta: {
        shoppingPath: "PROSPECT",
        flow: "4g",
      },
      planInfo: {
        planCode: pricePlanCode ?? params?.selectedPlan?.billingCode,
        planPrice:
          params?.selectedPlan?.monthlyFee ||
          params?.payload?.selectedPlan?.monthlyFee,
        cband:
          params?.selectedPlan?.cband || params?.payload?.selectedPlan?.cband,
      },
    };
    const resp = yield apiClient.post(API.GET_4G_ROUTER_DEVICES, requestBody);
    yield put(
      Landing4GSlice.setRouterDeviceResp({
        planType: params?.planType ?? params?.payload?.planType,
        response: resp?.data,
      })
    );
  } catch (err) {
    yield put(Landing4GSlice.setAPIError(true));
  } finally {
    if (!(isParallelCall || params?.isParallelCall)) {
      yield put(Landing4GSlice.set4GLandingPageLoading(false));
    }
  }
}

function* update4GPlanAndFetchRouters(params) {
  yield put(Landing4GSlice.setAPIError(false));
  yield put(Landing4GSlice.set4GLandingPageLoading(true));
  try {
    const { plan, planType = "primaryPlan" } = params?.payload || {};
    const isBackupPlan = planType === "backupPlan" ? true : false;

    const forks = [];
    if (params?.payload) {
      forks.push(
        yield fork(get4gRouterDevices, {
          payload: {
            planType: !isBackupPlan ? "primaryPlan" : "backupPlan",
            selectedPlan: params?.payload?.plansResp?.plans[plan],
            isParallelCall: true,
          },
        })
      );
      forks.push(
        yield fork(update4gPlans, {
          payload: { ...params?.payload, isParallelCall: true },
        })
      );
    }
    yield join([...forks]);
  } catch (err) {
    console.error(err);
    yield put(Landing4GSlice.setAPIError(true));
  } finally {
    yield put(Landing4GSlice.set4GLandingPageLoading(false));
  }
}

function* update4GRouter(params) {
  yield put(Landing4GSlice.setAPIError(false));
  yield put(Landing4GSlice.set4GLandingPageLoading(true));
  const globalData = yield select(selectors.globalData);
  const sessionCartData =
    globalData?.sessionCartData?.apiResponse?.prospectFlowData;
  try {
    const {
      flow,
      deviceInfo,
      pricingType,
      byor,
      newSim,
      simNumber,
      planType = "primaryPlan",
      routerDetails,
      routerSelected,
      fwaBundleSelected
    } = params.payload;

    const isBackupPlan = planType === "backupPlan" ? true : false;
    let packages = {},
      device = {},
      pricingDetails = {},
      key = "",
      operationId = "UPDATEBULKQTY";
    if (byor) {
      device = { ...deviceInfo };
      key = device?.deviceSkuId;
    } else {
      key = deviceInfo?.skulist[0]?.sku;
      const pricingTypeSplit = pricingType?.split("-");
      if (pricingTypeSplit?.length > 1) {
        pricingDetails =
          deviceInfo?.skulist[0].pricingDetails[pricingTypeSplit[0]][
            pricingTypeSplit[1]
          ];
      } else {
        pricingDetails = deviceInfo?.skulist[0].pricingDetails[pricingType];
      }
      device = {
        ...deviceInfo,
        ...deviceInfo?.skulist[0],
        ...pricingDetails,
      };
      delete device.skulist;
      delete device.pricingDetails;
    }
    device.byor = !!byor;
    device.newSim = newSim;
    if (newSim) device.simNumber = simNumber;

    packages[key] = device;

    let packageId;
    if (sessionCartData?.cart?.packages) {
      packageId = Object.keys(sessionCartData?.cart?.packages)?.filter(
        (packageKey) =>
          sessionCartData?.cart?.packages[packageKey].packageType === "FOURG"
      )[0];
    } else {
      // operationId = "ADD";
    }
    let skipIme;
    if(!device.imei){
      skipIme = true;
    }
    let requestBody = {
      cartMeta: {
        zipCode: sessionCartData?.prospect5gLoopQualAddress?.zipCode || "",
        shoppingPath: "PROSPECT",
        flow: flow,
      },
      fwaBundleSelected:fwaBundleSelected,
      lines: [
        {
          packageId: byor ? packageId : "",
          packageType: "FOURG",
          operation: operationId,
          quantity: params?.payload?.quantity,
          byor: byor,
          backUp: isBackupPlan,
          device: {
            // deviceSku: byor
            //   ? device.deviceSkuId ??
            //     (routerDetails?.deviceList?.length &&
            //       routerDetails?.deviceList[0]?.skulist?.length &&
            //       routerDetails?.deviceList[0]?.skulist[0]?.sku)
            //   : device.sku,
            deviceSku: byor && device.deviceSkuId ? device.deviceSkuId : byor && !device.deviceSkuId ? 
            (routerDetails?.deviceList?.length &&
                     routerDetails?.deviceList[0]?.skulist?.length &&
                     routerDetails?.deviceList[0]?.skulist[0]?.sku)
             :  routerSelected ? routerSelected : device.sku,
            // deviceId: device.deviceId,
            deviceCategory: device.category,
            deviceType: "FOURGROUTER",
            quantity: params?.payload?.quantity,
            priceType: pricingType === "zeroYear" ? "RETAIL" : "MONTH_TO_MONTH",
            installmentTerm: device.nofInstallements,
            seoUrlName: device.seoUrlName,
            byod: byor,
            ...(byor && {
              byodInfo: {
                imei: device.imei,
                name: device.name,
                simSku: device.simSku,
                psimCapable: device.psimCapable,
                esimCapable: device.esimCapable,
                newSim: newSim,
                simNumber: simNumber,
                skipImei: skipIme
                
              },
            }),
          },
        },
      ],
    };

    const isMixedCart = yield select(state=>state?.GlobalData?.sessionCartData?.isMixedCart)
    if(isMixedCart) requestBody = {...requestBody,"allowMixedCart":true}
    const isFromMyLinkDealsPage = yield select(state=>state?.GlobalData?.fromMyLinkDealsPage)
    if(isFromMyLinkDealsPage) {
      const repDetails = yield select(state=>state?.GlobalData?.repDetails)
      requestBody = {
        ...requestBody,
        fromMyLinkDealsPage: isFromMyLinkDealsPage,
        salesRepDetails: {
          cmpCode: repDetails?.channel,
          locationCode: repDetails?.location,
          repId: repDetails?.ecode,
          repSSO: repDetails?.uswin,
          emailId:repDetails?.email
        }
      }
    }
    const isFromAgentLinkPage = yield select(state=>state?.GlobalData?.fromAgentLinkDealsPage)
    if(isFromAgentLinkPage)
      {
        requestBody = yield call(isFromAgentLinkDealsPage,requestBody);
      } 
    const response = yield apiClient.post(
      API.MOBILITY_ADD_TO_CART,
      requestBody
    );
    yield put(Landing4GSlice.setRouterUpdateResp(response?.data)); 
    yield put(Landing4GSlice.setAddtoCartResponse(response?.data?.status));
    let sessionCartResponse = {
      prospectFlowData: response?.data?.response,
      serviceStatus: response?.data?.status,
    };
    if (response && response.data && response.data.response) {
      yield call(getSharedCartSave, {
        payload: { shoppingPath: "PROSPECT", fromUCSubmitOrder: false },
      });
      const newStore = Utils.setStoreFromRetrieve(sessionCartResponse);
      yield put(GlobalSlice.setSessionCartData(newStore));
      window?.localStorage?.setItem("_cartData", JSON.stringify(newStore));
      yield put(
        Landing4GSlice.setSelectedRouter({
          planType,
          value: byor ? "byor" : routerSelected,
        })
      );
      yield put(
        Landing4GSlice.setSelectedRouterPayment({
          planType,
          value: pricingType,
        })
      );
    }
    else if(response &&
      response?.data?.status?.statusMessage === "PACKAGE_INCOMPATIBLE") {
        yield put(Landing4GSlice.setpackageCompatible(true));
    }
  } catch (err) {
    yield put(Landing4GSlice.setAPIError(true));
  } finally {
    yield put(Landing4GSlice.set4GLandingPageLoading(false));
  }
}

function* addOrUpdate4GPage(params) {
  yield put(Landing4GSlice.setAPIError(false));
  yield put(Landing4GSlice.set4GLandingPageLoading(true));
  const globalData = yield select(selectors.globalData);
  const sessionCartData =
    globalData?.sessionCartData?.apiResponse?.prospectFlowData;
  try {
    let requestBody =params.payload;
    const isMixedCart = yield select(state=>state?.GlobalData?.sessionCartData?.isMixedCart)
    if(isMixedCart) requestBody = {...requestBody,"allowMixedCart":true}
    const isFromMyLinkDealsPage = yield select(state=>state?.GlobalData?.fromMyLinkDealsPage)
    if(isFromMyLinkDealsPage) {
      const repDetails = yield select(state=>state?.GlobalData?.repDetails)
      requestBody = {
        ...requestBody,
        fromMyLinkDealsPage: isFromMyLinkDealsPage,
        salesRepDetails: {
          cmpCode: repDetails?.channel,
          locationCode: repDetails?.location,
          repId: repDetails?.ecode,
          repSSO: repDetails?.uswin,
          emailId:repDetails?.email
        }
      }
    }
    const isFromAgentLinkPage = yield select(state=>state?.GlobalData?.fromAgentLinkDealsPage)
    if(isFromAgentLinkPage)
      {
        requestBody = yield call(isFromAgentLinkDealsPage,requestBody);
      } 
    const response = yield apiClient.post(
      API.MOBILITY_ADD_TO_CART,
      requestBody
    );
    yield put(Landing4GSlice.setRouterUpdateResp(response?.data)); 
    yield put(Landing4GSlice.setAddtoCartResponse(response?.data?.status));
    let sessionCartResponse = {
      prospectFlowData: response?.data?.response,
      serviceStatus: response?.data?.status,
    };
    if (response && response.data && response.data.response) {
      yield call(getSharedCartSave, {
        payload: { shoppingPath: "PROSPECT", fromUCSubmitOrder: false },
      });
      const newStore = Utils.setStoreFromRetrieve(sessionCartResponse);
      yield put(GlobalSlice.setSessionCartData(newStore));
      window?.localStorage?.setItem("_cartData", JSON.stringify(newStore));
      yield put(
        Landing4GSlice.setSelectedRouter({
          planType,
          value: byor ? "byor" : routerSelected,
        })
      );
      yield put(
        Landing4GSlice.setSelectedRouterPayment({
          planType,
          value: pricingType,
        })
      );
    }
    else if(response &&
      response?.data?.status?.statusMessage === "PACKAGE_INCOMPATIBLE") {
        yield put(Landing4GSlice.setpackageCompatible(true));
    }
  } catch (err) {
    yield put(Landing4GSlice.setAPIError(true));
  } finally {
    yield put(Landing4GSlice.set4GLandingPageLoading(false));
  }
}

function* getPlanPreselection(params) {
  const requestBody = {
    type: "4g",
  };
  const resp = yield apiClient.post(API.GET_PLAN_PRESELECTION, requestBody);
  yield put(Landing4GSlice.setPlanPreselection(resp?.data));
  if (params?.payload?.routers && resp?.data?.serviceStatus?.success) {
    const pricePlanCode =
      resp?.data?.planId && resp?.data?.planId?.split(",")[0];
    yield call(get4gRouterDevices, {
      payload: { planType: "primaryPlan", pricePlanCode,
    },
    });
  }
}

function* get4GPageLoadData(params) {
  yield put(Landing4GSlice.setAPIError(false));
  yield put(Landing4GSlice.setInitialLoad(false));
  yield put(Landing4GSlice.set4GLandingPageLoading(true));
  try {
    const forks = [];
    if (params?.payload?.plans?.enabled) {
      forks.push(call(getPlans, { payload: params?.payload?.plans?.request }));
      // forks.push(call(getComibnedPlanAndRouters, { payload: params?.payload?.plans?.request }));      
    }
    if (params?.payload?.routers?.enabled) {
      if (params?.payload?.routers?.request?.pricePlanCode) {
        forks.push(
          call(get4gRouterDevices, {
            payload: params?.payload?.routers?.request,
            isParallelCall: true,
          })
        );
      } else {
        //forks.push(call(getPlanPreselection, { payload: params?.payload }));
        // forks.push(call(getTaxcharges, { payload: params?.payload?.plans?.request }));

      }
    }
    forks.push(call(getPlanPreselection, { payload: params?.payload }));
    if(params?.payload?.planId){
      forks.push(call(getTaxcharges, { payload: params?.payload?.planId }));
    }
    yield all([...forks]);
  } catch (err) {
    console.error(err);
    yield put(Landing4GSlice.setAPIError(true));
  } finally {
    yield put(Landing4GSlice.setInitialLoad(true));
    yield put(Landing4GSlice.set4GLandingPageLoading(false));
  }
}

function* validateDeviceId(params) {
  yield put(Landing4GSlice.setAPIError(false));
  yield put(Landing4GSlice.set4GLandingPageLoading(true));
  const { deviceId, flow, router } = params.payload;
  try {
    const requestBody = {
      approach: "",
      deviceId: deviceId,
      flow: flow || "4g", // "4g"
      router: router, // true
      shoppingPath: "PROSPECT",
    };
    const resp = yield apiClient.post(API.VALIDATE_DEVICE_ID, requestBody);

    yield put(Landing4GSlice.setValidateDeviceIdResp(resp?.data));
  } catch (err) {
    yield put(Landing4GSlice.setAPIError(true));
  } finally {
    yield put(Landing4GSlice.set4GLandingPageLoading(false));
  }
}

function* validateSimId(params) {
  yield put(Landing4GSlice.setAPIError(true));
  yield put(Landing4GSlice.set4GLandingPageLoading(true));
  const { deviceId, flow, newSim, simNumber } = params.payload;
  try {
    const requestBody = {
      approach: "",
      deviceId: deviceId, 
      flow: flow, 
      newSim: newSim, 
      shoppingPath: "PROSPECT",
      simNumber: simNumber,
    };
    const resp = yield apiClient.post(API.VALIDATE_SIM_ID, requestBody);

    yield put(Landing4GSlice.setValidateSimIdResp(resp?.data));
  } catch (err) {
    yield put(Landing4GSlice.setAPIError(true));
  } finally {
    yield put(Landing4GSlice.set4GLandingPageLoading(false));
  }
}

function* addPlanAndRouter(params) {
  yield put(Landing4GSlice.set4GLandingPageLoading(true));
  const globalData = yield select(selectors.globalData);
  const sessionCartData =
    globalData?.sessionCartData?.apiResponse?.prospectFlowData;
  try {
    const fourGData = yield select(selectors.fourGData);
    const { planType = "primaryPlan" } = params?.payload;
    const common4gData =
      planType === "primaryPlan"
        ? fourGData?.primaryPlan
        : fourGData?.backupPlan;
    const isBackupPlan = planType === "backupPlan" ? true : false;

    const planId = params?.payload?.planId || common4gData?.selectedPlan;
    const isByor =
      params?.payload?.isByor || common4gData?.selectedRouter === "byor";
    let operationVal = "ADD";
    const pricingType =
      params?.payload?.priceType || common4gData?.selectedRouterPayment;
    const qty = params?.payload?.quantity || common4gData?.quantity || 1;

    let device = params?.payload?.device
      ? {
          ...params.payload.device,
          ...params.payload.device.skulist[0],
          ...params.payload.device.skulist[0].pricingDetails[pricingType],
        }
      : common4gData?.routerDetails?.deviceList?.length &&
        common4gData?.routerDetails?.deviceList[0];
    device =
      pricingType === "zeroYear"
        ? { ...device, ...device?.pricingDetails?.zeroYear }
        : { ...device, ...device?.pricingDetails?.devicePayments[0] };

    const reqDevice = {
      deviceSku: isByor ? device.deviceSkuId : device.sku,
      // deviceId: device.deviceId,
      deviceCategory: device.category,
      deviceType: "FOURGROUTER",
      quantity: qty,
      priceType: pricingType === "zeroYear" ? "RETAIL" : "MONTH_TO_MONTH",
      installmentTerm: device.nofInstallements,
      seoUrlName: device.seoUrlName,
      byod: !!isByor,
    };

    let requestBody = {
      cartMeta: {
        zipCode: sessionCartData?.prospect5gLoopQualAddress?.zipCode || "",
        shoppingPath: "PROSPECT",
        flow: "4g",
      },
      lines: [
        {
          packageId: "",
          packageType: "FOURG",
          operation: operationVal,
          quantity: qty,
          plan: {
            planId: planId,
          },
          byor: isByor,
          device: reqDevice,
          backUp: isBackupPlan,
        },
      ],
    };

    const isFromMyLinkDealsPage = yield select(state=>state?.GlobalData?.fromMyLinkDealsPage)
    if(isFromMyLinkDealsPage) {
      const repDetails = yield select(state=>state?.GlobalData?.repDetails)
      requestBody = {
        ...requestBody,
        fromMyLinkDealsPage: isFromMyLinkDealsPage,
        salesRepDetails: {
          cmpCode: repDetails?.channel,
          locationCode: repDetails?.location,
          repId: repDetails?.ecode,
          repSSO: repDetails?.uswin,
          emailId:repDetails?.email
        }
      }
    }
    const isFromAgentLinkPage = yield select(state=>state?.GlobalData?.fromAgentLinkDealsPage)
    if(isFromAgentLinkPage)
      {
        requestBody = yield call(isFromAgentLinkDealsPage,requestBody);
      } 
    const response = yield apiClient.post(API.MOBILITY_ADD_TO_CART, requestBody);
    let sessionCartResponse = {
      prospectFlowData: response?.data?.response,
      serviceStatus: response?.data?.status,
    };

    if (
      response &&
      response?.data?.status?.success &&
      response?.data?.response
    ) {
      const newStore = Utils.setStoreFromRetrieve(sessionCartResponse);
      yield put(GlobalSlice.setSessionCartData(newStore));
      yield put(Landing4GSlice.setSelectedPlan({ planType, value: planId }));
      yield put(
        Landing4GSlice.setSelectedRouter({
          planType,
          value: isByor ? "byor" : "router",
        })
      );
      yield put(
        Landing4GSlice.setSelectedRouterPayment({
          planType,
          value: pricingType,
        })
      );
      window?.localStorage?.setItem("_cartData", JSON.stringify(newStore));
    } else {
      throw Object.assign(
        new Error("Failed to update plan selection in session cart"),
        { status: response?.status, data: response?.data }
      );
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(Landing4GSlice.set4GLandingPageLoading(false));
  }
}

function* clear4g5gCart(params) {
  try {
    yield put(Landing4GSlice.set4GLandingPageLoading(true));
    const requestBody = {
      approach: "",
      flow: params?.payload?.flow || "5g",
      shoppingPath: "PROSPECT",
    };

    const resp = yield apiClient.post(API.CLEAR_4G5G_CART_URL, requestBody);
    yield put(Landing4GSlice.reset4GState());
    yield put(Landing5GSlice.reset5GState());
    setLocalCookie("SMB_PROSPECT_CARTQTY", "", 1);
  } catch (err) {
    // error handling
  } finally {
    yield put(Landing4GSlice.set4GLandingPageLoading(false));
  }
}

function* clearAuth5gCart(params) {
  try {
    yield put(Landing4GSlice.set4GLandingPageLoading(true));
    const requestBody = {
      approach: "",
      flow: "5g",
      shoppingPath: "PROSPECT",
    };

    yield apiClient.post(API.CLEAR_4G5G_CART_URL, requestBody);
    setLocalCookie("SMB_PROSPECT_CARTQTY", "", 1);
    yield put(Landing5GSlice.reset5GState());
  } catch (err) {
    // error handling
  } finally {
    yield put(Landing4GSlice.set4GLandingPageLoading(false));
  }
}

function* addToCartFromAuth(planType) {
  const globalData = yield select(selectors.globalData);
  const sessionCartData =
    globalData?.sessionCartData?.apiResponse?.prospectFlowData;
  yield put(Landing4GSlice.set4GLandingPageLoading(true));
  try {
    const fourGData = yield select(selectors.fourGData);
    const commonPlan =
      planType === "primaryPlan"
        ? fourGData?.primaryPlan
        : fourGData?.backupPlan;
    const isBackupPlan = planType === "backupPlan" ? true : false;
    const isByor = commonPlan?.selectedRouter === "byor";
    let operationVal = "ADD";

    const device =
      isByor && fourGData?.validateDeviceIdResp?.deviceInfo
        ? fourGData?.validateDeviceIdResp?.deviceInfo
        : commonPlan?.routerDetails?.deviceList?.length &&
          commonPlan?.routerDetails?.deviceList[0];

    const reqDevice = {
      deviceSku: isByor
        ? device?.deviceSkuId || device?.skulist[0]?.sku
        : device?.sku || device?.skulist[0]?.sku,
      // deviceId: device.deviceId,
      deviceCategory: device?.category,
      deviceType: "FOURGROUTER",
      quantity: commonPlan?.quantity,
      priceType:
        commonPlan?.selectedRouterPayment === "zeroYear"
          ? "RETAIL"
          : "MONTH_TO_MONTH",
      ...(!isByor && {
        installmentTerm:
          device?.nofInstallements ||
          device?.skulist[0]?.pricingDetails?.devicePayments[0]
            ?.nofInstallements,
        seoUrlName: device?.seoUrlName,
      }),
      byod: !!isByor,
      ...(isByor && {
        byodInfo: {
          imei: device?.imei,
          name: device?.name,
          simSku: device?.simSku,
          psimCapable: device?.psimCapable,
          esimCapable: device?.esimCapable,
        },
      }),
    };

    let requestBody = {
      cartMeta: {
        zipCode: sessionCartData?.prospect5gLoopQualAddress?.zipCode || "",
        shoppingPath: "PROSPECT",
        flow: "4g",
      },
      lines: [
        {
          packageId: "",
          packageType: "FOURG",
          operation: operationVal,
          quantity: commonPlan?.quantity,
          plan: {
            planId: commonPlan?.selectedPlan,
          },
          byor: isByor,
          device: reqDevice,
          backUp: isBackupPlan,
        },
      ],
    };

    const response = yield apiClient.post(API.MOBILITY_ADD_TO_CART, requestBody);
    let sessionCartResponse = {
      prospectFlowData: response?.data?.response,
      serviceStatus: response?.data?.status,
    };
    yield put(Landing4GSlice.setRouterUpdateResp(response?.data)); 
    yield put(Landing4GSlice.setAddtoCartResponse(response?.data?.status));
    if (
      response &&
      response?.data?.status?.success &&
      response?.data?.response
    ) {
      const newStore = Utils.setStoreFromRetrieve(sessionCartResponse);
      yield put(GlobalSlice.setSessionCartData(newStore));
      yield put(Landing4GSlice.setAuthCartSuccess(true));
      yield put(Landing4GSlice.setValidateDeviceIdResp(""));
      //yield put(Landing4GSlice.setSelectedPlan(planId));
      window?.localStorage?.setItem("_cartData", JSON.stringify(newStore));
      //yield call(getRetrieveCart5GInfo)
    } else {
      throw Object.assign(
        new Error("Failed to update plan selection in session cart"),
        { status: response?.status, data: response?.data }
      );
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(Landing4GSlice.set4GLandingPageLoading(false));
  }
}

function* clearCartAndAdd4G(params) {
  const fourGData = yield select(selectors.fourGData);
  yield put(Landing4GSlice.setAPIError(false));
  try {
    yield call(clearAuth5gCart);
    if (fourGData?.primaryPlan?.selectedPlan) {
      yield call(addToCartFromAuth, "primaryPlan");
    }
    if (fourGData?.backupPlan?.selectedPlan) {
      yield call(addToCartFromAuth, "backupPlan");
    }
  } catch (err) {
    console.error(err);
    yield put(Landing4GSlice.setAPIError(true));
  } finally {
    // ?
  }
}
function* updateFCCTemplate() {
  
  // yield put(Landing4GSlice.setAPIError(false));
  yield put(GlobalSlice.setBbloadError(false));
  // yield put(Landing4GSlice.set4GLandingPageLoading(true));

try {
 
  const resp = yield apiClient.post(API.FCC_FWA_TEMPLATE_URL);
  // const resp = mockFCCTemplate;
  if(resp?.data && resp?.data?.serviceStatus?.success){
    const fccTemplate = resp?.data?.data;
    var parser = new DOMParser();
    var DOM = parser.parseFromString(fccTemplate, "text/html");
    DOM = DOM.querySelector(".fcc-bl-main-container").outerHTML;
    yield put(Landing4GSlice.sethtmlTemplateFcc(DOM));
  }else{
    yield put(GlobalSlice.setBbloadError(true));
  }
  
} catch (err) {
  yield put(GlobalSlice.setBbloadError(true));
  // yield put(Landing4GSlice.setAPIError(true));
} finally {
    yield put(Landing4GSlice.set4GLandingPageLoading(false));
}
}

function* getCombinedAemData() {
  
  // yield put(Landing4GSlice.setAPIError(false));
  yield put(GlobalSlice.setBbloadError(false));
  // yield put(Landing4GSlice.set4GLandingPageLoading(true));

try {

  const requestBody={
    "clientName": "react",
    "languageCategory": "en-us",
    "packageType": "FWA"
  }
  const resp = yield apiClient.post(API.FCC_COMBINED_AEM_DATA,requestBody);
  // const resp = mockFCCTemplate;
  if(resp?.data && resp?.data?.serviceStatus?.success){
    const fccTemplate = resp?.data?.htmlData;
    var parser = new DOMParser();
    var DOM = parser.parseFromString(fccTemplate, "text/html");
    DOM = DOM.querySelector(".fcc-bl-main-container").outerHTML;
    yield put(Landing4GSlice.sethtmlTemplateFcc(DOM));
    if(resp?.data?.jsonData?.responseFor && resp?.data?.jsonData?.responseFor == "FWA" && resp?.data?.jsonData?.planDetails && Object.keys(resp?.data?.jsonData?.planDetails).length >0){
      yield put(Landing4GSlice.setPlansDetailsFccNewObj(resp?.data?.jsonData?.planDetails))
    }
    yield put(Landing4GSlice.setLteLearnShowAddressModal(true));
  }else{
    yield put(GlobalSlice.setBbloadError(true));
  }
  
} catch (err) {
  yield put(GlobalSlice.setBbloadError(true));
  // yield put(Landing4GSlice.setAPIError(true));
} finally {
    yield put(Landing4GSlice.set4GLandingPageLoading(false));
}
}

function* updateFCCJson(testPlan) {

  // yield put(Landing4GSlice.setAPIError(false));
    yield put(GlobalSlice.setBbloadError(false));
  // yield put(Landing4GSlice.set4GLandingPageLoading(true));

try {

  const resp = yield apiClient.post(API.FCC_FWA_JSON_URL);
      // const resp = mockFCCData;
 
  let obj=[];
  
  if(resp?.data && resp?.data?.serviceStatus?.success){
    for(let i of testPlan?.payload){
      if(resp?.data?.data?.responseFor && resp?.data?.data?.responseFor == "FWA" && resp?.data?.data?.planDetails && resp?.data?.data?.planDetails[i] && Array.isArray(resp?.data?.data?.planDetails[i]) && resp?.data?.data?.planDetails[i].length > 0){
        if(resp?.data?.data?.planDetails && Object.keys(resp?.data?.data?.planDetails).length >0){
          // setPlanDataFwa(resp.planDetails[testPlan]);
          obj.push(resp?.data?.data?.planDetails[i])
        }  
      }
    }
    yield put(Landing4GSlice.setPlansDetailsFcc(obj))
    yield put(Landing4GSlice.setLteLearnShowAddressModal(true));
  }else{
    yield put(GlobalSlice.setBbloadError(true));
  }
  if((testPlan?.payload.length != obj?.length) && obj?.length > 0){
    yield put(GlobalSlice.setContainerHeightBB("32.5"));
  }

} catch (err) {
  yield put(GlobalSlice.setBbloadError(true));
  // yield put(Landing4GSlice.setAPIError(true));
} finally {
 
    yield put(Landing4GSlice.set4GLandingPageLoading(false));
}
}

function* updateFCCJsonNew(testPlan) {

  // yield put(Landing4GSlice.setAPIError(false));
    yield put(GlobalSlice.setBbloadError(false));
  yield put(Landing4GSlice.set4GLandingPageLoading(true));

try {

  const resp = yield apiClient.post(API.FCC_FWA_JSON_URL);
  //const resp = mockFCCData;
  
  if(resp?.data && resp?.data?.serviceStatus?.success){
    // for(let i of testPlan?.payload){
    //   if(resp?.data?.data?.responseFor && resp?.data?.data?.responseFor == "FWA" && resp?.data?.data?.planDetails && resp?.data?.data?.planDetails[i] && Array.isArray(resp?.data?.data?.planDetails[i]) && resp?.data?.data?.planDetails[i].length > 0){
    //     if(resp?.data?.data?.planDetails && Object.keys(resp?.data?.data?.planDetails).length >0){
    //       // setPlanDataFwa(resp.planDetails[testPlan]);
    //       obj.push(resp?.data?.data?.planDetails[i])
    //     }  
    //   }
    // }
    if(resp?.data?.data?.responseFor && resp?.data?.data?.responseFor == "FWA" && resp?.data?.data?.planDetails && Object.keys(resp?.data?.data?.planDetails).length >0){
      yield put(Landing4GSlice.setPlansDetailsFccNewObj(resp?.data?.data?.planDetails))
    }
    yield put(Landing4GSlice.setLteLearnShowAddressModal(true));
  }else{
    yield put(GlobalSlice.setBbloadError(true));
  }
  // if(resp?.data?.data?.planDetails && Object.keys(resp?.data?.data?.planDetails)?.length >0){
  //   yield put(GlobalSlice.setContainerHeightBB("32.5"));
  // }
} catch (err) {
  yield put(GlobalSlice.setBbloadError(true));
  // yield put(Landing4GSlice.setAPIError(true));
} finally {
 
    yield put(Landing4GSlice.set4GLandingPageLoading(false));
}
}

function* storeFccDetails(params) {

  try {

    const resp = yield apiClient.post(API.FCC_STORE_DETAILS,params?.payload);

  } catch (err) {
    
  } finally {
  
  }
}

export default function* actionWatcher() {
  yield takeLatest(Landing4GSlice.get4gRouterDevices.type, get4gRouterDevices);
  yield takeLatest(Landing4GSlice.get4gPlans.type, getPlans);
  yield takeLatest(Landing4GSlice.get4gTabPlans.type, get4gTabPlans);
  yield takeLatest(Landing4GSlice.getTaxesCharges.type, getTaxcharges);
  yield takeLatest(Landing4GSlice.update4GPlan.type, update4gPlans);
  yield takeLatest(Landing4GSlice.update4GRouter.type, update4GRouter);
  yield takeLatest(Landing4GSlice.addOrUpdate4GPage.type, addOrUpdate4GPage);
  yield takeLatest(Landing4GSlice.addPlanAndRouter.type, addPlanAndRouter);
  yield takeLatest(Landing4GSlice.validateDeviceId.type, validateDeviceId);
  yield takeLatest(Landing4GSlice.validateSimId.type, validateSimId);
  yield takeLatest(Landing4GSlice.get4GPageLoadData, get4GPageLoadData);
  yield takeLatest(Landing4GSlice.clearCartAndAdd4G.type, clearCartAndAdd4G);
  yield takeLatest(
    Landing4GSlice.update4GPlanAndFetchRouters.type,
    update4GPlanAndFetchRouters
  );
  yield takeLatest(Landing4GSlice.updateFCCTemplate, updateFCCTemplate);
  yield takeLatest(Landing4GSlice.getCombinedAemData, getCombinedAemData);
  yield takeLatest(Landing4GSlice.updateFCCJson, updateFCCJson);
  yield takeLatest(Landing4GSlice.storeFccDetails, storeFccDetails);
  yield takeLatest(Landing4GSlice.getComibnedPlanAndRouters, getComibnedPlanAndRouters);
  yield takeLatest(Landing4GSlice.getPlandAndRouters, getPlandAndRouters);
  yield takeLatest(Landing4GSlice.updateFCCJsonNew, updateFCCJsonNew);
}
