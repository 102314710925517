import React from "react";
import { useTranslation } from "react-i18next";
import { Title } from "@vds3/typography";
import AccountLevelTile from "./AccountLevelTile";

const AccountLevelFeatureTile = ({
  feature,
  isEditable,
  onEditClick,
  ...props
}) => {
  const { t } = useTranslation(["shoppingCart"]);
  return (
    <AccountLevelTile
      eyebrowText={t("shopping-cart.cart.accountLevelFeature")}
      content={
        <Title size="small" bold>
          {feature?.featureDesc}
        </Title>
      }
      price={feature?.featurePrice}
      isEditable={isEditable}
      onEditClick={onEditClick}
    />
  );
};

export default AccountLevelFeatureTile;
