import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { TextLink } from "@vds3/buttons";
import { Input } from "@vds3/inputs";
import { Modal, ModalBody, ModalTitle, ModalFooter } from "@vds3/modals";
import { Body, Title } from "@vds3/typography";
import {
	MarginSpacerS,
	MarginSpacerL,
	MarginSpacerM,
} from "common/components/styleTag";
import { postSaveCart } from "app/ducks/GlobalSlice";
import styled from "styled-components";

const SaveCartModal = ({ opened, onOpenedChange, ...props }) => {
	const { t } = useTranslation(["shoppingCart"]);
	const dispatch = useDispatch();

	const globalData = useSelector((state) => state.ProspectWidgets_GlobalData);
	const lqData = useSelector((state) => state.ProspectWidgets_LQPage);
	const sessionCartData =
		globalData?.sessionCartData?.apiResponse?.prospectFlowData;

	const [email, setEmail] = useState(
		lqData?.BBALQResp?.emailLQ || sessionCartData?.businessInfo?.email || ""
	);
	const [isSubmit, setIsSubmit] = useState(
		!!(lqData?.BBALQResp?.emailLQ || sessionCartData?.businessInfo?.email)
	);
	const [showSuccessModal, setShowSuccessModal] = useState(false);

	const saveCartServiceStatus =
		globalData?.sessionCartData?.apiResponse?.serviceStatus;

	const saveCartSucess = globalData?.saveCartSuccess;
	const success = saveCartSucess?.data?.serviceStatus?.success;
	const statusMessage = saveCartSucess?.data?.serviceStatus?.statusMessage;
	const cartId = saveCartSucess?.data?.cartId;

	let isSaveCartExist =
		statusMessage == t("shopping-cart.save-device-modal.saveStatusMsg");

	const savecart = t("savecart", {
		returnObjects: true,
	});
	const emailCapture = t("emailCapture", {
		returnObjects: true,
	});

	const { heading, info, info1, msg, msg1 } = savecart;
	const existingCartInfo = savecart["existing-cart-info"];
	const checkLink = savecart["check-link"];
	const emailAddressLabel = savecart["email-address-label"];
	const saveBtn = savecart["save-btn"];
	const replaceBtn = savecart["replace-btn"];
	const cancelBtn = savecart["cancel-btn"];
	const continueBtn = savecart["continue-btn"];
	const desciption = emailCapture?.disclaimer?.desciption;
	const href = emailCapture?.disclaimer?.link?.href;
	const text = emailCapture?.disclaimer?.link?.text;

	useEffect(() => {
		if (cartId) {
		setShowSuccessModal(true);
		window.dispatchEvent(
			new CustomEvent("QuoteSentSuccessfulFromWidget", {
				detail: {
					quoteId: cartId,
					emailId: email
				},
			})
		);
		window.dispatchEvent(
			new CustomEvent("customerEmailInfoFromWidget", {
				detail: {
					customerEmail: email,
				},
			})
		);
		}
	}, [saveCartSucess]);

	const onSaveClick = (overrideCart) => {
		const payload = {
			cartMeta: {
				creditApplicationNumber: "",
				locationCode:
					lqData?.BBALQResp?.locationId ||
					globalData?.widgetInputParams?.locationId?.value,
				salesCode:
					lqData?.BBALQResp?.salesCode ||
					globalData?.widgetInputParams?.salesCode?.value,
				email: email,
				sourcePath: "SAVE",
				zipCode: sessionCartData?.businessInfo?.address?.zipCode,
			},
			overrideCart: true,
			cartId: "",
			sendMail: "true",
			shoppingPath: "PROSPECT",
			approach: "",
			flow: lqData.flow,
		};
		window.dispatchEvent(
			new CustomEvent("customerEmailInfoFromWidget", {
				detail: {
					customerEmail: email,
				},
			})
		);
		dispatch(postSaveCart(payload));
	};

	const isValidEmail = (key, val) => {
		if (key === "emailID") {
			if (!val?.trim()) {
				setIsSubmit(false);
			} else {
				const pattern =
					/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				pattern.test(val) ? setIsSubmit(true) : setIsSubmit(false);
			}
			setEmail(val);
		}
	};

	const onReplaceClick = () => {
		onSaveClick(true);
	};

	const handleClick = () => {
		onOpenedChange(false);
	};

	return (
		<>
			{opened && !success && (
				<Modal
					hideCloseButton={false}
					disableOutsideClick={true}
					onOpenedChange={onOpenedChange}
					opened={opened}
				>
					<ModalTitle>
						<Title size="large" bold>
							{heading}
						</Title>
					</ModalTitle>
					<MarginSpacerL />
					<ModalBody>
						<Body size="large">{t("shopping-cart.save-device-modal.body")}</Body>
						<MarginSpacerS />
						<Body size="large">{info1}</Body>
						<MarginSpacerL />
						<InputContainer>
							<Input
								label={emailAddressLabel}
								value={email}
								onChange={(e) => isValidEmail("emailID", e.target.value)}
							/>
						</InputContainer>
						<MarginSpacerM />
						<Body color="#6F7171">
							{desciption}{" "}
							<TextLink href={href} target="_blank" color="#6F7171">
								{text}
							</TextLink>
						</Body>
					</ModalBody>
					<ModalFooter
						buttonData={{
							primary: {
								children: saveBtn,
								disabled: !isSubmit,
								size: "large",
								onClick: () => {
									onSaveClick(false);
								},
							},
							close: {
								children: cancelBtn,
								size: "large",
								onClick: () => {
									handleClick();
								},
							},
						}}
					/>
				</Modal>
			)}
			{opened && success && !isSaveCartExist && (
				<Modal
					hideCloseButton={false}
					disableOutsideClick={true}
					onOpenedChange={onOpenedChange}
					opened={opened}
				>
					<ModalTitle>
						<Title size="large" bold>
							{heading}
						</Title>
					</ModalTitle>
					<MarginSpacerL />
					<ModalBody>
						<Body size="large">{t("shopping-cart.save-device-modal.body")}</Body>
						<MarginSpacerS />
						<Body size="large" bold>
							Cart Id: {cartId}
						</Body>
						<MarginSpacerS />
						<Body size="large">{checkLink}</Body>
					</ModalBody>
					<ModalFooter
						buttonData={{
							primary: {
								children: continueBtn,
								onClick: handleClick,
							},
							close: null,
						}}
					/>
				</Modal>
			)}

			{opened && success && isSaveCartExist && (
				<Modal
					hideCloseButton={false}
					disableOutsideClick={true}
					onOpenedChange={onOpenedChange}
					opened={opened}
				>
					<ModalTitle>
						<Title size="large" bold>
							{existingCartInfo}
						</Title>
					</ModalTitle>
					<MarginSpacerL />
					<ModalBody>
						<Title size="medium" bold={true}>
							Cart: {cartId}
						</Title>
						<MarginSpacerM />
						<Body size="large">{msg}</Body>
						<MarginSpacerS />
						<Body size="large">{msg1}</Body>
					</ModalBody>
					<ModalFooter
						buttonData={{
							primary: {
								children: replaceBtn,
								onClick: () => {
									onReplaceClick();
								},
							},
							close: {
								children: cancelBtn,
								onClick: () => {
									handleClick();
								},
							},
						}}
					/>
				</Modal>
			)}
		</>
	);
};

const InputContainer = styled.div`
	max-width: 328px;
	@media only screen and (max-width: 528px) {
		max-width: 100%;
	}
`;

export default SaveCartModal;
