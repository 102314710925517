import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalTitle, ModalBody, ModalFooter } from "@vds3/modals";
import { Body } from "@vds3/typography";
import { MarginSpacerM } from "common/components/styleTag";
import { StyledFooterContainer } from "./styles";
import FWAContext from "../fwaContext";
import { Button } from "@vds3/buttons";
import { UnorderedList, ListItem } from "@vds3/lists";

const SpeedPlanModal4G = ({
  opened,
  onOpenedChange,
  isCband,
  isMMW,
  planType,
  ...props
}) => {
  const { flow } = useContext(FWAContext);
  const { t } = useTranslation(["4G-5G"]);
  const aemKey = flow === "4g" ? "fourG" : "fiveG";
  const aemContentKey =
    aemKey === "fiveG" && isMMW && !isCband
      ? "speedPlanModalMMW"
      : aemKey === "fourG" && planType === 'backupPlan'
      ? "speedPlanModalBackup"
      : "speedPlanModal";
  const aem = t(`4G-5G.${aemKey}.plan-content.${aemContentKey}`, {
    returnObjects: true,
  });

  return (
    <>
      <Modal
        surface="light"
        hideCloseButton={false}
        opened={opened}
        onOpenedChange={onOpenedChange}
      >
        <ModalTitle>{aem?.heading}</ModalTitle>
        <ModalBody>
          {aem?.sections?.map((section) => (
            <div key={aem?.subHeading}>
              {!(section?.subHeading?.includes("400 Mbps") && isCband && !isMMW) &&<><Body size="large" bold>
                {section?.subHeading}
              </Body>
              <MarginSpacerM />
              {section.renderList ? 
                <>
                  <UnorderedList>
            {section?.text?.map((element, idx) => {
              return (
                <ListItem key={idx} size="bodyLarge" spacing="compact">
                  <span dangerouslySetInnerHTML={{ __html: element }} />
                </ListItem>
              );
            })}
            <MarginSpacerM />
          </UnorderedList>
                </>
              :
               section?.text?.map((i) => (
                <>
                  <Body size="large">{i}</Body>
                  <MarginSpacerM />
                </>
              ))}
              </>}
            </div>
          ))}
        </ModalBody>
        <StyledFooterContainer>
          <Button
            width="100%"
            onClick={() => {
              onOpenedChange(false);
            }}
          >
            {t("4G-5G.common.page-stepper.close")}
          </Button>
        </StyledFooterContainer>
      </Modal>
    </>
  );
};

export default SpeedPlanModal4G;
