import { createAction, createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
	apiError: false,
	loading: false,
	bussResponse: {},
	bussId: "",
	cartResponse: {},
	selectedProductBUSS:{}
};

const BussProductSlice = createSlice({
	name: "BussProduct",
	initialState,
	reducers: {
		resetBussProductsSlice: (state) => {
			return initialState;
		},
		setMArketPlaceAccess:(state, action)=>{
			state["marketPlaceAccess"] = action.payload;
		},
		setAPIError: (state, action) => {
			state["apiError"] = action.payload;
		},
		setPageLoading: (state, action) => {
			state["loading"] = action.payload;
		},
		setBussResponse: (state, action) => {
			state["bussResponse"] = action.payload;
		},
		setBussId: (state, action) => {
			state["bussId"] = action.payload;
		},
		setCartResponse: (state, action) => {
			state["cartResponse"] = action.payload;
		},
		setIsAddToCartCompleted: (state, action) => {
			state["bussAddToCartCompleted"] = action.payload;
		},
		setSelectedProduct: (state, action) => {
			state["selectedProductBUSS"] = action.payload;
		},
		setMarketplaceSaveProductResp: (state, action) => {
			state["marketplaceSaveProductResp"] = action.payload;
		},
		setMarketplaceAddtoCartResp: (state, action) => {
			state["marketplaceSaveAddtoCartResp"] = action.payload;
		},
		setSelectedBussProduct:(state, action) => {
			state["selectedBussProduct"] = action.payload;
		},
	},
});
export const accessToShowMarketplace = createAction("BussProductSlice/accessToShowMarketplace");
export const reducer = BussProductSlice.reducer;
export const getBussProductPlans = createAction(
	"BussProductSlice/getBussProductPlans"
);
export const addToCart = createAction("BussProductSlice/addToCart");
export const marketplaceAddToCart = createAction("BussProductSlice/marketplaceAddToCart");
export const bussGridwall = createAction("BussProductSlice/bussGridwall");
export const bussPDP = createAction("BussProductSlice/bussPDP");

export const {
	setAPIError,
	setPageLoading,
	setBussResponse,
	setBussId,
	setCartResponse,
	resetBussProductsSlice,
	setSelectedProduct,
	setMarketplaceSaveProductResp,
	setMarketplaceAddtoCartResp,
	setIsAddToCartCompleted,
	setSelectedBussProduct,
	setMArketPlaceAccess
} = BussProductSlice.actions;
