export const SignerState = {
  contactInfo: {
    firstName: "",
    lastName: "",
    email: "",
    businessName: "",
    jobTitle: "",
    address: "",
    city: "",
    zipCode: "",
    state: "",
    selectedMailType: "",
    fedTaxId: ""
  },
  businessInfo: {
    selected: true,
    address: "",
    city: "",
    propertyType: "",
    state: "",
    zipCode: ""
  },
  shippingInfo: {
    selected: false,
    address: "",
    city: "",
    propertyType: "",
    state: "",
    zipCode: ""
  },
  corporation: {
    DateOfBirth: '',
    SSN: '',
  },
  billingaddressLabel: "You must enter your billing address.",
  enableAltShipping: false,
  errorTexts: {
    contactInfo: {
      fName: '',
      lName: '',
      cPhoneNumber: '',
      cEmail: '',
      cAddress: '',
      cState: '',
      cZip: '',
      cCity: '',
      cBusinessName: '',
      cjobTitle: '',
      fedTaxId: ''
    },
    billingInfo: {
      bAddress: '',
      bCity: '',
      bState: '',
      bZip: '',
    },
    shippingInfo: {
      sAddress: '',
      sCity: '',
      sState: '',
      sZip: '',
    },
    billingErr: '',
    shippingErr: '',
    homeAdressInfoErr: '',
    corporation: {}
  },
  showBizAdrErr: false,
  homeAdressInfo: "",
  homeAdressInfo2: "",
  addressError: false,
  checkStreetAddress: '',
  isSameAddress: false,
  sameAsCompanyAddress: {},
  constactInfoBUSS:[],
  serviceAddressBuss:[]
};

export default SignerState;

export function reducer(state, action) {
  switch (action.type) {
    case 'CLEAR_ADDRESS_ERROR':
      return {
        ...state,
        addressError: false,
        unitError: false
      }
    case 'SHOW_ERRORS':
      return {
        ...state,
        showError: action.payload
      }
    case 'SHOW_BIZADDR_ERRORS':
      return {
        ...state,
        showBizAdrErr: action.payload
      }

    case 'SET_BUSINESS_INFO':
      return {
        ...state,
        businessInfo: action.payload
      }

    case 'SET_CONTACT_INFO':
      return {
        ...state,
        contactInfo: action.payload
      }
      case 'SET_CONTACT_INFO_BUSS':
      return {
        ...state,
        constactInfoBUSS: [...state.constactInfoBUSS,action.payload]
      }
      case 'SET_SERVICE_INFO_BUSS':
        return {
          ...state,
          serviceAddressBuss: [...state.serviceAddressBuss,action.payload]
        }
    case 'SET_ERROR_MESSAGES':
      return {
        ...state,
        errorTexts: action.payload
      }
    case 'SET_IS_SAME_ADDRESSS':
      return {
        ...state,
        isSameAddress: action.payload
      }
    case 'SET_HOME_STREET_ADDRESS':
      return {
        ...state,
        homeAdressInfo: action.payload
      }
    case 'SET_HOME_STREET_ADDRESS2':
      return {
        ...state,
        homeAdressInfo2: action.payload
      }
    case 'SET_CORPORATION':
      return { ...state, corporation: action.payload }
    case "SET_SAMEASCOMPANY_ADDRESS":
      return {...state, sameAsCompanyAddress: action.payload }
    default:
      // throw new Error();
      return {
        ...state
      }
  }
}


function clearAddressError() { return { type: 'CLEAR_ADDRESS_ERROR' } }
function showErrors(data) { return { type: 'SHOW_ERRORS', payload: data } }
function showBizAddrErrors(data) { return { type: 'SHOW_BIZADDR_ERRORS', payload: data } }
function setBusinessInformation(data) { return { type: 'SET_BUSINESS_INFO', payload: data } }
function setContactInformation(data) { return { type: 'SET_CONTACT_INFO', payload: data } }
function setErrorMessages(data) { return { type: 'SET_ERROR_MESSAGES', payload: data } }
function setIsSameAddress(data) { return { type: 'SET_IS_SAME_ADDRESSS', payload: data } }
function setHomeAddressInfo(data) { return { type: 'SET_HOME_STREET_ADDRESS', payload: data } }
function setHomeAddress2Info(data) { return { type: 'SET_HOME_STREET_ADDRESS2', payload: data } }
function setCorporation(data) { return { type: 'SET_CORPORATION', payload: data } }
function setSameAsCompanyAddress(data) { return { type: 'SET_SAMEASCOMPANY_ADDRESS', payload: data } }
function setContactInformationBUSS(data) { return { type: 'SET_CONTACT_INFO_BUSS', payload: data } }
function setServiceInformationBUSS(data) { return { type: 'SET_SERVICE_INFO_BUSS', payload: data } }

export const actions = {
  clearAddressError,
  showErrors,
  showBizAddrErrors,
  setContactInformation,
  setErrorMessages,
  setBusinessInformation,
  setIsSameAddress,
  setHomeAddressInfo,
  setHomeAddress2Info,
  setCorporation,
  setSameAsCompanyAddress,
  setContactInformationBUSS,
  setServiceInformationBUSS
};
