import { put, takeLatest, call, takeEvery, fork, select } from "redux-saga/effects";
import * as API from "../../../containers/APIurls";
import apiClient from "../../../utils/apiClient";
import * as LowerFunnelSlice from "./LowerFunnelSlice";
import LowerFunnelSections from './LowerFunnelSections.json'

function* getCheckoutSectionsInfo(params) {
    try {
      const isPlans = localStorage.getItem("planspage") ?? false
      const globalData = yield select(state=>state.ProspectWidgets_GlobalData.widgetInputParams)
      const clientName = globalData?.clientName?.value
        yield put(LowerFunnelSlice.setCheckoutLoading(true));
        const response = yield apiClient.post(API.GET_LOWER_FUNNEL_SECTIONS);
       // const response =  {status:200,data:{sections : LowerFunnelSections[isPlans ? "marketPlaceFWALoggedIn" : clientName == "MYBIZ" ? 'marketPlaceLoggedIn' : clientName == "prospect" ? 'bussSection' : 'defaultSections']}}
        //change condition here once have api working
        const lowerFunnelSec = yield select(state=>state.LowerFunnelSlice.sectionsConfig)
        if (response?.status == 200) {
          yield put(LowerFunnelSlice.setShoppingCartPageRevisitedLower(true))
          let resultData = response.data
          if(lowerFunnelSec !== null && lowerFunnelSec && Object.keys(lowerFunnelSec).length){
            Object.entries(lowerFunnelSec).map(([key,value])=>{
              if(!!resultData['sections'] && resultData['sections'][key]!==null && resultData['sections'][key] && Object.keys(resultData['sections'][key]).length){
                resultData['sections'][key] = {...value}
              }
            })
          }
          yield put(LowerFunnelSlice.setCheckoutSections(resultData));
          yield put(LowerFunnelSlice.setCheckoutLoading(false));
        } else {
        //   yield put(ReviewSlice.setAccountSecurityQueRes({ status: false }));
        
        }
      } catch (err) {
        yield put(LowerFunnelSlice.setCheckoutLoading(true));
      }    
}

export default function* actionWatcher() {
yield takeLatest(LowerFunnelSlice.getCheckoutSections.type, getCheckoutSectionsInfo);
}
