export const SET_BUSINESS_STREET_ADDRESS = 'SET_BUSINESS_STREET_ADDRESS'
export const SET_BUSINESS_STREET_ADDRESS2 = 'SET_BUSINESS_STREET_ADDRESS2'
export const SET_BUSINESS_UNITS = 'SET_UNITS'  
// export const SET_SHIPPING_STREET_ADDRESS = 'SET_SHIPPING_STREET_ADDRESS'
export const SET_SHIPPING_UNITS = 'SET_UNITS'  
export const SET_IS_SAME_ADDRESSS = 'SET_IS_SAME_ADDRESSS'  
export const SET_BUSINESS_FED_ID = 'SET_BUSINESS_FED_ID'
export const SET_COMPANY_NAME = 'SET_COMPANY_NAME'
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER'
export const SET_SHIPPING_ERRORS = 'SET_SHIPPING_ERRORS'
